import { Box, BoxProps, Center, Flex, Grid, GridItem, useDisclosure, VStack } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import React from 'react';
import Inquiry from '../../../../components/common/Modals/Inquiry';
import { BuildingIcon, HouseAddIcon, HouseCheckColorIcon, NotebookIcon } from '../../../../statics/common/icons';

interface GridItemBoxProps extends BoxProps {
  logo?: any;
  rowSpan?: number;
  colSpan?: number;
  section_1: JSX.Element | string;
  section_1_size?: string;
  spacing?: number;
}

const GridItemBox: React.FC<GridItemBoxProps> = ({
  children,
  logo,
  rowSpan,
  colSpan,
  section_1,
  section_1_size = '20px',
  spacing = 1,
  ...props
}) => (
  <GridItem
    rowSpan={rowSpan}
    colSpan={colSpan}
    bgColor="white"
    border={'1px solid'}
    borderColor={'silverGray'}
    borderRadius={'md'}
    // px={10}
    // py={7}
    userSelect={'none'}
    cursor={'pointer'}
    boxShadow={'base'}
    {...props}
  >
    <motion.div
      style={{ width: '100%', height: '100%', padding: '28px', borderRadius: '0.25rem' }}
      whileHover={{ backgroundColor: '#775C18', color: 'rgb(255, 255, 255)' }}
    >
      <VStack flexDir={'column'} h={'full'} spacing={spacing} justifyContent={'center'} alignItems={'unset'}>
        {logo && <Box>{logo}</Box>}
        <Box fontSize={section_1_size} fontWeight={'medium'}>
          {section_1}
        </Box>
        <Flex flexDir={'column'} fontSize={'13px'}>
          {children}
        </Flex>
      </VStack>
    </motion.div>
  </GridItem>
);

const AgencyServicePage = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Center h={'75vh'}>
      <Grid h="464px" w={'1000px'} templateRows="repeat(2, 1fr)" templateColumns="repeat(3, 1fr)" gap={4}>
        <GridItemBox
          logo={<NotebookIcon />}
          rowSpan={1}
          colSpan={2}
          section_1="보홈 서비스 가이드"
          section_1_size="38px"
        >
          매물 업로드 가이드
        </GridItemBox>
        <GridItemBox
          colSpan={1}
          section_1={
            <Box>
              <Box>문의하기</Box>
              <Box>도움이 필요하신가요?</Box>
            </Box>
          }
          section_1_size="20px"
          spacing={5}
          onClick={onOpen}
        >
          <Box>보홈 고객센터 운영시간</Box>
          <Box>평일 10:00 ~ 18:00</Box>
          <Box>점심시간 13:00 ~ 14:00</Box>
        </GridItemBox>
        {/* <GridItem colSpan={1} bg="red" /> */}
        <GridItemBox logo={<HouseAddIcon />} colSpan={1} section_1="매물 업로드 가이드">
          <Box>매물 업로드 가이드</Box>
        </GridItemBox>
        <GridItemBox logo={<BuildingIcon />} colSpan={1} section_1="매물 관리 가이드">
          <Box>매물 관리 가이드</Box>
        </GridItemBox>
        <GridItemBox logo={<HouseCheckColorIcon />} colSpan={1} section_1="매물 제안 가이드">
          <Box>매물 제안 가이드</Box>
        </GridItemBox>
      </Grid>
      {/* Modal */}
      <Inquiry isOpen={isOpen} onClose={onClose} />
    </Center>
  );
};

export default AgencyServicePage;

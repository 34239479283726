import { Box, Button, ButtonProps } from '@chakra-ui/react';
import { DeleteCircle } from '../../../statics/common/icons';

const RemoveButton: React.FC<ButtonProps> = ({ children, ...props }) => {
  return (
    <Button
      transition={'opacity 0.2s ease'}
      _hover={{ opacity: 0.5 }}
      cursor={'pointer'}
      bgColor="white"
      border={'1px solid'}
      borderColor={'silverGray'}
      borderRadius={'md'}
      fontSize={'12px'}
      fontWeight={'normal'}
      w={'100px'}
      h={'32px'}
      position={'relative'}
      zIndex={10}
      {...props}
    >
      <Box position="absolute" top={0} right={0} transform={'translate(40%, -40%)'} zIndex={20}>
        <DeleteCircle w={5} h={5} />
      </Box>
      <Box>{children}</Box>
    </Button>
  );
};

export default RemoveButton;

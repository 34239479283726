import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Checkbox,
  Flex,
  HStack,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { Field, useFormikContext } from 'formik';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { initialValue, StepProps } from '..';
import { agreementFulfill } from '../../../../../statics/common/data/toast';

const One: React.FC<StepProps> = ({ setStep }) => {
  const initialCheckedItems = Array(5).fill(false);
  const [checkedItems, setCheckedItems] = useState(initialCheckedItems);

  const { values, setFieldValue, handleChange } = useFormikContext<typeof initialValue>();

  const isChecked = Object.values(values).slice(0, 5).every(Boolean);
  const isIndeterminate =
    Object.values(values).slice(0, 5).some(Boolean) && !Object.values(values).slice(0, 3).every(Boolean);

  const toast_notFulfill = useToast();
  const toast_id = 'toast_notFulfill';

  console.log('values => ', values);

  return (
    <Accordion h={'full'}>
      {/* 전체 체크 */}
      <Checkbox
        isChecked={isChecked}
        isIndeterminate={isIndeterminate}
        onChange={() => {
          if (isChecked) {
            setFieldValue('one', false);
            setFieldValue('two', false);
            setFieldValue('three', false);
            setFieldValue('four', false);
            setFieldValue('five', false);
            setCheckedItems([false, false, false, false, false]);
          } else {
            setFieldValue('one', true);
            setFieldValue('two', true);
            setFieldValue('three', true);
            setFieldValue('four', true);
            setFieldValue('five', true);
            setCheckedItems([true, true, true, true, true]);
          }
        }}
        fontSize={'15px'}
        fontWeight={'bold'}
        mb={5}
      >
        <Box pl={3}>모든 약관에 동의합니다.</Box>
      </Checkbox>
      <Flex flexDir={'column'} h={'440px'}>
        {/* 1 - 최상단 */}
        <Field
          name="one"
          as={Checkbox}
          onChange={(e: any) => {
            handleChange(e);
            setCheckedItems([e.target.checked, checkedItems[1], checkedItems[2], checkedItems[3], checkedItems[4]]);
          }}
          value={checkedItems[0]}
          isChecked={checkedItems[0]}
          variant="basic"
        >
          <AccordionItem>
            <AccordionButton py={3}>
              <HStack spacing={1} flex="1" textAlign="left" textStyle={'medium14'}>
                <Box color="zetaBlue">[필수]</Box>
                <Box>보홈 서비스 이용 약관 동의</Box>
              </HStack>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4} bg={'primarySkyblue'} fontSize={'11px'}>
              <VStack spacing={1} alignItems={'unset'}>
                <Box fontWeight={'bold'} color="zetaBlue">
                  제1조 (약관의 적용목적)
                </Box>
                <Box height="fit-content" maxHeight={'80px'} overflow={'scroll'}>
                  이 약관은 리버블(이하 “회사”라 한다)가 운영하는 “보홈”에서 제공하는 인터넷과 모바일 서비스 (이하
                  “서비스”라 한다)를 이용함에 있어 “회사”와 “이용자”의 권리, 의무 및 책임 사항, 기타 필요한 사항을
                  규정함을 목적으로 한다.
                </Box>
              </VStack>
            </AccordionPanel>
          </AccordionItem>
        </Field>
        {/* 2 */}
        <Field
          name="two"
          as={Checkbox}
          onChange={(e: any) => {
            handleChange(e);
            setCheckedItems([checkedItems[0], e.target.checked, checkedItems[2], checkedItems[3], checkedItems[4]]);
          }}
          value={checkedItems[1]}
          isChecked={checkedItems[1]}
          variant="basic"
        >
          <AccordionItem>
            {/* <AccordionItem borderWidth={'1px'} borderBottomColor={'gray.200'}> */}
            <AccordionButton py={3}>
              <HStack spacing={1} flex="1" textAlign="left" textStyle={'medium14'}>
                <Box color="zetaBlue">[필수]</Box>
                <Box>중개사 서비스 이용 약관 동의</Box>
              </HStack>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4} bg={'primarySkyblue'} fontSize={'11px'}>
              <VStack spacing={1} alignItems={'unset'}>
                <Box fontWeight={'bold'} color="zetaBlue">
                  중개사 서비스 이용 약관 동의
                </Box>
                <Box>
                  이 약관은 리버블(이하 “회사”라 한다)가 운영하는 “보홈”에서 제공하는 인터넷과 모바일 서비스 (이하
                  “서비스”라 한다)를 이용함에 있어 “회사”와 “이용자”의 권리, 의무 및 책임 사항, 기타 필요한 사항을
                  규정함을 목적으로 한다.
                </Box>
              </VStack>
            </AccordionPanel>
          </AccordionItem>
        </Field>
        {/* 3 */}
        <Field
          name="three"
          as={Checkbox}
          onChange={(e: any) => {
            handleChange(e);
            setCheckedItems([checkedItems[0], checkedItems[1], e.target.checked, checkedItems[3], checkedItems[4]]);
          }}
          value={checkedItems[2]}
          isChecked={checkedItems[2]}
          variant="basic"
        >
          <AccordionItem>
            <AccordionButton py={3}>
              <HStack spacing={1} flex="1" textAlign="left" textStyle={'medium14'}>
                <Box color="zetaBlue">[필수]</Box>
                <Box>개인정보 수집 및 이용 동의</Box>
              </HStack>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4} bg={'primarySkyblue'} fontSize={'11px'}>
              <VStack spacing={1} alignItems={'unset'}>
                <Box fontWeight={'bold'} color="zetaBlue">
                  제1조 (약관의 적용목적)
                </Box>
                <Box>
                  이 약관은 리버블(이하 “회사”라 한다)가 운영하는 “보홈”에서 제공하는 인터넷과 모바일 서비스 (이하
                  “서비스”라 한다)를 이용함에 있어 “회사”와 “이용자”의 권리, 의무 및 책임 사항, 기타 필요한 사항을
                  규정함을 목적으로 한다.
                </Box>
              </VStack>
            </AccordionPanel>
          </AccordionItem>
        </Field>
        {/* 4 */}
        <Field
          name="four"
          as={Checkbox}
          onChange={(e: any) => {
            handleChange(e);
            setCheckedItems([checkedItems[0], checkedItems[1], checkedItems[2], e.target.checked, checkedItems[4]]);
          }}
          value={checkedItems[3]}
          isChecked={checkedItems[3]}
          variant="basic"
        >
          <AccordionItem>
            <AccordionButton py={3}>
              <HStack spacing={1} flex="1" textAlign="left" textStyle={'medium14'}>
                <Box color="zetaBlue">[필수]</Box>
                <Box>매물 관리 규정에 대한 동의</Box>
              </HStack>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4} bg={'primarySkyblue'} fontSize={'11px'}>
              <VStack spacing={1} alignItems={'unset'}>
                <Box fontWeight={'bold'} color="zetaBlue">
                  제1조 (약관의 적용목적)
                </Box>
                <Box>
                  이 약관은 리버블(이하 “회사”라 한다)가 운영하는 “보홈”에서 제공하는 인터넷과 모바일 서비스 (이하
                  “서비스”라 한다)를 이용함에 있어 “회사”와 “이용자”의 권리, 의무 및 책임 사항, 기타 필요한 사항을
                  규정함을 목적으로 한다.
                </Box>
              </VStack>
            </AccordionPanel>
          </AccordionItem>
        </Field>
        {/* 5 - 최하단 */}
        <Field
          name="five"
          as={Checkbox}
          onChange={(e: any) => {
            handleChange(e);
            setCheckedItems([checkedItems[0], checkedItems[1], checkedItems[2], checkedItems[3], e.target.checked]);
          }}
          value={checkedItems[4]}
          isChecked={checkedItems[4]}
          variant="basic"
        >
          <AccordionItem>
            <AccordionButton py={3}>
              <HStack spacing={1} flex="1" textAlign="left" textStyle={'medium14'}>
                <Box color="zetaBlue">[선택]</Box>
                <Box>이벤트 및 혜택 알림 수신 동의 (이메일, sms)</Box>
              </HStack>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4} bg={'primarySkyblue'} fontSize={'11px'}>
              <VStack spacing={1} alignItems={'unset'}>
                <Box fontWeight={'bold'} color="zetaBlue">
                  제1조 (약관의 적용목적)
                </Box>
                <Box>
                  이 약관은 리버블(이하 “회사”라 한다)가 운영하는 “보홈”에서 제공하는 인터넷과 모바일 서비스 (이하
                  “서비스”라 한다)를 이용함에 있어 “회사”와 “이용자”의 권리, 의무 및 책임 사항, 기타 필요한 사항을
                  규정함을 목적으로 한다.
                </Box>
              </VStack>
            </AccordionPanel>
          </AccordionItem>
        </Field>
      </Flex>
      <HStack>
        <Link to={'/login/agency'}>
          <Button
            variant={values.one && values.two && values.three && values.four ? 'gray' : 'basic'}
            boxShadow={'md'}
            boxSize={'max-content'}
            style={{ height: '40px' }}
          >
            로그인으로 돌아가기
          </Button>
        </Link>
        <Button
          variant={values.one && values.two && values.three && values.four ? 'basic' : 'gray'}
          boxShadow={'md'}
          w={'full'}
          style={{ height: '40px' }}
          onClick={() => {
            // validation
            if (values.one && values.two && values.three && values.four) {
              setStep(1);
            } else {
              if (!toast_notFulfill.isActive(toast_id)) {
                toast_notFulfill({
                  id: toast_id,
                  ...agreementFulfill,
                });
              }
            }
          }}
        >
          다음
        </Button>
      </HStack>
    </Accordion>
  );
};

export default One;

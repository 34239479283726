import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Center,
  Flex,
  HStack,
  Image,
  Link,
  ListItem,
  StackDivider,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
  UnorderedList,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { Field, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { SingleDatepicker } from '../../../components/common/Calender';
import Validation from '../../../components/common/FormikValidation/Validation';
import { CustomInput } from '../../../components/common/Input';
import AddressFinder from '../../../components/common/Modals/AddressFinder';
import { TdFullWContainer } from '../../../components/common/Table/Tr';
import { num2han } from '../../../lib/common/utils/base';
import { useUpdateCompanyInfo } from '../../../lib/subscriber/hooks/mutation';
import { useGetCompanyInfomation } from '../../../lib/subscriber/hooks/query';
import { CompanyTypeTrans } from '../../../lib/subscriber/utils/utils';
import { datedashformat } from '../../../lib/common/utils/dateformatter';
import { staticThreeCommafy, threeCommafy } from '../../../lib/common/utils/digitformatter';
import { EditPencilIcon, LeftArrowIcon, SubtractIcon } from '../../../statics/common/icons';
import OfficeBuilding from '../../../statics/common/images/office-building.png';

const CompanyPage = () => {
  const [selectedFile, setSelectedFile] = useState<any>();
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [preview, setPreview] = useState<string>();
  const navigate = useNavigate();

  const { data: cpData } = useGetCompanyInfomation();
  const { mutate, mutateAsync } = useUpdateCompanyInfo();

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);
    setIsEditable(true);
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  if (cpData === undefined) {
    return null;
  }

  const {
    company_code,
    name,
    business_type,
    business_registration_number,
    business_status,
    business_item,
    address,
    detail_address,
    capital,
    establishment_date,
    employee_number,
    representative_number,
    digital_tax_invoice_email,
    business_certificate,
    logo_url,
  } = cpData;

  const onSelectFile = (e: any) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    setSelectedFile(e.target.files[0]);
  };

  return (
    <Center w="full" h="full">
      <Formik
        initialValues={{
          business_status,
          business_item,
          address,
          detail_address,
          capital,
          establishment_date,
          employee_number,
          representative_number,
          digital_tax_invoice_email,
          logo_url,
        }}
        validationSchema={Yup.object().shape({
          business_status: Yup.string().required('사업자 등록증 내 업태를 입력해 주세요.'),
          business_item: Yup.string().required('사업자 등록증 내 종목을 입력해 주세요.'),
          address: Yup.string().required('기본 주소를 입력해 주세요.'),
          capital: Yup.string().matches(/^[0-9]+$/, '숫자만 입력 가능합니다.'),
          employee_number: Yup.string()
            .matches(/^[0-9]+$/, '숫자만 입력 가능합니다.')
            .test({
              message: '한 명 이상의 근로자 기입이 필요합니다.',
              test: (num: any) => {
                if (!num) {
                  return false;
                }
                if (num > 0) {
                  return true;
                } else {
                  return false;
                }
              },
            }),
          // .min(1, '한 명 이상의 근로자 기입이 필요합니다.'),
          establishment_date: Yup.string().required('회사설립연월일을 선택해주세요.'),
          representative_number: Yup.string()
            .required('회사 대표번호를 - 없이 입력해 주세요.')
            .matches(/^[0-9]+$/, '숫자만 입력 가능합니다.')
            .min(8, '번호는 최소 8글자 입니다.'),

          digital_tax_invoice_email: Yup.string()
            .email('유효하지 않는 이메일입니다.')
            .required('이메일을 입력해 주세요.'),
        })}
        onSubmit={(values, { setSubmitting }) => {
          console.log(values);
          setSubmitting(true);
          const formData = new FormData();
          formData.append('business_status', values.business_status as any);
          formData.append('business_item', values.business_item as any);
          formData.append('address', values.address as any);
          formData.append('detail_address', values.detail_address as any);
          formData.append('capital', values.capital as any);
          formData.append('establishment_date', values.establishment_date as any);
          formData.append('employee_number', values.employee_number as any);
          formData.append('representative_number', values.representative_number as any);
          formData.append('digital_tax_invoice_email', values.digital_tax_invoice_email as any);
          formData.append('logo', values.logo_url as any);
          mutateAsync({
            formData,
          })
            .then(() => {
              setIsEditable(false);
              setSubmitting(false);
            })
            .catch(() => {
              setSubmitting(false);
            });
        }}
      >
        {(props) => {
          const isNotAnyChange = props.initialValues === props.values;
          console.log('props.errors =>', props.errors);

          return (
            <Form id="form-a" style={{ width: '100%', height: '100%' }}>
              <Flex
                flexDir={'column'}
                bg="white"
                borderRadius={'lg'}
                boxShadow={'2px 2px 20px 0px #DDDDDD'}
                w={'full'}
                h="full"
                minW={'1048px'}
                alignItems={'flex-start'}
              >
                <Box
                  w={'full'}
                  borderBottomColor={'borderGray'}
                  boxShadow={'rgba(0, 0, 0, 0.02) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 2px 3px -2px'}
                  h={'120px'}
                  alignItems={'center'}
                >
                  <Flex h={'full'} px={'45px'} alignItems={'center'} justifyContent={'space-between'}>
                    <HStack>
                      {!isEditable && (
                        <LeftArrowIcon w={6} h={6} mr={2} cursor={'pointer'} onClick={() => navigate(-1)} />
                      )}
                      <Image src={OfficeBuilding} w={6} h={6} />
                      <Text fontWeight={'bold'} fontSize={'24px'} lineHeight={0}>
                        고객사 정보
                      </Text>
                    </HStack>
                    {isEditable ? (
                      <ButtonGroup variant="outline">
                        <Button
                          variant={'basic'}
                          form="form-a"
                          type="submit"
                          isLoading={props.isSubmitting}
                          isDisabled={!props.isValid || isNotAnyChange}
                        >
                          수정 완료
                        </Button>
                        <Button
                          variant={'gray'}
                          _hover={{ bgColor: 'red', borderColor: 'red' }}
                          onClick={() => {
                            props.handleReset();
                            setIsEditable((prev) => !prev);
                          }}
                          autoFocus
                          isDisabled={props.isSubmitting}
                        >
                          수정 취소
                        </Button>
                      </ButtonGroup>
                    ) : (
                      <SubtractIcon
                        w={6}
                        h={6}
                        cursor={'pointer'}
                        _hover={{
                          transition: '0.15s linear',
                          transform: 'rotate(90deg)',
                        }}
                        onClick={() => setIsEditable((prev) => !prev)}
                      />
                    )}
                  </Flex>
                </Box>
                <VStack w="full" h="full" spacing={0} divider={<StackDivider borderColor="gray.200" />}>
                  <HStack w={'full'} justifyContent={'space-between'} px={'45px'} py={6}>
                    {/*  */}
                    <VStack justifyContent={'start'} w={'100%'} spacing={6}>
                      {/* 기업 정보 */}
                      <Flex flexDir={'column'} justifyContent={'start'} alignItems={'flex-start'} w={'100%'}>
                        <Box fontWeight={'bold'} mb={3} fontSize={'18px'} textAlign={'start'} w={'112px'}>
                          기업 정보
                        </Box>
                        <UnorderedList mb={2}>
                          <ListItem>
                            <Text fontSize={'14px'}>
                              기업명, 사업자 구분, 사업자등록번호 변경은 고객센터 문의주시길 바랍니다.
                            </Text>
                          </ListItem>
                        </UnorderedList>
                        <Box w={'full'} borderTopWidth={'1px'} borderTopColor={'blackAlpha.500'} />
                        <Table w={'100%'} variant="simple_lining">
                          <Tbody w={'100%'}>
                            <Tr w={'100%'}>
                              <TdFullWContainer title={'고유코드'}>
                                <Text>{company_code}</Text>
                              </TdFullWContainer>
                            </Tr>
                            <Tr>
                              <TdFullWContainer title={'기업명'}>
                                <Text>{name}</Text>
                              </TdFullWContainer>
                              <Td rowSpan={5} borderInline={'1px solid #e2e8f0'} w={'200px'}>
                                <Center w={'full'} h={'full'} position={'relative'} cursor="pointer" role="group">
                                  <input
                                    type="file"
                                    accept="image/*"
                                    id="file-uploader"
                                    style={{ display: 'none' }}
                                    onChange={(e) => {
                                      console.log('logo upload: ', e.target.files);

                                      onSelectFile(e);
                                      if (e.target.files) {
                                        props.setFieldValue('logo_url', e.target.files[0]);
                                      }
                                    }}
                                  />
                                  <label
                                    htmlFor="file-uploader"
                                    style={{
                                      width: '128px',
                                      height: '128px',
                                      cursor: 'pointer',
                                      position: 'absolute',
                                      zIndex: 10000,
                                    }}
                                  />
                                  <Avatar
                                    name="보 홈"
                                    src={preview ? preview : logo_url}
                                    bg={'white'}
                                    borderWidth={1}
                                    borderColor={'#aaaaaa'}
                                    size={'2xl'}
                                    _groupHover={{
                                      opacity: 0.6,
                                      transition: '0.1s linear',
                                    }}
                                    objectFit={'scale-down'}
                                  />
                                  <Center position={'absolute'} bottom={1} right={1}>
                                    <Center
                                      w={'full'}
                                      h={'full'}
                                      borderColor={'#C4C4C4'}
                                      borderWidth={'2px'}
                                      borderRadius={'full'}
                                      p={1}
                                      bgColor={'white'}
                                    >
                                      <EditPencilIcon w={6} h={6} />
                                    </Center>
                                  </Center>
                                </Center>
                              </Td>
                            </Tr>
                            {/* <Tr>
                            <TdFullWContainer title={'대표자명'}>
                              <Text>{`서수민`}</Text>
                            </TdFullWContainer>
                          </Tr> */}
                            <Tr>
                              <TdFullWContainer title={'사업자 구분 / 등록번호'}>
                                <Text>
                                  {CompanyTypeTrans(business_type)} / {business_registration_number}
                                </Text>
                              </TdFullWContainer>
                            </Tr>
                            <Tr>
                              <TdFullWContainer title={'업태'}>
                                {isEditable ? (
                                  <Field
                                    name="business_status"
                                    as={CustomInput}
                                    w={'450px'}
                                    isInvalid={!!props.errors.business_status}
                                  />
                                ) : (
                                  <Text>{business_status}</Text>
                                )}
                              </TdFullWContainer>
                            </Tr>
                            <Tr>
                              <TdFullWContainer title={'종목'}>
                                {isEditable ? (
                                  <Field
                                    name="business_item"
                                    as={CustomInput}
                                    w={'450px'}
                                    isInvalid={!!props.errors.business_item}
                                  />
                                ) : (
                                  <Text>{business_item}</Text>
                                )}
                              </TdFullWContainer>
                            </Tr>
                            {isEditable ? (
                              <>
                                <Tr>
                                  <TdFullWContainer title={'사업장 소재지 주소'}>
                                    <HStack>
                                      <Flex w={'450px'}>
                                        <Field name="address" as={CustomInput} isDisabled />
                                      </Flex>
                                      <Button variant={'lotionWhite'} h={'32px'} onClick={onOpen}>
                                        주소 찾기
                                      </Button>
                                    </HStack>
                                  </TdFullWContainer>
                                </Tr>
                                <Tr>
                                  <TdFullWContainer title={'사업장 소재지 상세주소'}>
                                    <Flex w={'450px'}>
                                      <Field name="detail_address" as={CustomInput} />
                                    </Flex>
                                  </TdFullWContainer>
                                </Tr>
                              </>
                            ) : (
                              <Tr>
                                <TdFullWContainer title={'사업장 소재지'}>
                                  <Text>
                                    {address},{detail_address}
                                  </Text>
                                </TdFullWContainer>
                              </Tr>
                            )}
                            <Tr>
                              <TdFullWContainer title={'자본금'}>
                                {isEditable ? (
                                  <Flex w={'450px'}>
                                    <Field name="capital" as={CustomInput} isInvalid={!!props.errors.capital} />
                                  </Flex>
                                ) : (
                                  <Text>
                                    {staticThreeCommafy(capital)}원 ({num2han(capital)})
                                  </Text>
                                )}
                              </TdFullWContainer>
                            </Tr>
                            <Tr>
                              <TdFullWContainer title={'회사설립연월일'} ContentTdProps={{ colSpan: 2 }}>
                                {isEditable ? (
                                  <Flex w={'450px'}>
                                    <SingleDatepicker
                                      date={
                                        props.values.establishment_date
                                          ? new Date(props.values.establishment_date)
                                          : new Date()
                                      }
                                      // {...formik.getFieldProps('completion_date')}
                                      onDateChange={(date) => {
                                        props.setFieldValue('establishment_date', date);
                                      }}
                                      propsConfigs={{
                                        inputProps: {
                                          bgColor: 'lotionWhite',
                                          size: 'sm',
                                          fontSize: '12px',
                                          borderRadius: 'md',
                                          borderColor: 'silverGray',
                                          color: 'highGray',
                                        },
                                      }}
                                    />
                                  </Flex>
                                ) : (
                                  <Text>{datedashformat(establishment_date)}</Text>
                                )}
                              </TdFullWContainer>
                            </Tr>
                            <Tr>
                              <TdFullWContainer title={'근로자수'}>
                                {isEditable ? (
                                  <Flex flexDir={'column'}>
                                    <HStack>
                                      <Flex w={'80px'}>
                                        <Field
                                          name="employee_number"
                                          as={CustomInput}
                                          isInvalid={!!props.errors.employee_number}
                                        />
                                      </Flex>
                                      <Text>명</Text>
                                    </HStack>
                                    {/* <Validation error={props.errors.employee_number} /> */}
                                  </Flex>
                                ) : (
                                  <>
                                    <Text display={'inline-block'}>{employee_number}</Text>
                                    <Text display={'inline-block'}>명</Text>
                                  </>
                                )}
                              </TdFullWContainer>
                            </Tr>
                            <Tr>
                              <TdFullWContainer title={'회사 대표번호'} ContentTdProps={{ colSpan: 2 }}>
                                {isEditable ? (
                                  <Flex w={'450px'}>
                                    <Field
                                      name="representative_number"
                                      as={CustomInput}
                                      type="number"
                                      isInvalid={!!props.errors.representative_number}
                                    />
                                  </Flex>
                                ) : (
                                  <>
                                    <Text>{representative_number}</Text>
                                  </>
                                )}
                              </TdFullWContainer>
                            </Tr>
                            <Tr>
                              <TdFullWContainer
                                title={'전자 세금 계산서 전용 전자우편 주소'}
                                ContentTdProps={{ colSpan: 2 }}
                              >
                                {isEditable ? (
                                  <Field
                                    name="digital_tax_invoice_email"
                                    as={CustomInput}
                                    w={'450px'}
                                    isInvalid={!!props.errors.digital_tax_invoice_email}
                                  />
                                ) : (
                                  <Text>{digital_tax_invoice_email}</Text>
                                )}
                              </TdFullWContainer>
                            </Tr>
                          </Tbody>
                        </Table>
                      </Flex>
                      {/* 기업 정보 */}
                      <Flex flexDir={'column'} justifyContent={'start'} alignItems={'flex-start'} w={'100%'}>
                        <Box fontWeight={'bold'} mb={3} fontSize={'18px'} textAlign={'start'} w={'112px'}>
                          기업 정보
                        </Box>
                        <Box w={'full'} borderTopWidth={'1px'} borderTopColor={'blackAlpha.500'} />
                        <Table w={'100%'} variant="simple_lining">
                          <Tbody w={'100%'}>
                            <Tr w={'100%'}>
                              <TdFullWContainer title={'사업자 등록증'}>
                                <Link href={business_certificate} download isExternal>
                                  등록된 파일
                                </Link>
                              </TdFullWContainer>
                            </Tr>
                          </Tbody>
                        </Table>
                      </Flex>
                    </VStack>
                    {/*  */}
                  </HStack>
                </VStack>
              </Flex>
              <AddressFinder isOpen={isOpen} onClose={onClose} formik={props} />
            </Form>
          );
        }}
      </Formik>
    </Center>
  );
};

export default CompanyPage;

import { StarIcon } from '@chakra-ui/icons';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Checkbox,
  Collapse,
  Flex,
  HStack,
  Input,
  InputGroup,
  InputRightAddon,
  Modal,
  ModalContent,
  ModalOverlay,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Textarea,
  Thead,
  Tr,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { useRef } from 'react';
import { useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { ModalDisclosureProps, ModalDisclosureWithServiceIdProps } from '../../common/Modals/base';
import { useConfirmService, useWithdrawService } from '../../../lib/subscriber/hooks/mutation';
import {
  parseContractHistories,
  parseRequestHistories,
  useGetFormRequest,
  useGetUserAllHistories,
} from '../../../lib/subscriber/hooks/query';
import { deCommafy, threeCommafy } from '../../../lib/common/utils/digitformatter';
import { LeftArrowIcon, RightArrowIcon } from '../../../statics/common/icons';
import { GNoBBox } from '../../common/Box';
import { WTd } from '../../common/Table/Td';
import { WTh } from '../../common/Table/Th';
import ContractContentTable from '../Table/contractContentTable';
import RequestContentTable from '../Table/requestContentTable';
import UsageContentTable from '../Table/usageContentTable';
import RequestWithdrawalContentTable from '../Table/requestWithdrawalTable';

const WithdrawalRequestForm: React.FC<ModalDisclosureWithServiceIdProps> = ({
  isOpen,
  onClose,
  service_id,
  user_id,
}) => {
  const { isOpen: isFormOpen, onToggle: onFormToggle } = useDisclosure();
  const { isOpen: isHistoryOpen, onToggle: onHistoryToggle } = useDisclosure();
  const { isOpen: isContractOpen, onToggle: onContractToggle } = useDisclosure();

  const {
    isOpen: isConfirmApprovalOpen,
    onOpen: onConfirmApprovalOpen,
    onClose: onConfirmApprovalClose,
  } = useDisclosure();

  const { isOpen: isConfirmRejectOpen, onOpen: onConfirmRejectOpen, onClose: onConfirmRejectClose } = useDisclosure();

  const [phase, setPhase] = useState<'initial' | 'reject' | 'approve'>('initial');
  const [supportFee, setSupportFee] = useState(0);
  const [rejectReason, setRejectReason] = useState('');

  const componentStartRef = useRef<HTMLDivElement>(null);
  const componentEndRef = useRef<HTMLDivElement>(null);
  const inputFocus = useRef<any>(null);
  const confirmApprovalRef = useRef();
  const confirmRejectRef = useRef();

  // 이용 약관
  const [checkedItems, setCheckedItems] = useState([false, false]);

  const allChecked = checkedItems.every(Boolean);
  const isIndeterminate = checkedItems.some(Boolean) && !allChecked;

  useEffect(() => {
    if (phase === 'approve' || phase === 'reject') {
      const scroll = setTimeout(() => {
        componentEndRef.current?.scrollIntoView({ behavior: 'smooth' });
      }, 500);

      return () => clearTimeout(scroll);
    }
    if (phase === 'initial') {
      const scroll = setTimeout(() => {
        componentStartRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }, 200);
      return () => clearTimeout(scroll);
    }
  }, [phase]);

  const { data: serviceData } = useGetFormRequest(service_id);

  const { mutateAsync } = useWithdrawService();
  const { data: allHistoriesData } = useGetUserAllHistories(user_id);

  const allRequestHistoriesData = allHistoriesData && parseRequestHistories(allHistoriesData);
  const allContractHistoriesData = allHistoriesData && parseContractHistories(allHistoriesData);

  if (serviceData === undefined) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside" size={'2xl'} motionPreset="slideInRight">
      <ModalOverlay />
      <ModalContent>
        <Flex flexDir={'column'} w={'full'} h="full" alignItems={'flex-start'}>
          <Box
            w={'full'}
            borderBottomColor={'borderGray'}
            boxShadow={'rgba(0, 0, 0, 0.02) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 2px 3px -2px'}
            px={7}
            py={6}
          >
            <Flex justifyContent={'space-between'}>
              <HStack h={'34px'}>
                {phase !== 'initial' && (
                  <LeftArrowIcon w={6} h={6} color={'black'} cursor={'pointer'} onClick={() => setPhase('initial')} />
                )}
                <Text>💌</Text>
                <Text fontSize={'18px'} fontWeight={'medium'}>
                  {serviceData.employee.user_name}님의 계약 철회 신청서
                </Text>
              </HStack>
              {phase === 'initial' && (
                <HStack>
                  <Button
                    variant={'grayBorder_blackFont'}
                    w={'90px'}
                    h={'34px'}
                    borderRadius={'md'}
                    onClick={() => setPhase('reject')}
                  >
                    반려
                  </Button>
                  <Button variant={'basic'} w={'90px'} h={'34px'} onClick={() => setPhase('approve')}>
                    승인
                  </Button>
                </HStack>
              )}
            </Flex>
          </Box>
          <Scrollbars
            style={{ height: '640px' }}
            thumbSize={400}
            id="hmmm"
            renderView={({ style, ...props }) => (
              <div id="renderView" style={{ ...style }} {...props}>
                {/* renderView */}
              </div>
            )}
          >
            {/* 1. 신청 내용 */}
            <Box w={'full'} px={7} py={6} ref={componentStartRef}>
              <Flex onClick={onFormToggle} alignItems={'center'} justifyContent={'space-between'} cursor={'pointer'}>
                <Text fontWeight={'medium'} fontSize={'14px'}>
                  신청 내용
                </Text>
                <RightArrowIcon
                  w={6}
                  h={6}
                  transform={`rotate(${isFormOpen ? 270 : 90}deg)`}
                  transition={'transform 0.15s linear'}
                />
              </Flex>
              <Box pb={2} borderBottomWidth={'1px'} borderBottomColor={'gray'} />
              <Collapse in={!isFormOpen}>
                <RequestWithdrawalContentTable data={serviceData} />
              </Collapse>
            </Box>
            {/* 2. 이용 내역 */}
            <Box w={'full'} px={7} pb={6}>
              <Flex onClick={onHistoryToggle} alignItems={'center'} justifyContent={'space-between'} cursor={'pointer'}>
                <Text fontWeight={'medium'} fontSize={'14px'}>
                  {serviceData.employee.user_name}님의 이용 내역
                </Text>
                <RightArrowIcon
                  w={6}
                  h={6}
                  transform={`rotate(${isHistoryOpen ? 270 : 90}deg)`}
                  transition={'transform 0.15s linear'}
                />
              </Flex>
              <Box pb={2} borderBottomWidth={'1px'} borderBottomColor={'gray'} />
              <Collapse in={!isHistoryOpen}>
                <Flex flexDir={'column'} w={'full'} flexGrow={1}>
                  <UsageContentTable data={allRequestHistoriesData} />
                </Flex>
              </Collapse>
            </Box>
            {/* 3. 계약 내용 */}
            <Box w={'full'} px={7} pb={6}>
              <Flex
                onClick={onContractToggle}
                alignItems={'center'}
                justifyContent={'space-between'}
                cursor={'pointer'}
              >
                <Text fontWeight={'medium'} fontSize={'14px'}>
                  {serviceData.employee.user_name}님의 계약 내용
                </Text>
                <RightArrowIcon
                  w={6}
                  h={6}
                  transform={`rotate(${isContractOpen ? 270 : 90}deg)`}
                  transition={'transform 0.15s linear'}
                />
              </Flex>
              <Box pb={2} borderBottomWidth={'1px'} borderBottomColor={'gray'} />
              <Collapse in={!isContractOpen}>
                <Flex flexDir={'column'} w={'full'} flexGrow={1}>
                  <ContractContentTable data={allContractHistoriesData} />
                </Flex>
              </Collapse>
            </Box>
            {/* 승인 */}
            <Collapse in={phase === 'approve'} style={{ width: '100%' }}>
              <Box w={'full'} px={7} pb={6}>
                <HStack w="full" mt={6} justifyContent={'center'}>
                  <Button
                    variant={'gray_hoverGray'}
                    fontSize={'20px'}
                    fontWeight={'bold'}
                    style={{ width: '102px', height: '44px' }}
                    onClick={onClose}
                  >
                    취소
                  </Button>
                  <>
                    <Button
                      variant={'basic'}
                      fontSize={'20px'}
                      fontWeight={'bold'}
                      style={{ width: '192px', height: '44px' }}
                      // isDisabled={!allChecked}
                      onClick={() => {
                        onConfirmApprovalOpen();
                      }}
                    >
                      승인 완료
                    </Button>
                    <AlertDialog
                      isOpen={isConfirmApprovalOpen}
                      leastDestructiveRef={confirmApprovalRef.current}
                      onClose={onConfirmApprovalClose}
                      isCentered
                    >
                      <AlertDialogOverlay>
                        <AlertDialogContent>
                          <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            승인 확정
                          </AlertDialogHeader>

                          <AlertDialogBody>
                            <Text display={'inline-block'} fontWeight={'medium'}>
                              {serviceData.employee.user_name}
                            </Text>
                            님의 신청을 승인하시겠습니까?
                          </AlertDialogBody>

                          <AlertDialogFooter>
                            <Button
                              ref={confirmApprovalRef.current}
                              onClick={onConfirmApprovalClose}
                              variant={'gray_hoverGray'}
                            >
                              취소
                            </Button>
                            <Button
                              variant={'basic'}
                              style={{ height: '40px', fontSize: '16px' }}
                              onClick={() => {
                                mutateAsync({
                                  employee_mutation_id: serviceData.employee._id,
                                  service_mutation_id: serviceData.employee.service._id,
                                  check_status: 'withdrawn',
                                }).then(() => {
                                  onConfirmApprovalClose();
                                  onClose();
                                });
                              }}
                              ml={3}
                            >
                              승인 완료
                            </Button>
                          </AlertDialogFooter>
                        </AlertDialogContent>
                      </AlertDialogOverlay>
                    </AlertDialog>
                  </>
                </HStack>
              </Box>
            </Collapse>
            {/* 반려 */}
            <Collapse in={phase === 'reject'} style={{ width: '100%' }}>
              <Box w={'full'} px={7} pb={6}>
                <Textarea
                  value={rejectReason}
                  onChange={(e) => setRejectReason(e.target.value)}
                  placeholder="반려 사유를 작성해 주세요. 작성해주신 내용은 해당 임직원에게 전달됩니다."
                  _placeholder={{ color: 'silverGray', fontSize: '16px' }}
                  bgColor={'#f8f8f8'}
                  borderRadius={'md'}
                  resize={'none'}
                  h={'192px'}
                  px={'18px'}
                  py={'25px'}
                  ref={inputFocus}
                />
                <HStack w="full" mt={6} justifyContent={'center'}>
                  <Button
                    variant={'gray_hoverGray'}
                    fontSize={'20px'}
                    fontWeight={'bold'}
                    style={{ width: '102px', height: '44px' }}
                    onClick={onClose}
                  >
                    취소
                  </Button>
                  <>
                    <Button
                      variant={'basic'}
                      fontSize={'20px'}
                      fontWeight={'bold'}
                      style={{ width: '192px', height: '44px' }}
                      onClick={onConfirmRejectOpen}
                    >
                      반려 완료
                    </Button>
                    <AlertDialog
                      isOpen={isConfirmRejectOpen}
                      leastDestructiveRef={confirmRejectRef.current}
                      onClose={onConfirmRejectClose}
                      isCentered
                    >
                      <AlertDialogOverlay>
                        <AlertDialogContent>
                          <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            반려 확정
                          </AlertDialogHeader>

                          <AlertDialogBody>
                            <Text display={'inline-block'} fontWeight={'medium'}>
                              {serviceData.employee.user_name}
                            </Text>
                            님의 신청을 반려하시겠습니까?
                          </AlertDialogBody>

                          <AlertDialogFooter>
                            <Button
                              ref={confirmRejectRef.current}
                              onClick={onConfirmRejectClose}
                              variant={'gray_hoverGray'}
                            >
                              취소
                            </Button>
                            <Button
                              variant={'basic'}
                              style={{ height: '40px', fontSize: '16px' }}
                              onClick={() =>
                                mutateAsync({
                                  employee_mutation_id: serviceData.employee._id,
                                  service_mutation_id: serviceData.employee.service._id,
                                  // is_approved: false,
                                  // 'check_status':'rejected',
                                  rejected_reason: rejectReason,
                                }).then(() => {
                                  onConfirmRejectClose();
                                  onClose();
                                })
                              }
                              ml={3}
                            >
                              반려 완료
                            </Button>
                          </AlertDialogFooter>
                        </AlertDialogContent>
                      </AlertDialogOverlay>
                    </AlertDialog>
                  </>
                </HStack>
              </Box>
            </Collapse>
            <Box ref={componentEndRef} />
          </Scrollbars>
        </Flex>
      </ModalContent>
    </Modal>
  );
};

export default WithdrawalRequestForm;

import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Spinner,
  Table,
  Tbody,
  Text,
  Thead,
  Tr,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { isUndefined } from '@chakra-ui/utils';
import format from 'date-fns/format';
import { useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { FaSearch } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { PathProps } from '.';
import { GTitleItem } from '../../../../../components/common/GridItem';
import QueryParamsLayout from '../../../../../layouts/common/main/QueryParamsLayout';
import ExtendRequestForm from '../../../../../components/subscriber/Modals/ExtendRequestForm';
import ExtraRequestForm from '../../../../../components/subscriber/Modals/ExtraRequestForm';
import NewRequestForm from '../../../../../components/subscriber/Modals/NewRequestForm';
import WithdrawalRequestForm from '../../../../../components/subscriber/Modals/WithdrawalRequestForm';
import { WTd } from '../../../../../components/common/Table/Td';
import { WTh } from '../../../../../components/common/Table/Th';
import TextLink from '../../../../../components/common/Link/TextLink';
import { RequestType, Service, Using } from '../../../../../lib/common/utils/axios/types';
import {
  booleanToString,
  boolstrToBoolean,
  buildSearchParams,
  currentParamsObject,
  parseJsonToArr,
  ToAllFilledObj,
} from '../../../../../lib/common/utils/base';
import {
  parseCounts,
  useGetAllCompanyContractPayments,
  useGetAllCounts,
  useGetAllFormRequests,
  useGetAllUsings,
  useGetCompanyContract,
} from '../../../../../lib/subscriber/hooks/query';
import { tenanStatusTypeTrans } from '../../../../../lib/subscriber/utils/utils';
import { datedotformat } from '../../../../../lib/common/utils/dateformatter';
import { decimalize } from '../../../../../lib/common/utils/digitformatter';
import { LeftArrowIcon, RightArrowIcon } from '../../../../../statics/common/icons';
import UsageStatusModal from '../../../../../components/subscriber/Modals/UsageStatus';
import Loading from '../../../../../components/common/Loading';

export const request_type_transition = {
  new: '신규 승인 신청',
  changing: '추가 승인 요청',
  extending: '계약 연장 신청',
  withdrawal: '철회 신청',
} as {
  [key in RequestType]: string;
};

type tFilter = 'asc' | 'desc';
type UsageListsProps = PathProps;

export const Main = () => {
  const navigate = useNavigate();
  const [clickedFormUserId, setClickedFormUserId] = useState('');
  const [clickedFormServiceId, setClickedFormServiceId] = useState('');

  const {
    isOpen: isNewRequestFormOpen,
    onOpen: onNewRequestFormOpen,
    onClose: onNewRequestFormClose,
  } = useDisclosure();
  const {
    isOpen: isExtraRequestFormOpen,
    onOpen: onExtraRequestFormOpen,
    onClose: onExtraRequestFormClose,
  } = useDisclosure();
  const {
    isOpen: isExtendRequestFormOpen,
    onOpen: onExtendRequestFormOpen,
    onClose: onExtendRequestFormClose,
  } = useDisclosure();
  const {
    isOpen: isWithdrawalRequestFormOpen,
    onOpen: onWithdrawalRequestFormOpen,
    onClose: onWithdrawalRequestFormClose,
  } = useDisclosure();

  const {
    page,
    limit,
    search,
    employee_services_request_type,
    employee_services_check_status,
    request_type_tags,
    check_status_tags,
  } = currentParamsObject();

  const params = ToAllFilledObj({
    page,
    limit,
    search,
    sort: {
      'employee.service.request_type': employee_services_request_type,
      'employee.service.check_status': employee_services_check_status,
    },
    request_type_tags,
    check_status_tags,
  });

  const res = useGetAllFormRequests({
    axiosConfig: {
      params,
    },
  });

  const sortParams = (param_key: string, param_value: any) => {
    const searchParams = buildSearchParams({
      page: '1',
      pagelist: '1',
      [param_key]: booleanToString(param_value),
    });

    navigate(searchParams);
  };
  const { isLoading } = res;
  if (isLoading) return <Loading />;
  return (
    <GTitleItem
      upperSection={
        <Flex justifyContent={'space-between'} alignItems={'center'} cursor={'pointer'}>
          <Text fontSize={'18px'} fontWeight={'medium'}>
            {`전체 이용 신청 내역(${res.data?.result ? decimalize(res.data?.result?.length) : '00'})`}
          </Text>
        </Flex>
      }
      h="full"
      hasDivision={false}
    >
      <Flex flexDir={'column'} w={'full'} h="full" px={5} pb={5}>
        <QueryParamsLayout
          res={res}
          moreFilters={{
            requestTypeCheckboxes: 'left',
            checkStatusCheckboxes: 'left',
          }}
        >
          <Table variant="head_silverbg_toplining">
            <Thead bgColor={'#F8F8F8'} borderColor={'gray.100'}>
              <Tr>
                <WTh borderR>신청일</WTh>
                <WTh
                  borderR
                  tFilter={boolstrToBoolean(employee_services_request_type) ? 'asc' : 'desc'}
                  onClick={() => {
                    sortParams('employee_services_request_type', !boolstrToBoolean(employee_services_request_type));
                  }}
                >
                  신청 항목
                </WTh>
                <WTh borderR>이름</WTh>
                <WTh borderR>이용 기간</WTh>
                <WTh
                  borderR
                  tFilter={boolstrToBoolean(employee_services_check_status) ? 'asc' : 'desc'}
                  onClick={() => {
                    sortParams('employee_services_check_status', !boolstrToBoolean(employee_services_check_status));
                  }}
                >
                  상태 및 상세보기
                </WTh>
              </Tr>
            </Thead>
            <Tbody>
              {res.data?.result.map((service: Service, index) => {
                return (
                  <Tr h={'51px'} key={service.employee.service._id}>
                    <WTd borderR>{datedotformat(service.employee.service.createdAt)}</WTd>
                    <WTd borderR>{request_type_transition[service.employee.service.request_type]}</WTd>
                    <WTd borderR>{service.employee.user_name}</WTd>
                    <WTd borderR>
                      {service.employee.service.how_long_month ? `${service.employee.service.how_long_month}개월` : '-'}
                    </WTd>
                    <WTd borderR>
                      {service.employee.service.check_status === 'deprecated' ? (
                        <Text>새로운 신청으로 대체되었습니다.</Text>
                      ) : (
                        <>
                          <Button
                            variant={
                              service.employee.service.check_status === 'pending' ? 'grayBorder_blackFont' : 'text'
                            }
                            textDecorationLine={
                              service.employee.service.check_status === 'pending' ? 'unset' : 'underline'
                            }
                            textUnderlineOffset={'2px'}
                            h="34px"
                            onClick={() => {
                              setClickedFormUserId(service.employee._id);
                              setClickedFormServiceId(service.employee.service._id);
                              service.employee.service.request_type === 'new'
                                ? onNewRequestFormOpen()
                                : service.employee.service.request_type === 'changing'
                                ? onExtraRequestFormOpen()
                                : service.employee.service.request_type === 'extending'
                                ? onExtendRequestFormOpen()
                                : service.employee.service.request_type === 'withdrawal'
                                ? onWithdrawalRequestFormOpen()
                                : () => console.log('신청서 보기 오류');
                            }}
                          >
                            <Text>
                              {service.employee.service.check_status === 'pending'
                                ? '신청서 보기'
                                : service.employee.service.check_status === 'approved'
                                ? '승인'
                                : service.employee.service.check_status === 'cancelled'
                                ? '취소'
                                : service.employee.service.check_status === 'rejected'
                                ? '반려'
                                : service.employee.service.check_status === 'withdrawn'
                                ? '철회'
                                : ''}
                            </Text>
                          </Button>
                        </>
                      )}
                    </WTd>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </QueryParamsLayout>
      </Flex>
      {/*  */}
      {/* {service.employee.service.request_type === 'new' ? ( */}
      <NewRequestForm
        isOpen={isNewRequestFormOpen}
        onClose={onNewRequestFormClose}
        service_id={clickedFormServiceId}
        user_id={clickedFormUserId}
      />
      {/* ) : service.employee.service.request_type === 'changing' ? ( */}
      <ExtraRequestForm
        isOpen={isExtraRequestFormOpen}
        onClose={onExtraRequestFormClose}
        service_id={clickedFormServiceId}
        user_id={clickedFormUserId}
      />
      {/* ) : service.employee.service.request_type === 'extending' ? ( */}
      <ExtendRequestForm
        isOpen={isExtendRequestFormOpen}
        onClose={onExtendRequestFormClose}
        service_id={clickedFormServiceId}
        user_id={clickedFormUserId}
      />
      {/* ) : service.employee.service.request_type === 'withdrawal' ? ( */}
      <WithdrawalRequestForm
        isOpen={isWithdrawalRequestFormOpen}
        onClose={onWithdrawalRequestFormClose}
        service_id={clickedFormServiceId}
        user_id={clickedFormUserId}
      />
      {/* ) : (
        () => console.log('신청서 보기 오류')
      )} */}
      {/*  */}
    </GTitleItem>
  );
};

export const OnSeek = () => {
  const [status, setStatus] = useState<tFilter>('desc');

  const _params = currentParamsObject();
  const params = ToAllFilledObj(_params);

  const res = useGetAllUsings({
    axiosConfig: {
      params,
    },
  });

  const { data: countsdata } = useGetAllCounts();
  const { data: contractdata } = useGetCompanyContract();
  const { data: paymentsdata } = useGetAllCompanyContractPayments();

  console.log(countsdata === undefined, contractdata === undefined, paymentsdata === undefined);

  if (countsdata === undefined || contractdata === undefined || paymentsdata === undefined) {
    return null;
  }

  const { services_count, usings_count } = countsdata;

  const total_on_using_employees_count =
    parseCounts(usings_count, 'total') !== undefined ? parseCounts(usings_count, 'total').count : 0;

  const { subscription_type, available_user_count } = contractdata;

  const availableUserCount = isUndefined(available_user_count) ? 0 : available_user_count;

  const showPeriod = (using: Using) => {
    if (!using.employee.parsed_last_service) {
      return null;
    }
    if (['in_service', 'in_custom_room_request', 'contracting'].includes(using.employee.tenant_status)) {
      return using.employee.parsed_last_service.how_long_month + '개월';
    } else {
      if (using.employee.parsed_lease_contracts_length >= 1) {
        return (
          <VStack>
            {using.employee.parsed_lease_contracts.map((contract, index) => (
              <Text key={index}>
                {datedotformat(contract.contract_start_date)}~{datedotformat(contract.contract_end_date)}
              </Text>
            ))}
          </VStack>
        );
      } else {
        return null;
      }
    }
  };

  const { isLoading } = res;
  if (isLoading) return <Loading />;
  return (
    <GTitleItem
      upperSection={
        <Flex justifyContent={'space-between'} alignItems={'center'} cursor={'pointer'}>
          <Text fontSize={'18px'} fontWeight={'medium'}>
            이용 중인 임직원 전체보기
          </Text>
        </Flex>
      }
      h="calc(100vh - 128px)"
      minH="calc(100vh - 128px)"
    >
      <Box w="full" h={'full'}>
        <Flex flexDir={'column'} h={'full'} px={5} py={5}>
          {/* Text */}
          <Box mb={'75px'}>
            <Box fontSize={'14px'}>
              *이용 중인 임직원은&nbsp;
              <Text fontWeight={'medium'} display={'inline-block'}>
                최초 이용 승인이 완료된 임직원
              </Text>
              입니다.
            </Box>
            <Box fontSize={'14px'}>
              *최초 이용 승인 후 임직원의&nbsp;
              <Text fontWeight={'medium'} display={'inline-block'}>
                추가 이용 신청, 계약 연장 신청은 특이사항에서 확인하시고, 상세보기에서 승인처리 부탁드립니다.
              </Text>
            </Box>
          </Box>
          <Box mb={4}>
            <Text textAlign={'end'} fontSize={'14px'} fontWeight={500}>
              {`${total_on_using_employees_count}인 / ${availableUserCount}인 이용 중 (잔여: 
              ${availableUserCount - total_on_using_employees_count}인)`}
            </Text>
          </Box>
          {/* Table */}
          <Flex flexDir={'column'} w={'full'} flexGrow={1}>
            {/* <Scrollbars> */}
            <QueryParamsLayout res={res}>
              <Table variant="head_silverbg_toplining">
                <Thead>
                  <Tr>
                    <WTh
                      borderR
                      // tFilter={status}
                      // onClick={() => {
                      //   setStatus((prev) => (prev === 'asc' ? 'desc' : 'asc'));
                      // }}
                    >
                      이용 상태
                    </WTh>
                    <WTh borderR>임직원명</WTh>
                    <WTh borderR>입주 기간</WTh>
                    {/* <WTh borderR>특이 사항</WTh> */}
                    <WTh>상세보기</WTh>
                  </Tr>
                </Thead>
                <Tbody>
                  {res.data?.result?.map((using, index) => (
                    <Tr h={'51px'} key={index}>
                      <WTd borderR>{tenanStatusTypeTrans(using.employee.tenant_status)}</WTd>
                      <WTd borderR>{using.employee.user_name}</WTd>
                      <WTd borderR>{showPeriod(using)}</WTd>
                      {/* <WTd borderR>{'계약 연장 신청'}</WTd> */}
                      <WTd borderR>
                        <UsageStatusModal using={using} />
                      </WTd>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </QueryParamsLayout>
            {/* </Scrollbars> */}
            {/* 모달 */}
          </Flex>
        </Flex>
      </Box>
    </GTitleItem>
  );
};

/** 미사용중 */
const OnContract = () => {
  const [status, setStatus] = useState<tFilter>('desc');
  const [numPages, setNumPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  return (
    <GTitleItem
      upperSection={
        <Flex justifyContent={'space-between'} alignItems={'center'} cursor={'pointer'}>
          <Text fontSize={'18px'} fontWeight={'medium'}>
            계약 현황({`03`})
          </Text>
          <HStack w={'30%'}>
            <InputGroup>
              <InputLeftElement position={'absolute'} top="-2px" children={<FaSearch color={'#A0A0A0'} />} />
              <Input
                variant={'search'}
                fontSize={'14px'}
                fontWeight={'bold'}
                h={'36px'}
                placeholder={'이름으로 검색'}
              />
            </InputGroup>
            <Button variant={'basic'} w={'90px'}>
              조회
            </Button>
          </HStack>
        </Flex>
      }
      rowSpan={8}
      colSpan={3}
      hasDivision={false}
    >
      <Flex flexDir={'column'} w={'full'} px={5} flexGrow={1}>
        <Box borderTopWidth={'1px'} borderTopColor={'blackAlpha.500'} />
        <Scrollbars>
          <Table variant="simple_same">
            <Thead bgColor={'#F8F8F8'} borderColor={'gray.100'}>
              <Tr>
                <WTh
                  borderR
                  tFilter={status}
                  onClick={() => {
                    setStatus((prev) => (prev === 'asc' ? 'desc' : 'asc'));
                  }}
                >
                  상태
                </WTh>
                <WTh borderR>임직원명</WTh>
                <WTh borderR>계약 체결일</WTh>
                <WTh borderR>입주 예정일</WTh>
                <WTh>상세보기</WTh>
              </Tr>
            </Thead>
            <Tbody>
              <Tr h={'51px'}>
                <WTd borderR>{`집찾기 중`}</WTd>
                <WTd borderR>{`김소연`}</WTd>
                <WTd borderR>{`2022.02.01`}</WTd>
                <WTd borderR>{`2022.02.18`}</WTd>
                <WTd borderR>
                  <TextLink to="/test">상세보기</TextLink>
                </WTd>
              </Tr>
            </Tbody>
          </Table>
        </Scrollbars>
        {/* 모달 */}
      </Flex>
      <Center position={'sticky'} w="full">
        <Text p={'10px'} cursor={'pointer'} onClick={() => (pageNumber > 1 ? setPageNumber(pageNumber - 1) : null)}>
          <LeftArrowIcon w={6} h={6} />
        </Text>
        <Text>
          {/* {pageNumber} of {numPages} */}
          {pageNumber}
        </Text>
        <Text
          p={'10px'}
          cursor={'pointer'}
          onClick={() => (pageNumber < numPages ? setPageNumber(pageNumber + 1) : null)}
        >
          <RightArrowIcon w={6} h={6} />
        </Text>
      </Center>
    </GTitleItem>
  );
};

/** 미사용중 */
const OnMovein = () => {
  const [status, setStatus] = useState<tFilter>('desc');
  const [numPages, setNumPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  return (
    <GTitleItem
      upperSection={
        <Flex justifyContent={'space-between'} alignItems={'center'} cursor={'pointer'}>
          <Text fontSize={'18px'} fontWeight={'medium'}>
            계약 현황({`03`})
          </Text>
          <HStack w={'30%'}>
            <InputGroup>
              <InputLeftElement position={'absolute'} top="-2px" children={<FaSearch color={'#A0A0A0'} />} />
              <Input
                variant={'search'}
                fontSize={'14px'}
                fontWeight={'bold'}
                h={'36px'}
                placeholder={'이름으로 검색'}
              />
            </InputGroup>
            <Button variant={'basic'} w={'90px'}>
              조회
            </Button>
          </HStack>
        </Flex>
      }
      rowSpan={8}
      colSpan={3}
      hasDivision={false}
    >
      <Flex flexDir={'column'} w={'full'} px={5} flexGrow={1}>
        <Box borderTopWidth={'1px'} borderTopColor={'blackAlpha.500'} />
        <Scrollbars>
          <Table variant="simple_same">
            <Thead bgColor={'#F8F8F8'} borderColor={'gray.100'}>
              <Tr>
                <WTh
                  borderR
                  tFilter={status}
                  onClick={() => {
                    setStatus((prev) => (prev === 'asc' ? 'desc' : 'asc'));
                  }}
                >
                  상태
                </WTh>
                <WTh borderR>임직원명</WTh>
                <WTh borderR>계약 체결일</WTh>
                <WTh borderR>계약 기간</WTh>
                <WTh>상세보기</WTh>
              </Tr>
            </Thead>
            <Tbody>
              <Tr h={'51px'}>
                <WTd borderR>{`입주 중`}</WTd>
                <WTd borderR>{`김소연`}</WTd>
                <WTd borderR>{`2022.02.01`}</WTd>
                <WTd borderR>
                  {`2022.02.18`} ~ {`2023.02.17`} ({`12`}개월)
                </WTd>
                <WTd borderR>
                  <TextLink to="/test">상세보기</TextLink>
                </WTd>
              </Tr>
            </Tbody>
          </Table>
        </Scrollbars>
        {/* 모달 */}
      </Flex>
      <Center position={'sticky'} w="full">
        <Text p={'10px'} cursor={'pointer'} onClick={() => (pageNumber > 1 ? setPageNumber(pageNumber - 1) : null)}>
          <LeftArrowIcon w={6} h={6} />
        </Text>
        <Text>{pageNumber}</Text>
        <Text
          p={'10px'}
          cursor={'pointer'}
          onClick={() => (pageNumber < numPages ? setPageNumber(pageNumber + 1) : null)}
        >
          <RightArrowIcon w={6} h={6} />
        </Text>
      </Center>
    </GTitleItem>
  );
};

const UsageLists: React.FC<UsageListsProps> = ({ path }) => {
  const { main, onseek, oncontract, onmovein } = path;
  if (main.isCurrent) {
    return <Main />;
  } else if (onseek.isCurrent) {
    return <OnSeek />;
  } else if (oncontract.isCurrent) {
    return <OnContract />;
  } else if (onmovein.isCurrent) {
    return <OnMovein />;
  }
  return null;
};

export default UsageLists;

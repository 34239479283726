import { BoxProps } from '@chakra-ui/react';

export const baseStyle: StyleKeyObjectProps = {
  basic: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
  },
  plus: {
    // bgColor: 'zetaBlue',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    boxSize: '50px',
    pos: 'relative',
    transition: 'border .24s ease-in-out',
    _before: {
      content: `""`,
      pos: 'absolute',
      width: '6px',
      height: '80%',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      margin: 'auto',
      bgColor: 'zetaBlue',
      borderRadius: 'md',
    },
    _after: {
      content: `""`,
      pos: 'absolute',
      width: '80%',
      height: '6px',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      margin: 'auto',
      bgColor: 'zetaBlue',
      borderRadius: 'md',
    },
  } as BoxProps,
};

interface StyleKeyObjectProps {
  [key: string]: object;
}

export const focusedStyle: StyleKeyObjectProps = {
  basic: {
    borderColor: '#183377',
  },
  plus: {
    borderColor: '#183377',
  } as BoxProps,
};

export const acceptStyle: StyleKeyObjectProps = {
  basic: {
    borderColor: '#00e676',
  },
  plus: {
    borderColor: '#00e676',
  },
};

export const rejectStyle: StyleKeyObjectProps = {
  basic: {
    borderColor: '#ff1744',
  },
  plus: {
    borderColor: '#ff1744',
  },
};

import { Button, ButtonGroup } from '@chakra-ui/react';
import { FormikProps } from 'formik';

interface Props {
  isDisabledCompleteEdit: boolean;
  props: FormikProps<any>;
  isEditable: boolean;
  setIsEditable: (value: React.SetStateAction<boolean>) => void;
}

const InitFormikEditableButton: React.FC<Props> = ({ isDisabledCompleteEdit, props, isEditable, setIsEditable }) => {
  return isEditable ? (
    <ButtonGroup variant="outline">
      <Button
        variant={'gray'}
        _hover={{ bgColor: 'red', borderColor: 'red' }}
        onClick={() => {
          props.handleReset();
          setIsEditable(false);
        }}
        autoFocus
        w={'90px'}
        isDisabled={props.isSubmitting}
      >
        수정 취소
      </Button>
      <Button
        variant={'basic'}
        // form="form-a"
        type="submit"
        isLoading={props.isSubmitting}
        isDisabled={!props.isValid || isDisabledCompleteEdit}
        w={'90px'}
        onClick={() => {
          props.handleSubmit();
          console.log('수정 완료');
        }}
      >
        수정 완료
      </Button>
    </ButtonGroup>
  ) : (
    <Button variant={'warning'} w={'90px'} onClick={() => setIsEditable((prev) => !prev)}>
      수정
    </Button>
  );
};

export default InitFormikEditableButton;

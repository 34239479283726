import { AttachmentIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Center,
  Collapse,
  Flex,
  FlexboxProps,
  HStack,
  Image,
  Radio,
  RadioGroup,
  Text,
  Textarea,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { isEmptyArray } from '@chakra-ui/utils';
import { Field, Form, Formik } from 'formik';
import Scrollbars from 'react-custom-scrollbars-2';
import Dropzone from 'react-dropzone';
import { CustomInput } from '../../../../../../components/common/Input';
import QueryParamsLayout from '../../../../../../layouts/common/main/QueryParamsLayout';
import TextLink from '../../../../../../components/common/Link/TextLink';
import Thumb from '../../../../../../components/common/Thumb/base';
import Thumb_2 from '../../../../../../components/common/Thumb/Tumb_2';
import { useGetAllCompaniesTenantsComplaints } from '../../../../../../lib/admin/hooks/query';
import { pathOfUserDetailPage } from '../../../../../../lib/common/utils/utils';
import { currentParamsObject, ToAllFilledObj } from '../../../../../../lib/common/utils/base';
import { error } from '../../../../../../statics/common/data/toast';
import { datedetaildotformat_mm, datedetaildotkoformat } from '../../../../../../lib/common/utils/dateformatter';
import { phoneNumberdashfy } from '../../../../../../lib/common/utils/digitformatter';
import * as Yup from 'yup';
import ImageSliderModal from '../../../../../../components/common/Modals/ImageSliderModal';
import { useSearchParams } from 'react-router-dom';
import { useSendMessageOnComplaint } from '../../../../../../lib/admin/hooks/mutation';
import { useEffect, useRef, useState } from 'react';
import { FRadioButtonGroup, RadioButtonGroup } from '../../../../../../components/common/Radio';
import { complaint_type_radio } from '../../../../../../statics/common/data/radio';
import Loading from '../../../../../../components/common/Loading';

const Main = () => {
  // const { search: srch } = location;
  const [searchParams, setSearchParams] = useSearchParams();
  const chatBoxRef = useRef<HTMLDivElement>(null);

  const { mutateAsync: sendMessageOnComplaint } = useSendMessageOnComplaint();

  const _params = currentParamsObject();
  const params = ToAllFilledObj(_params);

  const res = useGetAllCompaniesTenantsComplaints({
    axiosConfig: {
      params,
    },
  });

  useEffect(() => {
    console.log(chatBoxRef.current);
    // chatBoxRef.current?.scrollToBottom();
    // chatBoxRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'end' });
    // chatBoxRef?.current?.scroll(0, 2100);
    // console.log(chatBoxRef?.current?.scroll());
  }, [searchParams]);

  const handleClick = () => {
    setTimeout(function () {
      console.log('clicked');
      chatBoxRef.current?.scrollIntoView(true);
    }, 200);
  };

  const [sent, setSent] = useState<any>(undefined);
  const { isLoading } = res;
  if (isLoading) return <Loading />;
  return (
    <QueryParamsLayout res={res}>
      <Box>
        <Accordion
          allowToggle
          variant={'bordered'}
          onChange={(expandedIndex) => {
            // searchParams.set('expandedIndex', expandedIndex as any);
            // setSearchParams(searchParams);
            console.log(expandedIndex);
            setSent(expandedIndex);
          }}
          index={sent}
        >
          {res.data?.result.map((item, index) => {
            const {
              employee: {
                user_name,
                _id: user_id,
                complaint: {
                  latest_message: { description, createdAt },
                  messages,
                  tenant_id,
                  _id: complaint_id,
                },
                tenant_status,
              },
              name,
            } = item;
            const base = pathOfUserDetailPage(tenant_status as any);
            return (
              <AccordionItem key={complaint_id} id={complaint_id}>
                <h2>
                  <AccordionButton fontSize={'14px'}>
                    <Box minW={'200px'} textAlign={'left'}>
                      <Text as="b" noOfLines={1}>
                        [{name}] {user_name}
                      </Text>
                    </Box>
                    <Box maxW={'calc(100vw - 750px)'} minW={'200px'} textAlign={'left'}>
                      <Text noOfLines={1}>{description}</Text>
                    </Box>
                    <Box flex={1} textAlign={'right'}>
                      <Text minW={'max'}>{datedetaildotformat_mm(createdAt)}</Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4} bgColor={'#8ba8b7'} p={0}>
                  <Box bgColor={'zetaBlue'} color={'white'} fontSize={'14px'}>
                    <Flex px={4} py={2} boxShadow={'0px 2px 2px 0px rgba(0, 0, 0, 0.4);'}>
                      <Box minW={'200px'} textAlign={'left'}>
                        <TextLink color={'white'} href={`${base}${tenant_id}`} target={'_blank'}>
                          유저 상세 정보
                        </TextLink>
                      </Box>
                      <Box maxW={'calc(100vw - 750px)'} minW={'200px'} textAlign={'left'}>
                        <TextLink color={'white'} href={`mailto:${item.employee.email}`} minW={'max'}>
                          {item.employee.email}
                        </TextLink>
                      </Box>
                      <Box flex={1} textAlign={'right'}>
                        <Text minW={'max'}>{phoneNumberdashfy(item.employee.phone_number_first)}</Text>
                      </Box>
                    </Flex>
                  </Box>
                  <Box minH={0} h={'420px'}>
                    <Scrollbars
                      style={{ height: '100%' }}
                      // universal
                      // onScrollFrame={(values) => {
                      //   console.log(values);
                      // }}
                    >
                      {/* <Box px={4} py={2}> */}
                      <Box px={4} py={2} ref={chatBoxRef}>
                        {messages.map((message, index) => {
                          const isTenant = tenant_id === message.user_id;
                          const alignSelf: FlexboxProps['alignSelf'] = !isTenant ? 'end' : 'start';
                          const bgColor = !isTenant ? '#183377' : '#E9E9EB';
                          const color = !isTenant ? 'white' : '#414141';

                          return (
                            <Box key={index}>
                              <Flex flexDir={'column'} w="full" pb={3}>
                                <Box wordBreak={'break-all'} alignSelf={alignSelf} maxW="70%">
                                  <Box px={3} py={1} bgColor={bgColor} borderRadius={'1rem'}>
                                    <Text whiteSpace={'pre-wrap'} fontSize={'15px'} fontWeight={400} color={color}>
                                      {`${message.description}`}
                                    </Text>
                                  </Box>
                                </Box>
                                <Box alignSelf={alignSelf} mt={1}>
                                  {!isEmptyArray(message.picture_urls) && (
                                    <HStack align={'end'}>
                                      {!isTenant && (
                                        <Box textStyle="normal12" color={'gray.200'}>
                                          첨부파일:
                                        </Box>
                                      )}
                                      {message.picture_urls.map((url, index) => {
                                        return (
                                          <ImageSliderModal
                                            key={index}
                                            titleAndPictures={{
                                              title: `${isTenant ? '받은' : '보낸'} 첨부파일`,
                                              pictures: message.picture_urls,
                                            }}
                                          >
                                            <Image
                                              boxSize={'100px'}
                                              borderRadius={'md'}
                                              objectFit="cover"
                                              src={url}
                                              alt={url}
                                            />
                                          </ImageSliderModal>
                                        );
                                      })}
                                      {isTenant && (
                                        <Box textStyle="normal12" color={'gray.200'}>
                                          :첨부파일
                                        </Box>
                                      )}
                                    </HStack>
                                  )}
                                </Box>
                                <Box alignSelf={alignSelf}>
                                  <Text textStyle="normal12" color={'white'}>
                                    {datedetaildotkoformat(message.createdAt)}
                                  </Text>
                                </Box>
                              </Flex>
                            </Box>
                          );
                        })}
                        {/* <Box ref={chatBoxRef} /> */}
                      </Box>
                    </Scrollbars>
                  </Box>
                  {/* <Button onClick={handleClick}>TEST</Button> */}
                  <Formik
                    initialValues={{
                      title: '',
                      description: '',
                      files: [],
                      complaint_type: '',
                    }}
                    validationSchema={Yup.object().shape({
                      title: Yup.string()
                        .required()
                        .min(1, ({ min }) => `최소 글자 수 이상을 기입해주세요.`),
                      // p_name
                      description: Yup.string()
                        .required()
                        .min(1, ({ min }) => `최소 글자 수 이상을 기입해주세요.`),
                      // complaint_type: Yup.string().required(),
                      // files: Yup.array().min(1, '하나 이상의 파일 첨부가 필요합니다.'),
                    })}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                      setSubmitting(true);
                      const formData = new FormData();
                      formData.append('title', values.title);
                      formData.append('description', values.description);
                      // formData.append('complaint_type', values.complaint_type);
                      values.files.map((file) => {
                        formData.append('pictures', file);
                      });
                      sendMessageOnComplaint({
                        formData,
                        user_id,
                        complaint_id,
                      })
                        .then(() => {
                          resetForm();
                          // setTimeout(() => {
                          //   window.location.reload();
                          // }, 2000);
                          setSent(-1);
                        })
                        .finally(() => {
                          setSubmitting(false);
                        });
                    }}
                    validateOnMount
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleSubmit,
                      isSubmitting,
                      setSubmitting,
                      getFieldProps,
                      isValid,
                      setFieldValue,
                    }) => {
                      console.log(values);
                      console.log(errors);

                      return (
                        <Form
                          style={{
                            height: '100%',
                            width: '100%',
                            display: 'flex',
                            // position: 'relative',
                            // paddingBottom: `${msg_spacing * 1.5}px`,
                          }}
                        >
                          <Box
                            px={4}
                            py={2}
                            bgColor={'#e0e8ec'}
                            w="full"
                            boxShadow={'2px 2px 2px 0px rgba(0, 0, 0, 0.4);'}
                          >
                            {/* 제목, 내용, 사진첨부, 민원종류, */}
                            <VStack w="full" align={'start'}>
                              <Flex flexDir={'column'} w="full">
                                <Box textStyle={'bold14'}>제목</Box>
                                <Field as={CustomInput} fontSize={'14px'} name={'title'} />
                              </Flex>
                              <Flex flexDir={'column'} w="full">
                                <Box textStyle={'bold14'}>내용</Box>
                                <Field
                                  as={Textarea}
                                  name={'description'}
                                  px={3}
                                  bgColor="lotionWhite"
                                  borderColor="silverGray"
                                  fontSize={'14px'}
                                />
                              </Flex>
                              <Flex flexDir={'column'} w="full">
                                <Box textStyle={'bold14'}>첨부파일</Box>
                                <Dropzone
                                  onDrop={(acceptedFiles) => {
                                    if (acceptedFiles.length === 0) {
                                      return;
                                      // } else if (acceptedFiles.length > 1) {
                                      //   toast({ ...error, title: '한 장의 파일만 업로드 가능합니다.', description: '' });
                                    } else {
                                      if (!isEmptyArray(values.files)) {
                                        setFieldValue('files', (values.files as any).concat(acceptedFiles));
                                      } else {
                                        setFieldValue('files', acceptedFiles);
                                      }
                                    }
                                  }}
                                >
                                  {({ isDragActive, isDragReject, acceptedFiles, getRootProps, getInputProps }) => {
                                    return (
                                      <Flex flexDir={'column'} w={'full'}>
                                        <Center {...getRootProps()} w={'full'}>
                                          <input {...getInputProps()} />
                                          <Center
                                            bgColor={'lotionWhite'}
                                            borderColor="silverGray"
                                            px={4}
                                            h={'80px'}
                                            borderStyle={'dashed'}
                                            w="full"
                                            borderRadius={'5px'}
                                            borderWidth={'1px'}
                                            fontWeight={'medium'}
                                            layerStyle={'btn_hover_zetaBlue_animate'}
                                          >
                                            <HStack spacing={1}>
                                              <Box>
                                                <AttachmentIcon />
                                              </Box>
                                              <Box minW={'max'}>첨부 파일 추가</Box>
                                            </HStack>
                                          </Center>
                                        </Center>
                                      </Flex>
                                    );
                                  }}
                                </Dropzone>
                                <Collapse style={{ width: '100%' }} in={values.files.length !== 0}>
                                  <HStack mt={6}>
                                    {values.files.map((picture, i) => (
                                      <Thumb key={i} file={picture} />
                                    ))}
                                  </HStack>
                                </Collapse>
                              </Flex>
                              {/* <Flex flexDir={'column'} w="full">
                              <Box textStyle={'bold14'}>민원종류</Box>
                              <FRadioButtonGroup name="complaint_type" options={complaint_type_radio} />
                            </Flex> */}
                              <Flex w="full" justify={'end'}>
                                <Button
                                  type={'submit'}
                                  variant={'basic'}
                                  w={'160px'}
                                  style={{ height: '40px' }}
                                  fontSize={'16px'}
                                  isDisabled={!isValid}
                                  isLoading={isSubmitting}
                                >
                                  전송하기
                                </Button>
                              </Flex>
                            </VStack>
                          </Box>
                        </Form>
                      );
                    }}
                  </Formik>
                </AccordionPanel>
              </AccordionItem>
            );
          })}
        </Accordion>
      </Box>
    </QueryParamsLayout>
  );
};

export default Main;

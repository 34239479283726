import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Image,
  Modal,
  ModalContent,
  ModalOverlay,
  Tag,
  Text,
  Textarea,
  toast,
  useBoolean,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { isEmptyArray, isEmptyObject, isUndefined } from '@chakra-ui/utils';
import { Form, Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import Scrollbars from 'react-custom-scrollbars-2';
import { WBox } from '../../Box';
import { RequestRoom } from '../../../../lib/common/utils/axios/types';
import { CheckCircleIcon } from '../../../../statics/common/icons';
import { useConfirmRecommendedRooms, useSuggestRooms } from '../../../../lib/admin/hooks/mutation';
import { useGetBuildingRoomTemplate } from '../../../../lib/admin/hooks/query';
import { useRef, useState } from 'react';
import { success, warning } from '../../../../statics/common/data/toast';
import { booleanToString, squaredToPeong } from '../../../../lib/common/utils/base';

interface RetrieveRoomInfoByRoomTypeModalProps {
  disabled?: boolean;
  building_id: string;
  room_type: string;
}

const RetrieveRoomInfoByRoomTypeModal: React.FC<RetrieveRoomInfoByRoomTypeModalProps> = ({
  children,
  disabled = false,
  building_id,
  room_type,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const { data: templateData, refetch } = useGetBuildingRoomTemplate(building_id, room_type, {
    options: {
      enabled: false,
    },
  });
  const { setFieldValue } = useFormikContext();
  const cancelRef = useRef();

  const handleModalOpen = () => {
    if (!disabled) {
      onOpen();
    }
  };

  const handleFinalize = () => {
    console.log('templateData =>', templateData);
    refetch()
      .then((result) => {
        if (isEmptyObject(result.data)) {
          toast({ ...warning, title: '불러올 정보가 없습니다', description: '' });
        } else {
          if (result.data) {
            const { room } = result.data;
            console.log('room => ', room);

            setFieldValue('deposit', room.deposit);
            setFieldValue('rent', room.rent);
            setFieldValue('management_fee', room.management_fee);
            setFieldValue('common_items', room.common_items);
            setFieldValue('individual_items', room.individual_items);
            setFieldValue('move_in.status', room.move_in.status);
            // 테스트 필요
            console.log('room.move_in.date =>', room.move_in.date);
            console.log('room.move_in.date2 =>', new Date(room.move_in.date));

            setFieldValue('move_in.date', new Date(room.move_in.date));
            // -
            setFieldValue('minimum_contract_month', room.minimum_contract_month);
            setFieldValue('floor_r', room.floor);
            setFieldValue('floor_type', room.floor_type);
            setFieldValue('room_living_type', room.room_living_type);
            setFieldValue('room_num', room.room_num);
            setFieldValue('toilet_num', room.toilet_num);
            setFieldValue('supply_area', room.supply_area);
            if (room.supply_area) {
              const supply_area_peong = squaredToPeong(room.supply_area);
              setFieldValue('supply_area_peong', supply_area_peong);
            }
            setFieldValue('dedicated_area', room.dedicated_area);
            if (room.dedicated_area) {
              const dedicated_area_peong = squaredToPeong(room.dedicated_area);
              setFieldValue('dedicated_area_peong', dedicated_area_peong);
            }
            setFieldValue('room_direction', room.room_direction);
            setFieldValue('heating_type', room.heating_type);
            setFieldValue('default_options', room.default_options);
            setFieldValue('additional_options', room.additional_options);
            setFieldValue('is_security_window', booleanToString(room.is_security_window));
            setFieldValue('is_parking_r', booleanToString(room.is_parking));
            setFieldValue('is_pet', booleanToString(room.is_pet));
            setFieldValue('share_facilities', room.share_facilities);
            setFieldValue('remodeling_season', room.remodeling_season);
            setFieldValue('remodeling_items', room.remodeling_items);

            toast({ ...success, title: '성공적으로 정보를 불러왔습니다', description: '' });
          }
        }
        console.log('refetch', result);
      })
      .then(() => {
        onClose();
      });
  };

  return (
    <>
      <Box onClick={handleModalOpen}>{children}</Box>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef.current}
        onClose={onClose}
        isCentered
        // onEsc={handleCloseAlert}
        // onOverlayClick={handleCloseAlert}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              룸타입 정보 불러오기
            </AlertDialogHeader>

            <AlertDialogBody>
              <Flex flexDir={'column'}>
                <Box>
                  기입된 정보가&nbsp;
                  <Text fontWeight={'bold'} display={'inline'}>
                    등록된 룸타입 {room_type} 정보
                  </Text>
                  로 대체됩니다.
                </Box>
                <Text>정보를 불러오시겠습니까?</Text>
              </Flex>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef.current} variant={'gray'} onClick={onClose}>
                취소
              </Button>
              <Button colorScheme="red" onClick={handleFinalize} variant={'basic'} ml={3}>
                확정하기
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default RetrieveRoomInfoByRoomTypeModal;

import { Box, Flex, HStack, Skeleton, Tag, Text, VStack } from '@chakra-ui/react';
import { addHours, addDays } from 'date-fns';
import { getDocs, where, query } from 'firebase/firestore';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { useCollection, useDocument } from 'react-firebase-hooks/firestore';
import { useNavigate } from 'react-router-dom';
import { useUserTokenVerify } from '../../../../../../lib/common/hooks/query';
import { datedetaildotformat_mm, datedotformat } from '../../../../../../lib/common/utils/dateformatter';
import { chatroomsQuery, messagesQuery } from '../../../../../../lib/common/utils/firebase/init';

interface ChatRoom {
  user_ids: Array<string>;
  user_names: Array<string>;
  latest_message: {
    createdAt: {
      seconds: number;
      nanoseconds: number;
    };
    text: string;
  };
  timestamp: {
    seconds: number;
    nanoseconds: number;
  };
}

const All = () => {
  const [value, loading] = useCollection(chatroomsQuery);
  const navigate = useNavigate();
  const { data: userData } = useUserTokenVerify();
  const [isComponentUpdate, setIsComponentUpdate] = useState<boolean>(false);

  return (
    <Box w="full" h="full">
      <Skeleton isLoaded={!loading} w="full" h="full">
        <VStack spacing={1}>
          {value?.docs?.map((doc, index) => {
            const data = doc.data();
            const id = doc.id;
            const latest_message_received_date = data?.latest_message.createdAt as string;
            const mainUser = data?.users?.filter((user: any) => user.user_type !== 'master')?.[0];
            const custom_room_request_id = data?.custom_room_request_id;

            if (!custom_room_request_id) {
              return null;
            }

            const requestType = (
              {
                custom_room_request: <Tag variant={'orange'}>매물문의</Tag>,
                common: <Tag variant={'green'}>일반문의</Tag>,
              } as any
            )[data?.chat_room_type];

            const userType = !isEmpty(mainUser) ? (
              (
                {
                  tenant: <Tag variant={'tenant_stamp'}>임</Tag>,
                  host: <Tag variant={'host_stamp'}>중</Tag>,
                } as any
              )[mainUser?.user_type]
            ) : (
              <Tag>X</Tag>
            );

            const userName = mainUser?.user_name;
            const userCompany = mainUser?.company_name ? `(${mainUser?.company_name})` : '';

            const test = async () =>
              await getDocs(messagesQuery(id, where('userArr', 'array-contains', userData?._id || '')));
            test().then(() => {
              return <div>hmm</div>;
            });

            return (
              <Box
                key={index}
                w="full"
                bgColor={'#FBFBFB'}
                border={'1px solid'}
                borderColor={'silverGray'}
                borderRadius={'md'}
                fontSize={'14px'}
                onClick={() => {
                  navigate(`/admin/chat/${id}?requestid=${custom_room_request_id}`);
                }}
                cursor={'pointer'}
              >
                <HStack px={2} py={1} spacing={4}>
                  {requestType}
                  {userType}
                  <Text textDecor={'underline'} textUnderlineOffset={'3px'} minW={'200px'} maxW="200px" isTruncated>
                    {userName}
                    {userCompany}
                  </Text>
                  {data?.latest_message.text && (
                    <Box layerStyle={'latest_message'}>
                      <Text isTruncated>{data?.latest_message.text}</Text>
                    </Box>
                  )}
                  <Text flexGrow={1} textAlign={'right'}>
                    {datedetaildotformat_mm(new Date(latest_message_received_date))}
                  </Text>
                </HStack>
              </Box>
            );
            // });
          })}
        </VStack>
      </Skeleton>
    </Box>
  );
};

export default All;

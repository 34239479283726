import { InfoOutlineIcon, SunIcon } from '@chakra-ui/icons';
import { Box, Center, Flex, HStack, Icon, SimpleGrid, Tag, Text, Tooltip, VStack, Link } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { addWeeks, format, isToday, subWeeks, startOfDay } from 'date-fns';
import { useState } from 'react';
import { Container, RequestActiveBox } from '.';
import { ClockIcon, LeftArrowIcon, RightArrowIcon } from '../../../../statics/common/icons';
import { takeWeek, weeks } from '../../../../lib/common/utils/calender';

const StatusSection = () => {
  const initialItemActivate = {
    id: 'reviewing',
  };
  const [itemActivate, setItemActivate] = useState(initialItemActivate);
  const handleItemClicked = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const currentTarget = e.currentTarget.id;
    setItemActivate((prev) => {
      return {
        ...prev,
        id: currentTarget,
      };
    });
  };
  const [currentDate, setCurrentDate] = useState(startOfDay(new Date()));
  const [selectedDate, setSelectedDate] = useState(startOfDay(new Date()));
  const day = weeks[selectedDate.getDay()];
  const weekArr = takeWeek(currentDate)();

  const nextWeek = () => {
    setCurrentDate(addWeeks(currentDate, 1));
  };

  const prevWeek = () => {
    setCurrentDate(subWeeks(currentDate, 1));
  };

  return (
    <HStack w={'100%'} spacing={{ base: 0, md: 4 }} flexDir={{ base: 'column', md: 'row' }} alignItems={'flex-start'}>
      <Container
        w={{ base: '100%', md: '55%' }}
        minW={{ md: '380px', lg: '480px' }}
        mb={{ base: 4, md: 0 }}
        boxShadow={'0px 0px 20px 0px #DDDDDD'}
      >
        <VStack spacing={3} alignItems={'unset'} width={'100%'}>
          {/* 나의 투어 일정 */}
          <Flex flexDir={'column'} mx={8}>
            <Box fontWeight={700} fontSize={'20px'}>
              나의 투어 일정
            </Box>
            <HStack flexWrap={'wrap'}>
              <Text fontWeight={700} fontSize={'24px'}>
                {format(selectedDate, 'M월 d일')}
              </Text>
              <Text fontWeight={700} fontSize={'24px'} color="silverGray">
                {day}요일
              </Text>
            </HStack>
          </Flex>
          {/* 투어 예약 */}
          <HStack>
            <Center onClick={prevWeek} cursor={'pointer'} h={'100%'}>
              <Icon color="zetaBlue" w={6} h={6}>
                <LeftArrowIcon />
              </Icon>
            </Center>
            <VStack alignItems={'unset'} spacing={2} flexGrow={1}>
              <HStack spacing={1}>
                {weekArr.map((day, index) => (
                  <VStack
                    key={index}
                    cursor={'pointer'}
                    transition={'0.18s linear'}
                    _hover={{ bgColor: 'mountainGreen', color: 'white' }}
                    flexGrow={1}
                    alignItems={'unset'}
                    justifyContent={'space-between'}
                    h={'100px'}
                    bgColor={JSON.stringify(day) === JSON.stringify(selectedDate) ? 'mountainGreen' : 'lotionWhite'}
                    border={'1px solid'}
                    borderColor={'silverGray'}
                    borderRadius={'md'}
                    boxShadow={'sm'}
                    color={
                      day.getDay() === 0
                        ? 'red'
                        : JSON.stringify(day) === JSON.stringify(selectedDate)
                        ? 'white'
                        : 'initial'
                    }
                    px={2}
                    py={'6px'}
                    onClick={() => {
                      setSelectedDate(day);
                    }}
                  >
                    {isToday(day) ? (
                      <Flex>
                        <Box fontSize={'21px'} fontWeight={'medium'}>
                          오늘
                        </Box>
                        <Box mt={1} ml={'1px'} w={1} h={1} bgColor={'primaryRed'} borderRadius={'full'} />
                      </Flex>
                    ) : (
                      /* if 투어 예약 데이터 존재하는 경우 빨간 점 */
                      <Flex>
                        <Box>{format(day, 'dd')}</Box>
                        <Box mt={1} ml={'1px'} w={1} h={1} bgColor={'primaryRed'} borderRadius={'full'} />
                      </Flex>
                    )}
                    <Flex position={'relative'}>
                      <Box fontSize={'22px'}>02건</Box>
                    </Flex>
                  </VStack>
                ))}
              </HStack>
              <HStack
                fontSize="18px"
                borderRadius={'md'}
                border={'0.5px solid'}
                bgColor={'lotionWhite'}
                borderColor={'silverGray'}
                padding={3}
                justifyContent={'space-between'}
              >
                <HStack spacing={2}>
                  <Tag variant={'tour_complete_11px'}>투어완료</Tag>
                  <Box mt={'1px'}>
                    <Icon as={ClockIcon} />
                  </Box>
                  <HStack>
                    <Box fontSize={'14px'}>{`오전 10:00`}-</Box>
                    <HStack spacing={0}>
                      <Box fontWeight={700}>{`이름`}</Box>
                      <Box color="midGray">님</Box>
                    </HStack>
                  </HStack>
                </HStack>
                <Link
                  as={RouterLink}
                  to="id"
                  fontSize={'16px'}
                  fontWeight={700}
                  color={'highGray'}
                  transition={'0.2s linear'}
                  _hover={{ color: 'zetaBlue' }}
                  textDecorationLine={'underline'}
                >
                  채팅 바로가기
                </Link>
              </HStack>
            </VStack>
            <Center onClick={nextWeek} cursor={'pointer'} h={'100%'}>
              <Icon color="zetaBlue" w={6} h={6}>
                <RightArrowIcon />
              </Icon>
            </Center>
          </HStack>
        </VStack>
      </Container>
      {/* 요청 상태 버튼들 */}
      <Flex w={{ base: '100%', md: '45%' }} minW={{ md: '380px', lg: '480px' }}>
        <SimpleGrid w={'100%'} columns={2} spacing={4}>
          <RequestActiveBox
            id="newRooms"
            w={'100%'}
            activate={itemActivate.id === 'newRooms'}
            onClick={(e) => {
              handleItemClicked(e);
              console.log('newRooms');
            }}
          >
            <HStack justifyContent={'space-between'}>
              <Box fontSize={'20px'} fontWeight={'bold'}>
                전달 대기중인 요청
              </Box>
              <Tooltip
                label="현재 보홈이 검토중으로 전달 대기중인 요청입니다."
                arrowShadowColor="#f0f0f0"
                placement="bottom"
                offset={[-40, 6]}
                hasArrow
                w={'200px'}
                py={'15px'}
                px={'5px'}
                textAlign={'center'}
              >
                <InfoOutlineIcon w={6} h={6} color={itemActivate.id === 'newRooms' ? 'white' : '#A0A0A0'} />
              </Tooltip>
            </HStack>
            <Box fontSize={'36px'} fontWeight={'bold'}>{`총 ${3}건`}</Box>
          </RequestActiveBox>
          <RequestActiveBox
            id="delivered"
            w={'100%'}
            activate={itemActivate.id === 'delivered'}
            onClick={handleItemClicked}
          >
            <HStack justifyContent={'space-between'}>
              <Box fontSize={'20px'} fontWeight={'bold'}>
                전달 완료된 요청
              </Box>
              <Tooltip
                label="검토가 완료되어 해당 이용자(임직원)에게 전달완료된 요청건입니다."
                arrowShadowColor="#f0f0f0"
                placement="bottom"
                offset={[-40, 6]}
                hasArrow
                w={'200px'}
                py={'15px'}
                px={'5px'}
                textAlign={'center'}
              >
                <InfoOutlineIcon w={6} h={6} color={itemActivate.id === 'delivered' ? 'white' : '#A0A0A0'} />
              </Tooltip>
            </HStack>
            <Box fontSize={'36px'} fontWeight={'bold'}>{`총 ${5}건`}</Box>
          </RequestActiveBox>
          <RequestActiveBox
            id="reviewing"
            w={'100%'}
            activate={itemActivate.id === 'reviewing'}
            onClick={handleItemClicked}
          >
            <HStack justifyContent={'space-between'}>
              <Box fontSize={'20px'} fontWeight={'bold'}>
                투어 예약된 요청
              </Box>
              <Tooltip
                // label="투어 예약이 확정된 요청입니다. 투어 예약건의 시간과 장소를 확인하세요!"
                label={
                  <VStack spacing={0}>
                    <Text w={'156px'}>투어 예약이 확정된 요청입니다.</Text>
                    <Text w={'156px'} textAlign={'start'}>
                      투어 예약건의 시간과 장소를 확인하세요!
                    </Text>
                  </VStack>
                }
                arrowShadowColor="#f0f0f0"
                placement="bottom"
                offset={[-40, 6]}
                hasArrow
                w={'200px'}
                py={'15px'}
                px={'5px'}
                textAlign={'center'}
              >
                <InfoOutlineIcon w={6} h={6} color={itemActivate.id === 'reviewing' ? 'white' : '#A0A0A0'} />
              </Tooltip>
            </HStack>
            <Box fontSize={'36px'} fontWeight={'bold'}>{`총 ${5}건`}</Box>
          </RequestActiveBox>
        </SimpleGrid>
      </Flex>
    </HStack>
  );
};

export default StatusSection;

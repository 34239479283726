import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import Fonts from './statics/common/fonts';
import theme from './theme/common/base';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 300 * 1000,
      staleTime: 300 * 1000,
      keepPreviousData: true,
      optimisticResults: false,
      refetchOnWindowFocus: false,
      retry: 10,
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <ChakraProvider theme={theme}>
          <Fonts />
          <ColorModeScript initialColorMode={theme.config.initialColorMode} />
          <App />
        </ChakraProvider>
      </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);

// ReactDOM.render(
//   <React.StrictMode>
//     <QueryClientProvider client={queryClient}>
//       <ChakraProvider theme={theme}>
//         <Fonts />
//         <SidebarHiddenStore>
//           <BrowserRouter>
//             <ColorModeScript initialColorMode={theme.config.initialColorMode} />
//             <App />
//           </BrowserRouter>
//         </SidebarHiddenStore>
//       </ChakraProvider>
//     </QueryClientProvider>
//   </React.StrictMode>,
//   document.getElementById('root'),
// );

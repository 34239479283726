import {
  Box,
  Button,
  Flex,
  HStack,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { ModalDisclosureProps } from '../../../common/Modals/base';
import * as Yup from 'yup';
import RoomRequestDetail from '../../../../layouts/common/RoomRequestDetail/BeforeSuggestion';
import { BasicSearch } from '../../../common/Search';
import Filters from './Filters';

const RoomRequestDetailsModal: React.FC<ModalDisclosureProps> = ({ isOpen, onClose }) => {
  return (
    <Modal size={'8xl'} isOpen={isOpen} onClose={onClose} scrollBehavior="inside" motionPreset="none" isCentered>
      <ModalOverlay />
      <ModalContent w={'full'} position="relative" m={0}>
        <HStack alignItems={'unset'} w="full" h={'full'} spacing={5}>
          {/* - 매물 요청 사항 */}
          <RoomRequestDetail />
          {/* - 제안할 매물 찾기 */}
          <Box
            id="divider_abs"
            position={'absolute'}
            h={'100%'}
            borderLeft="1px solid"
            opacity={0.6}
            borderColor={'borderGray'}
            left={'400px'}
          />
          <Box py={5} pr={5} w="full">
            <VStack w={'100%'} alignItems={'unset'} mb={5}>
              <Flex
                w={'100%'}
                flexDir={{ base: 'column', md: 'row' }}
                my={5}
                alignItems={'center'}
                justifyContent={'space-between'}
              >
                {/* 나의 건물 현황 */}
                <Box fontSize={'24px'} minW={'200px'}>
                  제안할 매물 찾기
                </Box>
                {/* 검색 조회 */}
                <BasicSearch placeholder="주소/건물명/매물번호" />
                <ModalCloseButton />
              </Flex>
              <Stack direction={{ base: 'column', md: 'row' }} w={'100%'} alignItems={'unset'}>
                {/* 필터 */}
                <HStack
                  alignItems={'unset'}
                  bg="lotionWhite"
                  border={'1px solid'}
                  borderColor={'silverGray'}
                  borderRadius={'lg'}
                  boxShadow={'sm'}
                  px={4}
                  py={2}
                  w={'100%'}
                  justifyContent={'space-between'}
                >
                  <Tooltip
                    label={
                      <VStack spacing={0} fontWeight={'normal'} px={2} py={1}>
                        <Box>이용자의 예산, 지역, 유형으로</Box>
                        <Box>자동 필터링된 매물을 찾을 수 있어요!</Box>
                      </VStack>
                    }
                    placement="bottom-start"
                    defaultIsOpen
                    hasArrow
                    // bgColor={'white'}
                    arrowShadowColor="#f1e9e9"
                    offset={[-52, 8]}
                  >
                    <Button variant={'basic'} w={'150px'}>
                      맞춤 필터로 찾기
                    </Button>
                  </Tooltip>
                  <VStack w={'100%'} alignItems={'unset'}>
                    <Formik
                      initialValues={{
                        test: 'aef',
                      }}
                      onSubmit={(values, { setSubmitting }) => {
                        console.log(values);
                        setSubmitting(false);
                      }}
                      validationSchema={Yup.object().shape({
                        p_email: Yup.string().email('유효하지 않는 이메일입니다.'),
                        // p_name
                        p_password: Yup.string()
                          .matches(/\w*[A-z]\w*/, '비밀번호는 영문을 포함하여야 합니다.')
                          .matches(/\d/, '비밀번호는 숫자를 포함하여야 합니다.')
                          .matches(/[!@#$%^&*()\-_"=+{}; :,<.>]/, '비밀번호는 특수문자를 포함하여야 합니다.')
                          .min(8, ({ min }) => `비밀번호는 최소 ${min}글자입니다.`)
                          .max(16, ({ max }) => `비밀번호는 최대 ${max}글자입니다.`),
                        p_confirm_password: Yup.string().when('p_password', {
                          is: (val: any) => (val && val.length > 0 ? true : false),
                          then: Yup.string().oneOf([Yup.ref('p_password')], '위의 비밀번호와 일치하지 않습니다.'),
                        }),
                        p_phone_number: Yup.string().matches(/^((?!-).)*$/, '- 없이 입력해 주세요.'),
                      })}
                    >
                      <Form style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
                        <Filters />
                      </Form>
                    </Formik>
                    {/* <Box>선택된 필터 리스트</Box> */}
                  </VStack>
                </HStack>
              </Stack>
            </VStack>
            {/* <RoomInfoContainer minifyMemo minifyActionBtns /> */}
          </Box>
        </HStack>
      </ModalContent>
    </Modal>
  );
};

export default RoomRequestDetailsModal;

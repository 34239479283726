import { Box, Flex, Table, Tbody, Td, Text, Th, Thead, Tr, VStack } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import { format } from 'date-fns';
import { UseQueryResult } from 'react-query';
import ContractFileStatusDropzone from '../../Dropzone/ContractFileStatusDropzone';
import ChangeCertificateIssueStatus from '../../../common/SelectBox/ChangeCertificateIssueStatus';
import ChangeContractPaymentStatus from '../../../common/SelectBox/ChangeContractStatus';
import ChangeSubscriptionContractStatus from '../../../common/SelectBox/ChanageSubscriptionContractGlobalStatus';
import ChangeSubscriptionPaymentGlobalStatus from '../../../common/SelectBox/ChanageSubscriptionPaymentGlobalStatus';
import ChangeSubscriptionPaymentStatus from '../../../common/SelectBox/ChangeSubscriptionPaymentStatus';
import ChangeSubscriptionRealPaymentDate from '../../../common/SelectBox/ChangeSubscriptionPaymentRealPaymentDate';
import TextLink from '../../../common/Link/TextLink';
import { ServiceCategoryTrans } from '../../../../lib/common/utils/utils';
import { CompanyContract, CompanyPayment, QueryResponse } from '../../../../lib/common/utils/axios/types';
import { paymentMethodTypeTrans, subscriptionTypeTrans } from '../../../../lib/subscriber/utils/utils';
import { datedotformat } from '../../../../lib/common/utils/dateformatter';
import { staticThreeCommafy } from '../../../../lib/common/utils/digitformatter';
import { request_type_transition } from '../../../../pages/UserType/subscriber/manage/employee/UsageLists';
import ConfirmApproveCompanyWithTableModal from '../../../common/Modals/ConfirmApproveCompanyWithTableModal';
import { DeleteCircle } from '../../../../statics/common/icons';
import ReCheckAlertModal from '../../../common/Modals/Alert/ReCheckAlert';
import { useDeleteSubscriptionContractField } from '../../../../lib/admin/hooks/mutation';

interface SubscriptionContractsTableProps<Type> {
  res: UseQueryResult<QueryResponse<Type>, unknown>;
}

const SubscriptionContractsTable: React.FC<SubscriptionContractsTableProps<CompanyContract[]>> = ({ res }) => {
  console.log('res =>', res);
  const { mutate: deleteSubscriptionContractField } = useDeleteSubscriptionContractField();
  return (
    <Table variant="head_silverbg_toplining">
      <Thead>
        <Tr>
          <Th>구독 신청일</Th>
          <Th>구분</Th>
          <Th>회사명</Th>
          <Th>서비스명</Th>
          <Th>신청 플랜</Th>
          <Th>옵션</Th>
          <Th>신청서 보기</Th>
          <Th>처리 상태</Th>
          <Th>상태</Th>
          <Th>계약서 관리</Th>
        </Tr>
      </Thead>

      <Tbody>
        {res?.data?.result.map((contract, index) => {
          const {
            name: cp_name,
            subscription_contract: {
              contract_created_date,
              request_type,
              subscription_type,
              company_id,
              category,
              available_user_count,
              status,
              payment_status,
              subscription_contract_pdf_url,
              _id: subscription_contract_id,
            },
          } = contract;

          const isDeprecated = status === 'deprecated';

          return (
            <Tr key={index}>
              <Td>{datedotformat(contract_created_date)}</Td>
              <Td>{request_type_transition[request_type]}</Td>
              <Td textStyle={'pointer'}>
                <Link to={`/admin/manage/customers/company/detail/info?id=${company_id}`}>
                  <Text>{cp_name}</Text>
                </Link>
              </Td>
              <Td>{ServiceCategoryTrans(category)}</Td>
              <Td>{subscriptionTypeTrans(subscription_type)}</Td>
              <Td>{available_user_count}인</Td>
              <Td textStyle={'pointer'}>
                {/* <Link to={`detail/info?id=${'test'}`} > */}
                {/* <Link to={`/admin/manage/customers/company/detail/info?id=${company_id}`}> */}
                <ConfirmApproveCompanyWithTableModal company_manager_data={contract}>
                  <Text>신청서 보기</Text>
                </ConfirmApproveCompanyWithTableModal>
                {/* </Link> */}
              </Td>
              <Td>
                <ChangeContractPaymentStatus
                  payment_status={payment_status}
                  subscription_contract_id={subscription_contract_id}
                />
              </Td>
              <Td>
                <ChangeSubscriptionContractStatus
                  global_status={status}
                  subscription_contract_id={subscription_contract_id}
                />
              </Td>
              <Td>
                {subscription_contract_pdf_url ? (
                  <Flex justifyContent={'center'}>
                    <Box pos={'relative'}>
                      <TextLink href={subscription_contract_pdf_url} target={'_blank'}>
                        계약서
                      </TextLink>
                      <Box layerStyle={'abs_tr'} zIndex={0}>
                        <ReCheckAlertModal
                          content={'계약서를 삭제하시겠습니까?'}
                          callback={() => {
                            deleteSubscriptionContractField({
                              subscription_contract_id,
                              field: 'subscription_contract_pdf_url',
                            });
                          }}
                        >
                          <DeleteCircle boxSize={4} layerStyle={'pointer'} />
                        </ReCheckAlertModal>
                      </Box>
                    </Box>
                  </Flex>
                ) : (
                  <ContractFileStatusDropzone
                    subscription_contract_id={subscription_contract_id}
                    contractType="subscription"
                    formDataKey="subscription_contract_pdf"
                    acceptOneFile
                  />
                )}
              </Td>
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
};

export default SubscriptionContractsTable;

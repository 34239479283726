import { isUndefined } from '@chakra-ui/utils';
import { format, isToday, isYesterday } from 'date-fns';
import {
  buildingFormsEnum,
  buildingTypesEnum,
  FloorsEnum,
  NotificationType,
  OneCompanyType,
  PaymentMethodType,
  RequestType,
  SubscriptionTypeEnum,
  TenantStatus,
} from '../../common/utils/axios/types';

export const subscription_type_trans = {
  basic: '베이직',
  advanced: '어드밴스드',
  enterprise: '엔터프라이즈',
};

export const subscriptionTypeTrans = (subscription_type: SubscriptionTypeEnum) => {
  return isUndefined(subscription_type) ? '' : subscription_type_trans[subscription_type];
};

export const paymentPeriodTrans = (is_year: boolean) => {
  return is_year ? '연 결제' : '월 결제';
};

const payment_method_type_trans = {
  virtual_account: '가상계좌',
  cms: 'CMS 자동이체',
  samsung_card: '삼성카드',
  account_transfer: '계좌이체',
  admin_privilege: '관리자 승인',
};

export const paymentMethodTypeTrans = (method_type: PaymentMethodType) => {
  return payment_method_type_trans[method_type];
};

const company_type_trans = {
  corporation: '법인',
  sole_proprietor: '개인',
};

export const CompanyTypeTrans = (company_type: OneCompanyType) => {
  return company_type_trans[company_type];
};

const request_type_trans = {
  new: '신규',
  extending: '연장',
  changing: '변경',
  withdrawal: '철회',
};

export const requesTypeTrans = (request_type: RequestType) => {
  return request_type_trans[request_type];
};

const subscription_color = {
  basic: 'basicPlan',
  advanced: 'advancedPlan',
  enterprise: 'enterprisePlan',
};

export const subscriptionColorTrans = (subscription_type: SubscriptionTypeEnum) => {
  return subscription_color[subscription_type];
};

const tenant_status_type_trans = {
  using: '이용중',
  in_service: '승인 완료',
  in_custom_room_request: '집 찾기 중',
  pending_in_custom_room_request: '집 찾기 중(미승인)',
  contracting: '계약 중',
  contracted: '계약 완료',
  moving_in: '입주 중',
  moving_out: '퇴실',
  extending: '연장',
  none: '-',
};

const tenant_status_variant_trans = {
  using: 'green',
  in_service: 'green',
  in_custom_room_request: 'green',
  pending_in_custom_room_request: 'green',
  contracting: 'green',
  contracted: 'greenBorder',
  moving_in: 'green',
  moving_out: 'green',
  extending: 'green',
  none: 'green',
};

export const tenanStatusTypeTrans = (tenant_status: TenantStatus) => {
  if (!tenant_status) {
    return '';
  }
  return tenant_status_type_trans[tenant_status];
};

export const tenanStatusVariantTrans = (tenant_status: TenantStatus) => {
  if (!tenant_status) {
    return '';
  }
  return tenant_status_variant_trans[tenant_status];
};

export const building_form_type_trans = {
  villa: '빌라',
  officetel: '오피스텔',
  row_house: '연립',
  house: '단독',
  multi_family_house_s: '다세대',
  multi_family_house_g: '다가구',
  country_house: '전원주택',
  commercial_house: '상가주택',
  hanok_house: '한옥주택',
  share_house: '쉐어하우스',
  coliving_house: '프리미엄오피스텔',
  apartment: '아파트',
};

export const buildingFormTypeTrans = (building_form: buildingFormsEnum | '') => {
  if (!building_form) {
    return '';
  }
  return building_form_type_trans[building_form];
};

export const building_type_trans = {
  detached_house: '단독주택',
  apartment_house: '공동주택',
  neighborhood_living_1: '제1종 근린생활시설',
  neighborhood_living_2: '제2종 근린생활시설',
  cultural_gathering: '문화 및 집회시설',
  religion: '종교시설',
  sale: '판매시설',
  transportation: '운수시설',
  medical: '의료시설',
  education_research: '교육연구시설',
  elderly_children: '노유자(幼者: 노인 및 어린이)시설',
  training: '수련시설',
  exercise: '운동시설',
  business: '업무시설',
  accommodation: '숙박시설',
  amusement: '위락(慰)시설',
  factory: '공장',
  ware_house: '창고시설',
  danger: '위험물 저장 및 처리 시설',
  car: '자동차 관련 시설',
  animal_plant: '동물 및 식물 관련 시설',
  resource_circulation: '자원순환 관련 시설',
  military: '교정(矯正) 및 군사 시설',
  broadcast: '방송통신시설',
  power_plant: '발전시설',
  cemetery: '묘지 관련 시설',
  tourist: '관광 휴게시설',
  funeral: '장례시설',
  campsite: '야영장 시설',
  unregistered: '미등기건물',
  others: '그 밖에 토지의 정착물',
};

export const buildingTypeTrans = (building_type: buildingTypesEnum) => {
  if (!building_type) {
    return '';
  }
  return building_type_trans[building_type];
};

export const floor_type_trans = {
  ground: '지상',
  rooftop: '옥탑',
  underground: '반지하',
};

export const floorTypeTrans = (floor_type: FloorsEnum) => {
  return floor_type_trans[floor_type];
};

export const checkArrAndGetFirst = (dataArr: Array<any>) => {
  if (dataArr.length >= 1) {
    return dataArr[0];
  } else {
    return {};
  }
};

export const NotiIconMatch = (notification_type: NotificationType) => {
  switch (notification_type) {
    case 'room_recommendation':
      return '📝';
    case 'user_approval_request':
      return '🆕';
    case 'custom_room_request_creation':
      return '🆕';
    case 'user_confirmation':
      return '🔔';
    case 'subscription_contract_request':
      return '🔔';
    case 'subscription_payment_request':
      return '💰';
    case 'service_request':
      return '💌';
    case 'lease_contract_completion':
      return '🏠';
    case 'proxy_payment_request':
      return '🚨';
    case 'subscription_payment_overdue_request':
      return '🚨';
    default:
      return '🔔';
  }
};

import {
  HStack,
  Menu,
  Tooltip,
  Flex,
  Box,
  MenuButton,
  Avatar,
  Stack,
  MenuList,
  MenuItem,
  Text,
} from '@chakra-ui/react';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { AlertStore } from '../../../contexts/common/AlertContext';
import { useGet_WP_Merchant } from '../../../lib/admin/hooks/query';
import { getCurrent_WP_Amt_FromList } from '../../../lib/common/utils/utils';
import { useGetUserType } from '../../../lib/common/hooks/query';
import { datedotkoformat, datedetaildotkoformat } from '../../../lib/common/utils/dateformatter';
import { staticThreeCommafy } from '../../../lib/common/utils/digitformatter';
import { auth, useLogout } from '../../../lib/common/utils/firebase/init';
import { getLocalStorageStringItem } from '../../../lib/common/utils/localstorage';
import { IconCircleContiner, SettingFocusIcon, SettingIcon } from '../../../statics/common/icons';
import OpenAlertButton from '../../../components/common/Button/OpenAlertButton';

const AdminMenu = () => {
  const user = auth.currentUser;
  const email = getLocalStorageStringItem('--bohome-email');
  const navigate = useNavigate();
  const _logout = useLogout();
  const { pathname } = useLocation();

  const isSettingPage = matchPath('admin/manage/setting/*', pathname) ? true : false;

  const { data, isLoading } = useGetUserType();
  if (data === undefined || isLoading) {
    console.log('loading...');
    return null;
  }

  const { company_type: userType } = data;

  const userTransType = {
    admin: '보홈관리자',
    agency: '중개사',
    subscriber: '고객사',
  }[userType];
  return (
    <HStack
      spacing={14}
      bgColor={{ base: 'unset', md: 'white' }}
      padding={{ base: 'unset', md: '8px 13px' }}
      borderRadius={{ base: 'unset', md: '10px' }}
      boxShadow={{ base: 'unset', md: 'rgba(100, 100, 111, 0.05) 0px 7px 29px 0px' }}
      // mb={7}
      // position={'absolute'}
      // right={0}
    >
      <Menu autoSelect={false}>
        <Tooltip
          label={
            <Flex flexDir={'column'}>
              <Flex>
                <Text>계정 생성일:&nbsp;</Text>
                <Text>{datedotkoformat(user?.metadata.creationTime)}</Text>
              </Flex>
              <Flex>
                <Text>최근 접속시각:&nbsp;</Text>
                <Text>{datedetaildotkoformat(user?.metadata.lastSignInTime)}</Text>
              </Flex>
              <Box></Box>
            </Flex>
          }
          hasArrow
        >
          <Box>
            <MenuButton transition={'0.2s'} _hover={{ borderBottom: { base: '0.5px solid #BAC2D6', md: 'none' } }}>
              <HStack>
                <Avatar size={'sm'} name={email || ''} bgColor={'gray.300'} />
                <Stack direction={{ base: 'column', md: 'row' }} spacing={{ base: 0, md: 2 }}>
                  <Text fontSize={'14px'} boxSize={'max-content'}>
                    {email}
                  </Text>
                  <Text fontSize={'14px'} boxSize={'max-content'} textColor={'midGray'}>
                    {userTransType}
                  </Text>
                </Stack>
              </HStack>
            </MenuButton>
          </Box>
        </Tooltip>
        <MenuList zIndex={100} bgColor={'zetaBlue'} color={'white'}>
          <MenuItem _focus={{ opacity: '0.7' }} onClick={_logout}>
            로그아웃
          </MenuItem>
        </MenuList>
      </Menu>
      <HStack display={{ base: 'none', md: 'flex' }} ml={{ base: 'unset', md: 2 }}>
        {isSettingPage ? (
          <IconCircleContiner
            onClick={() => {
              navigate('/admin/manage/setting');
            }}
            bgColor={'zetaBlue'}
          >
            <SettingFocusIcon />
          </IconCircleContiner>
        ) : (
          <IconCircleContiner
            onClick={() => {
              navigate('/admin/manage/setting');
            }}
          >
            <SettingIcon />
          </IconCircleContiner>
        )}
        <AlertStore>
          <OpenAlertButton />
        </AlertStore>
      </HStack>
    </HStack>
  );
};

export default AdminMenu;

import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Image,
  Table,
  Tbody,
  Text,
  Tr,
  useDisclosure,
  VStack,
  Wrap,
} from '@chakra-ui/react';
import { Field, FieldArray, Form, Formik } from 'formik';
import _, { isEqual, isUndefined } from 'lodash';
import { useMemo, useState } from 'react';
import * as Yup from 'yup';
import FormikEditableWithReCheckButton from '../../../common/Button/FormikEditableWithReCheckButton';
import FormikFileUploadButton from '../../../common/Button/FormikFileUploadButton';
import InitFormikEditableButton from '../../../common/Button/InitFormikEditableButton';
import FormikThumb from '../../../common/Thumb/FormikThumb';
import { CustomInput } from '../../../common/Input';
import QueryParamsModalLayout from '../../../../layouts/common/main/QueryParamsModalLayout';
import AddressFinder from '../../../common/Modals/AddressFinder';
import TextLink from '../../../common/Link/TextLink';
import { TdFullWContainer } from '../../../common/Table/Tr';
import { useUpdateTenantLeaseContract } from '../../../../lib/admin/hooks/mutation';
import { useGetTenantLeaseContract } from '../../../../lib/admin/hooks/query';
import { bankNameTrans, currentParamsObject } from '../../../../lib/common/utils/base';
import { datedotformat, dateformatter } from '../../../../lib/common/utils/dateformatter';
import { staticThreeCommafy } from '../../../../lib/common/utils/digitformatter';
import { DeleteCircle } from '../../../../statics/common/icons';
import SelectBox from '../../../agent/SelectBox/base';
import SupportPaymentApproveModal from '../../../common/Modals/SupportPaymentApproveModal';
import SupportPaymentRejectModal from '../../../common/Modals/SupportPaymentRejectModal';
import { bank_data } from '../../../../statics/common/data/bank';
import CreateUserVirtualAccountModal from '../../../common/Modals/CreateUserVirtualAccountModal';
import { globalPaymentStatusTrans, globalStatusTrans } from '../../../../lib/common/utils/utils';
import { GlobalStatus, LeasePaymentStatusEnum } from '../../../../lib/common/utils/axios/types';
import CreateUserVirtualAccountAndLeasePaymentModal from '../../../common/Modals/CreateUserVirtualAccountAndLeasePaymentModal';
import { isEmptyObject } from '@chakra-ui/utils';
import Loading from '../../../common/Loading';

interface Props {
  queryKeys: Array<string>;
}

const SupportPaymentUserInfoModal: React.FC<Props> = ({ queryKeys }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const queryValues = useMemo(() => {
    return queryKeys.map((queryKey) => {
      const { [queryKey]: queryValue } = currentParamsObject(false);
      return queryValue;
    });
  }, [queryKeys]);

  const [isEditable, setIsEditable] = useState<boolean>(false);

  const lease_contract_id = queryValues[0];

  const res = useGetTenantLeaseContract(lease_contract_id, {
    options: {
      enabled: _.every(queryValues),
      keepPreviousData: false,
    },
  });

  const { mutateAsync } = useUpdateTenantLeaseContract();

  const { data, isFetching, isLoading } = res;

  if (!data) {
    return null;
  }

  const lease_contract_status = data.employee.lease_contract.status as 'approved' | 'rejected';
  if (isLoading) return <Loading />;
  return (
    <QueryParamsModalLayout
      queryKeys={queryKeys}
      modalProps={{
        onOverlayClick: () => {
          setIsEditable(false);
        },
      }}
    >
      <Formik
        initialValues={{
          // 회원 정보
          // 집 정보
          address: data.employee?.lease_contract?.room?.building?.address,
          detail_address: data.employee?.lease_contract?.room?.detail_address,
          // contract_start_date: data.employee?.lease_contract?.contract_start_date,
          // contract_end_date: data.employee?.lease_contract?.contract_end_date,
          contract_start_date: dateformatter(new Date(data.employee?.lease_contract?.contract_start_date), '-'),
          contract_end_date: dateformatter(new Date(data.employee?.lease_contract?.contract_end_date), '-'),
          deposit: data.employee?.lease_contract?.deposit,
          rent: data.employee?.lease_contract?.rent,
          management_fee: data.employee?.lease_contract?.management_fee,
          // 월세 납부 정보
          payment_day: data.employee?.lease_contract?.payment_day,
          host_name: data.employee?.lease_contract?.room?.host?.user_name,
          host_phone_number_first: data.employee?.lease_contract?.room?.host?.phone_number_first,
          bank_code: data.employee?.lease_contract?.account?.bank_code,
          account_number: data.employee?.lease_contract?.account?.account_number,
          lease_contract_pdf_urls: data.employee?.lease_contract?.lease_contract_pdfs, //기존에 있던 string url
          lease_contract_pdfs: [], //lease_contract_pdfs (File type array)
          status: data.employee.lease_contract?.status,
          // r_user_name,
          // r_department,
          // r_position,
          // r_email,
          // r_phone_number_first,
          // name,
          // company_code,
          // business_type,
          // business_registration_number,
          // business_status,
          // business_item,
          // capital,
          // employee_number,
          // establishment_date,
          // address,
          // detail_address,
          // digital_tax_invoice_email,
          // business_certificate,
          // logo_url,
          // managers,
        }}
        validationSchema={Yup.object().shape({
          // r_user_name: Yup.string().required('대표 담당자명을 입력해 주세요.'),
          // r_department: Yup.string().required('부서 입력해 주세요.'),
          // r_position: Yup.string().required('직책을 입력해 주세요.'),
          // r_phone_number_first: Yup.string()
          //   .matches(/^((?!-).)*$/, '- 없이 입력해 주세요.')
          //   .min(7, ({ min }) => `연락처는 최소 ${min}글자입니다.`)
          //   .max(16, ({ max }) => `연락처는 최대 ${max}글자입니다.`)
          //   .required('연락처를 입력해 주세요.'),
          // r_email: Yup.string().email('이메일 형식으로 입력해 주세요.').required('이메일을 입력해 주세요.'),
        })}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          const formData = new FormData();
          formData.append('address', values.address);
          formData.append('detail_address', values.detail_address || '');
          formData.append('contract_start_date', values.contract_start_date);
          formData.append('contract_end_date', values.contract_end_date);
          formData.append('deposit', values.deposit || ('' as any));
          formData.append('rent', values.rent || ('' as any));
          formData.append('management_fee', values.management_fee || ('' as any));
          formData.append('payment_day', values.payment_day || ('' as any));
          formData.append('host_name', values.host_name);
          formData.append('host_phone_number_first', values.host_phone_number_first);
          formData.append('bank_code', values.bank_code || ('' as any));
          formData.append('account_number', values.account_number);
          values.lease_contract_pdf_urls.map((item) => {
            formData.append('lease_contract_pdf_urls[]', item);
          });
          // formData.append('lease_contract_pdf_urls', values.lease_contract_pdf_urls );
          // formData.append('lease_contract_pdfs', values.lease_contract_pdfs);
          values.lease_contract_pdfs.map((item) => {
            formData.append('lease_contract_pdfs', item);
          });
          formData.append('status', values.status);
          await mutateAsync({
            formData,
            lease_contract_id,
          }).then(() => {
            // setIsEditable(false);
            window.location.reload();
          });
        }}
      >
        {(props) => {
          const isNotAnyChange = isEqual(props.initialValues, props.values);

          const { values } = props;

          const eviprops = {
            isEditable,
            props,
          };

          return (
            <Form id="form-a" style={{ width: '100%', height: '100%' }}>
              <Flex flexDir={'column'} w={'full'} h="full" alignItems={'flex-start'}>
                <Box
                  w={'full'}
                  borderBottomColor={'borderGray'}
                  boxShadow={'rgba(0, 0, 0, 0.02) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 2px 3px -2px'}
                  px={7}
                  py={4}
                >
                  <Flex justifyContent={'space-between'}>
                    <HStack h={'34px'} textStyle={'medium18'}>
                      <Text>{data.employee.user_name}의 상세 정보</Text>
                      <Text></Text>
                    </HStack>
                    {/* 승인/반려 버튼 */}
                    {!isEditable && (
                      <HStack>
                        {lease_contract_status === 'approved' ? (
                          <SupportPaymentRejectModal lease_contract_id={lease_contract_id}>
                            <Button variant={'reject_bordering'} h={'34px'}>
                              반려로 변경
                            </Button>
                          </SupportPaymentRejectModal>
                        ) : lease_contract_status === 'rejected' ? (
                          <SupportPaymentApproveModal
                            lease_contract_id={lease_contract_id}
                            user_name={data.employee.user_name}
                          >
                            <Button variant={'approve_bordering'} h={'34px'}>
                              승인으로 변경
                            </Button>
                          </SupportPaymentApproveModal>
                        ) : (
                          <>
                            <SupportPaymentRejectModal lease_contract_id={lease_contract_id}>
                              <Button variant={'reject'} w={'90px'} h={'34px'} borderRadius={'md'}>
                                반려
                              </Button>
                            </SupportPaymentRejectModal>
                            <SupportPaymentApproveModal
                              lease_contract_id={lease_contract_id}
                              user_name={data.employee.user_name}
                            >
                              <Button variant={'approve'} w={'90px'} h={'34px'}>
                                승인
                              </Button>
                            </SupportPaymentApproveModal>
                          </>
                        )}
                      </HStack>
                    )}
                  </Flex>
                </Box>
                {/* 1. 신청 내용 */}
                <Box w={'full'} px={6} pt={4} pb={6}>
                  <Flex w={'100%'}>
                    <VStack justifyContent={'start'} w={'100%'} spacing={6}>
                      {/* 회원 정보 */}
                      <VStack spacing={3} justifyContent={'start'} align={'flex-start'} w={'100%'}>
                        <HStack w={'full'} spacing={0} justify={'space-between'}>
                          <Box fontWeight={'bold'} fontSize={'18px'} textAlign={'start'} w={'112px'}>
                            회원 정보
                          </Box>
                        </HStack>
                        <Table w={'100%'} variant="simple">
                          <Tbody w={'100%'}>
                            <Tr w={'100%'}>
                              <TdFullWContainer title={'회사명 / 부서'}>
                                {/* {isEditable ? (
                                  <>
                                    <HStack>
                                      <Field name="first" as={CustomInput} />
                                      <Box>/</Box>
                                      <Field name="first" as={CustomInput} />
                                    </HStack>
                                  </>
                                ) : (
                                  <HStack>
                                    <Text>{data.name}</Text>
                                    {data.employee?.department ? (
                                      <Text>{data.employee?.department}</Text>
                                    ) : (
                                      <HStack>
                                        <Text>/</Text>
                                        <Text color={'gray'}>부서 없음</Text>
                                      </HStack>
                                    )}
                                  </HStack>
                                )} */}
                                <HStack>
                                  <Text>{data.name}</Text>
                                  {data.employee?.department ? (
                                    <Text>{data.employee?.department}</Text>
                                  ) : (
                                    <HStack>
                                      <Text>/</Text>
                                      <Text color={'gray'}>부서 없음</Text>
                                    </HStack>
                                  )}
                                </HStack>
                              </TdFullWContainer>
                            </Tr>
                            <Tr>
                              <TdFullWContainer title={'휴대폰번호'}>
                                {/* {isEditable ? (
                                  <Field name="first" as={CustomInput} />
                                ) : (
                                  <Text>{data.employee.phone_number_first}</Text>
                                )} */}
                                <Text>{data.employee.phone_number_first}</Text>
                              </TdFullWContainer>
                            </Tr>
                            <Tr w={'100%'}>
                              <TdFullWContainer title={'계정'}>
                                {/* {isEditable ? (
                                  <Field name="first" as={CustomInput} />
                                ) : (
                                  <Text>{data.employee.email}</Text>
                                )} */}
                                <Text>{data.employee.email}</Text>
                              </TdFullWContainer>
                            </Tr>
                          </Tbody>
                        </Table>
                      </VStack>
                      {/* 집 정보 */}
                      <VStack spacing={3} justifyContent={'start'} align={'flex-start'} w={'100%'}>
                        <Box w={'100%'} fontWeight={'bold'} fontSize={'18px'} textAlign={'start'} flexGrow={1}>
                          집 정보
                        </Box>
                        <Table w={'100%'} variant="simple">
                          <Tbody w={'100%'}>
                            <Tr w={'100%'}>
                              <TdFullWContainer title={'주소'}>
                                {isEditable ? (
                                  <VStack w="full">
                                    <HStack w="full">
                                      <Field name="address" as={CustomInput} placeholder="기본 주소" />
                                      <Button
                                        variant={'basic'}
                                        style={{
                                          height: '32px',
                                        }}
                                        onClick={onOpen}
                                      >
                                        주소 검색
                                      </Button>
                                    </HStack>
                                    <Field name="detail_address" as={CustomInput} placeholder="상세 주소 입력" />
                                  </VStack>
                                ) : (
                                  <Flex w="full" flexDir={'column'}>
                                    <Text>{values.address || '-'}</Text>
                                    {values.detail_address ? (
                                      <Text>{values.detail_address}</Text>
                                    ) : (
                                      <Text color={'gray'}>{'상세 주소 없음'}</Text>
                                    )}
                                  </Flex>
                                )}
                              </TdFullWContainer>
                            </Tr>
                            <Tr>
                              <TdFullWContainer title={'계약기간'}>
                                {isEditable ? (
                                  <HStack>
                                    <Field
                                      name="contract_start_date"
                                      type="date"
                                      as={CustomInput}
                                      placeholder="날짜 선택"
                                    />
                                    <Text>-</Text>
                                    <Field
                                      name="contract_end_date"
                                      type="date"
                                      as={CustomInput}
                                      placeholder="날짜 선택"
                                    />
                                  </HStack>
                                ) : (
                                  <HStack w="full">
                                    <Text flex={1}>{datedotformat(values.contract_start_date) || '-'}</Text>
                                    <Text>{'~'}</Text>
                                    <Text flex={1}>{datedotformat(values.contract_end_date) || '-'}</Text>
                                  </HStack>
                                )}
                              </TdFullWContainer>
                            </Tr>
                            <Tr>
                              <TdFullWContainer title={'보증금'}>
                                {isEditable ? (
                                  <>
                                    <HStack>
                                      <Field name="deposit" as={CustomInput} placeholder="숫자만 입력" />
                                      <Box>원</Box>
                                    </HStack>
                                  </>
                                ) : (
                                  <Text>{staticThreeCommafy(values.deposit)}원</Text>
                                  // <CustomInput type={'datetime-local'}></CustomInput>
                                )}
                              </TdFullWContainer>
                            </Tr>
                            <Tr>
                              <TdFullWContainer title={'월세 / 관리비'}>
                                {isEditable ? (
                                  <>
                                    <HStack>
                                      <Field name="rent" as={CustomInput} placeholder="숫자만 입력" />
                                      <Box>원</Box>
                                      <Box>/</Box>
                                      <Field name="management_fee" as={CustomInput} placeholder="숫자만 입력" />
                                      <Box>원</Box>
                                    </HStack>
                                  </>
                                ) : (
                                  <HStack>
                                    <Text>{staticThreeCommafy(values.rent)}원</Text>
                                    <Text>/</Text>
                                    <Text>{staticThreeCommafy(values.management_fee)}원</Text>
                                  </HStack>
                                )}
                              </TdFullWContainer>
                            </Tr>
                          </Tbody>
                        </Table>
                      </VStack>
                      {/* 월세 납부 정보 */}
                      <VStack spacing={3} justifyContent={'start'} w={'100%'}>
                        <Box w={'100%'} fontWeight={'bold'} fontSize={'18px'} textAlign={'start'} flexGrow={1}>
                          월세 납부 정보
                        </Box>
                        <Table w={'100%'} variant="simple">
                          <Tbody w={'100%'}>
                            <Tr w={'100%'}>
                              <TdFullWContainer title={'납부일'}>
                                {isEditable ? (
                                  <HStack>
                                    <Field name="payment_day" as={CustomInput} placeholder={'1~31 사이의 숫자'} />
                                    <Text>일</Text>
                                  </HStack>
                                ) : (
                                  <Text>매월 {values.payment_day}일</Text>
                                )}
                              </TdFullWContainer>
                            </Tr>
                            <Tr>
                              <TdFullWContainer title={'임대인 성함 / 연락처'}>
                                {isEditable ? (
                                  <HStack>
                                    <Field name="host_name" as={CustomInput} placeholder="성함" />
                                    <Box>/</Box>
                                    <Field name="host_phone_number_first" as={CustomInput} placeholder="연락처" />
                                  </HStack>
                                ) : (
                                  <HStack>
                                    {/* {!data.employee?.lease_contract?.lease_payment?.host?.user_name ? ( */}
                                    {!values.host_name ? (
                                      <Text color={'gray'}>성함 없음</Text>
                                    ) : (
                                      <Text>{values.host_name || '-'}</Text>
                                    )}
                                    {!values.host_phone_number_first ? (
                                      <HStack>
                                        <Text>/</Text>
                                        <Text color={'gray'}>연락처 없음</Text>
                                      </HStack>
                                    ) : (
                                      <HStack>
                                        <Text>/</Text>
                                        <Text>{values.host_phone_number_first || '-'}</Text>
                                      </HStack>
                                    )}
                                  </HStack>
                                )}
                              </TdFullWContainer>
                            </Tr>
                            <Tr>
                              <TdFullWContainer title={'임대인 계좌'}>
                                {isEditable ? (
                                  <HStack>
                                    <Field
                                      name={`bank_code`}
                                      as={SelectBox}
                                      placeholder="은행 선택"
                                      fontSize="12px"
                                      h="32px"
                                    >
                                      {bank_data.data.map((option) => (
                                        <option key={option.bankId} value={option.bankId}>
                                          {option.bankName}
                                        </option>
                                      ))}
                                    </Field>
                                    <Box>/</Box>
                                    <Field name="account_number" as={CustomInput} placeholder="계좌번호" />
                                  </HStack>
                                ) : (
                                  <HStack>
                                    <Text>{bankNameTrans(values.bank_code)}</Text>
                                    <Text>{values.account_number}</Text>
                                  </HStack>
                                )}
                              </TdFullWContainer>
                            </Tr>
                            <Tr>
                              <TdFullWContainer title={'계약서 파일'}>
                                {/* formik_key = lease_contract_pdfs (File type array) */}
                                {/* 기존에 있던 string url = lease_contract_pdf_urls */}
                                {isEditable ? (
                                  <HStack justifyContent={'space-between'}>
                                    <Wrap>
                                      {values.lease_contract_pdf_urls.map((value, index) => {
                                        return (
                                          <FieldArray name="lease_contract_pdf_urls" key={index}>
                                            {({ remove }) => {
                                              return (
                                                <Box position={'relative'}>
                                                  <TextLink href={value} target={'_blank'}>
                                                    계약서 {index + 1}
                                                  </TextLink>
                                                  <Image src={value} boxSize={'100px'} alt="이미지가 아닙니다" />
                                                  <Box
                                                    position="absolute"
                                                    top={0}
                                                    right={0}
                                                    transform={'translate(40%, -40%)'}
                                                    zIndex={20}
                                                  >
                                                    {/* <Box position="absolute" zIndex={20}> */}
                                                    <DeleteCircle
                                                      w={5}
                                                      h={5}
                                                      _hover={{ opacity: 0.6 }}
                                                      transition={'0.18s linear'}
                                                      cursor={'pointer'}
                                                      onClick={() => remove(index)}
                                                    />
                                                  </Box>
                                                </Box>
                                              );
                                            }}
                                          </FieldArray>
                                        );
                                      })}
                                      {values.lease_contract_pdfs.map((value, index) => {
                                        // return <Text key={index}>{index}</Text>;
                                        return (
                                          <Box position={'relative'} key={index}>
                                            <Text>{`업로드 계약서 ${index + 1}`}</Text>
                                            <FormikThumb key={index} formikKey="lease_contract_pdfs" file={value}>
                                              {index}
                                            </FormikThumb>
                                          </Box>
                                        );
                                      })}
                                    </Wrap>
                                    <FormikFileUploadButton
                                      formikKey="lease_contract_pdfs"
                                      w={'fit-content'}
                                      centerProps={{
                                        justifyContent: 'end',
                                      }}
                                    />
                                  </HStack>
                                ) : (
                                  <HStack justifyContent={'space-between'}>
                                    {values.lease_contract_pdf_urls.length === 0 ||
                                    isUndefined(values.lease_contract_pdf_urls.length) ? (
                                      <Text color={'gray'} minW={'max'}>
                                        계약서 없음
                                      </Text>
                                    ) : (
                                      <Wrap>
                                        {values.lease_contract_pdf_urls?.map((pdf, index) => {
                                          return (
                                            <TextLink target={'_blank'} href={pdf} key={index} minW={'max'}>
                                              계약서 {index + 1}
                                            </TextLink>
                                          );
                                        })}
                                      </Wrap>
                                    )}
                                  </HStack>
                                )}
                              </TdFullWContainer>
                            </Tr>
                            {lease_contract_status === 'approved' && (
                              <>
                                <Tr>
                                  <TdFullWContainer title={'정산 계좌'}>
                                    {data?.employee?.virtual_account &&
                                    !isEmptyObject(data?.employee?.virtual_account) ? (
                                      <HStack>
                                        <Text>{data?.employee?.virtual_account?.account_owner_name}</Text>
                                        <Text>{bankNameTrans(data?.employee?.virtual_account?.bank_code)}</Text>
                                        <Text>{data?.employee?.virtual_account?.account_number}</Text>
                                      </HStack>
                                    ) : (
                                      <CreateUserVirtualAccountAndLeasePaymentModal
                                        userType={'tenant'}
                                        userId={data?.employee?._id}
                                        leaseContractId={data.employee.lease_contract._id}
                                      >
                                        <Button variant={'basic'}>계좌생성</Button>
                                      </CreateUserVirtualAccountAndLeasePaymentModal>
                                    )}
                                  </TdFullWContainer>
                                </Tr>
                                <Tr>
                                  <TdFullWContainer title={'월세 납부 상태'}>
                                    <Text
                                      color={
                                        data.employee.lease_contract.lease_payment.payment_status === 'overdue'
                                          ? 'primaryRed'
                                          : '#414141'
                                      }
                                    >
                                      {'월세' +
                                        ' ' +
                                        globalPaymentStatusTrans(
                                          data.employee.lease_contract.lease_payment
                                            .payment_status as LeasePaymentStatusEnum,
                                        )}
                                    </Text>
                                  </TdFullWContainer>
                                </Tr>
                              </>
                            )}
                            {lease_contract_status === 'rejected' && (
                              <Tr>
                                <TdFullWContainer title={'반려 사유'}>
                                  <Text>{data.employee.lease_contract.rejected_reason}</Text>
                                </TdFullWContainer>
                              </Tr>
                            )}
                          </Tbody>
                        </Table>
                      </VStack>
                      <Center w="full" justifyContent={'space-between'}>
                        <FormikEditableWithReCheckButton
                          props={props}
                          isDisabledCompleteEdit={isNotAnyChange}
                          isEditable={isEditable}
                          setIsEditable={setIsEditable}
                          title={`${data.employee.user_name}님의 집 정보를 수정하시겠습니까?`}
                        />
                        <Box textStyle={'medium18'} color={'gray.400'}>
                          최종 업데이트일 {datedotformat(data.employee?.lease_contract?.updatedAt)}
                        </Box>
                      </Center>
                    </VStack>
                  </Flex>
                </Box>
              </Flex>
              <AddressFinder isOpen={isOpen} onClose={onClose} formik={props} />
            </Form>
          );
        }}
      </Formik>
    </QueryParamsModalLayout>
  );
};

export default SupportPaymentUserInfoModal;

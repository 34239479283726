import { CheckCircleIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  Skeleton,
  Table,
  TableCellProps,
  Tbody,
  Td,
  Text,
  TextProps,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { getMonth, getYear } from 'date-fns';
import { isUndefined } from 'lodash';
import { useEffect, useState } from 'react';
import { matchPath, useSearchParams } from 'react-router-dom';
import SupportPaymentExcelUploadButton from '../../../../../../../../components/admin/Button/SupportPaymentExcelUploadButton';
import QueryParamsLayout from '../../../../../../../../layouts/common/main/QueryParamsLayout';
import SetYearAndMonthParams from '../../../../../../../../components/common/Checkbox/SetYearAndMonthParamsCheckBox';
import { TdFullWContainer } from '../../../../../../../../components/common/Table/Tr';
import {
  useGetCompanyAllManagersV2,
  useGetSupportPaymentCompanyAllUsers,
} from '../../../../../../../../lib/admin/hooks/query';
import {
  globalPaymentStatusTrans,
  globalStatusTrans,
  progressStatusTrans,
  subscriptionPaymentStatusTrans,
} from '../../../../../../../../lib/common/utils/utils';
import {
  GlobalStatus,
  LeasePaymentStatusEnum,
  ProgressStatusEnum,
} from '../../../../../../../../lib/common/utils/axios/types';
import { bankNameTrans, currentParamsObject, ToAllFilledObj } from '../../../../../../../../lib/common/utils/base';
import { phoneNumberdashfy } from '../../../../../../../../lib/common/utils/digitformatter';
import CreateCompanyVirtualAccountModal from '../../../../../../../../components/common/Modals/CreateCompanyVirtualAccountModal';
import SupportPaymentUserInfoModal from '../../../../../../../../components/admin/Modals/SupportPaymentUserInfoModal';
import Loading from '../../../../../../../../components/common/Loading';

const Info = () => {
  const { search: srch } = location;
  const {
    id,
    page,
    limit,
    search,
    year,
    month,
    is_using_support_payment,
    status,
    support_payment_status,
    payment_status,
  } = currentParamsObject(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const params = ToAllFilledObj({
    id,
    page,
    limit,
    search,
    year,
    month,
    is_using_support_payment,
    status,
    support_payment_status,
    payment_status,
  });

  const {
    data: cpData,
    isFetching,
    isLoading,
  } = useGetCompanyAllManagersV2(id, {
    axiosConfig: {
      params: {
        year,
        month,
      },
    },
  });

  const month_param = !isUndefined(month)
    ? parseInt(month) + 1 > 12
      ? 1
      : parseInt(month) + 1
    : getMonth(new Date()) + 2;

  const year_param = !isUndefined(year)
    ? parseInt(month) + 1 > 12
      ? parseInt(year) + 1
      : parseInt(year)
    : getYear(new Date());

  const res = useGetSupportPaymentCompanyAllUsers(id || '', {
    axiosConfig: {
      params: {
        ...params,
        month: month_param,
        year: year_param,
      },
    },
  });

  const isInCompanyManagePage = matchPath('/admin/manage/customers/company/detail/supportpayment/*', location.pathname);

  const componentHeight = isInCompanyManagePage ? 490 : 430;

  const supportpayment_userinfo_modal_querykey = 'supportpayment_userinfo_modal';

  if (isLoading) return <Loading />;
  return (
    // <Box w="full" h="full">
    <Box w="full">
      ``
      {/* 최상단 */}
      <Box mb={3}>
        <SetYearAndMonthParams />
      </Box>
      <Box mb={6}>
        <Skeleton isLoaded={!isLoading}>
          <Table w={'100%'} variant="simple">
            <Tbody w={'100%'}>
              <Tr w={'100%'}>
                <TdFullWContainer title={'결제 계좌정보'} isMinWMax>
                  {cpData?.support_payment_account ? (
                    <Text>
                      {bankNameTrans(cpData?.support_payment_account?.bank_code)}&nbsp;
                      {cpData?.support_payment_account?.account_number}
                    </Text>
                  ) : (
                    <CreateCompanyVirtualAccountModal companyId={cpData?._id || ''} category="support_payment">
                      <Button variant={'basic'}>가상 계좌 생성</Button>
                    </CreateCompanyVirtualAccountModal>
                  )}
                </TdFullWContainer>
                <TdFullWContainer title={'지원 금액/인원수'} isMinWMax>
                  <Flex align={'center'}>
                    <Box minW={'max'} mr={4}>
                      {cpData?.support_payment_subscription_contract.subscription_payment
                        ?.expected_total_support_payment
                        ? cpData?.support_payment_subscription_contract.subscription_payment
                            ?.expected_total_support_payment + '원'
                        : '미정'}
                      &nbsp;/&nbsp;
                      {cpData?.support_payment_subscription_contract.subscription_payment?.total_count
                        ? cpData?.support_payment_subscription_contract.subscription_payment?.total_count + '명'
                        : '미정'}
                    </Box>
                    <Tooltip label={'* 업로드 완료 시, 임직원에게 알림이 일괄적으로 보내집니다.'} hasArrow>
                      <Box>
                        <SupportPaymentExcelUploadButton company_id={id} />
                      </Box>
                    </Tooltip>
                  </Flex>
                </TdFullWContainer>
                <TdFullWContainer title={'지원금 납부상태'} isMinWMax>
                  {subscriptionPaymentStatusTrans(
                    cpData?.support_payment_subscription_contract.subscription_payment?.payment_status,
                  )}
                </TdFullWContainer>
              </Tr>
            </Tbody>
          </Table>
        </Skeleton>
      </Box>
      {/* Table 2 */}
      <Box w="full" h={`calc(100vh - ${componentHeight}px)`} maxH={'min-content'}>
        <Box mb={1}>
          <Text textStyle={'bold18'}>임직원 리스트</Text>
        </Box>
        <QueryParamsLayout
          res={res}
          moreFilters={{
            // serviceCategoryMenu: 'left',
            usingSupportPaymentCheckbox: 'left',
            checkHouseStatusMenu: 'left',
            supportPaymentStatusMenu: 'left',
            paymentStatusMenu: 'left',
          }}
        >
          <Table variant="head_silverbg_toplining">
            <Thead bgColor={'#F8F8F8'}>
              <Tr>
                <Th>부서</Th>
                <Th>이름</Th>
                <Th>연락처</Th>
                <Th>계정</Th>
                <Th>집정보 확인</Th>
                <Th>지원 예정 금액</Th>
                <Th>지원상태</Th>
                <Th>월세 납부상태</Th>
              </Tr>
            </Thead>
            <Tbody>
              {res.data?.result?.map((tenant, index) => {
                const {
                  employee: { user_name, _id, is_sign_up, department, email, phone_number_first, lease_contract },
                  name,
                } = tenant;

                const handleOpenUserInfoModal = () => {
                  if (lease_contract?._id) {
                    searchParams.set(supportpayment_userinfo_modal_querykey, lease_contract?._id || '');
                    setSearchParams(searchParams);
                  }
                };

                const isAbleOpenUserInfoModalTextProps = {
                  textDecor: lease_contract?._id ? 'underline' : 'unset',
                  cursor: lease_contract?._id ? 'pointer' : 'unset',
                  onClick: handleOpenUserInfoModal,
                } as TableCellProps;

                return (
                  <Tr key={srch + index}>
                    <Td>
                      <Text>{department || '-'}</Text>
                    </Td>
                    <Td {...isAbleOpenUserInfoModalTextProps}>
                      <Text>{user_name || '-'}</Text>
                    </Td>
                    <Td>
                      <Text>{phoneNumberdashfy(phone_number_first)}</Text>
                    </Td>
                    <Td>
                      <Text>{email || '-'}</Text>
                    </Td>
                    <Td {...isAbleOpenUserInfoModalTextProps}>
                      {/* HERE!!! 승인/반려 언더바/ 확인요청 모두 클릭시 모달오픈 */}
                      {/* status === pending일 때 확인요청 */}
                      {lease_contract?.status === 'pending' ? (
                        <Button variant={'basic'}>확인요청</Button>
                      ) : (
                        <Text>{globalStatusTrans(lease_contract?.status as GlobalStatus)}</Text>
                      )}
                    </Td>
                    <Td>
                      <Text>{lease_contract?.lease_payment?.support_payment || '-'}</Text>
                    </Td>
                    <Td>
                      <Text>
                        {progressStatusTrans(
                          lease_contract?.lease_payment.support_payment_status as ProgressStatusEnum,
                        ) || '-'}
                      </Text>
                    </Td>
                    <Td>
                      <Text>
                        {globalPaymentStatusTrans(
                          lease_contract?.lease_payment.payment_status as LeasePaymentStatusEnum,
                        )}
                      </Text>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
          <SupportPaymentUserInfoModal queryKeys={[supportpayment_userinfo_modal_querykey]} />
        </QueryParamsLayout>
      </Box>
    </Box>
  );
};

export default Info;

import { Table, Tbody, Thead, Tr } from '@chakra-ui/react';
import format from 'date-fns/format';
import { HistoryType } from '../../../lib/common/utils/axios/types';
import { datedotformat } from '../../../lib/common/utils/dateformatter';
import { WTd } from '../../common/Table/Td';
import { WTh } from '../../common/Table/Th';

interface ContractContentTableProps {
  data: HistoryType[] | undefined;
}

const ContractContentTable: React.FC<ContractContentTableProps> = ({ data: contractDataArr }) => (
  <Table variant="simple_lining">
    <Thead bgColor={'#F8F8F8'} borderColor={'gray.100'}>
      <Tr>
        <WTh borderR flexProps={{ justifyContent: 'space-between' }} w={'150px'} p={3}>
          계약 체결일
        </WTh>
        <WTh flexProps={{ justifyContent: 'space-between' }} p={3}>
          입주 기간
        </WTh>
      </Tr>
    </Thead>
    <Tbody>
      {contractDataArr?.map((data, index) => (
        <Tr h={'51px'} key={index}>
          <WTd borderR flexProps={{ justifyContent: 'space-between' }}>
            {datedotformat(data.date)}
          </WTd>
          <WTd flexProps={{ justifyContent: 'space-between' }}>{data.description}</WTd>
        </Tr>
      ))}
    </Tbody>
  </Table>
);

export default ContractContentTable;

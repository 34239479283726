import { Route, RouteProps, Routes } from 'react-router-dom';
import AdminLoginPage from '../../../pages/common/Login/Admin';

const AdminLoginRoute: React.FC<RouteProps> = () => {
  return (
    <Routes>
      <Route index element={<AdminLoginPage />} />
    </Routes>
  );
};

export default AdminLoginRoute;

import {
  Box,
  Button,
  Center,
  Collapse,
  Flex,
  HStack,
  ListItem,
  Textarea,
  UnorderedList,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { Field, isNaN, useFormikContext } from 'formik';
import { useEffect, useRef, useState } from 'react';
import Dropzone from 'react-dropzone';
import { useLocation, useNavigate } from 'react-router-dom';
import Thumb_2 from '../../../Thumb/Tumb_2';
import { useCreateRoomInBuilding } from '../../../../../lib/common/hooks/mutation';
import { booleanToString } from '../../../../../lib/common/utils/base';
import { error, failToUpload } from '../../../../../statics/common/data/toast';
import RegisterHostsInfoModal from '../../RegisterHostsInfoModal';
import { initialValue, StepProps } from '..';
import Upper from './Upper';
import * as Util from '../../../../../lib/common/utils/Util';

interface PictureProps extends StepProps {
  onCloseModal: () => void;
}

const Picture: React.FC<PictureProps> = ({ step, setStep, onCloseModal }) => {
  const formik = useFormikContext<typeof initialValue>();
  const { values, setFieldValue } = formik;
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const componentStartRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const routeUserType = Util.getRouteUserTypeFromUrl(location.pathname);
  const { mutateAsync: createRoomInBuilding } = useCreateRoomInBuilding();
  const {
    building_id,
    building_form,
    room_type,
    host_id,
    share_facilities,
    deposit,
    rent,
    management_fee,
    common_items,
    individual_items,
    move_in,
    minimum_contract_month,
    dong,
    ho,
    floor_r,
    floor_type,
    room_living_type,
    room_num,
    toilet_num,
    supply_area,
    dedicated_area,
    dedicated_area_peong,
    supply_area_peong,
    room_direction,
    heating_type,
    default_options,
    pictures,
    description,
    additional_options,
    is_security_window,
    is_parking_r,
    is_pet,
    remodeling_season,
    remodeling_items,
    is_bohome,
    access_status,
  } = values;

  const isColiving = building_form === 'coliving_house';

  const toast = useToast();
  useEffect(() => {
    componentStartRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, []);

  const isFinalStep = (values as any).is_clicked_register_host_button_once;

  const navigate = useNavigate();

  const handleSubmitRoomData = async () => {
    if (isFinalStep) {
      setIsSubmitting(true);
      const formData = new FormData();
      formData.append('building_id', building_id);
      formData.append('host_id', host_id);
      formData.append('room_type', room_type);
      formData.append('is_template', booleanToString(isColiving));

      formData.append('deposit', parseInt(deposit).toString());
      formData.append('rent', parseInt(rent).toString());
      formData.append('management_fee', parseInt(management_fee).toString());
      // formData.append('deposit', deposit);
      // formData.append('rent', rent);
      // formData.append('management_fee', management_fee);
      common_items.map((item) => {
        formData.append('common_items[]', item);
      });
      individual_items.map((item) => {
        formData.append('individual_items[]', item);
      });
      formData.append('move_in', JSON.stringify(move_in));
      formData.append('minimum_contract_month', minimum_contract_month);
      pictures.map((item) => {
        formData.append('room_pictures', item);
      });
      formData.append('dong', dong);
      formData.append('ho', ho);
      formData.append('floor_r', floor_r);
      formData.append('floor_type', floor_type);
      formData.append('room_living_type', room_living_type);
      formData.append('room_num', room_num);
      formData.append('toilet_num', toilet_num);
      formData.append('supply_area', supply_area);
      formData.append('dedicated_area', dedicated_area);
      formData.append('supply_area_peong', supply_area_peong);
      formData.append('dedicated_area_peong', dedicated_area_peong);
      formData.append('room_direction', room_direction);
      formData.append('heating_type', heating_type);
      default_options.map((item) => {
        formData.append('default_options[]', item);
      });
      additional_options.map((item) => {
        formData.append('additional_options[]', item);
      });
      share_facilities.map((item) => {
        formData.append('share_facilities[]', item);
      });
      formData.append('is_security_window', is_security_window);
      formData.append('is_parking_r', is_parking_r);
      formData.append('is_pet', is_pet);
      formData.append('remodeling_season', remodeling_season);
      remodeling_items.map((item) => {
        formData.append('remodeling_items[]', item);
      });
      formData.append('is_bohome', is_bohome);
      formData.append('access_status', access_status);
      formData.append('description', description);

      await createRoomInBuilding({
        building_id,
        formData,
      })
        .then((result) => {
          if ((result as any)?.data) {
            navigate(`/${routeUserType}/manage/roomInfo/${(result as any)?.data.data.room._id}`);
          }
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    } else {
      // toast({
      //   ...error,
      //   description: '관리자에게 문의가 필요합니다.',
      // });
    }
  };

  const buttonText = isFinalStep ? '최종 등록하기' : '등록하기';

  // console.log('최종 DB 결과 =======>', values);
  return (
    <Box w="full" h="full" ref={componentStartRef}>
      {/* 1 - progressive bar */}
      <Upper step={step} />
      {/* 2 - 사진 및 상세 정보 */}
      <VStack mx={'150px'} my={'50px'} spacing={7}>
        <Flex flexDir={'column'} w={'full'} alignItems={'stretch'}>
          <Box fontWeight={'bold'} fontSize={'18px'} w={'100%'} textAlign={'start'} flexGrow={1} mb={2}>
            사진등록
          </Box>
          <Box w="full" borderBottomWidth={'1px'} borderBottomColor={'gray'} />
          <UnorderedList w={'100%'} textAlign={'start'} flexGrow={1} pl={2} my={4}>
            <ListItem>
              최소 3장 이상의 사진을 등록해야 하며 최대 10장까지 등록이 가능합니다. (한 장당 10MB이내)
            </ListItem>
            <ListItem>첫번째 사진이 대표 이미지로 보여지며 순서를 변경 할 수 있습니다.</ListItem>
            <ListItem>매물과 관련없는 이미지, 홍보성 이미지, 워터마크 이미지는 등록하실 수 없습니다.</ListItem>
          </UnorderedList>
          <Box w="full" borderBottomWidth={'1px'} borderBottomColor={'gray.200'} mb={2} />
          <Dropzone
            accept={'image/*'}
            onDrop={(acceptedFiles) => {
              if (acceptedFiles.length === 0) {
                return;
              } else {
                if (pictures.length + acceptedFiles.length > 10) {
                  toast({
                    ...failToUpload,
                    description: '파일의 크기를 확인하시거나 개수가 10장을 넘지 않는지 확인하세요.',
                  });
                } else {
                  setFieldValue('pictures', (pictures as any).concat(acceptedFiles));
                }
              }
            }}
          >
            {({ isDragActive, isDragReject, acceptedFiles, getRootProps, getInputProps }) => {
              return (
                <Flex flexDir={'column'} w={'full'}>
                  <Center {...getRootProps()} w={'full'}>
                    <input {...getInputProps()} />
                    <Center
                      bgColor={'lotionWhite'}
                      w={'full'}
                      h={'160px'}
                      borderRadius={'2px'}
                      borderWidth={'1px'}
                      borderColor={'silverGray'}
                      cursor="pointer"
                      fontWeight={'medium'}
                      _hover={{ bgColor: 'zetaBlue', color: 'white' }}
                      transition={'0.18s linear'}
                    >
                      <Center
                        fontSize={'24px'}
                        fontWeight={'bold'}
                        bgColor={'zetaBlue'}
                        color="white"
                        w={'20%'}
                        borderRadius={'md'}
                        py={2}
                      >
                        + 사진 등록
                      </Center>
                    </Center>
                  </Center>
                  <Collapse style={{ width: '100%' }} in={pictures.length !== 0}>
                    <HStack mt={6}>
                      {pictures.map((file, i) => (
                        <Thumb_2 key={i} picture={file} />
                      ))}
                    </HStack>
                  </Collapse>
                </Flex>
              );
            }}
          </Dropzone>
        </Flex>

        <Flex flexDir={'column'} w={'full'} alignItems={'stretch'}>
          <Box fontWeight={'bold'} fontSize={'18px'} w={'100%'} textAlign={'start'} flexGrow={1} mb={2}>
            상세 정보
          </Box>
          <Box w="full" borderBottomWidth={'1px'} borderBottomColor={'gray'} />
          <UnorderedList w={'100%'} textAlign={'start'} flexGrow={1} pl={2} my={4}>
            <ListItem>해당 방에 대한 자세한 설명을 입력해 주세요.</ListItem>
            <ListItem>
              방 정보, 가격협의내용, 교통 등 자세한 내용을 작성하시면 거래가 성사될 가능성이 높아집니다.
            </ListItem>
            <ListItem>한글, 영어, 숫자, m2을 제외한 특수문자(괄호포함)등은 임의로 삭제될 수 있습니다.</ListItem>
          </UnorderedList>
          <Box w="full" borderBottomWidth={'1px'} borderBottomColor={'gray.200'} mb={2} />
          <Field
            name="description"
            as={Textarea}
            bgColor="lotionWhite"
            borderWidth={'1px'}
            borderColor={'silverGray'}
            maxLength={950}
            h="160px"
            _hover={{}}
          />
          {/* 다음 */}
          <Flex justifyContent={'center'} w={'100%'} mt={5} ml={2}>
            <RegisterHostsInfoModal disabled={isFinalStep}>
              <Button
                variant={'basic'}
                fontSize={'14px'}
                w={'150px'}
                h="34px"
                isLoading={isSubmitting}
                isDisabled={pictures.length < 3}
                onClick={handleSubmitRoomData}
              >
                {buttonText}
              </Button>
            </RegisterHostsInfoModal>
          </Flex>
        </Flex>
      </VStack>
    </Box>
  );
};

export default Picture;

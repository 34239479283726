import { Box, Flex } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { queryClient } from '../../../../..';
import Agencies from './agencies';
import Companies from './companies';
import Calendar from './sections/Calendar';
import Settlement from './settlement';
import Users from './users';

const Dashboard = () => {
  const { type } = useParams();
  return (
    <Flex>
      {type && { companies: <Companies />, users: <Users />, agencies: <Agencies />, settlement: <Settlement /> }[type]}
      <Flex flexGrow={8} justifyContent={'center'}>
        {/* Calender */}
        <Calendar />
      </Flex>
    </Flex>
  );
};

export default Dashboard;

import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import { useEditableControls, ButtonGroup, Flex, FlexProps } from '@chakra-ui/react';
import { IconContainer, NoteEditIcon } from '../../../statics/common/icons';

export const NoteButtonIcon: React.FC<FlexProps> = ({ ...props }) => {
  return (
    <Flex justifyContent="center" {...props}>
      <IconContainer style={{ width: '24px', height: '24px', padding: '0', margin: '0' }} type="button">
        <NoteEditIcon />
      </IconContainer>
    </Flex>
  );
};

interface NoteEditButtonIconProps {
  editButtonProps?: any;
}
export const NoteEditButtonIcon: React.FC<NoteEditButtonIconProps> = ({ editButtonProps }) => {
  return (
    <Flex justifyContent="center">
      <IconContainer
        style={{ width: '24px', height: '24px', padding: '0', margin: '0' }}
        {...(editButtonProps && { ...editButtonProps() })}
      >
        <NoteEditIcon />
      </IconContainer>
    </Flex>
  );
};

const EditableControls = () => {
  const { isEditing, getSubmitButtonProps, getCancelButtonProps, getEditButtonProps } = useEditableControls();

  return isEditing ? (
    <ButtonGroup justifyContent="center">
      <IconContainer {...getSubmitButtonProps()} style={{ width: '24px', height: '24px', padding: '0', margin: '0' }}>
        <CheckIcon w={4} h={4} />
      </IconContainer>
      <IconContainer
        {...getCancelButtonProps()}
        style={{ width: '24px', height: '24px', padding: '0', margin: '0', marginLeft: '5px' }}
      >
        <CloseIcon w={4} h={4} />
      </IconContainer>
    </ButtonGroup>
  ) : (
    <NoteEditButtonIcon editButtonProps={getEditButtonProps} />
  );
};

export default EditableControls;

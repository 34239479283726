import { ChevronDownIcon } from '@chakra-ui/icons';
import { Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { buildSearchParams, currentParamsObject } from '../../../../lib/common/utils/base';
import { default_items_limit_number } from '../../../../lib/common/utils/values';

const LimitMenu = () => {
  const navigate = useNavigate();

  const { page, limit: limit_param, search, ...params } = currentParamsObject(false);

  const items_per_page = 5;

  const onClickMenuItem = (count: number) => {
    const searchParams = buildSearchParams({
      page: '1',
      limit: count.toString(),
      pagelist: '1',
      search,
      ...params,
    });
    navigate(searchParams);
  };

  return (
    <Menu autoSelect={false}>
      <MenuButton as={Button} variant={'basic'} fontSize={14} rightIcon={<ChevronDownIcon />}>
        {limit_param || default_items_limit_number} / 페이지
      </MenuButton>
      <MenuList zIndex={2}>
        {Array.from(Array(4).keys()).map((value) => {
          const total_items = (value + 2) * items_per_page;
          const isSelected = limit_param ? parseInt(limit_param) === total_items : false;
          return (
            <MenuItem key={value} onClick={() => onClickMenuItem(total_items)} isDisabled={isSelected}>
              {total_items} / 페이지
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  );
};

export default LimitMenu;

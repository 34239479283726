import { ChevronDownIcon } from '@chakra-ui/icons';
import { Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { createSearchParams, URLSearchParamsInit, useNavigate, useSearchParams } from 'react-router-dom';
import { service_category_trans } from '../../../../lib/common/utils/utils';
import {
  addTotalToObj,
  buildSearchParams,
  currentParamsObject,
  extractValFromObj,
  ToAllFilledObj,
} from '../../../../lib/common/utils/base';

const ServiceCategoryMenu = () => {
  const { pathname, search: srch } = location;
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const { page, limit: limit_param, search, category } = currentParamsObject();

  const items_per_page = 5;

  const onClickMenuItem = (value: string) => {
    // searchParams.set('servicecategory', value);
    // setSearchParams(searchParams);
    const searchParams = buildSearchParams({
      page: '1',
      pagelist: '1',
      limit: limit_param,
      category: value,
    });
    navigate(searchParams);
  };

  const options = addTotalToObj(service_category_trans);
  const title = extractValFromObj(options, category, '전체');

  return (
    <Menu autoSelect={false}>
      <MenuButton as={Button} variant={'basic'} height={'36px'} fontSize={14} rightIcon={<ChevronDownIcon />}>
        {/* 선택에 따라 값 선택 */}
        {/* 구독 서비스 */}
        {title}
      </MenuButton>
      <MenuList zIndex={2}>
        {Object.entries(options).map((option, index) => {
          const [key, value] = option;
          return (
            <MenuItem key={index} onClick={() => onClickMenuItem(key)}>
              {value}
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  );
};

export default ServiceCategoryMenu;

import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Input,
  InputGroup,
  InputRightAddon,
  ListItem,
  Table,
  Tbody,
  UnorderedList,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import axios from 'axios';
import { Field, useFormikContext } from 'formik';
import React, { useState } from 'react';
import { initialValue, StepProps } from '..';
import { SingleDatepicker } from '../../../../../components/common/Calender';
import ValidationWithTouch from '../../../../../components/common/FormikValidation/ValidationWithTouch';
import { CustomInput } from '../../../../../components/common/Input';
import AddressFinder from '../../../../../components/common/Modals/AddressFinder';
import { FRadioButtonGroup } from '../../../../../components/common/Radio';
import { TrContainer } from '../../../../../components/common/Table/Tr';
import { base, version } from '../../../../../lib/common/utils/axios';
import { RegistrationNumberAutoFormat } from '../../../../../lib/common/utils/base';
import { deDashfy, threeCommafy } from '../../../../../lib/common/utils/digitformatter';
import { business_type_radio } from '../../../../../statics/common/data/radio';
import { duplicated, error } from '../../../../../statics/common/data/toast';
import { FileInput } from './sections/FileInput';

const Two: React.FC<StepProps> = ({ setStep }) => {
  // const { values, setFieldValue, handleChange } = useFormikContext<typeof initialValue>();
  const formik = useFormikContext<typeof initialValue>();
  const { values, setFieldValue, handleChange, errors, touched } = formik;
  const {
    company_name,
    business_type,
    business_registration_number,
    business_status,
    business_item,
    address,
    detail_address,
    capital,
    establishment_date,
    employee_number,
    representative_number,
    digital_tax_invoice_email,
    business_certificate,
    logo,
  } = values;

  const toast = useToast();
  const toast_id_duplicated_not_entered = 'duplicated_not_entered';
  const toast_id_duplicated = 'isCompleteDuplicated';
  const toast_id_allchecked = 'isAllChecked';
  const toast_id_certificate = 'certificate';
  const toast_id_employee_number = 'employee_number';

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [capitalFee, setCapitalFee] = useState(0);
  const [employeeNumber, setEmployeeNumber] = useState(0);
  const [establishmentDate, setEstablishmentDate] = useState(0);
  console.log('values => ', values);

  // 기업명 중복 체크
  const [isDuplicated, setIsDuplicated] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);

  return (
    <Flex flexDir={'column'} h={'full'} justifyContent={'space-between'}>
      {/* 설명 */}
      <UnorderedList fontSize={'12px'} mb={6}>
        <ListItem>회원가입에 관한 문의사항은 고객센터(1833-9092)으로 연락 바랍니다.</ListItem>
        <ListItem>보홈 고객사는 회원가입 요청 후 승인 후에 서비스를 이용하실 수 있습니다.</ListItem>
      </UnorderedList>
      {/* 고객사 정보 */}
      <VStack w="100%">
        <Flex flexDir={'column'} fontWeight={'bold'} fontSize={'15px'} textAlign={'start'} flexGrow={1} w={'100%'}>
          <Box>기업 정보</Box>
        </Flex>
        <Flex w="100%">
          <Table w="100%">
            <Tbody>
              <TrContainer title="기업명" fontSize={'12px'}>
                <HStack>
                  <Field
                    name="company_name"
                    as={CustomInput}
                    autoFocus
                    placeholder="기업명을 입력해 주세요."
                    onChange={handleChange}
                  ></Field>
                </HStack>
                <ValidationWithTouch error={errors.company_name} touch={touched.company_name} />
              </TrContainer>
              {/* <TrContainer title="사업자 대표명" fontSize={'12px'}>
                <Field name="ceo" as={CustomInput} placeholder="성함을 입력해 주세요." onChange={handleChange}></Field>
              </TrContainer> */}
              <TrContainer title="사업자 구분" fontSize={'12px'}>
                <VStack alignItems={'flex-start'} spacing={1}>
                  <Flex justify={'space-between'} w="full">
                    <Field name="business_type" as={FRadioButtonGroup} options={business_type_radio} />
                    <Button
                      variant={'basic'}
                      w="84px"
                      style={{ height: '32px' }}
                      isDisabled={!isDuplicated && isChecked}
                      onClick={async () => {
                        const empty = business_registration_number.length === 0;
                        if (empty || errors.business_registration_number) {
                          if (!toast.isActive(toast_id_duplicated_not_entered)) {
                            toast({
                              id: toast_id_duplicated_not_entered,
                              title: '사업자 구분',
                              description: `올바른 사업자 등록번호 기입이 필요합니다.`,
                              status: 'warning',
                              variant: 'warning',
                              duration: 2000,
                              isClosable: true,
                            });
                          }
                          return null;
                        } else {
                          await axios
                            .post(`${base}${version}/common/is-duplicated-business-registration-number/`, {
                              business_registration_number: deDashfy(business_registration_number),
                            })
                            .then((_data) => {
                              const {
                                data: {
                                  data: { is_duplicated },
                                },
                              } = _data;
                              if (is_duplicated) {
                                toast(duplicated);
                              } else {
                                setIsDuplicated(false);
                                setIsChecked(true);
                                toast({
                                  title: '사업자 구분',
                                  description: `중복 확인 완료되었습니다.`,
                                  status: 'success',
                                  variant: 'success',
                                  duration: 2000,
                                  isClosable: true,
                                });
                              }
                            })
                            .catch(() => {
                              toast(error);
                            });
                        }
                      }}
                    >
                      {!isDuplicated && isChecked ? '확인 완료' : '중복 확인'}
                    </Button>
                  </Flex>
                  <Field
                    name="business_registration_number"
                    as={CustomInput}
                    placeholder="사업자 등록번호를 입력해 주세요."
                    // onChange={handleChange}
                    onChange={(e: any) => {
                      setFieldValue('business_registration_number', e.target.value);
                    }}
                    value={RegistrationNumberAutoFormat(values.business_registration_number)}
                    isDisabled={!isDuplicated && isChecked}
                    maxLength={12}
                    autoComplete="off"
                  />
                </VStack>
                <ValidationWithTouch
                  error={errors.business_registration_number}
                  touch={touched.business_registration_number}
                />
              </TrContainer>
              <TrContainer title="업태" fontSize={'12px'}>
                <Field
                  name="business_status"
                  as={CustomInput}
                  placeholder="사업자 등록증 내 업태를 입력해 주세요."
                  onChange={handleChange}
                  autoComplete="off"
                />
                <ValidationWithTouch error={errors.business_status} touch={touched.business_status} />
              </TrContainer>
              <TrContainer title="종목" fontSize={'12px'}>
                <Field
                  name="business_item"
                  as={CustomInput}
                  placeholder="사업자 등록증 내 종목을 입력해 주세요."
                  onChange={handleChange}
                />
                <ValidationWithTouch error={errors.business_item} touch={touched.business_item} />
              </TrContainer>
              <TrContainer title="사업장 소재지" fontSize={'12px'}>
                {/* <CustomInput onFocus={} /> */}
                <VStack spacing={1}>
                  <Field
                    name="address"
                    as={CustomInput}
                    placeholder="기본 주소를 입력해 주세요."
                    onChange={() => true}
                    onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
                      onOpen();
                      e.currentTarget.blur();
                    }}
                    onClick={onOpen}
                    cursor={'pointer'}
                    autoComplete="off"
                  />
                  <Field
                    name="detail_address"
                    as={CustomInput}
                    placeholder="상세 주소를 입력해 주세요."
                    onChange={handleChange}
                  />
                </VStack>
                <ValidationWithTouch error={errors.address} touch={touched.address} />
                {/* <ValidationWithTouch error={errors.detail_address} touch={touched.detail_address} /> */}
              </TrContainer>
              {/* 자본금 */}
              <TrContainer title="자본금" fontSize={'12px'}>
                <InputGroup>
                  <Field
                    name="capital"
                    as={Input}
                    onChange={(e: any) => {
                      // console.log(Number(e.target.value).toString());

                      setFieldValue('capital', threeCommafy(e.target.value));
                    }}
                    value={values.capital}
                    style={{ backgroundColor: '#FBFBFB' }}
                    placeholder="자본금"
                    _placeholder={{ fontSize: '12px', color: 'silverGray' }}
                    fontSize="12px"
                    h={'32px'}
                    w={'50%'}
                    borderRightRadius={'0px'}
                    onFocus={(e: any) => e.target.select()}
                  />
                  <InputRightAddon
                    children="원"
                    bgColor={'#F8F8F8'}
                    fontSize={'14px'}
                    h={'32px'}
                    border={'1px solid'}
                    borderColor={'silverGray'}
                  />
                </InputGroup>
              </TrContainer>
              {/* 회사설립연월일 */}
              <TrContainer title="회사설립연월일" fontSize={'12px'}>
                {/* <Field
                  name="establishment_date"
                  as={CustomInput}
                  placeholder="회사설립연월일 8자리를 입력해 주세요."
                  onChange={(e: any) => {
                    setFieldValue('establishment_date', e.target.value);
                  }}
                  value={NumberInputTrim(values.establishment_date)}
                /> */}
                <SingleDatepicker
                  date={establishment_date ? new Date(establishment_date) : undefined}
                  // {...formik.getFieldProps('completion_date')}
                  onDateChange={(date) => {
                    setFieldValue('establishment_date', date);
                  }}
                  propsConfigs={{
                    inputProps: {
                      bgColor: 'lotionWhite',
                      size: 'sm',
                      fontSize: '12px',
                      borderRadius: 'md',
                      borderColor: 'silverGray',
                      color: 'highGray',
                      _placeholder: {
                        color: 'silverGray',
                      },
                      placeholder: '회사설립연월일 8자리를 입력해 주세요.',
                    },
                  }}
                />
                <ValidationWithTouch error={errors.establishment_date} touch={touched.establishment_date} />
              </TrContainer>
              <TrContainer title="근로자 수" fontSize={'12px'}>
                <InputGroup>
                  <Field
                    name="employee_number"
                    as={Input}
                    onChange={(e: any) => {
                      setFieldValue('employee_number', threeCommafy(e.target.value));
                    }}
                    value={values.employee_number}
                    style={{ backgroundColor: '#FBFBFB' }}
                    placeholder="근로자 수"
                    _placeholder={{ fontSize: '12px', color: 'silverGray' }}
                    _placeholderShown={{ textOverflow: 'ellipsis' }}
                    fontSize="12px"
                    h={'32px'}
                    w={'50%'}
                    borderRightRadius={'0px'}
                    onFocus={(e: any) => e.target.select()}
                  />
                  <InputRightAddon
                    children="명"
                    bgColor={'#F8F8F8'}
                    fontSize={'14px'}
                    h={'32px'}
                    border={'1px solid'}
                    borderColor={'silverGray'}
                  />
                </InputGroup>
              </TrContainer>
              <TrContainer title="회사 대표번호" fontSize={'12px'}>
                <Field
                  name="representative_number"
                  as={CustomInput}
                  placeholder="회사 대표번호를 - 없이 입력해 주세요."
                  onChange={handleChange}
                  type={'tel'}
                />
                <ValidationWithTouch error={errors.representative_number} touch={touched.representative_number} />
              </TrContainer>
              <TrContainer title="전사 세금 계산서 전용 전자우편 주소" fontSize={'12px'}>
                <Field
                  name="digital_tax_invoice_email"
                  as={CustomInput}
                  placeholder="전자 세금 계산서를 받을 이메일 주소를 입력해 주세요."
                  onChange={handleChange}
                  type="email"
                  list="email-dropdown-list"
                  className="life"
                />
                {/* {values.digital_tax_invoice_email.includes('@') && (
                  <datalist id="email-dropdown-list">
                    <option value={`${values.digital_tax_invoice_email}google.com`}></option>
                    <option value={`${values.digital_tax_invoice_email}naver.com`}></option>
                    <option value={`${values.digital_tax_invoice_email}daum.com`}></option>
                  </datalist>
                )} */}
                {/* <CustomInput list /> */}
                <ValidationWithTouch
                  error={errors.digital_tax_invoice_email}
                  touch={touched.digital_tax_invoice_email}
                />
              </TrContainer>
            </Tbody>
          </Table>
        </Flex>
      </VStack>
      {/* 관련 제출 서류 첨부 */}
      <Flex flexDir={'column'} mt={6} fontWeight={'bold'} fontSize={'15px'} textAlign={'start'} flexGrow={1} w={'100%'}>
        <Box>관련 제출 서류 첨부</Box>
        <VStack fontWeight={'normal'} fontSize={'12px'} alignItems={'flex-end'} justifyContent={'space-between'}>
          <FileInput id="business_certificate" name="business_certificate" handleFormikChange={handleChange}>
            사업자 등록증
          </FileInput>
          {/* <ValidationWithTouch error={errors.business_certificate} touch={touched.business_certificate} /> */}
          <FileInput id="logo" name="logo" handleFormikChange={handleChange}>
            로고 파일
          </FileInput>
        </VStack>
      </Flex>
      <Button
        _after={{ content: "''", height: '200px', width: '1px', opacity: 0, position: 'absolute' }}
        mt={6}
        variant={'gray'}
        boxShadow={'md'}
        w={'full'}
        style={{ height: '40px' }}
        onClick={() => {
          const isAllInfoValue =
            !!company_name &&
            !!business_type &&
            !!business_registration_number &&
            !!business_status &&
            !!business_item &&
            !!address &&
            // !!detail_address &&
            (capital === 0 || !!capital) &&
            !!establishment_date &&
            employee_number > 0 &&
            !!employee_number &&
            // !!employee_number &&
            !!representative_number &&
            !!digital_tax_invoice_email;
          // && !!business_certificate;
          // && !!logo;

          const isAllInfoNoError =
            !errors.company_name &&
            !errors.business_type &&
            !errors.business_registration_number &&
            !errors.business_status &&
            !errors.business_item &&
            !errors.address &&
            // !errors.detail_address &&
            // !errors.capital &&
            !errors.establishment_date &&
            !errors.employee_number &&
            !errors.representative_number &&
            !errors.digital_tax_invoice_email;
          // && !errors.business_certificate;
          // && !errors.logo;

          const isCompleteDuplicated = !isDuplicated && isChecked;
          const isAllInfoChecked = isAllInfoValue && isAllInfoNoError;

          if (!isCompleteDuplicated) {
            if (!toast.isActive(toast_id_duplicated)) {
              toast({
                id: toast_id_duplicated,
                title: '사업자 등록번호 인증',
                description: '사업자 등록번호 중복 확인이 필요합니다.',
                status: 'warning',
                duration: 2000,
                isClosable: true,
                position: 'bottom',
                variant: 'warning',
              });
            }
          } else if (!(employee_number > 0 && !!employee_number)) {
            if (!toast.isActive(toast_id_employee_number)) {
              toast({
                id: toast_id_employee_number,
                title: '근로자 수',
                description: '최소 한 명 이상의 근로자 수 기입이 필요합니다.',
                status: 'warning',
                duration: 2000,
                isClosable: true,
                position: 'bottom',
                variant: 'warning',
              });
            }
          } else if (!isAllInfoChecked) {
            if (!toast.isActive(toast_id_allchecked)) {
              toast({
                id: toast_id_allchecked,
                title: '정보 기입',
                description: '정보 기입 확인이 필요합니다.',
                status: 'warning',
                duration: 2000,
                isClosable: true,
                position: 'bottom',
                variant: 'warning',
              });
            }
          } else if (!business_certificate) {
            if (!toast.isActive(toast_id_certificate)) {
              toast({
                id: toast_id_certificate,
                title: '서류 첨부',
                description: '사업자 등록증 첨부가 필요합니다.',
                status: 'warning',
                duration: 2000,
                isClosable: true,
                position: 'bottom',
                variant: 'warning',
              });
            }
          } else {
            toast.closeAll();
            return setStep(2);
          }
        }}
        position={'relative'}
      >
        <Center h={'40px'} fontSize={'15px'}>
          다음
        </Center>
      </Button>
      {/* Modal */}
      <AddressFinder isOpen={isOpen} onClose={onClose} formik={formik} />
    </Flex>
  );
};

export default Two;

import { Box, Checkbox, CheckboxProps, Flex, FormLabel, Switch, SwitchProps, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { createSearchParams, URLSearchParamsInit, useNavigate } from 'react-router-dom';
import { buildSearchParams, currentParamsObject, ToAllFilledObj } from '../../../../lib/common/utils/base';

interface GlobalStatusSwitchProps extends SwitchProps {
  param_key: string;
}

export const GlobalStatusSwtich: React.FC<GlobalStatusSwitchProps> = ({ param_key, children, ...props }) => {
  const { pathname, search: srch } = location;
  const navigate = useNavigate();
  const { ...paramsprops } = currentParamsObject(false);
  const [isChecked, setIsChecked] = useState(false);

  const onChangeSwitch = () => setIsChecked((prev) => !prev);

  useEffect(() => {
    const param_value = isChecked || '';
    const searchParams = buildSearchParams({
      page: '1',
      pagelist: '1',
      [param_key]: param_value,
    });
    navigate(searchParams);
  }, [isChecked]);

  return (
    <Flex alignItems="center" justifyContent={'space-between'}>
      <FormLabel htmlFor={param_key} mb="0">
        <Box textStyle={'medium12'}>{children}</Box>
      </FormLabel>
      <Switch id={param_key} onChange={onChangeSwitch} />
    </Flex>
  );
};

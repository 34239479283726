import { Flex, Center, Box, Collapse, HStack, Button, FlexProps } from '@chakra-ui/react';
import Dropzone from 'react-dropzone';
import {
  MuteCreateSubscriptionPaymentOfSupportPayment,
  MuteRegisterPreServiceReqeustsFile,
} from '../../../lib/admin/hooks/mutation';
import Thumb from '../../common/Thumb/base';

interface Props extends FlexProps {
  company_id: string;
}

const SupportPaymentExcelUploadButton: React.FC<Props> = ({ company_id, ...props }) => {
  const { mutateAsync: RegisterFile } = MuteRegisterPreServiceReqeustsFile();
  const { mutateAsync: CreateSubscriptionPayment } = MuteCreateSubscriptionPaymentOfSupportPayment();
  return (
    <Dropzone
      // accept={[
      //   '.csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values',
      // ]}
      //   accept={'.csv'}
      accept={'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
      onDrop={async (acceptedFiles) => {
        if (acceptedFiles.length === 0) {
          return;
        } else {
          const formData = new FormData();
          formData.append('pre_service_request_file', acceptedFiles[0]);
          await RegisterFile({
            company_id,
            formData,
          }).then(async () => {
            await CreateSubscriptionPayment({
              company_id,
            });
          });
        }
      }}
    >
      {({ isDragActive, isDragReject, acceptedFiles, getRootProps, getInputProps }) => {
        return (
          <Flex flexDir={'column'} w={'full'} {...props}>
            <Center {...getRootProps()} w={'full'} justifyContent={'start'}>
              <input {...getInputProps()} />
              <Button variant={'basic'}>
                <Box>파일 업로드</Box>
              </Button>
            </Center>
          </Flex>
        );
      }}
    </Dropzone>
  );
};

export default SupportPaymentExcelUploadButton;

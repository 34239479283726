import { Flex, HStack, Text, VStack } from '@chakra-ui/react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import Loading from '../../../../../../../../../../components/common/Loading';
import { useGetCompanyHost } from '../../../../../../../../../../lib/common/hooks/query';
import { currentParamsObject } from '../../../../../../../../../../lib/common/utils/base';

interface ActiveBreadcrumbLinkProps {
  to: string;
}

export const ActiveBreadcrumbLink: React.FC<ActiveBreadcrumbLinkProps> = ({ to, children }) => {
  const { pathname } = useLocation();

  const included = to.includes(pathname);

  return (
    // <RouterLink to={to}>
    <Text as={RouterLink} to={to} fontWeight={included ? 'bold' : 'normal'} mt={included ? '3px' : 0}>
      {children}
    </Text>
    // </RouterLink>
  );
};

const AfterLayout: React.FC = ({ children }) => {
  const { id } = currentParamsObject(false);
  const { data, isFetching, isLoading } = useGetCompanyHost(id || '');

  if (isLoading) return <Loading />;
  return (
    <VStack alignItems={'start'} w={'100%'} h="full">
      <HStack alignItems={'end'} mb={4} id="ckd">
        <Text textStyle={'bold30'} lineHeight={1} mr={4}>
          {data?.data?.user?.user_name}
        </Text>
        <HStack spacing={8} divider={<Text>&nbsp;&nbsp;|&nbsp;&nbsp;</Text>}>
          <ActiveBreadcrumbLink to={`/admin/manage/customers/host/detail/info?id=${id}`}>
            회원 정보
          </ActiveBreadcrumbLink>
          <ActiveBreadcrumbLink to={`/admin/manage/customers/host/detail/contract?id=${id}`}>
            {'계약정보'}
          </ActiveBreadcrumbLink>
          <ActiveBreadcrumbLink to={`/admin/manage/customers/host/detail/estate?id=${id}`}>
            {'매물정보'}
          </ActiveBreadcrumbLink>
          <ActiveBreadcrumbLink to={`/admin/manage/customers/host/detail/lsettlement?id=${id}`}>
            정산내역
          </ActiveBreadcrumbLink>
          <ActiveBreadcrumbLink to={`/admin/manage/customers/host/detail/memo?id=${id}`}>메모</ActiveBreadcrumbLink>
        </HStack>
      </HStack>
      <Flex w={'100%'} h="full">
        {children}
      </Flex>
    </VStack>
  );
};

export default AfterLayout;

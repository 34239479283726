import { Flex, HStack, StackDivider, Table, Tbody, Text, Tr, VStack } from '@chakra-ui/react';
import { Field, useFormikContext } from 'formik';
import { CustomTd } from '../type';
import { FCheckboxButtonGroup } from '../../../../Checkbox/base';
import ValidationWithTouch from '../../../../FormikValidation/ValidationWithTouch';
import { CustomInput } from '../../../../Input';
import { FRadioButtonGroup } from '../../../../Radio';
import { peongToSquared, squaredToPeong } from '../../../../../../lib/common/utils/base';
import { security_facilities_checkbox } from '../../../../../../statics/common/data/checkbox';
import {
  elevator_radio,
  entrance_type_radio,
  floor_type_radio,
  parking_available_radio,
  room_living_type_radio,
} from '../../../../../../statics/common/data/radio';
import {
  room_direction as room_direction_data,
  heating_type as heating_type_data,
} from '../../../../../../statics/common/data/selectbox';
import SelectBox from '../../../../SelectBox/EstatesSelectBox';

const RoomInfoTable = () => {
  const formik = useFormikContext<any>();
  const { values, setFieldValue, errors, touched } = formik;
  const isColiving = values.building_form === 'coliving_house';

  return (
    <>
      <Table w={'100%'} variant="simple">
        <Tbody w={'100%'}>
          {!isColiving && (
            <Tr w={'100%'}>
              <CustomTd title={'단지 및 호수'} ContentTdProps={{ colSpan: 4 }}>
                <HStack w="100%">
                  <Flex w={'110px'} minW={'110px'}>
                    <Field name="dong" as={CustomInput} bgColor="lotionWhite" addon="동" />
                  </Flex>
                  <Flex w={'110px'} minW={'110px'}>
                    <Field name="ho" as={CustomInput} bgColor="lotionWhite" addon="호" />
                  </Flex>
                </HStack>
              </CustomTd>
            </Tr>
          )}
          <Tr w={'100%'}>
            <CustomTd title={'해당 층'} required ContentTdProps={{ colSpan: 4 }}>
              <HStack w="100%">
                <Flex w={'110px'} minW={'110px'}>
                  <Field name="floor_r" as={CustomInput} bgColor="lotionWhite" addon="층" />
                </Flex>
                <Flex w={'auto'}>
                  <Field name="floor_type" as={FRadioButtonGroup} options={floor_type_radio} />
                </Flex>
              </HStack>
            </CustomTd>
          </Tr>
          <Tr w={'100%'}>
            <CustomTd title={'방 거실형태'} required>
              <Flex w={'auto'}>
                <Field name="room_living_type" as={FRadioButtonGroup} options={room_living_type_radio} />
              </Flex>
            </CustomTd>
          </Tr>
          <Tr w={'100%'}>
            <CustomTd title={'방 개수'} required>
              <Flex w={'110px'} minW={'110px'}>
                <Field name="room_num" as={CustomInput} bgColor="lotionWhite" addon="개" />
                {/* <CustomInput type={'number'} min={0} max={3} /> */}
              </Flex>
              <ValidationWithTouch error={errors.room_num as any} touch={touched.room_num as any} />
            </CustomTd>
            <CustomTd title={'화장실 개수'} required>
              <Flex w={'110px'} minW={'110px'}>
                <Field name="toilet_num" as={CustomInput} bgColor="lotionWhite" addon="개" />
              </Flex>
            </CustomTd>
          </Tr>
          <Tr w={'100%'}>
            <CustomTd title={'공급 면적'}>
              <HStack w="100%">
                <Flex w={'110px'} minW={'110px'}>
                  <Field
                    name="supply_area"
                    as={CustomInput}
                    bgColor="lotionWhite"
                    addon="m2"
                    type="number"
                    onChange={(e: any) => {
                      const floored = squaredToPeong(e.target.value);
                      if (e.target.value === '') {
                        setFieldValue('supply_area', '');
                        setFieldValue('supply_area_peong', '');
                      } else {
                        setFieldValue('supply_area', e.target.value);
                        setFieldValue('supply_area_peong', floored);
                      }
                    }}
                  />
                </Flex>
                <Flex w={'110px'} minW={'110px'}>
                  <Field
                    name="supply_area_peong"
                    as={CustomInput}
                    bgColor="lotionWhite"
                    addon="평"
                    type="number"
                    onChange={(e: any) => {
                      const floored = peongToSquared(e.target.value);
                      if (e.target.value === '') {
                        setFieldValue('supply_area', '');
                        setFieldValue('supply_area_peong', '');
                      } else {
                        setFieldValue('supply_area', floored);
                        setFieldValue('supply_area_peong', e.target.value);
                      }
                    }}
                  />
                </Flex>
              </HStack>
            </CustomTd>
            <CustomTd title={'전용 면적'} required>
              <HStack w="100%">
                <Flex w={'110px'} minW={'110px'}>
                  <Field
                    name="dedicated_area"
                    as={CustomInput}
                    bgColor="lotionWhite"
                    addon="m2"
                    onChange={(e: any) => {
                      const floored = squaredToPeong(e.target.value);

                      if (e.target.value === '') {
                        setFieldValue('dedicated_area', '');
                        setFieldValue('dedicated_area_peong', '');
                      } else {
                        setFieldValue('dedicated_area', e.target.value);
                        setFieldValue('dedicated_area_peong', floored);
                      }
                    }}
                  />
                </Flex>
                <Flex w={'110px'} minW={'110px'}>
                  <Field
                    name="dedicated_area_peong"
                    as={CustomInput}
                    bgColor="lotionWhite"
                    addon="평"
                    onChange={(e: any) => {
                      const peong = parseFloat(e.target.value);
                      const squared_meter = peong * 3.305785;
                      const floored = Math.floor(squared_meter * 100) / 100;
                      if (e.target.value === '') {
                        setFieldValue('dedicated_area', '');
                        setFieldValue('dedicated_area_peong', '');
                      } else {
                        setFieldValue('dedicated_area', floored);
                        setFieldValue('dedicated_area_peong', e.target.value);
                      }
                    }}
                  />
                </Flex>
              </HStack>
            </CustomTd>
          </Tr>
          <Tr w={'100%'}>
            <CustomTd title={'주실 방향'}>
              <Field
                name="room_direction"
                as={SelectBox}
                placeholder="선택"
                minW="110px"
                w="110px"
                h="32px"
                bgColor="lotionWhite"
              >
                {room_direction_data.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Field>
            </CustomTd>
            <CustomTd title={'난방 방식'}>
              <Field
                name="heating_type"
                as={SelectBox}
                placeholder="선택"
                minW="110px"
                w="110px"
                h="32px"
                bgColor="lotionWhite"
              >
                {heating_type_data.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Field>
            </CustomTd>
          </Tr>
        </Tbody>
      </Table>
    </>
  );
};

export default RoomInfoTable;

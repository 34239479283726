/* global kakao */
import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { Box, Flex, FormControl, FormLabel, Switch, Text } from '@chakra-ui/react';
import bohomeIcon from '../../statics/common/images/apple-icon.png';
import { SetRoadInfo } from '../../../components/common/Checkbox/SetBooleanCheckBox2';
import { currentParamsObject, ToAllFilledObj } from '../../../lib/common/utils/base';

interface KakaoMapProps {
  coordinates: Array<number>;
}

const { kakao } = window as any;

const KakaoMap: React.FC<KakaoMapProps> = ({ coordinates }) => {
  const [yCoord, xCoord] = coordinates;

  const { set_on_roadview_info, ...paramsprops } = currentParamsObject(false);

  useEffect(() => {
    function addEventHandle(target: any, type: string, callback: EventListenerOrEventListenerObject) {
      if (target.addEventListener) {
        target.addEventListener(type, callback);
      } else {
        target.attachEvent('on' + type, callback);
      }
    }

    function onMouseDown(e: any) {
      console.log(e);
    }

    function MapWalker(this: any, position: any) {
      //커스텀 오버레이에 사용할 map walker 엘리먼트
      const content = document.createElement('div');
      const figure = document.createElement('div');
      const angleBack = document.createElement('div');

      //map walker를 구성하는 각 노드들의 class명을 지정 - style셋팅을 위해 필요
      content.className = 'MapWalker';
      figure.className = 'figure';
      angleBack.className = 'angleBack';

      content.appendChild(angleBack);
      content.appendChild(figure);

      //커스텀 오버레이 객체를 사용하여, map walker 아이콘을 생성
      const walker = new kakao.maps.CustomOverlay({
        position: position,
        content: content,
        yAnchor: 1,
      });

      addEventHandle(figure, 'mousedown', onMouseDown);

      // 커스텀 오버레이에 mousedown 했을 때 호출되는 핸들러 입니다

      this.walker = walker;
      this.content = content;
    }

    //로드뷰의 pan(좌우 각도)값에 따라 map walker의 백그라운드 이미지를 변경 시키는 함수
    //background로 사용할 sprite 이미지에 따라 계산 식은 달라 질 수 있음
    MapWalker.prototype.setAngle = function (angle: number) {
      const threshold = 22.5; //이미지가 변화되어야 되는(각도가 변해야되는) 임계 값
      for (let i = 0; i < 16; i++) {
        //각도에 따라 변화되는 앵글 이미지의 수가 16개
        if (angle > threshold * i && angle < threshold * (i + 1)) {
          //각도(pan)에 따라 아이콘의 class명을 변경
          const className = 'm' + i;
          this.content.className = this.content.className.split(' ')[0];
          this.content.className += ' ' + className;
          break;
        }
      }
    };

    //map walker의 위치를 변경시키는 함수
    MapWalker.prototype.setPosition = function (position: any) {
      this.walker.setPosition(position);
    };

    //map walker를 지도위에 올리는 함수
    MapWalker.prototype.setMap = function (map: any) {
      this.walker.setMap(map);
    };

    /*
     * 아래부터 실제 지도와 로드뷰 map walker를 생성 및 제어하는 로직
     */
    const mapContainer = document.getElementById('map'), // 지도를 표시할 div
      mapCenter = new kakao.maps.LatLng(xCoord, yCoord), // 지도의 가운데 좌표
      mapOption = {
        center: mapCenter, // 지도의 중심좌표
        level: 3, // 지도의 확대 레벨
      };

    // 지도를 표시할 div와  지도 옵션으로  지도를 생성합니다
    const map = new kakao.maps.Map(mapContainer, mapOption);

    // 지도 타입 변경 컨트롤 생성 (일반 지도 <-> 스카이뷰)
    const mapTypeControl = new kakao.maps.MapTypeControl();

    // 지도 타입 컨트롤을 지도에 표시
    map.addControl(mapTypeControl, kakao.maps.ControlPosition.TOPRIGHT);

    // 로드뷰 도로를 지도위에 올린다.
    if (set_on_roadview_info) {
      map.addOverlayMapTypeId(kakao.maps.MapTypeId.ROADVIEW);
    } else {
      map.removeOverlayMapTypeId(kakao.maps.MapTypeId.ROADVIEW);
    }

    const roadviewContainer = document.getElementById('roadview'); // 로드뷰를 표시할 div
    const roadview = new kakao.maps.Roadview(roadviewContainer); // 로드뷰 객체
    const roadviewClient = new kakao.maps.RoadviewClient(); // 좌표로부터 로드뷰 파노ID를 가져올 로드뷰 helper객체

    // 지도의 중심좌표와 가까운 로드뷰의 panoId를 추출하여 로드뷰를 띄운다.
    roadviewClient.getNearestPanoId(mapCenter, 50, function (panoId: any) {
      roadview.setPanoId(panoId, mapCenter); // panoId와 중심좌표를 통해 로드뷰 실행
    });

    let mapWalker: any = null;

    // 로드뷰의 초기화 되었을때 map walker를 생성한다.
    kakao.maps.event.addListener(roadview, 'init', function () {
      // map walker를 생성한다. 생성시 지도의 중심좌표를 넘긴다.
      //   mapWalker = new MapWalker(mapCenter)
      mapWalker = new (MapWalker as any)(mapCenter);
      mapWalker.setMap(map); // map walker를 지도에 설정한다.

      // 로드뷰가 초기화 된 후, 추가 이벤트를 등록한다.
      // 로드뷰를 상,하,좌,우,줌인,줌아웃을 할 경우 발생한다.
      // 로드뷰를 조작할때 발생하는 값을 받아 map walker의 상태를 변경해 준다.
      kakao.maps.event.addListener(roadview, 'viewpoint_changed', function () {
        // 이벤트가 발생할 때마다 로드뷰의 viewpoint값을 읽어, map walker에 반영
        const viewpoint = roadview.getViewpoint();
        mapWalker.setAngle(viewpoint.pan);
      });

      // 로드뷰내의 화살표나 점프를 하였을 경우 발생한다.
      // position값이 바뀔 때마다 map walker의 상태를 변경해 준다.
      kakao.maps.event.addListener(roadview, 'position_changed', function () {
        // 이벤트가 발생할 때마다 로드뷰의 position값을 읽어, map walker에 반영
        const position = roadview.getPosition();
        mapWalker.setPosition(position);
        map.setCenter(position);
      });
    });

    const geocoder = new kakao.maps.services.Geocoder();

    function searchAddrFromCoords(coords: any, callback: any) {
      // 좌표로 행정동 주소 정보를 요청합니다
      geocoder.coord2RegionCode(coords.getLng(), coords.getLat(), callback);
    }

    // 지도 좌측상단에 지도 중심좌표에 대한 주소정보를 표출하는 함수입니다
    function displayCenterInfo(result: any, status: any) {
      if (status === kakao.maps.services.Status.OK) {
        const infoDiv: any = document.getElementById('centerAddr');

        for (let i = 0; i < result.length; i++) {
          // 행정동의 region_type 값은 'H' 이므로
          if (result[i].region_type === 'H') {
            infoDiv.innerHTML = result[i].address_name;
            break;
          }
        }
      }
    }

    // 중심 좌표나 확대 수준이 변경됐을 때 지도 중심 좌표에 대한 주소 정보를 표시하도록 이벤트를 등록합니다
    kakao.maps.event.addListener(map, 'idle', function () {
      searchAddrFromCoords(map.getCenter(), displayCenterInfo);
    });

    // 모두 로드된 후, 이 콜백 함수가 실행됩니다.
    kakao.maps.load(function () {
      console.log('load map');
      searchAddrFromCoords(map.getCenter(), displayCenterInfo);
    });
  }, [set_on_roadview_info]);

  return (
    <Box>
      <Box className={cn('Map')} w="full" h="40vh" pos={'relative'}>
        <Box
          className="hAddr"
          pos={'absolute'}
          top={'10px'}
          left={'10px'}
          zIndex={2}
          background={'rgba(255,255,255,0.8)'}
          p={'2px'}
        >
          <Box className="title" textStyle={'bold12'}>
            지도중심기준 행정동 주소정보
          </Box>
          <Box id="centerAddr" fontSize={'12px'} fontWeight={'medium'}></Box>
          <Box w="full" borderBottom={'1px solid'} borderBottomColor={'gray.400'} my={1} />
          <Box>
            <SetRoadInfo />
          </Box>
        </Box>
        <Box className={cn('MapContainer')} id="map" w={'full'} h={'full'}></Box>
      </Box>
      <Box id="roadview" w="full" h="30vh" />
    </Box>
  );
};

export default KakaoMap;

import { Box, Collapse, Flex, Grid, GridItem, Text, useDisclosure } from '@chakra-ui/react';
import { GTitleItem } from '../../../../../components/common/GridItem';
import { useGetCompanyInfomation } from '../../../../../lib/subscriber/hooks/query';
import { useUserTokenVerify } from '../../../../../lib/common/hooks/query';
import { RightArrowIcon } from '../../../../../statics/common/icons';
import Rejected from './Rejected';
import CompanySection from './CompanyInfo';
import PlanInfo from '../../../../../layouts/subscriber/SubscriptionPlanInfo';
import PlanIntro from './PlanIntro';

/* .../company/manage/subscription/ */

const SubscriptionMainPage = () => {
  const { isOpen: isStatusOpen, onToggle: onStatusToggle } = useDisclosure();
  const { isOpen: isPlanOpen, onToggle: onPlanToggle } = useDisclosure();

  // useEffect(() => {
  //   onStatusToggle();
  //   onPlanToggle();
  // }, []);

  const { data: userData } = useUserTokenVerify();

  const { data: cpInfo } = useGetCompanyInfomation();
  console.log('cpInfo => ', cpInfo);

  if (cpInfo === undefined || userData === undefined) {
    return null;
  }
  const {
    _id,
    address,
    business_certificate,
    business_item,
    business_registration_number,
    business_status,
    business_type,
    capital,
    company_code,
    company_type,
    createdAt,
    detail_address,
    digital_tax_invoice_email,
    employee_ids,
    employee_number,
    establishment_date,
    is_activated,
    limit_manager,
    location: { coordinates, type },
    logo_url,
    name,
    representative_number,
    road_address,
    subscription_contract_ids,
    term_and_conditions,
    updatedAt,
  } = cpInfo;
  return (
    <Grid
      // maxH="100%"
      // templateRows="repeat(1, 1fr)"
      // templateColumns="repeat(4, 1fr)"
      // h="full"
      gridTemplateRows={'300px auto'}
      gridTemplateColumns={'300px auto'}
      gap={6}
    >
      {/* 회사 관련 정보 섹션 */}
      <CompanySection />
      {userData.is_approved === false ? (
        <Rejected />
      ) : (
        <GridItem>
          {/* 플랜 정보 */}
          <GTitleItem
            upperSection={
              <Flex justifyContent={'space-between'} alignItems={'center'} cursor={'pointer'} onClick={onStatusToggle}>
                <Text fontSize={'18px'} fontWeight={'medium'}>
                  플랜 정보
                </Text>
                <RightArrowIcon
                  w={6}
                  h={6}
                  transform={`rotate(${!isStatusOpen ? 270 : 90}deg)`}
                  transition={'transform 0.15s linear'}
                />
              </Flex>
            }
            display={'block'}
            mb={6}
            minW={'max'}
          >
            <Collapse in={!isStatusOpen} style={{ width: '100%', marginTop: 'unset' }} endingHeight={'230px'}>
              <PlanInfo />
            </Collapse>
          </GTitleItem>
          {/* 보홈 플랜 안내 */}
          <GTitleItem
            upperSection={
              <Flex justifyContent={'space-between'} alignItems={'center'} cursor={'pointer'} onClick={onPlanToggle}>
                <Text fontSize={'18px'} fontWeight={'medium'}>
                  보홈 플랜 안내
                </Text>
                <RightArrowIcon
                  w={6}
                  h={6}
                  transform={`rotate(${!isPlanOpen ? 270 : 90}deg)`}
                  transition={'transform 0.15s linear'}
                />
              </Flex>
            }
            height={!isPlanOpen ? '512px' : '59px'}
          >
            <Collapse in={!isPlanOpen} style={{ width: '100%', marginTop: 'unset' }}>
              <Flex w="full" h="full" px={4} py={4}>
                <Flex w="full" h="full">
                  <PlanIntro />
                </Flex>
              </Flex>
            </Collapse>
          </GTitleItem>
        </GridItem>
      )}
    </Grid>
  );
};

export default SubscriptionMainPage;

import {
  extendTheme,
  ThemeComponentProps,
  ThemeConfig,
  Theme,
  BoxProps,
  LinkProps,
  ButtonProps,
  TextProps,
  TagProps,
} from '@chakra-ui/react';
import { mode, cssVar, PartsStyleObject, StyleConfig } from '@chakra-ui/theme-tools';
import { modalAnatomy as parts } from '@chakra-ui/anatomy';
// https://github.com/chakra-ui/chakra-ui/tree/main/packages/theme/src/components
// import {} from '@chakra-ui/styled-system'

const $popperBg = cssVar('popper-bg');

const $arrowBg = cssVar('popper-arrow-bg');
const $arrowShadowColor = cssVar('popper-arrow-shadow-color');

const $tooltipbg = cssVar('tooltip-bg');

const styles = {
  global: (props: ThemeComponentProps) => ({
    body: {
      bg: mode('mainBgColor', 'mainBgColor')(props),
      // minH: '100%',
      color: 'highGray',
      wordBreak: 'keep-all',
      boxSizing: 'border-box',
    },
    '.ql-toolbar': {
      borderTopRadius: '5px',
    } as BoxProps,
    '.ql-container': {
      borderBottomRadius: '5px',
    } as BoxProps,
    '.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="12px"]::before': {
      content: '"12px"',
      fontSize: '12px !important',
    },
    '.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before': {
      content: '"14px"',
      fontSize: '14px !important',
    },
    '.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before': {
      content: '"16px"',
      fontSize: '16px !important',
    },
    '.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before': {
      content: '"18px"',
      fontSize: '18px !important',
    },
    '.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="20px"]::before': {
      content: '"20px"',
      fontSize: '20px !important',
    },
    '.room-detail-pictures-slider': {
      width: '100%',
      height: '100%',
    },
    '.slick-track': {
      display: 'flex',
      alignItems: 'center',
    },
    '.custom-scrollbar-v-container': {
      msOverflowStyle: 'none',
      scrollbarWidth: 'none',
    },
    '.slick-prev:before': {
      content: '"←"',
      color: 'gray',
    },
    '.slick-next:before': {
      content: '"→"',
      color: 'gray',
    },
    '.custom-scrollbar-v-container::-webkit-scrollbar': {
      display: 'none',
    },
    //
    '.map_wrap': { overflow: 'hidden', height: '330px' },
    /* 지도위에 로드뷰의 위치와 각도를 표시하기 위한 map walker 아이콘의 스타일 */
    '.MapWalker': { position: 'absolute', margin: '-26px 0 0 -51px' },
    '.MapWalker .figure': {
      position: 'absolute',
      width: '25px',
      left: '38px',
      top: '-2px',
      height: '39px',
      background:
        'url(https://t1.daumcdn.net/localimg/localimages/07/2018/pc/roadview_minimap_wk_2018.png) -298px -114px no-repeat',
    },
    '.MapWalker .angleBack': {
      width: '102px',
      height: '52px',
      background:
        'url(https://t1.daumcdn.net/localimg/localimages/07/2018/pc/roadview_minimap_wk_2018.png) -834px -2px no-repeat',
    },
    '.MapWalker.m0 .figure': { backgroundPosition: '-298px -114px' },
    '.MapWalker.m1 .figure': { backgroundPosition: '-335px -114px' },
    '.MapWalker.m2 .figure': { backgroundPosition: '-372px -114px' },
    '.MapWalker.m3 .figure': { backgroundPosition: '-409px -114px' },
    '.MapWalker.m4 .figure': { backgroundPosition: '-446px -114px' },
    '.MapWalker.m5 .figure': { backgroundPosition: '-483px -114px' },
    '.MapWalker.m6 .figure': { backgroundPosition: '-520px -114px' },
    '.MapWalker.m7 .figure': { backgroundPosition: '-557px -114px' },
    '.MapWalker.m8 .figure': { backgroundPosition: '-2px -114px' },
    '.MapWalker.m9 .figure': { backgroundPosition: '-39px -114px' },
    '.MapWalker.m10 .figure': { backgroundPosition: '-76px -114px' },
    '.MapWalker.m11 .figure': { backgroundPosition: '-113px -114px' },
    '.MapWalker.m12 .figure': { backgroundPosition: '-150px -114px' },
    '.MapWalker.m13 .figure': { backgroundPosition: '-187px -114px' },
    '.MapWalker.m14 .figure': { backgroundPosition: '-224px -114px' },
    '.MapWalker.m15 .figure': { backgroundPosition: '-261px -114px' },
    '.MapWalker.m0 .angleBack': { backgroundPosition: '-834px -2px' },
    '.MapWalker.m1 .angleBack': { backgroundPosition: '-938px -2px' },
    '.MapWalker.m2 .angleBack': { backgroundPosition: '-1042px -2px' },
    '.MapWalker.m3 .angleBack': { backgroundPosition: '-1146px -2px' },
    '.MapWalker.m4 .angleBack': { backgroundPosition: '-1250px -2px' },
    '.MapWalker.m5 .angleBack': { backgroundPosition: '-1354px -2px' },
    '.MapWalker.m6 .angleBack': { backgroundPosition: '-1458px -2px' },
    '.MapWalker.m7 .angleBack': { backgroundPosition: '-1562px -2px' },
    '.MapWalker.m8 .angleBack': { backgroundPosition: '-2px -2px' },
    '.MapWalker.m9 .angleBack': { backgroundPosition: '-106px -2px' },
    '.MapWalker.m10 .angleBack': { backgroundPosition: '-210px -2px' },
    '.MapWalker.m11 .angleBack': { backgroundPosition: '-314px -2px' },
    '.MapWalker.m12 .angleBack': { backgroundPosition: '-418px -2px' },
    '.MapWalker.m13 .angleBack': { backgroundPosition: '-522px -2px' },
    '.MapWalker.m14 .angleBack': { backgroundPosition: '-626px -2px' },
    '.MapWalker.m15 .angleBack': { backgroundPosition: '-730px -2px' },
    //
  }),
};

function getSize(value: string): PartsStyleObject<typeof parts> {
  if (value === 'full') {
    return {
      dialog: {
        maxW: '100vw',
        minH: '100vh',
        '@supports(min-height: -webkit-fill-available)': {
          minH: '-webkit-fill-available',
        },
        my: 0,
      },
    };
  }
  return {
    dialog: { maxW: value },
  };
}

const colors = {
  zetaBlue: '#183377',
  coolBlue: '#8B99BB',
  cottonBlue: '#BAC2D6',
  fragileBlue: '#C4CFDB',
  basicBlue: '#EBF0F6',
  mountainGreen: '#29CC97',
  primarySkyblue: '#ECF1FF',
  brightYellow: '#FDAA2E',
  highGray: '#414141',
  midGray: '#A7A7A7',
  gainGray: '#DDDDDD',
  borderGray: '#E5E5E5',
  whiteGray: '#F0F0F0',
  silverGray: '#C4C4C4',
  borderWhite: '#A0A0A0',
  lotionWhite: '#FBFBFB',
  pinkRed: '#FFE6E6',
  primaryRed: '#FB4667',
  mainBgColor: '#F8F8F8',
  basicPlan: '#29CC97',
  advancedPlan: '#C4C4C4',
  enterprisePlan: '#C4C4C4',
};

const components = {
  Drawer: {
    variants: {
      alert: {
        overlay: {
          bg: 'blackAlpha.300',
        },
        closeButton: {
          position: 'absolute',
          top: 4,
          insetEnd: 3,
        },
      },
      zIndex2000: {
        dialog: {
          zIndex: 2000,
        },
        dialogContainer: {
          zIndex: 2000,
        },
      },
    },
    sizes: {
      '50vw': {
        dialog: {
          width: '50vw',
        },
        dialogContainer: {
          width: '50vw',
        },
      },
    },
  } as StyleConfig,
  Heading: {
    variants: {
      'section-title': {
        textDecoration: 'underline',
        fontSize: 20,
        textUnderlineOffset: 6,
        textDecorationColor: '#525252',
        textDecorationThickness: 4,
        marginTop: 3,
        marginBottom: 4,
      },
    },
  },
  Divider: {
    defaultProps: { size: 'sm' },
    sizes: {
      lg: { borderWidth: '4px' },
      md: { borderWidth: '2px' },
      sm: { borderWidth: '1px' },
    },
  },
  Button: {
    variants: {
      solid: {
        transition: '0.15s linear',
        _focus: {
          outline: '2px solid',
          outlineColor: 'zetaBlue',
          borderColor: 'zetaBlue',
          outlineOffset: '-2px',
          boxShadow: 'unset',
        },
        _active: { outline: {}, border: {} },
      },
      basic: {
        color: 'white',
        bg: 'zetaBlue',
        fontSize: '14px',
        height: '34px',
        border: '1px solid',
        borderColor: 'zetaBlue',
        lineHeight: 0,
        _hover: {
          color: 'white',
          bgColor: 'rgb(45, 122, 196)',
          borderColor: 'rgb(45, 122, 196)',
          _disabled: {
            bg: 'silverGray',
            borderColor: 'silverGray',
          },
        },
        _disabled: {
          bg: 'silverGray',
          borderColor: 'silverGray',
          opacity: 1,
        },
      },
      red: {
        color: 'white',
        bg: 'primaryRed',
        fontSize: '14px',
        height: '34px',
        border: '1px solid',
        borderColor: 'primaryRed',
        lineHeight: 0,
        _hover: {
          color: 'white',
          bgColor: 'red.300',
          borderColor: 'red.300',
          _disabled: {
            bg: 'silverGray',
            borderColor: 'silverGray',
          },
        },
        _disabled: {
          bg: 'silverGray',
          borderColor: 'silverGray',
          opacity: 1,
        },
      },
      blue_hover_silver: {
        color: 'white',
        bg: 'zetaBlue',
        fontSize: '14px',
        border: '1px solid',
        borderColor: 'zetaBlue',
        lineHeight: 0,
        _hover: {
          color: 'white',
          bgColor: 'silverGray',
          borderColor: 'silverGray',
          _disabled: {
            bg: 'silverGray',
            borderColor: 'silverGray',
          },
        },
        _disabled: {
          bg: 'silverGray',
          borderColor: 'silverGray',
          opacity: 1,
        },
      },
      pagination_number_button: (props: ThemeComponentProps) => {
        return {
          mx: 1,
          px: 4,
          py: 2,
          rounded: 'md',
          // 다른 페이지
          _active: {
            bg: 'gray.300',
            color: 'blue.50',
            _hover: {
              color: 'white',
              bg: 'rgba(203,213,224,0.6)',
            },
            _disabled: {
              opacity: 1,
              background: 'zetaBlue',
            },
          },
          // 현재 페이지
          _disabled: {
            bg: 'zetaBlue',
            color: 'white',
            opacity: 1,
            cursor: 'unset',
            _hover: {
              cursor: 'unset',
              opacity: 1,
              background: 'zetaBlue',
            },
          },
          _hover: {
            _disabled: {
              cursor: 'unset',
              opacity: 1,
              background: 'zetaBlue',
            },
          },
        } as ButtonProps;
      },
      pagination_arrow_button: (props: ThemeComponentProps) => {
        return {
          mx: 1,
          px: 4,
          py: 2,
          rounded: 'md',
          // 다른 페이지
          _active: {
            bg: 'gray.300',
            color: 'white',
            _hover: {
              color: 'white',
              bg: 'rgba(203,213,224,0.6)',
            },
            _disabled: {
              opacity: 1,
              background: 'gray.100',
            },
          },
          // 현재 페이지
          _disabled: {
            bg: 'gray.100',
            color: 'white',
            opacity: 1,
            cursor: 'unset',
            _hover: {
              cursor: 'unset',
              opacity: 1,
              background: 'gray.100',
            },
          },
          _hover: {
            _disabled: {
              cursor: 'unset',
              opacity: 1,
              background: 'gray.100',
            },
          },
        } as ButtonProps;
      },
      warning: {
        color: 'white',
        bg: 'silverGray',
        fontSize: '14px',
        height: '34px',
        border: '1px solid',
        borderColor: 'silverGray',
        lineHeight: 0,
        _hover: {
          color: 'white',
          bgColor: 'primaryRed',
          borderColor: 'primaryRed',
          _disabled: {
            bg: 'silverGray',
            borderColor: 'silverGray',
          },
        },
        _disabled: {
          bg: 'silverGray',
          borderColor: 'silverGray',
          opacity: 1,
        },
      },
      gray: {
        color: 'white',
        bg: 'silverGray',
        fontSize: '14px',
        height: '34px',
        border: '1px solid',
        borderColor: 'silverGray',
        lineHeight: 0,
        _hover: {
          color: 'white',
          bgColor: 'rgb(45, 122, 196)',
          borderColor: 'rgb(45, 122, 196)',
          _disabled: {
            bg: 'silverGray',
            borderColor: 'silverGray',
          },
        },
        _disabled: {
          bg: 'silverGray',
          borderColor: 'silverGray',
          opacity: 1,
        },
      },
      gray_hoverGray: {
        color: 'white',
        bg: 'silverGray',
        fontSize: '16px',
        height: '40px',
        border: '1px solid',
        borderColor: 'silverGray',
        lineHeight: 0,
        _hover: {
          color: 'white',
          bgColor: 'gray.300',
          borderColor: 'gray.300',
          _disabled: {
            bg: 'silverGray',
            borderColor: 'silverGray',
          },
        },
        _disabled: {
          bg: 'silverGray',
          borderColor: 'silverGray',
          opacity: 1,
        },
      },
      approve: {
        color: 'white',
        bg: 'zetaBlue',
        fontSize: '14px',
        height: '34px',
        border: '1px solid',
        borderColor: 'zetaBlue',
        lineHeight: 0,
        _hover: {
          opacity: 0.8,
          _disabled: {
            bg: 'primaryRed',
            borderColor: 'primaryRed',
          },
        },
        _disabled: {
          bg: 'silverGray',
          borderColor: 'silverGray',
          opacity: 1,
        },
      },
      approve_bordering: {
        color: 'zetaBlue',
        bg: 'white',
        fontSize: '14px',
        fontWeight: 'medium',
        height: '34px',
        border: '1px solid',
        borderWidth: '1px',
        borderColor: 'zetaBlue',
        borderRadius: 'md',
        lineHeight: 0,
        _hover: {
          opacity: 0.8,
          _disabled: {
            bg: 'zetaBlue',
            borderColor: 'zetaBlue',
          },
        },
        _disabled: {
          bg: 'silverGray',
          borderColor: 'silverGray',
          opacity: 1,
        },
      },
      reject: {
        color: 'white',
        bg: 'primaryRed',
        fontSize: '14px',
        height: '34px',
        border: '1px solid',
        borderColor: 'primaryRed',
        lineHeight: 0,
        _hover: {
          opacity: 0.8,
          bg: 'primaryRed',
          borderColor: 'primaryRed',
          _disabled: {
            opacity: 1,
            bg: 'silverGray',
            borderColor: 'silverGray',
          },
        },
        _disabled: {
          bg: 'silverGray',
          borderColor: 'silverGray',
          opacity: 1,
        },
      },
      reject_bordering: {
        color: 'primaryRed',
        bg: 'white',
        fontSize: '14px',
        fontWeight: 'medium',
        height: '34px',
        border: '1px solid',
        borderWidth: '1px',
        borderColor: 'primaryRed',
        borderRadius: 'md',
        lineHeight: 0,
        _hover: {
          opacity: 0.8,
          _disabled: {
            bg: 'primaryRed',
            borderColor: 'primaryRed',
          },
        },
        _disabled: {
          bg: 'silverGray',
          borderColor: 'silverGray',
          opacity: 1,
        },
      },
      green: {
        color: 'white',
        bgColor: '#29CC97',
        _hover: {
          opacity: 0.6,
        },
      },
      grayBorder_whiteBg_zetaBlue: {
        w: '120px',
        border: '1px solid #c4c4c4',
        borderRadius: '2px',
        bgColor: 'white',
        fontSize: '16px',
        color: 'zetaBlue',
        fontWeight: 'medium',
        _hover: {
          opacity: 0.8,
        },
      },
      grayBorder_whiteBg_primaryRed: {
        w: '120px',
        border: '1px solid',
        borderColor: 'primaryRed',
        borderRadius: '2px',
        bgColor: 'primaryRed',
        fontSize: '16px',
        color: 'zetaBlue',
        fontWeight: 'medium',
        _hover: {
          opacity: 0.8,
        },
      },
      gray_black_font: {
        color: 'highGray',
        bg: '#EEEEEE',
        border: 'unset',
        _hover: {
          color: 'white',
          bgColor: 'rgb(45, 122, 196)',
          borderColor: 'rgb(45, 122, 196)',
        },
      },
      white: {
        color: 'zetaBlue',
        bg: 'white',
        fontSize: '14px',
        fontWeight: 400,
        transition: '0.2s',
        _hover: {
          borderColor: 'fragileBlue',
        },
      },
      blackAlphaIconContainer: {
        boxSize: '20px',
        width: '20px',
        minW: '20px',
        bgColor: 'blackAlpha.700',
        p: 0,
        borderRadius: 'full',
      },
      whiteBorder: {
        color: 'zetaBlue',
        bg: 'white',
        fontSize: '14px',
        fontWeight: 400,
        transition: '0.2s',
        border: '1px solid',
        borderColor: 'zetaBlue',
        _hover: {
          borderColor: 'fragileBlue',
        },
      },
      grayBorder_blackFont: {
        color: 'highGray',
        bg: 'white',
        fontSize: '14px',
        fontWeight: 400,
        transition: '0.2s',
        border: '1px solid',
        borderRadius: '0.25rem',
        borderColor: 'silverGray',
        _hover: {
          borderColor: 'fragileBlue',
        },
        lineHeight: 0,
        filter: 'drop-shadow(0 2px 2px #00000003)',
      },
      text: {
        color: 'highGray',
        bg: 'unset',
        fontSize: '14px',
        fontWeight: 400,
        transition: '0.2s',
        border: 'unset',
        _hover: {
          boxShadow: 'unset',
        },
        _focus: {
          boxShadow: 'unset',
        },
        _active: {
          boxShadow: 'unset',
        },
        lineHeight: 0,
      },
      lotionWhite: {
        color: 'highGray',
        bg: 'lotionWhite',
        fontSize: '14px',
        fontWeight: 400,
        transition: '0.2s',
        border: '1px solid',
        borderRadius: '0.25rem',
        borderColor: 'silverGray',
        _hover: {
          borderColor: 'fragileBlue',
        },
        lineHeight: 0,
        filter: 'drop-shadow(0 2px 2px #00000003)',
      },
      tab: {
        borderTop: '1px',
        borderColor: 'gray.200',
        borderRadius: 'unset',
        _hover: { bgColor: 'mainBgColor' },
        transition: '0.1s linear',
        bgColor: 'white',
        w: 'full',
        // h: '60px',
        px: 5,
        py: 7,
        _focus: { boxShadow: 'none' },
        _active: {},
        _last: {
          borderBottomWidth: '1px',
        },
      },
      checked: {
        color: 'zetaBlue',
        bg: 'white',
        h: '36px',
        fontSize: '14px',
        fontWeight: 400,
        transition: '0.2s',
        border: '1px solid',
        outline: '1px solid #183377',
        borderColor: 'zetaBlue',
        // _hover: {
        //   borderColor: 'blue.500',
        // },
        _expanded: { bgColor: 'zetaBlue', color: 'white' },
      },
      unChecked: {
        color: 'highGray',
        bg: 'white',
        h: '36px',
        fontSize: '14px',
        fontWeight: 400,
        transition: '0.2s',
        border: '1px solid',
        borderColor: 'silverGray',
        // _hover: {
        //   borderColor: 'blue.500',
        // },
        _expanded: { bgColor: 'zetaBlue', color: 'white' },
      },
      filter: {
        color: 'borderWhite',
        border: '1px solid ',
        borderColor: 'silverGray',
        bg: 'white',
        fontSize: '14px',
        fontWeight: 400,
        transition: '0.2s',
        _hover: {
          borderColor: 'fragileBlue',
          _disabled: {
            bg: 'white',
            borderColor: 'silverGray',
            opacity: 1,
          },
        },
        _disabled: {
          bg: 'white',
          borderColor: 'silverGray',
          opacity: 1,
        },
      },
      inactive: {
        color: 'silverGray',
        border: '1px solid ',
        borderColor: 'silverGray',
        borderRadius: 'unset',
      },
    },
  },
  Link: {
    baseStyle: (props: ThemeComponentProps) =>
      ({
        color: mode('#414141', '#414141')(props),
        textDecor: 'underline',
        textUnderlineOffset: 2,
        fontSize: '14px',
        _hover: {
          // textDecoration: 'unset',
        },
      } as LinkProps),
    variants: {
      clear: (props: ThemeComponentProps) =>
        ({
          color: mode('#414141', '#414141')(props),
          textDecor: 'none',
          textUnderlineOffset: 0,
          fontSize: '16px',
          _hover: {
            // textDecoration: 'unset',
          },
        } as LinkProps),
    },
  },
  Accordion: {
    baseStyle: (props: ThemeComponentProps) => ({
      container: {
        borderWidth: '0px',
        borderBottomColor: 'gray.200',
      },
      button: {
        _focus: {
          boxShadow: 'unset',
        },
      },
    }),
    variants: {
      bordered: {
        container: {
          borderBottomWidth: '1px',
          borderBottomColor: 'gray.200',
        },
        button: {
          _expanded: {
            bg: 'zetaBlue',
            color: 'white',
          },
        },
      },
    },
  },
  Popover: {
    baseStyle: {
      content: {
        bg: 'white',
        borderWidth: '0px',
        borderColor: 'white',
        [$arrowBg.variable]: 'white',
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
      },
      // header: {
      //   bg: 'white',
      //   borderWidth: '0px',
      //   borderColor: 'white',
      // },
      // body: {
      //   bg: 'white',
      //   borderWidth: '0px',
      //   borderColor: 'white',
      // },
      // footer: {
      //   bg: 'white',
      //   borderWidth: '0px',
      //   borderColor: 'white',
      // },
    },
  },
  Slider: {
    baseStyle: {
      track: {
        bg: 'gray.200',
        filledTrack: 'zetaBlue',
      },
      filledTrack: {
        bg: 'zetaBlue',
      },
      thumb: {
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
      },
    },
  },
  Alert: {
    variants: {
      error: {
        container: {
          bg: 'red.500',
          color: 'white',
        },
        icon: {
          color: 'white',
        },
      },
      warning: {
        container: {
          bg: 'orange.500',
          color: 'white',
        },
        icon: {
          color: 'white',
        },
      },
      success: {
        container: {
          bg: 'green.500',
          color: 'white',
        },
        icon: {
          color: 'white',
        },
      },
    },
  },
  Checkbox: {
    baseStyle: {
      control: {
        bg: 'white',
        borderWidth: '1px',
        borderColor: 'silverGray',
        _checked: {
          bg: 'zetaBlue',
          borderColor: 'zetaBlue',
          _hover: {
            bg: 'zetaBlue',
            border: 'unset',
          },
        },
        _indeterminate: {
          bg: 'zetaBlue',
        },
        _invalid: { borderColor: '#E53E3E', boxShadow: '0 0 0 1px #e53e3e' },
      },
      icon: {
        color: 'white',
      },
    },
    variants: {
      basic: {
        label: {
          width: '100%',
        },
      },
      small: {
        label: {
          width: '100%',
          fontSize: '12px',
        },
      },
    },
    sizes: {
      smd: {
        control: { h: '14px', w: '14px' },
        label: { fontSize: '12px' },
        icon: { fontSize: '0.60rem' },
      },
      slg: {
        control: { h: '20px', w: '20px' },
        label: { fontSize: '12px' },
        icon: { fontSize: '0.60rem' },
      },
      lg: {
        control: { h: '24px', w: '24px' },
        label: { fontSize: '12px' },
        icon: { fontSize: '0.75rem' },
      },
      xl: {
        control: { h: '30px', w: '30px' },
        label: { fontSize: '12px' },
        icon: { fontSize: '0.875rem' },
      },
      smd14: {
        control: { h: '17px', w: '17px' },
        label: { fontSize: '14px', minW: 'max' },
        icon: { fontSize: '0.60rem' },
      },
      slg14: {
        control: { h: '22px', w: '22px' },
        label: { fontSize: '14px' },
        icon: { fontSize: '0.60rem' },
      },
      lg14: {
        control: { h: '27px', w: '27px' },
        label: { fontSize: '14px' },
        icon: { fontSize: '0.75rem' },
      },
      xl14: {
        control: { h: '32px', w: '32px' },
        label: { fontSize: '14px' },
        icon: { fontSize: '0.875rem' },
      },
    },
  },
  Radio: {
    baseStyle: {
      label: {
        fontSize: '14px',
      },
    },
    sizes: {
      smd: {
        control: { w: '4', h: '4' },
        label: { fontSize: '14px' },
      },
    },
    // variants: {
    //   base: {
    //     label: {
    //       wordWrap: 'unset',
    //     },
    //   },
    // },
  },
  Modal: {
    baseStyle: () => ({
      dialog: {
        bg: 'white',
        zIndex: 1700,
      },
      overlay: { zIndex: 1700 },
      dialogContainer: { zIndex: 1700 },
    }),
    sizes: {
      '7xl': getSize('7xl'),
      '8xl': getSize('8xl'),
      '9xl': getSize('9xl'),
    },
  },
  Table: {
    // baseStyle: () => ({
    //   table: {
    //     bg: 'red',
    //   },
    //   th: {
    //     bg: 'red',
    //   },
    //   td: {
    //     bg: 'red',
    //   },
    // }),
    variants: {
      simple: {
        tbody: {
          tr: {
            td: {
              borderTopWidth: '1px',
              borderTopColor: '1px solid rgb(235,240,246)',
              borderBottomWidth: '1px',
              borderBottomColor: '1px solid rgb(235,240,246)',
              // borderTop: '1px solid',
              // borderTopColor: 'gray.400',
              // borderBottom: '1px solid',
              // borderBottomColor: 'gray.400',
            },
            '&:nth-of-type(1)': {
              td: {
                borderTopColor: 'gray.400',
              },
            },
          },
        },
      },
      simple_lining: {
        tbody: {
          tr: {
            td: {
              borderTopWidth: '1px',
              borderTopColor: 'gray.200',
              borderBottomWidth: '1px',
              borderBottomColor: 'gray.200',
            },
            '&:nth-of-type(1)': {
              td: {
                borderTopWidth: '0px',
              },
            },
          },
        },
      },
      topline: {
        tbody: {
          tr: {
            td: {
              borderTopWidth: '1px',
              borderTopColor: 'gray.200',
              borderBottomWidth: '1px',
              borderBottomColor: 'gray.200',
            },
            // '&:nth-of-type(1)': {
            //   td: {
            //     borderWidth: '1px',
            //     borderTopColor: 'gray',
            //   },
            // },
          },
          '&:nth-of-type(1)': {
            tr: {
              borderWidth: '1px',
              borderTopColor: 'gray',
            },
          },
        },
      },
      simple_same: {
        tbody: {
          tr: {
            td: {
              borderTopWidth: '1px',
              borderTopColor: 'gray.200',
              borderBottomWidth: '1px',
              borderBottomColor: 'gray.200',
            },
          },
        },
      },
      head_silver: {
        thead: {
          tr: {
            td: {
              borderWidth: '1px',
              borderColor: 'gray.200',
            },
            borderWidth: '1px',
            borderColor: 'gray.200',
          },
          th: {
            borderRight: '1px solid rgb(235,240,246)',
            borderLeft: '1px solid rgb(235,240,246)',
            fontSize: '14px',
            fontWeight: 'medium',
            wordBreak: 'keep-all',
            textAlign: 'center',
          },
          borderWidth: '1px',
          borderColor: 'gray.200',
        },
        tbody: {
          tr: {
            td: {
              borderRight: '1px solid rgb(235,240,246)',
              borderLeft: '1px solid rgb(235,240,246)',
              fontSize: '14px',
              wordBreak: 'keep-all',
              textAlign: 'center',
              px: 3,
              py: 2,
            },
            borderWidth: '1px',
            borderColor: 'gray.200',
          },
        },
      },
      head_silverbg_toplining: {
        thead: {
          position: 'sticky',
          top: 0,
          zIndex: 1,
          // position: 'relative',
          tr: {
            th: {
              // borderWidth: '1px',
              // borderTopWidth: '0px',
              // borderColor: 'gray.200',
              border: '1px solid rgb(235,240,246)',
              '&:first-of-type': {
                borderLeftWidth: '0px',
              },
              '&:last-of-type': {
                borderRightWidth: '0px',
              },
              fontSize: '14px',
              fontWeight: 'medium',
              wordBreak: 'keep-all',
              textAlign: 'center',
              whiteSpace: 'nowrap',
            },
          },
          bgColor: '#F8F8F8',
          _before: {
            content: '""',
            position: 'absolute',
            height: '1px',
            backgroundColor: 'gray.400',
            width: 'calc(100%)',
            top: '0px',
            left: '0px',
            // width: 'calc(100% + 3.5px)',
            // top: '-1px',
            // left: '-2px',
          },
          borderColor: 'gray.200',
          // borderWidth: '1px',
          borderTopWidth: '0px',
        },
        tbody: {
          tr: {
            td: {
              // borderRight: '1px solid rgb(235,240,246)',
              // borderLeft: '1px solid rgb(235,240,246)',
              fontSize: '14px',
              wordBreak: 'keep-all',
              textAlign: 'center',
              px: 1,
              py: 1,
              border: '1px solid rgb(235,240,246)',
              '&:first-of-type': {
                borderLeftWidth: '0px',
              },
              '&:last-of-type': {
                borderRightWidth: '0px',
              },
            },
            borderWidth: '1px',
            borderInlineWidth: '0px',
            borderColor: 'gray.200',
          },
        },
      },
      all_line_silver: {
        thead: {
          tr: {
            td: {
              borderWidth: '1px',
              borderColor: 'gray.200',
            },
            borderWidth: '1px',
            borderColor: 'gray.200',
          },
          th: {
            borderWidth: '1px',
            borderColor: 'gray.200',
            wordBreak: 'keep-all',
            textAlign: 'center',
            fontWeight: 500,
          },
          borderWidth: '1px',
          borderColor: 'gray.200',
        },
        tbody: {
          tr: {
            td: {
              borderWidth: '1px',
              borderColor: 'gray.200',
              fontWeight: 400,
            },
            borderWidth: '1px',
            borderColor: 'gray.200',
          },
          // '&:nth-of-type(1)': {
          //   tr: {
          //     borderWidth: '1px',
          //     borderTopColor: 'gray',
          //   },
          // },
        },
      },
      all_line_silver_12px: {
        thead: {
          tr: {
            td: {
              borderWidth: '1px',
              borderColor: 'gray.200',
              fontSize: '12px',
              p: 2,
            },
            borderWidth: '1px',
            borderColor: 'gray.200',
            fontSize: '12px',
            p: 2,
          },
          th: {
            borderWidth: '1px',
            borderColor: 'gray.200',
            wordBreak: 'keep-all',
            textAlign: 'center',
            fontWeight: 500,
            fontSize: '12px',
            p: 2,
          },
          borderWidth: '1px',
          borderColor: 'gray.200',
        },
        tbody: {
          tr: {
            td: {
              borderWidth: '1px',
              borderColor: 'gray.200',
              fontWeight: 400,
              fontSize: '12px',
              p: 2,
            },
            borderWidth: '1px',
            borderColor: 'gray.200',
            fontSize: '12px',
            p: 2,
          },
          // '&:nth-of-type(1)': {
          //   tr: {
          //     borderWidth: '1px',
          //     borderTopColor: 'gray',
          //   },
          // },
        },
      },
    },
  },
  // arrowShadowColor="#f1e9e9"
  Tooltip: {
    baseStyle: {
      // bg: 'lotionWhite',
      [$tooltipbg.variable]: 'aliceblue',
      [$arrowBg.variable]: 'aliceblue',
      color: 'highGray',
      fontSize: '12px',
    },
  },
  Number: {
    baseStyle: {
      root: {
        bg: 'lotionWhite',
      },
    },
  },
  Input: {
    baseStyle: () => ({
      field: {
        bgColor: 'red',
        // borderWidth: '1px',
        borderRadius: 'sm',
      },
    }),
    variants: {
      outline: {
        field: {
          bg: 'lotionWhite',
          borderRadius: 'md',
          borderWidth: '1px',
          borderColor: 'silverGray',
          transitionProperty: 'common',
          transitionDuration: 'normal',
          outline: '2px solid transparent',
          outlineOffset: '2px',
          _placeholder: { color: 'borderWhite' },
          _hover: {
            borderColor: {},
          },
          _focus: {
            zIndex: 1,
            borderColor: '#63b3ed',
            boxShadow: `0 0 0 1px #63b3ed`,
          },
        },
      },
      white36: {
        field: {
          bg: 'white',
          borderRadius: 'md',
          borderWidth: '1px',
          borderColor: 'silverGray',
          transitionProperty: 'common',
          transitionDuration: 'normal',
          outline: '2px solid transparent',
          outlineOffset: '2px',
          height: '36px',
          fontSize: '14px',
          _placeholder: { color: 'borderWhite' },
          _hover: {
            borderColor: {},
          },
          _focus: {
            zIndex: 1,
            borderColor: '#63b3ed',
            boxShadow: `0 0 0 1px #63b3ed`,
          },
          _invalid: { borderColor: '#E53E3E', boxShadow: '0 0 0 1px #e53e3e' },
          _disabled: {
            opacity: 0.4,
            cursor: 'not-allowed',
          },
        },
      },
      search: {
        field: {
          type: 'search',
          bg: 'white',
          borderRadius: 'md',
          borderWidth: '1px',
          borderColor: 'silverGray',
          transitionProperty: 'common',
          transitionDuration: 'normal',
          outline: '2px solid transparent',
          outlineOffset: '2px',
          _placeholder: { color: 'borderWhite' },
          _hover: {},
          _focus: {
            zIndex: 1,
            borderColor: '#63b3ed',
            boxShadow: `0 0 0 1px #63b3ed`,
          },
        },
      },
      fileStyle: {
        field: {
          bg: 'lotionWhite',
          borderColor: 'silverGray',
          borderWidth: '1px',
          boxShadow: 'sm',
          borderRadius: 'md',
          ':focus': {
            borderColor: '#a970ff',
            bg: '#000',
            color: 'white',
          },
          '::file-selector-button': {
            display: 'none',
          },
        },
      },
    },
  },
  Editable: {
    baseStyle: {
      textarea: {
        borderRadius: 'md',
        py: '3px',
        transitionProperty: 'common',
        transitionDuration: 'normal',
        width: 'full',
        _focus: { boxShadow: 'outline' },
        _placeholder: { opacity: 0.6 },
      },
    },
  },
  Switch: {
    baseStyle: {
      container: {},
      track: {
        bg: 'gray.300',
        _checked: {
          bg: 'zetaBlue',
        },
      },
      thumb: {
        bg: 'white',
      },
    },
  },
  Tag: {
    variants: {
      subtle: (props: any) => {
        const { colorScheme: c, theme } = props;
        const { bg: bgBadge } = theme.components.Badge.variants.subtle(props);
        return {
          container: {
            // bg: mode(`${c}.50`, bgBadge)(props),
            bg: 'white',
            border: '1px',
            color: 'highGray',
            lineHeight: 0,
            borderColor: mode('borderWhite', 'white'),
            fontSize: '11px',
            fontWeight: 'medium',
            // boxShadow: 'sm',
          },
        };
      },
      basic: (props: any) => {
        return {
          container: {
            border: '1px solid',
            borderColor: 'silverGray',
            borderRadius: 'md',
            // boxShadow: 'sm',
            fontSize: '12px',
            fontWeight: 'normal',
            bgColor: 'white',
            lineHeight: 0,
            h: '28px',
            px: 2,
            py: 1,
          },
        };
      },
      green: (props: any) => {
        return {
          container: {
            bg: 'mountainGreen',
            color: 'white',
            fontSize: '11px',
            fontWeight: 'medium',
            boxShadow: 'sm',
            lineHeight: 0,
            minW: '60px',
            justifyContent: 'center',
          },
        };
      },
      red: (props: any) => {
        return {
          container: {
            bg: 'primaryRed',
            color: 'white',
            fontSize: '11px',
            fontWeight: 400,
            boxShadow: 'sm',
            lineHeight: 0,
            minW: '60px',
            justifyContent: 'center',
          },
        };
      },
      greenBorder: (props: any) => {
        return {
          container: {
            borderColor: 'mountainGreen',
            borderWidth: '1px',
            color: 'mountainGreen',
            fontSize: '11px',
            fontWeight: 400,
            boxShadow: 'sm',
            lineHeight: 0,
            minW: '60px',
            justifyContent: 'center',
          },
        };
      },
      orange: (props: any) => {
        return {
          container: {
            bg: '#FFB800',
            color: 'white',
            fontSize: '11px',
            fontWeight: 400,
            // boxShadow: 'sm',
            lineHeight: 0,
            minW: '60px',
            justifyContent: 'center',
          } as TagProps,
        };
      },
      yellow: (props: any) => {
        return {
          container: {
            bg: '#FFECBB',
            color: '#FFB800',
            fontSize: '11px',
            fontWeight: 700,
            lineHeight: 0,
            minW: '58px',
            justifyContent: 'center',
          } as TagProps,
        };
      },
      strawberry: (props: any) => {
        return {
          container: {
            bg: '#FFC9D3',
            color: '#FB4667',
            fontSize: '11px',
            fontWeight: 700,
            lineHeight: 0,
            minW: '58px',
            justifyContent: 'center',
          } as TagProps,
        };
      },
      skyBlue: (props: any) => {
        return {
          container: {
            bg: 'blue.300',
            color: 'blue.700',
            fontSize: '11px',
            fontWeight: 700,
            lineHeight: 0,
            minW: '58px',
            justifyContent: 'center',
          } as TagProps,
        };
      },
      none: (props: any) => {
        return {
          container: {
            bg: 'white',
            color: '#414141',
            fontSize: '11px',
            fontWeight: 700,
            lineHeight: 0,
            minW: '58px',
            justifyContent: 'center',
          } as TagProps,
        };
      },
      gray: (props: any) => {
        return {
          container: {
            bg: '#DDDDDD',
            color: '#A0A0A0',
            fontSize: '11px',
            fontWeight: 400,
            boxShadow: 'sm',
            lineHeight: 0,
            minW: 'max',
            justifyContent: 'center',
          },
        };
      },
      tour_complete_11px: () => {
        return {
          container: {
            bgColor: 'silverGray',
            color: 'white',
            fontWeight: 'bold',
            fontSize: '11px',
            w: 'max-content',
            h: 'max-content',
            minW: 'max',
            lineHeight: 0,
          },
        };
      },
      tour_booking_11px: () => {
        return {
          container: {
            bgColor: 'mountainGreen',
            color: 'white',
            fontWeight: 'bold',
            fontSize: '11px',
            w: 'max-content',
            minW: 'max',
            h: 'max-content',
            lineHeight: 0,
          },
        };
      },
      new_request: () => {
        return {
          container: {
            bgColor: '#FEB62A',
            color: 'white',
            fontWeight: 'bold',
            fontSize: '11px',
            borderRadius: 'full',
            px: '12px',
            w: 'max-content',
            minW: 'max',
            lineHeight: 0,
          },
        };
      },
      end_expected_request: () => {
        return {
          container: {
            bgColor: '#DDDDDD',
            color: '#A0A0A0',
            fontWeight: 'bold',
            fontSize: '11px',
            borderRadius: 'full',
            px: '12px',
            w: 'max-content',
            minW: 'max',
            lineHeight: 0,
          },
        };
      },
      before_approved_service: () => {
        return {
          container: {
            bgColor: 'lightsalmon',
            color: 'white',
            fontWeight: 'bold',
            fontSize: '11px',
            borderRadius: 'full',
            px: '12px',
            w: 'max-content',
            minW: 'max',
            lineHeight: 0,
          },
        };
      },
      after_approved_service: () => {
        return {
          container: {
            bgColor: 'lightblue',
            color: 'white',
            fontWeight: 'bold',
            fontSize: '11px',
            borderRadius: 'full',
            px: '12px',
            w: 'max-content',
            minW: 'max',
            lineHeight: 0,
          },
        };
      },
      complete_recommendation: () => {
        return {
          container: {
            bgColor: '#29CC97',
            color: 'white',
            borderWidth: '1px',
            borderColor: '#29CC97',
            fontWeight: 'bold',
            fontSize: '16px',
            borderRadius: 'md',
            px: '12px',
            py: '12px',
            w: 'max-content',
            minW: 'max',
            lineHeight: 0,
          },
        };
      },
      pending_recommendation: () => {
        return {
          container: {
            bgColor: 'white',
            color: '#29CC97',
            borderWidth: '1px',
            borderColor: '#29CC97',
            fontWeight: 'bold',
            fontSize: '16px',
            borderRadius: 'md',
            px: '12px',
            py: '12px',
            w: 'max-content',
            minW: 'max',
            lineHeight: 0,
          },
        };
      },
      rejected_recommendation: () => {
        return {
          container: {
            bgColor: 'white',
            color: 'gray',
            borderWidth: '1px',
            borderColor: 'gray',
            fontWeight: 'bold',
            fontSize: '16px',
            borderRadius: 'md',
            px: '12px',
            py: '12px',
            w: 'max-content',
            minW: 'max',
            lineHeight: 0,
          },
        };
      },
      current_empty: () => {
        return {
          container: {
            bgColor: '#00A3FF',
            color: 'white',
            fontWeight: 'bold',
            fontSize: '11px',
            borderRadius: 'md',
            px: '12px',
            w: 'max-content',
            minW: 'max',
            lineHeight: 0,
          },
        };
      },

      current_filled: () => {
        return {
          container: {
            bgColor: 'silverGray',
            color: 'white',
            fontWeight: 'bold',
            fontSize: '11px',
            borderRadius: 'md',
            px: '12px',
            w: 'max-content',
            minW: 'max',
            lineHeight: 0,
          },
        };
      },
      room_number: () => {
        return {
          container: {
            bgColor: 'primarySkyblue',
            color: 'zetaBlue',
            fontWeight: 'medium',
            fontSize: '11px',
            borderRadius: 'md',
            px: '12px',
            w: 'max-content',
            minW: 'max',
            lineHeight: 0,
          },
        };
      },
      access_hidden: () => {
        return {
          container: {
            bgColor: 'primaryRed',
            color: 'white',
            fontWeight: 'bold',
            fontSize: '11px',
            borderRadius: 'md',
            px: '12px',
            w: 'max-content',
            minW: 'max',
            lineHeight: 0,
          },
        };
      },
      tenant_stamp: () => {
        return {
          container: {
            bgColor: 'white',
            color: '#FB4667',
            fontWeight: 'bold',
            fontSize: '11px',
            borderWidth: '1px',
            borderColor: '#FB4667',
            borderRadius: 'full',
            lineHeight: 0,
            minW: 'max',
          },
        };
      },
      host_stamp: () => {
        return {
          container: {
            bgColor: 'white',
            color: '#00A3FF',
            fontWeight: 'bold',
            fontSize: '11px',
            borderWidth: '1px',
            borderColor: '#00A3FF',
            borderRadius: 'full',
            lineHeight: 0,
            minW: 'max',
          },
        };
      },
    },
  },
};

export declare interface LayerStyles {
  [key: string]: BoxProps;
}

const layerStyles = {
  hover_opacity_animate: {
    _hover: {
      opacity: 0.7,
    },
    transition: 'linear 0.18s',
    cursor: 'pointer',
  },
  hover_ECF1FF_animate: {
    //
    _hover: {
      bgColor: 'rgba(236,241,255,0.5)',
    },
    transition: 'linear 0.18s',
    cursor: 'pointer',
  },
  btn_hover_zetaBlue_animate: {
    _hover: {
      bgColor: 'zetaBlue',
      color: 'white',
    },
    cursor: 'pointer',
    transition: 'linear 0.18s',
  },
  latest_message: {
    bgColor: '#ECF1FF',
    borderColor: 'silverGray',
    borderWidth: '1px',
    borderRadius: 'md',
    px: '4px',
    py: '2px',
    minW: '280px',
    maxW: '280px',
  },
  request_line_tab: {
    bgColor: 'lotionWhite',
    borderColor: 'silverGray',
    borderWidth: '1px',
    borderRadius: 'md',
    boxShadow: 'sm',
    px: '16px',
    py: '8px',
  },
  rejected: {
    bgColor: '#FB4667',
    borderColor: '#FB4667',
    borderWidth: '1px',
    borderRadius: 'md',
    fontWeight: 'bold',
    color: 'white',
    px: '16px',
    height: '34px',
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    // textAlign: 'center',
  },
  gray_dashed_line: {
    borderWidth: '2px',
    borderRadius: '2px',
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
  } as BoxProps,
  gray: {
    border: '1px solid rgb(235,240,246)',
    bgColor: 'mainBgColor',
    borderRadius: 'md',
  } as BoxProps,
  pointer: {
    _hover: {
      opacity: 0.6,
      cursor: 'pointer',
    },
  },
  abs_tr: {
    pos: 'absolute',
    top: 0,
    right: 0,
    transform: 'translate(50%, -50%)',
  },
} as LayerStyles;

// REVISE! container.md default size

const fonts = {
  body: "'Roboto', 'Noto Sans CJK KR'",
};

const textStyles = {
  pointer: {
    cursor: 'pointer',
    textDecorationLine: 'underline',
    textUnderlineOffset: '1px',
    _hover: {
      color: 'zetaBlue',
    },
    transition: '0.18s linear',
  } as TextProps,
  ellipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  } as TextProps,
  h2: {
    fontSize: { base: '24px', md: '40px', lg: '56px' },
    fontWeight: 'semibold',
    lineHeight: '110%',
    letterSpacing: '-1%',
  },
  u: {
    textDecor: 'underline',
    textUnderlineOffset: 2,
  },
  ul: {
    textDecor: 'underline',
    textUnderlineOffset: 2,
    cursor: 'pointer',
  },
  ul_green: {
    color: '#29CC97',
    textDecor: 'underline',
    textUnderlineOffset: 2,
    cursor: 'pointer',
  },
  // responsive start
  xl12_2xl16: {
    fontSize: {
      xl: '12px',
      '2xl': '16px',
    },
  },
  xl16_2xl22: {
    fontSize: {
      xl: '16px',
      '2xl': '22px',
    },
  },
  xl20_2xl32: {
    fontSize: {
      xl: '20px',
      '2xl': '32px',
    },
  },
  // responsive end
  normal8: {
    fontSize: '8px',
    fontWeight: 'normal',
  },
  medium8: {
    fontSize: '8px',
    fontWeight: 'medium',
  },
  bold8: {
    fontSize: '8px',
    fontWeight: 'bold',
  },
  normal10: {
    fontSize: '10px',
    fontWeight: 'normal',
  },
  medium10: {
    fontSize: '10px',
    fontWeight: 'medium',
  },
  bold10: {
    fontSize: '10px',
    fontWeight: 'bold',
  },
  normal11: {
    fontSize: '11px',
    fontWeight: 'normal',
  },
  medium11: {
    fontSize: '11px',
    fontWeight: 'medium',
  },
  bold11: {
    fontSize: '11px',
    fontWeight: 'bold',
  },
  normal12: {
    fontSize: '12px',
    fontWeight: 'normal',
  },
  medium12: {
    fontSize: '12px',
    fontWeight: 'medium',
  },
  bold12: {
    fontSize: '12px',
    fontWeight: 'bold',
  },
  normal14: {
    fontSize: '14px',
    fontWeight: 'normal',
  },
  medium14: {
    fontSize: '14px',
    fontWeight: 'medium',
  },
  bold14: {
    fontSize: '14px',
    fontWeight: 'bold',
  },
  normal15: {
    fontSize: '15px',
    fontWeight: 'normal',
  },
  medium15: {
    fontSize: '15px',
    fontWeight: 'medium',
  },
  bold15: {
    fontSize: '15px',
    fontWeight: 'bold',
  },
  normal16: {
    fontSize: '16px',
    fontWeight: 'normal',
  },
  medium16: {
    fontSize: '16px',
    fontWeight: 'medium',
  },
  bold16: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
  normal18: {
    fontSize: '18px',
    fontWeight: 'normal',
  },
  medium18: {
    fontSize: '18px',
    fontWeight: 'medium',
  },
  bold18: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
  normal20: {
    fontSize: '20px',
    fontWeight: 'normal',
  },
  medium20: {
    fontSize: '20px',
    fontWeight: 'medium',
  },
  bold20: {
    fontSize: '20px',
    fontWeight: 'bold',
  },
  normal24: {
    fontSize: '24px',
    fontWeight: 'normal',
  },
  medium24: {
    fontSize: '24px',
    fontWeight: 'medium',
  },
  bold24: {
    fontSize: '24px',
    fontWeight: 'bold',
  },
  normal25: {
    fontSize: '25px',
    fontWeight: 'normal',
  },
  medium25: {
    fontSize: '25px',
    fontWeight: 'medium',
  },
  bold25: {
    fontSize: '25px',
    fontWeight: 'bold',
  },
  normal26: {
    fontSize: '26px',
    fontWeight: 'normal',
  },
  medium26: {
    fontSize: '26px',
    fontWeight: 'medium',
  },
  bold26: {
    fontSize: '26px',
    fontWeight: 'bold',
  },
  normal28: {
    fontSize: '28px',
    fontWeight: 'normal',
  },
  medium28: {
    fontSize: '28px',
    fontWeight: 'medium',
  },
  bold28: {
    fontSize: '28px',
    fontWeight: 'bold',
  },
  normal30: {
    fontSize: '30px',
    fontWeight: 'normal',
  },
  medium30: {
    fontSize: '30px',
    fontWeight: 'medium',
  },
  bold30: {
    fontSize: '30px',
    fontWeight: 'bold',
  },
};

const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};

const theme = extendTheme({ config, styles, components, fonts, colors, layerStyles, textStyles });
export default theme;

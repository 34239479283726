import { HStack, Text } from '@chakra-ui/react';
import { useGetCompanyContract } from '../../../lib/subscriber/hooks/query';
import { paymentPeriodTrans } from '../../../lib/subscriber/utils/utils';
import { certificate_issue_type_object } from '../../../statics/common/data/selectbox';

const PaymentAndTax = () => {
  const { data: contractdata } = useGetCompanyContract();
  if (contractdata === undefined) {
    return null;
  }

  const { is_year, certificate_issue_type } = contractdata;

  return (
    <HStack spacing={'6px'}>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13.3333 3.33333H2.66667C2.29848 3.33333 2 3.63181 2 4V12C2 12.3682 2.29848 12.6667 2.66667 12.6667H13.3333C13.7015 12.6667 14 12.3682 14 12V3.99999C14 3.63181 13.7015 3.33333 13.3333 3.33333Z"
          stroke="#A0A0A0"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M4.66667 10H7.33334" stroke="#A0A0A0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M2 7.33333H14" stroke="#A0A0A0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M2 6H14" stroke="#A0A0A0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
      <Text fontWeight={'medium'}>
        {paymentPeriodTrans(is_year)} / {certificate_issue_type_object[certificate_issue_type]} 발행
      </Text>
    </HStack>
  );
};

export default PaymentAndTax;

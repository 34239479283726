import { Box, Flex, Skeleton } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useGetAdminAnnouncement } from '../../../../../../../lib/admin/hooks/query';
import AnnoucementUploadTable from '../../../../../../../components/common/Table/AnnoucementUploadTable';
import Loading from '../../../../../../../components/common/Loading';

const Detail = () => {
  const { announcement_id } = useParams();
  const res = useGetAdminAnnouncement(announcement_id || '');
  const { isLoading } = res;
  if (isLoading) return <Loading />;
  return (
    <Flex flexDir={'column'}>
      <Box textStyle={'bold24'}>공지 게시글 상세</Box>
      <Skeleton isLoaded={!isLoading}>
        <AnnoucementUploadTable isPatchMode={true} />
      </Skeleton>
    </Flex>
  );
};

export default Detail;

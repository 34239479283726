import { GlobalRangeSlider } from '..';
import { currentParamsObject, parseJsonToObj } from '../../../../../lib/common/utils/base';
import { staticThreeCommafy } from '../../../../../lib/common/utils/digitformatter';

// import { GlobalStatusCheckbox } from '../SetGlobalStatus';

// export const SetUsingSupportPayment: React.FC = () => {
//   return <GlobalStatusCheckbox param_key="is_using_support_payment">대상자만 보기</GlobalStatusCheckbox>;
// };

export const SetDeposit: React.FC = () => {
  const param_key = 'deposit';
  const { ...paramsprops } = currentParamsObject(false);
  const initial_minmax = { min: 0, max: 1200000 };
  const minmax_param = parseJsonToObj(paramsprops[param_key]);

  const min = minmax_param.min ? minmax_param.min : initial_minmax.min;
  const max = minmax_param.max ? minmax_param.max : initial_minmax.max;

  const isEqualMax = max === 'Infinity' || max === initial_minmax.max;

  return (
    <GlobalRangeSlider
      param_key={param_key}
      initial_minmax={initial_minmax}
      labels={{
        left_bottom: {
          value: `${staticThreeCommafy(min)}원`,
        },
        center_bottom: {
          value: `${staticThreeCommafy((initial_minmax.min + initial_minmax.max) / 2)}원`,
        },
        right_bottom: {
          value: isEqualMax ? '무제한' : `${staticThreeCommafy(max)}원`,
          style: {
            color: 'zetaBlue',
          },
        },
        left_top: {
          value: '월세',
        },
        center_top: {},
        right_top: {},
      }}
    />
  );
};

export const SetManagementFee: React.FC = () => {
  const param_key = 'management_fee';
  const { ...paramsprops } = currentParamsObject(false);
  const initial_minmax = { min: 0, max: 1200000 };
  const minmax_param = parseJsonToObj(paramsprops[param_key]);

  const min = minmax_param.min ? minmax_param.min : initial_minmax.min;
  const max = minmax_param.max ? minmax_param.max : initial_minmax.max;

  const isEqualMax = max === 'Infinity' || max === initial_minmax.max;

  return (
    <GlobalRangeSlider
      param_key={param_key}
      initial_minmax={initial_minmax}
      labels={{
        left_bottom: {
          value: `${staticThreeCommafy(min)}원`,
        },
        center_bottom: {
          value: `${staticThreeCommafy((initial_minmax.min + initial_minmax.max) / 2)}원`,
        },
        right_bottom: {
          value: isEqualMax ? '무제한' : `${staticThreeCommafy(max)}원`,
          style: {
            color: 'zetaBlue',
          },
        },
        left_top: {
          value: '관리비',
        },
        center_top: {},
        right_top: {},
      }}
    />
  );
};

export const SetTotalSum: React.FC = () => {
  const param_key = 'total_sum';
  const { ...paramsprops } = currentParamsObject(false);
  const initial_minmax = { min: 0, max: 1200000 };
  const minmax_param = parseJsonToObj(paramsprops[param_key]);

  const min = minmax_param.min ? minmax_param.min : initial_minmax.min;
  const max = minmax_param.max ? minmax_param.max : initial_minmax.max;

  const isEqualMax = max === 'Infinity' || max === initial_minmax.max;

  return (
    <GlobalRangeSlider
      param_key={param_key}
      initial_minmax={initial_minmax}
      labels={{
        left_bottom: {
          value: `${staticThreeCommafy(min)}원`,
        },
        center_bottom: {
          value: `${staticThreeCommafy((initial_minmax.min + initial_minmax.max) / 2)}원`,
        },
        right_bottom: {
          value: isEqualMax ? '무제한' : `${staticThreeCommafy(max)}원`,
          style: {
            color: 'zetaBlue',
          },
        },
        left_top: {
          value: '월세 + 관리비',
        },
        center_top: {},
        right_top: {},
      }}
    />
  );
};

export const SetRent: React.FC = () => {
  const param_key = 'rent';
  const { ...paramsprops } = currentParamsObject(false);
  const initial_minmax = { min: 0, max: 200000000 };
  const minmax_param = parseJsonToObj(paramsprops[param_key]);

  const min = minmax_param.min ? minmax_param.min : initial_minmax.min;
  const max = minmax_param.max ? minmax_param.max : initial_minmax.max;

  const isEqualMax = max === 'Infinity' || max === initial_minmax.max;

  return (
    <GlobalRangeSlider
      param_key={param_key}
      initial_minmax={initial_minmax}
      labels={{
        left_bottom: {
          value: `${staticThreeCommafy(min)}원`,
        },
        center_bottom: {
          value: `${staticThreeCommafy((initial_minmax.min + initial_minmax.max) / 2)}원`,
        },
        right_bottom: {
          value: isEqualMax ? '무제한' : `${staticThreeCommafy(max)}원`,
          style: {
            color: 'zetaBlue',
          },
        },
        left_top: {
          value: '보증금',
        },
        center_top: {},
        right_top: {},
      }}
      step={500000}
    />
  );
};

export const keyOfGetCompanyAndUserType = '/common/company-and-user-type';
export const keyOfGetOneBuilding = '/buildings/${building_id}';
export const keyOfGetAllBuildingsAllRooms = '/buildings/rooms';
export const keyOfGetAllTransferOuts = '/transfer-outs';
export const keyOfGetSendingMoniesUrl = '/sending-monies/url';

//#region legacy
// GET

export const rooms_path = '/room';
export const rooms_key = '/room';

// 글로벌하게,
export const tenant_tour_key = 'tenant_tour_key';
export const tenant_contract_key = 'tenant_contract_key';
export const tenant_complaint_key = 'tenant_complaint_key';
export const pre_service_requests_key = 'pre_service_requests_key';
export const supportPayment_companies_key = 'supportPayment_companies_key';
export const supportPayment_company_users_key = 'supportPayment_company_users_key';
export const supportPayment_company_users_v2_key = 'supportPayment_company_users_v2_key';
export const host_leaseContracts_key = 'host_leaseContracts_key';
export const host_leaseContracts_leasePayments_key = 'host_leaseContracts_leasePayments_key';
export const host_buildings_rooms_key = 'host_buildings_rooms_key';
export const companies_employees_services_key = 'companies/${company_id}/employees/services';
export const companies_employees_usings_key = 'companies/${company_id}/employees/usings';
export const companies_subscription_contracts_key = '/companies/${company_id}/subscription-contracts';

export const Revise_buildings_rooms_path = '/buildings/rooms';
export const Revise_buildings_rooms_key = 'Revise/buildings/rooms';
export const buildings_rooms_path = '/buildings/rooms';
export const buildings_rooms_key = '/buildings/rooms';
export const companies_managers_path = '/companies/managers';
export const companies_managers_key = '/companies/managers';
export const companies_hosts_path = '/companies/hosts';
export const companies_hosts_key = '/companies/hosts';
export const companies_tenants_path = '/companies/tenants';
export const companies_tenants_key = '/companies/tenants';
export const companies_tenants_leaseContracts_path = '/companies/tenants/lease-contracts/lease-payments';
export const companies_tenants_leaseContracts_key = '/companies/tenants/lease-contracts/lease-payments';
export const companies_subscribers_path = '/companies/subscribers';
export const companies_subscribers_key = '/companies/subscribers';

export const room_new_request_path = '/agency/master/custom-room-requests/new';
export const room_manage_new_request_key = '/agency/master/manage/custom-room-requests/new';

export const room_suggested_request_path = '/agency/master/custom-room-requests/suggested';
export const room_manage_suggested_request_key = '/agency/master/manage/custom-room-requests/suggested';

export const building_list_key = 'building_list_key';
export const building_list_path = '/agency/master/building';

export const building_detail_key = 'building_detail_key';
export const building_partialPath = '/admin/master/buildings';

export const rooms_id_path = '/admin/master/rooms';
// export const rooms_id_key = '/admin/master/rooms';

// POST

export const building_upload_path = '/admin/master/building';
export const room_request_like_partialPath = '/agency/master/custom-room-requests';
// Example

// export const verify_user_path = '/subscriber/master/token/verify';
// to
// export const verify_user_key = '/token/verify';

export const custom_room_requests_path = '/companies/tenants/custom-room-requests';
export const custom_room_requests_key = '/companies/tenants/custom-room-requests';

export const custom_room_request_path = '/custom-room-requests/{custom_room_request_id}';
export const custom_room_request_key = '/custom-room-requests/{custom_room_request_id}';

export const custom_room_request_rooms_path = '/custom-room-requests/{custom_room_request_id}/rooms';
export const custom_room_request_rooms_key = '/custom-room-requests/{custom_room_request_id}/rooms';

export const building_room_path = '/building/rooms/{room_id}';
export const building_room_key = '/building/rooms/{room_id}';

export const building_path = '/buildings/${building_id}';
export const building_key = '/buildings/${building_id}';

export const building_rooms_path = '/buildings/${building_id}/rooms';
export const building_rooms_key = '/buildings/${building_id}/rooms';

export const buildings_path = '/buildings';
export const buildings_key = '/buildings';

export const company_managers_path = '/companies/{company_id}/managers';
export const company_managers_key = '/companies/{company_id}/managers';

export const company_hosts_key = '/company/hosts/${user_id}';

export const tenants_custom_room_requests_path = '/tenants/{tenant_id}/custom-room-requests';
export const tenants_custom_room_requests_key = '/tenants/{tenant_id}/custom-room-requests';

export const tenant_detail_lookup_path = '/tenants/{tenant_id}';
export const tenant_detail_lookup_key = '/tenants/{tenant_id}';

export const announcement_detail_lookup_path = '/announcements/{announcement_id}';
export const announcement_detail_lookup_key = '/announcements/{announcement_id}';

export const tenant_lease_contract_detail_lookup_key = '/lease-contracts/${lease_contract_id}';

export const companies_contracts_path = '/companies/subscription-contracts';
export const companies_contracts_key = '/companies/subscription-contracts';

export const announcements_path = '/announcements';
export const announcements_key = 'admin/announcements';

export const company_users_lease_contracts_path = '/company/users/{user_id}/lease-contracts';
export const company_users_lease_contracts_key = '/company/users/{user_id}/lease-contracts';

export const companies_contracts_payments_path = '/companies/subscription-contracts/subscription-payments';
export const companies_contracts_payments_key = '/companies/subscription-contracts/subscription-payments';

export const companies_contracts_support_payments_path = companies_contracts_payments_path + '/support-payment';
export const companies_contracts_support_payments_key = companies_contracts_payments_key + '/support-payment';

export const virtual_account_user_path = '/{user_id}/wp/virtual-account';
export const virtual_account_user_key = '/{user_id}/wp/virtual-account';

export const virtual_account_company_path = '/companies/{company_id}/wp/user-and-virtual-account';
export const virtual_account_company_key = '/companies/{company_id}/wp/user-and-virtual-account';

export const user_path = '/company/users/{user_id}';
export const user_key = '/company/users/{user_id}';

export const common_user_path = '/companies/users';
export const common_user_key = '/companies/users';

export const company_payments_path = '/subscription-contracts/{subscription_contract_id}/subscription-payments';
export const company_payments_key = '/subscription-contracts/{subscription_contract_id}/subscription-payments';
//#endregion

export const verify_user_path = '/token/verify';
// export const verify_user_path = '/subscriber/master/token/verify';
export const verify_user_key = '/token/verify';
// export const verify_user_key = '/subscriber/master/token/verify';
export const company_path = '/subscriber/master/company';
export const company_key = '/subscriber/master/company';
export const contract_key = '/subscriber/master/subscription-contract';
export const contract_path = '/subscriber/master/subscription-contract';
export const contracts_key = '/subscriber/master/company/subscription-contracts';
export const contracts_path = '/subscriber/master/company/subscription-contracts';
export const payments_key = '/subscriber/master/subscription-payments';
export const payments_path = '/subscriber/master/subscription-payments';
export const notifications_list_key = 'notifications';
export const notifications_list_path = 'notifications';
export const employee_services_key = '/subscriber/master/company/employees/services';
export const employee_services_path = '/company/employees/services';
export const employee_histories_key = '/subscriber/master/employees/user_id/histories';
export const employee_counts_key = '/subscriber/master/employees/count';
export const employee_counts_path = '/subscriber/master/employees/count';
export const employee_usings_key = '/subscriber/master/company/employees/usings';
export const employee_usings_path = '/company/employees/usings';

export const not_checked_count_path = 'notifications-fcms/not-checked-count';
export const not_checked_count_key = 'notifications-fcms/not-checked-count';

export const all_announcements_path = 'announcements';
export const all_announcements_key = 'announcements';
export const naver_form_url = 'naver-form/url';

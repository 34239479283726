import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Icon,
  Image,
  Modal,
  ModalContent,
  ModalOverlay,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { FiClock } from 'react-icons/fi';
import { HiOutlinePaperAirplane } from 'react-icons/hi';
import { ModalDisclosureProps } from './base';
import air_plane from '../../../statics/common/images/air_plane.png';

const Inquiry: React.FC<ModalDisclosureProps> = ({ isOpen, onClose }) => {
  const [buildingForm, setBuildingForm] = useState<string>('');
  const [householdNumber, setHouseholdNumber] = useState<number | string>(0);
  const [fileType, setFileType] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const dataForm = {
    building_form: buildingForm,
    household_number: householdNumber,
    file_type: fileType,
    message: message,
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside" size={'md'} motionPreset="slideInRight">
      <ModalOverlay />
      <ModalContent bgColor={'white'}>
        <Scrollbars style={{ height: '735px' }} autoHide universal>
          {/* 섹션 1 */}
          <VStack
            spacing={8}
            alignItems={'flex-start'}
            px={8}
            py={10}
            bgColor={'zetaBlue'}
            color="white"
            borderTopRadius={'md'}
            position={'relative'}
          >
            <Flex>
              <Icon as={HiOutlinePaperAirplane} transform={'rotate(45deg)'} w={6} h={6} />
              <Box fontSize={'20px'} fontWeight={'medium'}>
                문의하기
              </Box>
            </Flex>
            <Box>
              <Flex>문의하실 내용이 있으신가요?</Flex>
              <Flex wordBreak={'keep-all'}>
                아래에 문의를 남겨주시면 운영시간 내 채널로 회신드리겠습니다. 보홈 도움말도 참고해주세요.
              </Flex>
            </Box>
            <HStack alignItems={'flex-start'}>
              <Center w={6} h={6}>
                <Icon as={FiClock} w={4} h={4} />
              </Center>
              <Flex flexDir={'column'} fontSize={'11px'}>
                <Box>보홈 고객센터 운영시간</Box>
                <Box>평일 10:00 - 18:00 점심시간 12:00 - 13:30</Box>
              </Flex>
            </HStack>
            <Image
              src={air_plane}
              right={-1}
              bottom={-2.5}
              boxSize={'200px'}
              objectFit={'contain'}
              position={'absolute'}
            />
          </VStack>
          {/* 섹션 2 */}
          <VStack spacing={6} alignItems={'unset'} p={8} w={'100%'}>
            {/* 메시지 */}
            <Textarea
              placeholder="메시지"
              h={'320px'}
              onChange={(e) => {
                setMessage(e.target.value);
              }}
            />
            <Button
              variant={'basic'}
              style={{ height: '40px' }}
              onClick={(e) => {
                console.log(dataForm);
              }}
            >
              전송
            </Button>
          </VStack>
        </Scrollbars>
      </ModalContent>
    </Modal>
  );
};

export default Inquiry;

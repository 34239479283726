import { Collapse, useDisclosure, VStack } from '@chakra-ui/react';

import { NotificationType, Notification } from '../../../../../lib/common/utils/axios/types';
import Card from '../../../../admin/Card/OpenAletButtonCard';
import FirstCardSet from '../../../../admin/Card/OpenAletButtonCard/FirstCardSet';

interface CardContainerProps {
  data: Notification[];
}

const CardContainer: React.FC<CardContainerProps> = ({ data }) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <>
      <VStack w="full" spacing={1}>
        {data.map((info, idx: any) => {
          if (idx === 0) {
            if (data.length === 1) {
              return <Card key={idx} info={info} />;
            } else {
              return <FirstCardSet key={idx} info={info} isOpen={isOpen} count={data.length} onToggle={onToggle} />;
            }
          } else {
            return (
              <Collapse in={isOpen} key={idx} animateOpacity style={{ width: '100%' }}>
                {/* <VStack> */}
                <Card key={idx} info={info} />
                {/* </VStack> */}
              </Collapse>
            );
          }
        })}
      </VStack>
    </>
  );
};

export default CardContainer;

import { Checkbox, Text } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { admin_login_initialValues } from '..';
import { savedEmailAdminKey } from '../../../../../lib/common/utils/localstorage/key';

const SaveIdCheckBox = () => {
  const {
    values: { email, password, isSaveEmail },
    setFieldValue,
  } = useFormikContext<typeof admin_login_initialValues>();

  useEffect(() => {
    if (localStorage.getItem(savedEmailAdminKey)) {
      setFieldValue('email', localStorage.getItem(savedEmailAdminKey));
      setFieldValue('isSaveEmail', true);
    }
  }, []);

  return (
    <Checkbox
      onChange={(e) => {
        setFieldValue('isSaveEmail', e.target.checked);
      }}
      isChecked={isSaveEmail}
      w="full"
    >
      <Text fontSize={'12px'} color={'#6F6F6F'}>
        아이디 저장
      </Text>
    </Checkbox>
  );
};

export default SaveIdCheckBox;

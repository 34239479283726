import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Input,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  Tooltip,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import Scrollbars from 'react-custom-scrollbars-2';
import * as Yup from 'yup';
import { WBox } from '../../Box';
import { FRadioButtonGroup } from '../../Radio';
import { useMakeUserHost, useMakeUserHostAndCompany } from '../../../../lib/common/hooks/mutation';
import { alternative_radio, move_in_radio } from '../../../../statics/common/data/radio';
import { CheckCircleIcon } from '../../../../statics/common/icons';
import { OneSelectHasCompany, TwoComplexRegister, TwoSimpleRegister } from './stage';

interface RegisterNewHostModalProps {
  disabled?: boolean;
}

export interface initialValuesProps {
  has_company: 'not-selected' | 'true' | 'false';
  step: 'one_select-has-company' | 'two_simple-register' | 'two_complex-register';
  user_name: string;
  phone_number_first: string;
  accounts: Array<{
    bank_code: number;
    account_number: string;
    account_owner_name: string;
    is_auth: boolean; // 인증된 계좌
    is_added: boolean; // 등록되서 리스트에 추가된 계좌
  }>;
  business_certificate: File | string; // File 한 장만
  resident_registers: Array<File>; // Files
  account_copies: Array<File>; // Files
  business_type: string;
  business_registration_number: string;
  company_name: string;
  address: string;
  road_address: string;
  sido_name: string;
  sigungu_name: string;
  dongli_name: string;
  detail_address: string;
  registration_number_front: string;
  registration_number_back: string;
}

const RegisterNewHostModal: React.FC<RegisterNewHostModalProps> = ({ children, disabled = false }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleModalOpen = () => {
    if (!disabled) {
      onOpen();
    }
  };

  //
  const { mutateAsync: mutateAsyncHost } = useMakeUserHost();
  const { mutateAsync: mutateAsyncHostAndCompany } = useMakeUserHostAndCompany();

  return (
    <>
      <Tooltip label={'신규 임대인 등록하기'} fontSize={'14px'} defaultIsOpen hasArrow placement={'top'}>
        <Box onClick={handleModalOpen}>{children}</Box>
      </Tooltip>

      <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside" isCentered size={'xl'}>
        <ModalOverlay />
        <Formik
          initialValues={
            {
              /* 회사 유무 판단 */
              has_company: 'not-selected',
              step: 'one_select-has-company',
              /* 회원 정보 */
              user_name: '',
              phone_number_first: '',
              registration_number_front: '',
              registration_number_back: '',
              address: '',
              road_address: '',
              sido_name: '',
              sigungu_name: '',
              dongli_name: '',
              detail_address: '',
              /* 사업자 정보 */
              business_type: '',
              business_registration_number: '',
              company_name: '',

              // 첨부 서류들
              business_certificate: '',
              resident_registers: [],
              account_copies: [],
              /* 계좌 정보 */
              // accounts: [
              //   { bank_code: 1234, account_number: '', account_owner_name: '', is_auth: false, is_added: false },
              // ],
              accounts: [],
            } as initialValuesProps
          }
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            const {
              user_name,
              phone_number_first,
              accounts,
              resident_registers,
              account_copies,
              business_certificate,
              business_type,
              business_registration_number,
              registration_number_front,
              registration_number_back,
              company_name,
              address,
              road_address,
              sido_name,
              sigungu_name,
              dongli_name,
              detail_address,
              step,
            } = values;

            const accounts_filtered = accounts.filter((account) => account.is_added === true);

            const accounts_parsed = accounts_filtered.map((account) => ({
              bank_code: account?.bank_code,
              account_number: account?.account_number,
              account_owner_name: account?.account_owner_name,
            }));
            const registration_number =
              registration_number_front && registration_number_back
                ? registration_number_front + registration_number_back
                : '';

            if (step === 'two_simple-register') {
              mutateAsyncHost({
                user_name,
                phone_number_first,
                registration_number,
                accounts: accounts_parsed,
                resident_registers,
                account_copies,
                address,
                road_address,
                sido_name,
                sigungu_name,
                dongli_name,
                detail_address,
              }).then(() => {
                setSubmitting(false);
                onClose();
              });
            } else if (step === 'two_complex-register') {
              mutateAsyncHostAndCompany({
                user_name,
                phone_number_first,
                registration_number,
                accounts: accounts_parsed,
                resident_registers,
                account_copies,
                business_certificate: business_certificate as File,
                business_type,
                business_registration_number,
                company_name,
                address,
                road_address,
                sido_name,
                sigungu_name,
                dongli_name,
                detail_address,
              }).then(() => {
                setSubmitting(false);
                onClose();
              });
            } else {
              return;
            }
          }}
          validationSchema={Yup.object().shape({})}
        >
          {({ values, errors, isValid, handleChange, setFieldValue, isSubmitting }) => {
            const isAbleNextStep = values.has_company !== 'not-selected';
            const isAbleReservation = true;

            console.log('values =>', values);

            const steps = {
              'one_select-has-company': <OneSelectHasCompany />,
              'two_simple-register': <TwoSimpleRegister />,
              'two_complex-register': <TwoComplexRegister />,
            } as {
              [key in initialValuesProps['step']]: JSX.Element;
            };

            if (values.step === 'one_select-has-company') {
              return (
                <Form style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
                  <ModalContent p={5} h={'max'}>
                    <ModalCloseButton />
                    <Flex flexDir={'column'} m={10} alignItems={'flex-start'}>
                      <Box w="full">{steps[values.step]}</Box>
                    </Flex>
                  </ModalContent>
                </Form>
              );
            } else {
              return (
                <Form style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
                  <ModalContent p={5} h={'full'}>
                    <ModalCloseButton />
                    <Scrollbars style={{ height: '100%' }}>
                      <Flex flexDir={'column'} m={10} alignItems={'flex-start'}>
                        <HStack mb={5}>
                          <CheckCircleIcon w={8} h={8} />
                          <Text fontSize={'23px'}>신규 임대인 등록</Text>
                        </HStack>
                        <Box w="full">{steps[values.step]}</Box>
                      </Flex>
                    </Scrollbars>
                  </ModalContent>
                </Form>
              );
            }
          }}
        </Formik>
      </Modal>
    </>
  );
};

export default RegisterNewHostModal;

import { Box, BoxProps } from '@chakra-ui/react';
import { DropzoneOptions } from 'react-dropzone';
import { useDropzoneMultiFilesUpload } from '../../../lib/common/hooks/dropzone/hook';

interface Props {
  funcInSuccessProcess: (files: Array<File>) => void;
  customVariant?: string;
  dropzoneOptions?: DropzoneOptions;
}

const MultiFilesCommonDropzone: React.FC<Props> = ({
  funcInSuccessProcess,
  customVariant = 'basic',
  dropzoneOptions,
  children,
}) => {
  const [useDropzone, style] = useDropzoneMultiFilesUpload(funcInSuccessProcess, customVariant, dropzoneOptions);
  const { getRootProps, getInputProps } = useDropzone;
  return (
    <Box className="container">
      <Box {...getRootProps({ height: '200px', justifyContent: 'center', ...style } as BoxProps)}>
        <input {...getInputProps()} />
        {children}
      </Box>
    </Box>
  );
};

export default MultiFilesCommonDropzone;

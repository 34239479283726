import {
  Flex,
  Box,
  HStack,
  Button,
  InputGroup,
  InputLeftElement,
  Input,
  Center,
  Divider,
  VStack,
  Text,
  StackDivider,
  Icon,
} from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import { useQuery } from 'react-query';
import {
  room_manage_suggested_request_key,
  room_suggested_request_path,
} from '../../../../../statics/agent/data/KeyPath';
import { fetchClient } from '../../../../../lib/common/utils/axios';
import { BaseResponse } from '../../../../../lib/common/utils/axios/types';
import { decimalize, threeCommafy } from '../../../../../lib/common/utils/digitformatter';
import { ClickIconContainer, LeftArrowIcon, RightArrowIcon } from '../../../../../statics/common/icons';
import NewRequestContainer from '../../../../../layouts/agent/CustomRoomRequest/new';
import SuggestedRequestContainer from '../../../../../layouts/agent/CustomRoomRequest/suggested';
import { agency } from '../dummydata';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../../../../lib/common/utils/firebase/init';

interface StatusBarItemProps {
  status: string;
  statusTotalNum: number;
  activeId: any;
  setActiveId: any;
}

const StatusBarItem: React.FC<StatusBarItemProps> = ({ status, statusTotalNum, activeId, setActiveId }) => {
  const clickHandler = () => {
    setActiveId(status);
  };

  return (
    <Box w={'120px'} minW={'120px'} my={3}>
      <VStack
        spacing={0}
        onClick={clickHandler}
        cursor={'pointer'}
        bgColor="white"
        borderRadius={'xl'}
        outline={activeId === status ? 'solid 2px' : 'solid 1px'}
        outlineColor={
          status === '전체' && activeId === '전체' ? 'zetaBlue' : activeId === status ? '#29CC97' : 'silverGray'
        }
        px={8}
        pt={2}
        fontWeight={activeId === status ? 'medium' : 'normal'}
        color={status === '전체' && activeId === '전체' ? 'zetaBlue' : activeId === status ? '#29CC97' : 'highGray'}
        borderWidth={activeId === status ? '2px' : '1px'}
        borderColor={
          status === '전체' && activeId === '전체' ? 'zetaBlue' : activeId === status ? '#29CC97' : 'silverGray'
        }
        transition={'background-color 0.18s linear'}
      >
        <Box fontSize={'13px'}>{status}</Box>
        <Box fontSize={'36px'}>{decimalize(statusTotalNum)}</Box>
      </VStack>
    </Box>
  );
};

interface StatusBarContainerProps {
  activeId: any;
  setActiveId: any;
}

const StatusBarContainer: React.FC<StatusBarContainerProps> = ({ activeId, setActiveId }) => {
  return (
    <HStack spacing={1} px={4} w={'full'} justifyContent={'space-between'}>
      <StatusBarItem
        status="전체"
        statusTotalNum={agency.request_status.suggested}
        activeId={activeId}
        setActiveId={setActiveId}
      />
      <Flex justifyContent={'center'} w={6} h={'110px'}>
        <Divider orientation="vertical" borderColor={'gray'} />
      </Flex>
      <StatusBarItem
        status="제안 완료"
        statusTotalNum={agency.request_status.suggested}
        activeId={activeId}
        setActiveId={setActiveId}
      />
      <Icon w={6} h={6}>
        <RightArrowIcon />
      </Icon>
      <StatusBarItem
        status="검토 중"
        statusTotalNum={agency.request_status.reviewing}
        activeId={activeId}
        setActiveId={setActiveId}
      />
      <Icon w={6} h={6}>
        <RightArrowIcon />
      </Icon>
      <StatusBarItem
        status="반려"
        statusTotalNum={agency.request_status.rejected}
        activeId={activeId}
        setActiveId={setActiveId}
      />
      <Icon w={6} h={6}>
        <RightArrowIcon />
      </Icon>
      <StatusBarItem
        status="투어요청"
        statusTotalNum={agency.request_status.tour_request}
        activeId={activeId}
        setActiveId={setActiveId}
      />
      <Icon w={6} h={6}>
        <RightArrowIcon />
      </Icon>
      <StatusBarItem
        status="계약 완료"
        statusTotalNum={agency.request_status.complete_contract}
        activeId={activeId}
        setActiveId={setActiveId}
      />
    </HStack>
  );
};

const SuggestedRequestSection: React.FC = () => {
  const [user, loading, error] = useAuthState(auth);
  const [activeId, setActiveId] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  console.log('activeId => ', activeId);

  const { data: suggestedRequests, refetch } = useQuery(
    [room_manage_suggested_request_key, currentPage],
    async () => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      const value = await instance.get<BaseResponse<any>>(room_suggested_request_path, {
        params: { page: currentPage, limit: 10 },
      });
      return value?.data;
    },
    {
      retry: false,
    },
  );
  console.log('suggestedRequests => ', suggestedRequests);

  return (
    <Flex
      bg="white"
      boxShadow={'2px 2px 4px 0px #DDDDDD'}
      borderBottomRadius={'md'}
      borderTopRightRadius={'md'}
      px={4}
      py={5}
      w={'100%'}
      flexWrap={'wrap'}
    >
      {/* 상단 */}
      <Flex w="full">
        {/* 필터 */}
        <Flex
          mr={2}
          bg="lotionWhite"
          border={'1px solid'}
          borderColor={'silverGray'}
          borderRadius={'lg'}
          boxShadow={'sm'}
          px={3}
          mb={5}
          w={'70%'}
          minW={'940px'}
        >
          <HStack divider={<StackDivider borderColor="gray.200" />} w={'full'}>
            <StatusBarContainer activeId={activeId} setActiveId={setActiveId} />
          </HStack>
        </Flex>
        {/* 검색 조회 */}
        <Flex direction={{ base: 'row', md: 'column' }} w={'30%'}>
          <HStack
            bgColor={'lotionWhite'}
            p={2}
            alignItems={'center'}
            h={'100%'}
            border="1px solid"
            borderColor={'silverGray'}
            borderRadius={'md'}
            boxShadow={'sm'}
          >
            <InputGroup>
              <InputLeftElement
                position={'absolute'}
                top="-2px"
                pointerEvents="none"
                children={<FaSearch color={'borderWhite'} />}
              />
              <Input
                type="search"
                h={'36px'}
                bgColor={'white'}
                placeholder="유저명/요청번호"
                fontSize={'14px'}
                _placeholder={{ color: 'borderWhite' }}
                _placeholderShown={{ textOverflow: 'ellipsis' }}
                _hover={{}}
              />
            </InputGroup>
            <Button variant={'basic'} w={'90px'}>
              조회
            </Button>
          </HStack>
          {/* 화살표 */}
          <Center justifyContent={'flex-end'} h={'100%'} mb={'20px'}>
            <Box fontSize={'14px'} fontWeight={'bold'} color="zetaBlue" mr={3}>{`검색 결과 총 ${'10'}건`}</Box>
            <HStack align="right" spacing={4} alignItems={'center'} justifyContent={'flex-end'}>
              <ClickIconContainer
                clickableArea={7}
                color="zetaBlue"
                w={6}
                h={6}
                onClick={() => {
                  setCurrentPage((prev) => {
                    if (prev < 2) {
                      return prev;
                    }
                    return prev - 1;
                  });
                }}
              >
                <LeftArrowIcon />
              </ClickIconContainer>
              <Text fontSize={'16px'} cursor={'default'} fontWeight={'medium'}>
                {currentPage}
              </Text>
              <ClickIconContainer
                clickableArea={7}
                color="zetaBlue"
                w={6}
                h={6}
                onClick={() => {
                  setCurrentPage((prev) => prev + 1);
                }}
              >
                <RightArrowIcon />
              </ClickIconContainer>
            </HStack>
          </Center>
        </Flex>
      </Flex>

      <Divider borderColor="borderGray" />
      {/* 요청 리스트 */}
      <VStack alignItems={'unset'} borderBottomRadius={'md'} w={'100%'}>
        {/* {request_item_lists.map((list) => (
          <SuggestedRequestContainer key={list._id} item={list} addDetailBtns addChatBtns addLikeBtn />
        ))} */}
      </VStack>
    </Flex>
  );
};

export default SuggestedRequestSection;

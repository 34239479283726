import { Box, Button, Flex, Table, Tbody, Text, Thead, Tr, useDisclosure, VStack } from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import { GTitleItem } from '../../GridItem';
import QueryParamsLayout from '../../../../layouts/common/main/QueryParamsLayout';
import QueryParamsModalLayout from '../../../../layouts/common/main/QueryParamsModalLayout';
import ExtendRequestForm from '../../../subscriber/Modals/ExtendRequestForm';
import ExtraRequestForm from '../../../subscriber/Modals/ExtraRequestForm';
import NewRequestForm from '../../../subscriber/Modals/NewRequestForm';
import WithdrawalRequestForm from '../../../subscriber/Modals/WithdrawalRequestForm';
import { WTd } from '../../Table/Td';
import { WTh } from '../../Table/Th';
import TextLink from '../../Link/TextLink';
import { useGetAllUsersHouseHuntingRequests, useGetAllUsersUsageLists } from '../../../../lib/admin/hooks/query';
import { pathOfUserDetailPage } from '../../../../lib/common/utils/utils';
import { Service, Using } from '../../../../lib/common/utils/axios/types';
import { currentParamsObject, ToAllFilledObj } from '../../../../lib/common/utils/base';
import { tenanStatusTypeTrans } from '../../../../lib/subscriber/utils/utils';
import { datedotformat } from '../../../../lib/common/utils/dateformatter';
import { decimalize } from '../../../../lib/common/utils/digitformatter';
import UsageStatusModal from '../../../subscriber/Modals/UsageStatus';
import { request_type_transition } from '../../../../pages/UserType/subscriber/manage/employee/UsageLists';
import { isEmpty } from 'lodash';
import Loading from '../../Loading';

interface Props {
  queryKeys: Array<string>;
}

const CompanyAllUsersUsageListModal: React.FC<Props> = ({ queryKeys }) => {
  const queryValues = useMemo(() => {
    return queryKeys.map((queryKey) => {
      const { [queryKey]: queryValue } = currentParamsObject(false);
      return queryValue;
    });
  }, [queryKeys]);

  const company_id = queryValues[0];

  const _params = currentParamsObject();
  const params = ToAllFilledObj(_params);

  const res = useGetAllUsersUsageLists(company_id, {
    options: {
      enabled: !!company_id,
    },
    axiosConfig: {
      params,
    },
  }) as any;

  const showPeriod = (using: Using) => {
    if (!using.employee.parsed_last_service) {
      return null;
    }
    if (['in_service', 'in_custom_room_request', 'contracting'].includes(using.employee.tenant_status)) {
      return using.employee.parsed_last_service.how_long_month + '개월';
    } else {
      if (using.employee.parsed_lease_contracts_length >= 1) {
        return (
          <VStack>
            {using.employee.parsed_lease_contracts.map((contract, index) => (
              <Text key={index}>
                {datedotformat(contract.contract_start_date)}~{datedotformat(contract.contract_end_date)}
              </Text>
            ))}
          </VStack>
        );
      } else {
        return null;
      }
    }
  };
  const { isLoading } = res;
  if (isLoading) return <Loading />;
  return (
    <QueryParamsModalLayout
      queryKeys={queryKeys}
      modalProps={{
        scrollBehavior: 'outside',
        isCentered: true,
        size: '5xl',
      }}
    >
      <GTitleItem
        upperSection={
          <Flex justifyContent={'space-between'} alignItems={'center'} cursor={'pointer'}>
            <Text fontSize={'18px'} fontWeight={'medium'}>
              이용 중인 임직원 전체보기
            </Text>
          </Flex>
        }
        w="full"
        h="calc(100vh - 128px)"
        minH="calc(100vh - 128px)"
        boxShadow={'unset'}
      >
        <Box w="full" h={'full'}>
          <Flex flexDir={'column'} h={'full'} px={5} py={5}>
            {/* Text */}
            <Box mb={4}>
              <Box fontSize={'14px'}>
                *이용 중인 임직원은&nbsp;
                <Text fontWeight={'medium'} display={'inline-block'}>
                  최초 이용 승인이 완료된 임직원
                </Text>
                입니다.
              </Box>
              <Box fontSize={'14px'}>
                *최초 이용 승인 후 임직원의&nbsp;
                <Text fontWeight={'medium'} display={'inline-block'}>
                  추가 이용 신청, 계약 연장 신청은 특이사항에서 확인하시고, 상세보기에서 승인처리 부탁드립니다.
                </Text>
              </Box>
            </Box>
            <Box mb={4}>
              {/* <Text textAlign={'end'} fontSize={'14px'} fontWeight={500}>
                {`${total_on_using_employees_count}인 / ${availableUserCount}인 이용 중 (잔여: 
              ${availableUserCount - total_on_using_employees_count}인)`}
              </Text> */}
            </Box>
            {/* Table */}
            <Flex flexDir={'column'} w={'full'} flexGrow={1}>
              {/* <Scrollbars> */}
              <QueryParamsLayout res={res}>
                <Table variant="head_silverbg_toplining">
                  <Thead>
                    <Tr>
                      <WTh borderR>이용 상태</WTh>
                      <WTh borderR>임직원명</WTh>
                      <WTh borderR>입주 기간</WTh>
                      {/* <WTh borderR>특이 사항</WTh> */}
                      <WTh>상세보기</WTh>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {res.data?.result?.map((using: any, index: any) => (
                      <Tr h={'51px'} key={index}>
                        <WTd borderR>{tenanStatusTypeTrans(using.employee.tenant_status)}</WTd>
                        <WTd borderR>
                          <TextLink
                            target={'_blank'}
                            href={`${pathOfUserDetailPage(using.employee.tenant_status)}${using.employee._id}`}
                          >
                            {using.employee.user_name}
                          </TextLink>
                        </WTd>
                        <WTd borderR>{showPeriod(using)}</WTd>
                        <WTd borderR>
                          <UsageStatusModal using={using} />
                        </WTd>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </QueryParamsLayout>
              {/* </Scrollbars> */}
              {/* 모달 */}
            </Flex>
          </Flex>
        </Box>
      </GTitleItem>
    </QueryParamsModalLayout>
  );
};

export default CompanyAllUsersUsageListModal;

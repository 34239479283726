import { Route, RouteProps, Routes } from 'react-router-dom';
import AgencyLoginPage from '../../../pages/common/Login/Agency';
import AgencyIdCheckPage from '../../../pages/common/Login/Agency/Id';
import Found from '../../../pages/common/Login/Agency/Id/Found';
import AgencyPasswordCheckPage from '../../../pages/common/Login/Agency/Password';
import Reset from '../../../pages/common/Login/Agency/Password/Reset';

const AgencyLoginRoute: React.FC<RouteProps> = () => {
  return (
    <Routes>
      <Route index element={<AgencyLoginPage />} />
      {/* <Route path="id">
        <Route index element={<AgencyIdCheckPage />} />
        <Route path="found">
          <Route index element={<Found />} />
        </Route>
      </Route>
      <Route path="password">
        <Route index element={<AgencyPasswordCheckPage />} />
        <Route path="reset">
          <Route index element={<Reset />} />
        </Route>
      </Route> */}
    </Routes>
  );
};

export default AgencyLoginRoute;

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Checkbox,
  Flex,
  HStack,
  useToast,
} from '@chakra-ui/react';
import { Field, useFormikContext } from 'formik';
import { useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { Link } from 'react-router-dom';
import { initialValue, StepProps } from '..';
import { agreementFulfill } from '../../../../../statics/common/data/toast';
import { MarketingTerm, PersonInfoTerm, ServiceTerm } from './sections/terms/index';

const One: React.FC<StepProps> = ({ setStep }) => {
  const initialCheckedItems = Array(3).fill(false);
  const [checkedItems, setCheckedItems] = useState(initialCheckedItems);

  const { values, setFieldValue, handleChange } = useFormikContext<typeof initialValue>();

  const isChecked = Object.values(values).slice(0, 3).every(Boolean);
  const isIndeterminate =
    Object.values(values).slice(0, 3).some(Boolean) && !Object.values(values).slice(0, 3).every(Boolean);

  const toast_notFulfill = useToast();
  const toast_id = 'toast_notFulfill';

  console.log('values => ', values);

  return (
    <Accordion h={'full'}>
      {/* 전체 체크 */}
      <Checkbox
        isChecked={isChecked}
        isIndeterminate={isIndeterminate}
        onChange={() => {
          if (isChecked) {
            setFieldValue('one', false);
            setFieldValue('two', false);
            setFieldValue('three', false);
            setCheckedItems([false, false, false]);
          } else {
            setFieldValue('one', true);
            setFieldValue('two', true);
            setFieldValue('three', true);
            setCheckedItems([true, true, true]);
          }
        }}
        fontSize={'15px'}
        fontWeight={'bold'}
        mb={5}
      >
        <Box pl={3}>모든 약관에 동의합니다.</Box>
      </Checkbox>
      <Flex flexDir={'column'} h={'440px'}>
        {/* 1 - 최상단 */}
        <Field
          name="one"
          as={Checkbox}
          onChange={(e: any) => {
            handleChange(e);
            setCheckedItems([e.target.checked, checkedItems[1], checkedItems[2]]);
          }}
          value={checkedItems[0]}
          isChecked={checkedItems[0]}
          variant="basic"
        >
          <AccordionItem>
            <AccordionButton py={3}>
              <HStack spacing={1} flex="1" textAlign="left" textStyle={'medium14'}>
                <Box color="zetaBlue">[필수]</Box>
                <Box>보홈 서비스 이용 약관 동의</Box>
              </HStack>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4} bg={'primarySkyblue'} fontSize={'11px'}>
              <Scrollbars style={{ height: '105px' }}>
                <ServiceTerm />
              </Scrollbars>
            </AccordionPanel>
          </AccordionItem>
        </Field>
        {/* 2 */}
        <Field
          name="two"
          as={Checkbox}
          onChange={(e: any) => {
            handleChange(e);
            setCheckedItems([checkedItems[0], e.target.checked, checkedItems[2]]);
          }}
          value={checkedItems[1]}
          isChecked={checkedItems[1]}
          variant="basic"
        >
          <AccordionItem>
            {/* <AccordionItem borderWidth={'1px'} borderBottomColor={'gray.200'}> */}
            <AccordionButton py={3}>
              <HStack spacing={1} flex="1" textAlign="left" textStyle={'medium14'}>
                <Box color="zetaBlue">[필수]</Box>
                <Box>개인정보 수집 및 이용 동의</Box>
              </HStack>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4} bg={'primarySkyblue'} fontSize={'11px'}>
              <Scrollbars style={{ height: '105px' }}>
                <PersonInfoTerm />
              </Scrollbars>
            </AccordionPanel>
          </AccordionItem>
        </Field>
        {/* 3 */}
        <Field
          name="three"
          as={Checkbox}
          onChange={(e: any) => {
            handleChange(e);
            setCheckedItems([checkedItems[0], checkedItems[1], e.target.checked]);
          }}
          value={checkedItems[2]}
          isChecked={checkedItems[2]}
          variant="basic"
        >
          <AccordionItem>
            <AccordionButton py={3}>
              <HStack spacing={1} flex="1" textAlign="left" textStyle={'medium14'}>
                <Box color="zetaBlue">[선택]</Box>
                <Box>이벤트 및 혜택 알림 수신 동의 (이메일, sms)</Box>
              </HStack>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4} bg={'primarySkyblue'} fontSize={'11px'}>
              <Scrollbars style={{ height: '105px' }}>
                <MarketingTerm />
              </Scrollbars>
            </AccordionPanel>
          </AccordionItem>
        </Field>
      </Flex>
      <HStack>
        <Link to={'/login/company'}>
          <Button
            variant={values.one && values.two ? 'gray' : 'basic'}
            boxShadow={'md'}
            boxSize={'max-content'}
            style={{ height: '40px' }}
          >
            로그인으로 돌아가기
          </Button>
        </Link>
        <Button
          variant={values.one && values.two ? 'basic' : 'gray'}
          boxShadow={'md'}
          w={'full'}
          style={{ height: '40px' }}
          onClick={() => {
            // validation
            if (values.one && values.two) {
              setStep(1);
            } else {
              if (!toast_notFulfill.isActive(toast_id)) {
                toast_notFulfill({
                  id: toast_id,
                  ...agreementFulfill,
                });
              }
            }
          }}
        >
          다음
        </Button>
      </HStack>
    </Accordion>
  );
};

export default One;

import {
  useDisclosure,
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Input,
  DrawerFooter,
  Box,
  DrawerProps,
  Text,
  Wrap,
  Image,
  Flex,
  WrapItem,
  Grid,
  GridItem,
  SimpleGrid,
} from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetAllBuildingsRoomsForMap } from '../../../lib/admin/hooks/query';
import { buildSearchParams, currentParamsObject, ToAllFilledObj } from '../../../lib/common/utils/base';
import { staticThreeCommafy } from '../../../lib/common/utils/digitformatter';
import Loading from '../../common/Loading';
import CustomSliderOne from '../../common/Slider/CustomSliderOne';

interface RoomListDrawerProps extends Omit<DrawerProps, 'isOpen' | 'onClose'> {
  //   room_request_id?: string;
  disabled?: boolean;
}

const RoomListDrawer: React.FC<RoomListDrawerProps> = ({ disabled = false, ...props }) => {
  const { isOpen, onOpen, onClose } = useDisclosure({
    defaultIsOpen: true,
  });
  const paramsprops = currentParamsObject(false);
  const navigate = useNavigate();

  const { level, center_lat, center_lng, select_room_id, search } = paramsprops;

  const params = ToAllFilledObj(paramsprops);
  const btnRef = React.useRef();
  const res = useGetAllBuildingsRoomsForMap({
    axiosConfig: {
      params,
    },
  });

  const onMouseEnterItem = (id: string) => {
    const searchParams = buildSearchParams({
      select_room_id: id,
    });

    navigate(searchParams);
  };
  if (res?.isLoading) return <Loading />;
  return (
    <>
      <Drawer
        variant={'zIndex2000'}
        placement="left"
        finalFocusRef={btnRef.current}
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        size={'50vw'}
        closeOnEsc={false}
        {...props}
      >
        {/* <DrawerOverlay /> */}
        <DrawerContent maxW={'50vw'} w="50vw">
          <DrawerHeader>
            <Text textStyle={'medium14'}>매물 {res.data?.total}개</Text>
          </DrawerHeader>

          <DrawerBody>
            <SimpleGrid columns={2} spacing={6}>
              {res.data?.result.map((v: any, index: any) => {
                const [lng, lat] = v.location.coordinates;
                // console.log('v => ', v);
                const isClickedFollowBox = select_room_id === v.room._id;

                // 여기부터

                return (
                  <Box
                    id="room-item"
                    key={index}
                    onMouseEnter={() => {
                      onMouseEnterItem(v.room._id);
                    }}
                  >
                    <Box as={'a'} href={`/admin/manage/roomInfo/${v.room._id}`} target={'_blank'}>
                      <Box borderRadius={'lg'} boxShadow={'md'}>
                        <CustomSliderOne>
                          {v.room.pictures.map((url: any, index: any) => (
                            <Image
                              key={index}
                              src={url}
                              h={'210px'}
                              objectFit={'cover'}
                              borderTopRadius="lg"
                              backgroundColor={'white'}
                            />
                          ))}
                        </CustomSliderOne>
                        <Box bgColor={'white'} borderBottomRadius={'lg'} p={4} pt={10} fontSize={'15px'}>
                          <Box fontWeight={'medium'} fontSize={'14px'}>
                            {v.address}
                          </Box>
                          <Flex fontWeight={600} fontSize={'12px'} flexDir={'column'}>
                            <Flex>
                              <Text w="42px" minW={'42px'}>
                                보증금
                              </Text>
                              <Text>{staticThreeCommafy(v.room.deposit)}원</Text>
                            </Flex>
                            <Flex>
                              <Text w="42px" minW={'42px'}>
                                월세
                              </Text>
                              <Text>{staticThreeCommafy(v.room.rent)}원</Text>
                            </Flex>
                          </Flex>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                );
              })}
            </SimpleGrid>
          </DrawerBody>

          <DrawerFooter>{/*  */}</DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default RoomListDrawer;

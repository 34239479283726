import { Box, Flex, TableCellProps, Text } from '@chakra-ui/react';
import { TdFullWContainer } from '../../../Table/Tr';

export const fixedTitleTdWidth = {
  w: '170px',
  minW: '170px',
};

export const fixedContentTdWidth = {
  w: 'auto',
};

interface CustomTdProps {
  title: any;
  required?: boolean;
  titleTdProps?: TableCellProps;
  ContentTdProps?: TableCellProps;
}

export const CustomTd: React.FC<CustomTdProps> = ({
  title,
  children,
  required = false,
  titleTdProps,
  ContentTdProps,
}) => (
  <TdFullWContainer
    title={title}
    titleTdProps={{ ...titleTdProps, ...fixedTitleTdWidth }}
    required={required}
    ContentTdProps={{ ...ContentTdProps, ...fixedContentTdWidth }}
  >
    {children}
  </TdFullWContainer>
);

interface TableTitleContainerProps {
  title: any;
  table: any;
}

export const titleProperties = {
  mb: 2,
  textStyle: 'bold18',
};

export const TableTitleContainer: React.FC<TableTitleContainerProps> = ({ title, children, table }) => {
  return (
    <Flex flexDir={'column'} w={'full'} alignItems={'stretch'}>
      <Flex alignItems={'flex-end'} w="full" justify={'space-between'} {...titleProperties}>
        <Box>{title}</Box>
        {children}
      </Flex>
      {table}
    </Flex>
  );
};

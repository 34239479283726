import { Divider, Flex, HStack, VStack } from '@chakra-ui/react';
import InfoSector from '../sections/InfoSector';

const Users = () => {
  return (
    <HStack alignItems={'flex-start'} spacing={10} pr={10}>
      {/* section 1 */}
      <Flex w={'250px'}>
        <VStack w={'full'} spacing={6}>
          {/* 신규 입주 신청 */}
          <InfoSector total={0}>신규 입주 신청</InfoSector>
          {/* 매물 전달 요청 */}
          <InfoSector total={0}>매물 전달 요청</InfoSector>
          {/* 투어 예정 */}
          <InfoSector total={0}>투어 예약</InfoSector>
          {/* 임대 계약 예정 */}
          <InfoSector total={0}>임대 계약 예정</InfoSector>
          {/* 임대 계약 완료 */}
          <InfoSector total={0}>임대 계약 완료</InfoSector>
          <Divider />
          {/* 신규 입주 신청 */}
          <InfoSector total={0}>계약 연장 요청</InfoSector>
          {/* 매물 전달 요청 */}
          <InfoSector total={0}>퇴실 승인 요청</InfoSector>
          {/* 투어 예정 */}
          <InfoSector total={0}>민원 요청</InfoSector>
          {/* 임대 계약 예정 */}
          <InfoSector total={0}>입주 예정</InfoSector>
          {/* 임대 계약 완료 */}
          <InfoSector total={0}>퇴실 예정</InfoSector>
        </VStack>
      </Flex>
    </HStack>
  );
};
export default Users;

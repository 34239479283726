import { ArrowLeftIcon, ArrowRightIcon } from '@chakra-ui/icons';
import { Center, chakra, Icon, LinkProps } from '@chakra-ui/react';
import { useState } from 'react';
import { HiDotsHorizontal } from 'react-icons/hi';

interface PageListButtonProps extends LinkProps {
  isright?: boolean;
  isleft?: boolean;
}

const PageListButton: React.FC<PageListButtonProps> = ({ children, isleft, isright, ...props }) => {
  const DoubleArrow = isleft ? ArrowLeftIcon : isright ? ArrowRightIcon : ArrowRightIcon;
  const [hovered, setHovered] = useState(false);
  return (
    <chakra.a
      as={Center}
      boxSize={8}
      //   py={2}
      color="gray.700"
      onMouseOver={() => setHovered(true)}
      onMouseOut={() => setHovered(false)}
      cursor="pointer"
      {...props}
    >
      {hovered ? (
        <Icon as={DoubleArrow} boxSize={3} cursor="pointer" color={'gray.500'} />
      ) : (
        <Icon as={HiDotsHorizontal} color={'gray.300'} boxSize={4} />
      )}
    </chakra.a>
  );
};

export default PageListButton;

import {
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  HStack,
  Input,
  Link,
  Stack,
  Text,
  Tooltip,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Link as RouterLink, Navigate, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import Validation from '../../../../../components/common/FormikValidation/Validation';
import { fetchClient } from '../../../../../lib/common/utils/axios';
import { loginFail, loginSuccess, serverOrTypeNotMatched } from '../../../../../statics/common/data/toast';
import { auth, getWebFcmToken, signIn, useLogout } from '../../../../../lib/common/utils/firebase/init';
import { setLocalStorageStringItem, setWithExpiry } from '../../../../../lib/common/utils/localstorage';
import { Logo_Blue } from '../../../../../statics/common/icons';
import * as KeyPath from '../../../../../statics/common/data/KeyPath';
import { signInWithEmailAndPassword } from 'firebase/auth';

const Login: React.FC = () => {
  const toast = useToast();
  const savedEmail = localStorage.getItem('saved_email-agency-bohome-kr');
  const [idSave, setIdSave] = useState<boolean>(true);
  const [autoLogin, setAutoLogin] = useState<boolean>(true);
  const navigate = useNavigate();
  const _logout = useLogout();
  const formik = useFormik({
    initialValues: { email: '', password: '' },
    validationSchema: Yup.object({
      email: Yup.string().email('유효하지 않는 이메일입니다.'),
      // .required('이메일을 입력해 주세요.'),
      password: Yup.string(),
      // .matches(/\w*[A-z]\w*/, '비밀번호는 영문을 포함하여야 합니다.')
      // .matches(/\d/, '비밀번호는 숫자를 포함하여야 합니다.')
      // .matches(/[!@#$%^&*()\-_"=+{}; :,<.>]/, '비밀번호는 특수문자를 포함하여야 합니다.')
      // .min(8, ({ min }) => `비밀번호는 최소 ${min}글자입니다.`)
      // .max(16, ({ max }) => `비밀번호는 최대 ${max}글자입니다.`),
    }),
    onSubmit: async (values) => {
      const { email, password } = values;
      if (idSave) {
        localStorage.setItem('saved_email-agency-bohome-kr', email);
      }
      try {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        const firebaseUser = userCredential.user;
        const token = await firebaseUser?.getIdToken();
        const instance = await fetchClient({ headers: { token } });
        let result = await instance.get(KeyPath.keyOfGetCompanyAndUserType);
        let user = result?.data?.data as any;
        if (user?.company_type == 'agency' && user?.user_type == 'master') {
          setLocalStorageStringItem('--bohome-company-type', 'agency');
          setLocalStorageStringItem('--bohome-user-type', 'master');
          setLocalStorageStringItem('--bohome-token', token);
          setLocalStorageStringItem('--bohome-email', email);
          setLocalStorageStringItem('--bohome-route-user-type', 'agency');
          setLocalStorageStringItem('--bohome-is-login', 'true');
          navigate('/agency/manage/estates/rooms');
          toast(loginSuccess);
          const web_fcm_token = await getWebFcmToken();
          web_fcm_token && (await instance.post<any>('/agency/master/web-fcm-token', { web_fcm_token }));
        } else {
          console.log('SERVER NOT MATCHED OR TYPE NOT MATCHED');
          toast(serverOrTypeNotMatched);
          _logout();
        }
      } catch (err) {
        toast(loginFail);
      }
    },
  });

  const effect = () => {
    if (savedEmail) {
      setIdSave(!!savedEmail);
      formik.setFieldValue('email', savedEmail);
    }
  };
  useEffect(() => {
    effect();
    return effect();
  }, []);

  return (
    <form style={{ width: '100%', height: '100%' }} onSubmit={formik.handleSubmit}>
      <Flex align={'center'} justify={'center'} h="100%">
        <Flex
          flexDir={'column'}
          minW={'400px'}
          maxW={'400px'}
          justifyContent={'center'}
          h={'100%'}
          bgColor={'white'}
          borderTopLeftRadius={'3rem'}
          px={'40px'}
          pb={'50px'}
        >
          <Flex justifyContent={'center'} mb={'38px'}>
            <Logo_Blue w={'178px'} h={'56px'} />
          </Flex>
          <Box mb={'10px'}>
            <Input
              type="email"
              name="email"
              placeholder="이메일을 입력하세요."
              bgColor={'whiteGray'}
              _placeholder={{ color: 'midGray', fontSize: '14px' }}
              fontSize={'14px'}
              onChange={formik.handleChange}
              boxShadow={'base'}
              value={formik.values.email}
            />
            <Validation error={formik.errors.email} />
          </Box>

          <Box mb={'10px'}>
            <Input
              type="password"
              name="password"
              placeholder="비밀번호를 입력하세요."
              bgColor={'whiteGray'}
              _placeholder={{ color: 'midGray', fontSize: '14px' }}
              fontSize={'14px'}
              boxShadow={'base'}
              onChange={formik.handleChange}
              value={formik.values.password}
            />
            <Validation error={formik.errors.password} />
          </Box>
          <Button variant="basic" height={'40px'} type="submit">
            로그인
          </Button>
          <Stack
            position={'relative'}
            my={'15px'}
            direction={{ base: 'column', sm: 'row' }}
            align={'start'}
            justify={'space-between'}
            w="100%"
          >
            <Checkbox
              onChange={(e) => {
                setIdSave(e.target.checked);
                if (!e.target.checked) {
                  localStorage.removeItem('saved_email-agency-bohome-kr');
                }
              }}
              isChecked={idSave}
            >
              <Text fontSize={'12px'} color={'#6F6F6F'}>
                아이디 저장
              </Text>
            </Checkbox>
          </Stack>
          <Box position={'relative'}>
            <VStack id="here" position={'absolute'} top="150px" right={'0'} left={'0'}>
              <HStack justifyContent={'center'} w="100%" fontSize={'13px'}>
                <Box>보홈이 처음이신가요?</Box>
                <Link
                  as={RouterLink}
                  to="/signup/agency"
                  color={'zetaBlue'}
                  textDecor={'underline'}
                  fontWeight={'bold'}
                >
                  회원가입
                </Link>
              </HStack>
              <Flex fontSize={'9px'}>
                <Text>아이디, 비밀번호 찾기는&nbsp;</Text>

                <Tooltip
                  label={
                    <VStack spacing={0}>
                      <Box>빠른 안내</Box>
                      <Box>1833-9092</Box>
                    </VStack>
                  }
                  placement="bottom"
                  hasArrow
                  arrowShadowColor="#F3F3F3"
                >
                  <Text textDecorationLine={'underline'} cursor={'none'}>
                    고객센터로
                  </Text>
                </Tooltip>
                <Text>&nbsp;문의 부탁드립니다.</Text>
              </Flex>
            </VStack>
          </Box>
        </Flex>
      </Flex>
    </form>
  );
};

export default Login;

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { useUpdateLeasePaymentsStatus } from '../../../../lib/admin/hooks/mutation';
import { settlement_payment_status } from '../../../../statics/common/data/selectbox';
import SelectBox from '../../../agent/SelectBox/base';

interface ChangeSettlementPaymentStatusProps {
  is_transferred: boolean;
  lease_payment_id: string;
}

const ChangeSettlementPaymentStatus: React.FC<ChangeSettlementPaymentStatusProps> = ({
  is_transferred,
  lease_payment_id,
}) => {
  const { mutateAsync } = useUpdateLeasePaymentsStatus();
  const [value, setValue] = useState<boolean>(is_transferred);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();

  const handleChangeOption = (e: any) => {
    const v = e.target.value === 'true' ? true : false;
    setValue(v);
    onOpen();
  };

  const handleFinalize = () => {
    mutateAsync({
      lease_payment_id,
      lease_contract: {
        is_transferred: value,
      },
    }).then(() => {
      onClose();
    });
  };

  const handleCloseAlert = () => {
    setValue(is_transferred);
  };

  const handleCancle = () => {
    handleCloseAlert();
    onClose();
  };

  return (
    <>
      <SelectBox
        value={value ? 'true' : 'false'}
        // value={is_transferred ? 'true' : 'false'}
        onChange={(e) => {
          handleChangeOption(e);
        }}
      >
        {settlement_payment_status.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </SelectBox>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef.current}
        onClose={onClose}
        isCentered
        onEsc={handleCloseAlert}
        onOverlayClick={handleCloseAlert}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              재확인
            </AlertDialogHeader>

            <AlertDialogBody>확정하시겠습니까?</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef.current} variant={'gray'} onClick={handleCancle}>
                취소
              </Button>
              <Button colorScheme="red" onClick={handleFinalize} variant={'basic'} ml={3}>
                확정하기
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default ChangeSettlementPaymentStatus;

import { InfoOutlineIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Center,
  Collapse,
  Flex,
  HStack,
  ListItem,
  StackDivider,
  Table,
  Tbody,
  Text,
  Tooltip,
  Tr,
  UnorderedList,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import axios from 'axios';
import { Field, useFormikContext } from 'formik';
import Dropzone from 'react-dropzone';
import { initialValue, StepProps } from '../..';
import { FRadioButtonGroup } from '../../../../Radio';
import { building_upload_path } from '../../../../../../statics/agent/data/KeyPath';
import { security_facilities_checkbox } from '../../../../../../statics/common/data/checkbox';
import {
  elevator_radio,
  entrance_type_radio,
  management_radio,
  parking_available_radio,
} from '../../../../../../statics/common/data/radio';
import {
  building_form as building_form_data,
  building_type as building_type_data,
} from '../../../../../../statics/common/data/selectbox';
import { auth } from '../../../../../../lib/common/utils/firebase/init';
import SelectBox from '../../../../../agent/SelectBox/base';
import { SingleDatepicker } from '../../../../Calender';
import { FCheckboxButtonGroup } from '../../../../Checkbox/base';
import { CustomInput } from '../../../../Input';
import FacilityThumb from '../../../../Thumb/base';
import AddressFinder from '../../../AddressFinder';
import RequestUpload from '../../../../../agent/Modals/RequestUpload';
import RetrieveBuilding from '../RetrieveBuilding';
import Upper from '../Upper';
import { useEffect, useState } from 'react';
import { CustomTd, TableTitleContainer, titleProperties } from '../type';
import BuildingInfoTable from './a-buildinginfo';
import FacilityInfoTable from './b-facilityinfo';
import ManagementTable from './d-management';
import { UploadBuildingPictureDropZone, UploadBuildingPictureDropZoneContainer } from './e-uploadpicture';
import { useCreateBuilding } from '../../../../../../lib/common/hooks/mutation';
import { success } from '../../../../../../statics/common/data/toast';
import ColivingHostInfoTable from './c-hostinfo';
import ColivingBuildingInfoTable from './a-buildinginfoOfColiving';
import { UploadFacilityDropZoneContainer } from './g-uploadfacility';
import { BuildingDescription } from './f-buildingdescription';
import { FileInfosProps } from '../../../../Dropzone/DropzoneOpacityFormikThumb';
import { filterRemovedFiles } from '../../../../../../lib/common/utils/dropzone/function';

const CommonTypeBuilding: React.FC<StepProps> = ({ step, setStep }) => {
  const { isOpen: isOpenAddressFinder, onOpen: onOpenAddressFinder, onClose: onCloseAddressFinder } = useDisclosure();
  const { isOpen: isRetrieveOpen, onOpen: onRetrieveOpen, onClose: onRetrieveClose } = useDisclosure();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { mutateAsync } = useCreateBuilding();

  const formik = useFormikContext<typeof initialValue>();
  const { values, setFieldValue, handleChange, resetForm } = formik;
  const toast = useToast();
  const {
    building_type,
    building_form,
    building_id,
    address,
    jibun_address,
    road_address,
    zip_code,
    sido_name,
    sigungu_name,
    dongli_name,
    building_number,
    name,
    completion_date,
    floor,
    room_types,
    household_number,
    entrance_type,
    is_elevator,
    is_management,
    security_facilities,
    is_parking,
    parking_num,
    parking_cost,
    building_pictures,
    building_description,
    files,
    facilities,
    host_id,
  } = values;

  useEffect(() => {
    if (is_parking === 'false') {
      setFieldValue('parking_num', '');
      setFieldValue('parking_cost', '');
    }
  }, [is_parking]);

  useEffect(() => {
    if (building_form === 'coliving_house') {
      toast({ ...success, title: '건물형태 변경', description: '프리미엄오피스텔을 선택하셨습니다.' });
    }
  }, [building_form]);

  const isColiving = building_form === 'coliving_house';

  return (
    <Box w="full" h="full">
      {/* 1 - progressive bar */}
      <Upper step={step} />
      {/* 2 - 정보 등록 */}
      <VStack mx={'150px'} my={'50px'} spacing={7}>
        {/* a. 건물 정보 */}
        <TableTitleContainer
          title="건물 정보"
          table={isColiving ? <ColivingBuildingInfoTable /> : <BuildingInfoTable />}
        >
          <HStack>
            <Button variant={'basic'} onClick={onRetrieveOpen}>
              건물 정보 불러오기
            </Button>
          </HStack>
        </TableTitleContainer>
        {/* b. 건물 시설 정보 */}
        <TableTitleContainer title="건물 시설 정보" table={<FacilityInfoTable />} />
        {/* c. 임대인 정보 */}
        {isColiving && <TableTitleContainer title="임대인 정보" table={<ColivingHostInfoTable />} />}
        {/* d. 임대관리 신청 여부 */}
        <TableTitleContainer title="임대관리 신청 여부" table={<ManagementTable />} />
        {/* e. 건물 사진 등록 */}
        <UploadBuildingPictureDropZoneContainer />
        {/* f. 건물 설명 등록 */}
        <BuildingDescription />
        {/* g. 공용시설 등록 */}
        {isColiving && <UploadFacilityDropZoneContainer />}

        {/* 다음 버튼 */}
        <Flex justifyContent={'flex-end'} w={'100%'}>
          <Button
            variant={'basic'}
            w={'150px'}
            disabled={!building_type || !building_form || !address || !is_elevator || !is_parking || !is_management}
            isLoading={isSubmitting}
            onClick={async () => {
              setIsSubmitting(true);
              const formData = new FormData();
              formData.append('building_type', building_type);
              formData.append('building_form', building_form);
              formData.append('address', address);
              formData.append('jibun_address', jibun_address);
              formData.append('road_address', road_address);
              formData.append('zip_code', zip_code);
              formData.append('sido_name', sido_name);
              formData.append('sigungu_name', sigungu_name);
              formData.append('dongli_name', dongli_name);
              formData.append('building_number', building_number);
              formData.append('entrance_type', entrance_type);
              formData.append('name', name);
              formData.append('completion_date', completion_date);
              formData.append('floor', floor);
              formData.append('household_number', household_number);
              formData.append('is_elevator', is_elevator);
              formData.append('room_types', JSON.stringify(room_types));
              security_facilities.map((security: any) => {
                formData.append('security_facilities[]', security);
              });
              const filtered_facilities = facilities.filter((facility: any) => !facility.removed);
              formData.append(
                'facilities',
                JSON.stringify(filtered_facilities?.map((facility: any) => ({ name: facility.name }))),
              );
              filtered_facilities?.map(
                (
                  facility: {
                    name: string;
                    removed: boolean;
                    file_infos: FileInfosProps[];
                  },
                  index,
                ) => {
                  const [filtered_exists, _1, filtered_Files, filtered_urls] = filterRemovedFiles(facility.file_infos);

                  filtered_Files.map((file) => {
                    formData.append(`facility_pictures${index}`, file);
                  });
                  formData.append(
                    `facility_picture_urls${index}`,
                    JSON.stringify(
                      filtered_urls.map((url) => {
                        return url;
                      }),
                    ),
                  );
                },
              );
              formData.append('host_id', host_id);
              formData.append('is_parking', is_parking);
              formData.append('is_management', is_management);
              formData.append('parking_num', parking_num);
              formData.append('parking_cost', parking_cost);

              files.map((file: any) => {
                formData.append('building_pictures', file);
              });

              formData.append('building_description', building_description);

              await mutateAsync({
                formData: formData,
              }).then((result) => {
                const building_id = (result as any).data.data.building_id;
                setFieldValue('building_id', building_id);
                setStep(1);
              });
            }}
          >
            다음
          </Button>
        </Flex>
        {/* Modal */}
        <AddressFinder isOpen={isOpenAddressFinder} onClose={onCloseAddressFinder} formik={formik} />
        <RetrieveBuilding isOpen={isRetrieveOpen} onClose={onRetrieveClose} step={step} setStep={setStep} />
      </VStack>
    </Box>
  );
};

export default CommonTypeBuilding;

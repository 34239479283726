export const success = {
  title: '성공',
  description: '성공',
  status: 'success',
  variant: 'success',
  duration: 2000,
  isClosable: true,
  position: 'bottom',
} as const;

export const warning = {
  title: '경고',
  description: '경고',
  status: 'warning',
  variant: 'warning',
  duration: 2000,
  isClosable: true,
} as const;

export const error = {
  title: '에러',
  description: `에러`,
  status: 'error',
  variant: 'error',
  duration: 2000,
  isClosable: true,
} as const;

export const loginSuccess = {
  title: '로그인 성공',
  description: '로그인 성공',
  status: 'success',
  variant: 'success',
  duration: 2000,
  isClosable: true,
  position: 'bottom',
} as const;

export const AuthSuccess = {
  title: '인증 성공',
  description: '인증 성공',
  status: 'success',
  variant: 'success',
  duration: 2000,
  isClosable: true,
  position: 'bottom',
} as const;

export const loginFail = {
  title: '로그인 실패',
  description: '로그인 실패',
  status: 'error',
  variant: 'error',
  duration: 2000,
  isClosable: true,
  position: 'bottom',
} as const;

export const serverOrTypeNotMatched = {
  title: '로그인 페이지 재확인',
  description: '로그인 페이지 재확인',
  status: 'error',
  variant: 'error',
  duration: 2000,
  isClosable: true,
  position: 'bottom',
} as const;

export const duplicated = {
  title: '중복',
  description: `중복된 사업자가 존재합니다.`,
  status: 'error',
  variant: 'error',
  duration: 2000,
  isClosable: true,
} as const;

export const failToUpload = {
  title: '파일 업로드 실패',
  description: `파일의 크기를 확인하시거나 개수가 5장을 넘지 않는지 확인하세요.`,
  status: 'error',
  variant: 'error',
  duration: 2000,
  isClosable: true,
} as const;

export const locationDup = {
  title: '영업 지역 중복',
  description: '새로운 영업 지역을 선택해주세요.',
  status: 'warning',
  variant: 'warning',
  duration: 2000,
  isClosable: true,
} as const;

export const locationEntire = {
  title: '영업 지역 중복',
  description: '이미 전체 읍/면/동이 선택되었습니다.',
  status: 'warning',
  variant: 'warning',
  duration: 2000,
  isClosable: true,
} as const;

export const locationExceed = {
  title: '최대 영업 지역 초과',
  description: '영업 지역은 최대 10개까지 선택 가능합니다.',
  status: 'warning',
  variant: 'warning',
  duration: 2000,
  isClosable: true,
} as const;

export const agreementFulfill = {
  title: '약관 동의',
  description: '모든 필수 약관 동의가 필요합니다.',
  status: 'warning',
  variant: 'warning',
  duration: 2000,
  isClosable: true,
  position: 'bottom',
} as const;

export const inputFulfill = {
  title: '추가 기입 필요',
  description: '모든 사항에 기입이 필요합니다.',
  status: 'warning',
  variant: 'warning',
  duration: 2000,
  isClosable: true,
  position: 'bottom',
} as const;

export const verifyAccountSuccess = {
  title: '계좌 인증 성공',
  description: '계좌 인증에 성공했습니다.',
  status: 'success',
  variant: 'success',
  duration: 2000,
  isClosable: true,
  position: 'bottom',
} as const;

export const verifyAccountDuplicated = {
  title: '계좌 중복',
  description: '중복된 계좌가 존재합니다.',
  status: 'warning',
  variant: 'warning',
  duration: 2000,
  isClosable: true,
  position: 'bottom',
} as const;

export const verifyAccountFail = {
  title: '계좌 인증 실패',
  description: '계좌 인증에 실패했습니다.',
  status: 'error',
  variant: 'error',
  duration: 2000,
  isClosable: true,
  position: 'bottom',
} as const;

export const saveSuccess = {
  title: '저장 성공',
  description: '저장에 성공했습니다.',
  status: 'success',
  variant: 'success',
  duration: 2000,
  isClosable: true,
  position: 'bottom',
} as const;

export const saveCancle = {
  title: '저장 취소',
  description: '저장을 취소했습니다.',
  status: 'error',
  variant: 'error',
  duration: 2000,
  isClosable: true,
  position: 'bottom',
} as const;

export const saveFail = {
  title: '저장 실패',
  description: '저장에 실패했습니다.',
  status: 'error',
  variant: 'error',
  duration: 2000,
  isClosable: true,
  position: 'bottom',
} as const;

export const password_notFillIn = {
  title: '에러',
  description: '비밀번호를 입력해주시기 바랍니다.',
  status: 'warning',
  variant: 'warning',
  position: 'bottom-right',
  duration: 2000,
  isClosable: true,
} as const;

export const password_notMatched = {
  title: '에러',
  description: '입력한 비밀번호가 일치하지 않습니다.',
  status: 'error',
  variant: 'error',
  position: 'bottom-right',
  duration: 2000,
  isClosable: true,
} as const;

export const overTried = {
  title: '에러',
  description: '입력 횟수를 초과했습니다. 잠시 후 다시 시도해 주시기 바랍니다.',
  status: 'error',
  variant: 'error',
  position: 'bottom-right',
  duration: 2000,
  isClosable: true,
} as const;

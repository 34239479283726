import { useState, useCallback, useEffect } from 'react';

export const useLocalFormik = (key: string) => {
  const initialState = {};

  const [state, setState] = useState(() => {
    const localStorageItem = localStorage.getItem(key);
    if (localStorageItem === null) {
      return initialState;
    }
    try {
      return JSON.parse(localStorageItem);
    } catch (err) {
      return initialState;
    }
  });

  const onChange = useCallback(
    (e) => {
      console.log('onChange');

      const { value } = e.target;
      setState({ ...JSON.parse(localStorage.getItem(key) as any), [e.target.name]: value });
    },
    [key],
  );

  const onSet = useCallback(
    (object) => {
      setState({ ...JSON.parse(localStorage.getItem(key) as any), ...object });
    },
    [key],
  );

  const onImageSet = useCallback(
    (imgArr) => {
      const arrData = [];
      for (let i = 0; i < imgArr.length; i++) {
        const file = imgArr[i];
        const obj = {
          lastModified: file.lastModified,
          name: file.name,
          size: file.size,
          type: file.type,
          webkitRelativePath: file.webkitRelativePath,
        };
        arrData.push(obj);
      }

      setState({ ...JSON.parse(localStorage.getItem(key) as any), ...{ images: imgArr } });
      // setState({ ...JSON.parse(localStorage.getItem(key) as any), ...{ images: imgArr } });
    },
    [key],
  );

  // const onSet = useCallback(
  //   (objectKey: string, objectValue: string) => {
  //     console.log('test1');

  //     setState({ ...JSON.parse(localStorage.getItem(key) as any), [objectKey]: objectValue });
  //   },
  //   [key],
  // );

  // const onMultiSet = useCallback(() => {

  // }, []);

  const onReset = useCallback(
    (objectKey) => {
      localStorage.removeItem(objectKey);
      setState({ ...JSON.parse(localStorage.getItem(key) as any) });
    },
    [key],
  );

  const onAllRest = useCallback(() => {
    setState(initialState);
  }, []);

  useEffect(() => {
    // console.log('state in userEffect => ', JSON.stringify(state));

    localStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);

  return [state, onChange, onSet, onImageSet, onReset];
};

import { Box, Button, Center, Divider, Flex, HStack, useDisclosure, useToast, VStack } from '@chakra-ui/react';
import axios from 'axios';
import { FirebaseError } from 'firebase/app';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import { FieldArray, useFormikContext } from 'formik';
import { useState } from 'react';
import { QueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { initialValue, StepProps } from '..';
import { queryClient } from '../../../../..';
import RemoveButton from '../../../../../components/common/Button/RemoveButton';
import { base, fetchClient } from '../../../../../lib/common/utils/axios';
import { deDashfy } from '../../../../../lib/common/utils/digitformatter';
import { failToUpload, inputFulfill } from '../../../../../statics/common/data/toast';
import { auth } from '../../../../../lib/common/utils/firebase/init';
import { setLocalStorageStringItem } from '../../../../../lib/common/utils/localstorage';
import AddWorkLocation from './Modal/AddWorkLocation';

const Four: React.FC<StepProps> = ({ setStep }) => {
  const { values, setFieldValue, handleChange } = useFormikContext<typeof initialValue>();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast_notFulfill = useToast();
  const navigate = useNavigate();

  return (
    <Flex flexDir={'column'} h={'full'} justifyContent={'space-between'}>
      {/* 중개 사무소 정보 */}
      <VStack w="100%">
        <Flex flexDir={'column'} fontWeight={'bold'} fontSize={'15px'} textAlign={'start'} flexGrow={1} w={'100%'}>
          <Box>영업 지역</Box>
          <HStack>
            <Box mt={2} fontWeight={'normal'} fontSize={'12px'}>
              중개 활동을 하고 있는 지역을 최대 10개까지 선택해주세요. 해당 지역의 매물 요청을 받을 수 있습니다.
            </Box>
          </HStack>
        </Flex>
        <Flex w="100%">
          <VStack w={'full'}>
            <Center
              w={'full'}
              bgColor={'#F8F8F8'}
              h={'40px'}
              borderWidth={'1px'}
              borderTopColor={'borderWhite'}
              fontSize={'12px'}
            >
              주요 영업 지역
            </Center>
            {/* 추가 전 */}
            {values.sales_regions.length === 0 ? (
              <Center w={'full'} borderBottomWidth={'1px'} borderBottomColor={'gray.200'} pb={2}>
                <Button variant={'basic'} style={{ width: '85px', height: '32px' }} minW="85px" onClick={onOpen}>
                  추가하기
                </Button>
              </Center>
            ) : (
              <HStack
                w={'full'}
                justifyContent={'space-between'}
                alignItems={'flex-start'}
                borderBottomWidth={'1px'}
                borderBottomColor={'gray.200'}
                pb={2}
              >
                <Flex flexWrap={'wrap'}>
                  <FieldArray name="sales_regions">
                    {({ insert, remove, push }) =>
                      values.sales_regions.map((location, index) => (
                        <RemoveButton
                          mr={2}
                          mb={1}
                          boxSize={'max-content'}
                          height={'32px'}
                          key={index}
                          onClick={() => remove(index)}
                        >
                          {(location as any).sigungu_name}&nbsp;
                          {(location as any).dongli_name}
                        </RemoveButton>
                      ))
                    }
                  </FieldArray>
                </Flex>
                <Button variant={'basic'} style={{ width: '85px', height: '32px' }} minW="85px" onClick={onOpen}>
                  추가하기
                </Button>
              </HStack>
            )}
            <Divider />
          </VStack>
        </Flex>
      </VStack>
      <HStack mt={20}>
        <Button variant={'basic'} boxShadow={'md'} w={'105px'} height={'40px'} onClick={() => setStep(2)}>
          이전
        </Button>
        <Button
          variant={'gray'}
          boxShadow={'md'}
          w={'full'}
          style={{ height: '40px' }}
          onClick={async () => {
            if (values.sales_regions) {
              try {
                setLocalStorageStringItem('--bohome-route-user-type', 'agency');
                setStep(4);
                const {
                  company_name,
                  ceo,
                  business_registration_number,
                  business_certificate,
                  brokerage_license,
                  brokerage_number,
                  address,
                  road_address,
                  detail_address,
                  sido_name,
                  sigungu_name,
                  dongli_name,
                  phone_number,
                  sales_regions,
                  email,
                  phone_number_first,
                } = values;
                await createUserWithEmailAndPassword(auth, values.email, values.p_password);
                const userCredential = await signInWithEmailAndPassword(auth, email, values.p_password);
                const firebaseUser = userCredential.user;
                const token = await firebaseUser.getIdToken();
                const instance = await fetchClient({
                  headers: { token },
                });
                await instance.post('agency/master/token/verify', {
                  firebase_uid: (firebaseUser as any).accessToken,
                  login_type: 'email',
                  email: values.email,
                });

                const companyFormData = new FormData();
                companyFormData.append('company_name', company_name);
                companyFormData.append('ceo', ceo);
                companyFormData.append('business_registration_number', deDashfy(business_registration_number));
                companyFormData.append('business_certificate', business_certificate);
                companyFormData.append('brokerage_license', brokerage_license);
                companyFormData.append('brokerage_number', brokerage_number);
                companyFormData.append('address', address);
                companyFormData.append('road_address', road_address);
                companyFormData.append('detail_address', detail_address);
                companyFormData.append('sido_name', sido_name);
                companyFormData.append('sigungu_name', sigungu_name);
                companyFormData.append('dongli_name', dongli_name);
                companyFormData.append('phone_number', phone_number);
                await instance.post('agency/master/company', companyFormData);

                const userFormData = new FormData();
                userFormData.append('user_name', ceo);
                userFormData.append('email', email);
                userFormData.append('phone_number_first', phone_number_first);
                userFormData.append('sales_regions', JSON.stringify(sales_regions));
                userFormData.append('company_name', company_name);
                userFormData.append('business_registration_number', deDashfy(business_registration_number));
                await instance.post('agency/master/profile/init', userFormData);

                setLocalStorageStringItem('--bohome-company-type', 'agency');
                setLocalStorageStringItem('--bohome-user-type', 'master');
                setLocalStorageStringItem('--bohome-token', token);
                setLocalStorageStringItem('--bohome-email', email);
                setLocalStorageStringItem('--bohome-route-user-type', 'agency');
                setLocalStorageStringItem('--bohome-is-login', 'true');

                navigate('/agency/manage/estates/rooms');
              } catch (err: any) {
                if (err?.message.includes('(auth/email-already-in-use)')) {
                  console.log('(auth/email-already-in-use)');
                }
                toast_notFulfill({
                  ...failToUpload,
                  title: '고객센터 문의',
                  description: '에러가 발생했습니다. 고객센터 1833-9092로 문의주시길 바랍니다.',
                });
              }
            } else {
              toast_notFulfill(inputFulfill);
            }
          }}
        >
          가입
        </Button>
      </HStack>
      <AddWorkLocation isOpen={isOpen} onClose={onClose} />
    </Flex>
  );
};

export default Four;

import { Flex, Table, Tbody, Tr } from '@chakra-ui/react';
import { Field } from 'formik';
import { CustomTd } from '../type';
import { FRadioButtonGroup } from '../../../../Radio';
import { management_radio } from '../../../../../../statics/common/data/radio';

const ManagementTable = () => {
  return (
    <>
      <Table w={'100%'} variant="simple">
        <Tbody w={'100%'}>
          <Tr w={'100%'}>
            <CustomTd title={'임대 관리 신청'} required>
              <Flex w={'20%'}>
                <Field name="is_management" as={FRadioButtonGroup} options={management_radio} />
              </Flex>
            </CustomTd>
          </Tr>
        </Tbody>
      </Table>
    </>
  );
};

export default ManagementTable;

import { getLocalStorageStringItem } from './localstorage';

export const isJsonString = (arg: any) => {
  let result = false;
  try {
    if (typeof arg == 'string') {
      if (arg.length > 0) {
        const firstElem = arg[0];
        const lastElem = arg.slice(-1)[0];
        if (firstElem === '{' && lastElem === '}') {
          result = true;
        }
      }
    }
  } catch (err) {
    console.log(err);
  } finally {
    // eslint-disable-next-line no-unsafe-finally
    return result;
  }
};

export const isCachedItems = (...args: Array<any>) => {
  let result = true;
  result = args?.reduce((pre, curr) => {
    let item = getLocalStorageStringItem(curr);
    if (!item) {
      pre = false;
    }
    return pre;
  }, true);
  return result;
};

interface IsEnabledProps {
  isEnabled?: boolean;
  user?: any;
}
export const isEnabledQuery = ({ isEnabled, user }: IsEnabledProps) => {
  let result = false;
  try {
    result = isEnabled === undefined ? !!user : isEnabled && !!user;
  } catch (err) {
    console.error(err);
  } finally {
    return result;
  }
};

import {
  Box,
  Button,
  HStack,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

interface ReCheckAlertModalProps {
  disabled?: boolean;
  callback?: () => void;
  content?: any;
}

const ReCheckAlertModal: React.FC<ReCheckAlertModalProps> = ({ children, disabled = false, callback, content }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleModalOpen = () => {
    if (!disabled) {
      onOpen();
    }
  };

  const onClickCancelButton = () => {
    onClose();
  };

  const onClickConfirmButton = () => {
    // 함수 실행
    callback?.();
    onClose();
  };

  return (
    <>
      <Box onClick={handleModalOpen}>{children}</Box>

      <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside" isCentered size={'sm'}>
        <ModalOverlay />
        <ModalContent px={5} py={4}>
          <ModalCloseButton />
          {/* <Scrollbars style={{ height: '100%' }}> */}
          <Box w="full" h="full" pb={4}>
            <Box textAlign={'left'}>
              <Text textStyle={'bold18'}>재확인</Text>
            </Box>
          </Box>
          {(content ??= <Box>닫기 또는 확정하시겠습니까?</Box>)}
          <HStack w="full" mt={4} justify={'right'}>
            <Button variant={'gray_hoverGray'} w={'100px'} height={'34px'} onClick={onClickCancelButton}>
              <Text textStyle={'medium14'}>닫기</Text>
            </Button>
            <Button variant={'basic'} w={'100px'} onClick={onClickConfirmButton}>
              <Text textStyle={'medium14'}>확정</Text>
            </Button>
          </HStack>
          {/* </Scrollbars> */}
        </ModalContent>
      </Modal>
    </>
  );
};

export default ReCheckAlertModal;

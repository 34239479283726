import { VStack, Box, Center, Button, Flex, HStack, Text, Tooltip } from '@chakra-ui/react';
import { Field, useFormikContext } from 'formik';
import { formikInitialValuesProps, initialValuesProps } from '.';
import { FRadioButtonGroup } from '../../Radio';
import { alternative_radio } from '../../../../statics/common/data/radio';
import { CheckCircleIcon } from '../../../../statics/common/icons';
import RegisterContractInfoModal from '../RegisterContractInfoModal';
import RegisterHostsInfoModal from '../RegisterHostsInfoModal';
import RoomCheckForConfirmModal from '../RoomCheckForConfirmModal';

interface BoxContainerProps {
  name: 'room' | 'host' | 'contract';
}

const BoxContainer: React.FC<BoxContainerProps> = ({ name, children }) => {
  const {
    values: {
      is_clicked_register_room_button_once,
      is_clicked_register_host_button_once,
      is_clicked_register_contract_button_once,
    },
  } = useFormikContext<formikInitialValuesProps>();

  const isChecked = {
    room: is_clicked_register_room_button_once,
    host: is_clicked_register_host_button_once,
    contract: is_clicked_register_contract_button_once,
  }[name];

  const WrappedModal = {
    room: RoomCheckForConfirmModal,
    host: RegisterHostsInfoModal,
    // host: RegisterHostsInfoModal,
    contract: RegisterContractInfoModal,
  }[name];

  const isClickable = {
    room:
      !is_clicked_register_room_button_once &&
      !is_clicked_register_host_button_once &&
      !is_clicked_register_contract_button_once,
    host:
      is_clicked_register_room_button_once &&
      !is_clicked_register_host_button_once &&
      !is_clicked_register_contract_button_once,
    contract:
      is_clicked_register_room_button_once &&
      is_clicked_register_host_button_once &&
      !is_clicked_register_contract_button_once,
  }[name];

  const displayText = (() => {
    if (isChecked) {
      return '등록 완료';
    } else {
      if (!isClickable) {
        return '';
      }
      return '등록하기';
    }
  })();

  return (
    <Box
      borderWidth="1px"
      bgColor={isChecked ? 'zetaBlue' : 'lotionWhite'}
      borderColor={isChecked ? 'zetaBlue' : 'silverGray'}
      color={isChecked ? 'white' : 'highGray'}
      borderRadius={'md'}
      w="full"
      fontSize={'14px'}
    >
      <Box p={3}>
        <Center justifyContent={'space-between'}>
          <Text>{children}</Text>
          <WrappedModal disabled={!isClickable} necessityAddingHost={true}>
            <Text as={'u'} cursor={isClickable ? 'pointer' : 'initial'}>
              {/* {isChecked ? '등록완료' : '등록하기'} */}
              {displayText ? displayText : ''}
            </Text>
          </WrappedModal>
        </Center>
      </Box>
    </Box>
  );
};

export const ZeroMain = () => {
  const {
    values: {
      is_clicked_register_room_button_once,
      is_clicked_register_host_button_once,
      is_clicked_register_contract_button_once,
    },
  } = useFormikContext<formikInitialValuesProps>();

  const isAbleConfirm =
    is_clicked_register_room_button_once &&
    is_clicked_register_host_button_once &&
    is_clicked_register_contract_button_once;

  return (
    <>
      <Flex flexDir={'column'} mx={10} my={10} alignItems={'flex-start'}>
        <HStack>
          <CheckCircleIcon w={8} h={8} />
          <Text fontSize={'23px'}>계약 완료 등록</Text>
        </HStack>
        <Box w="full">
          <VStack fontSize={'18px'} alignItems={'start'}>
            <VStack spacing={0} alignItems={'start'} mt={2} mb={4}>
              <Box>{'해당 유저의 계약이 완료되었습니까?'}</Box>
              <Box>{'계약 내용을 등록해 주세요.'}</Box>
            </VStack>
            <BoxContainer name="room">1. 매물 정보 확인</BoxContainer>
            <BoxContainer name="host">2. 임대인 정보 등록</BoxContainer>
            <BoxContainer name="contract">3. 계약 정보 등록</BoxContainer>
          </VStack>
          <Center w="full">
            <Tooltip label={'클릭하면 계약이 완료됩니다!'} hasArrow>
              <Button
                mt={'30px'}
                variant={'basic'}
                w={'140px'}
                fontSize={'14px'}
                fontWeight={'bold'}
                type="submit"
                isDisabled={!isAbleConfirm}
              >
                계약 완료
              </Button>
            </Tooltip>
          </Center>
        </Box>
      </Flex>
    </>
  );
};

import {
  Avatar,
  Box,
  Button,
  Container,
  Divider,
  Flex,
  Grid,
  HStack,
  ListItem,
  Radio,
  RadioGroup,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Tr,
  UnorderedList,
  VStack,
} from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { GTitleItem } from '../../../../../../components/common/GridItem';
import { useGetCompanyContract } from '../../../../../../lib/subscriber/hooks/query';
import { fetchClient } from '../../../../../../lib/common/utils/axios';
import { headCount_convert } from '../../../../../../statics/common/data/radio';
import { staticThreeCommafy } from '../../../../../../lib/common/utils/digitformatter';
import { LeftArrowIcon } from '../../../../../../statics/common/icons';
import bank_mask_logo from '../../../../../../statics/common/images/sinhan_bank_logo.jpeg';
import SelectBox from '../../../../../../components/agent/SelectBox/base';
import { format } from 'date-fns';
import { datedotformat } from '../../../../../../lib/common/utils/dateformatter';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../../../../../lib/common/utils/firebase/init';

const SubscriptionInvoicePage: React.FC = () => {
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const { data: contractData, isFetched } = useGetCompanyContract();

  if (contractData === undefined || isFetched === false) {
    return null;
  }

  const initialValues = {
    planType: 'basicPlan',
    headCount: contractData.available_user_count,
    paymentSubscription: '',
    paymentMethod: contractData.is_year ? 'is_year' : 'is_month',
    issuanceMethod: contractData.certificate_issue_type,
  };

  console.log('contractData => ', contractData);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(false);
        const { planType, headCount, paymentSubscription, paymentMethod, issuanceMethod } = values;
        const token = await user?.getIdToken();
        const instance = await fetchClient({ headers: { token } });
        const value = await instance.post('/subscriber/master/subscription-contract', {
          payment_day: 20,
          subscription_type: 'basic',
          is_year: paymentMethod === 'is_year',
          available_user_count: headCount,
          certificate_issue_type: issuanceMethod,
          request_type: 'changing',
        });
        const subscription_contract_id = value.data?.data._id;
        await instance.post(
          `/subscriber/master/subscription-contracts/${subscription_contract_id}/subscription-payment/init`,
        );
        navigate('/company/manage/subscription', {
          replace: true,
        });
      }}
    >
      {({ values, errors, isValid, handleChange, setFieldValue }) => {
        const fee_per_person = 50000;
        const is_year = values.paymentMethod === 'is_year';
        const month = 1;
        const year = month * 12;
        const discount = 0.1;
        const vat = 0.1;
        //
        const monthlyFee_number = fee_per_person * values.headCount;
        const monthlyFee = staticThreeCommafy(monthlyFee_number);

        // only is_year
        const discounted_number = year * monthlyFee_number * discount;
        const discounted = staticThreeCommafy(discounted_number);
        // --
        const finalFee_beforeVAT_number = is_year
          ? year * monthlyFee_number * (1 - discount)
          : 3 * month * monthlyFee_number;
        const finalFee_number = Math.floor(finalFee_beforeVAT_number * (1 + vat));
        const finalFee = staticThreeCommafy(finalFee_number);
        const vatFee_number = finalFee_beforeVAT_number * vat;
        const vatFee = staticThreeCommafy(vatFee_number);

        return (
          <Form style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
            <Grid h="100%" gridAutoRows={'minmax(170px, auto)'} templateColumns="repeat(13, 1fr)" gap={6}>
              {/* 플랜 정보 */}
              <GTitleItem
                upperSection={
                  <HStack>
                    <Button
                      style={{ all: 'unset', cursor: 'pointer' }}
                      as={RouterLink}
                      to={'/company/manage/subscription'}
                    >
                      <LeftArrowIcon w={6} h={6} />
                    </Button>
                    <Text fontSize={'18px'} fontWeight={'medium'}>
                      청구서
                    </Text>
                  </HStack>
                }
                rowSpan={4}
                colSpan={9}
              >
                <Container maxW={'unset'} mx={4} my={7}>
                  <VStack alignItems={'flex-start'} spacing={7}>
                    <VStack alignItems={'flex-start'}>
                      <Text fontSize={'18px'} fontWeight={'bold'}>
                        구독하실 플랜
                      </Text>
                      <VStack>
                        <HStack>
                          <Text fontSize={'14px'} minW="65px">
                            플랜 종류
                          </Text>
                          <SelectBox
                            placeholder="베이직 플랜"
                            h={'30px'}
                            w={'150px'}
                            fontSize={'12px'}
                            icon={<Box />}
                            isDisabled
                            _disabled={{
                              opacity: 1,
                              cursor: 'text',
                            }}
                          >
                            {/* <option>1</option> */}
                          </SelectBox>
                        </HStack>
                        <HStack>
                          <Text fontSize={'14px'} minW="65px">
                            이용 인원
                          </Text>
                          <SelectBox
                            h={'30px'}
                            w={'150px'}
                            fontSize={'12px'}
                            onChange={(e) => {
                              setFieldValue('headCount', parseInt(e.target.value));
                            }}
                            defaultValue={contractData.available_user_count}
                            isDisabled
                          >
                            {headCount_convert.map((value) => (
                              <option key={value.label} value={value.value}>
                                {value.label}
                              </option>
                            ))}
                          </SelectBox>
                        </HStack>
                      </VStack>
                    </VStack>
                    <VStack alignItems={'flex-start'}>
                      <Text fontSize={'18px'} fontWeight={'bold'}>
                        결제 방식
                      </Text>
                      <VStack>
                        <RadioGroup
                          defaultValue={contractData.is_year ? 'is_year' : 'is_month'}
                          onChange={(e) => {
                            setFieldValue('paymentMethod', e);
                          }}
                          // value={value}
                          isDisabled
                        >
                          <VStack alignItems={'flex-start'}>
                            <Radio size={'sm'} value="is_month" colorScheme={'facebook'}>
                              <Text fontSize={'14px'}>월 결제</Text>
                            </Radio>
                            <Radio size={'sm'} value="is_year" colorScheme={'facebook'}>
                              <HStack spacing={4}>
                                <Text fontSize={'14px'}>연 결제</Text>
                                <Tag variant={'green'} fontWeight={'bold'} borderRadius={'lg'}>
                                  {discount * 100}% 할인
                                </Tag>
                              </HStack>
                            </Radio>
                          </VStack>
                        </RadioGroup>
                      </VStack>
                    </VStack>
                    <VStack alignItems={'flex-start'} w="full" pr={8}>
                      <Text fontSize={'18px'} fontWeight={'bold'}>
                        결제 수단
                      </Text>

                      <VStack w="full">
                        <Table variant={'simple_same'} border="1px solid" borderColor={'gray.200'}>
                          <Tbody>
                            <Tr border="1px solid" borderColor={'gray.200'}>
                              <Td>
                                <Radio size={'sm'} colorScheme={'facebook'} defaultChecked isDisabled>
                                  <Text fontSize={'14px'}>계좌이체</Text>
                                </Radio>
                              </Td>
                            </Tr>
                            <Tr border="1px solid" borderColor={'gray.200'}>
                              <Td border="1px solid" borderColor={'gray.200'} rowSpan={2}>
                                <Text fontSize={'14px'}>증빙 발행 수단</Text>
                              </Td>
                              <Td>
                                <RadioGroup
                                  defaultValue={contractData.certificate_issue_type}
                                  onChange={(e) => {
                                    setFieldValue('issuanceMethod', e);
                                  }}
                                  // value={value}
                                  width={'full'}
                                  isDisabled
                                >
                                  <HStack alignItems={'flex-start'} w="full" spacing={'50px'}>
                                    <Radio size={'sm'} value="tax_invoice" colorScheme={'facebook'}>
                                      <Text fontSize={'14px'}>세금계산서 신청</Text>
                                    </Radio>
                                    <Radio size={'sm'} value="cash_receipt" colorScheme={'facebook'}>
                                      <Text fontSize={'14px'}>현금 영수증 신청</Text>
                                    </Radio>
                                  </HStack>
                                </RadioGroup>
                              </Td>
                            </Tr>
                            <Tr border="1px solid" borderColor={'gray.200'}>
                              <Td>
                                <Box fontSize={'14px'}>
                                  <UnorderedList>
                                    <ListItem>
                                      구독 계약 서명 및 결제 완료 후&nbsp;
                                      <Text fontSize={'14px'} color="#FB4667" display={'inline-block'}>
                                        7일이내&nbsp;
                                      </Text>
                                      현금영수증 및 세금계산서가 발행됩니다.
                                    </ListItem>
                                  </UnorderedList>
                                </Box>
                              </Td>
                            </Tr>
                          </Tbody>
                        </Table>
                      </VStack>
                    </VStack>
                    <VStack alignItems={'flex-start'}>
                      <Text fontSize={'18px'} fontWeight={'bold'}>
                        구독 안내 사항
                      </Text>
                      <UnorderedList fontSize={'14px'} pl={6} pr={8}>
                        <ListItem>
                          정기 결제 구독시, 플랜 신청 및 플랜 변경시 첫 3개월 구독료는 선납이며 이 기간 동안 플랜 변경은
                          불가능합니다. 4개월차부터 매 달 전자(세금)계산서를 송부해 드립니다.
                        </ListItem>
                        <ListItem>
                          연 결제시, 할인된 금액으로 결제하게 되며, 플랜 업그레이드만 가능합니다. 업그레이드 시 추가
                          결제금액은 할인이 불가합니다.
                        </ListItem>
                        <ListItem>
                          결제 요청시, 계약서와 함께 전자세금계산서를 발행하여 담당자 이메일로 송부해드리고 있습니다.
                          전자세금계산서에 따라 결제 완료시 구독이 시작 됩니다.
                        </ListItem>
                        <ListItem>환불 규정 및 자세한 사항은 계약서를 확인해 주세요.</ListItem>
                      </UnorderedList>
                    </VStack>
                  </VStack>
                </Container>
              </GTitleItem>
              <GTitleItem
                upperSection={
                  <HStack justifyContent={'space-between'}>
                    <Text fontSize={'18px'} fontWeight={'medium'}>
                      견적 상세
                    </Text>
                    <Text textStyle={'medium14'}>VAT포함</Text>
                  </HStack>
                }
                rowSpan={1}
                colSpan={4}
                // minW={'340px'}
              >
                <Flex flexDir={'column'} p={6} w="full" h="full" justify={'space-between'}>
                  <VStack spacing={3}>
                    <HStack justifyContent={'space-between'} w="full">
                      <Text fontSize={'16px'}>월 구독료</Text>
                      <Text fontSize={'16px'}>{monthlyFee}</Text>
                    </HStack>
                    <Divider borderColor={'#DDDDDD'} borderStyle={'dashed'} />
                    {is_year ? (
                      <>
                        <HStack justifyContent={'space-between'} w="full">
                          <Text fontSize={'16px'}>연 결제</Text>
                          <Text fontSize={'16px'}>*{`${year}`}</Text>
                        </HStack>
                        <Divider borderColor={'#DDDDDD'} borderStyle={'dashed'} />
                        <HStack justifyContent={'space-between'} w="full">
                          <Text fontSize={'16px'}>-{discount * 100}% 할인</Text>
                          <Text fontSize={'16px'}>- {discounted}</Text>
                        </HStack>
                      </>
                    ) : (
                      <>
                        <HStack justifyContent={'space-between'} w="full">
                          <Text fontSize={'16px'}>최초 {`${3}`}개월분</Text>
                          <Text fontSize={'16px'}>*{`${3}`}</Text>
                        </HStack>
                      </>
                    )}
                    <Divider borderColor={'#DDDDDD'} borderStyle={'dashed'} />
                    <HStack justifyContent={'space-between'} w="full">
                      <Text fontSize={'16px'}>VAT</Text>
                      <Text fontSize={'16px'}>{vatFee}</Text>
                    </HStack>
                    <Divider borderColor={'#DDDDDD'} />
                    <Flex justifyContent={'space-between'} w="full">
                      <Text fontSize={'21px'} fontWeight={'medium'} color={'zetaBlue'}>
                        청구 금액
                      </Text>
                      <Text fontSize={'21px'} fontWeight={'medium'} color={'zetaBlue'}>
                        {finalFee}원
                      </Text>
                    </Flex>
                  </VStack>
                </Flex>
              </GTitleItem>
              <GTitleItem
                upperSection={
                  <Text fontSize={'18px'} fontWeight={'medium'}>
                    결제 안내
                  </Text>
                }
                rowSpan={1}
                colSpan={4}
                // minW={'340px'}
              >
                <VStack alignItems={'flex-start'} justifyContent={'unset'} w={'full'} h={'full'} spacing={0} py={4}>
                  <Flex flexDir={'column'} w="full" h="65%" borderRadius={'xl'} px={5} my={2}>
                    <Text fontSize={'13px'} fontWeight={'bold'}>
                      입금 계좌
                    </Text>
                    <HStack w="full" spacing={3} flexGrow={1} fontSize={'18px'} flexWrap={'wrap'} my={2}>
                      <Avatar w={8} h={8} name="은행로고" src={bank_mask_logo} />
                      <VStack alignItems={'flex-start'} fontSize={'13px'} spacing={0}>
                        <Flex alignItems={'center'}>
                          <Text>신한은행&nbsp;</Text>
                          <Text>{'100-035-629864'}</Text>
                        </Flex>
                        <Text>{'(예금주: 주식회사 리버블)'}</Text>
                      </VStack>
                    </HStack>
                  </Flex>
                  <Flex flexDir={'column'} w="full" h="65%" borderRadius={'xl'} fontSize={'13px'} px={5} my={2}>
                    <Text fontWeight={'bold'}>납부 기한</Text>
                    {/* <Text mb={4}>~ - ~까지</Text> */}
                    <Text mb={4}>{datedotformat(contractData.contract_created_date, 86400000 * 7)} 00:00 까지</Text>
                    <Text>{'납부 기한 내 납부하셔야 구독 체결이 완료됩니다.'}</Text>
                  </Flex>
                </VStack>
              </GTitleItem>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default SubscriptionInvoicePage;

import { Box, Flex, Collapse, Text, useDisclosure } from '@chakra-ui/react';
import RequestContentTable from '../../Table/requestContentTable';
import UsageContentTable from '../../Table/usageContentTable';
import { HistoryType, Service, ServiceDetail } from '../../../../lib/common/utils/axios/types';
import { RightArrowIcon } from '../../../../statics/common/icons';

interface FourContractedProps {
  usageData: HistoryType[] | undefined;
  contractData: HistoryType[] | undefined;
  userData: any;
}

// _id: string;
// name: string;
// employee: {
//   _id: string;
//   phone_number_first: string;
//   user_name: string;
//   email: string;
//   service: ServiceDetail;
// };

export const FourContracted: React.FC<FourContractedProps> = ({ usageData, contractData, userData }) => {
  const { isOpen: isHistoryOpen, onToggle: onHistoryToggle } = useDisclosure();
  const { isOpen: isContractOpen, onToggle: onContractToggle } = useDisclosure();

  const parsed_service_data = {
    _id: '',
    name: '',
    employee: {
      _id: '',
      phone_number_first: '',
      user_name: userData.user_name,
      email: userData.email,
      //   service: { ...serviceData },
    },
  } as Service;

  return (
    <>
      {/* 1. 이용 내역 */}
      <Box w={'full'} px={7} py={6}>
        <Flex onClick={onHistoryToggle} alignItems={'center'} justifyContent={'space-between'} cursor={'pointer'}>
          <Text fontWeight={'medium'} fontSize={'14px'}>
            {parsed_service_data.employee.user_name}님의 이용 내역
          </Text>
          <RightArrowIcon
            w={6}
            h={6}
            transform={`rotate(${isHistoryOpen ? 270 : 90}deg)`}
            transition={'transform 0.15s linear'}
          />
        </Flex>
        <Box pb={2} borderBottomWidth={'1px'} borderBottomColor={'gray'} />
        <Collapse in={!isHistoryOpen}>
          <Flex flexDir={'column'} w={'full'} flexGrow={1}>
            <UsageContentTable data={usageData} />
          </Flex>
        </Collapse>
      </Box>
      {/* 2. 계약 내용 */}
      <Box w={'full'} px={7} pb={6}>
        <Flex onClick={onContractToggle} alignItems={'center'} justifyContent={'space-between'} cursor={'pointer'}>
          <Text fontWeight={'medium'} fontSize={'14px'}>
            {parsed_service_data.employee.user_name}님의 계약 내용
          </Text>
          <RightArrowIcon
            w={6}
            h={6}
            transform={`rotate(${isContractOpen ? 270 : 90}deg)`}
            transition={'transform 0.15s linear'}
          />
        </Flex>
        <Box pb={2} borderBottomWidth={'1px'} borderBottomColor={'gray'} />
        <Collapse in={!isContractOpen}>
          <Flex flexDir={'column'} w={'full'} flexGrow={1}>
            <UsageContentTable data={contractData} />
          </Flex>
        </Collapse>
      </Box>
    </>
  );
};

import { Button, ButtonProps } from '@chakra-ui/react';

interface NumberButtonProps extends ButtonProps {
  isCurrent?: boolean;
}

const NumberButton: React.FC<NumberButtonProps> = ({ children, isCurrent = false, ...props }) => {
  return (
    <Button variant={'pagination_number_button'} isActive={!isCurrent} isDisabled={isCurrent} {...props}>
      {children}
    </Button>
  );
};

export default NumberButton;

import { isEmptyArray } from 'formik';
import {
  additional_options_checkbox,
  common_items_checkbox,
  default_options_checkbox,
  individual_items_checkbox,
  security_facilities_checkbox,
} from '../../../statics/common/data/checkbox';

export const direct_func = (data: string[] | undefined) => {
  if (data === undefined) return;
  const newArr: string[] = [];
  default_options_checkbox.map((el) => {
    const arr = data.includes(el.value);

    if (arr) return newArr.push(el.label + ' ');
  });
  return newArr;
};

export const additional_func = (data: string[] | undefined) => {
  if (data === undefined) return;
  const newArr: string[] = [];
  additional_options_checkbox.map((el) => {
    const arr = data.includes(el.value);

    if (arr) return newArr.push(el.label + ' ');
  });
  return newArr;
};

export const security_func = (data: string[] | undefined) => {
  if (data === undefined) return;
  const newArr: string[] = [];
  security_facilities_checkbox.map((el) => {
    const arr = data.includes(el.value);

    if (arr) return newArr.push(el.label + ' ');
  });
  return newArr;
};

export const commons_func = (data: string[] | any) => {
  if (data === undefined) return;
  const newArr: string[] = [];
  common_items_checkbox.map((el) => {
    const arr = data.includes(el.value);

    if (arr) return newArr.push(el.label + ' ');
  });
  return newArr;
};

export const individual_func = (data: string[] | undefined) => {
  if (data === undefined || isEmptyArray(data)) return '-';
  const newArr: string[] = [];
  individual_items_checkbox.map((el) => {
    const arr = data.includes(el.value);

    if (arr) return newArr.push(el.label + ' ');
  });
  return newArr;
};

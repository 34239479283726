import {
  Box,
  Button,
  Center,
  HStack,
  Input,
  ListItem,
  Modal,
  ModalContent,
  ModalOverlay,
  Tag,
  Text,
  UnorderedList,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { isEmptyArray } from '@chakra-ui/utils';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { WBox } from '../../Box';
import { useMakeTourReservationWithAdmin } from '../../../../lib/admin/hooks/mutation';
import { RequestRoom } from '../../../../lib/common/utils/axios/types';
import { CheckCircleIcon } from '../../../../statics/common/icons';
import * as Type from '../../../../types/common/CommonType';

interface MakeTourReservationModalProps {
  disabled: boolean;
  requestRooms: Type.Company[];
}

const MakeTourReservationModal: React.FC<MakeTourReservationModalProps> = ({ children, disabled, requestRooms }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleModalOpen = () => {
    if (!disabled) {
      onOpen();
    }
  };

  const custom_room_request_id = isEmptyArray(requestRooms) ? '' : requestRooms[0].employee.custom_room_request._id;

  const { mutateAsync } = useMakeTourReservationWithAdmin(custom_room_request_id || '');

  return (
    <>
      <Box onClick={handleModalOpen}>{children}</Box>

      <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside" size={'4xl'} motionPreset="scale">
        <ModalOverlay />
        <Formik
          initialValues={{
            room_ids: requestRooms.map((room) => room?.employee?.custom_room_request?.recommended_room?.room_id),
            start_date: '',
            end_date: '',
            location_description: '',
          }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            mutateAsync({
              room_ids: values?.room_ids as any,
              start_date: new Date(Date.parse(values.start_date)),
              end_date: new Date(Date.parse(values.start_date) + 1000 * 60 * 60),
              location_description: values.location_description,
              tour_type: 'house_tour',
            }).then(() => {
              onClose();
            });
          }}
          validationSchema={Yup.object().shape({})}
        >
          {({ values, errors, isValid, handleChange, setFieldValue, isSubmitting }) => {
            const isAbleReservation = values.start_date && values.location_description;
            return (
              // <Form style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>

              <Form>
                <ModalContent p={5}>
                  <Center flexDir={'column'} m={10} alignItems={'flex-start'}>
                    <HStack>
                      <CheckCircleIcon w={8} h={8} />
                      <Text fontSize={'23px'}>투어 일정 확정</Text>
                    </HStack>
                    <VStack fontSize={'20px'} flexDir={'column'} my={4} spacing={0} alignItems={'flex-start'}>
                      <Box>{'선택하신 매물의 투어 일정을 입력해 주세요.'}</Box>
                    </VStack>
                    {/* 선택된 매물 리스트 */}

                    <VStack w={'full'}>
                      <WBox w={'full'}>
                        <Box w={'full'} p={3}>
                          <VStack spacing={1} w={'full'} px={'80px'} py={'20px'}>
                            {/* 주소 */}
                            <UnorderedList>
                              {requestRooms.map((requestRoom, index) => {
                                const employee = requestRoom?.employee;
                                const custom_room_request = employee?.custom_room_request;
                                const room = requestRoom?.employee?.custom_room_request?.recommended_room?.room;
                                return (
                                  <ListItem key={index}>
                                    <HStack w="full" justify={'space-between'}>
                                      <Box fontSize={'14px'}>
                                        {room?.building?.address} {room?.building?.name && `(${room?.building?.name})`}
                                        {room?.dong && `${room?.dong}동`} {room?.ho && `${room?.ho}호`}
                                      </Box>
                                      {/* 매물번호 */}
                                      <Tag variant={'room_number'}>매물번호 {room?.code}</Tag>
                                    </HStack>
                                  </ListItem>
                                );
                              })}
                            </UnorderedList>
                          </VStack>
                        </Box>
                        <Box w="full" px={'30px'} pb={'30px'}>
                          <VStack>
                            <HStack w="full">
                              <Text minW="130px" fontSize={'14px'}>
                                투어 날짜/시간
                              </Text>
                              <Field name={'start_date'} as={Input} type={'datetime-local'} />
                            </HStack>
                            <HStack w="full">
                              <Text minW="130px" fontSize={'14px'}>
                                약속 장소
                              </Text>
                              <Field name={'location_description'} as={Input} />
                            </HStack>
                          </VStack>
                        </Box>
                      </WBox>
                    </VStack>

                    <Center w="full">
                      <Button
                        mt={'30px'}
                        variant={'basic'}
                        w={'140px'}
                        fontSize={'14px'}
                        fontWeight={'bold'}
                        type="submit"
                        // isDisabled={!isAbleReservation}
                        isLoading={isSubmitting}
                      >
                        예약하기
                      </Button>
                    </Center>
                  </Center>
                </ModalContent>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

export default MakeTourReservationModal;

import type * as T from '../../types/common/CommonType';

export const colors = {
  zetaBlue: '#183377',
  coolBlue: '#8B99BB',
  cottonBlue: '#BAC2D6',
  fragileBlue: '#C4CFDB',
  basicBlue: '#EBF0F6',
  mountainGreen: '#29CC97',
  primarySkyblue: '#ECF1FF',
  brightYellow: '#FDAA2E',
  highGray: '#414141',
  midGray: '#A7A7A7',
  gainGray: '#DDDDDD',
  borderGray: '#E5E5E5',
  whiteGray: '#F0F0F0',
  silverGray: '#C4C4C4',
  borderWhite: '#A0A0A0',
  lotionWhite: '#FBFBFB',
  pinkRed: '#FFE6E6',
  primaryRed: '#FB4667',
  mainBgColor: '#F8F8F8',
  basicPlan: '#29CC97',
  advancedPlan: '#C4C4C4',
  enterprisePlan: '#C4C4C4',
};

export const basicButton: T.CSSType = {
  backgroundColor: 'lotionWhite',
  height: '36px',
  padding: '4px',
  margin: '4px',
  borderRadius: '5px',
  borderWidth: '1px',
  fontWeight: 'medium',
  ':hover': {
    color: 'white',
    backgroundColor: colors.zetaBlue,
  },
};

export const CustomRoomRequestDetailBorderBox: T.CSSType = {
  marginLeft: '16px',
  border: '1px',
  height: '585px',
  overflow: 'scroll',
  borderRadius: '4px',
  borderColor: colors.silverGray,
};

import { Box } from '@chakra-ui/react';
import { matchPath, useLocation } from 'react-router-dom';
import HeaderNavBar from '.';
import { useGetUserType } from '../../../lib/common/hooks/query';
import { SubTitleInfosType } from '../main/MainLayout';

export const HeadNavBarLayout: React.FC = () => {
  const { pathname } = useLocation();
  const { data: ud } = useGetUserType();
  let title = '';
  console.log('this is HeadNavBarLayout');
  let subTitleInfos: SubTitleInfosType = [];
  const { company_type: userType } = ud;

  if (userType === 'admin') {
    if (matchPath('/admin/dashboard/*', pathname)) {
      title = '대시보드';
      subTitleInfos = [
        {
          subTitle: '고객사',
          router_name: '/admin/dashboard/companies',
        },
        {
          subTitle: '유저',
          router_name: '/admin/dashboard/users',
        },
        {
          subTitle: '중개사',
          router_name: '/admin/dashboard/agencies',
        },
        {
          subTitle: '정산관리',
          router_name: '/admin/dashboard/settlement',
        },
      ];
    } else if (matchPath('/admin/manage/customers/*', pathname)) {
      title = '회원 관리';
      subTitleInfos = [
        {
          subTitle: '임직원',
          router_name: '/admin/manage/customers/user',
        },
        {
          subTitle: '고객사',
          router_name: '/admin/manage/customers/company',
        },
        {
          subTitle: '중개사',
          router_name: '/admin/manage/customers/agency',
        },
        {
          subTitle: '임대인',
          router_name: '/admin/manage/customers/host',
        },
      ];
    } else if (matchPath('/admin/manage/companies/*', pathname)) {
      title = '구독 관리';
      subTitleInfos = [
        {
          subTitle: '구독 요청',
          router_name: '/admin/manage/companies/new',
        },
        {
          subTitle: '구독 중',
          router_name: '/admin/manage/companies/existing',
        },
      ];
    } else if (matchPath('/admin/manage/supportpayment/*', pathname)) {
      title = '월세 지원 관리';
      subTitleInfos = [
        {
          subTitle: '메인',
          router_name: '/admin/manage/supportpayment/main',
        },
        {
          subTitle: '사전 등록',
          router_name: '/admin/manage/supportpayment/preservicerequests',
        },
        {
          subTitle: '정산 관리',
          router_name: '/admin/manage/supportpayment/settlements/subscription',
        },
      ];
    } else if (matchPath('/admin/manage/moverequests/*', pathname)) {
      title = '입주요청 관리';
      subTitleInfos = [
        {
          subTitle: '요청/제안',
          router_name: '/admin/manage/moverequests/stepone',
        },
      ];
    } else if (matchPath('/admin/manage/moves/*', pathname)) {
      title = '입주중 관리';
      subTitleInfos = [
        {
          subTitle: '입주중',
          router_name: '/admin/manage/moves',
        },
      ];
    } else if (matchPath('/admin/manage/roomInfo/*', pathname)) {
      title = '매물 상세 정보';
    } else if (matchPath('/admin/manage/estates/*', pathname)) {
      title = '매물 관리';
      subTitleInfos = [
        {
          subTitle: '매물별 관리',
          router_name: '/admin/manage/estates/rooms',
        },
        {
          subTitle: '건물별 관리',
          router_name: '/admin/manage/estates/buildings',
        },
      ];
    } else if (matchPath('/admin/manage/complaints/*', pathname)) {
      title = '민원 관리';
      subTitleInfos = [
        {
          subTitle: '민원 내역',
          router_name: '/admin/manage/complaints/main',
        },
      ];
    } else if (matchPath('/admin/chat/*', pathname)) {
      title = '채팅';
    } else if (matchPath('/admin/manage/chats/*', pathname)) {
      title = '채팅 관리';
      subTitleInfos = [
        {
          subTitle: '전체 채팅',
          router_name: '/admin/manage/chats/all',
        },
      ];
    } else if (matchPath('/admin/manage/settlements/*', pathname)) {
      title = '정산 관리';
      subTitleInfos = [
        {
          subTitle: '월세 정산내역',
          router_name: '/admin/manage/settlements/main',
        },
        {
          subTitle: '구독 정산내역',
          router_name: '/admin/manage/settlements/subscription',
        },
        {
          subTitle: '가상계좌 관리',
          router_name: '/admin/manage/settlements/virtualaccounts',
        },
      ];
    } else if (matchPath('/admin/manage/wp/*', pathname)) {
      title = '웰컴 머천트 관리';
      subTitleInfos = [
        {
          subTitle: '정산내역',
          router_name: '/admin/manage/wp/main',
        },
      ];
    } else if (matchPath('/admin/manage/transfer/*', pathname)) {
      title = '송금';
      subTitleInfos = [
        {
          subTitle: '송금',
          router_name: '/admin/manage/transfer',
        },
      ];
    } else if (matchPath('/admin/manage/tours/*', pathname)) {
      title = '투어 일정';
      subTitleInfos = [
        {
          subTitle: '투어 내역',
          router_name: '/admin/manage/tours/main',
        },
      ];
    } else if (matchPath('/admin/manage/announcements/*', pathname)) {
      title = '공지 관리';
      subTitleInfos = [
        {
          subTitle: '공지 관리',
          router_name: '/admin/manage/announcements/main',
        },
        {
          subTitle: '공지 설정',
          router_name: '/admin/manage/announcements/setting',
        },
      ];
    } else if (matchPath('/admin/manage/paccounts/*', pathname)) {
      title = '계정 관리';
      subTitleInfos = [
        {
          subTitle: '계정 관리',
          router_name: '/admin/manage/paccounts',
        },
      ];
    }
  } else if (userType === 'subscriber') {
    if (matchPath('/company/manage/dashboard/*', pathname)) {
      title = '대시보드';
    } else if (matchPath('/company/manage/changeinfos/*', pathname)) {
      title = '내 정보 수정';
      subTitleInfos = [
        {
          subTitle: '회원 정보',
          router_name: '/company/manage/customers/b2buser',
        },
        {
          subTitle: '가입 정보',
          router_name: '/company/manage/customers/b2cuser',
        },
      ];
    } else if (matchPath('/company/manage/subscription/*', pathname)) {
      title = '구독 관리';
    } else if (matchPath('/company/manage/employee/*', pathname)) {
      title = '직원 관리';
    } else if (matchPath('/company/manage/payment/*', pathname)) {
      title = '정산 관리';
    } else if (matchPath('/company/manage/service/*', pathname)) {
      title = '고객 센터';
    } else if (matchPath('/company/infos/*', pathname)) {
      title = '';
    }
  } else if (userType === 'agency') {
    if (matchPath('/agency/dashboard/*', pathname)) {
      title = '대시보드';
    } else if (matchPath('/agency/manage/requests/*', pathname)) {
      title = '요청 관리';
    } else if (matchPath('/agency/manage/estates/*', pathname)) {
      title = '매물 관리';
      subTitleInfos = [
        {
          subTitle: '매물관리',
          router_name: '/agency/manage/estates/rooms',
        },
        {
          subTitle: '건물관리',
          router_name: '/agency/manage/estates/buildings',
        },
      ];
    } else if (matchPath('/company/manage/employees/*', pathname)) {
      title = '직원 관리';
    } else if (matchPath('/agency/manage/account/*', pathname)) {
      title = '계정 관리';
    } else if (matchPath('/agency/service/*', pathname)) {
      title = '고객 센터';
    } else if (matchPath('/agency/manage/moverequests/*', pathname)) {
      {
        title = '입주요청 관리';
        subTitleInfos = [
          {
            subTitle: '요청/제안',
            router_name: '/agency/manage/moverequests/stepone',
          },
          // {
          //   subTitle: '건물관리',
          //   router_name: '/agency/manage/estates/buildings',
          // },
        ];
      }
    }
  }
  return (
    <Box id="header_container">
      <HeaderNavBar title={title} subTitleInfos={subTitleInfos} />
    </Box>
  );
};

import { Button, Flex, HStack, StackDivider, Table, Tbody, Text, Tr, VStack } from '@chakra-ui/react';
import { Field, useFormikContext } from 'formik';
import { CustomTd } from '../type';
import { FCheckboxButtonGroup } from '../../../../Checkbox/base';
import { CustomInput } from '../../../../Input';
import { FRadioButtonGroup } from '../../../../Radio';
import { security_facilities_checkbox } from '../../../../../../statics/common/data/checkbox';
import {
  elevator_radio,
  entrance_type_radio,
  parking_available_radio,
} from '../../../../../../statics/common/data/radio';
import RegisterHostsInfoModal from '../../../RegisterHostsInfoModal';

const ColivingHostInfoTable = () => {
  const formik = useFormikContext<any>();
  const { values } = formik;

  const textInButton = values.host_name ? '재선택' : '임대인 선택';
  return (
    <>
      <Table w={'100%'} variant="simple">
        <Tbody w={'100%'}>
          <Tr w={'100%'}>
            <CustomTd title={'임대인 등록'}>
              <HStack>
                <Flex w="130px" minW="130px">
                  <Field name="host_name" as={CustomInput} bgColor="lotionWhite" isDisabled />
                </Flex>
                {/* <CustomInput isDisabled></CustomInput> */}
                <RegisterHostsInfoModal>
                  <Button variant={'lotionWhite'} h={'32px'}>
                    {textInButton}
                  </Button>
                </RegisterHostsInfoModal>
              </HStack>
            </CustomTd>
          </Tr>
        </Tbody>
      </Table>
    </>
  );
};

export default ColivingHostInfoTable;

import { Box, Button, Skeleton, useDisclosure, Wrap } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import QueryParamsLayout from '../main/QueryParamsLayout';
import Loading from '../../../components/common/Loading';
import { SetMoveIn } from '../../../components/common/Checkbox/SetBooleanCheckBox';
import {
  SetBuildingFormCheckboxes,
  SetRoomConditionsCheckboxes,
  SetRoomSizeCheckboxes,
} from '../../../components/common/Checkbox/SetMultiBooleanCheckBox/SetCustomStatus';
import { SetRoomPriceRangeSliders } from '../../../components/common/Slider/SetMultiRangeSliders/SetCustomStatus';
import { useGetAllBuildingsRooms } from '../../../lib/common/hooks/query';
import { currentParamsObject, ToAllFilledObj } from '../../../lib/common/utils/base';
import AddNewRoomBtn from '../../../components/common/Button/AddNewRoomButton';
import RoomInfoContainer from '../RoomInfoContainer';
import * as Util from '../../../lib/common/utils/Util';
import * as Type from '../../../types/common/CommonType';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { ref } from 'yup';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../../lib/common/utils/firebase/init';
import { fetchClient } from '../../../lib/common/utils/axios';
import { default_items_limit_number } from '../../../lib/common/utils/values';
import NoShownData from '../../../components/common/NoShownData';
import { HeadNavBarLayout } from '../HeadNavBar/HeadNavBarLayout';
import { queryClient } from '../../..';
import { OtherRoomCreatorModal } from '../../../components/common/Modals/OtherRoomCreatorModal';

const RoomManageSection = () => {
  const [user, loading, error] = useAuthState(auth);
  const _params = currentParamsObject();
  const params = ToAllFilledObj(_params) as any;
  const location = useLocation();
  const routeUserType = Util.getRouteUserTypeFromUrl(location.pathname);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenOtherRoomModal,
    onOpen: onOpenOtherRoomModal,
    onClose: onCloseOtherRoomModal,
  } = useDisclosure();

  const res = useGetAllBuildingsRooms({
    axiosConfig: {
      params,
    },
  }) as any;
  // const _data = queryClient.getQueryCache();
  // console.log('queryCaches', _data);

  if (res.isLoading) return <Loading />;
  const buildingsRoomsData = res?.data?.data;

  return (
    <>
      <Box w="full" h="full">
        <Wrap align={'center'} mb={2}>
          <Box>
            <SetMoveIn />
          </Box>
          <Box>
            <SetBuildingFormCheckboxes />
          </Box>
          <Box>
            <SetRoomPriceRangeSliders />
          </Box>
          <Box>
            <SetRoomSizeCheckboxes />
          </Box>
          <Box>
            <SetRoomConditionsCheckboxes />
          </Box>
          <Box>
            <Button variant={'basic'} as="a" href={`/${routeUserType}/manage/estates/rooms/mapview`} target={'_blank'}>
              지도로 보기
            </Button>
          </Box>
          <Box>
            <Button onClick={onOpenOtherRoomModal}>
              URL
              <OtherRoomCreatorModal
                isOpen={isOpenOtherRoomModal}
                onClose={onCloseOtherRoomModal}
              ></OtherRoomCreatorModal>
            </Button>
          </Box>
        </Wrap>
        <Box h="calc(100% - 70px)">
          <QueryParamsLayout
            res={res}
            modalOnLayout={
              <Box pos={'absolute'} right={0} bottom={0}>
                <AddNewRoomBtn isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
              </Box>
            }
          >
            <RoomInfoContainer minifyMemo buildingsroomsData={buildingsRoomsData} />
          </QueryParamsLayout>
        </Box>
      </Box>
    </>
  );
};

export default RoomManageSection;

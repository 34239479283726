import { Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import QueryParamsLayout from '../../../../../../layouts/common/main/QueryParamsLayout';
import TextLink from '../../../../../../components/common/Link/TextLink';
import { useGetAllCompaniesSubscribers } from '../../../../../../lib/admin/hooks/query';
import { CompanySubscriber } from '../../../../../../lib/common/utils/axios/types';
import { currentParamsObject, ToAllFilledObj } from '../../../../../../lib/common/utils/base';
import { subscriptionTypeTrans } from '../../../../../../lib/subscriber/utils/utils';
import { datedotformat } from '../../../../../../lib/common/utils/dateformatter';
import Notfoundpage from '../../../../../common/NotFound';

const Existing = () => {
  const { search: srch } = location;
  const _params = currentParamsObject();
  const params = ToAllFilledObj(_params);

  const res = useGetAllCompaniesSubscribers({
    options: {
      select: (data: any) => {
        const { last_page, limit, page, result, total } = data;
        return {
          result: result.filter((cp: CompanySubscriber) => cp.last_subscription_contract !== undefined),
          last_page,
          limit,
          page,
          total,
        };
      },
    },
    axiosConfig: {
      params,
    },
  });

  return <Notfoundpage />;

  return (
    <QueryParamsLayout res={res}>
      <Table variant="head_silverbg_toplining">
        <Thead bgColor={'#F8F8F8'} borderColor={'gray.100'}>
          <Tr>
            <Th>기업명</Th>
            <Th>회사 코드</Th>
            <Th>구독 플랜</Th>
            <Th>이용 현황</Th>
            <Th>승인 대기</Th>
            <Th>구독료 납부</Th>
            <Th>구독 유효 기간</Th>
          </Tr>
        </Thead>
        <Tbody>
          {res.data?.result.map((subscriber, index) => {
            return (
              <Tr key={srch + index}>
                <Td>
                  <TextLink href={`/admin/manage/customers/company/detail/info?id=${subscriber._id}`} target={'_blank'}>
                    {subscriber.name}
                  </TextLink>
                </Td>
                <Td>{subscriber.company_code}</Td>
                <Td>{subscriptionTypeTrans(subscriber.last_subscription_contract.subscription_type)}</Td>
                <Td>
                  {subscriber.employees.length}
                  {'/'}
                  {subscriber.last_subscription_contract.available_user_count}
                  {' 인'}
                </Td>
                <Td>{'인원수'}</Td>
                <Td>{'납부여부'}</Td>
                <Td>
                  {datedotformat(subscriber.last_subscription_contract.contract_start_date)}
                  {' - '}
                  {datedotformat(subscriber.last_subscription_contract.contract_end_date)}
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </QueryParamsLayout>
  );
};

export default Existing;

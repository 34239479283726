import { fetchClient } from '../utils/axios';
import {
  Announcement,
  BaseResponse,
  Basic,
  Building,
  CompanyTypeEnum,
  NotificationCountType,
  QueryResponse,
  User,
  UserTypeEnum,
} from '../utils/axios/types';
import { useToast } from '@chakra-ui/react';
import axios, { AxiosRequestConfig } from 'axios';
import { useNavigate } from 'react-router-dom';
import { queryClient } from '../../..';
import { error, success, verifyAccountFail, verifyAccountSuccess } from '../../../statics/common/data/toast';
import { saveFail, saveSuccess } from '../../../statics/common/data/toast';

import { getToken } from 'firebase/messaging';
import { QueryKey, useInfiniteQuery, UseQueryResult, useQuery } from 'react-query';
import { default_items_limit_number, maximized_limit_number } from '../utils/values';
import { currentParamsObject } from '../utils/Util';

import * as Util from '../utils/Util';
import * as Validator from '../utils/Validator';
import * as KeyPath from '../../../statics/common/data/KeyPath';
import * as Type from '../../../types/common/CommonType';
import { getLocalStorageItem, getLocalStorageStringItem } from '../utils/localstorage';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../utils/firebase/init';
import { validateArgCount } from '@firebase/util';
import { useMemo } from 'react';
import _ from 'lodash';

export const useQueryParamsFrame = <T>(
  queryKey: QueryKey,
  path: string,
  basic?: Type.Basic,
): UseQueryResult<Type.QueryResponse<T>> => {
  const [user, loading, error] = useAuthState(auth);
  const { data: ud } = useGetUserPathandKey();
  const { page, limit, search } = currentParamsObject(false);
  const config = basic?.axiosConfig;
  const firstKey = Array.isArray(queryKey) ? queryKey : [queryKey];
  const secondaryKey = {
    ...basic?.axiosConfig?.params,
    page: basic?.axiosConfig?.params?.page || 1,
    limit: basic?.axiosConfig?.params?.limit || default_items_limit_number,
    search: basic?.axiosConfig?.params?.search || '',
  };
  const key = [...firstKey, secondaryKey];
  return useQuery(
    key,
    async () => {
      const token = await user?.getIdToken();
      console.log('common path', path);
      const instance = await fetchClient({
        headers: {
          token,
        },
      });
      const res = await instance.get<Type.BaseResponse<T>>(ud?.path + path, {
        ...config,
        params: {
          ...config?.params,
          page: config?.params?.page || 1,
          limit: config?.params?.limit || default_items_limit_number,
          search: config?.params?.search || search,
        },
      });
      const result = {
        result: res.data?.data,
        limit: config?.params?.limit || default_items_limit_number,
        page: res.data?.page_info?.page,
        total: res.data?.page_info?.total,
        last_page: res.data?.page_info?.last_page || 100,
        ...res.data?.extra_info,

        data: res.data?.data as T,
        message: res.data?.message as string,
        page_info: res.data?.page_info as Type.PageInfo,
        extra_info: res.data?.extra_info,
      };
      return result;
    },
    {
      ...basic?.options,
      enabled: !!user,
    },
  );
};

export const useGetFirebaseIdById = (user_id: string, basic?: Basic<any>) => {
  const [user, loading, error] = useAuthState(auth);
  const { data: ud } = useGetUserPathandKey();

  return useQuery(
    ['FirebaseIdById'],
    async () => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      const value = await instance.get<
        BaseResponse<{
          company_type: CompanyTypeEnum;
          user_type: UserTypeEnum;
        }>
      >(ud?.path + `/users/${user_id}/firebase-uid`, {
        ...basic?.axiosConfig,
      });
      return value?.data?.data;
    },

    {
      ...basic?.options,
      enabled: basic?.options?.enabled === undefined ? !!ud : basic?.options?.enabled && !!ud,
    },
  );
};

export const useGetUserType = () => {
  return { data: Util.getCompanyUserTypePathKeyFromLocalStorage(), isLoading: false };
};

export const useGetUserPathandKey = () => {
  return {
    data: Util.getCompanyUserTypePathKeyFromLocalStorage(),
    isLoading: false,
  };
};

export const useUserTokenVerify = () => {
  const [user, loading, error] = useAuthState(auth);
  const { data: ud } = useGetUserPathandKey();

  return useQuery(
    [ud?.key + KeyPath.verify_user_key],
    async () => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      const value = await instance.post<BaseResponse<User>>(ud?.path + KeyPath.verify_user_path);
      return value.data?.data;
    },
    {
      enabled: !!ud,
    },
  );
};

//특정 사용자 모든 알림 내역
export const useGetNotCheckedCount = (basic?: Basic<any>) => {
  const [user, loading, error] = useAuthState(auth);
  const { data: ud } = useGetUserPathandKey();

  return useQuery(
    KeyPath.not_checked_count_key,
    async () => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      const value = await instance.get<BaseResponse<NotificationCountType>>(
        `${ud?.path}/${KeyPath.not_checked_count_path}`,
        {
          ...basic?.axiosConfig,
        },
      );
      return value.data?.data;
    },
    {
      ...basic?.options,
    },
  );
};

export const useGetNaverFormUrl = (basic?: Basic<any>) => {
  const [user, loading, error] = useAuthState(auth);
  const { data: ud } = useGetUserPathandKey();

  return useQuery(
    KeyPath.naver_form_url,
    async () => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      const value = await instance.get<BaseResponse<any>>(`${ud?.path}/${KeyPath.naver_form_url}`, {
        ...basic?.axiosConfig,
      });
      return value.data?.data;
    },
    {
      ...basic?.options,
    },
  );
};

export const useGetAllAnnouncements = (
  company_type: CompanyTypeEnum,
  user_type: UserTypeEnum,
  basic?: Basic<Announcement[]>,
) => {
  const [user, loading, error] = useAuthState(auth);
  return useQuery(
    [KeyPath.all_announcements_key, company_type, user_type],
    async () => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      const value = await instance.get<BaseResponse<Announcement[]>>(
        `/${company_type}/${user_type}/${KeyPath.all_announcements_path}`,
      );
      return value?.data?.data;
    },

    basic?.options,
  );
};

export const useGetBuildings = (basic?: Type.Basic) => {
  const [user, loading, error] = useAuthState(auth);
  const { data: ud } = useGetUserPathandKey();

  return useQuery(
    [KeyPath.buildings_key, { ...basic?.axiosConfig?.params }],
    async () => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      const value = await instance.get<BaseResponse<Building[]>>(ud?.path + `${KeyPath.buildings_path}`, {
        ...basic?.axiosConfig,
        params: {
          limit: maximized_limit_number,
          ...basic?.axiosConfig?.params,
        },
      });
      return value?.data?.data;
    },

    {
      ...basic?.options,
      enabled: basic?.options?.enabled === undefined ? !!ud : basic?.options?.enabled && !!ud,
    },
  );
};

export const useGetAllBuildingsRooms = (basic?: Type.Basic) => {
  return useQueryParamsFrame<Type.Building[]>(
    [KeyPath.keyOfGetAllBuildingsAllRooms],
    KeyPath.keyOfGetAllBuildingsAllRooms,
    basic,
  );
};

export const useGetBuilding = (building_id: string, basic?: Type.Basic) => {
  const [user, loading, error] = useAuthState(auth);
  const { data: ud } = useGetUserPathandKey();

  return useQuery(
    [
      KeyPath.building_key,
      {
        ud: ud?.key,
        building_id,
        params: basic?.axiosConfig?.params,
      } as any,
    ],
    async () => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      const value = await instance.get<Type.BaseResponse<Type.Building>>(ud?.path + `/buildings/${building_id}`, {
        ...basic?.axiosConfig,
      });
      return value?.data?.data;
    },
    {
      ...basic?.options,
      enabled: Validator.isEnabledQuery({ isEnabled: basic?.options?.enabled, user: ud }),
    },
  );
};

export const useGetBuildingRoom = (room_id: string, basic?: Type.Basic) => {
  const [user, loading, error] = useAuthState(auth);
  const { data: ud } = useGetUserPathandKey();

  return useQuery(
    [
      KeyPath.building_room_key,
      {
        ud: ud?.key,
        room_id,
        params: basic?.axiosConfig?.params,
      } as any,
    ],
    async () => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      const value = await instance.get<Type.BaseResponse<Type.Building>>(ud?.path + `/building/rooms/${room_id}`, {
        ...basic?.axiosConfig,
      });
      return value.data?.data;
    },
    {
      ...basic?.options,
      enabled: Validator.isEnabledQuery({ isEnabled: basic?.options?.enabled, user: ud }),
    },
  );
};

export const _useGetBuildingRoom = (room_id: string, basic?: Type.Basic) => {
  return useQueryParamsFrame<Type.Building>([KeyPath.building_room_key, room_id], `/building/rooms/${room_id}`, basic);
};

export const useGetBuildingAllRooms = (building_id: string, basic?: Type.Basic) => {
  return useQueryParamsFrame<Type.Building[]>(
    [KeyPath.building_rooms_key, building_id],
    `/buildings/${building_id}/rooms`,
    basic,
  );
};

export const useGetUser = (user_id: string, basic?: Type.Basic) => {
  const [user, loading, error] = useAuthState(auth);
  const { data: ud } = useGetUserPathandKey();

  return useQuery(
    [
      KeyPath.user_key,
      {
        ud: ud?.key,
        user_id,
        params: basic?.axiosConfig?.params,
      } as any,
    ],
    async () => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      const value = await instance.get<Type.BaseResponse<any>>(ud?.path + `/company/users/${user_id}`, {
        ...basic?.axiosConfig,
      });
      return value.data?.data;
    },

    {
      ...basic?.options,
      enabled: Validator.isEnabledQuery({ isEnabled: basic?.options?.enabled, user: ud }),
    },
  );
};

export const useGetCustomRoomRequests = (basic?: Type.Basic) => {
  return useQueryParamsFrame<Type.Company[]>(
    [KeyPath.custom_room_requests_key],
    KeyPath.custom_room_requests_path,
    basic,
  );
};

export const useGetCustomRoomRequestRecommendedRooms = (custom_room_request_id: string, basic?: Type.Basic) => {
  const [user, loading, error] = useAuthState(auth);
  const { data: ud } = useGetUserPathandKey();

  return useQuery(
    [
      KeyPath.custom_room_request_rooms_key,
      {
        ud: ud?.key,
        custom_room_request_id,
        params: basic?.axiosConfig?.params,
      },
    ],
    async () => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      const value = await instance.get<Type.BaseResponse<Type.Company[]>>(
        ud?.path + `/custom-room-requests/${custom_room_request_id}/rooms`,
        {
          ...basic?.axiosConfig,
        },
      );
      return value?.data?.data;
    },
    {
      ...basic?.options,
      enabled: Validator.isEnabledQuery({ isEnabled: basic?.options?.enabled, user: ud }),
    },
  );
};

export const useGetAllCompaniesAllHostsOnModal = (basic?: Type.Basic) => {
  return useQueryParamsFrame<Type.Company[]>([KeyPath.company_hosts_key], KeyPath.company_hosts_key, basic);
};

export const useGetAllCompaniesAllHosts = (basic?: Type.Basic) => {
  return useQueryParamsFrame<Type.Company[]>([KeyPath.company_hosts_key], '/companies/hosts', basic);
};

export const useGetCompanyHost = (user_id: string, basic?: Type.Basic) => {
  const [user, loading, error] = useAuthState(auth);
  const config = basic?.axiosConfig;
  const { path } = Util.getCompanyUserTypePathKeyFromLocalStorage();
  return useQuery(
    [KeyPath.company_hosts_key, { ...config?.params }],
    async () => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      const res = await instance.get<Type.BaseResponse<Type.Company>>(path + `/company/hosts/${user_id}`, {
        ...config,
      });
      const result = {
        data: res.data?.data as Type.Company,
        message: res.data?.message as string,
        page_info: res.data?.page_info as Type.PageInfo,
        extra_info: res.data?.extra_info,
      };
      return result;
    },
    {
      ...basic?.options,
    },
  );
};

export const useGetAllTransferOuts = (basic?: Type.Basic) => {
  return useQueryParamsFrame<Type.TransferOut[]>(
    [KeyPath.keyOfGetAllTransferOuts],
    KeyPath.keyOfGetAllTransferOuts,
    basic,
  );
};

export const useGetSendingMoniesUrl = (basic?: Type.Basic) => {
  return useQueryParamsFrame<string>([KeyPath.keyOfGetSendingMoniesUrl], KeyPath.keyOfGetSendingMoniesUrl, basic);
};

export const useGetCustomRoomRequest_ = (custom_room_request_id: string, basic?: any) => {
  const [user, loading, error] = useAuthState(auth);
  const { data: ud } = useGetUserPathandKey();

  return useQuery(
    [
      KeyPath.custom_room_request_key,
      {
        ud: ud?.key,
        custom_room_request_id,
        params: basic?.axiosConfig?.params,
      } as any,
    ],
    async () => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      const value = await instance.get(ud?.path + `/custom-room-requests/${custom_room_request_id}`, {
        ...basic?.axiosConfig,
      });
      return value.data?.data as Type.CustomRoomRequest;
    },

    {
      ...basic?.options,
      enabled: basic?.options?.enabled === undefined ? !!ud : basic?.options?.enabled && !!ud,
    },
  );
};

import { NumberIncrementStepperProps } from '@chakra-ui/react';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { UseMutationResult } from 'react-query';
import type * as CSS from 'csstype';

export type CSSType = CSS.Properties | PseudoType;
type PseudoType = { [P in CSS.SimplePseudos]?: CSS.Properties };

export enum planStatus {
  appliedButWait = 'appliedButWait',
  approvedButNotApply = 'approvedButNotApply',
  approvedAndWaitSign = 'approvedAndWaitSign',
  approvedAndWaitPayment = 'approvedAndWaitPayment',
  approved = 'approved',
  rejected = 'appliedButWait',
}
export type toKRTransType<T, KR> = {
  [key in T extends string ? T : keyof T]: KR;
};
export type UndefinedNull = undefined | null | 'undefined' | 'null';
export type RouteUserType = 'admin' | 'company' | 'agency' | undefined | null;
export type CompanyType = 'all' | 'admin' | 'subscriber' | 'agency' | 'lease';
export type CompanyTypeKR = '전부' | '어드민' | '회사관리자' | '중개사' | '임대';
export const CompanyTranType = {
  all: '전부',
  admin: '어드민',
  subscriber: '회사관리자',
  agency: '중개사',
  lease: '임대',
};
export type UserType = 'all' | 'master' | 'manager' | 'tenant' | 'host';
export type UserTypeKR = '전부' | '마스터' | '매니저' | '임차인' | '호스트';
export const UserTransType = {
  all: '전부',
  master: '마스터',
  manager: '매니저',
  tenant: '임차인',
  host: '호스트',
};
export interface CompanyTypeUserType {
  company_type: CompanyType;
  user_type: UserType;
}
export interface SubTitleInfoType {
  subTitle: string;
  router_name: string;
}
export type SubTitleInfosType = Array<SubTitleInfoType | UndefinedNull>;
export type QueryResponseType<Type> = _QueryResponseType<Type> | QueryResponsePromiseType<Type>;
export type QueryResponsePromiseType<Type> = Promise<_QueryResponseType<Type>>;
export interface _QueryResponseType<Type> extends PageInfo {
  result?: Type;
  limit?: number;
  total?: number;
  page?: number;
  last_page?: number;
  [key: string]: any;
}
export interface PageInfo {
  limit?: number;
  total?: number;
  page?: number;
  last_page?: number;
}

export type Pick<T, K extends keyof T> = {
  [P in K]: T[P];
};

export type RequiredAll<T> = {
  [P in keyof T]-?: T[P];
};

export type Exclude<T, U> = T extends U ? never : T;

export type Omit<T, U extends keyof any> = Pick<T, Exclude<keyof T, U>>;

export type SubPartial<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export type ToggleBtnStage = 'showingWith' | 'showingAlone' | 'hidden';

export type pos = 'left' | 'middle' | 'right';

export type numberOrString = number | string;

export type Building = Partial<_Building>;

export interface _Building extends AddressType {
  //static
  _id: string;
  category: BuildingCategoryType;
  coliving_room_types: string[];
  code: string;
  agent_id: string;
  building_type: BuildingType;
  building_form: BuildingFormType;
  building_number: numberOrString;
  name: string;
  completed_date: Date;
  floor: numberOrString;
  household_number: numberOrString;
  entrance_type: EntranceType;
  is_elevator: boolean;
  security_facilities: SecurityFacilitiesType;
  is_parking: boolean;
  is_management: boolean;
  parking_num: numberOrString;
  parking_cost: numberOrString;
  pictures: string[];
  room_types: string[];
  facilities: BuildingFacilitiesType;
  room_ids: string;
  is_real: boolean;
  is_template: boolean;
  related_real_building_id: string;
  related_building_id: string;
  host_id: string;
  admin_secret_memo: string;
  description: string;
  registrant_type: RegistrantType;
  registrant_company_id: string;
  registrant_user_id: string;
  // dynamic
  createdAt: Date;
  updatedAt: Date;
  room: Room;
  host: User;
  template_rooms: Room[];
}

export type BuildingCategoryType = RoomCategoryType;
export type BuildingType =
  | 'detached_house'
  | 'apartment_house'
  | 'neighborhood_living_1'
  | 'neighborhood_living_2'
  | 'cultural_gathering'
  | 'religion'
  | 'sale'
  | 'transportation'
  | 'medical'
  | 'education_research'
  | 'elderly_children'
  | 'training'
  | 'exercise'
  | 'business'
  | 'accommodation'
  | 'amusement'
  | 'factory'
  | 'ware_house'
  | 'danger'
  | 'car'
  | 'animal_plant'
  | 'resource_circulation'
  | 'military'
  | 'broadcast'
  | 'power_plant'
  | 'cemetery'
  | 'tourist'
  | 'funeral'
  | 'campsite'
  | 'unregistered'
  | 'others';

export type BuildingFormType =
  | 'apartment'
  | 'villa'
  | 'officetel'
  | 'row_house'
  | 'house'
  | 'multi_family_house_s'
  | 'multi_family_house_g'
  | 'country_house'
  | 'commercial_house'
  | 'hanok_house'
  | 'share_house'
  | 'coliving_house';

export type EntranceType = 'stare' | 'hallway' | 'mix';
export type EntranceKRType = '계단' | '홀' | '혼합';
export interface EntranceTransType extends toKRTransType<EntranceType, EntranceKRType> {
  stare: '계단';
  hallway: '홀';
  mix: '혼합';
}
export const EntranceTrans: toKRTransType<EntranceType, EntranceKRType> = {
  stare: '계단',
  hallway: '홀',
  mix: '혼합',
};

export type SecurityFacilitiesType = SecurityFacilitiesType[];

export type SecurityFacilityType =
  | 'entrance_security'
  | 'cctv'
  | 'interphone'
  | 'videophone'
  | 'cardkey'
  | 'security_window'
  | 'own_security_guard'
  | 'private_security_guard';

export type BuildingFacilitiesType = BuildingFacilityType[];

export interface BuildingFacilityType {
  facility_type: _BuildingFacilityType;
  picture_urls: string[];
  dong: string;
  floor: string;
  name: string;
  ho: string;
  supply_area: string;
  dedicated_area: string;
  options: BuildingFacilityOptionsType;
  description: string;
}

export type _BuildingFacilityType = 'default';
export type BuildingFacilityOptionsType = BuildingFacilityOptionType[];
export type BuildingFacilityOptionType = string;
export interface AddressType {
  address?: string;
  road_address?: string;
  jibun_address?: string;
  detail_address?: string;
  zip_code?: string;
  sido_name?: string;
  sigungu_name?: string;
  dongli_name?: string;
  location?: LocationType;
}
export interface LocationType {
  type: 'Point';
  coordinates: number[];
}

export type Room = Partial<_Room>;

export interface _Room {
  // static
  _id: string;
  category: RoomCategoryType;
  room_type: string;
  coliving_room_type: string;
  access_status: AccessStatusType;
  code: string;
  agent_id: string;
  building_id: string;
  deposit: number | string;
  rent: number | string;
  management_fee: number | string;
  common_items: RoomCommonItemsType[];
  individual_items: IndividualItemsType;
  move_in: RoomMoveInType;
  minimum_contract_month: number | string;
  pictures: any;
  dong: string;
  ho: string;
  floor: numberOrString;
  detail_address: string;
  floor_type: RoomFloorType;
  room_living_type: RoomLivingType;
  room_num: numberOrString;
  toilet_num: numberOrString;
  supply_area: numberOrString;
  dedicated_area: numberOrString;
  room_direction: RoomDirectionType;
  heating_type: HeatingType;
  default_options: RoomDefaultOptionsType;
  additional_options: RoomAdditionalOptionsType;
  is_security_window: boolean;
  is_parking: boolean;
  is_pet: boolean;
  remodeling_season: RoomRemodelingSeasonType;
  remodeling_items: RemodeldingItemsType;
  is_move_in: boolean;
  is_management: boolean;
  agent_secret_memo: boolean;
  admin_secret_memo: boolean;
  custom_room_request_ids: string[];
  related_real_room_id: string;
  contracted_custom_room_request_id: string;
  is_activated: boolean;
  is_held: boolean;
  host_id: boolean;
  description: string;
  is_real: boolean;
  is_template: boolean;
  related_room_id: string;
  tenant_id: string;
  lease_contract_id: string;
  lease_contract_ids: string[];
  share_facilities: RoomShareFacilitiesType;
  contract_start_date: Date;
  contract_end_date: Date;
  registrant_type: RegistrantType;
  registrant_company_id: string;
  registrant_user_id: string;
  is_bohome: boolean;
  // dynamic
  createdAt: Date;
  updatedAt: Date;
  building: Building;
  company_memo: string;
  custom_room_requests: CustomRoomRequest[];
  recommended_users: RoomRecommendedUser[];
  host: User;
  agent: User;
  room_id: string;
}

type RegistrantType = 'admin' | 'subscriber' | 'agent' | 'tenant' | 'host';
type RegistrantTypeKR = '어드민' | '고객사' | '중개사' | '임차인' | '임대인';
export const RegistrantTransType = {
  admin: '어드민',
  subscriber: '고객사',
  agent: '중개사',
  tenant: '임차인',
  host: '임대인',
};

type RoomCategoryType = 'qualified' | 'simple';
type AccessStatusType = 'open' | 'hidden' | 'closed';
type RoomCommonItemsType = 'electricity' | 'gas' | 'water' | 'internet' | 'tv' | 'clean';
type IndividualItemsType = IndividualItemType[];
type IndividualItemType = 'electricity' | 'gas' | 'water' | 'heat';
interface RoomMoveInType {
  status: RoomMoveInStatusType;
  date: Date | string | number;
}
type RoomMoveInStatusType = 'now' | 'conference' | 'direct';
type RoomFloorType = 'ground' | 'semi_ground' | 'underground' | 'rooftop';
type RoomLivingType = 'open' | 'devide' | 'duplex';
type RoomDirectionType =
  | 'east'
  | 'west'
  | 'south'
  | 'north'
  | 'south_east'
  | 'south_west'
  | 'north_east'
  | 'north_west';
type HeatingType = 'individual_heating' | 'central_heating' | 'district_heating' | 'mix_heating' | 'none';
type RoomDefaultOptionsType = RoomDefaultOptionType[];
type RoomDefaultOptionType =
  | 'washing_machine'
  | 'air_conditioner'
  | 'refrigerator'
  | 'sink'
  | 'gas_range'
  | 'induction';
type RoomAdditionalOptionsType = RoomAdditionalOptionType[];
type RoomAdditionalOptionType =
  | 'bed'
  | 'desk'
  | 'closet'
  | 'hanger'
  | 'shoe_closet'
  | 'built_in_wardrobe'
  | 'tv'
  | 'oven'
  | 'microwave';
type RoomRemodelingSeasonType = 'first' | 'within_few_years' | 'tunable' | 'none';
type RemodeldingItemsType = RemodeldingItemType[];
type RemodeldingItemType = 'veneer' | 'wallpaper' | 'molding' | 'toilet' | 'kitchen';
type RoomShareFacilitiesType = RoomShareFacilityType[];
type RoomShareFacilityType = 'kitchen' | 'shower_room' | 'toilet' | 'lounge' | 'terrace' | 'rooftop' | 'laundary_room';
type RoomRecommendedUser = {
  user_name: string;
};

export type BuildingRoomStep = 0 | 1 | 2;
export type MultiCheckType = 'none' | 'some' | 'every';
export type FirebaseRouterProps = {
  user: any;
  loading: boolean;
  error: any;
};

export type RequestStatusEnum =
  | 'new'
  | 'delivery_waiting'
  | 'delivery_completed'
  | 'tour_reservation'
  | 'contracting'
  | 'contracted'
  | 'moving_in'
  | 'moving_out'
  | 'rejected'
  | 'cancelled';

export type ProxyPaymentStatusEnum = 'none' | 'pending' | 'paid' | 'cancelled' | 'withdrawal';
export type CertificateIssueTypeEnum = 'none' | 'cash_receipt' | 'tax_invoice';
export type ServiceCategoryEnum = 'house_hunting' | 'support_payment';
export type AnnouncementTypeEnum = 'default' | 'urgent' | 'updated';

export type User = {
  _id: string;
  firebase_uid: string;
  is_activated: boolean;
  is_init: boolean;
  email: string;
  birth_date: string;
  notification_ids: string[];
  residential_type_ids: never[];
  custom_room_request_ids: string[];
  default_memo: Memo;
  tour_ids: string[];
  complaint_ids: never[];
  managing_building_ids: never[];
  managing_room_ids: never[];
  like_custom_room_request_ids: never[];
  block_custom_room_request_ids: never[];
  real_room_ids: string[];
  point: number;
  point_transfers: never[];
  tenant_status: string;
  is_rent_supported: boolean;
  sales_regions: never[];
  services: ServiceDetail[];
  recommending_rooms: never[];
  related_custom_room_requests: never[];
  chat_rooms: ChatRoom[];
  term_and_conditions: {
    term_and_condition_id: string;
    is_agreed: boolean;
    agreed_date: string;
  }[];

  fcm_token: string;
  company_id: string;
  company_name: string;
  company_type: string;
  gender: string;
  is_approved: boolean;
  login_type: string;
  phone_number_first: string;
  picture: string;
  user_name: string;
  user_type: string;
  wp_uid: string;
  virtual_account?: Account;

  //dynamic
  createdAt: string;
  updatedAt: string;
  __v: number;
  approved_recommended_rooms: never[];
  pending_recommended_rooms: never[];
  rejected_recommended_rooms: never[];
  approved_recommended_rooms_length: number;
  pending_recommended_rooms_length: number;
  rejected_recommended_rooms_length: number;
  parsed_services: Array<ParsedServices>;
  parsed_services_length: number;
  parsed_last_service: ParsedServices;
  parsed_lease_contracts: Array<LeaseContract>;
  parsed_lease_contracts_length: number;
  parsed_last_lease_contract: LeaseContract;
  is_deliverd: boolean;
  custom_room_request: CustomRoomRequest;
  accounts: Account[];
  account: Account;
  lease_contract: LeaseContract;
  is_sign_up: boolean;
  complaint: Complaint;
  registrant_type: RegistrantType;
  registrant_company_id: string;
  registrant_user_id: string;
  resident_register_urls: Array<any>;
  service: ServiceDetail;
};

export type Complaint = {
  _id: string;
  status: ProgressStatusEnum;
  real_room_id: string;
  lease_contract_id: string;
  tenant_id: string;
  complaint_type: string;
  title: string;
  messages: {
    _id: string;
    picture_urls: string[];
    user_id: string;
    tenant_check_status: string;
    admin_check_status: string;
    description: string;
    createdAt: string;
  }[];
  latest_message: {
    picture_urls: string[];
    user_id: string;
    description: string;
    createdAt: string;
  };
  createdAt: string;
  updatedAt: string;
  __v: number;
};

export type CustomRoomRequest = Partial<_CustomRoomRequest>;

export type _CustomRoomRequest = {
  _id: string;
  matching_type: RequestMatchingTypeEnum;
  custom_room_request_type: string;
  code: string;
  room_codes: string[];
  room_number_codes: string[]; // 유저가 선택한 보홈 기존 매물의 방 번호 리스트
  within_week: number;
  end_date: string;
  how_long_month: number;
  status: RequestStatusEnum;
  regions: Regions[];
  rents: number[];
  deposits: number[];
  room_nums: RoomNumsEnum[];
  room_living_types: RoomLivingTypesEnum[];
  building_forms: buildingFormsEnum[];
  floors: FloorsEnum[];
  is_option: boolean;
  more_options: MoreOptionsEnum[];
  priorities: RoomPriority;
  company_address: string;
  company_location: {
    type: string;
    coordinates: number[];
  };
  commute_transportations: CommuteTransportationsEnum[];
  commute_times: CommuteTimesEnum[];
  comment: string;
  tenant_id: string;
  tenant_name: string;
  self_recommending_room_codes: never[];
  recommended_rooms: RecommendedRoom[];
  createdAt: string;
  updatedAt: string;
  __v: number;
  chat_room_id: string;
  real_room_id: string;
  //dynamic
  tenants: User[];
  room: Room;
  virtual_account: Account;
  new: 'to_be_deleted' | 'new';
  upload_date: string;
  like: boolean;
  custom_room_request_id: string;
  employee: User;
  recommended_room: RecommendedRoom;
};

export type Priorities = {
  rent_priority: number;
  commute_priority: number;
  room_condition_priority: number;
};

export type RecommendedRoom = {
  room_id: string;
  agent_id: string;
  agent_relay_memo: string;
  admin_relay_memo: string;
  admin_check_status: GlobalStatus;
  tenant_check_status: GlobalStatus;
  status: string;
  house_tour_id: string;
  contract_meeting_id: string;

  //virtual
  house_tour: Tour;
  contract_meeting: Tour;
  room: Room;
  agent: User;
};

export type Tour = {
  _id: string;
  custom_room_request_id: string;
  room_ids: string[];
  agent_id: string;
  tenant_id: string;
  start_date: string;
  end_date: string;
  tour_type: TourTypeEnum;
  is_admin: boolean;
  location_description: string;
  //dynamic
  createdAt: string;
  updatedAt: string;
  rooms: Room[];
  custom_room_request: CustomRoomRequest;
  agent: User;
  tenant: User;
};

export type WpBalanceLog = {
  tid: number;
  crrncyType: string;
  changeAmt: number;
  balanceAmt: number;
  createDttm: number;
  inoutType: 'IN' | 'OUT';
  trnsctnType: string;
  trnsctnState: string;
};
export type WpBalanceLogListType = Array<WpBalanceLog>;

export interface QueryResponse<Type> {
  result: Type;
  data?: Type;
  message?: string;
  page_info?: PageInfo;
  limit: number;
  total: number;
  page: number;
  last_page: number;
  [key: string]: any;
}

//todo page_info partial 타입 주의
export interface BaseResponse<Type> {
  message: string;
  data: Type;
  page_info: {
    limit: number;
    total: number;
    page: number;
    last_page: number;
  };
  extra_info: object;
}

export interface Basic {
  axiosConfig?: AxiosRequestConfig;
  options?: any;
}

export type OperationEnum = 'push' | 'delete';

export type RemodelingItemEnum = 'veneer' | 'wallpaper' | 'molding' | 'toilet' | 'kitchen';
export type RemodelingSeasonEnum = 'first' | 'within_few_years' | 'tunable' | 'none';

export type RoomPriority = Priorities;

export type RequestMatchingTypeEnum = 'forward' | 'reverse';

export type Regions = {
  _id?: string;
  center?: {
    type?: string;
    coordinates?: number[];
  };
  sido_name?: string;
  sigungu_name?: string;
  dongli_name?: string;
};

export type RoomNumsEnum = 'one_room' | 'two_room' | 'three_room';
export type RoomLivingTypesEnum = 'open' | 'devide' | 'duplex';
export type ShareFacilitiesEnum =
  | 'kitchen'
  | 'shower_room'
  | 'toilet'
  | 'lounge'
  | 'terrace'
  | 'rooftop'
  | 'laundary_room';
export type buildingFormsEnum =
  | 'villa'
  | 'officetel'
  | 'row_house'
  | 'house'
  | 'multi_family_house_s'
  | 'multi_family_house_g'
  | 'country_house'
  | 'commercial_house'
  | 'hanok_house'
  | 'share_house'
  | 'coliving_house';

export type buildingTypesEnum =
  | 'detached_house'
  | 'apartment_house'
  | 'neighborhood_living_1'
  | 'neighborhood_living_2'
  | 'cultural_gathering'
  | 'religion'
  | 'sale'
  | 'transportation'
  | 'medical'
  | 'education_research'
  | 'elderly_children'
  | 'training'
  | 'exercise'
  | 'business'
  | 'accommodation'
  | 'amusement'
  | 'factory'
  | 'ware_house'
  | 'danger'
  | 'car'
  | 'animal_plant'
  | 'resource_circulation'
  | 'military'
  | 'broadcast'
  | 'power_plant'
  | 'cemetery'
  | 'tourist'
  | 'funeral'
  | 'campsite'
  | 'unregistered'
  | 'others';

export type FloorsEnum = 'ground' | 'rooftop' | 'underground';
export type MoreOptionsEnum =
  | 'new_construction'
  | 'parking'
  | 'security_facility'
  | 'elevator'
  | 'pets'
  | 'roadside_location'
  | 'etc';
export type CommuteTransportationsEnum = 'walk' | 'bus' | 'subway' | 'own_car';
export type CompanyUserEnum = 'master' | 'manager';
export type CommuteTimesEnum = 'walkable' | 'transport_20' | 'transport_40' | 'transport_60' | 'do_not_matter';
export type TourTypeEnum = 'house_tour' | 'contract_meeting';

export type Account = {
  bank_code?: number;
  account_number?: string;
  account_owner_name?: string;
  wp_uid?: string;
  category?: string;
  account_type?: string;
  control?: {
    deposit_amount?: number;
    deposit_end_date?: string;
    is_control?: boolean;
  };
};

export type AccessStatusEnum = 'open' | 'hidden';

export type FacilityType = {
  facility_type: string;
  picture_urls: string[];
  name: string;
  options: never[];
  _id: string;
  description: string;
};

/* 고객사 */
export type SubscriptionTypeEnum = 'basic' | 'advanced' | 'enterprise';

export type OneCompanyType = 'corporation' | 'sole_proprietor';

export type Company = {
  _id: string;
  address: string;
  business_certificate: string;
  business_item: string;
  business_registration_number: string;
  business_status: string;
  business_type: OneCompanyType;
  capital: number;
  company_code: string;
  company_type: string;
  createdAt: string;
  detail_address: string;
  digital_tax_invoice_email: string;
  employee_ids: Array<string>;
  employee_number: number;
  establishment_date: string;
  is_approved: boolean;
  is_activated: boolean;
  limit_manager: number;
  location: {
    type: string;
    coordinates: Array<number>;
  };
  logo_url: string;
  name: string;
  representative_number: string;
  road_address: string;
  subscription_contract_ids: Array<any>;
  term_and_conditions: Array<any>;
  updatedAt: string;
  virtual_account?: VirtualAccount;
  pre_service_request_ids: never[];
  point: number;
  //dynamic
  user: User;
  employees: User[];
  employee: User;
  subscription_contracts: SubscriptionContract[];
  subscription_contract: SubscriptionContract;
  managers: User[];
  manager: User;
  masters: User[];
  master: User;
  accounts: Account[];
  pre_service_request: PreServiceRequest;
  support_payment_account: Account;
  house_hunting_account: Account;
  approved_last_subscription_contract: SubscriptionContract;
  last_subscription_contract_id: string;
  last_subscription_contract: SubscriptionContract;
  default_memo: Memo;
};

export type PreServiceRequest = {
  _id: string;
  category: string;
  status: GlobalStatus;
  company_id: string;
  service_request_id: string;
  user_id: string;
  user_info: User;
  service_info: ServiceDetail;
  //dynamic
  createdAt: string;
  updatedAt: string;
  user: User;
  service_request: ServiceDetail;
};

export type SubscriptionContract = {
  contract_created_date: string;
  contract_start_date: string;
  contract_end_date: string;
  available_user_count: number;
  count: number;
  pending_count: number;
  //dynamic
  subscription_contracts: SubscriptionContract[];
  subscription_contract: SubscriptionContract;
  house_hunting_subscription_contract: SubscriptionContract;
  support_payment_subscription_contract: SubscriptionContract;
};

export type SubscriptionPayment = {
  _id: string;
  category: string;
  payment_type: string;
  payment_method_type: string;
  is_paid: boolean;
  is_expenditure_evidence: boolean;
  certificate_issue_type: CertificateIssueTypeEnum;
  status: string;
  company_id: string;
  subscription_contract_id: string;
  price: number;
  expected_total_support_payment: number;
  user_ids: string[];
  use_start_date: string;
  use_end_date: string;
  expected_payment_start_date: string;
  expected_payment_end_date: string;
  real_payment_date?: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
  pre_expected_total_support_payment: number;
  pre_price_info: {
    user_id: string;
    service_request_id: string;
    pre_service_request_id: string;
    support_payment: number;
  }[];
  sum_date: string;
  price_info: {
    user_id: string;
    support_payment: number;
    service_request_id?: undefined;
  };
};

export type AdminAnnouncement = {
  _id: string;
  announcement_type: AnnouncementTypeEnum;
  reserved_date: string;
  is_fixed: boolean;
  status: ProgressStatusEnum;
  readable_company_type: CompanySelectTypeEnum;
  readable_user_type: UserSelectTypeEnum;
  title: string;
  description: string;
  picture_urls: string[];
  file_urls: string[];
  createdAt: string;
  updatedAt: string;
  user_id: string;
  __v: number;
  user: {
    _id: string;
    company_id: string;
    company_name: string;
    user_name: string;
  };
};

export type ChatRoom = {
  chat_room_id: string;
  chat_room_type: chatRoomTypeEnum;
  collection_name: string;
  custom_room_request_id: string;
  is_activated: boolean;
  _id: string;
};

export type chatRoomTypeEnum = 'common' | 'custom_room_request';

export type MoveIn = {
  admin_check_status: GlobalStatus;
  tenant_check_status: GlobalStatus;
  status: GlobalStatus;
  is_broken: boolean;
  entrance_pictures: string[];
  toilet_pictures: string[];
  kitchen_pictures: string[];
  room_pictures: string[];
  broken_pictures: never[];
  createdAt: string;
  tenant_description: string;
};
export type MoveOut = {
  admin_check_status: GlobalStatus;
  tenant_check_status: GlobalStatus;
  status: GlobalStatus;
  is_broken: boolean;
  entrance_pictures: never[];
  toilet_pictures: never[];
  kitchen_pictures: never[];
  room_pictures: never[];
  broken_pictures: never[];
};
export type MoveExtending = {
  status: GlobalStatus;
};

export type Memo = Partial<{
  _id: string;
  memo_type: string;
  description: string;
  file_urls: string[];
  createdAt: string;
  updatedAt: string;
  __v: number;
}>;

export type CompanyPayment = {
  _id: string;
  name: string;
  subscription_contract: {
    subscription_type: string;
    contract_created_date: string;
    contract_start_date: string;
    available_user_count: number;
    subscription_payment: {
      _id: string;
      company_id: string;
      certificate_issue_type: CertificateIssueTypeEnum;
      subscription_contract_id: string;
      payment_type: string;
      payment_status: SubscriptionPaymentStatusEnum;
      status: GlobalStatus;
      category: ServiceCategoryEnum;
      price: number;
      discount_price: number;
      vat: number;
      use_start_date: string;
      expected_payment_date: string;
      total_sum: number;
      total_count: number;
      real_payment_date?: string;
      payment_method_type: PaymentMethodType;
      is_paid: boolean;
      is_expenditure_evidence: boolean;
      createdAt: string;
      updatedAt: string;
      __v: number;
    };
  };
  virtual_account?: VirtualAccount;
};

export type Service = {
  _id: string;
  name: string;
  employee: {
    _id: string;
    phone_number_first: string;
    user_name: string;
    email: string;
    service: ServiceDetail;
    tenant_status: TenantStatus;
    // service: ServiceDetail;
  };
};

export type ServiceDetail = {
  _id?: string;
  category?: string;
  request_type?: string;
  check_status?: string;
  within_week?: number;
  how_long_month?: number;
  start_date?: string;
  end_date?: string;
  inactive_date?: string;
  description?: string;
  confirmed_date?: string;
  support_payment: number;

  //dynamic
  createdAt: string;
  updateAt: string;
  time_order?: number;
  status_order?: number;
};

export type NotificationType =
  | 'user_confirmation'
  | 'subscription_contract_request'
  | 'subscription_payment_request'
  | 'service_request'
  | 'lease_contract_completion'
  | 'proxy_payment_request'
  | 'subscription_payment_overdue_request'
  //
  | 'room_recommendation'
  | 'user_approval_request'
  | 'custom_room_request_creation';

export type Notification = {
  createdAt: string;
  description: string;
  is_checked: boolean;
  notification_type: NotificationType;
  title: string;
  updatedAt: string;
  __v: number;
  _id: string;
};

export type NotificationCountType = {
  count: number;
  notification_count: number;
  fcm_count: number;
  fcm_counts: Array<any>;
};

export type HistoryType = {
  date: string;
  type: 'request_history' | 'contract_history';
  description: string;
};

export type GlobalStatus =
  | 'none'
  | 'pending'
  | 'approved'
  | 'proceeding'
  | 'completed'
  | 'rejected'
  | 'cancelled'
  | 'delayed'
  | 'checked'
  | 'withdrawn'
  | 'deprecated'
  | 'finished';

export type GlobalRequestType =
  | 'none'
  | 'move_in'
  | 'moving_in'
  | 'move_out'
  | 'moving_out'
  | 'extending'
  | 'moving_out_and_extending'
  | 'new';

export type ContractPaymentStatus =
  | 'confirm_expected'
  | 'sign_expected'
  | 'total_contract_payment_expected'
  | 'subscription_payment_expected'
  | 'payment_expected'
  | 'payment_completed';

export type PaymentStatus = 'down_payment_expected' | 'balance_payment_expected' | 'rent_expected';

export type PaymentMethodType = 'virtual_account' | 'samsung_card' | 'account_transfer' | 'admin_privilege' | 'cms';
export type RequestType = 'new' | 'extending' | 'changing' | 'withdrawal';

export type SubscriberCheckStatus = CheckStatus;
export type AdminCheckStatus = CheckStatus;

export type CompanyContract = {
  _id: string;
  name: string;
  subscription_contract: DetailContract;
};

export type ServiceStatus = 'total' | 'new' | 'changing' | 'extending' | 'withdrawal' | 'moving_out_and_extending';
export type TenantStatus =
  | 'using'
  | 'in_service'
  | 'in_custom_room_request'
  | 'pending_in_custom_room_request'
  | 'contracting'
  | 'contracted'
  | 'moving_in'
  | 'moving_out'
  | 'extending';
export type SupportsStatus = 'total';
export type ProxyPaymentCountStatus = 'total';
export type LeasePaymentOverduesCountStatus = 'total';
export type InactiveCount = 'total';
export type SubscriptionPaymentOverduesCount = 'total';

export type CountDetailStatus =
  | ServiceStatus
  | TenantStatus
  | SupportsStatus
  | ProxyPaymentCountStatus
  | LeasePaymentOverduesCountStatus
  | InactiveCount
  | SubscriptionPaymentOverduesCount;

export type CountDetailType<DetailType> = Array<{
  _id: {
    key: DetailType;
  };
  count: number;
  ids: string[];
}>;

// Count 타입에서 키 뽑아 내기.
export type Count = {
  services_count: CountDetailType<ServiceStatus>;
  usings_count: CountDetailType<TenantStatus>;
  supports_count: CountDetailType<SupportsStatus>;
  proxy_payments_count: CountDetailType<ProxyPaymentCountStatus>;
  lease_payment_overdues_count: CountDetailType<LeasePaymentOverduesCountStatus>;
  inactive_count: CountDetailType<InactiveCount>;
  subscription_payment_overdues_count: CountDetailType<SubscriptionPaymentOverduesCount>;
};

export type CheckStatus = GlobalStatus;

export type LeaseContract = Partial<_LeaseContract>;
export type _LeaseContract = {
  _id: string;
  tenant_id: string;
  custom_room_request_id: string;
  room_id: string;
  agent_id: string;
  management_fee: number;
  deposit: number;
  down_payment: number;
  balance_payment: number;
  support_payment: number;
  lease_payment_ids: Array<string>;
  contract_options: {
    is_pet: boolean;
    is_parking: boolean;
    is_lease_fund: any;
  };
  tenant_check_status: CheckStatus;
  account: Account;
  admin_check_status: AdminCheckStatus;
  agent_check_status: CheckStatus;
  host_check_status: CheckStatus;
  lease_contract_pdfs: Array<string>;
  description: string;
  is_proxy_payment: boolean;
  is_init: boolean;
  payment_method_type: PaymentMethodType;
  change_request: {
    check_status: string;
    description: string;
  };
  request_type: RequestType;
  move_in: MoveInAndOut;
  moving_out: MoveInAndOut;
  real_room_id: string;
  contract_created_date: string;
  contract_end_date: string;
  contract_start_date: string;
  contract_type: string;
  payment_day: number;
  payment_status: string;
  rent: number;
  salary_deduction_contract_pdf: string;
  //dynamic
  lease_payments: LeasePayment[];
  lease_payment: LeasePayment;
  createdAt: string;
  updatedAt: string;
  room?: Room;
  real_room: Room;
  host: User;
  agent: User;
  tenant: User;
};

export type LeasePayment = Partial<_LeasePayment>;
export type _LeasePayment = {
  _id: string;
  real_room_id?: string;
  tenant_id: string;
  host_id?: string;
  lease_contract_id: string;
  payment_type: string;
  contract_payment: number;
  price: number;
  management_fee: number;
  discount_price: number;
  support_payment: number;
  use_start_date: string;
  use_end_date: string;
  certificate_issue_type: CertificateIssueTypeEnum;
  payment_method_type: PaymentMethodType;
  expected_payment_date: string; // 납기일
  real_payment_date: string; // 납부일
  real_transfer_date: string; // 정산일
  is_paid: boolean;
  is_transferred: boolean;
  is_proxy_payment: boolean;
  proxy_payment_status: LeaseProxyPaymentStatusEnum;
  support_status: string;
  payment_status: LeasePaymentStatusEnum;
  account: Account;
  description: string;
  company_id: string;
  subscription_contract_id: string;
  subscription_type: SubscriptionTypeEnum;
  //dynamic
  createdAt: string;
  updatedAt: string;
  host: User;
  agent: User;
  tenant: User;
  available_user_count: number;
  total_sum: number;
};

export type MoveInAndOut = {
  admin_check_status: AdminCheckStatus;
  tenant_check_status: CheckStatus;
  is_broken: boolean;
  entrance_pictures: string[];
  toilet_pictures: string[];
  kitchen_pictures: string[];
  room_pictures: string[];
  broken_pictures: string[];
  createdAt: string;
  tenant_description: string;
};

export type LeasePaymentStatusEnum = 'none' | 'overdue' | 'paid';
export type SubscriptionPaymentStatusEnum = 'none' | 'overdue' | 'paid';
export type ProgressStatusEnum = 'pending' | 'proceeding' | 'completed';
export type LeaseProxyPaymentStatusEnum =
  | 'none'
  | 'pending'
  | 'approved'
  | 'proceeding'
  | 'completed'
  | 'rejected'
  | 'cancelled';

export type Announcement = Partial<_Announcement>;
export type _Announcement = {
  _id: string;
  title: string;
  description: string;
  picture_urls: string[];
  file_urls: string[];
  createdAt: string;
  updatedAt: string;
  __v: number;
};

export type TransferOut = Partial<_TransferOut>;
export type _TransferOut = {
  transfer_out_type: TransferOutType;
  bank_code: number;
  account_number: string;
  account_owner_name: string;
  tid: string;
  price: number;
  send_name: string;
  info: TransferOutInfoType;
  company: Company;
  user: User;
  host: User;
  tenant: User;
  createdAt: Date;
  updatedAt: Date;
};

export type TransferOutType = 'default' | 'to_host_for_lease_payment' | 'to_tenant_for_support_payment';
export type TransferOutInfoType = {
  account_number?: string;
  account_owner_name?: string;
  bank_code?: number;
  bank_name?: string;
  company_name?: string;
  user_name?: string;
  phone_number_first: string;
};

export type MutationResult<T> = UseMutationResult<AxiosResponse<BaseResponse<T>, any>, unknown, any, unknown>;

export type LeaseItem = Partial<_LeaseItem>;

export type _LeaseItem = {
  _id: string;
  rent_type: LesaeItemRentType;
  rent_amount: number;
  rent_start_day: number;
  rent_end_day: number;
  zip: string;
  address: string;
  detail_address: string;
  pay_day: LeaseItemPaydayType;
  receive_type: LeaseItemReceiveType;
  ceo: string;
  phone_one: string;
  phone_two: string;
  phone_three: string;
  biz_name: string;
  biz_no: string;
  bank_code: number;
  account_no: string;
  account_holder: string;
  _ceo: string;
  _phnoe_one: string;
  _phone_two: string;
  _phone_three: string;
  _biz_name: string;
  _biz_no: string;
  _account_no: string;
  _account_holder: string;
  mills: string;
  hash_value: string;
  create_day: string;
  register_yn: YNType;
  terminate_yn: YNType;
  customer_number: string;
  mid: string;
  payments: LeaseItemPaymentType[];
  status: GlobalStatus;
  change: LeaseItemChangeType;
  createdAt: Date;
  updatedAt: Date;
  //virtual
  payment: LeaseItemPaymentType;
  bank_type: BankType;
  bank_name: string;
  bank_url: string;
};
export type Bank = {
  bank_code?: number;
  bank_name?: string;
  swift_code?: string;
  bank_type?: BankType;
  bank_order?: number;
  bank_url?: string;
  createdAt?: Date;
  updateAt?: Date;
};

export type BankType = 'bank' | 'stock';
export type LesaeItemRentType = 'HOUSE' | 'STORE' | 'OFFICETEL';
export type LeaseItemReceiveType = 'RECEIVE_FIRST' | 'RECEIVE_LATER';
export type YNType = 'Y' | 'N';
export type LeaseItemPaymentType = {
  rent_amount: number;
  acquire_yn: YNType;
  status: GlobalStatus;
  requested_date: Date;
  received_date: Date;
};
export type LeaseItemChangeType = {
  update_field: LeaseItemUpdateFieldType;
  rent_amount: string;
  rent_end_day: string;
  agree_code: string;
  status: GlobalStatus;
};
export type LeaseItemUpdateFieldType = 'RENT_AMOUNT' | 'RENT_END_DAY';
export type LeaseItemAgreeCodeType = 'AGREE' | 'DISAGREE';
export type LeaseItemPaydayType =
  | '01'
  | '02'
  | '03'
  | '04'
  | '05'
  | '06'
  | '07'
  | '08'
  | '09'
  | '10'
  | '11'
  | '12'
  | '13'
  | '14'
  | '15'
  | '16'
  | '17'
  | '18'
  | '19'
  | '20'
  | '21'
  | '22'
  | '23'
  | '24'
  | '25'
  | '26'
  | '27'
  | '28'
  | '29'
  | '30'
  | '31';

export type PhonePrefixType = '010' | '011' | '016' | '017' | '019';

export type FuntionType = (...args: any) => any;
//#region alias
export type CompanyTypeEnum = CompanyType;
export type CompanySelectTypeEnum = CompanyType;
export type UserTypeEnum = UserType;
export type UserSelectTypeEnum = UserType;
export type Infinite = Basic;

export type RoomRequest = Company;
export type RequestRoom = Company;
export type CompanyUser = Company;
export type HostUser = Company;
export type HostSimpleInfo = Company;
export type TenantLeaseContract = Company;
export type TenantsWithTour = Company;
export type Using = Company;
export type CommonUserType = Company;
export type CompanyTenant = Company;
export type SupportPaymentCompany = Company;
export type SupportPaymentCompanyUser = Company;
export type TenantsWithContract = Company;
export type CompanyWithPreServiceRequest = Company;
export type TenantsWithComplaints = Company;
export type HostLeaseContractLeasePayments = Company;
export type CompanySupportPayment = Company;
export type CompanyManagers = Company;
export type CompanyManager = Company;
export type CompanySubscriber = Company;

export type HostBuildingRoom = Building;
export type BuildingRoom = Building;
export type BuildingRoomTemplate = Building;
export type BuildingDetail = Building;
export type BuildingRoomForMap = Building;

export type RoomNewRequest = CustomRoomRequest;
export type Payment = LeasePayment;
export type ParsedServices = ServiceDetail;

export type HostLeaseContract = LeaseContract;
export type DetailContract = LeaseContract;

export type VirtualAccount = Account;
export type AccountType = Account;

export type RoomDetailInfo = Room;

//#endregion

import { HStack, Flex, VStack, Divider } from '@chakra-ui/react';
import InfoSector from '../sections/InfoSector';

const Settlement = () => {
  return (
    <HStack alignItems={'flex-start'} spacing={10} pr={10}>
      {/* section 1 */}
      <Flex w={'250px'}>
        <VStack w={'full'} spacing={6}>
          {/* 임대료 입금 */}
          <InfoSector total={0}>임대료 입금</InfoSector>
          {/* 구독료 입금 */}
          <InfoSector total={0}>구독료 입금</InfoSector>
          {/* 임대료 체납 */}
          <InfoSector total={0}>임대료 체납</InfoSector>
          {/* 구독료 체납 */}
          <InfoSector total={0}>구독료 체납</InfoSector>
          {/* 임대료 대납 요청 */}
          <InfoSector total={0}>임대료 대납 요청</InfoSector>
          <Divider />
          {/* 월별 임대료 입금 */}
          <InfoSector total={0}>월별 임대료 입금</InfoSector>
          {/* 월별 구독료 입금 */}
          <InfoSector total={0}>월별 구독료 입금</InfoSector>
          {/* 월별 세금계산서 발행 */}
          <InfoSector total={0}>월별 세금계산서 발행</InfoSector>
          {/* 월별 현금영수증 발행 */}
          <InfoSector total={0}>월별 현금영수증 발행</InfoSector>
        </VStack>
      </Flex>
    </HStack>
  );
};
export default Settlement;

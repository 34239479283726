import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { useUpdateSubscriptionPaymentStatus } from '../../../../lib/admin/hooks/mutation';
import { subscriptionPaymentStatusTrans } from '../../../../lib/common/utils/utils';
import { ContractPaymentStatus, SubscriptionPaymentStatusEnum } from '../../../../lib/common/utils/axios/types';
import {
  contract_status,
  global_payment_status_select,
  subscription_payment_status,
} from '../../../../statics/common/data/selectbox';
import SelectBox from '../../../agent/SelectBox/base';

interface ChangeSubscriptionPaymentStatusProps {
  // is_paid: boolean;
  payment_status: SubscriptionPaymentStatusEnum;
  subscription_payment_id: string;
}

const ChangeSubscriptionPaymentStatus: React.FC<ChangeSubscriptionPaymentStatusProps> = ({
  payment_status,
  subscription_payment_id,
}) => {
  const { mutateAsync } = useUpdateSubscriptionPaymentStatus(subscription_payment_id);
  const [value, setValue] = useState<SubscriptionPaymentStatusEnum>(payment_status);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();

  const handleChangeOption = (e: any) => {
    // const v = subscriptionPaymentStatusTrans(e.target.value) as SubscriptionPaymentStatusEnum;
    const v = e.target.value;
    setValue(v);
    onOpen();
  };

  const handleFinalize = () => {
    mutateAsync({
      payment_status: value,
    }).then(() => {
      onClose();
    });
  };

  const handleCloseAlert = () => {
    setValue(payment_status);
  };

  const handleCancle = () => {
    handleCloseAlert();
    onClose();
  };

  return (
    <>
      <SelectBox
        value={payment_status}
        onChange={(e) => {
          handleChangeOption(e);
        }}
      >
        {global_payment_status_select.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </SelectBox>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef.current}
        onClose={onClose}
        isCentered
        onEsc={handleCloseAlert}
        onOverlayClick={handleCloseAlert}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              재확인
            </AlertDialogHeader>

            <AlertDialogBody>확정하시겠습니까?</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef.current} variant={'gray'} onClick={handleCancle}>
                취소
              </Button>
              <Button colorScheme="red" onClick={handleFinalize} variant={'basic'} ml={3}>
                확정하기
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default ChangeSubscriptionPaymentStatus;

import { HamburgerIcon } from '@chakra-ui/icons';
import {
  Avatar,
  Box,
  Center,
  Flex,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { isArray } from 'lodash';
import React, { forwardRef } from 'react';
import {
  Link as RouterLink,
  matchPath,
  NavLink,
  NavLinkProps,
  useLocation,
  useMatch,
  useNavigate,
} from 'react-router-dom';
import { AlertStore } from '../../../contexts/common/AlertContext';
import { useGetUserType } from '../../../lib/common/hooks/query';
import { datedetaildotkoformat, datedotkoformat } from '../../../lib/common/utils/dateformatter';
import { auth, logout } from '../../../lib/common/utils/firebase/init';
import { IconCircleContiner, SettingFocusIcon, SettingIcon } from '../../../statics/common/icons';
import { SubTitleInfosType } from '../main/MainLayout';
import {
  AdminSideBarLists,
  AgencySideBarLists,
  CompanySideBarLists,
  SideBarListsType,
} from '../../../components/common/Sidebar/sidebarLists';
import AdminMenu from '../../admin/HeaderNavBar';
import CompanyMenu from '../../../components/subscriber/HeaderNavBar';
import * as Util from '../../../lib/common/utils/Util';
import * as Type from '../.././../types/common/CommonType';
import Loading from '../../../components/common/Loading';

interface ToggleMenuContainerProps {
  lists: SideBarListsType;
}

const ToggleMenuContainer: React.FC<ToggleMenuContainerProps> = ({ lists }) => {
  return (
    <>
      {lists.map((list, index) => {
        if (isArray(list)) {
          return <div key={index}></div>;
        }
        return (
          <MenuItem key={list.title}>
            {/* 분기점. 수정 */}
            <RouterLink to={list.route_name || ''}>{list.title}</RouterLink>
          </MenuItem>
        );
      })}
    </>
  );
};

interface HeaderNavBarType {
  title: string;
  subTitleInfos: SubTitleInfosType;
}

const HeaderNavBar: React.FC<HeaderNavBarType> = ({ title, subTitleInfos }) => {
  const { pathname } = useLocation();
  const routeUserType = Util.getRouteUserTypeFromUrl(pathname);

  const { data } = useGetUserType();

  const { company_type: userType } = data;

  return (
    <Box h="100%" w="100%" id="header">
      <Flex h="max-content" alignItems={'center'} justifyContent={'space-between'} position={'relative'} mb={6}>
        <Center>
          <Box display={{ base: 'inline-block', md: 'none' }} mr={3}>
            <Menu>
              <MenuButton as={IconButton} aria-label="Options" icon={<HamburgerIcon />} variant="outline" />
              <MenuList zIndex={100}>
                {
                  {
                    admin: <ToggleMenuContainer lists={AdminSideBarLists} />,
                    subscriber: <ToggleMenuContainer lists={CompanySideBarLists} />,
                    agency: <ToggleMenuContainer lists={AgencySideBarLists} />,
                  }[userType]
                }
              </MenuList>
            </Menu>
          </Box>
          <Box fontWeight={'normal'} fontSize="24px" boxSize={'max-content'} display={'inline-block'} mr={8}>
            {title}
          </Box>
        </Center>
        {/* 클릭시 메뉴 팝업 */}
        {
          { admin: <AdminMenu />, company: <CompanyMenu />, agency: <CompanyMenu /> }[
            (routeUserType as Type.RouteUserType) || 'admin'
          ]
        }
      </Flex>
      <HStack spacing={2}>
        {subTitleInfos.map((value) => (
          <NavLink
            to={value.router_name}
            key={value.subTitle}
            style={({ isActive }) => ({
              color: isActive ? '#414141' : 'white',
              fontWeight: isActive ? 700 : 400,
              textUnderlineOffset: isActive ? '2px' : 'unset',
              backgroundColor: isActive ? 'white' : '#C4C4C4',
              fontSize: '16px',
              paddingInline: '12px',
              paddingBlock: '8px',
              borderTopLeftRadius: '5px',
              borderTopRightRadius: '5px',
              width: '140px',
              boxShadow: '2px 0px rgba(221,221,221, 0.2)',
            })}
          >
            <Center>
              <Text key={value.subTitle}>{value.subTitle}</Text>
            </Center>
          </NavLink>
        ))}
      </HStack>
    </Box>
  );
};

export default HeaderNavBar;

export type SideBarListsType = (
  | {
      title: string;
      route_name: string;
      items?: undefined;
    }
  | {
      title: string;
      items: {
        title: string;
        route_name: string;
      }[];
      route_name?: undefined;
    }
  | {
      title: string;
      route_name: string;
    }[]
)[];

export const AdminSideBarLists: SideBarListsType = [
  {
    title: '대시 보드',
    route_name: '/admin/dashboard/companies',
  },
  {
    title: '회원 관리',
    route_name: '/admin/manage/customers/user',
  },
  {
    title: '구독 관리',
    route_name: '/admin/manage/companies/new',
  },
  {
    title: '임직원 관리',
    items: [
      {
        title: '입주요청 관리',
        route_name: '/admin/manage/moverequests/stepone',
      },
      {
        title: '투어 일정',
        route_name: '/admin/manage/tours/main',
      },
      {
        title: '입주중 관리',
        route_name: '/admin/manage/moves/main',
      },
      {
        title: '민원 관리',
        route_name: '/admin/manage/complaints/main',
      },
    ],
  },
  // {
  //   title: '월세 지원 관리',
  //   route_name: '/admin/manage/supportpayment/main',
  // },
  {
    title: '매물 관리',
    route_name: '/admin/manage/estates/rooms',
  },
  {
    title: '채팅 관리',
    route_name: '/admin/manage/chats/all',
  },
  {
    title: '정산 관리',
    route_name: '/admin/manage/settlements/main',
  },
  [
    {
      title: '공지 관리',
      route_name: '/admin/manage/announcements/main',
    },
    {
      title: '계정 관리',
      route_name: '/admin/manage/paccounts',
    },
  ],
  {
    title: 'AI 이미지 변환',
    route_name: '/admin/manage/setting/functions/removefurnitures',
  },
  {
    title: '웰컴 머천트 관리',
    route_name: '/admin/manage/wp/main',
  },
  {
    title: '송금',
    route_name: '/admin/manage/transfer',
  },
];

export const CompanySideBarLists: SideBarListsType = [
  {
    title: '대시보드',
    route_name: 'company/manage/dashboard',
  },
  {
    title: '구독 관리',
    route_name: 'company/manage/subscription',
  },
  {
    title: '직원 관리',
    route_name: 'company/manage/employee',
  },
  {
    title: '정산 관리',
    route_name: 'company/manage/payment',
  },
  {
    title: '고객 센터',
    route_name: 'company/manage/service',
  },
];

export const AgencySideBarLists: SideBarListsType = [
  //  alias sidabar |  sidebar tab | menu | 메뉴 | 메뉴 리스트 | 매뉴 탭 | 사이드바
  // {
  //   title: '대시 보드',
  //   route_name: 'agency/dashboard',
  // },
  // {
  //   title: '요청 관리',
  //   route_name: 'agency/manage/requests',
  // },
  {
    title: '매물 관리',
    route_name: 'agency/manage/estates/rooms',
  },
  {
    title: '입주요청 관리',
    route_name: 'agency/manage/moverequests/stepone',
  },
  // {
  //   title: '계정 관리',
  //   route_name: 'agency/manage/account',
  // },
  // {
  //   title: '고객 센터',
  //   route_name: 'agency/service',
  // },
];

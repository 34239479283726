import { StarIcon } from '@chakra-ui/icons';
import { Grid, Box, Center, VStack, HStack, Flex, Text, Link } from '@chakra-ui/react';
import { GItem, GTitleItem } from '../../../../../components/common/GridItem';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { BuildingIcon, HouseAddIcon, NotebookIcon } from '../../../../../statics/common/icons';

const Service = () => {
  const navigate = useNavigate();
  return (
    <Flex h="full" flexDir={'column'} justifyContent={'start'} userSelect={'none'}>
      <Grid h="500px" templateRows="repeat(2, 1fr)" templateColumns="repeat(3, 1fr)" gap={6}>
        {/* 관리자를 위한 이용 가이드 */}
        <GItem
          rowSpan={1}
          colSpan={2}
          display={'flex'}
          flexDir={'column'}
          justifyContent={'center'}
          alignItems={'flex-start'}
          fontSize={'20px'}
          transition={'0.2s linear'}
          cursor={'pointer'}
          _hover={{
            opacity: 0.6,
          }}
        >
          <a
            href="https://bohome-livable.notion.site/d92fccb86ae24d61a35bc514ad7f12eb"
            target={'_blank'}
            style={{ padding: '45px', width: '100%', height: '100%' }}
          >
            <NotebookIcon />
            <Text fontSize={'38px'} fontWeight={'medium'}>
              관리자를 위한 이용 가이드
            </Text>
            <Text fontSize={'13px'} fontWeight={'medium'}>
              원활한 보홈 이용을 위한 관리자가 꼭 알아야할 필수 가이드
            </Text>
          </a>
        </GItem>
        {/* 도움이 필요하신가요? */}
        <GItem
          rowSpan={2}
          colSpan={1}
          display={'flex'}
          flexDir={'column'}
          justifyContent={'center'}
          p={'45px'}
          alignItems={'flex-start'}
          fontSize={'20px'}
          transition={'0.2s linear'}
          userSelect={'text'}
        >
          <VStack alignItems={'flex-start'} fontSize={'38px'} fontWeight={700} mb={9}>
            <Text>도움이</Text>
            <Text>필요하신가요?</Text>
          </VStack>
          <VStack spacing={0} alignItems={'flex-start'}>
            <Text fontSize={'17px'} fontWeight={'medium'}>
              1833-9092
            </Text>
            <VStack spacing={0} py={3} alignItems={'flex-start'}>
              <Text fontSize={'17px'} fontWeight={'medium'}>
                보홈 고객센터 운영 시간
              </Text>
              <Text fontSize={'17px'} fontWeight={'medium'}>
                평일 10:00~18:00
              </Text>
              <Text fontSize={'17px'} fontWeight={'medium'}>
                점심시간 13:00~14:00
              </Text>
            </VStack>
            <Text fontSize={'17px'} fontWeight={'medium'}>
              service@bohome.kr
            </Text>
          </VStack>
        </GItem>
        {/* 플랜 안내 */}
        <GItem
          rowSpan={1}
          colSpan={1}
          display={'flex'}
          flexDir={'column'}
          justifyContent={'center'}
          alignItems={'flex-start'}
          fontSize={'20px'}
          transition={'0.2s linear'}
          cursor={'pointer'}
          _hover={{
            opacity: 0.6,
          }}
        >
          <a
            href="https://bohome-livable.notion.site/0efc5ed19597493fa71458a720806011"
            target={'_blank'}
            style={{ padding: '45px', width: '100%', height: '100%' }}
          >
            <HouseAddIcon />
            <Text fontSize={'20px'} fontWeight={'medium'}>
              플랜 안내
            </Text>
            <Text fontSize={'13px'} fontWeight={'medium'}>
              보홈 구독 플랜이 궁금하신가요?
            </Text>
          </a>
        </GItem>
        {/* FAQ */}
        <GItem
          rowSpan={1}
          colSpan={1}
          display={'flex'}
          flexDir={'column'}
          justifyContent={'center'}
          alignItems={'flex-start'}
          fontSize={'20px'}
          transition={'0.2s linear'}
          cursor={'pointer'}
          _hover={{
            opacity: 0.6,
          }}
        >
          <a
            href="https://bohome-livable.notion.site/FAQ-888e7878cc3f42f7becf6c95724ca38f"
            target={'_blank'}
            style={{ padding: '45px', width: '100%', height: '100%' }}
          >
            <BuildingIcon />
            <Text fontSize={'20px'} fontWeight={'medium'}>
              FAQ
            </Text>
            <Text fontSize={'13px'} fontWeight={'medium'}>
              자주 물어보는 질문
            </Text>
          </a>
        </GItem>
      </Grid>
    </Flex>
  );
};

export default Service;

import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  Grid,
  HStack,
  ListItem,
  Radio,
  RadioGroup,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Tr,
  UnorderedList,
  VStack,
} from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { queryClient } from '../../../../../../..';
import { GTitleItem } from '../../../../../../../components/common/GridItem';
import RequestSubscriptionModalButton from '../../../../../../../components/subscriber/Modals/RequestSubscription';
import { fetchClient } from '../../../../../../../lib/common/utils/axios';
import { headCount_convert } from '../../../../../../../statics/common/data/radio';
import { staticThreeCommafy } from '../../../../../../../lib/common/utils/digitformatter';
import { LeftArrowIcon } from '../../../../../../../statics/common/icons';
import SelectBox from '../../../../../../../components/agent/SelectBox/base';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../../../../../../lib/common/utils/firebase/init';

const Basic = () => {
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  return (
    <Formik
      initialValues={{
        planType: 'basicPlan',
        headCount: '0',
        paymentSubscription: '',
        paymentMethod: 'is_month',
        issuanceMethod: 'tax_invoice',
        payment_method_type: 'cms',
      }}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(false);
        setIsSubmitting(true);
        const { planType, headCount, paymentSubscription, paymentMethod, issuanceMethod, payment_method_type } = values;
        const token = await user?.getIdToken();
        const instance = await fetchClient({ headers: { token } });
        const value = await instance.post('/subscriber/master/subscription-contract', {
          payment_day: 20,
          subscription_type: 'basic',
          is_year: paymentMethod === 'is_month' ? false : true,
          available_user_count: headCount,
          certificate_issue_type: issuanceMethod,
          payment_method_type,
        });
        const subscription_contract_id = value.data.data._id;
        await instance.post(
          `/subscriber/master/subscription-contracts/${subscription_contract_id}/subscription-payment/init`,
        );
        queryClient.invalidateQueries();
        navigate('/company/manage/subscription', {
          replace: true,
        });
        setIsSubmitting(false);
      }}
    >
      {({ values, errors, isValid, handleChange, setFieldValue }) => {
        const fee_per_person = 50000;
        const is_year = values.paymentMethod === 'is_year';
        const month = 1;
        const year = month * 12;
        const discount = 0.1;
        const vat = 0.1;
        //
        const monthlyFee_number = fee_per_person * parseInt(values.headCount);
        const monthlyFee = staticThreeCommafy(monthlyFee_number);

        // only is_year
        const discounted_number = year * monthlyFee_number * discount;
        const discounted = staticThreeCommafy(discounted_number);
        // --
        const finalFee_beforeVAT_number = is_year
          ? year * monthlyFee_number * (1 - discount)
          : 3 * month * monthlyFee_number;
        const finalFee_number = Math.floor(finalFee_beforeVAT_number * (1 + vat));
        const finalFee = staticThreeCommafy(finalFee_number);
        const vatFee_number = finalFee_beforeVAT_number * vat;
        const vatFee = staticThreeCommafy(vatFee_number);

        return (
          <Form style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
            <Grid h="100%" gridAutoRows={'minmax(170px, auto)'} templateColumns="repeat(13, 1fr)" gap={6}>
              {/* 플랜 정보 */}
              <GTitleItem
                upperSection={
                  <HStack>
                    <Button
                      style={{ all: 'unset', cursor: 'pointer' }}
                      as={RouterLink}
                      to={'/company/manage/subscription'}
                    >
                      <LeftArrowIcon w={6} h={6} />
                    </Button>
                    <Text fontSize={'18px'} fontWeight={'medium'}>
                      베이직 플랜으로 시작
                    </Text>
                  </HStack>
                }
                rowSpan={4}
                colSpan={9}
              >
                <Container maxW={'unset'} mx={4} my={7}>
                  <VStack alignItems={'flex-start'} spacing={7}>
                    <VStack alignItems={'flex-start'}>
                      <Text fontSize={'18px'} fontWeight={'bold'}>
                        구독하실 플랜
                      </Text>
                      <VStack>
                        <HStack>
                          <Text fontSize={'14px'} minW="65px">
                            플랜 종류
                          </Text>
                          <SelectBox
                            placeholder="베이직 플랜"
                            h={'30px'}
                            w={'150px'}
                            fontSize={'12px'}
                            icon={<Box />}
                            isDisabled
                            _disabled={{
                              opacity: 1,
                              cursor: 'text',
                            }}
                          >
                            {/* <option>1</option> */}
                          </SelectBox>
                        </HStack>
                        <HStack>
                          <Text fontSize={'14px'} minW="65px">
                            이용 인원
                          </Text>
                          <SelectBox
                            // placeholder="선택"
                            h={'30px'}
                            w={'150px'}
                            fontSize={'12px'}
                            onChange={(e) => {
                              setFieldValue('headCount', e.target.value);
                            }}
                          >
                            {headCount_convert.map((value) => (
                              <option key={value.label} value={value.value}>
                                {value.label}
                              </option>
                            ))}
                          </SelectBox>
                        </HStack>
                      </VStack>
                    </VStack>
                    <VStack alignItems={'flex-start'}>
                      <Text fontSize={'18px'} fontWeight={'bold'}>
                        결제 방식
                      </Text>
                      <VStack>
                        <RadioGroup
                          defaultValue={'is_month'}
                          onChange={(e) => {
                            setFieldValue('paymentMethod', e);
                          }}
                          // value={value}
                        >
                          <VStack alignItems={'flex-start'}>
                            <Radio size={'sm'} value="is_month" colorScheme={'facebook'}>
                              <Text fontSize={'14px'}>월 결제</Text>
                            </Radio>
                            <Radio size={'sm'} value="is_year" colorScheme={'facebook'}>
                              <HStack spacing={4}>
                                <Text fontSize={'14px'}>연 결제</Text>
                                <Tag variant={'green'} fontWeight={'bold'} borderRadius={'lg'}>
                                  {discount * 100}% 할인
                                </Tag>
                              </HStack>
                            </Radio>
                          </VStack>
                        </RadioGroup>
                      </VStack>
                    </VStack>
                    <VStack alignItems={'flex-start'} w="full" pr={8}>
                      <Text fontSize={'18px'} fontWeight={'bold'}>
                        결제 수단
                      </Text>

                      <VStack w="full">
                        <Table variant={'simple_same'} border="1px solid" borderColor={'gray.200'}>
                          <Tbody>
                            <Tr border="1px solid" borderColor={'gray.200'}>
                              <Td>
                                <RadioGroup
                                  defaultValue={'cms'}
                                  onChange={(e) => {
                                    setFieldValue('payment_method_type', e);
                                  }}
                                  // value={value}
                                  width={'full'}
                                >
                                  <HStack alignItems={'flex-start'} w="full" spacing={'50px'}>
                                    <Radio size={'sm'} value="cms" colorScheme={'facebook'}>
                                      <Text fontSize={'14px'}>CMS자동이체</Text>
                                    </Radio>
                                    <Radio size={'sm'} value="account_transfer" colorScheme={'facebook'}>
                                      <Text fontSize={'14px'}>계좌이체</Text>
                                    </Radio>
                                  </HStack>
                                </RadioGroup>
                              </Td>
                            </Tr>
                            <Tr border="1px solid" borderColor={'gray.200'}>
                              <Td border="1px solid" borderColor={'gray.200'} rowSpan={2}>
                                <Text fontSize={'14px'}>증빙 발행 수단</Text>
                              </Td>
                              <Td>
                                <RadioGroup
                                  defaultValue={'tax_invoice'}
                                  onChange={(e) => {
                                    setFieldValue('issuanceMethod', e);
                                  }}
                                  // value={value}
                                  width={'full'}
                                >
                                  <HStack alignItems={'flex-start'} w="full" spacing={'50px'}>
                                    <Radio size={'sm'} value="tax_invoice" colorScheme={'facebook'}>
                                      <Text fontSize={'14px'}>세금계산서 신청</Text>
                                    </Radio>
                                    <Radio size={'sm'} value="cash_receipt" colorScheme={'facebook'}>
                                      <Text fontSize={'14px'}>현금 영수증 신청</Text>
                                    </Radio>
                                  </HStack>
                                </RadioGroup>
                              </Td>
                            </Tr>
                            <Tr border="1px solid" borderColor={'gray.200'}>
                              <Td>
                                <Box fontSize={'14px'}>
                                  <UnorderedList>
                                    <ListItem>
                                      구독 계약 서명 및 결제 완료 후 7일 이내로 전자세금계산서 또는 현금영수증이
                                      발행됩니다.
                                    </ListItem>
                                  </UnorderedList>
                                </Box>
                              </Td>
                            </Tr>
                          </Tbody>
                        </Table>
                      </VStack>
                    </VStack>
                    <VStack alignItems={'flex-start'}>
                      <Text fontSize={'18px'} fontWeight={'bold'}>
                        구독 안내 사항
                      </Text>
                      <UnorderedList fontSize={'14px'} pl={6} pr={8}>
                        <ListItem>
                          월 결제 구독 신청 시 이용자의 최소주거기간 보장을 위해 첫 3개월 구독료는 선납이며, 이 기간
                          동안 플랜 하향은 불가능합니다.
                        </ListItem>
                        <ListItem>
                          월 결제는 4개월차부터 CMS 자동출금이 진행됩니다. 또한, 매 달 전자(세금)계산서 또는
                          현금영수증이 발행됩니다.
                        </ListItem>
                        <ListItem>
                          연 결제 시, 할인이 적용된 금액으로 결제금액을 청구합니다. 구독 도중 플랜 상향 또는 하향 시
                          발생되는 차액 정산분은 보홈이 환불 또는 청구합니다.
                        </ListItem>
                        <ListItem>
                          구독료 결제 이후, 첫 이용자 승인이 발생하는 시점부터 구독일수 카운팅이 시작 됩니다.
                        </ListItem>
                        <ListItem>환불 규정 및 자세한 정책사항은 계약서를 확인해 주세요.</ListItem>
                      </UnorderedList>
                    </VStack>
                  </VStack>
                </Container>
              </GTitleItem>
              <GTitleItem
                upperSection={
                  <HStack justifyContent={'space-between'}>
                    <Text fontSize={'18px'} fontWeight={'medium'}>
                      견적 상세
                    </Text>
                    <Text textStyle={'medium14'}>VAT포함</Text>
                  </HStack>
                }
                rowSpan={2}
                colSpan={4}
                // minW={'340px'}
              >
                <Flex flexDir={'column'} p={6} w="full" h="full" justify={'space-between'}>
                  <VStack spacing={3}>
                    <HStack justifyContent={'space-between'} w="full">
                      <Text fontSize={'16px'}>월 구독료</Text>
                      <Text fontSize={'16px'}>{monthlyFee}</Text>
                    </HStack>
                    <Divider borderColor={'#DDDDDD'} borderStyle={'dashed'} />
                    {is_year ? (
                      <>
                        <HStack justifyContent={'space-between'} w="full">
                          <Text fontSize={'16px'}>연 결제</Text>
                          <Text fontSize={'16px'}>*{`${year}`}</Text>
                        </HStack>
                        <Divider borderColor={'#DDDDDD'} borderStyle={'dashed'} />
                        <HStack justifyContent={'space-between'} w="full">
                          <Text fontSize={'16px'}>-{discount * 100}% 할인</Text>
                          <Text fontSize={'16px'}>- {discounted}</Text>
                        </HStack>
                      </>
                    ) : (
                      <>
                        <HStack justifyContent={'space-between'} w="full">
                          <Text fontSize={'16px'}>최초 {`${3}`}개월분</Text>
                          <Text fontSize={'16px'}>*{`${3}`}</Text>
                        </HStack>
                      </>
                    )}
                    <Divider borderColor={'#DDDDDD'} borderStyle={'dashed'} />
                    <HStack justifyContent={'space-between'} w="full">
                      <Text fontSize={'16px'}>VAT</Text>
                      <Text fontSize={'16px'}>{vatFee}</Text>
                    </HStack>
                    <Divider borderColor={'#DDDDDD'} />
                    <Flex justifyContent={'space-between'} w="full">
                      <Text fontSize={'21px'} fontWeight={'medium'} color={'zetaBlue'}>
                        청구 금액
                      </Text>
                      <Text fontSize={'21px'} fontWeight={'medium'} color={'zetaBlue'}>
                        {finalFee}원
                      </Text>
                    </Flex>
                  </VStack>
                  <Box mt={6} mb={2}>
                    <RequestSubscriptionModalButton
                      initBtnProps={{
                        isDisabled: values.headCount === '0',
                        isLoading: isSubmitting,
                      }}
                    >
                      {'구독 계약 요청'}
                    </RequestSubscriptionModalButton>
                  </Box>
                </Flex>
              </GTitleItem>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Basic;

import { Box, Button, Center, Flex, Image } from '@chakra-ui/react';
import { useState } from 'react';
import OneFileCommonDropzone from '../../../components/common/Dropzone/OneFileCommonDropzone';
import { useRemoveFurnitures_AIVoucher } from '../../../lib/common/hooks/mutation';
import useFilePreview from '../../../lib/common/hooks/useFilePreview';
import { GridSpinner } from '../../../statics/common/icons/spinners';

const RemoveFurnitures = () => {
  const { mutateAsync, isLoading } = useRemoveFurnitures_AIVoucher();
  const makeResult = async (file: File) => {
    funcInSuccessProcess(file);
    const formData = new FormData();
    formData.append('file', file);
    await mutateAsync({
      formData,
    }).then((value) => {
      const url = value.data.data;
      setRefinedUrl(url);
    });
  };
  const [funcInSuccessProcess, originalPreviewUrl, setOriginalPreviewUrl] = useFilePreview();
  const [refinedUrl, setRefinedUrl] = useState<string | null>(null);

  // console.log(originalPreviewUrl);

  const handleClickResetButton = () => {
    setRefinedUrl(null);
    setOriginalPreviewUrl(null);
  };

  return (
    <Flex flexDir={'column'} h="full" justifyContent={'space-between'}>
      <Flex flexDir={'column'} h="full">
        <Flex>
          <Box textStyle={'bold26'} borderLeft={'5px solid #183377'} pl={'15px'} mb={3} minW={'max'}>
            생활물품 지우기
          </Box>
          <Flex justifyContent={'end'} w="full">
            <Button as={'a'} variant={'basic'} mr={2} href={'https://bohomeai.netlify.app/'} target={'_blank'}>
              브러쉬로 지우기
            </Button>
            <Button variant={'reject'} mr={2} onClick={handleClickResetButton} isDisabled={!refinedUrl}>
              리셋하기
            </Button>
            <Button
              as={'a'}
              variant={'basic'}
              href={refinedUrl || ''}
              download={!!refinedUrl}
              isDisabled={!refinedUrl}
              isLoading={isLoading}
            >
              결과물 저장하기
            </Button>
          </Flex>
        </Flex>
        <OneFileCommonDropzone funcInSuccessProcess={makeResult}>
          {!isLoading ? (
            <p>이미지 처리를 위해 JPG/JPEG 파일 클릭이나 드래그해주세요.</p>
          ) : (
            <Center flexDir={'column'}>
              <GridSpinner />
              <Box mt={2} textStyle={'medium14'}>
                10~20초 이내 완료됩니다.
              </Box>
            </Center>
          )}
        </OneFileCommonDropzone>
        <Flex id="result" mt={3} mb={5} h="full">
          <Flex flexDir={'column'} flex={1}>
            <Box>원본</Box>
            <Image src={originalPreviewUrl}></Image>
            {/* <Box bgColor={'gray'} w="full" h="full"></Box> */}
          </Flex>
          <Box layerStyle={'gray_dashed_line'} mx={2}></Box>
          <Flex flexDir={'column'} flex={1}>
            <Box>결과물</Box>
            {refinedUrl && <Image src={refinedUrl}></Image>}
            {/* <Box bgColor={'gray'} w="full" h="full"></Box> */}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default RemoveFurnitures;

import { Modal, ModalContent, ModalOverlay } from '@chakra-ui/react';
import { FormikProps } from 'formik';
import DaumPostcode, { Address } from 'react-daum-postcode';
import { ModalDisclosureProps } from './base';
import { useLocalFormik } from '../../../lib/common/hooks/useLocalFormik';

interface AddressFinderProps extends ModalDisclosureProps {
  formik: FormikProps<any>;
}

const AddressFinder: React.FC<AddressFinderProps> = ({ isOpen, onClose, formik }) => {
  const [infos, onSelectChange, onSet] = useLocalFormik('upload_building_form');
  const handleComplete = (data: Address) => {
    const {
      address,
      roadAddress,
      autoRoadAddress,
      zonecode,
      sido,
      sigungu,
      bname,
      buildingCode,
      jibunAddress,
      autoJibunAddress,
    } = data;
    console.log('hmm: ', data);
    const jibun = jibunAddress || autoJibunAddress;
    const road = roadAddress || autoRoadAddress;
    formik.setFieldValue('address', address);
    formik.setFieldValue('road_address', road);
    formik.setFieldValue('jibun_address', jibun);
    formik.setFieldValue('zip_code', zonecode);
    formik.setFieldValue('sido_name', sido);
    formik.setFieldValue('sigungu_name', sigungu);
    formik.setFieldValue('dongli_name', bname);
    formik.setFieldValue('latitude', ''); //
    formik.setFieldValue('longitude', ''); //
    formik.setFieldValue('building_number', buildingCode);
    onSet({
      address: address,
      road_address: roadAddress,
      zip_code: zonecode,
      sido_name: sido,
      sigungu_name: sigungu,
      dongli_name: bname,
      // latitude,
      // longitude,
      building_number: buildingCode,
    });
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside" size={'4xl'} motionPreset="scale">
      <ModalOverlay />
      <ModalContent p={10}>
        {/* modal  */}
        <DaumPostcode
          onComplete={(data) => {
            handleComplete(data);
            onClose();
          }}
        />
      </ModalContent>
    </Modal>
  );
};

export default AddressFinder;

import {
  Flex,
  HStack,
  InputGroup,
  InputLeftElement,
  Input,
  Button,
  Center,
  Box,
  Divider,
  Text,
  VStack,
  Spinner,
} from '@chakra-ui/react';
import { Formik } from 'formik';
import { useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import { useQuery } from 'react-query';
import { room_manage_new_request_key, room_new_request_path } from '../../../../../statics/agent/data/KeyPath';
import { fetchClient } from '../../../../../lib/common/utils/axios';
import { BaseResponse, RoomNewRequest } from '../../../../../lib/common/utils/axios/types';
import { ClickIconContainer, LeftArrowIcon, RightArrowIcon } from '../../../../../statics/common/icons';
import NewRequestContainer from '../../../../../layouts/agent/CustomRoomRequest/new';
import New from './new2/new';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../../../../lib/common/utils/firebase/init';

export const new_initialValues = {
  regionType: [] as Array<string>,
  depositType: {
    min: 0,
    max: 1000,
  },
  rentType: {
    min: 0,
    max: 100,
  },
  roomType: [] as Array<string>,
  options: [] as Array<string>,
};

const NewRequestSection: React.FC = () => {
  const [user, loading, error] = useAuthState(auth);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { data: newRequests, refetch } = useQuery(
    [room_manage_new_request_key, currentPage],
    async () => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      const value = await instance.get<BaseResponse<RoomNewRequest[]>>(room_new_request_path, {
        params: { page: currentPage, limit: 10 },
      });
      return value?.data;
    },

    {
      retry: false,
    },
  );
  return (
    <Flex
      bg="white"
      borderBottomRadius={'md'}
      borderTopRightRadius={'md'}
      px={4}
      w={'100%'}
      flexWrap={'wrap'}
      userSelect={'none'}
      boxShadow={'2px 2px 4px 0px #DDDDDD'}
    >
      <Flex w={'full'} alignItems={'flex-end'}>
        <VStack w={'full'} py={5} alignItems={'flex-start'}>
          <Text fontSize={'14px'} fontWeight={500}>
            * 요청받은 날짜로부터 7일까지 조회 및 제안하실 수 있으며, 7일이 지나면 자동 삭제됩니다.
          </Text>
          <Text fontSize={'14px'} fontWeight={500}>
            * 신규 요청 확인 후 매물을 제안한 경우, 해당 요청 내역은 ‘제안한 요청’에서 확인할 수 있습니다.
          </Text>
        </VStack>
        <Text
          minW={'max-content'}
          mx={'14px'}
          my={2}
          fontSize={'14px'}
          fontWeight={'bold'}
          color={'zetaBlue'}
        >{`검색 결과 총 ${'10'}건`}</Text>
      </Flex>
      <Divider borderColor="borderGray" mb={5} />
      {/* 요청 리스트 */}
      {/* <VStack alignItems={'unset'} borderBottomRadius={'md'} w={'100%'}>
        {request_item_lists.map((list) => (
          <RequestContainer key={list._id} item={list} addLikeBtn addProcessBtns />
        ))}
      </VStack> */}
      <VStack w="full">
        <VStack alignItems={'unset'} w="full" id="here" h={'572px'}>
          {newRequests ? (
            newRequests.data.map((request) => {
              const { custom_room_request_id } = request;
              return <NewRequestContainer key={custom_room_request_id} item={request} addLikeBtn addProcessBtns />;
            })
          ) : (
            <Center h={'full'}>
              <Spinner color="zetaBlue" />
            </Center>
          )}
        </VStack>
        {/* 화살표 */}
        <HStack flex={1} align="right" spacing={4} pt={6} pb={8} alignItems={'center'} justifyContent={'flex-end'}>
          <ClickIconContainer
            clickableArea={7}
            color="zetaBlue"
            w={6}
            h={6}
            onClick={() => {
              setCurrentPage((prev) => {
                if (prev < 2) {
                  return prev;
                }
                return prev - 1;
              });
            }}
          >
            <LeftArrowIcon />
          </ClickIconContainer>
          <Text fontSize={'16px'} cursor={'default'} fontWeight={'medium'}>
            {currentPage}
          </Text>
          <ClickIconContainer
            clickableArea={7}
            color="zetaBlue"
            w={6}
            h={6}
            onClick={() => {
              setCurrentPage((prev) => prev + 1);
            }}
          >
            <RightArrowIcon />
          </ClickIconContainer>
        </HStack>
      </VStack>
    </Flex>
  );
};

export default NewRequestSection;

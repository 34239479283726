import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Divider,
  Flex,
  HStack,
  Icon,
  Table,
  TableCellProps,
  Tag,
  Tbody,
  Td,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { ko } from 'date-fns/esm/locale';
import { useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { datedetaildotformat_mm } from '../../../lib/common/utils/dateformatter';
import { FillHeart, OutLineHeart } from '../../../statics/common/icons';
import { IndexingPie } from '../../../components/common/Graph/Pie';

const CustomTd: React.FC<TableCellProps> = ({ children, ...props }) => {
  return (
    <Td bgColor="lotionWhite" w={'132px'} minW={'132px'} px={2} fontSize={'12px'} {...props}>
      {children}
    </Td>
  );
};

const prefered_room_types = ['오피스텔', '프리미엄오피스텔', '오픈형 원룸', '분리형 원룸', '반지층 제외'];

const RoomRequestDetail = () => {
  const [isLike, setIsLike] = useState<boolean>(true);
  const handleLikeBtnClick = () => {
    setIsLike((prevLike) => !prevLike);
  };
  return (
    <Box py={5} pl={5}>
      <Box py={4} px={6}>
        <VStack alignItems={'unset'} spacing={0}>
          <Tag variant={'new_request'}>신규 요청</Tag>
          <Flex alignItems={'center'} justifyContent={'space-between'} pt={3}>
            <Box fontSize={'24px'} fontWeight={400}>
              홍길동님의 매물 요청 사항
            </Box>
            <Icon
              as={isLike ? FillHeart : OutLineHeart}
              color={isLike ? '#FB4667' : 'silverGray'}
              transition={'0.3s'}
              cursor="pointer"
              onClick={handleLikeBtnClick}
              w={5}
              h={5}
            />
          </Flex>
          <HStack fontSize={'12px'} spacing={3} pl={'2px'}>
            <HStack spacing={1}>
              <Box fontWeight={'bold'}>요청번호</Box>
              <Box>202202</Box>
            </HStack>
            <HStack spacing={1}>
              <Box fontWeight={'bold'}>제출 일자</Box>
              <Box>{datedetaildotformat_mm(new Date('2021-12-17T03:24:00'))}</Box>
            </HStack>
          </HStack>
        </VStack>
      </Box>
      <Divider borderColor="borderGray" />
      <Box px={1} py={2} w={'400px'} h={'80%'}>
        <Scrollbars autoHide universal>
          <Accordion defaultIndex={[0, 1, 2]} allowMultiple>
            <AccordionItem border="unset">
              <AccordionButton>
                <Box flex="1" textAlign="left" fontWeight={700} fontSize={'14px'}>
                  필수 요청사항
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel w={'100%'}>
                <Table variant="simple_same" size="sm" p={0}>
                  <Tbody>
                    <Tr borderTop={'1px solid rgb(235, 240, 246)'}>
                      <CustomTd>희망 입주일 / 계약 기간</CustomTd>
                      <Td py={1} fontSize={'12px'} minW={'200px'}>
                        요청일로부터 2주내 / 6개월
                      </Td>
                    </Tr>
                    <Tr>
                      <CustomTd>보증금, 월세 예산</CustomTd>
                      <Td py={1} fontSize={'12px'}>
                        ...
                      </Td>
                    </Tr>
                    <Tr>
                      <CustomTd>선호하는 지역</CustomTd>
                      <Td py={1} fontSize={'12px'}>
                        ...
                      </Td>
                    </Tr>
                    <Tr>
                      <CustomTd>선호하는 유형</CustomTd>
                      <Tr w={'full'}>
                        <Td
                          // w={'full'}
                          borderTop={'unset'}
                          borderBottom={'unset'}
                          borderRightWidth={'1px'}
                          borderRightColor={'gray.200'}
                          fontSize={'12px'}
                          w={'80px'}
                          minW={'80px'}
                        >
                          건물 형태
                        </Td>
                        <Td
                          w={'full'}
                          borderTop={'unset'}
                          borderBottom={'unset'}
                          borderRight={'unset'}
                          borderRightWidth={'1px'}
                          borderRightColor={'gray.200'}
                          fontSize={'12px'}
                        >
                          빌라/주택, 오피스텔
                        </Td>
                      </Tr>
                      <Tr w={'full'}>
                        <Td
                          // w={'full'}
                          borderBottom={'unset'}
                          borderRightWidth={'1px'}
                          borderRightColor={'gray.200'}
                          fontSize={'12px'}
                          w={'80px'}
                          minW={'80px'}
                        >
                          층수
                        </Td>
                        <Td
                          w={'full'}
                          borderBottom={'unset'}
                          borderRight={'unset'}
                          borderRightWidth={'1px'}
                          borderRightColor={'gray.200'}
                          fontSize={'12px'}
                        >
                          빌라/주택, 오피스텔
                        </Td>
                      </Tr>
                      <Tr w={'full'}>
                        <Td
                          // w={'full'}
                          borderBottom={'unset'}
                          borderRightWidth={'1px'}
                          borderRightColor={'gray.200'}
                          fontSize={'12px'}
                          w={'80px'}
                          minW={'80px'}
                        >
                          방 개수
                        </Td>
                        <Td
                          w={'full'}
                          borderBottom={'unset'}
                          borderRight={'unset'}
                          borderRightWidth={'1px'}
                          borderRightColor={'gray.200'}
                          fontSize={'12px'}
                        >
                          빌라/주택, 오피스텔
                        </Td>
                      </Tr>
                      <Tr w={'full'}>
                        <Td
                          // w={'full'}
                          borderBottom={'unset'}
                          borderRightWidth={'1px'}
                          borderRightColor={'gray.200'}
                          fontSize={'12px'}
                          w={'80px'}
                          minW={'80px'}
                        >
                          방 구조
                        </Td>
                        <Td
                          w={'full'}
                          borderBottom={'unset'}
                          borderRight={'unset'}
                          borderRightWidth={'1px'}
                          borderRightColor={'gray.200'}
                          fontSize={'12px'}
                        >
                          빌라/주택, 오피스텔
                        </Td>
                      </Tr>
                    </Tr>
                    <Tr>
                      <CustomTd>필수 옵션</CustomTd>
                      <Td py={1} fontSize={'12px'}>
                        ...
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem border="unset">
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left" fontWeight={700} fontSize={'14px'}>
                    홍길동 님의 개별 맞춤 조건
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Table variant="simple_same" size="sm" p={0}>
                  <Tbody>
                    <Tr borderTop={'1px solid rgb(235, 240, 246)'}>
                      <CustomTd>우선 순위</CustomTd>
                      <Td p={0} fontSize={'12px'}>
                        {/* <IndexingPie width={125} height={125} /> */}
                      </Td>
                    </Tr>
                    <Tr>
                      <CustomTd>회사 위치</CustomTd>
                      <Td py={1} fontSize={'12px'}>
                        ...
                      </Td>
                    </Tr>
                    <Tr>
                      <CustomTd>평소 출퇴근 교통수단</CustomTd>
                      <Td py={1} fontSize={'12px'}>
                        ...
                      </Td>
                    </Tr>
                    <Tr>
                      <CustomTd>희망 통근 시간</CustomTd>
                      <Td py={1} fontSize={'12px'}>
                        ...
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem border="unset">
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left" fontWeight={700} fontSize={'14px'}>
                    추가 요청 사항
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Box border={'1px solid rgb(235, 240, 246)'} p={4} fontSize={'14px'}>
                  {'받아온 추가 요청 사항'}
                </Box>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem border="unset">
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left" fontWeight={700} fontSize={'14px'}>
                    제안한 매물
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Box border={'1px solid rgb(235, 240, 246)'} p={4} fontSize={'14px'}>
                  {'제안한 매물 리스트'}
                </Box>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Scrollbars>
      </Box>
    </Box>
  );
};

export default RoomRequestDetail;

import { Box, BoxProps, Collapse } from '@chakra-ui/react';
import React from 'react';

interface ValidationProps extends BoxProps {
  error: string | undefined;
}

const Validation: React.FC<ValidationProps> = ({ error, ...props }) => (
  <Collapse in={!!error}>
    <Box fontSize={'12px'} color="primaryRed" mt={1} ml={1} {...props}>
      *{error}
    </Box>
  </Collapse>
);

export default Validation;

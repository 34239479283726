import { Route, RouteProps, Routes } from 'react-router-dom';
import CompanySignUpPage from '../../../pages/common/SignUp/Company';

const CompanySignUpRoute: React.FC<RouteProps> = () => {
  return (
    <Routes>
      <Route index element={<CompanySignUpPage />} />
    </Routes>
  );
};

export default CompanySignUpRoute;

import { Table, Tbody, Td, Tr } from '@chakra-ui/react';
import format from 'date-fns/format';
import { Service } from '../../../lib/common/utils/axios/types';
import { datedotformat } from '../../../lib/common/utils/dateformatter';
import { request_type_transition } from '../../../pages/UserType/subscriber/manage/employee/UsageLists';
import * as Type from '../../../types/common/CommonType';

interface RequestContentTableProps {
  data: Service | undefined;
}

const RequestContentTable: React.FC<RequestContentTableProps> = ({ data: serviceData }) => {
  console.log('in table => ', serviceData);

  if (!serviceData) {
    return <></>;
  }

  return (
    <Table variant={'simple_lining'}>
      <Tbody>
        <Tr fontSize={'14px'}>
          <Td bgColor={'#F8F8F8'} w={'150px'} fontWeight={'medium'} p={3}>
            신청일
          </Td>
          <Td>{datedotformat(serviceData.employee.service.createdAt)}</Td>
        </Tr>
        <Tr fontSize={'14px'}>
          <Td bgColor={'#F8F8F8'} w={'150px'} fontWeight={'medium'} p={3}>
            신청 항목
          </Td>
          <Td>{request_type_transition[serviceData.employee.service.request_type]}</Td>
        </Tr>
        <Tr fontSize={'14px'}>
          <Td bgColor={'#F8F8F8'} w={'150px'} fontWeight={'medium'} p={3}>
            이름
          </Td>
          <Td>{serviceData.employee.user_name}</Td>
        </Tr>
        <Tr fontSize={'14px'}>
          <Td bgColor={'#F8F8F8'} w={'150px'} fontWeight={'medium'} p={3}>
            이메일
          </Td>
          <Td>{serviceData.employee.email}</Td>
        </Tr>
        <Tr fontSize={'14px'}>
          <Td bgColor={'#F8F8F8'} w={'150px'} fontWeight={'medium'} p={3}>
            희망 거주 기간
          </Td>
          <Td>총 {serviceData.employee.service.how_long_month}개월</Td>
        </Tr>
        <Tr fontSize={'14px'}>
          <Td bgColor={'#F8F8F8'} w={'150px'} fontWeight={'medium'} p={3}>
            희망 입주일
          </Td>
          <Td>
            {serviceData.employee.service.within_week === undefined
              ? '-'
              : `${serviceData.employee.service.within_week}주 이내`}
          </Td>
        </Tr>
        <Tr fontSize={'14px'}>
          <Td bgColor={'#F8F8F8'} w={'150px'} fontWeight={'medium'} p={3}>
            신청 사유
          </Td>
          <Td>{serviceData.employee.service.description}</Td>
        </Tr>
      </Tbody>
    </Table>
  );
};

export default RequestContentTable;

import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Image,
  InputGroup,
  InputRightElement,
  StackDivider,
  Table,
  Tbody,
  Text,
  toast,
  Tr,
  useBoolean,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import ValidationWithTouch from '../../../components/common/FormikValidation/ValidationWithTouch';
import { CustomInput } from '../../../components/common/Input';
import { TdFullWContainer } from '../../../components/common/Table/Tr';
import {
  auth,
  FirebaseReauthenticateWithCredential,
  FirebaseUpdatePassword,
} from '../../../lib/common/utils/firebase/init';
import { LeftArrowIcon } from '../../../statics/common/icons';
import LockedWithKey from '../../../statics/common/images/locked_with_key.png';
import { isEmpty, pickBy, isString, isLength, identity } from 'lodash';
import { isAllFilledObj } from '../../../lib/common/utils/base';
import { AuthError } from 'firebase/auth';
import { loginFail, success } from '../../../statics/common/data/toast';

const AccountPage = () => {
  const [showPwd, setShowPwd] = useBoolean(false);
  const navigate = useNavigate();
  const user = auth.currentUser;
  const toast = useToast();

  const handleShowPassword = () => {
    setShowPwd.toggle();
  };

  const showPwdText = showPwd ? '숨기기' : '보이기';
  const showPwdType = showPwd ? 'text' : 'password';

  return (
    <Center w="full" h="full">
      <Formik
        initialValues={{
          current_password: '',
          new_password: '',
          new_password_check: '',
        }}
        validationSchema={Yup.object().shape({
          current_password: Yup.string()
            .matches(/\w*[A-z]\w*/, '비밀번호는 영문을 포함하여야 합니다.')
            .matches(/\d/, '비밀번호는 숫자를 포함하여야 합니다.')
            .matches(/[!@#$%^&*()\-_"=+{}; :,<.>]/, '비밀번호는 특수문자를 포함하여야 합니다.')
            .min(8, ({ min }) => `비밀번호는 최소 ${min}글자입니다.`)
            .max(16, ({ max }) => `비밀번호는 최대 ${max}글자입니다.`),
          new_password: Yup.string()
            .matches(/\w*[A-z]\w*/, '비밀번호는 영문을 포함하여야 합니다.')
            .matches(/\d/, '비밀번호는 숫자를 포함하여야 합니다.')
            .matches(/[!@#$%^&*()\-_"=+{}; :,<.>]/, '비밀번호는 특수문자를 포함하여야 합니다.')
            .min(8, ({ min }) => `비밀번호는 최소 ${min}글자입니다.`)
            .max(16, ({ max }) => `비밀번호는 최대 ${max}글자입니다.`),
          new_password_check: Yup.string().when('new_password', {
            is: (val: any) => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf([Yup.ref('new_password')], '위의 비밀번호와 일치하지 않습니다.'),
          }),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          await FirebaseReauthenticateWithCredential(values.current_password)
            .then(async (value) => {
              await FirebaseUpdatePassword(values.new_password).then(() => {
                toast({ ...success, title: '비밀번호 변경 성공', description: '대시보드로 이동합니다.' });
                navigate('/company/manage/dashboard');
              });
            })
            .catch((error: AuthError) => {
              if (error.message.includes('auth/wrong-password')) {
                toast({ ...loginFail, title: '인증 실패', description: '비밀번호 인증에 실패하였습니다.' });
              }
              console.log('error => ', error.message.includes('auth/wrong-password'));
            });
        }}
      >
        {({ values, touched, errors, isValid, isSubmitting }) => {
          const isAllFilled = isAllFilledObj(values);
          const isAbleSubmit = isAllFilled && isValid;
          return (
            <Form id="form-a" style={{ width: '100%', height: '100%' }}>
              <Flex
                flexDir={'column'}
                bg="white"
                borderRadius={'lg'}
                boxShadow={'2px 2px 20px 0px #DDDDDD'}
                w={'full'}
                h="full"
                alignItems={'flex-start'}
              >
                <Box
                  w={'full'}
                  borderBottomColor={'borderGray'}
                  boxShadow={'rgba(0, 0, 0, 0.02) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 2px 3px -2px'}
                  h={'120px'}
                  alignItems={'center'}
                >
                  <Flex h={'full'} px={'45px'} alignItems={'center'} justifyContent={'space-between'}>
                    <HStack>
                      <LeftArrowIcon w={6} h={6} mr={2} cursor={'pointer'} onClick={() => navigate(-1)} />
                      <Image src={LockedWithKey} w={6} h={6} />
                      <Text fontWeight={'bold'} fontSize={'24px'} lineHeight={0}>
                        계정 설정
                      </Text>
                    </HStack>
                  </Flex>
                </Box>
                <VStack w="full" h="full" spacing={0} divider={<StackDivider borderColor="gray.200" />}>
                  <HStack w={'full'} justifyContent={'space-between'} px={'45px'} py={6}>
                    {/*  */}
                    <VStack justifyContent={'start'} w={'100%'} spacing={6}>
                      {/* 계정 */}
                      <Flex flexDir={'column'} justifyContent={'start'} alignItems={'flex-start'} w={'100%'}>
                        <Box fontWeight={'bold'} mb={3} fontSize={'18px'} textAlign={'start'} w={'112px'}>
                          계정
                        </Box>
                        <Box w={'full'} borderTopWidth={'1px'} borderTopColor={'blackAlpha.500'} />
                        <Table w={'100%'} variant="simple_lining">
                          <Tbody w={'100%'}>
                            <Tr w={'100%'}>
                              <TdFullWContainer title={'이메일'}>
                                <Text>{user?.email}</Text>
                              </TdFullWContainer>
                            </Tr>
                          </Tbody>
                        </Table>
                      </Flex>
                      {/* 비밀번호 변경 */}
                      <Flex flexDir={'column'} justifyContent={'start'} alignItems={'flex-start'} w={'100%'}>
                        <Box fontWeight={'bold'} mb={3} fontSize={'18px'} textAlign={'start'} w={'112px'}>
                          비밀번호 변경
                        </Box>
                        <Box w={'full'} borderTopWidth={'1px'} borderTopColor={'blackAlpha.500'} />
                        <Table w={'100%'} variant="simple_lining">
                          <Tbody w={'100%'}>
                            <Tr w={'100%'}>
                              <TdFullWContainer title={'이메일'}>
                                <Text>{user?.email}</Text>
                              </TdFullWContainer>
                            </Tr>
                            <Tr w={'100%'}>
                              <TdFullWContainer
                                title={'현재 비밀번호'}
                                ContentTdProps={{
                                  p: 1,
                                  pl: 3,
                                }}
                              >
                                <InputGroup size="sm" w={'80%'}>
                                  <Field
                                    as={CustomInput}
                                    name="current_password"
                                    type={showPwdType}
                                    placeholder={'현재 비밀번호를 입력해 주세요.'}
                                  />
                                  <InputRightElement width="4.5rem">
                                    <Button
                                      variant="basic"
                                      style={{ height: '26px' }}
                                      fontSize="12px"
                                      fontWeight={600}
                                      size="xs"
                                      mr={1}
                                      minW="72px"
                                      onClick={handleShowPassword}
                                    >
                                      {showPwdText}
                                    </Button>
                                  </InputRightElement>
                                </InputGroup>
                                <ValidationWithTouch error={errors.current_password} touch={true} />
                              </TdFullWContainer>
                            </Tr>
                            <Tr w={'100%'}>
                              <TdFullWContainer
                                title={'신규 비밀번호'}
                                ContentTdProps={{
                                  p: 1,
                                  pl: 3,
                                }}
                              >
                                <InputGroup size="sm" w={'80%'}>
                                  <Field
                                    as={CustomInput}
                                    name="new_password"
                                    placeholder={
                                      '신규 비밀번호를 숫자, 문자, 특수문자 포함 8자리 이상으로 입력해 주세요.'
                                    }
                                    type="password"
                                  />
                                </InputGroup>
                                <ValidationWithTouch error={errors.new_password} touch={true} />
                              </TdFullWContainer>
                            </Tr>
                            <Tr w={'100%'}>
                              <TdFullWContainer
                                title={'비밀번호 확인'}
                                ContentTdProps={{
                                  p: 1,
                                  pl: 3,
                                }}
                              >
                                <HStack>
                                  <InputGroup size="sm" w={'80%'}>
                                    <Field
                                      as={CustomInput}
                                      name="new_password_check"
                                      placeholder={'비밀번호를 다시 입력해 주세요.'}
                                      type="password"
                                    />
                                  </InputGroup>
                                  <Button
                                    variant={'basic'}
                                    style={{
                                      height: '32px',
                                    }}
                                    isDisabled={!isAbleSubmit}
                                    isLoading={isSubmitting}
                                    type="submit"
                                  >
                                    변경하기
                                  </Button>
                                </HStack>
                                <ValidationWithTouch error={errors.new_password_check} touch={true} />
                              </TdFullWContainer>
                            </Tr>
                          </Tbody>
                        </Table>
                      </Flex>
                    </VStack>
                    {/*  */}
                  </HStack>
                </VStack>
              </Flex>
            </Form>
          );
        }}
      </Formik>
    </Center>
  );
};

export default AccountPage;

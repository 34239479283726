import { Box, Button, Center, Flex, HStack, Switch, Text, useBoolean, useToast, VStack } from '@chakra-ui/react';
import React, { useState } from 'react';
import TextLink from '../../../../../components/common/Link/TextLink';
import { default_items_limit_number } from '../../../../../lib/common/utils/values';
import { success } from '../../../../../statics/common/data/toast';
import {
  roomDupLocalKey,
  number_of_items_per_page_bohome_admin,
} from '../../../../../lib/common/utils/localstorage/key';
import SliderThumbWithTooltip from '../../../../../components/common/Slider/LimitSlider';
import * as T from '../../../../../types/common/CommonType';
import { useGetNaverFormUrl } from '../../../../../lib/common/hooks/query';
import Loading from '../../../../../components/common/Loading';
import { useUpdateStaticData } from '../../../../../lib/common/hooks/mutation';
import { Field, Form, Formik } from 'formik';
import { WBox } from '../../../../../components/common/Box';
import { CustomInput } from '../../../../../components/common/Input';

const SettingPage = () => {
  const toast = useToast();
  const toast_id_pageItems = 'toast_id_pageItems';
  const [status, setStatus] = useState<'unset' | 'initialized' | 'updated'>('unset');
  const [flag, setFlag] = useBoolean();
  const hasRoomDupLocalKey = localStorage.getItem(roomDupLocalKey) === 'true';
  const { data: naverFormUrlData, isLoading } = useGetNaverFormUrl();
  const { mutateAsync: UpdateNaverFormUrl } = useUpdateStaticData();
  if (isLoading) return <Loading />;
  return (
    <Box>
      <Box mx={6}>
        <Box textStyle={'bold30'} mb={6}>
          설정
        </Box>
        <Flex flexDir={'column'}>
          <Box mb={1}>기본 목록 개수 설정하기</Box>
          <SliderThumbWithTooltip
            ranges={[
              {
                title: '10',
                value: 10,
              },
              {
                title: '15',
                value: 15,
              },
              {
                title: '20',
                value: 20,
              },
              {
                title: '25',
                value: 25,
              },
            ]}
            defaultValue={default_items_limit_number}
            min={10}
            max={25}
            onChange={(v) => {
              setFlag.toggle();
              localStorage.setItem(number_of_items_per_page_bohome_admin, v as any);
              if (status === 'unset') {
                setStatus('initialized');
              } else if (status === 'initialized') {
                setStatus('updated');
              }
              if (!toast.isActive(toast_id_pageItems)) {
                toast({
                  ...success,
                  id: toast_id_pageItems,
                  title: `기본 목록 개수 ${v}개로 변경`,
                  description: '기다려주세요! 3초 뒤 대시보드로 이동합니다',
                });
              } else {
                toast.update(toast_id_pageItems, {
                  ...success,
                  title: `기본 목록 개수 ${v}개로 변경`,
                  description: '기다려주세요! 3초 뒤 대시보드로 이동합니다',
                });
              }
            }}
          />
        </Flex>
      </Box>
      <Box mx={6} mt={'100px'}>
        <Box textStyle={'bold30'}>기능</Box>
        <VStack maxW={'min'} minW={'max'} align={'start'}>
          <TextLink to={'functions/removefurnitures'} fontSize={'16px'}>
            생활물품 지우기
          </TextLink>
          <HStack>
            <Text fontSize={'16px'}>매물 복사 기능활성화</Text>
            <Switch
              onChange={(e) => {
                const isChecked = e.target.checked;
                if (!isChecked) {
                  localStorage.removeItem('activate-room-duplicate-function');
                } else {
                  localStorage.setItem('activate-room-duplicate-function', 'true');
                }
              }}
              defaultChecked={hasRoomDupLocalKey}
            />
          </HStack>
          <Flex>
            <Formik
              initialValues={{
                _type: 'naver_form',
                key: 'site_url',
                value: naverFormUrlData,
              }}
              onSubmit={async (values, { setSubmitting }) => {
                setSubmitting(true);
                await UpdateNaverFormUrl({
                  _type: values?._type,
                  key: values?.key,
                  value: values?.value,
                });
              }}
            >
              {({ values, errors, isValid, handleChange, setFieldValue, isSubmitting }) => {
                return (
                  <Form>
                    <Center flexDir={'column'} m={10} alignItems={'flex-start'}>
                      <VStack w={'full'}>
                        <WBox w={'full'}>
                          <Box w="full" px={'30px'} py={'30px'}>
                            <VStack>
                              <HStack w="full">
                                <Text minW="130px" fontSize={'14px'}>
                                  NaverFormURL변경
                                </Text>
                                <Field name={'value'} as={CustomInput} fontSize={'14px'} />
                              </HStack>
                            </VStack>
                          </Box>
                        </WBox>
                      </VStack>

                      <Center w="full">
                        <Button
                          mt={'30px'}
                          variant={'basic'}
                          w={'140px'}
                          fontSize={'14px'}
                          fontWeight={'bold'}
                          type="submit"
                          isLoading={isSubmitting}
                        >
                          수정하기
                        </Button>
                      </Center>
                    </Center>
                  </Form>
                );
              }}
            </Formik>
          </Flex>
        </VStack>
      </Box>
    </Box>
  );
};

export default SettingPage;

import * as Util from '../Util';
//todo 재확인
export const setWithExpiry = (key: string, value: any, ttl: number) => {
  const now = new Date();
  const item = {
    value: value,
    expiry: now.getTime() + ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
};

export const getWithExpiry = (key: string) => {
  let result;
  try {
    removeLocalStorageItemIfExpried(key);
    result = getLocalStorageItem(key)?.value;
  } catch (err) {
    console.error(err);
  } finally {
    return result;
  }
};

// export const getWithExpiry = (key: string) => {
//   const itemStr = localStorage.getItem(key);
//   if (!itemStr) {
//     return null;
//   }
//   let result;
//   const item = JSON.parse(itemStr);
//   const now = new Date();
//   // if (now.getTime() > item.expiry)
//   return item;
// };
export const removeLocalStorageItemIfExpried = (key: string) => {
  try {
    const now = new Date();
    const item = getLocalStorageItem(key);
    now.getTime() > item?.expiry && localStorage.removeItem(key);
  } catch (err) {
    console.error(err);
  }
};

export const getLocalStorageItem = (key: string) => {
  let result;
  try {
    result = localStorage.getItem(key);
    result = Util.parseJsonString(result);
  } catch (err) {
    console.error(err);
  } finally {
    return result;
  }
};

export const setLocalStorageStringItem = (key: string, value: string) => {
  try {
    localStorage.setItem(key, value);
  } catch (err) {
    console.error(err);
  }
};

export const getLocalStorageStringItem = (key: string): string | null => {
  let result = null;
  try {
    result = localStorage.getItem(key);
  } catch (err) {
    console.error(err);
  } finally {
    return result;
  }
};

export const removeLocalStorageItems = (keys: Array<string>) => {
  try {
    keys?.map((elem) => removeLocalStorageItem(elem));
  } catch (err) {
    console.error(err);
  }
};

export const removeLocalStorageItem = (key: string) => {
  try {
    localStorage.removeItem(key);
  } catch (err) {
    console.error(err);
  }
};

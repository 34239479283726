import { Button, HStack, Box, Text } from '@chakra-ui/react';
import { RightArrowIcon } from '../../../statics/common/icons';
import { Link as RouterLink, useMatch } from 'react-router-dom';

interface TabProps {
  to: string;
  logo: string;
  text: string;
}

const Tab: React.FC<TabProps> = ({ to, logo, text }) => {
  const isCurrent = useMatch(to);

  return (
    <Button
      variant={'tab'}
      as={RouterLink}
      // minW={'300px'}
      // w="300px"
      to={to}
      bgColor={isCurrent ? 'mainBgColor' : 'initial'}
    >
      <HStack w="full" spacing={4}>
        <Text>{logo}</Text>
        <Box fontWeight={'medium'}>{text}</Box>
      </HStack>
      <RightArrowIcon w={6} h={6} />
    </Button>
  );
};

export default Tab;

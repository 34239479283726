import { InputProps, InputGroup, Input, InputRightAddon, Flex, Box, Collapse } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { useState } from 'react';
import { initialValue } from '../../../pages/common/SignUp/Agency';

interface CustomInputProps extends InputProps {
  addon?: string;
  addonW?: string;
}

export const CustomInput: React.FC<CustomInputProps> = ({ addon, addonW, isInvalid, ...props }) => {
  //   return <Input bgColor="lotionWhite" borderColor="silverGray" {...props} />;
  return (
    <InputGroup size="sm">
      <Input
        bgColor="lotionWhite"
        boxShadow={'sm'}
        borderColor="silverGray"
        borderRadius={'md'}
        fontSize={'12px'}
        _hover={{}}
        _placeholder={{
          color: 'silverGray',
          fontSize: '12px',
        }}
        errorBorderColor="red.500"
        _focus={
          isInvalid
            ? {}
            : {
                zIndex: 1,
                borderColor: '#63b3ed',
                boxShadow: '0 0 0 1px #63b3ed',
              }
        }
        isInvalid={isInvalid}
        {...props}
      />
      {addon && (
        <InputRightAddon
          children={addon}
          boxShadow={'sm'}
          borderRightRadius={'md'}
          bgColor={'gray.100'}
          // color="white"
          w={addonW ? addonW : 'max-content'}
        />
      )}
    </InputGroup>
  );
};

export const CustomFileInput: React.FC<CustomInputProps> = ({ addon, addonW, ...props }) => {
  //   return <Input bgColor="lotionWhite" borderColor="silverGray" {...props} />;
  return (
    <InputGroup size="sm">
      <Input type="file" variant={'fileStyle'} {...props} />
      {addon && (
        <InputRightAddon
          children={addon}
          boxShadow={'sm'}
          borderRightRadius={'md'}
          w={addonW ? addonW : 'max-content'}
        />
      )}
    </InputGroup>
  );
};

// interface FileInputProps extends InputProps {
//   handleFormikChange: any;
// }

// export const FileInput: React.FC<FileInputProps> = ({ children, id, handleFormikChange, ...props }) => {
//   const [selectedFile, setSelectedFile] = useState('');
//   const [selectedFileName, setSelectedFileName] = useState('');
//   const handleselectedFile = (e: any) => {
//     setSelectedFile(e.target.files[0]);
//     setSelectedFileName(e.target.files[0].name);
//     handleFormikChange(e);
//     // check!
//     setFieldValue('business_registration', e.target.files[0]);
//   };
//   // console.log('selectedFile => ', selectedFile);

//   const { values, setFieldValue, handleChange } = useFormikContext<typeof initialValue>();

//   console.log('input => ', values);

//   return (
//     <>
//       <Input type="file" id={id} display={'none'} onChange={handleselectedFile} {...props} />
//       <label htmlFor={id} style={{ width: '100%' }}>
//         <Flex
//           bgColor={'lotionWhite'}
//           borderColor={'silverGray'}
//           borderWidth={'1px'}
//           boxShadow={'sm'}
//           borderRadius={'md'}
//           fontSize={'12px'}
//           h={'32px'}
//           _hover={{
//             bgColor: 'zetaBlue',
//             color: 'white',
//             borderColor: 'silverGray',
//           }}
//           transition={'0.18s ease'}
//           alignItems={'center'}
//           justifyContent={'space-between'}
//           px={3}
//           cursor="pointer"
//         >
//           <Box>{children}</Box>
//           {!!selectedFileName && <Box>첨부 완료</Box>}
//         </Flex>
//       </label>
//       <Collapse style={{ width: '100%' }} in={!!selectedFileName}>
//         {selectedFileName}
//       </Collapse>
//     </>
//   );
// };

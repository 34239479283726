import { Box, HStack, Table, Tag, Tbody, Text, Thead, Tr, VStack } from '@chakra-ui/react';
import { isEmptyArray, isUndefined } from '@chakra-ui/utils';
import format from 'date-fns/format';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import RequestChatButton from '../../../../../../../../components/admin/Button/RequestChatButton';
import { WTd } from '../../../../../../../../components/common/Table/Td';
import { WTh } from '../../../../../../../../components/common/Table/Th';
import { useGetCompanyManagerAllRequests } from '../../../../../../../../lib/admin/hooks/query';
import { currentParamsObject, requestStatusTrans } from '../../../../../../../../lib/common/utils/base';
import { datedotformat } from '../../../../../../../../lib/common/utils/dateformatter';
import RequestRoomDetailTables from '../../../../../../../../layouts/common/CustomRoomRequestDetail';
import RequestRoomsModal from '../../../../../../../../components/common/Modals/CustomRoomRequestModal';
import Loading from '../../../../../../../../components/common/Loading';
import { useGetUser } from '../../../../../../../../lib/common/hooks/query';

const RequestAndStatus = () => {
  const { id, requestid } = currentParamsObject(false);

  const { data: requestsData, isLoading } = useGetCompanyManagerAllRequests(id || '');
  const { data: userData, isLoading: isLoadingUser } = useGetUser(id);
  const navigate = useNavigate();

  if (isLoading) return <Loading />;
  if (isLoadingUser) return <Loading />;
  let user = userData?.employee;

  return (
    <HStack align={'start'} id="check_here" w="full">
      <VStack justifyContent={'start'} w={'100%'} spacing={6}>
        <VStack spacing={3} justifyContent={'start'} align={'flex-start'} w={'100%'}>
          <Box w={'100%'} fontWeight={'bold'} fontSize={'18px'} textAlign={'start'} flexGrow={1}>
            입주 요청 내역
          </Box>
          <Table variant="head_silverbg_toplining">
            <Thead>
              <Tr>
                <WTh borderR>
                  <Text minW={'max'}>입주 요청일</Text>
                </WTh>
                <WTh borderR>
                  <Text minW={'max'}>요청 번호</Text>
                </WTh>
                <WTh borderR>
                  <Text minW={'max'}>상태</Text>
                </WTh>
                <WTh>
                  <Text minW={'max'}>채팅 바로가기</Text>
                </WTh>
                <WTh>
                  <Text minW={'max'}>제안하기</Text>
                </WTh>
              </Tr>
            </Thead>
            <Tbody>
              {requestsData?.map((request, index) => {
                const isCurrentOpenedForm = request._id === requestid;
                return (
                  <Tr
                    key={index}
                    // bgColor={isCurrentOpenedForm ? 'zetaBlue' : 'white'}
                    // color={isCurrentOpenedForm ? 'white' : '#414141'}
                  >
                    <WTd borderR>{datedotformat(request.createdAt)}</WTd>
                    <WTd
                      borderR
                      onClick={() =>
                        navigate(`/admin/manage/customers/user/detail/request?id=${id}&requestid=${request._id}`)
                      }
                      textStyle={'pointer'}
                      transition={'unset'}
                      fontWeight={isCurrentOpenedForm ? 'bold' : 'normal'}
                      // _hover={{
                      //   color: isCurrentOpenedForm ? 'white' : 'zetaBlue',
                      // }}
                    >
                      {request.code}
                    </WTd>
                    <WTd borderR>
                      <Tag
                        variant={
                          request.status === 'contracted'
                            ? 'orange'
                            : request.status === 'moving_in'
                            ? 'orange'
                            : 'green'
                        }
                      >
                        {requestStatusTrans(request.status)}
                      </Tag>
                    </WTd>
                    <WTd borderR>
                      <RequestChatButton chat_room_id={`${request.chat_room_id}?requestid=${request._id}`} />
                    </WTd>
                    <WTd borderR>
                      <RequestRoomsModal
                        room_request_id={request?._id}
                        room_number_codes={request?.room_number_codes}
                        request_createdAt={request?.createdAt}
                        matching_type={request?.matching_type}
                        user_name={user?.user_name}
                        company_name={user?.company_name}
                        request_code={request?.code}
                        tenant_id={user?._id}
                        tenant_status={user?.tenant_status}
                      >
                        <Text
                          maxW={'180px'}
                          w={'180px'}
                          isTruncated
                          textDecor={'underline'}
                          textUnderlineOffset={2}
                          fontSize={'14px'}
                          cursor={'pointer'}
                        >
                          {user?.user_name} ({user?.company_name})
                        </Text>
                      </RequestRoomsModal>
                    </WTd>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
          <Text>{isEmptyArray(requestsData) && '입주 요청 내역이 존재하지 않습니다.'}</Text>
        </VStack>
      </VStack>
      <Box borderWidth={1} borderRadius={'xl'}>
        <RequestRoomDetailTables />
      </Box>
    </HStack>
  );
};

export default RequestAndStatus;

import { Text } from '@chakra-ui/react';
import { useUpdateSubscriptionPaymentStatus, useUpdateLeasePaymentsStatus } from '../../../../lib/admin/hooks/mutation';
import { CertificateIssueTypeEnum, ContractPaymentStatus } from '../../../../lib/common/utils/axios/types';
import { certificate_issue_type_select, contract_status } from '../../../../statics/common/data/selectbox';
import SelectBox from '../../../agent/SelectBox/base';

interface ChangeCertificateIssueStatusProps {
  lease_payment_id?: string;
  subscription_payment_id?: string;
  certificate_issue_status?: CertificateIssueTypeEnum;
}

const ChangeCertificateIssueStatus: React.FC<ChangeCertificateIssueStatusProps> = ({
  lease_payment_id,
  subscription_payment_id,
  certificate_issue_status,
}) => {
  const { mutate: updateLeasePaymentsStatus } = useUpdateLeasePaymentsStatus();
  const { mutate: updateSubscriptionPaymentStatus } = useUpdateSubscriptionPaymentStatus(subscription_payment_id || '');

  // switch (certificate_issue_status) {
  //   case undefined:
  //     return <Text fontSize={'14px'}></Text>;
  //   case 'tax_invoice':
  //     return <Text fontSize={'14px'}>세금계산서</Text>;
  //   case 'cash_receipt':
  //     return <Text fontSize={'14px'}>현금 영수증</Text>;
  //   case 'none':
  return (
    <SelectBox
      value={certificate_issue_status}
      onChange={(e) => {
        const select = e.target.value as CertificateIssueTypeEnum;
        if (lease_payment_id) {
          switch (select) {
            case 'none':
              updateLeasePaymentsStatus({
                lease_payment_id,
                lease_contract: {
                  certificate_issue_type: select,
                },
              });
              break;
            case 'cash_receipt':
              updateLeasePaymentsStatus({
                lease_payment_id,
                lease_contract: {
                  certificate_issue_type: select,
                },
              });
              break;
            case 'tax_invoice':
              updateLeasePaymentsStatus({
                lease_payment_id,
                lease_contract: {
                  certificate_issue_type: select,
                },
              });
              break;
            default:
              break;
          }
        } else if (subscription_payment_id) {
          switch (select) {
            case 'none':
              updateSubscriptionPaymentStatus({
                certificate_issue_type: select,
              });
              break;
            case 'cash_receipt':
              updateSubscriptionPaymentStatus({
                certificate_issue_type: select,
              });
              break;
            case 'tax_invoice':
              updateSubscriptionPaymentStatus({
                certificate_issue_type: select,
              });
              break;
            default:
              break;
          }
        }
      }}
    >
      {certificate_issue_type_select.map((option, index) => (
        <option key={index} value={option.value}>
          {option.label}
        </option>
      ))}
    </SelectBox>
  );
  // }
};

export default ChangeCertificateIssueStatus;

import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  HStack,
  Link,
  Table,
  Tbody,
  Text,
  Thead,
  Tr,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { isUndefined, isEqual } from 'lodash';
import { format } from 'date-fns';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';
import CopyTextButton from '../../../components/common/Button/CopyTextButton';
import FormikEditableValidationComponent, {
  FormikEditableInput,
} from '../../../components/common/FormikValidation/FormikEditableValidationInput';
import AddressFinder from '../../../components/common/Modals/AddressFinder';
import { WTd } from '../../../components/common/Table/Td';
import { WTh } from '../../../components/common/Table/Th';
import { TdFullWContainer } from '../../../components/common/Table/Tr';
import { useUpdateUserInfo } from '../../../lib/admin/hooks/mutation';
import { useGetCompanyAllManagers } from '../../../lib/admin/hooks/query';
import { companyUserTypeTrans } from '../../../lib/common/utils/utils';
import { currentParamsObject, num2han } from '../../../lib/common/utils/base';
import { CompanyTypeTrans } from '../../../lib/common/utils/utils';
import { datedotformat, threetwofiveDashfy } from '../../../lib/common/utils/dateformatter';
import { phoneNumberdashfy } from '../../../lib/common/utils/digitformatter';
import ConfirmApproveCompanyModal from '../../../components/common/Modals/ConfirmApproveCompanyModal';
import InitFormikEditableButton from '../../../components/common/Button/InitFormikEditableButton';

const Info = () => {
  // const [searchParams, setSearchParams] = useSearchParams();
  const { id } = currentParamsObject(false);

  const [isEditable, setIsEditable] = useState<boolean>(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { data: cpData } = useGetCompanyAllManagers(id || '');
  const { mutateAsync: updateUserInfo } = useUpdateUserInfo();

  console.log(cpData, 'cpcpcpcpcp');

  if (isUndefined(cpData)) {
    return null;
  }
  const {
    master,
    name,
    company_code,
    business_type,
    business_registration_number,
    business_status,
    business_item,
    capital,
    employee_number,
    establishment_date,
    address,
    detail_address,
    digital_tax_invoice_email,
    business_certificate,
    logo_url,
    managers,
  } = cpData;
  const r_user_name = master?.user_name;
  const r_department = master?.department;
  const r_position = master?.position;
  const r_email = master?.email;
  const r_phone_number_first = master?.phone_number_first;
  const user_id = master?._id;

  return (
    <Formik
      initialValues={{
        r_user_name,
        r_department,
        r_position,
        r_email,
        r_phone_number_first,
        name,
        company_code,
        business_type,
        business_registration_number,
        business_status,
        business_item,
        capital,
        employee_number,
        establishment_date,
        address,
        detail_address,
        digital_tax_invoice_email,
        business_certificate,
        logo_url,
        managers,
      }}
      validationSchema={Yup.object().shape({
        r_user_name: Yup.string().required('대표 담당자명을 입력해 주세요.'),
        r_department: Yup.string().required('부서 입력해 주세요.'),
        r_phone_number_first: Yup.string()
          .matches(/^((?!-).)*$/, '- 없이 입력해 주세요.')
          .min(7, ({ min }) => `연락처는 최소 ${min}글자입니다.`)
          .max(16, ({ max }) => `연락처는 최대 ${max}글자입니다.`)
          .required('연락처를 입력해 주세요.'),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        await updateUserInfo({
          user_id,
          user_name: values.r_user_name,
          department: values.r_department,
          position: values.r_position,
          phone_number_first: values.r_phone_number_first,
        })
          .then(() => {
            setIsEditable(false);
          })
          .finally(() => {
            setSubmitting(false);
          });
      }}
    >
      {(props) => {
        const isNotAnyChange = isEqual(props.initialValues, props.values);

        const { values } = props;

        const eviprops = {
          isEditable,
          props,
        };

        return (
          <Form id="form-a" style={{ width: '100%', height: '100%' }}>
            <Flex w={'100%'}>
              <VStack justifyContent={'start'} w={'100%'} spacing={6}>
                {/* 회원 정보 */}
                <VStack spacing={3} justifyContent={'start'} align={'flex-start'} w={'100%'}>
                  <HStack w={'full'} spacing={0} justify={'space-between'}>
                    <Box fontWeight={'bold'} fontSize={'18px'} textAlign={'start'} w={'112px'}>
                      회원 정보
                    </Box>
                    <HStack>
                      {cpData.master?.is_approved === undefined ? (
                        //todo testtest
                        <ConfirmApproveCompanyModal company_manager_data={cpData as any}>
                          <Button variant={'basic'}>승인 요청</Button>
                        </ConfirmApproveCompanyModal>
                      ) : cpData.master?.is_approved ? (
                        <Box></Box>
                      ) : (
                        <Box layerStyle={'rejected'}>반려됨</Box>
                      )}
                      <CopyTextButton
                        text={values.r_email}
                        wordsBeforeCopy="메일 주소 복사"
                        wordsAfterCopy="주소 복사 완료"
                      />
                      <InitFormikEditableButton
                        props={props}
                        isDisabledCompleteEdit={isNotAnyChange}
                        isEditable={isEditable}
                        setIsEditable={setIsEditable}
                      />
                    </HStack>
                  </HStack>
                  <Table w={'100%'} variant="simple">
                    <Tbody w={'100%'}>
                      <Tr w={'100%'} borderTopWidth={'1px'} borderTopColor={'gainGray'}>
                        <TdFullWContainer title={'대표 담당자명'}>
                          <FormikEditableValidationComponent {...eviprops} name={'r_user_name'} />
                        </TdFullWContainer>
                        <TdFullWContainer title={'부서 / 직책'}>
                          <FormikEditableValidationComponent {...eviprops} name={['r_department', 'r_position']} />
                        </TdFullWContainer>
                      </Tr>
                      <Tr>
                        <TdFullWContainer title={'이메일'}>
                          <Text>{r_email}</Text>
                        </TdFullWContainer>
                        <TdFullWContainer title={'연락처'}>
                          {isEditable ? (
                            <FormikEditableInput props={props} name={'r_phone_number_first'} />
                          ) : (
                            <Text>{phoneNumberdashfy(values.r_phone_number_first)}</Text>
                          )}
                        </TdFullWContainer>
                      </Tr>
                    </Tbody>
                  </Table>
                </VStack>
                {/* 고객사 정보 */}
                <VStack spacing={3} justifyContent={'start'} align={'flex-start'} w={'100%'}>
                  <Box w={'100%'} fontWeight={'bold'} fontSize={'18px'} textAlign={'start'} flexGrow={1}>
                    고객사 정보
                  </Box>
                  <Table w={'100%'} variant="simple">
                    <Tbody w={'100%'}>
                      <Tr w={'100%'} borderTopWidth={'1px'} borderTopColor={'gainGray'}>
                        <TdFullWContainer title={'기업명'}>
                          <HStack>
                            <Box>{values.name}</Box>
                          </HStack>
                        </TdFullWContainer>
                        <TdFullWContainer title={'고유 코드'}>
                          <HStack>
                            <Box>{values.company_code}</Box>
                          </HStack>
                        </TdFullWContainer>
                      </Tr>
                      <Tr>
                        <TdFullWContainer title={'기업 형태'}>
                          <Box>{CompanyTypeTrans(values.business_type as any)}</Box>
                        </TdFullWContainer>
                        <TdFullWContainer title={'사업자번호'}>
                          <Box>{threetwofiveDashfy(values.business_registration_number as any)}</Box>
                        </TdFullWContainer>
                      </Tr>
                      <Tr>
                        <TdFullWContainer title={'업종/종목'}>
                          <Box>
                            {business_status} / {business_item}
                          </Box>
                        </TdFullWContainer>
                        <TdFullWContainer title={'자본금'}>
                          <Box>{num2han(capital)}</Box>
                        </TdFullWContainer>
                      </Tr>
                      <Tr>
                        <TdFullWContainer title={'근로자수'}>
                          <Box>{employee_number}명</Box>
                        </TdFullWContainer>
                        <TdFullWContainer title={'회사설립연월일'}>
                          <Box>{datedotformat(establishment_date)}</Box>
                        </TdFullWContainer>
                      </Tr>
                      <Tr>
                        <TdFullWContainer title={'사업자 소재지'}>
                          <Box>
                            {address} {!!detail_address && detail_address}
                          </Box>
                        </TdFullWContainer>
                        <TdFullWContainer title={'전자 세금 계산서 전용 전자우편 주소'}>
                          <Box>{digital_tax_invoice_email}</Box>
                        </TdFullWContainer>
                      </Tr>
                      <Tr>
                        <TdFullWContainer title={'사업자 등록증'}>
                          <Link href={business_certificate} isExternal>
                            파일 열기
                          </Link>
                        </TdFullWContainer>
                        <TdFullWContainer title={'로고 파일'}>
                          <Link href={logo_url} isExternal>
                            파일 열기
                          </Link>
                        </TdFullWContainer>
                      </Tr>
                    </Tbody>
                  </Table>
                </VStack>
                {/* 담당자 정보 */}
                <VStack spacing={3} justifyContent={'start'} w={'100%'}>
                  <Box w={'100%'} fontWeight={'bold'} fontSize={'18px'} textAlign={'start'} flexGrow={1}>
                    담당자 정보
                  </Box>
                  <Table variant={'head_silverbg_toplining'}>
                    <Thead bgColor={'#F8F8F8'}>
                      <Tr>
                        <WTh borderR>권한</WTh>
                        <WTh borderR>이름</WTh>
                        <WTh borderR>부서/직책</WTh>
                        <WTh borderR>이메일</WTh>
                        <WTh>휴대폰 번호</WTh>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {values.managers?.map((manager, index) => (
                        <Tr key={index}>
                          <WTd borderR>{companyUserTypeTrans(manager.user_type)}</WTd>
                          <WTd borderR>{manager.user_name}</WTd>
                          <WTd borderR>
                            {manager.department} / {manager.position}
                          </WTd>
                          <WTd borderR>{manager.email}</WTd>
                          <WTd borderR>{manager.phone_number_first}</WTd>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </VStack>
              </VStack>
            </Flex>
            <AddressFinder isOpen={isOpen} onClose={onClose} formik={props} />
          </Form>
        );
      }}
    </Formik>
  );
};

export default Info;

import { Box, HStack, Table, Tbody, Text, Tooltip, Tr, VStack, Wrap } from '@chakra-ui/react';
import { isUndefined } from '@chakra-ui/utils';
import { useSearchParams } from 'react-router-dom';
import CommonChatButton from '../../../../../../../../components/admin/Button/CommonChatButton';
import { CustomEditable } from '../../../../../../../../components/common/Editable';
import TextLink from '../../../../../../../../components/common/Link/TextLink';
import { TdFullWContainer } from '../../../../../../../../components/common/Table/Tr';
import { useGetCompanyManager } from '../../../../../../../../lib/admin/hooks/query';
import { bankNameTrans } from '../../../../../../../../lib/common/utils/base';
import { datedotformat } from '../../../../../../../../lib/common/utils/dateformatter';
import { staticThreeCommafy } from '../../../../../../../../lib/common/utils/digitformatter';
import MoveInAndOutInfoCheckModal from '../../../../../../../../components/common/Modals/MoveInAndOutInfoCheckModal';

const Lmove = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get('id');

  const { data } = useGetCompanyManager(id || '');

  if (isUndefined(data)) {
    return null;
  }

  const _data = data;

  const {
    employee: {
      _id: employee_id,
      user_name,
      email,
      phone_number_first,
      createdAt,
      company_name,
      services: serviceArrData,
      lease_contract,
      chat_rooms,
    },
  } = _data;

  // console.log('_data =>', _data);

  console.log('data =>', data);

  return (
    <VStack justifyContent={'start'} w={'100%'} spacing={6}>
      {/* 계약정보 */}
      <VStack spacing={3} justifyContent={'start'} align={'flex-start'} w={'100%'}>
        <HStack w={'full'} spacing={0} justify={'space-between'}>
          <Box fontWeight={'bold'} fontSize={'18px'} textAlign={'start'} w={'112px'}>
            계약정보
          </Box>
          <CommonChatButton chatRooms={chat_rooms} user_ids={[employee_id]} />
        </HStack>
        <Table w={'100%'} variant="simple">
          <Tbody w={'100%'}>
            <Tr w={'100%'} borderTopWidth={'1px'} borderTopColor={'gainGray'}>
              <TdFullWContainer title={'최초 계약 등록일'}>
                <CustomEditable value={datedotformat(lease_contract?.contract_created_date)} />
              </TdFullWContainer>
              <TdFullWContainer title={'최종 수정일'}>
                <CustomEditable value={datedotformat(lease_contract?.updatedAt)} />
              </TdFullWContainer>
            </Tr>
            <Tr>
              <TdFullWContainer title={'매물번호'}>
                <CustomEditable value={lease_contract?.real_room?.code} />
              </TdFullWContainer>
              <TdFullWContainer title={'임대인'}>
                <Tooltip label={'연락처: ' + lease_contract?.host?.phone_number_first} placement="left" hasArrow>
                  <Box>
                    <Text textStyle={'u'}>{lease_contract?.host?.user_name}</Text>
                  </Box>
                </Tooltip>
              </TdFullWContainer>
            </Tr>
            <Tr>
              <TdFullWContainer
                title={'주소'}
                ContentTdProps={{
                  colSpan: 3,
                }}
              >
                <Box>{`${lease_contract?.real_room?.building?.address} ${
                  lease_contract?.real_room?.dong ? lease_contract?.real_room?.dong + '동' : ' '
                } ${lease_contract?.real_room?.ho ? lease_contract?.real_room?.ho + '호' : ' '}`}</Box>
              </TdFullWContainer>
            </Tr>
            <Tr w={'100%'} borderTopWidth={'1px'} borderTopColor={'gainGray'}>
              <TdFullWContainer title={'계약 기간'}>
                <CustomEditable
                  value={`${datedotformat(lease_contract?.contract_start_date)} - ${datedotformat(
                    lease_contract?.contract_end_date,
                  )}`}
                />
              </TdFullWContainer>
              <TdFullWContainer title={'계약서'}>
                <Wrap>
                  {lease_contract?.lease_contract_pdfs?.map((url, index) => (
                    <TextLink target={'_blank'} href={url} key={index}>
                      {`계약서${index + 1}`}
                    </TextLink>
                  ))}
                </Wrap>
              </TdFullWContainer>
            </Tr>
            <Tr>
              <TdFullWContainer title={'계약금'}>
                <Box>{staticThreeCommafy(lease_contract?.down_payment)}원</Box>
              </TdFullWContainer>
              <TdFullWContainer title={'잔금'}>
                <Box>{staticThreeCommafy(lease_contract?.balance_payment)}원</Box>
              </TdFullWContainer>
            </Tr>
          </Tbody>
        </Table>
      </VStack>
      {/* 임대료 정보 */}
      <VStack spacing={3} justifyContent={'start'} align={'flex-start'} w={'100%'}>
        <HStack w={'full'} spacing={0} justify={'space-between'}>
          <Box fontWeight={'bold'} fontSize={'18px'} textAlign={'start'} w={'112px'}>
            임대료 정보
          </Box>
        </HStack>
        <Table w={'100%'} variant="simple">
          <Tbody w={'100%'}>
            <Tr w={'100%'} borderTopWidth={'1px'} borderTopColor={'gainGray'}>
              <TdFullWContainer title={'보증금'}>
                <Box>{staticThreeCommafy(lease_contract?.deposit)}원</Box>
              </TdFullWContainer>
              <TdFullWContainer title={'납기일'}>
                {/* <CustomEditable value={company_name} /> */}
                <Box>{staticThreeCommafy(lease_contract?.payment_day)}일</Box>
              </TdFullWContainer>
            </Tr>
            <Tr>
              <TdFullWContainer title={'임대료'}>
                <Box>{staticThreeCommafy(lease_contract?.rent)}원</Box>
              </TdFullWContainer>
              <TdFullWContainer title={'관리비'}>
                <Box>{staticThreeCommafy(lease_contract?.management_fee)}원</Box>
              </TdFullWContainer>
            </Tr>
            <Tr>
              <TdFullWContainer
                title={'가상계좌'}
                ContentTdProps={{
                  colSpan: 3,
                }}
              >
                <Box>
                  {lease_contract?.account?.bank_code &&
                    `${bankNameTrans(lease_contract?.account?.bank_code)} ${lease_contract?.account?.account_number} (${
                      lease_contract?.account?.account_owner_name
                    })`}
                </Box>
              </TdFullWContainer>
            </Tr>
            <Tr>
              <TdFullWContainer title={'월세 지원 금액'}>
                <Box>{staticThreeCommafy(lease_contract?.support_payment)}원</Box>
              </TdFullWContainer>
              <TdFullWContainer title={''}></TdFullWContainer>
            </Tr>
          </Tbody>
        </Table>
      </VStack>
      {/* 입주 중 체크사항 */}
      <VStack spacing={3} justifyContent={'start'} align={'flex-start'} w={'100%'}>
        <HStack w={'full'} spacing={0} justify={'space-between'}>
          <Box fontWeight={'bold'} fontSize={'18px'} textAlign={'start'} w={'max'}>
            입주 중 체크사항
          </Box>
        </HStack>
        <Table w={'100%'} variant="simple">
          <Tbody w={'100%'}>
            <Tr w={'100%'} borderTopWidth={'1px'} borderTopColor={'gainGray'}>
              <TdFullWContainer title={'체크인 사진'}>
                <MoveInAndOutInfoCheckModal
                  lease_contract={lease_contract}
                  disabled={!lease_contract?.move_in?.createdAt}
                >
                  <Text textStyle={'ul_green'}>{lease_contract?.move_in?.createdAt ? '등록완료' : '등록예정'}</Text>
                </MoveInAndOutInfoCheckModal>
              </TdFullWContainer>
              <TdFullWContainer title={'체크아웃 사진'}>
                <MoveInAndOutInfoCheckModal
                  lease_contract={lease_contract}
                  disabled={!lease_contract?.moving_out?.createdAt}
                >
                  <Text textStyle={'ul_green'}>{lease_contract?.moving_out?.createdAt ? '등록완료' : '등록예정'}</Text>
                </MoveInAndOutInfoCheckModal>
              </TdFullWContainer>
            </Tr>
            <Tr>
              <TdFullWContainer
                title={'민원'}
                ContentTdProps={{
                  colSpan: 3,
                }}
              >
                <Box>🌟업데이트 예정🌟</Box>
              </TdFullWContainer>
            </Tr>
            <Tr>
              <TdFullWContainer
                title={'연장 요청(요청일)'}
                ContentTdProps={{
                  colSpan: 3,
                }}
              >
                <Box>🌟업데이트 예정🌟</Box>
              </TdFullWContainer>
            </Tr>
          </Tbody>
        </Table>
      </VStack>
    </VStack>
  );
};

export default Lmove;

import {
  Box,
  Button,
  Center,
  Flex,
  Grid,
  HStack,
  Image,
  Link,
  ListItem,
  Text,
  UnorderedList,
  VStack,
} from '@chakra-ui/react';
import { ParentSize } from '@visx/responsive';
import { format } from 'date-fns';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { GItem, GTitleItem } from '../../../../../components/common/GridItem';
import AnnouncementModal from '../../../../../components/subscriber/Modals/Announcement';
import TextLink from '../../../../../components/common/Link/TextLink';
import { useGetAllAnnouncements } from '../../../../../lib/common/hooks/query';
import {
  parseCounts,
  useGetAllCompanyContractPayments,
  useGetAllCounts,
  useGetCompanyContract,
  useGetCompanyInfomation,
} from '../../../../../lib/subscriber/hooks/query';
import { checkArrAndGetFirst, subscriptionTypeTrans } from '../../../../../lib/subscriber/utils/utils';
import { RightArrowIcon } from '../../../../../statics/common/icons';
import SubscribePlan from './approved/subscribeplan';
import UnSubscribePlan from './blocked/unsubscribeplan';
import { isEmptyObject, isUndefined } from '@chakra-ui/utils';
import Scrollbars from 'react-custom-scrollbars-2';
import { datedotformat } from '../../../../../lib/common/utils/dateformatter';

const Dashboard = () => {
  const { data: cpInfo } = useGetCompanyInfomation();

  const { data: allUsings } = useGetAllCounts();

  const {
    data: contractData,
    status: contractStatus,
    isPlaceholderData: isContractPlaceholderData,
  } = useGetCompanyContract();

  const { data: paymentsdata, isPlaceholderData: isPaymentsPlaceholderData } = useGetAllCompanyContractPayments();

  const { data: allAnnouncements } = useGetAllAnnouncements('subscriber', 'master');

  const navigate = useNavigate();

  const isNotSubscribing = isContractPlaceholderData || isEmptyObject(contractData);

  console.log(cpInfo, allUsings, contractData, paymentsdata, allAnnouncements);
  console.log('contractData =>', contractData);

  if (
    cpInfo === undefined ||
    allUsings === undefined ||
    contractData === undefined ||
    paymentsdata === undefined ||
    allAnnouncements === undefined
  ) {
    return null;
  }

  const { available_user_count, payment_method_type, subscription_type } = contractData;

  const availableUserCount = isUndefined(available_user_count) ? 0 : available_user_count;

  const {
    services_count,
    usings_count,
    inactive_count,
    lease_payment_overdues_count,
    proxy_payments_count,
    subscription_payment_overdues_count,
    supports_count,
  } = allUsings;
  // 승인 요청
  const total_requests_count =
    parseCounts(services_count, 'total') !== undefined ? parseCounts(services_count, 'total').count : 0;

  const total_using_employees_count =
    parseCounts(usings_count, 'total') !== undefined ? parseCounts(usings_count, 'total').count : 0;
  // 비활성 유저
  const total_in_active_count =
    parseCounts(inactive_count, 'total') !== undefined ? parseCounts(inactive_count, 'total').count : 0;
  const total_lease_payment_overdues_count =
    parseCounts(lease_payment_overdues_count, 'total') !== undefined
      ? parseCounts(lease_payment_overdues_count, 'total').count
      : 0;
  const total_proxy_payments_count =
    parseCounts(proxy_payments_count, 'total') !== undefined ? parseCounts(proxy_payments_count, 'total').count : 0;
  const total_subscription_payment_overdues_count =
    parseCounts(subscription_payment_overdues_count, 'total') !== undefined
      ? parseCounts(subscription_payment_overdues_count, 'total').count
      : 0;
  // const total_supports_count =
  //   parseCounts(supports_count, 'total') !== undefined ? parseCounts(supports_count, 'total').count : 0;

  const is_paid_paymentsdata = paymentsdata.filter((payment) => payment.is_paid === true);
  const use_start_date = checkArrAndGetFirst(is_paid_paymentsdata).use_start_date;
  const use_end_date = checkArrAndGetFirst(is_paid_paymentsdata.slice(-1)).use_end_date;

  console.log('is_paid_paymentsdata =>', is_paid_paymentsdata);

  const is_not_paid_paymentsdata = paymentsdata.filter((payment) => payment.is_paid === false);
  const next_payment_date = checkArrAndGetFirst(is_not_paid_paymentsdata).expected_payment_date;

  // const is_supported_count =
  //   parseCounts(supports_count, 'total') !== undefined ? parseCounts(supports_count, 'total').count : 0;

  return (
    // <Grid h="100%" templateRows="repeat(20, minmax(30px, auto))" templateColumns="repeat(4, 1fr)" gap={6}>
    // <Grid w="full" h="full" maxH="90vh" templateRows={'1fr 3fr auto'} templateColumns="repeat(3, 1fr)" gap={6}>
    <Grid w="full" h="full" minH="inherit" templateRows={'70px 250px auto'} templateColumns="repeat(3, 1fr)" gap={6}>
      {/* 관리자 가이드 */}
      <GItem colSpan={3} px={5} py={5} fontSize={'20px'} minW={'920px'}>
        <Flex h="full" w="full" justifyContent={'space-between'}>
          <HStack h="full">
            <Text>📌</Text>
            <Text display={'inline'} fontWeight={'medium'} minW={'max'}>
              보홈이 처음이신가요?
            </Text>
            <Text display={'inline'} minW={'max'}>
              관리자를 위한 가이드가 준비되어 있어요!
            </Text>
          </HStack>
          <HStack cursor={'pointer'} onClick={() => navigate('/company/manage/service')}>
            <Text minW={'max'}>관리자 가이드 보러가기</Text>
            <RightArrowIcon />
          </HStack>
        </Flex>
      </GItem>
      {/* 오늘 할 일 */}
      <GTitleItem
        upperSection={
          <Flex justifyContent={'space-between'} alignItems={'center'}>
            <Text fontSize={'24px'} fontWeight={'medium'}>
              ✅ 오늘 할 일
            </Text>
          </Flex>
        }
        // rowSpan={1}
        colSpan={1}
        // bg="#FFD31F"
      >
        <Box px={6} py={4} w="full">
          <UnorderedList spacing={2} fontSize={'20px'}>
            <ListItem>
              <HStack w={'full'} justifyContent={'space-between'}>
                <Box fontWeight={'medium'}>승인 요청</Box>
                <Box>
                  <TextLink to={'/company/manage/employee'} fontSize={'20px'} fontWeight={'bold'} color={'red'}>
                    {total_requests_count}건
                  </TextLink>
                </Box>
              </HStack>
            </ListItem>
            <ListItem>
              <HStack w={'full'} justifyContent={'space-between'}>
                <Box fontWeight={'medium'}>구독료 결제</Box>
                <Box fontWeight={'medium'}>{total_subscription_payment_overdues_count}건</Box>
              </HStack>
            </ListItem>
            <ListItem>
              <HStack w={'full'} justifyContent={'space-between'}>
                <Box fontWeight={'medium'}>월세 대납 요청</Box>
                <Box fontWeight={'medium'}>{total_proxy_payments_count}건</Box>
              </HStack>
            </ListItem>
          </UnorderedList>
        </Box>
      </GTitleItem>
      {/* 보홈 이용 현황 */}
      <GTitleItem
        upperSection={
          <Flex justifyContent={'space-between'} alignItems={'center'}>
            <Text fontSize={'24px'} fontWeight={'medium'}>
              🏠 보홈 이용 현황
            </Text>
          </Flex>
        }
        // rowSpan={1}
        colSpan={1}
        bg="white"
      >
        <Box px={6} py={4} w="full">
          <UnorderedList spacing={2} fontSize={'20px'}>
            <ListItem>
              <HStack w={'full'} justifyContent={'space-between'}>
                <Box fontWeight={'medium'}>보홈 이용</Box>
                <Box fontWeight={'medium'}>
                  <TextLink to={'/company/manage/employee'} fontSize={'20px'}>
                    {total_using_employees_count}/{availableUserCount}명
                  </TextLink>
                </Box>
              </HStack>
            </ListItem>
            {/* 기능 완료, 미사용중 */}
            {/* <VStack fontSize={'16px'}>
              <HStack w={'full'} justifyContent={'space-between'}>
                <Box fontWeight={'medium'}>⌞ 활성 유저</Box>
                <Box fontWeight={'medium'}>{total_using_employees_count - total_in_active_count}명</Box>
              </HStack>
              <HStack w={'full'} justifyContent={'space-between'}>
                <Box fontWeight={'medium'}>⌞ 비활성 유저</Box>
                <Box fontWeight={'medium'}>{total_in_active_count}명</Box>
              </HStack>
            </VStack> */}
          </UnorderedList>
        </Box>
      </GTitleItem>
      {/* 구독 현황 */}
      <GTitleItem
        upperSection={
          <Flex justifyContent={'space-between'} alignItems={'center'}>
            <Text fontSize={'24px'} fontWeight={'medium'}>
              📝 구독 현황
            </Text>
            <Text
              as={RouterLink}
              to="/company/manage/subscription"
              textDecorationLine={'underline'}
              textUnderlineOffset={'2px'}
              cursor={'pointer'}
            >
              더보기
            </Text>
          </Flex>
        }
        // rowSpan={1}
        colSpan={1}
      >
        <Box px={6} py={4} w="full">
          <UnorderedList spacing={2}>
            <ListItem>
              <HStack w={'full'} justifyContent={'space-between'}>
                <Box fontWeight={'medium'}>구독 플랜</Box>
                <Box>
                  <Box fontWeight={'medium'}>
                    {isNotSubscribing ? '-' : `${subscriptionTypeTrans(subscription_type)} ${available_user_count}인`}
                    {/* {subscriptionTypeTrans(subscription_type)} {available_user_count}인 */}
                  </Box>
                </Box>
              </HStack>
            </ListItem>
            <ListItem>
              <HStack w={'full'} justifyContent={'space-between'} align={'start'}>
                <Box fontWeight={'medium'}>구독 유효 기간</Box>
                <Box fontSize={'14px'} pt={'2px'}>
                  <Text fontWeight={'medium'}>
                    {/* {`시작일: ${use_start_date === undefined ? '-' : datedotformat(use_start_date)}`} */}
                    {`시작일: ${datedotformat(contractData?.contract_start_date) || '-'}`}
                  </Text>
                  <Text fontWeight={'medium'}>
                    {/* {`종료일: ${use_end_date === undefined ? '-' : datedotformat(use_end_date)}`} */}
                    {`종료일: ${datedotformat(contractData?.contract_end_date) || '-'}`}
                  </Text>
                </Box>
              </HStack>
            </ListItem>
            <ListItem>
              <HStack w={'full'} justifyContent={'space-between'}>
                <Box fontWeight={'medium'}>다음 결제일</Box>
                <Box>
                  <Text fontWeight={'medium'}>
                    {isPaymentsPlaceholderData
                      ? '-'
                      : next_payment_date === undefined
                      ? '-'
                      : datedotformat(next_payment_date)}
                  </Text>
                </Box>
              </HStack>
            </ListItem>
          </UnorderedList>
        </Box>
      </GTitleItem>
      {/* 보홈 소식 */}
      <GTitleItem
        upperSection={
          <Flex justifyContent={'space-between'} alignItems={'center'}>
            <Text fontSize={'24px'} fontWeight={'medium'}>
              보홈 소식
            </Text>
          </Flex>
        }
        // rowSpan={16}
        // minH={'524px'}
        w="full"
        h="full"
        colSpan={3}
        position={'relative'}
      >
        {/* 대시보드 스크롤바 수정 */}
        <Scrollbars style={{ width: '100%', height: '100%' }}>
          <Box px={5} py={3} w="full" h="full">
            {/* <Box px={5} py={3} w="full" overflowY={'scroll'}> */}
            <VStack w="full" h="full" align={'start'}>
              {allAnnouncements.length === 0 ? (
                <Text>새로운 보홈 소식이 없습니다.</Text>
              ) : (
                allAnnouncements.map((announcement, index) => <AnnouncementModal data={announcement} key={index} />)
              )}
            </VStack>
          </Box>
        </Scrollbars>
        <Box
          id="alpha-bottom-line"
          position={'absolute'}
          bottom={0}
          w="full"
          bgColor={'whiteAlpha.700'}
          h="12px"
          borderRadius={'full'}
        />
      </GTitleItem>
      {/* 달력 */}
      {/* <GItem rowSpan={9} colSpan={1} /> */}
      {/* 이용 현황 */}
      {/* <GTitleItem
        upperSection={
          <Flex justifyContent={'space-between'} alignItems={'center'}>
            <Text fontSize={'24px'} fontWeight={'medium'}>
              이용 현황
            </Text>
            <Text
              as={RouterLink}
              to="test"
              textDecorationLine={'underline'}
              textUnderlineOffset={'2px'}
              cursor={'pointer'}
            >
              더보기
            </Text>
          </Flex>
        }
        rowSpan={14}
        colSpan={2}
      >
        <Center px={5} pt={1} w="full" h="full">
          <ParentSize style={{ width: '100%', height: '100%' }}>
            {(parent) => <LinearGridGraph data={dataset} width={parent.width} height={parent.height} />}
          </ParentSize>
        </Center>
      </GTitleItem> */}

      {/* 관리자 메모 */}
      {/* {cpInfo.is_approved ? <SubscribePlan /> : <UnSubscribePlan />} */}
    </Grid>
  );
};

export default Dashboard;

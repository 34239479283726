import { getToken } from 'firebase/messaging';
import { QueryKey, useInfiniteQuery, useQuery, UseQueryResult } from 'react-query';
import {
  announcements_key,
  announcements_path,
  announcement_detail_lookup_key,
  buildings_key,
  buildings_path,
  building_key,
  building_rooms_key,
  building_room_key,
  common_user_key,
  common_user_path,
  companies_contracts_key,
  companies_contracts_path,
  companies_contracts_payments_key,
  companies_contracts_payments_path,
  companies_contracts_support_payments_key,
  companies_contracts_support_payments_path,
  company_hosts_key,
  company_managers_key,
  company_payments_key,
  company_users_lease_contracts_key,
  custom_room_requests_key,
  custom_room_requests_path,
  custom_room_request_key,
  custom_room_request_rooms_key,
  custom_room_request_rooms_path,
  tenants_custom_room_requests_key,
  tenant_detail_lookup_key,
  tenant_lease_contract_detail_lookup_key,
  user_key,
  virtual_account_user_key,
} from '../../../statics/admin/data/KeyPath';
import { fetchClient } from '../../common/utils/axios';
import {
  AdminAnnouncement,
  BaseResponse,
  Basic,
  Building,
  BuildingDetail,
  BuildingRoom,
  BuildingRoomForMap,
  BuildingRoomTemplate,
  CommonUserType,
  CompanyContract,
  CompanyManager,
  CompanyManagers,
  CompanyPayment,
  CompanySubscriber,
  CompanySupportPayment,
  CompanyTenant,
  CompanyUser,
  CompanyWithPreServiceRequest,
  HostBuildingRoom,
  HostLeaseContract,
  HostLeaseContractLeasePayments,
  HostSimpleInfo,
  HostUser,
  Infinite,
  QueryResponse,
  RequestRoom,
  Room,
  RoomDetailInfo,
  RoomRequest,
  SupportPaymentCompany,
  SupportPaymentCompanyUser,
  TenantLeaseContract,
  TenantsWithComplaints,
  TenantsWithContract,
  TenantsWithTour,
  Using,
  WpBalanceLog,
  WpBalanceLogListType,
} from '../../common/utils/axios/types';
import { auth, messaging } from '../../common/utils/firebase/init';
import { useGetUserPathandKey } from '../../common/hooks/query';
import {
  Revise_buildings_rooms_key,
  Revise_buildings_rooms_path,
  companies_hosts_key,
  companies_hosts_path,
  companies_managers_key,
  companies_managers_path,
  companies_subscribers_key,
  companies_subscribers_path,
  companies_tenants_key,
  companies_tenants_leaseContracts_key,
  companies_tenants_leaseContracts_path,
  companies_tenants_path,
  rooms_key,
  rooms_path,
  tenant_tour_key,
  buildings_rooms_key,
  buildings_rooms_path,
  tenant_contract_key,
  tenant_complaint_key,
  pre_service_requests_key,
  supportPayment_companies_key,
  supportPayment_company_users_key,
  host_leaseContracts_key,
  host_buildings_rooms_key,
  host_leaseContracts_leasePayments_key,
  supportPayment_company_users_v2_key,
  companies_employees_services_key,
  companies_employees_usings_key,
  companies_subscription_contracts_key,
  agencies_managers_key,
} from '../../../statics/agent/data/KeyPath';
import { default_items_limit_number, maximized_limit_number } from '../../common/utils/values';
import { currentParamsObject } from '../../common/utils/base';
import * as Util from '../../../lib/common/utils/Util';
import * as Type from '../../../types/common/CommonType';
import { getLocalStorageItem } from '../../common/utils/localstorage';
import { useAuthState } from 'react-firebase-hooks/auth';
import _ from 'lodash';

/** 페지네이션 */
export const useQueryParamsFrame = <TypeA>(queryKey: QueryKey, path: string, basic?: Basic<QueryResponse<TypeA>>) => {
  const { data: ud } = useGetUserPathandKey();
  const { page, limit, search } = currentParamsObject(false);
  const [user, loading, error] = useAuthState(auth);
  const firstKey = Array.isArray(queryKey) ? queryKey : [queryKey];
  const secondaryKey = {
    ...basic?.axiosConfig?.params,
    page: basic?.axiosConfig?.params?.page || 1,
    limit: basic?.axiosConfig?.params?.limit || default_items_limit_number,
    search: basic?.axiosConfig?.params?.search || '',
  };
  const key = [...firstKey, secondaryKey];
  return useQuery<QueryResponse<TypeA>>(
    key,
    async () => {
      const token = await user?.getIdToken();
      console.log('admin path', path);
      const instance = await fetchClient({
        headers: {
          token,
        },
      });
      const res = await instance.get<BaseResponse<TypeA>>(ud?.path + path, {
        ...basic?.axiosConfig,
        params: {
          ...basic?.axiosConfig?.params,
          page: basic?.axiosConfig?.params?.page || 1,
          limit: basic?.axiosConfig?.params?.limit || default_items_limit_number,
          search: basic?.axiosConfig?.params?.search || search,
        },
      });
      const result = {
        result: res.data.data,
        limit: basic?.axiosConfig?.params?.limit || default_items_limit_number,
        page: res.data.page_info?.page,
        total: res.data.page_info?.total,
        last_page: res.data.page_info?.last_page || 100,
        ...res.data.extra_info,

        data: res.data?.data as TypeA,
        message: res.data?.message as string,
        page_info: res.data?.page_info as Type.PageInfo,
        extra_info: res.data?.extra_info,
      };
      return result;
    },
    {
      ...basic?.options,
      enabled: !!user,
      retry: 10,
    },
  );
};

export const usePostAdminFCMToken = () => {
  const [user, loading, error] = useAuthState(auth);
  return useQuery(['web-fcm-token'], () =>
    getToken(messaging, {
      vapidKey: 'BEatN0NcWA6UdKr8u-teIepcJl4kNlED-shMnqWcZApuycPQ_vYfo7Ltpq1sj1_m_DlmDgmcJJJlTp_JCU4RIO0',
    }).then(async (value) => {
      const localUser = Util.getCompanyUserTypePathKeyFromLocalStorage();
      const userType = localUser?.user_type;
      const companyType = localUser?.company_type;
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      const _result = await instance.post<any>(localUser.path + '/' + 'web-fcm-token', {
        web_fcm_token: value,
      });
      return _result;
    }),
  );
};

export const useGetCustomRoomRequests = (basic?: Basic<QueryResponse<RoomRequest[]>>) => {
  return useQueryParamsFrame<RoomRequest[]>([custom_room_requests_key], custom_room_requests_path, basic);
};

export const useGetCustomRoomRequestRecommendedRooms = (
  custom_room_request_id: string,
  basic?: Basic<RequestRoom[]>,
) => {
  const [user, loading, error] = useAuthState(auth);
  const { data: ud } = useGetUserPathandKey();

  return useQuery(
    [
      custom_room_request_rooms_key,
      {
        ud: ud?.key,
        custom_room_request_id,
        params: basic?.axiosConfig?.params,
      } as any,
    ],
    async () => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      const value = await instance.get<BaseResponse<RequestRoom[]>>(
        ud?.path + `/custom-room-requests/${custom_room_request_id}/rooms`,
        {
          ...basic?.axiosConfig,
        },
      );
      return value.data.data;
    },
    {
      ...basic?.options,
      enabled: basic?.options?.enabled === undefined ? !!ud : basic?.options?.enabled && !!ud,
    },
  );
};

export const useGetRoom = (room_id: string, basic?: Basic<RoomDetailInfo>) => {
  const [user, loading, error] = useAuthState(auth);
  const { data: ud } = useGetUserPathandKey();

  return useQuery(
    [
      custom_room_request_rooms_key,
      {
        ud: ud?.key,
        room_id,
        params: basic?.axiosConfig?.params,
      } as any,
    ],
    async () => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      const value = await instance.get<BaseResponse<RoomDetailInfo>>(ud?.path + `/rooms/${room_id}`, {
        ...basic?.axiosConfig,
      });
      return value.data.data;
    },

    {
      ...basic?.options,
      enabled: basic?.options?.enabled === undefined ? !!ud : basic?.options?.enabled && !!ud,
    },
  );
};

export const useGetBuildingRoom = (room_id: string, basic?: Basic<BuildingRoom>) => {
  const [user, loading, error] = useAuthState(auth);
  const { data: ud } = useGetUserPathandKey();

  return useQuery(
    [
      building_room_key,
      {
        ud: ud?.key,
        room_id,
        params: basic?.axiosConfig?.params,
      } as any,
    ],
    async () => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      const value = await instance.get<BaseResponse<BuildingRoom>>(ud?.path + `/building/rooms/${room_id}`, {
        ...basic?.axiosConfig,
      });
      return value.data.data;
    },

    {
      ...basic?.options,
      enabled: basic?.options?.enabled === undefined ? !!ud : basic?.options?.enabled && !!ud,
    },
  );
};

export const useGetBuildings = (basic?: Basic<Building[]>) => {
  const [user, loading, error] = useAuthState(auth);
  const { data: ud } = useGetUserPathandKey();

  return useQuery(
    [buildings_key, { ...basic?.axiosConfig?.params }],
    async () => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      const value = await instance.get<BaseResponse<Building[]>>(ud?.path + `${buildings_path}`, {
        ...basic?.axiosConfig,
        params: {
          limit: maximized_limit_number,
          ...basic?.axiosConfig?.params,
        },
      });
      return value.data.data;
    },

    {
      ...basic?.options,
      enabled: basic?.options?.enabled === undefined ? !!ud : basic?.options?.enabled && !!ud,
    },
  );
};

export const useGetBuilding = (building_id: string, basic?: Basic<BuildingDetail>) => {
  const [user, loading, error] = useAuthState(auth);
  const { data: ud } = useGetUserPathandKey();

  return useQuery(
    [
      building_key,
      {
        ud: ud?.key,
        building_id,
        params: basic?.axiosConfig?.params,
      } as any,
    ],
    async () => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      const value = await instance.get<BaseResponse<BuildingDetail>>(ud?.path + `/buildings/${building_id}`, {
        ...basic?.axiosConfig,
      });
      return value.data.data;
    },

    {
      ...basic?.options,
      enabled: basic?.options?.enabled === undefined ? !!ud : basic?.options?.enabled && !!ud,
    },
  );
};

//
export const usePreviousGetBuildingAllRooms = (building_id: string, basic?: Basic<BuildingRoomTemplate[]>) => {
  const [user, loading, error] = useAuthState(auth);
  const { data: ud } = useGetUserPathandKey();

  return useQuery(
    [building_rooms_key, { ...basic?.axiosConfig?.params }],
    async () => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      const value = await instance.get<BaseResponse<BuildingRoomTemplate[]>>(
        ud?.path + `/buildings/${building_id}/rooms`,
        {
          ...basic?.axiosConfig,
        },
      );
      return value.data.data;
    },

    {
      ...basic?.options,
      enabled: basic?.options?.enabled === undefined ? !!ud : basic?.options?.enabled && !!ud,
    },
  );
};

/** 한 회사의 모든 유저와 모든 월세지원 리스트 보기 */
export const useGetCompanyAllManagersV2 = (company_id: string, basic?: Basic<CompanyManagers>) => {
  const [user, loading, error] = useAuthState(auth);
  const { data: ud } = useGetUserPathandKey();

  return useQuery(
    [
      supportPayment_company_users_v2_key,
      {
        ud: ud?.key,
        company_id,
        params: basic?.axiosConfig?.params,
      } as any,
    ],
    async () => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      const value = await instance.get<BaseResponse<CompanyManagers>>(
        ud?.path + `/companies/${company_id}/managers/v2`,
        {
          ...basic?.axiosConfig,
        },
      );
      return value.data.data;
    },

    {
      ...basic?.options,
      enabled: basic?.options?.enabled === undefined ? !!ud : basic?.options?.enabled && !!ud,
    },
  );
};

export const useGetBuildingAllRooms = (building_id: string, basic?: Basic<QueryResponse<BuildingRoomTemplate[]>>) => {
  return useQueryParamsFrame<BuildingRoomTemplate[]>(
    [building_rooms_key, building_id],
    `/buildings/${building_id}/rooms`,
    basic,
  );
};

export const useGetAllBuildingsRoomsForMap = (basic?: Basic<QueryResponse<BuildingRoomForMap[]>>) => {
  return useQueryParamsFrame<BuildingRoomForMap[]>([building_rooms_key], `/buildings/rooms/map`, {
    ...basic,
    axiosConfig: {
      ...basic?.axiosConfig,
      params: {
        ...basic?.axiosConfig?.params,
        limit: maximized_limit_number,
      },
    },
  });
};

export const useGetAllRooms = (basic?: Infinite<Room[]>) => {
  const [user, loading, error] = useAuthState(auth);
  const { data: ud } = useGetUserPathandKey();

  return useInfiniteQuery(
    [
      rooms_key,
      {
        params: basic?.axiosConfig?.params,
      },
    ],
    async () => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      const res = await instance.get<BaseResponse<Room[]>>(ud?.path + rooms_path, {
        ...basic?.axiosConfig,
      });
      return res.data?.data;
    },
    {
      ...basic?.options,
      enabled: basic?.options?.enabled === undefined ? !!ud : basic?.options?.enabled && !!ud,
    },
  );
};

/** 수정 필요 */
export const usePreviousGetAllBuildingsRooms = (basic?: Infinite<BuildingRoom[]>) => {
  const [user, loading, error] = useAuthState(auth);
  const { data: ud } = useGetUserPathandKey();

  return useInfiniteQuery(
    [
      Revise_buildings_rooms_key,
      {
        params: basic?.axiosConfig?.params,
      },
    ],
    async () => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      const res = await instance.get<BaseResponse<BuildingRoom[]>>(ud?.path + Revise_buildings_rooms_path, {
        ...basic?.axiosConfig,
      });
      return res.data.data;
    },
    {
      ...basic?.options,
      enabled: basic?.options?.enabled === undefined ? !!ud : basic?.options?.enabled && !!ud,
    },
  );
};

export const useGetAllBuildingsRooms = (basic?: Basic<QueryResponse<BuildingRoom[]>>) => {
  return useQueryParamsFrame<BuildingRoom[]>([buildings_rooms_key], buildings_rooms_path, basic);
};

export const useGetAllCompaniesTenants = (basic?: Basic<QueryResponse<CompanyTenant[]>>) => {
  return useQueryParamsFrame<CompanyTenant[]>([companies_tenants_key], companies_tenants_path, basic);
};

export const usePreviousGetAllCompaniesTenantsLeaseContracts = (basic?: Infinite<TenantLeaseContract[]>) => {
  const [user, loading, error] = useAuthState(auth);
  const { data: ud } = useGetUserPathandKey();

  return useInfiniteQuery(
    // [companies_tenants_leaseContracts_key],
    [
      companies_tenants_leaseContracts_key,
      {
        ...basic?.axiosConfig?.params,
      },
    ],
    async () => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      const res = await instance.get<BaseResponse<TenantLeaseContract[]>>(
        ud?.path + companies_tenants_leaseContracts_path,
        {
          ...basic?.axiosConfig,
        },
      );
      return res.data?.data;
    },

    {
      ...basic?.options,
      enabled: basic?.options?.enabled === undefined ? !!ud : basic?.options?.enabled && !!ud,
    },
  );
};

export const useGetAllCompaniesTenantsLeaseContracts = (basic?: Basic<QueryResponse<TenantLeaseContract[]>>) => {
  return useQueryParamsFrame<TenantLeaseContract[]>(
    [companies_tenants_leaseContracts_key],
    companies_tenants_leaseContracts_path,
    basic,
  );
};

export const useGetAllAgenciesManagers = (basic?: Basic<any>) => {
  return useQueryParamsFrame<Type.Company[]>([agencies_managers_key], agencies_managers_key, basic);
};

export const useGetAllCompaniesManagers = (basic?: Basic<QueryResponse<CompanyManager[]>>) => {
  return useQueryParamsFrame<Type.Company[]>([companies_managers_key], companies_managers_path, basic);
};

export const useGetAllCompaniesSubscribers = (basic?: Basic<QueryResponse<CompanySubscriber[]>>) => {
  return useQueryParamsFrame<CompanySubscriber[]>([companies_subscribers_key], companies_subscribers_path, basic);
};

/** QueryParamsFrame으로 바꾸다가 모달이라 변경 미정, 차후 변경할 것 */
export const useModalGetAllCompaniesHosts = (basic?: Infinite<HostUser[]>) => {
  const [user, loading, error] = useAuthState(auth);
  const { data: ud } = useGetUserPathandKey();

  return useInfiniteQuery(
    [companies_hosts_key],
    async () => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      const res = await instance.get<BaseResponse<HostUser[]>>(ud?.path + companies_hosts_path, {
        ...basic?.axiosConfig,
      });
      return res.data?.data;
    },

    {
      ...basic?.options,
      enabled: basic?.options?.enabled === undefined ? !!ud : basic?.options?.enabled && !!ud,
    },
  );
};

export const useGetAllCompaniesHosts = (basic?: Basic<QueryResponse<HostUser[]>>) => {
  return useQueryParamsFrame<HostUser[]>([companies_hosts_key], companies_hosts_path, basic);
};

/** 임대인의 모든 빌딩의 모든 매물 조회 */
export const useGetHostAllBuildingsRooms = (user_id: string, basic?: Basic<any>) =>
  useQueryParamsFrame<HostBuildingRoom[]>(
    [host_buildings_rooms_key, user_id],
    `/company/hosts/${user_id}/buildings/rooms`,
    basic,
  );

export const useGetAllUsersHouseHuntingRequests = (company_id: string, basic?: Basic<any>) =>
  useQueryParamsFrame<any[]>(
    [companies_employees_services_key, company_id],
    `/companies/${company_id}/employees/services`,
    basic,
  );

export const useGetAllUsersUsageLists = (company_id: string, basic?: Basic<QueryResponse<Using[]>>) => {
  return useQueryParamsFrame<Using[]>(
    [companies_employees_usings_key, company_id],
    `/companies/${company_id}/employees/usings`,
    basic,
  );
};

/** 임대인의 모든 계약서 보기 */
export const useGetHostAllLeaseContracts = (user_id: string, basic?: Basic<any>) =>
  useQueryParamsFrame<HostLeaseContract[]>(
    [host_leaseContracts_key, user_id],
    `/company/hosts/${user_id}/lease-contracts`,
    basic,
  );

/** 임대인의 모든 계약서의 모든 납부 영수증 보기 */
export const useGetHostAllLeaseContractsLeasePayments = (user_id: string, basic?: Basic<any>) =>
  useQueryParamsFrame<HostLeaseContractLeasePayments[]>(
    [host_leaseContracts_leasePayments_key, user_id],
    `/company/hosts/${user_id}/lease-contracts/lease-payments`,
    basic,
  );

/** 한 회사의 모든 유저와 모든 월세지원 리스트 보기 */
export const useGetSupportPaymentCompanyAllUsers = (company_id: string, basic?: Basic<any>) =>
  useQueryParamsFrame<SupportPaymentCompanyUser[]>(
    [supportPayment_company_users_key, company_id],
    `/companies/${company_id}/service-requests/support-payment`,
    basic,
  );

/** 한 회사의 모든 구독 정보 조회 */
export const useGetAllSubscriptionContracts = (company_id: string, basic?: Basic<any>) =>
  useQueryParamsFrame<CompanyContract[]>(
    [companies_subscription_contracts_key, company_id],
    `/companies/${company_id}/subscription-contracts`,
    basic,
  );

export const useGetCompanyUserLeaseContracts = (user_id: string, basic?: Basic<any>) =>
  useQueryParamsFrame<TenantLeaseContract[]>(
    [company_users_lease_contracts_key, user_id],
    `/company/users/${user_id}/lease-contracts/lease-payments`,
    basic,
  );

/** 월세지원 회사 모두 보기 */
export const useGetAllSupportPaymentCompanies = (basic?: Basic<any>) =>
  useQueryParamsFrame<SupportPaymentCompany[]>([supportPayment_companies_key], '/companies/managers/v2', basic);

/** 모든 회사의 모든 임차인의 모든 투어 조회 */
export const useGetAllCompaniesTenantsTours = (basic?: Basic<any>) =>
  useQueryParamsFrame<TenantsWithTour[]>([tenant_tour_key], '/companies/tenants/tours', basic);

/** 모든 회사의 모든 임차인의 모든 계약서 조회 */
export const useGetAllCompaniesTenantsContracts = (basic?: Basic<any>) =>
  useQueryParamsFrame<TenantsWithContract[]>([tenant_contract_key], '/companies/tenants/lease-contracts', basic);

/** 모든 회사의 모든 임차인의 모든 민원 조회 */
export const useGetAllCompaniesTenantsComplaints = (basic?: Basic<any>) =>
  useQueryParamsFrame<TenantsWithComplaints[]>([tenant_complaint_key], '/companies/tenants/complaints', basic);

/** 모든 회사의 모든 사전 서비스 등록 조회 */
export const useGetAllCompaniesPreServiceRequests = (basic?: Basic<any>) =>
  useQueryParamsFrame<CompanyWithPreServiceRequest[]>(
    [pre_service_requests_key],
    '/companies/pre-service-requests',
    basic,
  );

export const useGetCompanyAllManagers = (company_id: string, basic?: Basic<CompanyManager>) => {
  const [user, loading, error] = useAuthState(auth);
  const { data: ud } = useGetUserPathandKey();

  return useQuery(
    [
      company_managers_key,
      {
        ud: ud?.key,
        company_id,
        params: basic?.axiosConfig?.params,
      } as any,
    ],
    async () => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      const value = await instance.get<BaseResponse<CompanyManager>>(ud?.path + `/companies/${company_id}/managers`, {
        ...basic?.axiosConfig,
      });
      return value.data?.data;
    },

    {
      ...basic?.options,
      enabled: basic?.options?.enabled === undefined ? !!ud : basic?.options?.enabled && !!ud,
    },
  );
};

export const useGetCompanyAllHosts = (user_id: string, basic?: Basic<HostSimpleInfo>) => {
  const [user, loading, error] = useAuthState(auth);
  const { data: ud } = useGetUserPathandKey();

  return useQuery(
    [
      company_hosts_key,
      {
        ud: ud?.key,
        user_id,
        params: basic?.axiosConfig?.params,
      } as any,
    ],
    async () => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      const res = await instance.get<BaseResponse<HostSimpleInfo>>(ud?.path + `/company/hosts/${user_id}`, {
        ...basic?.axiosConfig,
      });
      return res.data?.data;
    },

    {
      ...basic?.options,
      enabled: basic?.options?.enabled === undefined ? !!ud : basic?.options?.enabled && !!ud,
    },
  );
};

export const useGetCompanyManagerAllRequests = (
  tenant_id: string,
  basic?: Basic<RoomRequest['employee']['custom_room_request'][]>,
) => {
  const [user, loading, error] = useAuthState(auth);
  const { data: ud } = useGetUserPathandKey();

  return useQuery(
    [
      tenants_custom_room_requests_key,
      {
        ud: ud?.key,
        tenant_id,
        params: basic?.axiosConfig?.params,
      } as any,
    ],
    async () => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      const value = await instance.get<BaseResponse<RoomRequest['employee']['custom_room_request'][]>>(
        ud?.path + `/tenants/${tenant_id}/custom-room-requests`,
        {
          ...basic?.axiosConfig,
        },
      );
      return value.data?.data;
    },

    {
      ...basic?.options,
      enabled:
        basic?.options?.enabled === undefined ? !!ud && !!tenant_id : basic?.options?.enabled && !!ud && !!tenant_id,
    },
  );
};

export const useGetCompanyManager = (tenant_id: string, basic?: Basic<CompanyUser>) => {
  const [user, loading, error] = useAuthState(auth);
  const { data: ud } = useGetUserPathandKey();

  return useQuery(
    [
      tenant_detail_lookup_key,
      {
        ud: ud?.key,
        tenant_id,
        params: basic?.axiosConfig?.params,
      } as any,
    ],
    async () => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      const value = await instance.get<BaseResponse<CompanyUser>>(ud?.path + `/tenants/${tenant_id}`, {
        ...basic?.axiosConfig,
      });
      return value.data?.data;
    },

    {
      ...basic?.options,
      enabled:
        basic?.options?.enabled === undefined ? !!ud && !!tenant_id : basic?.options?.enabled && !!ud && !!tenant_id,
    },
  );
};

export const useGet_WP_Merchant = (basic?: Basic<any>) => {
  // return {} as any;
  return useQueryParamsFrame<{ wpBalanceLogList: WpBalanceLog[] }>(['useGet_WP_Merchant'], '/wp/merchant/inout', basic);
};

export const useGetAdminAnnouncement = (announcement_id: string, basic?: Basic<AdminAnnouncement>) => {
  const [user, loading, error] = useAuthState(auth);
  const { data: ud } = useGetUserPathandKey();

  return useQuery(
    [
      announcement_detail_lookup_key,
      {
        ud: ud?.key,
        announcement_id,
        params: basic?.axiosConfig?.params,
      } as any,
    ],
    async () => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      const value = await instance.get<BaseResponse<AdminAnnouncement>>(
        ud?.path + `/announcements/${announcement_id}`,
        {
          ...basic?.axiosConfig,
        },
      );
      return value.data?.data;
    },

    {
      ...basic?.options,
      staleTime: 1000,
      enabled: !!basic?.options?.enabled && !!ud && !!announcement_id,
    },
  );
};

export const useGetTenantLeaseContract = (lease_contract_id: string, basic?: Basic<TenantLeaseContract>) => {
  const [user, loading, error] = useAuthState(auth);
  const { data: ud } = useGetUserPathandKey();

  return useQuery(
    [
      tenant_lease_contract_detail_lookup_key,
      {
        ud: ud?.key,
        lease_contract_id,
        params: basic?.axiosConfig?.params,
      } as any,
    ],
    async () => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      const value = await instance.get<BaseResponse<TenantLeaseContract>>(
        ud?.path + `/lease-contracts/${lease_contract_id}`,
        {
          ...basic?.axiosConfig,
        },
      );
      return value.data?.data;
    },

    {
      ...basic?.options,
      enabled:
        basic?.options?.enabled === undefined
          ? !!ud && !!lease_contract_id
          : basic?.options?.enabled && !!ud && !!lease_contract_id,
    },
  );
};
export const useGetAllCompaniesContracts = (basic?: Basic<any>) =>
  useQueryParamsFrame<CompanyContract[]>([companies_contracts_key], companies_contracts_path, basic);

export const useGetAllAdminAnnouncements = (basic?: Basic<any>) =>
  useQueryParamsFrame<AdminAnnouncement[]>([announcements_key], announcements_path, basic);

export const usePreviousGetAllCompaniesContractsPayments = (basic?: Infinite<CompanyPayment[]>) => {
  const [user, loading, error] = useAuthState(auth);
  const { data: ud } = useGetUserPathandKey();

  return useInfiniteQuery(
    [
      companies_contracts_payments_key,
      {
        ...basic?.axiosConfig?.params,
      },
    ],
    async () => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      const res = await instance.get<BaseResponse<CompanyPayment[]>>(ud?.path + companies_contracts_payments_path, {
        ...basic?.axiosConfig,
      });
      return res.data?.data;
    },

    {
      ...basic?.options,
      enabled: basic?.options?.enabled === undefined ? !!ud : basic?.options?.enabled && !!ud,
    },
  );
};

export const useGetAllCompaniesContractsPayments = (basic?: Basic<QueryResponse<CompanyPayment[]>>) => {
  return useQueryParamsFrame<CompanyPayment[]>(
    [companies_contracts_payments_key],
    companies_contracts_payments_path,
    basic,
  );
};

export const useGetAllCompaniesContractsSupportPayments = (basic?: Basic<any>) => {
  return useQueryParamsFrame<CompanySupportPayment[]>(
    [companies_contracts_support_payments_key],
    companies_contracts_support_payments_path,
    basic,
  );
};

export const useGetCompanyAllPayments = (company_id: string, basic?: Basic<any>) => {
  return useQueryParamsFrame<CompanyPayment[]>(
    [company_payments_key],
    `/companies/${company_id}/subscription-payments`,
    basic,
  );
};

export const useGetBuildingRoomTemplate = (
  building_id: string,
  room_type: string,
  basic?: Basic<BuildingRoomTemplate>,
) => {
  const [user, loading, error] = useAuthState(auth);
  const { data: ud } = useGetUserPathandKey();

  return useQuery(
    [
      company_payments_key,
      {
        ud: ud?.key,
        params: basic?.axiosConfig?.params,
        building_id,
        room_type,
      } as any,
    ],
    async () => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      const value = await instance.get<BaseResponse<BuildingRoomTemplate>>(
        ud?.path + `/buildings/${building_id}/room-types/${room_type}`,
        {
          ...basic?.axiosConfig,
        },
      );
      return value.data?.data;
    },

    {
      ...basic?.options,
      enabled: basic?.options?.enabled === undefined ? !!ud : basic?.options?.enabled && !!ud,
    },
  );
};

export const useGetCustomRoomRequest_ = (
  custom_room_request_id: string,
  basic?: Basic<RoomRequest['employee']['custom_room_request']>,
) => {
  const [user, loading, error] = useAuthState(auth);
  const { data: ud } = useGetUserPathandKey();

  return useQuery(
    [
      custom_room_request_key,
      {
        ud: ud?.key,
        custom_room_request_id,
        params: basic?.axiosConfig?.params,
      } as any,
    ],
    async () => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      const value = await instance.get<BaseResponse<RoomRequest['employee']['custom_room_request']>>(
        ud?.path + `/custom-room-requests/${custom_room_request_id}`,
        {
          ...basic?.axiosConfig,
        },
      );
      return value.data?.data;
    },

    {
      ...basic?.options,
      enabled: basic?.options?.enabled === undefined ? !!ud : basic?.options?.enabled && !!ud,
    },
  );
};

export const useGetUser = (user_id: string, basic?: Basic<any>) => {
  const [user, loading, error] = useAuthState(auth);
  const { data: ud } = useGetUserPathandKey();

  return useQuery(
    [
      user_key,
      {
        ud: ud?.key,
        user_id,
        params: basic?.axiosConfig?.params,
      } as any,
    ],
    async () => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      const value = await instance.get<BaseResponse<any>>(ud?.path + `/company/users/${user_id}`, {
        ...basic?.axiosConfig,
      });
      return value?.data?.data;
    },
    {
      ...basic?.options,
      enabled: basic?.options?.enabled === undefined ? !!ud : basic?.options?.enabled && !!ud,
    },
  );
};

export const useGetUserVirtualAccount = (user_id: string, basic?: Basic<any>) => {
  const [user, loading, error] = useAuthState(auth);
  const { data: ud } = useGetUserPathandKey();

  return useQuery(
    [
      virtual_account_user_key,
      {
        ud: ud?.key,
        user_id,
        params: basic?.axiosConfig?.params,
      } as any,
    ],
    async () => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      const value = await instance.get<BaseResponse<any>>(ud?.path + `/users/tenants/${user_id}/wp/virtual-account`, {
        ...basic?.axiosConfig,
      });
      return value.data?.data;
    },

    {
      ...basic?.options,
      enabled: basic?.options?.enabled === undefined ? !!ud : basic?.options?.enabled && !!ud,
    },
  );
};

export const useGetAllUsers = (basic?: Basic<CommonUserType[]>) => {
  const [user, loading, error] = useAuthState(auth);
  const { data: ud } = useGetUserPathandKey();

  return useQuery(
    [
      common_user_key,
      {
        ud: ud?.key,
        params: basic?.axiosConfig?.params,
      } as any,
    ],
    async () => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      const value = await instance.get<BaseResponse<CommonUserType[]>>(ud?.path + common_user_path, {
        ...basic?.axiosConfig,
      });
      return value?.data?.data;
    },

    {
      ...basic?.options,
      enabled: basic?.options?.enabled === undefined ? !!ud : basic?.options?.enabled && !!ud,
    },
  );
};

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Divider,
  Flex,
  HStack,
  Image,
  Link,
  StackDivider,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { isEmptyArray, isEmptyObject, isUndefined } from '@chakra-ui/utils';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { IndexingPie } from '../../../components/common/Graph/Pie';
import { useGetCustomRoomRequestRecommendedRooms, useGetCustomRoomRequest_ } from '../../../lib/common/hooks/query';
import {
  commuteTimesTrans,
  commuteTransportationsTrans,
  fullOptionTrans,
  isRoomRecommendationStatus,
  moreOptionsTrans,
  pathOfUserDetailPage,
  roomLivingTypeTrans,
  useNavigateToUserDetailPage,
} from '../../../lib/common/utils/utils';
import {
  CheckEmpty,
  ComponentAndTextCheckEmpty,
  currentParamsObject,
  extractAddressArrFromRegionArr,
  requestStatusTrans,
  TextWithCheckEmpty,
} from '../../../lib/common/utils/base';
import { buildingFormTypeTrans, floorTypeTrans } from '../../../lib/subscriber/utils/utils';
import { datedetaildotformat_mm } from '../../../lib/common/utils/dateformatter';
import { isEmpty } from 'lodash';
import { staticThreeCommafy } from '../../../lib/common/utils/digitformatter';
import Loading from '../../../components/common/Loading';
import * as Type from '../../../types/common/CommonType';

interface RequestRoomDetailTablesProps {
  room_request_id?: string;
}

const RequestRoomDetailTables: React.FC<RequestRoomDetailTablesProps> = ({ room_request_id }) => {
  let { requestid } = currentParamsObject(false);
  requestid = requestid || room_request_id;

  const { data: requestFormData } = useGetCustomRoomRequest_(requestid, {
    options: {
      enabled: !!requestid,
    },
  });

  const { data: requestRoomsData, isLoading: isLoadingCustomRoomRequests } = useGetCustomRoomRequestRecommendedRooms(
    requestid,
    {
      options: {
        enabled: !!requestid,
      },
    },
  );
  if (isUndefined(requestFormData) || isUndefined(requestRoomsData) || !requestid) {
    return null;
  }

  const {
    status,
    tenant_name,
    tenant_id,
    code,
    createdAt,
    within_week,
    how_long_month,
    deposits,
    rents,
    regions,
    building_forms,
    floors,
    // room_nums,
    matching_type,
    room_living_types,
    more_options,
    is_option,
    priorities,
    company_address,
    commute_times,
    commute_transportations,
    comment,
  } = requestFormData;
  const base = pathOfUserDetailPage(status as any);
  const defaultPriorities = {
    rent_priority: 0.33,
    commute_priority: 0.33,
    room_condition_priority: 0.34,
  } as Type.Priorities;

  console.log(requestFormData);

  const Items = () =>
    matching_type === 'reverse' ? (
      // 역매칭
      <>
        {/* 필수 요청사항 */}
        <AccordionItem border="unset">
          <AccordionButton>
            <Box flex="1" textAlign="left" fontWeight={700}>
              필수 요청사항
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel w={'100%'}>
            <Table variant="all_line_silver_12px" size="sm" p={0} fontSize={'12px'}>
              <Tbody>
                <Tr>
                  <Td bgColor="lotionWhite">희망 입주일 / 계약 기간</Td>
                  <Td colSpan={2}>
                    요청일로부터 {within_week}주내 / {how_long_month}개월
                  </Td>
                </Tr>
                <Tr>
                  <Td bgColor="lotionWhite">보증금, 월세 예산</Td>
                  <Td colSpan={2}>
                    보증금 {staticThreeCommafy(deposits?.[0])}~{staticThreeCommafy(deposits?.[1])}원 / 월세{' '}
                    {staticThreeCommafy(rents?.[0])}~{staticThreeCommafy(rents?.[1])}원
                  </Td>
                </Tr>
                <Tr>
                  <Td bgColor="lotionWhite">선호하는 지역</Td>
                  <Td colSpan={2}>
                    <TextWithCheckEmpty value={regions}>
                      {extractAddressArrFromRegionArr(regions || [])
                        .map((region) => region)
                        .join(', ')}
                    </TextWithCheckEmpty>
                  </Td>
                </Tr>
                <Tr>
                  <Td bgColor="lotionWhite" rowSpan={3}>
                    선호하는 유형
                  </Td>
                  <Td maxW="10px">건물 형태</Td>
                  <Td>
                    <TextWithCheckEmpty value={building_forms}>
                      {building_forms?.map((form) => buildingFormTypeTrans(form)).join(', ')}
                    </TextWithCheckEmpty>
                  </Td>
                </Tr>
                <Tr>
                  <Td maxW="10px">층수</Td>
                  <Td>
                    <TextWithCheckEmpty value={floors}>
                      {floors?.map((floor) => floorTypeTrans(floor)).join(', ')}
                    </TextWithCheckEmpty>
                  </Td>
                </Tr>
                <Tr>
                  <Td maxW="10px">방 구조</Td>
                  <Td>
                    <TextWithCheckEmpty value={room_living_types}>
                      {room_living_types?.map((living) => roomLivingTypeTrans(living)).join(', ')}
                    </TextWithCheckEmpty>
                  </Td>
                </Tr>
                <Tr>
                  <Td bgColor="lotionWhite">추가 옵션</Td>
                  <Td colSpan={2}>
                    {is_option ? (
                      <Text>{fullOptionTrans(is_option)}</Text>
                    ) : (
                      <TextWithCheckEmpty value={more_options}>
                        {more_options?.map((option) => moreOptionsTrans(option)).join(', ')}
                      </TextWithCheckEmpty>
                    )}
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </AccordionPanel>
        </AccordionItem>

        {/* 개별 맞춤 조건 */}
        <AccordionItem border="unset">
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left" fontWeight={700}>
                {tenant_name} 님의 개별 맞춤 조건
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Table variant="all_line_silver_12px" size="sm" p={0}>
              <Tbody>
                <Tr borderTop={'1px solid rgb(235, 240, 246)'}>
                  <Td bgColor="lotionWhite" w={'200px'}>
                    우선 순위
                  </Td>
                  <Td>
                    <ComponentAndTextCheckEmpty value={priorities?.commute_priority ? priorities : defaultPriorities}>
                      <IndexingPie
                        dataSet={priorities?.commute_priority ? priorities : defaultPriorities}
                        width={125}
                        height={125}
                      />
                    </ComponentAndTextCheckEmpty>
                  </Td>
                </Tr>
                <Tr>
                  <Td bgColor="lotionWhite">회사 위치</Td>
                  <Td>
                    <TextWithCheckEmpty value={company_address}>{company_address}</TextWithCheckEmpty>
                  </Td>
                </Tr>
                <Tr>
                  <Td bgColor="lotionWhite">평소 출퇴근 교통수단</Td>
                  <Td>
                    <TextWithCheckEmpty value={commute_transportations}>
                      {commute_transportations?.map((trans) => commuteTransportationsTrans(trans)).join(', ')}
                    </TextWithCheckEmpty>
                  </Td>
                </Tr>
                <Tr>
                  <Td bgColor="lotionWhite">희망 통근 시간</Td>
                  <Td>
                    <TextWithCheckEmpty value={commute_times}>
                      {commute_times?.map((time) => commuteTimesTrans(time)).join(', ')}
                    </TextWithCheckEmpty>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </AccordionPanel>
        </AccordionItem>
        {/* 제안한 매물 */}
        <AccordionItem border="unset">
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left" fontWeight={700}>
                제안한 매물
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            {/* <Box p={0} fontSize={'14px'}> */}
            <Box>
              <VStack w="full">
                {requestRoomsData?.map((requestRoom, index) => {
                  const employee = requestRoom?.employee;
                  const custom_room_request = employee?.custom_room_request;
                  const recommended_room = custom_room_request?.recommended_room;
                  const room = recommended_room?.room;
                  const admin_check_status = recommended_room?.admin_check_status;
                  const roomRecommendationStatus = isRoomRecommendationStatus(admin_check_status);

                  if (isEmpty(room)) {
                    return null;
                  }

                  return (
                    <Box
                      bgColor={'#FBFBFB'}
                      key={index}
                      onClick={() => {
                        window.open(`/admin/manage/roomInfo/${room?._id}`);
                      }}
                      layerStyle={'hover_ECF1FF_animate'}
                      w="full"
                      border={'1px solid'}
                      borderColor={'#A0A0A0'}
                      borderRadius={'md'}
                      p={2}
                      boxShadow={'sm'}
                    >
                      <HStack spacing={4} justifyContent={'space-between'}>
                        {/* 매물 정보 */}
                        <HStack spacing={4} flexGrow={1} minW={'330px'} maxW={'330px'}>
                          <Box
                          // boxSize={'117px'}
                          >
                            <Image
                              boxSize={'80px'}
                              minW={'80px'}
                              // src={!isEmptyArray(room.pictures) ? room.pictures[0] : 'https://via.placeholder.com/80'}
                              src={
                                !isEmptyArray(room?.pictures) ? room?.pictures?.[0] : 'https://via.placeholder.com/80'
                              }
                              alt="이미지가 없습니다."
                              objectFit="cover"
                            />
                          </Box>
                          <VStack alignItems={'unset'} flexDir={'column'}>
                            <HStack fontSize={'11px'}>
                              <Box
                                bgColor={'#ECF1FF'}
                                color="zetaBlue"
                                fontWeight={'medium'}
                                px={1.5}
                                py={0.5}
                                borderRadius={'md'}
                              >
                                매물번호 {room?.code}
                              </Box>
                            </HStack>
                            <Flex w={'100%'} fontSize={'14px'} fontWeight={'normal'}>
                              <Text>
                                {room?.building?.address} {room?.building?.name && `(${room?.building?.name})`}
                                {room?.dong && `${room?.dong}동`} {room?.ho && `${room?.ho}호`}
                              </Text>
                            </Flex>
                            <HStack
                              w={'100%'}
                              flexWrap={'wrap'}
                              divider={
                                <Text color={'#414141'} mx={1}>
                                  /
                                </Text>
                              }
                              fontSize={'11px'}
                              spacing={2}
                            >
                              {room?.building?.building_form && (
                                <Text>{buildingFormTypeTrans(room?.building?.building_form)}</Text>
                              )}
                              {room?.room_num && <Text>방{room?.room_num}개</Text>}
                              {room?.dedicated_area && <Text>{room?.dedicated_area}m²</Text>}
                              {room?.floor && <Text>{room?.floor}층</Text>}
                              {room?.deposit && <Text>보증금 {staticThreeCommafy(room?.deposit)}원</Text>}
                              {room?.rent && <Text>월세 {staticThreeCommafy(room?.rent)}원</Text>}
                              {room?.management_fee && <Text>관리비 {staticThreeCommafy(room?.management_fee)}원</Text>}
                            </HStack>
                          </VStack>
                        </HStack>
                        {/* 상태 */}
                        <Flex flex={1} justify={'center'}>
                          <VStack>
                            <Tag
                              fontSize={'11px'}
                              variant={
                                roomRecommendationStatus === '전달 완료'
                                  ? 'complete_recommendation'
                                  : roomRecommendationStatus === '전달 대기'
                                  ? 'pending_recommendation'
                                  : roomRecommendationStatus === '반려'
                                  ? 'rejected_recommendation'
                                  : 'solid'
                              }
                            >
                              {roomRecommendationStatus}
                            </Tag>
                            <Tag variant={room ? 'current_empty' : 'current_filled'}>
                              {room?.is_move_in ? '현재 공실' : '거래 완료'}
                            </Tag>
                          </VStack>
                        </Flex>
                      </HStack>
                    </Box>
                  );
                })}
              </VStack>
            </Box>
            {/* </Box> */}
          </AccordionPanel>
        </AccordionItem>

        {/* 추가 요청 사항 */}
        <AccordionItem border="unset">
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left" fontWeight={700}>
                추가 요청 사항
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Box border={'1px solid rgb(235, 240, 246)'} p={4} fontSize={'14px'}>
              <CheckEmpty value={comment} shownAs={'추가 요청 사항이 없습니다'}>
                {comment}
              </CheckEmpty>
            </Box>
          </AccordionPanel>
        </AccordionItem>
      </>
    ) : (
      // 순매칭
      <>
        {/* 제안한 매물 */}
        <AccordionItem border="unset">
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left" fontWeight={700}>
                제안한 매물
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            {/* <Box p={0} fontSize={'14px'}> */}
            <Box>
              <VStack w="full">
                {requestRoomsData?.map((requestRoom, index) => {
                  const employee = requestRoom?.employee;
                  const custom_room_request = employee?.custom_room_request;
                  const recommended_room = custom_room_request?.recommended_room;
                  const room = recommended_room?.room;
                  const admin_check_status = recommended_room?.admin_check_status;
                  const roomRecommendationStatus = isRoomRecommendationStatus(admin_check_status);

                  if (isEmptyObject(room)) {
                    return null;
                  }

                  // onClick={() => {
                  //       window.open(`/admin/manage/roomInfo/${room._id}`);
                  //     }}

                  return (
                    <Box
                      bgColor={'#FBFBFB'}
                      key={index}
                      onClick={() => {
                        window.open(`/admin/manage/roomInfo/${room?._id}`);
                      }}
                      layerStyle={'hover_ECF1FF_animate'}
                      w="full"
                      border={'1px solid'}
                      borderColor={'#A0A0A0'}
                      borderRadius={'md'}
                      p={2}
                      boxShadow={'sm'}
                    >
                      <HStack spacing={4} justifyContent={'space-between'}>
                        {/* 매물 정보 */}
                        <HStack spacing={4} flexGrow={1} minW={'330px'} maxW={'330px'}>
                          <Box
                          // boxSize={'117px'}
                          >
                            <Image
                              boxSize={'80px'}
                              minW={'80px'}
                              src={
                                !isEmptyArray(room?.pictures) ? room?.pictures?.[0] : 'https://via.placeholder.com/80'
                              }
                              alt="이미지가 없습니다."
                              objectFit="cover"
                            />
                          </Box>
                          <VStack alignItems={'unset'} flexDir={'column'}>
                            <HStack fontSize={'11px'}>
                              <Box
                                bgColor={'#ECF1FF'}
                                color="zetaBlue"
                                fontWeight={'medium'}
                                px={1.5}
                                py={0.5}
                                borderRadius={'md'}
                              >
                                매물번호 {room?.code}
                              </Box>
                            </HStack>
                            <Flex w={'100%'} fontSize={'14px'} fontWeight={'normal'}>
                              <Text>
                                {room?.building?.address} {room?.building?.name && `(${room?.building?.name})`}
                                {room?.dong && `${room?.dong}동`} {room?.ho && `${room.ho}호`}
                              </Text>
                            </Flex>
                            <HStack
                              w={'100%'}
                              flexWrap={'wrap'}
                              divider={
                                <Text color={'#414141'} mx={1}>
                                  /
                                </Text>
                              }
                              fontSize={'11px'}
                              spacing={2}
                            >
                              {room?.building?.building_form && (
                                <Text>{buildingFormTypeTrans(room?.building?.building_form)}</Text>
                              )}
                              {room?.room_num && <Text>방{room?.room_num}개</Text>}
                              {room?.dedicated_area && <Text>{room?.dedicated_area}m²</Text>}
                              {room?.floor && <Text>{room?.floor}층</Text>}
                              {room?.deposit && <Text>보증금 {room?.deposit}원</Text>}
                              {room?.rent && <Text>월세 {room?.rent}원</Text>}
                              {room?.management_fee && <Text>관리비 {room?.management_fee}원</Text>}
                            </HStack>
                          </VStack>
                        </HStack>
                        {/* 상태 */}
                        <Flex flex={1} justify={'center'}>
                          <VStack>
                            <Tag
                              fontSize={'11px'}
                              variant={
                                roomRecommendationStatus === '전달 완료'
                                  ? 'complete_recommendation'
                                  : roomRecommendationStatus === '전달 대기'
                                  ? 'pending_recommendation'
                                  : roomRecommendationStatus === '반려'
                                  ? 'rejected_recommendation'
                                  : 'solid'
                              }
                            >
                              {roomRecommendationStatus}
                            </Tag>
                            <Tag variant={room ? 'current_empty' : 'current_filled'}>
                              {room?.is_move_in ? '현재 공실' : '거래 완료'}
                            </Tag>
                          </VStack>
                        </Flex>
                      </HStack>
                    </Box>
                  );
                })}
              </VStack>
            </Box>
            {/* </Box> */}
          </AccordionPanel>
        </AccordionItem>
        {/* 필수요청사항 */}
        <AccordionItem border="unset">
          <AccordionButton>
            <Box flex="1" textAlign="left" fontWeight={700}>
              필수 요청사항
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel w={'100%'}>
            <Table variant="all_line_silver_12px" size="sm" p={0} fontSize={'12px'}>
              <Tbody>
                <Tr>
                  <Td bgColor="lotionWhite">희망 입주일 / 계약 기간</Td>
                  <Td colSpan={2}>
                    요청일로부터 {within_week}주내 / {how_long_month}개월
                  </Td>
                </Tr>
                <Tr>
                  <Td bgColor="lotionWhite">보증금, 월세 예산</Td>
                  <Td colSpan={2}>
                    보증금 {deposits?.[0]}~{deposits?.[1]}원 / 월세 {rents?.[0]}~{rents?.[1]}원
                  </Td>
                </Tr>
                <Tr>
                  <Td bgColor="lotionWhite">선호하는 지역</Td>
                  <Td colSpan={2}>
                    <TextWithCheckEmpty value={regions}>
                      {extractAddressArrFromRegionArr(regions || [])
                        .map((region) => region)
                        .join(', ')}
                    </TextWithCheckEmpty>
                  </Td>
                </Tr>
                <Tr>
                  <Td bgColor="lotionWhite" rowSpan={3}>
                    선호하는 유형
                  </Td>
                  <Td maxW="10px">건물 형태</Td>
                  <Td>
                    <TextWithCheckEmpty value={building_forms}>
                      {building_forms?.map((form) => buildingFormTypeTrans(form)).join(', ')}
                    </TextWithCheckEmpty>
                  </Td>
                </Tr>
                <Tr>
                  <Td maxW="10px">층수</Td>
                  <Td>
                    <TextWithCheckEmpty value={floors}>
                      {floors?.map((floor) => floorTypeTrans(floor)).join(', ')}
                    </TextWithCheckEmpty>
                  </Td>
                </Tr>
                <Tr>
                  <Td maxW="10px">방 구조</Td>
                  <Td>
                    <TextWithCheckEmpty value={room_living_types}>
                      {room_living_types?.map((living) => roomLivingTypeTrans(living)).join(', ')}
                    </TextWithCheckEmpty>
                  </Td>
                </Tr>
                <Tr>
                  <Td bgColor="lotionWhite">추가 옵션</Td>
                  <Td colSpan={2}>
                    {is_option ? (
                      <Text>{fullOptionTrans(is_option)}</Text>
                    ) : (
                      <TextWithCheckEmpty value={more_options}>
                        {more_options?.map((option) => moreOptionsTrans(option)).join(', ')}
                      </TextWithCheckEmpty>
                    )}
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </AccordionPanel>
        </AccordionItem>
        {/* 개별 맞춤 조건 */}
        <AccordionItem border="unset">
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left" fontWeight={700}>
                {tenant_name} 님의 개별 맞춤 조건
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Table variant="all_line_silver_12px" size="sm" p={0}>
              <Tbody>
                <Tr borderTop={'1px solid rgb(235, 240, 246)'}>
                  <Td bgColor="lotionWhite" w={'200px'}>
                    우선 순위
                  </Td>
                  <Td>
                    <ComponentAndTextCheckEmpty value={priorities?.commute_priority ? priorities : defaultPriorities}>
                      <IndexingPie
                        dataSet={priorities?.commute_priority ? priorities : defaultPriorities}
                        width={125}
                        height={125}
                      />
                    </ComponentAndTextCheckEmpty>
                  </Td>
                </Tr>
                <Tr>
                  <Td bgColor="lotionWhite">회사 위치</Td>
                  <Td>
                    <TextWithCheckEmpty value={company_address}>{company_address}</TextWithCheckEmpty>
                  </Td>
                </Tr>
                <Tr>
                  <Td bgColor="lotionWhite">평소 출퇴근 교통수단</Td>
                  <Td>
                    <TextWithCheckEmpty value={commute_transportations}>
                      {commute_transportations?.map((trans) => commuteTransportationsTrans(trans)).join(', ')}
                    </TextWithCheckEmpty>
                  </Td>
                </Tr>
                <Tr>
                  <Td bgColor="lotionWhite">희망 통근 시간</Td>
                  <Td>
                    <TextWithCheckEmpty value={commute_times}>
                      {commute_times?.map((time) => commuteTimesTrans(time)).join(', ')}
                    </TextWithCheckEmpty>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </AccordionPanel>
        </AccordionItem>
        {/* 추가 요청 사항 */}
        <AccordionItem border="unset">
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left" fontWeight={700}>
                추가 요청 사항
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Box border={'1px solid rgb(235, 240, 246)'} p={4} fontSize={'14px'}>
              <CheckEmpty value={comment} shownAs={'추가 요청 사항이 없습니다'}>
                {comment}
              </CheckEmpty>
            </Box>
          </AccordionPanel>
        </AccordionItem>
      </>
    );

  return (
    <Box h={'full'} w={'full'} minW="580px">
      {/* 상단 */}
      <Box px="6" py="3">
        <VStack alignItems={'unset'} spacing={1}>
          <Tag
            variant={status === 'contracted' ? 'orange' : status === 'moving_in' ? 'orange' : 'green'}
            w="fit-content"
          >
            {requestStatusTrans(status)}
          </Tag>
          <Box fontSize={'24px'} fontWeight={400} display={'inline-flex'}>
            <Link fontSize={'24px'} href={`${base}${tenant_id}`} isExternal>
              {tenant_name}
            </Link>
            님의 매물 요청 사항
          </Box>
          <Box fontSize={'12px'} color={'#414141'} display={'inline-flex'}>
            <Box mr={2}>
              <Text textStyle={'bold12'} display={'inline-flex'} mr={1}>
                요청 번호
              </Text>
              {code}
            </Box>
            <Box>
              <Text textStyle={'bold12'} display={'inline-flex'} mr={1}>
                제출 일자
              </Text>
              {datedetaildotformat_mm(createdAt)}
            </Box>
          </Box>
        </VStack>
      </Box>
      <Divider borderColor="borderGray" />
      <Box px={3} py={2} h={'full'} w={'full'}>
        <Accordion defaultIndex={[0, 1, 2, 3]} allowMultiple h={'full'} w={'full'} reduceMotion>
          <Items />
        </Accordion>
      </Box>
    </Box>
  );
};

export default RequestRoomDetailTables;

import { useReducer, useState } from 'react';
import * as Util from '../utils/Util';
export const useIsChecked = (arg: any) => {
  return useReducer<any>(
    (pre: any, curr: any) => {
      return !Util.parseBoolean(pre);
    },
    arg ? true : false,
  );
};

export const useLocalParams = (): {
  search: any;
  setSearch: any;
  page: any;
  setPage: any;
  limit: any;
  setLimit: any;
} => {
  const [search, setSearch] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(100);
  return {
    search,
    setSearch,
    page,
    setPage,
    limit,
    setLimit,
  };
};

import { Center, Box, Text, Image, Flex } from '@chakra-ui/react';
import React from 'react';
import { RouteProps, useNavigate } from 'react-router-dom';
import { useLogout } from '../../../lib/common/utils/firebase/init';
import { RightArrowIcon } from '../../../statics/common/icons';
import logo_blue from '../../../statics/common/images/logo-blue.png';

const Notfoundpage: React.FC<RouteProps> = () => {
  const navigate = useNavigate();
  const _logout = useLogout();
  return (
    <Center w="full" h="full" flexDir={'column'}>
      <Flex align={'end'} mb={8}>
        <Image src={logo_blue} />
        <Text fontWeight={500} fontSize={'22px'} lineHeight={1} color={'zetaBlue'}>
          404
        </Text>
      </Flex>
      <Box>
        <Text fontWeight={500} fontSize={'22px'}>
          페이지가 존재하지 않습니다.
        </Text>
      </Box>
      <Center fontSize={'20px'} mt={4} onClick={_logout} cursor={'pointer'} _hover={{ opacity: 0.7 }}>
        <Text>로그아웃하기</Text>
        <RightArrowIcon />
      </Center>
    </Center>
  );
};

export default Notfoundpage;

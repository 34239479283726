import { Box, Button, Flex, HStack, Input, Text } from '@chakra-ui/react';
import React, { useRef, useState } from 'react';

interface FileUploaderProps {
  testFormik: any;
}
const FileUploader: React.FC<FileUploaderProps> = ({ testFormik }) => {
  const [fileName, setFileName] = useState('');
  const [lastUpdatedDate, setLastUpdatedDate] = useState<any>();

  const hiddenFileInput = useRef(null);
  const handleClick = (e: any) => {
    (hiddenFileInput.current as any).click();
  };
  const handleChange = (e: any) => {
    const fileUploaded = e.target.files[0];
    console.log('fileUploaded => ', fileUploaded);

    setFileName(fileUploaded.name);
    setLastUpdatedDate(fileUploaded.lastModifiedDate);
  };

  console.log('fileName: ', fileName);
  console.log('lastUpdatedDate: ', lastUpdatedDate);

  return (
    <Flex align={'center'}>
      <Box w={'130px'}>
        <Text>사업자 등록증</Text>
      </Box>
      <HStack w={'270px'}>
        <Flex
          cursor="pointer"
          align={'center'}
          pl={'16px'}
          borderRadius={'md'}
          bgColor="whiteGray"
          w={'300px'}
          h={'33px'}
          onClick={handleClick}
          boxSizing="border-box"
          _hover={{
            transition: '0.02s',
            outline: '2px solid #183377',
          }}
          userSelect={'none'}
        >
          <Text fontSize={'14px'} color="highGray">
            {fileName ? fileName : '파일이 없습니다.'}
          </Text>
        </Flex>
        <Button
          variant={'basic'}
          size={'sm'}
          borderRadius={'md'}
          type="button"
          h={'33px'}
          w={'70px'}
          onClick={handleClick}
        >
          첨부
        </Button>
        <Input
          id="business_registration"
          type="file"
          ref={hiddenFileInput}
          // onChange={handleChange}
          {...testFormik.getFieldProps('business_registration')}
          onChange={(e) => {
            handleChange(e);
            testFormik.handleChange(e);
          }}
          style={{ display: 'none' }}
        />
      </HStack>
    </Flex>
  );
};

export default FileUploader;

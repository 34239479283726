import {
  Box,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import Slider from 'react-slick';

interface ImageSliderModalProps {
  disabled?: boolean;
  titleAndPictures?: {
    title?: string;
    pictures?: string[] | undefined;
  };
}

const ImageSliderModal: React.FC<ImageSliderModalProps> = ({ disabled = false, titleAndPictures, children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleModalOpen = () => {
    if (!disabled) {
      onOpen();
    }
  };

  /**Arrow */
  const SamplePrevArrow = (props: any) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: 'block', left: '20px', zIndex: 10, transform: 'scale(2)' }}
        onClick={onClick}
      />
    );
  };

  const SampleNextArrow = (props: any) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: 'block', right: '20px', zIndex: 10, transform: 'scale(2)' }}
        onClick={onClick}
      />
    );
  };

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
  };

  return (
    <>
      <Box onClick={handleModalOpen} cursor={'pointer'}>
        {children}
      </Box>
      <Modal isOpen={isOpen} onClose={onClose} isCentered size="7xl">
        <ModalOverlay />
        <ModalContent boxShadow={'none'}>
          {/* <ModalContent> */}
          <ModalCloseButton size={'lg'} autoFocus={false} />
          <ModalBody>
            {titleAndPictures?.title && (
              <Text textStyle={'bold25'} fontSize={'32px'} w="max">
                {titleAndPictures.title}
              </Text>
            )}
            <Slider {...sliderSettings} className="room-detail-pictures-slider">
              {titleAndPictures?.pictures?.map((picture, index) => (
                <Image
                  key={index}
                  cursor={'pointer'}
                  src={picture}
                  fallbackSrc="https://via.placeholder.com/600"
                  alt={'오류'}
                  w={'full'}
                  maxH={'calc(100vh - 200px)'}
                  objectFit={'contain'}
                  borderRadius={'md'}
                />
              ))}
            </Slider>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ImageSliderModal;

import { Box, Collapse, Flex, HStack, StackDivider, Table, Tbody, Text, Tr, VStack } from '@chakra-ui/react';
import { Field, useFormikContext } from 'formik';
import { CustomTd } from '../type';
import { SingleDatepicker } from '../../../../Calender';
import { FCheckboxButtonGroup } from '../../../../Checkbox/base';
import { CustomInput } from '../../../../Input';
import { FRadioButtonGroup } from '../../../../Radio';
import ColivingRoomType from '../../../../SelectBox/ColivingRoomTypeSelectBox';
import { common_items_checkbox, individual_items_checkbox } from '../../../../../../statics/common/data/checkbox';
import { move_in_radio } from '../../../../../../statics/common/data/radio';
import { contract_period } from '../../../../../../statics/common/data/selectbox';
import SelectBox from '../../../../../agent/SelectBox/base';

const RoomTypeSelect = () => {
  const formik = useFormikContext<any>();
  const { setFieldValue, values } = formik;
  return (
    <Flex flexDir={'column'} w={'full'} alignItems={'stretch'} bgColor={'#F8F8F8'} borderRadius={'md'}>
      <Flex flexDir={'column'} mx={6} my={5}>
        <Box fontSize={'18px'}>
          <Text fontWeight={'bold'} display={'inline'}>
            룸 타입&nbsp;&nbsp;
          </Text>
          <Text display={'inline'}>
            룸 타입별로 매물 정보를 입력할 수 있습니다. 정보를 입력할 타입부터 선택해주세요.
          </Text>
        </Box>
        <Box mt={2}>
          <ColivingRoomType />
        </Box>
      </Flex>
    </Flex>
  );
};

export default RoomTypeSelect;

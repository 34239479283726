import {
  background,
  Box,
  Button,
  Flex,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Table,
  Tbody,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { Field, FieldArray, Formik, useFormik, useFormikContext, FormikProps } from 'formik';
import ValidationWithTouch from '../../FormikValidation/ValidationWithTouch';
import { CustomInput } from '../../Input';
import { TrContainer } from '../../Table/Tr';
import { CheckCircleIcon } from '../../../../statics/common/icons';
import SelectBox from '../../SelectBox/EstatesSelectBox';
import { initialValuesProps } from '../RegisterNewHostModal';
import { bank_data } from '../../../../statics/common/data/bank';
import { useVerifyAccount } from '../../../../lib/common/hooks/mutation';
import { verifyAccountDuplicated } from '../../../../statics/common/data/toast';
import { isUndefined } from 'lodash';
import { useMemo, useState } from 'react';
import { useAddUserAccount } from '../../../../lib/common/hooks/mutation';
import * as Util from '../../../../lib/common/utils/Util';
import { currentParamsObject } from '../../../../lib/common/utils/base';

const initialValues = {
  account_owner_name: '',
  account_number: '',
  bank_code: '',
} as {
  account_owner_name: string;
  account_number: string;
  bank_code: string;
};

interface PropsType {
  isOpen: any;
  onClose: any;
}

export const AddAccountModal: React.FC<PropsType> = ({ isOpen, onClose }) => {
  let params = currentParamsObject(false);
  let id = params?.id;
  const { mutateAsync: AddUserAccount } = useAddUserAccount();
  const { mutateAsync: verifyAccount, isLoading } = useVerifyAccount();
  const formik = useFormik({
    initialValues,
    onSubmit: async (values: any, formikHelpers) => {
      await AddUserAccount({
        user_id: id,
        account: {
          account_number: values.account_number,
          account_owner_name: values.account_owner_name,
          bank_code: values.bank_code,
        } as any,
      });

      formikHelpers.resetForm();
      await onClose();
    },
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside" isCentered size={'xl'}>
      <ModalOverlay />
      <ModalContent>
        {/* <ModalHeader>Header</ModalHeader> */}
        <ModalBody>
          {
            <Flex flexDir={'column'} m={10} alignItems={'flex-start'}>
              <HStack mb={5}>
                <CheckCircleIcon w={8} h={8} />
                <Text fontSize={'23px'}>계좌 추가</Text>
              </HStack>
              <Box mb={5}>
                <Text fontSize={'16px'}>추가하실 임대인의 계좌정보를 입력해 주세요.</Text>
              </Box>
              <Table w={'100%'}>
                <Tbody w={'100%'}>
                  <TrContainer title="은행" textStyle={'medium14'}>
                    <SelectBox
                      placeholder="은행 선택"
                      fontSize="12px"
                      h="32px"
                      onChange={(e) => {
                        formik.setFieldValue('bank_code', e.target.value);
                      }}
                    >
                      {bank_data.data.map((option) => (
                        <option key={option.bankId} value={option.bankId}>
                          {option.bankName}
                        </option>
                      ))}
                    </SelectBox>
                  </TrContainer>
                  <TrContainer title="계좌번호" textStyle={'medium14'}>
                    <CustomInput
                      px={4}
                      placeholder={"'-'없이 숫자만 입력해 주세요."}
                      onChange={(e) => {
                        formik.setFieldValue('account_number', e.target.value);
                      }}
                      value={formik.values.account_number}
                    ></CustomInput>
                  </TrContainer>
                  <TrContainer title="예금주명" textStyle={'medium14'}>
                    <HStack>
                      <CustomInput
                        px={4}
                        placeholder="예금주명을 입력해 주세요."
                        onChange={(e) => {
                          formik.setFieldValue('account_owner_name', e.target.value);
                        }}
                        value={formik.values.account_owner_name}
                      ></CustomInput>
                      <Button
                        variant="basic"
                        style={{ height: '32px' }}
                        onClick={() => {
                          verifyAccount({
                            account_number: formik.values.account_number,
                            account_owner_name: formik.values.account_owner_name,
                            bank_code: formik.values.bank_code,
                          });
                        }}
                      >
                        실계좌 인증
                      </Button>
                    </HStack>
                  </TrContainer>
                </Tbody>
              </Table>
              <HStack w="full" justify={'center'} mt={4}>
                <Button variant={'gray'} onClick={onClose} w="114px">
                  취소
                </Button>
                <Button variant="basic" onClick={formik.submitForm}>
                  계좌 등록하기
                </Button>
              </HStack>
            </Flex>
          }
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

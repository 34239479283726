import { Tbody, Td, Tr } from '@chakra-ui/react';

interface RooonTableType {
  tableText?: string;
  color?: string;
  title: string;
  subTitle?: string;
  data: string[] | boolean | number | string | undefined;
  subData?: string[] | boolean | number | string | undefined;
  width?: string;
}

const QuarterTable = (props: RooonTableType) => {
  return (
    <>
      <Tbody fontSize={'14px'}>
        <Tr>
          <Td fontWeight={'500'} bgColor={'#f8f8f8'} width={'25%'}>
            {props.title}
          </Td>
          <Td width={'25%'}>{props.data}</Td>
          <Td fontWeight={'500'} bgColor={props.color} width={props.width}>
            {props.subTitle}
          </Td>
          <Td width={props.width}>{props.subData}</Td>
        </Tr>
      </Tbody>
    </>
  );
};

export default QuarterTable;

import { Box, Center, Flex, HStack, StackDivider, Text, VStack } from '@chakra-ui/react';
import { logout, useLogout } from '../../../lib/common/utils/firebase/init';
import { LockClosedIcon, LockIcon } from '../../../statics/common/icons';
import ManRaisingHand from '../../../statics/common/images/man-raising-hand-light-skin-tone.png';
import OfficeBuilding from '../../../statics/common/images/office-building.png';
import LockedWithKey from '../../../statics/common/images/locked_with_key.png';
import { Container } from '../Container/CommingSoon';

const SelectionPage = () => {
  const _logout = useLogout();
  return (
    <Center w="full" h="90%">
      <Flex
        flexDir={'column'}
        bg="white"
        borderRadius={'lg'}
        boxShadow={'2px 2px 20px 0px #DDDDDD'}
        w={'500px'}
        h="430px"
        alignItems={'flex-start'}
      >
        <Box
          w={'full'}
          borderBottomColor={'borderGray'}
          boxShadow={'rgba(0, 0, 0, 0.02) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 2px 3px -2px'}
          //   px={5}
          py={12}
        >
          <HStack justifyContent={'center'}>
            <LockIcon w={6} h={6} />
            <Text fontWeight={'bold'} fontSize={'24px'}>
              계정 정보
            </Text>
          </HStack>
        </Box>
        <VStack w="full" h="full" spacing={0} divider={<StackDivider borderColor="gray.200" />}>
          <Container title="고객사 정보" to="/company/infos/selection/company" image={OfficeBuilding} />
          <Container block title="담당자 설정" to="/company/infos/selection/user" image={ManRaisingHand} />
          <Container
            // block
            title="계정 설정"
            to="/company/infos/selection/account"
            image={LockedWithKey}
          />
          <HStack w={'full'} justifyContent={'space-between'} px={'45px'} py={6}>
            <Text
              fontWeight={'medium'}
              color={'#c4c4c4'}
              textDecorationLine={'underline'}
              textUnderlineOffset={'2px'}
              cursor={'pointer'}
              onClick={_logout}
              lineHeight={'18px'}
              pl={1}
            >
              로그아웃
            </Text>
          </HStack>
        </VStack>
      </Flex>
    </Center>
  );
};

export default SelectionPage;

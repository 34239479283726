import { Input, InputProps } from '@chakra-ui/react';
import { getYear, getMonth, getDate } from 'date-fns';
import { useSearchParams } from 'react-router-dom';
import { currentParamsObject } from '../../../../lib/common/utils/base';
import { decimalize } from '../../../../lib/common/utils/digitformatter';

// interface SetYearAndMonthParamsProps extends InputProps {
//   test?: string;
// }

const SetYearAndMonthParams: React.FC<InputProps> = ({ ...props }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { year, month, ...params } = currentParamsObject(false);
  const now = new Date();
  const value_year = year ? year : getYear(now);
  const value_month = month ? month : decimalize(getMonth(now) + 1);
  const value_now = value_year + '-' + value_month;

  return (
    <Input
      type="month"
      h={'36px'}
      bg="white"
      w={'200px'}
      min={'2007-01'}
      max={'2027-01'}
      value={value_now}
      onChange={(e) => {
        const { value } = e.target;
        const [year, month] = value.split('-');
        console.log(year, month);
        searchParams.set('year', year);
        searchParams.set('month', month);
        setSearchParams(searchParams);
      }}
      {...props}
    />
  );
};

export default SetYearAndMonthParams;

import { Box, Button, Flex, Link, Table, Tbody, Text, Thead, Tr, useDisclosure } from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import { GTitleItem } from '../../GridItem';
import QueryParamsLayout from '../../../../layouts/common/main/QueryParamsLayout';
import QueryParamsModalLayout from '../../../../layouts/common/main/QueryParamsModalLayout';
import ExtendRequestForm from '../../../subscriber/Modals/ExtendRequestForm';
import ExtraRequestForm from '../../../subscriber/Modals/ExtraRequestForm';
import NewRequestForm from '../../../subscriber/Modals/NewRequestForm';
import WithdrawalRequestForm from '../../../subscriber/Modals/WithdrawalRequestForm';
import { WTd } from '../../Table/Td';
import { WTh } from '../../Table/Th';
import TextLink from '../../Link/TextLink';
import { useGetAllUsersHouseHuntingRequests } from '../../../../lib/admin/hooks/query';
import { pathOfUserDetailPage, useNavigateToUserDetailPage } from '../../../../lib/common/utils/utils';
import { Service } from '../../../../lib/common/utils/axios/types';
import { currentParamsObject, ToAllFilledObj } from '../../../../lib/common/utils/base';
import { datedotformat } from '../../../../lib/common/utils/dateformatter';
import { decimalize } from '../../../../lib/common/utils/digitformatter';
import { request_type_transition } from '../../../../pages/UserType/subscriber/manage/employee/UsageLists';
import Loading from '../../Loading';

interface Props {
  queryKeys: Array<string>;
}

const CompanyAllUsersHouseHuntingRequestModal: React.FC<Props> = ({ queryKeys }) => {
  const [clickedFormUserId, setClickedFormUserId] = useState('');
  const [clickedFormServiceId, setClickedFormServiceId] = useState('');
  const [onNavigate] = useNavigateToUserDetailPage();

  const queryValues = useMemo(() => {
    return queryKeys.map((queryKey) => {
      const { [queryKey]: queryValue } = currentParamsObject(false);
      return queryValue;
    });
  }, [queryKeys]);

  const company_id = queryValues[0];

  const _params = currentParamsObject();
  const params = ToAllFilledObj(_params);

  const res = useGetAllUsersHouseHuntingRequests(company_id, {
    options: {
      enabled: !!company_id,
    },
    axiosConfig: {
      params,
    },
  }) as any;

  const {
    isOpen: isNewRequestFormOpen,
    onOpen: onNewRequestFormOpen,
    onClose: onNewRequestFormClose,
  } = useDisclosure();
  const {
    isOpen: isExtraRequestFormOpen,
    onOpen: onExtraRequestFormOpen,
    onClose: onExtraRequestFormClose,
  } = useDisclosure();
  const {
    isOpen: isExtendRequestFormOpen,
    onOpen: onExtendRequestFormOpen,
    onClose: onExtendRequestFormClose,
  } = useDisclosure();
  const {
    isOpen: isWithdrawalRequestFormOpen,
    onOpen: onWithdrawalRequestFormOpen,
    onClose: onWithdrawalRequestFormClose,
  } = useDisclosure();

  const { isLoading } = res;
  if (isLoading) return <Loading />;
  return (
    <QueryParamsModalLayout
      queryKeys={queryKeys}
      modalProps={{
        scrollBehavior: 'outside',
        isCentered: true,
      }}
    >
      <GTitleItem
        upperSection={
          <Flex justifyContent={'space-between'} alignItems={'center'} cursor={'pointer'}>
            <Text fontSize={'18px'} fontWeight={'medium'}>
              {`전체 이용 신청 내역(${res.data?.result ? decimalize(res.data?.result?.length) : '00'})`}
            </Text>
          </Flex>
        }
        upperProps={{
          mb: 2,
        }}
        w="full"
        // h="full"
        h="calc(100vh - 128px)"
        minH="calc(100vh - 128px)"
        boxShadow={'unset'}
      >
        <Box w={'full'} h="full" px={5} pb={5}>
          <QueryParamsLayout res={res}>
            <Table variant="head_silverbg_toplining">
              <Thead bgColor={'#F8F8F8'} borderColor={'gray.100'}>
                <Tr>
                  <WTh borderR>신청일</WTh>
                  <WTh borderR>신청 항목</WTh>
                  <WTh borderR>이름</WTh>
                  <WTh borderR>이용 기간</WTh>
                  <WTh borderR>상태 및 상세보기</WTh>
                </Tr>
              </Thead>
              <Tbody>
                {res.data?.result.map((service: Service, index: any) => {
                  const base = pathOfUserDetailPage(service.employee.tenant_status as any);

                  return (
                    <Tr h={'51px'} key={service.employee.service._id}>
                      <WTd borderR>{datedotformat(service.employee.service.createdAt)}</WTd>
                      <WTd borderR>{request_type_transition[service.employee.service.request_type]}</WTd>
                      <WTd borderR>
                        <TextLink href={`${base}${service.employee._id}`} target={'_blank'}>
                          {service.employee.user_name}
                        </TextLink>
                      </WTd>
                      <WTd borderR>
                        {service.employee.service.how_long_month
                          ? `${service.employee.service.how_long_month}개월`
                          : '-'}
                      </WTd>
                      <WTd borderR>
                        {service.employee.service.check_status === 'deprecated' ? (
                          <Text>새로운 신청으로 대체되었습니다.</Text>
                        ) : (
                          <>
                            <Button
                              variant={
                                service.employee.service.check_status === 'pending' ? 'grayBorder_blackFont' : 'text'
                              }
                              textDecorationLine={
                                service.employee.service.check_status === 'pending' ? 'unset' : 'underline'
                              }
                              textUnderlineOffset={'2px'}
                              h="34px"
                              onClick={() => {
                                setClickedFormUserId(service.employee._id);
                                setClickedFormServiceId(service.employee.service._id);
                                service.employee.service.request_type === 'new'
                                  ? onNewRequestFormOpen()
                                  : service.employee.service.request_type === 'changing'
                                  ? onExtraRequestFormOpen()
                                  : service.employee.service.request_type === 'extending'
                                  ? onExtendRequestFormOpen()
                                  : service.employee.service.request_type === 'withdrawal'
                                  ? onWithdrawalRequestFormOpen()
                                  : () => console.log('신청서 보기 오류');
                              }}
                            >
                              <Text>
                                {service.employee.service.check_status === 'pending'
                                  ? '신청서 보기'
                                  : service.employee.service.check_status === 'approved'
                                  ? '승인'
                                  : service.employee.service.check_status === 'cancelled'
                                  ? '취소'
                                  : service.employee.service.check_status === 'rejected'
                                  ? '반려'
                                  : service.employee.service.check_status === 'withdrawn'
                                  ? '철회'
                                  : ''}
                              </Text>
                            </Button>
                          </>
                        )}
                      </WTd>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </QueryParamsLayout>
          {/* {service.employee.service.request_type === 'new' ? ( */}
          <NewRequestForm
            isOpen={isNewRequestFormOpen}
            onClose={onNewRequestFormClose}
            service_id={clickedFormServiceId}
            user_id={clickedFormUserId}
          />
          {/* ) : service.employee.service.request_type === 'changing' ? ( */}
          <ExtraRequestForm
            isOpen={isExtraRequestFormOpen}
            onClose={onExtraRequestFormClose}
            service_id={clickedFormServiceId}
            user_id={clickedFormUserId}
          />
          {/* ) : service.employee.service.request_type === 'extending' ? ( */}
          <ExtendRequestForm
            isOpen={isExtendRequestFormOpen}
            onClose={onExtendRequestFormClose}
            service_id={clickedFormServiceId}
            user_id={clickedFormUserId}
          />
          {/* ) : service.employee.service.request_type === 'withdrawal' ? ( */}
          <WithdrawalRequestForm
            isOpen={isWithdrawalRequestFormOpen}
            onClose={onWithdrawalRequestFormClose}
            service_id={clickedFormServiceId}
            user_id={clickedFormUserId}
          />
        </Box>
      </GTitleItem>
    </QueryParamsModalLayout>
  );
};

export default CompanyAllUsersHouseHuntingRequestModal;

import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import { useGetCompanyManager } from '../../../../lib/admin/hooks/query';
import { pathOfUserDetailPage } from '../../../../lib/common/utils/utils';
import { Notification, TenantStatus } from '../../../../lib/common/utils/axios/types';
import { useGetUserType } from '../../../../lib/common/hooks/query';
import { TodayYesterdayFormat } from '../../../../lib/common/utils/base';
import { NotiIconMatch } from '../../../../lib/subscriber/utils/utils';
import { isUndefined } from 'lodash';

interface CardProps {
  info: Notification;
}

const Card: React.FC<CardProps> = ({ info }) => {
  // different card per type
  const userId = ((info as any)?.data as any)?.user_id;
  const isChecked = (info as any)?.is_checked;

  const { refetch } = useGetCompanyManager(userId || '', {
    options: {
      enabled: false,
    },
  });

  const { data, isLoading } = useGetUserType();
  if (data === undefined || isLoading) {
    console.log('loading...');
    return null;
  }
  const company_type = data?.company_type;

  const handleNavigateUserDetailPage = async () => {
    await refetch().then((result) => {
      const status = result.data?.employee.tenant_status as TenantStatus;
      const userId = result.data?.employee._id;
      const base = pathOfUserDetailPage(status);
      if (userId) {
        window.open(`${base}${userId}`);
      }
    });
  };

  const handleClickCard = async () => {
    const data = (info as any).data;

    if (isUndefined(company_type)) {
      return;
    }

    // if (company_type === 'admin') {

    // } else if ()

    /* 1. company_type에 따라 기능 분리 */
    switch (company_type) {
      case 'admin':
        // if (data.notification_type === 'user_approval_request') {
        // }
        if (data.user_id) {
          await handleNavigateUserDetailPage();
        } else if (data.subscription_contract_id) {
          window.open(`/admin/manage/companies/new`);
        }
        break;
      case 'agency':
        break;
      case 'subscriber':
        break;

      default:
        return;
    }
  };

  return (
    <Box
      layerStyle={company_type === 'admin' ? 'hover_opacity_animate' : ''}
      _hover={{
        opacity: 1,
        bgColor: 'rgba(251,251,251, 0.1)',
      }}
      bgColor={'#FBFBFB'}
      borderColor={isChecked ? 'gray.100' : 'gray.400'}
      borderRadius={'md'}
      borderWidth={1}
      color={isChecked ? 'gray.300' : '#414141'}
      w="full"
      onClick={handleClickCard}
    >
      <Box p={3}>
        <HStack align={'start'}>
          <Box display={'inline-block'}>{NotiIconMatch(info.notification_type)}</Box>
          <VStack w="full" align={'start'}>
            <HStack w="full" justify={'space-between'}>
              <Box>
                {/* 제목 */}
                <Text fontSize={'12px'} fontWeight={'bold'}>
                  {info.title}
                </Text>
              </Box>
              {/* 날짜 */}
              <Box>
                <Text fontSize={'12px'} fontWeight={'medium'} color="#C4C4C4">
                  {TodayYesterdayFormat(info.createdAt)}
                </Text>
              </Box>
            </HStack>
            <Box>
              <Text fontSize={'14px'}>{info.description}</Text>
            </Box>
            {/* <Flex w="full" justify={'end'}>
              <Text
                textDecor={'underline'}
                color={'zetaBlue'}
                textUnderlineOffset={'1px'}
                cursor={'pointer'}
                fontSize={'12px'}
                fontWeight={'medium'}
                onClick={() => {
                  console.log('더 보기');
                }}
              >
                4개 더 보기
              </Text>
            </Flex> */}
          </VStack>
        </HStack>
      </Box>
    </Box>
  );
};

const TextCard = () => {
  return;
};

export default Card;

import { Box, Tooltip, TooltipProps } from '@chakra-ui/react';
import { Memo } from '../../../lib/common/utils/axios/types';

interface UserTooltipProps extends TooltipProps {
  memo: Memo;
}

const UserTooltip: React.FC<UserTooltipProps> = ({ children, memo, ...props }) => {
  return (
    <Box>
      <Tooltip
        hasArrow
        isDisabled={!memo.description}
        label={<Box whiteSpace={'pre-wrap'}>{memo.description}</Box>}
        placement="right"
        {...props}
      >
        <Box>{children}</Box>
      </Tooltip>
    </Box>
  );
};

export default UserTooltip;

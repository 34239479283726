import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Input,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { ModalDisclosureProps } from '../../common/Modals/base';
import { useUpdateRoom } from '../../../lib/admin/hooks/mutation';
import SelectBox from '../SelectBox/base';
import { CheckCircleIcon } from '../../../statics/common/icons';

interface CancleDealModalProps {
  disabled?: boolean;
  room_id: string;
}

const CancleDealModal: React.FC<CancleDealModalProps> = ({ children, disabled = false, room_id }) => {
  const [date_1, setDate_1] = useState<string>('');
  const [date_2, setDate_2] = useState<string>(new Date().toString());
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { mutateAsync: updateRoom } = useUpdateRoom();
  console.log(room_id);

  const handleModalOpen = () => {
    if (!disabled) {
      onOpen();
    }
  };

  const onClickSubmitButton = async () => {
    const formData = new FormData();
    // formData.append('contract_start_date', date_1);
    formData.append('contract_end_date', date_2);
    await updateRoom({
      formData,
      room_id,
    }).then(() => {
      onClose();
    });
  };

  const isAbleClickButton = (() => {
    // return !!date_1 && !!date_2;
    return true;
  })();

  return (
    <>
      <Box onClick={handleModalOpen}>{children}</Box>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
        size={'2xl'}
        motionPreset="slideInRight"
        isCentered
      >
        <ModalOverlay />
        <ModalContent px={8} pt={8} pb={5}>
          <ModalCloseButton />
          <Center flexDir={'column'} m={4}>
            <Flex align={'center'} w="full">
              <CheckCircleIcon boxSize={'34px'} mr={1} />
              <Box fontSize={'23px'}>거래취소(공실로 전환)</Box>
            </Flex>
            <Flex flexDir={'column'} w="full" fontSize={'18px'} pt={3}>
              <Box>해당 매물의 계약정보를 삭제하시겠습니까?</Box>
              <Box>삭제 후에는 공실상태로 변경됩니다.</Box>
            </Flex>
            <Center mt={4}>
              <Button variant={'warning'} w={'130px'} mr={2}>
                취소
              </Button>
              <Button variant={'reject'} w={'130px'} isDisabled={!isAbleClickButton} onClick={onClickSubmitButton}>
                거래취소
              </Button>
            </Center>
          </Center>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CancleDealModal;

import { PlusSquareIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, ListItem, Skeleton, Table, Tbody, Text, Tr, UnorderedList, VStack } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import DropzoneOpacityFormikThumb, {
  FileInfosProps,
} from '../../../components/common/Dropzone/DropzoneOpacityFormikThumb';
import MultiFilesCommonDropzone from '../../../components/common/Dropzone/MultiFilesCommonDropzone';
import { filterRemovedFiles } from '../../../lib/common/utils/dropzone/function';
import { TdFullWContainer } from '../../../components/common/Table/Tr';
import { useUpdateBuilding } from '../../../lib/common/hooks/mutation';
import { useGetBuilding } from '../../../lib/common/hooks/query';
import { datedashformat, dateformatter } from '../../../lib/common/utils/dateformatter';
import { DeleteCircle } from '../../../statics/common/icons';
import ReCheckAlertModal from '../../../components/common/Modals/Alert/ReCheckAlert';
import { TableTitleContainer } from '../../../components/common/Modals/RegisterBuildingRoom/sections/type';
import BuildingInfoTable from '../../../components/common/Modals/RegisterBuildingRoom/sections/Building/a-buildinginfo';
import FacilityInfoTable from '../../../components/common/Modals/RegisterBuildingRoom/sections/Building/b-facilityinfo';
import ColivingBuildingInfoTable from '../../../components/common/Modals/RegisterBuildingRoom/sections/Building/a-buildinginfoOfColiving';
import ColivingHostInfoTable from '../../../components/common/Modals/RegisterBuildingRoom/sections/Building/c-hostinfo';
import ManagementTable from '../../../components/common/Modals/RegisterBuildingRoom/sections/Building/d-management';
import { BuildingDescription } from '../../../components/common/Modals/RegisterBuildingRoom/sections/Building/f-buildingdescription';
import RegisterFacilityInfoModal from '../../../components/common/Modals/RegisterFacilityInfoModal';
import Loading from '../../../components/common/Loading';

const BuildingDetailPage = () => {
  const params = useParams();
  const params_buildingId = params.buildingId || '';

  const res = useGetBuilding(params_buildingId, {
    options: {
      enabled: !!params_buildingId,
    },
  });

  const { mutateAsync: updateBuilding } = useUpdateBuilding();

  const { isLoading } = res;
  if (isLoading) return <Loading />;
  return (
    <Skeleton h="full" isLoaded={!isLoading}>
      <Formik
        initialValues={{
          ...res.data,
          completion_date: dateformatter(new Date(res.data?.completed_date || new Date()), '-') || '',
          // completion_date: res.data.completion_date ? new Date(res.data.completion_date) : '',
          host_id: res.data?.host?._id,
          host_name: res.data?.host?.user_name,
          building_description: res.data?.description,
          file_infos: res.data?.pictures?.map((url) => {
            return {
              url,
              removed: false,
            } as FileInfosProps;
          }),
          facilities: res.data?.facilities?.map((facility) => {
            return {
              // 여기부터
              name: facility.name,
              removed: false,
              file_infos: facility.picture_urls?.map((url) => {
                return {
                  url,
                  removed: false,
                } as FileInfosProps;
              }),
            };
          }),
        }}
        onSubmit={async (values, { setSubmitting }) => {
          console.log(values);
          const formData = new FormData();
          // setSubmitting(true);
          formData.append('building_type', values.building_type || '');
          formData.append('building_form', values.building_form || '');
          formData.append('address', values.address || '');
          formData.append('jibun_address', values.jibun_address || '');
          formData.append('road_address', values.road_address || '');
          formData.append('zip_code', values.zip_code || '');
          formData.append('sido_name', values.sido_name || '');
          formData.append('sigungu_name', values.sigungu_name || '');
          formData.append('dongli_name', values.dongli_name || '');
          formData.append('name', values.name || '');
          formData.append('building_number', values.building_number?.toString() || '');
          formData.append('entrance_type', values.entrance_type || '');
          formData.append('completion_date', values.completion_date as any);
          formData.append('floor', values.floor as any);
          formData.append('household_number', values.household_number as any);
          formData.append('is_elevator', values.is_elevator as any);
          formData.append('room_types', JSON.stringify(values.room_types));
          values.security_facilities?.map((security: any) => {
            formData.append('security_facilities[]', security);
          });

          if (values.host_id) {
            // 여쭤보기
            formData.append('host_id', values.host_id);
          }
          formData.append('is_parking', values.is_parking as any);
          formData.append('is_management', values.is_management as any);
          formData.append('parking_num', values.parking_num as any);
          formData.append('parking_cost', values.parking_cost as any);

          const [filtered_exists, _1, filtered_Files, filtered_urls] = filterRemovedFiles(values.file_infos);
          filtered_Files.map((file) => {
            formData.append('building_pictures', file);
          });
          formData.append(
            'building_picture_urls',
            JSON.stringify(
              filtered_urls.map((url) => {
                return url;
              }),
            ),
          );
          const filtered_facilities = values.facilities?.filter((facility) => !facility.removed);
          formData.append(
            'facilities',
            JSON.stringify(filtered_facilities?.map((facility) => ({ name: facility.name }))),
          );
          console.log('filtered_facilities =>', filtered_facilities);
          console.log('values.facilities =>', values.facilities);

          filtered_facilities?.map((facility, index) => {
            const [filtered_exists, _1, filtered_Files, filtered_urls] = filterRemovedFiles(facility.file_infos);
            filtered_Files.map((file) => {
              formData.append(`facility_pictures${index}`, file);
            });
            formData.append(
              `facility_picture_urls${index}`,
              JSON.stringify(
                filtered_urls.map((url) => {
                  return url;
                }),
              ),
            );
          });

          formData.append('building_description', values.building_description || '');

          await updateBuilding({
            building_id: params_buildingId,
            formData,
          }).then(() => {
            window.location.reload();
          });
        }}
        validationSchema={Yup.object().shape({})}
      >
        {(props) => {
          console.log(props.values);

          const isColiving = props.values.building_form === 'coliving_house';

          const makeBuildingPicturesResult = async (files: File[]) => {
            // funcInSuccessProcess(files);

            if (_.isEmpty(files)) {
              //
            } else {
              props.setFieldValue('file_infos', [
                ...(props.values.file_infos as any),
                ...files.map((url) => ({
                  url,
                  removed: false,
                })),
              ]);
            }
          };

          return (
            <Form style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
              <VStack w={'full'} spacing={7}>
                {/* 전송 버튼 */}
                <Flex w="full" justify={'end'}>
                  <ReCheckAlertModal callback={async () => await props.submitForm()}>
                    <Button variant={'basic'}>수정하기</Button>
                  </ReCheckAlertModal>
                </Flex>
                <TableTitleContainer
                  title="건물 정보"
                  table={isColiving ? <ColivingBuildingInfoTable /> : <BuildingInfoTable />}
                ></TableTitleContainer>
                <TableTitleContainer title="건물 시설 정보" table={<FacilityInfoTable />} />
                {isColiving && <TableTitleContainer title="임대인 정보" table={<ColivingHostInfoTable />} />}
                <TableTitleContainer title="임대관리 신청 여부" table={<ManagementTable />} />
                {/* <UploadBuildingPictureDropZoneContainer /> */}
                <TableTitleContainer
                  title="건물 사진 등록"
                  table={
                    <Table w={'100%'} variant="simple">
                      <Tbody w={'100%'}>
                        <Tr w={'100%'}>
                          <TdFullWContainer title={'사진 정보'} required ContentTdProps={{ colSpan: 4 }}>
                            <Box>
                              <Flex align={'center'}>
                                <Box mr={4}>
                                  <MultiFilesCommonDropzone
                                    funcInSuccessProcess={makeBuildingPicturesResult}
                                    customVariant={'plus'}
                                  />
                                </Box>
                                <Box>파일 1개당 첨부용량 30MB, 파일 업로드 문서당 최대 10개 가능</Box>
                              </Flex>
                              {!_.isEmpty(props.values.file_infos) && (
                                <Box mt={2}>
                                  <DropzoneOpacityFormikThumb
                                    file_infos={props.values.file_infos}
                                    // onDeleteBtn={isEditable}
                                    onDeleteBtn={true}
                                  />
                                </Box>
                              )}
                            </Box>
                          </TdFullWContainer>
                        </Tr>
                      </Tbody>
                    </Table>
                  }
                />
                <BuildingDescription />
                {isColiving && (
                  <TableTitleContainer
                    title={
                      <Flex align={'center'}>
                        <Box mr={1}>{'공용시설 등록'}</Box>
                        <RegisterFacilityInfoModal is_fileInfosProps fieldArrayKey={'facilities'}>
                          <PlusSquareIcon layerStyle={'pointer'} />
                        </RegisterFacilityInfoModal>
                      </Flex>
                    }
                    table={
                      <Box>
                        <Box mb={2}>
                          <UnorderedList>
                            <ListItem>시설별로 이름과 사진을 함께 등록해주세요.</ListItem>
                          </UnorderedList>
                        </Box>
                        <Table w={'100%'} variant="simple">
                          <Tbody w={'100%'}>
                            {props.values.facilities?.map((facility, index) => {
                              return (
                                <Tr w={'100%'} key={index} opacity={facility.removed ? 0.4 : 1}>
                                  <TdFullWContainer
                                    title={
                                      <Flex align={'center'}>
                                        <DeleteCircle
                                          mr={1}
                                          layerStyle={'pointer'}
                                          boxSize={4}
                                          onClick={(e) => {
                                            props.setFieldValue(
                                              `facilities[${index}].removed`,
                                              !props.values.facilities?.[index]?.removed,
                                            );
                                          }}
                                        />
                                        <Text>{facility.name}</Text>
                                      </Flex>
                                    }
                                    ContentTdProps={{ colSpan: 4 }}
                                  >
                                    <Box>
                                      <Flex align={'center'}>
                                        <Box mr={4}>
                                          <MultiFilesCommonDropzone
                                            funcInSuccessProcess={async (files) => {
                                              if (_.isEmpty(files)) {
                                                //
                                              } else {
                                                props.setFieldValue(`facilities[${index}].file_infos`, [
                                                  ...(props.values?.facilities?.[index]?.file_infos as any),
                                                  ...files.map((url) => ({
                                                    url,
                                                    removed: false,
                                                  })),
                                                ]);
                                              }
                                            }}
                                            customVariant={'plus'}
                                          />
                                        </Box>
                                        <Box>파일 1개당 첨부용량 30MB, 파일 업로드 문서당 최대 10개 가능</Box>
                                      </Flex>
                                      {!_.isEmpty(facility.file_infos) && (
                                        <Box mt={2}>
                                          <DropzoneOpacityFormikThumb
                                            file_infos={facility.file_infos}
                                            // onDeleteBtn={isEditable}
                                            formikDeleteKey={`facilities[${index}]`}
                                            onDeleteBtn={true}
                                          />
                                        </Box>
                                      )}
                                    </Box>
                                  </TdFullWContainer>
                                </Tr>
                              );
                            })}
                          </Tbody>
                        </Table>
                      </Box>
                    }
                  />
                )}
                {/* {isColiving && <UploadFacilityDropZoneContainer />} */}
              </VStack>
            </Form>
          );
        }}
      </Formik>
    </Skeleton>
  );
};

export default BuildingDetailPage;

import { Box, Modal, ModalContent, ModalOverlay, ModalProps, useDisclosure } from '@chakra-ui/react';
import _, { isUndefined } from 'lodash';
import { createContext, ReactNode, useEffect, useMemo, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { QueryParamsModalLayoutContext } from '../../../contexts/common/QueryParamsModalLayoutContext';
import { currentParamsObject } from '../../../lib/common/utils/base';

const defaultValue = {
  queryKeys: [],
  queryValues: [],
};

interface QueryParamsModalLayoutProps {
  queryKeys: Array<string>;
  modalProps?: Partial<ModalProps>;
  //   context: any;
}

const QueryParamsModalLayout: React.FC<QueryParamsModalLayoutProps> = ({
  queryKeys,
  modalProps,
  children,
  //   context,
  ...props
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchParams, setSearchParams] = useSearchParams();

  const queryValues = useMemo(() => {
    return queryKeys.map((queryKey) => {
      const { [queryKey]: queryValue } = currentParamsObject(false);
      return queryValue;
    });
  }, [queryKeys]);

  useEffect(() => {
    if (_.every(queryValues)) {
      onOpen();
    }
  }, [queryValues]);

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      if (!isOpen) {
        queryKeys.map((queryKey) => {
          searchParams.delete(queryKey);
        });
        setSearchParams(searchParams);
      }
    }
  }, [isOpen]);
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="outside"
      size={'2xl'}
      motionPreset="none"
      autoFocus={false}
      {...modalProps}
    >
      <ModalOverlay />
      <ModalContent>
        {/* <QueryParamsModalLayoutContext.Provider
          value={{
            queryKeys,
            queryValues,
          }}
        >
          <QueryParamsModalLayoutContext.Consumer>
            {children as (value: { queryKeys: string[]; queryValues: string[] }) => ReactNode}
          </QueryParamsModalLayoutContext.Consumer>
        </QueryParamsModalLayoutContext.Provider> */}
        {children}
      </ModalContent>
    </Modal>
  );
};

export default QueryParamsModalLayout;

import { Box, Skeleton, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import QueryParamsLayout from '../../../../../../../../layouts/common/main/QueryParamsLayout';
import TextLink from '../../../../../../../../components/common/Link/TextLink';
import {
  useGetHostAllBuildingsRooms,
  useGetHostAllLeaseContractsLeasePayments,
} from '../../../../../../../../lib/admin/hooks/query';
import { currentParamsObject, ToAllFilledObj } from '../../../../../../../../lib/common/utils/base';
import { datedotformat } from '../../../../../../../../lib/common/utils/dateformatter';
import { decimalize } from '../../../../../../../../lib/common/utils/digitformatter';
import SettlementTable from './table';
import Loading from '../../../../../../../../components/common/Loading';

const LSettlement = () => {
  const { search: srch } = location;
  const { page, limit, id } = currentParamsObject(false);

  const _params = currentParamsObject();
  const params = ToAllFilledObj(_params);

  const res = useGetHostAllLeaseContractsLeasePayments(id, {
    axiosConfig: {
      params,
    },
  });

  const { isLoading } = res;
  if (isLoading) return <Loading />;
  return (
    <Box w="full" h="calc(100vh - 320px)">
      <Box mb={2}>
        <Skeleton isLoaded={!isLoading}>
          <Text textStyle={'bold18'}>{`전체 정산 내역 (${decimalize(res.data?.total)}건)`}</Text>
        </Skeleton>
      </Box>
      <QueryParamsLayout res={res}>
        <SettlementTable res={res} />
      </QueryParamsLayout>
    </Box>
  );
};
export default LSettlement;

import { Avatar, AvatarBadge, Box, Button, Center, Flex, HStack, Icon, Table, Tbody, VStack } from '@chakra-ui/react';
import { WBox } from '../../../../../../components/common/Box';
import { CustomInput } from '../../../../../../components/common/Input';
import { TrContainer } from '../../../../../../components/common/Table/Tr';
import { EditPencilIcon, UserSquareActiveIcon } from '../../../../../../statics/common/icons';

const EditInfoPage = () => {
  return (
    <WBox px={8} py={5}>
      {/* Title */}
      <VStack alignItems={'flex-start'} spacing={8}>
        <HStack>
          <UserSquareActiveIcon />
          <Box fontSize={'24px'} fontWeight={'bold'}>
            개인 정보 수정
          </Box>
        </HStack>
        {/* 개인 정보 */}
        <VStack w="100%">
          <Box fontWeight={'bold'} fontSize={'18px'} textAlign={'start'} flexGrow={1} w={'100%'}>
            개인 정보
          </Box>
          <Flex w="100%">
            <Table w="75%">
              <Tbody>
                <TrContainer title="이름">김리법</TrContainer>
                <TrContainer title="권한/직책">
                  <HStack>
                    <CustomInput />
                    <Box>/</Box>
                    <CustomInput />
                  </HStack>
                </TrContainer>
                <TrContainer title="이메일 주소">123456789</TrContainer>
                <TrContainer title="휴대폰 번호">
                  <HStack>
                    <CustomInput />
                    <Button variant={'basic'}>본인 인증</Button>
                  </HStack>
                </TrContainer>
                <TrContainer title="인사말">
                  <CustomInput />
                </TrContainer>
              </Tbody>
            </Table>
            <Center w="25%" h="222px" border="1px solid" borderColor={'rgb(235,240,246)'}>
              <Avatar bg={'silverGray'} size="2xl">
                <AvatarBadge boxSize="0.875em" bg="white" border={'1px'} borderColor={'silverGray'}>
                  <Center position={'absolute'} left={'5px'}>
                    {/* <Icon as={EditPencilIcon} w={6} h={6} /> */}
                    <Icon as={EditPencilIcon} w={9} h={9} />
                  </Center>
                </AvatarBadge>
              </Avatar>
            </Center>
          </Flex>
        </VStack>
        {/* 비밀번호 변경 */}
        <VStack w="100%">
          <Box fontWeight={'bold'} fontSize={'18px'} textAlign={'start'} flexGrow={1} w={'100%'}>
            비밀번호 변경
          </Box>
          <Table>
            <Tbody>
              <TrContainer title="현재 비밀번호">
                <CustomInput placeholder="현재 비밀번호를 입력해 주세요." />
              </TrContainer>
              <TrContainer title="새로운 비밀번호">
                <CustomInput placeholder="숫자/영문 조합 8자 이상만 가능합니다." />
              </TrContainer>
              <TrContainer title="비밀번호 재입력">
                <CustomInput placeholder="비밀번호를 재입력해 주세요." />
              </TrContainer>
            </Tbody>
          </Table>
        </VStack>
        <Flex w="100%" justifyContent={'flex-end'}>
          <Button variant={'basic'}>수정 완료</Button>
        </Flex>
      </VStack>
    </WBox>
  );
};

export default EditInfoPage;

import { HStack, Flex, VStack } from '@chakra-ui/react';
import InfoSector from '../sections/InfoSector';

const Agencies = () => {
  return (
    <HStack alignItems={'flex-start'} spacing={10} pr={10}>
      {/* section 1 */}
      <Flex w={'250px'}>
        <VStack w={'full'} spacing={6}>
          {/* 중개사 회원가입 신청 */}
          <InfoSector total={0}>중개사 회원가입 신청</InfoSector>
          {/* 중개수수료 정산 요청 */}
          <InfoSector total={0}>중개수수료 정산 요청</InfoSector>
          {/* 신규 매물 등록 */}
          <InfoSector total={0}>신규 매물 등록</InfoSector>
        </VStack>
      </Flex>
    </HStack>
  );
};
export default Agencies;

import { Box, HStack, Table, Tag, Tbody, Thead, Tr, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { WTd } from '../../../../../../components/common/Table/Td';
import { WTh } from '../../../../../../components/common/Table/Th';

type tFilter = 'asc' | 'desc';

const AccountContentTable = () => {
  // filter
  const [date, setDate] = useState<tFilter>('desc');
  const [account, setAccount] = useState<tFilter>('desc');
  const [status, setStatus] = useState<tFilter>('desc');

  useEffect(() => {
    const dummy_filter = {
      date,
      account,
      status,
    };
    console.log(dummy_filter);
  }, [date, account, status]);

  return (
    <VStack spacing={6} w="100%">
      <Table variant="simple">
        <Thead bgColor={'#F8F8F8'} borderColor={'gray.100'}>
          <Tr>
            <WTh
              borderR
              tFilter={date}
              onClick={() => {
                setDate((prev) => (prev === 'asc' ? 'desc' : 'asc'));
              }}
            >
              정산 일자
            </WTh>
            <WTh borderR>정산 내용</WTh>
            <WTh
              borderR
              tFilter={account}
              onClick={() => {
                setAccount((prev) => (prev === 'asc' ? 'desc' : 'asc'));
              }}
            >
              정산 계좌
            </WTh>
            <WTh borderR>정산 금액</WTh>
            <WTh
              borderR
              tFilter={status}
              onClick={() => {
                setStatus((prev) => (prev === 'asc' ? 'desc' : 'asc'));
              }}
            >
              상태
            </WTh>
            <WTh>비고</WTh>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <WTd borderR>test</WTd>
            <WTd borderR>test</WTd>
            <WTd borderR>test</WTd>
            <WTd borderR>test</WTd>
            <WTd borderR>
              <Tag variant={'green'} fontWeight={'bold'}>
                정산 예정
              </Tag>
            </WTd>
            <WTd>test</WTd>
          </Tr>
          <Tr>
            <WTd borderR>test</WTd>
            <WTd borderR>test</WTd>
            <WTd borderR>test</WTd>
            <WTd borderR>test</WTd>
            <WTd borderR>
              <Tag variant={'tour_complete_11px'}>정산 완료</Tag>
            </WTd>
            <WTd>test</WTd>
          </Tr>
        </Tbody>
      </Table>
      <HStack>
        <Box>화살표</Box>
        <Box>번호</Box>
        <Box>화살표</Box>
      </HStack>
    </VStack>
  );
};

export default AccountContentTable;

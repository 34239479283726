import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
} from '@chakra-ui/react';
import { useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import {
  like_request_select_1,
  like_request_select_2_2,
  like_request_select_2_3,
  SelectListNumberType,
} from '../../../../../statics/common/data/selectbox';
import { ClickIconContainer, LeftArrowIcon, RightArrowIcon } from '../../../../../statics/common/icons';
import SelectBox from '../../../../../components/agent/SelectBox/base';

const LikedRequestSection = () => {
  const initialFirstSelect = 1;
  const [firstSelect, setFirstSelect] = useState<number | string>(initialFirstSelect);
  const [secondSelectLists, setSecondSelectLists] = useState<SelectListNumberType>();

  return (
    <Flex bg="white" borderBottomRadius={'md'} borderTopRightRadius={'md'} px={4} py={5} w={'full'} flexWrap={'wrap'}>
      {/* 상단 */}
      <HStack w={'full'} alignItems={'flex-start'} minW={'870px'}>
        {/* 필터 */}
        <Flex
          // mr={{ base: 0, md: 2 }}
          bg="lotionWhite"
          border={'1px solid'}
          borderColor={'silverGray'}
          borderRadius={'lg'}
          boxShadow={'sm'}
          p={4}
          w={'70%'}
        >
          <HStack spacing={4} w={'full'}>
            <SelectBox
              // value={firstSelect}
              w={'full'}
              onChange={(e) => {
                setFirstSelect(e.target.value);
                if (e.target.value == '2') {
                  setSecondSelectLists(like_request_select_2_2);
                } else if (e.target.value == '3') {
                  setSecondSelectLists(like_request_select_2_3);
                } else {
                  setSecondSelectLists(undefined);
                }
              }}
            >
              {like_request_select_1.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </SelectBox>
            <SelectBox w={'full'} isDisabled={firstSelect == initialFirstSelect}>
              {secondSelectLists?.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </SelectBox>
            <Button variant={'filter'} minW={'140px'} h={'36px'}>
              필터 초기화
            </Button>
            <Button variant={'basic'} minW={'140px'}>
              조회
            </Button>
          </HStack>
        </Flex>
        {/* 검색 조회 */}
        <Flex flexDir={'column'} w={'30%'}>
          <HStack
            bgColor={'lotionWhite'}
            p={2}
            alignItems={'center'}
            h={'100%'}
            border="1px solid"
            borderColor={'silverGray'}
            borderRadius={'md'}
            boxShadow={'sm'}
          >
            <InputGroup>
              <InputLeftElement
                position={'absolute'}
                top="-2px"
                pointerEvents="none"
                children={<FaSearch color={'borderWhite'} />}
              />
              <Input
                type="search"
                h={'36px'}
                bgColor={'white'}
                placeholder="유저명/요청번호"
                fontSize={'14px'}
                _placeholder={{ color: 'borderWhite' }}
                _hover={{}}
              />
            </InputGroup>
            <Button variant={'basic'} w={'90px'}>
              조회
            </Button>
          </HStack>
          {/* 페지네이션 */}
          <Center justifyContent={'flex-end'} h={'100%'} mb={'20px'}>
            <Box fontSize={'14px'} fontWeight={'bold'} color="zetaBlue" mr={3}>{`검색 결과 총 ${'10'}건`}</Box>
            <Flex alignItems={'center'}>
              <ClickIconContainer
                clickableArea={7}
                color="zetaBlue"
                w={6}
                h={6}
                onClick={() => console.log('previous')}
              >
                <LeftArrowIcon color="zetaBlue" />
              </ClickIconContainer>
              <Text mx={'20px'} textColor={'highGray'}>
                {`${1}`}
              </Text>
              <ClickIconContainer
                clickableArea={7}
                color="zetaBlue"
                w={6}
                h={6}
                onClick={() => console.log('previous')}
              >
                <RightArrowIcon color="zetaBlue" />
              </ClickIconContainer>
            </Flex>
          </Center>
        </Flex>
      </HStack>

      <Divider borderColor="borderGray" />
      {/* 요청 리스트 */}
      {/* <VStack alignItems={'unset'} borderBottomRadius={'md'} w={'100%'}>
        {request_item_lists.map((list) => (
          <RequestContainer key={list._id} item={list} addLikeBtn addProcessBtns />
        ))}
      </VStack> */}
    </Flex>
  );
};

export default LikedRequestSection;

import { Box, Button, HStack, Tag, useDisclosure, useToast } from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { queryClient } from '../../..';
import RoomRequestDetailsModal from '../../../components/agent/Modals/RoomRequestDetails';
import { roomNumberTrans } from '../../../lib/common/utils/utils';
import { room_new_request_path, room_request_like_partialPath } from '../../../statics/agent/data/KeyPath';
import { fetchClient } from '../../../lib/common/utils/axios';
import { RoomNewRequest } from '../../../lib/common/utils/axios/types';
import { saveFail, saveSuccess } from '../../../statics/common/data/toast';
import { FillHeart, OutLineHeart } from '../../../statics/common/icons';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../../lib/common/utils/firebase/init';

interface ConditionsProps {
  item: RoomNewRequest;
  max?: any;
}

export const Conditions: React.FC<ConditionsProps> = ({ item, max = 4 }) => {
  // const stacks = [];
  // for (let i = 0; i < item.conditions.length; i++) {
  //   const condition = item.conditions[i];
  //   if (i < max) {
  //     stacks.push(condition);
  //   } else if (i === max) {
  //     stacks.push('...');
  //     break;
  //   }
  // }
  const { deposit, rent, regions, room_nums } = item;

  const deposit_max = deposit.max;
  const rent_max = rent.max;

  return (
    <HStack id="hmm" flexWrap={'wrap'} overflow={'hidden'} h={'28px'}>
      {regions.length !== 0 &&
        regions.map((region, index) => {
          if (index > 2) {
            return null;
          }
          return (
            <Tag variant={'basic'} px={3} key={index} fontSize={'14px'}>
              {region.sigungu_name} {region.dongli_name}
            </Tag>
          );
        })}
      {deposit_max && (
        <Tag variant={'basic'} px={6} fontSize={'14px'}>
          ~{deposit_max}만 원
        </Tag>
      )}
      {rent_max && (
        <Tag variant={'basic'} px={6} fontSize={'14px'}>
          ~{rent_max}만 원
        </Tag>
      )}
      {room_nums.length !== 0 &&
        room_nums.map((room, index) => (
          <Tag variant={'basic'} px={6} key={index} fontSize={'14px'}>
            {roomNumberTrans(room)}
          </Tag>
        ))}
      ;
    </HStack>
  );
};

interface NewRequestContainerProps {
  item: RoomNewRequest;
  onClickLikeBtn?: () => void;
  addLikeBtn?: boolean;
  addProcessBtns?: boolean;
  addDetailBtns?: boolean;
  addChatBtns?: boolean;
}

const NewRequestContainer: React.FC<NewRequestContainerProps> = ({
  item,
  addLikeBtn,
  onClickLikeBtn,
  addProcessBtns,
  addDetailBtns,
  addChatBtns,
  children,
}) => {
  const {
    like,
    deposit: deposits,
    custom_room_request_id,
    upload_date,
    tenant_name,
    room_nums,
    rent: rents,
    regions,
    new: item_new,
  } = item;
  const [isLike, setIsLike] = useState<boolean>(like);
  const toast = useToast();
  const [user, loading, error] = useAuthState(auth);
  const mutation = useMutation(
    async () => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      return await instance.post(`${room_request_like_partialPath}/${custom_room_request_id}/like`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(room_new_request_path);
        if (isLike) {
          toast({
            ...saveSuccess,
            variant: 'warning',
            duration: 2000,
            description: '매물 찜을 취소했습니다.',
            position: 'bottom-right',
          });
        } else {
          toast({ ...saveSuccess, duration: 2000, description: '매물 찜에 성공했습니다.', position: 'bottom-right' });
        }
      },
      onError: () => {
        toast({
          ...saveFail,
          variant: 'error',
          duration: 6000,
          description: '저장에 실패했습니다. 새로고침 후 다시 시도해주세요.',
          position: 'bottom-right',
        });
      },
    },
  );

  const handleLikeBtnClick = () => {
    setIsLike((prevLike) => !prevLike);
    mutation.mutate();
  };
  const handleRequestFormClick = () => {
    return;
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  const variants = {
    initial: { opacity: 0 },
    open: { opacity: 1 },
    // closed: { opacity: 0, x: '-100%' },
    // exit: { opacity: 0 },
  };

  return (
    <HStack
      spacing={6}
      px={4}
      py={2}
      border={'1px solid'}
      bgColor={'lotionWhite'}
      borderColor={'silverGray'}
      borderRadius={'lg'}
      boxShadow={'0px 2px 2px 0px #00000008'}
    >
      {/* Heart Icon  */}
      <AnimatePresence>
        {addLikeBtn &&
          (isLike ? (
            <motion.div variants={variants} initial={'initial'} animate={'open'}>
              <FillHeart
                color={'#FB4667'}
                cursor="pointer"
                h={'100%'}
                filter={'drop-shadow( 1px 1px 1px rgba(0, 0, 0, .2))'}
                onClick={() => {
                  handleLikeBtnClick();
                  if (onClickLikeBtn) {
                    onClickLikeBtn();
                  }
                }}
              />
            </motion.div>
          ) : (
            <OutLineHeart
              color={'#FB4667'}
              cursor="pointer"
              h={'100%'}
              onClick={() => {
                handleLikeBtnClick();
                if (onClickLikeBtn) {
                  onClickLikeBtn();
                }
              }}
            />
          ))}
      </AnimatePresence>
      {item_new === 'new' ? (
        <Tag
          variant={'orange'}
          fontWeight={'bold'}
          w={'75px'}
          minW={'75px'}
          justifyContent={'center'}
          fontSize={'12px'}
          borderRadius={'lg'}
        >
          신규 요청
        </Tag>
      ) : item_new === 'to_be_deleted' ? (
        <Tag
          variant={'gray'}
          fontWeight={'bold'}
          w={'75px'}
          minW={'75px'}
          justifyContent={'center'}
          fontSize={'12px'}
          borderRadius={'lg'}
        >
          종료 예정
        </Tag>
      ) : (
        <Box minW="75px" />
      )}

      {tenant_name && (
        <Box fontSize={'14px'} wordBreak={'keep-all'} lineHeight={0}>
          {tenant_name}
        </Box>
      )}
      <HStack h={'31px'} flexWrap={'wrap'} overflow={'hidden'}>
        <Conditions item={item} />
      </HStack>
      <HStack flexGrow={1} justify={'flex-end'}>
        {addProcessBtns && (
          <Button
            variant={'basic'}
            fontSize={'16px'}
            height={'32px'}
            onClick={() => {
              handleRequestFormClick();
              onOpen();
            }}
          >
            요청/제안
          </Button>
        )}
        {addDetailBtns && (
          <Button variant={'basic'} fontSize={'16px'} height={'32px'} onClick={onOpen}>
            상세 보기
          </Button>
        )}
        {addChatBtns && (
          <Button variant={'whiteBorder'} fontSize={'16px'} fontWeight={'bold'} height={'32px'}>
            채팅하기
          </Button>
        )}
      </HStack>
      {/* 모달 */}
      <RoomRequestDetailsModal isOpen={isOpen} onClose={onClose} />
    </HStack>
  );
};

export default NewRequestContainer;

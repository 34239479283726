import { Box, BoxProps, Flex, FlexProps } from '@chakra-ui/react';
import React from 'react';
import RequestsSection from './requests';
import StatusSection from './status';

export const Container: React.FC<FlexProps> = ({ children, ...props }) => (
  <Flex bgColor={'white'} borderRadius={'md'} boxShadow={'md'} p={5} {...props}>
    {children}
  </Flex>
);

interface RequestActiveBoxProps extends BoxProps {
  activate?: boolean;
  customBgColor?: any;
}

export const RequestActiveBox: React.FC<RequestActiveBoxProps> = ({
  children,
  activate = false,
  customBgColor = 'mountainGreen',
  ...props
}) => {
  return (
    <Box
      w={'210px'}
      color={activate ? 'white' : 'silverGray'}
      border={'1px solid'}
      borderColor={activate ? customBgColor : 'silverGray'}
      bgColor={activate ? customBgColor : 'white'}
      fontWeight={activate ? 700 : 'normal'}
      boxShadow={activate ? 'md' : 'sm'}
      padding={5}
      borderRadius={'lg'}
      cursor="pointer"
      userSelect={'none'}
      transition={'0.3s linear'}
      _hover={{
        color: 'white',
        fontWeight: 700,
        bgColor: customBgColor,
      }}
      {...props}
    >
      {children}
    </Box>
  );
};

const AgencyDashboard = () => {
  return (
    <Flex flexWrap={'wrap'}>
      {/* 매물 요청 */}
      <RequestsSection />
      <StatusSection />
    </Flex>
  );
};

export default AgencyDashboard;

import { useState } from 'react';

/** [makePreviewUrl, previewUrl = null, setPreviewUrl] */
const useFilePreview = (): [(file: File) => void, any, React.Dispatch<any>] => {
  const [previewUrl, setPreviewUrl] = useState<any>(null);

  const makePreviewUrl = (file: File) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = (ev) => {
      const result = ev.target?.result;
      if (result) {
        setPreviewUrl(result);
      }
    };
    return () => {
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    };
  };

  return [makePreviewUrl, previewUrl, setPreviewUrl];
};

export default useFilePreview;

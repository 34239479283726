import {
  Box,
  Button,
  Center,
  Collapse,
  Editable,
  EditablePreview,
  EditableTextarea,
  Flex,
  HStack,
  Skeleton,
  StackDivider,
  Tooltip,
  useToast,
  VStack,
  Wrap,
} from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import _ from 'lodash';
import { useState } from 'react';
import Dropzone from 'react-dropzone';
import InitFormikEditableButton from '../../../../../../../../components/common/Button/InitFormikEditableButton';
import DropzoneOpacityFormikThumb, {
  FileInfosProps,
} from '../../../../../../../../components/common/Dropzone/DropzoneOpacityFormikThumb';
import { filterRemovedFiles } from '../../../../../../../../lib/common/utils/dropzone/function';
import TextLink from '../../../../../../../../components/common/Link/TextLink';
import Thumb from '../../../../../../../../components/common/Thumb/base';
import { useUpdateAdminToTenantMemo } from '../../../../../../../../lib/admin/hooks/mutation';
import { useGetCompanyManager } from '../../../../../../../../lib/admin/hooks/query';
import { currentParamsObject } from '../../../../../../../../lib/common/utils/base';
import { saveCancle, saveFail } from '../../../../../../../../statics/common/data/toast';
import { DeleteCircle } from '../../../../../../../../statics/common/icons';
import Loading from '../../../../../../../../components/common/Loading';

const Lmemo = () => {
  const { id } = currentParamsObject(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [description, setDescription] = useState<string>('');
  const res = useGetCompanyManager(id || '', {
    options: {
      enabled: !!id,
      keepPreviousData: false,
    },
  });
  const { data } = res;
  const { mutateAsync: updateTenantMemo } = useUpdateAdminToTenantMemo();

  const toast = useToast();
  const { isLoading } = res;
  if (isLoading) return <Loading />;
  return (
    <Skeleton isLoaded={!isLoading} w="full">
      <VStack justifyContent={'start'} w={'100%'} spacing={6}>
        {/* 메모 */}
        <VStack spacing={3} justifyContent={'start'} align={'flex-start'} w={'100%'} divider={<StackDivider />}>
          <HStack w={'full'}>
            <HStack w={'full'} spacing={4} alignItems={'flex-end'}>
              <Box fontWeight={'bold'} fontSize={'18px'} textAlign={'start'}>
                메모
              </Box>
            </HStack>
          </HStack>
          {/* 메모 */}
          <Tooltip
            label="수정하려면 클릭하세요!"
            fontSize="14px"
            placement="top-end"
            isOpen={!isEditMode}
            hasArrow
            bg={'white'}
            arrowShadowColor="#F3F3F3"
          >
            <Editable
              defaultValue={data?.employee.default_memo.description}
              w={'full'}
              onEdit={() => setIsEditMode(true)}
              onCancel={() => {
                toast({
                  ...saveCancle,
                  description: '메모 저장 취소',
                });
              }}
              onSubmit={async () => {
                const formData = new FormData();
                setIsEditMode(false);
                formData.append('description', description);
                await updateTenantMemo({
                  formData,
                  user_id: id,
                });
              }}
              onChange={setDescription}
            >
              <EditablePreview
                px={3}
                py={2}
                layerStyle={'gray'}
                w="full"
                h="250px"
                cursor="pointer"
                whiteSpace={'pre-wrap'}
              />
              <EditableTextarea h="250px" px={3} py={2} />
            </Editable>
          </Tooltip>
        </VStack>
        {/* 파일 추가 */}
        <Formik
          initialValues={{
            title: '',
            content: '',
            files: [],
          }}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            const hasFile = !_.isEmpty(values.files);
            setSubmitting(true);

            const formData = new FormData();
            setIsEditMode(false);
            values.files.map((file) => formData.append('files', file));

            if (!hasFile) {
              return toast({ ...saveFail, description: '한 장 이상의 파일을 업로드 해주세요' });
            }
            await updateTenantMemo({
              formData,
              user_id: id,
            }).finally(() => {
              setSubmitting(false);
              resetForm();
            });
          }}
        >
          {({ handleChange, values, handleSubmit, setFieldValue }) => {
            const hasFile = !_.isEmpty(values.files);
            return (
              <VStack spacing={3} justifyContent={'start'} align={'flex-start'} w={'100%'} divider={<StackDivider />}>
                <HStack w={'full'} spacing={4} alignItems={'flex-end'} justifyContent={'space-between'}>
                  <Box fontWeight={'bold'} fontSize={'18px'} textAlign={'start'}>
                    파일 추가
                  </Box>
                  <Button type={'submit'} form={'save-file'} isDisabled={!hasFile} variant={'basic'}>
                    파일 저장하기
                  </Button>
                </HStack>
                <Form id="save-file" style={{ display: 'flex', width: '100%' }}>
                  <VStack w={'100%'} spacing={6}>
                    <HStack w={'100%'}>
                      <Dropzone
                        // accept={'image/*'}
                        onDrop={(acceptedFiles) => {
                          if (acceptedFiles.length === 0) {
                            return;
                          } else {
                            setFieldValue('files', (values.files as any).concat(acceptedFiles));
                          }
                        }}
                      >
                        {({ isDragActive, isDragReject, acceptedFiles, getRootProps, getInputProps }) => {
                          return (
                            <Flex flexDir={'column'} w={'full'}>
                              <Center {...getRootProps()} w={'full'}>
                                <input {...getInputProps()} />
                                <Center
                                  layerStyle={'gray'}
                                  w={'full'}
                                  h={'120px'}
                                  cursor="pointer"
                                  fontWeight={'medium'}
                                  _hover={{ bgColor: 'zetaBlue', color: 'white' }}
                                  transition={'0.18s linear'}
                                >
                                  <Box>첨부 파일을 추가해주세요.</Box>
                                </Center>
                              </Center>
                              <Collapse style={{ width: '100%' }} in={values.files.length !== 0}>
                                <HStack mt={6}>
                                  {values.files.map((file, i) => (
                                    <Thumb key={i} file={file} />
                                  ))}
                                </HStack>
                              </Collapse>
                            </Flex>
                          );
                        }}
                      </Dropzone>
                    </HStack>
                  </VStack>
                </Form>
              </VStack>
            );
          }}
        </Formik>
        {/* 파일 목록 */}
        <Formik
          initialValues={{
            file_infos: data?.employee.default_memo.file_urls?.map((url) => ({
              url,
              removed: false,
            })) as FileInfosProps[] | undefined,
          }}
          enableReinitialize
          onSubmit={async (values, { setSubmitting, setFieldValue, resetForm }) => {
            setSubmitting(true);

            const formData = new FormData();

            const [no, filtered_urls] = filterRemovedFiles(values.file_infos);

            if (_.isEmpty(filtered_urls)) {
              formData.append('file_urls', JSON.stringify([]));
            } else {
              filtered_urls?.map((url) => formData.append('file_urls[]', url));
            }

            await updateTenantMemo({
              formData,
              user_id: id,
            }).finally(() => {
              setSubmitting(false);
              setIsEditable(false);
            });
          }}
        >
          {(props) => {
            const isNotAnyChange = _.isEqual(props.initialValues, props.values);
            const { values, setFieldValue } = props;

            return (
              <Form id="life" style={{ width: '100%' }}>
                <VStack spacing={3} justifyContent={'start'} align={'flex-start'} w={'100%'} divider={<StackDivider />}>
                  <HStack w={'full'} spacing={4} alignItems={'flex-end'} justifyContent={'space-between'}>
                    <Box fontWeight={'bold'} fontSize={'18px'} textAlign={'start'}>
                      파일 목록
                    </Box>
                    <InitFormikEditableButton
                      props={props}
                      isDisabledCompleteEdit={isNotAnyChange}
                      isEditable={isEditable}
                      setIsEditable={setIsEditable}
                    />
                  </HStack>
                  <DropzoneOpacityFormikThumb file_infos={values.file_infos} onDeleteBtn={isEditable} />
                </VStack>
              </Form>
            );
          }}
        </Formik>
      </VStack>
    </Skeleton>
  );
};

export default Lmemo;

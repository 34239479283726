import { Box, Grid, GridItem, HStack, Tag } from '@chakra-ui/react';
import { orderBy } from 'firebase/firestore';
import { isUndefined } from 'lodash';
import { useEffect, useRef } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { useCollection, useDocumentData } from 'react-firebase-hooks/firestore';
import { useParams } from 'react-router-dom';
import { useGetCompanyManager } from '../../../lib/admin/hooks/query';
import { useGetFirebaseIdById } from '../../../lib/common/hooks/query';
import { tenanStatusTypeTrans } from '../../../lib/subscriber/utils/utils';
import { phoneNumberdashfy } from '../../../lib/common/utils/digitformatter';
import { chatroomDocRef, getUserId, messagesQuery } from '../../../lib/common/utils/firebase/init';
import ChatSenderBar from './ChatSenderBar';
import ChatBox from '../../common/Box/ChatBox';

const ChatRoom = () => {
  const params = useParams();
  const chatroomid = params.chatroomid ? params.chatroomid : '';

  const [data] = useDocumentData(chatroomDocRef(chatroomid));

  const the_other_one_data = data?.users?.filter(
    (user_data: { company_name: string; user_name: string; user_type: string; _id: string }) => {
      if (user_data.user_type === 'master') {
        return user_data._id;
      }
    },
  )[0]._id;
  console.log(the_other_one_data);

  const { data: cp } = useGetCompanyManager(the_other_one_data);

  if (isUndefined(cp)) {
    return <></>;
  }

  return (
    <Grid
      id="grid-container"
      templateAreas={`"header   header" 
                      "chatroom chatroom"
                      "input1   input1"`}
      gridTemplateRows={'auto auto 1fr'}
      h={'full'}
      // ref={componentEndRef}
    >
      {/* 상단 */}
      <GridItem area={'header'} id="header">
        <Box borderBottom="1px solid" borderColor={'silverGray'} boxShadow="0px 2px 4px 0px #0000001A">
          <HStack px="6" py="3" h={'71px'}>
            <Box fontSize={'31px'} fontWeight={'bold'}>
              {cp?.employee.user_name}
            </Box>
            <Tag variant={'green'} h={'20px'}>
              {phoneNumberdashfy(cp?.employee.phone_number_first)}
            </Tag>
          </HStack>
        </Box>
      </GridItem>
      {/* 중단 */}
      <GridItem area={'chatroom'} id="chatroom" minH={0} h="400px">
        {/* <Box flexGrow={1}> */}
        {/* <Scrollbars style={{ height: '100%' }}> */}
        <ChatBox />
        {/* </Scrollbars> */}
        {/* </Box> */}
        <Box id="chatroom-bottom" />
        {/* <Box id="chatroom-bottom" border="1px solid black" /> */}
      </GridItem>
      {/* 하단 */}
      <GridItem area={'input1'} id="input1">
        <ChatSenderBar />
      </GridItem>
    </Grid>
  );
};

export default ChatRoom;

import {
  Avatar,
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Tag,
  Textarea,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import { CustomInput } from '../../../../../components/common/Input';
// import { loadUserLists, saveMessage, testCollection } from '../../../../lib/firebase/init';

const ChatList = () => {
  const initialTextChat = '';
  const [textChat, setTextChat] = useState<string>(initialTextChat);

  // selected item
  const initialSelectedId = 0;
  const [selectedId, setSelectedId] = useState<string | number>(initialSelectedId);

  const onCheckEnter = (e: any) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      handleSubmit(e);
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    console.log(textChat);
    // saveMessage(textChat);
    setTextChat(initialTextChat);
  };

  const testSubmit = (e: any) => {
    e.preventDefault();
    // testCollection('test');
    // loadUserLists();
  };

  useEffect(() => {
    // loadUserLists();
    // console.log('test => ', loadUserLists());
  }, []);
  return (
    <Flex flexDir={'column'} h="100%">
      {/* 상단 */}
      <Box border="1px solid" borderColor={'silverGray'} borderBottom={'unset'} borderTopRadius={'0.3rem'}>
        <HStack px="6" py="3" h={'71px'} spacing={1}>
          <InputGroup>
            <InputLeftElement pointerEvents="none" children={<FaSearch color={'#A0A0A0'} />} />
            <Input type="search" fontSize={'13px'} h={'36px'} bgColor={'white'} placeholder={'성함/요청번호'} />
          </InputGroup>
          <Button variant={'basic'} w={'90px'}>
            조회
          </Button>
        </HStack>
      </Box>
      {/* 중단 */}
      <Box flexGrow={1} border="1px solid" borderColor={'silverGray'} borderBottom={'unset'}>
        <Flex flexDir={'column'}>
          {/* 탭 */}
          <Flex px={3} py={4} alignItems={'center'} borderBottom={'1px solid'} borderColor={'silverGray'}>
            <Button variant={'basic'} borderRadius={'full'} style={{ width: '133px', height: '38px' }}>
              전체 채팅
            </Button>
            <Button variant={'white'} borderRadius={'full'} style={{ width: '133px', height: '38px' }}>
              채팅방 생성
            </Button>
          </Flex>
          {/* 리스트 */}
          <Flex flexDir={'column'}>
            {/* 1 */}
            <Flex
              cursor={'pointer'}
              userSelect={'none'}
              borderBottom={'1px solid'}
              borderColor={'silverGray'}
              py={5}
              pr={5}
              flexGrow={1}
              transition={'0.2s ease'}
              bgColor={'primarySkyblue'}
              _hover={{ bgColor: 'primarySkyblue' }}
            >
              <Box px={5}>
                <Avatar name="김한별" />
              </Box>
              <Flex flexDir={'column'}>
                <Flex fontWeight={'bold'} alignItems={'center'}>
                  <Box fontSize={'19px'}>김한별</Box>
                  <Box
                    fontSize={'12px'}
                    color="silverGray"
                    _before={{ content: '""', borderLeft: '1px solid #C4C4C4', marginInline: '8px' }}
                  >
                    12345678
                  </Box>
                </Flex>
                <Box
                  fontWeight={'bold'}
                  fontSize={'14px'}
                  overflow="hidden"
                  whiteSpace={'nowrap'}
                  textOverflow={'ellipsis'}
                  maxW={'180px'}
                >
                  안녕하세요! 문의드립니다문의드립니다 문의드립니다.
                </Box>
                <Tag variant={'green'} boxSize={'max-content'} width={'62px'} justifyContent={'center'} mt={1}>
                  투어 중
                </Tag>
              </Flex>
              <Flex flexGrow={1} justifyContent={'flex-end'}>
                <Flex top={'3px'} fontSize={'11px'} fontWeight={'bold'} color="silverGray">
                  지금
                </Flex>
              </Flex>
            </Flex>
            {/* 2 */}
            <Flex borderBottom={'1px solid'} borderColor={'silverGray'} py={5} pr={5} flexGrow={1}>
              <Box px={5}>
                <Avatar name="김한별" />
              </Box>
              <Flex flexDir={'column'}>
                <Flex fontWeight={'bold'} alignItems={'center'}>
                  <Box fontSize={'19px'}>김한별</Box>
                  <Box
                    fontSize={'12px'}
                    color="silverGray"
                    _before={{ content: '""', borderLeft: '1px solid #C4C4C4', marginInline: '8px' }}
                  >
                    12345678
                  </Box>
                </Flex>
                <Box
                  fontWeight={'bold'}
                  fontSize={'14px'}
                  overflow="hidden"
                  whiteSpace={'nowrap'}
                  textOverflow={'ellipsis'}
                  maxW={'180px'}
                >
                  안녕하세요! 문의드립니다문의드립니다 문의드립니다.
                </Box>
                <Tag variant={'green'} boxSize={'max-content'} width={'62px'} justifyContent={'center'} mt={1}>
                  투어 중
                </Tag>
              </Flex>
              <Flex flexGrow={1} justifyContent={'flex-end'}>
                <Flex top={'3px'} fontSize={'11px'} fontWeight={'bold'} color="silverGray">
                  지금
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Box>
      {/* 하단 */}
      <Box
        borderBottom="1px solid"
        borderColor={'silverGray'}
        borderBottomRadius={'0.3rem'}
        h={'4%'}
        bgColor={'zetaBlue'}
      />
    </Flex>
  );
};

export default ChatList;

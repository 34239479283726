import { Flex, HStack, StackDivider, Table, Tbody, Text, Tr, VStack } from '@chakra-ui/react';
import { Field, useFormikContext } from 'formik';
import { CustomTd } from '../type';
import { FCheckboxButtonGroup } from '../../../../Checkbox/base';
import { CustomInput } from '../../../../Input';
import { FRadioButtonGroup } from '../../../../Radio';
import { security_facilities_checkbox } from '../../../../../../statics/common/data/checkbox';
import {
  elevator_radio,
  entrance_type_radio,
  parking_available_radio,
} from '../../../../../../statics/common/data/radio';

const FacilityInfoTable = () => {
  const formik = useFormikContext<any>();
  const { values } = formik;
  return (
    <>
      <Table w={'100%'} variant="simple">
        <Tbody w={'100%'}>
          <Tr w={'100%'}>
            <CustomTd title={'현관유형'}>
              <Flex w={'20%'}>
                <Field name="entrance_type" as={FRadioButtonGroup} options={entrance_type_radio} />
              </Flex>
            </CustomTd>
          </Tr>
          <Tr w={'100%'}>
            <CustomTd title={'엘리베이터'} required>
              <Flex w={'20%'}>
                <Field name="is_elevator" as={FRadioButtonGroup} options={elevator_radio} />
              </Flex>
            </CustomTd>
          </Tr>
          <Tr w={'100%'}>
            <CustomTd title={'보안시설'}>
              <Flex w={'100%'}>
                <Field name="security_facilities" as={FCheckboxButtonGroup} options={security_facilities_checkbox} />
              </Flex>
            </CustomTd>
          </Tr>
          <Tr w={'100%'}>
            <CustomTd title={'주차시설'} required>
              <VStack alignItems={'flex-start'} divider={<StackDivider borderColor="gray.200" />}>
                <Field name="is_parking" as={FRadioButtonGroup} options={parking_available_radio} />
                <HStack w={'70%'} spacing={7}>
                  <HStack minW="183px" w="183px">
                    <Text minW="75px">총 주차대수</Text>
                    <Field
                      name="parking_num"
                      as={CustomInput}
                      bgColor="lotionWhite"
                      addon="대"
                      isDisabled={values.is_parking === 'false'}
                    />
                  </HStack>
                  <HStack minW="183px" w="183px">
                    <Text minW="75px">월 주차비용</Text>
                    <Field
                      name="parking_cost"
                      as={CustomInput}
                      bgColor="lotionWhite"
                      addon="원"
                      isDisabled={values.is_parking === 'false'}
                    />
                  </HStack>
                </HStack>
              </VStack>
            </CustomTd>
          </Tr>
        </Tbody>
      </Table>
    </>
  );
};

export default FacilityInfoTable;

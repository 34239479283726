import {
  Box,
  Center,
  ComponentWithAs,
  Flex,
  HStack,
  StackProps,
  TableCellProps,
  TableColumnHeaderProps,
  Th,
} from '@chakra-ui/react';
import { BelowArrowIcon } from '../../../../statics/common/icons';

interface WThProps extends TableColumnHeaderProps {
  borderR?: boolean;
  borderL?: boolean;
  tFilter?: 'asc' | 'desc';
  flexProps?: StackProps;
}

export const WTh: ComponentWithAs<'th', WThProps> = ({ children, borderR, borderL, tFilter, flexProps, ...props }) => {
  return (
    <Th
      borderRight={borderR ? '1px solid rgb(235,240,246)' : 'unset'}
      borderLeft={borderL ? '1px solid rgb(235,240,246)' : 'unset'}
      fontSize={'14px'}
      fontWeight={'medium'}
      wordBreak={'keep-all'}
      cursor={tFilter ? 'pointer' : 'unset'}
      {...props}
    >
      <HStack justifyContent={'center'} {...flexProps}>
        <Box>{children}</Box>
        {tFilter && (
          <Box transition="transform ease 0.2s" transform={tFilter === 'asc' ? 'rotate(180deg)' : 'rotate(0deg)'}>
            <BelowArrowIcon />
          </Box>
        )}
      </HStack>
    </Th>
  );
};

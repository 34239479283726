import { Button } from '@chakra-ui/react';
import { useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import RoomListDrawer from '../../../components/admin/Drawer/RoomListDrawer';

const PlaygroundTwo = () => {
  const [value, setValue] = useState('');
  const a = 1;
  const b = 0;

  // console.log(_.isEmpty(undefined));

  // return <Editor placeholder={'Write something...'} />;
  return (
    <RoomListDrawer
    // variant={'overlayClickable'}
    >
      <Button>TEST</Button>
    </RoomListDrawer>
  );
};

export default PlaygroundTwo;

import {
  Box,
  Button,
  Center,
  Editable,
  EditablePreview,
  EditableTextarea,
  Flex,
  HStack,
  Radio,
  RadioGroup,
  StackDivider,
  VStack,
} from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { MemoProps } from '../../../common/Memo/base';
import Editor from '../../../common/Editor/EditorWithUseQuill';
import { useUpdateAdminRelayMemo, useUpdateCompanyRoomMemo } from '../../../../lib/common/hooks/mutation';
import EditableControls, { NoteButtonIcon } from '../../../common/Memo/EditableControls';
import { MemoPhase } from '../../../../types/common/Memo';

const AdminMemo: React.FC<MemoProps> = ({ editableAll, userType, data }) => {
  const [memoStep, setMemoStep] = useState<MemoPhase>('admin');
  const [isEditable, setIsEditable] = useState<boolean>(false);

  // const { mutateAsync: updateRoomAdminMemo } = useUpdateRoomAdminMemo();
  const { mutateAsync: updateCompanyRoomMemo } = useUpdateCompanyRoomMemo();
  //
  const { mutateAsync: updateAdminRelayMemo } = useUpdateAdminRelayMemo();

  console.log('data =>', data);

  const employee = data?.employee;
  const custom_room_request = employee?.custom_room_request;
  const recommended_room = custom_room_request?.recommended_room;
  const room = recommended_room?.room;
  const company_memo = room?.company_memo;
  const agent_relay_memo = recommended_room?.agent_relay_memo;
  const admin_relay_memo = recommended_room?.admin_relay_memo;

  const [memo, setMemo] = useState<string>(company_memo || '');

  useEffect(() => {
    const memoPlaceholder = {
      admin: company_memo || '비공개 보홈 관리자 메모입니다.',
      agency: agent_relay_memo || '중개사 코멘트입니다.',
      bohome: admin_relay_memo || '보홈 코멘트입니다.',
      suggestion: '',
    }[memoStep];
    setMemo(memoPlaceholder);
  }, [memoStep]);

  return (
    <Box>
      <Formik
        initialValues={{
          description: admin_relay_memo,
        }}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          console.log('submit');
          const formData = new FormData();
          formData.append('description', values.description || '');
          await updateAdminRelayMemo({
            formData,
            custom_room_request_id: custom_room_request?._id,
            room_id: room?._id,
          })
            .then(() => {
              setIsEditable(false);
            })
            .finally(() => {
              setSubmitting(false);
            });
        }}
      >
        {(props) => {
          console.log('props=>', props.values.description);

          return (
            <Box
              bgColor={'lotionWhite'}
              border={'1px solid'}
              borderColor={'silverGray'}
              borderRadius={'md'}
              fontWeight={'medium'}
            >
              <Box p={1}>
                <VStack divider={<StackDivider style={{ margin: 0 }} />} w="full">
                  <Editable
                    fontSize="14px"
                    isPreviewFocusable={false}
                    w={'100%'}
                    h={'100%'}
                    value={memo}
                    onChange={(v) => setMemo(v)}
                    submitOnBlur={false}
                    onSubmit={(v) => {
                      if (memoStep === 'admin') {
                        updateCompanyRoomMemo({
                          company_memo: v,
                          room_id: room?._id,
                        });
                      }
                    }}
                  >
                    <Flex justifyContent={'space-between'} alignItems={'center'}>
                      <RadioGroup onChange={(e: MemoPhase) => setMemoStep(e)} value={memoStep} size={'sm'}>
                        <HStack>
                          <Radio value="admin">관리자 메모</Radio>
                          {/* <Radio value="agency">중개사 코멘트</Radio> */}
                          {/* <Radio value="bohome">보홈 코멘트</Radio> */}
                        </HStack>
                      </RadioGroup>
                      <Box>
                        <EditableControls />
                      </Box>
                    </Flex>
                    <EditablePreview my={1} />
                    <EditableTextarea my={1} />
                  </Editable>
                  <Form style={{ width: '100%' }}>
                    <Center justifyContent={'space-between'} mb={1}>
                      <Radio size={'sm'} isChecked>
                        <Box fontSize={'14px'}>보홈 코멘트</Box>
                      </Radio>
                      <Center>
                        <NoteButtonIcon
                          onClick={() => {
                            setIsEditable(!isEditable);
                          }}
                        />
                      </Center>
                    </Center>
                    <Editable>
                      <Flex flexDir={'column'}>
                        {isEditable && (
                          <Button type="submit" variant={'basic'}>
                            코멘트 남기기
                          </Button>
                        )}
                        <Editor
                          formikKey={'description'}
                          refProps={{ h: '80px' }}
                          readOnly={true}
                          isEditable={isEditable}
                        />
                      </Flex>
                    </Editable>
                  </Form>
                </VStack>
              </Box>
            </Box>
          );
        }}
      </Formik>
    </Box>
  );
};

export default AdminMemo;

import { Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { format } from 'date-fns';
import { UseQueryResult } from 'react-query';
import { Link } from 'react-router-dom';
import ChangeCertificateIssueStatus from '../../../../../../../../components/common/SelectBox/ChangeCertificateIssueStatus';
import ChangeSettlementPaymentStatus from '../../../../../../../../components/common/SelectBox/ChangeSettlementPaymentStatus';
import ChangeLeasePaymentStatus from '../../../../../../../../components/common/SelectBox/LeasePaymentStatus';
import ChangeLeaseProxyPaymentStatus from '../../../../../../../../components/common/SelectBox/LeaseProxyPaymentStatus';
import TextLink from '../../../../../../../../components/common/Link/TextLink';
import {
  HostLeaseContractLeasePayments,
  QueryResponse,
  TenantLeaseContract,
} from '../../../../../../../../lib/common/utils/axios/types';
import { paymentMethodTypeTrans } from '../../../../../../../../lib/subscriber/utils/utils';
import { datedotformat } from '../../../../../../../../lib/common/utils/dateformatter';
import { staticThreeCommafy } from '../../../../../../../../lib/common/utils/digitformatter';

interface SettlementsTableProps<Type> {
  res?: UseQueryResult<QueryResponse<Type>, unknown>;
}

const SettlementsTable: React.FC<SettlementsTableProps<HostLeaseContractLeasePayments[]>> = ({ res }) => {
  console.log('res.data =>', res?.data);

  return (
    <Table variant="head_silverbg_toplining">
      <Thead bgColor={'#F8F8F8'} borderColor={'gray.100'}>
        <Tr>
          <Th>청구 종류</Th>
          <Th>유저이름</Th>
          <Th>송금인명</Th>
          <Th>청구 금액</Th>
          <Th>납부 상태</Th>
          <Th>정산 상태</Th>
          <Th>납기일</Th>
          <Th>납부일</Th>
          <Th>정산일</Th>
          <Th>납부방법</Th>
          <Th>증빙발행</Th>
          {/* <Th>대납 상태</Th> */}
        </Tr>
      </Thead>
      <Tbody>
        {res?.data?.result.map((payment, index) => {
          const {
            employee: {
              // 송금인
              user_name,
              _id: user_id,
              lease_contract: {
                _id: lease_contract_id,
                contract_start_date,
                contract_end_date,
                tenant,
                lease_payment: {
                  _id: lease_payment_id,
                  host,
                  // 납기일
                  expected_payment_date,
                  // 납부일
                  real_payment_date,
                  // 정산일
                  real_transfer_date,
                  payment_method_type,
                  certificate_issue_type,
                  proxy_payment_status,
                  is_paid,
                  is_transferred,
                  price,
                  host_id,
                  contract_payment,
                  management_fee,
                  payment_status,
                },
              },
            },
          } = payment;
          console.log(contract_payment, management_fee, price);

          return (
            <Tr key={lease_payment_id + index}>
              <Td>임대료({new Date(expected_payment_date).getMonth() + 1}월)</Td>
              <Td>
                <Text> {tenant?.user_name}</Text>
              </Td>
              <Td>
                <Text> {tenant?.virtual_account?.account_owner_name || tenant?.user_name}</Text>
              </Td>
              <Td>{staticThreeCommafy(contract_payment + management_fee + price)}원</Td>
              <Td>
                <ChangeLeasePaymentStatus
                  payment_status={payment_status}
                  lease_payment_id={lease_payment_id}
                ></ChangeLeasePaymentStatus>
              </Td>
              <Td>
                <ChangeSettlementPaymentStatus is_transferred={is_transferred} lease_payment_id={lease_payment_id} />
              </Td>
              <Td>{datedotformat(expected_payment_date)}</Td>
              <Td>{datedotformat(real_payment_date)}</Td>
              <Td>{datedotformat(real_transfer_date)}</Td>
              <Td>{paymentMethodTypeTrans(payment_method_type)}</Td>
              <Td>
                <ChangeCertificateIssueStatus
                  certificate_issue_status={certificate_issue_type}
                  lease_payment_id={lease_payment_id}
                ></ChangeCertificateIssueStatus>
              </Td>
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
};

export default SettlementsTable;

import { HStack, StackProps } from '@chakra-ui/react';

const OptionBtn: React.FC<StackProps> = ({ children, ...props }) => (
  <HStack
    spacing={1}
    bgColor="lotionWhite"
    p="6px"
    border="1px solid"
    borderColor={'rgb(227, 231, 241)'}
    borderRadius={'0.3rem'}
    cursor={'pointer'}
    pointerEvents={'unset'}
    transition={'0.15s ease'}
    _hover={{ bgColor: 'zetaBlue', color: 'white' }}
    {...props}
  >
    {children}
  </HStack>
);

export default OptionBtn;

import { Box, ListItem, Text, UnorderedList, VStack } from '@chakra-ui/react';
import { Container } from '../sections/Container';
import { Main } from '../sections/Main';

export const ServiceTerm = () => (
  <Container height="100%">
    <Main>
      <VStack alignItems={'start'}>
        <VStack alignItems={'start'} spacing={3}>
          {/* 제1조 */}
          <VStack alignItems={'start'}>
            <Box fontWeight={'bold'} color="zetaBlue">
              제1조 목적
            </Box>
            <Box fontSize={'11px'}>
              이 약관은 주식회사 리버블 (이하 “회사”) 이 제공하는 인터넷 서비스 및 모바일 어플리케이션(이하
              “서비스”)에서 제공하는 서비스의 이용과 관련하여 회사와 이용자와의 권리, 의무 및 책임사항, 기타 필요한
              사항을 규정함을 목적으로 합니다.
            </Box>
          </VStack>
          {/* 제2조 */}
          <VStack alignItems={'start'}>
            <Box fontWeight={'bold'} color="zetaBlue">
              제2조 정의
            </Box>
            <Box fontSize={'11px'}>이 약관에서 사용하는 용어의 정의는 다음과 같습니다.</Box>
            <Box>
              <UnorderedList fontSize={'11px'} pl={2}>
                <ListItem>
                  “서비스”란 고객사의 주거복지 도입 및 운영관리를 지원는 회사가 제공하는 일체의 서비스를 말합니다.
                </ListItem>
                <ListItem>
                  "이용자"라 함은 "회사"가 제공하는 서비스 이용계약을 체결하고 회사가 제공하는 서비스를 이용하는 개인
                  또는 기업을 의미합니다.
                </ListItem>
              </UnorderedList>
            </Box>
          </VStack>
          {/* 제3조 */}
          <VStack alignItems={'start'}>
            <Box fontWeight={'bold'} color="zetaBlue">
              제3조 약관 등의 명시와 설명 및 개정
            </Box>
            <VStack alignItems={'start'} fontSize={'11px'} spacing={0}>
              <Box fontSize={'11px'}>
                회사는 이 약관의 내용을 업체회원이 쉽게 알 수 있도록 서비스 내 또는 연결화면을 통하여 온라인으로
                게시합니다.
              </Box>
              <Box fontSize={'11px'}>
                회사는 「약관의규제에관한법률」, 「정보통신망이용촉진및정보보호등에관한법률(이하 “정보통신망법”)」 등
                관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
              </Box>
              <Box fontSize={'11px'}>
                회사가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 제 1항의 방식에 따라 그
                개정약관의 적용일자 7일전부터 적용일자 전일까지 공지합니다. 다만 업체회원의 권리, 의무에 중대한 영향을
                주는 변경인 경우에는 적용일자 30일 전부터 공지하도록 합니다.
              </Box>
              <Box fontSize={'11px'}>
                회사가 약관을 개정할 경우 변경된 약관은 공지된 시점부터 그 효력이 발생하며, 업체회원은 약관이 변경된
                후에도 반대의사를 표하지 않고 본 서비스를 계속 이용함으로써 변경 후의 약관에 대해 동의를 한 것으로
                간주됩니다.
              </Box>
            </VStack>
          </VStack>
          {/* 제4조 */}
          <VStack alignItems={'start'}>
            <Box fontWeight={'bold'} color="zetaBlue">
              제4조 (서비스의 제공 및 변경)
            </Box>
            <VStack alignItems={'start'} spacing={0}>
              <Box fontSize={'11px'}>회사는 다음과 같은 서비스를 제공합니다.</Box>
              <Box>
                <UnorderedList fontSize={'11px'} pl={2} pb={2}>
                  <ListItem>이용자를 대신하여 주택 임대차 계약 신고를 진행하는 서비스</ListItem>
                </UnorderedList>
              </Box>
              <Box fontSize={'11px'}>
                회사는 제공하고 있는 서비스의 내용을 운영상, 기술적 사양 등의 사유로 일부 또는 전부 변경할 수 있습니다.
              </Box>
              <Box fontSize={'11px'}>
                서비스의 변경이 실질적으로 이용자에게 불리한 것이 아닌 경우 그 사유를 이용자에게 통지하거나, 이용자가
                알아볼 수 있도록 공지사항으로 게시합니다.
              </Box>
              <Box fontSize={'11px'}>
                회사는 정보 제공 목적으로 이용자에게 이메일, 문자 메시지, 푸시 알림(App Push), 알림톡을 발송 할 수
                있습니다.
              </Box>
            </VStack>
          </VStack>
          {/* 제5조 */}
          <VStack alignItems={'start'}>
            <Box fontWeight={'bold'} color="zetaBlue">
              제5조 (서비스의 중단)
            </Box>
            <VStack alignItems={'start'} spacing={0}>
              <Box fontSize={'11px'}>
                회원의 서비스 기간은 회사에 서비스를 신청하여 이용 승낙이 있은 날로부터 이용 계약 해지 시까지입니다.
              </Box>
              <Box fontSize={'11px'}>
                회사는 컴퓨터 등 정보 통신 설비의 보수 점검, 교체 및 고장, 통신의 두절, 천재지변 등 운영상 상당한 이유가
                있는 경우에는 서비스의 제공을 제한하거나 일시적으로 중단할 수 있습니다. 이 경우 서비스 일시 중단 사실과
                그 사유를 사전에 사이트 초기 화면에 통지합니다. 다만, 회사가 사전에 통지할 수 없는 부득이한 사유가 있는
                경우 제8조에 정한 방법으로 사후에 통지할 수 있습니다.
              </Box>
              <Box fontSize={'11px'}>
                사업종목의 전환, 사업의 포기, 업체 간의 통합 등의 이유로 서비스를 제공할 수 없게 되는 경우에는 회사는
                제8조에 정한 방법으로 충분한 기간을 두어 이용자에게 통지하거나 이용자가 알아볼 수 있도록 공지 사항으로
                게시합니다.{' '}
              </Box>
            </VStack>
          </VStack>
          {/* 제6조 */}
          <VStack alignItems={'start'}>
            <Box fontWeight={'bold'} color="zetaBlue">
              제6조 회사의 의무
            </Box>
            <VStack alignItems={'start'} spacing={0}>
              <Box fontSize={'11px'}>
                회사는 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 이 약관이 정하는 바에 따라
                지속적이고, 안정적으로 업체회원에게 서비스를 제공하기 위해 최선을 다합니다.
              </Box>
            </VStack>
          </VStack>
          {/* 제7조 */}
          <VStack alignItems={'start'}>
            <Box fontWeight={'bold'} color="zetaBlue">
              제7조 이용자의 의무
            </Box>
            <VStack alignItems={'start'} spacing={2}>
              <Box fontSize={'11px'}>
                이용자는 다음 각호의 행위를 하여서는 안됩니다. 만약 다음 각호와 같은 행위가 확인될 경우 회사는
                이용자에게 서비스 이용에 대한 제재를 가할 수 있으며 민형사상의 책임을 물을 수 있습니다.
              </Box>
              <Box>
                <UnorderedList fontSize={'11px'} pl={2}>
                  <ListItem>회사 서비스의 운영을 고의 및 과실로 방해하는 경우</ListItem>
                  <ListItem>신청 또는 변경 시 허위내용의 등록</ListItem>
                  <ListItem>타인의 정보 도용</ListItem>
                  <ListItem>회사가 정한 정보 이외의 정보 등의 송신 또는 게시</ListItem>
                  <ListItem>회사 및 기타 제3자의 저작권 등 지적재산권에 대한 침해</ListItem>
                  <ListItem>회사 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위</ListItem>
                  <ListItem>
                    외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 서비스 내에 공개 또는 게시하는
                    행위
                  </ListItem>
                  <ListItem>
                    기타 중대한 사유로 인하여 회사가 서비스 제공을 지속하는 것이 부적당하다고 인정하는 경우
                  </ListItem>
                </UnorderedList>
              </Box>
              <Box fontSize={'11px'}>
                회사는 전항의 규정에 의하여 서비스의 이용을 제한하거나 중지할 수 있는 모든 권한을 가지고 있습니다.
                회사는 회사 정책에 위반한 행동을 하는 이용자를 제재할 수 있고, 이용 중지 등 모든 서비스 제한조치를
                이용자에게 통보 없이 직권으로 할 수 있습니다.
              </Box>
            </VStack>
          </VStack>
          {/* 제8조 */}
          <VStack alignItems={'start'}>
            <Box fontWeight={'bold'} color="zetaBlue">
              제8조 책임의 한계 및 면책 등
            </Box>
            <Box>
              <UnorderedList fontSize={'11px'} pl={2}>
                <ListItem>
                  회사는 천재지변, 불가항력, 서비스용 설비의 보수, 교체, 점검, 공사 등 기타 이에 준하는 사항으로 정보 및
                  서비스를 제공할 수 없는 경우에 이에 대한 책임이 면제됩니다.
                </ListItem>
                <ListItem>
                  회사는 이용자의 귀책사유로 인한 정보 및 서비스 이용의 장애에 관하여 책임을 지지 않습니다.
                </ListItem>
                <ListItem>
                  회사는 이용자가 게재한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 관하여는 책임을 지지 아니합니다.
                </ListItem>
                <ListItem>
                  회사는 회원 상호간 또는 회원과 제3자 상호간 회사의 서비스를 매개로 발생한 분쟁에 대하여는 분쟁조정의
                  의무 및 손해배상의 책임이 없습니다.
                </ListItem>
              </UnorderedList>
            </Box>
          </VStack>
          {/* 제9조 */}
          <VStack alignItems={'start'}>
            <Box fontWeight={'bold'} color="zetaBlue">
              제9조 손해배상
            </Box>
            <VStack alignItems={'start'} spacing={0}>
              <Box fontSize={'11px'}>
                회사는 이용자가 서비스를 이용함에 있어 회사의 고의 또는 과실로 인하여 손해가 발생한 경우에는 민법 등
                관련 법령이 규율하는 범위 내에서 그 손해를 배상합니다.
              </Box>
              <Box fontSize={'11px'}>
                이용자가 이 약관을 위반하거나 관계법령을 위반하여 회사에 손해가 발생한 경우에는 회사에 그 손해를
                배상하여야 합니다.
              </Box>
              <Box fontSize={'11px'}>
                이용자가 이 약관을 위반하거나 관계법령을 위반하여 제3자가 회사를 상대로 민형사상의 법적 조치를 취하는
                경우 이용자가 자신의 비용과 책임으로 회사를 면책시켜야 하며, 이로 인해 발생하는 손해에 대해 배상하여야
                합니다.
              </Box>
            </VStack>
          </VStack>
          {/* 제10조 */}
          <VStack alignItems={'start'}>
            <Box fontWeight={'bold'} color="zetaBlue">
              제10조 재판권 및 준거법
            </Box>
            <VStack alignItems={'start'} spacing={0}>
              <Box fontSize={'11px'}>회사와 회원간 제기된 소송은 대한민국법을 준거법으로 합니다.</Box>
              <Box fontSize={'11px'}>
                회사와 회원간 발생한 분쟁에 관한 소송은 민사소송법 상의 관할법원에 제소합니다.
              </Box>
              <Box fontSize={'11px'}>이 약관은 2022년 5월 30일 공고, 6월 1일부터 적용됩니다.</Box>
            </VStack>
          </VStack>
          <Text fontSize={'11px'} color="gray">
            *위 동의를 거부할 권리가 있으며, 동의를 거부하실 경우 서비스 이용이 제한됩니다.
          </Text>
        </VStack>
      </VStack>
    </Main>
  </Container>
);

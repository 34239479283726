import {
  Avatar,
  Box,
  Button,
  Center,
  Flex,
  Grid,
  HStack,
  Skeleton,
  Spinner,
  Table,
  Tbody,
  Text,
  Thead,
  Tr,
  useClipboard,
  VStack,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { useState } from 'react';
import { GTitleItem } from '../../../../../components/common/GridItem';
import { WTd } from '../../../../../components/common/Table/Td';
import { WTh } from '../../../../../components/common/Table/Th';
import TextLink from '../../../../../components/common/Link/TextLink';
import {
  useGetAllCompanyContractPayments,
  useGetCompanyContract,
  useGetCompanyInfomation,
} from '../../../../../lib/subscriber/hooks/query';
import { checkArrAndGetFirst, paymentMethodTypeTrans } from '../../../../../lib/subscriber/utils/utils';
import { DoubleRect } from '../../../../../statics/common/icons';
import bank_mask_logo from '../../../../../statics/common/images/sinhan_bank_logo.jpeg';
import { isEmptyObject } from '@chakra-ui/utils';
import { staticThreeCommafy, threeCommafy } from '../../../../../lib/common/utils/digitformatter';
import { datedashformat, datedotformat } from '../../../../../lib/common/utils/dateformatter';
import { certificate_issue_type_object } from '../../../../../statics/common/data/selectbox';

type tFilter = 'asc' | 'desc';

const Payment = () => {
  const [date, setDate] = useState<tFilter>('desc');
  const [dateB, setDateB] = useState<tFilter>('desc');
  const [type, setType] = useState<tFilter>('desc');
  const [method, setMethod] = useState<tFilter>('desc');
  const [proval, setProval] = useState<tFilter>('desc');
  // 구독료 납비 계좌 카피
  const [account, setAccount] = useState('100-035-629864 신한은행');
  const { hasCopied, onCopy } = useClipboard(account);

  const { data: cpInfo } = useGetCompanyInfomation();

  const { data: contractData, isPlaceholderData: isContractPlaceholderData } = useGetCompanyContract();

  const { data: paymentsData, isPlaceholderData: isContractPaymentsPlaceholderData } =
    useGetAllCompanyContractPayments();

  if (cpInfo === undefined || contractData === undefined || paymentsData === undefined) {
    return (
      <Center w="full" h="full">
        <Spinner />
      </Center>
    );
  }

  const is_not_paid_paymentsdata = paymentsData.filter((payment) => payment.is_paid === false);
  const next_payment_date = checkArrAndGetFirst(is_not_paid_paymentsdata).expected_payment_date;

  const isNotSubscribing = isContractPlaceholderData || isEmptyObject(contractData);

  // return null;
  return (
    // <Grid h="100%" templateRows="repeat(23, 1fr)" templateColumns="repeat(1, 1fr)" gap={6}>
    <Grid h="100%" gap={6}>
      {/* 결제 정보 */}
      <GTitleItem
        upperSection={
          <Flex alignItems={'center'}>
            <Text fontSize={'24px'} fontWeight={'medium'}>
              결제 정보
            </Text>
          </Flex>
        }
        minW={'1100px'}
      >
        <HStack spacing={0} w="full" h="full">
          <Box w={'70%'} h={'full'} px={7} py={4}>
            <HStack mb={3}>
              <Text color="borderWhite" fontSize={'13px'}>
                연 결제시 10% 할인 혜택이 있어요!
              </Text>
              {/* <Text fontWeight={'bold'} textDecorationLine={'underline'} textUnderlineOffset={'2px'} fontSize={'14px'}>
                연 결제로 전환하기
              </Text> */}
            </HStack>
            <Box borderTopWidth={'1px'} borderTopColor={'blackAlpha.500'} />
            <Table variant="simple_same" h={'full'} maxH={'130px'}>
              <Thead bgColor={'#F8F8F8'} borderColor={'gray.100'}>
                <Tr>
                  <WTh borderR>구독 플랜</WTh>
                  <WTh borderR>결제 방식</WTh>
                  <WTh borderR>
                    <Text display={'inline-block'} w="max">
                      구독 유효 기간
                    </Text>
                  </WTh>
                  <WTh borderR>구독료</WTh>
                  <WTh borderR>다음 결제일</WTh>
                  <WTh>결제 수단</WTh>
                </Tr>
              </Thead>
              <Tbody>
                {isNotSubscribing ? null : (
                  <Tr>
                    <WTd borderR>
                      {contractData.subscription_type} {contractData.available_user_count}인
                    </WTd>
                    <WTd borderR>{contractData.is_year ? '연' : '월'} 결제</WTd>
                    <WTd borderR>
                      {datedotformat(contractData.contract_start_date)}~{datedotformat(contractData.contract_end_date)}
                    </WTd>
                    <WTd borderR>
                      <Box>
                        월 {staticThreeCommafy(contractData.price - contractData.discount_price + contractData.vat)}원
                      </Box>
                      <Box>(VAT 포함)</Box>
                    </WTd>
                    <WTd borderR>{datedotformat(next_payment_date) || '-'}</WTd>
                    <WTd>{paymentMethodTypeTrans(contractData.payment_method_type)}</WTd>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </Box>
          <Box w={'30%'} h={'full'} pr={5} py={6}>
            <VStack alignItems={'flex-start'} justifyContent={'unset'} w={'full'} h={'full'}>
              <Flex flexDir={'column'} w="full" h="65%" bgColor={'#f8f8f8'} borderRadius={'xl'} p={5}>
                <HStack justifyContent={'space-between'} w={'full'}>
                  <Text textStyle={'medium14'}>구독료 납부 계좌</Text>
                  <Button variant={'green'} fontSize={'12px'} fontWeight={'bold'} w="max" h="25px" onClick={onCopy}>
                    <HStack spacing={1}>
                      <DoubleRect />
                      <Box>{hasCopied ? '계좌 번호 복사 완료' : '계좌 번호 복사'} </Box>
                    </HStack>
                  </Button>
                </HStack>
                <HStack w="full" spacing={1} flexGrow={1} fontSize={'18px'} flexWrap={'wrap'} my={4}>
                  <Avatar w={8} h={8} name="은행로고" src={bank_mask_logo} />
                  <VStack alignItems={'flex-start'} spacing={0}>
                    <Flex alignItems={'center'}>
                      <Text fontSize={'15px'}>신한은행&nbsp;</Text>
                      <Text fontSize={'15px'}>{'100-035-629864'}</Text>
                    </Flex>
                    <Text fontSize={'15px'}>{'주식회사 리버블'}</Text>
                  </VStack>
                </HStack>
              </Flex>
              <Box w="full" h="35%" bgColor={'#f8f8f8'} borderRadius={'xl'} px={5}>
                <HStack justifyContent={'space-between'} w={'full'} h={'full'}>
                  <Text textStyle={'medium14'}>
                    전자 세금 계산서 전용 전자우편 주소 {cpInfo.digital_tax_invoice_email}
                  </Text>
                  <TextLink to={'/company/infos'} fontSize={'14px'} fontWeight={'bold'}>
                    변경
                  </TextLink>
                </HStack>
              </Box>
            </VStack>
          </Box>
        </HStack>
      </GTitleItem>
      {/* 결제 내역 */}
      <GTitleItem
        upperSection={
          <Flex alignItems={'center'} justifyContent={'space-between'}>
            <Text fontSize={'24px'} fontWeight={'medium'}>
              결제 내역
            </Text>
            <TextLink to={'/company/policy/return'} fontSize={'14px'} fontWeight={'bold'}>
              환불 안내
            </TextLink>
          </Flex>
        }
        minH={'calc(100vh - 460px)'}
      >
        <Box w={'full'} p={7}>
          <Table variant="head_silverbg_toplining">
            <Thead>
              <Tr>
                <WTh
                  borderR
                  tFilter={date}
                  onClick={() => {
                    setDate((prev) => (prev === 'asc' ? 'desc' : 'asc'));
                  }}
                >
                  납기일
                </WTh>
                <WTh
                  borderR
                  tFilter={dateB}
                  onClick={() => {
                    setDateB((prev) => (prev === 'asc' ? 'desc' : 'asc'));
                  }}
                >
                  납부일
                </WTh>
                <WTh
                  borderR
                  tFilter={type}
                  onClick={() => {
                    setType((prev) => (prev === 'asc' ? 'desc' : 'asc'));
                  }}
                >
                  항목
                </WTh>
                <WTh borderR>
                  <Text display={'inline-block'} w="max">
                    결제 금액(VAT 포함)
                  </Text>
                </WTh>
                <WTh
                  borderR
                  tFilter={method}
                  onClick={() => {
                    setMethod((prev) => (prev === 'asc' ? 'desc' : 'asc'));
                  }}
                >
                  결제 수단
                </WTh>
                <WTh
                  borderR
                  tFilter={proval}
                  onClick={() => {
                    setProval((prev) => (prev === 'asc' ? 'desc' : 'asc'));
                  }}
                >
                  증빙 수단
                </WTh>
                {/* <WTh>비고</WTh> */}
              </Tr>
            </Thead>
            <Tbody>
              {isContractPaymentsPlaceholderData
                ? null
                : paymentsData.map((payment) => {
                    console.log('payment =>', payment);

                    return (
                      <Tr key={payment._id}>
                        <WTd borderR>{datedashformat(payment.expected_payment_date) || '-'}</WTd>
                        <WTd borderR>{datedashformat(payment.real_payment_date) || '-'}</WTd>
                        <WTd borderR>
                          {contractData.subscription_type} {contractData.available_user_count}인
                        </WTd>
                        <WTd borderR>{staticThreeCommafy(payment.price + payment.vat - payment.discount_price)} 원</WTd>
                        <WTd borderR>{paymentMethodTypeTrans(payment.payment_method_type)}</WTd>
                        <WTd borderR>{certificate_issue_type_object[contractData.certificate_issue_type]}</WTd>
                        {/* <WTd>{payment.description}</WTd> */}
                      </Tr>
                    );
                  })}
            </Tbody>
          </Table>
        </Box>
      </GTitleItem>
    </Grid>
  );
};

export default Payment;

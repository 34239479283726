import React from 'react';
import { Table, Tbody, Td, Text, Th, Thead, Tr, Button, Box, Flex, Center } from '@chakra-ui/react';
import Pagination from '../../../components/common/Pagination';
import { currentParamsObject } from '../../../lib/common/utils/Util';
import { staticThreeCommafy } from '../../../lib/common/utils/Util';
import Loading from '../../../components/common/Loading';
import * as Type from '../../../types/common/CommonType';
import * as Util from '../../../lib/common/utils/Util';
import { default_items_limit_number } from '../../../lib/common/utils/values';
import { useGetAllTransferOuts, useGetSendingMoniesUrl } from '../../../lib/common/hooks/query';
import QueryParamsLayout from '../main/QueryParamsLayout';
import Dropzone from 'react-dropzone';
import SendMoniesDropzone from '../../../components/admin/Dropzone/SendMonies';
import { useSendMonies } from '../../../lib/common/hooks/mutation';
import { useGetCompanyAllManagers } from '../../../lib/admin/hooks/query';
import UrlButton from '../../../components/common/Button/UrlButton';
import { datedetaildotformat_ss } from '../../../lib/common/utils/dateformatter';

const TransferOut: React.FC = () => {
  let { page, limit, search, id } = currentParamsObject(false);
  page = page || 1;
  limit = limit || default_items_limit_number;
  search = search || '';

  const res = useGetAllTransferOuts({
    axiosConfig: {
      params: {
        page,
        limit,
        search,
        company_id: id,
      },
    },
  });

  const { isLoading: isLoadingUrl, data: dataSendingMoniesUrlData } = useGetSendingMoniesUrl();

  const { isLoading, data } = res;
  if (isLoading || isLoadingUrl) return <Loading />;

  return (
    <QueryParamsLayout res={res}>
      <Flex flexDir={'row'} alignItems="center">
        <SendMoniesDropzone />
        <UrlButton url={dataSendingMoniesUrlData?.data} content={'xlsx 다운로드'} />
      </Flex>
      <Table variant="head_silverbg_toplining">
        <Thead bgColor={'#F8F8F8'} borderColor={'gray.100'}>
          <Tr>
            <Th>시간</Th>
            <Th>회사</Th>
            <Th>이름</Th>
            <Th>휴대폰</Th>
            <Th>은행</Th>
            <Th>계좌번호</Th>
            <Th>예금주</Th>
            <Th>금액</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data?.data?.map((transferOut, index) => {
            console.log(transferOut);
            return (
              <Tr key={transferOut?.tid || index}>
                <Td>{datedetaildotformat_ss(transferOut?.createdAt)}</Td>
                <Td>{`${
                  transferOut?.info?.company_name || transferOut?.user?.company_name || transferOut?.host?.company_name
                }`}</Td>
                <Td>{`${
                  transferOut?.info?.user_name || transferOut?.user?.user_name || transferOut?.host?.user_name
                }`}</Td>
                <Td>{`${
                  transferOut?.info?.phone_number_first ||
                  transferOut?.user?.phone_number_first ||
                  transferOut?.host?.phone_number_first
                }`}</Td>
                <Td>{`${Util.bankNameTrans(transferOut?.bank_code)}`}</Td>
                <Td>{`${transferOut?.account_number}`}</Td>
                <Td>{`${transferOut?.account_owner_name}`}</Td>
                <Td>{`${Util.staticThreeCommafy(transferOut?.price)}원`}</Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </QueryParamsLayout>
  );
};

export default TransferOut;

import { Box, Skeleton, Table, Tbody, Td, Text, Th, Thead, Tr, VStack } from '@chakra-ui/react';
import QueryParamsLayout from '../../../../../../layouts/common/main/QueryParamsLayout';
import Pagination from '../../../../../../components/common/Pagination';
import { useGet_WP_Merchant } from '../../../../../../lib/admin/hooks/query';
import { getCurrent_WP_Amt, inoutColorTrans, inoutTypeTrans } from '../../../../../../lib/common/utils/utils';
import { WpBalanceLog, WpBalanceLogListType } from '../../../../../../lib/common/utils/axios/types';
import { currentParamsFilledObject, currentParamsObject } from '../../../../../../lib/common/utils/base';
import { datedetaildotformat_mm, datedetaildotformat_ss } from '../../../../../../lib/common/utils/dateformatter';
import { staticThreeCommafy } from '../../../../../../lib/common/utils/digitformatter';
import { default_items_limit_number } from '../../../../../../lib/common/utils/values';
import Loading from '../../../../../../components/common/Loading';

const WpMainPage = () => {
  const { page: page_param, limit: limit_param, search: search_param } = currentParamsObject(true);

  /* source */
  const page = page_param || 1;
  const limit = limit_param || default_items_limit_number;
  const search = search_param || '';
  const page_info = {
    page,
    limit,
  };
  /* //source */

  const is_first_page = page === 1;

  console.log('page =>', page, 'limit =>', limit);

  const res = useGet_WP_Merchant({
    axiosConfig: {
      params: {
        page,
        size: limit,
      },
    },
  });

  const { isLoading } = res;

  console.log(res);
  if (isLoading) return <Loading />;
  return (
    <QueryParamsLayout res={res} removeSearchBar pageInfo={{ page, limit }}>
      <Table variant="head_silverbg_toplining" id="test11">
        <Thead bgColor={'#F8F8F8'} borderColor={'gray.100'}>
          <Tr>
            <Th>createDttm</Th>
            <Th>tid</Th>
            <Th>통화</Th>
            <Th>balanceAmt</Th>
            <Th>changeAmt</Th>
            <Th>입출금</Th>
            <Th>최종 잔액</Th>
            <Th>상태</Th>
            <Th>trnsctnType</Th>
          </Tr>
        </Thead>
        <Tbody>
          {((res.data as any)?.result as { wpBalanceLogList: WpBalanceLog[] })?.wpBalanceLogList.map(
            (wpBalanceLog, index) => {
              const { tid, crrncyType, changeAmt, balanceAmt, createDttm, inoutType, trnsctnState, trnsctnType } =
                wpBalanceLog;
              const isFirstPageIndex = is_first_page && index === 0;

              return (
                <Tr key={tid}>
                  <Td>{datedetaildotformat_ss(createDttm)}</Td>
                  <Td>{tid}</Td>
                  <Td>{crrncyType}</Td>
                  <Td>{staticThreeCommafy(balanceAmt)}원</Td>
                  <Td>{staticThreeCommafy(changeAmt)}원</Td>
                  <Td color={inoutColorTrans(inoutType)} fontWeight={500}>
                    {inoutTypeTrans(inoutType)}
                  </Td>
                  <Td fontWeight={isFirstPageIndex ? 'bold' : 'normal'}>
                    {staticThreeCommafy(getCurrent_WP_Amt(wpBalanceLog))}원
                  </Td>
                  <Td>{trnsctnState}</Td>
                  <Td>
                    <Text textStyle={'medium10'}>{trnsctnType}</Text>
                  </Td>
                </Tr>
              );
            },
          )}
        </Tbody>
      </Table>
    </QueryParamsLayout>
  );
};

export default WpMainPage;

import { Box, Center, Flex, useToast } from '@chakra-ui/react';
import Dropzone from 'react-dropzone';
import { useGetCompanyAllManagers } from '../../../lib/admin/hooks/query';
import { useSendMonies } from '../../../lib/common/hooks/mutation';
import { currentParamsObject } from '../../../lib/common/utils/Util';
import { default_items_limit_number } from '../../../lib/common/utils/values';
import { error } from '../../../statics/common/data/toast';
import * as S from '../../../theme/common/styles';
interface SendMoniesDropzoneProps {
  props?: any;
}

const SendMoniesDropzone: React.FC<SendMoniesDropzoneProps> = ({ props }) => {
  const { mutate: sendMonies } = useSendMonies();
  return (
    <Dropzone
      onDrop={(files) => {
        if (files.length == 1) {
          const formData = new FormData();
          formData.append('file', files[0]);
          sendMonies({ data: formData });
        }
        return;
      }}
    >
      {({ getRootProps, getInputProps }) => {
        return (
          <Flex {...getRootProps()}>
            <input {...getInputProps()}></input>
            <Center sx={{ ...S.basicButton }}>첨부 파일 추가</Center>
          </Flex>
        );
      }}
    </Dropzone>
  );
};

export default SendMoniesDropzone;

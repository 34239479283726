export const bank_data = {
  data: [
    {
      bankId: 2,
      bankName: 'KDB산업은행',
      swiftCode: 'KODBKRSE',
    },
    {
      bankId: 3,
      bankName: 'IBK기업은행',
      swiftCode: 'IBKOKRSE',
    },
    {
      bankId: 4,
      bankName: 'KB국민은행',
      swiftCode: 'CZNBKRSE',
    },
    {
      bankId: 7,
      bankName: 'Sh수협은행',
      swiftCode: 'NFFCKRSE',
    },
    {
      bankId: 8,
      bankName: '한국수출입은행',
      swiftCode: 'EXIKKRSE',
    },
    {
      bankId: 11,
      bankName: 'NH농협은행',
      swiftCode: 'NACFKRSE',
    },
    {
      bankId: 20,
      bankName: '우리은행',
      swiftCode: 'HVBKKRSE',
    },
    {
      bankId: 23,
      bankName: 'SC제일은행',
      swiftCode: 'SCBLKRSE',
    },
    {
      bankId: 27,
      bankName: '한국씨티은행',
      swiftCode: 'CITIKRSX',
    },
    {
      bankId: 31,
      bankName: '대구은행',
      swiftCode: 'DAEBKR22',
    },
    {
      bankId: 32,
      bankName: '부산은행',
      swiftCode: 'PUSBKR2P',
    },
    {
      bankId: 34,
      bankName: '광주은행',
      swiftCode: 'KWABKRSE',
    },
    {
      bankId: 35,
      bankName: '제주은행',
      swiftCode: 'JJBKKR22',
    },
    {
      bankId: 37,
      bankName: '전북은행',
      swiftCode: 'JEONKRSE',
    },
    {
      bankId: 39,
      bankName: '경남은행',
      swiftCode: 'KYNAKR22',
    },
    {
      bankId: 45,
      bankName: '새마을금고',
    },
    {
      bankId: 48,
      bankName: '신용협동조합',
    },
    {
      bankId: 50,
      bankName: '상호저축은행',
    },
    {
      bankId: 54,
      bankName: 'HSBC',
    },
    {
      bankId: 64,
      bankName: '산림조합',
    },
    {
      bankId: 71,
      bankName: '우체국',
      swiftCode: 'SHBKKRSEKP',
    },
    {
      bankId: 81,
      bankName: 'KEB하나은행',
      swiftCode: 'KOEXKRSE',
    },
    {
      bankId: 88,
      bankName: '신한은행',
      swiftCode: 'SHBKKRSE',
    },
    {
      bankId: 89,
      bankName: '케이뱅크',
    },
    {
      bankId: 90,
      bankName: '카카오뱅크',
      swiftCode: 'CITIKRSXKA',
    },
    {
      bankId: 209,
      bankName: '유안타증권',
    },
    {
      bankId: 218,
      bankName: 'KB증권',
    },
    {
      bankId: 238,
      bankName: '미래에셋대우',
    },
    {
      bankId: 240,
      bankName: '삼성증권',
    },
    {
      bankId: 243,
      bankName: '한국투자증권',
    },
    {
      bankId: 247,
      bankName: 'NH투자증권',
    },
    {
      bankId: 261,
      bankName: '교보증권',
    },
    {
      bankId: 262,
      bankName: '하이투자증권',
    },
    {
      bankId: 263,
      bankName: 'HMC투자증권',
    },
    {
      bankId: 264,
      bankName: '키움증권',
    },
    {
      bankId: 265,
      bankName: '이베스트투자증권',
    },
    {
      bankId: 266,
      bankName: 'SK증권',
    },
    {
      bankId: 267,
      bankName: '대신증권',
    },
    {
      bankId: 269,
      bankName: '한화투자증권',
    },
    {
      bankId: 270,
      bankName: '하나금융투자',
    },
    {
      bankId: 278,
      bankName: '신한금융투자',
    },
    {
      bankId: 279,
      bankName: '동부증권',
    },
    {
      bankId: 280,
      bankName: '유진투자증권',
    },
    {
      bankId: 287,
      bankName: '메리츠종합금융증권',
    },
    {
      bankId: 290,
      bankName: '부국증권',
    },
    {
      bankId: 291,
      bankName: '신영증권',
    },
    {
      bankId: 292,
      bankName: '케이프증권',
    },
    {
      bankId: 1,
      bankName: '한국은행',
    },
    {
      bankId: 0,
      bankName: '웰컴저축은행',
    },
    {
      bankId: 12,
      bankName: '지역농축협',
    },
    {
      bankId: 221,
      bankName: '골든브릿지투자증권',
    },
    {
      bankId: 222,
      bankName: '한양증권',
    },
    {
      bankId: 223,
      bankName: '리딩투자증권',
    },
    {
      bankId: 224,
      bankName: 'BNK투자증권',
    },
    {
      bankId: 225,
      bankName: 'IBK투자증권',
    },
    {
      bankId: 227,
      bankName: 'KTB투자증권',
    },
    {
      bankId: 288,
      bankName: '카카오페이증권',
    },
    {
      bankId: 92,
      bankName: '토스뱅크',
    },
    {
      bankId: 6,
      bankName: 'KB국민은행',
      swiftCode: 'CZNBKRSE',
    },
  ],
};

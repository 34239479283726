export const removeItemListOnLogout = [
  'upload_building_form',
  'saved_email-company-bohome-kr',
  'sidebar-accordion-임직원 관리',
  'email',
  'password',
  'bohome_company_type',
  'bohome_user_type',
  'company_type',
  'user_type',
  '--bohome-email',
  '--bohome-company-type',
  '--bohome-user-type',
  '--bohome-token',
  '--bohome-is-login',
];

export const cachedItemList = [
  'upload_building_form',
  'saved_email-company-bohome-kr',
  'sidebar-accordion-임직원 관리',
  'email',
  'password',
  'bohome_company_type',
  'bohome_user_type',
  'company_type',
  'user_type',
  '--bohome-email',
  '--bohome-company-type',
  '--bohome-user-type',
  '--bohome-token',
  '--bohome-route-user-type',
  '--bohome-is-login',
];

export const shouldCachedItemList = [
  '--bohome-company-type',
  '--bohome-user-type',
  '--bohome-token',
  '--bohome-route-user-type',
  '--bohome-is-login',
];

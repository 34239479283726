import { Stack, VStack, Editable, Flex, Box, EditablePreview, EditableTextarea } from '@chakra-ui/react';
import { useState } from 'react';
import { CompanyTypeEnum, RequestRoom } from '../../../../lib/common/utils/axios/types';
import AdminMemo from '../../../agent/Memo/base';
import * as Type from '../../../../types/common/CommonType';

export interface MemoProps {
  editableAll: boolean;
  userType: CompanyTypeEnum;
  data: Type.Company;
}

const Memo: React.FC<MemoProps> = ({ editableAll, userType, data }) => {
  switch (userType) {
    case 'admin':
      return <AdminMemo editableAll={editableAll} userType={userType} data={data} />;
    case 'agency':
      return <AdminMemo editableAll={editableAll} userType={userType} data={data} />;
    case 'subscriber':
      return <AdminMemo editableAll={editableAll} userType={userType} data={data} />;
    default:
      return null;
  }
};

export default Memo;

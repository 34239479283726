import { Box, Flex, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import { PathProps } from '.';
import { GTitleItem } from '../../../../../components/common/GridItem';
import TextLink from '../../../../../components/common/Link/TextLink';
import {
  parseCounts,
  useGetAllCompanyContractPayments,
  useGetAllCounts,
  useGetCompanyContract,
} from '../../../../../lib/subscriber/hooks/query';
import { decimalize } from '../../../../../lib/common/utils/digitformatter';

type RequestListsProps = PathProps;

// 1 - 메인
const Main = () => {
  const { data: countsdata } = useGetAllCounts();
  const { data: contractdata } = useGetCompanyContract();
  const { data: paymentsdata } = useGetAllCompanyContractPayments();

  if (countsdata === undefined || contractdata === undefined || paymentsdata === undefined) {
    return null;
  }

  const { services_count } = countsdata;

  const changing_requests_count =
    parseCounts(services_count, 'changing') !== undefined ? parseCounts(services_count, 'changing').count : 0;
  const extending_requests_count =
    parseCounts(services_count, 'extending') !== undefined ? parseCounts(services_count, 'extending').count : 0;
  const new_requests_count =
    parseCounts(services_count, 'new') !== undefined ? parseCounts(services_count, 'new').count : 0;

  return (
    <GTitleItem
      upperSection={
        <Flex justifyContent={'space-between'} alignItems={'center'} cursor={'pointer'}>
          <Text fontSize={'18px'} fontWeight={'medium'}>
            이용 신청 현황
          </Text>
        </Flex>
      }
      // gridColumnStart={2}
      // gridColumnEnd={5}
      // rowSpan={2}
      // colSpan={3}
    >
      <Flex w="full" h="full" flexDir={'column'} p={2}>
        <Box w={'full'}>
          <UnorderedList display={'flex'} mx={8} my={6}>
            <ListItem fontWeight={'bold'} fontSize={'18px'} minW={'max'} mr={20}>
              <Text display={'inline-block'} mr={2}>
                추가 승인 요청
              </Text>
              <Text display={'inline-block'} color="primaryRed">
                {decimalize(changing_requests_count)}
              </Text>
            </ListItem>
            <ListItem fontWeight={'bold'} fontSize={'18px'} minW={'max'} mr={20}>
              <Text display={'inline-block'} mr={2}>
                계약 연장 신청
              </Text>
              <Text display={'inline-block'} color="primaryRed">
                {decimalize(extending_requests_count)}
              </Text>
            </ListItem>
            <ListItem fontWeight={'bold'} fontSize={'18px'} minW={'max'}>
              <Text display={'inline-block'} mr={2}>
                신규 이용 신청
              </Text>
              <Text display={'inline-block'} color="primaryRed">
                {decimalize(new_requests_count)}
              </Text>
            </ListItem>
          </UnorderedList>
        </Box>
      </Flex>
    </GTitleItem>
  );
};

const OnSeek = () => (
  <GTitleItem
    upperSection={
      <Flex justifyContent={'space-between'} alignItems={'center'} cursor={'pointer'}>
        <Text fontSize={'18px'} fontWeight={'medium'}>
          집찾기 중인 임직원 현황
        </Text>
      </Flex>
    }
    rowSpan={2}
    colSpan={3}
  >
    <Flex w="full" h="full" flexDir={'column'} p={2}>
      <Box w={'full'}>
        <UnorderedList display={'flex'} mx={8} my={6}>
          <ListItem fontWeight={'bold'} fontSize={'18px'} mr={20}>
            <Text display={'inline-block'} mr={2}>
              입주 신청 전
            </Text>
            <Text display={'inline-block'} color="primaryRed">{`02`}</Text>
          </ListItem>
          <ListItem fontWeight={'bold'} fontSize={'18px'}>
            <Text display={'inline-block'} mr={2}>
              집찾기 중
            </Text>
            <Text display={'inline-block'} color="primaryRed">{`02`}</Text>
          </ListItem>
        </UnorderedList>
      </Box>
    </Flex>
  </GTitleItem>
);

const OnContract = () => (
  <GTitleItem
    upperSection={
      <Flex justifyContent={'space-between'} alignItems={'center'} cursor={'pointer'}>
        <Text fontSize={'18px'} fontWeight={'medium'}>
          계약 중인 임직원 현황
        </Text>
      </Flex>
    }
    rowSpan={2}
    colSpan={3}
  >
    <Flex w="full" h="full" flexDir={'column'} p={2}>
      <Box w={'full'}>
        <UnorderedList display={'flex'} mx={8} my={6}>
          <ListItem fontWeight={'bold'} fontSize={'18px'} mr={20}>
            <TextLink to={'/test'} textUnderlineOffset={'4px'} display={'inline-block'} mr={2}>
              계약 서명 요청 ({`01`}건)
            </TextLink>
          </ListItem>
          <ListItem fontWeight={'bold'} fontSize={'18px'} mr={20}>
            <TextLink to={'/test'} textUnderlineOffset={'4px'} display={'inline-block'} mr={2}>
              계약 완료 ({`02`}건)
            </TextLink>
          </ListItem>
        </UnorderedList>
      </Box>
    </Flex>
  </GTitleItem>
);

const OnMovein = () => (
  <GTitleItem
    upperSection={
      <Flex justifyContent={'space-between'} alignItems={'center'} cursor={'pointer'}>
        <Text fontSize={'18px'} fontWeight={'medium'}>
          입주 중인 임직원 현황
        </Text>
      </Flex>
    }
    rowSpan={2}
    colSpan={3}
  >
    <Flex w="full" h="full" flexDir={'column'} p={2}>
      <Box w={'full'}>
        <UnorderedList display={'flex'} mx={8} my={6}>
          <ListItem fontWeight={'bold'} fontSize={'18px'} mr={20}>
            <TextLink to={'/test'} textUnderlineOffset={'4px'} display={'inline-block'} mr={2}>
              입주 중 ({`02`}건)
            </TextLink>
          </ListItem>
        </UnorderedList>
      </Box>
    </Flex>
  </GTitleItem>
);

const RequestLists: React.FC<RequestListsProps> = ({ path }) => {
  const { main, onseek, oncontract, onmovein } = path;
  if (main.isCurrent) {
    return <Main />;
    // } else if (onseek.isCurrent) {
    //   return <OnSeek />;
  } else if (oncontract.isCurrent) {
    return <OnContract />;
  } else if (onmovein.isCurrent) {
    return <OnMovein />;
  }
  return null;
};

export default RequestLists;

import { HStack, Image, Text, Tooltip } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { RightArrowIcon } from '../../../../statics/common/icons';

interface CotainerProps {
  icon?: any;
  image?: any;
  title: string;
  to: string;
  block?: boolean;
}

export const Container: React.FC<CotainerProps> = ({ icon, image, title, to, block }) => {
  const navigate = useNavigate();

  return (
    <Tooltip
      label="곧 출시 예정이에요!"
      aria-label={`${title} tooltip`}
      isDisabled={!block}
      placement="left"
      hasArrow
      closeOnClick={false}
    >
      <HStack
        w={'full'}
        justifyContent={'space-between'}
        px={12}
        py={6}
        transition={'0.15s linear'}
        _hover={{
          opacity: 0.7,
        }}
        onClick={() => {
          if (!block) {
            navigate(to);
          }
        }}
        cursor={'pointer'}
        aria-disabled={block}
        role="group"
        _disabled={{
          cursor: 'not-allowed',
          userSelect: 'none',
          _hover: {
            bgColor: '#f8f8f8',
          },
        }}
      >
        <HStack>
          {icon && icon}
          {image && <Image src={image} w={6} h={6} />}
          <Text fontSize={'18px'} fontWeight={'medium'}>
            {title}
          </Text>
        </HStack>
        <RightArrowIcon w={6} h={6} />
      </HStack>
    </Tooltip>
  );
};

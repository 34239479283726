import { Box, Input, Center, Flex, HStack, useCheckbox } from '@chakra-ui/react';
import { Field, FieldInputProps } from 'formik';
import { useEffect } from 'react';
import { useLocalFormik } from '../../../../lib/common/hooks/useLocalFormik';
import { RadioLabelProps } from '../../../../statics/common/data/radio';

interface CheckboxButtonProps {
  field: FieldInputProps<string>;
  id: string;
  label: string;
  initValue: string;
  localName: string;
}

export const CheckboxButton: React.FC<CheckboxButtonProps> = ({
  field: { name, value, checked, onChange, onBlur },
  id,
  label,
  initValue,
  localName,
  ...props
}) => {
  // const isChecked = initValue === value; // value is array. it does not apply.
  const isChecked = value.includes(initValue);
  const [_, __, onSet] = useLocalFormik(localName);

  useEffect(() => {
    onSet({ [name]: value });
  }, [value]);

  return (
    <Box>
      <Input
        display="none"
        name={name}
        id={id}
        type="checkbox"
        value={initValue} // could be something else for output?
        checked={isChecked}
        onChange={(e) => {
          onChange(e);
          // onLocalChange(e);
          // onSet({ [name]: value });
        }}
        onBlur={onBlur}
        {...props}
      />
      <Box as="label" htmlFor={id}>
        <Center
          fontSize={'14px'}
          boxSize={'max-content'}
          h={'34px'}
          cursor="pointer"
          borderWidth="1px"
          borderRadius={'md'}
          bgColor={isChecked ? 'zetaBlue' : 'lotionWhite'}
          borderColor={isChecked ? 'zetaBlue' : 'silverGray'}
          color={isChecked ? 'white' : 'highGray'}
          // _checked={{
          //   bg: 'zetaBlue',
          //   color: 'white',
          //   borderColor: 'zetaBlue',
          // }}
          _hover={{
            bgColor: 'zetaBlue',
            color: 'white',
          }}
          transition={'0.2s linear'}
          px={5}
          py={3}
          m={1}
        >
          {label}
        </Center>
      </Box>
    </Box>
  );
};

interface CheckboxButtonGroupProps {
  name: string;
  localName: string;
  options: RadioLabelProps;
}

export const CheckboxButtonGroup: React.FC<CheckboxButtonGroupProps> = ({ name, localName, options }) => {
  return (
    <Flex role="group" aria-labelledby={`${name}-radio-group`} flexWrap={'wrap'}>
      {options.map((option) => (
        <Field
          key={option.label + name}
          component={CheckboxButton}
          name={name}
          id={option.value + name}
          label={option.label}
          initValue={option.value}
          localName={localName}
        />
      ))}
    </Flex>
  );
};

interface FCheckboxButtonProps {
  field: FieldInputProps<string>;
  id: string;
  label: string;
  initValue: string;
}

export const FCheckboxButton: React.FC<FCheckboxButtonProps> = ({
  field: { name, value, checked, onChange, onBlur },
  id,
  label,
  initValue,
  ...props
}) => {
  // const isChecked = initValue === value; // value is array. it does not apply.
  const isChecked = value.includes(initValue);
  return (
    <Box>
      <Input
        display="none"
        name={name}
        id={id}
        type="checkbox"
        value={initValue} // could be something else for output?
        checked={isChecked}
        onChange={(e) => {
          onChange(e);
        }}
        onBlur={onBlur}
        {...props}
      />
      <Box as="label" htmlFor={id}>
        <Center
          fontSize={'14px'}
          boxSize={'max-content'}
          h={'32px'}
          cursor="pointer"
          borderWidth="1px"
          borderRadius={'md'}
          bgColor={isChecked ? 'zetaBlue' : 'lotionWhite'}
          borderColor={isChecked ? 'zetaBlue' : 'silverGray'}
          color={isChecked ? 'white' : 'highGray'}
          _hover={{
            opacity: 0.9,
          }}
          transition={'0.2s linear'}
          px={3}
          minW={'50px'}
          py={3}
        >
          {label}
        </Center>
      </Box>
    </Box>
  );
};

interface FCheckboxButtonGroupProps {
  name: string;
  options: RadioLabelProps;
}

export const FCheckboxButtonGroup: React.FC<FCheckboxButtonGroupProps> = ({ name, options }) => {
  return (
    <Flex role="group" aria-labelledby={`${name}-radio-group`} flexWrap={'wrap'}>
      <HStack spacing={'6px'}>
        {options.map((option) => (
          <Field
            key={option.label + name}
            component={FCheckboxButton}
            name={name}
            id={option.value + name}
            label={option.label}
            initValue={option.value}
          />
        ))}
      </HStack>
    </Flex>
  );
};

export const NoStyleCheckbox: React.FC = ({ children }) => {
  const { getInputProps, getCheckboxProps } = useCheckbox();

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        boxShadow="md"
        _checked={{
          bg: 'teal.600',
          color: 'white',
          borderColor: 'teal.600',
        }}
        _focus={{
          boxShadow: 'outline',
        }}
        px={5}
        py={3}
      >
        {children}
      </Box>
    </Box>
  );
};

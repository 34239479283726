import { Box, Button, Flex, FlexboxProps, Text, VStack } from '@chakra-ui/react';
import { orderBy } from 'firebase/firestore';
import { Fragment, useEffect, useRef } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import { useParams } from 'react-router-dom';
import { datedotkoformat, date_ahhmm_format } from '../../../../lib/common/utils/dateformatter';
import { auth, getUserId, messagesQuery } from '../../../../lib/common/utils/firebase/init';
import { isSameDay, isSameMinute } from 'date-fns';
import Scrollbars from 'react-custom-scrollbars-2';

const ChatBox = () => {
  const params = useParams();
  const inputFocus = useRef<HTMLInputElement>(null);

  const componentEndRef = useRef<HTMLDivElement>(null);

  // Doc 값들
  const chatroomid = params.chatroomid ? params.chatroomid : '';

  const [data] = useCollection(messagesQuery(chatroomid, orderBy('createdAt', 'asc')));

  useEffect(() => {
    componentEndRef.current?.scrollIntoView({ behavior: 'auto', block: 'end' });
  }, [data]);

  const testClick = () => {
    console.log(componentEndRef.current?.scrollHeight);
    console.log(componentEndRef.current?.clientHeight);
    console.log(componentEndRef.current?.offsetHeight);
    console.log(componentEndRef.current?.scrollTop);
    console.log(componentEndRef.current?.clientTop);
    console.log(componentEndRef.current?.offsetTop);
  };

  return (
    <Scrollbars style={{ height: '100%' }}>
      <Box m={2} ref={componentEndRef}>
        <VStack w="full" spacing={0}>
          {data?.docs.map((doc, index, docs) => {
            const data = doc.data();

            const next_data = docs[index - 1]?.data();
            const last_data = index === docs.length - 1 ? doc.data() : '';

            const isDifferentDay = !isSameDay(data.createdAt, next_data?.createdAt || 0);
            const isDifferentMinute = !isSameMinute(data.createdAt, next_data?.createdAt || 0);

            const newDayText = isDifferentDay ? next_data?.createdAt : '';
            const newMinuteText = isDifferentMinute ? next_data?.createdAt : last_data ? last_data.createdAt : '';

            // const isMyself = getUserId() === data.user._id;

            const isSystem = data.system === true;
            const isMyself = data?.user?._id === getUserId();

            // console.log(index, data?.user?._id, getUserId());

            const bgColor = isSystem ? 'white' : isMyself ? '#183377' : '#E9E9EB';
            const fontSize = isSystem ? '11px' : '15px';
            const fontWeight = isSystem ? 600 : 400;
            const color = isSystem ? '#C4C4C4' : isMyself ? 'white' : '#414141';
            const alignSelf: FlexboxProps['alignSelf'] = isMyself ? 'end' : isSystem ? 'center' : 'start';
            const align: FlexboxProps['alignContent'] = isMyself ? 'end' : isSystem ? 'center' : 'start';

            return (
              <Fragment key={index}>
                {newDayText && (
                  <Box w="full" py={4}>
                    <Text textAlign={'center'} color={'white'} textStyle="normal14" bgColor={'gray.400'}>
                      {datedotkoformat(newDayText)}
                    </Text>
                  </Box>
                )}
                <Flex flexDir={'column'} w="full" pb={newMinuteText ? 2 : '1px'}>
                  <Box wordBreak={'break-all'} alignSelf={alignSelf} maxW="70%">
                    <Box px={3} py={1} bgColor={bgColor} borderRadius={'1rem'}>
                      <Text whiteSpace={'pre-wrap'} fontSize={fontSize} fontWeight={fontWeight} color={color}>
                        {`${data.text}`}
                      </Text>
                    </Box>
                  </Box>
                  <Box alignSelf={alignSelf}>
                    <Text textStyle="normal12">{date_ahhmm_format(newMinuteText)}</Text>
                  </Box>
                </Flex>
                {/* <Button onClick={testClick}>TEST</Button> */}
              </Fragment>
            );
          })}
        </VStack>
      </Box>
    </Scrollbars>
  );
};

export default ChatBox;

import { Table, Thead, Tr, Tbody, HStack, Avatar, Box, Button, useDisclosure } from '@chakra-ui/react';
import { useState } from 'react';
import RemoveButton from '../../../../../../../components/common/Button/RemoveButton';
import DeleteReCheck from '../../../../../../../components/common/Dialog/DeleteReCheck';
import { WTd } from '../../../../../../../components/common/Table/Td';
import { WTh } from '../../../../../../../components/common/Table/Th';

const dummy_items = [
  {
    id: '11',
    area: '구로구 구로동',
  },
  {
    id: '12',
    area: '관악구 신림동',
  },
  {
    id: '13',
    area: '강남구 역삼동',
  },
];

const SalesRegion = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [clickedItem, setClickedItem] = useState<string>();

  return (
    <Table variant="simple">
      <Thead bgColor={'#F8F8F8'}>
        <Tr>
          <WTh>주요 영업 지역</WTh>
        </Tr>
      </Thead>
      <Tbody>
        <Tr>
          <WTd borderR>
            <HStack justifyContent={'center'} spacing={10}>
              <HStack>
                {dummy_items.map((item) => (
                  <RemoveButton
                    key={item.id}
                    id={item.id}
                    onClick={(e) => {
                      setClickedItem((e.currentTarget as any).id);
                      onOpen();
                    }}
                  >
                    {item.area}
                  </RemoveButton>
                ))}
              </HStack>
              <Button variant={'basic'} fontSize={'12px'} height={'32px'}>
                추가하기
              </Button>
            </HStack>
            {/* 모달 */}
            <DeleteReCheck
              isOpen={isOpen}
              onClose={onClose}
              title={'영업 지역 삭제'}
              content={'중개사 영업 지역을 정말로 삭제하시겠습니까?'}
              item={clickedItem}
            />
          </WTd>
        </Tr>
      </Tbody>
    </Table>
  );
};

export default SalesRegion;

import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Image,
  Input,
  ListItem,
  Modal,
  ModalContent,
  ModalOverlay,
  Tag,
  Text,
  Textarea,
  UnorderedList,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { isEmptyArray, isUndefined } from '@chakra-ui/utils';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import Scrollbars from 'react-custom-scrollbars-2';
import { WBox } from '../../Box';
import { RequestRoom } from '../../../../lib/common/utils/axios/types';
import { CheckCircleIcon } from '../../../../statics/common/icons';
import {
  useConfirmRecommendedRooms,
  useCreateUserVirtualAccount,
  useMakeTourReservationWithAdmin,
  useSuggestRooms,
} from '../../../../lib/admin/hooks/mutation';
import * as Type from '../../../../types/common/CommonType';

interface MakeContractReservationModalProps {
  disabled?: boolean;
  requestRooms: Type.Company[];
}

const MakeContractReservationModal: React.FC<MakeContractReservationModalProps> = ({
  children,
  disabled = false,
  requestRooms,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleModalOpen = () => {
    if (!disabled) {
      onOpen();
    }
  };

  const custom_room_request_id = isEmptyArray(requestRooms) ? '' : requestRooms[0].employee.custom_room_request._id;
  const employee_id = isEmptyArray(requestRooms) ? '' : requestRooms[0].employee._id;
  const virtual_account = isEmptyArray(requestRooms) ? '' : requestRooms[0].employee.virtual_account;

  const { mutateAsync: MakeTourReservation } = useMakeTourReservationWithAdmin(custom_room_request_id || '');
  const { mutateAsync: CreateVirtualAccount } = useCreateUserVirtualAccount();

  return (
    <>
      <Box onClick={handleModalOpen}>{children}</Box>

      <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside" size={'4xl'} motionPreset="scale">
        <ModalOverlay />
        <Formik
          initialValues={{
            room_ids: requestRooms.map((room) => room?.employee.custom_room_request?.recommended_room?.room_id),
            start_date: '',
            end_date: '',
            location_description: '',
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            await MakeTourReservation({
              room_ids: values.room_ids as any,
              start_date: new Date(Date.parse(values.start_date)),
              end_date: new Date(Date.parse(values.start_date) + 1000 * 60 * 60),
              location_description: values.location_description,
              tour_type: 'contract_meeting',
            }).then(async () => {
              if (!virtual_account) {
                await CreateVirtualAccount({
                  userId: employee_id,
                  userType: 'tenant',
                }).then(() => {
                  onClose();
                });
              } else {
                onClose();
              }
            });
          }}
          validationSchema={Yup.object().shape({})}
        >
          {({ values, errors, isValid, handleChange, setFieldValue, isSubmitting }) => {
            const isAbleReservation = values.start_date && values.location_description;
            return (
              // <Form style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>

              <Form>
                <ModalContent p={5}>
                  <Center flexDir={'column'} m={10} alignItems={'flex-start'}>
                    <HStack>
                      <CheckCircleIcon w={8} h={8} />
                      <Text fontSize={'23px'}>계약서 작성일 확정</Text>
                    </HStack>
                    <VStack fontSize={'20px'} flexDir={'column'} my={4} spacing={0} alignItems={'flex-start'}>
                      <Box>{'임직원과 계약서 작성할 날짜,시간, 장소를 입력해 주세요.'}</Box>
                    </VStack>
                    {/* 선택된 매물 리스트 */}

                    <VStack w={'full'}>
                      <WBox w={'full'}>
                        <Box w={'full'} p={3}>
                          <VStack spacing={1} w={'full'} px={'80px'} py={'20px'}>
                            {/* 주소 */}
                            <UnorderedList>
                              {requestRooms.map((requestRoom, index) => {
                                const employee = requestRoom?.employee;
                                const custom_room_request = employee?.custom_room_request;
                                const recommended_room = custom_room_request?.recommended_room;
                                const room = recommended_room?.room;
                                const admin_check_status = recommended_room?.admin_check_status;
                                return (
                                  <ListItem key={index}>
                                    <HStack w="full" justify={'space-between'}>
                                      <Box fontSize={'14px'}>
                                        {room?.building?.address} {room?.building?.name && `(${room?.building?.name})`}
                                        {room?.dong && `${room?.dong}동`} {room?.ho && `${room?.ho}호`}
                                      </Box>
                                      {/* 매물번호 */}
                                      <Tag variant={'room_number'}>매물번호 {room?.code}</Tag>
                                    </HStack>
                                  </ListItem>
                                );
                              })}
                            </UnorderedList>
                          </VStack>
                        </Box>
                        <Box w="full" px={'30px'} pb={'30px'}>
                          <VStack>
                            <HStack w="full">
                              <Text minW="130px" fontSize={'14px'}>
                                투어 날짜/시간
                              </Text>
                              <Field name={'start_date'} as={Input} type={'datetime-local'} />
                            </HStack>
                            <HStack w="full">
                              <Text minW="130px" fontSize={'14px'}>
                                약속 장소
                              </Text>
                              <Field name={'location_description'} as={Input} />
                            </HStack>
                          </VStack>
                        </Box>
                      </WBox>
                    </VStack>

                    <Center w="full">
                      <Button
                        mt={'30px'}
                        variant={'basic'}
                        w={'140px'}
                        fontSize={'14px'}
                        fontWeight={'bold'}
                        type="submit"
                        isDisabled={!isAbleReservation}
                        isLoading={isSubmitting}
                      >
                        예약하기
                      </Button>
                    </Center>
                  </Center>
                </ModalContent>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

export default MakeContractReservationModal;

import { Skeleton, VStack } from '@chakra-ui/react';
import { isUndefined } from 'lodash';
import { Outlet, useSearchParams } from 'react-router-dom';
import { useGetCompanyManager } from '../../../../../../../../../lib/admin/hooks/query';
import BeforeLayout from './sections/BeforeLayout';
import AfterLayout from './sections/AfterLayout';
import { currentParamsObject } from '../../../../../../../../../lib/common/utils/base';

const StatusDetectLayout = () => {
  const { id } = currentParamsObject(false);

  const { data: user_data, isLoading } = useGetCompanyManager(id || '', {
    options: {
      enabled: !!id,
    },
  });

  let status;

  switch (user_data?.employee.tenant_status) {
    case 'contracted':
    case 'moving_in':
    case 'moving_out':
      status = true;
      break;
    default:
      status = false;
      break;
  }

  return (
    <VStack alignItems={'start'} w={'full'} h="full">
      <Skeleton isLoaded={!isLoading} w="full" h="full">
        {status ? (
          <AfterLayout>
            <Outlet />
          </AfterLayout>
        ) : (
          <BeforeLayout>
            <Outlet />
          </BeforeLayout>
        )}
      </Skeleton>
    </VStack>
  );
};

export default StatusDetectLayout;

import { HStack, Icon, Center, Box, Button, VStack } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { FillHeart, OutLineHeart } from '../../../../../../../../statics/common/icons';
import { request_item_lists } from '../../../../../../agent/manage/dummydata';
import { format } from 'date-fns';
import { datedashformat } from '../../../../../../../../lib/common/utils/dateformatter';

const Status = () => {
  return (
    <VStack w={'full'}>
      <Box w={'100%'} fontWeight={'bold'} fontSize={'18px'} textAlign={'start'} flexGrow={1}>
        전달 현황
      </Box>
      {request_item_lists.map((item) => (
        <VStack w={'full'} alignItems={'start'} key={item._id}>
          <HStack
            spacing={6}
            px={4}
            py={1}
            border={'1px solid'}
            bgColor={'lotionWhite'}
            borderColor={'silverGray'}
            borderRadius={'lg'}
            w={'full'}
          >
            <HStack w={'full'}>
              <Center
                fontSize={'12px'}
                fontWeight={700}
                w={'75px'}
                bgColor={item.status === '신규' ? 'mountainGreen' : 'gainGray'}
                color={item.status === '신규' ? 'white' : 'borderWhite'}
                px={3}
                py={0.5}
                borderRadius={'lg'}
              >
                {item.status}
              </Center>
              <Box textStyle={'medium14'}>{item.name}</Box>
              <Box fontSize={'14px'}>
                {/* item.date */}
                {datedashformat(new Date())}
              </Box>
            </HStack>
            <HStack>
              <Button variant={'gray'}>
                {/* <Button variant={'white'} fontWeight={700}> */}
                상세보기
              </Button>
              <Button variant={'basic'}>채팅하기</Button>
            </HStack>
            {/* <HStack h={'31px'} flexWrap={'wrap'} overflow={'hidden'}>
            <Conditions item={item} max={10} />
          </HStack> */}
          </HStack>
          {/* <RoomInfoContainer /> */}
        </VStack>
      ))}
    </VStack>
  );
};

export default Status;

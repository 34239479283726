import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import UnderTapLayout from '../../layouts/common/main/UnderTapLayout';
import { useGetUserType } from '../../lib/common/hooks/query';
import NotFoundPage from '../../pages/common/NotFound';
import ChatPage from '../../components/admin/Chat/ChatPage';
import Announcements from '../../pages/UserType/admin/manage/announcements/main';
import AnnouncementDetail from '../../pages/UserType/admin/manage/announcements/main/detail';
import PostAnnouncementPage from '../../pages/UserType/admin/manage/announcements/main/post/announcement';
import AnnoucementSetting from '../../pages/UserType/admin/manage/announcements/setting';
import All from '../../pages/UserType/admin/manage/chats/all';
import Important from '../../pages/UserType/admin/manage/chats/important';
import ChatUsers from '../../pages/UserType/admin/manage/chats/users';
import Existing from '../../pages/UserType/admin/manage/companies/existing';
import New from '../../pages/UserType/admin/manage/companies/new';
import Complaints from '../../pages/UserType/admin/manage/complaints/main';
import Agency from '../../pages/UserType/admin/manage/customers/agency';
import Company from '../../pages/UserType/admin/manage/customers/company';
import CompanyInfo from '../../pages/UserType/admin/manage/customers/company/detail/info';
import CompanyLmemo from '../../pages/UserType/admin/manage/customers/company/detail/memo';
import CompanyLsettlement from '../../pages/UserType/admin/manage/customers/company/detail/lsettlement';
import CompanyStatusDetectLayout from '../../pages/UserType/admin/manage/customers/company/detail/sections/DetailLayoutRouter';
import CompanyStatus from '../../pages/UserType/admin/manage/customers/company/detail/status';
import Host from '../../pages/UserType/admin/manage/customers/host';
import HostContract from '../../pages/UserType/admin/manage/customers/host/detail/contract';
import HostEstate from '../../pages/UserType/admin/manage/customers/host/detail/estate';
import HostInfo from '../../pages/UserType/admin/manage/customers/host/detail/info';
import HostLsettlement from '../../pages/UserType/admin/manage/customers/host/detail/lsettlement';
import HostStatusDetectLayout from '../../pages/UserType/admin/manage/customers/host/detail/sections/layout';
import User from '../../pages/UserType/admin/manage/customers/user';
import UserInfo from '../../pages/UserType/admin/manage/customers/user/detail/info';
import Lcomplaint from '../../pages/UserType/admin/manage/customers/user/detail/lcomplaint';
import Linfo from '../../pages/UserType/admin/manage/customers/user/detail/Linfo';
import Lmemo from '../../pages/UserType/admin/manage/customers/user/detail/memo';
import Lmove from '../../pages/UserType/admin/manage/customers/user/detail/lmove';
import UserLsettlement from '../../pages/UserType/admin/manage/customers/user/detail/lsettlement';
import RequestAndStatus from '../../pages/UserType/admin/manage/customers/user/detail/requestandstatus';
import UserStatusDetectLayout from '../../pages/UserType/admin/manage/customers/user/detail/sections/layout';
import UserStatus from '../../pages/UserType/admin/manage/customers/user/detail/status';
import Dashboard from '../../pages/UserType/admin/manage/dashboard';
import Buildings from '../../pages/UserType/admin/manage/estates/buildings';
import BuildingDetail from '../../pages/UserType/admin/manage/estates/buildings/detail';
import Rooms from '../../pages/UserType/admin/manage/estates/rooms';
import RoomsMapView from '../../layouts/common/MapView';
import CustomRoomRequesets from '../../layouts/common/CustomRoomRequests';
import StepOne from '../../pages/UserType/admin/manage/moverequests/stepone';
import TenantContractsMain from '../../pages/UserType/admin/manage/moves/main';
import Paccounts from '../../pages/UserType/admin/manage/paccounts';
import RoomDetailPage from '../../pages/UserType/admin/manage/roomInfo';
import SettingPage from '../../pages/UserType/admin/manage/setting';
import RemoveFurnitures from '../../layouts/admin/RemoveFurniture';
import Settlements from '../../pages/UserType/admin/manage/settlements';
import SubscriptionSettlements from '../../pages/UserType/admin/manage/settlements/subscription';
import SupportPaymentCompanyDetailPage from '../../pages/UserType/admin/manage/supportpayment/main/detail/info';
import TourMain from '../../pages/UserType/admin/manage/tours/main';
import WpMainPage from '../../pages/UserType/admin/manage/wp/main';
import PlaygroundOne from '../../layouts/common/Playground/one';
import PlaygroundThree from '../../layouts/common/Playground/three';
import PlaygroundTwo from '../../layouts/common/Playground/two';
import Loading from '../../components/common/Loading';
import RoomDetail from '../../layouts/common/RoomDetail';
import AgencyCompanyDetailPage from '../../pages/UserType/admin/manage/customers/agency/detail/info';
import DetailLayoutRouter from '../../pages/UserType/admin/manage/customers/agency/detail/sections/DetailLayoutRouter';
import Detail from '../../pages/UserType/admin/manage/announcements/main/detail';
import Transfer from '../../pages/UserType/admin/manage/transfer';

const AdminCheckRoute: React.FC = () => {
  const naviagte = useNavigate();

  useEffect(() => {
    document.addEventListener('keydown', (e: KeyboardEvent) => {
      if (e.altKey && e.ctrlKey) {
        if (e.key === '1') {
          naviagte(
            '/admin/playground/one?level=7&left_top=%5B126.81%2C37.65%5D&right_bottom=%5B126.98%2C37.52%5D&center_lat=37.590436837847946&center_lng=126.90235363444833&select_room_id=6368ae7a540c64068c07eeb7',
          );
        } else if (e.key === '2') {
          naviagte('/admin/playground/two');
        } else if (e.key === '3') {
          naviagte('/admin/playground/three');
        }
      }
    });
  }, []);

  const { data, isLoading } = useGetUserType();

  const { company_type: userType } = data;

  if (userType !== 'admin') {
    console.log('logged in, but not admin');

    return <Navigate to={{ pathname: '/noaccess' }} />;
  }

  return (
    <Routes>
      <Route element={<UnderTapLayout />}>
        <Route path="dashboard">
          <Route path=":type" element={<Dashboard />} />
        </Route>
        <Route path="chat">
          <Route path=":chatroomid" element={<ChatPage />} />
        </Route>
        <Route path="manage">
          <Route path="customers">
            <Route path="user">
              <Route index element={<User />} />
              <Route element={<UserStatusDetectLayout />}>
                <Route path="detail">
                  {/* 입주 전 */}
                  <Route path="info" element={<UserInfo />} />
                  <Route path="request" element={<RequestAndStatus />} />
                  <Route path="status" element={<UserStatus />} />
                  <Route path="memo" element={<Lmemo />} />
                  {/* 입주 후 */}
                  <Route path="linfo" element={<Linfo />} />
                  <Route path="lmove" element={<Lmove />} />
                  <Route path="lsettlement" element={<UserLsettlement />} />
                  <Route path="lcomplaint" element={<Lcomplaint />} />
                  <Route path="memo" element={<Lmemo />} />
                </Route>
              </Route>
            </Route>

            <Route path="company">
              <Route index element={<Company />} />
              <Route element={<CompanyStatusDetectLayout />}>
                <Route path="detail">
                  <Route path="info" element={<CompanyInfo />} />
                  <Route path="supportpayment" element={<SupportPaymentCompanyDetailPage />} />
                  <Route path="status" element={<CompanyStatus />} />
                  <Route path="lsettlement" element={<CompanyLsettlement />} />
                  <Route path="transfer-out" element={<Transfer />} />
                  <Route path="memo" element={<CompanyLmemo />} />
                </Route>
              </Route>
            </Route>
            <Route path="agency">
              <Route index element={<Agency />}></Route>
              <Route element={<DetailLayoutRouter />}>
                <Route path="detail">
                  <Route path="info" element={<AgencyCompanyDetailPage />} />
                  <Route path="memo" element={<CompanyLmemo />} />
                </Route>
              </Route>
            </Route>
            <Route path="host">
              <Route index element={<Host />} />
              <Route element={<HostStatusDetectLayout />}>
                <Route path="detail">
                  <Route path="info" element={<HostInfo />} />
                  <Route path="contract" element={<HostContract />} />
                  <Route path="estate" element={<HostEstate />} />
                  <Route path="lsettlement" element={<HostLsettlement />} />
                  <Route path="memo" element={<Lmemo />} />
                </Route>
              </Route>
            </Route>
          </Route>
          <Route path="wp">
            <Route path="main" element={<WpMainPage />} />
          </Route>

          <Route path="tours">
            <Route path="main" element={<TourMain />} />
          </Route>
          <Route path="moves">
            <Route path="main" element={<TenantContractsMain />} />
          </Route>
          <Route path="companies">
            <Route path="new" element={<New />} />
            <Route path="existing" element={<Existing />} />
          </Route>
          <Route path="moverequests">
            <Route path="stepone" element={<StepOne />} />
          </Route>

          <Route path="estates">
            <Route path="buildings">
              <Route index element={<Buildings />} />
              <Route path="detail">
                <Route path=":buildingId" element={<BuildingDetail />} />
              </Route>
            </Route>
            <Route path="rooms">
              <Route index element={<Rooms />} />
              <Route path="mapview" element={<RoomsMapView />} />
            </Route>
          </Route>
          <Route path="roomInfo">
            <Route path=":id" element={<RoomDetail />} />
          </Route>
          <Route path="complaints">
            <Route path="main" element={<Complaints />} />
          </Route>
          <Route path="chats">
            <Route path="all" element={<All />} />
            <Route path="important" element={<Important />} />
            <Route path="users" element={<ChatUsers />} />
            <Route path="agencies" element={<ChatUsers />} />
          </Route>
          <Route path="settlements">
            <Route path="main" element={<Settlements />} />
            <Route path="subscription" element={<SubscriptionSettlements />} />
          </Route>
          <Route path="announcements">
            <Route path="main">
              <Route index element={<Announcements />} />
              <Route path="detail">
                <Route path=":announcement_id" element={<AnnouncementDetail />} />
              </Route>
              <Route path="post">
                <Route path="announcement" element={<PostAnnouncementPage />} />
              </Route>
            </Route>
            <Route path="setting" element={<AnnoucementSetting />} />
          </Route>
          <Route path="setting">
            <Route index element={<SettingPage />} />
            <Route path="functions">
              <Route path="removefurnitures" element={<RemoveFurnitures />} />
            </Route>
          </Route>
          <Route path="paccounts">
            <Route index element={<Paccounts />} />
          </Route>
          <Route path="transfer">
            <Route index element={<Transfer />} />
          </Route>
          {/* 기능 완료, 미사용중 */}
          {/* <Route path="supportpayment">
            <Route path="main">
              <Route index element={<SupportPaymentMain />} />
              <Route path="detail">
                <Route path="info" element={<SupportPaymentCompanyDetailPage />} />
              </Route>
            </Route>
            <Route path="preservicerequests" element={<PreServiceRequestsPage />} />
            <Route path="settlements">
              <Route path="subscription" element={<SupportPaymentSubscriptionSettlementsPage />} />
            </Route>
          </Route> */}
        </Route>
        <Route path="playground">
          <Route path="one" element={<PlaygroundOne />} />
          <Route path="two" element={<PlaygroundTwo />} />
          <Route path="three" element={<PlaygroundThree />} />
        </Route>
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
};

export default AdminCheckRoute;

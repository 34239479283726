import { Box, Tooltip, useDisclosure } from '@chakra-ui/react';
import { FaPlusInCircle } from '../../../../statics/common/icons';
import RegisterBuildingRoom from '../../Modals/RegisterBuildingRoom';
import * as Type from '../../../../types/common/CommonType';

interface Props {
  isOpen: boolean;
  onOpen: Type.FuntionType;
  onClose: Type.FuntionType;
}
const AddNewRoomBtn: React.FC<Props> = ({ isOpen, onOpen, onClose }) => {
  return (
    <>
      <FaPlusInCircle onClick={onOpen} />
      <RegisterBuildingRoom isOpen={isOpen} onClose={onClose} />
    </>
  );
};

export default AddNewRoomBtn;

import { Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import format from 'date-fns/format';
import QueryParamsLayout from '../../../../../../layouts/common/main/QueryParamsLayout';
import UserTooltip from '../../../../../../components/common/Tooltip';
import { useGetAllCompaniesTenants } from '../../../../../../lib/admin/hooks/query';
import { useNavigateToUserDetailPage } from '../../../../../../lib/common/utils/utils';
import { currentParamsObject, ToAllFilledObj } from '../../../../../../lib/common/utils/base';
import { tenanStatusTypeTrans } from '../../../../../../lib/subscriber/utils/utils';
import { datedotformat } from '../../../../../../lib/common/utils/dateformatter';
import { phoneNumberdashfy } from '../../../../../../lib/common/utils/digitformatter';
import Loading from '../../../../../../components/common/Loading';
import * as Util from '../../../../../../lib/common/utils/Util';

const User = () => {
  const [onNavigate] = useNavigateToUserDetailPage();
  const { search: srch } = location;
  const _params = currentParamsObject();
  const params = ToAllFilledObj(_params);

  console.log('this is debug params', params);

  const res = useGetAllCompaniesTenants({
    axiosConfig: {
      params,
    },
  });

  const { isLoading } = res;
  if (isLoading) return <Loading />;
  return (
    <QueryParamsLayout res={res}>
      <Table variant="head_silverbg_toplining">
        <Thead bgColor={'#F8F8F8'} borderColor={'gray.100'}>
          <Tr>
            <Th>가입일</Th>
            <Th>소속</Th>
            <Th>이름</Th>
            <Th>상태</Th>
            <Th>연락처</Th>
            <Th>이메일</Th>
          </Tr>
        </Thead>
        <Tbody>
          {res.data?.result?.map((tenant, index) => {
            console.log(index, tenant.user.tenant_status);

            return (
              <Tr key={srch + index}>
                <Td>{datedotformat(tenant.user.createdAt)}</Td>
                <Td>{tenant.name}</Td>
                <Td textStyle={'pointer'}>
                  <UserTooltip memo={tenant.user.default_memo}>
                    <Text onClick={() => onNavigate(tenant.user.tenant_status, tenant.user._id)}>
                      {tenant.user.user_name}
                    </Text>
                  </UserTooltip>
                </Td>
                <Td>{tenanStatusTypeTrans(tenant.user.tenant_status)}</Td>
                <Td>{phoneNumberdashfy(tenant.user.phone_number_first)}</Td>
                <Td>{tenant.user.email}</Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </QueryParamsLayout>
  );
};

export default User;

import { VStack } from '@chakra-ui/react';
import { Outlet, useSearchParams } from 'react-router-dom';
import AfterLayout from './sections/AfterLayout';
import BeforeLayout from './sections/BeforeLayout';

const StatusDetectLayout = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get('id');
  //   서버에 id 넘기고 계약 status 갖고오기.
  let status;
  if (id === '11111') {
    status = false;
  } else {
    status = true;
  }

  return (
    <VStack alignItems={'start'} w={'100%'} h="full">
      {status ? (
        <AfterLayout>
          <Outlet />
        </AfterLayout>
      ) : (
        <BeforeLayout>
          <Outlet />
        </BeforeLayout>
      )}
    </VStack>
  );
};

export default StatusDetectLayout;

import { CheckCircleIcon } from '@chakra-ui/icons';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  Center,
  VStack,
  Box,
  HStack,
  Button,
  Flex,
  Spinner,
  Text,
  Link,
} from '@chakra-ui/react';
import { useState } from 'react';
import { ModalDisclosureProps } from '../../common/Modals/base';
import { Document, Page, pdfjs } from 'react-pdf';
import { LeftArrowIcon, RightArrowIcon } from '../../../statics/common/icons';
import Scrollbars from 'react-custom-scrollbars-2';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface Location {
  강원도: object;
  경기도: object;
  서울특별시: object;
}

const ContractPDF: React.FC<ModalDisclosureProps> = ({ isOpen, onClose }) => {
  const [sido, setSido] = useState<keyof Location | ''>('');
  const [sigungu, setSigungu] = useState<any | ''>('');
  const [dong, setDong] = useState<string | ''>('');

  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: any }) => {
    setNumPages(numPages);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside" size={'3xl'} motionPreset="slideInRight">
      <ModalOverlay />
      <ModalContent>
        <Flex h={'full'} justifyContent={'center'} flexDir={'column'}>
          <Box borderBottomWidth={'1px'} borderBottomColor={'gray.200'}>
            <Flex
              position={'sticky'}
              zIndex={10000}
              alignItems={'center'}
              justifyContent={'space-between'}
              fontSize={'18px'}
              fontWeight={'medium'}
              mx={7}
              my={4}
            >
              <Text>
                {`2022.02.19`} {`어드밴스드`} {`10인`} / {`연 결제`}
              </Text>
              <Button variant={'grayBorder_blackFont'} as={Link} h={'34px'} href="/test.txt" download>
                PDF로 다운로드
              </Button>
            </Flex>
          </Box>

          <Document
            file="/test.pdf"
            onLoadSuccess={onDocumentLoadSuccess}
            loading={
              <Center h="600px">
                <Spinner color="zetaBlue" />
              </Center>
            }
          >
            <Scrollbars style={{ height: '700px' }}>
              <Page pageNumber={pageNumber} width={743} />
            </Scrollbars>

            <Center position={'sticky'}>
              <Text
                p={'10px'}
                cursor={'pointer'}
                onClick={() => (pageNumber > 1 ? setPageNumber(pageNumber - 1) : null)}
              >
                <LeftArrowIcon w={6} h={6} />
              </Text>
              <Text>
                {pageNumber} of {numPages}
              </Text>
              <Text
                p={'10px'}
                cursor={'pointer'}
                onClick={() => (pageNumber < numPages ? setPageNumber(pageNumber + 1) : null)}
              >
                <RightArrowIcon w={6} h={6} />
              </Text>
            </Center>
          </Document>
        </Flex>
      </ModalContent>
    </Modal>
  );
};

export default ContractPDF;

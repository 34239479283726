import { Box, Image, Link, Spinner, Text, VStack } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { DeleteCircle } from '../../../../statics/common/icons';

// Formik name is 'pictures'.

interface ThumbProps {
  picture: File;
}

const Thumb_2: React.FC<ThumbProps> = ({ picture }) => {
  const [thumbFile, setThumbFile] = useState<any>('');
  const { values, setFieldValue, handleChange, getFieldProps } = useFormikContext();

  useEffect(() => {
    // URL.revokeObjectURL((file as any).preview);
    setThumbFile(
      Object.assign(picture, {
        preview: URL.createObjectURL(picture),
      }),
    );
  }, [thumbFile]);

  const fileRemove = () => {
    const formikFiles = (values as any).pictures as Array<any>;
    const newFiles = [] as any;
    formikFiles.map((formikFile: any) => {
      if (formikFile.name === picture.name) {
        console.log(formikFile);
      } else {
        // Object.
        URL.revokeObjectURL((picture as any).preview);
        newFiles.push(formikFile);
      }
    });
    console.log(typeof picture, picture);
    // URL.revokeObjectURL((file as any).preview);
    setFieldValue('pictures', newFiles);
    setThumbFile('');
  };

  return thumbFile !== '' ? (
    <VStack>
      {/* <Box>{thumbFile.name}</Box> */}
      {(thumbFile.type as string).includes('image') ? (
        <Box position={'relative'}>
          <Image src={thumbFile.preview} boxSize={'100px'} objectFit={'cover'} alt={thumbFile.name} />
          <Box position="absolute" top={0} right={0} transform={'translate(40%, -40%)'} zIndex={20}>
            {/* <Box position="absolute" zIndex={20}> */}
            <DeleteCircle
              w={5}
              h={5}
              _hover={{ opacity: 0.6 }}
              transition={'0.18s linear'}
              cursor={'pointer'}
              onClick={fileRemove}
            />
          </Box>
        </Box>
      ) : (
        // <div>aef</div>
        <Text
          noOfLines={2}
          as={Link}
          href={`/${thumbFile.path}`}
          download
          borderWidth={'1px'}
          borderColor={'silverGray'}
          borderRadius={'5px'}
          color={'highGray'}
          w={'200px'}
          h={'max-content'}
          px={2}
          _hover={{ opacity: 0.6 }}
          transition={'0.18s ease'}
          cursor={'pointer'}
        >
          {thumbFile.name}
        </Text>
      )}
    </VStack>
  ) : null;
};

export default Thumb_2;

import { Box, Button, Center, Flex, HStack, Tag, Textarea } from '@chakra-ui/react';
import { useState } from 'react';
// import { loadUserLists, saveMessage, testCollection } from '../../../../lib/firebase/init';
import { HouseAddChatIcon, HouseAddIcon, ImageChatIcon } from '../../../../../statics/common/icons';
import OptionBtn from '../../../../../components/agent/Button/OptionBtn';

const ChatRoom = () => {
  const initialTextChat = '';
  const [textChat, setTextChat] = useState<string>(initialTextChat);

  const onCheckEnter = (e: any) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      handleSubmit(e);
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    console.log(textChat);
    // saveMessage(textChat);
    setTextChat(initialTextChat);
  };

  const testSubmit = (e: any) => {
    e.preventDefault();
    // testCollection('test');
    // loadUserLists();
  };
  return (
    <Flex flexDir={'column'} h="100%">
      {/* 상단 */}
      <Box borderBottom="1px solid" borderColor={'silverGray'} boxShadow="0px 2px 4px 0px #0000001A">
        <HStack px="6" py="3" h={'71px'}>
          <Box fontSize={'31px'} fontWeight={'bold'}>
            상대방이름
          </Box>
          <Tag variant={'green'} h={'20px'}>
            상대방Status
          </Tag>
        </HStack>
      </Box>
      {/* 중단 */}
      <Box flexGrow={1}>채팅 내용</Box>
      {/* 하단 */}
      <Box border="1px solid" borderColor={'silverGray'} m={4} borderRadius={'0.3rem'}>
        <Box borderBottom="1px solid rgb(227, 231, 241)">
          <HStack px={5} py={2}>
            <OptionBtn>
              <ImageChatIcon />
              <Box fontSize={'10px'} fontWeight={'bold'}>
                사진
              </Box>
            </OptionBtn>
            <OptionBtn>
              <HouseAddChatIcon />
              <Box fontSize={'10px'} fontWeight={'bold'}>
                추가 매물
              </Box>
            </OptionBtn>
          </HStack>
        </Box>
        <Flex>
          <Textarea
            value={textChat}
            onChange={(e) => setTextChat(e.target.value)}
            onKeyPress={onCheckEnter}
            placeholder="메세지를 입력해 주세요. (Enter : 전송)"
            fontSize={'14px'}
            fontWeight={'medium'}
            border={'none'}
            borderRadius={'unset'}
            resize="none"
            _focus={{}}
          />
          <Center p={2}>
            <Button onClick={handleSubmit} variant={'basic'} style={{ height: '100%' }}>
              전송
            </Button>
            <Button onClick={testSubmit} variant={'basic'} style={{ height: '100%', marginLeft: '8px' }}>
              테스트버튼
            </Button>
          </Center>
        </Flex>
      </Box>
    </Flex>
  );
};

export default ChatRoom;

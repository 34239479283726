import { Box, Flex, Collapse, Text, useDisclosure } from '@chakra-ui/react';
import RequestContentTable from '../../Table/requestContentTable';
import UsageContentTable from '../../Table/usageContentTable';
import { HistoryType, Service, ServiceDetail } from '../../../../lib/common/utils/axios/types';
import { RightArrowIcon } from '../../../../statics/common/icons';

interface FiveMovingInandExtendProps {
  serviceData: ServiceDetail;
  usageData: HistoryType[] | undefined;
  userData: any;
}

// _id: string;
// name: string;
// employee: {
//   _id: string;
//   phone_number_first: string;
//   user_name: string;
//   email: string;
//   service: ServiceDetail;
// };

export const FiveMovingInandExtend: React.FC<FiveMovingInandExtendProps> = ({ serviceData, usageData, userData }) => {
  const { isOpen: isFormOpen, onToggle: onFormToggle } = useDisclosure();
  const { isOpen: isHistoryOpen, onToggle: onHistoryToggle } = useDisclosure();

  const parsed_service_data = {
    _id: '',
    name: '',
    employee: {
      _id: '',
      phone_number_first: '',
      user_name: userData.user_name,
      email: userData.email,
      service: { ...serviceData },
    },
  } as Service;

  return (
    <>
      {/* 1. 신청 내용 */}
      <Box w={'full'} px={7} py={6}>
        <Flex onClick={onFormToggle} alignItems={'center'} justifyContent={'space-between'} cursor={'pointer'}>
          <Text fontWeight={'medium'} fontSize={'14px'}>
            신청 내용
          </Text>
          <RightArrowIcon
            w={6}
            h={6}
            transform={`rotate(${isFormOpen ? 270 : 90}deg)`}
            transition={'transform 0.15s linear'}
          />
        </Flex>
        <Box pb={2} borderBottomWidth={'1px'} borderBottomColor={'gray'} />
        <Collapse in={!isFormOpen}>
          <RequestContentTable data={parsed_service_data} />
        </Collapse>
      </Box>
      {/* 2. 이용 내역 */}
      <Box w={'full'} px={7} pb={6}>
        <Flex onClick={onHistoryToggle} alignItems={'center'} justifyContent={'space-between'} cursor={'pointer'}>
          <Text fontWeight={'medium'} fontSize={'14px'}>
            {parsed_service_data.employee.user_name}님의 이용 내역
          </Text>
          <RightArrowIcon
            w={6}
            h={6}
            transform={`rotate(${isHistoryOpen ? 270 : 90}deg)`}
            transition={'transform 0.15s linear'}
          />
        </Flex>
        <Box pb={2} borderBottomWidth={'1px'} borderBottomColor={'gray'} />
        <Collapse in={!isHistoryOpen}>
          <Flex flexDir={'column'} w={'full'} flexGrow={1}>
            <UsageContentTable data={usageData} />
          </Flex>
        </Collapse>
      </Box>
    </>
  );
};

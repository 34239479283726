import {
  Box,
  Button,
  Checkbox,
  Flex,
  HStack,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Table,
  Tbody,
  Text,
  Tr,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import _ from 'lodash';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import InitFormikEditableButton from '../Button/InitFormikEditableButton';
import DropzoneOpacityFormikThumb, { FileInfosProps } from '../Dropzone/DropzoneOpacityFormikThumb';
import MultiFilesCommonDropzone from '../Dropzone/MultiFilesCommonDropzone';
import { filterRemovedFiles } from '../../../lib/common/utils/dropzone/function';
import Editor from '../Editor/EditorWithUseQuill';
import { tabletitle_fitcontent_props, TdFullWContainer } from './Tr';
import { useCreateAnnouncement, useUpdateAnnouncement } from '../../../lib/admin/hooks/mutation';
import { useGetAdminAnnouncement } from '../../../lib/admin/hooks/query';
import { announcementTypeTrans, companySelectTypeTrans, userSelectTypeTrans } from '../../../lib/common/utils/utils';
import { CompanySelectTypeEnum } from '../../../lib/common/utils/axios/types';
import { booleanToString, boolstrToBoolean } from '../../../lib/common/utils/base';
import { announcement_select, choose_user_with_total_optional_select } from '../../../statics/common/data/selectbox';
import { error, warning } from '../../../statics/common/data/toast';
import { datedetaildotkoformat, dateISOflatformat } from '../../../lib/common/utils/dateformatter';
import SelectBox from '../../agent/SelectBox/base';
import Loading from '../Loading';

interface AnnoucementUploadTableProps {
  isPatchMode: boolean;
}

const AnnoucementUploadTable: React.FC<AnnoucementUploadTableProps> = ({ isPatchMode }) => {
  const [isEditable, setIsEditable] = useState<boolean>(!isPatchMode);
  const toast = useToast();
  const { announcement_id } = useParams();
  const res = useGetAdminAnnouncement(announcement_id || '', {
    options: {
      enabled: isPatchMode,
    },
  });
  const { mutateAsync: updateAnnouncement } = useUpdateAnnouncement();
  const { mutateAsync: createAnnouncement } = useCreateAnnouncement();
  const navigate = useNavigate();
  // const [funcInSuccessProcess, originalPreviewsUrl, setOriginalPreviewsUrl] = useFilesPreview();

  // console.log('originalPreviewsUrl =>', originalPreviewsUrl);

  console.log(res);

  // if (!res.data && !res.isSuccess) {
  //   return null;
  // }

  const initialValues = !res.data
    ? {
        title: '',
        description: '',
        // 공지 구분
        announcement_select: {
          type: '',
          company_target: '',
          user_target: '',
        },
        is_fixed: false,
        // 게시일자
        reserved_date: {
          instant: 'true',
          date: '',
        },
        file_infos: [],
      }
    : {
        title: res.data.title,
        description: res.data.description,
        // 공지 구분
        announcement_select: {
          type: res.data.announcement_type,
          company_target: res.data.readable_company_type,
          user_target: res.data.readable_user_type,
        },
        is_fixed: res.data.is_fixed,
        // 게시일자
        reserved_date: {
          instant: 'true',
          date: res.data.reserved_date,
        },
        file_infos: res.data.file_urls?.map((url) => {
          return {
            url,
            removed: false,
          } as FileInfosProps;
        }),
      };
  const { isLoading } = res;
  if (isLoading) return <Loading />;
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        title: Yup.string().required(),
        description: Yup.string().required(),
        announcement_select: Yup.object()
          .shape({
            type: Yup.string().required('type is required'),
            company_target: Yup.string().required('company_target is required'),
            user_target: Yup.string().required('user_target is required'),
          })
          .required('required'),
        // reserved_date: Yup.object().shape({
        //   date: Yup.string().when('reserved_date', {
        //     is: (reserved_date: any) => reserved_date.instant === 'true',
        //     then: Yup.string().required('예약 필수'),
        //   }),
        // }),
        reserved_date: Yup.object().test({
          message: 'check',
          test: (reserved_date: any) => {
            if (reserved_date.instant === 'true') {
              return true;
            } else {
              if (reserved_date.date) {
                return true;
              } else {
                return false;
              }
            }
          },
        }),
      })}
      onSubmit={async (values, { setSubmitting, validateForm }) => {
        setSubmitting(true);
        if (!values.description) {
          return toast({ ...error, title: '게시내용을 입력해주세요', description: '' });
        }
        const formData = new FormData();
        formData.append('announcement_type', values.announcement_select.type);

        if (boolstrToBoolean(values.reserved_date.instant)) {
          formData.append('reserved_date', new Date().toString());
        } else {
          formData.append('reserved_date', values.reserved_date.date);
        }

        const [_1, _2, filtered_Files, filtered_urls] = filterRemovedFiles(values.file_infos);

        formData.append('is_fixed', booleanToString(values.is_fixed));
        formData.append('readable_company_type', values.announcement_select.company_target);
        formData.append('readable_user_type', values.announcement_select.user_target);
        formData.append('title', values.title);
        formData.append('description', '<div>' + values.description + '</div>');
        // formData.append('picture_urls');
        // formData.append('pictures');

        // filtered_urls.map((url) => {
        //   formData.append('file_urls[]', url);
        // });
        formData.append(
          'file_urls',
          JSON.stringify(
            filtered_urls.map((url) => {
              return url;
            }),
          ),
        );

        filtered_Files.map((file) => {
          formData.append('files', file);
        });
        if (isPatchMode) {
          await updateAnnouncement({
            announcement_id: announcement_id || '',
            formData,
          })
            .then(() => {
              navigate('/admin/manage/announcements/main');
            })
            .finally(() => {
              setSubmitting(false);
            });
        } else {
          await createAnnouncement({
            formData,
          })
            .then(() => {
              navigate('/admin/manage/announcements/main');
            })
            .finally(() => {
              setSubmitting(false);
            });
        }
      }}
    >
      {(props) => {
        const isNotAnyChange = _.isEqual(props.initialValues, props.values);
        // console.log('errors =>', props.errors);

        const makeResult = async (files: File[]) => {
          // funcInSuccessProcess(files);

          if (_.isEmpty(files)) {
            //
          } else {
            props.setFieldValue('file_infos', [
              ...props.values.file_infos,
              ...files.map((url) => ({
                url,
                removed: false,
              })),
            ]);
          }
        };
        // console.log(props.errors);

        // 공지 구분(2~3)
        // 구분2: 회사 종류
        const handleChangeAnnouncementCompanyTarget = (e: React.ChangeEvent<HTMLSelectElement>) => {
          if (props.values.announcement_select.user_target) {
            toast({ ...warning, title: '회사 종류 변경', description: '유저 종류를 다시 선택해주세요.' });
            props.setFieldValue('announcement_select.user_target', '');
          }
          props.setFieldValue('announcement_select.company_target', e.target.value);
        };
        // 구분3: 유저 종류
        const isDisabledAnnouncementUserTarget = !props.values.announcement_select.company_target;
        const announcement_select_user_target = choose_user_with_total_optional_select(
          props.values.announcement_select.company_target as CompanySelectTypeEnum,
        );

        console.log(props.values);

        return (
          <Form>
            <VStack justifyContent={'start'} w={'100%'} spacing={6}>
              <VStack spacing={3} justifyContent={'start'} align={'flex-start'} w={'100%'}>
                {/* <Button type="submit">TEST</Button> */}
                <HStack w={'full'} spacing={2} justify={'space-between'}>
                  <Box flex={1} />
                  {isPatchMode ? (
                    <InitFormikEditableButton
                      props={props}
                      isDisabledCompleteEdit={isNotAnyChange}
                      isEditable={isEditable}
                      setIsEditable={setIsEditable}
                    />
                  ) : (
                    <Button variant={'basic'} type="submit" isDisabled={!props.isValid || isNotAnyChange}>
                      공지 업로드하기
                    </Button>
                  )}
                </HStack>
                <Table w={'100%'} variant="simple">
                  <Tbody w={'100%'}>
                    <Tr>
                      <TdFullWContainer title={'공지 구분'} {...tabletitle_fitcontent_props}>
                        {isEditable ? (
                          <HStack>
                            <Field
                              as={SelectBox}
                              name={'announcement_select.type'}
                              w="120px"
                              placeholder="게시물종류"
                              isInvalid={
                                props.touched.announcement_select?.type && props.errors.announcement_select?.type
                              }
                            >
                              {announcement_select.type.map((option) => {
                                return (
                                  <option key={option.value} value={option.value}>
                                    {option.label}
                                  </option>
                                );
                              })}
                            </Field>
                            <Field
                              as={SelectBox}
                              name={'announcement_select.company_target'}
                              w="120px"
                              placeholder="회사 종류"
                              onChange={handleChangeAnnouncementCompanyTarget}
                              isInvalid={
                                props.touched.announcement_select?.company_target &&
                                props.errors.announcement_select?.company_target
                              }
                            >
                              {announcement_select.company_target.map((option) => {
                                return (
                                  <option key={option.value} value={option.value}>
                                    {option.label}
                                  </option>
                                );
                              })}
                            </Field>
                            <Field
                              as={SelectBox}
                              name={'announcement_select.user_target'}
                              w="120px"
                              placeholder="유저 종류"
                              isDisabled={isDisabledAnnouncementUserTarget}
                              isInvalid={
                                props.touched.announcement_select?.user_target &&
                                props.errors.announcement_select?.user_target
                              }
                            >
                              {announcement_select_user_target.map((option: any) => {
                                return (
                                  <option key={option.value} value={option.value}>
                                    {option.label}
                                  </option>
                                );
                              })}
                            </Field>
                            {/* <SelectBox isDisabled></SelectBox> */}
                            <Field
                              as={Checkbox}
                              name={'is_fixed'}
                              size={'smd14'}
                              pl={2}
                              onChange={(e: any) => {
                                props.setFieldValue('is_fixed', e.target.checked);
                              }}
                              isChecked={props.values.is_fixed}
                            >
                              상단 고정글
                            </Field>
                          </HStack>
                        ) : (
                          <HStack spacing={3}>
                            <HStack spacing={1}>
                              <Box color={'gray.400'}>종류</Box>
                              <Box>{announcementTypeTrans(props.values.announcement_select.type as any)}</Box>
                            </HStack>
                            <HStack spacing={1}>
                              <Box color={'gray.400'}>작성자</Box>
                              <Box>
                                {companySelectTypeTrans(props.values.announcement_select.company_target as any)}
                              </Box>
                              <Box>/</Box>
                              <Box>{userSelectTypeTrans(props.values.announcement_select.user_target as any)}</Box>
                            </HStack>
                          </HStack>
                        )}
                      </TdFullWContainer>
                    </Tr>
                    <Tr>
                      <TdFullWContainer title={'제목'} {...tabletitle_fitcontent_props}>
                        {isEditable ? (
                          <Field
                            name={'title'}
                            as={Input}
                            variant={'white36'}
                            isInvalid={props.touched.title && props.errors.title}
                          />
                        ) : (
                          <Text>{props.values.title}</Text>
                        )}
                      </TdFullWContainer>
                    </Tr>
                    <Tr>
                      <TdFullWContainer title={'게시일자'} {...tabletitle_fitcontent_props}>
                        {isEditable ? (
                          <RadioGroup
                            // onChange={setValue} value={value}
                            //   fontSize={'14px'}
                            size={'smd'}
                            onChange={(v) => {
                              if (v === 'false') {
                                props.setFieldValue('reserved_date.instant', 'false');
                              } else {
                                // 등록 즉시 게시 클릭시
                                props.setFieldValue('reserved_date.instant', 'true');
                                props.setFieldValue('reserved_date.date', '');
                              }
                            }}
                            value={props.values.reserved_date.instant}
                          >
                            <Stack direction="row" spacing={4}>
                              <Radio value={'true'} minW={'max'}>
                                등록 즉시 게시
                              </Radio>
                              <Radio value={'false'} minW={'max'}>
                                게시 예약
                              </Radio>
                              <Field
                                as={Input}
                                name={'reserved_date.date'}
                                w={'240px'}
                                variant={'white36'}
                                type={'datetime-local'}
                                min={dateISOflatformat(new Date())}
                                isInvalid={
                                  props.errors.reserved_date &&
                                  !(boolstrToBoolean(props.values.reserved_date.instant) as boolean)
                                }
                                isDisabled={boolstrToBoolean(props.values.reserved_date.instant) as boolean}
                              />
                            </Stack>
                          </RadioGroup>
                        ) : (
                          <Text>{datedetaildotkoformat(props.values.reserved_date.date)}</Text>
                        )}
                      </TdFullWContainer>
                    </Tr>
                    <Tr>
                      <TdFullWContainer title={'게시내용'} {...tabletitle_fitcontent_props}>
                        {isEditable ? (
                          <Editor formikKey={'description'} readOnly={false} isEditable={isEditable} />
                        ) : (
                          <Box dangerouslySetInnerHTML={{ __html: props.values.description }}></Box>
                        )}
                      </TdFullWContainer>
                    </Tr>
                    <Tr>
                      <TdFullWContainer title={'첨부파일'} {...tabletitle_fitcontent_props}>
                        {/* <Box>{datedotformat(createdAt)}</Box> */}
                        {isEditable ? (
                          <Box>
                            <Flex align={'center'}>
                              <Box mr={4}>
                                <MultiFilesCommonDropzone funcInSuccessProcess={makeResult} customVariant={'plus'} />
                              </Box>
                              <Box>파일 1개당 첨부용량 30MB, 파일 업로드 문서당 최대 5개 가능</Box>
                            </Flex>
                            {!_.isEmpty(props.values.file_infos) && (
                              <Box mt={2}>
                                <DropzoneOpacityFormikThumb
                                  file_infos={props.values.file_infos}
                                  onDeleteBtn={isEditable}
                                />
                              </Box>
                            )}
                          </Box>
                        ) : (
                          <DropzoneOpacityFormikThumb file_infos={props.values.file_infos} onDeleteBtn={isEditable} />
                        )}
                      </TdFullWContainer>
                    </Tr>
                  </Tbody>
                </Table>
              </VStack>
            </VStack>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AnnoucementUploadTable;

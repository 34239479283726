// 건물
export const security_facilities_checkbox = [
  {
    label: '현관보안',
    value: 'entrance_security',
  },
  {
    label: 'CCTV',
    value: 'cctv',
  },
  {
    label: '인터폰',
    value: 'interphone',
  },
  {
    label: '비디오폰',
    value: 'videophone',
  },
  {
    label: '카드키',
    value: 'cardkey',
  },
  {
    label: '방범창',
    value: 'security_window',
  },
  {
    label: '자체 경비원',
    value: 'own_security_guard',
  },
  {
    label: '사설 경비',
    value: 'private_security_guard',
  },
];
// 방
export const common_items_checkbox = [
  {
    label: '전기',
    value: 'electricity',
  },
  {
    label: '가스',
    value: 'gas',
  },
  {
    label: '수도',
    value: 'water',
  },
  {
    label: '인터넷',
    value: 'internet',
  },
  {
    label: 'TV',
    value: 'tv',
  },
  {
    label: '청소',
    value: 'clean',
  },
];
export const individual_items_checkbox = [
  {
    label: '전기',
    value: 'electricity',
  },
  {
    label: '가스',
    value: 'gas',
  },
  {
    label: '수도',
    value: 'water',
  },
  {
    label: '난방',
    value: 'heat',
  },
];
export const default_options_checkbox = [
  {
    label: '세탁기',
    value: 'washing_machine',
  },
  {
    label: '에어컨',
    value: 'air_conditioner',
  },
  {
    label: '냉장고',
    value: 'refrigerator',
  },
  {
    label: '싱크대',
    value: 'sink',
  },
  {
    label: '가스레인지',
    value: 'gas_range',
  },
  {
    label: '인덕션',
    value: 'induction',
  },
];

export const additional_options_checkbox = [
  {
    label: '침대',
    value: 'bed',
  },
  {
    label: '책상',
    value: 'desk',
  },
  {
    label: '옷장',
    value: 'closet',
  },
  {
    label: '행거',
    value: 'hanger',
  },
  {
    label: '신발장',
    value: 'shoe_closet',
  },
  {
    label: '붙박이장',
    value: 'built_in_wardrobe',
  },
  {
    label: 'TV',
    value: 'tv',
  },
  {
    label: '오븐',
    value: 'oven',
  },
  {
    label: '전자레인지',
    value: 'microwave',
  },
];

export const share_facilities_options_checkbox = [
  {
    label: '공용 주방',
    value: 'kitchen',
  },
  {
    label: '공용 샤워실',
    value: 'shower_room',
  },
  {
    label: '공용 화장실',
    value: 'toilet',
  },
  {
    label: '라운지',
    value: 'lounge',
  },
  {
    label: '테라스',
    value: 'terrace',
  },
  {
    label: '루프탑',
    value: 'rooftop',
  },
  {
    label: '세탁실',
    value: 'laundary_room',
  },
];
export const remodeling_items_checkbox = [
  {
    label: '장판',
    value: 'veneer',
  },
  {
    label: '벽지',
    value: 'wallpaper',
  },
  {
    label: '몰딩',
    value: 'molding',
  },
  {
    label: '화장실',
    value: 'toilet',
  },
  {
    label: '부엌',
    value: 'kitchen',
  },
];

import { FileInfosProps } from '../../../../components/common/Dropzone/DropzoneOpacityFormikThumb';
import _ from 'lodash';
import { isFile } from '../base';

/** return [filtered_exists[], filtered_Files_or_Strings[], filtered_Files[], filtered_urls[]] */
export const filterRemovedFiles = (
  file_infos: FileInfosProps[] | [] | undefined,
): [FileInfosProps[], string[], File[], string[]] => {
  if (_.isEmpty(file_infos)) {
    return [[], [], [], []];
  }
  // 값 복사
  const infos = !file_infos ? [] : [...file_infos];

  const filtered_exists =
    infos &&
    _.remove(infos, (value) => {
      return !value.removed;
    });

  const filtered_Files_or_Strings = filtered_exists?.map((v) => {
    return v.url;
  });

  const filtered_Files = (filtered_Files_or_Strings as any).filter((v: any) => {
    return isFile(v);
  });

  const filtered_urls = (filtered_Files_or_Strings as any).filter((v: any) => {
    return typeof v === 'string';
  });

  return [filtered_exists, filtered_Files_or_Strings, filtered_Files, filtered_urls];
};

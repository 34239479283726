import { Box, BoxProps, Flex, GridItem, GridItemProps, VStack } from '@chakra-ui/react';
import { motion } from 'framer-motion';

export const GItem: React.FC<GridItemProps> = ({ children, ...props }) => {
  return (
    <GridItem
      bg="white"
      as={motion.div}
      whileHover={{ scale: 1 }}
      borderRadius={'lg'}
      boxShadow={'2px 2px 20px 0px #DDDDDD'}
      {...props}
    >
      <Flex flexDir={'column'} w={'full'} h="full" alignItems={'flex-start'}>
        {children}
      </Flex>
    </GridItem>
  );
};

interface GTitleItem extends GridItemProps {
  upperSection: React.ReactNode;
  upperProps?: BoxProps;
  hasDivision?: boolean;
}

// const test = () => (
//   <motion.div whileHover={{
//     animat
//   }}>aef</motion.div>
// )

export const GTitleItem: React.FC<GTitleItem> = ({
  children,
  upperSection,
  upperProps,
  hasDivision = true,
  ...props
}) => {
  return (
    <GridItem
      as={motion.div}
      whileHover={{ scale: 1 }}
      // whileTap={{ scale: 1 }}
      // transition="all linear 0.18s"
      // transitionDelay={'1s'}
      // transitionTimingFunction={'ease-in-out'}
      bg="white"
      borderRadius={'lg'}
      boxShadow={'2px 2px 20px 0px #DDDDDD'}
      {...props}
    >
      {/* <motion.div whileHover={{scale: 1.01, transition: {delay: 0.3}}}></motion.div> */}
      <Flex flexDir={'column'} w={'full'} h="full" alignItems={'flex-start'}>
        <Box
          w={'full'}
          borderBottomColor={'borderGray'}
          boxShadow={
            hasDivision ? 'rgba(0, 0, 0, 0.02) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 2px 3px -2px' : 'unset'
          }
          px={5}
          py={4}
          {...upperProps}
        >
          {upperSection}
        </Box>
        <>{children}</>
      </Flex>
    </GridItem>
  );
};

import { Box, Button, HStack, Table, Tbody, Text, Thead, Tr, useDisclosure, VStack } from '@chakra-ui/react';
import { isEmptyArray, isUndefined } from '@chakra-ui/utils';
import { format } from 'date-fns';
import { Formik } from 'formik';
import { useSearchParams } from 'react-router-dom';
import CommonChatButton from '../../../../../../../../components/admin/Button/CommonChatButton';
import CommonChatButtonProps from '../../../../../../../../components/admin/Button/CommonChatButton';
import { CustomEditable } from '../../../../../../../../components/common/Editable';
import ExtendRequestForm from '../../../../../../../../components/subscriber/Modals/ExtendRequestForm';
import ExtraRequestForm from '../../../../../../../../components/subscriber/Modals/ExtraRequestForm';
import NewRequestForm from '../../../../../../../../components/subscriber/Modals/NewRequestForm';
import WithdrawalRequestForm from '../../../../../../../../components/subscriber/Modals/WithdrawalRequestForm';
import { WTd } from '../../../../../../../../components/common/Table/Td';
import { WTh } from '../../../../../../../../components/common/Table/Th';
import { TdFullWContainer } from '../../../../../../../../components/common/Table/Tr';
import { useGetCompanyManager } from '../../../../../../../../lib/admin/hooks/query';
import { request_type_transition } from '../../../../../../subscriber/manage/employee/UsageLists';
import CreateVirtualAccountModal from '../../../../../../../../components/common/Modals/CreateUserVirtualAccountModal';
import * as Yup from 'yup';
import { useUpdateUserInfo } from '../../../../../../../../lib/admin/hooks/mutation';
import { useState } from 'react';
import { isEqual } from 'lodash';
import FormikEditableValidationComponent from '../../../../../../../../components/common/FormikValidation/FormikEditableValidationInput';
import InitFormikEditableButton from '../../../../../../../../components/common/Button/InitFormikEditableButton';
import CreateUserVirtualAccountAndLeasePaymentModal from '../../../../../../../../components/common/Modals/CreateUserVirtualAccountAndLeasePaymentModal';
import { datedotformat } from '../../../../../../../../lib/common/utils/dateformatter';

const Info = () => {
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [clickedFormUserId, setClickedFormUserId] = useState('');
  const [clickedFormServiceId, setClickedFormServiceId] = useState('');
  const {
    isOpen: isNewRequestFormOpen,
    onOpen: onNewRequestFormOpen,
    onClose: onNewRequestFormClose,
  } = useDisclosure();
  const {
    isOpen: isExtraRequestFormOpen,
    onOpen: onExtraRequestFormOpen,
    onClose: onExtraRequestFormClose,
  } = useDisclosure();
  const {
    isOpen: isExtendRequestFormOpen,
    onOpen: onExtendRequestFormOpen,
    onClose: onExtendRequestFormClose,
  } = useDisclosure();
  const {
    isOpen: isWithdrawalRequestFormOpen,
    onOpen: onWithdrawalRequestFormOpen,
    onClose: onWithdrawalRequestFormClose,
  } = useDisclosure();
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get('id');
  const { mutateAsync: updateUserInfo } = useUpdateUserInfo();

  const { data } = useGetCompanyManager(id || '');

  if (isUndefined(data)) {
    return null;
  }

  const {
    employee: {
      _id: employee_id,
      user_name,
      user_type,
      email,
      phone_number_first,
      createdAt,
      company_name,
      services: serviceArrData,
      lease_contracts,
      chat_rooms,
      virtual_account,
    },
  } = data;

  console.log(data);

  return (
    <Formik
      initialValues={{
        user_name,
        phone_number_first,
      }}
      validationSchema={Yup.object().shape({
        user_name: Yup.string().required('대표 담당자명을 입력해 주세요.'),
        // r_department: Yup.string().required('부서 입력해 주세요.'),
        // r_position: Yup.string().required('직책을 입력해 주세요.'),
        phone_number_first: Yup.string()
          .matches(/^((?!-).)*$/, '- 없이 입력해 주세요.')
          .min(7, ({ min }) => `연락처는 최소 ${min}글자입니다.`)
          .max(16, ({ max }) => `연락처는 최대 ${max}글자입니다.`)
          .required('연락처를 입력해 주세요.'),
        // r_email: Yup.string().email('이메일 형식으로 입력해 주세요.').required('이메일을 입력해 주세요.'),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        await updateUserInfo({
          user_id: id || '',
          user_name: values.user_name,
          phone_number_first: values.phone_number_first,
        })
          .then(() => {
            setIsEditable(false);
          })
          .finally(() => {
            setSubmitting(false);
          });
      }}
    >
      {(props) => {
        const isNotAnyChange = isEqual(props.initialValues, props.values);

        const eviprops = {
          isEditable,
          props,
        };

        return (
          <VStack justifyContent={'start'} w={'100%'} spacing={6}>
            {/* 회원 정보 */}
            <VStack spacing={3} justifyContent={'start'} align={'flex-start'} w={'100%'}>
              <HStack w={'full'} spacing={2} justify={'space-between'}>
                <Box fontWeight={'bold'} fontSize={'18px'} textAlign={'start'} w={'112px'}>
                  회원 정보
                </Box>
                <Box flex={1} />
                <CommonChatButton chatRooms={chat_rooms} user_ids={[employee_id]} />
                <InitFormikEditableButton
                  props={props}
                  isDisabledCompleteEdit={isNotAnyChange}
                  isEditable={isEditable}
                  setIsEditable={setIsEditable}
                />
              </HStack>
              <Table w={'100%'} variant="simple">
                <Tbody w={'100%'}>
                  <Tr w={'100%'} borderTopWidth={'1px'} borderTopColor={'gainGray'}>
                    <TdFullWContainer title={'이름'}>
                      <FormikEditableValidationComponent {...eviprops} name={'user_name'} />
                    </TdFullWContainer>
                    <TdFullWContainer title={'기업명'}>
                      <Text>{company_name}</Text>
                    </TdFullWContainer>
                  </Tr>
                  <Tr>
                    <TdFullWContainer title={'휴대폰번호'}>
                      <FormikEditableValidationComponent {...eviprops} name={'phone_number_first'} />
                    </TdFullWContainer>
                    <TdFullWContainer title={'이메일'}>
                      <Text>{email}</Text>
                    </TdFullWContainer>
                  </Tr>
                  <Tr>
                    <TdFullWContainer title={'회원가입일'}>
                      <Box>{datedotformat(createdAt)}</Box>
                    </TdFullWContainer>
                    <TdFullWContainer title={'가상계좌'}>
                      {virtual_account?.account_number || (
                        <CreateUserVirtualAccountAndLeasePaymentModal userId={employee_id} userType={user_type as any}>
                          <Button variant={'basic'} height={'30px'}>
                            가상계좌 생성하기
                          </Button>
                        </CreateUserVirtualAccountAndLeasePaymentModal>
                      )}
                    </TdFullWContainer>
                  </Tr>
                </Tbody>
              </Table>
            </VStack>
            {/* 이용 내역 */}
            <VStack spacing={3} justifyContent={'start'} align={'flex-start'} w={'100%'}>
              <Box w={'100%'} fontWeight={'bold'} fontSize={'18px'} textAlign={'start'} flexGrow={1}>
                이용 내역
              </Box>
              <>
                <Table variant="head_silverbg_toplining">
                  <Thead bgColor={'#F8F8F8'} borderColor={'gray.100'}>
                    <Tr>
                      <WTh borderR>신청일</WTh>
                      <WTh borderR>신청 항목</WTh>
                      <WTh borderR>이용 기간</WTh>
                      <WTh borderR>상태 및 상세보기</WTh>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {serviceArrData.map((service) => (
                      <Tr h={'51px'} key={service._id}>
                        <WTd borderR>{datedotformat(service.createdAt)}</WTd>

                        <WTd borderR>{request_type_transition[service.request_type]}</WTd>
                        <WTd borderR>{service.how_long_month ? `${service.how_long_month}개월` : '-'}</WTd>
                        <WTd borderR>
                          {service.check_status === 'deprecated' ? (
                            <Text>새로운 신청으로 대체되었습니다.</Text>
                          ) : (
                            <>
                              <Button
                                variant={service.check_status === 'pending' ? 'grayBorder_blackFont' : 'text'}
                                textDecorationLine={service.check_status === 'pending' ? 'unset' : 'underline'}
                                textUnderlineOffset={'2px'}
                                h="34px"
                                onClick={() => {
                                  setClickedFormUserId(employee_id);
                                  setClickedFormServiceId(service._id);
                                  service.request_type === 'new'
                                    ? onNewRequestFormOpen()
                                    : service.request_type === 'changing'
                                    ? onExtraRequestFormOpen()
                                    : service.request_type === 'extending'
                                    ? onExtendRequestFormOpen()
                                    : service.request_type === 'withdrawal'
                                    ? onWithdrawalRequestFormOpen()
                                    : () => console.log('신청서 보기 오류');
                                }}
                              >
                                {/* 신청서 보기 */}
                                <Text>
                                  {service.check_status === 'pending'
                                    ? '신청서 보기'
                                    : service.check_status === 'approved'
                                    ? '승인'
                                    : service.check_status === 'cancelled'
                                    ? '취소'
                                    : service.check_status === 'rejected'
                                    ? '반려'
                                    : service.check_status === 'withdrawn'
                                    ? '철회'
                                    : ''}
                                </Text>
                              </Button>
                            </>
                          )}
                        </WTd>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
                <>
                  <NewRequestForm
                    isOpen={isNewRequestFormOpen}
                    onClose={onNewRequestFormClose}
                    service_id={clickedFormServiceId}
                    user_id={clickedFormUserId}
                  />
                  <ExtraRequestForm
                    isOpen={isExtraRequestFormOpen}
                    onClose={onExtraRequestFormClose}
                    service_id={clickedFormServiceId}
                    user_id={clickedFormUserId}
                  />
                  <ExtendRequestForm
                    isOpen={isExtendRequestFormOpen}
                    onClose={onExtendRequestFormClose}
                    service_id={clickedFormServiceId}
                    user_id={clickedFormUserId}
                  />
                  <WithdrawalRequestForm
                    isOpen={isWithdrawalRequestFormOpen}
                    onClose={onWithdrawalRequestFormClose}
                    service_id={clickedFormServiceId}
                    user_id={clickedFormUserId}
                  />
                </>
              </>
              <Text>{isEmptyArray(serviceArrData) && '이용 내역이 존재하지 않습니다.'}</Text>
            </VStack>
          </VStack>
        );
      }}
    </Formik>
  );
};

export default Info;

import { useEffect, useState } from 'react';
import { useQuill } from 'react-quilljs';
import BlotFormatter from 'quill-blot-formatter';
import 'quill/dist/quill.snow.css';
import { Box, BoxProps } from '@chakra-ui/react';
import { useUploadFiles } from '../../../lib/common/hooks/mutation';
import { useFormikContext } from 'formik';
import htmlEditButton from 'quill-html-edit-button';

// import './styles.css';

interface EditorProps extends BoxProps {
  formikKey: string;
  refProps?: BoxProps;
  isEditable?: boolean;
  readOnly?: boolean;
}

const Editor: React.FC<EditorProps> = ({
  children,
  formikKey,
  refProps,
  isEditable = true,
  readOnly = false,
  ...props
}) => {
  const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],

    [{ header: 1 }, { header: 2 }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ script: 'sub' }, { script: 'super' }],
    [{ indent: '-1' }, { indent: '+1' }],
    [{ direction: 'rtl' }],

    [{ size: ['12px', '14px', '16px', '18px', '20px'] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ['link', 'image'],
    [{ color: [] }, { background: [] }],
    [{ font: [] }],
    [{ align: [] }],

    ['clean'],
  ];
  const { quill, quillRef, Quill } = useQuill({
    modules: {
      blotFormatter: {},
      htmlEditButton: {
        buttonHTML: '<div style="min-width: max-content">&lt;&gt;<div>',
      },
      toolbar: toolbarOptions,
    },
    readOnly,
  });

  useEffect(() => {
    if (isEditable) {
      quill?.enable();
    } else {
      quill?.disable();
    }
  }, [isEditable]);

  const { mutateAsync: uploadFiles } = useUploadFiles();

  const formikprops = useFormikContext();

  if (Quill && !quill) {
    Quill.register({
      'modules/blotFormatter': BlotFormatter,
      'modules/htmlEditButton': htmlEditButton,
    });
    const Size = Quill.import('attributors/style/size');
    Size.whitelist = ['12px', '14px', '16px', '18px', '20px'];
    Quill.register(Size, true);
  }

  const insertToEditor = (url: string) => {
    if (quill) {
      const range = quill.getSelection();
      if (range) {
        quill?.insertEmbed(range.index, 'image', url);
      }
    }
  };

  const saveToServer = async (files: FileList | undefined) => {
    const formData = new FormData();

    if (files) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        formData.append('pictures', file);
      }
      await uploadFiles({ formData }).then((v) => {
        v.data.data.map((url: string) => {
          insertToEditor(url);
        });
      });
    }
  };

  /** https://www.npmjs.com/package/react-quilljs / With Custom Attached Image Upload */
  const selectLocalImage = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.setAttribute('multiple', '');
    input.click();

    input.onchange = () => {
      const files = input.files ? input.files : undefined;
      saveToServer(files);
    };
  };

  useEffect(() => {
    if (quill) {
      quill.on('text-change', (delta, oldContents) => {
        const currentContentHtml = quill.root.innerHTML;
        formikprops.setFieldValue(formikKey, currentContentHtml);
      });
      // if (!isEditable) {
      quill.getModule('toolbar').addHandler('image', selectLocalImage);
      // }

      quill.clipboard.dangerouslyPasteHTML((formikprops.values as any)[formikKey]);
    }
  }, [quill, Quill, isEditable]);

  return (
    <Box id="editor-mostTop-continer" {...props}>
      <Box ref={quillRef} boxShadow={'sm'} h={'300px'} {...refProps} />
    </Box>
  );
};

export default Editor;

import React from 'react';
import { Navigate, Route, RouteProps, Routes, useLocation } from 'react-router-dom';
import BlockLayout from '../../layouts/subscriber/BlockLayout';
import { useGetUserType } from '../../lib/common/hooks/query';
import InfosPage from '../../pages/UserType/subscriber/infos';
import CompanyInfosPage from '../../pages/UserType/subscriber/infos/company';
import PrivateInfosPage from '../../pages/UserType/subscriber/infos/private';
import SelectionPage from '../../layouts/subscriber/InfosLayout';
import AccountPage from '../../layouts/subscriber/account';
import CompanyPage from '../../layouts/subscriber/company';
import UserPage from '../../layouts/subscriber/user';
import CompanyMainPage from '../../pages/UserType/subscriber/Main';
import Dashboard from '../../pages/UserType/subscriber/manage/dashboard';
import EmployeeMainPage from '../../pages/UserType/subscriber/manage/employee';
import Payment from '../../pages/UserType/subscriber/manage/payment';
import Service from '../../pages/UserType/subscriber/manage/service';
import SubscriptionMainPage from '../../pages/UserType/subscriber/manage/subscription';
import SubscriptionApplyPage from '../../pages/UserType/subscriber/manage/subscription/apply';
import ApplyBasicPlanPage from '../../pages/UserType/subscriber/manage/subscription/apply/basic';
import PlanPaymentPage from '../../pages/UserType/subscriber/manage/subscription/apply/planpayment';
import SubscriptionSignUpPage from '../../pages/UserType/subscriber/manage/subscription/apply/signup';
import SubscriptionContractPage from '../../pages/UserType/subscriber/manage/subscription/contract';
import SubscriptionInvoicePage from '../../pages/UserType/subscriber/manage/subscription/invoice';
import Basic from '../../pages/UserType/subscriber/manage/subscription/subscribe/basic';
import Change from '../../pages/UserType/subscriber/manage/subscription/subscribe/change';
import ReturnPolicyPage from '../../pages/UserType/subscriber/policy/return';
import Notfoundpage from '../../pages/common/NotFound';
import PasswordRecheckedRoute from '../common/PasswordRecheckedRoute';
import Loading from '../../components/common/Loading';
import * as Util from '../../lib/common/utils/Util';

const CompanyCheckRoute: React.FC = () => {
  const { pathname } = useLocation();
  const routerUserType = Util.getRouteUserTypeFromUrl(pathname);
  const { data, isLoading } = useGetUserType();

  const { company_type: userType } = data;
  if (userType !== 'subscriber') {
    console.log('logged in, but not company');

    return <Navigate to={{ pathname: '/noaccess' }} />;
  }
  console.log('companyCheck');
  // 결제 승인 받은 유저만. 그 외 라우터 접근 막기.

  // 여기부터 : company 라우터 체크
  return (
    <Routes>
      <Route>
        <Route path="/" element={<CompanyMainPage />} />
        {/* 관리 */}
        <Route path="manage">
          {/* <Route index element={<Notfoundpage isLogin={isLogin} />} /> */}
          {/* <Route element={<BlockLayout />}> */}
          <Route path="dashboard">
            <Route index element={<Dashboard />} />
          </Route>
          {/* </Route> */}
          <Route path="subscription">
            <Route index element={<SubscriptionMainPage />} />
            {/* <Route index element={<Notfoundpage isLogin={isLogin} />} /> */}
            <Route path="invoice">
              <Route index element={<SubscriptionInvoicePage />} />
            </Route>
            <Route path="contract">
              <Route index element={<SubscriptionContractPage />} />
            </Route>
            <Route path="subscribe">
              {/* <Route index element={<SubscriptionContractPage />} /> */}
              <Route path="basic">
                <Route index element={<Basic />} />
              </Route>
              <Route path="change">
                <Route index element={<Change />} />
              </Route>
            </Route>
            {/* <Route path="apply">
            <Route index element={<SubscriptionApplyPage />} />
            <Route path="signup">
              <Route index element={<SubscriptionSignUpPage />} />
            </Route>
            <Route path=":planpayment" element={<PlanPaymentPage />}>
              <Route index element={<ApplyBasicPlanPage />} />
            </Route>
          </Route> */}
          </Route>
          <Route path="employee">
            <Route index element={<EmployeeMainPage />} />
            <Route path="onseek">
              <Route index element={<EmployeeMainPage />} />
            </Route>
            <Route path="oncontract">
              <Route index element={<EmployeeMainPage />} />
            </Route>
            <Route path="onmovein">
              <Route index element={<EmployeeMainPage />} />
            </Route>
          </Route>
          <Route path="payment">
            <Route index element={<Payment />} />
          </Route>
          <Route path="service">
            <Route index element={<Service />} />
          </Route>
        </Route>
        {/* 내 정보 수정 */}
        <Route path="infos">
          <Route index element={<InfosPage />} />
          <Route element={<PasswordRecheckedRoute />}>
            <Route path="selection">
              <Route index element={<SelectionPage />} />
              <Route path="company" element={<CompanyPage />} />
              <Route path="user" element={<UserPage />} />
              <Route path="account" element={<AccountPage />} />
            </Route>
          </Route>
          <Route path="private">
            <Route index element={<PrivateInfosPage />} />
          </Route>
          <Route path="company">
            <Route index element={<CompanyInfosPage />} />
          </Route>
        </Route>
        {/* 정책 */}
        <Route path="policy">
          <Route path="return" element={<ReturnPolicyPage />} />
        </Route>
        <Route path="*" element={<Notfoundpage />} />
      </Route>
    </Routes>
  );
};

export default CompanyCheckRoute;

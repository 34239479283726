import { VStack, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Flex } from '@chakra-ui/react';
import { useSearchParams, Link } from 'react-router-dom';

const BeforeLayout: React.FC = ({ children }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get('id');
  return (
    <VStack alignItems={'start'} w={'100%'}>
      <Breadcrumb pb={5}>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to={`/admin/manage/customers/user/detail/info?id=${id}`}>
            a
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to={`/admin/manage/customers/user/detail/request?id=${id}`}>
            b
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to={`/admin/manage/customers/user/detail/status?id=${id}`}>
            c
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to={`/admin/manage/customers/user/detail/memo?id=${id}`}>
            d
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Flex w={'100%'}>{children}</Flex>
    </VStack>
  );
};
export default BeforeLayout;

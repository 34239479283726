import {
  Box,
  Button,
  Center,
  HStack,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  Textarea,
  useDisclosure,
} from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';
import { useUpdateTenantLeaseContract } from '../../../../lib/admin/hooks/mutation';
import { ClickIconContainer, LeftArrowIcon } from '../../../../statics/common/icons';

interface SupportPaymentRejectModalProps {
  lease_contract_id: string;
}

const SupportPaymentRejectModal: React.FC<SupportPaymentRejectModalProps> = ({ children, lease_contract_id }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [rejectedReason, setRejectedReason] = useState<string>('');
  const { mutateAsync } = useUpdateTenantLeaseContract();

  return (
    <>
      <Box onClick={onOpen}>{children}</Box>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        autoFocus={false}
        scrollBehavior="inside"
        size={'xl'}
        motionPreset="scale"
        isCentered
      >
        <ModalOverlay />
        <Formik
          initialValues={{}}
          onSubmit={async (values, { setSubmitting }) => {
            const formData = new FormData();
            formData.append('status', 'rejected');
            formData.append('rejected_reason', rejectedReason);
            await mutateAsync({
              lease_contract_id,
              formData,
            }).then(() => {
              onClose();
            });
          }}
          validationSchema={Yup.object().shape({})}
        >
          {({ values, errors, isValid, handleChange, setFieldValue }) => {
            return (
              <Form>
                <ModalContent p={5}>
                  <Center flexDir={'column'} mx={6} my={4}>
                    <HStack w="full" justifyContent={'space-between'}>
                      <HStack>
                        <ClickIconContainer clickableArea={7} color="zetaBlue" w={8} h={8} onClick={() => onClose()}>
                          <LeftArrowIcon color="zetaBlue" />
                        </ClickIconContainer>
                        <Text fontSize={'20px'}>반려 사유 입력</Text>
                      </HStack>
                      <Button variant={'reject'} w={'140px'} fontSize={'14px'} fontWeight={'bold'} type="submit">
                        반려 완료
                      </Button>
                    </HStack>
                    <Textarea
                      m={3}
                      placeholder="반려 사유를 입력해 주세요"
                      onChange={(e) => {
                        setRejectedReason(e.target.value);
                      }}
                      minH={'160px'}
                    />
                  </Center>
                </ModalContent>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

export default SupportPaymentRejectModal;

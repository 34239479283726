import { Box, HStack, StackDivider, Table, Tbody, Tr, VStack } from '@chakra-ui/react';
import { Field } from 'formik';
import { CustomTd } from '../type';
import { FCheckboxButtonGroup } from '../../../../Checkbox/base';
import { remodeling_items_checkbox } from '../../../../../../statics/common/data/checkbox';
import { remodeling_season as remodeling_season_data } from '../../../../../../statics/common/data/selectbox';
import SelectBox from '../../../../../agent/SelectBox/base';

const InteriorInfoTable = () => {
  return (
    <Table w={'100%'} variant="simple">
      <Tbody w={'100%'}>
        <Tr w={'100%'}>
          <CustomTd title={'리모델링'}>
            <VStack divider={<StackDivider borderColor="gray.200" />} w={'100%'} alignItems={'flex-start'}>
              <HStack>
                <Box minW={'100px'} w={'100px'}>
                  리모델링 시기
                </Box>
                <Field name="remodeling_season" as={SelectBox} h={'32px'} bgColor="lotionWhite" placeholder="선택">
                  {remodeling_season_data.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Field>
              </HStack>
              <HStack>
                <Box minW={'100px'} w={'100px'}>
                  리모델링 항목
                </Box>
                <Field name="remodeling_items" as={FCheckboxButtonGroup} options={remodeling_items_checkbox} />
              </HStack>
            </VStack>
          </CustomTd>
        </Tr>
      </Tbody>
    </Table>
  );
};

export default InteriorInfoTable;

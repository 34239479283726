import { StackProps, VStack, Box, Center, HStack, Text } from '@chakra-ui/react';
import { useRef } from 'react';

interface ProgressItemProps extends StackProps {
  num: number;
  last?: boolean;
  step: number;
}

const ProgressItem: React.FC<ProgressItemProps> = ({ num, title, step, ...props }) => {
  const hoverEffect = useRef<HTMLDivElement>(null);
  if (step === 0) {
    if (num === 0) {
      return (
        <VStack position="relative" spacing={2} h="100%" w={'240px'} transition={'0.2s linear'} {...props}>
          <Box
            _before={{
              content: '""',
              width: '100%',
              height: '1px',
              bgColor: 'silverGray',
              position: 'absolute',
              top: '30%',
              transform: 'scaleX(0.9)',
              zIndex: -1,
              transition: '2s',
            }}
          />
          <Center
            w={'42px'}
            h={'42px'}
            bgColor={'zetaBlue'}
            color="white"
            borderRadius={'full'}
            fontWeight={'bold'}
            fontSize={'20px'}
            transition={'0.2s linear'}
          >
            {num + 1}
          </Center>
          <Box ref={hoverEffect} fontWeight={'bold'} fontSize={'17px'} color="zetaBlue">
            {title}
          </Box>
        </VStack>
      );
    } else {
      return (
        <VStack position="relative" spacing={2} h={'100%'} w={'240px'} transition={'0.2s linear'} {...props}>
          {num === 1 && (
            <Box
              _before={{
                content: '""',
                width: '100%',
                height: '1px',
                bgColor: 'silverGray',
                position: 'absolute',
                top: '30%',
                transform: 'scaleX(0.9)',
                zIndex: -1,
                transition: '2s',
              }}
            />
          )}
          {num === 2 && (
            <Box
              _before={{
                content: '""',
              }}
            />
          )}
          <Center
            w={'42px'}
            h={'42px'}
            bgColor={'white'}
            color="zetaBlue"
            borderWidth={'1px'}
            borderColor={'borderWhite'}
            borderRadius={'full'}
            fontWeight={'bold'}
            fontSize={'20px'}
            transition={'0.2s linear'}
          >
            {num + 1}
          </Center>
          <Box ref={hoverEffect} fontSize={'17px'} color="zetaBlue">
            {title}
          </Box>
        </VStack>
      );
    }
  } else if (step === 1) {
    if (num === 0) {
      return (
        <VStack position="relative" spacing={2} w={'240px'} transition={'0.2s linear'} {...props}>
          <Box
            _before={{
              content: '""',
              width: '100%',
              height: '1px',
              bgColor: 'silverGray',
              position: 'absolute',
              top: '33%',
              transform: 'scaleX(0.9)',
              zIndex: -1,
              transition: '2s',
            }}
          />
          <Center
            w={'42px'}
            h={'42px'}
            bgColor={'white'}
            color="zetaBlue"
            borderRadius={'full'}
            borderWidth={'1px'}
            borderColor={'zetaBlue'}
            fontWeight={'bold'}
            fontSize={'20px'}
            transition={'0.2s linear'}
          >
            <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M16.1673 2.94203C16.6876 2.29736 16.5867 1.35298 15.942 0.832707C15.2974 0.312436 14.353 0.413288 13.8327 1.05797L16.1673 2.94203ZM6.33333 12.739L5.16604 13.6811C5.45081 14.0339 5.8799 14.239 6.33333 14.239C6.78676 14.239 7.21586 14.0339 7.50063 13.6811L6.33333 12.739ZM3.16729 6.42749C2.64702 5.78281 1.70264 5.68196 1.05797 6.20223C0.413288 6.7225 0.312436 7.66688 0.832707 8.31156L3.16729 6.42749ZM13.8327 1.05797L5.16604 11.797L7.50063 13.6811L16.1673 2.94203L13.8327 1.05797ZM7.50063 11.797L3.16729 6.42749L0.832707 8.31156L5.16604 13.6811L7.50063 11.797Z"
                fill="#183377"
              />
            </svg>
          </Center>
          <Box ref={hoverEffect} fontSize={'17px'} color="zetaBlue">
            {title}
          </Box>
        </VStack>
      );
    } else if (num === 1) {
      return (
        <VStack position="relative" spacing={2} w={'240px'} transition={'0.2s linear'} {...props}>
          <Box
            _before={{
              content: '""',
              width: '100%',
              height: '1px',
              bgColor: 'silverGray',
              position: 'absolute',
              top: '33%',
              transform: 'scaleX(0.9)',
              zIndex: -1,
              transition: '2s',
            }}
          />
          <Center
            w={'42px'}
            h={'42px'}
            bgColor={'zetaBlue'}
            color="white"
            borderRadius={'full'}
            fontWeight={'bold'}
            fontSize={'20px'}
            transition={'0.2s linear'}
          >
            {num + 1}
          </Center>
          <Box ref={hoverEffect} fontWeight={'bold'} fontSize={'17px'} color="zetaBlue">
            {title}
          </Box>
        </VStack>
      );
    } else {
      return (
        <VStack position="relative" spacing={2} w={'240px'} transition={'0.2s linear'} {...props}>
          {num === 1 && (
            <Box
              _before={{
                content: '""',
                width: '100%',
                height: '1px',
                bgColor: 'silverGray',
                position: 'absolute',
                top: '33%',
                transform: 'scaleX(0.9)',
                zIndex: -1,
                transition: '2s',
              }}
            />
          )}
          {num === 2 && (
            <Box
              _before={{
                content: '""',
              }}
            />
          )}
          <Center
            w={'42px'}
            h={'42px'}
            bgColor={'white'}
            color="zetaBlue"
            borderWidth={'1px'}
            borderColor={'borderWhite'}
            borderRadius={'full'}
            fontWeight={'bold'}
            fontSize={'20px'}
            transition={'0.2s linear'}
          >
            {num + 1}
          </Center>
          <Box ref={hoverEffect} fontSize={'17px'} color="zetaBlue">
            {title}
          </Box>
        </VStack>
      );
    }
  } else if (step === 2) {
    if (num === 0) {
      return (
        <VStack position="relative" spacing={2} w={'240px'} transition={'0.2s linear'} {...props}>
          <Box
            _before={{
              content: '""',
              width: '100%',
              height: '1px',
              bgColor: 'silverGray',
              position: 'absolute',
              top: '33%',
              transform: 'scaleX(0.9)',
              zIndex: -1,
              transition: '2s',
            }}
          />
          <Center
            w={'42px'}
            h={'42px'}
            bgColor={'white'}
            color="zetaBlue"
            borderRadius={'full'}
            borderWidth={'1px'}
            borderColor={'zetaBlue'}
            fontWeight={'bold'}
            fontSize={'20px'}
            transition={'0.2s linear'}
          >
            <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M16.1673 2.94203C16.6876 2.29736 16.5867 1.35298 15.942 0.832707C15.2974 0.312436 14.353 0.413288 13.8327 1.05797L16.1673 2.94203ZM6.33333 12.739L5.16604 13.6811C5.45081 14.0339 5.8799 14.239 6.33333 14.239C6.78676 14.239 7.21586 14.0339 7.50063 13.6811L6.33333 12.739ZM3.16729 6.42749C2.64702 5.78281 1.70264 5.68196 1.05797 6.20223C0.413288 6.7225 0.312436 7.66688 0.832707 8.31156L3.16729 6.42749ZM13.8327 1.05797L5.16604 11.797L7.50063 13.6811L16.1673 2.94203L13.8327 1.05797ZM7.50063 11.797L3.16729 6.42749L0.832707 8.31156L5.16604 13.6811L7.50063 11.797Z"
                fill="#183377"
              />
            </svg>
          </Center>
          <Box ref={hoverEffect} fontSize={'17px'} color="zetaBlue">
            {title}
          </Box>
        </VStack>
      );
    } else if (num === 1) {
      return (
        <VStack position="relative" spacing={2} w={'240px'} transition={'0.2s linear'} {...props}>
          <Box
            _before={{
              content: '""',
              width: '100%',
              height: '1px',
              bgColor: 'silverGray',
              position: 'absolute',
              top: '33%',
              transform: 'scaleX(0.9)',
              zIndex: -1,
              transition: '2s',
            }}
          />
          <Center
            w={'42px'}
            h={'42px'}
            bgColor={'white'}
            color="zetaBlue"
            borderRadius={'full'}
            borderWidth={'1px'}
            borderColor={'zetaBlue'}
            fontWeight={'bold'}
            fontSize={'20px'}
            transition={'0.2s linear'}
          >
            <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M16.1673 2.94203C16.6876 2.29736 16.5867 1.35298 15.942 0.832707C15.2974 0.312436 14.353 0.413288 13.8327 1.05797L16.1673 2.94203ZM6.33333 12.739L5.16604 13.6811C5.45081 14.0339 5.8799 14.239 6.33333 14.239C6.78676 14.239 7.21586 14.0339 7.50063 13.6811L6.33333 12.739ZM3.16729 6.42749C2.64702 5.78281 1.70264 5.68196 1.05797 6.20223C0.413288 6.7225 0.312436 7.66688 0.832707 8.31156L3.16729 6.42749ZM13.8327 1.05797L5.16604 11.797L7.50063 13.6811L16.1673 2.94203L13.8327 1.05797ZM7.50063 11.797L3.16729 6.42749L0.832707 8.31156L5.16604 13.6811L7.50063 11.797Z"
                fill="#183377"
              />
            </svg>
          </Center>
          <Box ref={hoverEffect} fontSize={'17px'} color="zetaBlue">
            {title}
          </Box>
        </VStack>
      );
    } else {
      return (
        <VStack position="relative" spacing={2} w={'240px'} transition={'0.2s linear'} {...props}>
          {num === 1 && (
            <Box
              _before={{
                content: '""',
                width: '100%',
                height: '1px',
                bgColor: 'silverGray',
                position: 'absolute',
                top: '33%',
                transform: 'scaleX(0.9)',
                zIndex: -1,
                transition: '2s',
              }}
            />
          )}
          {num === 2 && (
            <Box
              _before={{
                content: '""',
              }}
            />
          )}
          <Center
            w={'42px'}
            h={'42px'}
            bgColor={'zetaBlue'}
            color="white"
            borderRadius={'full'}
            fontWeight={'bold'}
            fontSize={'20px'}
          >
            {num + 1}
          </Center>
          <Box ref={hoverEffect} fontSize={'17px'} color="zetaBlue">
            {title}
          </Box>
        </VStack>
      );
    }
  } else {
    return <Box>잘못된 경로입니다.</Box>;
  }
};

interface UpperProps {
  step: number;
}

const Upper: React.FC<UpperProps> = ({ step }) => {
  return (
    <Box w="full" h="full" boxShadow={'rgba(0, 0, 0, 0.06) 0px 10px 15px -3px, rgba(0, 0, 0, 0.2) 0px 2px 3px -2px'}>
      <Center flexDir={'column'} mx={'150px'} pt={'50px'} pb={'30px'}>
        <Text fontSize={'24px'} mb={'10px'}>
          매물 등록
        </Text>
        <HStack w="full" h="100px" justifyContent={'center'}>
          <ProgressItem step={step} num={0} title={'건물 등록'} />
          <ProgressItem step={step} num={1} title={'방 정보 등록'} />
          <ProgressItem step={step} num={2} title={'사진 및 상세 정보'} />
        </HStack>
      </Center>
    </Box>
  );
};

export default Upper;

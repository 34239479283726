import { useToast } from '@chakra-ui/react';
import { useMutation } from 'react-query';
import {
  company_key,
  contract_key,
  employee_counts_key,
  employee_services_key,
} from '../../../statics/subscriber/data/KeyPath';
import { queryClient } from '../../..';
import { fetchClient } from '../../common/utils/axios';
import { error as toastError, saveSuccess } from '../../../statics/common/data/toast';
import { CheckStatus } from '../../common/utils/axios/types';
import { useGetUserPathandKey } from '../../common/hooks/query';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../common/utils/firebase/init';

/* ---------------- */
/* --- Mutation --- */
/* ---------------- */

export const useConfirmService = () => {
  const [user, loading, error] = useAuthState(auth);
  const toast = useToast();
  const { data: ud } = useGetUserPathandKey();

  return useMutation<
    any,
    any,
    {
      employee_mutation_id: string;
      service_mutation_id: string;
      is_approved?: boolean;
      rejected_reason?: string;
      check_status?: CheckStatus;
    },
    any
  >(
    async ({ employee_mutation_id, service_mutation_id, is_approved, rejected_reason, check_status }) => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      return await instance.post(
        `${ud?.path}/employees/${employee_mutation_id}/services/${service_mutation_id}/confirm`,
        {
          is_approved,
          rejected_reason,
          check_status,
        },
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries().then(() => {
          toast({
            ...saveSuccess,
          });
        });
      },
      onError: () => {
        toast({
          ...toastError,
        });
      },
    },
  );
};

export const useWithdrawService = () => {
  const [user, loading, error] = useAuthState(auth);
  const toast = useToast();
  const { data: ud } = useGetUserPathandKey();

  return useMutation<
    any,
    any,
    {
      employee_mutation_id: string;
      service_mutation_id: string;
      is_approved?: boolean | 'pending';
      rejected_reason?: string;
      check_status?: CheckStatus;
    },
    any
  >(
    async ({ employee_mutation_id, service_mutation_id, is_approved, rejected_reason }) => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      return await instance.post(
        `${ud?.path}/employees/${employee_mutation_id}/services/${service_mutation_id}/confirm-withdrawal`,
        {
          is_approved,
          rejected_reason,
        },
      );
    },
    {
      onSuccess: () => {
        queryClient
          .invalidateQueries(employee_services_key)
          .then(() => {
            queryClient.invalidateQueries(employee_counts_key);
          })
          .then(() => {
            toast({
              ...saveSuccess,
            });
          });
      },
      onError: () => {
        toast({
          ...toastError,
        });
      },
    },
  );
};

export const useUpdateCompanyInfo = () => {
  const [user, loading, error] = useAuthState(auth);
  const toast = useToast();
  const { data: ud } = useGetUserPathandKey();

  return useMutation<
    any,
    any,
    {
      formData: FormData;
    },
    any
  >(
    async ({ formData }) => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      return await instance.patch(`${ud?.path}/company`, formData);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(company_key).then(() => {
          toast({
            ...saveSuccess,
          });
        });
      },
      onError: () => {
        toast({
          ...toastError,
        });
      },
    },
  );
};

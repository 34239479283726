import React, { createContext, useContext, useState } from 'react';

type ContextState = {
  etc: string;
  handleEtcState: (str: string) => void;
};

const defaultValue: ContextState = {
  etc: '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleEtcState: (str) => {},
};

export const QueryParamsLayoutContext = createContext(defaultValue);

export const QueryParamsLayoutContextProvider: React.FC = (props) => {
  const [etc, setEtc] = useState<string>('');

  const handleEtcState = (str: string) => {
    setEtc(str);
  };
  return (
    <QueryParamsLayoutContext.Provider value={{ etc, handleEtcState }}>
      {props.children}
    </QueryParamsLayoutContext.Provider>
  );
};

export const useQueryParamsLayoutContext = () => {
  return useContext(QueryParamsLayoutContext);
};

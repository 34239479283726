import { Box, Button, HStack, StackDivider, Table, Tbody, Thead, Tr, useDisclosure, VStack } from '@chakra-ui/react';
import { format } from 'date-fns';
import { useSearchParams } from 'react-router-dom';
import ComplaintRequestForm from '../../../../../../../../components/admin/Modals/ComplaintRequestForm';
import { WTd } from '../../../../../../../../components/common/Table/Td';
import { WTh } from '../../../../../../../../components/common/Table/Th';
import { TdFullWContainer } from '../../../../../../../../components/common/Table/Tr';
import { datedotformat } from '../../../../../../../../lib/common/utils/dateformatter';

const dummy_complaints = [
  {
    _id: '2',
    request_id: '1002',
    status: '진행중',
    title: '물이 잘 안내려가요',
    user_name: '김철수',
    complaint_date: new Date('2021-9-20'),
    final_admin_dealwith: '전희재',
    // final_date_dealwith: new Date('2021-9-23'),
  },
  {
    _id: '1',
    request_id: '1001',
    status: '처리 완료',
    title: '윗집이 너무 시끄러워요',
    user_name: '김철수',
    complaint_date: new Date('2021-9-10'),
    final_admin_dealwith: '전희재',
    final_date_dealwith: new Date('2021-9-12'),
  },
];

const Lcomplaint = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get('id');
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <VStack justifyContent={'start'} w={'100%'} spacing={6}>
      {/* 입주 정보 */}
      <VStack spacing={3} justifyContent={'start'} align={'flex-start'} w={'100%'} divider={<StackDivider />}>
        <HStack w={'full'}>
          <HStack w={'full'} spacing={4} alignItems={'flex-end'}>
            <Box fontWeight={'bold'} fontSize={'18px'} textAlign={'start'}>
              민원 내역
            </Box>
          </HStack>
        </HStack>
        {/* 고객사 승인 내역 */}
        <Table variant="simple">
          <Thead bgColor={'#F8F8F8'} borderColor={'gray.100'}>
            <Tr>
              <WTh borderR>요청 번호</WTh>
              <WTh borderR>상태</WTh>
              <WTh borderR>제목</WTh>
              <WTh borderR>요청자</WTh>
              <WTh borderR>요청생성일</WTh>
              <WTh borderR>최종처리자</WTh>
              <WTh borderR>최종처리일</WTh>
              <WTh>메모</WTh>
            </Tr>
          </Thead>
          <Tbody>
            {/* format(date, "yy-MM-dd") */}
            {dummy_complaints.map((complaint, index) => (
              <Tr key={index}>
                <WTd borderR>{complaint.request_id}</WTd>
                <WTd borderR>{complaint.status}</WTd>
                <WTd borderR>{complaint.title}</WTd>
                <WTd borderR>{complaint.user_name}</WTd>
                <WTd borderR>{datedotformat(complaint.complaint_date)}</WTd>
                <WTd borderR>{complaint.final_admin_dealwith}</WTd>
                <WTd borderR>{datedotformat(complaint.final_date_dealwith)}</WTd>
                <WTd
                  borderR
                  textDecorationLine={'underline'}
                  cursor="pointer"
                  onClick={() => {
                    console.log(complaint.request_id);
                    onOpen();
                  }}
                >
                  메모 보기
                </WTd>
                {/* 2. memo */}
                {/* <WTd borderR>{complaint.final_date_dealwith}</WTd> */}
              </Tr>
            ))}
          </Tbody>
        </Table>
        {/* 모달 */}
      </VStack>
      <ComplaintRequestForm isOpen={isOpen} onClose={onClose} />
    </VStack>
  );
};

export default Lcomplaint;

import { CloseIcon } from '@chakra-ui/icons';
import { Box, Button, Center, Flex, Image, Text } from '@chakra-ui/react';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import {
  CustomOverlayMap,
  Map,
  MapInfoWindow,
  MapMarker,
  MarkerClusterer,
  CustomOverlayMapProps,
} from 'react-kakao-maps-sdk';
import { createSearchParams, URLSearchParamsInit, useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import RoomListDrawer from '../../../components/admin/Drawer/RoomListDrawer';
import Loading from '../../../components/common/Loading';
import CustomSliderOne from '../../../components/common/Slider/CustomSliderOne';
import { useGetAllBuildingsRoomsForMap } from '../../../lib/admin/hooks/query';
import { buildSearchParams, currentParamsObject, ToAllFilledObj } from '../../../lib/common/utils/base';
import { maximized_limit_number } from '../../../lib/common/utils/values';
import { sliderSettings } from '../RoomDetail';

const PlaygroundOne = () => {
  const paramsprops = currentParamsObject(false);
  const navigate = useNavigate();

  const { level, center_lat, center_lng, select_room_id } = paramsprops;

  const params = ToAllFilledObj(paramsprops);

  const res = useGetAllBuildingsRoomsForMap({
    axiosConfig: {
      params: {
        ...params,
      },
    },
  });

  const onClusterclick = (target: kakao.maps.MarkerClusterer, cluster: kakao.maps.Cluster) => {
    return;
  };

  const roundPrecision = (level: number) => {
    const maxLevel = 14;
    if (level < 6) {
      return 4;
    } else if (level < 8) {
      return 3;
    } else if (level <= maxLevel) {
      return 2;
    }
  };

  const onBoundsChanged = (map: kakao.maps.Map) => {
    const left = map.getBounds().getSouthWest().getLng();
    const _top = map.getBounds().getNorthEast().getLat();
    const right = map.getBounds().getNorthEast().getLng();
    const _bottom = map.getBounds().getSouthWest().getLat();

    // urlset

    const precision = roundPrecision(level);

    const left_top = [_.floor(left, precision), _.floor(_top, precision)];
    const right_bottom = [_.floor(right, precision), _.floor(_bottom, precision)];

    const searchParams = buildSearchParams({
      level: map.getLevel(),
      left_top: JSON.stringify(left_top),
      right_bottom: JSON.stringify(right_bottom),
      center_lat: map.getCenter().getLat(),
      center_lng: map.getCenter().getLng(),
    });

    navigate(searchParams);
  };

  const onZoomChanged = (map: kakao.maps.Map) => {
    onBoundsChanged(map);
  };

  const onDragEnd = (map: kakao.maps.Map) => {
    onBoundsChanged(map);
  };

  const onClickMarker = (id: string) => {
    const searchParams = buildSearchParams({
      select_room_id: id,
    });

    navigate(searchParams);
  };

  const onClickCFollowBoxCloseButton = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    const searchParams = buildSearchParams({
      select_room_id: '',
    });

    navigate(searchParams);
  };

  const onClickFollowBox = () => {
    // as={'a'}도 생각하기
    // navigate
  };

  // 첫 렌더링시 초기값
  useEffect(() => {
    const defaultCoord = {
      // level: 11,
      level: 7,
      center: {
        lat: 37.6012,
        lng: 126.9177,
      },
      bounds: {
        left_top: [126.3, 38.1],
        right_bottom: [128.4, 36.8],
      },
    };

    const searchParams = buildSearchParams({
      level: defaultCoord.level,
      left_top: JSON.stringify(defaultCoord.bounds.left_top),
      right_bottom: JSON.stringify(defaultCoord.bounds.right_bottom),
      center_lat: defaultCoord.center.lat,
      center_lng: defaultCoord.center.lng,
    });

    navigate(searchParams);
  }, []);
  const { isLoading } = res;
  if (isLoading) return <Loading />;
  return (
    <Box w={'calc(50vw - 28px)'} h={'calc(100vh - 128px)'} id="lllife" float={'right'}>
      <Map
        center={{ lat: center_lat, lng: center_lng }}
        style={{ width: '100%', height: '100%' }}
        level={level}
        onZoomChanged={onZoomChanged}
        onDragEnd={onDragEnd}
      >
        <MarkerClusterer averageCenter={true} minLevel={10} disableClickZoom onClusterclick={onClusterclick}>
          {res.data?.result.map((v: any, index: number) => {
            const [lng, lat] = v.location.coordinates;
            // console.log('v => ', v);

            // 여기부터
            const isClickedFollowBox = select_room_id === v.room._id;

            return (
              // <CustomOverlayMap
              //   position={{
              //     lat,
              //     lng,
              //   }}
              //   xAnchor={0.3}
              //   yAnchor={0.91}
              //   key={index}
              // >
              //   커스텀 오버레이에 표시할 내용입니다
              //   <div className="label" style={{ color: '#000', width: '20px' }}>
              //     <span className="left"></span>
              //     <span className="center">카카오!</span>
              //     <span className="right"></span>
              //   </div>
              // </CustomOverlayMap>

              <MapMarker
                key={index}
                position={{
                  lat,
                  lng,
                }}
                onClick={(marker) => {
                  onClickMarker(v.room._id);
                }}
              >
                {isClickedFollowBox && (
                  <Box pos={'relative'} maxW={'150px'} minW={'150px'} id="followBox" zIndex={10000}>
                    <Box
                      as={'a'}
                      href={`/admin/manage/roomInfo/${v.room._id}`}
                      target={'_blank'}
                      pos={'absolute'}
                      bottom={'-24px'}
                      right={'-87px'}
                    >
                      <Box borderRadius={'lg'} boxShadow={'md'}>
                        <Box pos={'relative'}>
                          <Box minW={'324px'} maxW={'324px'} w={'324px'} h={'210px'}>
                            <CustomSliderOne>
                              {v.room.pictures.map((url: any, index: number) => (
                                <Image
                                  key={index}
                                  src={url}
                                  minW={'324px'}
                                  maxW={'324px'}
                                  w={'324px'}
                                  h={'210px'}
                                  objectFit={'cover'}
                                  borderTopRadius="lg"
                                  backgroundColor={'white'}
                                />
                              ))}
                            </CustomSliderOne>
                          </Box>
                          {/* <Button as={CloseIcon} pos={'absolute'} top={0} borderRadius={'full'} boxSize={'10px'}></Button> */}
                          <Button
                            pos={'absolute'}
                            top={3}
                            left={3}
                            variant={'blackAlphaIconContainer'}
                            id="followBox-close-button"
                            onClick={(e) => onClickCFollowBoxCloseButton(e)}
                          >
                            <CloseIcon boxSize={'8px'} color={'white'} />
                          </Button>
                        </Box>
                        <Box bgColor={'white'} borderBottomRadius={'lg'} p={4} pt={10} fontSize={'15px'}>
                          <Box fontWeight={'medium'}>{v.address}</Box>
                          <Flex fontWeight={600}>
                            <Flex>
                              <Text>보증금&nbsp;</Text>
                              <Text>{v.room.deposit}만원</Text>
                            </Flex>
                            <Box>·</Box>
                            <Flex>
                              <Text>월세&nbsp;</Text>
                              <Text>{v.room.rent}만원</Text>
                            </Flex>
                          </Flex>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                )}
              </MapMarker>
            );
          })}
        </MarkerClusterer>
      </Map>
      {/* Modal */}
      <RoomListDrawer children={undefined} />
    </Box>
  );
};

export default PlaygroundOne;

import { Select, SelectProps } from '@chakra-ui/react';
import { BelowArrowIcon } from '../../../../statics/common/icons';

const SelectBox: React.FC<SelectProps> = ({ children, ...props }) => {
  return (
    <Select
      display={'flex'}
      icon={<BelowArrowIcon />}
      bgColor={'white'}
      border={'1px solid'}
      borderColor={'silverGray'}
      fontSize={'14px'}
      cursor={'pointer'}
      height={'36px'}
      boxSizing="border-box"
      _hover={{}}
      minW={'max'}
      {...props}
    >
      {children}
    </Select>
  );
};

export default SelectBox;

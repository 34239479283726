import axios from 'axios';
import { auth, firebaseConfig } from '../firebase/init';
import firebase, { getAuth } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { CompanyTypeEnum, UserTypeEnum } from './types';
import { deDashfy } from '../digitformatter';
import { useToast } from '@chakra-ui/react';
import { duplicated, error, success } from '../../../../statics/common/data/toast';
import { getLocalStorageStringItem, getWithExpiry, setLocalStorageStringItem } from '../localstorage';
import { useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';

export const base = process.env.REACT_APP_API_URL;

export const version = 'v1';

export const fetchClient = async (props?: any) => {
  const headers = props?.headers;

  const instance = axios.create({
    baseURL: `${base}${version}`,
    headers: {
      ...headers,
    },
  });

  return instance;
};

export const useChatNotify = () => {
  const [user, loading, error] = useAuthState(auth);
  return async (company_type: CompanyTypeEnum, user_type: UserTypeEnum, chat_room_id: string, data: any) => {
    const token = await user?.getIdToken();
    const instance = await fetchClient({ headers: { token } });
    return await instance.post(`/${company_type}/${user_type}/chat-rooms/${chat_room_id}/message/notify`, data);
  };
};

export const useCheckDuplicatedRegistrationNumber = (business_registration_number: string) => {
  const toast = useToast();
  axios
    .post(`${base}${version}/common/is-duplicated-business-registration-number/`, {
      business_registration_number: deDashfy(business_registration_number),
    })
    .then((_data) => {
      const {
        data: {
          data: { is_duplicated },
        },
      } = _data;
      return is_duplicated;
    })
    .catch(() => {
      toast(error);
    });
};

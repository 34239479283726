import {
  Box,
  Button,
  Center,
  Checkbox,
  Collapse,
  Flex,
  Grid,
  GridItem,
  HStack,
  Img,
  Link,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  StackDivider,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Textarea,
  Tooltip,
  Tr,
  useBoolean,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { Field, isEmptyArray, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { SingleDatepicker } from '../../Calender';
import { FCheckboxButtonGroup } from '../../Checkbox/base';
import { CustomInput } from '../../Input';
import { FRadioButtonGroup } from '../../Radio';
import { TdFullWContainer } from '../../Table/Tr';
import { remodelingItemTrans, remodelingSeasonTrans, squaredToPeong } from '../../../../lib/common/utils/base';
import { buildingFormTypeTrans } from '../../../../lib/subscriber/utils/utils';
import {
  additional_options_checkbox,
  common_items_checkbox,
  default_options_checkbox,
  remodeling_items_checkbox,
} from '../../../../statics/common/data/checkbox';
import {
  floor_type_radio,
  is_parking_radio,
  is_pet_radio,
  is_security_window_radio,
  move_in_radio,
  room_living_type_radio,
} from '../../../../statics/common/data/radio';
import {
  contract_period,
  heating_type as heating_type_data,
  remodeling_season as remodeling_season_data,
  room_direction as room_direction_data,
} from '../../../../statics/common/data/selectbox';
import SelectBox from '../../../agent/SelectBox/base';
import HalfTable from '../../Table/HalfTable';
import QuarterTable from '../../Table/QuaterTable';
import BuildingInfo from '../../../../layouts/common/BuildingItem/buildingItem';
import { additional_func, commons_func, direct_func, individual_func } from '../../../../lib/common/utils/roomInfoUtil';
import { sliderSettings } from '../../../../layouts/common/RoomDetail';
import { formikInitialValuesProps } from '../ConfirmContractModal';
import TextLink from '../../Link/TextLink';
import { pathOfUserDetailPage, shareFacilitiesTrans } from '../../../../lib/common/utils/utils';
import { TenantStatus } from '../../../../lib/common/utils/axios/types';
import { datedotformat } from '../../../../lib/common/utils/dateformatter';
import { staticThreeCommafy } from '../../../../lib/common/utils/digitformatter';
import { _useGetBuildingRoom } from '../../../../lib/common/hooks/query';
import Loading from '../../Loading';
import * as Type from '../../../../types/common/CommonType';

interface RoomCheckForConfirmModalProps {
  disabled?: boolean;
}

const RoomCheckForConfirmModal: React.FC<RoomCheckForConfirmModalProps> = ({ children, disabled = false }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { values, setFieldValue } = useFormikContext<formikInitialValuesProps>();

  const handleModalOpen = () => {
    if (!disabled) {
      onOpen();
    }
  };

  const onClickRegisterButton = () => {
    setFieldValue('is_clicked_register_room_button_once', true);
    onClose();
  };

  const [isEditable, setIsEditable] = useBoolean();
  const inputWidth = '120px';
  const [is_defaults_checked, setIs_defaults_checked] = useState<boolean>(false);

  const res = _useGetBuildingRoom(values.room_id || '', {
    options: {
      enabled: !!values.room_id,
    },
  });

  useEffect(() => {
    if (values.default_options.length !== 6) {
      setIs_defaults_checked(false);
    } else {
      setIs_defaults_checked(true);
    }
  }, [values.default_options]);

  const { isLoading } = res;
  if (isLoading) return <Loading />;
  const building = res.data?.data as Type.Building;
  const room = res.data?.data?.room as Type.Room;
  console.log('this is building', building);
  console.log('this is room', room);
  console.log('this is res', res);
  console.log('this is value', values);
  return (
    <>
      <Box onClick={handleModalOpen}>{children}</Box>

      <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside" isCentered size={'full'}>
        <ModalOverlay />
        <ModalContent p={10} h={'100%'}>
          <ModalCloseButton />
          <Scrollbars style={{ height: '100%' }}>
            <VStack px={5} w={'100%'}>
              <Flex w={'100%'} justify={'flex-end'} pb={10} mb={10} borderBottom="1px solid #c4c4c4">
                {/* <EventButton alignItems={'center'} onClick={props.backToRooms} borderRadius={'36px'} w={'230px'}>
                  <LeftArrowIcon mr={'5px'} />
                  이전 페이지로 돌아가기
                </EventButton> */}
                <Stack spacing={4} direction="row" align="center">
                  <Tooltip
                    label={
                      values.is_template ? (
                        <Box>
                          <Box>
                            <Text color={'primaryRed'} display={'inline-block'}>
                              템플릿
                            </Text>{' '}
                            매물입니다.
                          </Box>
                          <Box>
                            필수 수정 사항을{' '}
                            <Text color={'primaryRed'} display={'inline-block'}>
                              수정 후
                            </Text>{' '}
                            최종 등록해주세요!
                          </Box>
                        </Box>
                      ) : null
                    }
                    hasArrow
                    isOpen
                  >
                    <Box display={'flex'}>
                      <Button
                        variant={'grayBorder_whiteBg_zetaBlue'}
                        onClick={setIsEditable.toggle}
                        type={!isEditable ? 'submit' : 'button'}
                      >
                        {isEditable ? '수정 완료' : '수정'}
                      </Button>
                    </Box>
                  </Tooltip>
                  <Button
                    variant={'grayBorder_whiteBg_zetaBlue'}
                    bgColor={'zetaBlue'}
                    color={'white'}
                    borderColor={'white'}
                    onClick={onClickRegisterButton}
                  >
                    등록
                  </Button>
                </Stack>
              </Flex>
              {isEditable ? (
                // 수정 모드
                <VStack w="full" spacing={7}>
                  {/* 거래 정보 */}
                  <Flex flexDir={'column'} w={'full'} alignItems={'stretch'}>
                    <Flex alignItems={'flex-end'} mb={2}>
                      <Text fontWeight={'bold'} fontSize={'18px'} w={'100%'}>
                        거래 정보
                      </Text>
                    </Flex>
                    <Box w="full" borderBottomWidth={'1px'} borderBottomColor={'gray'} />
                    <Table w={'100%'} variant="simple_lining">
                      <Tbody w={'100%'}>
                        <Tr w={'100%'}>
                          <TdFullWContainer title={'가격 정보'} required ContentTdProps={{ colSpan: 4 }}>
                            <VStack alignItems={'flex-start'} divider={<StackDivider borderColor="gray.200" />}>
                              <HStack w="100%">
                                <Text minW="75px">보증금</Text>
                                <Flex w={inputWidth} minW={inputWidth}>
                                  <Field name="deposit" as={CustomInput} bgColor="lotionWhite" addon="원" />
                                </Flex>
                              </HStack>
                              <HStack w="100%">
                                <Text minW="75px">월세</Text>
                                <Flex w={inputWidth} minW={inputWidth}>
                                  <Field name="rent" as={CustomInput} bgColor="lotionWhite" addon="원" />
                                </Flex>
                              </HStack>
                            </VStack>
                          </TdFullWContainer>
                        </Tr>
                        <Tr w={'100%'}>
                          <TdFullWContainer title={'공용 관리비'} required>
                            <Flex w={'100px'} minW={'100px'}>
                              <Field name="management_fee" as={CustomInput} bgColor="lotionWhite" addon="원" />
                            </Flex>
                          </TdFullWContainer>
                          <TdFullWContainer title={'공용 관리비 항목'}>
                            <Flex w={'100%'}>
                              <Field name="common_items" as={FCheckboxButtonGroup} options={common_items_checkbox} />
                            </Flex>
                          </TdFullWContainer>
                        </Tr>
                        <Tr w={'100%'}>
                          <TdFullWContainer title={'입주 가능일'} required>
                            <Flex w={'100%'}>
                              <Field name="move_in.status" as={FRadioButtonGroup} options={move_in_radio} />
                            </Flex>
                            {/* {move_in_status === 'direct' && <Box>aef</Box>} */}
                            <Collapse in={values.move_in.status === 'direct'}>
                              <HStack w={'100%'} mt={2}>
                                {/* <Flex w="full"> */}
                                <SingleDatepicker
                                  date={values.move_in.date as any}
                                  // 준공일 기입 안 할시 무슨 데이터? '' Null?
                                  onDateChange={(date) => {
                                    setFieldValue('move_in.date', date);
                                  }}
                                  propsConfigs={{
                                    inputProps: {
                                      bgColor: 'lotionWhite',
                                      size: 'sm',
                                      borderRadius: 'md',
                                      borderColor: 'silverGray',
                                      color: 'highGray',
                                      minW: '120px',
                                      w: '120px',
                                    },
                                    dateNavBtnProps: {
                                      color: 'highGray',
                                      _focus: {},
                                    },
                                  }}
                                />
                                {/* </Flex> */}
                                <Box minW={'86px'} w={'86px'}>
                                  이후 입주 가능
                                </Box>
                              </HStack>
                            </Collapse>
                          </TdFullWContainer>
                          <TdFullWContainer title={'최소 계약 기간'}>
                            <Field
                              name="minimum_contract_month"
                              as={SelectBox}
                              placeholder="선택"
                              w={'30%'}
                              h="32px"
                              bgColor="lotionWhite"
                              // defaultValue={values.minimum_contract_month}
                            >
                              {contract_period.map((option, index) => (
                                <option key={index} value={option.value}>
                                  {option.label}개월
                                </option>
                              ))}
                            </Field>
                            {/* <SelectBox default /> */}
                          </TdFullWContainer>
                        </Tr>
                      </Tbody>
                    </Table>
                  </Flex>
                  {/* 방 정보 */}
                  <Flex flexDir={'column'} w={'full'} alignItems={'stretch'}>
                    <Flex alignItems={'flex-end'} mb={2}>
                      <Text fontWeight={'bold'} fontSize={'18px'} w={'100%'}>
                        방 정보
                      </Text>
                    </Flex>
                    <Box w="full" borderBottomWidth={'1px'} borderBottomColor={'gray'} />
                    <Table w={'100%'} variant="simple_lining">
                      <Tbody w={'100%'}>
                        <Tr w={'100%'}>
                          <TdFullWContainer title={'단지 및 호수'} ContentTdProps={{ colSpan: 4 }}>
                            <HStack w="100%">
                              <Flex w={'110px'} minW={'110px'}>
                                <Field name="dong" as={CustomInput} bgColor="lotionWhite" addon="동" />
                              </Flex>
                              <Flex w={'110px'} minW={'110px'}>
                                <Field name="ho" as={CustomInput} bgColor="lotionWhite" addon="호" />
                              </Flex>
                            </HStack>
                          </TdFullWContainer>
                        </Tr>
                        <Tr w={'100%'}>
                          <TdFullWContainer title={'해당층'} required ContentTdProps={{ colSpan: 4 }}>
                            <HStack w="100%">
                              <Flex w={'110px'} minW={'110px'}>
                                <Field name="floor_r" as={CustomInput} bgColor="lotionWhite" addon="층" />
                              </Flex>
                              <Flex w={'auto'}>
                                <Field name="floor_type" as={FRadioButtonGroup} options={floor_type_radio} />
                              </Flex>
                            </HStack>
                          </TdFullWContainer>
                        </Tr>
                        <Tr w={'100%'}>
                          <TdFullWContainer title={'방 거실형태'} required>
                            <Flex w={'auto'}>
                              <Field name="room_living_type" as={FRadioButtonGroup} options={room_living_type_radio} />
                            </Flex>
                          </TdFullWContainer>
                        </Tr>
                        <Tr w={'100%'}>
                          <TdFullWContainer title={'방 개수'} required>
                            <Flex w={'110px'} minW={'110px'}>
                              <Field name="room_num" as={CustomInput} bgColor="lotionWhite" addon="개" />
                              {/* <CustomInput type={'number'} min={0} max={3} /> */}
                            </Flex>
                            {/* <ValidationWithTouch error={errors.room_num} touch={touched.room_num} /> */}
                          </TdFullWContainer>
                          <TdFullWContainer title={'화장실 개수'} required>
                            <Flex w={'110px'} minW={'110px'}>
                              <Field name="toilet_num" as={CustomInput} bgColor="lotionWhite" addon="개" />
                            </Flex>
                          </TdFullWContainer>
                        </Tr>
                        <Tr w={'100%'}>
                          <TdFullWContainer title={'공급 면적'}>
                            <HStack w="100%">
                              <Flex w={'110px'} minW={'110px'}>
                                <Field
                                  name="supply_area"
                                  as={CustomInput}
                                  bgColor="lotionWhite"
                                  addon="m²"
                                  onChange={(e: any) => {
                                    const floored = squaredToPeong(e.target.value);

                                    if (e.target.value === '') {
                                      setFieldValue('supply_area', '');
                                      setFieldValue('supply_area_peong', '');
                                    } else {
                                      setFieldValue('supply_area', e.target.value);
                                      setFieldValue('supply_area_peong', floored);
                                    }
                                  }}
                                />
                              </Flex>
                              <Flex w={'110px'} minW={'110px'}>
                                <Field
                                  name="supply_area_peong"
                                  as={CustomInput}
                                  bgColor="lotionWhite"
                                  addon="평"
                                  onChange={(e: any) => {
                                    const peong = parseFloat(e.target.value);
                                    const squared_meter = peong * 3.305785;
                                    const floored = Math.floor(squared_meter * 100) / 100;
                                    if (e.target.value === '') {
                                      setFieldValue('supply_area', '');
                                      setFieldValue('supply_area_peong', '');
                                    } else {
                                      setFieldValue('supply_area', floored);
                                      setFieldValue('supply_area_peong', e.target.value);
                                    }
                                  }}
                                />
                              </Flex>
                            </HStack>
                          </TdFullWContainer>
                          <TdFullWContainer title={'전용 면적'} required>
                            <HStack w="100%">
                              <Flex w={'110px'} minW={'110px'}>
                                <Field
                                  name="dedicated_area"
                                  as={CustomInput}
                                  bgColor="lotionWhite"
                                  addon="m²"
                                  onChange={(e: any) => {
                                    const floored = squaredToPeong(e.target.value);

                                    if (e.target.value === '') {
                                      setFieldValue('dedicated_area', '');
                                      setFieldValue('dedicated_area_peong', '');
                                    } else {
                                      setFieldValue('dedicated_area', e.target.value);
                                      setFieldValue('dedicated_area_peong', floored);
                                    }
                                  }}
                                />
                              </Flex>
                              <Flex w={'110px'} minW={'110px'}>
                                <Field
                                  name="dedicated_area_peong"
                                  as={CustomInput}
                                  bgColor="lotionWhite"
                                  addon="평"
                                  onChange={(e: any) => {
                                    const peong = parseFloat(e.target.value);
                                    const squared_meter = peong * 3.305785;
                                    const floored = Math.floor(squared_meter * 100) / 100;
                                    if (e.target.value === '') {
                                      setFieldValue('dedicated_area', '');
                                      setFieldValue('dedicated_area_peong', '');
                                    } else {
                                      setFieldValue('dedicated_area', floored);
                                      setFieldValue('dedicated_area_peong', e.target.value);
                                    }
                                  }}
                                />
                              </Flex>
                            </HStack>
                          </TdFullWContainer>
                        </Tr>
                        <Tr w={'100%'}>
                          <TdFullWContainer title={'주실 방향'}>
                            <Field
                              name="room_direction"
                              as={SelectBox}
                              placeholder="선택"
                              minW="110px"
                              w="110px"
                              h="32px"
                              bgColor="lotionWhite"
                            >
                              {room_direction_data.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </Field>
                          </TdFullWContainer>
                          <TdFullWContainer title={'난방 방식'}>
                            <Field
                              name="heating_type"
                              as={SelectBox}
                              placeholder="선택"
                              minW="110px"
                              w="110px"
                              h="32px"
                              bgColor="lotionWhite"
                            >
                              {heating_type_data.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </Field>
                          </TdFullWContainer>
                        </Tr>
                      </Tbody>
                    </Table>
                  </Flex>
                  {/* 방 옵션 */}
                  <Flex flexDir={'column'} w={'full'} alignItems={'stretch'}>
                    <Flex alignItems={'flex-end'} mb={2}>
                      <Text fontWeight={'bold'} fontSize={'18px'} w={'100%'}>
                        방 옵션
                      </Text>
                    </Flex>
                    <Box w="full" borderBottomWidth={'1px'} borderBottomColor={'gray'} />
                    <Table w={'100%'} variant="simple_lining">
                      <Tbody w={'100%'}>
                        <Tr w={'100%'}>
                          <TdFullWContainer title={'기본 옵션'}>
                            <HStack w="100%" spacing={4}>
                              <Field
                                name="default_options"
                                as={FCheckboxButtonGroup}
                                options={default_options_checkbox}
                              />
                              <Checkbox
                                wordBreak={'keep-all'}
                                size="sm"
                                // defaultChecked={isIncludeAll}
                                // onChange={() => setIsIncludeAll((prev) => !prev)}
                                isChecked={is_defaults_checked}
                                onChange={(e) => {
                                  setIs_defaults_checked((prev) => !prev);
                                  // console.log('----->', is_defaults_checked);
                                  if (is_defaults_checked) {
                                    setFieldValue('default_options', []);
                                  } else {
                                    setFieldValue('default_options', [
                                      'washing_machine',
                                      'air_conditioner',
                                      'refrigerator',
                                      'sink',
                                      'gas_range',
                                      'induction',
                                    ]);
                                  }
                                }}
                              >
                                모두 포함
                              </Checkbox>
                            </HStack>
                          </TdFullWContainer>
                        </Tr>
                        <Tr w={'100%'}>
                          <TdFullWContainer title={'추가 옵션'}>
                            <Field
                              name="additional_options"
                              as={FCheckboxButtonGroup}
                              options={additional_options_checkbox}
                            />
                          </TdFullWContainer>
                        </Tr>
                        <Tr w={'100%'}>
                          <TdFullWContainer title={'시설 옵션'} required>
                            <VStack
                              divider={<StackDivider borderColor="gray.200" />}
                              w={'100%'}
                              alignItems={'flex-start'}
                            >
                              <HStack>
                                <Box wordBreak={'keep-all'} w={'100px'}>
                                  방범창
                                </Box>
                                <Field
                                  name="is_security_window"
                                  as={FRadioButtonGroup}
                                  options={is_security_window_radio}
                                />
                              </HStack>
                              <HStack>
                                <Box wordBreak={'keep-all'} w={'100px'}>
                                  주차 여부
                                </Box>
                                <Field name="is_parking_r" as={FRadioButtonGroup} options={is_parking_radio} />
                              </HStack>
                              <HStack>
                                <Box wordBreak={'keep-all'} w={'100px'}>
                                  반려 동물
                                </Box>
                                <Field name="is_pet" as={FRadioButtonGroup} options={is_pet_radio} />
                              </HStack>
                            </VStack>
                          </TdFullWContainer>
                        </Tr>
                      </Tbody>
                    </Table>
                  </Flex>
                  {/* 인테리어 정보 */}
                  <Flex flexDir={'column'} w={'full'} alignItems={'stretch'}>
                    <Flex alignItems={'flex-end'} mb={2}>
                      <Text fontWeight={'bold'} fontSize={'18px'} w={'100%'}>
                        인테리어 정보
                      </Text>
                    </Flex>
                    <Box w="full" borderBottomWidth={'1px'} borderBottomColor={'gray'} />
                    <Table w={'100%'} variant="simple_lining">
                      <Tbody w={'100%'}>
                        <Tr w={'100%'}>
                          <TdFullWContainer title={'리모델링'}>
                            <VStack
                              divider={<StackDivider borderColor="gray.200" />}
                              w={'100%'}
                              alignItems={'flex-start'}
                            >
                              <HStack>
                                <Box minW={'100px'} w={'100px'}>
                                  리모델링 시기
                                </Box>
                                <Field
                                  name="remodeling_season"
                                  as={SelectBox}
                                  h={'32px'}
                                  bgColor="lotionWhite"
                                  placeholder="선택"
                                >
                                  {remodeling_season_data.map((option) => (
                                    <option key={option.value} value={option.value}>
                                      {option.label}
                                    </option>
                                  ))}
                                </Field>
                              </HStack>
                              <HStack>
                                <Box minW={'100px'} w={'100px'}>
                                  리모델링 항목
                                </Box>
                                <Field
                                  name="remodeling_items"
                                  as={FCheckboxButtonGroup}
                                  options={remodeling_items_checkbox}
                                />
                              </HStack>
                            </VStack>
                          </TdFullWContainer>
                        </Tr>
                      </Tbody>
                    </Table>
                  </Flex>
                </VStack>
              ) : (
                // 보기 모드
                <Grid w={'100%'} templateColumns="repeat(3, 1fr)" gap={3}>
                  <GridItem colSpan={2}>
                    {/* <Slider> */}
                    <Slider {...sliderSettings}>
                      {values.pictures.map((el, index) => (
                        <Box mb={10} key={index} borderWidth="1px" overflow="hidden">
                          <Img width={'100%'} src={`${el}`} />
                        </Box>
                      ))}
                    </Slider>
                    <Box py={5}>
                      <Text fontWeight={700} pb={3}>
                        거래정보
                      </Text>
                      <Table fontSize={'14px'} borderTop={'1px solid #414141'}>
                        <QuarterTable
                          title="공용 관리비"
                          data={`${values.management_fee}원`}
                          subTitle="공용 관리비 항목"
                          color={'#f8f8f8'}
                          subData={commons_func(values.common_items)}
                        />
                        <Tbody fontSize={'14px'}>
                          <Tr>
                            <Td fontWeight={500} bgColor={'#f8f8f8'}>
                              개별 사용료 항목
                            </Td>
                            <Td colSpan={3}>{individual_func(values.individual_items)}</Td>
                          </Tr>
                        </Tbody>
                        <QuarterTable
                          title="입주 가능일"
                          data={
                            values.move_in.status === 'direct'
                              ? datedotformat(values.move_in.date)
                              : move_in_radio[move_in_radio.findIndex((el) => el.value == values.move_in.status)].label
                          }
                          subTitle="최소 계약 기간"
                          color={'#f8f8f8'}
                          subData={values.minimum_contract_month && `${values.minimum_contract_month}개월`}
                        />
                      </Table>
                    </Box>
                    <Box py={5}>
                      <Text fontWeight={700} pb={3}>
                        방 정보
                      </Text>
                      <Table>
                        {values.room_type && <QuarterTable title="룸 타입" data={`${values.room_type}`} />}
                        {(values.dong || values.ho) && (
                          <QuarterTable
                            title="단지 및 호수"
                            data={`${values.dong ? values.dong + '동' : ''} ${values.ho && values.ho + '호'}`}
                          />
                        )}
                        <QuarterTable
                          title="해당층"
                          data={`${values.floor}층 / ${
                            floor_type_radio[floor_type_radio.findIndex((el) => el.value == values.floor_type)].label
                          }`}
                        />
                        <QuarterTable
                          title="방 거실형태"
                          data={
                            room_living_type_radio[
                              room_living_type_radio.findIndex((el) => el.value == values.room_living_type)
                            ].label
                          }
                        />
                        <QuarterTable
                          title="방 개수"
                          data={`${values.room_num}개`}
                          subTitle="화장실 개수"
                          subData={`${values.toilet_num}개`}
                          color={'#f8f8f8'}
                        />
                        <QuarterTable
                          title="공급 면적"
                          data={`${values.supply_area}m² (${squaredToPeong(values.supply_area)}평)`}
                          subTitle="전용 면적"
                          subData={`${values.dedicated_area}m² (${squaredToPeong(values.dedicated_area)}평)`}
                          color={'#f8f8f8'}
                        />
                        <QuarterTable
                          title="주실 방향"
                          data={
                            values.room_direction &&
                            `${
                              room_direction_data[
                                room_direction_data.findIndex((el) => el.value == values.room_direction)
                              ].label
                            }쪽`
                          }
                          subTitle="난방 방식"
                          subData={
                            heating_type_data[heating_type_data.findIndex((el) => el.value == values.heating_type)]
                              ?.label
                          }
                          color={'#f8f8f8'}
                        />
                      </Table>
                    </Box>

                    <Box py={5}>
                      <Text fontWeight={700} pb={3}>
                        방 옵션
                      </Text>
                      <Table>
                        <HalfTable title="기본 옵션" data={direct_func(values.default_options)} />
                        <HalfTable title="추가 옵션" data={additional_func(values.additional_options)} />
                        <Tbody fontSize={'14px'}>
                          <Tr>
                            <Td fontWeight={500} bgColor={'#f8f8f8'}>
                              시설 옵션
                            </Td>
                            <Td>
                              <HStack divider={<Text>,&nbsp;</Text>}>
                                {values.is_parking && <Text>{values.is_parking ? '주차 가능' : ''}</Text>}
                                {values.is_pet && <Text>{values.is_pet ? '반려동물 가능' : ''}</Text>}
                                {values.is_security_window && <Text>{values.is_security_window ? '방범창' : ''}</Text>}
                              </HStack>
                            </Td>
                          </Tr>
                        </Tbody>
                        {!isEmptyArray(values.share_facilities) && (
                          <Tbody fontSize={'14px'}>
                            <Tr>
                              <Td fontWeight={500} bgColor={'#f8f8f8'}>
                                공용 공간
                              </Td>
                              <Td>
                                <HStack divider={<Text>,&nbsp;</Text>}>
                                  {values.share_facilities.map((facility: any, index) => (
                                    <Text key={index}>{shareFacilitiesTrans(facility)}</Text>
                                  ))}
                                </HStack>
                              </Td>
                            </Tr>
                          </Tbody>
                        )}
                      </Table>
                    </Box>
                    <Box py={5}>
                      <BuildingInfo id={values.building_id} building={building} room={room} />
                    </Box>
                    <Box py={5}>
                      <Text fontWeight={700} pb={3}>
                        임대관리 신청 여부
                      </Text>
                      <Table>
                        <HalfTable title="기본 옵션" data={'미정'} />
                      </Table>
                    </Box>
                    <Box py={5}>
                      <Text fontWeight={700} pb={3}>
                        인테리어 정보
                      </Text>
                      <Table>
                        <HalfTable title="셀프 인테리어 가능 여부" data={'미정'} />
                        {/* <HalfTable title="리모델링" data={values.remo=} /> */}
                        <HalfTable
                          title="리모델링"
                          data={
                            <Center justifyContent={'start'}>
                              <HStack>
                                <Text>리모델링 시기:</Text>
                                {/* {values.remodeling_items.map((item) => (
                                  <Text>{remodelingItemTrans(item)}&nbsp;</Text>
                                ))} */}
                                <Text>{remodelingSeasonTrans(values.remodeling_season)}</Text>
                              </HStack>
                            </Center>
                          }
                        />
                        {!isEmptyArray(values.remodeling_items) && (
                          <HalfTable
                            title=""
                            data={
                              <Center justifyContent={'start'}>
                                <HStack>
                                  <Text>리모델링 항목:</Text>
                                  {values.remodeling_items.map((item, index) => (
                                    <Text key={index}>{remodelingItemTrans(item)}&nbsp;</Text>
                                  ))}
                                </HStack>
                              </Center>
                            }
                          />
                        )}
                      </Table>
                    </Box>
                    <Box py={5}>
                      <Text fontWeight={700} pb={3}>
                        상세 정보
                      </Text>
                      <Textarea
                        bgColor="#FBFBFB"
                        height={'250px'}
                        resize="none"
                        fontSize={'14px'}
                        isReadOnly
                        placeholder={values.description}
                      />
                    </Box>
                  </GridItem>
                  <GridItem colSpan={1} id="following-content" pos={'relative'}>
                    <Box
                      boxShadow="2px 2px 20px #DDD"
                      borderWidth="1px"
                      borderRadius="lg"
                      pos={'fixed'}
                      w="calc(32% - 80px)"
                    >
                      <Box py={3} bgColor="#ECF1FF" textAlign={'center'}>
                        <Text fontSize="22px" color="#183377" fontWeight={500}>
                          매물번호 {values.code}
                        </Text>
                      </Box>
                      <Box fontWeight={500} p={5}>
                        <Tag variant={values.is_move_in ? 'current_empty' : 'current_filled'} px={2}>
                          {values.is_move_in ? '현재 공실' : '거래 완료'}
                        </Tag>
                        <Box py={3} borderBottom="1px solid #DDD">
                          <Text>
                            {values.address} {values.dong && `${values.dong}동`} {values.ho && `${values.ho}호`}
                          </Text>
                          <Box py={3}>
                            <Text textStyle={'xl20_2xl32'} fontWeight={'medium'} color="#000">
                              보증금 {staticThreeCommafy(values.deposit)}원 / 월세 {staticThreeCommafy(values.rent)}원
                            </Text>
                            <Text textStyle={'xl20_2xl32'} fontWeight={'medium'} color="#c4c4c4">
                              관리비 {staticThreeCommafy(values.management_fee)}원
                            </Text>
                          </Box>
                          <Text pb={1} textStyle={'xl12_2xl16'}>
                            {buildingFormTypeTrans(values.building_form)} | 방 {values.room_num}개 |{' '}
                            {values.dedicated_area}m² | {values.floor}층
                          </Text>
                          <Tooltip
                            hasArrow
                            label={
                              <Flex flexDir={'column'}>
                                <Box>전화번호: {values.host?.phone_number_first}</Box>
                                <Box>이메일: {values.host?.email}</Box>
                              </Flex>
                            }
                          >
                            <Box maxW="min">
                              <TextLink
                                to={`/admin/manage/customers/host/detail/info?id=${values.host?._id}`}
                                isExternal
                              >
                                <Text textStyle={'xl12_2xl16'} minW={'max'}>
                                  임대인({values.host?.user_name})
                                </Text>
                              </TextLink>
                            </Box>
                          </Tooltip>
                        </Box>
                        <Box py={1}>
                          <Box
                            my={3}
                            p={3}
                            border="1px solid #A0A0A0"
                            borderRadius="lg"
                            h={{
                              xl: '100px',
                              '2xl': '160px',
                            }}
                          >
                            <Text textStyle={'xl12_2xl16'}>매물 메모</Text>
                            <Text color="#c4c4c4" textStyle={'xl12_2xl16'}>
                              {values.admin_secret_memo ||
                                '비공개 메모입니다. 본인만 확인 가능하며, 외부에는 노출되지 않습니다.'}
                            </Text>
                          </Box>
                          <Box
                            my={3}
                            p={3}
                            border="1px solid #A0A0A0"
                            borderRadius="lg"
                            h={{
                              xl: '100px',
                              '2xl': '160px',
                            }}
                            textStyle={'xl12_2xl16'}
                          >
                            <Text>제안 내역</Text>
                            {isEmptyArray(values.custom_room_requests) ? (
                              <Text>제안 내역 없음</Text>
                            ) : (
                              <HStack spacing={1}>
                                {values.custom_room_requests?.map((request: any, index: any) => {
                                  const { tenant_id, tenant_name, _id, status } = request;
                                  const base = pathOfUserDetailPage(status as TenantStatus);
                                  return (
                                    <Link href={`${base}${tenant_id}`} key={index} isExternal>
                                      <Text textStyle={'xl12_2xl16'}>{tenant_name}</Text>
                                    </Link>
                                  );
                                })}
                              </HStack>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </GridItem>
                </Grid>
              )}
            </VStack>
          </Scrollbars>
        </ModalContent>
      </Modal>
    </>
  );
};

export default RoomCheckForConfirmModal;

import React, { createContext, useState } from 'react';

type ContextState = {
  tab: '중요 알림' | '공지 사항';
  handleTabState: () => void;
};

const defaultValue: ContextState = {
  tab: '중요 알림',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleTabState: () => {},
};

export const AlertContext = createContext(defaultValue);

export const AlertStore: React.FC = (props) => {
  const [tab, setTab] = useState<'중요 알림' | '공지 사항'>('중요 알림');

  const handleTabState = () => {
    setTab((prev) => (prev === '중요 알림' ? '공지 사항' : '중요 알림'));
  };
  return <AlertContext.Provider value={{ tab, handleTabState }}>{props.children}</AlertContext.Provider>;
};

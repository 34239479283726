import { addDays, endOfMonth, startOfDay, startOfMonth, startOfWeek } from 'date-fns';

export const weeks = ['일', '월', '화', '수', '목', '금', '토'];

export const takeWeek = (start = new Date()) => {
  let date = startOfWeek(startOfDay(start));
  return () => {
    const week = [...Array(7)].map((_, index) => addDays(date, index));
    date = addDays(week[6], 1);
    return week;
  };
};

export const lastDayOfRange = (range: any) => {
  return range[range.length - 1][6];
};

export const takeMonth = (start = new Date()) => {
  let month: Date[][] = [];
  let date = start;

  return () => {
    const weekGen = takeWeek(startOfMonth(date));
    const endDate = startOfDay(endOfMonth(date));
    month.push(weekGen());

    while (lastDayOfRange(month) < endDate) {
      month.push(weekGen());
    }

    const range = month;
    month = [];
    date = addDays(lastDayOfRange(range), 1);

    return range;
  };
};

import {
  Box,
  Button,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  StackDivider,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { currentParamsObject, parseJsonToArr } from '../../../../../lib/common/utils/base';
import { GlobalStatusCheckboxes } from '../SetGlobalStatus';
import _ from 'lodash';

export const SetRequestTypeCheckboxes: React.FC = () => {
  const param_key = 'request_type_tags';
  const { ...paramsprops } = currentParamsObject(false);
  const param_existing_arr: Array<any> = parseJsonToArr(paramsprops[param_key]);
  const is_filled_arr = !_.isEmpty(param_existing_arr);
  return (
    <Popover>
      <PopoverTrigger>
        <Button variant={is_filled_arr ? 'checked' : 'unChecked'}>신청 항목 필터</Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>신청 항목 필터</PopoverHeader>
        <PopoverBody>
          <VStack align={'start'}>
            <GlobalStatusCheckboxes param_key={param_key} param_arr_value={'changing'}>
              추가 승인 요청
            </GlobalStatusCheckboxes>
            <GlobalStatusCheckboxes param_key={param_key} param_arr_value={'extending'}>
              계약 연장 신청
            </GlobalStatusCheckboxes>
            <GlobalStatusCheckboxes param_key={param_key} param_arr_value={'new'}>
              신규 이용 신청
            </GlobalStatusCheckboxes>
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export const SetCheckStatusCheckboxes: React.FC = () => {
  const param_key = 'check_status_tags';
  const { ...paramsprops } = currentParamsObject(false);
  const param_existing_arr: Array<any> = parseJsonToArr(paramsprops[param_key]);
  const is_filled_arr = !_.isEmpty(param_existing_arr);
  return (
    <Popover>
      <PopoverTrigger>
        <Button variant={is_filled_arr ? 'checked' : 'unChecked'}>상태 필터</Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>상태 필터</PopoverHeader>
        <PopoverBody>
          <VStack align={'start'}>
            <GlobalStatusCheckboxes param_key={param_key} param_arr_value={'pending'}>
              신청서 보기
            </GlobalStatusCheckboxes>
            <GlobalStatusCheckboxes param_key={param_key} param_arr_value={'approved'}>
              승인
            </GlobalStatusCheckboxes>
            <GlobalStatusCheckboxes param_key={param_key} param_arr_value={'cancelled'}>
              취소
            </GlobalStatusCheckboxes>
            <GlobalStatusCheckboxes param_key={param_key} param_arr_value={'rejected'}>
              반려
            </GlobalStatusCheckboxes>
            <GlobalStatusCheckboxes param_key={param_key} param_arr_value={'withdrawn'}>
              철회
            </GlobalStatusCheckboxes>
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export const SetBuildingFormCheckboxes: React.FC = () => {
  const param_key = 'building_form_tags';
  const { ...paramsprops } = currentParamsObject(false);
  const param_existing_arr: Array<any> = parseJsonToArr(paramsprops[param_key]);
  const is_filled_arr = !_.isEmpty(param_existing_arr);
  return (
    <Popover>
      <PopoverTrigger>
        <Button variant={is_filled_arr ? 'checked' : 'unChecked'}>건물 형태</Button>
      </PopoverTrigger>
      <PopoverContent w="fit-content">
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>건물 형태</PopoverHeader>
        <PopoverBody>
          <VStack align={'start'}>
            <GlobalStatusCheckboxes param_key={param_key} param_arr_value={'villa'}>
              빌라/주택
            </GlobalStatusCheckboxes>
            <GlobalStatusCheckboxes param_key={param_key} param_arr_value={'officetel'}>
              오피스텔
            </GlobalStatusCheckboxes>
            <GlobalStatusCheckboxes param_key={param_key} param_arr_value={'share_house'}>
              쉐어하우스
            </GlobalStatusCheckboxes>
            <GlobalStatusCheckboxes param_key={param_key} param_arr_value={'coliving_house'}>
              프리미엄오피스텔
            </GlobalStatusCheckboxes>
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export const SetRoomSizeCheckboxes: React.FC = () => {
  const param_key = 'room_size_tags';
  const { ...paramsprops } = currentParamsObject(false);
  const param_existing_arr: Array<any> = parseJsonToArr(paramsprops[param_key]);
  const is_filled_arr = !_.isEmpty(param_existing_arr);
  return (
    <Popover>
      <PopoverTrigger>
        <Button variant={is_filled_arr ? 'checked' : 'unChecked'}>방크기</Button>
      </PopoverTrigger>
      <PopoverContent w={'fit-content'}>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>
          <VStack alignItems={'flex-start'} spacing={1} mb={2}>
            <Box>평수</Box>
            <Box fontSize={'12px'} color={'borderWhite'}>
              중복선택이 가능합니다.
            </Box>
          </VStack>
        </PopoverHeader>
        <PopoverBody>
          <VStack align={'start'}>
            <GlobalStatusCheckboxes param_key={param_key} param_arr_value={'lte_5'}>
              5평 이하
            </GlobalStatusCheckboxes>
            <GlobalStatusCheckboxes param_key={param_key} param_arr_value={'gte_6_lte_10'}>
              6평~10평
            </GlobalStatusCheckboxes>
            <GlobalStatusCheckboxes param_key={param_key} param_arr_value={'gte_11'}>
              11평 이상
            </GlobalStatusCheckboxes>
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export const SetRoomConditionsCheckboxes: React.FC = () => {
  const { ...paramsprops } = currentParamsObject(false);
  const v = {
    room_num_tags: {
      key: 'room_num_tags',
      text: '방개수',
      is_filled: !_.isEmpty(parseJsonToArr(paramsprops['room_num_tags'])),
    },
    room_living_type_tags: {
      key: 'room_living_type_tags',
      text: '방구조',
      is_filled: !_.isEmpty(parseJsonToArr(paramsprops['room_living_type_tags'])),
    },
    floor_type_tags: {
      key: 'floor_type_tags',
      text: '층수',
      is_filled: !_.isEmpty(parseJsonToArr(paramsprops['floor_type_tags'])),
    },
    option_tags: {
      key: 'option_tags',
      text: '추가필터',
      is_filled: !_.isEmpty(parseJsonToArr(paramsprops['option_tags'])),
    },
  };
  const default_width = '130px';
  // const param_existing_arr: Array<any> = parseJsonArrToArr(paramsprops[param_key]);
  // const is_filled_arr = !_.isEmpty(param_existing_arr);
  return (
    <Popover>
      <PopoverTrigger>
        <Button variant={v.room_num_tags.is_filled ? 'checked' : 'unChecked'}>추가필터</Button>
      </PopoverTrigger>
      <PopoverContent minW={'fit-content'}>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>
          <HStack divider={<StackDivider borderColor={'gray.200'} />} alignItems={'flex-start'}>
            {/* 방개수 */}
            <Box w={default_width}>
              <VStack alignItems={'flex-start'} spacing={1} mb={2}>
                <Box>{v.room_num_tags.text}</Box>
                <Box fontSize={'12px'} color={'borderWhite'}>
                  중복선택이 가능합니다.
                </Box>
              </VStack>
              <VStack alignItems={'start'}>
                <GlobalStatusCheckboxes param_key={v.room_num_tags.key} param_arr_value={'one_room'}>
                  원룸
                </GlobalStatusCheckboxes>
                <GlobalStatusCheckboxes param_key={v.room_num_tags.key} param_arr_value={'two_room'}>
                  투룸
                </GlobalStatusCheckboxes>
                <GlobalStatusCheckboxes param_key={v.room_num_tags.key} param_arr_value={'three_room'}>
                  쓰리룸 이상
                </GlobalStatusCheckboxes>
              </VStack>
            </Box>
            {/* 방구조 */}
            <Box w={default_width}>
              <VStack alignItems={'flex-start'} spacing={1} mb={2}>
                <Box>{v.room_living_type_tags.text}</Box>
                <Box fontSize={'12px'} color={'borderWhite'}>
                  중복선택이 가능합니다.
                </Box>
              </VStack>
              <VStack alignItems={'start'}>
                <GlobalStatusCheckboxes param_key={v.room_living_type_tags.key} param_arr_value={'open'}>
                  오픈형
                </GlobalStatusCheckboxes>
                <GlobalStatusCheckboxes param_key={v.room_living_type_tags.key} param_arr_value={'devide'}>
                  분리형
                </GlobalStatusCheckboxes>
                <GlobalStatusCheckboxes param_key={v.room_living_type_tags.key} param_arr_value={'duplex'}>
                  복층형
                </GlobalStatusCheckboxes>
              </VStack>
            </Box>
            {/* 층수 */}
            <Box w={default_width}>
              <VStack alignItems={'flex-start'} spacing={1} mb={2}>
                <Box>{v.floor_type_tags.text}</Box>
                <Box fontSize={'12px'} color={'borderWhite'}>
                  중복선택이 가능합니다.
                </Box>
              </VStack>
              <VStack alignItems={'start'}>
                <GlobalStatusCheckboxes param_key={v.floor_type_tags.key} param_arr_value={'ground'}>
                  지상
                </GlobalStatusCheckboxes>
                <GlobalStatusCheckboxes param_key={v.floor_type_tags.key} param_arr_value={'semi_ground'}>
                  반지하
                </GlobalStatusCheckboxes>
                <GlobalStatusCheckboxes param_key={v.floor_type_tags.key} param_arr_value={'underground'}>
                  지하
                </GlobalStatusCheckboxes>
                <GlobalStatusCheckboxes param_key={v.floor_type_tags.key} param_arr_value={'rooftop'}>
                  옥탑
                </GlobalStatusCheckboxes>
              </VStack>
            </Box>
            {/* 추가옵션 */}
            <Box w={default_width}>
              <VStack alignItems={'flex-start'} spacing={1} mb={2}>
                <Box>{v.option_tags.text}</Box>
                <Box fontSize={'12px'} color={'borderWhite'}>
                  중복선택이 가능합니다.
                </Box>
              </VStack>
              <VStack alignItems={'start'}>
                <GlobalStatusCheckboxes param_key={v.option_tags.key} param_arr_value={'parking'}>
                  주차가능
                </GlobalStatusCheckboxes>
                <GlobalStatusCheckboxes param_key={v.option_tags.key} param_arr_value={'full_option'}>
                  풀옵션
                </GlobalStatusCheckboxes>
                <GlobalStatusCheckboxes param_key={v.option_tags.key} param_arr_value={'elevator'}>
                  엘리베이터
                </GlobalStatusCheckboxes>
                <GlobalStatusCheckboxes param_key={v.option_tags.key} param_arr_value={'pet'}>
                  반려동물
                </GlobalStatusCheckboxes>
              </VStack>
            </Box>
          </HStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export type RadioLabelProps = Array<{ label: string; value: any }>;
// 건물
export const entrance_type_radio = [
  {
    label: '계단식',
    value: 'stare',
  },
  {
    label: '복도식',
    value: 'hallway',
  },
  {
    label: '복합식',
    value: 'mix',
  },
];
export const complaint_type_radio = [
  {
    label: '일반민원',
    value: 'common',
  },
  {
    label: '시설관리',
    value: 'facility',
  },
  {
    label: '수리요청',
    value: 'damage',
  },
];
export const elevator_radio = [
  {
    label: '있음',
    value: 'true',
  },
  {
    label: '없음',
    value: 'false',
  },
];
export const parking_available_radio = [
  {
    label: '있음',
    value: 'true',
  },
  {
    label: '없음',
    value: 'false',
  },
];

export const management_radio = [
  {
    label: '신청함',
    value: 'true',
  },
  {
    label: '신청안함',
    value: 'false',
  },
];
// 방
export const individual_fee_radio = [
  {
    label: '있음',
    value: 'true',
  },
  {
    label: '없음',
    value: 'false',
  },
];
export const move_in_radio = [
  {
    label: '즉시 입주',
    value: 'now',
  },
  {
    label: '날짜 협의',
    value: 'conference',
  },
  {
    label: '직접 입력',
    value: 'direct', // db 수정 필요
  },
];

export const floor_type_radio = [
  {
    label: '지상',
    value: 'ground',
  },
  {
    label: '반지하',
    value: 'semi_ground',
  },
  {
    label: '지하',
    value: 'underground',
  },
  {
    label: '옥탑',
    value: 'rooftop',
  },
];

export const room_living_type_radio = [
  {
    label: '오픈형',
    value: 'open',
  },
  {
    label: '분리형',
    value: 'devide',
  },
  {
    label: '복층',
    value: 'duplex',
  },
];
export const access_status_radio = [
  {
    label: '공개',
    value: 'open',
  },
  {
    label: '숨기기',
    value: 'hidden',
  },
];
export const is_security_window_radio = [
  {
    label: '있음',
    value: 'true',
  },
  {
    label: '없음',
    value: 'false',
  },
];
export const is_parking_radio = [
  {
    label: '가능',
    value: 'true',
  },
  {
    label: '불가능',
    value: 'false',
  },
];
export const is_pet_radio = [
  {
    label: '가능',
    value: 'true',
  },
  {
    label: '불가능',
    value: 'false',
  },
];
export const is_interior_radio = [
  {
    label: '가능',
    value: 'true',
  },
  {
    label: '불가능',
    value: 'false',
  },
];

export const business_type_radio = [
  {
    label: '개인',
    value: 'sole_proprietor',
  },
  {
    label: '법인',
    value: 'corporation',
  },
];

export const headCount_convert = [
  {
    label: '선택',
    value: '0',
  },
  {
    label: '1인',
    value: '1',
  },
  {
    label: '5인',
    value: '5',
  },
  {
    label: '10인',
    value: '10',
  },
  {
    label: '15인',
    value: '15',
  },
  {
    label: '20인',
    value: '20',
  },
  {
    label: '30인',
    value: '30',
  },
];

export const alternative_radio = [
  {
    label: '예',
    value: 'true',
  },
  {
    label: '아니오',
    value: 'false',
  },
];

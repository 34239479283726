import { Box, Button, Flex, HStack, Table, Tbody, Text, useToast, VStack } from '@chakra-ui/react';
import axios from 'axios';
import { FirebaseError } from 'firebase/app';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import { Field, useFormikContext } from 'formik';
import { Suspense, useState } from 'react';
import { CountdownRenderProps } from 'react-countdown';
import { initialValue, StepProps } from '..';
import { queryClient } from '../../../../..';
import Validation from '../../../../../components/common/FormikValidation/Validation';
import { CustomInput } from '../../../../../components/common/Input';
import { TrContainer } from '../../../../../components/common/Table/Tr';
import { base, fetchClient } from '../../../../../lib/common/utils/axios';
import { deCommafy, deDashfy } from '../../../../../lib/common/utils/digitformatter';
import { failToUpload, inputFulfill } from '../../../../../statics/common/data/toast';
import { auth, useLogout } from '../../../../../lib/common/utils/firebase/init';
import { setLocalStorageStringItem } from '../../../../../lib/common/utils/localstorage';
import { useNavigate } from 'react-router-dom';

const booleanConverter = (bool: boolean) => {
  const value = bool ? 'true' : 'false';
  return value;
};

const Timeout: React.FC<CountdownRenderProps> = ({ minutes, seconds, completed }) => {
  return (
    <>
      남은 시간: {minutes}:{seconds}
    </>
  );
};

const Four: React.FC<StepProps> = ({ setStep }) => {
  const { values, errors, setFieldValue, handleChange, isValid } = useFormikContext<typeof initialValue>();
  const toast_notFulfill = useToast();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const navigate = useNavigate();
  const _logout = useLogout();
  return (
    <Flex flexDir={'column'} h={'full'} justifyContent={'space-between'}>
      {/* 회원 가입 */}
      <VStack w="100%">
        <Flex flexDir={'column'} fontWeight={'bold'} fontSize={'15px'} textAlign={'start'} flexGrow={1} w={'100%'}>
          <Box>담당자 정보</Box>
          <VStack mt={2} fontWeight={'normal'} fontSize={'12px'} alignItems={'flex-start'} spacing={0}>
            <Text>보홈 서비스 도입 및 운영하실 담당자의 정보를 입력해 주세요.</Text>
          </VStack>
        </Flex>
        <Flex w="100%">
          <Table w="100%">
            <Tbody>
              <TrContainer title="담당자명" fontSize={'12px'}>
                <HStack>
                  <Field
                    name="user_name"
                    as={CustomInput}
                    autoFocus
                    placeholder="성함을 입력해 주세요."
                    onChange={handleChange}
                    _placeholderShown={{ textOverflow: 'ellipsis' }}
                  />
                </HStack>
                <Validation error={errors.user_name} />
              </TrContainer>
              <TrContainer title="부서 / 직책" fontSize={'12px'}>
                <HStack>
                  <Field name="department" as={CustomInput} placeholder="부서" type="text" onChange={handleChange} />
                  <Text>/</Text>
                  <Field name="position" as={CustomInput} placeholder="직책" type="text" onChange={handleChange} />
                </HStack>
                <Validation error={errors.department} mt={errors.position ? 0 : 1} />
                <Validation error={errors.position} mt={errors.department ? 0 : 1} />
              </TrContainer>
              <TrContainer title="연락처" fontSize={'12px'}>
                {/* <CustomInput placeholder="비밀번호를 다시 한번 입력해 주세요." /> */}
                <Field
                  name="phone_number_first"
                  as={CustomInput}
                  placeholder="'-'없이 입력해 주세요."
                  type="text"
                  onChange={handleChange}
                />
                <Validation error={errors.phone_number_first} />
              </TrContainer>
            </Tbody>
          </Table>
        </Flex>
      </VStack>
      <HStack>
        <Button
          variant={
            values.user_name &&
            values.department &&
            values.position &&
            values.phone_number_first &&
            !errors.user_name &&
            !errors.department &&
            !errors.position &&
            !errors.phone_number_first
              ? 'gray'
              : 'basic'
          }
          isDisabled={isSubmitting}
          boxShadow={'md'}
          w={'105px'}
          style={{ height: '40px' }}
          onClick={() => setStep(2)}
        >
          이전
        </Button>
        <Button
          variant={
            values.user_name &&
            values.department &&
            values.position &&
            values.phone_number_first &&
            !errors.user_name &&
            !errors.department &&
            !errors.position &&
            !errors.phone_number_first
              ? 'basic'
              : 'gray'
          }
          boxShadow={'md'}
          w={'full'}
          style={{ height: '40px' }}
          isLoading={isSubmitting}
          onClick={async () => {
            try {
              console.log('a2');
              setLocalStorageStringItem('--bohome-route-user-type', 'company');
              setStep(4);
              const {
                one,
                two,
                three,
                company_name,
                business_certificate,
                logo,
                business_status,
                business_item,
                business_type,
                business_registration_number,
                address,
                road_address,
                capital,
                detail_address,
                employee_number,
                establishment_date,
                representative_number,
                digital_tax_invoice_email,
                email,
                p_password,
                p_confirm_password,
                user_name,
                department,
                position,
                phone_number_first,
                // brokerage_license,
              } = values;
              await createUserWithEmailAndPassword(auth, values.email, values.p_password);
              const userCredential = await signInWithEmailAndPassword(auth, email, values.p_password);
              const firebaseUser = userCredential.user;
              const token = await firebaseUser.getIdToken();
              const instance = await fetchClient({
                headers: { token },
              });
              await instance.post('subscriber/master/token/verify', {
                firebase_uid: (firebaseUser as any).accessToken,
                login_type: 'email',
                email: values.email,
              });
              const companyFormData = new FormData();

              companyFormData.append('is_service', booleanConverter(one));
              companyFormData.append('is_privacy', booleanConverter(two));
              companyFormData.append('is_marketing', booleanConverter(three));

              companyFormData.append('company_name', company_name);
              companyFormData.append('business_certificate', business_certificate);
              companyFormData.append('business_status', business_status);
              companyFormData.append('business_item', business_item);
              companyFormData.append('business_registration_number', deDashfy(business_registration_number));
              companyFormData.append('address', address);
              companyFormData.append('road_address', road_address);
              companyFormData.append('detail_address', detail_address);
              companyFormData.append('employee_number', deCommafy(employee_number.toString()));
              companyFormData.append('business_type', business_type);
              companyFormData.append('capital', deCommafy(capital.toString()));
              companyFormData.append('establishment_date', establishment_date);
              companyFormData.append('representative_number', representative_number);
              companyFormData.append('digital_tax_invoice_email', digital_tax_invoice_email);
              companyFormData.append('logo', logo);
              await instance.post('subscriber/master/company', companyFormData);

              const userFormData = new FormData();
              userFormData.append('user_name', user_name);
              userFormData.append('email', email);
              userFormData.append('phone_number_first', phone_number_first);
              userFormData.append('profile_picture', logo);
              userFormData.append('business_registration_number', deDashfy(business_registration_number));
              userFormData.append('company_name', company_name);
              userFormData.append('department', department);
              userFormData.append('position', position);
              userFormData.append('login_type', 'email');
              userFormData.append('is_service', booleanConverter(one));
              userFormData.append('is_privacy', booleanConverter(two));
              userFormData.append('is_marketing', booleanConverter(three));
              await instance.post('subscriber/master/profile/init', userFormData);

              setLocalStorageStringItem('--bohome-company-type', 'subscriber');
              setLocalStorageStringItem('--bohome-user-type', 'master');
              setLocalStorageStringItem('--bohome-token', token);
              setLocalStorageStringItem('--bohome-email', email);
              setLocalStorageStringItem('--bohome-route-user-type', 'company');
              setLocalStorageStringItem('--bohome-is-login', 'true');

              navigate('/company/manage/dashboard');
            } catch (err: any) {
              if (err?.message?.includes('(auth/email-already-in-use)')) {
                console.log('(auth/email-already-in-use)');
              }
              toast_notFulfill({
                ...failToUpload,
                title: '고객센터 문의',
                description: '에러가 발생했습니다. 고객센터 1833-9092로 문의주시길 바랍니다.',
              });
            }
          }}
        >
          가입
        </Button>
      </HStack>
    </Flex>
  );
};

export default Four;

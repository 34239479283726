import React from 'react';
import { Pie } from '@visx/shape';
import { Group } from '@visx/group';
import { scaleOrdinal } from '@visx/scale';
import { letterFrequency } from '@visx/mock-data';
import { Box, Center, Flex, HStack, List, ListIcon, ListItem, VStack } from '@chakra-ui/react';
import { RoomPriority } from '../../../lib/common/utils/axios/types';

const defaultMargin = { top: 20, right: 20, bottom: 20, left: 20 };

export type PieProps = {
  dataSet: RoomPriority;
  width: number;
  height: number;
  margin?: typeof defaultMargin;
};

export const BasicPie = ({ dataSet, width, height, margin = defaultMargin }: PieProps) => {
  const { rent_priority, commute_priority, room_condition_priority } = dataSet;
  const priorityData = [
    { condition: 'monthly_price', value: rent_priority },
    { condition: 'commute_time', value: commute_priority },
    { condition: 'building_condition', value: room_condition_priority },
  ];
  const priorityData_condi_en_arr = priorityData.map((data) => data.condition);
  const priorityData_condi_kr_arr = ['월세 가격', '통근 시간', '건물 컨디션'];
  const priorityData_trans_obj = priorityData_condi_en_arr.reduce(
    (a, v, i) => ({ ...a, [v]: priorityData_condi_kr_arr[i] }),
    {},
  ) as any;

  const getCoditionColor = scaleOrdinal({
    domain: priorityData.map((l) => l.condition),
    range: ['#183377', '#00A3FF', '#9CE1FF'],
  });

  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;
  const radius = Math.min(innerWidth, innerHeight) / 2;
  const centerY = innerHeight / 2;
  const centerX = innerWidth / 2;
  const top = centerY + margin.top;
  const left = centerX + margin.left;
  const pieSortValues = (a: any, b: any) => b - a;

  return (
    <svg width={width} height={height}>
      <Group top={top} left={left}>
        <Pie data={priorityData} pieValue={(d) => d.value} pieSortValues={pieSortValues} outerRadius={radius}>
          {(pie) => {
            return pie.arcs.map((arc, index) => {
              const { condition } = arc.data;
              const [centroidX, centroidY] = pie.path.centroid(arc);
              const hasSpaceForLabel = arc.endAngle - arc.startAngle >= 0.1;
              const arcPath = pie.path(arc) as any;
              const arcFill = getCoditionColor(condition);
              return (
                <g key={`arc-${condition}-${index}`}>
                  <path d={arcPath} fill={arcFill} />
                  {hasSpaceForLabel && (
                    <text
                      x={centroidX}
                      y={centroidY}
                      dy=".33em"
                      fill="#ffffff"
                      fontSize={22}
                      textAnchor="middle"
                      pointerEvents="none"
                    >
                      {priorityData_trans_obj[arc.data.condition]}
                    </text>
                  )}
                </g>
              );
            });
          }}
        </Pie>
      </Group>
    </svg>
  );
};

export const IndexingPie = ({ dataSet, width, height, margin = defaultMargin }: PieProps) => {
  if (!dataSet) {
    return <div></div>;
  }
  const { rent_priority, commute_priority, room_condition_priority } = dataSet;
  const priorityData = [
    { condition: 'monthly_price', value: rent_priority },
    { condition: 'commute_time', value: commute_priority },
    { condition: 'building_condition', value: room_condition_priority },
  ];
  const priorityData_condi_en_arr = priorityData.map((data) => data.condition);
  const priorityData_condi_kr_arr = ['월세 가격', '통근 시간', '건물 컨디션'];
  const priorityData_trans_obj = priorityData_condi_en_arr.reduce(
    (a, v, i) => ({ ...a, [v]: priorityData_condi_kr_arr[i] }),
    {},
  ) as any;

  const getCoditionColor = scaleOrdinal({
    domain: priorityData.map((l) => l.condition),
    range: ['#183377', '#00A3FF', '#9CE1FF'],
  });
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;
  const radius = Math.min(innerWidth, innerHeight) / 2;
  const centerY = innerHeight / 2;
  const centerX = innerWidth / 2;
  const top = centerY + margin.top;
  const left = centerX + margin.left;
  const pieSortValues = (a: any, b: any) => b - a;

  return (
    <Flex w="full" alignItems={'center'}>
      <svg width={width} height={height}>
        <Group top={top} left={left}>
          <Pie
            data={priorityData}
            pieValue={(d) => d.value}
            pieSortValues={pieSortValues}
            outerRadius={radius}
            startAngle={22}
          >
            {(pie) => {
              return pie.arcs.map((arc, index) => {
                const { condition } = arc.data;
                const [centroidX, centroidY] = pie.path.centroid(arc);
                const hasSpaceForLabel = arc.endAngle - arc.startAngle >= 0.1;
                const arcPath = pie.path(arc) as any;
                const arcFill = getCoditionColor(condition);
                return (
                  <g key={`arc-${condition}-${index}`}>
                    <path d={arcPath} fill={arcFill} />
                    {/* {hasSpaceForLabel && (
                      <text
                        x={centroidX}
                        y={centroidY}
                        dy=".33em"
                        fill="#ffffff"
                        fontSize={22}
                        textAnchor="middle"
                        pointerEvents="none"
                      >
                        {priorityData_trans_obj[condition]}
                      </text>
                    )} */}
                  </g>
                );
              });
            }}
          </Pie>
        </Group>
      </svg>
      <Center bgColor={'#F9FAFB'} w={'100px'} h={'90px'} borderRadius={'md'}>
        <List spacing={3}>
          <ListItem>
            <HStack justifyContent={'flex-start'}>
              <Box w={1} h={1} borderRadius={'full'} bgColor={'#183377'} />
              <Box>월세 가격</Box>
            </HStack>
          </ListItem>
          <ListItem>
            <HStack justifyContent={'flex-start'}>
              <Box w={1} h={1} borderRadius={'full'} bgColor={'#00A3FF'} />
              <Box>통근 시간</Box>
            </HStack>
          </ListItem>
          <ListItem>
            <HStack justifyContent={'flex-start'}>
              <Box w={1} h={1} borderRadius={'full'} bgColor={'#9CE1FF'} />
              <Box>건물 컨디션</Box>
            </HStack>
          </ListItem>
        </List>
      </Center>
    </Flex>
  );
};

import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Image,
  Modal,
  ModalContent,
  ModalOverlay,
  Tag,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { isEmptyArray } from '@chakra-ui/utils';
import { Form, Formik } from 'formik';
import Scrollbars from 'react-custom-scrollbars-2';
import * as Yup from 'yup';
import { WBox } from '../../Box';
import { useSuggestRooms } from '../../../../lib/common/hooks/mutation';
import { BuildingRoom, Room } from '../../../../lib/common/utils/axios/types';
import { CheckCircleIcon } from '../../../../statics/common/icons';
import * as Type from '../../../../types/common/CommonType';

interface ConfirmRecommendRoomsModalProps {
  room_request_id: string;
  disabled: boolean;
  buildingsRooms: Type.Building[];
}

const ConfirmRecommendRoomsModal: React.FC<ConfirmRecommendRoomsModalProps> = ({
  children,
  disabled,
  buildingsRooms,
  room_request_id,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { mutateAsync } = useSuggestRooms(room_request_id);

  const handleModalOpen = () => {
    if (!disabled) {
      onOpen();
    }
  };

  return (
    <>
      <Box onClick={handleModalOpen}>{children}</Box>

      <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside" size={'4xl'} motionPreset="slideInRight">
        <ModalOverlay />
        <Formik
          initialValues={{
            buildingrooms: buildingsRooms.map((buildingroom) => {
              return {
                _id: buildingroom?.room?._id,
                admin_relay_memo: buildingroom?.room?.admin_secret_memo,
              };
            }),
          }}
          onSubmit={async (values, { setSubmitting }) => {
            await mutateAsync({
              rooms: values.buildingrooms,
            }).then(() => {
              onClose();
            });
          }}
          validationSchema={Yup.object().shape({})}
        >
          {({ values, errors, isValid, handleChange, setFieldValue }) => {
            console.log('values => ', values);
            return (
              // <Form style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>

              <Form>
                <ModalContent p={5} h={'720px'}>
                  <Center flexDir={'column'} m={10} alignItems={'flex-start'}>
                    <HStack>
                      <CheckCircleIcon w={8} h={8} />
                      <Text fontSize={'23px'}>매물 추가</Text>
                    </HStack>
                    <VStack fontSize={'20px'} flexDir={'column'} my={4} spacing={0} alignItems={'flex-start'}>
                      {/* <Box>{'매물과 함께 임직원에게 전달하실 메세지를 작성해 주세요.'}</Box> */}
                    </VStack>
                    {/* 선택된 매물 리스트 */}
                    <Scrollbars
                      autoHide
                      universal
                      style={{
                        height: '380px',
                      }}
                    >
                      <VStack w={'full'}>
                        {/* <Box>List 1</Box> */}
                        {buildingsRooms.map((building, index) => {
                          const { address, name: building_name, building_form } = building;

                          const room = building?.room;
                          const code = room?.code;
                          const pictures = room?.pictures;
                          const is_move_in = room?.is_move_in;
                          const dong = room?.dong;
                          const ho = room?.ho;
                          const room_num = room?.room_num;
                          const rent = room?.rent;
                          const deposit = room?.deposit;
                          const dedicated_area = room?.dedicated_area;
                          const floor = room?.floor;
                          const management_fee = room?.management_fee;
                          const room_type = room?.room_type;
                          const agent = room?.agent;

                          return (
                            <WBox w={'full'} key={index}>
                              <HStack w={'full'} p={3} spacing={3}>
                                <Image
                                  boxSize={'92px'}
                                  src={!isEmptyArray(pictures) ? pictures[0] : 'https://via.placeholder.com/117'}
                                  alt={'room-thumnail'}
                                />
                                <VStack spacing={1} w={'full'}>
                                  <Flex w={'full'} justifyContent={'space-between'} align={'center'}>
                                    {/* 주소 */}
                                    <Box fontSize={'14px'}>
                                      {address} {building_name && `(${building_name})`}
                                      {dong && `${dong}동`} {ho && `${ho}호`}
                                    </Box>
                                    {/* 매물번호 */}
                                    <VStack>
                                      {room_type && <Tag>{room_type}</Tag>}
                                      <Tag variant={'room_number'}>매물번호 {code}</Tag>
                                    </VStack>
                                  </Flex>
                                </VStack>
                              </HStack>
                            </WBox>
                          );
                        })}
                      </VStack>
                    </Scrollbars>
                    <Flex
                      justifyContent={'center'}
                      w={'100%'}
                      borderBottom={'1px solid'}
                      borderBottomColor={'gray.400'}
                      mt={1}
                    />
                    <Center w="full">
                      <Button
                        mt={'30px'}
                        variant={'basic'}
                        w={'140px'}
                        fontSize={'14px'}
                        fontWeight={'bold'}
                        type="submit"
                      >
                        추가 완료
                      </Button>
                    </Center>
                  </Center>
                </ModalContent>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

export default ConfirmRecommendRoomsModal;

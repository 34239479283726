import {
  Box,
  Button,
  Center,
  Collapse,
  Flex,
  Grid,
  HStack,
  Link,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { isUndefined } from '@chakra-ui/utils';
import format from 'date-fns/format';
import { useState } from 'react';
import { GTitleItem } from '../../../../../../components/common/GridItem';
import ContractPDF from '../../../../../../components/subscriber/Modals/ContractPDF';
import { WTd } from '../../../../../../components/common/Table/Td';
import { WTh } from '../../../../../../components/common/Table/Th';
import { useGetAllCompanyContracts } from '../../../../../../lib/subscriber/hooks/query';
import { datedotformat } from '../../../../../../lib/common/utils/dateformatter';
import { RightArrowIcon } from '../../../../../../statics/common/icons';
import CompanySection from '../CompanyInfo';

/* .../company/manage/subscription/ */

const contract_type_label = {
  basic: '베이직',
  advanced: '어드밴스드',
} as any;

type tFilter = 'asc' | 'desc';

const SubscriptionContractPage = () => {
  const { isOpen: isContractOpen, onToggle: onContractToggle } = useDisclosure();

  // filter
  const [date, setDate] = useState<tFilter>('desc');
  const [account, setAccount] = useState<tFilter>('desc');
  const [status, setStatus] = useState<tFilter>('desc');
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { data: contractArrData } = useGetAllCompanyContracts({
    axiosConfig: {
      params: {
        sort: 'subscription_contract.contract_created_date',
        is_reverse: date === 'desc',
      },
    },
  });

  // console.log('contract.subscription_contract.subscription_contract_pdf_url => ', contract.subscription_contract.subscription_contract_pdf_url);

  // contractArrData?.map((contract) => {
  //   console.log(!contract.subscription_contract.subscription_contract_pdf_url);
  // });

  console.log('contractArrData => ', contractArrData);

  return (
    <Grid w="full" h="full" gridTemplateColumns={'300px auto'} gap={6}>
      {/* 회사 관련 정보 섹션 */}
      <CompanySection />
      {/* 보홈 플랜 안내 */}
      <GTitleItem
        upperSection={
          <Flex justifyContent={'space-between'} alignItems={'center'} cursor={'pointer'} onClick={onContractToggle}>
            <Text fontSize={'24px'} fontWeight={'medium'}>
              계약서 조회
            </Text>
            <RightArrowIcon
              w={6}
              h={6}
              transform={`rotate(${!isContractOpen ? 270 : 90}deg)`}
              transition={'transform 0.15s linear'}
            />
          </Flex>
        }
        // rowSpan={isContractOpen ? 1 : 1}
        h={!isContractOpen ? 'calc(100vh - 128px)' : '68px'}
        // h="calc(100vh - 128px)"
        // minH="calc(100vh - 128px)"
      >
        <Collapse
          in={!isContractOpen}
          startingHeight={'1px'}
          style={{ width: '100%', marginTop: 'unset' }}
          endingHeight={'100%'}
        >
          <Box w={'full'} p={7}>
            <Text fontWeight={'bold'} fontSize={'18px'} mb={2}>
              계약서 내역
            </Text>
            <Box borderTopWidth={'1px'} borderTopColor={'blackAlpha.500'} />
            <Table variant="simple_same">
              <Thead bgColor={'#F8F8F8'} borderColor={'gray.100'}>
                <Tr>
                  <WTh
                    borderR
                    tFilter={date}
                    onClick={() => {
                      setDate((prev) => (prev === 'asc' ? 'desc' : 'asc'));
                    }}
                  >
                    계약 체결일
                  </WTh>
                  <WTh borderR>구독 플랜</WTh>
                  <WTh
                    borderR
                    // tFilter={account}
                    onClick={() => {
                      setAccount((prev) => (prev === 'asc' ? 'desc' : 'asc'));
                    }}
                  >
                    결제 수단
                  </WTh>
                  <WTh borderR>구독 사유</WTh>
                  <WTh
                    borderR
                    // tFilter={status}
                    onClick={() => {
                      setStatus((prev) => (prev === 'asc' ? 'desc' : 'asc'));
                    }}
                  >
                    계약서 조회
                  </WTh>
                </Tr>
              </Thead>

              <Tbody w="full">
                {contractArrData?.map((contract, index) => {
                  const {
                    subscription_contract: {
                      admin_check_status,
                      subscriber_check_status,
                      subscription_contract_pdf_url,
                    },
                  } = contract;

                  // const LookupComponent = (() => {
                  //   if (is_deprecated) {
                  //     return <Text>새로운 신청으로 대체되었습니다.</Text>;
                  //   } else {
                  //     const luc =
                  //       admin_check_status === 'approved' &&
                  //       subscriber_check_status === 'approved' &&
                  //       !isUndefined(subscription_contract_pdf_url) ? (
                  //         <Button
                  //           variant={'grayBorder_blackFont'}
                  //           h="30px"
                  //           as={Link}
                  //           textDecor={'none'}
                  //           _hover={{ textDecor: 'none' }}
                  //           href={contract.subscription_contract.subscription_contract_pdf_url}
                  //           isExternal
                  //           download
                  //         >
                  //           조회
                  //         </Button>
                  //       ) : admin_check_status === 'approved' && subscriber_check_status === 'approved' ? (
                  //         <Text>계약서 작성 중</Text>
                  //       ) : admin_check_status === 'rejected' || subscriber_check_status === 'rejected' ? (
                  //         <Text>반려</Text>
                  //       ) : admin_check_status === 'pending' || subscriber_check_status === 'pending' ? (
                  //         <Text>계약 체결 중</Text>
                  //       ) : null;
                  //     return luc;
                  //   }
                  // })();

                  return (
                    <Tr key={index}>
                      <WTd borderR>{datedotformat(contract.subscription_contract.contract_created_date)}</WTd>
                      <WTd borderR>
                        {contract_type_label[contract.subscription_contract.subscription_type]}{' '}
                        {contract.subscription_contract.available_user_count}인
                      </WTd>
                      <WTd borderR>{contract.subscription_contract.is_year ? '연' : '월'} 결제</WTd>
                      <WTd borderR>{contract.subscription_contract.description || '-'}</WTd>
                      <WTd borderR>
                        {contract.subscription_contract.subscription_contract_pdf_url ? (
                          <HStack justifyContent={'center'}>
                            <Button
                              variant={'grayBorder_blackFont'}
                              h="30px"
                              as={Link}
                              textDecor={'none'}
                              _hover={{ textDecor: 'none' }}
                              href={contract.subscription_contract.subscription_contract_pdf_url}
                              isExternal
                              download
                            >
                              조회
                            </Button>
                          </HStack>
                        ) : (
                          '-'
                        )}
                      </WTd>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </Box>
          {/* Modal */}
          <ContractPDF isOpen={isOpen} onClose={onClose} />
        </Collapse>
      </GTitleItem>
    </Grid>
  );
};

export default SubscriptionContractPage;

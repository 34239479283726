import { HStack, Text } from '@chakra-ui/react';
import { useGetCompanyContract } from '../../../lib/subscriber/hooks/query';
import { UsersIcon } from '../../../statics/common/icons';

// 최종 이전에만 쓰이는 UI
const AvailableUser = () => {
  const { data: contractdata } = useGetCompanyContract();
  if (contractdata === undefined) {
    return null;
  }

  const { available_user_count } = contractdata;

  return (
    <HStack>
      <UsersIcon />
      <Text>{available_user_count}인</Text>
    </HStack>
  );
};

export default AvailableUser;

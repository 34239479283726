// companyUser
export const request_item_lists = [
  {
    _id: 1,
    like: true,
    status: '신규',
    name: '김한별',
    conditions: ['구로구 구로동', '관악구 신림동', '~1000만원', '~70만원', '원룸'],
  },
  {
    _id: 2,
    like: false,
    status: '신규',
    name: '백지호',
    conditions: ['구로구 구로동', '관악구 신림동', '~500만원', '~80만원', '투룸'],
  },
  {
    _id: 3,
    like: false,
    status: '신규',
    name: '이성문',
    conditions: ['구로구 구로동', '관악구 신림동', '~500만원', '~60만원', '원룸'],
  },
  {
    _id: 4,
    like: false,
    status: '신규',
    name: '오민식',
    conditions: ['구로구 구로동', '관악구 신림동', '~1000만원', '~50만원', '원룸'],
  },
  // {
  //   _id: 3,
  //   like: true,
  //   status: '종료 예정',
  //   name: '박찬호',
  //   conditions: ['구로구 구로동', '관악구 신림동', '~1000만원', '~70만원', '원룸'],
  // },
];

export const request_detail_lists = [
  {
    list_id: 1,
    like: false,
    submit_date: new Date(2021, 11, 17, 3, 24, 0),
    hope_enter_date: new Date('2022-2-5'),
    contract_period: '6개월',
    deposit: '최대 500만원', // 최대는 내가 넣기
    monthly_rent_budget: '최대 55만원',
    prefered_areas: ['구로구 구로동', '관악구 신림동', '강남구 역삼동'],
    prefered_types: ['오피스텔', '프리미엄오피스텔', '오픈형 원룸', '분리형 원룸', '반지층 제외'],
    must_have_optionss: ['보안시설', '엘리베이터', '세탁기', '반려동물 허용'],
    priorities: ['월세 가격', '통근 시간', '건물 컨디션'], // 60% 30% 10%
    location_company: '강남구 역삼동 123-1',
    transportation: '버스, 지하철', // [도보 버스 지하철 자가용] 중복 ㅇ
    hope_commuting_time: '대중 교통으로 최대 40분이내', // [5] 중복 ㅇ
    extra_request_note: '추가 요청 사항 기입!',
  },
];

// agency - Own

export const agency = {
  user_id: 10,
  request_total_num: 54,
  request_total_like: 3,
  request_status: {
    suggested: 5,
    reviewing: 5,
    rejected: 1,
    tour_request: 1,
    complete_contract: 10,
  },
};

export const agency_all_rooms = [
  {
    room_id: 1,
    room_uid: 123123,
    thumbnail: 'https://via.placeholder.com/117',
    building_type: '빌라',
    name: '행복빌라', // 존재하면 (행복빌라)로 표시
    address: '서울시 구로구 구로동 123-1',
    // rooms
    dong: '', // 없을 경우 생략 가능
    ho: '101A',
    floor: 1, // 층
    room_type: '방1개', //one_room
    supply_area: 72.7, // 평 단위로 저장, 경우에 따라 제곱미터로 변환해서 사용. 일단 더미 데이터는 제곱미터로.
    deposit: 500, // 만원
    rent: 60, // 만원
    management_fee: 5, // 만원
    // agency memo
    memo: '욕실 수리 필요',
    suggested_lists: ['강바다', '강하늘'],
    // 물어볼 것
    status: '현재 공실',
  },
  {
    room_id: 2,
    room_uid: 123124,
    thumbnail: 'https://via.placeholder.com/117',
    building_type: '빌라',
    name: '행복빌라', // 존재하면 (행복빌라)로 표시
    address: '서울시 구로구 구로동 123-1',
    // rooms
    dong: '', // 없을 경우 생략 가능
    ho: '101B',
    floor: 1, // 층
    room_type: '방1개', //one_room
    supply_area: 72.7, // 평 단위로 저장, 경우에 따라 제곱미터로 변환해서 사용. 일단 더미 데이터는 제곱미터로.
    deposit: 500, // 만원
    rent: 60, // 만원
    management_fee: 5, // 만원
    // agency memo
    memo: '협의 가능',
    suggested_lists: ['강바다', '강하늘'],
    // 물어볼 것
    status: '현재 공실',
  },
  {
    room_id: 3,
    room_uid: 124125,
    thumbnail: 'https://via.placeholder.com/117',
    building_type: '빌라',
    name: '', // 존재하면 (행복빌라)로 표시
    address: '서울시 강서구 화곡로 335',
    // rooms
    dong: '', // 없을 경우 생략 가능
    ho: '101B',
    floor: 2, // 층
    room_type: '방2개', //two_room
    deposit: 550, // 만원
    rent: 50, // 만원
    management_fee: 7, // 만원
    // agency memo
    // memo: '메모입니다!',
    suggested_lists: ['이강찬', '강하늘'],
    // 물어볼 것
    status: '거래 완료',
  },
  {
    room_id: 4,
    room_uid: 124126,
    thumbnail: 'https://via.placeholder.com/117',
    building_type: '빌라',
    name: '', // 존재하면 (행복빌라)로 표시
    address: '서울시 강서구 화곡로 335',
    // rooms
    dong: '', // 없을 경우 생략 가능
    ho: '101C',
    floor: 2, // 층
    room_type: '방2개', //two_room
    deposit: 550, // 만원
    rent: 50, // 만원
    management_fee: 7, // 만원
    // agency memo
    // memo: '메모입니다!',
    suggested_lists: ['이강찬', '강하늘'],
    // 물어볼 것
    status: '거래 완료',
  },
];

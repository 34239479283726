import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Input,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { ModalDisclosureProps } from '../../common/Modals/base';
import { useUpdateRoom } from '../../../lib/admin/hooks/mutation';
import SelectBox from '../SelectBox/base';
import { CheckCircleIcon } from '../../../statics/common/icons';

interface CutDealModalProps {
  disabled?: boolean;
  room_id: string;
}

const CutDealModal: React.FC<CutDealModalProps> = ({ children, disabled = false, room_id }) => {
  const [date_1, setDate_1] = useState<string>('');
  const [date_2, setDate_2] = useState<string>('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { mutateAsync: updateRoom } = useUpdateRoom();
  console.log(room_id);

  const handleModalOpen = () => {
    if (!disabled) {
      onOpen();
    }
  };

  const onClickSubmitButton = async () => {
    const formData = new FormData();
    formData.append('contract_start_date', date_1);
    formData.append('contract_end_date', date_2);
    await updateRoom({
      formData,
      room_id,
    }).then(() => {
      onClose();
    });
  };

  const isAbleClickButton = (() => {
    return !!date_1 && !!date_2;
  })();

  return (
    <>
      <Box onClick={handleModalOpen}>{children}</Box>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
        size={'2xl'}
        motionPreset="slideInRight"
        isCentered
      >
        <ModalOverlay />
        <ModalContent px={8} pt={8} pb={5}>
          <ModalCloseButton />
          <Center flexDir={'column'} m={4}>
            <Flex align={'center'} w="full">
              <CheckCircleIcon boxSize={'34px'} mr={1} />
              <Box fontSize={'23px'}>거래완료(외부)</Box>
            </Flex>
            <Flex flexDir={'column'} w="full" fontSize={'18px'} pt={3}>
              <Box>해당 매물이 외부에서 거래가 완료되었나요?</Box>
              <Box>내부에서 거래가 완료되었을 경우, 요청 관리에서 거래완료해주세요.</Box>
            </Flex>
            <VStack
              w={'full'}
              spacing={4}
              bgColor={'lotionWhite'}
              px={4}
              py={4}
              my={4}
              alignItems={'center'}
              h={'100%'}
              border="1px solid"
              borderColor={'silverGray'}
              borderRadius={'md'}
              boxShadow={'sm'}
            >
              <HStack w={'full'} spacing={7}>
                <Center w={'120px'} textStyle={'medium16'}>
                  매물상태
                </Center>
                <HStack w={'full'}>
                  <Box>거래완료</Box>
                </HStack>
              </HStack>
              <HStack w={'full'} spacing={7}>
                <Center w={'120px'} textStyle={'medium16'}>
                  계약기간
                </Center>
                <Center w="full">
                  <Input
                    type={'date'}
                    variant={'white36'}
                    value={date_1}
                    onChange={(e) => setDate_1(e.target.value)}
                  ></Input>
                  <Box mx={3}>-</Box>
                  <Input
                    type={'date'}
                    variant={'white36'}
                    value={date_2}
                    onChange={(e) => setDate_2(e.target.value)}
                  ></Input>
                </Center>
              </HStack>
            </VStack>
            <Center mt={4}>
              <Button variant={'warning'} w={'130px'} mr={2}>
                취소
              </Button>
              <Button variant={'basic'} w={'130px'} isDisabled={!isAbleClickButton} onClick={onClickSubmitButton}>
                거래완료
              </Button>
            </Center>
          </Center>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CutDealModal;

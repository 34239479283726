import { Box, Button, Center, VStack } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { CheckCircleIcon } from '../../../../../statics/common/icons';

const Five = () => {
  return (
    <Center flexDir={'column'} h="full" m={10}>
      <motion.div
        // animate={{ scale: [0, 0.4, 0.6, 1] }} transition={{ times: [0, 0.4, 0.6, 1] }}
        initial={{
          scale: 0,
        }}
        animate={{
          scale: 1,
        }}
        transition={{
          duration: 1,
        }}
      >
        <CheckCircleIcon boxSize={'120px'} mb={'50px'} />
      </motion.div>
      <VStack spacing={0} fontSize={'25px'} pos={'relative'}>
        <motion.div
          animate={{
            opacity: 0,
          }}
          transition={{
            duration: 1.5,
          }}
          style={{
            position: 'absolute',
            top: 5,
          }}
        >
          <Box minW={'max'} fontWeight={500}>
            회원가입 신청 완료
          </Box>
        </motion.div>
        <motion.div
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
          transition={{
            duration: 0.5,
            delay: 1,
          }}
          style={{
            position: 'absolute',
          }}
        >
          <Link to={'/company/manage/dashboard'}>
            <Button variant={'basic'} w={'210px'} height={'44px'} fontSize={'18px'}>
              대시보드로 이동
            </Button>
          </Link>
        </motion.div>
      </VStack>
    </Center>
  );
};

export default Five;

import {
  Box,
  Button,
  Center,
  HStack,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  Textarea,
  useDisclosure,
} from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';
import { useConfirmCompany, useUpdateTenantLeaseContract } from '../../../../lib/admin/hooks/mutation';
import { CompanyManager } from '../../../../lib/common/utils/axios/types';
import { ClickIconContainer, LeftArrowIcon } from '../../../../statics/common/icons';

interface SupportPaymentApproveModalProps {
  lease_contract_id: string;
  user_name?: string;
}

const SupportPaymentApproveModal: React.FC<SupportPaymentApproveModalProps> = ({
  lease_contract_id,
  user_name,
  children,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { mutateAsync } = useUpdateTenantLeaseContract();

  return (
    <>
      <Box onClick={onOpen}>{children}</Box>

      <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside" size={'xl'} motionPreset="scale" isCentered>
        <ModalOverlay />
        <Formik
          initialValues={
            {
              // rooms: rooms.map((room) => {
              //   return {
              //     _id: room.room_id,
              //     admin_relay_memo: room.admin_secret_memo,
              //   };
              // }),
            }
          }
          onSubmit={async (values, { setSubmitting }) => {
            const formData = new FormData();
            formData.append('status', 'approved');
            await mutateAsync({
              lease_contract_id,
              formData,
            }).then(() => {
              onClose();
            });
          }}
          validationSchema={Yup.object().shape({})}
        >
          {({ values, errors, isValid, handleChange, setFieldValue }) => {
            return (
              // <Form style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>

              <Form>
                <ModalContent p={5}>
                  <ModalCloseButton autoFocus />
                  <Center flexDir={'column'} mx={6} my={8}>
                    <Text textStyle={'medium18'} textAlign={'center'}>
                      {user_name}님의 집정보가 확인이 완료되어 승인하시겠습니까? 승인완료 후에는 임직원에게 승인완료
                      알림이 전달됩니다.
                    </Text>
                    <Center w="full" mt={'30px'}>
                      <HStack>
                        <Button
                          variant={'warning'}
                          w={'140px'}
                          fontSize={'14px'}
                          fontWeight={'bold'}
                          onClick={() => onClose()}
                        >
                          취소
                        </Button>
                        <Button variant={'basic'} w={'140px'} fontSize={'14px'} fontWeight={'bold'} type="submit">
                          승인
                        </Button>
                      </HStack>
                    </Center>
                  </Center>
                </ModalContent>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

export default SupportPaymentApproveModal;

import {
  Box,
  Button,
  Center,
  HStack,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  Textarea,
  useDisclosure,
} from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';
import { useConfirmCompany } from '../../../../lib/admin/hooks/mutation';
import { CompanyManager } from '../../../../lib/common/utils/axios/types';
import { ClickIconContainer, LeftArrowIcon } from '../../../../statics/common/icons';
import * as Type from '../../../../types/common/CommonType';

interface ConfirmApproveCompanyModalProps {
  company_manager_data: Type.Company;
}

const ConfirmApproveCompanyModal: React.FC<ConfirmApproveCompanyModalProps> = ({ children, company_manager_data }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { name, master } = company_manager_data;
  const _id = master?._id;
  const { mutateAsync } = useConfirmCompany(_id);

  const [isRejectClicked, setIsRejectClicked] = useState<boolean>(false);
  const [rejectedReason, setRejectedReason] = useState<string>('');

  return (
    <>
      <Box onClick={onOpen}>{children}</Box>

      <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside" size={'xl'} motionPreset="scale" isCentered>
        <ModalOverlay />
        <Formik
          initialValues={{}}
          onSubmit={(values, { setSubmitting }) => {
            return;
          }}
          validationSchema={Yup.object().shape({})}
        >
          {({ values, errors, isValid, handleChange, setFieldValue }) => {
            console.log('values => ', values);
            return (
              <Form>
                <ModalContent p={5}>
                  <ModalCloseButton autoFocus />
                  {isRejectClicked ? (
                    <Center flexDir={'column'} m={10}>
                      <HStack>
                        <ClickIconContainer
                          clickableArea={7}
                          color="zetaBlue"
                          w={8}
                          h={8}
                          // onClick={() => setPageNum((prev) => (prev > 1 ? prev - 1 : 1))}
                          onClick={() => setIsRejectClicked(false)}
                        >
                          <LeftArrowIcon color="zetaBlue" />
                        </ClickIconContainer>
                        <Text fontSize={'20px'}>{name}의 회원가입 반려를 확정 하시겠습니까?</Text>
                      </HStack>
                      <Textarea
                        m={3}
                        placeholder="반려 사유를 입력해 주세요"
                        onChange={(e) => {
                          setRejectedReason(e.target.value);
                        }}
                      />
                      <Button
                        variant={'warning'}
                        w={'140px'}
                        fontSize={'14px'}
                        fontWeight={'bold'}
                        onClick={() => {
                          mutateAsync({
                            is_approved: false,
                            rejected_reason: rejectedReason,
                          }).then(() => {
                            onClose();
                          });
                        }}
                      >
                        반려 완료
                      </Button>
                    </Center>
                  ) : (
                    <Center flexDir={'column'} m={10}>
                      <Text fontSize={'20px'}>{name}의 회원가입을 승인하시겠습니까?</Text>
                      <Center w="full" mt={'30px'}>
                        <HStack>
                          <Button
                            variant={'warning'}
                            w={'140px'}
                            fontSize={'14px'}
                            fontWeight={'bold'}
                            onClick={() => {
                              setIsRejectClicked(true);
                            }}
                          >
                            반려
                          </Button>
                          <Button
                            variant={'basic'}
                            w={'140px'}
                            fontSize={'14px'}
                            fontWeight={'bold'}
                            //   autoFocus
                            onClick={() => {
                              mutateAsync({
                                is_approved: true,
                              }).then(() => {
                                onClose();
                              });
                            }}
                          >
                            승인
                          </Button>
                        </HStack>
                      </Center>
                    </Center>
                  )}
                </ModalContent>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

export default ConfirmApproveCompanyModal;

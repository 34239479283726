import { Button, useClipboard } from '@chakra-ui/react';
import { useState } from 'react';

interface CopyTextButtonProps {
  text: string;
  wordsBeforeCopy: string;
  wordsAfterCopy: string;
}

const CopyTextButton: React.FC<CopyTextButtonProps> = ({ text, wordsBeforeCopy, wordsAfterCopy }) => {
  //   const [value, setValue] = useState('Hello world');
  const { hasCopied, onCopy } = useClipboard(text);

  return (
    <Button onClick={onCopy} variant={'basic'}>
      {hasCopied ? wordsAfterCopy : wordsBeforeCopy}
    </Button>
  );
};
export default CopyTextButton;

import { Box, Center, Skeleton, Tooltip } from '@chakra-ui/react';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { UseQueryResult } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { QueryResponse } from '../../../lib/common/utils/axios/types';
import { currentParamsObject, unionTwoKeys } from '../../../lib/common/utils/base';
import ArrowButton from '../Button/arrowbutton';
import NumberButton from '../Button/numberbutton';
import PageListButton from '../Button/pagelistbutton';
import * as Type from '../../../types/common/CommonType';
import { default_items_limit_number } from '../../../lib/common/utils/values';

interface PaginationProps {
  pageRange?: number;
  res?: UseQueryResult<Type.QueryResponse<any>>;
  pseudoQueryKey?: string;
  pageInfo?: Type.PageInfo;
}

const Pagination: React.FC<PaginationProps> = ({ res, pageRange = 5, pseudoQueryKey, pageInfo }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  // const { page: page_param, limit: limit_param, search: search_param } = currentParamsObject();
  const baseQueryKey = 'page';
  const queryKey = unionTwoKeys(pseudoQueryKey, baseQueryKey);

  const page = res?.data?.page_info?.page || pageInfo?.page || 1;
  const limit = res?.data?.page_info?.limit || pageInfo?.limit || default_items_limit_number;
  const last_page = res?.data?.page_info?.last_page || 10;

  const pagelist = useMemo(() => {
    return Math.ceil(page / pageRange);
  }, [page, pageRange]);

  const is_first_page = page === 1;
  const is_last_page = page === last_page;
  const is_last_pageList = useMemo(() => {
    return Math.ceil(last_page / pageRange) === pagelist;
  }, [last_page, pageRange, pagelist]);

  const is_first_pageList = useMemo(() => {
    return Math.ceil(1 / pageRange) === pagelist;
  }, [pageRange, pagelist]);

  const onClickNumberButton = useCallback(
    (page_number: number) => {
      // if (searchParams.has(queryKey)) {
      searchParams.set(queryKey, page_number.toString());
      setSearchParams(searchParams);
      // }
    },
    [setSearchParams, searchParams],
  );

  const onClickLeftArrowButton = useCallback(() => {
    // if (searchParams.has(queryKey)) {
    const num_str = searchParams.get(queryKey) || '0';
    const num = parseInt(num_str);
    if (num > 1) {
      searchParams.set(queryKey, (num - 1).toString());
      setSearchParams(searchParams);
    }
    // }
  }, [setSearchParams, searchParams]);

  const onClickRightArrowButton = useCallback(() => {
    // if (searchParams.has(queryKey)) {
    const num_str = searchParams.get(queryKey) || '0';
    const num = parseInt(num_str);
    if (num < last_page) {
      searchParams.set(queryKey, (num + 1).toString());
      setSearchParams(searchParams);
    }
    // }
  }, [setSearchParams, searchParams, last_page]);

  const onClickRightPageListButton = useCallback(() => {
    const num_str = searchParams.get(queryKey) || '0';
    const num = parseInt(num_str);
    if (num + pageRange < last_page) {
      searchParams.set(queryKey, (num + pageRange).toString());
      setSearchParams(searchParams);
    } else {
      searchParams.set(queryKey, last_page.toString());
      setSearchParams(searchParams);
    }
  }, [last_page, pageRange, searchParams, setSearchParams]);

  const onClickLeftPageListButton = useCallback(() => {
    const num_str = searchParams.get(queryKey) || '0';
    const num = parseInt(num_str);
    if (num - pageRange > 1) {
      searchParams.set(queryKey, (num - pageRange).toString());
      setSearchParams(searchParams);
    } else {
      searchParams.set(queryKey, (1).toString());
      setSearchParams(searchParams);
    }
  }, [pageRange, searchParams, setSearchParams]);
  const firstRender = useRef(false);

  useEffect(() => {
    if (!firstRender.current) {
      null;
    }
  }, [limit]);

  useEffect(() => {
    if (firstRender.current) {
      null;
    } else {
      firstRender.current = true;
    }
  }, [page, pagelist, searchParams, setSearchParams]);

  return (
    <Skeleton isLoaded={!res?.isLoading} w="full">
      <Center>
        <ArrowButton isleft onClick={onClickLeftArrowButton} isDisabled={is_first_page} />
        {!is_first_pageList && (
          <>
            <NumberButton onClick={() => onClickNumberButton(1)} isCurrent={is_first_page}>
              {1}
            </NumberButton>
            <PageListButton isleft onClick={onClickLeftPageListButton} />
          </>
        )}
        {Array.from(Array(pageRange).keys()).map((value) => {
          const page_number = (pagelist - 1) * pageRange + value + 1;
          const is_current_page = page_number === page;
          const is_last_page = page_number === last_page;
          const is_over_last_page = page_number > last_page;
          return (
            !is_over_last_page && (
              <Tooltip label={'마지막 페이지!'} key={value} isDisabled={!is_last_page} hasArrow>
                <Box>
                  <NumberButton
                    key={value}
                    onClick={() => onClickNumberButton(page_number)}
                    isCurrent={is_current_page}
                  >
                    {page_number}
                  </NumberButton>
                </Box>
              </Tooltip>
            )
          );
        })}
        {!is_last_pageList && (
          <>
            <PageListButton isright onClick={onClickRightPageListButton} />
            <NumberButton onClick={() => onClickNumberButton(last_page)} isCurrent={is_last_page}>
              {last_page}
            </NumberButton>
          </>
        )}
        <ArrowButton isright onClick={onClickRightArrowButton} isDisabled={is_last_page} />
      </Center>
    </Skeleton>
  );
};

export default Pagination;

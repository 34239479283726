import { Box, ListItem, OrderedList, Text, UnorderedList, VStack } from '@chakra-ui/react';
import { GItem } from '../../../../../components/common/GridItem';

const ReturnPolicyPage = () => {
  return (
    <GItem>
      <Box m={5}>
        <Text fontWeight={'bold'} color="zetaBlue" fontSize={'20px'} mb={5}>
          구독 해지 및 환불 안내
        </Text>
        <VStack alignItems={'start'}>
          <VStack alignItems={'start'} spacing={3}>
            {/* 제1조 */}
            <VStack alignItems={'start'}>
              <Box>
                <VStack align={'start'} spacing={6} pl={1}>
                  <Text>
                    ① ‘고객사’가 서비스 구독 해지를 원하는 경우, 계약 만료시점 한 달 전까지 ‘공급사’에게 ‘관리자 웹’을
                    통해 통보해야 합니다.
                  </Text>
                  <Text>
                    ② ‘고객사’가 서비스 구독 해지를 원하는 시점에 한 명의 ‘이용자’라도 입주 계약이 남아있을 경우
                    입주계약이 끝나는 날까지 서비스 구독 해지를 할 수 없습니다.
                  </Text>
                  <Text>
                    ③ ‘보홈’ 구독 해지불가 사유에 해당하지 않아 구독해지가 승인이 된 경우, ‘고객사’가 서비스 해지 신청한
                    날을 기준으로 남은 구독기간에 대해 일할 계산하여 환불금을 책정하여 영업일 7일 이내에 ‘고객사’의
                    계좌로 입금 완료됩니다.
                  </Text>
                  <Text>
                    ④ 구독료 할인혜택을 받은 경우, 할인혜택을 제하고 구독플랜의 원래가격으로 일할 계산하여 환불금을
                    책정합니다.
                  </Text>
                  <Box>
                    <Text>⑤ 환불금 책정 계산식은 아래와 같습니다.</Text>
                    <Box ml={4}>
                      <Text>
                        - 구독 결제 후, 미사용시 7일 이내 전액 환불이 가능하며, 7일이 경과한 경우 수수료를 공제한 부분
                        환불이 가능합니다.
                      </Text>
                      <Text>
                        - 환불 시 환불액 : 결제금액-결제금액X(경과일수/전체이용기간)-수수료(환불 대상 상품 결제 금액의
                        10%)
                      </Text>
                    </Box>
                  </Box>
                </VStack>
              </Box>
            </VStack>
          </VStack>
        </VStack>
      </Box>
    </GItem>
  );
};

export default ReturnPolicyPage;

import { Global } from '@emotion/react';

const Fonts = () => (
  <Global
    styles={`

      /* ============================================================================ */
      /* ============ hairline: 100, light: 300, normal: 400, medium: 500 =========== */
      /* ============ bold: 700, black: 900 =========== *Chara-UI Theming*=========== */
      /* ============================================================================ */
      /* ============ Noto Sans CJK KR, 한글, 특수문자 =============================== */
      /* ============================================================================ */

      @font-face {
        font-family: 'Noto Sans CJK KR';
        font-style: normal;
        font-weight: 100;
        src: url("/fonts/kr/NotoSansCJKKR/NotoSansKR-Thin.woff2") format('woff2'),
        url("/fonts/kr/NotoSansCJKKR/NotoSansKR-Thin.woff") format('woff'),
        url("/fonts/kr/NotoSansCJKKR/NotoSansKR-Thin.otf") format('truetype');
      }

      @font-face {
        font-family: 'Noto Sans CJK KR';
        font-style: normal;
        font-weight: 300;
        src: url("/fonts/kr/NotoSansCJKKR/NotoSansKR-Light.woff2") format('woff2'),
        url("/fonts/kr/NotoSansCJKKR/NotoSansKR-Light.woff") format('woff'),
        url("/fonts/kr/NotoSansCJKKR/NotoSansKR-Light.otf") format('truetype');
      }

      @font-face {
        font-family: 'Noto Sans CJK KR';
        font-style: normal;
        font-weight: 400;
        src: url("/fonts/kr/NotoSansCJKKR/NotoSansKR-Regular.woff2") format('woff2'),
        url("/fonts/kr/NotoSansCJKKR/NotoSansKR-Regular.woff") format('woff'),
        url("/fonts/kr/NotoSansCJKKR/NotoSansKR-Regular.otf") format('truetype');
      }

      @font-face {
        font-family: 'Noto Sans CJK KR';
        font-style: normal;
        font-weight: 500;
        src: url("/fonts/kr/NotoSansCJKKR/NotoSansKR-Medium.woff2") format('woff2'),
        url("/fonts/kr/NotoSansCJKKR/NotoSansKR-Medium.woff") format('woff'),
        url("/fonts/kr/NotoSansCJKKR/NotoSansKR-Medium.otf") format('truetype');
      }

      @font-face {
        font-family: 'Noto Sans CJK KR';
        font-style: normal;
        font-weight: 700;
        src: url("/fonts/kr/NotoSansCJKKR/NotoSansKR-Bold.woff2") format('woff2'),
             url("/fonts/kr/NotoSansCJKKR/NotoSansKR-Bold.woff") format('woff'),
             url("/fonts/kr/NotoSansCJKKR/NotoSansKR-Bold.otf") format('truetype');
      }

      @font-face {
        font-family: 'Noto Sans CJK KR';
        font-style: normal;
        font-weight: 900;
        src: url("/fonts/kr/NotoSansCJKKR/NotoSansKR-Black.woff2") format('woff2'),
             url("/fonts/kr/NotoSansCJKKR/NotoSansKR-Black.woff") format('woff'),
             url("/fonts/kr/NotoSansCJKKR/NotoSansKR-Black.otf") format('truetype');
      }

      /* ============================================================================ */
      /* ===================== Roboto, 영문(대문자, 소문자), 숫자 ==================== */
      /* ============================================================================ */

      /* roboto-100 - latin */
      @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 100;
        src: url('/fonts/en/Roboto/roboto-v29-latin-100.eot'); /* IE9 Compat Modes */
        src: local(''),
             url('/fonts/en/Roboto/roboto-v29-latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
             url('/fonts/en/Roboto/roboto-v29-latin-100.woff2') format('woff2'), /* Super Modern Browsers */
             url('/fonts/en/Roboto/roboto-v29-latin-100.woff') format('woff'), /* Modern Browsers */
             url('/fonts/en/Roboto/roboto-v29-latin-100.ttf') format('truetype'), /* Safari, Android, iOS */
             url('/fonts/en/Roboto/roboto-v29-latin-100.svg#Roboto') format('svg'); /* Legacy iOS */
        unicode-range:U+0041-005A, U+0061-007A, U+0030-0039;     
      }
      /* roboto-100italic - latin */
      @font-face {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 100;
        src: url('/fonts/en/Roboto/roboto-v29-latin-100italic.eot'); /* IE9 Compat Modes */
        src: local(''),
             url('/fonts/en/Roboto/roboto-v29-latin-100italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
             url('/fonts/en/Roboto/roboto-v29-latin-100italic.woff2') format('woff2'), /* Super Modern Browsers */
             url('/fonts/en/Roboto/roboto-v29-latin-100italic.woff') format('woff'), /* Modern Browsers */
             url('/fonts/en/Roboto/roboto-v29-latin-100italic.ttf') format('truetype'), /* Safari, Android, iOS */
             url('/fonts/en/Roboto/roboto-v29-latin-100italic.svg#Roboto') format('svg'); /* Legacy iOS */
        unicode-range:U+0041-005A, U+0061-007A, U+0030-0039;     
      }
      /* roboto-300 - latin */
      @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        src: url('/fonts/en/Roboto/roboto-v29-latin-300.eot'); /* IE9 Compat Modes */
        src: local(''),
             url('/fonts/en/Roboto/roboto-v29-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
             url('/fonts/en/Roboto/roboto-v29-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
             url('/fonts/en/Roboto/roboto-v29-latin-300.woff') format('woff'), /* Modern Browsers */
             url('/fonts/en/Roboto/roboto-v29-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
             url('/fonts/en/Roboto/roboto-v29-latin-300.svg#Roboto') format('svg'); /* Legacy iOS */
        unicode-range:U+0041-005A, U+0061-007A, U+0030-0039;     
      }
      /* roboto-300italic - latin */
      @font-face {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 300;
        src: url('/fonts/en/Roboto/roboto-v29-latin-300italic.eot'); /* IE9 Compat Modes */
        src: local(''),
             url('/fonts/en/Roboto/roboto-v29-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
             url('/fonts/en/Roboto/roboto-v29-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
             url('/fonts/en/Roboto/roboto-v29-latin-300italic.woff') format('woff'), /* Modern Browsers */
             url('/fonts/en/Roboto/roboto-v29-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
             url('/fonts/en/Roboto/roboto-v29-latin-300italic.svg#Roboto') format('svg'); /* Legacy iOS */
        unicode-range:U+0041-005A, U+0061-007A, U+0030-0039;     
      }
      /* roboto-regular - latin */
      @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        src: url('/fonts/en/Roboto/roboto-v29-latin-regular.eot'); /* IE9 Compat Modes */
        src: local(''),
             url('/fonts/en/Roboto/roboto-v29-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
             url('/fonts/en/Roboto/roboto-v29-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
             url('/fonts/en/Roboto/roboto-v29-latin-regular.woff') format('woff'), /* Modern Browsers */
             url('/fonts/en/Roboto/roboto-v29-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
             url('/fonts/en/Roboto/roboto-v29-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
        unicode-range:U+0041-005A, U+0061-007A, U+0030-0039;     
      }
      /* roboto-italic - latin */
      @font-face {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 400;
        src: url('/fonts/en/Roboto/roboto-v29-latin-italic.eot'); /* IE9 Compat Modes */
        src: local(''),
             url('/fonts/en/Roboto/roboto-v29-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
             url('/fonts/en/Roboto/roboto-v29-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
             url('/fonts/en/Roboto/roboto-v29-latin-italic.woff') format('woff'), /* Modern Browsers */
             url('/fonts/en/Roboto/roboto-v29-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
             url('/fonts/en/Roboto/roboto-v29-latin-italic.svg#Roboto') format('svg'); /* Legacy iOS */
        unicode-range:U+0041-005A, U+0061-007A, U+0030-0039;     
      }
      /* roboto-500 - latin */
      @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        src: url('/fonts/en/Roboto/roboto-v29-latin-500.eot'); /* IE9 Compat Modes */
        src: local(''),
             url('/fonts/en/Roboto/roboto-v29-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
             url('/fonts/en/Roboto/roboto-v29-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
             url('/fonts/en/Roboto/roboto-v29-latin-500.woff') format('woff'), /* Modern Browsers */
             url('/fonts/en/Roboto/roboto-v29-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
             url('/fonts/en/Roboto/roboto-v29-latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
        unicode-range:U+0041-005A, U+0061-007A, U+0030-0039;     
      }
      /* roboto-500italic - latin */
      @font-face {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 500;
        src: url('/fonts/en/Roboto/roboto-v29-latin-500italic.eot'); /* IE9 Compat Modes */
        src: local(''),
             url('/fonts/en/Roboto/roboto-v29-latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
             url('/fonts/en/Roboto/roboto-v29-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
             url('/fonts/en/Roboto/roboto-v29-latin-500italic.woff') format('woff'), /* Modern Browsers */
             url('/fonts/en/Roboto/roboto-v29-latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
             url('/fonts/en/Roboto/roboto-v29-latin-500italic.svg#Roboto') format('svg'); /* Legacy iOS */
        unicode-range:U+0041-005A, U+0061-007A, U+0030-0039;     
      }
      /* roboto-700italic - latin */
      @font-face {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 700;
        src: url('/fonts/en/Roboto/roboto-v29-latin-700italic.eot'); /* IE9 Compat Modes */
        src: local(''),
             url('/fonts/en/Roboto/roboto-v29-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
             url('/fonts/en/Roboto/roboto-v29-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
             url('/fonts/en/Roboto/roboto-v29-latin-700italic.woff') format('woff'), /* Modern Browsers */
             url('/fonts/en/Roboto/roboto-v29-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
             url('/fonts/en/Roboto/roboto-v29-latin-700italic.svg#Roboto') format('svg'); /* Legacy iOS */
        unicode-range:U+0041-005A, U+0061-007A, U+0030-0039;     
      }
      /* roboto-700 - latin */
      @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        src: url('/fonts/en/Roboto/roboto-v29-latin-700.eot'); /* IE9 Compat Modes */
        src: local(''),
             url('/fonts/en/Roboto/roboto-v29-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
             url('/fonts/en/Roboto/roboto-v29-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
             url('/fonts/en/Roboto/roboto-v29-latin-700.woff') format('woff'), /* Modern Browsers */
             url('/fonts/en/Roboto/roboto-v29-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
             url('/fonts/en/Roboto/roboto-v29-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
        unicode-range:U+0041-005A, U+0061-007A, U+0030-0039;     
      }
      /* roboto-900 - latin */
      @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 900;
        src: url('/fonts/en/Roboto/roboto-v29-latin-900.eot'); /* IE9 Compat Modes */
        src: local(''),
             url('/fonts/en/Roboto/roboto-v29-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
             url('/fonts/en/Roboto/roboto-v29-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
             url('/fonts/en/Roboto/roboto-v29-latin-900.woff') format('woff'), /* Modern Browsers */
             url('/fonts/en/Roboto/roboto-v29-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
             url('/fonts/en/Roboto/roboto-v29-latin-900.svg#Roboto') format('svg'); /* Legacy iOS */
        unicode-range:U+0041-005A, U+0061-007A, U+0030-0039;     
      }
      /* roboto-900italic - latin */
      @font-face {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 900;
        src: url('/fonts/en/Roboto/roboto-v29-latin-900italic.eot'); /* IE9 Compat Modes */
        src: local(''),
             url('/fonts/en/Roboto/roboto-v29-latin-900italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
             url('/fonts/en/Roboto/roboto-v29-latin-900italic.woff2') format('woff2'), /* Super Modern Browsers */
             url('/fonts/en/Roboto/roboto-v29-latin-900italic.woff') format('woff'), /* Modern Browsers */
             url('/fonts/en/Roboto/roboto-v29-latin-900italic.ttf') format('truetype'), /* Safari, Android, iOS */
             url('/fonts/en/Roboto/roboto-v29-latin-900italic.svg#Roboto') format('svg'); /* Legacy iOS */
        unicode-range:U+0041-005A, U+0061-007A, U+0030-0039;     
      }
      `}
  />
);

export default Fonts;

import { Box, Collapse, Flex, HStack, StackDivider, Table, Tbody, Text, Tr, VStack } from '@chakra-ui/react';
import { Field, useFormikContext } from 'formik';
import { CustomTd } from '../type';
import { SingleDatepicker } from '../../../../Calender';
import { FCheckboxButtonGroup } from '../../../../Checkbox/base';
import { CustomInput } from '../../../../Input';
import { FRadioButtonGroup } from '../../../../Radio';
import { common_items_checkbox, individual_items_checkbox } from '../../../../../../statics/common/data/checkbox';
import { move_in_radio } from '../../../../../../statics/common/data/radio';
import { contract_period } from '../../../../../../statics/common/data/selectbox';
import SelectBox from '../../../../../agent/SelectBox/base';

const PriceInfoTable = () => {
  const formik = useFormikContext<any>();
  const { setFieldValue, values } = formik;
  return (
    <>
      <Table w={'100%'} variant="simple">
        <Tbody w={'100%'}>
          <Tr w={'100%'}>
            <CustomTd title={'가격 정보'} required ContentTdProps={{ colSpan: 4 }}>
              <VStack alignItems={'flex-start'} divider={<StackDivider borderColor="gray.200" />}>
                <HStack w="100%">
                  <Text minW="75px">보증금</Text>
                  <Flex w={'100px'} minW={'100px'}>
                    <Field name="deposit" as={CustomInput} bgColor="lotionWhite" addon="원" />
                  </Flex>
                </HStack>
                <HStack w="100%">
                  <Text minW="75px">월세</Text>
                  <Flex w={'100px'} minW={'100px'}>
                    <Field name="rent" as={CustomInput} bgColor="lotionWhite" addon="원" />
                  </Flex>
                </HStack>
              </VStack>
            </CustomTd>
          </Tr>
          <Tr w={'100%'}>
            <CustomTd title={'공용 관리비'} required>
              <Flex w={'100px'} minW={'100px'}>
                <Field name="management_fee" as={CustomInput} bgColor="lotionWhite" addon="원" />
              </Flex>
            </CustomTd>
            <CustomTd title={'공용 관리비 항목'}>
              <Flex w={'100%'}>
                <Field name="common_items" as={FCheckboxButtonGroup} options={common_items_checkbox} />
              </Flex>
            </CustomTd>
          </Tr>
          <Tr w={'100%'}>
            <CustomTd title={'개별 사용료 항목'} ContentTdProps={{ colSpan: 4 }}>
              <Field name="individual_items" as={FCheckboxButtonGroup} options={individual_items_checkbox} />
            </CustomTd>
          </Tr>
          <Tr w={'100%'}>
            <CustomTd title={'입주 가능일'} required>
              <Flex w={'100%'}>
                <Field name="move_in.status" as={FRadioButtonGroup} options={move_in_radio} />
              </Flex>
              {/* {move_in_status === 'direct' && <Box>aef</Box>} */}
              <Collapse in={values.move_in.status === 'direct'}>
                <HStack w={'100%'} mt={2}>
                  {/* <Flex w="full"> */}
                  <SingleDatepicker
                    date={values.move_in.date as any}
                    // 준공일 기입 안 할시 무슨 데이터? '' Null?
                    onDateChange={(date) => {
                      setFieldValue('move_in.date', date);
                    }}
                    propsConfigs={{
                      inputProps: {
                        bgColor: 'lotionWhite',
                        size: 'sm',
                        borderRadius: 'md',
                        borderColor: 'silverGray',
                        color: 'highGray',
                        minW: '120px',
                        w: '120px',
                      },
                      dateNavBtnProps: {
                        color: 'highGray',
                        _focus: {},
                      },
                    }}
                  />
                  {/* </Flex> */}
                  <Box minW={'86px'} w={'86px'}>
                    이후 입주 가능
                  </Box>
                </HStack>
              </Collapse>
            </CustomTd>
            <CustomTd title={'최소 계약 기간'}>
              <Field
                name="minimum_contract_month"
                as={SelectBox}
                placeholder="선택"
                w={'30%'}
                h="32px"
                bgColor="lotionWhite"
              >
                {contract_period.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}개월
                  </option>
                ))}
              </Field>
            </CustomTd>
          </Tr>
        </Tbody>
      </Table>
    </>
  );
};

export default PriceInfoTable;

import {
  Box,
  BoxProps,
  NumberInputProps,
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderProps,
  RangeSliderThumb,
  RangeSliderTrack,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { buildSearchParams, currentParamsObject, parseJsonToObj } from '../../../../../lib/common/utils/base';
import { staticThreeCommafy } from '../../../../../lib/common/utils/digitformatter';

interface GlobalRangeSliderProps extends RangeSliderProps {
  param_key: string;
  initial_minmax: {
    min: number;
    max: number;
  };
  labels?: Partial<{
    left_bottom: Partial<{
      style: BoxProps;
      value: any;
    }>;
    center_bottom: Partial<{
      style: BoxProps;
      value: any;
    }>;
    right_bottom: Partial<{
      style: BoxProps;
      value: any;
    }>;
    left_top: Partial<{
      style: BoxProps;
      value: any;
    }>;
    center_top: Partial<{
      style: BoxProps;
      value: any;
    }>;
    right_top: Partial<{
      style: BoxProps;
      value: any;
    }>;
  }>;
}

export const GlobalRangeSlider: React.FC<GlobalRangeSliderProps> = ({
  param_key,
  initial_minmax,
  children,
  labels,
  ...props
}) => {
  const navigate = useNavigate();
  const { ...paramsprops } = currentParamsObject(false);

  const minmax_param = parseJsonToObj(paramsprops[param_key]);

  const [minmax, setMinmax] = useState({
    min: minmax_param.min ? minmax_param.min : initial_minmax.min,
    max: minmax_param.max ? minmax_param.max : initial_minmax.max,
  });
  const isFirstRender = useRef(true);

  const isEqualMax = minmax.max === initial_minmax.max;

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      const str_minmax = JSON.stringify({
        min: minmax.min,
        max: isEqualMax ? 'Infinity' : minmax.max,
      });

      const searchParams = buildSearchParams({
        page: '1',
        pagelist: '1',
        [param_key]: str_minmax,
        // [param_key]: JSON.stringify(minmax),
      });
      navigate(searchParams);
    }
  }, [minmax]);

  const label_common_style = {
    pos: 'absolute',
    w: 'fit-content',
    fontSize: '14px',
  } as BoxProps;

  const labelsObj = {
    left_bottom: {
      style: {
        ...label_common_style,
        left: '0',
        top: '15px',
        ...labels?.left_bottom?.style,
      } as BoxProps,
      value: labels?.left_bottom?.value ? labels?.left_bottom.value : initial_minmax.min,
    },
    center_bottom: {
      style: {
        ...label_common_style,
        left: '50%',
        top: '15px',
        transform: 'translate(-50%, 0)',
        ...labels?.center_bottom?.style,
      } as BoxProps,
      value: labels?.center_bottom?.value ? labels?.center_bottom.value : (initial_minmax.min + initial_minmax.max) / 2,
    },
    right_bottom: {
      style: {
        ...label_common_style,
        right: '0',
        top: '15px',
        ...labels?.right_bottom?.style,
      } as BoxProps,
      value: labels?.right_bottom?.value ? labels?.right_bottom.value : initial_minmax.max,
    },
    left_top: {
      style: {
        ...label_common_style,
        left: '0',
        bottom: '15px',
        ...labels?.left_top?.style,
      } as BoxProps,
      value: labels?.left_top?.value ? labels?.left_top.value : '최소',
    },
    center_top: {
      style: {
        ...label_common_style,
        left: '50%',
        bottom: '15px',
        transform: 'translate(-50%, 0)',
        ...labels?.center_top?.style,
      } as BoxProps,
      value: labels?.center_top?.value ? labels?.center_top.value : '',
    },
    right_top: {
      style: {
        ...label_common_style,
        right: '0',
        bottom: '15px',
        ...labels?.right_top?.style,
      } as BoxProps,
      value: labels?.right_top?.value ? labels?.right_top.value : '최대',
    },
  };

  return (
    <RangeSlider
      aria-label={[`${param_key}-min`, `${param_key}-max`]}
      colorScheme="blue"
      defaultValue={[minmax.min, minmax.max]}
      min={initial_minmax.min}
      max={initial_minmax.max}
      onChange={(value) => {
        const [min, max] = value;
        setMinmax({
          min,
          max,
        });
      }}
      my={'24px'}
      step={(initial_minmax.min + initial_minmax.max) / 40}
      {...props}
    >
      <RangeSliderTrack>
        <RangeSliderFilledTrack />
      </RangeSliderTrack>
      <Box w="full">
        {Object.values(labelsObj).map((label, index) => {
          return (
            <Box {...label.style} key={index}>
              {label.value}
              {/* {Infinity} */}
            </Box>
          );
        })}
      </Box>
      <RangeSliderThumb index={0}>
        {/* <Box {...label_common_style} top={'15px'} fontSize={'14px'} color={'gray'}>
          {staticThreeCommafy(minmax.min)}원
        </Box> */}
      </RangeSliderThumb>
      <RangeSliderThumb index={1}>
        {/* <Box {...label_common_style} top={'15px'} fontSize={'14px'} color={'gray'}>
          {staticThreeCommafy(minmax.max)}원
        </Box> */}
      </RangeSliderThumb>
    </RangeSlider>
  );
};

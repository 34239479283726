import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  HStack,
  Skeleton,
  Table,
  Tbody,
  Text,
  Tr,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { isUndefined } from '@chakra-ui/utils';
import { Field, Form, Formik } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';
import { CustomInput } from '../../../../../../../../components/common/Input';
import QueryParamsLayout from '../../../../../../../../layouts/common/main/QueryParamsLayout';
import AddressFinder from '../../../../../../../../components/common/Modals/AddressFinder';
import { TdFullWContainer } from '../../../../../../../../components/common/Table/Tr';
import { useGetCompanyAllManagers, useGetCompanyAllPayments } from '../../../../../../../../lib/admin/hooks/query';
import { bankNameTrans, currentParamsObject, ToAllFilledObj } from '../../../../../../../../lib/common/utils/base';
import { FaPlusInCircle } from '../../../../../../../../statics/common/icons';
import RegisterManualBillModal from '../../../../../../../../components/common/Modals/RegisterManualBillModal';
import SubscriptionSettlementsTable from '../../../../../../../../components/admin/Table/SubscriptionTable/table';
import Loading from '../../../../../../../../components/common/Loading';

const Lsettlement = () => {
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  // const [searchParams, setSearchParams] = useSearchParams();
  // const id = searchParams.get('id');
  const { page, limit, id } = currentParamsObject(false);
  const _params = currentParamsObject();
  const params = ToAllFilledObj(_params);
  const { data: cpManagersData } = useGetCompanyAllManagers(id || '');
  const res = useGetCompanyAllPayments(id || '', {
    axiosConfig: {
      params,
    },
  });
  const { data: cpPaymentsData } = res;

  if (isUndefined(cpManagersData) || isUndefined(cpPaymentsData)) {
    return <Skeleton w={'full'} h="full" />;
  }

  console.log('res =>', res);

  const {
    master: {
      user_name: r_user_name,
      department: r_department,
      position: r_position,
      email: r_email,
      phone_number_first: r_phone_number_first,
    },
    name,
    company_code,
    business_type,
    business_registration_number,
    business_status,
    business_item,
    capital,
    employee_number,
    establishment_date,
    address,
    detail_address,
    digital_tax_invoice_email,
    business_certificate,
    logo_url,
    managers,
    createdAt,
    house_hunting_account,
  } = cpManagersData;

  console.log('house_hunting_account =>', house_hunting_account);
  const { isLoading } = res;
  if (isLoading) return <Loading />;
  return (
    <Formik
      initialValues={{
        account_number: house_hunting_account?.account_number,
        account_owner_name: house_hunting_account?.account_owner_name,
        bank_code: house_hunting_account?.bank_code,
        deposit_amount: house_hunting_account?.control.deposit_amount,
        deposit_end_date: house_hunting_account?.control.deposit_end_date,
      }}
      validationSchema={Yup.object().shape({})}
      onSubmit={(values, { setSubmitting }) => {
        const account_number = values?.account_number;
        const account_owner_name = values?.account_owner_name;
        const bank_code = values?.bank_code;
        setSubmitting(true);
        const formData = new FormData();
      }}
    >
      {(props) => {
        const isNotAnyChange = props.initialValues === props.values;

        const { errors, values } = props;
        return (
          <Form id="form-a" style={{ width: '100%', height: '100%' }}>
            <Flex w={'100%'} h="full">
              <VStack justifyContent={'start'} w={'100%'} h="full" spacing={6}>
                {/* 가상계좌 정보 */}
                <VStack spacing={3} justifyContent={'start'} align={'flex-start'} w={'100%'}>
                  <HStack w={'full'} spacing={0} justify={'space-between'}>
                    <Box fontWeight={'bold'} fontSize={'18px'} textAlign={'start'} w={'112px'}>
                      가상계좌 정보
                    </Box>
                    {/* <ChatButton userId={id} /> */}
                    {isEditable ? (
                      <ButtonGroup variant="outline">
                        <Button
                          variant={'basic'}
                          form="form-a"
                          type="submit"
                          isLoading={props.isSubmitting}
                          isDisabled={!props.isValid || isNotAnyChange}
                        >
                          수정 완료
                        </Button>
                        <Button
                          variant={'gray'}
                          _hover={{ bgColor: 'red', borderColor: 'red' }}
                          onClick={() => {
                            props.handleReset();
                            setIsEditable((prev) => !prev);
                          }}
                          autoFocus
                          isDisabled={props.isSubmitting}
                        >
                          수정 취소
                        </Button>
                      </ButtonGroup>
                    ) : (
                      <Button variant={'warning'} isDisabled onClick={() => setIsEditable((prev) => !prev)}>
                        수정
                      </Button>
                    )}
                  </HStack>
                  <Table w={'100%'} variant="simple">
                    <Tbody w={'100%'}>
                      <Tr w={'100%'} borderTopWidth={'1px'} borderTopColor={'gainGray'}>
                        <TdFullWContainer title={'계좌 소유주명'}>
                          {isEditable ? (
                            <Field name="account_owner_name" as={CustomInput} isInvalid={!!errors.account_owner_name} />
                          ) : (
                            <Text>{values?.account_owner_name ? `보홈 ${values?.account_owner_name}` : '-'}</Text>
                          )}
                        </TdFullWContainer>
                        <TdFullWContainer title={'이용자'}>
                          {isEditable ? (
                            <Field name="account_owner_name" as={CustomInput} isInvalid={!!errors.account_owner_name} />
                          ) : (
                            <Text>{values?.account_owner_name || '-'}</Text>
                          )}
                        </TdFullWContainer>
                        <TdFullWContainer title={'은행'}>
                          {isEditable ? (
                            <Field name="bank_code" as={CustomInput} isInvalid={!!errors.bank_code} />
                          ) : (
                            <Text>{values?.bank_code ? bankNameTrans(values?.bank_code) : '-'}</Text>
                          )}
                        </TdFullWContainer>
                      </Tr>
                      <Tr w={'100%'} borderTopWidth={'1px'} borderTopColor={'gainGray'}>
                        <TdFullWContainer title={'계좌 번호'}>
                          {isEditable ? (
                            <Field name="account_number" as={CustomInput} isInvalid={!!errors.account_number} />
                          ) : (
                            <Text>{values?.account_number || '-'}</Text>
                          )}
                        </TdFullWContainer>
                        <TdFullWContainer title={'제어 금액'}>
                          {isEditable ? (
                            <Field name="deposit_amount" as={CustomInput} isInvalid={!!errors.deposit_amount} />
                          ) : (
                            <Text>{values.deposit_amount ? values.deposit_amount + '원' : '-'}</Text>
                          )}
                        </TdFullWContainer>
                        <TdFullWContainer title={''}></TdFullWContainer>
                      </Tr>
                    </Tbody>
                    {/* <Field name={'start_date'} as={Input} type={'datetime-local'} /> */}
                  </Table>
                </VStack>
                {/* 정산 내역 */}
                <VStack spacing={3} justifyContent={'start'} align={'flex-start'} w={'100%'} h="full">
                  <Box fontWeight={'bold'} fontSize={'18px'} textAlign={'start'} w={'112px'}>
                    정산 관리
                  </Box>
                  <QueryParamsLayout
                    res={res}
                    modalOnLayout={
                      // 수동 청구서 등록하기
                      <Box pos={'absolute'} right={0} bottom={0}>
                        <RegisterManualBillModal disabled={false}>
                          <FaPlusInCircle />
                        </RegisterManualBillModal>
                      </Box>
                    }
                    removeTopBar
                  >
                    <SubscriptionSettlementsTable res={res} />
                  </QueryParamsLayout>
                </VStack>
              </VStack>
            </Flex>
            <AddressFinder isOpen={isOpen} onClose={onClose} formik={props} />
          </Form>
        );
      }}
    </Formik>
  );
};

export default Lsettlement;

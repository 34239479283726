import { Box, HStack, Skeleton, Table, Tbody, Td, Text, Th, Thead, Tr, VStack } from '@chakra-ui/react';
import { FaPlus } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import QueryParamsLayout from '../../../../../../../../layouts/common/main/QueryParamsLayout';
import TextLink from '../../../../../../../../components/common/Link/TextLink';
import { useGetAllCompaniesHosts, useGetHostAllLeaseContracts } from '../../../../../../../../lib/admin/hooks/query';
import { currentParamsObject, ToAllFilledObj } from '../../../../../../../../lib/common/utils/base';
import { CompanyTypeTrans } from '../../../../../../../../lib/subscriber/utils/utils';
import { datedotformat, threetwofiveDashfy } from '../../../../../../../../lib/common/utils/dateformatter';
import { decimalize, phoneNumberdashfy } from '../../../../../../../../lib/common/utils/digitformatter';
import RegisterNewHostModal from '../../../../../../../../components/common/Modals/RegisterNewHostModal';
import Loading from '../../../../../../../../components/common/Loading';

const Contract = () => {
  const { search: srch } = location;
  const { page, limit, id } = currentParamsObject(false);

  const _params = currentParamsObject();
  const params = ToAllFilledObj(_params);

  const res = useGetHostAllLeaseContracts(id, {
    axiosConfig: {
      params,
    },
  });

  const { isLoading } = res;
  if (isLoading) return <Loading />;
  return (
    <Box w="full" h="calc(100vh - 320px)">
      <Box mb={2}>
        <Skeleton isLoaded={!isLoading}>
          <Text textStyle={'bold18'}>{`체결한 계약 (${decimalize(res.data?.total)}건)`}</Text>
        </Skeleton>
      </Box>
      <QueryParamsLayout res={res}>
        <Table variant="head_silverbg_toplining">
          <Thead bgColor={'#F8F8F8'} borderColor={'gray.100'}>
            <Tr>
              <Th>계약 체결일</Th>
              <Th>임차인명</Th>
              <Th>계약 기간</Th>
              <Th>주소</Th>
              <Th>매물번호</Th>
              <Th>계약서 조회</Th>
            </Tr>
          </Thead>
          <Tbody>
            {res.data?.result.map((contract, index) => {
              const {
                _id: contract_id,
                contract_created_date,
                contract_start_date,
                contract_end_date,
                lease_contract_pdfs,
                tenant,
                room,
              } = contract;
              return (
                <Tr key={srch + index}>
                  <Td>{datedotformat(contract_created_date)}</Td>
                  <Td
                    cursor="pointer"
                    textDecorationLine={'underline'}
                    textUnderlineOffset={'1px'}
                    _hover={{ color: 'zetaBlue' }}
                    transition={'0.18s linear'}
                  >
                    <Link to={`/admin/manage/customers/user/detail/linfo?id=${tenant._id}`}>
                      <Text>{tenant.user_name}</Text>
                    </Link>
                  </Td>
                  <Td>
                    <Text>{datedotformat(contract_start_date) + '~' + datedotformat(contract_end_date)}</Text>
                  </Td>
                  <Td>
                    <Text minW={'max'} textAlign={'start'}>
                      {room?.building?.address} {room?.ho && `${room?.ho}호`} {room?.dong && `${room?.dong}동`}
                    </Text>
                  </Td>
                  <Td>
                    {/* /admin/manage/room?Info/63184c6885a54e1a49f7d70c */}
                    <TextLink href={`/admin/manage/roomInfo/${room?._id}`} target={'_blank'} minW={'max'}>
                      {room?.code}
                    </TextLink>
                  </Td>
                  <Td>
                    {lease_contract_pdfs.length === 0 ? (
                      <Text color={'gray'}>등록된 계약서가 없습니다.</Text>
                    ) : (
                      <VStack>
                        {lease_contract_pdfs.map((url, index) => (
                          <TextLink key={index} href={url} target={'_blank'} minW={'max'}>
                            {`계약서 ${index + 1}`}
                          </TextLink>
                        ))}
                      </VStack>
                    )}
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </QueryParamsLayout>
    </Box>
  );
};
export default Contract;

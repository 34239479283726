import { Box } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { isFile } from '../../../lib/common/utils/base';
import { DeleteCircle } from '../../../statics/common/icons';
import TextLink from '../Link/TextLink';

interface TextLinkFileThumbProps {
  file: File;
  removed?: boolean;
}
const TextLinkFileThumb: React.FC<TextLinkFileThumbProps> = ({ file, removed, children }) => {
  const [url, setUrl] = useState('');
  useEffect(() => {
    setUrl(URL.createObjectURL(file));
  }, []);
  return (
    <TextLink href={url} target={'_blank'} fontSize={'14px'} opacity={removed ? 0.4 : 1}>
      {children}
    </TextLink>
  );
};

export default TextLinkFileThumb;

import { Checkbox, CheckboxProps } from '@chakra-ui/react';
import { isEmptyArray } from 'formik';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { buildSearchParams, currentParamsObject, parseJsonToArr } from '../../../../../lib/common/utils/base';

interface GlobalStatusCheckboxesProps extends CheckboxProps {
  param_key: string;
  param_arr_value: string;
}

export const GlobalStatusCheckboxes: React.FC<GlobalStatusCheckboxesProps> = ({
  param_key,
  param_arr_value,
  children,
  ...props
}) => {
  const navigate = useNavigate();
  const { ...paramsprops } = currentParamsObject(false);

  const onClickCheckbox = () => setIsChecked((prev) => !prev);

  const param_existing_arr: Array<any> = parseJsonToArr(paramsprops[param_key]);
  const is_filled_arr = !_.isEmpty(param_existing_arr);
  const is_exist_item = param_existing_arr.includes(param_arr_value);
  const [isChecked, setIsChecked] = useState(is_exist_item);
  const isFirstRender = useRef(true);

  useEffect(() => {
    const param_new_value = param_arr_value;
    let value: any = '';

    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      if (isChecked) {
        if (is_filled_arr) {
          value = isEmptyArray([...param_existing_arr, param_new_value])
            ? []
            : JSON.stringify([...param_existing_arr, param_new_value]);
        } else {
          value = isEmptyArray([param_new_value]) ? [] : JSON.stringify([param_new_value]);
        }
      } else {
        const filtered_arr = param_existing_arr.filter((item) => item !== param_new_value);
        value = isEmptyArray(filtered_arr) ? [] : JSON.stringify(filtered_arr);
      }
      const searchParams = buildSearchParams({
        page: '1',
        pagelist: '1',
        [param_key]: value,
      });
      navigate(searchParams);
    }
  }, [isChecked]);

  return (
    <Checkbox onChange={onClickCheckbox} isChecked={isChecked} size={'smd14'} {...props}>
      {children}
    </Checkbox>
  );
};

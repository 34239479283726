import { Box, Checkbox, HStack, StackDivider, Table, Tbody, Tr, VStack } from '@chakra-ui/react';
import { Field, useFormikContext } from 'formik';
import { useState } from 'react';
import { CustomTd } from '../type';
import { FCheckboxButtonGroup } from '../../../../Checkbox/base';
import { FRadioButtonGroup } from '../../../../Radio';
import {
  additional_options_checkbox,
  default_options_checkbox,
  share_facilities_options_checkbox,
} from '../../../../../../statics/common/data/checkbox';
import { is_parking_radio, is_pet_radio, is_security_window_radio } from '../../../../../../statics/common/data/radio';

const OptionTable = () => {
  const [is_defaults_checked, setIs_defaults_checked] = useState<boolean>(false);
  const formik = useFormikContext<any>();
  const { setFieldValue, values } = formik;
  const isColiving = values.building_form === 'coliving_house';
  const isChecked = values.default_options.length === 6;

  return (
    <Table w={'100%'} variant="simple">
      <Tbody w={'100%'}>
        <Tr w={'100%'}>
          <CustomTd title={'기본 옵션'}>
            <HStack w="100%" spacing={4}>
              <Field name="default_options" as={FCheckboxButtonGroup} options={default_options_checkbox} />
              <Checkbox
                wordBreak={'keep-all'}
                size="sm"
                isChecked={isChecked}
                onChange={(e) => {
                  // setIs_defaults_checked((prev) => !prev);
                  if (isChecked) {
                    setFieldValue('default_options', []);
                  } else {
                    setFieldValue('default_options', [
                      'washing_machine',
                      'air_conditioner',
                      'refrigerator',
                      'sink',
                      'gas_range',
                      'induction',
                    ]);
                  }
                }}
              >
                모두 포함
              </Checkbox>
            </HStack>
          </CustomTd>
        </Tr>
        <Tr w={'100%'}>
          <CustomTd title={'추가 옵션'}>
            <Field name="additional_options" as={FCheckboxButtonGroup} options={additional_options_checkbox} />
          </CustomTd>
        </Tr>
        <Tr w={'100%'}>
          <CustomTd title={'시설 옵션'} required>
            <VStack divider={<StackDivider borderColor="gray.200" />} w={'100%'} alignItems={'flex-start'}>
              <HStack>
                <Box wordBreak={'keep-all'} w={'100px'}>
                  방범창
                </Box>
                <Field name="is_security_window" as={FRadioButtonGroup} options={is_security_window_radio} />
              </HStack>
              <HStack>
                <Box wordBreak={'keep-all'} w={'100px'}>
                  주차 여부
                </Box>
                <Field name="is_parking_r" as={FRadioButtonGroup} options={is_parking_radio} />
              </HStack>
              <HStack>
                <Box wordBreak={'keep-all'} w={'100px'}>
                  반려 동물
                </Box>
                <Field name="is_pet" as={FRadioButtonGroup} options={is_pet_radio} />
              </HStack>
            </VStack>
          </CustomTd>
        </Tr>
        {isColiving && (
          <Tr w={'100%'}>
            <CustomTd title={'공용 공간'} required>
              <Field name="share_facilities" as={FCheckboxButtonGroup} options={share_facilities_options_checkbox} />
            </CustomTd>
          </Tr>
        )}
      </Tbody>
    </Table>
  );
};

export default OptionTable;

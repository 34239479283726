import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { useChangeSubscriptionContractPaymentStatus } from '../../../../lib/admin/hooks/mutation';
import { ContractPaymentStatus } from '../../../../lib/common/utils/axios/types';
import { contract_status } from '../../../../statics/common/data/selectbox';
import SelectBox from '../../../agent/SelectBox/base';

interface ChangeContractPaymentStatusProps {
  payment_status: ContractPaymentStatus;
  subscription_contract_id: string;
}

const ChangeContractPaymentStatus: React.FC<ChangeContractPaymentStatusProps> = ({
  payment_status,
  subscription_contract_id,
}) => {
  const { mutate } = useChangeSubscriptionContractPaymentStatus();
  const [value, setValue] = useState<ContractPaymentStatus>(payment_status);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();

  const handleChangeOption = (e: any) => {
    setValue(e.target.value);
    onOpen();
  };

  const handleFinalize = () => {
    switch (value as ContractPaymentStatus) {
      case 'confirm_expected':
        mutate({
          status_path: 'confirm-expected',
          subscription_contract_id,
        });
        break;
      case 'sign_expected':
        mutate({
          status_path: 'sign-expected',
          subscription_contract_id,
        });
        break;
      case 'total_contract_payment_expected':
        mutate({
          status_path: 'total-contract-payment-expected',
          subscription_contract_id,
        });
        break;
      case 'subscription_payment_expected':
        mutate({
          status_path: 'subscription-payment-expected',
          subscription_contract_id,
        });
        break;
      default:
        break;
    }
    onClose();
  };

  const handleCloseAlert = () => {
    setValue(payment_status);
  };

  const handleCancle = () => {
    handleCloseAlert();
    onClose();
  };
  return (
    <>
      <SelectBox
        value={payment_status}
        onChange={(e) => {
          handleChangeOption(e);
        }}
      >
        {contract_status.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </SelectBox>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef.current}
        onClose={onClose}
        isCentered
        onEsc={handleCloseAlert}
        onOverlayClick={handleCloseAlert}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              재확인
            </AlertDialogHeader>

            <AlertDialogBody>확정하시겠습니까?</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef.current} variant={'gray'} onClick={handleCancle}>
                취소
              </Button>
              <Button colorScheme="red" onClick={handleFinalize} variant={'basic'} ml={3}>
                확정하기
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default ChangeContractPaymentStatus;

import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  ListItem,
  Skeleton,
  StackDivider,
  Text,
  UnorderedList,
  VStack,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { useGetCompanyContract } from '../../../../../lib/subscriber/hooks/query';
import { PlanCardBox } from '../../../../../components/subscriber/Card/PlanCard/PlanCard';

// 가입 승인 전(대기중)
const Zero = () => {
  return (
    <HStack h={'auto'} w="full" spacing={4}>
      {/* 베이직 */}
      <PlanCardBox>
        <VStack divider={<StackDivider borderColor={'#E5E5E5'} />}>
          <VStack spacing={5}>
            <Center mt={3}>
              <Box w={'8px'} h={'25px'} borderRadius={'md'} bgColor={'basicPlan'} mr={3} />
              <Text fontSize={'20px'} fontWeight={700}>{`베이직`}</Text>
            </Center>
            <Text fontSize={'14px'} fontWeight={500} textAlign={'center'} w={'240px'}>
              보증금 지원 효과를 원하신다면 추천드리는 기본 플랜
            </Text>
            <HStack pb={3}>
              <Text color={'mountainGreen'} fontSize={'20px'} fontWeight={500}>
                5만원/월
              </Text>
              <Text color={'#A0A0A0'} fontSize={'14px'} fontWeight={500}>
                (1인 기준)
              </Text>
            </HStack>
          </VStack>
          <VStack>
            <HStack spacing={0} my={2}>
              <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M2.70825 11.375C2.70825 9.2809 4.40584 7.58331 6.49992 7.58331C8.594 7.58331 10.2916 9.2809 10.2916 11.375"
                  stroke="#414141"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6.49992 5.95833C7.69654 5.95833 8.66659 4.98828 8.66659 3.79167C8.66659 2.59505 7.69654 1.625 6.49992 1.625C5.3033 1.625 4.33325 2.59505 4.33325 3.79167C4.33325 4.98828 5.3033 5.95833 6.49992 5.95833Z"
                  stroke="#414141"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <Text fontSize={'12px'} fontWeight={500}>
                최소 1인부터 옵션 선택 가능
              </Text>
            </HStack>
            <HStack alignItems={'flex-start'} pb={5}>
              <HStack alignItems={'flex-start'} h={'100px'}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M4 12.0001L8.94975 16.9499L19.5563 6.34326"
                    stroke="#29CC97"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <VStack alignItems={'flex-start'}>
                  <Text fontSize={'14px'} fontWeight={700}>
                    고객사 혜택
                  </Text>
                  <UnorderedList>
                    <ListItem fontSize={'12px'} fontWeight={500}>
                      임직원 수요도 조사
                    </ListItem>
                    <ListItem fontSize={'12px'} fontWeight={500}>
                      관리자 도구 제공
                    </ListItem>
                    <ListItem fontSize={'12px'} fontWeight={500}>
                      이용자 인원 관리
                    </ListItem>
                  </UnorderedList>
                  {/* <VStack spacing={0} alignItems={'flex-start'}>
                    <Text fontSize={'12px'} fontWeight={500}>
                      임직원 수요도 조사
                    </Text>
                    <Text fontSize={'12px'} fontWeight={500}>
                      관리자 도구 제공
                    </Text>
                    <Text fontSize={'12px'} fontWeight={500}>
                      이용자 인원 관리
                    </Text>
                  </VStack> */}
                </VStack>
              </HStack>
              <HStack alignItems={'flex-start'} h={'100px'}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M4 12.0001L8.94975 16.9499L19.5563 6.34326"
                    stroke="#29CC97"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <VStack alignItems={'flex-start'}>
                  <Text fontSize={'14px'} fontWeight={700}>
                    임직원 혜택
                  </Text>
                  <UnorderedList>
                    <ListItem fontSize={'12px'} fontWeight={500}>
                      맞춤 매물 역매칭
                    </ListItem>
                    <ListItem fontSize={'12px'} fontWeight={500}>
                      보증금 지원
                    </ListItem>
                    <ListItem fontSize={'12px'} fontWeight={500}>
                      임차 관리 서비스
                    </ListItem>
                    <ListItem fontSize={'12px'} fontWeight={500}>
                      수납 서비스
                    </ListItem>
                  </UnorderedList>
                  {/* <VStack spacing={0} alignItems={'flex-start'}>
                    <Text fontSize={'12px'} fontWeight={500}>
                      맞춤 매물 역매칭
                    </Text>
                    <Text fontSize={'12px'} fontWeight={500}>
                      보증금 지원
                    </Text>
                    <Text fontSize={'12px'} fontWeight={500}>
                      임차 관리 서비스
                    </Text>
                    <Text fontSize={'12px'} fontWeight={500}>
                      수납 서비스
                    </Text>
                  </VStack> */}
                </VStack>
              </HStack>
            </HStack>
            {/* <Button
              variant={'green'}
              fontSize={'20px'}
              fontWeight={700}
              h={'44px'}
              w={'full'}
              as={RouterLink}
              to="/company/manage/subscription/subscribe/basic"
            >
              베이직으로 시작
            </Button> */}
          </VStack>
        </VStack>
      </PlanCardBox>
      {/* 월세지원 */}
      <PlanCardBox>
        <VStack h="full">
          <VStack spacing={5}>
            <Center mt={3}>
              <Box w={'8px'} h={'25px'} borderRadius={'md'} bgColor={'advancedPlan'} mr={3} />
              <Text fontSize={'20px'} fontWeight={700}>{`월세지원`}</Text>
            </Center>
            <Text color={'#C4C4C4'} fontSize={'14px'} fontWeight={500} textAlign={'center'} w={'240px'}>
              월세 지원시 비용처리까지 원하신다면 추천드리는 플랜
            </Text>
          </VStack>
          <Text as={Flex} alignItems={'center'} color={'#828282'} fontSize={'20px'} fontWeight={500} flexGrow={1}>
            출시 예정
          </Text>
        </VStack>
      </PlanCardBox>
      {/* 출시예정 */}
      <PlanCardBox>
        <VStack h="full">
          <VStack spacing={5}>
            <Center mt={3}>
              <Box w={'8px'} h={'25px'} borderRadius={'md'} bgColor={'advancedPlan'} mr={3} />
              <Text fontSize={'20px'} fontWeight={700}>{`출시예정`}</Text>
            </Center>
            <Text color={'#C4C4C4'} fontSize={'14px'} fontWeight={500} textAlign={'center'} w={'240px'}>
              맞춤형 서비스가 출시예정입니다!
            </Text>
          </VStack>
          <Text as={Flex} alignItems={'center'} color={'#828282'} fontSize={'20px'} fontWeight={500} flexGrow={1}>
            출시 예정
          </Text>
        </VStack>
      </PlanCardBox>
    </HStack>
  );
};

// 가입 승인 후
const First = () => {
  return (
    <HStack h={'auto'} w="full" spacing={4}>
      {/* 베이직 */}
      <PlanCardBox>
        <VStack divider={<StackDivider borderColor={'#E5E5E5'} />}>
          <VStack spacing={5}>
            <Center mt={3}>
              <Box w={'8px'} h={'25px'} borderRadius={'md'} bgColor={'basicPlan'} mr={3} />
              <Text fontSize={'20px'} fontWeight={700}>{`베이직`}</Text>
            </Center>
            <Text fontSize={'14px'} fontWeight={500} textAlign={'center'} w={'240px'}>
              보증금 지원 효과를 원하신다면 추천드리는 기본 플랜
            </Text>
            <HStack pb={3}>
              <Text color={'mountainGreen'} fontSize={'20px'} fontWeight={500}>
                5만원/월
              </Text>
              <Text color={'#A0A0A0'} fontSize={'14px'} fontWeight={500}>
                (1인 기준)
              </Text>
            </HStack>
          </VStack>
          <VStack>
            <HStack spacing={0} my={2}>
              <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M2.70825 11.375C2.70825 9.2809 4.40584 7.58331 6.49992 7.58331C8.594 7.58331 10.2916 9.2809 10.2916 11.375"
                  stroke="#414141"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6.49992 5.95833C7.69654 5.95833 8.66659 4.98828 8.66659 3.79167C8.66659 2.59505 7.69654 1.625 6.49992 1.625C5.3033 1.625 4.33325 2.59505 4.33325 3.79167C4.33325 4.98828 5.3033 5.95833 6.49992 5.95833Z"
                  stroke="#414141"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <Text fontSize={'12px'} fontWeight={500}>
                최소 1인부터 옵션 선택 가능
              </Text>
            </HStack>
            <HStack alignItems={'flex-start'} pb={5}>
              <HStack alignItems={'flex-start'} h={'100px'}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M4 12.0001L8.94975 16.9499L19.5563 6.34326"
                    stroke="#29CC97"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <VStack alignItems={'flex-start'}>
                  <Text fontSize={'14px'} fontWeight={700}>
                    고객사 혜택
                  </Text>
                  <UnorderedList>
                    <ListItem fontSize={'12px'} fontWeight={500}>
                      임직원 수요도 조사
                    </ListItem>
                    <ListItem fontSize={'12px'} fontWeight={500}>
                      관리자 도구 제공
                    </ListItem>
                    <ListItem fontSize={'12px'} fontWeight={500}>
                      이용자 인원 관리
                    </ListItem>
                  </UnorderedList>
                  {/* <VStack spacing={0} alignItems={'flex-start'}>
                    <Text fontSize={'12px'} fontWeight={500}>
                      임직원 수요도 조사
                    </Text>
                    <Text fontSize={'12px'} fontWeight={500}>
                      관리자 도구 제공
                    </Text>
                    <Text fontSize={'12px'} fontWeight={500}>
                      이용자 인원 관리
                    </Text>
                  </VStack> */}
                </VStack>
              </HStack>
              <HStack alignItems={'flex-start'} h={'100px'}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M4 12.0001L8.94975 16.9499L19.5563 6.34326"
                    stroke="#29CC97"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <VStack alignItems={'flex-start'}>
                  <Text fontSize={'14px'} fontWeight={700}>
                    임직원 혜택
                  </Text>
                  <UnorderedList>
                    <ListItem fontSize={'12px'} fontWeight={500}>
                      맞춤 매물 역매칭
                    </ListItem>
                    <ListItem fontSize={'12px'} fontWeight={500}>
                      보증금 지원
                    </ListItem>
                    <ListItem fontSize={'12px'} fontWeight={500}>
                      임차 관리 서비스
                    </ListItem>
                    <ListItem fontSize={'12px'} fontWeight={500}>
                      수납 서비스
                    </ListItem>
                  </UnorderedList>
                  {/* <VStack spacing={0} alignItems={'flex-start'}>
                    <Text fontSize={'12px'} fontWeight={500}>
                      맞춤 매물 역매칭
                    </Text>
                    <Text fontSize={'12px'} fontWeight={500}>
                      보증금 지원
                    </Text>
                    <Text fontSize={'12px'} fontWeight={500}>
                      임차 관리 서비스
                    </Text>
                    <Text fontSize={'12px'} fontWeight={500}>
                      수납 서비스
                    </Text>
                  </VStack> */}
                </VStack>
              </HStack>
            </HStack>
            <Button
              variant={'green'}
              fontSize={'20px'}
              fontWeight={700}
              h={'44px'}
              w={'full'}
              as={RouterLink}
              to="/company/manage/subscription/subscribe/basic"
            >
              베이직으로 시작
            </Button>
          </VStack>
        </VStack>
      </PlanCardBox>
      {/* 월세지원 */}
      <PlanCardBox>
        <VStack h="full">
          <VStack spacing={5}>
            <Center mt={3}>
              <Box w={'8px'} h={'25px'} borderRadius={'md'} bgColor={'advancedPlan'} mr={3} />
              <Text fontSize={'20px'} fontWeight={700}>{`월세지원`}</Text>
            </Center>
            <Text color={'#C4C4C4'} fontSize={'14px'} fontWeight={500} textAlign={'center'} w={'240px'}>
              월세 지원시 비용처리까지 원하신다면 추천드리는 플랜
            </Text>
          </VStack>
          <Text as={Flex} alignItems={'center'} color={'#828282'} fontSize={'20px'} fontWeight={500} flexGrow={1}>
            출시 예정
          </Text>
        </VStack>
      </PlanCardBox>
      {/* 출시예정 */}
      <PlanCardBox>
        <VStack h="full">
          <VStack spacing={5}>
            <Center mt={3}>
              <Box w={'8px'} h={'25px'} borderRadius={'md'} bgColor={'advancedPlan'} mr={3} />
              <Text fontSize={'20px'} fontWeight={700}>{`출시예정`}</Text>
            </Center>
            <Text color={'#C4C4C4'} fontSize={'14px'} fontWeight={500} textAlign={'center'} w={'240px'}>
              맞춤형 서비스가 출시예정입니다!
            </Text>
          </VStack>
          <Text as={Flex} alignItems={'center'} color={'#828282'} fontSize={'20px'} fontWeight={500} flexGrow={1}>
            출시 예정
          </Text>
        </VStack>
      </PlanCardBox>
    </HStack>
  );
};

// 구독 후 / 확정 전

const Second = () => {
  return (
    <HStack h={'auto'} w="full" spacing={4}>
      {/* 베이직 */}
      <PlanCardBox>
        <VStack divider={<StackDivider borderColor={'#E5E5E5'} />}>
          <VStack spacing={5}>
            <Center mt={3}>
              <Box w={'8px'} h={'25px'} borderRadius={'md'} bgColor={'basicPlan'} mr={3} />
              <Text fontSize={'20px'} fontWeight={700}>{`베이직`}</Text>
            </Center>
            <Text fontSize={'14px'} fontWeight={500} textAlign={'center'} w={'240px'}>
              보증금 지원 효과를 원하신다면 추천드리는 기본 플랜
            </Text>
            <HStack pb={3}>
              <Text color={'mountainGreen'} fontSize={'20px'} fontWeight={500}>
                5만원/월
              </Text>
              <Text color={'#A0A0A0'} fontSize={'14px'} fontWeight={500}>
                (1인 기준)
              </Text>
            </HStack>
          </VStack>
          <VStack>
            <HStack spacing={0} my={2}>
              <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M2.70825 11.375C2.70825 9.2809 4.40584 7.58331 6.49992 7.58331C8.594 7.58331 10.2916 9.2809 10.2916 11.375"
                  stroke="#414141"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6.49992 5.95833C7.69654 5.95833 8.66659 4.98828 8.66659 3.79167C8.66659 2.59505 7.69654 1.625 6.49992 1.625C5.3033 1.625 4.33325 2.59505 4.33325 3.79167C4.33325 4.98828 5.3033 5.95833 6.49992 5.95833Z"
                  stroke="#414141"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <Text fontSize={'12px'} fontWeight={500}>
                최소 1인부터 옵션 선택 가능
              </Text>
            </HStack>
            <HStack alignItems={'flex-start'} pb={5}>
              <HStack alignItems={'flex-start'} h={'100px'}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M4 12.0001L8.94975 16.9499L19.5563 6.34326"
                    stroke="#29CC97"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <VStack alignItems={'flex-start'}>
                  <Text fontSize={'14px'} fontWeight={700}>
                    고객사 혜택
                  </Text>
                  <UnorderedList>
                    <ListItem fontSize={'12px'} fontWeight={500}>
                      임직원 수요도 조사
                    </ListItem>
                    <ListItem fontSize={'12px'} fontWeight={500}>
                      관리자 도구 제공
                    </ListItem>
                    <ListItem fontSize={'12px'} fontWeight={500}>
                      이용자 인원 관리
                    </ListItem>
                  </UnorderedList>
                  {/* <VStack spacing={0} alignItems={'flex-start'}>
                    <Text fontSize={'12px'} fontWeight={500}>
                      임직원 수요도 조사
                    </Text>
                    <Text fontSize={'12px'} fontWeight={500}>
                      관리자 도구 제공
                    </Text>
                    <Text fontSize={'12px'} fontWeight={500}>
                      이용자 인원 관리
                    </Text>
                  </VStack> */}
                </VStack>
              </HStack>
              <HStack alignItems={'flex-start'} h={'100px'}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M4 12.0001L8.94975 16.9499L19.5563 6.34326"
                    stroke="#29CC97"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <VStack alignItems={'flex-start'}>
                  <Text fontSize={'14px'} fontWeight={700}>
                    임직원 혜택
                  </Text>
                  <UnorderedList>
                    <ListItem fontSize={'12px'} fontWeight={500}>
                      맞춤 매물 역매칭
                    </ListItem>
                    <ListItem fontSize={'12px'} fontWeight={500}>
                      보증금 지원
                    </ListItem>
                    <ListItem fontSize={'12px'} fontWeight={500}>
                      임차 관리 서비스
                    </ListItem>
                    <ListItem fontSize={'12px'} fontWeight={500}>
                      수납 서비스
                    </ListItem>
                  </UnorderedList>
                  {/* <VStack spacing={0} alignItems={'flex-start'}>
                    <Text fontSize={'12px'} fontWeight={500}>
                      맞춤 매물 역매칭
                    </Text>
                    <Text fontSize={'12px'} fontWeight={500}>
                      보증금 지원
                    </Text>
                    <Text fontSize={'12px'} fontWeight={500}>
                      임차 관리 서비스
                    </Text>
                    <Text fontSize={'12px'} fontWeight={500}>
                      수납 서비스
                    </Text>
                  </VStack> */}
                </VStack>
              </HStack>
            </HStack>
            <Button
              variant={'green'}
              fontSize={'20px'}
              fontWeight={700}
              h={'44px'}
              w={'full'}
              as={RouterLink}
              to="/company/manage/subscription/subscribe/change"
            >
              플랜 변경
            </Button>
          </VStack>
        </VStack>
      </PlanCardBox>
      {/* 월세지원 */}
      <PlanCardBox>
        <VStack h="full">
          <VStack spacing={5}>
            <Center mt={3}>
              <Box w={'8px'} h={'25px'} borderRadius={'md'} bgColor={'advancedPlan'} mr={3} />
              <Text fontSize={'20px'} fontWeight={700}>{`월세지원`}</Text>
            </Center>
            <Text color={'#C4C4C4'} fontSize={'14px'} fontWeight={500} textAlign={'center'} w={'240px'}>
              월세 지원시 비용처리까지 원하신다면 추천드리는 플랜
            </Text>
          </VStack>
          <Text as={Flex} alignItems={'center'} color={'#828282'} fontSize={'20px'} fontWeight={500} flexGrow={1}>
            출시 예정
          </Text>
        </VStack>
      </PlanCardBox>
      {/* 출시예정 */}
      <PlanCardBox>
        <VStack h="full">
          <VStack spacing={5}>
            <Center mt={3}>
              <Box w={'8px'} h={'25px'} borderRadius={'md'} bgColor={'advancedPlan'} mr={3} />
              <Text fontSize={'20px'} fontWeight={700}>{`출시예정`}</Text>
            </Center>
            <Text color={'#C4C4C4'} fontSize={'14px'} fontWeight={500} textAlign={'center'} w={'240px'}>
              맞춤형 서비스가 출시예정입니다!
            </Text>
          </VStack>
          <Text as={Flex} alignItems={'center'} color={'#828282'} fontSize={'20px'} fontWeight={500} flexGrow={1}>
            출시 예정
          </Text>
        </VStack>
      </PlanCardBox>
    </HStack>
  );
};

const Third = () => {
  return (
    <HStack h={'auto'} w="full" spacing={4}>
      {/* 베이직 */}
      <PlanCardBox>
        <VStack divider={<StackDivider borderColor={'#E5E5E5'} />}>
          <VStack spacing={5}>
            <Center mt={3}>
              <Box w={'8px'} h={'25px'} borderRadius={'md'} bgColor={'basicPlan'} mr={3} />
              <Text fontSize={'20px'} fontWeight={700}>{`베이직`}</Text>
            </Center>
            <Text fontSize={'14px'} fontWeight={500} textAlign={'center'} w={'240px'}>
              보증금 지원 효과를 원하신다면 추천드리는 기본 플랜
            </Text>
            <HStack pb={3}>
              <Text color={'mountainGreen'} fontSize={'20px'} fontWeight={500}>
                5만원/월
              </Text>
              <Text color={'#A0A0A0'} fontSize={'14px'} fontWeight={500}>
                (1인 기준)
              </Text>
            </HStack>
          </VStack>
          <VStack>
            <HStack spacing={0} my={2}>
              <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M2.70825 11.375C2.70825 9.2809 4.40584 7.58331 6.49992 7.58331C8.594 7.58331 10.2916 9.2809 10.2916 11.375"
                  stroke="#414141"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6.49992 5.95833C7.69654 5.95833 8.66659 4.98828 8.66659 3.79167C8.66659 2.59505 7.69654 1.625 6.49992 1.625C5.3033 1.625 4.33325 2.59505 4.33325 3.79167C4.33325 4.98828 5.3033 5.95833 6.49992 5.95833Z"
                  stroke="#414141"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <Text fontSize={'12px'} fontWeight={500}>
                최소 1인부터 옵션 선택 가능
              </Text>
            </HStack>
            <HStack alignItems={'flex-start'} pb={5}>
              <HStack alignItems={'flex-start'} h={'100px'}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M4 12.0001L8.94975 16.9499L19.5563 6.34326"
                    stroke="#29CC97"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <VStack alignItems={'flex-start'}>
                  <Text fontSize={'14px'} fontWeight={700}>
                    고객사 혜택
                  </Text>
                  <UnorderedList>
                    <ListItem fontSize={'12px'} fontWeight={500}>
                      임직원 수요도 조사
                    </ListItem>
                    <ListItem fontSize={'12px'} fontWeight={500}>
                      관리자 도구 제공
                    </ListItem>
                    <ListItem fontSize={'12px'} fontWeight={500}>
                      이용자 인원 관리
                    </ListItem>
                  </UnorderedList>
                  {/* <VStack spacing={0} alignItems={'flex-start'}>
                    <Text fontSize={'12px'} fontWeight={500}>
                      임직원 수요도 조사
                    </Text>
                    <Text fontSize={'12px'} fontWeight={500}>
                      관리자 도구 제공
                    </Text>
                    <Text fontSize={'12px'} fontWeight={500}>
                      이용자 인원 관리
                    </Text>
                  </VStack> */}
                </VStack>
              </HStack>
              <HStack alignItems={'flex-start'} h={'100px'}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M4 12.0001L8.94975 16.9499L19.5563 6.34326"
                    stroke="#29CC97"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <VStack alignItems={'flex-start'}>
                  <Text fontSize={'14px'} fontWeight={700}>
                    임직원 혜택
                  </Text>
                  <UnorderedList>
                    <ListItem fontSize={'12px'} fontWeight={500}>
                      맞춤 매물 역매칭
                    </ListItem>
                    <ListItem fontSize={'12px'} fontWeight={500}>
                      보증금 지원
                    </ListItem>
                    <ListItem fontSize={'12px'} fontWeight={500}>
                      임차 관리 서비스
                    </ListItem>
                    <ListItem fontSize={'12px'} fontWeight={500}>
                      수납 서비스
                    </ListItem>
                  </UnorderedList>
                  {/* <VStack spacing={0} alignItems={'flex-start'}>
                    <Text fontSize={'12px'} fontWeight={500}>
                      맞춤 매물 역매칭
                    </Text>
                    <Text fontSize={'12px'} fontWeight={500}>
                      보증금 지원
                    </Text>
                    <Text fontSize={'12px'} fontWeight={500}>
                      임차 관리 서비스
                    </Text>
                    <Text fontSize={'12px'} fontWeight={500}>
                      수납 서비스
                    </Text>
                  </VStack> */}
                </VStack>
              </HStack>
            </HStack>
            <Center h={'44px'} w={'full'} bgColor={'#29CC97'} borderRadius={'md'} cursor={'default'}>
              <Box fontSize={'20px'} color={'white'} fontWeight={700}>
                현재 이용 중
              </Box>
            </Center>
          </VStack>
        </VStack>
      </PlanCardBox>
      {/* 월세지원 */}
      <PlanCardBox>
        <VStack h="full">
          <VStack spacing={5}>
            <Center mt={3}>
              <Box w={'8px'} h={'25px'} borderRadius={'md'} bgColor={'advancedPlan'} mr={3} />
              <Text fontSize={'20px'} fontWeight={700}>{`월세지원`}</Text>
            </Center>
            <Text color={'#C4C4C4'} fontSize={'14px'} fontWeight={500} textAlign={'center'} w={'240px'}>
              월세 지원시 비용처리까지 원하신다면 추천드리는 플랜
            </Text>
          </VStack>
          <Text as={Flex} alignItems={'center'} color={'#828282'} fontSize={'20px'} fontWeight={500} flexGrow={1}>
            출시 예정
          </Text>
        </VStack>
      </PlanCardBox>
      {/* 출시예정 */}
      <PlanCardBox>
        <VStack h="full">
          <VStack spacing={5}>
            <Center mt={3}>
              <Box w={'8px'} h={'25px'} borderRadius={'md'} bgColor={'advancedPlan'} mr={3} />
              <Text fontSize={'20px'} fontWeight={700}>{`출시예정`}</Text>
            </Center>
            <Text color={'#C4C4C4'} fontSize={'14px'} fontWeight={500} textAlign={'center'} w={'240px'}>
              맞춤형 서비스가 출시예정입니다!
            </Text>
          </VStack>
          <Text as={Flex} alignItems={'center'} color={'#828282'} fontSize={'20px'} fontWeight={500} flexGrow={1}>
            출시 예정
          </Text>
        </VStack>
      </PlanCardBox>
    </HStack>
  );
};

const step = 'zero';

const phases = {
  zero: <Zero />,
  first: <First />,
  second: <Second />,
  third: <Third />,
};

const PlanInfo = () => {
  const {
    data: contractData,
    isIdle: isContractIdle,
    isPlaceholderData,
    isFetchedAfterMount,
    isFetched,
    isFetching,
    isLoading,
  } = useGetCompanyContract();

  console.log(contractData);
  console.log('isPlaceholderData ', isPlaceholderData);

  if (isPlaceholderData) {
    return phases.zero;
  } else {
    if (contractData?.payment_status === undefined) {
      return phases.first;
    } else if (contractData?.payment_status === 'confirm_expected') {
      return phases.second;
    } else if (contractData?.payment_status === 'sign_expected') {
      return phases.zero;
    } else if (contractData?.payment_status === 'total_contract_payment_expected') {
      return phases.zero;
    } else if (contractData?.payment_status === 'subscription_payment_expected') {
      return phases.third;
    } else if (contractData?.payment_status === 'payment_completed') {
      return phases.second;
    } else {
      return null;
    }
  }
};
export default PlanInfo;

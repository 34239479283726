import { Box, Button, ButtonProps, Flex, Text, Tooltip } from '@chakra-ui/react';
import { isEmptyArray } from '@chakra-ui/utils';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCreateCommonChatRoom } from '../../../lib/admin/hooks/mutation';
import { ChatRoom } from '../../../lib/common/utils/axios/types';

interface CommonChatButtonProps extends ButtonProps {
  chatRooms: Array<ChatRoom>;
  user_ids: Array<string>;
}

const CommonChatButton: React.FC<CommonChatButtonProps> = ({ children, chatRooms, user_ids, ...props }) => {
  const navigate = useNavigate();

  const parsedCommonRooms = chatRooms.filter((chatRoom) => chatRoom.chat_room_type === 'common');
  const { mutateAsync } = useCreateCommonChatRoom();
  const [reload, setReload] = useState<boolean>(false);
  const handleCreateCommonChatRoom = () => {
    mutateAsync({
      user_ids,
    }).then(() => {
      // setReload((prev) => !prev);
      window.location.reload();
    });
  };

  if (isEmptyArray(parsedCommonRooms)) {
    return (
      <Tooltip
        label={
          <Flex flexDir={'column'}>
            <Text>현재 일반 채팅방이 없습니다.</Text>
            <Text>새로운 채팅방을 생성하시겠습니까?</Text>
          </Flex>
        }
        defaultIsOpen
        placement="top"
        hasArrow
      >
        <Button variant={'basic'} onClick={handleCreateCommonChatRoom} {...props}>
          일반 채팅방 생성하기
        </Button>
      </Tooltip>
    );
  }
  const isHasCommonChatRoom = parsedCommonRooms.length !== 0;
  const commonChatRoom = parsedCommonRooms[0];
  const { chat_room_id, chat_room_type, collection_name, custom_room_request_id, is_activated } = commonChatRoom;

  if (isHasCommonChatRoom) {
    return (
      <Button
        variant={'basic'}
        onClick={() => {
          navigate(`/admin/chat/${chat_room_id}`, {
            state: {
              chat_room_id,
            },
          });
        }}
        {...props}
      >
        {children ? children : '일반 채팅하기'}
      </Button>
    );
  } else {
    return (
      <Tooltip
        label={
          <Flex flexDir={'column'}>
            <Text>현재 일반 채팅방이 없습니다.</Text>
            <Text>새로운 채팅방을 생성하시겠습니까?</Text>
          </Flex>
        }
        defaultIsOpen
        placement="top"
        hasArrow
      >
        <Button variant={'basic'} onClick={handleCreateCommonChatRoom} {...props}>
          일반 채팅방 생성하기
        </Button>
      </Tooltip>
    );
  }
};

export default CommonChatButton;

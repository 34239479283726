import { Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import QueryParamsLayout from '../../../../../../layouts/common/main/QueryParamsLayout';
import TextLink from '../../../../../../components/common/Link/TextLink';
import { useGetAllCompaniesTenantsTours } from '../../../../../../lib/admin/hooks/query';
import { pathOfUserDetailPage, tourStatusTrans } from '../../../../../../lib/common/utils/utils';
import { currentParamsObject, ToAllFilledObj } from '../../../../../../lib/common/utils/base';
import { datedetaildotkoformat } from '../../../../../../lib/common/utils/dateformatter';
import Loading from '../../../../../../components/common/Loading';

const Main = () => {
  const { search: srch } = location;
  const _params = currentParamsObject();
  const params = ToAllFilledObj(_params);

  const res = useGetAllCompaniesTenantsTours({
    axiosConfig: {
      params,
    },
  });
  const { isLoading } = res;
  if (isLoading) return <Loading />;
  return (
    <QueryParamsLayout res={res}>
      <Table variant="head_silverbg_toplining">
        <Thead>
          <Tr>
            <Th>이름</Th>
            <Th>투어 종류</Th>
            <Th>장소</Th>
            <Th>시작날짜</Th>
            <Th>종료날짜</Th>
          </Tr>
        </Thead>
        <Tbody h="200px" maxH="200px" overflow={'scroll'}>
          {res.data?.result.map((value, index) => {
            const status = value.employee.tenant_status;
            const base = pathOfUserDetailPage(status);
            return (
              <Tr key={value.employee._id + value.employee.tour._id + index}>
                <Td>
                  <TextLink to={`${base}${value.employee._id}`} isExternal key={index}>
                    {value.employee.user_name}
                  </TextLink>
                </Td>
                <Td>{tourStatusTrans(value.employee.tour.tour_type)}</Td>
                <Td maxW={'200px'}>{value.employee.tour.location_description}</Td>
                <Td>{datedetaildotkoformat(value.employee.tour.start_date)}</Td>
                <Td>{datedetaildotkoformat(value.employee.tour.end_date)}</Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </QueryParamsLayout>
  );
};

export default Main;

import { Input, InputProps } from '@chakra-ui/react';
import { HTMLInputTypeAttribute } from 'react';

interface BasicInputProps extends InputProps {
  placeholder?: string;
  type: HTMLInputTypeAttribute;
  id: string;
}

export const BasicInput: React.FC<BasicInputProps> = ({ w = '270px', placeholder, type, id }) => {
  return (
    <Input
      id={id}
      w={w}
      bgColor={'whiteGray'}
      color={'highGray'}
      fontSize={'14px'}
      minW={'60px'}
      _placeholder={{ color: 'midGray', fontSize: '14px' }}
      height={'33px'}
      width={'80px'}
      placeholder={placeholder}
      type={type}
    />
  );
};

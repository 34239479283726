import {
  Box,
  Button,
  HStack,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import {
  useCreateLeasePaymentsStatus,
  useCreateUserVirtualAccount,
  useCreateUserVirtualAccountAndLeasePaymentStatus,
  VirtualAccountUserType,
} from '../../../../lib/admin/hooks/mutation';
import { formikInitialValuesProps } from '../ConfirmContractModal';

interface CreateUserVirtualAccountAndLeasePaymentModalProps {
  disabled?: boolean;
  userType: VirtualAccountUserType;
  userId: string;
  leaseContractId?: string;
}

/** leaseContractId 입력시, 가상계좌 생성 후 '납부 영수증까지' 생성 */
const CreateUserVirtualAccountAndLeasePaymentModal: React.FC<CreateUserVirtualAccountAndLeasePaymentModalProps> = ({
  children,
  disabled = false,
  userType,
  leaseContractId,
  userId,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { mutateAsync: createUserVirtualAccount } = useCreateUserVirtualAccount();

  const { mutateAsync: createUserVirtualAccountAndLeasePaymentStatus } =
    useCreateUserVirtualAccountAndLeasePaymentStatus();

  const handleModalOpen = () => {
    if (!disabled) {
      onOpen();
    }
  };

  const onClickCancelButton = () => {
    onClose();
  };

  const onClickCreateButton = async () => {
    if (leaseContractId) {
      await createUserVirtualAccountAndLeasePaymentStatus({
        lease_contract_id: leaseContractId,
        userId,
        userType,
      }).then(() => {
        onClose();
      });
    } else {
      await createUserVirtualAccount({
        userId,
        userType,
      }).then(() => {
        onClose;
      });
    }
  };

  const UserType = userType === 'company' ? '고객사' : userType === 'tenant' ? '임직원' : '...';

  return (
    <>
      <Box onClick={handleModalOpen}>{children}</Box>

      <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside" isCentered size={'xl'}>
        <ModalOverlay />
        <ModalContent
          p={'100px'}
          // h={values.step === 'zero_main' ? '45%' : 'full'}
        >
          <ModalCloseButton />
          {/* <Scrollbars style={{ height: '100%' }}> */}
          <Box w="full" h="full">
            <Box textAlign={'center'}>
              <Text textStyle={'medium18'}>해당 {UserType}의 가상계좌를 발급하시겠습니까?</Text>
            </Box>
          </Box>
          <HStack w="full" mt={'30px'} justify={'center'}>
            <Button variant={'gray'} w={'100px'} onClick={onClickCancelButton}>
              <Text textStyle={'medium14'}>아니오</Text>
            </Button>
            <Button variant={'basic'} w={'100px'} onClick={onClickCreateButton}>
              <Text textStyle={'medium14'}>네</Text>
            </Button>
          </HStack>
          {/* </Scrollbars> */}
        </ModalContent>
      </Modal>
    </>
  );
};

export default CreateUserVirtualAccountAndLeasePaymentModal;

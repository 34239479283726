import {
  Box,
  Button,
  Center,
  Checkbox,
  Collapse,
  Flex,
  HStack,
  Img,
  Link,
  Stack,
  StackDivider,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Textarea,
  Tooltip,
  Tr,
  useBoolean,
  useToast,
  VStack,
  Wrap,
} from '@chakra-ui/react';
import { Field, Form, Formik, isEmptyArray } from 'formik';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import * as Yup from 'yup';
import { SingleDatepicker } from '../../../components/common/Calender';
import { FCheckboxButtonGroup } from '../../../components/common/Checkbox/base';
import DropzoneOpacityFormikThumb, {
  FileInfosProps,
} from '../../../components/common/Dropzone/DropzoneOpacityFormikThumb';
import MultiFilesCommonDropzone from '../../../components/common/Dropzone/MultiFilesCommonDropzone';
import { filterRemovedFiles } from '../../../lib/common/utils/dropzone/function';
import { CustomEditable } from '../../../components/common/Editable';
import { CustomInput } from '../../../components/common/Input';
import KakaoMap from '../KakaoMap';
import TextLink from '../../../components/common/Link/TextLink';
import CancleDealModal from '../../../components/agent/Modals/CancleDealModal';
import CutDealModal from '../../../components/agent/Modals/CutDealModal';
import { FRadioButtonGroup } from '../../../components/common/Radio';
import { TdFullWContainer } from '../../../components/common/Table/Tr';
import { useUpdateCompanyRoomMemo, useDuplicateRoom, useUpdateRoom } from '../../../lib/common/hooks/mutation';
import {
  isParkingTrans,
  isPetTrans,
  isSecurityWindowTrans,
  pathOfUserDetailPage,
  shareFacilitiesTrans,
} from '../../../lib/common/utils/utils';
import { BuildingRoom, TenantStatus } from '../../../lib/common/utils/axios/types';
import {
  booleanToString,
  peongToSquared,
  remodelingItemTrans,
  remodelingSeasonTrans,
  squaredToPeong,
} from '../../../lib/common/utils/base';
import { datedotformat } from '../../../lib/common/utils/dateformatter';
import { staticThreeCommafy } from '../../../lib/common/utils/digitformatter';
import { buildingFormTypeTrans } from '../../../lib/subscriber/utils/utils';
import {
  additional_options_checkbox,
  common_items_checkbox,
  default_options_checkbox,
  remodeling_items_checkbox,
} from '../../../statics/common/data/checkbox';
import {
  access_status_radio,
  floor_type_radio,
  is_parking_radio,
  is_pet_radio,
  is_security_window_radio,
  move_in_radio,
  room_living_type_radio,
} from '../../../statics/common/data/radio';
import {
  contract_period,
  heating_type as heating_type_data,
  remodeling_season as remodeling_season_data,
  room_direction as room_direction_data,
} from '../../../statics/common/data/selectbox';
import { warning } from '../../../statics/common/data/toast';
import { roomDupLocalKey } from '../../../lib/common/utils/localstorage/key';
import { LeftArrowIcon } from '../../../statics/common/icons';
import SelectBox from '../../../components/agent/SelectBox/base';
import ReCheckAlertModal from '../../../components/common/Modals/Alert/ReCheckAlert';
import ImageSliderModal from '../../../components/common/Modals/ImageSliderModal';
import RegisterHostsInfoModal from '../../../components/common/Modals/RegisterHostsInfoModal';
import HalfTable from '../../../components/common/Table/HalfTable';
import QuarterTable from '../../../components/common/Table/QuaterTable';
import BuildingInfo from '../BuildingItem/buildingItem';
import { additional_func, commons_func, direct_func, individual_func } from '../../../lib/common/utils/roomInfoUtil';
import { useGetBuildingRoom, _useGetBuildingRoom } from '../../../lib/common/hooks/query';
import * as Util from '../../../lib/common/utils/Util';
import * as Type from '../../../types/common/CommonType';
import { useIsChecked } from '../../../lib/common/hooks/Hook';
import Loading from '../../../components/common/Loading';

interface DetailUIType {
  data: Type.Building;
}

export const SamplePrevArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', left: '20px', zIndex: 10, transform: 'scale(2)' }}
      onClick={onClick}
    />
  );
};

export const SampleNextArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', right: '20px', zIndex: 10, transform: 'scale(2)' }}
      onClick={onClick}
    />
  );
};

export const sliderSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  prevArrow: <SamplePrevArrow />,
  nextArrow: <SampleNextArrow />,
};

const RoomInfoUI = () => {
  const navigate = useNavigate();
  const params = useParams();
  const toast = useToast();
  const location = useLocation();
  const routeUserType = Util.getRouteUserTypeFromUrl(location.pathname);

  const hasRoomDupLocalKey = localStorage.getItem(roomDupLocalKey);

  const { mutateAsync: updateCompanyRoomMemo } = useUpdateCompanyRoomMemo();

  const [isEditable, setIsEditable] = useBoolean();
  const inputWidth = '120px';
  // const [is_defaults_checked, setIs_defaults_checked] = useState<boolean>(false);
  const [is_defaults_checked, setIs_defaults_checked] = useIsChecked(false);
  const [mapMode, setMapMode] = useBoolean(false);

  const { mutateAsync: UpdateRoom } = useUpdateRoom();
  const { mutateAsync: DuplicateRoom } = useDuplicateRoom();
  const backToRooms = () => {
    navigate(`/${routeUserType}/manage/estates/rooms`);
  };
  const res = _useGetBuildingRoom(params.id || '', {
    options: {
      enabled: !!params.id,
    },
  });

  const { isLoading } = res;
  if (isLoading) return <Loading />;

  const building = res.data?.data as Type.Building;
  const room = res.data?.data?.room as Type.Room;
  const custom_room_requests = room?.custom_room_requests;
  const coordinates = building?.location?.coordinates;
  const name = building?.name;
  const parking_cost = building?.parking_cost;

  return (
    <Formik
      initialValues={{
        //
        updateMethod: 'originUpdate', // dupUpdate, originUpdate
        //
        code: room?.code,
        //
        // 1 - 건물 등록
        files: [],
        building_type: '',
        building_form: building?.building_form,
        address: building?.address,
        jibun_address: '',
        road_address: '',
        zip_code: '',
        sido_name: '',
        sigungu_name: '',
        dongli_name: '',
        building_number: '',
        name: '',
        completion_date: '',
        share_facilities: room?.share_facilities,
        floor: building?.floor,

        room_type: room?.room_type,
        household_number: '',
        entrance_type: '',
        is_elevator: '',
        is_management: '',
        security_facilities: [],
        is_parking: room?.is_parking,
        parking_num: '',
        parking_cost: '',
        building_pictures: [],
        access_status: room?.access_status,

        // 2 - 매물 등록
        building_id: '',
        deposit: room?.deposit, // applied
        rent: room?.rent, // applied
        management_fee: room?.management_fee, // applied
        common_items: room?.common_items, // applied
        individual_items: room?.individual_items, // applied
        move_in: {
          status: room?.move_in?.status,
          date: room?.move_in?.date ? new Date(room?.move_in?.date) : new Date(),
        },
        minimum_contract_month: room?.minimum_contract_month, // applied
        file_infos: room?.pictures?.map((url: any) => {
          return {
            url,
            removed: false,
          } as FileInfosProps;
        }), // to room_pictures
        description: room?.description,
        dong: room?.dong, // applied
        ho: room?.ho, // applied
        host: room?.host,
        floor_r: room?.floor,
        floor_type: room?.floor_type, // applied
        room_living_type: room?.room_living_type, // applied
        room_num: room?.room_num, // applied
        toilet_num: room?.toilet_num, // applied
        supply_area: room?.supply_area, // applied
        supply_area_peong: squaredToPeong(room?.supply_area),
        dedicated_area: room?.dedicated_area, // applied
        dedicated_area_peong: squaredToPeong(room?.dedicated_area),
        room_direction: room?.room_direction, // applied
        heating_type: room?.heating_type, // applied
        default_options: room?.default_options, // applied
        additional_options: room?.additional_options, // applied
        is_security_window: booleanToString(room?.is_security_window), // applied
        is_parking_r: booleanToString(room?.is_parking), // applied
        is_pet: booleanToString(room?.is_pet),
        // is_interior: '',
        remodeling_season: room?.remodeling_season,
        remodeling_items: room?.remodeling_items,
        is_move_in: room?.is_move_in,
      }}
      onSubmit={async (values, { setSubmitting }) => {
        console.log(values);
        const formData = new FormData();

        formData.append('deposit', values.deposit?.toString?.() || '');
        formData.append('rent', values.rent?.toString?.() || '');
        formData.append('management_fee', values.management_fee?.toString?.() || '');
        values.common_items?.map((item: any) => {
          formData.append('common_items[]', item);
        });
        values.individual_items?.map((item: any) => {
          formData.append('individual_items[]', item);
        });
        formData.append('move_in', JSON.stringify(values.move_in));
        if (values.minimum_contract_month) {
          formData.append('minimum_contract_month', values.minimum_contract_month.toString());
        }
        const [filtered_exists, _1, filtered_Files, filtered_urls] = filterRemovedFiles(values.file_infos);

        if (!filtered_exists.length) {
          return toast({ ...warning, title: '한 개 이상의 파일을 등록해주세요', description: '' });
        }

        filtered_Files.map((file) => {
          formData.append('room_pictures', file);
        });
        formData.append(
          'room_picture_urls',
          JSON.stringify(
            filtered_urls.map((url) => {
              return url;
            }),
          ),
        );

        if (values.host) {
          formData.append('host_id', values.host._id);
        }

        formData.append('dong', values.dong || '');
        formData.append('ho', values.ho || '');
        formData.append('floor_r', values.floor_r?.toString?.() || '');
        formData.append('floor_type', values.floor_type || '');
        formData.append('room_living_type', values.room_living_type || '');
        formData.append('room_num', values.room_num?.toString?.() || '');
        formData.append('toilet_num', values.toilet_num?.toString?.() || '');
        formData.append('supply_area', values.supply_area?.toString?.() || '');
        formData.append('supply_area_peong', values.supply_area_peong?.toString() || '');
        formData.append('dedicated_area', values.dedicated_area?.toString() || '');
        formData.append('dedicated_area_peong', values.dedicated_area_peong?.toString() || '');
        formData.append('room_direction', values.room_direction || '');
        formData.append('heating_type', values.heating_type || '');
        values.default_options?.map((item: any) => {
          formData.append('default_options[]', item);
        });
        values.additional_options?.map((item: any) => {
          formData.append('additional_options[]', item);
        });
        formData.append('is_security_window', booleanToString(values.is_security_window));
        formData.append('is_parking_r', booleanToString(values.is_parking_r));
        formData.append('is_pet', booleanToString(values.is_pet));
        formData.append('remodeling_season', values.remodeling_season || '');
        values.remodeling_items?.map((item) => {
          formData.append('remodeling_items[]', item);
        });
        formData.append('access_status', values.access_status || '');
        formData.append('description', values.description || '');

        await UpdateRoom({
          formData,
          room_id: room?._id,
        }).then(() => {
          window.location.reload();
          setSubmitting(false);
        });
      }}
      validationSchema={Yup.object().shape({
        room_num: Yup.string()
          .required('1~3개의 방 개수만 입력 가능합니다.')
          .test(
            'only positive room_num',
            '1~3개의 방 개수만 입력 가능합니다.',
            (val) => typeof val === 'string' && parseFloat(val) >= 1 && parseFloat(val) <= 3,
          )
          .matches(/^[0-9|.|]+$/, '숫자만 입력 가능합니다.'),
        file_infos: Yup.array()
          .of(
            Yup.object().shape({
              url: Yup.string(),
              removed: Yup.boolean(),
            }),
          )
          .test({
            message: '한 개 이상의 파일을 등록해주세요',
            test: (file_infos) => {
              if (!file_infos?.filter((file_info) => !file_info.removed).length) {
                return false;
              }
              return true;
            },
          }),
      })}
    >
      {(formikProps) => {
        const { setFieldValue, values } = formikProps;
        console.log('values =>', values);

        const makeResult = async (files: File[]) => {
          // funcInSuccessProcess(files);

          if (_.isEmpty(files)) {
            //
          } else {
            formikProps.setFieldValue('file_infos', [
              ...formikProps.values.file_infos,
              ...files.map((url) => ({
                url,
                removed: false,
              })),
            ]);
          }
        };

        return (
          <Form style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
            <VStack px={5} w={'100%'}>
              <Flex w={'100%'} justify={'space-between'} pb={10} mb={10} borderBottom="1px solid #c4c4c4">
                <Button
                  variant={'grayBorder_whiteBg_zetaBlue'}
                  alignItems={'center'}
                  onClick={backToRooms}
                  borderRadius={'36px'}
                  w={'230px'}
                >
                  <LeftArrowIcon mr={'5px'} />
                  이전 페이지로 돌아가기
                </Button>

                <Stack spacing={4} direction="row" align="center">
                  {values.is_move_in ? (
                    <CutDealModal room_id={room?._id || ''}>
                      <Button variant={'grayBorder_whiteBg_zetaBlue'}>거래완료</Button>
                    </CutDealModal>
                  ) : (
                    <CancleDealModal room_id={room?._id || ''}>
                      <Button variant={'grayBorder_whiteBg_primaryRed'} color={'white'}>
                        거래취소
                      </Button>
                    </CancleDealModal>
                  )}
                  {
                    <Button
                      variant={'grayBorder_whiteBg_zetaBlue'}
                      onClick={() => {
                        DuplicateRoom({
                          room_id: room?._id || '',
                        }).then(() => {
                          navigate(`/${routeUserType}/manage/estates/rooms`);
                        });
                      }}
                    >
                      복사하기
                    </Button>
                  }
                  <Box display={'flex'}>
                    <Button
                      variant={'grayBorder_whiteBg_zetaBlue'}
                      onClick={setIsEditable.toggle}
                      type={!isEditable ? 'submit' : 'button'}
                      isDisabled={!formikProps.isValid}
                      borderRight={'none'}
                      borderRightRadius={'none'}
                    >
                      {isEditable ? '수정 완료' : '수정'}
                    </Button>
                    <ReCheckAlertModal callback={() => () => ({})}>
                      <Button variant={'grayBorder_whiteBg_zetaBlue'} borderLeftRadius={'none'}>
                        삭제
                      </Button>
                    </ReCheckAlertModal>
                  </Box>
                </Stack>
              </Flex>
              {isEditable ? (
                // 수정 모드
                <VStack w="full" spacing={7}>
                  {/* 임대인 정보 */}
                  <Flex flexDir={'column'} w={'full'} alignItems={'stretch'}>
                    <Flex alignItems={'flex-end'} mb={2}>
                      <Text fontWeight={'bold'} fontSize={'18px'} w={'100%'}>
                        임대인 정보
                      </Text>
                    </Flex>
                    <Box w="full" borderBottomWidth={'1px'} borderBottomColor={'gray'} />
                    <Table w={'100%'} variant="simple_lining">
                      <Tbody w={'100%'}>
                        <Tr w={'100%'}>
                          <TdFullWContainer title={'임대인 정보'} required ContentTdProps={{ colSpan: 4 }}>
                            <RegisterHostsInfoModal
                              handleRegisterButton={(id, name, accounts) => {
                                setFieldValue('host._id', id);
                                setFieldValue('host.user_name', name);
                              }}
                            >
                              <Flex align={'center'}>
                                <Text mr={2}>{values.host?.user_name}</Text>
                                <Button variant={'basic'}>임대인 추가/수정</Button>
                              </Flex>
                            </RegisterHostsInfoModal>
                          </TdFullWContainer>
                        </Tr>
                      </Tbody>
                    </Table>
                  </Flex>
                  {/* 사진 정보 */}
                  <Flex flexDir={'column'} w={'full'} alignItems={'stretch'}>
                    <Flex alignItems={'flex-end'} mb={2}>
                      <Text fontWeight={'bold'} fontSize={'18px'} w={'100%'}>
                        사진 정보
                      </Text>
                    </Flex>
                    <Box w="full" borderBottomWidth={'1px'} borderBottomColor={'gray'} />
                    <Table w={'100%'} variant="simple_lining">
                      <Tbody w={'100%'}>
                        <Tr w={'100%'}>
                          <TdFullWContainer title={'사진 정보'} required ContentTdProps={{ colSpan: 4 }}>
                            <Box>
                              <Flex align={'center'}>
                                <Box mr={4}>
                                  <MultiFilesCommonDropzone funcInSuccessProcess={makeResult} customVariant={'plus'} />
                                </Box>
                                <Box>파일 1개당 첨부용량 30MB, 파일 업로드 문서당 최대 10개 가능</Box>
                              </Flex>
                              {!_.isEmpty(values.file_infos) && (
                                <Box mt={2}>
                                  <DropzoneOpacityFormikThumb file_infos={values.file_infos} onDeleteBtn={isEditable} />
                                </Box>
                              )}
                            </Box>
                          </TdFullWContainer>
                        </Tr>
                      </Tbody>
                    </Table>
                  </Flex>
                  {/* 거래 정보 */}
                  <Flex flexDir={'column'} w={'full'} alignItems={'stretch'}>
                    <Flex alignItems={'flex-end'} mb={2}>
                      <Text fontWeight={'bold'} fontSize={'18px'} w={'100%'}>
                        거래 정보
                      </Text>
                    </Flex>
                    <Box w="full" borderBottomWidth={'1px'} borderBottomColor={'gray'} />
                    <Table w={'100%'} variant="simple_lining">
                      <Tbody w={'100%'}>
                        <Tr w={'100%'}>
                          <TdFullWContainer title={'가격 정보'} required ContentTdProps={{ colSpan: 4 }}>
                            <VStack alignItems={'flex-start'} divider={<StackDivider borderColor="gray.200" />}>
                              <HStack w="100%">
                                <Text minW="75px">보증금</Text>
                                <Flex w={inputWidth} minW={inputWidth}>
                                  <Field name="deposit" as={CustomInput} bgColor="lotionWhite" addon="원" />
                                </Flex>
                              </HStack>
                              <HStack w="100%">
                                <Text minW="75px">월세</Text>
                                <Flex w={inputWidth} minW={inputWidth}>
                                  <Field name="rent" as={CustomInput} bgColor="lotionWhite" addon="원" />
                                </Flex>
                              </HStack>
                            </VStack>
                          </TdFullWContainer>
                        </Tr>
                        <Tr w={'100%'}>
                          <TdFullWContainer title={'공용 관리비'} required>
                            <Flex w={'100px'} minW={'100px'}>
                              <Field name="management_fee" as={CustomInput} bgColor="lotionWhite" addon="원" />
                            </Flex>
                          </TdFullWContainer>
                          <TdFullWContainer title={'공용 관리비 항목'}>
                            <Flex w={'100%'}>
                              <Field name="common_items" as={FCheckboxButtonGroup} options={common_items_checkbox} />
                            </Flex>
                          </TdFullWContainer>
                        </Tr>
                        <Tr w={'100%'}>
                          <TdFullWContainer title={'입주 가능일'} required>
                            <Flex w={'100%'}>
                              <Field name="move_in.status" as={FRadioButtonGroup} options={move_in_radio} />
                            </Flex>
                            {/* {move_in_status === 'direct' && <Box>aef</Box>} */}
                            <Collapse in={values.move_in?.status === 'direct'}>
                              <HStack w={'100%'} mt={2}>
                                {/* <Flex w="full"> */}
                                <SingleDatepicker
                                  date={values.move_in.date as any}
                                  // 준공일 기입 안 할시 무슨 데이터? '' Null?
                                  onDateChange={(date) => {
                                    setFieldValue('move_in.date', date);
                                  }}
                                  propsConfigs={{
                                    inputProps: {
                                      bgColor: 'lotionWhite',
                                      size: 'sm',
                                      borderRadius: 'md',
                                      borderColor: 'silverGray',
                                      color: 'highGray',
                                      minW: '120px',
                                      w: '120px',
                                    },
                                    dateNavBtnProps: {
                                      color: 'highGray',
                                      _focus: {},
                                    },
                                  }}
                                />
                                {/* </Flex> */}
                                <Box minW={'86px'} w={'86px'}>
                                  이후 입주 가능
                                </Box>
                              </HStack>
                            </Collapse>
                          </TdFullWContainer>
                          <TdFullWContainer title={'최소 계약 기간'}>
                            <Field
                              name="minimum_contract_month"
                              as={SelectBox}
                              placeholder="선택"
                              w={'30%'}
                              h="32px"
                              bgColor="lotionWhite"
                              // defaultValue={values.minimum_contract_month}
                            >
                              {contract_period?.map((option: any, index) => (
                                <option key={option?.value} value={option?.value}>
                                  {option?.label}개월
                                </option>
                              ))}
                            </Field>
                            {/* <SelectBox default /> */}
                          </TdFullWContainer>
                        </Tr>
                      </Tbody>
                    </Table>
                  </Flex>
                  {/* 방 정보 */}
                  <Flex flexDir={'column'} w={'full'} alignItems={'stretch'}>
                    <Flex alignItems={'flex-end'} mb={2}>
                      <Text fontWeight={'bold'} fontSize={'18px'} w={'100%'}>
                        방 정보
                      </Text>
                    </Flex>
                    <Box w="full" borderBottomWidth={'1px'} borderBottomColor={'gray'} />
                    <Table w={'100%'} variant="simple_lining">
                      <Tbody w={'100%'}>
                        {/* {values.dong && values.ho && ( */}
                        <Tr w={'100%'}>
                          <TdFullWContainer title={'단지 및 호수'} ContentTdProps={{ colSpan: 4 }}>
                            <HStack w="100%">
                              <Flex w={'110px'} minW={'110px'}>
                                <Field name="dong" as={CustomInput} bgColor="lotionWhite" addon="동" />
                              </Flex>
                              <Flex w={'110px'} minW={'110px'}>
                                <Field name="ho" as={CustomInput} bgColor="lotionWhite" addon="호" />
                              </Flex>
                            </HStack>
                          </TdFullWContainer>
                        </Tr>
                        {/* )} */}
                        <Tr w={'100%'}>
                          <TdFullWContainer title={'해당층'} required ContentTdProps={{ colSpan: 4 }}>
                            <HStack w="100%">
                              <Flex w={'110px'} minW={'110px'}>
                                <Field name="floor_r" as={CustomInput} bgColor="lotionWhite" addon="층" />
                              </Flex>
                              <Flex w={'auto'}>
                                <Field name="floor_type" as={FRadioButtonGroup} options={floor_type_radio} />
                              </Flex>
                            </HStack>
                          </TdFullWContainer>
                        </Tr>
                        <Tr w={'100%'}>
                          <TdFullWContainer title={'방 거실형태'} required>
                            <Flex w={'auto'}>
                              <Field name="room_living_type" as={FRadioButtonGroup} options={room_living_type_radio} />
                            </Flex>
                          </TdFullWContainer>
                        </Tr>
                        <Tr w={'100%'}>
                          <TdFullWContainer title={'방 개수'} required>
                            <Flex w={'110px'} minW={'110px'}>
                              <Field name="room_num" as={CustomInput} bgColor="lotionWhite" addon="개" />
                              {/* <CustomInput type={'number'} min={0} max={3} /> */}
                            </Flex>
                            {/* <ValidationWithTouch error={errors.room_num} touch={touched.room_num} /> */}
                          </TdFullWContainer>
                          <TdFullWContainer title={'화장실 개수'} required>
                            <Flex w={'110px'} minW={'110px'}>
                              <Field name="toilet_num" as={CustomInput} bgColor="lotionWhite" addon="개" />
                            </Flex>
                          </TdFullWContainer>
                        </Tr>
                        <Tr w={'100%'}>
                          <TdFullWContainer title={'공급 면적'}>
                            <HStack w="100%">
                              <Flex w={'110px'} minW={'110px'}>
                                <Field
                                  name="supply_area"
                                  as={CustomInput}
                                  bgColor="lotionWhite"
                                  addon="m²"
                                  onChange={(e: any) => {
                                    const floored = squaredToPeong(e.target.value);
                                    console.log('floored =>', floored);
                                    if (!e.target.value) {
                                      setFieldValue('supply_area', '');
                                      setFieldValue('supply_area_peong', '');
                                    } else {
                                      setFieldValue('supply_area', e.target.value);
                                      setFieldValue('supply_area_peong', floored);
                                    }
                                  }}
                                />
                              </Flex>
                              <Flex w={'110px'} minW={'110px'}>
                                <Field
                                  name="supply_area_peong"
                                  as={CustomInput}
                                  bgColor="lotionWhite"
                                  addon="평"
                                  onChange={(e: any) => {
                                    const floored = peongToSquared(e.target.value);

                                    if (e.target.value === '') {
                                      setFieldValue('supply_area', '');
                                      setFieldValue('supply_area_peong', '');
                                    } else {
                                      setFieldValue('supply_area', floored);
                                      setFieldValue('supply_area_peong', e.target.value);
                                    }
                                  }}
                                />
                              </Flex>
                            </HStack>
                          </TdFullWContainer>
                          <TdFullWContainer title={'전용 면적'} required>
                            <HStack w="100%">
                              <Flex w={'110px'} minW={'110px'}>
                                <Field
                                  name="dedicated_area"
                                  as={CustomInput}
                                  bgColor="lotionWhite"
                                  addon="m²"
                                  onChange={(e: any) => {
                                    const floored = squaredToPeong(e.target.value);
                                    if (e.target.value === '') {
                                      setFieldValue('dedicated_area', '');
                                      setFieldValue('dedicated_area_peong', '');
                                    } else {
                                      setFieldValue('dedicated_area', e.target.value);
                                      setFieldValue('dedicated_area_peong', floored);
                                    }
                                  }}
                                />
                              </Flex>
                              <Flex w={'110px'} minW={'110px'}>
                                <Field
                                  name="dedicated_area_peong"
                                  as={CustomInput}
                                  bgColor="lotionWhite"
                                  addon="평"
                                  onChange={(e: any) => {
                                    const floored = peongToSquared(e.target.value);
                                    if (e.target.value === '') {
                                      setFieldValue('dedicated_area', '');
                                      setFieldValue('dedicated_area_peong', '');
                                    } else {
                                      setFieldValue('dedicated_area', floored);
                                      setFieldValue('dedicated_area_peong', e.target.value);
                                    }
                                  }}
                                />
                              </Flex>
                            </HStack>
                          </TdFullWContainer>
                        </Tr>
                        <Tr w={'100%'}>
                          <TdFullWContainer title={'주실 방향'}>
                            <Field
                              name="room_direction"
                              as={SelectBox}
                              placeholder="선택"
                              minW="110px"
                              w="110px"
                              h="32px"
                              bgColor="lotionWhite"
                            >
                              {room_direction_data?.map((option: any) => (
                                <option key={option?.value} value={option?.value}>
                                  {option?.label}
                                </option>
                              ))}
                            </Field>
                          </TdFullWContainer>
                          <TdFullWContainer title={'난방 방식'}>
                            <Field
                              name="heating_type"
                              as={SelectBox}
                              placeholder="선택"
                              minW="110px"
                              w="110px"
                              h="32px"
                              bgColor="lotionWhite"
                            >
                              {heating_type_data.map((option: any) => (
                                <option key={option?.value} value={option?.value}>
                                  {option?.label}
                                </option>
                              ))}
                            </Field>
                          </TdFullWContainer>
                        </Tr>
                      </Tbody>
                    </Table>
                  </Flex>
                  {/* 방 옵션 */}
                  <Flex flexDir={'column'} w={'full'} alignItems={'stretch'}>
                    <Flex alignItems={'flex-end'} mb={2}>
                      <Text fontWeight={'bold'} fontSize={'18px'} w={'100%'}>
                        방 옵션
                      </Text>
                    </Flex>
                    <Box w="full" borderBottomWidth={'1px'} borderBottomColor={'gray'} />
                    <Table w={'100%'} variant="simple_lining">
                      <Tbody w={'100%'}>
                        <Tr w={'100%'}>
                          <TdFullWContainer title={'기본 옵션'}>
                            <HStack w="100%" spacing={4}>
                              <Field
                                name="default_options"
                                as={FCheckboxButtonGroup}
                                options={default_options_checkbox}
                              />
                            </HStack>
                          </TdFullWContainer>
                        </Tr>
                        <Tr w={'100%'}>
                          <TdFullWContainer title={'추가 옵션'}>
                            <Field
                              name="additional_options"
                              as={FCheckboxButtonGroup}
                              options={additional_options_checkbox}
                            />
                          </TdFullWContainer>
                        </Tr>
                        <Tr w={'100%'}>
                          <TdFullWContainer title={'시설 옵션'} required>
                            <VStack
                              divider={<StackDivider borderColor="gray.200" />}
                              w={'100%'}
                              alignItems={'flex-start'}
                            >
                              <HStack>
                                <Box wordBreak={'keep-all'} w={'100px'}>
                                  방범창
                                </Box>
                                <Field
                                  name="is_security_window"
                                  as={FRadioButtonGroup}
                                  options={is_security_window_radio}
                                />
                              </HStack>
                              <HStack>
                                <Box wordBreak={'keep-all'} w={'100px'}>
                                  주차 여부
                                </Box>
                                <Field name="is_parking_r" as={FRadioButtonGroup} options={is_parking_radio} />
                              </HStack>
                              <HStack>
                                <Box wordBreak={'keep-all'} w={'100px'}>
                                  반려 동물
                                </Box>
                                <Field name="is_pet" as={FRadioButtonGroup} options={is_pet_radio} />
                              </HStack>
                            </VStack>
                          </TdFullWContainer>
                        </Tr>
                      </Tbody>
                    </Table>
                  </Flex>
                  {/* 인테리어 정보 */}
                  <Flex flexDir={'column'} w={'full'} alignItems={'stretch'}>
                    <Flex alignItems={'flex-end'} mb={2}>
                      <Text fontWeight={'bold'} fontSize={'18px'} w={'100%'}>
                        인테리어 정보
                      </Text>
                    </Flex>
                    <Box w="full" borderBottomWidth={'1px'} borderBottomColor={'gray'} />
                    <Table w={'100%'} variant="simple_lining">
                      <Tbody w={'100%'}>
                        <Tr w={'100%'}>
                          <TdFullWContainer title={'리모델링'}>
                            <VStack
                              divider={<StackDivider borderColor="gray.200" />}
                              w={'100%'}
                              alignItems={'flex-start'}
                            >
                              <HStack>
                                <Box minW={'100px'} w={'100px'}>
                                  리모델링 시기
                                </Box>
                                <Field
                                  name="remodeling_season"
                                  as={SelectBox}
                                  h={'32px'}
                                  bgColor="lotionWhite"
                                  placeholder="선택"
                                >
                                  {remodeling_season_data.map((option: any) => (
                                    <option key={option?.value} value={option?.value}>
                                      {option?.label}
                                    </option>
                                  ))}
                                </Field>
                              </HStack>
                              <HStack>
                                <Box minW={'100px'} w={'100px'}>
                                  리모델링 항목
                                </Box>
                                <Field
                                  name="remodeling_items"
                                  as={FCheckboxButtonGroup}
                                  options={remodeling_items_checkbox}
                                />
                              </HStack>
                            </VStack>
                          </TdFullWContainer>
                        </Tr>
                      </Tbody>
                    </Table>
                  </Flex>
                  {/* 공개여부 */}
                  <Flex flexDir={'column'} w={'full'} alignItems={'stretch'}>
                    <Flex alignItems={'flex-end'} mb={2}>
                      <Text fontWeight={'bold'} fontSize={'18px'} w={'100%'}>
                        공개여부
                      </Text>
                    </Flex>
                    <Box w="full" borderBottomWidth={'1px'} borderBottomColor={'gray'} />
                    <Table w={'100%'} variant="simple_lining">
                      <Tbody w={'100%'}>
                        <Tr w={'100%'}>
                          <TdFullWContainer title={'매물 공개여부'}>
                            <HStack>
                              <Field name="access_status" as={FRadioButtonGroup} options={access_status_radio} />
                            </HStack>
                          </TdFullWContainer>
                        </Tr>
                      </Tbody>
                    </Table>
                  </Flex>
                  {/* 매물 상세 정보 */}
                  <Flex flexDir={'column'} w={'full'} alignItems={'stretch'}>
                    <Flex alignItems={'flex-end'} mb={2}>
                      <Text fontWeight={'bold'} fontSize={'18px'} w={'100%'}>
                        상세 정보
                      </Text>
                    </Flex>
                    <Field
                      name="description"
                      as={Textarea}
                      bgColor="lotionWhite"
                      borderWidth={'1px'}
                      borderColor={'silverGray'}
                      maxLength={950}
                      h="160px"
                      _hover={{}}
                    />
                  </Flex>
                </VStack>
              ) : (
                // 보기 모드
                <Flex w="full" pos={'relative'}>
                  <Box w={'calc(100% - 360px)'}>
                    <Button onClick={() => setMapMode.toggle()} variant={'basic'} mb={2}>
                      {mapMode ? '매물 사진 보기' : '매물 지도 보기'}
                    </Button>
                    <Box w="full" mb={10}>
                      {mapMode ? (
                        <KakaoMap coordinates={coordinates || []} />
                      ) : (
                        <Slider {...sliderSettings} className="room-detail-pictures-slider">
                          {values.file_infos?.map((el: any, index: number) => (
                            <ImageSliderModal
                              titleAndPictures={{
                                title: `매물번호 ${room?.code}`,
                                pictures: values.file_infos?.map((file_info: any) => file_info.url),
                              }}
                              key={index}
                            >
                              <Img w={'full'} h={'300px'} src={`${el.url}`} objectFit={'contain'} />
                            </ImageSliderModal>
                          ))}
                        </Slider>
                      )}
                      {/* <KakaoMap coordinates={coordinates} /> */}
                    </Box>
                    <Box py={5}>
                      <Text fontWeight={700} pb={3}>
                        거래정보
                      </Text>
                      <Table fontSize={'14px'} borderTop={'1px solid #414141'}>
                        <QuarterTable
                          title="공용 관리비"
                          data={`${staticThreeCommafy(values.management_fee)}원`}
                          subTitle="공용 관리비 항목"
                          color={'#f8f8f8'}
                          subData={commons_func(values.common_items)}
                        />
                        <Tbody fontSize={'14px'}>
                          <Tr>
                            <Td fontWeight={500} bgColor={'#f8f8f8'}>
                              개별 사용료 항목
                            </Td>
                            <Td colSpan={3}>{individual_func(values.individual_items)}</Td>
                          </Tr>
                        </Tbody>
                        <QuarterTable
                          title="입주 가능일"
                          data={
                            values.move_in.status === 'direct'
                              ? datedotformat(values.move_in.date)
                              : move_in_radio[move_in_radio.findIndex((el) => el.value == values.move_in.status)].label
                          }
                          subTitle="최소 계약 기간"
                          color={'#f8f8f8'}
                          subData={values.minimum_contract_month && `${values.minimum_contract_month}개월`}
                        />
                      </Table>
                    </Box>
                    <Box py={5}>
                      <Text fontWeight={700} pb={3}>
                        방 정보
                      </Text>
                      <Table>
                        {values.room_type && <QuarterTable title="룸 타입" data={`${values.room_type}`} />}
                        {(values.dong || values.ho) && (
                          <QuarterTable
                            title="단지 및 호수"
                            data={`${values.dong ? values.dong + '동' : ''} ${values.ho ? values.ho + '호' : ''}`}
                          />
                        )}
                        <QuarterTable
                          title="해당층"
                          data={`${room?.floor ? `${room?.floor}층 /` : ''}  ${
                            floor_type_radio[floor_type_radio.findIndex((el) => el.value == values.floor_type)].label
                          }`}
                        />
                        <QuarterTable
                          title="방 거실형태"
                          data={
                            room_living_type_radio[
                              room_living_type_radio.findIndex((el) => el.value == values.room_living_type)
                            ].label
                          }
                        />
                        <QuarterTable
                          title="방 개수"
                          data={`${values.room_num}개`}
                          subTitle="화장실 개수"
                          subData={`${values.toilet_num}개`}
                          color={'#f8f8f8'}
                        />
                        <QuarterTable
                          title="공급 면적"
                          data={`${values.supply_area}m² (${squaredToPeong(values.supply_area)}평)`}
                          subTitle="전용 면적"
                          subData={`${values.dedicated_area}m² (${squaredToPeong(values.dedicated_area)}평)`}
                          color={'#f8f8f8'}
                        />
                        <QuarterTable
                          title="주실 방향"
                          data={
                            values.room_direction &&
                            (values.room_direction as any) != 'undefined' &&
                            `${
                              room_direction_data[
                                room_direction_data.findIndex((el) => el.value == values.room_direction)
                              ].label
                            }쪽`
                          }
                          subTitle="난방 방식"
                          subData={
                            heating_type_data[heating_type_data.findIndex((el) => el.value == values.heating_type)]
                              ?.label
                          }
                          color={'#f8f8f8'}
                        />
                      </Table>
                    </Box>

                    <Box py={5}>
                      <Text fontWeight={700} pb={3}>
                        방 옵션
                      </Text>
                      <Table>
                        <HalfTable title="기본 옵션" data={direct_func(values.default_options)} />
                        <HalfTable title="추가 옵션" data={additional_func(values.additional_options)} />
                        <Tbody fontSize={'14px'}>
                          <Tr>
                            <Td fontWeight={500} bgColor={'#f8f8f8'}>
                              시설 옵션
                            </Td>
                            <Td>
                              <HStack divider={<Text> </Text>}>
                                {<Text>{isParkingTrans(values.is_parking_r)}</Text>}
                                {<Text>{isPetTrans(values.is_pet)}</Text>}
                                {<Text>{isSecurityWindowTrans(values.is_security_window)}</Text>}
                              </HStack>
                            </Td>
                          </Tr>
                        </Tbody>
                        {!isEmptyArray(values.share_facilities) && (
                          <Tbody fontSize={'14px'}>
                            <Tr>
                              <Td fontWeight={500} bgColor={'#f8f8f8'}>
                                공용 공간
                              </Td>
                              <Td>
                                <HStack divider={<Text>,&nbsp;</Text>}>
                                  {values.share_facilities?.map((facility: any, index) => (
                                    <Text key={index}>{shareFacilitiesTrans(facility)}</Text>
                                  ))}
                                </HStack>
                              </Td>
                            </Tr>
                          </Tbody>
                        )}
                      </Table>
                    </Box>
                    <Box py={5}>
                      <BuildingInfo id={building._id} building={building} room={room} />
                    </Box>
                    <Box py={5}>
                      <Text fontWeight={700} pb={3}>
                        임대관리 신청 여부
                      </Text>
                      <Table>
                        <HalfTable title="기본 옵션" data={'미정'} />
                      </Table>
                    </Box>
                    <Box py={5}>
                      <Text fontWeight={700} pb={3}>
                        인테리어 정보
                      </Text>
                      <Table>
                        <HalfTable title="셀프 인테리어 가능 여부" data={'미정'} />
                        {/* <HalfTable title="리모델링" data={values.remo=} /> */}
                        <HalfTable
                          title="리모델링"
                          data={
                            <Center justifyContent={'start'}>
                              <HStack>
                                <Text>리모델링 시기:</Text>
                                <Text>{remodelingSeasonTrans(values.remodeling_season || '')}</Text>
                              </HStack>
                            </Center>
                          }
                        />
                        {!isEmptyArray(values.remodeling_items) && (
                          <HalfTable
                            title=""
                            data={
                              <Center justifyContent={'start'}>
                                <HStack>
                                  <Text>리모델링 항목:</Text>
                                  {values.remodeling_items?.map((item: any, index) => (
                                    <Text key={index}>{remodelingItemTrans(item)}&nbsp;</Text>
                                  ))}
                                </HStack>
                              </Center>
                            }
                          />
                        )}
                      </Table>
                    </Box>
                    <Box py={5}>
                      <Text fontWeight={700} pb={3}>
                        상세 정보
                      </Text>
                      {/* <Textarea
                        bgColor="#FBFBFB"
                        height={'250px'}
                        resize="none"
                        fontSize={'14px'}
                        isReadOnly
                        placeholder={values.description}
                      /> */}
                      {values.description ? (
                        <Textarea
                          isDisabled
                          fontSize={'14px'}
                          bgColor={'#FBFBFB'}
                          border={'1px solid'}
                          borderColor={'silverGray'}
                          borderRadius={'md'}
                          p={2}
                          minH={'250px'}
                          variant={'unstyled'}
                          defaultValue={values.description || ''}
                        />
                      ) : (
                        <Box
                          fontSize={'14px'}
                          bgColor={'#FBFBFB'}
                          border={'1px solid'}
                          borderColor={'silverGray'}
                          borderRadius={'md'}
                          p={2}
                          minH={'250px'}
                        >
                          <Text>상세 정보가 없습니다.</Text>
                        </Box>
                      )}
                    </Box>
                  </Box>
                  {/* 팔로잉 */}
                  <Box
                    boxShadow="2px 2px 20px #DDD"
                    borderWidth="1px"
                    borderRadius="lg"
                    pos={'sticky'}
                    top={4}
                    // right={'70px'}
                    id="following-content"
                    // w="calc(32% - 80px)"
                    w={'360px'}
                    minW={'360px'}
                    h={'fit-content'}
                    // w={'full'}
                    // minW={followBoxWidth}
                    // w={followBoxWidth}
                  >
                    {/* <RoomCheckForConfirmModal /> */}
                    <Box py={3} bgColor="#ECF1FF" textAlign={'center'}>
                      <Text fontSize="22px" color="#183377" fontWeight={500}>
                        매물번호 {room?.code}
                      </Text>
                    </Box>
                    <Box fontWeight={500} p={5}>
                      <Tag variant={values.is_move_in ? 'current_empty' : 'current_filled'} px={2}>
                        {values.is_move_in ? '현재 공실' : '거래 완료'}
                      </Tag>
                      <Box py={3} borderBottom="1px solid #DDD">
                        <Text textStyle={'xl12_2xl16'}>
                          {values.address} {values.dong && `${values.dong}동`} {values.ho && `${values.ho}호`}
                        </Text>
                        <Box py={3}>
                          <Text textStyle={'xl20_2xl32'} fontWeight={'medium'} color="#000">
                            보증금 {staticThreeCommafy(values.deposit)}원 / 월세 {staticThreeCommafy(values.rent)}원
                          </Text>
                          <Text textStyle={'xl20_2xl32'} fontWeight={'medium'} color="#c4c4c4">
                            관리비 {staticThreeCommafy(values.management_fee)}원
                          </Text>
                          <Text fontSize={'xl'} fontWeight={'small'} color="#c4c4c4">
                            주차비 {staticThreeCommafy(parking_cost)}원
                          </Text>
                        </Box>
                        <Text pb={1} textStyle={'xl12_2xl16'}>
                          {buildingFormTypeTrans(values.building_form || '')} | 방 {values.room_num}개 |{' '}
                          {values.dedicated_area}m² | {values.floor}층
                        </Text>
                        {room?.host?.user_name ? (
                          <Tooltip
                            hasArrow
                            label={
                              <Flex flexDir={'column'}>
                                <Box>전화번호: {room?.host?.phone_number_first}</Box>
                                <Box>이메일: {room?.host?.email}</Box>
                              </Flex>
                            }
                          >
                            <Box maxW="min">
                              <TextLink
                                to={`/${routeUserType}/manage/customers/host/detail/info?id=${room?.host?._id}`}
                                isExternal
                              >
                                <Text textStyle={'xl12_2xl16'} minW={'max'}>
                                  임대인({room?.host?.user_name})
                                </Text>
                              </TextLink>
                            </Box>
                          </Tooltip>
                        ) : (
                          <Text color={'gray.400'} fontSize={'14px'}>
                            등록된 임대인이 없습니다.
                          </Text>
                        )}
                      </Box>
                      <Box py={1}>
                        <Box my={3} p={3} border="1px solid #A0A0A0" borderRadius="lg">
                          <CustomEditable
                            display={'text'}
                            value={
                              room?.company_memo ||
                              '비공개 메모입니다. 본인만 확인 가능하며, 외부에는 노출되지 않습니다.'
                            }
                            submitCallback={async (nextValue) => {
                              await updateCompanyRoomMemo({
                                company_memo: nextValue,
                                room_id: params.id || '',
                              });
                            }}
                            selectInputOrTextArea="input"
                          />
                        </Box>
                        <Box
                          my={3}
                          p={3}
                          border="1px solid #A0A0A0"
                          borderRadius="lg"
                          h={{
                            xl: '100px',
                            '2xl': '160px',
                          }}
                          textStyle={'xl12_2xl16'}
                        >
                          <Text>제안 내역</Text>
                          {isEmptyArray(room?.custom_room_requests) ? (
                            <Text>제안 내역 없음</Text>
                          ) : (
                            <Wrap>
                              {custom_room_requests?.map((request: any, index: number) => {
                                const { tenant_id, tenant_name, _id, status } = request;
                                const base = pathOfUserDetailPage(status as TenantStatus);
                                return (
                                  <Link href={`${base}${tenant_id}`} key={index} isExternal>
                                    <Text textStyle={'xl12_2xl16'}>{tenant_name}</Text>
                                  </Link>
                                );
                              })}
                            </Wrap>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Flex>
              )}
            </VStack>
          </Form>
        );
      }}
    </Formik>
  );
};

export default RoomInfoUI;

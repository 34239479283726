import { HStack, Button } from '@chakra-ui/react';
import SelectBox from '../../SelectBox/base';

const Filters = () => {
  return (
    <HStack w={'100%'}>
      <SelectBox placeholder="지역" w={'20%'} textOverflow={'ellipsis'} />
      <SelectBox placeholder="건물 형태" w={'20%'} textOverflow={'ellipsis'} />
      <SelectBox placeholder="보증금/월세" w={'20%'} textOverflow={'ellipsis'} />
      <SelectBox placeholder="방크기" w={'20%'} textOverflow={'ellipsis'} />
      <SelectBox placeholder="추가필터" w={'20%'} textOverflow={'ellipsis'} />
      <Button variant={'basic'} w={'10%'}>
        조회
      </Button>
      <Button variant={'filter'} h={'36px'} w={'15%'}>
        필터 초기화
      </Button>
    </HStack>
  );
};

export default Filters;

import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Modal,
  ModalContent,
  ModalOverlay,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { FieldArray, useFormikContext } from 'formik';
import { useState } from 'react';
import { initialValue } from '../..';
import RemoveButton from '../../../../../../components/common/Button/RemoveButton';
import { ModalDisclosureProps } from '../../../../../../components/common/Modals/base';
import { _locationData } from '../../../../../../statics/common/data/locationData';
import { locationDup, locationExceed } from '../../../../../../statics/common/data/toast';
import { CheckCircleIcon } from '../../../../../../statics/common/icons';
import SelectBox from '../../../../../../components/agent/SelectBox/base';

const AddWorkLocation: React.FC<ModalDisclosureProps> = ({ isOpen, onClose }) => {
  const [sido, setSido] = useState<any | ''>('');
  const [sigungu, setSigungu] = useState<any | ''>('');
  const [dong, setDong] = useState<string | ''>('');
  const locationData = _locationData as any;

  console.log(sido, sigungu, dong);

  const { values, setFieldValue, handleChange } = useFormikContext<typeof initialValue>();
  console.log('values =>', values);

  const toast_dup = useToast();
  const toast_exceed = useToast();

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
        setSido('');
        setSigungu('');
        setDong('');
      }}
      scrollBehavior="inside"
      size={'2xl'}
      motionPreset="slideInRight"
    >
      <ModalOverlay />
      <ModalContent p={5}>
        <FieldArray name="sales_regions">
          {({ insert, remove, push }) => (
            <Center flexDir={'column'} m={10}>
              <CheckCircleIcon />
              <VStack spacing={0} fontSize={'23px'}>
                <Box>나의 주요 영업 지역을 추가하시나요?</Box>
                <Box>추가하실 지역을 선택해주세요.</Box>
              </VStack>
              <VStack
                w={'full'}
                spacing={4}
                bgColor={'lotionWhite'}
                px={7}
                py={14}
                my={7}
                alignItems={'flex-start'}
                h={'100%'}
                border="1px solid"
                borderColor={'silverGray'}
                borderRadius={'md'}
                boxShadow={'sm'}
              >
                <Center w={'full'} alignItems={'flex-end'} justifyContent={'space-between'}>
                  <Box fontSize={'23px'}>영업지역 추가하기</Box>
                  <Box fontSize={'16px'}>*최대 10개</Box>
                </Center>
                <HStack w={'full'} spacing={2}>
                  <SelectBox
                    placeholder="-- 시도선택 --"
                    onChange={(e) => {
                      setSido(e.target.value);
                      setSigungu('');
                      setDong('');
                    }}
                  >
                    {Object.keys(locationData).map((key) => (
                      <option key={key}>{key}</option>
                    ))}
                  </SelectBox>
                  <SelectBox
                    placeholder="-- 시군구선택 --"
                    onChange={(e) => {
                      setSigungu(e.target.value);
                      setDong('');
                    }}
                  >
                    {sido ? Object.keys(locationData[sido]).map((key) => <option key={key}>{key}</option>) : undefined}
                  </SelectBox>
                  <SelectBox placeholder="-- 읍면동선택 --" onChange={(e) => setDong(e.target.value)}>
                    {sido && sigungu
                      ? ((locationData as any)[sido] as any)[sigungu].map((value: any) => (
                          <option key={value}>{value}</option>
                        ))
                      : undefined}
                  </SelectBox>
                </HStack>
                <Flex w={'100%'} h={'120px'} flexWrap={'wrap'}>
                  {values.sales_regions.map((location, index) => (
                    <RemoveButton
                      mr={2}
                      mb={1}
                      boxSize={'max-content'}
                      height={'32px'}
                      key={index}
                      onClick={() => remove(index)}
                    >
                      {/* {(location as any).sido}&nbsp; */}
                      {(location as any).sigungu_name}&nbsp;
                      {(location as any).dongli_name}
                    </RemoveButton>
                  ))}
                </Flex>
              </VStack>
              <Center>
                <Button
                  variant={dong ? 'basic' : 'gray'}
                  w={'160px'}
                  onClick={() => {
                    // 중복 체크
                    const arr_sido = values.sales_regions.map((location) => (location as any).sido_name);
                    const arr_sigungu = values.sales_regions.map((location) => (location as any).sigungu_name);
                    const arr_dong = values.sales_regions.map((location) => (location as any).dongli_name);

                    const isDup_sido = arr_sido.includes(sido);
                    const isDup_sigungu = arr_sigungu.includes(sigungu);
                    const isDup_dong = arr_dong.includes(dong);
                    const isDuplicate = isDup_sido && isDup_sigungu && isDup_dong;
                    if (isDuplicate) {
                      toast_dup(locationDup);
                      return;
                    }
                    if (values.sales_regions.length < 10) {
                      // 성공적으로 추가
                      push({ sido_name: sido, sigungu_name: sigungu, dongli_name: dong });
                    } else {
                      toast_exceed(locationExceed);
                    }
                  }}
                  // isDisabled={dong ? false : true}
                  pointerEvents={dong ? 'all' : 'none'}
                >
                  추가하기
                </Button>
              </Center>
            </Center>
          )}
        </FieldArray>
      </ModalContent>
    </Modal>
  );
};

export default AddWorkLocation;

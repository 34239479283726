import { Box, Flex, HStack, Text, VStack } from '@chakra-ui/react';

const Intro = () => {
  return (
    <VStack alignItems={'unset'} spacing={7} h="100%">
      <Box
        fontSize={'17px'}
        fontWeight={'bold'}
        color={'zetaBlue'}
        bgColor={'white'}
        boxSize={'max'}
        px={'17px'}
        py={'6px'}
        borderRadius={'full'}
      >
        공인중개사 전용
      </Box>
      <VStack alignItems={'unset'} fontSize={'30px'} color="white" spacing={0}>
        <Box>중개사의 든든한 파트너</Box>
        <Box>부동산 임대차 관리는 보홈</Box>
      </VStack>
      <VStack alignItems={'unset'} fontSize={'16px'} color="white" spacing={0}>
        <Box>중개사의 든든한 파트너</Box>
        <Box>부동산 임대차 관리는 보홈!</Box>
      </VStack>
      <VStack flexGrow={1} alignItems={'unset'} justifyContent={'flex-end'} color="white" spacing={2}>
        <Box fontSize={'12px'}>도움이 필요하신가요?</Box>
        <Box fontSize={'19px'} fontWeight={'medium'}>
          빠른 안내 1833-9092
        </Box>
        <HStack spacing={1} flexWrap={'wrap'} fontSize={'11px'}>
          <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M5.49663 9.23252C7.56961 9.23252 9.2501 7.55204 9.2501 5.47905C9.2501 3.40607 7.56961 1.72559 5.49663 1.72559C3.42365 1.72559 1.74316 3.40607 1.74316 5.47905C1.74316 7.55204 3.42365 9.23252 5.49663 9.23252Z"
              stroke="white"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path d="M5.49658 3.39355V5.47881H7.58184" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
          </svg>

          <Text fontWeight={'bold'}>보홈 고객센터 운영시간&nbsp;</Text>
          <Text>평일 10:00 - 18:00 점심시간 12:00 - 13:30</Text>
        </HStack>
      </VStack>
    </VStack>
  );
};

export default Intro;

import { Box, Button, Flex, HStack, VStack } from '@chakra-ui/react';
import { WBox } from '../../../../../../components/common/Box';
import { CreditCardActiveIcon } from '../../../../../../statics/common/icons';
import AccountContentTable from './AccountContentTable';
import ManageAccountTable from './ManageAccountTable';

const EditAccountPage = () => {
  return (
    <WBox px={8} py={5}>
      <VStack alignItems={'flex-start'} spacing={8}>
        {/* Title */}
        <HStack>
          <CreditCardActiveIcon />
          <Box fontSize={'24px'} fontWeight={'bold'}>
            정산 계좌 정보
          </Box>
        </HStack>
        {/* 정산 계좌 관리 */}
        <VStack w="100%">
          <Box fontWeight={'bold'} fontSize={'18px'} textAlign={'start'} flexGrow={1} w={'100%'}>
            정산 계좌 관리
          </Box>
          <Flex w="100%">
            <ManageAccountTable />
          </Flex>
        </VStack>
        {/* 정산 내역 */}
        <VStack w="100%">
          <Box fontWeight={'bold'} fontSize={'18px'} textAlign={'start'} flexGrow={1} w={'100%'}>
            정산 내역
          </Box>
          {/* table */}
          <AccountContentTable />
        </VStack>
        <Flex w="100%" justifyContent={'flex-end'}>
          <Button variant={'basic'}>수정 완료</Button>
        </Flex>
      </VStack>
    </WBox>
  );
};

export default EditAccountPage;

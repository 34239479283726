import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { useRef, useState } from 'react';
import SelectBox from '../../../agent/SelectBox/base';

// interface ColivingRoomTypeProps {
//   roomTypeArray: [];
// }

const ColivingRoomType: React.FC = () => {
  // const [value, setValue] = useState<boolean>(is_paid);
  // const [arr, setArr] = useState<ColivingRoomTypeProps['array']>(array);
  const [selected, setSelected] = useState<string>('');
  const [temp, setTemp] = useState<string>('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();
  const { setFieldValue, values } = useFormikContext<any>();

  const handleChangeOption = (e: any) => {
    setTemp(e.target.value);
    onOpen();
  };

  const handleFinalize = () => {
    setSelected(temp);
    setFieldValue('room_type', temp);
    onClose();
  };

  const handleCloseAlert = () => {
    setTemp('');
  };

  const handleCancle = () => {
    handleCloseAlert();
    onClose();
  };

  return (
    <>
      <SelectBox
        value={selected}
        onChange={(e) => {
          handleChangeOption(e);
        }}
        placeholder="선택"
        h={'34px'}
        w={'200px'}
      >
        {values.room_types.map((value: string, index: number) => (
          <option key={index} value={value}>
            {value}
          </option>
        ))}
      </SelectBox>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef.current}
        onClose={onClose}
        isCentered
        onEsc={handleCloseAlert}
        onOverlayClick={handleCloseAlert}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              재확인
            </AlertDialogHeader>

            <AlertDialogBody>룸 타입 {temp}을/를 선택하시겠습니까?</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef.current} variant={'gray'} onClick={handleCancle}>
                취소
              </Button>
              <Button colorScheme="red" onClick={handleFinalize} variant={'basic'} ml={3}>
                선택
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default ColivingRoomType;

import { Text } from '@chakra-ui/react';
import { useUpdateLeasePaymentsStatus } from '../../../../lib/admin/hooks/mutation';
import {
  CertificateIssueTypeEnum,
  LeaseProxyPaymentStatusEnum,
  ContractPaymentStatus,
} from '../../../../lib/common/utils/axios/types';
import { contract_status, lease_payment_proxy_status } from '../../../../statics/common/data/selectbox';
import SelectBox from '../../../agent/SelectBox/base';

interface ChangeLeaseProxyPaymentStatusProps {
  lease_payment_id: string;
  lease_proxy_payment_status: LeaseProxyPaymentStatusEnum;
}

const ChangeLeaseProxyPaymentStatus: React.FC<ChangeLeaseProxyPaymentStatusProps> = ({
  lease_payment_id,
  lease_proxy_payment_status,
}) => {
  const { mutate } = useUpdateLeasePaymentsStatus();
  return (
    <SelectBox
      value={lease_proxy_payment_status}
      onChange={(e) => {
        const select = e.target.value as LeaseProxyPaymentStatusEnum;
        mutate({
          lease_payment_id,
          lease_contract: {
            proxy_payment_status: select,
          },
        });
      }}
    >
      {lease_payment_proxy_status.map((option, index) => (
        <option key={index} value={option.value}>
          {option.label}
        </option>
      ))}
    </SelectBox>
  );
};

export default ChangeLeaseProxyPaymentStatus;

import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  ListItem,
  Table,
  Tbody,
  UnorderedList,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import axios from 'axios';
import { Field, useFormikContext } from 'formik';
import { useState } from 'react';
import { initialValue, StepProps } from '..';
import ValidationWithTouch from '../../../../../components/common/FormikValidation/ValidationWithTouch';
import { CustomInput } from '../../../../../components/common/Input';
import AddressFinder from '../../../../../components/common/Modals/AddressFinder';
import { FRadioButtonGroup } from '../../../../../components/common/Radio';
import { TrContainer } from '../../../../../components/common/Table/Tr';
import { base, version } from '../../../../../lib/common/utils/axios';
import { deDashfy } from '../../../../../lib/common/utils/digitformatter';
import { business_type_radio } from '../../../../../statics/common/data/radio';
import { FileInput } from './sections/FileInput';
import { duplicated, error } from '../../../../../statics/common/data/toast';
import { RegistrationNumberAutoFormat } from '../../../../../lib/common/utils/base';

const Two: React.FC<StepProps> = ({ setStep }) => {
  // const { values, setFieldValue, handleChange } = useFormikContext<typeof initialValue>();
  const formik = useFormikContext<typeof initialValue>();
  const { values, setFieldValue, handleChange, errors, touched } = formik;
  const {
    company_name = '',
    ceo = '',
    brokerage_number = '',
    address = '',
    phone_number = '',
    business_registration_number = '',
    business_certificate = '',
    brokerage_license = '',
  } = values;

  const toast_notFulfill = useToast();

  const [isDuplicated, setIsDuplicated] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const toast = useToast();
  const toast_id_duplicated_not_entered = 'duplicated_not_entered';
  const toast_id_duplicated = 'isCompleteDuplicated';
  const toast_id_allchecked = 'isAllChecked';
  const toast_id_certificate = 'certificate';
  const toast_id_employee_number = 'employee_number';

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Flex flexDir={'column'} h={'full'} justifyContent={'space-between'}>
      {/* 설명 */}
      <UnorderedList fontSize={'12px'} mb={6}>
        <ListItem>
          보홈은 중개사무소를 개설 등록한 대표자(개업공인중개사)가 회원가입 가능하며, 소속 공인중개사는 가입 후 대표자
          계정에서 추가 등록할 수 있습니다.
        </ListItem>
        <ListItem>회원가입에 관한 문의사항은 고객센터(1833-9092)으로 연락 바랍니다.</ListItem>
        <ListItem>보홈 중개사는 회원가입 요청 후 승인 후에 서비스를 이용하실 수 있습니다.</ListItem>
      </UnorderedList>
      {/* 중개 사무소 정보 */}
      <VStack w="100%">
        <Flex flexDir={'column'} fontWeight={'bold'} fontSize={'15px'} textAlign={'start'} flexGrow={1} w={'100%'}>
          <Box>중개 사무소 정보</Box>
          {/* <HStack fontWeight={'normal'} fontSize={'12px'} alignItems={'flex-end'} justifyContent={'space-between'}>
            <Box>‘조회하기’ 버튼 클릭 후, 중개사무소를 검색하면 관련 정보가 자동으로 입력됩니다.</Box>
            <Button variant={'basic'}>조회하기</Button>
          </HStack> */}
        </Flex>
        <Flex w="100%">
          <Table w="100%">
            <Tbody>
              <TrContainer title="사업자 상호" fontSize={'12px'}>
                <Field
                  name="company_name"
                  as={CustomInput}
                  autoFocus
                  placeholder="상호를 입력해 주세요."
                  onChange={handleChange}
                ></Field>
              </TrContainer>
              <TrContainer title="사업자 대표명" fontSize={'12px'}>
                <Field name="ceo" as={CustomInput} placeholder="성함을 입력해 주세요." onChange={handleChange}></Field>
              </TrContainer>
              <TrContainer title="중개사 등록번호" fontSize={'12px'}>
                <Field
                  name="brokerage_number"
                  as={CustomInput}
                  placeholder="‘-’ 없이 입력해 주세요."
                  onChange={handleChange}
                ></Field>
              </TrContainer>
              <TrContainer title="주소지" fontSize={'12px'}>
                <Field
                  name="address"
                  as={CustomInput}
                  placeholder="중개사무소 주소지를 입력해 주세요."
                  onChange={() => true}
                  onClick={onOpen}
                  cursor={'pointer'}
                  autoComplete="off"
                ></Field>
              </TrContainer>
              <TrContainer title="전화번호" fontSize={'12px'}>
                <Field
                  name="phone_number"
                  as={CustomInput}
                  placeholder="‘-’ 없이 입력해 주세요."
                  onChange={handleChange}
                ></Field>{' '}
              </TrContainer>
            </Tbody>
          </Table>
        </Flex>
      </VStack>
      {/* 사업자 등록번호 확인 */}
      <VStack
        spacing={1}
        mt={6}
        alignItems={'unset'}
        fontWeight={'bold'}
        fontSize={'15px'}
        textAlign={'start'}
        flexGrow={1}
        w={'100%'}
      >
        <Box>사업자 등록번호 확인</Box>
      </VStack>
      <>
        <VStack alignItems={'flex-start'} spacing={1}>
          <Flex justify={'space-between'} w="full">
            <Field name="business_type" as={FRadioButtonGroup} options={business_type_radio} />
            <Button
              variant={'basic'}
              w="84px"
              style={{ height: '32px' }}
              isDisabled={!isDuplicated && isChecked}
              onClick={async () => {
                const empty = business_registration_number.length === 0;
                if (empty || errors.business_registration_number) {
                  if (!toast.isActive(toast_id_duplicated_not_entered)) {
                    toast({
                      id: toast_id_duplicated_not_entered,
                      title: '사업자 구분',
                      description: `올바른 사업자 등록번호 기입이 필요합니다.`,
                      status: 'warning',
                      variant: 'warning',
                      duration: 2000,
                      isClosable: true,
                    });
                  }
                  return null;
                } else {
                  await axios
                    .post(`${base}${version}/common/is-duplicated-business-registration-number/`, {
                      business_registration_number: deDashfy(business_registration_number),
                    })
                    .then((_data) => {
                      const {
                        data: {
                          data: { is_duplicated },
                        },
                      } = _data;
                      if (is_duplicated) {
                        toast(duplicated);
                      } else {
                        setIsDuplicated(false);
                        setIsChecked(true);
                        toast({
                          title: '사업자 구분',
                          description: `중복 확인 완료되었습니다.`,
                          status: 'success',
                          variant: 'success',
                          duration: 2000,
                          isClosable: true,
                        });
                      }
                    })
                    .catch(() => {
                      toast(error);
                    });
                }
              }}
            >
              {!isDuplicated && isChecked ? '확인 완료' : '중복 확인'}
            </Button>
          </Flex>
          <Field
            name="business_registration_number"
            as={CustomInput}
            placeholder="사업자 등록번호를 입력해 주세요."
            // onChange={handleChange}
            onChange={(e: any) => {
              setFieldValue('business_registration_number', e.target.value);
            }}
            value={RegistrationNumberAutoFormat(values.business_registration_number)}
            isDisabled={!isDuplicated && isChecked}
            maxLength={12}
            autoComplete="off"
          />
        </VStack>
        <ValidationWithTouch error={errors.business_registration_number} touch={touched.business_registration_number} />
      </>
      {/* 관련 제출 서류 첨부 */}
      <Flex flexDir={'column'} mt={6} fontWeight={'bold'} fontSize={'15px'} textAlign={'start'} flexGrow={1} w={'100%'}>
        <Box>관련 제출 서류 첨부</Box>
        <VStack fontWeight={'normal'} fontSize={'12px'} alignItems={'flex-end'} justifyContent={'space-between'}>
          <FileInput id="business_certificate" name="business_certificate" handleFormikChange={handleChange}>
            사업자등록증 첨부
          </FileInput>
          <FileInput id="brokerage_license" name="brokerage_license" handleFormikChange={handleChange}>
            중개등록증 첨부
          </FileInput>
        </VStack>
      </Flex>
      <Button
        _after={{ content: "''", height: '200px', width: '1px', opacity: 0, position: 'absolute' }}
        mt={6}
        variant={'gray'}
        boxShadow={'md'}
        w={'full'}
        style={{ height: '40px' }}
        onClick={() => {
          const isAllChecked = !!company_name;
          if (!isAllChecked) {
            toast_notFulfill({
              title: '정보 기입',
              description: '정보 기입 또는 서류 첨부가 필요합니다.',
              status: 'warning',
              duration: 2000,
              isClosable: true,
              position: 'bottom',
              variant: 'warning',
            });
          } else {
            return setStep(2);
          }
        }}
        position={'relative'}
      >
        <Center h={'40px'} fontSize={'15px'}>
          다음
        </Center>
      </Button>
      {/* Modal */}
      <AddressFinder isOpen={isOpen} onClose={onClose} formik={formik} />
    </Flex>
  );
};

export default Two;

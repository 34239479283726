import {
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  SliderProps,
  Tooltip,
  useToast,
} from '@chakra-ui/react';
import React from 'react';
import { Route, useNavigate } from 'react-router-dom';
import { success } from '../../../../statics/common/data/toast';

interface Props extends SliderProps {
  ranges: {
    title: string;
    value: number;
  }[];
}

const SliderThumbWithTooltip: React.FC<Props> = ({ ranges, ...props }) => {
  const [sliderValue, setSliderValue] = React.useState(5);
  const [showTooltip, setShowTooltip] = React.useState(false);

  const sliderLabel = ranges.find((range) => range.value === sliderValue);

  return (
    <Slider
      id="slider"
      defaultValue={5}
      min={0}
      max={100}
      step={5}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
      {...props}
      onChange={(v) => {
        setSliderValue(v);
        if (props.onChange) {
          props?.onChange(v);
        }
      }}
    >
      {ranges.map((range, index) => (
        <SliderMark key={index} value={range.value} mt="1" ml="-2.5" fontSize="sm">
          {range.title}
        </SliderMark>
      ))}
      <SliderTrack>
        <SliderFilledTrack />
      </SliderTrack>
      <Tooltip
        hasArrow
        bg="zetaBlue"
        color="white"
        placement="top"
        isOpen={showTooltip}
        label={sliderLabel?.title || ''}
      >
        <SliderThumb />
      </Tooltip>
    </Slider>
  );
};

export default SliderThumbWithTooltip;

import { Box, Button, Center, Flex, HStack, Table, Tbody, Text, useToast, VStack } from '@chakra-ui/react';
import axios from 'axios';
import { Field, useFormikContext } from 'formik';
import { useState } from 'react';
import { useTimer } from 'react-timer-hook';
import { initialValue, StepProps } from '..';
import Validation from '../../../../../components/common/FormikValidation/Validation';
import { CustomInput } from '../../../../../components/common/Input';
import { TrContainer } from '../../../../../components/common/Table/Tr';
import { base, version } from '../../../../../lib/common/utils/axios';
import { error, inputFulfill, success } from '../../../../../statics/common/data/toast';

const Three: React.FC<StepProps> = ({ setStep }) => {
  const { values, errors, setFieldValue, handleChange, isValid } = useFormikContext<typeof initialValue>();
  const toast_notFulfill = useToast();
  const [isRequestCode, setIsRequestCode] = useState<boolean>(false);

  const toast = useToast();

  const [isDuplicated, setIsDuplicated] = useState<boolean>(false);
  const [authCode, setAuthCode] = useState<string>('');
  const [isEmailAuth, setIsEmailAuth] = useState<boolean>(false);

  // const timeA = new Date();
  // timeA.setSeconds(timeA.getSeconds() + 180);

  // const [timeA, setTimeA] = useState<Date>(new Date());
  const { seconds, minutes, hours, days, isRunning, start, pause, resume, restart } = useTimer({
    expiryTimestamp: new Date(),
    onExpire: () => console.warn('onExpire called'),
  });

  return (
    <Flex flexDir={'column'} h={'full'} justifyContent={'space-between'}>
      {/* 회원 가입 */}
      <VStack w="100%">
        <Flex flexDir={'column'} fontWeight={'bold'} fontSize={'15px'} textAlign={'start'} flexGrow={1} w={'100%'}>
          <Box>계정 정보</Box>
          <VStack mt={2} fontWeight={'normal'} fontSize={'12px'} alignItems={'flex-start'} spacing={0}>
            <Text>로그인에 사용하실 이메일과 비밀번호를 작성해주세요.</Text>
            <Text>해당 이메일로 주요 알림(가입,구독,결제,이용현황)메일이 전송됩니다.</Text>
          </VStack>
        </Flex>
        <Flex w="100%">
          <Table w="100%">
            <Tbody>
              <TrContainer title="이메일" fontSize={'12px'}>
                <HStack>
                  <Field
                    name="email"
                    as={CustomInput}
                    autoFocus
                    placeholder="로그인에 사용하실 이메일을 입력해 주세요."
                    onChange={handleChange}
                    _placeholderShown={{ textOverflow: 'ellipsis' }}
                    isDisabled={isRequestCode || isEmailAuth}
                  />
                  {isEmailAuth ? null : (
                    <Button
                      variant={isRequestCode ? 'gray' : 'basic'}
                      isDisabled={errors.email ? true : false}
                      onClick={async () => {
                        await axios
                          .post(`${base}${version}/common/is-duplicated-email`, { email: values.email })
                          .then(async (value) => {
                            if (!value.data.data.is_duplicated) {
                              await axios
                                .post(`${base}${version}/common/email/code/send`, { email: values.email })
                                .then((value) => {
                                  console.log(value);
                                  const time = new Date();
                                  time.setSeconds(time.getSeconds() + 180);
                                  restart(time);
                                  setIsRequestCode(true);
                                  // setCurrentDate(Date.now());
                                });
                            } else {
                              console.log(value);

                              toast({
                                ...error,
                                title: '중복',
                                description: '이미 가입된 이메일입니다.',
                              });
                            }
                          });
                      }}
                      style={{ width: '160px', height: '32px' }}
                    >
                      {isRequestCode ? <Box>인증 코드 재요청</Box> : <Box>인증 코드 요청</Box>}
                    </Button>
                  )}
                </HStack>
                <Validation error={errors.email} />
              </TrContainer>
              <TrContainer title="이메일 인증 코드 확인" fontSize={'12px'}>
                {/* <Collapse in={isRequestCode} style={{ width: '100%' }}> */}
                <Flex>
                  {isEmailAuth ? (
                    <Text fontSize={'12px'} ml={'2px'}>
                      코드 인증 완료
                    </Text>
                  ) : (
                    isRequestCode && (
                      <VStack w="full" spacing={1}>
                        <HStack w="full">
                          <CustomInput
                            placeholder="발송된 이메일 인증 코드를 기입해주세요."
                            _placeholderShown={{ textOverflow: 'ellipsis' }}
                            // maxW="200.52px"
                            onChange={(e) => {
                              setAuthCode(e.target.value);
                            }}
                          />
                        </HStack>
                        <HStack w="full">
                          <Button
                            variant={'basic'}
                            w="200.52px"
                            style={{ height: '32px' }}
                            isDisabled={authCode.length === 0 || isEmailAuth}
                            onClick={async () => {
                              await axios
                                .post(`${base}${version}/common/email/verify`, {
                                  email: values.email,
                                  code: authCode,
                                })
                                .then((value) => {
                                  if (value.data.data.is_pass) {
                                    console.log('value.data.data.is_pass => ', value.data.data.is_pass);
                                    setIsEmailAuth(true);
                                    toast({
                                      ...success,
                                      title: '인증 완료',
                                      description: '이메일 인증이 완료되었습니다.',
                                    });
                                  } else {
                                    setIsEmailAuth(false);
                                    toast({
                                      ...error,
                                      title: '인증 오류',
                                      description: '입력된 코드가 일치하지 않습니다.',
                                    });
                                  }
                                });
                            }}
                          >
                            {isEmailAuth ? '인증 완료' : '인증 확인'}
                          </Button>
                          <Center fontSize={'14px'} w={'80px'} flexGrow={1}>
                            <div style={{ textAlign: 'center' }}>
                              <div style={{ fontSize: '13px' }}>
                                남은 시간: <span>{minutes}</span>:<span>{seconds}</span>
                              </div>
                              <p style={{ fontSize: '13px' }}>{!isRunning && '재요청 필요'}</p>
                            </div>
                          </Center>
                        </HStack>
                      </VStack>
                    )
                  )}
                </Flex>
                {/* </Collapse> */}
              </TrContainer>
              <TrContainer title="비밀번호" fontSize={'12px'}>
                {/* <CustomInput placeholder="영문[대,소문자]+숫자+특수문자[!@#$%^&*] 포함 8자리이상" /> */}
                <Field
                  name="p_password"
                  as={CustomInput}
                  placeholder="영문[대,소문자]+숫자+특수문자 포함 8자리이상"
                  type="password"
                  onChange={handleChange}
                />
                <Validation error={errors.p_password} />
              </TrContainer>
              <TrContainer title="비밀번호 확인" fontSize={'12px'}>
                {/* <CustomInput placeholder="비밀번호를 다시 한번 입력해 주세요." /> */}
                <Field
                  name="p_confirm_password"
                  as={CustomInput}
                  placeholder="비밀번호를 다시 한번 입력해 주세요."
                  type="password"
                  onChange={handleChange}
                />
                <Validation error={errors.p_confirm_password} />
              </TrContainer>
            </Tbody>
          </Table>
        </Flex>
      </VStack>
      <HStack>
        <Button
          variant={
            values.email &&
            values.p_password &&
            values.p_confirm_password &&
            !errors.email &&
            !errors.p_password &&
            !errors.p_confirm_password
              ? 'gray'
              : 'basic'
          }
          boxShadow={'md'}
          w={'105px'}
          style={{ height: '40px' }}
          onClick={() => setStep(1)}
        >
          이전
        </Button>
        <Button
          variant={
            values.email &&
            values.p_password &&
            values.p_confirm_password &&
            !errors.email &&
            !errors.p_password &&
            !errors.p_confirm_password &&
            isEmailAuth
              ? 'basic'
              : 'gray'
          }
          isDisabled={
            values.email &&
            values.p_password &&
            values.p_confirm_password &&
            !errors.email &&
            !errors.p_password &&
            !errors.p_confirm_password &&
            isEmailAuth
              ? false
              : true
          }
          boxShadow={'md'}
          w={'full'}
          style={{ height: '40px' }}
          onClick={() => {
            if (
              values.email &&
              values.p_password &&
              values.p_confirm_password &&
              !errors.email &&
              !errors.p_password &&
              !errors.p_confirm_password &&
              isEmailAuth
            ) {
              setStep(3);
            } else {
              toast_notFulfill(inputFulfill);
            }
          }}
        >
          다음
        </Button>
      </HStack>
    </Flex>
  );
};

export default Three;

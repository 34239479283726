import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Image,
  Modal,
  ModalContent,
  ModalOverlay,
  Tag,
  Text,
  Textarea,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { isEmptyArray, isUndefined } from '@chakra-ui/utils';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import Scrollbars from 'react-custom-scrollbars-2';
import { WBox } from '../../Box';
import { RequestRoom } from '../../../../lib/common/utils/axios/types';
import { CheckCircleIcon } from '../../../../statics/common/icons';
import { useConfirmRecommendedRooms } from '../../../../lib/common/hooks/mutation';
import * as Type from '../../../../types/common/CommonType';

interface ConfirmRequestRoomsModalProps {
  disabled: boolean;
  requestRooms: Type.Company[];
}

const ConfirmRequestRoomsModal: React.FC<ConfirmRequestRoomsModalProps> = ({ children, disabled, requestRooms }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { mutateAsync } = useConfirmRecommendedRooms(
    isEmptyArray(requestRooms) ? '' : requestRooms?.[0]?.employee?.custom_room_request?._id || '',
  );

  const handleModalOpen = () => {
    if (!disabled) {
      onOpen();
    }
  };

  return (
    <>
      <Box onClick={handleModalOpen}>{children}</Box>

      <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside" size={'4xl'} motionPreset="slideInRight">
        <ModalOverlay />
        <Formik
          initialValues={{
            rooms: requestRooms.map((requestRoom) => {
              return {
                _id: requestRoom?.employee?.custom_room_request?.recommended_room?.room_id,
              };
            }),
          }}
          onSubmit={(values, { setSubmitting }) => {
            mutateAsync({
              is_admin: true,
              rooms: values.rooms || [],
            }).then(() => {
              onClose();
            });
          }}
          validationSchema={Yup.object().shape({})}
        >
          {({ values, errors, isValid, handleChange, setFieldValue }) => {
            console.log('values => ', values);
            return (
              <Form>
                <ModalContent p={5} h={'720px'}>
                  <Center flexDir={'column'} m={10} alignItems={'flex-start'}>
                    <HStack>
                      <CheckCircleIcon w={8} h={8} />
                      <Text fontSize={'23px'}>매물 제안 메시지</Text>
                    </HStack>
                    <VStack fontSize={'20px'} flexDir={'column'} my={4} spacing={0} alignItems={'flex-start'}>
                      <Box>{'매물과 메세지가 함께 임직원에게 전달됩니다. 메세지 수정은 요청서에서 가능합니다.'}</Box>
                    </VStack>
                    {/* 선택된 매물 리스트 */}
                    <Scrollbars
                      autoHide
                      universal
                      style={{
                        height: '380px',
                      }}
                    >
                      <VStack w={'full'}>
                        {/* <Box>List 1</Box> */}
                        {requestRooms.map((requestRoom, index) => {
                          const custom_room_request = requestRoom?.employee?.custom_room_request;
                          const recommended_room = custom_room_request?.recommended_room;
                          const room = recommended_room?.room;

                          return (
                            <WBox w={'full'} key={index}>
                              <HStack w={'full'} p={3} spacing={3}>
                                <Image
                                  boxSize={'92px'}
                                  src={
                                    !isEmptyArray(room?.pictures)
                                      ? room?.pictures?.[0]
                                      : 'https://via.placeholder.com/120'
                                  }
                                  alt={'room-thumnail'}
                                />
                                <VStack spacing={1} w={'full'}>
                                  <Flex w={'full'} justifyContent={'space-between'}>
                                    {/* 주소 */}
                                    <Box fontSize={'14px'}>
                                      {room?.building?.address} {room?.building?.name && `(${room?.building.name})`}
                                      {room?.dong && `${room?.dong}동`} {room?.ho && `${room?.ho}호`}
                                    </Box>
                                    {/* 매물번호 */}
                                    <HStack>
                                      {room?.room_type && <Tag>{room?.room_type}</Tag>}
                                      <Tag variant={'room_number'}>매물번호 {room?.code}</Tag>
                                    </HStack>
                                  </Flex>
                                  <Flex flexDir={'column'} w="full"></Flex>
                                </VStack>
                              </HStack>
                            </WBox>
                          );
                        })}
                      </VStack>
                    </Scrollbars>
                    <Flex
                      justifyContent={'center'}
                      w={'100%'}
                      borderBottom={'1px solid'}
                      borderBottomColor={'gray.400'}
                      mt={1}
                    />
                    <Center w="full">
                      <Button
                        mt={'30px'}
                        variant={'basic'}
                        w={'140px'}
                        fontSize={'14px'}
                        fontWeight={'bold'}
                        type="submit"
                      >
                        제안 완료
                      </Button>
                    </Center>
                  </Center>
                </ModalContent>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

export default ConfirmRequestRoomsModal;

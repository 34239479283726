export const _locationData = {
  서울특별시: {
    강남구: [
      '가락동',
      '개포동',
      '거여동',
      '고덕동',
      '길동',
      '내곡동',
      '논현동',
      '대치동',
      '도곡동',
      '둔촌동',
      '마천동',
      '명일동',
      '문정동',
      '반포동',
      '방배동',
      '방이동',
      '삼성동',
      '삼전동',
      '상일동',
      '서초동',
      '석촌동',
      '성내동',
      '세곡동',
      '송금동',
      '송파동',
      '수서동',
      '신사동',
      '신원동',
      '신천동',
      '암사동',
      '압구정동',
      '양재동',
      '역삼동',
      '염곡동',
      '오금동',
      '우면동',
      '원지동',
      '율현동',
      '이동',
      '일원동',
      '자곡동',
      '잠실동',
      '잠원동',
      '장지동',
      '천호동',
      '청담동',
      '포이동',
      '풍납동',
      '하일동',
      '학동',
    ],
    강동구: [
      '가락동',
      '강일동',
      '거여동',
      '고덕동',
      '길동',
      '둔촌동',
      '마천동',
      '명일동',
      '문정동',
      '방이동',
      '삼전동',
      '상일동',
      '석촌동',
      '성내동',
      '송파동',
      '신천동',
      '암사동',
      '오금동',
      '이동',
      '잠실동',
      '장지동',
      '천호동',
      '풍납동',
      '하일동',
    ],
    강북구: ['미아동', '번동', '수유동', '우이동'],
    강서구: [
      '가양동',
      '개화동',
      '공항동',
      '과해동',
      '내발산동',
      '등촌동',
      '마곡동',
      '목동',
      '발산동',
      '방화동',
      '신월동',
      '신정동',
      '염창동',
      '오곡동',
      '오쇠동',
      '외발산동',
      '화곡동',
      '화곡본동',
    ],
    관악구: [
      '남현동',
      '노량진동',
      '대방동',
      '동작동',
      '방배동',
      '본동',
      '봉천동',
      '봉천본동',
      '사당동',
      '상도동',
      '신대방동',
      '신림동',
      '신림본동',
      '흑석동',
    ],
    광진구: ['광장동', '구의동', '군자동', '노유동', '능동', '모진동', '자양동', '중곡동', '화양동'],
    구로구: [
      '가리봉동',
      '개봉동',
      '고척동',
      '구로동',
      '궁동',
      '독산동',
      '독산본동',
      '수궁동',
      '시흥동',
      '시흥본동',
      '신도림동',
      '오류동',
      '온수동',
      '천왕동',
      '항동',
    ],
    금천구: ['가산동', '독산동', '시흥동'],
    노원구: ['공릉동', '도봉동', '상계동', '월계동', '중계동', '창동', '하계동'],
    도봉구: [
      '공릉동',
      '도봉동',
      '미아동',
      '방학동',
      '번동',
      '상계동',
      '수유동',
      '쌍문동',
      '우이동',
      '월계동',
      '중계동',
      '창동',
      '하계동',
    ],
    동대문구: [
      '군자동',
      '능동',
      '답십리동',
      '망우동',
      '면목동',
      '묵동',
      '상봉동',
      '석관동',
      '숭인동',
      '신내동',
      '신설동',
      '용두동',
      '이문동',
      '장안동',
      '전농동',
      '제기동',
      '종암동',
      '중곡동',
      '중화동',
      '창신동',
      '청량리동',
      '회기동',
      '휘경동',
    ],
    동작구: ['노량진동', '대방동', '동작동', '본동', '사당동', '상도동', '신대방동', '흑석동'],
    마포구: [
      '공덕동',
      '구수동',
      '남가좌동',
      '노고산동',
      '당인동',
      '대흥동',
      '도화동',
      '동교동',
      '마포동',
      '망원동',
      '상수동',
      '상암동',
      '서교동',
      '성산동',
      '수색동',
      '신공덕동',
      '신수동',
      '신정동',
      '아현동',
      '연남동',
      '연희동',
      '염리동',
      '용강동',
      '율도동',
      '중동',
      '창전동',
      '토정동',
      '하수동',
      '하중동',
      '합정동',
      '현석동',
    ],
    서대문구: [
      '갈현동',
      '교남동',
      '교북동',
      '구기동',
      '구산동',
      '구파발동',
      '남가좌동',
      '냉천동',
      '노고산동',
      '녹번동',
      '대신동',
      '대조동',
      '대현동',
      '미근동',
      '봉원동',
      '부암동',
      '북가좌동',
      '북아현동',
      '불광동',
      '상암동',
      '서소문동',
      '성산동',
      '송월동',
      '수색동',
      '순화동',
      '신사동',
      '신영동',
      '신촌동',
      '역촌동',
      '연희동',
      '영천동',
      '옥천동',
      '응암동',
      '정동',
      '중동',
      '중림동',
      '증산동',
      '진관내동',
      '진관외동',
      '창천동',
      '천연동',
      '충정로동',
      '평동',
      '평창동',
      '합동',
      '행촌동',
      '현저동',
      '홍은동',
      '홍제동',
      '홍지동',
      '홍파동',
    ],
    서초구: [
      '개포동',
      '내곡동',
      '도곡동',
      '반포동',
      '반포본동',
      '방배동',
      '방배본동',
      '서초동',
      '신사동',
      '신원동',
      '양재동',
      '역삼동',
      '염곡동',
      '우면동',
      '원지동',
      '잠원동',
    ],
    성동구: [
      '가락동',
      '개포동',
      '거여동',
      '고덕동',
      '광장동',
      '구의동',
      '군자동',
      '금호동',
      '길동',
      '내곡동',
      '논현동',
      '능동',
      '답십리동',
      '대치동',
      '도곡동',
      '도선동',
      '둔촌동',
      '마장동',
      '마천동',
      '면목동',
      '명일동',
      '모진동',
      '무학동',
      '문정동',
      '반포동',
      '방이동',
      '사근동',
      '삼성동',
      '삼전동',
      '상왕십리동',
      '상일동',
      '서초동',
      '석촌동',
      '성내동',
      '세곡동',
      '송정동',
      '송파동',
      '수서동',
      '신당동',
      '신사동',
      '신원동',
      '신천동',
      '암사동',
      '압구정동',
      '양재동',
      '역삼동',
      '염곡동',
      '오금동',
      '옥수동',
      '용답동',
      '용두동',
      '우면동',
      '원지동',
      '율현동',
      '응봉동',
      '이동',
      '일원동',
      '자곡동',
      '자양동',
      '잠실동',
      '잠원동',
      '장지동',
      '중곡동',
      '천호동',
      '청담동',
      '포이동',
      '풍납동',
      '하왕십리동',
      '하일동',
      '학동',
      '행당동',
      '홍익동',
      '화양동',
      '황학동',
      '흥인동',
    ],
    성북구: [
      '공릉동',
      '길음동',
      '도봉동',
      '돈암동',
      '동선동',
      '미아동',
      '방학동',
      '번동',
      '상계동',
      '상월곡동',
      '석관동',
      '성북동',
      '수유동',
      '쌍문동',
      '우이동',
      '월계동',
      '장위동',
      '정릉동',
      '종암동',
      '중계동',
      '창동',
      '하계동',
      '하월곡동',
    ],
    송파구: [
      '가락동',
      '거여동',
      '마천동',
      '문정동',
      '방이동',
      '삼전동',
      '석촌동',
      '송파동',
      '신천동',
      '오금동',
      '이동',
      '잠실동',
      '장지동',
      '풍납동',
    ],
    양천구: ['목동', '신월동', '신정동'],
    영등포구: [
      '가리봉동',
      '가양동',
      '개봉동',
      '개화동',
      '고척동',
      '공항동',
      '과해동',
      '구로동',
      '궁동',
      '내발산동',
      '노량진동',
      '당산동',
      '대림동',
      '대방동',
      '도림동',
      '독산동',
      '동작동',
      '등촌동',
      '마곡동',
      '목동',
      '반포동',
      '방배동',
      '방화동',
      '본동',
      '봉천동',
      '사당동',
      '상도동',
      '서초동',
      '시흥동',
      '신길동',
      '신대방동',
      '신도림동',
      '신림동',
      '신월동',
      '신정동',
      '양재동',
      '양평동',
      '양화동',
      '여의도동',
      '염창동',
      '영등포동',
      '오곡동',
      '오류동',
      '오쇠동',
      '온수동',
      '외발산동',
      '우면동',
      '원지동',
      '잠원동',
      '천왕동',
      '항동',
      '화곡동',
      '흑석동',
    ],
    용산구: [
      '갈월동',
      '남영동',
      '도원동',
      '동빙고동',
      '동자동',
      '문배동',
      '보광동',
      '산천동',
      '서계동',
      '서빙고동',
      '신계동',
      '신창동',
      '옥수동',
      '용문동',
      '이촌동',
      '이태원동',
      '주성동',
      '청암동',
      '한남동',
      '효창동',
      '후암동',
    ],
    은평구: [
      '갈현동',
      '구산동',
      '구파발동',
      '녹번동',
      '대조동',
      '불광동',
      '수색동',
      '신사동',
      '역촌동',
      '응암동',
      '증산동',
      '진관내동',
      '진관동',
      '진관외동',
    ],
    종로구: [
      '가회동',
      '견지동',
      '경운동',
      '계동',
      '공평동',
      '관수동',
      '관철동',
      '관훈동',
      '교남동',
      '교북동',
      '구기동',
      '궁정동',
      '권농동',
      '낙원동',
      '내수동',
      '내자동',
      '누상동',
      '누하동',
      '당주동',
      '도렴동',
      '돈의동',
      '동숭동',
      '묘동',
      '무악동',
      '봉익동',
      '부암동',
      '사간동',
      '사직동',
      '삼청동',
      '서린동',
      '세종로',
      '소격동',
      '송월동',
      '송현동',
      '수송동',
      '숭인동',
      '신교동',
      '신영동',
      '안국동',
      '연건동',
      '연지동',
      '예지동',
      '옥인동',
      '와룡동',
      '운니동',
      '원남동',
      '원서동',
      '이화동',
      '익선동',
      '인사동',
      '인의동',
      '장사동',
      '재동',
      '적선동',
      '중학동',
      '창성동',
      '창신동',
      '청운동',
      '청진동',
      '체부동',
      '충신동',
      '통의동',
      '통인동',
      '팔판동',
      '평동',
      '평창동',
      '필운동',
      '행촌동',
      '혜화동',
      '홍지동',
      '홍파동',
      '화동',
      '효자동',
      '효제동',
      '훈정동',
    ],
    중구: [
      '남창동',
      '남학동',
      '다동',
      '동자동',
      '무교동',
      '무학동',
      '묵정동',
      '방산동',
      '북창동',
      '산림동',
      '삼각동',
      '서소문동',
      '소공동',
      '수표동',
      '수하동',
      '순화동',
      '신당동',
      '쌍림동',
      '양동',
      '예관동',
      '예장동',
      '오장동',
      '입정동',
      '장교동',
      '정동',
      '주교동',
      '주자동',
      '중림동',
      '초동',
      '황학동',
      '흥인동',
    ],
    중랑구: ['망우동', '면목동', '묵동', '상봉동', '신내동', '중화동'],
  },
  세종특별자치시: {
    세종시: [
      '가람동',
      '고운동',
      '금남면',
      '나성동',
      '다정동',
      '대평동',
      '도담동',
      '반곡동',
      '보람동',
      '부강면',
      '새롬동',
      '소담동',
      '소정면',
      '아름동',
      '어진동',
      '연기면',
      '연동면',
      '연서면',
      '장군면',
      '전동면',
      '전의면',
      '조치원읍',
      '종촌동',
      '한솔동',
    ],
  },
  강원도: {
    강릉시: [
      '강동면',
      '강문동',
      '견소동',
      '교동',
      '구정면',
      '금학동',
      '난곡동',
      '남문동',
      '남항진동',
      '내곡동',
      '노암동',
      '담산동',
      '대전동',
      '두산동',
      '명주동',
      '박월동',
      '병산동',
      '사천면',
      '성남동',
      '성내동',
      '성산면',
      '송정동',
      '신석동',
      '안현동',
      '연곡면',
      '옥계면',
      '옥천동',
      '왕산면',
      '용강동',
      '운산동',
      '운정동',
      '월호평동',
      '유산동',
      '유천동',
      '임당동',
      '입암동',
      '장현동',
      '저동',
      '주문진읍',
      '죽전동',
      '죽헌동',
      '중앙동',
      '지변동',
      '청량동',
      '초당동',
      '포남동',
      '학동',
      '홍제동',
      '회산동',
    ],
    고성군: ['간성면', '간성읍', '거진면', '거진읍', '수동면', '죽왕면', '토성면', '현내면'],
    동해시: [
      '괴란동',
      '구미동',
      '구호동',
      '귀운동',
      '나안동',
      '내동',
      '단봉동',
      '달방동',
      '대구동',
      '대진동',
      '동호동',
      '동회동',
      '만우동',
      '망상동',
      '묵호동',
      '묵호진동',
      '발한동',
      '부곡동',
      '북삼동',
      '북평동',
      '비천동',
      '사문동',
      '삼화동',
      '삼흥동',
      '송정동',
      '쇄운동',
      '신흥동',
      '심곡동',
      '어달동',
      '용정동',
      '이기동',
      '이도동',
      '이로동',
      '이원동',
      '지가동',
      '지흥동',
      '천곡동',
      '초구동',
      '추암동',
      '평릉동',
      '향로동',
      '호현동',
      '효가동',
    ],
    명주군: ['강동면', '구정면', '묵호읍', '사천면', '성산면', '연곡면', '옥계면', '왕산면', '주문진읍'],
    삼척군: [
      '가곡면',
      '근덕면',
      '노곡면',
      '도계읍',
      '미로면',
      '북평읍',
      '삼척읍',
      '상장면',
      '소달면',
      '신기면',
      '원덕면',
      '원덕읍',
      '장성읍',
      '하장면',
      '황지읍',
    ],
    삼척시: [
      '가곡면',
      '갈천동',
      '건지동',
      '교동',
      '근덕면',
      '근산동',
      '남양동',
      '노곡면',
      '당저동',
      '도경동',
      '도계읍',
      '도원동',
      '등봉동',
      '마달동',
      '마평동',
      '미로면',
      '사직동',
      '성남동',
      '성내동',
      '성북동',
      '신기면',
      '오분동',
      '오사동',
      '우지동',
      '원당동',
      '원덕읍',
      '월계동',
      '읍상동',
      '읍중동',
      '자원동',
      '자지동',
      '적노동',
      '정라동',
      '정상동',
      '정하동',
      '조비동',
      '증산동',
      '평전동',
      '하장면',
    ],
    속초시: [
      '교동',
      '금호동',
      '노학동',
      '대포동',
      '도문동',
      '동명동',
      '사진동',
      '설악동',
      '영랑동',
      '장사동',
      '조양동',
      '중앙동',
      '청학동',
      '청호동',
    ],
    양구군: ['국토정중앙면', '남면', '동면', '방산면', '양구면', '양구읍', '해안면'],
    양양군: ['강현면', '서면', '속초읍', '손양면', '양양면', '양양읍', '현남면', '현북면'],
    영월군: [
      '김삿갓면',
      '남면',
      '북면',
      '산솔면',
      '상동면',
      '상동읍',
      '서면',
      '수주면',
      '영월면',
      '영월읍',
      '주천면',
      '중동면',
      '하동면',
      '한반도면',
    ],
    울진군: ['근남면', '기성면', '북면', '온정면', '울진면', '원남면', '평해면'],
    원성군: ['귀래면', '문막면', '부론면', '소초면', '신림면', '지정면', '판부면', '호저면', '흥업면'],
    원주군: ['귀래면', '문막면', '부론면', '소초면', '신림면', '지정면', '판부면', '호저면', '흥업면'],
    원주시: [
      '가현동',
      '개운동',
      '관설동',
      '귀래면',
      '단계동',
      '단구동',
      '명륜동',
      '무실동',
      '문막면',
      '문막읍',
      '반곡동',
      '봉산동',
      '부론면',
      '소초면',
      '신림면',
      '우산동',
      '원동',
      '원인동',
      '인동',
      '일산동',
      '중앙동',
      '중평동',
      '지정면',
      '태장동',
      '판부면',
      '평원동',
      '학성동',
      '행구동',
      '호저면',
      '흥업면',
    ],
    인제군: ['기린면', '남면', '북면', '상남면', '서화면', '인제면', '인제읍'],
    정선군: [
      '고한읍',
      '남면',
      '동면',
      '북면',
      '북평면',
      '사북읍',
      '신동면',
      '신동읍',
      '여량면',
      '임계면',
      '정선면',
      '정선읍',
      '화암면',
    ],
    철원군: [
      '갈말면',
      '갈말읍',
      '근남면',
      '근동면',
      '근북면',
      '김화읍',
      '동송면',
      '동송읍',
      '서면',
      '원남면',
      '원동면',
      '임남면',
      '임문면',
      '철원읍',
    ],
    춘성군: ['남면', '남산면', '동내면', '동면', '동산면', '북산면', '사북면', '서면', '신동면', '신북면'],
    춘천군: ['남면', '남산면', '동내면', '동면', '동산면', '북산면', '사북면', '서면', '신동면', '신북면'],
    춘천시: [
      '교동',
      '근화동',
      '낙원동',
      '남면',
      '남산면',
      '동내면',
      '동면',
      '동산면',
      '봉의동',
      '북산면',
      '사농동',
      '사북면',
      '사우동',
      '삼천동',
      '서면',
      '석사동',
      '소락동',
      '소양동',
      '송암동',
      '신동',
      '신동면',
      '신북면',
      '신북읍',
      '약사동',
      '옥천동',
      '온의동',
      '요선동',
      '우두동',
      '운교동',
      '조양동',
      '조운동',
      '죽림동',
      '중도동',
      '중앙동',
      '칠송동',
      '칠전동',
      '퇴계동',
      '호반동',
      '효자동',
      '후평동',
    ],
    태백시: [
      '계산동',
      '금천동',
      '동점동',
      '문곡동',
      '백산동',
      '상사미동',
      '상장동',
      '소도동',
      '연화동',
      '원동',
      '장성동',
      '적각동',
      '조탄동',
      '창죽동',
      '철암동',
      '통동',
      '하사미동',
      '혈동',
      '화광동',
      '화전동',
      '황지동',
    ],
    평창군: ['대관령면', '대화면', '도암면', '미탄면', '방림면', '봉평면', '용평면', '진부면', '평창면', '평창읍'],
    홍천군: [
      '남면',
      '내면',
      '내촌면',
      '동면',
      '두촌면',
      '북방면',
      '서면',
      '서석면',
      '영귀미면',
      '홍천면',
      '홍천읍',
      '화촌면',
    ],
    화천군: ['간동면', '사내면', '상서면', '하남면', '화천면', '화천읍'],
    횡성군: ['갑천면', '강림면', '공근면', '둔내면', '서원면', '안흥면', '우천면', '청일면', '횡성면', '횡성읍'],
  },
  경기도: {
    가평군: ['가평면', '가평읍', '북면', '상면', '설악면', '외서면', '조종면', '청평면', '하면'],
    강화군: [
      '강화면',
      '강화읍',
      '교동면',
      '길상면',
      '내가면',
      '불은면',
      '삼산면',
      '서도면',
      '선원면',
      '송해면',
      '양도면',
      '양사면',
      '하점면',
      '화도면',
    ],
    고양군: [
      '벽제면',
      '벽제읍',
      '송포면',
      '신도면',
      '신도읍',
      '원당면',
      '원당읍',
      '일산읍',
      '중면',
      '지도면',
      '지도읍',
      '화전읍',
    ],
    고양시: [
      '가좌동',
      '강매동',
      '고양동',
      '관산동',
      '구산동',
      '내곡동',
      '내유동',
      '대자동',
      '대장동',
      '대화동',
      '덕은동',
      '덕이동',
      '도내동',
      '동산동',
      '마두동',
      '문봉동',
      '백석동',
      '법곳동',
      '벽제동',
      '북한동',
      '사리현동',
      '산황동',
      '삼송동',
      '선유동',
      '설문동',
      '성사동',
      '성석동',
      '식사동',
      '신원동',
      '신평동',
      '오금동',
      '용두동',
      '원당동',
      '원흥동',
      '일산동',
      '장항동',
      '주교동',
      '주엽동',
      '지영동',
      '지축동',
      '탄현동',
      '토당동',
      '풍동',
      '행신동',
      '행주내동',
      '행주외동',
      '향동동',
      '현천동',
      '화전동',
      '화정동',
      '효자동',
    ],
    고양시덕양구: [
      '강매동',
      '고양동',
      '관산동',
      '내곡동',
      '내유동',
      '대자동',
      '대장동',
      '덕은동',
      '도내동',
      '동산동',
      '벽제동',
      '북한동',
      '삼송동',
      '선유동',
      '성사동',
      '신원동',
      '신평동',
      '오금동',
      '용두동',
      '원당동',
      '원흥동',
      '주교동',
      '지축동',
      '토당동',
      '행신동',
      '행주내동',
      '행주외동',
      '향동동',
      '현천동',
      '화전동',
      '화정동',
      '효자동',
    ],
    고양시일산구: [
      '가좌동',
      '구산동',
      '대화동',
      '덕이동',
      '마두동',
      '문봉동',
      '백석동',
      '법곳동',
      '사리현동',
      '산황동',
      '설문동',
      '성석동',
      '식사동',
      '일산동',
      '장항동',
      '주엽동',
      '지영동',
      '탄현동',
      '풍동',
    ],
    고양시일산동구: [
      '마두동',
      '문봉동',
      '백석동',
      '사리현동',
      '산황동',
      '설문동',
      '성석동',
      '식사동',
      '장항동',
      '정발산동',
      '중산동',
      '지영동',
      '풍동',
    ],
    고양시일산서구: ['가좌동', '구산동', '대화동', '덕이동', '법곳동', '일산동', '주엽동', '탄현동'],
    과천시: ['갈현동', '과천동', '관문동', '막계동', '문원동', '별양동', '부림동', '원문동', '주암동', '중앙동'],
    광명시: ['가학동', '광명동', '노온사동', '소하동', '옥길동', '일직동', '철산동', '하안동', '학온동'],
    광주군: [
      '광주면',
      '광주읍',
      '구천면',
      '낙생면',
      '남종면',
      '대왕면',
      '도척면',
      '돌마면',
      '동부면',
      '동부읍',
      '서부면',
      '실촌면',
      '언주면',
      '오포면',
      '중대면',
      '중부면',
      '초월면',
      '퇴촌면',
    ],
    광주시: [
      '경안동',
      '곤지암읍',
      '남종면',
      '남한산성면',
      '도척면',
      '목동',
      '목현동',
      '삼동',
      '송정동',
      '실촌면',
      '실촌읍',
      '쌍령동',
      '역동',
      '오포읍',
      '장지동',
      '중대동',
      '중부면',
      '직동',
      '초월면',
      '초월읍',
      '탄벌동',
      '태전동',
      '퇴촌면',
      '회덕동',
    ],
    구리시: ['갈매동', '교문동', '동구동', '사노동', '수택동', '수평동', '아천동', '인창동', '토평동'],
    군포시: ['금정동', '당동', '당정동', '대야미동', '도마교동', '둔대동', '부곡동', '산본동', '속달동'],
    김포군: [
      '검단면',
      '계양면',
      '고촌면',
      '김포면',
      '김포읍',
      '대곳면',
      '대곶면',
      '양동면',
      '양서면',
      '양촌면',
      '오정면',
      '월곳면',
      '월곶면',
      '통진면',
      '하성면',
    ],
    김포시: [
      '감정동',
      '걸포동',
      '고촌면',
      '고촌읍',
      '구래동',
      '대곶면',
      '마산동',
      '북변동',
      '사우동',
      '양촌면',
      '양촌읍',
      '운양동',
      '월곶면',
      '장기동',
      '통진면',
      '통진읍',
      '풍무동',
      '하성면',
    ],
    남양주군: [
      '구리읍',
      '미금읍',
      '별내면',
      '수동면',
      '와부면',
      '와부읍',
      '조안면',
      '진건면',
      '진접면',
      '진접읍',
      '퇴계원면',
      '화도면',
      '화도읍',
    ],
    남양주시: [
      '가운동',
      '금곡동',
      '다산동',
      '도농동',
      '별내동',
      '별내면',
      '삼패동',
      '수동면',
      '수석동',
      '오남면',
      '오남읍',
      '와부읍',
      '이패동',
      '일패동',
      '조안면',
      '지금동',
      '진건면',
      '진건읍',
      '진접읍',
      '퇴계원면',
      '퇴계원읍',
      '평내동',
      '호평동',
      '화도읍',
    ],
    동두천시: [
      '걸산동',
      '광암동',
      '내행동',
      '동두천동',
      '동안동',
      '보산동',
      '상봉암동',
      '상패동',
      '생연동',
      '소요동',
      '송내동',
      '안흥동',
      '지행동',
      '탑동동',
      '하봉암동',
    ],
    미금시: [
      '가운동',
      '금곡동',
      '도농동',
      '삼패동',
      '수석동',
      '양정동',
      '이패동',
      '일패동',
      '지금동',
      '평내동',
      '호평동',
    ],
    부천군: ['계양면', '대부면', '덕적면', '북도면', '소래면', '소사읍', '영종면', '영흥면', '오정면', '용유면'],
    부천시: [
      '계수동',
      '고강동',
      '괴안동',
      '내동',
      '대장동',
      '도당동',
      '범박동',
      '삼정동',
      '상동',
      '소사동',
      '소사본동',
      '송내동',
      '심곡동',
      '심곡본동',
      '약대동',
      '여월동',
      '역곡동',
      '오정동',
      '옥길동',
      '원미동',
      '원종동',
      '작동',
      '중동',
      '춘의동',
    ],
    부천시남구: ['계수동', '괴안동', '범박동', '상동', '소사동', '송내동', '심곡동', '역곡동', '옥길동', '중동'],
    부천시소사구: ['계수동', '괴안동', '범박동', '소사동', '소사본동', '송내동', '심곡본동', '옥길동'],
    부천시오정구: ['고강동', '내동', '대장동', '삼정동', '여월동', '오정동', '원종동', '작동'],
    부천시원미구: [
      '도당동',
      '상동',
      '소사동',
      '송내동',
      '심곡동',
      '약대동',
      '여월동',
      '역곡동',
      '원미동',
      '작동',
      '중동',
      '춘의동',
    ],
    부천시중구: [
      '고강동',
      '내동',
      '대장동',
      '도당동',
      '삼정동',
      '성곡동',
      '성지동',
      '심곡동',
      '약대동',
      '여월동',
      '오정동',
      '원미동',
      '원종동',
      '작동',
      '중동',
      '춘의동',
    ],
    성남시: [
      '갈현동',
      '고등동',
      '구미동',
      '궁내동',
      '금곡동',
      '금토동',
      '단대동',
      '대장동',
      '도촌동',
      '동원동',
      '둔전동',
      '백현동',
      '복정동',
      '분당동',
      '사송동',
      '삼평동',
      '상대원동',
      '상적동',
      '서현동',
      '석운동',
      '성남동',
      '수내동',
      '수진동',
      '시흥동',
      '신촌동',
      '신흥동',
      '심곡동',
      '야탑동',
      '여수동',
      '오야동',
      '운중동',
      '율동',
      '은행동',
      '이매동',
      '정자동',
      '중동',
      '창곡동',
      '태평동',
      '판교동',
      '하대원동',
      '하산운동',
    ],
    성남시분당구: [
      '구미동',
      '궁내동',
      '금곡동',
      '대장동',
      '동원동',
      '백현동',
      '분당동',
      '삼평동',
      '서현동',
      '석운동',
      '수내동',
      '야탑동',
      '운중동',
      '율동',
      '이매동',
      '정자동',
      '판교동',
      '하산운동',
    ],
    성남시수정구: [
      '고등동',
      '금토동',
      '단대동',
      '둔전동',
      '복정동',
      '사송동',
      '산성동',
      '상적동',
      '수진동',
      '시흥동',
      '신촌동',
      '신흥동',
      '심곡동',
      '양지동',
      '오야동',
      '창곡동',
      '태평동',
    ],
    성남시중원구: [
      '갈현동',
      '구미동',
      '궁내동',
      '금곡동',
      '금광동',
      '대장동',
      '도촌동',
      '동원동',
      '백현동',
      '분당동',
      '삼평동',
      '상대원동',
      '서현동',
      '석운동',
      '성남동',
      '수내동',
      '야탑동',
      '여수동',
      '운중동',
      '율동',
      '은행동',
      '이매동',
      '정자동',
      '중동',
      '중앙동',
      '판교동',
      '하대원동',
      '하산운동',
    ],
    송탄시: [
      '가재동',
      '도원동',
      '도일동',
      '독곡동',
      '동부동',
      '모곡동',
      '서정동',
      '송북동',
      '신장동',
      '이충동',
      '장당동',
      '장안동',
      '중앙동',
      '지산동',
      '칠괴동',
      '칠원동',
    ],
    수원시: [
      '고등동',
      '고색동',
      '곡반정동',
      '곡선동',
      '교동',
      '구운동',
      '구천동',
      '권선동',
      '금곡동',
      '남수동',
      '남창동',
      '남향동',
      '대황교동',
      '매교동',
      '매산동',
      '매탄동',
      '매향동',
      '반정동',
      '북수동',
      '상광교동',
      '서둔동',
      '세류동',
      '송원동',
      '송죽동',
      '신안동',
      '신풍동',
      '연무동',
      '영동',
      '영화동',
      '오목천동',
      '우만동',
      '원천동',
      '율전동',
      '이목동',
      '이의동',
      '인계동',
      '장안동',
      '장지동',
      '정자동',
      '조원동',
      '중동',
      '지동',
      '지만동',
      '천천동',
      '탑동',
      '파장동',
      '팔달동',
      '평동',
      '평리동',
      '하광교동',
      '하동',
      '호매실동',
      '화서동',
    ],
    수원시권선구: [
      '고등동',
      '고색동',
      '곡반정동',
      '곡선동',
      '교동',
      '구운동',
      '권선동',
      '금곡동',
      '당수동',
      '대황교동',
      '매교동',
      '매산동',
      '매탄동',
      '서둔동',
      '세류동',
      '오목천동',
      '원천동',
      '인계동',
      '입북동',
      '장지동',
      '탑동',
      '평동',
      '평리동',
      '호매실동',
    ],
    수원시영통구: ['망포동', '매탄동', '신동', '영통동', '원천동', '이의동', '하동'],
    수원시장안구: [
      '구천동',
      '남수동',
      '남창동',
      '남향동',
      '매향동',
      '북수동',
      '상광교동',
      '송원동',
      '송죽동',
      '신안동',
      '신풍동',
      '연무동',
      '영동',
      '영화동',
      '우만동',
      '율전동',
      '이목동',
      '이의동',
      '장안동',
      '정자동',
      '조원동',
      '중동',
      '지동',
      '지만동',
      '천천동',
      '파장동',
      '팔달동',
      '하광교동',
      '하동',
      '화서동',
    ],
    수원시팔달구: [
      '고등동',
      '교동',
      '구천동',
      '남수동',
      '남창동',
      '망포동',
      '매교동',
      '매탄동',
      '매향동',
      '북수동',
      '신동',
      '신풍동',
      '영동',
      '영통동',
      '우만동',
      '원천동',
      '이의동',
      '인계동',
      '장안동',
      '중동',
      '지동',
      '하동',
      '화서동',
    ],
    시흥군: [
      '과천면',
      '군자면',
      '군포읍',
      '남면',
      '동면',
      '서면',
      '소래면',
      '소래읍',
      '소하읍',
      '수암면',
      '신동면',
      '안양읍',
      '의왕면',
      '의왕읍',
    ],
    시흥시: [
      '거모동',
      '계수동',
      '과림동',
      '광석동',
      '군자동',
      '금이동',
      '논곡동',
      '능곡동',
      '대야동',
      '도창동',
      '매화동',
      '목감동',
      '무지내동',
      '무지동',
      '물왕동',
      '미산동',
      '방산동',
      '배곧동',
      '산현동',
      '수암동',
      '신관동',
      '신천동',
      '안산동',
      '안현동',
      '월곶동',
      '은행동',
      '장곡동',
      '장상동',
      '장하동',
      '장현동',
      '정왕동',
      '조남동',
      '죽율동',
      '포동',
      '하상동',
      '하중동',
      '화정동',
    ],
    안산시: [
      '건건동',
      '고잔동',
      '군자동',
      '남동',
      '대부남동',
      '대부동동',
      '대부북동',
      '동동',
      '목내동',
      '반월동',
      '본오동',
      '부곡동',
      '북동',
      '사동',
      '사사동',
      '선감동',
      '선부동',
      '성곡동',
      '성포동',
      '수암동',
      '신길동',
      '양상동',
      '와동',
      '원곡동',
      '원시동',
      '원초동',
      '월피동',
      '이동',
      '일동',
      '장상동',
      '장하동',
      '중앙동',
      '초지동',
      '팔곡이동',
      '팔곡일동',
      '풍도동',
      '화정동',
    ],
    안산시단원구: [
      '고잔동',
      '대부남동',
      '대부동동',
      '대부북동',
      '목내동',
      '선감동',
      '선부동',
      '성곡동',
      '신길동',
      '와동',
      '원곡동',
      '원시동',
      '초지동',
      '풍도동',
      '화정동',
    ],
    안산시상록구: [
      '건건동',
      '본오동',
      '부곡동',
      '사동',
      '사사동',
      '성포동',
      '수암동',
      '양상동',
      '월피동',
      '이동',
      '일동',
      '장상동',
      '장하동',
      '팔곡이동',
      '팔곡일동',
    ],
    안성군: [
      '고삼면',
      '공도면',
      '금광면',
      '대덕면',
      '미양면',
      '보개면',
      '삼죽면',
      '서운면',
      '안성읍',
      '양성면',
      '원곡면',
      '이죽면',
      '일죽면',
      '죽산면',
    ],
    안성시: [
      '가사동',
      '가현동',
      '계동',
      '고삼면',
      '공도면',
      '공도읍',
      '구포동',
      '금광면',
      '금산동',
      '금석동',
      '낙원동',
      '당왕동',
      '대덕면',
      '대천동',
      '도기동',
      '동본동',
      '명륜동',
      '미양면',
      '발화동',
      '보개면',
      '봉남동',
      '봉산동',
      '사곡동',
      '삼죽면',
      '서운면',
      '서인동',
      '석정동',
      '성남동',
      '숭인동',
      '신건지동',
      '신모산동',
      '신소현동',
      '신흥동',
      '아양동',
      '양성면',
      '연지동',
      '영동',
      '옥산동',
      '옥천동',
      '원곡면',
      '인지동',
      '일죽면',
      '죽산면',
      '중리동',
      '창전동',
      '현수동',
    ],
    안양시: ['관양동', '박달동', '비산동', '석수동', '안양동', '평촌동', '호계동'],
    안양시동안구: ['관양동', '비산동', '안양동', '평촌동', '호계동'],
    안양시만안구: ['박달동', '석수동', '안양동'],
    양주군: [
      '광적면',
      '구리면',
      '구리읍',
      '남면',
      '노해면',
      '동두천읍',
      '미금면',
      '미금읍',
      '백석면',
      '백석읍',
      '별내면',
      '수동면',
      '양주읍',
      '와부면',
      '은현면',
      '의정부읍',
      '이남면',
      '장흥면',
      '주내면',
      '진건면',
      '진접면',
      '화도면',
      '회천면',
      '회천읍',
    ],
    양주시: [
      '고암동',
      '고읍동',
      '광사동',
      '광적면',
      '남면',
      '남방동',
      '덕계동',
      '덕정동',
      '마전동',
      '만송동',
      '백석읍',
      '봉양동',
      '산북동',
      '삼숭동',
      '어둔동',
      '옥정동',
      '유양동',
      '율정동',
      '은현면',
      '장흥면',
      '회암동',
      '회정동',
    ],
    양평군: [
      '강상면',
      '강하면',
      '개군면',
      '단월면',
      '서종면',
      '양동면',
      '양서면',
      '양평면',
      '양평읍',
      '옥천면',
      '용문면',
      '지제면',
      '지평면',
      '청운면',
    ],
    여주군: [
      '가남면',
      '강천면',
      '개군면',
      '금사면',
      '능서면',
      '대신면',
      '북내면',
      '산북면',
      '여주읍',
      '점동면',
      '흥천면',
    ],
    여주시: [
      '가남읍',
      '가업동',
      '강천면',
      '교동',
      '금사면',
      '능서면',
      '능현동',
      '단현동',
      '대신면',
      '매룡동',
      '멱곡동',
      '북내면',
      '산북면',
      '삼교동',
      '상거동',
      '상동',
      '신진동',
      '연라동',
      '연양동',
      '오금동',
      '오학동',
      '우만동',
      '월송동',
      '점동면',
      '점봉동',
      '창동',
      '천송동',
      '하거동',
      '하동',
      '현암동',
      '홍문동',
      '흥천면',
    ],
    연천군: [
      '관인면',
      '군남면',
      '미산면',
      '백학면',
      '신서면',
      '연천면',
      '연천읍',
      '왕징면',
      '장남면',
      '전곡면',
      '전곡읍',
      '중면',
      '청산면',
    ],
    오산시: [
      '가수동',
      '가장동',
      '갈곶동',
      '고현동',
      '궐동',
      '금암동',
      '남촌동',
      '내삼미동',
      '누읍동',
      '두곡동',
      '벌음동',
      '부산동',
      '서동',
      '서랑동',
      '세교동',
      '세마동',
      '수청동',
      '신장동',
      '양산동',
      '역촌동',
      '오산동',
      '외삼미동',
      '원동',
      '은계동',
      '중앙동',
      '지곶동',
      '청학동',
      '청호동',
      '초평동',
      '탑동',
    ],
    옹진군: ['대부면', '대청면', '덕적면', '백령면', '북도면', '송림면', '영종면', '영흥면', '용유면', '자월면'],
    용인군: [
      '고삼면',
      '구성면',
      '기흥면',
      '기흥읍',
      '남사면',
      '내사면',
      '모현면',
      '수지면',
      '외사면',
      '용인면',
      '용인읍',
      '원삼면',
      '이동면',
      '포곡면',
    ],
    용인시: [
      '고기동',
      '고림동',
      '구성면',
      '구성읍',
      '기흥읍',
      '김량장동',
      '남동',
      '남사면',
      '동천동',
      '마평동',
      '모현면',
      '백암면',
      '삼가동',
      '상현동',
      '성복동',
      '수지읍',
      '신봉동',
      '양지면',
      '역북동',
      '운학동',
      '원삼면',
      '유방동',
      '이동면',
      '죽전동',
      '포곡면',
      '풍덕천동',
      '해곡동',
      '호동',
    ],
    용인시기흥구: [
      '고매동',
      '공세동',
      '구갈동',
      '농서동',
      '동백동',
      '마북동',
      '보라동',
      '보정동',
      '상갈동',
      '상하동',
      '서천동',
      '신갈동',
      '언남동',
      '영덕동',
      '중동',
      '지곡동',
      '청덕동',
      '하갈동',
    ],
    용인시수지구: ['고기동', '동천동', '상현동', '성복동', '신봉동', '죽전동', '풍덕천동'],
    용인시처인구: [
      '고림동',
      '김량장동',
      '남동',
      '남사면',
      '남사읍',
      '마평동',
      '모현면',
      '모현읍',
      '백암면',
      '삼가동',
      '양지면',
      '역북동',
      '운학동',
      '원삼면',
      '유방동',
      '이동면',
      '이동읍',
      '포곡읍',
      '해곡동',
      '호동',
    ],
    의왕시: [
      '고천동',
      '내손동',
      '부곡동',
      '삼동',
      '오전동',
      '왕곡동',
      '월암동',
      '이동',
      '청계동',
      '초평동',
      '포일동',
      '학의동',
    ],
    의정부시: [
      '가능동',
      '가능리',
      '고산동',
      '금오동',
      '금오리',
      '낙양동',
      '낙양리',
      '녹양동',
      '녹양리',
      '민락동',
      '민락리',
      '산곡동',
      '송산동',
      '신곡동',
      '신곡리',
      '용현동',
      '용현리',
      '의정부동',
      '의정부리',
      '자금동',
      '자일동',
      '자일리',
      '장곡동',
      '장암동',
      '장암리',
      '호원동',
      '호원리',
    ],
    이천군: [
      '대월면',
      '마장면',
      '모가면',
      '백사면',
      '부발면',
      '부발읍',
      '설성면',
      '신둔면',
      '율면',
      '이천읍',
      '장호원읍',
      '호법면',
    ],
    이천시: [
      '갈산동',
      '고담동',
      '관고동',
      '단월동',
      '대월면',
      '대포동',
      '마장면',
      '모가면',
      '백사면',
      '부발읍',
      '사음동',
      '설성면',
      '송정동',
      '신둔면',
      '안흥동',
      '율면',
      '율현동',
      '장록동',
      '장호원읍',
      '중리동',
      '증일동',
      '증포동',
      '진리동',
      '창전동',
      '호법면',
    ],
    인천시: [
      '가정동',
      '가좌동',
      '간석동',
      '갈산동',
      '검암동',
      '경동',
      '경서동',
      '계산동',
      '고잔동',
      '공촌동',
      '관교동',
      '관동',
      '구산동',
      '구월동',
      '금곡동',
      '남촌동',
      '내동',
      '논현동',
      '답동',
      '도림동',
      '도원동',
      '도화동',
      '동춘동',
      '만석동',
      '만수동',
      '문학동',
      '백석동',
      '부개동',
      '부평동',
      '북성동',
      '사동',
      '산곡동',
      '삼산동',
      '서운동',
      '서창동',
      '석남동',
      '선린동',
      '선학동',
      '선화동',
      '송림동',
      '송월동',
      '송학동',
      '송현동',
      '수산동',
      '숭의동',
      '시천동',
      '신생동',
      '신포동',
      '신현동',
      '신흥동',
      '심곡동',
      '십정동',
      '연수동',
      '연희동',
      '옥련동',
      '용동',
      '용현동',
      '운연동',
      '원창동',
      '유동',
      '율목동',
      '인현동',
      '일신동',
      '작전동',
      '장수동',
      '전동',
      '주안동',
      '중앙동',
      '창영동',
      '청천동',
      '청학동',
      '학익동',
      '항동',
      '해안동',
      '화수동',
      '화평동',
      '효성동',
    ],
    인천시남구: [
      '간석동',
      '고잔동',
      '관교동',
      '구기동',
      '구월동',
      '남촌동',
      '논현동',
      '도림동',
      '도화동',
      '동춘동',
      '만수동',
      '문학동',
      '서창동',
      '선학동',
      '수산동',
      '숭의동',
      '연수동',
      '옥련동',
      '용현동',
      '운연동',
      '장수동',
      '주안동',
      '청학동',
      '학익동',
    ],
    인천시동구: ['금곡동', '만석동', '송림동', '송현동', '창영동', '화수동', '화평동'],
    인천시북구: [
      '가정동',
      '가좌동',
      '갈산동',
      '검암동',
      '경서동',
      '계산동',
      '공촌동',
      '구산동',
      '백석동',
      '백천동',
      '부개동',
      '부평동',
      '산곡동',
      '삼산동',
      '서운동',
      '석남동',
      '시천동',
      '신현동',
      '심곡동',
      '십정동',
      '연희동',
      '원창동',
      '일신동',
      '작전동',
      '청천동',
      '효성동',
    ],
    인천시중구: [
      '경동',
      '관동',
      '내동',
      '답동',
      '도원동',
      '북성동',
      '사동',
      '선린동',
      '선화동',
      '송월동',
      '송학동',
      '신생동',
      '신포동',
      '신흥동',
      '용동',
      '유동',
      '율목동',
      '인현동',
      '전동',
      '중앙동',
      '항동',
      '해안동',
    ],
    파주군: [
      '광탄면',
      '교하면',
      '군내면',
      '금촌읍',
      '문산읍',
      '법원읍',
      '아동면',
      '월롱면',
      '임진면',
      '장단면',
      '적성면',
      '조리면',
      '주내면',
      '주내읍',
      '진동면',
      '진서면',
      '천현면',
      '탄현면',
      '파주읍',
      '파평면',
    ],
    파주시: [
      '검산동',
      '광탄면',
      '교하동',
      '교하면',
      '교하읍',
      '군내면',
      '금능동',
      '금릉동',
      '금촌동',
      '다율동',
      '당하동',
      '동패동',
      '맥금동',
      '목동동',
      '문발동',
      '문산읍',
      '법원읍',
      '산남동',
      '상지석동',
      '서패동',
      '송촌동',
      '신촌동',
      '아동동',
      '야당동',
      '야동동',
      '연다산동',
      '오도동',
      '와동동',
      '월롱면',
      '장단면',
      '적성면',
      '조리면',
      '조리읍',
      '진동면',
      '진서면',
      '탄현면',
      '파주읍',
      '파평면',
      '하지석동',
    ],
    평택군: [
      '고덕면',
      '서탄면',
      '송탄면',
      '송탄읍',
      '안중면',
      '오성면',
      '진위면',
      '청북면',
      '팽성면',
      '팽성읍',
      '평택읍',
      '포승면',
      '현덕면',
    ],
    평택시: [
      '가재동',
      '고덕면',
      '군문동',
      '도일동',
      '독곡동',
      '동삭동',
      '모곡동',
      '비전동',
      '서부동',
      '서정동',
      '서탄면',
      '세교동',
      '소사동',
      '신대동',
      '신장동',
      '신평동',
      '안중면',
      '안중읍',
      '오성면',
      '용이동',
      '월곡동',
      '유천동',
      '이충동',
      '장당동',
      '장안동',
      '죽백동',
      '지산동',
      '지제동',
      '진위면',
      '청룡동',
      '청북면',
      '청북읍',
      '칠괴동',
      '칠원동',
      '통복동',
      '팽성읍',
      '평택동',
      '포승면',
      '포승읍',
      '합정동',
      '현덕면',
    ],
    포천군: [
      '가산면',
      '관인면',
      '군내면',
      '내촌면',
      '소흘면',
      '소흘읍',
      '신북면',
      '영북면',
      '영중면',
      '이동면',
      '일동면',
      '창수면',
      '청산면',
      '포천면',
      '포천읍',
      '화현면',
    ],
    포천시: [
      '가산면',
      '관인면',
      '군내면',
      '내촌면',
      '동교동',
      '선단동',
      '설운동',
      '소흘읍',
      '신북면',
      '신읍동',
      '어룡동',
      '영북면',
      '영중면',
      '이동면',
      '일동면',
      '자작동',
      '창수면',
      '화현면',
    ],
    하남시: [
      '감북동',
      '감이동',
      '감일동',
      '광암동',
      '교산동',
      '당정동',
      '덕풍동',
      '망월동',
      '미사동',
      '배알미동',
      '상사창동',
      '상산곡동',
      '선동',
      '신장동',
      '창우동',
      '천현동',
      '초이동',
      '초일동',
      '춘궁동',
      '풍산동',
      '하사창동',
      '하산곡동',
      '학암동',
      '항동',
    ],
    화성군: [
      '남양면',
      '동탄면',
      '마도면',
      '매송면',
      '반월면',
      '봉담면',
      '봉담읍',
      '비봉면',
      '서신면',
      '송산면',
      '안용면',
      '양감면',
      '오산면',
      '오산읍',
      '우정면',
      '일왕면',
      '장안면',
      '정남면',
      '태안면',
      '태안읍',
      '태장면',
      '팔탄면',
      '향남면',
    ],
    화성시: [
      '금곡동',
      '기산동',
      '기안동',
      '남양동',
      '남양읍',
      '능동',
      '동탄면',
      '마도면',
      '매송면',
      '목동',
      '무송동',
      '문호동',
      '반송동',
      '반월동',
      '반정동',
      '방교동',
      '배양동',
      '병점동',
      '봉담읍',
      '북양동',
      '비봉면',
      '산척동',
      '새솔동',
      '서신면',
      '석우동',
      '송동',
      '송림동',
      '송산동',
      '송산면',
      '수화동',
      '시동',
      '신남동',
      '신동',
      '신외동',
      '안녕동',
      '안석동',
      '양감면',
      '영천동',
      '오산동',
      '온석동',
      '우정면',
      '우정읍',
      '원천동',
      '장덕동',
      '장안면',
      '장전동',
      '장지동',
      '정남면',
      '중동',
      '진안동',
      '청계동',
      '태안읍',
      '팔탄면',
      '향남면',
      '향남읍',
      '활초동',
      '황계동',
    ],
  },
  경상남도: {
    거제군: [
      '거제면',
      '남부면',
      '동부면',
      '둔덕면',
      '사등면',
      '신현면',
      '신현읍',
      '연초면',
      '일운면',
      '장목면',
      '장승포읍',
      '하청면',
    ],
    거제시: [
      '거제면',
      '고현동',
      '남부면',
      '능포동',
      '덕포동',
      '동부면',
      '두모동',
      '둔덕면',
      '문동동',
      '사등면',
      '삼거동',
      '상동동',
      '수월동',
      '신현읍',
      '아양동',
      '아주동',
      '양정동',
      '연초면',
      '옥포동',
      '일운면',
      '장목면',
      '장승포동',
      '장평동',
      '하청면',
    ],
    거창군: [
      '가북면',
      '가조면',
      '거창읍',
      '고제면',
      '남상면',
      '남하면',
      '마리면',
      '북상면',
      '신원면',
      '웅양면',
      '위천면',
      '주상면',
    ],
    고성군: [
      '개천면',
      '거류면',
      '고성읍',
      '구만면',
      '대가면',
      '동해면',
      '마암면',
      '삼산면',
      '상리면',
      '영오면',
      '영현면',
      '하이면',
      '하일면',
      '회화면',
    ],
    김해군: [
      '가락면',
      '김해읍',
      '녹산면',
      '대동면',
      '대저면',
      '대저읍',
      '명지면',
      '상동면',
      '생림면',
      '이북면',
      '장유면',
      '주촌면',
      '진례면',
      '진영읍',
      '한림면',
    ],
    김해시: [
      '강동',
      '관동동',
      '구산동',
      '내덕동',
      '내동',
      '내외동',
      '대동면',
      '대성동',
      '대청동',
      '동상동',
      '명법동',
      '무계동',
      '봉황동',
      '부곡동',
      '부원동',
      '북부동',
      '불암동',
      '삼계동',
      '삼문동',
      '삼방동',
      '삼안동',
      '삼정동',
      '상동면',
      '생림면',
      '서부동',
      '서상동',
      '수가동',
      '신문동',
      '안동',
      '어방동',
      '외동',
      '유하동',
      '율하동',
      '응달동',
      '이동',
      '장유동',
      '장유면',
      '전하동',
      '주촌면',
      '지내동',
      '진례면',
      '진영읍',
      '칠산동',
      '풍유동',
      '한림면',
      '화목동',
      '활천동',
      '회현동',
      '흥동',
    ],
    남해군: ['고현면', '남면', '남해면', '남해읍', '미조면', '삼동면', '상주면', '서면', '설천면', '이동면', '창선면'],
    동래군: ['기장면', '서생면', '일광면', '장안면', '정관면', '철마면'],
    마산시: [
      '가율동',
      '가음정동',
      '가포동',
      '교방동',
      '교원동',
      '구산면',
      '구암동',
      '귀곡동',
      '귀산동',
      '귀현동',
      '남산동',
      '남성동',
      '남지동',
      '내동',
      '내리동',
      '내서읍',
      '대내동',
      '대방동',
      '대외동',
      '대창동',
      '덕동',
      '덕동동',
      '덕정동',
      '도계동',
      '동성동',
      '동정동',
      '두대동',
      '두척동',
      '명곡동',
      '목동',
      '문화동',
      '반계동',
      '반송동',
      '반월동',
      '봉덕동',
      '봉림동',
      '봉암동',
      '부림동',
      '북동',
      '불모산동',
      '사파정동',
      '사화동',
      '산호동',
      '삼귀동',
      '삼동동',
      '삼정자동',
      '상남동',
      '상복동',
      '상북동',
      '상원동',
      '서곡동',
      '서상동',
      '서성동',
      '석전동',
      '성주동',
      '성호동',
      '소계동',
      '소답동',
      '수성동',
      '신월동',
      '신창동',
      '신흥동',
      '안민동',
      '양곡동',
      '양덕동',
      '연덕동',
      '예곡동',
      '오동동',
      '완암동',
      '완월동',
      '외동',
      '용동',
      '용지동',
      '우산동',
      '웅남동',
      '월림동',
      '월영동',
      '월포동',
      '유록동',
      '율림동',
      '자산동',
      '적현동',
      '정동',
      '중동',
      '중성동',
      '지귀동',
      '진동면',
      '진북면',
      '진전면',
      '차룡동',
      '창곡동',
      '창동',
      '천선동',
      '청계동',
      '추산동',
      '토월동',
      '퇴촌동',
      '팔용동',
      '평화동',
      '합성동',
      '합포동',
      '해운동',
      '현동',
      '홍문동',
      '화영동',
      '회산동',
      '회성동',
      '회원동',
    ],
    마산시합포구: [
      '가포동',
      '교방동',
      '교원동',
      '구산면',
      '남성동',
      '대내동',
      '대외동',
      '대창동',
      '덕동동',
      '동성동',
      '문화동',
      '반월동',
      '부림동',
      '산호동',
      '상남동',
      '서성동',
      '성호동',
      '수성동',
      '신월동',
      '신창동',
      '신흥동',
      '예곡동',
      '오동동',
      '완월동',
      '우산동',
      '월영동',
      '월포동',
      '유록동',
      '자산동',
      '중성동',
      '진동면',
      '진북면',
      '진전면',
      '창동',
      '청계동',
      '추산동',
      '평화동',
      '해운동',
      '현동',
      '홍문동',
      '화영동',
    ],
    마산시회원구: ['구암동', '내서면', '내서읍', '두척동', '봉암동', '석전동', '양덕동', '합성동', '회성동', '회원동'],
    밀양군: [
      '단장면',
      '무안면',
      '밀양읍',
      '부북면',
      '산내면',
      '산외면',
      '삼랑진면',
      '삼랑진읍',
      '상남면',
      '상동면',
      '청도면',
      '초동면',
      '하남면',
      '하남읍',
    ],
    밀양시: [
      '가곡동',
      '교동',
      '남포동',
      '내이동',
      '내일동',
      '단장면',
      '무안면',
      '부북면',
      '산내면',
      '산외면',
      '삼랑진읍',
      '삼문동',
      '상남면',
      '상동면',
      '용평동',
      '용활동',
      '청도면',
      '초동면',
      '하남읍',
      '활성동',
    ],
    부산시동구: ['범일동', '수정동', '좌천동', '초량동'],
    부산시동래구: [
      '거제동',
      '광안동',
      '구서동',
      '구서리',
      '금사동',
      '금성동',
      '금성리',
      '낙민동',
      '남산동',
      '남산리',
      '노포동',
      '노포리',
      '두구동',
      '두구리',
      '망미동',
      '명륜동',
      '명장동',
      '민락동',
      '반송동',
      '반여동',
      '복천동',
      '부곡동',
      '사직동',
      '서동',
      '석대동',
      '선동',
      '선리',
      '송정동',
      '송정리',
      '수안동',
      '수영동',
      '안락동',
      '연산동',
      '오륜동',
      '온천동',
      '우동',
      '우리',
      '장전동',
      '재송동',
      '좌동',
      '좌리',
      '중동',
      '중리',
      '청용동',
      '청용리',
      '칠산동',
      '회동동',
    ],
    부산시부산진: [
      '가야동',
      '감만동',
      '감전동',
      '감전리',
      '개금동',
      '괘법동',
      '괘법리',
      '구포동',
      '구포리',
      '금곡동',
      '금곡리',
      '금성동',
      '금성리',
      '남천동',
      '당감동',
      '대연동',
      '덕천동',
      '덕천리',
      '덕포동',
      '덕포리',
      '만덕동',
      '만덕리',
      '모라동',
      '모라리',
      '문현동',
      '범일동',
      '범전동',
      '부암동',
      '부전동',
      '삼락동',
      '삼락리',
      '양정동',
      '엄궁동',
      '엄궁리',
      '연지동',
      '용당동',
      '용호동',
      '우암동',
      '적기동',
      '전포동',
      '주례동',
      '주례리',
      '초읍동',
      '학장동',
      '학장리',
      '화명동',
      '화명리',
    ],
    부산시서구: [
      '감천동',
      '괴정동',
      '구평동',
      '남부민동',
      '다대동',
      '다래동',
      '당리동',
      '신평동',
      '아미동',
      '암남동',
      '장림동',
      '초장동',
      '하단동',
    ],
    부산시영도구: ['남포동', '남향동', '대교동', '동삼동', '청학동', '태평동'],
    부산시중구: ['복병동', '부평동', '영주동'],
    사천군: ['곤명면', '곤양면', '사남면', '사천읍', '서포면', '용현면', '정동면', '축동면'],
    사천시: [
      '곤명면',
      '곤양면',
      '궁지동',
      '노룡동',
      '늑도동',
      '대방동',
      '대포동',
      '동금동',
      '동동',
      '동림동',
      '마도동',
      '백천동',
      '벌리동',
      '봉남동',
      '사남면',
      '사등동',
      '사천읍',
      '서금동',
      '서동',
      '서포면',
      '선구동',
      '송포동',
      '신벽동',
      '신수동',
      '실안동',
      '와룡동',
      '용강동',
      '용현면',
      '이금동',
      '이홀동',
      '정동면',
      '좌룡동',
      '죽림동',
      '축동면',
      '향촌동',
    ],
    산청군: [
      '금서면',
      '단성면',
      '산청면',
      '산청읍',
      '삼장면',
      '생비량면',
      '생초면',
      '시천면',
      '신등면',
      '신안면',
      '오부면',
      '차황면',
    ],
    삼천포시: [
      '궁지동',
      '노대동',
      '노룡동',
      '늑도동',
      '대방동',
      '대포동',
      '동금동',
      '동동',
      '동림동',
      '동서금동',
      '동서동',
      '동좌동',
      '마도동',
      '백신동',
      '백천동',
      '벌룡동',
      '벌리동',
      '봉남동',
      '봉이동',
      '사등동',
      '서금동',
      '서동',
      '선구동',
      '송포동',
      '신벽동',
      '신수동',
      '실마동',
      '실안동',
      '와룡동',
      '용강동',
      '이궁사동',
      '이금동',
      '이홀동',
      '좌룡동',
      '죽림동',
      '향촌동',
    ],
    양산군: [
      '기장면',
      '기장읍',
      '동면',
      '물금면',
      '상북면',
      '서생면',
      '양산면',
      '양산읍',
      '웅상면',
      '웅상읍',
      '원동면',
      '일광면',
      '장안면',
      '장안읍',
      '정관면',
      '철마면',
      '하북면',
    ],
    양산시: [
      '교동',
      '남부동',
      '다방동',
      '덕계동',
      '동면',
      '매곡동',
      '명곡동',
      '명동',
      '물금읍',
      '북부동',
      '북정동',
      '산막동',
      '삼호동',
      '상북면',
      '소주동',
      '신기동',
      '어곡동',
      '용당동',
      '웅상읍',
      '원동면',
      '유산동',
      '주남동',
      '주진동',
      '중부동',
      '평산동',
      '하북면',
      '호계동',
    ],
    울산군: [
      '강동면',
      '농소면',
      '대현면',
      '두동면',
      '두서면',
      '방어진읍',
      '범서면',
      '삼남면',
      '삼동면',
      '상북면',
      '서생면',
      '언양면',
      '온산면',
      '온양면',
      '울산읍',
      '웅촌면',
      '청량면',
      '하상면',
    ],
    울산시: [
      '고사동',
      '고사리',
      '교동',
      '남외동',
      '남외리',
      '남화동',
      '남화리',
      '다운동',
      '다운리',
      '달동',
      '달리',
      '동동',
      '동리',
      '동부동',
      '동부리',
      '두왕동',
      '두왕리',
      '매암동',
      '매암리',
      '명촌동',
      '명촌리',
      '무거동',
      '무거리',
      '미포동',
      '미포리',
      '반구동',
      '반구리',
      '방어동',
      '방어리',
      '복산동',
      '부곡동',
      '부곡리',
      '북정동',
      '삼산동',
      '삼산리',
      '상개동',
      '상개리',
      '서동',
      '서리',
      '서부동',
      '서부리',
      '선암동',
      '선암리',
      '성남동',
      '성안동',
      '성안리',
      '성암동',
      '성암리',
      '송정동',
      '송정리',
      '신정동',
      '신정리',
      '야음동',
      '야음리',
      '약사동',
      '약사리',
      '양정동',
      '양정리',
      '여천동',
      '여천리',
      '연암동',
      '연암리',
      '염포동',
      '염포리',
      '옥교동',
      '옥동',
      '옥리',
      '용연동',
      '용연리',
      '용잠동',
      '용잠리',
      '우정동',
      '유곡동',
      '유곡리',
      '일산동',
      '일산리',
      '장생포동',
      '장생포리',
      '장현동',
      '장현리',
      '전하동',
      '전하리',
      '주전동',
      '주전리',
      '진장동',
      '진장리',
      '태화동',
      '태화리',
      '학산동',
      '학성동',
      '화봉동',
      '화봉리',
      '화정동',
      '화정리',
      '황성동',
      '황성리',
      '효문동',
      '효문리',
    ],
    울산시남구: [
      '고사동',
      '남화동',
      '달동',
      '두왕동',
      '매암동',
      '무거동',
      '부곡동',
      '삼산동',
      '상개동',
      '선암동',
      '성암동',
      '신정동',
      '야음동',
      '여천동',
      '옥동',
      '용연동',
      '용잠동',
      '장생포동',
      '황성동',
    ],
    울산시동구: ['동부동', '미포동', '방어동', '서부동', '일산동', '전하동', '주전동', '화정동'],
    울산시울주구: [
      '강동면',
      '농소면',
      '농소읍',
      '두동면',
      '두서면',
      '범서면',
      '삼남면',
      '삼동면',
      '상북면',
      '서생면',
      '언양면',
      '언양읍',
      '온산면',
      '온산읍',
      '온양면',
      '웅촌면',
      '청량면',
    ],
    울산시중구: [
      '교동',
      '남외동',
      '다운동',
      '동동',
      '동부동',
      '명촌동',
      '미포동',
      '반구동',
      '방어동',
      '병영동',
      '복산동',
      '북정동',
      '서동',
      '서부동',
      '성남동',
      '성안동',
      '송정동',
      '약사동',
      '양정동',
      '연암동',
      '염포동',
      '옥교동',
      '우정동',
      '유곡동',
      '일산동',
      '장현동',
      '전하동',
      '주전동',
      '진장동',
      '태화동',
      '학산동',
      '학성동',
      '화봉동',
      '화정동',
      '효문동',
    ],
    울주군: [
      '강동면',
      '농소면',
      '두동면',
      '두서면',
      '범서면',
      '삼남면',
      '삼동면',
      '상북면',
      '서생면',
      '언양면',
      '온산면',
      '온양면',
      '웅촌면',
      '청량면',
    ],
    의령군: [
      '가례면',
      '궁류면',
      '궁유면',
      '낙서면',
      '대의면',
      '봉수면',
      '부림면',
      '용덕면',
      '유곡면',
      '의령면',
      '의령읍',
      '정곡면',
      '지정면',
      '칠곡면',
      '화정면',
    ],
    의창군: ['구산면', '내서면', '대산면', '동면', '북면', '웅동면', '진동면', '진북면', '진전면', '천가면'],
    장승포시: ['능포동', '덕포동', '두모동', '마전동', '아양동', '아주동', '옥포동', '장승포동'],
    진양군: [
      '금곡면',
      '금산면',
      '내동면',
      '대곡면',
      '대평면',
      '명석면',
      '문산면',
      '미천면',
      '사봉면',
      '수곡면',
      '이반성면',
      '일반성면',
      '정촌면',
      '지수면',
      '진성면',
      '집현면',
    ],
    진주시: [
      '가좌동',
      '가호동',
      '강남동',
      '계동',
      '귀곡동',
      '금곡면',
      '금산면',
      '남성동',
      '내동면',
      '대곡면',
      '대안동',
      '대평면',
      '동성동',
      '망경남동',
      '망경동',
      '망경북동',
      '명석면',
      '문산면',
      '문산읍',
      '미천면',
      '본성동',
      '봉곡동',
      '봉래동',
      '사봉면',
      '상대동',
      '상봉동',
      '상봉동동',
      '상봉서동',
      '상평동',
      '상평동동',
      '상평서동',
      '수곡면',
      '수정남동',
      '수정동',
      '수정북동',
      '신안동',
      '옥봉남동',
      '옥봉동',
      '옥봉북동',
      '유곡동',
      '이반성면',
      '이현남동',
      '이현동',
      '이현북동',
      '인사동',
      '일반성면',
      '장대동',
      '장재동',
      '정촌면',
      '주약동',
      '중안동',
      '지수면',
      '진성면',
      '집현면',
      '초전남동',
      '초전동',
      '초전북동',
      '충무공동',
      '칠암동',
      '판문남동',
      '판문동',
      '판문북동',
      '평거동',
      '평안동',
      '하대동',
      '하촌동',
      '호탄동',
    ],
    진해시: [
      '가주동',
      '경화동',
      '광화동',
      '근화동',
      '남문동',
      '남빈동',
      '남양동',
      '대영동',
      '대장동',
      '대죽동',
      '대천동',
      '대흥동',
      '덕산동',
      '도만동',
      '도천동',
      '동상동',
      '두동',
      '마천동',
      '명동',
      '무송동',
      '부흥동',
      '북부동',
      '비봉동',
      '서중동',
      '석동',
      '성내동',
      '소사동',
      '속천동',
      '송죽동',
      '송학동',
      '수도동',
      '수송동',
      '숭인동',
      '신흥동',
      '안곡동',
      '안골동',
      '앵곡동',
      '여좌동',
      '연도동',
      '용원동',
      '원포동',
      '이동',
      '익선동',
      '인사동',
      '인의동',
      '자은동',
      '장천동',
      '제덕동',
      '제황산동',
      '죽곡동',
      '중앙동',
      '중평동',
      '창선동',
      '청안동',
      '충무동',
      '충의동',
      '태백동',
      '태평동',
      '통신동',
      '평안동',
      '풍호동',
      '행암동',
      '현동',
      '화천동',
      '회현동',
    ],
    창녕군: [
      '계성면',
      '고암면',
      '길곡면',
      '남지면',
      '남지읍',
      '대지면',
      '대합면',
      '도천면',
      '부곡면',
      '성산면',
      '영산면',
      '유어면',
      '이방면',
      '장마면',
      '창녕읍',
    ],
    창원군: [
      '구산면',
      '내서면',
      '대산면',
      '동면',
      '북면',
      '상남면',
      '웅남면',
      '웅동면',
      '웅천면',
      '진동면',
      '진북면',
      '진전면',
      '창원면',
      '창원읍',
      '천가면',
    ],
    창원시: [
      '가음동',
      '가음정동',
      '귀곡동',
      '귀산동',
      '귀현동',
      '남산동',
      '남양동',
      '남지동',
      '내동',
      '내리동',
      '대방동',
      '대산면',
      '대원동',
      '덕정동',
      '도계동',
      '동면',
      '동읍',
      '동정동',
      '두대동',
      '명곡동',
      '명서동',
      '목동',
      '반계동',
      '반림동',
      '반송동',
      '반지동',
      '봉곡동',
      '봉림동',
      '북동',
      '북면',
      '불모산동',
      '사림동',
      '사파동',
      '사파정동',
      '사화동',
      '삼귀동',
      '삼동동',
      '삼정자동',
      '상남동',
      '상복동',
      '상북동',
      '서곡동',
      '서상동',
      '성산동',
      '성주동',
      '소계동',
      '소답동',
      '신월동',
      '신촌동',
      '안민동',
      '양곡동',
      '연덕동',
      '완암동',
      '외동',
      '용동',
      '용지동',
      '용호동',
      '웅남동',
      '월림동',
      '의안동',
      '적현동',
      '정동',
      '중동',
      '중앙동',
      '지귀동',
      '차용동',
      '창곡동',
      '천선동',
      '토월동',
      '퇴촌동',
      '팔용동',
    ],
    창원시마산합포구: [
      '가포동',
      '교방동',
      '교원동',
      '구산면',
      '남성동',
      '대내동',
      '대외동',
      '대창동',
      '덕동동',
      '동성동',
      '문화동',
      '반월동',
      '부림동',
      '산호동',
      '상남동',
      '서성동',
      '성호동',
      '수성동',
      '신월동',
      '신창동',
      '신흥동',
      '예곡동',
      '오동동',
      '완월동',
      '우산동',
      '월영동',
      '월포동',
      '유록동',
      '자산동',
      '중성동',
      '진동면',
      '진북면',
      '진전면',
      '창동',
      '청계동',
      '추산동',
      '평화동',
      '해운동',
      '현동',
      '홍문동',
      '화영동',
    ],
    창원시마산회원구: ['구암동', '내서읍', '두척동', '봉암동', '석전동', '양덕동', '합성동', '회성동', '회원동'],
    창원시성산구: [
      '가음동',
      '가음정동',
      '귀곡동',
      '귀산동',
      '귀현동',
      '남산동',
      '남양동',
      '남지동',
      '내동',
      '대방동',
      '대원동',
      '덕정동',
      '두대동',
      '반림동',
      '반송동',
      '반지동',
      '불모산동',
      '사파동',
      '사파정동',
      '삼동동',
      '삼정자동',
      '상남동',
      '상복동',
      '성산동',
      '성주동',
      '신월동',
      '신촌동',
      '안민동',
      '양곡동',
      '완암동',
      '외동',
      '용지동',
      '용호동',
      '웅남동',
      '월림동',
      '적현동',
      '중앙동',
      '창곡동',
      '천선동',
      '토월동',
      '퇴촌동',
    ],
    창원시의창구: [
      '내리동',
      '대산면',
      '대원동',
      '덕정동',
      '도계동',
      '동읍',
      '동정동',
      '두대동',
      '명곡동',
      '명서동',
      '반계동',
      '반송동',
      '봉곡동',
      '봉림동',
      '북동',
      '북면',
      '사림동',
      '사화동',
      '삼동동',
      '서곡동',
      '서상동',
      '소계동',
      '소답동',
      '신월동',
      '용동',
      '용지동',
      '용호동',
      '중동',
      '지귀동',
      '차용동',
      '퇴촌동',
      '팔용동',
    ],
    창원시진해구: [
      '가주동',
      '경화동',
      '광화동',
      '근화동',
      '남문동',
      '남빈동',
      '남양동',
      '대영동',
      '대장동',
      '대죽동',
      '대천동',
      '대흥동',
      '덕산동',
      '도만동',
      '도천동',
      '동상동',
      '두동',
      '마천동',
      '명동',
      '무송동',
      '부흥동',
      '북부동',
      '비봉동',
      '서중동',
      '석동',
      '성내동',
      '소사동',
      '속천동',
      '송죽동',
      '송학동',
      '수도동',
      '수송동',
      '숭인동',
      '신흥동',
      '안곡동',
      '안골동',
      '앵곡동',
      '여좌동',
      '연도동',
      '용원동',
      '원포동',
      '이동',
      '익선동',
      '인사동',
      '인의동',
      '자은동',
      '장천동',
      '제덕동',
      '제황산동',
      '죽곡동',
      '중앙동',
      '중평동',
      '창선동',
      '청안동',
      '충무동',
      '충의동',
      '태백동',
      '태평동',
      '통신동',
      '평안동',
      '풍호동',
      '행암동',
      '현동',
      '화천동',
      '회현동',
    ],
    충무시: [
      '당동',
      '도남동',
      '도천동',
      '동호동',
      '명정동',
      '무전동',
      '문화동',
      '미수동',
      '봉평동',
      '북신동',
      '서호동',
      '인평동',
      '정량동',
      '중앙동',
      '태평동',
      '평림동',
      '항남동',
    ],
    통영군: ['광도면', '도산면', '사량면', '산양면', '욕지면', '용남면', '한산면'],
    통영시: [
      '광도면',
      '당동',
      '도남동',
      '도산면',
      '도천동',
      '동호동',
      '명정동',
      '무전동',
      '문화동',
      '미수동',
      '봉평동',
      '북신동',
      '사량면',
      '산양면',
      '산양읍',
      '서호동',
      '욕지면',
      '용남면',
      '인평동',
      '정량동',
      '중앙동',
      '태평동',
      '평림동',
      '한산면',
      '항남동',
    ],
    하동군: [
      '고전면',
      '금남면',
      '금성면',
      '북천면',
      '악양면',
      '양보면',
      '옥종면',
      '적량면',
      '진교면',
      '청암면',
      '하동읍',
      '화개면',
      '횡천면',
    ],
    함안군: [
      '가야면',
      '가야읍',
      '군북면',
      '대산면',
      '법수면',
      '산인면',
      '여항면',
      '칠북면',
      '칠서면',
      '칠원면',
      '칠원읍',
      '함안면',
    ],
    함양군: [
      '마천면',
      '백전면',
      '병곡면',
      '서상면',
      '서하면',
      '수동면',
      '안의면',
      '유림면',
      '지곡면',
      '함양읍',
      '휴천면',
    ],
    합천군: [
      '가야면',
      '가회면',
      '대병면',
      '대양면',
      '덕곡면',
      '묘산면',
      '봉산면',
      '삼가면',
      '쌍백면',
      '쌍책면',
      '야로면',
      '용주면',
      '율곡면',
      '적중면',
      '청덕면',
      '초계면',
      '합천면',
      '합천읍',
    ],
  },
  경상북도: {
    경산군: [
      '경산읍',
      '고산면',
      '남산면',
      '남천면',
      '안심면',
      '안심읍',
      '압량면',
      '와촌면',
      '용성면',
      '자인면',
      '진량면',
      '하양면',
      '하양읍',
    ],
    경산시: [
      '갑제동',
      '계양동',
      '남방동',
      '남부동',
      '남산면',
      '남천면',
      '내동',
      '대동',
      '대정동',
      '대평동',
      '동부동',
      '백천동',
      '북부동',
      '사동',
      '사정동',
      '삼남동',
      '삼북동',
      '삼풍동',
      '상방동',
      '서부동',
      '서상동',
      '신교동',
      '신천동',
      '압량면',
      '압량읍',
      '여천동',
      '옥곡동',
      '옥산동',
      '와촌면',
      '용성면',
      '유곡동',
      '임당동',
      '자인면',
      '점촌동',
      '정평동',
      '조영동',
      '중방동',
      '중산동',
      '중앙동',
      '진량면',
      '진량읍',
      '평산동',
      '하양읍',
    ],
    경주군: [
      '감포읍',
      '강동면',
      '건천읍',
      '내남면',
      '산내면',
      '서면',
      '안강읍',
      '양남면',
      '양북면',
      '외동읍',
      '천북면',
      '현곡면',
    ],
    경주시: [
      '감포읍',
      '강동면',
      '건천읍',
      '광명동',
      '교동',
      '구정동',
      '구황동',
      '남산동',
      '내남면',
      '노동동',
      '노서동',
      '덕동',
      '도동동',
      '도지동',
      '동방동',
      '동부동',
      '동천동',
      '마동',
      '문무대왕면',
      '배동',
      '배반동',
      '보덕동',
      '보문동',
      '보황동',
      '북군동',
      '북부동',
      '불국동',
      '사정동',
      '산내면',
      '서면',
      '서부동',
      '서악동',
      '석장동',
      '선도동',
      '성건동',
      '성내동',
      '성동동',
      '손곡동',
      '시동',
      '시래동',
      '신평동',
      '안강읍',
      '암곡동',
      '양남면',
      '양북면',
      '외동읍',
      '용강동',
      '용황동',
      '율동',
      '인교동',
      '인왕동',
      '정래동',
      '조양동',
      '중앙동',
      '진현동',
      '천군동',
      '천북면',
      '충효동',
      '탑동',
      '탑정동',
      '평동',
      '하동',
      '현곡면',
      '황남동',
      '황성동',
      '황오동',
      '황용동',
      '효현동',
    ],
    고령군: ['개진면', '고령면', '고령읍', '다산면', '대가야읍', '덕곡면', '성산면', '쌍림면', '우곡면', '운수면'],
    구미시: [
      '거의동',
      '고아면',
      '고아읍',
      '공단동',
      '광평동',
      '구평동',
      '구포동',
      '금전동',
      '남통동',
      '도개면',
      '도량동',
      '도산동',
      '무을면',
      '봉곡동',
      '부곡동',
      '비산동',
      '사곡동',
      '산동면',
      '산동읍',
      '상모동',
      '선기동',
      '선산읍',
      '선주동',
      '송정동',
      '수점동',
      '시미동',
      '신동',
      '신시동',
      '신평동',
      '양포동',
      '양호동',
      '오태동',
      '옥계동',
      '옥성면',
      '원남동',
      '원평동',
      '인동동',
      '인의동',
      '임수동',
      '임오동',
      '임은동',
      '장천면',
      '지산동',
      '진미동',
      '진평동',
      '해평면',
      '형곡동',
      '황상동',
    ],
    군위군: ['고로면', '군위면', '군위읍', '부계면', '산성면', '소보면', '우보면', '의흥면', '효령면'],
    금릉군: [
      '감문면',
      '감천면',
      '개령면',
      '구성면',
      '남면',
      '농소면',
      '대덕면',
      '대항면',
      '봉산면',
      '부항면',
      '아포면',
      '어모면',
      '조마면',
      '증산면',
      '지례면',
    ],
    김천시: [
      '감문면',
      '감천면',
      '감호동',
      '개령면',
      '교동',
      '구성면',
      '금산동',
      '남면',
      '남산동',
      '농소면',
      '다수동',
      '대광동',
      '대덕면',
      '대응동',
      '대항면',
      '덕곡동',
      '모암동',
      '문당동',
      '미곡동',
      '백옥동',
      '봉산면',
      '부곡동',
      '부항면',
      '삼락동',
      '성내동',
      '신음동',
      '아포면',
      '아포읍',
      '양천동',
      '어모면',
      '용두동',
      '용호동',
      '율곡동',
      '응명동',
      '조마면',
      '증산면',
      '지례면',
      '지좌동',
      '평화동',
      '황금동',
    ],
    달성군: [
      '가창면',
      '공산면',
      '구지면',
      '논공면',
      '다사면',
      '성서면',
      '성서읍',
      '옥포면',
      '월배면',
      '월배읍',
      '유가면',
      '하빈면',
      '현풍면',
      '화원면',
      '화원읍',
    ],
    대구시: [
      '갈산동',
      '감삼동',
      '검단동',
      '검사동',
      '공평동',
      '교동',
      '남산동',
      '남성로',
      '남일동',
      '내당동',
      '내동',
      '노곡동',
      '능성동',
      '단산동',
      '달성동',
      '대명동',
      '대봉동',
      '대신동',
      '대안동',
      '대일동',
      '대천동',
      '대현동',
      '덕곡동',
      '덕산동',
      '도동',
      '도원동(桃園)',
      '도원동(桃源)',
      '도학동',
      '동문동',
      '동변동',
      '동산동',
      '동일동',
      '두산동',
      '둔산동',
      '만촌동',
      '문화동',
      '미곡동',
      '방촌동',
      '백안동',
      '범물동',
      '범어동',
      '복현동',
      '본리동',
      '봉덕동',
      '봉무동',
      '봉산동',
      '부동',
      '북내동',
      '불로동',
      '비산동',
      '사일동',
      '산격동',
      '삼각동',
      '상덕동',
      '상동',
      '상리동',
      '상서동',
      '상원동',
      '상인동',
      '서내동',
      '서변동',
      '서야동',
      '성당동',
      '송정동',
      '송현동',
      '수동',
      '수성동',
      '수창동',
      '시장북로',
      '신당동',
      '신무동',
      '신암동',
      '신용동',
      '신천동',
      '신평동',
      '연경동',
      '완전동',
      '용덕동',
      '용산동',
      '용수동',
      '우미동',
      '원대동',
      '월성동',
      '월암동',
      '유천동',
      '읍산동',
      '읍호동',
      '이곡동',
      '이현동',
      '인교동',
      '입석동',
      '장관동',
      '장기동',
      '장동',
      '전동',
      '정대동',
      '조야동',
      '주동',
      '죽전동',
      '중대동',
      '중동',
      '지모동',
      '지사동',
      '지산동',
      '지저동',
      '진안동',
      '진천동',
      '침산동',
      '파동',
      '파산동',
      '파호동',
      '평광동',
      '평리동',
      '포정동',
      '하서동',
      '한천동',
      '행정동',
      '향촌동',
      '호림동',
      '화전동',
      '효목동',
    ],
    대구시남구: [
      '남산동',
      '대곡동',
      '대명동',
      '대봉동',
      '대천동',
      '도원동',
      '봉덕동',
      '상인동',
      '송현동',
      '월성동',
      '월암동',
      '유천동',
      '이천동',
      '진천동',
    ],
    대구시동구: [
      '각산동',
      '검사동',
      '괴전동',
      '금강동',
      '내곡동',
      '내동',
      '능성동',
      '대림동',
      '덕곡동',
      '도동',
      '도학동',
      '동내동',
      '동호동',
      '두산동',
      '둔산동',
      '만촌동',
      '매여동',
      '미곡동',
      '미대동',
      '방촌동',
      '백안동',
      '범물동',
      '범어동',
      '봉무동',
      '부동',
      '불로동',
      '사복동',
      '상동',
      '상매동',
      '서호동',
      '송정동',
      '숙천동',
      '신기동',
      '신무동',
      '신암동',
      '신용동',
      '신천동',
      '신평동',
      '안심동',
      '연경동',
      '용계동',
      '용수동',
      '율암동',
      '율화동',
      '입석동',
      '중대동',
      '중동',
      '지묘동',
      '지산동',
      '지저동',
      '진안동',
      '파동',
      '평광동',
      '황금동',
      '황천동',
      '황청동',
      '효목동',
    ],
    대구시북구: [
      '검단동',
      '관음동',
      '노곡동',
      '대현동',
      '도남동',
      '동변동',
      '동천동',
      '복현동',
      '사수동',
      '산격동',
      '서변동',
      '읍내동',
      '조야동',
      '침산동',
      '학정동',
    ],
    대구시서구: [
      '갈산동',
      '감삼동',
      '내당동',
      '노곡동',
      '본리동',
      '비산동',
      '상리동',
      '성당동',
      '신당동',
      '용산동',
      '원대동',
      '이곡동',
      '이현동',
      '장기동',
      '장동',
      '조야동',
      '죽전동',
      '중리동',
      '파산동',
      '파호동',
      '평리동',
      '호림동',
    ],
    대구시수성구: [
      '가천동',
      '고모동',
      '내관동',
      '노변동',
      '두산동',
      '만촌동',
      '매호동',
      '범물동',
      '범어동',
      '사월동',
      '삼덕동',
      '상동',
      '성동',
      '시지동',
      '신매동',
      '연호동',
      '욱수동',
      '이천동',
      '중동',
      '지산동',
      '파동',
      '황금동',
    ],
    대구시중구: [
      '공평동',
      '교동',
      '남산동',
      '남성로',
      '남일동',
      '달성동',
      '대봉동',
      '대신동',
      '대안동',
      '덕산동',
      '도원동',
      '동문동',
      '동산동',
      '동일동',
      '문화동',
      '봉산동',
      '북내동',
      '사일동',
      '상덕동',
      '상서동',
      '서내동',
      '서야동',
      '수동',
      '수창동',
      '시장북로',
      '신암동',
      '완전동',
      '용덕동',
      '인교동',
      '장관동',
      '전동',
      '포정동',
      '하서동',
      '향촌동',
      '화전동',
    ],
    문경군: [
      '가은면',
      '가은읍',
      '농암면',
      '동로면',
      '마성면',
      '문경면',
      '문경읍',
      '산북면',
      '산양면',
      '영순면',
      '점촌읍',
      '호계면',
    ],
    문경시: [
      '가은읍',
      '공평동',
      '농암면',
      '동로면',
      '마성면',
      '모전동',
      '문경읍',
      '불정동',
      '산북면',
      '산양면',
      '신기동',
      '영순면',
      '영신동',
      '우지동',
      '유곡동',
      '윤직동',
      '점촌동',
      '창동',
      '호계면',
      '흥덕동',
    ],
    봉화군: [
      '명호면',
      '물야면',
      '법전면',
      '봉성면',
      '봉화면',
      '봉화읍',
      '상운면',
      '석포면',
      '소천면',
      '재산면',
      '춘양면',
    ],
    상주군: [
      '공검면',
      '공성면',
      '낙동면',
      '내서면',
      '모동면',
      '모서면',
      '사벌면',
      '상주읍',
      '외남면',
      '외서면',
      '은척면',
      '이안면',
      '중동면',
      '청리면',
      '함창면',
      '함창읍',
      '화남면',
      '화동면',
      '화북면',
      '화서면',
    ],
    상주시: [
      '가장동',
      '개운동',
      '거동동',
      '계림동',
      '계산동',
      '공검면',
      '공성면',
      '낙동면',
      '낙상동',
      '낙양동',
      '남성동',
      '남원동',
      '남장동',
      '남적동',
      '내서면',
      '냉림동',
      '도남동',
      '동문동',
      '동성동',
      '만산동',
      '모동면',
      '모서면',
      '무양동',
      '병성동',
      '복룡동',
      '부원동',
      '북문동',
      '사벌국면',
      '사벌면',
      '서곡동',
      '서문동',
      '서성동',
      '성동동',
      '성하동',
      '신봉동',
      '신흥동',
      '양촌동',
      '연원동',
      '오대동',
      '외남면',
      '외답동',
      '외서면',
      '은척면',
      '이안면',
      '인봉동',
      '인평동',
      '죽전동',
      '중덕동',
      '중동면',
      '중앙동',
      '지천동',
      '청리면',
      '초산동',
      '함창읍',
      '헌신동',
      '화개동',
      '화남면',
      '화동면',
      '화북면',
      '화산동',
      '화서면',
      '흥각동',
    ],
    선산군: [
      '고아면',
      '구미면',
      '구미읍',
      '도개면',
      '무을면',
      '산동면',
      '선산면',
      '선산읍',
      '옥성면',
      '장천면',
      '해평면',
    ],
    성주군: [
      '가천면',
      '금수면',
      '대가면',
      '벽진면',
      '선남면',
      '성주면',
      '성주읍',
      '수륜면',
      '용암면',
      '월항면',
      '초전면',
    ],
    신안동시: [
      '광석동',
      '금곡동',
      '남문동',
      '남부동',
      '노하동',
      '당북동',
      '대석동',
      '동문동',
      '동부동',
      '명륜동',
      '목성동',
      '법상동',
      '법흥동',
      '북문동',
      '삼산동',
      '상아동',
      '서부동',
      '석동동',
      '성곡동',
      '송천동',
      '송현동',
      '수상동',
      '수하동',
      '신세동',
      '신안동',
      '안기동',
      '안막동',
      '안흥동',
      '옥동',
      '옥야동',
      '옥정동',
      '용상동',
      '운안동',
      '운흥동',
      '율세동',
      '이천동',
      '정상동',
      '정하동',
      '천리동',
      '태화동',
      '평화동',
      '화성동',
    ],
    안동군: [
      '길안면',
      '남선면',
      '남후면',
      '녹전면',
      '도산면',
      '북후면',
      '서후면',
      '안동읍',
      '예안면',
      '와룡면',
      '월곡면',
      '일직면',
      '임동면',
      '임하면',
      '풍산면',
      '풍산읍',
      '풍천면',
    ],
    안동시: [
      '강남동',
      '광석동',
      '금곡동',
      '길안면',
      '남문동',
      '남부동',
      '남선면',
      '남후면',
      '노하동',
      '녹전면',
      '당북동',
      '대석동',
      '대신동',
      '대흥동',
      '도산면',
      '동남동',
      '동문동',
      '동부동',
      '명륜동',
      '목성동',
      '법상동',
      '법흥동',
      '북문동',
      '북후면',
      '삼산동',
      '상아동',
      '서부동',
      '서후면',
      '석동동',
      '성곡동',
      '송천동',
      '송하동',
      '송현동',
      '수상동',
      '수하동',
      '신세동',
      '신안동',
      '신흥동',
      '안기동',
      '안막동',
      '안흥동',
      '예안면',
      '옥동',
      '옥야동',
      '옥율동',
      '옥정동',
      '와룡면',
      '용상동',
      '용성동',
      '운안동',
      '운흥동',
      '율세동',
      '이천동',
      '일직면',
      '임동면',
      '임하면',
      '정상동',
      '정하동',
      '중구동',
      '천리동',
      '태화동',
      '평화동',
      '풍산읍',
      '풍천면',
      '화성동',
    ],
    영덕군: ['강구면', '남정면', '달산면', '병곡면', '영덕면', '영덕읍', '영해면', '지품면', '창수면', '축산면'],
    영양군: ['석보면', '수비면', '영양면', '영양읍', '일월면', '입암면', '청기면'],
    영일군: [
      '구룡포읍',
      '기계면',
      '기북면',
      '대보면',
      '대송면',
      '동해면',
      '송라면',
      '신광면',
      '연일면',
      '연일읍',
      '오천면',
      '오천읍',
      '의창면',
      '의창읍',
      '장기면',
      '조촌면',
      '죽장면',
      '지행면',
      '청하면',
      '흥해읍',
    ],
    영주군: [
      '단산면',
      '문수면',
      '봉현면',
      '부석면',
      '순흥면',
      '안정면',
      '영주읍',
      '이산면',
      '장수면',
      '평은면',
      '풍기면',
      '풍기읍',
    ],
    영주시: [
      '가흥동',
      '고현동',
      '단산면',
      '문수면',
      '문정동',
      '봉현면',
      '부석면',
      '상망동',
      '상줄동',
      '순흥면',
      '아지동',
      '안정면',
      '영주동',
      '이산면',
      '장수면',
      '적서동',
      '조암동',
      '조와동',
      '창진동',
      '평은면',
      '풍기읍',
      '하망동',
      '휴천동',
    ],
    영천군: [
      '고경면',
      '금호면',
      '금호읍',
      '대창면',
      '북안면',
      '신령면',
      '영천읍',
      '임고면',
      '자양면',
      '청통면',
      '화남면',
      '화북면',
      '화산면',
    ],
    영천시: [
      '고경면',
      '과전동',
      '괴연동',
      '교동',
      '교촌동',
      '금노동',
      '금호읍',
      '녹전동',
      '대전동',
      '대창면',
      '도남동',
      '도동',
      '도림동',
      '동부동',
      '망정동',
      '매산동',
      '명산동',
      '문내동',
      '문외동',
      '범어동',
      '본촌동',
      '봉동',
      '봉작동',
      '북안면',
      '서산동',
      '성내동',
      '신기동',
      '신녕면',
      '쌍계동',
      '야사동',
      '언하동',
      '영도동',
      '오미동',
      '오수동',
      '완산동',
      '임고면',
      '자양면',
      '작산동',
      '조교동',
      '주남동',
      '중앙동',
      '창구동',
      '채신동',
      '청통면',
      '화남면',
      '화룡동',
      '화북면',
      '화산면',
    ],
    영풍군: ['단산면', '문수면', '봉현면', '부석면', '순흥면', '안정면', '이산면', '장수면', '평은면', '풍기읍'],
    예천군: [
      '감천면',
      '개포면',
      '보문면',
      '상리면',
      '예천읍',
      '용궁면',
      '용문면',
      '유천면',
      '은풍면',
      '지보면',
      '풍양면',
      '하리면',
      '호명면',
      '효자면',
    ],
    울릉군: ['남면', '북면', '서면', '울릉읍'],
    울진군: [
      '근남면',
      '금강송면',
      '기성면',
      '매화면',
      '북면',
      '서면',
      '온정면',
      '울진면',
      '울진읍',
      '원남면',
      '죽변면',
      '평해면',
      '평해읍',
      '후포면',
    ],
    월성군: [
      '감포읍',
      '강동면',
      '건천읍',
      '내남면',
      '산내면',
      '서면',
      '안강읍',
      '양남면',
      '양북면',
      '외동면',
      '외동읍',
      '천북면',
      '현곡면',
    ],
    의성군: [
      '가음면',
      '구천면',
      '금성면',
      '다인면',
      '단밀면',
      '단북면',
      '단촌면',
      '봉양면',
      '비안면',
      '사곡면',
      '신평면',
      '안계면',
      '안사면',
      '안평면',
      '옥산면',
      '의성읍',
      '점곡면',
      '춘산면',
    ],
    점촌시: [
      '공평동',
      '대성동',
      '모전동',
      '불정동',
      '신기동',
      '신흥동',
      '영신동',
      '우지동',
      '유곡동',
      '윤직동',
      '점촌동',
      '중앙동',
      '창동',
      '충현동',
      '흥덕동',
    ],
    청도군: ['각남면', '각북면', '금천면', '매전면', '운문면', '이서면', '청도읍', '풍각면', '화양면', '화양읍'],
    청송군: ['부남면', '부동면', '안덕면', '주왕산면', '진보면', '청송면', '청송읍', '파천면', '현동면', '현서면'],
    칠곡군: [
      '가산면',
      '기산면',
      '동명면',
      '북삼면',
      '북삼읍',
      '석적면',
      '석적읍',
      '약목면',
      '왜관읍',
      '인동면',
      '지천면',
      '칠곡면',
      '칠곡읍',
    ],
    포항시: [
      '괴동',
      '남빈동',
      '대도동',
      '대신동',
      '대이동',
      '대잠동',
      '대흥동',
      '덕산동',
      '덕수동',
      '동빈동',
      '동촌동',
      '두호동',
      '득량동',
      '상도동',
      '상원동',
      '송내동',
      '송도동',
      '송정동',
      '신흥동',
      '양덕동',
      '양학동',
      '여남동',
      '여천동',
      '용흥동',
      '우창동',
      '우현동',
      '이동',
      '인덕동',
      '일월동',
      '장량동',
      '장성동',
      '장흥동',
      '제철동',
      '죽도동',
      '중앙동',
      '지곡동',
      '창포동',
      '청림동',
      '학산동',
      '학잠동',
      '항구동',
      '해도동',
      '호동',
      '환여동',
      '환호동',
      '효곡동',
      '효자동',
    ],
    포항시남구: [
      '괴동동',
      '구룡포읍',
      '대도동',
      '대보면',
      '대송면',
      '대잠동',
      '동촌동',
      '동해면',
      '상도동',
      '송내동',
      '송도동',
      '송정동',
      '연일읍',
      '오천읍',
      '이동',
      '인덕동',
      '일월동',
      '장기면',
      '장흥동',
      '지곡동',
      '청림동',
      '해도동',
      '호동',
      '호미곶면',
      '효자동',
    ],
    포항시북구: [
      '기계면',
      '기북면',
      '남빈동',
      '대신동',
      '대흥동',
      '대흥동(두번)',
      '덕산동',
      '덕수동',
      '두호동',
      '득량동',
      '상원동',
      '송라면',
      '신광면',
      '신흥동',
      '양덕동',
      '여남동',
      '여천동',
      '용흥동',
      '우현동',
      '장성동',
      '죽도동',
      '죽장면',
      '중앙동',
      '창포동',
      '청하면',
      '학산동',
      '학잠동',
      '항구동',
      '환호동',
      '흥해읍',
    ],
  },
  광주광역시: {
    광산구: [
      '고룡동',
      '광산동',
      '구소동',
      '금호동',
      '남산동',
      '내산동',
      '대산동',
      '대지동',
      '덕림동',
      '도금동',
      '도덕동',
      '도산동',
      '도천동',
      '도호동',
      '동림동',
      '동산동',
      '동호동',
      '두정동',
      '등임동',
      '마륵동',
      '매월동',
      '명도동',
      '명화동',
      '박호동',
      '벽진동',
      '복룡동',
      '본덕동',
      '북산동',
      '비아동',
      '사호동',
      '산막동',
      '산수동',
      '산월동',
      '산정동',
      '삼거동',
      '삼도동',
      '서봉동',
      '서창동',
      '석정동',
      '선동',
      '선암동',
      '세하동',
      '소촌동',
      '송대동',
      '송산동',
      '송정동',
      '송촌동',
      '송치동',
      '송학동',
      '수완동',
      '승촌동',
      '신가동',
      '신동',
      '신룡동',
      '신장동',
      '신창동',
      '신촌동',
      '쌍암동',
      '안청동',
      '압촌동',
      '양과동',
      '양동',
      '양산동',
      '양촌동',
      '연산동',
      '오산동',
      '오선동',
      '오운동',
      '옥동',
      '왕동',
      '요기동',
      '용곡동',
      '용동',
      '용두동',
      '용봉동',
      '우산동',
      '운남동',
      '운수동',
      '원산동',
      '월계동',
      '월곡동',
      '월성동',
      '월전동',
      '유계동',
      '이장동',
      '임곡동',
      '장덕동',
      '장록동',
      '장수동',
      '지산동',
      '지석동',
      '지정동',
      '지죽동',
      '지평동',
      '진곡동',
      '칠석동',
      '풍암동',
      '하남동',
      '하산동',
      '화장동',
      '황룡동',
      '흑석동',
    ],
    남구: [
      '구동',
      '구소동',
      '노대동',
      '대지동',
      '덕남동',
      '도금동',
      '방림동',
      '백운동',
      '봉선동',
      '사동',
      '서동',
      '석정동',
      '송하동',
      '승촌동',
      '신장동',
      '압촌동',
      '양과동',
      '양림동',
      '양촌동',
      '원산동',
      '월산동',
      '월성동',
      '이장동',
      '임암동',
      '주월동',
      '지석동',
      '진월동',
      '칠석동',
      '행암동',
      '화장동',
    ],
    동구: [
      '계림동',
      '광산동',
      '궁동',
      '금동',
      '남동',
      '내남동',
      '대의동',
      '대인동',
      '동명동',
      '불로동',
      '산수동',
      '서석동',
      '선교동',
      '소태동',
      '수기동',
      '용산동',
      '용연동',
      '운림동',
      '월남동',
      '장동',
      '지산동',
      '학동',
      '호남동',
      '황금동',
    ],
    북구: [
      '각화동',
      '금곡동',
      '누문동',
      '대촌동',
      '덕의동',
      '동림동',
      '두암동',
      '망월동',
      '매곡동',
      '문흥동',
      '본촌동',
      '북동',
      '삼각동',
      '생용동',
      '수곡동',
      '신안동',
      '신용동',
      '양산동',
      '연제동',
      '오룡동',
      '오치동',
      '용강동',
      '용두동',
      '용봉동',
      '용전동',
      '우산동',
      '운암동',
      '운정동',
      '월출동',
      '유동',
      '일곡동',
      '임동',
      '장등동',
      '중흥동',
      '지야동',
      '청풍동',
      '충효동',
      '태령동',
      '풍향동',
      '화암동',
      '효령동',
    ],
    서구: [
      '광천동',
      '구동',
      '금호동',
      '내방동',
      '노대동',
      '농성동',
      '덕남동',
      '덕흥동',
      '동천동',
      '마륵동',
      '매월동',
      '방림동',
      '백운동',
      '벽진동',
      '봉선동',
      '사동',
      '서동',
      '서창동',
      '세하동',
      '송하동',
      '쌍촌동',
      '양동',
      '양림동',
      '용두동',
      '월산동',
      '유촌동',
      '임암동',
      '주월동',
      '진월동',
      '치평동',
      '풍암동',
      '행암동',
      '화정동',
    ],
  },
  대구광역시: {
    남구: ['대명동', '봉덕동', '이천동'],
    달서구: [
      '갈산동',
      '감삼동',
      '대곡동',
      '대천동',
      '도원동',
      '두류동',
      '본동',
      '본리동',
      '상인동',
      '성당동',
      '송현동',
      '신당동',
      '용산동',
      '월성동',
      '월암동',
      '유천동',
      '이곡동',
      '장기동',
      '장동',
      '죽전동',
      '진천동',
      '파산동',
      '파호동',
      '호림동',
      '호산동',
    ],
    달성군: [
      '가창면',
      '구지면',
      '논공면',
      '논공읍',
      '다사면',
      '다사읍',
      '옥포면',
      '옥포읍',
      '유가면',
      '유가읍',
      '하빈면',
      '현풍면',
      '현풍읍',
      '화원읍',
    ],
    동구: [
      '각산동',
      '검사동',
      '괴전동',
      '금강동',
      '내곡동',
      '내동',
      '능성동',
      '대림동',
      '덕곡동',
      '도동',
      '도학동',
      '동내동',
      '동호동',
      '둔산동',
      '매여동',
      '미곡동',
      '미대동',
      '방촌동',
      '백안동',
      '봉무동',
      '부동',
      '불로동',
      '사복동',
      '상매동',
      '서호동',
      '송정동',
      '숙천동',
      '신기동',
      '신무동',
      '신서동',
      '신암동',
      '신용동',
      '신천동',
      '신평동',
      '용계동',
      '용수동',
      '율암동',
      '율하동',
      '입석동',
      '중대동',
      '지묘동',
      '지저동',
      '진인동',
      '평광동',
      '효목동',
    ],
    북구: [
      '검단동',
      '관음동',
      '구암동',
      '국우동',
      '금호동',
      '노곡동',
      '대현동',
      '도남동',
      '동변동',
      '동천동',
      '동호동',
      '매천동',
      '복현동',
      '사수동',
      '산격동',
      '서변동',
      '연경동',
      '읍내동',
      '조야동',
      '침산동',
      '태전동',
      '팔달동',
      '학정동',
    ],
    서구: ['내당동', '비산동', '상리동', '이현동', '중리동', '평리동'],
    수성구: [
      '가천동',
      '고모동',
      '내환동',
      '노변동',
      '대흥동',
      '두산동',
      '만촌동',
      '매호동',
      '범물동',
      '범어동',
      '사월동',
      '삼덕동',
      '상동',
      '성동',
      '시지동',
      '신매동',
      '연호동',
      '욱수동',
      '이천동',
      '중동',
      '지산동',
      '파동',
      '황금동',
    ],
    중구: [
      '공평동',
      '교동',
      '남산동',
      '남성로',
      '남일동',
      '달성동',
      '대봉동',
      '대신동',
      '대안동',
      '덕산동',
      '도원동',
      '동문동',
      '동산동',
      '동일동',
      '문화동',
      '봉산동',
      '북내동',
      '사일동',
      '상덕동',
      '상서동',
      '서내동',
      '서야동',
      '수동',
      '수창동',
      '시장북로',
      '완전동',
      '용덕동',
      '인교동',
      '장관동',
      '전동',
      '포정동',
      '하서동',
      '향촌동',
      '화전동',
    ],
  },
  대전광역시: {
    대덕구: [
      '갈전동',
      '대화동',
      '덕암동',
      '목상동',
      '문평동',
      '미호동',
      '법동',
      '부수동',
      '비래동',
      '삼정동',
      '상서동',
      '석봉동',
      '송촌동',
      '신대동',
      '신일동',
      '신탄진동',
      '연축동',
      '오정동',
      '와동',
      '용호동',
      '읍내동',
      '이현동',
      '장동',
      '중리동',
      '평촌동',
      '황호동',
    ],
    동구: [
      '가양동',
      '가오동',
      '구도동',
      '낭월동',
      '내탑동',
      '대동',
      '대별동',
      '대성동',
      '마산동',
      '비룡동',
      '사성동',
      '삼괴동',
      '삼성동',
      '삼정동',
      '상소동',
      '성남동',
      '세천동',
      '소제동',
      '소호동',
      '신상동',
      '신안동',
      '신촌동',
      '신하동',
      '신흥동',
      '오동',
      '용계동',
      '용운동',
      '용전동',
      '원동',
      '이사동',
      '인동',
      '자양동',
      '장척동',
      '정동',
      '주산동',
      '주촌동',
      '중동',
      '직동',
      '천동',
      '추동',
      '판암동',
      '하소동',
      '홍도동',
      '효동',
      '효평동',
    ],
    서구: [
      '가수원동',
      '가장동',
      '갈마동',
      '관저동',
      '괴곡동',
      '괴정동',
      '내동',
      '도마동',
      '도안동',
      '둔산동',
      '만년동',
      '매노동',
      '변동',
      '복수동',
      '봉곡동',
      '산직동',
      '삼천동',
      '오동',
      '용문동',
      '용촌동',
      '우명동',
      '원정동',
      '월평동',
      '장안동',
      '정림동',
      '탄방동',
      '평촌동',
      '흑석동',
    ],
    유성구: [
      '가정동',
      '갑동',
      '계산동',
      '관평동',
      '교촌동',
      '구룡동',
      '구성동',
      '구암동',
      '궁동',
      '금고동',
      '금탄동',
      '노은동',
      '대동',
      '대정동',
      '덕명동',
      '덕진동',
      '도룡동',
      '둔곡동',
      '문지동',
      '반석동',
      '방동',
      '방현동',
      '복용동',
      '봉명동',
      '봉산동',
      '상대동',
      '성북동',
      '세동',
      '송강동',
      '송정동',
      '수남동',
      '신동',
      '신봉동',
      '신성동',
      '안산동',
      '어은동',
      '외삼동',
      '용계동',
      '용산동',
      '원내동',
      '원신흥동',
      '원촌동',
      '자운동',
      '장대동',
      '장동',
      '전민동',
      '죽동',
      '지족동',
      '추목동',
      '탑립동',
      '하기동',
      '학하동',
      '화암동',
    ],
    중구: [
      '구완동',
      '금동',
      '대사동',
      '대흥동',
      '목달동',
      '목동',
      '무수동',
      '문창동',
      '문화동',
      '부사동',
      '사정동',
      '산성동',
      '석교동',
      '선화동',
      '안영동',
      '어남동',
      '오류동',
      '옥계동',
      '용두동',
      '유천동',
      '은행동',
      '정생동',
      '중촌동',
      '침산동',
      '태평동',
      '호동',
    ],
  },
  부산광역시: {
    강서구: [
      '강동동',
      '구랑동',
      '녹산동',
      '눌차동',
      '대항동',
      '동선동',
      '명지동',
      '미음동',
      '범방동',
      '봉림동',
      '생곡동',
      '성북동',
      '송정동',
      '식만동',
      '신호동',
      '죽동동',
      '죽림동',
      '지사동',
      '천성동',
      '화전동',
    ],
    금정구: [
      '구서동',
      '금사동',
      '금성동',
      '남산동',
      '노포동',
      '두구동',
      '부곡동',
      '서동',
      '선동',
      '오륜동',
      '장전동',
      '청룡동',
      '회동동',
    ],
    기장군: ['기장읍', '일광면', '장안읍', '정관면', '정관읍', '철마면'],
    남구: [
      '감만동',
      '광안동',
      '남천동',
      '대연동',
      '망미동',
      '문현동',
      '민락동',
      '수영동',
      '용당동',
      '용호동',
      '우암동',
    ],
    동구: ['범일동', '수정동', '좌천동', '초량동'],
    동래구: [
      '거제동',
      '낙민동',
      '명륜동',
      '명장동',
      '복천동',
      '사직동',
      '수안동',
      '안락동',
      '연산동',
      '온천동',
      '칠산동',
    ],
    부산진구: [
      '가야동',
      '개금동',
      '당감동',
      '범전동',
      '범천동',
      '부암동',
      '부전동',
      '양정동',
      '연지동',
      '전포동',
      '초읍동',
    ],
    북구: [
      '감전동',
      '괘법동',
      '구포동',
      '금곡동',
      '덕천동',
      '덕포동',
      '만덕동',
      '모라동',
      '삼락동',
      '엄궁동',
      '주례동',
      '학장동',
      '화명동',
    ],
    사상구: ['감전동', '괘법동', '덕포동', '모라동', '삼락동', '엄궁동', '주례동', '학장동'],
    사하구: ['감천동', '괴정동', '구평동', '다대동', '당리동', '신평동', '장림동', '하단동'],
    서구: ['남부민동', '암남동', '초장동'],
    수영구: ['광안동', '남천동', '망미동', '민락동', '수영동'],
    연제구: ['거제동', '연산동'],
    영도구: ['동삼동', '청학동'],
    중구: ['영주동'],
    해운대구: ['반송동', '반여동', '석대동', '송정동', '우동', '재송동', '좌동', '중동'],
  },

  울산광역시: {
    남구: [
      '고사동',
      '남화동',
      '달동',
      '두왕동',
      '매암동',
      '무거동',
      '부곡동',
      '삼산동',
      '상개동',
      '선암동',
      '성암동',
      '신정동',
      '야음동',
      '여천동',
      '옥동',
      '용연동',
      '용잠동',
      '장생포동',
      '황성동',
    ],
    동구: ['동부동', '미포동', '방어동', '서부동', '염포동', '일산동', '전하동', '주전동', '화정동'],
    북구: [
      '가대동',
      '구유동',
      '달천동',
      '당사동',
      '대안동',
      '매곡동',
      '명촌동',
      '무룡동',
      '산하동',
      '상안동',
      '송정동',
      '시례동',
      '신명동',
      '신천동',
      '신현동',
      '양정동',
      '어물동',
      '연암동',
      '염포동',
      '정자동',
      '중산동',
      '진장동',
      '창평동',
      '천곡동',
      '호계동',
      '화봉동',
      '효문동',
    ],
    울주군: [
      '두동면',
      '두서면',
      '범서면',
      '범서읍',
      '삼남면',
      '삼남읍',
      '삼동면',
      '상북면',
      '서생면',
      '언양읍',
      '온산읍',
      '온양면',
      '온양읍',
      '웅촌면',
      '청량면',
      '청량읍',
    ],
    중구: [
      '교동',
      '남외동',
      '다운동',
      '동동',
      '반구동',
      '복산동',
      '북정동',
      '서동',
      '성남동',
      '성안동',
      '약사동',
      '옥교동',
      '우정동',
      '유곡동',
      '장현동',
      '태화동',
      '학산동',
      '학성동',
    ],
  },
  인천광역시: {
    강화군: [
      '강화읍',
      '교동면',
      '길상면',
      '내가면',
      '불은면',
      '삼산면',
      '서도면',
      '선원면',
      '송해면',
      '양도면',
      '양사면',
      '하점면',
      '화도면',
    ],
    계양구: [
      '갈현동',
      '계산동',
      '귤현동',
      '노오지동',
      '다남동',
      '동양동',
      '둑실동',
      '목상동',
      '박촌동',
      '방축동',
      '병방동',
      '삼산동',
      '상야동',
      '서운동',
      '선주지동',
      '오류동',
      '용종동',
      '이화동',
      '임학동',
      '작전동',
      '장기동',
      '평동',
      '하야동',
      '효성동',
    ],
    남구: [
      '관교동',
      '도화동',
      '동춘동',
      '문학동',
      '선학동',
      '숭의동',
      '연수동',
      '옥련동',
      '용현동',
      '주안동',
      '청학동',
      '학익동',
    ],
    남동구: [
      '간석동',
      '고잔동',
      '구월동',
      '남촌동',
      '논현동',
      '도림동',
      '만수동',
      '서창동',
      '수산동',
      '운연동',
      '장수동',
    ],
    동구: ['금곡동', '만석동', '송림동', '송현동', '창영동', '화수동', '화평동'],
    미추홀구: ['관교동', '도화동', '문학동', '숭의동', '용현동', '주안동', '학익동'],
    부평구: [
      '갈산동',
      '계산동',
      '구산동',
      '부개동',
      '부평동',
      '산곡동',
      '삼산동',
      '서운동',
      '십정동',
      '일신동',
      '작전동',
      '청천동',
      '효성동',
    ],
    북구: [
      '갈산동',
      '갈현동',
      '계산동',
      '구산동',
      '귤현동',
      '노오지동',
      '다남동',
      '동양동',
      '둑실동',
      '목상동',
      '박촌동',
      '방축동',
      '병방동',
      '부개동',
      '부평동',
      '산곡동',
      '삼산동',
      '상야동',
      '서운동',
      '선주지동',
      '십정동',
      '오류동',
      '용종동',
      '이화동',
      '일신동',
      '임학동',
      '작전동',
      '장기동',
      '청천동',
      '평동',
      '하야동',
      '효성동',
    ],
    서구: [
      '가정동',
      '가좌동',
      '검암동',
      '경서동',
      '공촌동',
      '금곡동',
      '당하동',
      '대곡동',
      '마전동',
      '백석동',
      '불노동',
      '불로동',
      '석남동',
      '시천동',
      '신현동',
      '심곡동',
      '연희동',
      '오류동',
      '왕길동',
      '원당동',
      '원창동',
      '청라동',
    ],
    연수구: ['동춘동', '선학동', '송도동', '연수동', '옥련동', '청학동'],
    옹진군: ['대청면', '덕적면', '백령면', '북도면', '송림면', '연평면', '영흥면', '자월면'],
    중구: [
      '경동',
      '남북동',
      '내동',
      '답동',
      '덕교동',
      '도원동',
      '무의동',
      '사동',
      '선린동',
      '선화동',
      '신생동',
      '신포동',
      '용동',
      '운남동',
      '운북동',
      '운서동',
      '유동',
      '율목동',
      '을왕동',
      '인현동',
      '전동',
      '중산동',
    ],
  },
  전라남도: {
    강진군: [
      '강진읍',
      '군동면',
      '대구면',
      '도암면',
      '마량면',
      '병영면',
      '성전면',
      '신전면',
      '옴천면',
      '작천면',
      '칠량면',
    ],
    고흥군: [
      '고흥면',
      '고흥읍',
      '과역면',
      '금산면',
      '남양면',
      '대서면',
      '도덕면',
      '도양면',
      '도양읍',
      '도화면',
      '동강면',
      '동일면',
      '두원면',
      '봉래면',
      '산내면',
      '영남면',
      '점암면',
      '포두면',
      '풍양면',
    ],
    곡성군: [
      '겸면',
      '고달면',
      '곡성면',
      '곡성읍',
      '목사동면',
      '삼기면',
      '석곡면',
      '오곡면',
      '오산면',
      '옥과면',
      '입면',
      '죽곡면',
    ],
    광산군: ['대촌면', '동곡면', '본양면', '비아면', '삼도면', '서창면', '송정읍', '임곡면', '평동면', '하남면'],
    광양군: ['골약면', '광양읍', '다압면', '봉강면', '옥곡면', '옥룡면', '진상면', '진월면', '태금면'],
    광양시: [
      '광양읍',
      '광영동',
      '금호동',
      '다압면',
      '도이동',
      '마동',
      '봉강면',
      '성황동',
      '옥곡면',
      '옥룡면',
      '중군동',
      '중동',
      '진상면',
      '진월면',
      '태인동',
      '황금동',
      '황길동',
    ],
    광주시: [
      '각화동',
      '계림동',
      '광산동',
      '광천동',
      '구동',
      '궁동',
      '금곡동',
      '금곡리',
      '금동',
      '남동',
      '내남동',
      '내방동',
      '노대동',
      '농성동',
      '누문동',
      '대의동',
      '대인동',
      '대촌동',
      '대촌리',
      '덕남동',
      '덕의동',
      '덕의리',
      '덕흥동',
      '동림동',
      '동명동',
      '두암동',
      '등용동',
      '망월동',
      '매곡동',
      '문흥동',
      '방림동',
      '방하동',
      '백운동',
      '본촌동',
      '본촌리',
      '봉선동',
      '북동',
      '불로동',
      '사동',
      '산수동',
      '삼각동',
      '생용동',
      '생용리',
      '서동',
      '서석동',
      '서호동',
      '선교동',
      '소태동',
      '송석동',
      '송하동',
      '수곡동',
      '수곡리',
      '수기동',
      '신안동',
      '신용동',
      '신용리',
      '신호동',
      '쌍촌동',
      '양동',
      '양림동',
      '양산동',
      '양산리',
      '연제동',
      '연제리',
      '오룡동',
      '오룡리',
      '오치동',
      '용강동',
      '용강리',
      '용두동',
      '용봉동',
      '용산동',
      '용수리',
      '용연동',
      '용전동',
      '용전리',
      '우산동',
      '운림동',
      '운암동',
      '운정동',
      '월남동',
      '월산동',
      '월출동',
      '월출리',
      '유동',
      '유촌동',
      '일곡동',
      '일곡리',
      '임동',
      '임암동',
      '장동',
      '장등동',
      '주월동',
      '중흥동',
      '지산동',
      '지야동',
      '지야리',
      '진월동',
      '청풍동',
      '충효동',
      '충효리',
      '치평동',
      '태령동',
      '태령리',
      '풍향동',
      '학동',
      '학승동',
      '행암동',
      '호남동',
      '화암동',
      '화정동',
      '황금동',
      '효령동',
      '효령리',
    ],
    광주시동구: [
      '각화동',
      '계림동',
      '광산동',
      '궁동',
      '금곡동',
      '금동',
      '남동',
      '내남동',
      '대의동',
      '대인동',
      '덕의동',
      '동명동',
      '두암동',
      '망월동',
      '매곡동',
      '문흥동',
      '방림동',
      '불로동',
      '산수동',
      '삼각동',
      '서석동',
      '선교동',
      '소태동',
      '수기동',
      '양림동',
      '오치동',
      '용산동',
      '용연동',
      '우산동',
      '운림동',
      '운정동',
      '월남동',
      '장동',
      '장등동',
      '중흥동',
      '지산동',
      '청풍동',
      '충효동',
      '풍향동',
      '학동',
      '호남동',
      '화암동',
      '황금동',
    ],
    광주시북구: [
      '각화동',
      '금곡동',
      '누문동',
      '대촌동',
      '덕의동',
      '동림동',
      '두암동',
      '망월동',
      '매곡동',
      '문흥동',
      '본촌동',
      '북동',
      '삼각동',
      '생룡동',
      '수곡동',
      '신안동',
      '신용동',
      '양산동',
      '연제동',
      '오룡동',
      '오치동',
      '용강동',
      '용두동',
      '용봉동',
      '용전동',
      '우산동',
      '운암동',
      '운정동',
      '월출동',
      '유동',
      '일곡동',
      '임동',
      '장등동',
      '중흥동',
      '지야동',
      '청풍동',
      '충효동',
      '태령동',
      '풍향동',
      '화암동',
      '효령동',
    ],
    광주시서구: [
      '광천동',
      '구동',
      '내방동',
      '노대동',
      '농성동',
      '누문동',
      '대촌동',
      '덕남동',
      '덕흥동',
      '동림동',
      '방림동',
      '백운동',
      '본촌동',
      '봉선동',
      '북동',
      '사동',
      '생용동',
      '서동',
      '송하동',
      '수곡동',
      '신안동',
      '신용동',
      '쌍촌동',
      '양동',
      '양림동',
      '양산동',
      '연제동',
      '오룡동',
      '용강동',
      '용두동',
      '용봉동',
      '용전동',
      '운암동',
      '월산동',
      '월출동',
      '유동',
      '유촌동',
      '일곡동',
      '임동',
      '임암동',
      '주월동',
      '지야동',
      '진월동',
      '치평동',
      '태령동',
      '행암동',
      '화정동',
      '효령동',
    ],
    구례군: ['간전면', '광의면', '구례면', '구례읍', '마산면', '문척면', '산동면', '용방면', '토지면'],
    금성시: [
      '경현동',
      '과원동',
      '관정동',
      '교동',
      '금계동',
      '금성동',
      '남내동',
      '남외동',
      '대기동',
      '대호동',
      '동수동',
      '보산동',
      '부덕동',
      '산정동',
      '삼도동',
      '삼영동',
      '서내동',
      '석현동',
      '성북동',
      '송월동',
      '송촌동',
      '안창동',
      '영산동',
      '오량동',
      '용산동',
      '운곡동',
      '이창동',
      '죽림동',
      '중앙동',
      '진포동',
      '청동',
      '토계동',
      '평산동',
    ],
    나주군: [
      '공산면',
      '금천면',
      '나주읍',
      '남평면',
      '노안면',
      '다도면',
      '다시면',
      '동강면',
      '문평면',
      '반남면',
      '봉황면',
      '산포면',
      '세지면',
      '영산포읍',
      '왕곡면',
    ],
    나주시: [
      '가야동',
      '경현동',
      '공산면',
      '과원동',
      '관정동',
      '교동',
      '금계동',
      '금남동',
      '금성동',
      '금천면',
      '남내동',
      '남산동',
      '남외동',
      '남평면',
      '남평읍',
      '노안면',
      '다도면',
      '다시면',
      '대기동',
      '대호동',
      '동강면',
      '동수동',
      '문평면',
      '반남면',
      '보산동',
      '봉황면',
      '부덕동',
      '빛가람동',
      '산정동',
      '산포면',
      '삼도동',
      '삼영동',
      '서내동',
      '석현동',
      '성북동',
      '세지면',
      '송월동',
      '송촌동',
      '송현동',
      '안창동',
      '영강동',
      '영산동',
      '오량동',
      '왕곡면',
      '용산동',
      '운곡동',
      '이창동',
      '죽림동',
      '중앙동',
      '진포동',
      '청동',
      '토계동',
      '평산동',
      '향교동',
    ],
    담양군: [
      '가사문학면',
      '고서면',
      '금성면',
      '남면',
      '담양읍',
      '대덕면',
      '대전면',
      '무정면',
      '봉산면',
      '수북면',
      '용면',
      '월산면',
      '창평면',
    ],
    동광양시: [
      '광영동',
      '금당동',
      '금호동',
      '도이동',
      '마동',
      '성황동',
      '중군동',
      '중동',
      '중마동',
      '태인동',
      '황금동',
      '황길동',
    ],
    목포시: [
      '경동',
      '고하동',
      '광동',
      '금동',
      '금화동',
      '남교동',
      '내삼학동',
      '달동',
      '달성동',
      '대반동',
      '대성동',
      '대안동',
      '대양동',
      '대의동',
      '동명동',
      '만호동',
      '명륜동',
      '무안동',
      '보광동',
      '복만동',
      '북교동',
      '산정동',
      '삼향동',
      '상동',
      '상락동',
      '서산동',
      '석현동',
      '수강동',
      '양동',
      '연동',
      '연산동',
      '영해동',
      '옥암동',
      '온금동',
      '용당동',
      '용해동',
      '유달동',
      '유동',
      '율도동',
      '이로동',
      '죽교동',
      '죽동',
      '중동',
      '중앙동',
      '창평동',
      '축복동',
      '충무동',
      '측후동',
      '항동',
      '해안동',
      '행복동',
      '호남동',
    ],
    무안군: [
      '도초면',
      '망운면',
      '몽탄면',
      '무안면',
      '무안읍',
      '비금면',
      '삼향면',
      '삼향읍',
      '안좌면',
      '암태면',
      '압해면',
      '운남면',
      '이로면',
      '일로면',
      '일로읍',
      '자은면',
      '장산면',
      '지도면',
      '청계면',
      '추자면',
      '하의면',
      '해제면',
      '현경면',
      '흑산면',
    ],
    보성군: [
      '겸백면',
      '노동면',
      '득량면',
      '문덕면',
      '미력면',
      '벌교읍',
      '보성읍',
      '복내면',
      '웅치면',
      '율어면',
      '조성면',
      '회천면',
    ],
    송정시: [
      '도산동',
      '도호동',
      '박호동',
      '서봉동',
      '선암동',
      '소촌동',
      '송정동',
      '신촌동',
      '우산동',
      '운수동',
      '황룡동',
    ],
    순천시: [
      '가곡동',
      '교량동',
      '금곡동',
      '낙안면',
      '남내동',
      '남정동',
      '남제동',
      '대대동',
      '대룡동',
      '대평동',
      '덕암동',
      '덕연동',
      '덕월동',
      '덕흥동',
      '동외동',
      '매곡동',
      '별량면',
      '삼거동',
      '삼산동',
      '상사면',
      '생목동',
      '서면',
      '석현동',
      '송광면',
      '승주읍',
      '안풍동',
      '야흥동',
      '연향동',
      '영동',
      '영옥동',
      '오천동',
      '옥천동',
      '와룡동',
      '왕조동',
      '왕지동',
      '외서면',
      '용당동',
      '용수동',
      '월등면',
      '인안동',
      '인월동',
      '인제동',
      '장천동',
      '저전동',
      '조곡동',
      '조례동',
      '주암면',
      '중앙동',
      '풍덕동',
      '해룡면',
      '행금동',
      '행동',
      '홍내동',
      '황전면',
    ],
    승주군: [
      '낙안면',
      '별량면',
      '상사면',
      '서면',
      '송광면',
      '승주읍',
      '쌍암면',
      '쌍암읍',
      '외서면',
      '월등면',
      '주암면',
      '해룡면',
      '황전면',
    ],
    신안군: [
      '도초면',
      '비금면',
      '신의면',
      '안좌면',
      '암태면',
      '압해면',
      '압해읍',
      '임자면',
      '자은면',
      '장산면',
      '증도면',
      '지도면',
      '지도읍',
      '팔금면',
      '하의면',
      '흑산면',
    ],
    여수시: [
      '경호동',
      '고소동',
      '고하동',
      '공화동',
      '관문동',
      '광무동',
      '교동',
      '국동',
      '군자동',
      '낙포동',
      '남면',
      '남산동',
      '덕충동',
      '돌산읍',
      '동산동',
      '둔덕동',
      '만흥동',
      '묘도동',
      '문수동',
      '미평동',
      '봉강동',
      '봉계동',
      '봉산동',
      '삼산면',
      '상암동',
      '서교동',
      '선원동',
      '소라면',
      '소호동',
      '수정동',
      '시전동',
      '신기동',
      '신덕동',
      '신월동',
      '안산동',
      '여서동',
      '여천동',
      '연등동',
      '오림동',
      '오천동',
      '웅천동',
      '월내동',
      '월하동',
      '율촌면',
      '적량동',
      '종화동',
      '주삼동',
      '중앙동',
      '중흥동',
      '충무동',
      '평여동',
      '학동',
      '학용동',
      '해산동',
      '호명동',
      '화양면',
      '화장동',
      '화정면',
      '화치동',
    ],
    여천군: [
      '남면',
      '돌산면',
      '돌산읍',
      '삼산면',
      '삼일면',
      '삼일읍',
      '소라면',
      '쌍봉면',
      '율촌면',
      '화양면',
      '화정면',
    ],
    여천시: [
      '낙포동',
      '묘도동',
      '봉계동',
      '삼일동',
      '상암동',
      '선원동',
      '소호동',
      '시전동',
      '신기동',
      '신덕동',
      '쌍봉동',
      '안산동',
      '여천동',
      '웅천동',
      '월내동',
      '월하동',
      '적량동',
      '주삼동',
      '중흥동',
      '평여동',
      '학동',
      '학용동',
      '해산동',
      '호명동',
      '화장동',
      '화치동',
    ],
    영광군: [
      '군남면',
      '군서면',
      '낙월면',
      '대마면',
      '묘량면',
      '백수면',
      '백수읍',
      '법성면',
      '불갑면',
      '염산면',
      '영광읍',
      '홍농면',
      '홍농읍',
    ],
    영암군: [
      '군서면',
      '금정면',
      '덕진면',
      '도포면',
      '미암면',
      '삼호면',
      '삼호읍',
      '서호면',
      '시종면',
      '신북면',
      '영암면',
      '영암읍',
      '학산면',
    ],
    완도군: [
      '고금면',
      '군외면',
      '금당면',
      '금일면',
      '금일읍',
      '노화면',
      '노화읍',
      '보길면',
      '생일면',
      '소안면',
      '신지면',
      '약산면',
      '완도읍',
      '청산면',
    ],
    장성군: [
      '남면',
      '동화면',
      '북상면',
      '북이면',
      '북일면',
      '북하면',
      '삼계면',
      '삼서면',
      '서삼면',
      '장성읍',
      '진원면',
      '황룡면',
    ],
    장흥군: [
      '관산면',
      '관산읍',
      '대덕면',
      '대덕읍',
      '부산면',
      '안양면',
      '용산면',
      '유치면',
      '장동면',
      '장평면',
      '장흥읍',
      '회진면',
    ],
    진도군: ['고군면', '군내면', '의신면', '임회면', '조도면', '지산면', '진도면', '진도읍'],
    함평군: ['나산면', '대동면', '손불면', '신광면', '엄다면', '월야면', '학교면', '함평면', '함평읍', '해보면'],
    해남군: [
      '계곡면',
      '마산면',
      '문내면',
      '북일면',
      '북평면',
      '산이면',
      '삼산면',
      '송지면',
      '옥천면',
      '해남읍',
      '현산면',
      '화산면',
      '화원면',
      '황산면',
    ],
    화순군: [
      '남면',
      '능주면',
      '도곡면',
      '도암면',
      '동면',
      '동복면',
      '백아면',
      '북면',
      '사평면',
      '이서면',
      '이양면',
      '청풍면',
      '춘양면',
      '한천면',
      '화순면',
      '화순읍',
    ],
  },
  전라북도: {
    고창군: [
      '고수면',
      '고창읍',
      '공음면',
      '대산면',
      '무장면',
      '부안면',
      '상하면',
      '성내면',
      '성송면',
      '신림면',
      '심원면',
      '아산면',
      '해리면',
      '흥덕면',
    ],
    군산시: [
      '개복동',
      '개사동',
      '개정동',
      '개정면',
      '경암동',
      '경장동',
      '구암동',
      '금광동',
      '금동',
      '금암동',
      '나운동',
      '나포면',
      '내초동',
      '내흥동',
      '대명동',
      '대야면',
      '동흥남동',
      '둔율동',
      '명산동',
      '문화동',
      '미룡동',
      '미성동',
      '미원동',
      '미장동',
      '비응도동',
      '사정동',
      '산북동',
      '삼학동',
      '서수면',
      '서흥남동',
      '선양동',
      '성산면',
      '소룡동',
      '송창동',
      '송풍동',
      '수송동',
      '신관동',
      '신영동',
      '신창동',
      '신풍동',
      '신흥동',
      '영동',
      '영화동',
      '오룡동',
      '오식도동',
      '옥구읍',
      '옥도면',
      '옥산면',
      '옥서면',
      '월명동',
      '임피면',
      '장미동',
      '장재동',
      '조촌동',
      '죽성동',
      '중동',
      '지곡동',
      '창성동',
      '평화동',
      '해망동',
      '회현면',
      '흥남동',
    ],
    금산군: ['군북면', '금산읍', '금성면', '남이면', '남일면', '복수면', '부리면', '제원면', '진산면', '추부면'],
    김제군: [
      '공덕면',
      '광활면',
      '금구면',
      '금산면',
      '김제읍',
      '만경면',
      '백구면',
      '백산면',
      '봉남면',
      '부량면',
      '성덕면',
      '용지면',
      '월촌면',
      '죽산면',
      '진봉면',
      '청하면',
      '황산면',
    ],
    김제시: [
      '갈공동',
      '검산동',
      '공덕면',
      '광활면',
      '교동',
      '금구면',
      '금산면',
      '난봉동',
      '도장동',
      '만경면',
      '만경읍',
      '명덕동',
      '백구면',
      '백산면',
      '백학동',
      '복죽동',
      '봉남면',
      '봉황동',
      '부량면',
      '상동동',
      '서암동',
      '서정동',
      '서흥동',
      '성덕면',
      '순동',
      '신곡동',
      '신덕동',
      '신월동',
      '신풍동',
      '양전동',
      '연정동',
      '오정동',
      '옥산동',
      '요촌동',
      '용동',
      '용지면',
      '월봉동',
      '월성동',
      '월촌동',
      '입석동',
      '장화동',
      '제월동',
      '죽산면',
      '진봉면',
      '청하면',
      '하동',
      '황산동',
      '황산면',
      '흥사동',
    ],
    남원군: [
      '금지면',
      '남원읍',
      '대강면',
      '대산면',
      '덕과면',
      '동면',
      '보절면',
      '사매면',
      '산내면',
      '산동면',
      '송동면',
      '수지면',
      '아영면',
      '운봉면',
      '이백면',
      '주생면',
      '주천면',
    ],
    남원시: [
      '갈치동',
      '고죽동',
      '광치동',
      '금동',
      '금지면',
      '내척동',
      '노암동',
      '대강면',
      '대산면',
      '덕과면',
      '도통동',
      '동면',
      '동충동',
      '보절면',
      '사매면',
      '산곡동',
      '산내면',
      '산동면',
      '송동면',
      '수지면',
      '식정동',
      '신정동',
      '신촌동',
      '쌍교동',
      '아영면',
      '어현동',
      '왕정동',
      '용정동',
      '운봉면',
      '운봉읍',
      '월락동',
      '이백면',
      '인월면',
      '조산동',
      '주생면',
      '주천면',
      '죽항동',
      '천거동',
      '하정동',
      '향교동',
      '화정동',
    ],
    무주군: ['무주면', '무주읍', '무풍면', '부남면', '설천면', '안성면', '적상면'],
    부안군: [
      '계화면',
      '동진면',
      '백산면',
      '변산면',
      '보안면',
      '부안읍',
      '산내면',
      '상서면',
      '위도면',
      '주산면',
      '줄포면',
      '진서면',
      '하서면',
      '행안면',
    ],
    순창군: [
      '구림면',
      '금과면',
      '동계면',
      '복흥면',
      '순창면',
      '순창읍',
      '쌍치면',
      '유등면',
      '인계면',
      '적성면',
      '팔덕면',
      '풍산면',
    ],
    옥구군: [
      '개정면',
      '나포면',
      '대야면',
      '미면',
      '미성읍',
      '서수면',
      '성산면',
      '옥구면',
      '옥구읍',
      '옥도면',
      '옥산면',
      '옥서면',
      '임피면',
      '회현면',
    ],
    완주군: [
      '경천면',
      '고산면',
      '구이면',
      '동상면',
      '봉동면',
      '봉동읍',
      '비봉면',
      '삼례읍',
      '상관면',
      '소양면',
      '용진면',
      '용진읍',
      '운주면',
      '이서면',
      '조촌면',
      '조촌읍',
      '화산면',
    ],
    이리시: [
      '갈산동',
      '계문동',
      '금강동',
      '남중동',
      '덕기동',
      '동산동',
      '마동',
      '만석동',
      '모현동',
      '목천동',
      '부송동',
      '북일동',
      '삼성동',
      '석암동',
      '석왕동',
      '석탄동',
      '송학동',
      '신동',
      '신용동',
      '신흥동',
      '어양동',
      '영등동',
      '용제동',
      '월성동',
      '은기동',
      '인화동',
      '임상동',
      '정족동',
      '주현동',
      '중앙동',
      '창인동',
      '팔봉동',
      '평화동',
      '현영동',
    ],
    익산군: [
      '금마면',
      '낭산면',
      '망성면',
      '북일면',
      '삼기면',
      '성당면',
      '여산면',
      '오산면',
      '왕궁면',
      '용동면',
      '용안면',
      '웅포면',
      '춘포면',
      '팔봉면',
      '함라면',
      '함열면',
      '함열읍',
      '황등면',
      '황화면',
    ],
    익산시: [
      '갈산동',
      '금강동',
      '금마면',
      '남중동',
      '낭산면',
      '덕기동',
      '동산동',
      '마동',
      '만석동',
      '망성면',
      '목천동',
      '부송동',
      '삼기면',
      '석암동',
      '석왕동',
      '석탄동',
      '성당면',
      '송학동',
      '신동',
      '신용동',
      '신흥동',
      '어양동',
      '여산면',
      '영등동',
      '오산면',
      '왕궁면',
      '용동면',
      '용안면',
      '용제동',
      '웅포면',
      '월성동',
      '은기동',
      '임상동',
      '정족동',
      '주현동',
      '춘포면',
      '팔봉동',
      '평화동',
      '함라면',
      '함열읍',
      '현영동',
      '황등면',
    ],
    임실군: [
      '강진면',
      '관촌면',
      '덕치면',
      '둔남면',
      '삼계면',
      '성수면',
      '신덕면',
      '신평면',
      '오수면',
      '운암면',
      '임실면',
      '임실읍',
      '지사면',
      '청웅면',
    ],
    장수군: ['계남면', '계내면', '계북면', '번암면', '산서면', '장계면', '장수면', '장수읍', '천천면'],
    전주시: [
      '경원동',
      '고랑동',
      '고사동',
      '교동',
      '금상동',
      '금암동',
      '남고동',
      '남노송동',
      '다가동',
      '대성동',
      '덕진동',
      '동산동',
      '동서학동',
      '동완산동',
      '만성동',
      '반월동',
      '산정동',
      '삼천동',
      '색장동',
      '서노송동',
      '서서학동',
      '서신동',
      '서완산동',
      '석구동',
      '성덕동',
      '송천동',
      '여의동',
      '용복동',
      '용정동',
      '우아동',
      '원당동',
      '원동',
      '장동',
      '전동',
      '전미동',
      '조촌동',
      '중노송동',
      '중앙동',
      '중인동',
      '중화산동',
      '진북동',
      '태평동',
      '팔복동',
      '평화동',
      '풍남동',
      '호성동',
      '화전동',
      '효자동',
    ],
    전주시덕진구: [
      '강흥동',
      '고랑동',
      '금상동',
      '금암동',
      '남정동',
      '덕진동',
      '도덕동',
      '도도동',
      '동산동',
      '만성동',
      '반월동',
      '산정동',
      '서노송동',
      '성덕동',
      '송천동',
      '여의동',
      '용정동',
      '우아동',
      '원동',
      '장동',
      '전미동',
      '중동',
      '진북동',
      '팔복동',
      '호성동',
      '화전동',
    ],
    전주시완산구: [
      '경원동',
      '고사동',
      '교동',
      '남노송동',
      '다가동',
      '대성동',
      '동서학동',
      '동완산동',
      '삼천동',
      '상림동',
      '색장동',
      '서노송동',
      '서서학동',
      '서신동',
      '서완산동',
      '석구동',
      '용복동',
      '원당동',
      '전동',
      '중노송동',
      '중동',
      '중앙동',
      '중인동',
      '중화산동',
      '태평동',
      '평화동',
      '풍남동',
      '효자동',
    ],
    정읍군: [
      '감곡면',
      '고부면',
      '내장면',
      '덕천면',
      '북면',
      '산내면',
      '산외면',
      '소성면',
      '신태인읍',
      '영원면',
      '옹동면',
      '이평면',
      '입암면',
      '정우면',
      '정주읍',
      '칠보면',
      '태인면',
    ],
    정읍시: [
      '감곡면',
      '고부면',
      '공평동',
      '과교동',
      '교암동',
      '구룡동',
      '금붕동',
      '내장동',
      '농소동',
      '덕천면',
      '망제동',
      '부전동',
      '북면',
      '산내면',
      '산외면',
      '삼산동',
      '상동',
      '상평동',
      '소성면',
      '송산동',
      '수성동',
      '시기동',
      '신월동',
      '신정동',
      '신태인읍',
      '쌍암동',
      '연지동',
      '영원면',
      '영파동',
      '옹동면',
      '용계동',
      '용산동',
      '이평면',
      '입암면',
      '장명동',
      '정우면',
      '진산동',
      '칠보면',
      '태인면',
      '하모동',
      '하북동',
      '흑암동',
    ],
    정주시: [
      '공평동',
      '과교동',
      '교암동',
      '구룡동',
      '금붕동',
      '내장동',
      '농소동',
      '망제동',
      '부전동',
      '삼산동',
      '상동',
      '상평동',
      '송산동',
      '수성동',
      '시기동',
      '신월동',
      '신정동',
      '쌍암동',
      '연지동',
      '영파동',
      '용계동',
      '용산동',
      '장명동',
      '정일동',
      '진산동',
      '하모동',
      '하북동',
      '흑암동',
    ],
    진안군: [
      '동향면',
      '마령면',
      '백운면',
      '부귀면',
      '상전면',
      '성수면',
      '안천면',
      '용담면',
      '정천면',
      '주천면',
      '진안면',
      '진안읍',
    ],
  },
  제주특별자치도: {
    서귀포시: [
      '강정동',
      '남원읍',
      '대정읍',
      '대포동',
      '도순동',
      '동홍동',
      '법환동',
      '보목동',
      '상예동',
      '상효동',
      '색달동',
      '서귀동',
      '서호동',
      '서홍동',
      '성산읍',
      '신효동',
      '안덕면',
      '영남동',
      '월평동',
      '중문동',
      '토평동',
      '표선면',
      '하예동',
      '하원동',
      '하효동',
      '호근동',
      '회수동',
    ],
    제주시: [
      '건입동',
      '구좌읍',
      '내도동',
      '노형동',
      '도남동',
      '도두이동',
      '도두일동',
      '도련이동',
      '도련일동',
      '도평동',
      '봉개동',
      '삼도이동',
      '삼도일동',
      '삼양삼동',
      '삼양이동',
      '삼양일동',
      '아라이동',
      '아라일동',
      '애월읍',
      '연동',
      '영평동',
      '오등동',
      '오라삼동',
      '오라이동',
      '오라일동',
      '외도이동',
      '외도일동',
      '용강동',
      '용담삼동',
      '용담이동',
      '용담일동',
      '우도면',
      '월평동',
      '이도이동',
      '이도일동',
      '이호이동',
      '이호일동',
      '일도이동',
      '일도일동',
      '조천읍',
      '추자면',
      '한경면',
      '한림읍',
      '해안동',
      '화북이동',
      '화북일동',
      '회천동',
    ],
  },
  충청남도: {
    계룡시: ['금암동', '남선면', '두마면', '신도안면', '엄사면'],
    공주군: [
      '계룡면',
      '공주읍',
      '반포면',
      '사곡면',
      '신풍면',
      '우성면',
      '유구면',
      '의당면',
      '이인면',
      '장기면',
      '정안면',
      '탄천면',
    ],
    공주시: [
      '검상동',
      '계룡면',
      '교동',
      '금성동',
      '금학동',
      '금흥동',
      '동현동',
      '무릉동',
      '반죽동',
      '반포면',
      '봉정동',
      '봉황동',
      '사곡면',
      '산성동',
      '상왕동',
      '석장리동',
      '소학동',
      '송선동',
      '신관동',
      '신기동',
      '신풍면',
      '쌍신동',
      '오곡동',
      '옥룡동',
      '우성면',
      '웅진동',
      '월미동',
      '월송동',
      '유구면',
      '유구읍',
      '의당면',
      '이인면',
      '장기면',
      '정안면',
      '주미동',
      '중동',
      '중학동',
      '탄천면',
      '태봉동',
    ],
    금산군: ['군북면', '금산읍', '금성면', '남이면', '남일면', '복수면', '부리면', '제원면', '진산면', '추부면'],
    논산군: [
      '가야곡면',
      '강경읍',
      '광석면',
      '구자곡면',
      '노성면',
      '논산읍',
      '두마면',
      '벌곡면',
      '부적면',
      '상월면',
      '성동면',
      '양촌면',
      '연무읍',
      '연산면',
      '은진면',
      '채운면',
    ],
    논산시: [
      '가야곡면',
      '강경읍',
      '강산동',
      '관촉동',
      '광석면',
      '내동',
      '노성면',
      '대교동',
      '덕지동',
      '두마면',
      '등화동',
      '반월동',
      '벌곡면',
      '부적면',
      '부창동',
      '상월면',
      '성동면',
      '양촌면',
      '연무읍',
      '연산면',
      '은진면',
      '지산동',
      '채운면',
      '취암동',
      '화지동',
    ],
    당진군: [
      '고대면',
      '당진면',
      '당진읍',
      '대호지면',
      '면천면',
      '석문면',
      '송산면',
      '송악면',
      '송악읍',
      '순성면',
      '신평면',
      '우강면',
      '정미면',
      '합덕면',
      '합덕읍',
    ],
    당진시: [
      '고대면',
      '구룡동',
      '대덕동',
      '대호지면',
      '면천면',
      '사기소동',
      '석문면',
      '송산면',
      '송악읍',
      '수청동',
      '순성면',
      '시곡동',
      '신평면',
      '용연동',
      '우강면',
      '우두동',
      '원당동',
      '읍내동',
      '정미면',
      '채운동',
      '합덕읍',
      '행정동',
    ],
    대덕군: [
      '구즉면',
      '기성면',
      '동면',
      '북면',
      '산내면',
      '신탄진읍',
      '유성면',
      '유성읍',
      '유천면',
      '진잠면',
      '탄동면',
      '회덕면',
    ],
    대전시: [
      '가양동',
      '가오동',
      '가오리',
      '가장동',
      '가장리',
      '갈마동',
      '갈마리',
      '괴정동',
      '괴정리',
      '내동',
      '내동리',
      '대동',
      '대사동',
      '대화동',
      '대화리',
      '대흥동',
      '도마동',
      '도마리',
      '둔산동',
      '둔산리',
      '목동',
      '문창동',
      '문화동',
      '변동',
      '변동리',
      '복수동',
      '복수리',
      '부사동',
      '사정동',
      '사정리',
      '산성동',
      '삼성동',
      '삼정동',
      '삼정리',
      '삼천동',
      '삼천리',
      '석교동',
      '선화동',
      '성남동',
      '소제동',
      '신안동',
      '신흥동',
      '안영동',
      '안영리',
      '오류동',
      '오정동',
      '오정리',
      '옥계동',
      '옥계리',
      '용두동',
      '용문동',
      '용운동',
      '용전동',
      '용전리',
      '원동',
      '월평동',
      '월평리',
      '유천동',
      '은행동',
      '인동',
      '자양동',
      '정동',
      '정림동',
      '정림리',
      '중동',
      '중촌동',
      '천동',
      '탄방동',
      '탄방리',
      '태평동',
      '판암동',
      '호동',
      '호동리',
      '홍도동',
      '효동',
    ],
    대전시동구: [
      '가양동',
      '가오동',
      '대동',
      '대화동',
      '법동',
      '비래동',
      '삼성동',
      '삼정동',
      '성남동',
      '소제동',
      '송촌동',
      '신대동',
      '신안동',
      '신흥동',
      '연축동',
      '오정동',
      '와동',
      '용운동',
      '용전동',
      '원동',
      '읍내동',
      '인동',
      '자양동',
      '정동',
      '중동',
      '중리동',
      '천동',
      '판암동',
      '홍도동',
      '효동',
    ],
    대전시서구: [
      '가수원동',
      '가장동',
      '가정동',
      '갈마동',
      '갑동',
      '계산동',
      '관저동',
      '괴정동',
      '교촌동',
      '구성동',
      '구암동',
      '궁동',
      '내동',
      '노은동',
      '대정동',
      '덕명동',
      '덕진동',
      '도룡동',
      '도마동',
      '도안동',
      '둔산동',
      '문지동',
      '방현동',
      '변동',
      '복수동',
      '복용동',
      '봉명동',
      '삼천동',
      '상대동',
      '신성동',
      '안영동',
      '어은동',
      '용계동',
      '용문동',
      '원내동',
      '원신흥동',
      '원촌동',
      '월평동',
      '장대동',
      '장동',
      '전민동',
      '정림동',
      '죽동',
      '지족동',
      '탄방동',
      '하기동',
      '학하동',
      '화암동',
    ],
    대전시중구: [
      '가수원동',
      '가장동',
      '가정동',
      '갈마동',
      '갑동',
      '계산동',
      '관저동',
      '괴정동',
      '교촌동',
      '구성동',
      '구암동',
      '궁동',
      '내동',
      '노은동',
      '대사동',
      '대정동',
      '대흥동',
      '덕명동',
      '덕진동',
      '도룡동',
      '도마동',
      '도안동',
      '둔산동',
      '목동',
      '문지동',
      '문창동',
      '문화동',
      '방현동',
      '변동',
      '복수동',
      '복용동',
      '봉명동',
      '부사동',
      '사정동',
      '산성동',
      '삼천동',
      '상대동',
      '석교동',
      '선화동',
      '신성동',
      '안영동',
      '어은동',
      '오류동',
      '옥계동',
      '용계동',
      '용두동',
      '용문동',
      '원내동',
      '원신흥동',
      '원촌동',
      '월평동',
      '유천동',
      '은행동',
      '장대동',
      '장동',
      '전민동',
      '정림동',
      '죽동',
      '중촌동',
      '지족동',
      '탄방동',
      '태평동',
      '하기동',
      '학하동',
      '호동',
      '화암동',
    ],
    대천시: [
      '궁촌동',
      '남곡동',
      '내항동',
      '대관동',
      '대신동',
      '대천동',
      '동대동',
      '명천동',
      '신흑동',
      '왕대동',
      '요암동',
      '원동',
      '죽정동',
      '현포동',
      '화산동',
      '흥덕동',
    ],
    보령군: [
      '남포면',
      '대천면',
      '대천읍',
      '미산면',
      '성주면',
      '오천면',
      '웅천면',
      '주교면',
      '주산면',
      '주포면',
      '천북면',
      '청라면',
      '청소면',
    ],
    보령시: [
      '궁촌동',
      '남곡동',
      '남포면',
      '내항동',
      '대천동',
      '동대동',
      '명천동',
      '미산면',
      '성주면',
      '신흑동',
      '오천면',
      '요암동',
      '웅천면',
      '웅천읍',
      '주교면',
      '주산면',
      '주포면',
      '죽정동',
      '천북면',
      '청라면',
      '청소면',
      '화산동',
    ],
    부여군: [
      '구룡면',
      '규암면',
      '남면',
      '내산면',
      '부여면',
      '부여읍',
      '석성면',
      '세도면',
      '양화면',
      '옥산면',
      '외산면',
      '은산면',
      '임천면',
      '장암면',
      '초촌면',
      '충화면',
      '홍산면',
    ],
    서산군: [
      '고남면',
      '고북면',
      '근흥면',
      '남면',
      '대산면',
      '대산읍',
      '부석면',
      '서산읍',
      '성연면',
      '소원면',
      '안면면',
      '안면읍',
      '운산면',
      '원북면',
      '음암면',
      '이북면',
      '이원면',
      '인지면',
      '지곡면',
      '태안면',
      '태안읍',
      '팔봉면',
      '해미면',
    ],
    서산시: [
      '갈산동',
      '고북면',
      '대산읍',
      '덕지천동',
      '동문동',
      '부석면',
      '부춘동',
      '석남동',
      '석림동',
      '성연면',
      '수석동',
      '양대동',
      '예천동',
      '오남동',
      '오산동',
      '온석동',
      '운산면',
      '음암면',
      '읍내동',
      '인지면',
      '잠홍동',
      '장동',
      '죽성동',
      '지곡면',
      '팔봉면',
      '해미면',
      '활성동',
    ],
    서천군: [
      '기산면',
      '마산면',
      '마서면',
      '문산면',
      '비인면',
      '서면',
      '서천면',
      '서천읍',
      '시초면',
      '장항읍',
      '종천면',
      '판교면',
      '한산면',
      '화양면',
    ],
    아산군: [
      '도고면',
      '둔포면',
      '배방면',
      '선장면',
      '송악면',
      '신창면',
      '염치면',
      '염치읍',
      '영인면',
      '온양읍',
      '음봉면',
      '인주면',
      '탕정면',
    ],
    아산시: [
      '권곡동',
      '기산동',
      '남동',
      '도고면',
      '둔포면',
      '득산동',
      '모종동',
      '방축동',
      '배미동',
      '배방면',
      '배방읍',
      '법곡동',
      '선장면',
      '송악면',
      '신동',
      '신인동',
      '신창면',
      '실옥동',
      '염치읍',
      '영인면',
      '온천동',
      '용화동',
      '음봉면',
      '읍내동',
      '인주면',
      '장존동',
      '점양동',
      '좌부동',
      '초사동',
      '탕정면',
      '풍기동',
    ],
    연기군: ['금남면', '남면', '동면', '서면', '소정면', '전동면', '전의면', '조치원읍'],
    예산군: [
      '고덕면',
      '광시면',
      '대술면',
      '대흥면',
      '덕산면',
      '봉산면',
      '삽교면',
      '삽교읍',
      '신암면',
      '신양면',
      '예산읍',
      '오가면',
      '응봉면',
    ],
    온양시: [
      '권곡동',
      '기산동',
      '남동',
      '득산동',
      '모종동',
      '방축동',
      '배미동',
      '법곡동',
      '신동',
      '신인동',
      '신정동',
      '실옥동',
      '온주동',
      '온천동',
      '용화동',
      '읍내동',
      '장존동',
      '점양동',
      '좌부동',
      '초사동',
      '풍기동',
    ],
    천안군: [
      '광덕면',
      '동면',
      '목천면',
      '병천면',
      '북면',
      '성거면',
      '성거읍',
      '성남면',
      '성환읍',
      '수신면',
      '입장면',
      '직산면',
      '천안읍',
      '풍세면',
      '환성면',
    ],
    천안시: [
      '광덕면',
      '구룡동',
      '구성동',
      '남산동',
      '다가동',
      '대룡동',
      '대흥동',
      '동면',
      '두정동',
      '목천면',
      '목천읍',
      '문성동',
      '문화동',
      '백석동',
      '병천면',
      '봉명동',
      '부대동',
      '부성동',
      '북면',
      '불당동',
      '사직동',
      '삼룡동',
      '성거읍',
      '성남면',
      '성성동',
      '성정동',
      '성촌동',
      '성환읍',
      '성황동',
      '수신면',
      '신당동',
      '신방동',
      '신부동',
      '신안동',
      '신용동',
      '쌍봉동',
      '쌍용동',
      '안서동',
      '업성동',
      '영성동',
      '오룡동',
      '와촌동',
      '용곡동',
      '원성동',
      '유량동',
      '입장면',
      '직산면',
      '직산읍',
      '차암동',
      '청당동',
      '청수동',
      '청용동',
      '풍세면',
    ],
    천안시동남구: [
      '광덕면',
      '구룡동',
      '구성동',
      '다가동',
      '대흥동',
      '동면',
      '목천읍',
      '문화동',
      '병천면',
      '봉명동',
      '북면',
      '사직동',
      '삼룡동',
      '성남면',
      '성황동',
      '수신면',
      '신방동',
      '신부동',
      '쌍용동',
      '안서동',
      '영성동',
      '오룡동',
      '용곡동',
      '원성동',
      '유량동',
      '청당동',
      '청수동',
      '풍세면',
    ],
    천안시서북구: [
      '두정동',
      '백석동',
      '부대동',
      '불당동',
      '성거읍',
      '성성동',
      '성정동',
      '성환읍',
      '신당동',
      '쌍용동',
      '업성동',
      '와촌동',
      '입장면',
      '직산읍',
      '차암동',
    ],
    천원군: [
      '광덕면',
      '동면',
      '목천면',
      '병천면',
      '북면',
      '성거면',
      '성거읍',
      '성남면',
      '성환면',
      '성환읍',
      '수신면',
      '입장면',
      '직산면',
      '풍세면',
    ],
    청양군: [
      '남양면',
      '대치면',
      '목면',
      '비봉면',
      '사양면',
      '운곡면',
      '장평면',
      '적곡면',
      '정산면',
      '청남면',
      '청양면',
      '청양읍',
      '화성면',
    ],
    태안군: ['고남면', '근흥면', '남면', '소원면', '안면읍', '원북면', '이원면', '태안읍'],
    홍성군: [
      '갈산면',
      '결성면',
      '광천읍',
      '구항면',
      '금마면',
      '서부면',
      '은하면',
      '장곡면',
      '홍동면',
      '홍북면',
      '홍북읍',
      '홍성면',
      '홍성읍',
    ],
  },
  충청북도: {
    괴산군: [
      '감물면',
      '괴산면',
      '괴산읍',
      '도안면',
      '문광면',
      '불정면',
      '사리면',
      '상모면',
      '소수면',
      '연풍면',
      '장연면',
      '증평읍',
      '청안면',
      '청천면',
      '칠성면',
    ],
    단양군: ['가곡면', '단성면', '단양면', '단양읍', '대강면', '매포면', '매포읍', '어상천면', '영춘면', '적성면'],
    보은군: [
      '내북면',
      '내속리면',
      '마로면',
      '보은면',
      '보은읍',
      '산외면',
      '삼승면',
      '속리산면',
      '수한면',
      '외속리면',
      '장안면',
      '탄부면',
      '회남면',
      '회북면',
      '회인면',
    ],
    영동군: [
      '매곡면',
      '상촌면',
      '심천면',
      '양강면',
      '양산면',
      '영동읍',
      '용산면',
      '용화면',
      '추풍령면',
      '학산면',
      '황간면',
      '황금면',
    ],
    옥천군: ['군북면', '군서면', '동이면', '안남면', '안내면', '옥천읍', '이원면', '청산면', '청성면'],
    음성군: ['감곡면', '금왕면', '금왕읍', '대소면', '맹동면', '삼성면', '생극면', '소이면', '원남면', '음성읍'],
    제원군: ['금성면', '덕산면', '백운면', '봉양면', '송학면', '수산면', '청풍면', '한수면'],
    제천군: ['금성면', '덕산면', '백운면', '봉양면', '송학면', '수산면', '제천읍', '청풍면', '한수면'],
    제천시: [
      '강제동',
      '고명동',
      '고암동',
      '교동',
      '금성면',
      '남천동',
      '대랑동',
      '덕산면',
      '동현동',
      '두학동',
      '명동',
      '명서동',
      '명지동',
      '모산동',
      '백운면',
      '봉양면',
      '봉양읍',
      '산곡동',
      '서부동',
      '송학면',
      '수산면',
      '신동',
      '신백동',
      '신월동',
      '영천동',
      '왕암동',
      '용두동',
      '의림동',
      '자작동',
      '장락동',
      '중앙동',
      '천남동',
      '청전동',
      '청풍면',
      '하소동',
      '한수면',
      '화산동',
      '흑석동',
    ],
    중원군: [
      '가금면',
      '금가면',
      '노은면',
      '동량면',
      '산척면',
      '살미면',
      '상모면',
      '소태면',
      '신니면',
      '앙성면',
      '엄정면',
      '이류면',
      '주덕면',
    ],
    증평군: ['도안면', '증평읍'],
    진천군: ['광혜원면', '덕산면', '덕산읍', '만승면', '문백면', '백곡면', '이월면', '진천면', '진천읍', '초평면'],
    청원군: [
      '가덕면',
      '강내면',
      '강서면',
      '강외면',
      '남이면',
      '남일면',
      '낭성면',
      '내수읍',
      '문의면',
      '미원면',
      '부용면',
      '북이면',
      '북일면',
      '사주면',
      '오송읍',
      '오창면',
      '오창읍',
      '옥산면',
      '현도면',
    ],
    청주시: [
      '가경동',
      '강서동',
      '개신동',
      '금천동',
      '남주동',
      '남촌동',
      '내곡동',
      '내덕동',
      '농촌동',
      '대성동',
      '동막동',
      '명암동',
      '모충동',
      '문암동',
      '문화동',
      '미평동',
      '방서동',
      '복대동',
      '봉명동',
      '분평동',
      '비하동',
      '사직동',
      '사창동',
      '사천동',
      '산남동',
      '산성동',
      '상신동',
      '서문동',
      '서운동',
      '서촌동',
      '석곡동',
      '석교동',
      '석소동',
      '성화동',
      '송절동',
      '송정동',
      '수곡동',
      '수동',
      '수의동',
      '신대동',
      '신봉동',
      '신성동',
      '신전동',
      '신촌동',
      '영동',
      '영운동',
      '오동동',
      '외남동',
      '외북동',
      '외평동',
      '외하동',
      '용담동',
      '용암동',
      '용정동',
      '우암동',
      '운동동',
      '운천동',
      '원평동',
      '월오동',
      '율량동',
      '장성동',
      '장암동',
      '정봉동',
      '정북동',
      '정상동',
      '정하동',
      '주성동',
      '주중동',
      '죽림동',
      '지동동',
      '지북동',
      '탑동',
      '평동',
      '평촌동',
      '향정동',
      '현암동',
      '화계동',
      '휴암동',
    ],
    청주시상당구: [
      '가덕면',
      '금천동',
      '남일면',
      '남주동',
      '낭성면',
      '내덕동',
      '대성동',
      '명암동',
      '문의면',
      '문화동',
      '미원면',
      '방서동',
      '사천동',
      '산성동',
      '서문동',
      '서운동',
      '석교동',
      '수동',
      '영동',
      '영운동',
      '오동동',
      '외남동',
      '외평동',
      '외하동',
      '용담동',
      '용암동',
      '용정동',
      '우암동',
      '운동동',
      '월오동',
      '율량동',
      '정북동',
      '정상동',
      '정하동',
      '주성동',
      '주중동',
      '지북동',
      '탑동',
      '평촌동',
    ],
    청주시서원구: [
      '개신동',
      '남이면',
      '모충동',
      '미평동',
      '분평동',
      '사직동',
      '사창동',
      '산남동',
      '성화동',
      '수곡동',
      '장성동',
      '장암동',
      '죽림동',
      '현도면',
    ],
    청주시청원구: [
      '내덕동',
      '내수읍',
      '북이면',
      '사천동',
      '오동동',
      '오창읍',
      '외남동',
      '외평동',
      '외하동',
      '우암동',
      '율량동',
      '정북동',
      '정상동',
      '정하동',
      '주성동',
      '주중동',
    ],
    청주시흥덕구: [
      '가경동',
      '강내면',
      '강서동',
      '개신동',
      '남촌동',
      '내곡동',
      '동막동',
      '모충동',
      '문암동',
      '미평동',
      '복대동',
      '봉명동',
      '분평동',
      '비하동',
      '사직동',
      '사창동',
      '산남동',
      '상신동',
      '서촌동',
      '석곡동',
      '석소동',
      '성화동',
      '송절동',
      '송정동',
      '수곡동',
      '수의동',
      '신대동',
      '신봉동',
      '신성동',
      '신전동',
      '신촌동',
      '오송읍',
      '옥산면',
      '외북동',
      '운천동',
      '원평동',
      '장성동',
      '장암동',
      '정봉동',
      '죽림동',
      '지동동',
      '평동',
      '향정동',
      '현암동',
      '화계동',
      '휴암동',
    ],
    충주시: [
      '가금면',
      '가주동',
      '교현동',
      '금가면',
      '금능동',
      '금릉동',
      '노은면',
      '단월동',
      '달천동',
      '대소원면',
      '동량면',
      '목벌동',
      '목행동',
      '문화동',
      '봉방동',
      '산척면',
      '살미면',
      '상모면',
      '성남동',
      '성내동',
      '성서동',
      '소태면',
      '수안보면',
      '신니면',
      '안림동',
      '앙성면',
      '엄정면',
      '역전동',
      '연수동',
      '용관동',
      '용두동',
      '용산동',
      '용탄동',
      '이류면',
      '종민동',
      '주덕면',
      '주덕읍',
      '중앙탑면',
      '지현동',
      '직동',
      '충의동',
      '충인동',
      '칠금동',
      '풍동',
      '호암동',
    ],
  },
};

import { Box, Collapse, Flex, GridItem, Text, useDisclosure, VStack } from '@chakra-ui/react';
import Scrollbars from 'react-custom-scrollbars-2';
import { WFlex } from '../../../../../../components/common/Flex';
import { GTitleItem } from '../../../../../../components/common/GridItem';
import { RightArrowIcon } from '../../../../../../statics/common/icons';
import PlanIntro from '../PlanIntro';

const Rejected = () => {
  const { isOpen: isStatusOpen, onToggle: onStatusToggle } = useDisclosure();
  const { isOpen: isPlanOpen, onToggle: onPlanToggle } = useDisclosure();
  return (
    <>
      {/* 가입 결과 안내 */}
      <GridItem>
        <GTitleItem
          upperSection={
            <Flex justifyContent={'space-between'} alignItems={'center'} cursor={'pointer'} onClick={onStatusToggle}>
              <Text fontSize={'18px'} fontWeight={'medium'}>
                플랜 정보
              </Text>
              <RightArrowIcon
                w={6}
                h={6}
                transform={`rotate(${!isStatusOpen ? 270 : 90}deg)`}
                transition={'transform 0.15s linear'}
              />
            </Flex>
          }
          mb={6}
          // gridColumnStart={2}
          // gridColumnEnd={5}
          // rowSpan={1}
        >
          <Flex flexDir={'column'} w="full">
            <Text textAlign={'center'} my={10} fontWeight={'medium'}>
              가입이 반려되었습니다.
            </Text>
            <WFlex flexDir={'column'} px={9} py={5} mx={7} mb={9}>
              <Text fontWeight={'medium'} pb={4}>
                반려 사유
              </Text>
              <Text fontSize={'14px'}>이메일에서 반려 사유 확인 후 재승인 요청 해주세요.</Text>
            </WFlex>
          </Flex>
        </GTitleItem>
        {/* 보홈 플랜 안내 */}
        <GTitleItem
          upperSection={
            <Flex justifyContent={'space-between'} alignItems={'center'} cursor={'pointer'} onClick={onPlanToggle}>
              <Text fontSize={'18px'} fontWeight={'medium'}>
                보홈 플랜 안내
              </Text>
              <RightArrowIcon
                w={6}
                h={6}
                transform={`rotate(${!isPlanOpen ? 270 : 90}deg)`}
                transition={'transform 0.15s linear'}
              />
            </Flex>
          }
          gridColumnStart={2}
          gridColumnEnd={5}
          // rowSpan={1}
        >
          {/* <Scrollbars style={{ height: '100%' }} renderTrackVertical={() => <div />}> */}
          <Collapse
            in={!isPlanOpen}
            startingHeight={'1px'}
            style={{ width: '100%', marginTop: 'unset' }}
            // endingHeight={'100%'}
          >
            <Box mx={6} my={4}>
              <PlanIntro />
            </Box>
          </Collapse>
          {/* </Scrollbars> */}
        </GTitleItem>
      </GridItem>
    </>
  );
};

export default Rejected;

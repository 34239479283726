import { VStack, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Flex, Text, HStack, Box, Tooltip } from '@chakra-ui/react';
import { useSearchParams, Link as RouterLink, matchPath, useLocation, useParams } from 'react-router-dom';
import Loading from '../../../../../../../../components/common/Loading';
import { useGetCompanyAllManagers } from '../../../../../../../../lib/admin/hooks/query';
import { currentParamsObject } from '../../../../../../../../lib/common/utils/base';
import ActiveBreadcrumbLink from '../../../../../../../../components/common/ActiveBreadcrumbLink';

const AfterLayout: React.FC = ({ children }) => {
  const { id } = currentParamsObject(false);
  const { data: cpData, isLoading } = useGetCompanyAllManagers(id || '');

  return (
    <VStack alignItems={'start'} w={'100%'} h="full">
      <HStack alignItems={'end'} mb={4}>
        <Text textStyle={'bold30'} lineHeight={1} mr={4}>
          {cpData?.name}
        </Text>
        <HStack spacing={8} divider={<Text>&nbsp;&nbsp;|&nbsp;&nbsp;</Text>}>
          <ActiveBreadcrumbLink to={`/admin/manage/customers/agency/detail/info?id=${id}`}>
            회원 정보
          </ActiveBreadcrumbLink>
          <ActiveBreadcrumbLink to={`/admin/manage/customers/agency/detail/memo?id=${id}`}>메모</ActiveBreadcrumbLink>
        </HStack>
      </HStack>
      <Flex w={'100%'} h="full">
        {children}
      </Flex>
    </VStack>
  );
};

export default AfterLayout;

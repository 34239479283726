import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Text,
  toast,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { useUpdateLeasePaymentsStatus } from '../../../../lib/admin/hooks/mutation';
import {
  CertificateIssueTypeEnum,
  LeasePaymentStatusEnum,
  ContractPaymentStatus,
} from '../../../../lib/common/utils/axios/types';
import {
  certificate_issue_type_select,
  contract_status,
  global_payment_status_select,
} from '../../../../statics/common/data/selectbox';
import { error } from '../../../../statics/common/data/toast';
import SelectBox from '../../../agent/SelectBox/base';

interface ChangeLeasePaymentStatusProps {
  lease_payment_id: string;
  payment_status: LeasePaymentStatusEnum;
}

const ChangeLeasePaymentStatus: React.FC<ChangeLeasePaymentStatusProps> = ({ lease_payment_id, payment_status }) => {
  const { mutate } = useUpdateLeasePaymentsStatus();
  const toast = useToast();
  const [value, setValue] = useState<LeasePaymentStatusEnum>(payment_status);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();

  const handleChangeOption = (e: any) => {
    setValue(e.target.value);
    onOpen();
  };

  const handleCloseAlert = () => {
    setValue(payment_status);
  };

  const handleCancle = () => {
    handleCloseAlert();
    onClose();
  };

  const handleFinalize = () => {
    switch (value as LeasePaymentStatusEnum) {
      case 'paid':
        mutate({
          lease_payment_id,
          lease_contract: {
            is_paid: true,
          },
        });
        break;
      default:
        toast({
          ...error,
          title: '불가능한 시도',
          description: "'연체'와 '납부 전'은 서로 대체될 수 없습니다. 새로고침이 필요합니다.",
        });
        break;
    }
    onClose();
  };

  if (payment_status === undefined) {
    return <Text fontSize={'14px'}></Text>;
  } else if (payment_status === 'paid') {
    return <Text fontSize={'14px'}>납부 완료</Text>;
  } else {
    return (
      <>
        <SelectBox
          value={payment_status}
          onChange={(e) => {
            handleChangeOption(e);
          }}
        >
          {global_payment_status_select.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </SelectBox>

        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef.current}
          onClose={onClose}
          isCentered
          onEsc={handleCloseAlert}
          onOverlayClick={handleCloseAlert}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                재확인
              </AlertDialogHeader>

              <AlertDialogBody>확정하시겠습니까?</AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef.current} variant={'gray'} onClick={handleCancle}>
                  취소
                </Button>
                <Button colorScheme="red" onClick={handleFinalize} variant={'basic'} ml={3}>
                  확정하기
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </>
    );
  }
};

export default ChangeLeasePaymentStatus;

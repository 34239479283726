import { Box, HStack, StackDivider, Table, Tbody, Tr, VStack } from '@chakra-ui/react';
import { Field } from 'formik';
import { CustomTd } from '../type';
import { FCheckboxButtonGroup } from '../../../../Checkbox/base';
import { FRadioButtonGroup } from '../../../../Radio';
import { remodeling_items_checkbox } from '../../../../../../statics/common/data/checkbox';
import { access_status_radio } from '../../../../../../statics/common/data/radio';
import { remodeling_season as remodeling_season_data } from '../../../../../../statics/common/data/selectbox';
import SelectBox from '../../../../../agent/SelectBox/base';

const AccessRangeTable = () => {
  return (
    <Table w={'100%'} variant="simple">
      <Tbody w={'100%'}>
        <Tr w={'100%'}>
          <CustomTd title={'매물 공개여부'}>
            <HStack>
              {/* <Field name="remodeling_items" as={FCheckboxButtonGroup} options={remodeling_items_checkbox} /> */}
              <Field name="access_status" as={FRadioButtonGroup} options={access_status_radio} />
            </HStack>
          </CustomTd>
        </Tr>
      </Tbody>
    </Table>
  );
};

export default AccessRangeTable;

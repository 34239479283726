import { Button, ButtonProps, IconProps } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';

interface ArrowButtonProps extends ButtonProps {
  isright?: boolean;
  isleft?: boolean;
}

const ArrowButton: React.FC<ArrowButtonProps> = ({ children, isright, isleft, ...props }) => {
  const icon_props = {
    boxSize: 8,
  } as IconProps;

  const child = isright ? (
    <ChevronRightIcon {...icon_props} />
  ) : isleft ? (
    <ChevronLeftIcon {...icon_props} />
  ) : (
    children
  );

  const icon_container_props =
    isright || isleft
      ? ({
          boxSize: 10,
          p: 0,
        } as ButtonProps)
      : {};
  return (
    <Button
      p={0}
      variant={'pagination_arrow_button'}
      isActive
      //   isDisabled={isCurrent}
      {...icon_container_props}
      {...props}
    >
      {child}
    </Button>
  );
};

export default ArrowButton;

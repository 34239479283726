import {
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { Field, Form, Formik, useFormikContext } from 'formik';
import Scrollbars from 'react-custom-scrollbars-2';
import { useCreateManualSimpleLeasePayment } from '../../../../lib/admin/hooks/mutation';
import { useGetAllUsers } from '../../../../lib/admin/hooks/query';
import { AccountType } from '../../../../lib/common/utils/axios/types';
import { bankNameTrans } from '../../../../lib/common/utils/base';
import {
  certificate_issue_type_select,
  lease_payment_proxy_status,
  payment_method_select,
} from '../../../../statics/common/data/selectbox';
import SelectBox from '../../../agent/SelectBox/base';
import { formikInitialValuesProps } from '../ConfirmContractModal';
import { mapValues, pickBy, identity } from 'lodash';
import * as Yup from 'yup';

interface RegisterManualBillModalProps {
  disabled?: boolean;
}

const RegisterManualBillModal: React.FC<RegisterManualBillModalProps> = ({ children, disabled = false }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data: all_users_data } = useGetAllUsers();
  const boxMinWidth = '150px';

  const handleModalOpen = () => {
    if (!disabled) {
      onOpen();
    }
  };

  const { mutateAsync } = useCreateManualSimpleLeasePayment();

  return (
    <>
      <Tooltip label={'수동 청구서 등록하기'} fontSize={'14px'} defaultIsOpen hasArrow placement={'top'}>
        <Box onClick={handleModalOpen}>{children}</Box>
      </Tooltip>

      <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside" isCentered size={'6xl'}>
        <ModalOverlay />
        <Formik
          initialValues={{
            // 납기일
            expected_payment_date: '',
            // 이용 시작일
            use_start_date: '',
            // 이용 종료일
            use_end_date: '',
            // 납부일
            real_payment_date: '',
            // 정산일
            real_transfer_date: '',
            payment_method_type: '',
            certificate_issue_type: '',
            proxy_payment_status: '',
            // *
            host_id: '',
            // *
            tenant_id: '',
            host_frontend: '',
            // *
            account: '',
            account_frontend: '',
            accounts: [],
            price: '',
            is_paid: false,
          }}
          validateOnMount
          validationSchema={Yup.object().shape({
            host_id: Yup.string().required(),
            tenant_id: Yup.string().required(),
            account: Yup.object().required(),
          })}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);

            // const filtered_values = mapValues(values, (value) => !!value);
            const setv = {
              use_start_date: values.use_start_date,
              use_end_date: values.use_end_date,
              expected_payment_date: values.expected_payment_date,
              payment_method_type: values.payment_method_type,
              tenant_id: values.tenant_id,
              host_id: values.host_id,
              account: values.account,
              price: values.price,
              is_paid: values.is_paid,
            };
            const fv = pickBy(setv, identity);
            const sender = { ...(fv as any), is_paid: values.is_paid };
            console.log('data sender =>', sender);

            mutateAsync(sender);
          }}
        >
          {({ values, setFieldValue, errors, isValid }) => {
            console.log('errors =>', errors);

            const isAbleSubmit = isValid;

            return (
              <Form>
                <ModalContent p={5}>
                  <ModalCloseButton />
                  <Flex flexDir={'column'} mx={2} my={6} alignItems={'flex-start'}>
                    <Text textStyle={'bold24'}>수동 청구서 등록</Text>
                    <Text textStyle={'bold18'} my={2}>
                      등록할 청구서 내용을 입력해 주세요.
                    </Text>
                    <Scrollbars style={{ height: '150px' }}>
                      <Table variant="head_silverbg_toplining">
                        <Thead bgColor={'#F8F8F8'} borderColor={'gray.100'}>
                          <Tr>
                            <Th>*유저이름</Th>
                            <Th>*송금인명</Th>
                            <Th>*정산(임대인)</Th>
                            <Th>*계좌(임대인)</Th>
                            <Th>*청구 금액</Th>
                            <Th>납부 여부</Th>
                            <Th>납기일</Th>
                            <Th>*이용 시작일</Th>
                            <Th>*이용 종료일</Th>
                            <Th>납부일</Th>
                            <Th>정산일</Th>
                            <Th>*납부 방법</Th>
                            <Th>증빙 발행</Th>
                            <Th>대납 상태</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          <Tr>
                            <Td>
                              {/* 송금인 */}
                              <Field
                                name="tenant_id"
                                as={SelectBox}
                                minW={boxMinWidth}
                                placeholder={'선택'}
                                h={'32px'}
                                bgColor="lotionWhite"
                              >
                                {all_users_data?.map((company, index) => (
                                  <option value={company.user._id} key={index}>
                                    {company.name + '(' + company.user?.virtual_account?.account_owner_name + ')'}
                                  </option>
                                ))}
                              </Field>
                            </Td>
                            <Td>
                              {/* 정산(임대인) */}
                              <Field
                                name="host_frontend"
                                as={SelectBox}
                                minW={boxMinWidth}
                                placeholder={'선택'}
                                h={'32px'}
                                bgColor="lotionWhite"
                                onChange={(e: any) => {
                                  const value = JSON.parse(e.target.value);

                                  setFieldValue('host_frontend', e.target.value);
                                  setFieldValue('host_id', value._id);
                                  setFieldValue('accounts', value.accounts);
                                }}
                              >
                                {all_users_data?.map((company, index) => {
                                  if (company.user.user_type === 'host') {
                                    return (
                                      <option value={JSON.stringify(company.user)} key={index}>
                                        {company.name + '(' + company.user.user_name + ')'}
                                      </option>
                                    );
                                  }
                                })}
                              </Field>
                            </Td>
                            <Td>
                              {/* 계좌(임대인) */}
                              <Field
                                name="account_frontend"
                                as={SelectBox}
                                minW={boxMinWidth}
                                placeholder={'선택'}
                                h={'32px'}
                                bgColor="lotionWhite"
                                onChange={(e: any) => {
                                  const value = JSON.parse(e.target.value);

                                  setFieldValue('account_frontend', e.target.value);
                                  setFieldValue('account', value);
                                }}
                              >
                                {values?.accounts?.map((account: AccountType, index) => {
                                  console.log(account);

                                  return (
                                    <option value={JSON.stringify(account)} key={index}>
                                      {bankNameTrans(account?.bank_code) +
                                        ' ' +
                                        account?.account_owner_name +
                                        '(' +
                                        account?.account_number +
                                        ')'}
                                    </option>
                                  );
                                })}
                              </Field>
                            </Td>
                            <Td>
                              <InputGroup size="sm">
                                {/* 청구 금액 */}
                                <Field name={'price'} as={Input} minW={boxMinWidth} fontSize={'14px'} h={'32px'} />
                                <InputRightElement children="원" />
                              </InputGroup>
                            </Td>
                            <Td>
                              {/* 납부 여부 */}
                              <Field name={'is_paid'} as={Checkbox} />
                            </Td>
                            <Td>
                              {/* 납기일 */}
                              <Field
                                name={'expected_payment_date'}
                                as={Input}
                                minW={boxMinWidth}
                                type={'date'}
                                fontSize={'14px'}
                                h={'32px'}
                              />
                            </Td>
                            <Td>
                              {/* 이용 시작일 */}
                              <Field
                                name={'use_start_date'}
                                as={Input}
                                minW={boxMinWidth}
                                type={'date'}
                                fontSize={'14px'}
                                h={'32px'}
                              />
                            </Td>
                            <Td>
                              {/* 이용 종료일 */}
                              <Field
                                name={'use_end_date'}
                                as={Input}
                                minW={boxMinWidth}
                                type={'date'}
                                fontSize={'14px'}
                                h={'32px'}
                              />
                            </Td>
                            <Td>
                              {/* 납부일 */}
                              <Field
                                name={'real_payment_date'}
                                as={Input}
                                minW={boxMinWidth}
                                type={'date'}
                                fontSize={'14px'}
                                h={'32px'}
                              />
                            </Td>
                            <Td>
                              {/* 정산일 */}
                              <Field
                                name={'real_transfer_date'}
                                as={Input}
                                minW={boxMinWidth}
                                type={'date'}
                                fontSize={'14px'}
                                h={'32px'}
                              />
                            </Td>
                            <Td>
                              {/* 납부 방법 */}
                              <Field
                                name="payment_method_type"
                                as={SelectBox}
                                minW={boxMinWidth}
                                h={'32px'}
                                bgColor="lotionWhite"
                                placeholder="선택"
                              >
                                {payment_method_select.map((option) => (
                                  <option key={option.value} value={option.value}>
                                    {option.label}
                                  </option>
                                ))}
                              </Field>
                            </Td>
                            <Td>
                              {/* 증빙 발행 */}
                              <Field
                                name="certificate_issue_type"
                                as={SelectBox}
                                minW={boxMinWidth}
                                h={'32px'}
                                bgColor="lotionWhite"
                                placeholder="선택"
                              >
                                {certificate_issue_type_select.map((option) => (
                                  <option key={option.value} value={option.value}>
                                    {option.label}
                                  </option>
                                ))}
                              </Field>
                            </Td>
                            <Td>
                              {/* 대납 상태 */}
                              <Field
                                name="proxy_payment_status"
                                as={SelectBox}
                                minW={boxMinWidth}
                                h={'32px'}
                                bgColor="lotionWhite"
                                placeholder="선택"
                              >
                                {lease_payment_proxy_status.map((option) => (
                                  <option key={option.value} value={option.value}>
                                    {option.label}
                                  </option>
                                ))}
                              </Field>
                            </Td>
                          </Tr>
                        </Tbody>
                      </Table>
                    </Scrollbars>
                    <Center pt={10} w="full">
                      <Button variant={'basic'} w="200px" minH="44px" type="submit" isDisabled={!isAbleSubmit}>
                        등록 완료
                      </Button>
                    </Center>
                  </Flex>
                </ModalContent>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

export default RegisterManualBillModal;

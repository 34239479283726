import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  ListItem,
  Skeleton,
  Spinner,
  Text,
  UnorderedList,
  VStack,
} from '@chakra-ui/react';
import { isEmptyObject, isUndefined } from '@chakra-ui/utils';
import styled from '@emotion/styled';
import format from 'date-fns/format';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  parseCounts,
  useGetAllCompanyContractPayments,
  useGetAllCounts,
  useGetCompanyContract,
  useGetCompanyIsApproved,
} from '../../../lib/subscriber/hooks/query';
import { checkArrAndGetFirst, paymentMethodTypeTrans, paymentPeriodTrans } from '../../../lib/subscriber/utils/utils';
import { datedotformat } from '../../../lib/common/utils/dateformatter';
import { decimalize } from '../../../lib/common/utils/digitformatter';
import _ from 'lodash';
import { RightArrowIcon, UserAddIcon, UserCheckIcon, UsersIcon } from '../../../statics/common/icons';
import AvailableUser from './availableuser';
import ContractType from './contracttype';
import PaymentAndTax from './paymentandtax';

// 가입 승인 전
const ErrorP = () => {
  return (
    <VStack h="209px" justifyContent={'center'} fontSize={'20px'} fontWeight={'medium'}>
      <Box>새로고침을 해주세요.</Box>
    </VStack>
  );
};

// 가입 승인 전
const Zero = () => {
  return (
    <VStack h="209px" justifyContent={'center'} fontSize={'20px'} fontWeight={'medium'}>
      <Box>가입 승인 대기 중입니다.</Box>
    </VStack>
  );
};

// 가입 승인 후 / 플랜 구입 전
const First = () => {
  return (
    <VStack h="209px" justifyContent={'center'} fontSize={'20px'} fontWeight={'medium'}>
      <Box>가입 승인이 완료되었습니다.</Box>
      <Box>원하시는 플랜으로 구독 신청해주세요.</Box>
    </VStack>
  );
};

const NBox = styled(Box)`
  width: 100%;
  text-align: center;
  border: 1px solid #e5e5e5;
  background-color: #ffffff;
  padding: 6px;
  border-radius: 10px;
  color: #c4c4c4;
  font-size: 14px;
`;

// 가입 승인 완료
const Second = () => {
  const navigate = useNavigate();
  const { data: contractData } = useGetCompanyContract();
  if (isUndefined(contractData)) {
    return null;
  }
  return (
    <Flex w="full" h="full">
      {/* 현재 상태 */}
      <Flex
        w={'33%'}
        flexDir={'column'}
        justifyContent={'space-between'}
        borderWidth={'1px'}
        borderColor={'borderGray'}
        borderRadius={'xl'}
        ml={6}
        my={5}
        px={6}
        py={3}
        boxShadow={'0px 0px 4px 4px #E5E5E53B;'}
      >
        <Box>
          <Flex w={'full'} alignItems={'center'} justifyContent={'space-between'} fontSize={'14px'}>
            <Text fontWeight={'bold'}>구독 신청</Text>
            <Text color="silverGray">{datedotformat(contractData.contract_created_date)}</Text>
          </Flex>
          <ContractType centerprops={{ mt: 3, mb: 2 }} />
          <Flex flexDir={'column'} fontSize={'12px'}>
            <AvailableUser />
            <PaymentAndTax />
          </Flex>
        </Box>
        <Button
          variant={'gray_black_font'}
          fontSize={'14px'}
          mt={1}
          onClick={() => {
            navigate('/company/manage/subscription/subscribe/change');
          }}
        >
          요청한 플랜 확인 및 변경
        </Button>
      </Flex>
      <RightArrowIcon w={6} h={6} color="silverGray" border="unset" pos={'relative'} top={'28px'} mx={2} />
      {/* 진행될 미래 상태들 */}
      <VStack w="67%" my={5} mr={5} spacing={3} alignItems={'flex-start'}>
        <HStack
          w="full"
          justifyContent={'space-evenly'}
          divider={<RightArrowIcon w={6} h={6} color="silverGray" border="unset" />}
        >
          <NBox>계약서 서명</NBox>
          <NBox>결제</NBox>
          <NBox>서비스 도입</NBox>
        </HStack>
        <Center flexDir={'column'} bg={'mainBgColor'} w="full" h="full" borderRadius={'2xl'}>
          <Box>보홈에서 구독 신청 확인 중입니다.</Box>
          <Box>확인 완료 후 계약서를 전송해드리겠습니다.</Box>
        </Center>
      </VStack>
    </Flex>
  );
};

// 계약서 서명요청
const Third = () => {
  const navigate = useNavigate();
  const { data: contractData } = useGetCompanyContract();
  if (isUndefined(contractData)) {
    return null;
  }
  return (
    <Flex w="full" h="full">
      {/* 현재 상태 */}
      <Flex
        w={'33%'}
        flexDir={'column'}
        justifyContent={'space-between'}
        borderWidth={'1px'}
        borderColor={'borderGray'}
        borderRadius={'xl'}
        ml={6}
        my={5}
        px={6}
        py={3}
        boxShadow={'0px 0px 4px 4px #E5E5E53B;'}
      >
        <Box>
          <Flex w={'full'} alignItems={'center'} justifyContent={'space-between'} fontSize={'14px'}>
            <Text fontWeight={'bold'}>결제</Text>
            <Text color="silverGray">{datedotformat(contractData.contract_created_date)}</Text>
          </Flex>
          <ContractType centerprops={{ mt: 4, mb: 1 }} />
          <Flex flexDir={'column'} fontSize={'12px'}>
            <AvailableUser />
            <PaymentAndTax />
          </Flex>
        </Box>
        <Button
          variant={'gray_black_font'}
          fontSize={'14px'}
          onClick={() => {
            navigate('invoice');
          }}
        >
          청구서 보기
        </Button>
      </Flex>
      <RightArrowIcon w={6} h={6} color="silverGray" border="unset" pos={'relative'} top={'28px'} mx={2} />
      {/* 진행될 미래 상태들 */}
      <VStack w="67%" my={5} mr={5} spacing={3} alignItems={'flex-start'}>
        <HStack
          w="66%"
          justifyContent={'space-evenly'}
          divider={<RightArrowIcon w={6} h={6} color="silverGray" border="unset" />}
        >
          <NBox w={'calc(100% / 3)'}>결제</NBox>
          <NBox>서비스 도입</NBox>
        </HStack>
        <Center flexDir={'column'} bg={'mainBgColor'} w="full" h="full" borderRadius={'2xl'}>
          <Box>계약서가 도착했습니다.</Box>
          <Box>이메일에서 계약서 확인 후 서명해 주세요.</Box>
        </Center>
      </VStack>
    </Flex>
  );
};

// 결제 요청
const Fourth = () => {
  const navigate = useNavigate();
  const { data: contractData } = useGetCompanyContract();
  if (isUndefined(contractData)) {
    return null;
  }
  return (
    <Flex w="full" h="full">
      {/* 현재 상태 */}
      <Flex
        w={'33%'}
        flexDir={'column'}
        justifyContent={'space-between'}
        borderWidth={'1px'}
        borderColor={'borderGray'}
        borderRadius={'xl'}
        ml={6}
        my={5}
        px={6}
        py={3}
        boxShadow={'0px 0px 4px 4px #E5E5E53B;'}
      >
        <Box>
          <Flex w={'full'} alignItems={'center'} justifyContent={'space-between'} fontSize={'14px'}>
            <Text fontWeight={'bold'}>결제</Text>
            <Text color="silverGray">{datedotformat(contractData.contract_created_date)}</Text>
          </Flex>
          <ContractType centerprops={{ mt: 4, mb: 1 }} />
          <Flex flexDir={'column'} fontSize={'12px'}>
            <AvailableUser />
            <PaymentAndTax />
          </Flex>
        </Box>
        <Button
          variant={'gray_black_font'}
          fontSize={'14px'}
          onClick={() => {
            navigate('invoice');
          }}
        >
          청구서 보기
        </Button>
      </Flex>
      <RightArrowIcon w={6} h={6} color="silverGray" border="unset" pos={'relative'} top={'28px'} mx={2} />
      {/* 진행될 미래 상태들 */}
      <VStack w="67%" my={5} mr={5} spacing={3} alignItems={'flex-start'}>
        <HStack
          w="33%"
          justifyContent={'space-evenly'}
          divider={<RightArrowIcon w={6} h={6} color="silverGray" border="unset" />}
        >
          <NBox>서비스 도입</NBox>
        </HStack>
        <Center flexDir={'column'} bg={'mainBgColor'} w="full" h="full" borderRadius={'2xl'}>
          <Box>계약서 서명이 확인 되었습니다.</Box>
          <Box>청구서 확인 후 결제 완료해 주세요.</Box>
          <Box>결제까지 완료되어야 구독 체결이 완료됩니다.</Box>
        </Center>
      </VStack>
    </Flex>
  );
};

// 구독 관리
const Fifth = () => {
  const navigate = useNavigate();
  const { data: contractData } = useGetCompanyContract();
  if (isUndefined(contractData)) {
    return null;
  }
  return (
    <Flex w="full" h="full">
      {/* 현재 상태 */}
      <Flex
        w={'33%'}
        flexDir={'column'}
        justifyContent={'space-between'}
        borderWidth={'1px'}
        borderColor={'borderGray'}
        borderRadius={'xl'}
        mx={6}
        my={5}
        px={6}
        py={3}
        boxShadow={'0px 0px 4px 4px #E5E5E53B;'}
      >
        <Box>
          <Flex w={'full'} alignItems={'center'} justifyContent={'space-between'} fontSize={'14px'}>
            <Text fontWeight={'bold'}>결제</Text>
            <Text color="silverGray">{datedotformat(contractData.contract_created_date)}</Text>
          </Flex>
          <ContractType />
          <Flex flexDir={'column'} fontSize={'12px'}>
            <AvailableUser />
            <PaymentAndTax />
          </Flex>
        </Box>
        <Button
          variant={'gray_black_font'}
          fontSize={'14px'}
          onClick={() => {
            navigate('invoice');
          }}
        >
          청구서 보기
        </Button>
      </Flex>
      {/* 진행될 미래 상태들 */}
      <VStack w="67%" my={5} mr={5} spacing={3} alignItems={'flex-start'}>
        <Center flexDir={'column'} bg={'mainBgColor'} w="full" h="full" borderRadius={'2xl'}>
          <Box>구독 체결이 완료되었습니다.</Box>
          <Box>서비스 이용에 앞서 도입 준비를 도와드리겠습니다.</Box>
        </Center>
      </VStack>
    </Flex>
  );
};

const Sixth = () => {
  const { data: countsdata } = useGetAllCounts();
  const { data: contractdata } = useGetCompanyContract();
  const { data: paymentsdata } = useGetAllCompanyContractPayments();

  if (countsdata === undefined || contractdata === undefined || paymentsdata === undefined) {
    return (
      <Center w={'100%'} h={'100%'}>
        <Spinner />
      </Center>
    );
  }

  const { services_count, usings_count, supports_count } = countsdata;

  const total_requests_count =
    parseCounts(services_count, 'total') !== undefined ? parseCounts(services_count, 'total').count : 0;
  const total_on_using_employees_count =
    parseCounts(usings_count, 'total') !== undefined ? parseCounts(usings_count, 'total').count : 0;

  const { is_year, payment_method_type, subscription_type, available_user_count } = contractdata;

  const is_paid_paymentsdata = paymentsdata.filter((payment) => payment.is_paid === true);
  const use_start_date = checkArrAndGetFirst(is_paid_paymentsdata).use_start_date;
  const use_end_date = checkArrAndGetFirst(is_paid_paymentsdata.slice(-1)).use_end_date;
  // is_paid_paymentsdata.slice(-1)[0].use_end_date;

  const is_not_paid_paymentsdata = paymentsdata.filter((payment) => payment.is_paid === false);
  const next_payment_date = checkArrAndGetFirst(is_not_paid_paymentsdata).expected_payment_date;

  // const is_supported_count =
  //   parseCounts(supports_count, 'total') !== undefined ? parseCounts(supports_count, 'total').count : 0;

  return (
    <HStack w="full" h="full" p={4} spacing={4} align={'start'}>
      {/* 구독 현황 */}
      <Flex
        w={'full'}
        h="full"
        flexDir={'column'}
        justifyContent={'space-between'}
        borderWidth={'1px'}
        borderColor={'borderGray'}
        borderRadius={'xl'}
        px={6}
        py={4}
        boxShadow={'0px 0px 4px 4px #E5E5E53B;'}
      >
        <Box>
          <Center alignItems={'flex-start'} justifyContent={'space-between'} fontSize={'20px'} fontWeight={700}>
            <Text fontWeight={'bold'} fontSize={'14px'}>
              구독 현황
            </Text>
            {/* <Button variant={'gray_black_font'} fontSize={'12px'} h={'30px'} px={2} lineHeight={0}>
              플랜 변경
            </Button> */}
          </Center>
          <Box my={'6px'}>
            <ContractType />
          </Box>
          <Flex flexDir={'column'} fontSize={'14px'}>
            <HStack>
              <UsersIcon />
              <Text fontWeight={'medium'}>
                {total_on_using_employees_count}인 이용 중 / {available_user_count}인
              </Text>
            </HStack>
            {/* <HStack>
              <UserAddIcon />
              <Text fontWeight={'medium'}>월세 지원 : {is_supported_count}인</Text>
            </HStack> */}
            <HStack>
              <UserCheckIcon />
              <Text fontWeight={'medium'}>
                {`잔여`} : {available_user_count - total_on_using_employees_count}인
              </Text>
            </HStack>
            <PaymentAndTax />
          </Flex>
        </Box>
      </Flex>
      {/* 이용 정보 */}
      <Flex
        display={'block'}
        w={'full'}
        h="full"
        flexDir={'column'}
        borderWidth={'1px'}
        borderColor={'borderGray'}
        borderRadius={'xl'}
        px={6}
        py={4}
        boxShadow={'0px 0px 4px 4px #E5E5E53B;'}
      >
        <Flex w={'full'} alignItems={'center'} justifyContent={'space-between'} fontSize={'14px'} pb={4}>
          <Text fontWeight={'bold'}>이용 정보</Text>
          {/* <Text
            as={RouterLink}
            to="/company/manage/employee"
            textDecorationLine={'underline'}
            textUnderlineOffset={'2px'}
            cursor={'pointer'}
            fontSize={'10px'}
          >
            전체 보기
          </Text> */}
        </Flex>
        <VStack alignItems={'flex-start'} fontSize={'14px'}>
          <HStack>
            <Text>💌</Text>
            <Text
              as={RouterLink}
              to="/company/manage/employee"
              textDecorationLine={'underline'}
              textUnderlineOffset={'2px'}
              cursor={'pointer'}
              fontWeight={'medium'}
            >
              전체 이용 신청 ({decimalize(total_requests_count)}건)
            </Text>
          </HStack>
          <HStack>
            <Text>🏠</Text>
            <Text
              as={RouterLink}
              to="/company/manage/employee/onseek"
              textDecorationLine={'underline'}
              textUnderlineOffset={'2px'}
              cursor={'pointer'}
              fontWeight={'medium'}
            >
              이용중인 임직원 ({decimalize(total_on_using_employees_count)}건)
            </Text>
          </HStack>
        </VStack>
      </Flex>
      {/* 결제 정보 */}
      <Flex
        w={'full'}
        h="full"
        flexDir={'column'}
        borderWidth={'1px'}
        borderColor={'borderGray'}
        borderRadius={'xl'}
        px={6}
        py={4}
        boxShadow={'0px 0px 4px 4px #E5E5E53B;'}
      >
        <Flex w={'full'} alignItems={'center'} justifyContent={'space-between'} fontSize={'14px'} pb={4}>
          <Text fontWeight={'bold'}>결제 정보</Text>
          {/* <Text
            as={RouterLink}
            to="test"
            textDecorationLine={'underline'}
            textUnderlineOffset={'2px'}
            cursor={'pointer'}
            fontSize={'10px'}
          >
            결제 정보 설정
          </Text> */}
        </Flex>
        <UnorderedList spacing={1}>
          <ListItem>
            <HStack fontSize={'14px'}>
              <Text fontWeight={'medium'} w={'100px'}>
                결제 방식
              </Text>
              <Text>{paymentPeriodTrans(is_year)}</Text>
            </HStack>
          </ListItem>
          <ListItem>
            <HStack fontSize={'14px'}>
              <Text fontWeight={'medium'} w={'100px'}>
                결제 수단
              </Text>
              <Text>{paymentMethodTypeTrans(payment_method_type)}</Text>
            </HStack>
          </ListItem>
          <ListItem>
            <HStack fontSize={'14px'}>
              <Text fontWeight={'medium'} w={'100px'}>
                구독 유효 기간
              </Text>
              <Text minW={'max'}>
                {datedotformat(contractdata?.contract_start_date)} - {datedotformat(contractdata?.contract_end_date)}
              </Text>
              {/* <Text>
                {datedotformat(use_start_date)} - {datedotformat(use_end_date)}
              </Text> */}
            </HStack>
          </ListItem>
          <ListItem>
            <HStack fontSize={'14px'}>
              <Text fontWeight={'medium'} w={'100px'}>
                다음 결제일
              </Text>
              <Text>{datedotformat(next_payment_date)}</Text>
            </HStack>
          </ListItem>
        </UnorderedList>
      </Flex>
    </HStack>
  );
};

const phases = {
  error: <ErrorP />,
  zero: <Zero />,
  first: <First />,
  second: <Second />,
  third: <Third />,
  fourth: <Fourth />,
  fifth: <Fifth />,
  sixth: <Sixth />,
};

const PlanInfo = () => {
  const {
    data: contractData,
    isIdle: isContractIdle,
    isPlaceholderData,
    isFetchedAfterMount,
    isFetched,
    isFetching,
    isLoading,
  } = useGetCompanyContract();

  const isApproved = useGetCompanyIsApproved();

  const judge = () => {
    if (isLoading) {
      return false;
    } else {
      if (isFetched) {
        if (isPlaceholderData) {
          return true;
        }
      }
    }
    return false;
  };

  // if (!isFetched || isPlaceholderData) {
  //   return null;
  // }

  // console.log(contractData);
  console.log('contractData?.payment_status =>', contractData?.payment_status); // ''
  console.log('contractData =>', contractData);

  console.log(isPlaceholderData);

  if (isApproved) {
    if (_.isEmpty(contractData) || !contractData?.payment_status) {
      return phases.first;
    } else {
      if (contractData?.payment_status === 'confirm_expected') {
        return phases.second;
      } else if (contractData?.payment_status === 'sign_expected') {
        return phases.third;
      } else if (contractData?.payment_status === 'total_contract_payment_expected') {
        return phases.fourth;
      } else if (contractData?.payment_status === 'subscription_payment_expected') {
        return phases.sixth;
      } else if (contractData?.payment_status === 'payment_completed') {
        return phases.sixth;
      } else {
        return null;
      }
    }
  } else {
    // 미가입시
    return phases.zero;
  }
};
export default PlanInfo;

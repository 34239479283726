import { Box, Button, Center, Flex, Image, Input, InputProps, Stack, Text, useToast, VStack } from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import Validation from '../../../../components/common/FormikValidation/Validation';
import TextLink from '../../../../components/common/Link/TextLink';
import { loginFail, loginSuccess, serverOrTypeNotMatched } from '../../../../statics/common/data/toast';
import { getWebFcmToken, messaging, signIn, useLogout } from '../../../../lib/common/utils/firebase/init';
import { setLocalStorageStringItem, setWithExpiry } from '../../../../lib/common/utils/localstorage';
import { savedEmailAdminKey } from '../../../../lib/common/utils/localstorage/key';
import logo_blue from '../../../../statics/common/images/logo-blue.png';
import SaveIdCheckBox from './sections/saveidcheckbox';
import firebase, { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../../../lib/common/utils/firebase/init';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { fetchClient } from '../../../../lib/common/utils/axios';
import * as KeyPath from '../../../../statics/common/data/KeyPath';
import { usePostAdminFCMToken } from '../../../../lib/admin/hooks/query';
import { getToken } from 'firebase/messaging';
import { useSignInWithEmailAndPassword } from 'react-firebase-hooks/auth';

export const admin_login_initialValues = {
  email: '',
  password: '',
  isSaveEmail: false,
};

const AdminLoginPage = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const _logout = useLogout();
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [signInFn] = useSignInWithEmailAndPassword(auth);
  const inputprops = {
    onFocus: () => {
      setIsFocus(true);
    },
    onBlur: () => {
      setIsFocus(false);
    },
  } as InputProps;

  return (
    <Box
      w={'100vw'}
      h={'100vh'}
      //  bgColor={'zetaBlue'}
      bgGradient="linear(to-r, white, zetaBlue)"
    >
      <Center w="full" h="full">
        <Formik
          initialValues={admin_login_initialValues}
          validationSchema={Yup.object({
            email: Yup.string().email('유효하지 않는 이메일입니다.'),
            password: Yup.string()
              .matches(/\w*[A-z]\w*/, '비밀번호는 영문을 포함하여야 합니다.')
              .matches(/\d/, '비밀번호는 숫자를 포함하여야 합니다.')
              .matches(/[!@#$%^&*()\-_~"=+{}; :,<.>]/, '비밀번호는 특수문자를 포함하여야 합니다.')
              .min(8, ({ min }) => `비밀번호는 최소 ${min}글자입니다.`)
              .max(16, ({ max }) => `비밀번호는 최대 ${max}글자입니다.`),
          })}
          onSubmit={async (values) => {
            const { email, password } = values;
            if (values.isSaveEmail) {
              localStorage.setItem(savedEmailAdminKey, email);
            } else {
              localStorage.removeItem(savedEmailAdminKey);
            }
            try {
              const userCredential = await signInWithEmailAndPassword(auth, email, password);
              const firebaseUser = userCredential?.user;
              const token = await firebaseUser?.getIdToken();
              const instance = await fetchClient({ headers: { token } });
              let result = await instance.get(KeyPath.keyOfGetCompanyAndUserType);
              let user = result?.data?.data as any;
              if (user?.company_type == 'admin' && user?.user_type == 'master') {
                setLocalStorageStringItem('--bohome-company-type', 'admin');
                setLocalStorageStringItem('--bohome-user-type', 'master');
                setLocalStorageStringItem('--bohome-token', token);
                setLocalStorageStringItem('--bohome-email', email);
                setLocalStorageStringItem('--bohome-route-user-type', 'admin');
                setLocalStorageStringItem('--bohome-is-login', 'true');
                navigate('/admin/dashboard/companies');
                toast(loginSuccess);
                const web_fcm_token = await getWebFcmToken();
                web_fcm_token && (await instance.post<any>('/admin/master/web-fcm-token', { web_fcm_token }));
              } else {
                console.log('SERVER NOT MATCHED OR TYPE NOT MATCHED');
                toast(serverOrTypeNotMatched);
                _logout();
              }
            } catch (err) {
              toast(loginFail);
            }
          }}
        >
          {(formik) => {
            const isDisabled =
              !formik.values.email || !formik.values.password || !!formik.errors.email || !!formik.errors.password;

            return (
              <Form style={{ width: '400px' }}>
                {/* <motion.div whileHover={{ scale: 1.2 }}> */}
                <Stack direction={'column'}>
                  <motion.div
                    initial={{ opacity: 0, scale: 0.5 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{
                      default: {
                        duration: 0.3,
                        ease: [0, 0.71, 0.2, 1.01],
                      },
                      scale: {
                        type: 'spring',
                        damping: 5,
                        stiffness: 100,
                        restDelta: 0.001,
                      },
                    }}
                  >
                    <motion.div
                      animate={{
                        scale: isFocus ? 1.05 : 1,
                        y: isFocus ? -10 : 0,
                      }}
                      transition={{
                        type: 'linear',
                        delay: 0.04,
                      }}
                    >
                      <Flex align={'end'} justify={'center'} mb={4}>
                        <Image src={logo_blue} />
                        <Text
                          bgGradient="linear(to-l, gray.600 1%, zetaBlue 99%)"
                          // bgGradient="linear(to-l, #7928CA, #FF0080)"
                          bgClip="text"
                          fontWeight={500}
                          fontSize={'18px'}
                          // lineHeight={1}
                          // color={'zetaBlue'}
                        >
                          어드민
                        </Text>
                      </Flex>
                    </motion.div>
                  </motion.div>
                  <motion.div
                    animate={{
                      scale: isFocus ? 1.05 : 1,
                    }}
                    transition={{
                      type: 'linear',
                    }}
                  >
                    <Box
                      bgColor={'white'}
                      p={5}
                      borderRadius={'lg'}
                      boxShadow={isFocus ? '2xl' : 'lg'}
                      // transition={'0.18s linear boxShadow'}
                    >
                      <VStack flexDir={'column'} spacing={4}>
                        <Box w="full">
                          <Field as={Input} name="email" id="email" placeholder="이메일" {...inputprops} />
                          <Validation error={formik.errors.email} />
                        </Box>
                        <Box w="full">
                          <Field
                            as={Input}
                            type="password"
                            name="password"
                            id="password"
                            placeholder="패스워드"
                            {...inputprops}
                          />
                          <Validation error={formik.errors.password} />
                        </Box>
                        <Flex w="full" align={'center'}>
                          <SaveIdCheckBox />
                          <TextLink fontSize={'10px'} to={'/login/company'} minW={'max'}>
                            고객사 바로가기
                          </TextLink>
                        </Flex>
                        <Button
                          variant={'basic'}
                          w="full"
                          height={'40px'}
                          type="submit"
                          // isLoading={isSubmitting}
                          isDisabled={isDisabled}
                        >
                          로그인
                        </Button>
                      </VStack>
                    </Box>
                  </motion.div>
                </Stack>
                {/* </motion.div> */}
              </Form>
            );
          }}
        </Formik>
      </Center>
    </Box>
  );
};

export default AdminLoginPage;

import {
  Avatar,
  AvatarBadge,
  Box,
  BoxProps,
  Center,
  Flex,
  FlexProps,
  Grid,
  GridItem,
  HStack,
  Icon,
  Link,
  StackDivider,
  Switch,
  Table,
  Tag,
  Tbody,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Inquiry from '../../../../../components/common/Modals/Inquiry';
import { TrContainer } from '../../../../../components/common/Table/Tr';
import {
  BellRingIcon,
  CreditCardIcon,
  EditPencilIcon,
  HouseIcon,
  SubtractIcon,
  UserSquareIcon,
} from '../../../../../statics/common/icons';

const dummy_1 = ['구로구 구로동', '관악구 신림동', '강남구 역삼동'];

const dummy_2 = {
  allow_noti_requestRoom: false,
  allow_noti_roomStatusChange: false,
  allow_noti_completePaid: false,
  allow_noti_chat: false,
  allow_noti_event: false,
};

interface GridItemBoxProps extends BoxProps {
  logo: any;
  subtract?: boolean;
  to?: string;
  rowSpan?: number;
  colSpan?: number;
  section_1: JSX.Element | string;
  section_1_size?: string;
  spacing?: number;
}

const GridItemBox: React.FC<GridItemBoxProps> = ({
  children,
  logo,
  subtract,
  to,
  rowSpan,
  colSpan,
  section_1,
  section_1_size = '24px',
  spacing = 0,
  ...props
}) => (
  <GridItem
    rowSpan={rowSpan}
    colSpan={colSpan}
    bgColor="white"
    border={'1px solid'}
    borderColor={'silverGray'}
    borderRadius={'md'}
    // userSelect={'none'}
    boxShadow={'base'}
    {...props}
  >
    <motion.div
      style={{ width: '100%', height: '100%', borderRadius: '0.25rem' }}
      // whileHover={{ backgroundColor: '#775C18', color: 'rgb(255, 255, 255)' }}
    >
      <VStack flexDir={'column'} spacing={spacing} justifyContent={'center'} alignItems={'unset'}>
        <HStack px="32px" py="20px" justifyContent={'space-between'}>
          <HStack>
            <Box>{logo}</Box>
            <Box fontSize={section_1_size} fontWeight={'medium'}>
              {section_1}
            </Box>
          </HStack>
          {to && (
            <Link
              to={to}
              as={RouterLink}
              cursor={'pointer'}
              transition="transform ease 0.2s"
              _hover={{ transform: 'rotate(90deg)' }}
              _focus={{}}
            >
              {subtract && <Icon as={SubtractIcon} />}
            </Link>
          )}
        </HStack>
        <Box>{children}</Box>
      </VStack>
    </motion.div>
  </GridItem>
);

const AlarmFlex: React.FC<FlexProps> = ({ children, ...props }) => (
  <Flex
    {...props}
    alignItems={'center'}
    justifyContent={'space-between'}
    w={'100%'}
    bgColor="white"
    border={'1px solid'}
    borderColor={'silverGray'}
    borderRadius={'md'}
    px={3}
    py={2}
  >
    {children}
  </Flex>
);

const AgencyManageAccountPage = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [notiStatus, setNotiStatus] = useState(dummy_2);

  const onChangeStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNotiStatus((prev: any) => {
      const prevOne = prev[e.target.id];
      return {
        ...prev,
        [e.target.id]: !prevOne,
      };
    });
  };

  return (
    <Center>
      <Grid h={'750px'} w={'100%'} templateRows="repeat(18, 1fr)" templateColumns="repeat(2, 1fr)" gap={4}>
        {/* 개인 정보 */}
        <GridItemBox
          to={'/agency/manage/account/editInfo'}
          logo={<UserSquareIcon />}
          subtract
          rowSpan={5}
          colSpan={1}
          section_1="개인 정보"
        >
          <HStack alignItems={'start'} spacing={5} paddingX="32px">
            <Avatar bg={'silverGray'} size="md">
              <AvatarBadge boxSize="1em" bg="white" border={'1px'} borderColor={'silverGray'}>
                <Center position={'absolute'} left={'1px'}>
                  <Icon as={EditPencilIcon} w={4} h={4} />
                </Center>
              </AvatarBadge>
            </Avatar>
            <VStack alignItems={'flex-start'}>
              <HStack alignItems={'flex-end'}>
                <Box fontSize={'28px'} position={'relative'} top="5px">
                  김리법 님
                </Box>
                <hr style={{ borderRight: '1px solid', borderRightColor: '#e2e8f0', height: '18px' }} />
                <Box fontSize={'14px'}>리버블 공인중개사</Box>
              </HStack>
              <VStack alignItems={'flex-start'} spacing={0}>
                <HStack>
                  <Box w={'100px'} fontWeight={'medium'}>
                    연락처
                  </Box>
                  <HStack divider={<StackDivider />} fontSize={'14px'}>
                    <Box>010.4321.1234</Box>
                    <Box>02.2345.5432</Box>
                  </HStack>
                </HStack>
                <HStack>
                  <Box w={'100px'} fontWeight={'medium'}>
                    이메일 주소
                  </Box>
                  <Box fontSize={'14px'}>abc@gmail.com</Box>
                </HStack>
              </VStack>
            </VStack>
          </HStack>
        </GridItemBox>

        {/* 정산 계좌 정보 */}
        <GridItemBox
          to={'/agency/manage/account/editAccount'}
          logo={<CreditCardIcon />}
          subtract
          rowSpan={5}
          colSpan={1}
          section_1="정산 계좌 정보"
        >
          <Center
            h={'100px'}
            mx={'32px'}
            bgColor="white"
            border={'1px solid'}
            borderColor={'silverGray'}
            borderRadius={'md'}
          >
            <HStack>
              <Avatar name="Oshigaki Kisame" />
              <Box fontSize={'20px'}>신한 110-000-0000</Box>
            </HStack>
          </Center>
        </GridItemBox>

        {/* 중개사무소 정보 */}
        <GridItemBox
          to={'/agency/manage/account/editAgency'}
          logo={<HouseIcon />}
          position="relative"
          subtract
          rowSpan={13}
          colSpan={1}
          section_1="중개사무소 정보"
        >
          <Box
            _after={{
              content: '""',
              position: 'absolute',
              w: '100%',
              boxShadow: '0px 0px 2px 1px #E5E5E5',
            }}
          />
          <VStack w="100%" spacing={8} px="32px" py="20px" alignItems={'start'}>
            <VStack w="100%" spacing={2} alignItems={'unset'} flexDir={'column'} fontWeight={'bold'}>
              <Box>나의 영업 지역</Box>
              <Flex flexWrap={'wrap'}>
                {dummy_1.map((list) => (
                  <Tag key={list} size="lg" mr={1} mb={1}>
                    {list}
                  </Tag>
                ))}
              </Flex>
            </VStack>
            <VStack w="100%" alignItems={'unset'} spacing={2} flexDir={'column'}>
              <Box fontWeight={'bold'}>중개사무소 정보</Box>
              <Table colorScheme="teal">
                <Tbody fontSize={'14px'}>
                  <TrContainer title="중개사무소명">리버블 부동산</TrContainer>
                  <TrContainer title="중개사무소 주소">서울특별시 구로구 구로동</TrContainer>
                  <TrContainer title="사업자 등록번호">123456789</TrContainer>
                  <TrContainer title="대표자명">김리법</TrContainer>
                  <TrContainer title="대표 연락처">02-111-11111</TrContainer>
                </Tbody>
              </Table>
            </VStack>
          </VStack>
        </GridItemBox>

        {/* 알림 설정 */}
        <GridItemBox logo={<BellRingIcon />} position="relative" rowSpan={13} colSpan={1} section_1="알림 설정">
          <Box
            _after={{
              content: '""',
              position: 'absolute',
              w: '100%',
              boxShadow: '0px 0px 2px 1px #E5E5E5',
            }}
          />
          <VStack px="32px" py="20px">
            <AlarmFlex>
              <Box fontSize={'14px'}>매물 요청 알림</Box>
              <Switch variant={'subtle'} id="allow_noti_requestRoom" onChange={onChangeStatus} />
            </AlarmFlex>
            <AlarmFlex>
              <Box fontSize={'14px'}>매물 상태 변경 알림</Box>
              <Switch variant={'subtle'} id="allow_noti_roomStatusChange" onChange={onChangeStatus} />
            </AlarmFlex>
            <AlarmFlex>
              <Box fontSize={'14px'}>정산 완료 알림</Box>
              <Switch variant={'subtle'} id="allow_noti_completePaid" onChange={onChangeStatus} />
            </AlarmFlex>
            <AlarmFlex>
              <Box fontSize={'14px'}>채팅 알림</Box>
              <Switch variant={'subtle'} id="allow_noti_chat" onChange={onChangeStatus} />
            </AlarmFlex>
            <AlarmFlex>
              <Box fontSize={'14px'}>이벤트 및 공지 사항 수신 알림</Box>
              <Switch variant={'subtle'} id="allow_noti_event" onChange={onChangeStatus} />
            </AlarmFlex>
          </VStack>
        </GridItemBox>
      </Grid>
      {/* Modal */}
      <Inquiry isOpen={isOpen} onClose={onClose} />
    </Center>
  );
};

export default AgencyManageAccountPage;

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Center,
  Divider,
  Flex,
  FlexProps,
  Heading,
  HStack,
  Icon,
  Image,
  Text,
  Tooltip,
  useMediaQuery,
} from '@chakra-ui/react';
import { motion, Variants } from 'framer-motion';
import { isArray } from 'lodash';
import React, { useContext, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { IoIosLogOut } from 'react-icons/io';
import { RiMenuFoldFill, RiMenuUnfoldFill } from 'react-icons/ri';
import { Link, NavLink, useLocation, matchPath, matchRoutes, useNavigate } from 'react-router-dom';
import { useGetUserType } from '../../../lib/common/hooks/query';
import { useLogout } from '../../../lib/common/utils/firebase/init';
import Logo from '../../../statics/common/images/sidebar.png';
import { AdminSideBarLists, AgencySideBarLists, CompanySideBarLists, SideBarListsType } from './sidebarLists';

const matchRoutePath = (route_name: string, pathname: string) => {
  let activate: boolean | 'isActive' = false;
  if (matchPath('/admin/*', route_name)) {
    if (
      (matchPath('/admin/dashboard/*', route_name) && matchPath('/admin/dashboard/*', pathname)) ||
      (matchPath('/admin/manage/estates/rooms/*', route_name) && matchPath('/admin/manage/roomInfo/*', pathname)) ||
      (matchPath('/admin/manage/customers/*', route_name) && matchPath('/admin/manage/customers/*', pathname)) ||
      (matchPath('/admin/manage/companies/*', route_name) && matchPath('/admin/manage/companies/*', pathname)) ||
      (matchPath('/admin/manage/moverequests/*', route_name) && matchPath('/admin/manage/moverequests/*', pathname)) ||
      (matchPath('/admin/manage/moves/*', route_name) && matchPath('/admin/manage/moves/*', pathname)) ||
      (matchPath('/admin/manage/moves/*', route_name) && matchPath('/admin/manage/moves/*', pathname)) ||
      (matchPath('/admin/manage/estates/*', route_name) && matchPath('/admin/manage/estates/*', pathname)) ||
      (matchPath('/admin/manage/complaints/*', route_name) && matchPath('/admin/manage/complaints/*', pathname)) ||
      (matchPath('/admin/manage/chats/*', route_name) && matchPath('/admin/manage/chats/*', pathname)) ||
      (matchPath('/admin/manage/chats/*', route_name) && matchPath('/admin/chat/*', pathname)) ||
      (matchPath('/admin/manage/settlements/*', route_name) && matchPath('/admin/manage/settlements/*', pathname)) ||
      (matchPath('/admin/manage/notifications/*', route_name) &&
        matchPath('/admin/manage/notifications/*', pathname)) ||
      (matchPath('/admin/manage/paccounts/*', route_name) && matchPath('/admin/manage/paccounts/*', pathname)) ||
      (matchPath('/admin/manage/tours/*', route_name) && matchPath('/admin/manage/tours/*', pathname)) ||
      (matchPath('/admin/manage/announcements/*', route_name) &&
        matchPath('/admin/manage/announcements/*', pathname)) ||
      (matchPath('/admin/manage/supportpayment/*', route_name) &&
        matchPath('/admin/manage/supportpayment/*', pathname)) ||
      (matchPath('/admin/manage/setting/functions/*', route_name) &&
        matchPath('/admin/manage/setting/functions/*', pathname)) ||
      (matchPath('/admin/manage/wp/*', route_name) && matchPath('/admin/manage/wp/*', pathname))
    ) {
      activate = true;
    } else {
      activate = false;
    }
  }
  return activate;
};

const isTenantTab = (pathname: string) => {
  let activate = false;
  if (matchPath('/admin/*', pathname)) {
    if (
      matchPath('/admin/manage/moverequests/*', pathname) ||
      matchPath('/admin/manage/tours/*', pathname) ||
      matchPath('/admin/manage/moves/*', pathname) ||
      matchPath('/admin/manage/complaints/*', pathname)
    ) {
      activate = true;
    } else {
      activate = false;
    }
  }
  return activate;
};

const AdminMenuItemList = ({ lists }: { lists: SideBarListsType }) => {
  const { pathname } = useLocation();
  const isCurrentTenantTab = isTenantTab(pathname);
  const navigate = useNavigate();
  const hasLocalTenantTab = localStorage.getItem('sidebar-accordion-임직원 관리');
  return (
    <>
      {lists.map((list, index) => {
        // 0 - 한 상자에 두 개의 탭
        if (isArray(list)) {
          return (
            <Flex key={index}>
              {list.map((value, index) => {
                const activate = matchRoutePath(value.route_name || '/', pathname);
                return (
                  <Center
                    key={value.title + index}
                    p={'12px'}
                    color={activate ? 'white' : 'white'}
                    pl={activate ? '16px' : '18px'}
                    borderBottom={'1px solid #929fc0'}
                    fontWeight={activate ? 500 : 400}
                    _hover={{
                      fontWeight: 500,
                      color: 'rgb(255, 255, 255)',
                    }}
                    flex={1}
                    _odd={{
                      borderLeft: activate ? '2px solid white' : 'unset',
                    }}
                    _even={{
                      // pl: activate ? '11px' : '12px',
                      borderLeft: activate ? '2px solid white' : '1px solid #929fc0',
                    }}
                    onClick={() => navigate(value.route_name)}
                    cursor={'pointer'}
                  >
                    {value.title}
                  </Center>
                );
              })}
            </Flex>
          );
        }
        const activate = matchRoutePath(list.route_name || '/', pathname);
        return list.route_name ? (
          // 1 - 일반 탭
          <NavLink to={list.route_name || '/'} key={list.title + index}>
            {({ isActive }) => {
              return (
                <Box
                  p={'12px'}
                  color={activate ? 'white' : 'white'}
                  borderLeft={activate ? '2px solid white' : 'unset'}
                  pl={activate ? '16px' : '18px'}
                  borderBottom={'1px solid #929fc0'}
                  fontWeight={activate ? 500 : 400}
                  _hover={{
                    fontWeight: 500,
                    color: 'rgb(255, 255, 255)',
                  }}
                >
                  <Flex>{list.title}</Flex>
                </Box>
              );
            }}
          </NavLink>
        ) : (
          // 2 - 아코디언 탭
          <Accordion
            key={list.title + index}
            allowToggle={hasLocalTenantTab ? true : isCurrentTenantTab ? true : false}
            defaultIndex={hasLocalTenantTab ? [0] : isCurrentTenantTab ? [0] : []}
            reduceMotion
            onChange={(e) => {
              if (e === -1) {
                localStorage.removeItem('sidebar-accordion-임직원 관리');
              } else {
                localStorage.setItem('sidebar-accordion-임직원 관리', 'true');
              }
            }}
            // 열면 set 닫으면 remove/item?
          >
            <AccordionItem borderBottom={'1px solid #929fc0'}>
              <AccordionButton
                p={'12px'}
                pl={'18px'}
                color={isCurrentTenantTab ? 'white' : 'white'}
                // fontWeight={hasLocalTenantTab ? 'bold' : 'normal'}
                _expanded={{ color: 'white', fontWeight: isCurrentTenantTab ? 'bold' : 'normal' }}
                transition={'0.15s linear'}
              >
                <Box flex="1" textAlign="left">
                  <Text fontSize={'14px'}>{list.title}</Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
              {list.items?.map((item, index) => {
                const narrow_activate = matchRoutePath(item.route_name || '/', pathname);
                // console.log(item.title, narrow_activate, item.route_name);
                const isLast = list.items?.length - 1 === index;
                return (
                  <AccordionPanel p={0} key={index}>
                    <NavLink to={item.route_name || '/'} key={item.title}>
                      {/* 위 분기점. 수정 */}
                      {({ isActive }) => {
                        // const apply = narrow_activate === 'isActive' ? isActive : narrow_activate;
                        // const apply = narrow_activate;

                        return (
                          <Box
                            p={'12px'}
                            color={narrow_activate ? 'white' : 'white'}
                            borderLeft={narrow_activate ? '2px solid white' : 'unset'}
                            pl={narrow_activate ? '24px' : '26px'}
                            // borderBottom={'1px solid #929fc0'}
                            fontWeight={narrow_activate ? 500 : 400}
                            _hover={{
                              fontWeight: 500,
                              color: 'rgb(255, 255, 255)',
                            }}
                            backgroundColor={'#1e3170'}
                            borderBottom={isLast ? 'unset' : '1px solid'}
                            borderBottomColor={isLast ? 'unset' : 'gray.600'}
                          >
                            <Flex>{item.title}</Flex>
                          </Box>
                        );
                      }}
                    </NavLink>
                  </AccordionPanel>
                );
              })}
            </AccordionItem>
          </Accordion>
        );
      })}
    </>
  );
};

const MenuItemList = ({ lists }: { lists: SideBarListsType }) => {
  return (
    <>
      {lists.map((list, index) => {
        if (isArray(list)) {
          return <div key={index}></div>;
        }
        return (
          <NavLink to={list.route_name || '/'} key={list.title}>
            {({ isActive }) => {
              return (
                <Box
                  p={'18px'}
                  color={isActive ? 'white' : 'coolBlue'}
                  borderLeft={isActive ? '2px solid white' : 'unset'}
                  pl={isActive ? '16px' : '18px'}
                  borderBottom={'1px solid #929fc0'}
                  fontWeight={isActive ? 500 : 400}
                  _hover={{
                    fontWeight: 500,
                    color: 'rgb(255, 255, 255)',
                  }}
                >
                  <Flex>{list.title}</Flex>
                </Box>
              );
            }}
          </NavLink>
        );
      })}
    </>
  );
};

const sidebar_width = '210px';

const MotionFlex = motion<FlexProps>(Flex);

const sidebarVariants: Variants = {
  open: { opacity: 1, x: 0, transition: { type: 'tween', duration: 0.12 } },
  closed: { opacity: 0, x: '-100%', transition: { type: 'tween', duration: 0.12 } },
};

const toggleBtnVariants: Variants = {
  showingWith: { opacity: 1, x: 0, transition: { ease: [0.38, 0.01, 0.09, 0.98], duration: 0.4 } },
  showingAlone: { opacity: 0.5, x: '-210px', transition: { ease: [0.38, 0.01, 0.09, 0.98], duration: 0.4 } },
  hidden: { opacity: 0, x: '-100%', transition: { ease: [0.38, 0.01, 0.09, 0.98], duration: 0.4 } },
};

const Sidebar: React.FC = () => {
  const [toggleBtnStage, setToggleBtnStage] = useState<'showingWith' | 'showingAlone' | 'hidden'>('showingAlone');

  const handleSidebarMouseEnter = () => {
    setToggleBtnStage('showingWith');
  };

  const handleSidebarMouseLeave = () => {
    // setToggleBtnStage('hidden');
    // if (localSidebarHidden) {
    //   setToggleBtnStage('showingAlone');
    // } else {
    //   setToggleBtnStage('hidden');
    // }
  };
  const _logout = useLogout();

  // console.log('toggleBtnStage =>', toggleBtnStage);

  const { data, isLoading } = useGetUserType();

  if (data === undefined || isLoading) {
    console.log('loading...');
    return null;
  }

  const { company_type: userType } = data;

  return (
    <Box onMouseEnter={handleSidebarMouseEnter} onMouseLeave={handleSidebarMouseLeave} id="sidebar">
      <motion.div
        onClick={() => {
          // toggleSidebarHidden();
          // if (localSidebarHidden) {
          //   setToggleBtnStage('showingWith');
          // } else {
          //   setToggleBtnStage('showingAlone');
          // }
        }}
        style={{
          position: 'fixed',
          cursor: 'pointer',
          zIndex: 10,
          top: '31px',
          left: sidebar_width,
          height: '31px',
          backgroundColor: '#BAC2D6',
          borderStartEndRadius: '5px',
        }}
        initial={false}
        animate={
          toggleBtnStage === 'showingWith'
            ? 'showingWith'
            : toggleBtnStage === 'showingAlone'
            ? 'showingAlone'
            : 'hidden'
        }
        variants={toggleBtnVariants}
      >
        <Icon as={RiMenuFoldFill} w={6} h={6} color={'white'} />
      </motion.div>
      {/* Sidebar */}
      <MotionFlex
        as="nav"
        position="fixed"
        // bgColor={'zetaBlue'}
        background={'linear-gradient(180deg, rgba(24,51,119,1) 0%, rgba(35,75,175,1) 80%)'}
        color={'white'}
        w={sidebar_width}
        h="100vh"
        flexDir={'column'}
        justifyContent={'space-between'}
        zIndex={1500}
        boxShadow={'2px 0 5px rgb(0 0 0 / 20%)'}
        initial={false}
        animate={'open'}
        variants={sidebarVariants}
        overflowY={'scroll'}
        className="custom-scrollbar-v-container"
      >
        <Box fontSize={'14px'} fontWeight={400}>
          {/* Header */}
          <Link
            to={
              {
                admin: '/admin/dashboard/companies',
                subscriber: '/company/manage/dashboard',
                agency: '/agency/manage/estates/rooms',
              }[userType] || '/'
            }
          >
            <HStack pl={'28px'} pt={'36px'} pb={'56px'} alignItems={'flex-end'} flexWrap={'wrap'}>
              <Image alt={'Logo'} maxW={'75px'} objectFit={'contain'} src={Logo} />
              <Heading
                fontWeight={'bold'}
                fontSize={'14px'}
                textTransform={'uppercase'}
                borderLeft={'1px solid white'}
                lineHeight={1}
                px={'5px'}
              >
                {{ admin: '어드민', subscriber: '고객사', agency: '중개사' }[userType]}
              </Heading>
            </HStack>
          </Link>
          {/* Items */}
          <Divider borderColor={'#929fc0'} borderBottomWidth={'1px'} opacity={1} />
          {
            {
              admin: <AdminMenuItemList lists={AdminSideBarLists} />,
              subscriber: <MenuItemList lists={CompanySideBarLists} />,
              agency: <MenuItemList lists={AgencySideBarLists} />,
            }[userType]
          }
        </Box>
        {/* </Scrollbars> */}
        {/* 최하단 로그아웃 */}
        <Tooltip label={'클릭시 즉시 로그아웃 됩니다.'} placement="right" hasArrow>
          <Box>
            <HStack mx={'20px'} my={'16px'} cursor="pointer" w={'intrinsic'} spacing={3}>
              <Text fontSize={'14px'} onClick={_logout}>
                로그아웃
              </Text>
              <Icon as={IoIosLogOut} width={4} h={4} />
            </HStack>
          </Box>
        </Tooltip>
      </MotionFlex>
    </Box>
  );
};

export default Sidebar;

import { Box, Flex, Collapse, Text, useDisclosure } from '@chakra-ui/react';
import UsageContentTable from '../../Table/usageContentTable';
import { HistoryType } from '../../../../lib/common/utils/axios/types';
import { RightArrowIcon } from '../../../../statics/common/icons';

interface OneIsApprovedProps {
  usageData: HistoryType[] | undefined;
  userData: any;
}

export const OneIsApproved: React.FC<OneIsApprovedProps> = ({ usageData, userData }) => {
  const { isOpen, onToggle } = useDisclosure();
  console.log('OneIsApproved => ', usageData);

  return (
    <>
      <Box w={'full'} px={7} py={6}>
        <Flex onClick={onToggle} alignItems={'center'} justifyContent={'space-between'} cursor={'pointer'}>
          <Text fontWeight={'medium'} fontSize={'14px'}>
            {userData.user_name}님의 신청 내역
          </Text>
          <RightArrowIcon
            w={6}
            h={6}
            transform={`rotate(${isOpen ? 270 : 90}deg)`}
            transition={'transform 0.15s linear'}
          />
        </Flex>
        <Box pb={2} borderBottomWidth={'1px'} borderBottomColor={'gray'} />
        <Collapse in={!isOpen}>
          <UsageContentTable data={usageData} />
        </Collapse>
      </Box>
    </>
  );
};

import { Box, Input, Center, Flex, useRadio, HStack } from '@chakra-ui/react';
import { FieldInputProps, Field, useFormikContext } from 'formik';
import { boolstrToBoolean } from '../../../lib/common/utils/base';
import { useLocalFormik } from '../../../lib/common/hooks/useLocalFormik';
import { RadioLabelProps } from '../../../statics/common/data/radio';

interface RadioButtonProps {
  field: FieldInputProps<string>;
  id: string;
  label: string;
  initValue: string;
  localName: string;
}
export const RadioButton: React.FC<RadioButtonProps> = ({
  field: { name, value, checked, onChange, onBlur },
  id,
  label,
  initValue,
  localName,
  ...props
}) => {
  const isChecked = initValue === value;
  const [infos, onLocalChange, onSet] = useLocalFormik(localName);

  return (
    <Box>
      <Input
        display="none"
        name={name}
        id={id}
        type="radio"
        value={initValue} // could be something else for output?
        checked={isChecked}
        onChange={(e) => {
          onChange(e);
          console.log(e.target);
          console.log(e.target.name);
          onLocalChange(e);
        }}
        onBlur={onBlur}
        {...props}
      />
      <Box as="label" htmlFor={id}>
        <Center
          fontSize={'14px'}
          h={'34px'}
          w={'100px'}
          cursor="pointer"
          borderWidth="1px"
          bgColor={isChecked ? 'zetaBlue' : 'lotionWhite'}
          borderColor={isChecked ? 'zetaBlue' : 'silverGray'}
          color={isChecked ? 'white' : 'highGray'}
          px={5}
          py={3}
          _hover={{
            bgColor: 'zetaBlue',
            color: 'white',
          }}
          transition={'0.2s linear'}
        >
          {label}
        </Center>
      </Box>
    </Box>
  );
};

interface RadioButtonGroupProps {
  name: string;
  localName: string;
  options: RadioLabelProps;
}

export const RadioButtonGroup: React.FC<RadioButtonGroupProps> = ({ name, localName, options }) => {
  // const [infos, onChange, onSet] = useLocalFormik(localName);

  return (
    <Flex role="group" aria-labelledby={`${name}-radio-group`}>
      {options.map((option) => (
        <Field
          key={option.label + name}
          component={RadioButton}
          name={name}
          id={option.value + name}
          label={option.label}
          initValue={option.value}
          localName={localName}
        />
      ))}
    </Flex>
  );
};

interface FRadioButtonProps {
  field: FieldInputProps<string>;
  id: string;
  label: string;
  initValue: string;
  isFirst: boolean;
  isLast: boolean;
}

export const FRadioButton: React.FC<FRadioButtonProps> = ({
  field: { name, value, checked, onChange, onBlur },
  id,
  label,
  initValue,
  isFirst,
  isLast,
  ...props
}) => {
  const { values } = useFormikContext();
  const initVal = boolstrToBoolean(initValue);
  // const isChecked = initVal === value || initVal === (values as any)[name];

  const isChecked = (() => {
    if (initVal === value || initValue === value) {
      return true;
    } else if (initVal === (values as any)[name]) {
      return true;
    } else {
      return false;
    }
  })();

  return (
    <Box>
      <Input
        display="none"
        name={name}
        id={id}
        type="radio"
        value={initValue} // could be something else for output?
        checked={isChecked}
        onChange={(e) => {
          onChange(e);
        }}
        onBlur={onBlur}
        {...props}
      />
      <Box as="label" htmlFor={id}>
        <Center
          fontSize={'14px'}
          h={'32px'}
          w={'75px'}
          cursor="pointer"
          borderWidth="1px"
          borderRightWidth={isLast ? '1px' : '0px'}
          bgColor={isChecked ? 'zetaBlue' : 'lotionWhite'}
          borderColor={isChecked ? 'zetaBlue' : 'silverGray'}
          color={isChecked ? 'white' : 'highGray'}
          borderLeftRadius={isFirst ? 'md' : 'unset'}
          borderRightRadius={isLast ? 'md' : 'unset'}
          py={3}
          _hover={{
            bgColor: 'zetaBlue',
            color: 'white',
          }}
          transition={'0.2s linear'}
        >
          {label}
        </Center>
      </Box>
    </Box>
  );
};

interface FRadioButtonGroupProps {
  name: string;
  options: RadioLabelProps;
}

export const FRadioButtonGroup: React.FC<FRadioButtonGroupProps> = ({ name, options }) => {
  // const [infos, onChange, onSet] = useLocalFormik(localName);
  return (
    <Flex role="group" aria-labelledby={`${name}-radio-group`}>
      {options.map((option, index) => {
        const isFirst = index === 0;
        const isLast = options.length === index + 1;
        return (
          <Field
            key={option.label + name}
            component={FRadioButton}
            isFirst={isFirst}
            isLast={isLast}
            name={name}
            id={option.value + name}
            label={option.label}
            initValue={option.value}
          />
        );
      })}
    </Flex>
  );
};

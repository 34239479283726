import { Button, ButtonProps, forwardRef } from '@chakra-ui/react';
import { BelowArrowIcon } from '../../../../statics/common/icons';

const RightArrowButton: React.FC<ButtonProps> = forwardRef(({ children, ...props }, ref) => {
  return (
    <Button
      border={'1px solid #C4C4C4'}
      bgColor={'white'}
      fontSize={'14px'}
      fontWeight={400}
      h={'34px'}
      textAlign={'start'}
      rightIcon={<BelowArrowIcon />}
      _focus={{}}
      ref={ref}
      {...props}
    >
      {children}
    </Button>
  );
});

export default RightArrowButton;

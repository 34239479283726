import { Box, Button, Flex, HStack, Skeleton, Text, useDisclosure, VStack } from '@chakra-ui/react';
import { useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import QueryParamsLayout from '../main/QueryParamsLayout';
import { CustomSearch, StyledSearch } from '../../../components/common/Search';
import { useGetBuildings, useGetBuildingAllRooms } from '../../../lib/common/hooks/query';
import { currentParamsObject, ToAllFilledObj } from '../../../lib/common/utils/base';
import { buildingFormTypeTrans } from '../../../lib/subscriber/utils/utils';
import AddNewRoomBtn from '../../../components/common/Button/AddNewRoomButton';
import SelectBox from '../../../components/common/SelectBox/EstatesSelectBox';
import BuildingStatus from '../BuildingStatus/BuildingStatus';
import RoomInfoContainer from '../RoomInfoContainer';
import * as Util from '../../../lib/common/utils/Util';
import Loading from '../../../components/common/Loading';

const BuildingManageSection = () => {
  //todo 조회 수정 axiosConfig를 QueryKey로 두기
  const [pageNum, setPageNum] = useState(1);
  const [builidngSearch, setBuildingSearch] = useState<string>('');
  // const [buildingId, setBuildingId] = useState<string>('');
  const [searchParams, setSearchParams] = useSearchParams();
  const queryBuildingId = searchParams.get('buildingId') || '';
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  const { search: srch, pathname } = location;
  const routeUserType = Util.getRouteUserTypeFromUrl(location?.pathname);
  let { page, limit } = currentParamsObject();

  const _params = currentParamsObject();
  const params = ToAllFilledObj(_params);

  const {
    data: buildingLists,
    isFetching,
    isLoading,
  } = useGetBuildings({
    axiosConfig: {
      params,
    },
  });

  const res = useGetBuildingAllRooms(queryBuildingId, {
    options: {
      enabled: !!queryBuildingId,
    },
    axiosConfig: {
      params,
    },
  }) as any;

  const building_all_rooms_data = res.data?.result;
  if (isLoading) return <Loading />;
  return (
    <Flex flexDir={'column'} w={'100%'}>
      {/* 공지 사항, 나의 건물 현황 상단 */}
      {/* <HStack w={'100%'} mb={3} alignItems={'unset'}>
        <BuildingStatus w={{ base: '100%', md: '100%' }} />
      </HStack> */}
      <HStack w={'100%'} h={'calc(100vh - 300px)'} alignItems={'unset'}>
        {/* 왼쪽 빌딩 섹션 */}
        <VStack w={{ base: '100%', md: '27%' }} h={'full'} alignItems={'unset'} justifyContent={'unset'}>
          {/* <StyledSearch placeholder="건물명/주소" w={'100%'} h={'54px'} mb={'1px'} /> */}
          <CustomSearch placeholder="조회"></CustomSearch>
          {/* 건물 리스트 스크롤 필요 */}
          <Skeleton isLoaded={!isLoading} w="full" h="full">
            <Scrollbars universal style={{ height: '100%' }} autoHide autoHideTimeout={2000}>
              <VStack id="building-items-list" w={'100%'} alignItems={'unset'} justifyContent={'unset'} h={'full'}>
                {buildingLists?.map((building, index) => {
                  const building_id = building._id;
                  const isActivating = building_id === queryBuildingId;
                  return (
                    <Flex
                      key={index}
                      id="building-item"
                      position={'relative'}
                      flexDir={'column'}
                      bg={isActivating ? 'rgba(236, 241, 255, 1)' : 'lotionWhite'}
                      border={'1px solid'}
                      borderColor={'silverGray'}
                      borderRadius={'lg'}
                      boxShadow={'sm'}
                      px={4}
                      py={2}
                      layerStyle={'hover_opacity_animate'}
                      _hover={
                        !isActivating
                          ? {
                              bgColor: 'rgba(236, 241, 255, 0.5)',
                            }
                          : {}
                      }
                      transition={'linear 0.18s'}
                      cursor="pointer"
                      onClick={() => setSearchParams({ buildingId: building_id })}
                    >
                      <Flex
                        fontSize={'14px'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        position={'relative'}
                        my={1}
                      >
                        <Box fontWeight={'bold'}>{building.name}</Box>
                        <HStack flexGrow={1} alignItems={'center'} justify={'end'} fontSize={'10px'}>
                          <Box minW={'max'}>입주 중</Box>
                          <Box fontWeight={'bold'}>../{building.household_number}</Box>
                        </HStack>
                      </Flex>
                      <Box fontSize={'11px'}>{building.address}</Box>
                      <Box fontSize={'11px'}>
                        <Text>{buildingFormTypeTrans(building.building_form)}</Text>
                      </Box>
                      <Box pos="absolute" right={1} bottom={1}>
                        <Button
                          variant={'approve_bordering'}
                          fontSize={'10px'}
                          px={1}
                          height={'20px'}
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate(`/${routeUserType}/manage/estates/buildings/detail/${building_id}`);
                          }}
                        >
                          상세 보기
                        </Button>
                      </Box>
                    </Flex>
                  );
                })}
              </VStack>
            </Scrollbars>
          </Skeleton>
        </VStack>
        {/* 오른쪽 메인 섹션 */}
        <VStack w={{ base: '100%', md: '73%' }} alignItems={'unset'} justifyContent={'unset'}>
          <Flex h="36px"></Flex>
          <Flex h="calc(100vh - 380px)">
            <QueryParamsLayout
              res={res}
              removeTopBar
              modalOnLayout={
                <Box pos={'absolute'} right={0} bottom={0}>
                  <AddNewRoomBtn isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
                </Box>
              }
            >
              <RoomInfoContainer
                minifyMemo
                // pageNum={pageNum}
                // setPageNum={setPageNum}
                buildingsroomsData={building_all_rooms_data}
              />
            </QueryParamsLayout>
            {/* <RoomsDataTable roomsData={building_all_rooms_data?.map((building) => building.room)} /> */}
          </Flex>
        </VStack>
      </HStack>
    </Flex>
  );
};

export default BuildingManageSection;

import { Box, Button, Flex, HStack, Text, VStack } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { TableTitleContainer } from '../type';
import { building_detail_key } from '../../../../../../statics/agent/data/KeyPath';
import { BaseResponse, BuildingDetail } from '../../../../../../lib/common/utils/axios/types';
import RetrieveRoomInfoByRoomTypeModal from '../../../RetrieveRoomInfoByRoomTypeModal';
import { initialValue, StepProps } from '../..';
import Upper from '../Upper';
import PriceInfoTable from './a-priceinfo';
import RoomInfoTable from './b-roominfo';
import OptionTable from './c-option';
import RoomTypeSelect from './a-roomtypeselect';
import InteriorInfoTable from './d-interiorinfo';
import AccessRangeTable from './e-accessrange';

const Room: React.FC<StepProps> = ({ step, setStep }) => {
  const formik = useFormikContext<typeof initialValue>();
  const { values, errors, touched, setFieldValue, handleChange, resetForm } = formik;
  const [is_defaults_checked, setIs_defaults_checked] = useState<boolean>(false);
  const componentStartRef = useRef<HTMLDivElement>(null);
  const {
    building_id,
    deposit,
    rent,
    management_fee,
    common_items,
    individual_items,
    move_in,
    minimum_contract_month,
    dong,
    ho,
    floor_r,
    floor_type,
    room_living_type,
    room_num,
    toilet_num,
    supply_area,
    dedicated_area,
    room_direction,
    heating_type,
    default_options,
    additional_options,
    is_security_window: is_security_windwo,
    is_parking_r,
    is_pet,
    remodeling_season,
    remodeling_items,
    is_bohome,
  } = values;

  useEffect(() => {
    componentStartRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, []);

  useEffect(() => {
    if (move_in.date) {
      setFieldValue('move_in.date', '');
    }
  }, [move_in.status]);

  useEffect(() => {
    if (default_options.length !== 6) {
      setIs_defaults_checked(false);
    } else {
      setIs_defaults_checked(true);
    }
  }, [default_options]);

  const { data } = useQuery<BaseResponse<BuildingDetail>>(building_detail_key, {
    onSuccess: (building) => {
      console.log('building =>', building);

      setFieldValue('building_id', building.data._id);
    },
  });

  function myValidation() {
    if (
      values.deposit &&
      values.rent &&
      values.management_fee &&
      values.move_in.status &&
      values.room_living_type &&
      values.floor_r &&
      values.floor_type &&
      values.room_num &&
      !errors.room_num &&
      values.toilet_num &&
      values.dedicated_area &&
      values.is_security_window &&
      values.is_parking_r &&
      values.is_pet
      // &&
      // values.remodeling_season &&
      // values.remodeling_items
    ) {
      return false;
    } else {
      return true;
    }
  }

  const booleanResult = myValidation();
  const isColiving = values.building_form === 'coliving_house';
  const isCheckColivingAndSelectedType = isColiving ? !!values.room_type : !isColiving;

  return (
    <Box w="full" h="full" ref={componentStartRef}>
      {/* 1 - progressive bar */}
      <Upper step={step} />
      {/* 2 - 정보 등록 */}
      <VStack mx={'150px'} my={'50px'} spacing={7}>
        {/* z. 룸 타입 선택 */}
        {isColiving && <RoomTypeSelect />}
        {isCheckColivingAndSelectedType && (
          <>
            {/* a. 거래 정보 */}
            <TableTitleContainer title="거래 정보" table={<PriceInfoTable />}>
              {isColiving ? (
                <RetrieveRoomInfoByRoomTypeModal building_id={values.building_id} room_type={values.room_type}>
                  <Button variant={'basic'}>룸타입 정보 불러오기</Button>
                </RetrieveRoomInfoByRoomTypeModal>
              ) : null}
            </TableTitleContainer>
            {/* b. 방 정보 */}
            <TableTitleContainer title="방 정보" table={<RoomInfoTable />} />
            {/* c. 방 옵션 */}
            <TableTitleContainer title="방 옵션" table={<OptionTable />} />
            {/* c. 인테리어 정보 */}
            <TableTitleContainer title="인테리어 정보" table={<InteriorInfoTable />} />
            {/* d. 공개여부 */}
            <TableTitleContainer title="공개여부" table={<AccessRangeTable />} />
          </>
        )}
        {/* 다음 */}
        <Flex justifyContent={'flex-end'} w={'100%'}>
          <Button
            isDisabled={booleanResult}
            variant={'basic'}
            w={'150px'}
            mt={7}
            onClick={() => {
              setStep(2);
            }}
          >
            다음
          </Button>
        </Flex>
      </VStack>
    </Box>
  );
};

export default Room;

import {
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  StackDivider,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  VStack,
  Wrap,
} from '@chakra-ui/react';
import { isEmptyArray, isUndefined } from '@chakra-ui/utils';
import { useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { StyledSearch } from '../../Search';
import { AccessHiddenTag } from '../../Tag';
import { usePreviousGetAllBuildingsRooms } from '../../../../lib/admin/hooks/query';
import { BuildingRoom } from '../../../../lib/common/utils/axios/types';
import { datedotformat } from '../../../../lib/common/utils/dateformatter';
import { buildingFormTypeTrans } from '../../../../lib/subscriber/utils/utils';
import { ClickIconContainer, LeftArrowIcon, RightArrowIcon } from '../../../../statics/common/icons';
import ConfirmRecommendRoomsModal from '../ConfirmRecommendRoomsModal';
import * as Type from '../../../../types/common/CommonType';
import { useGetAllBuildingsRooms } from '../../../../lib/common/hooks/query';
import Loading from '../../Loading';

interface RecommendRoomsModalProps {
  room_request_id: string;
  user_name?: string;
  company_name?: string;
  request_code?: string;
  request_createdAt: string;
}

const RecommendRoomsModal: React.FC<RecommendRoomsModalProps> = ({
  room_request_id,
  user_name,
  company_name,
  request_code,
  request_createdAt,
  children,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [typedSearch, setTypedSearch] = useState<string>('');
  const [checkedItems, setCheckedItems] = useState<Type.Building[]>([]);
  const [pageNum, setPageNum] = useState<number>(1);

  const checkedItemIds = checkedItems.map((building) => building?.room?.code);

  const { data, isLoading } = useGetAllBuildingsRooms({
    axiosConfig: {
      params: {
        page: pageNum,
        search: typedSearch,
      },
    },
  });

  if (isLoading) return <></>;
  const buildingsRooms = data?.data;

  const buildingroomData = buildingsRooms;
  const currentAllRoomIds = buildingroomData?.map((building: any) => building.room.code);

  const allChecked = currentAllRoomIds?.every((item: any) => checkedItemIds.includes(item));
  const isIndeterminate = currentAllRoomIds?.some((item: any) => checkedItemIds.includes(item)) && !allChecked;
  const saveIsDisabled = !currentAllRoomIds?.some((item: any) => checkedItemIds.includes(item));

  return (
    <>
      <Box onClick={onOpen}>{children}</Box>
      <Modal isOpen={isOpen} onClose={onClose} size={'7xl'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Flex pr={6} align={'end'}>
              <Box flexGrow={1}>
                <Text>
                  {user_name} ({company_name}) 목록에 매물 추가하기
                </Text>
              </Box>
              <Box fontSize={'16px'}>
                <HStack>
                  <HStack>
                    <Text>요청 번호</Text>
                    <Text fontWeight={'normal'} lineHeight={1.2}>
                      {request_code}
                    </Text>
                  </HStack>
                  <HStack>
                    <Text>제출 일자</Text>
                    <Text fontWeight={'normal'} lineHeight={1.2}>
                      {datedotformat(request_createdAt)}
                    </Text>
                  </HStack>
                </HStack>
              </Box>
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <StyledSearch
              w={'full'}
              placeholder="회사/유저 이름과 주소로 검색이 가능합니다."
              inputProps={{
                onChange: (e) => {
                  setTypedSearch(e.target.value);
                },
              }}
            />
            <Flex flexDir={'column'} w={'100%'}>
              <Box h={'60vh'}>
                <Scrollbars universal>
                  <Table variant="all_line_silver">
                    <Thead>
                      <Tr>
                        <Th w={'20px'}>
                          <Center>
                            <Checkbox
                              isChecked={allChecked}
                              isIndeterminate={isIndeterminate}
                              onChange={(e) => {
                                if (isIndeterminate || allChecked) {
                                  console.log('모든 선택 취소');

                                  setCheckedItems((prevArr) => {
                                    if (!isUndefined(currentAllRoomIds)) {
                                      return prevArr.filter((item) => !currentAllRoomIds.includes(item?.room?.code));
                                    } else {
                                      return [];
                                    }
                                  });
                                } else {
                                  console.log('모든 선택');

                                  setCheckedItems((prevArr) => {
                                    if (!isUndefined(currentAllRoomIds) && !isUndefined(buildingroomData)) {
                                      // const ableRooms = buildingroomData.filter((building) => building.room.is_move_in);
                                      const notyetIncludeItems = buildingroomData.filter(
                                        (building) =>
                                          !checkedItemIds.includes(building?.room?.code || '') &&
                                          building?.room?.is_move_in,
                                      );
                                      return prevArr.concat(notyetIncludeItems);
                                    }
                                    return [];
                                  });
                                }
                              }}
                            />
                          </Center>
                        </Th>
                        <Th colSpan={1}>
                          <HStack divider={<StackDivider borderColor="rgb(235, 240, 246)" />}>
                            <ConfirmRecommendRoomsModal
                              room_request_id={room_request_id}
                              disabled={saveIsDisabled}
                              buildingsRooms={checkedItems}
                            >
                              <Button
                                variant={'basic'}
                                style={{
                                  height: '27px',
                                }}
                                fontSize={'14px'}
                                h={'27px'}
                                p={2}
                                borderRadius={'0.2rem'}
                                isDisabled={saveIsDisabled}
                              >
                                추가하기
                              </Button>
                            </ConfirmRecommendRoomsModal>
                          </HStack>
                        </Th>
                        <Th></Th>
                        <Th>메모</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {buildingroomData?.map((building, index) => {
                        const { address, name: building_name, building_form } = building;

                        const room = building?.room;
                        const code = room?.code;
                        const pictures = room?.pictures;
                        const is_move_in = room?.is_move_in;
                        const is_template = room?.is_template;
                        const access_status = room?.access_status;
                        const dong = room?.dong;
                        const ho = room?.ho;
                        const room_num = room?.room_num;
                        const rent = room?.rent;
                        const deposit = room?.deposit;
                        const dedicated_area = room?.dedicated_area;
                        const floor = room?.floor;
                        const management_fee = room?.management_fee;
                        const room_type = room?.room_type;
                        const agent = room?.agent;
                        const agent_name = agent?.user_name;
                        const company_name = agent?.company_name;

                        const isChecked = checkedItems.some((item: any) => item?.room?.code === building?.room?.code);

                        const isDisableSelectItem = !is_move_in;

                        return (
                          <Tr key={code || '' + index} bgColor={isChecked ? '#ECF1FF' : 'unset'}>
                            <Td>
                              <Center>
                                <Checkbox
                                  isChecked={isChecked}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setCheckedItems((prevArr) => prevArr.concat(building));
                                    } else {
                                      setCheckedItems((prevArr) =>
                                        prevArr.filter((item) => item?.room?.code !== building?.room?.code),
                                      );
                                    }
                                  }}
                                  isDisabled={isDisableSelectItem}
                                />
                              </Center>
                            </Td>
                            <Td textAlign={'center'} width={'200px'} fontSize={'14px'}>
                              <VStack>
                                <Text>{agent_name}</Text>
                                <Text>{company_name}</Text>
                              </VStack>
                            </Td>
                            <Td>
                              {/* 매물 정보 */}
                              <HStack spacing={4} justifyContent={'space-between'} w="full">
                                <HStack spacing={4} flexGrow={1}>
                                  <Image
                                    boxSize={'117px'}
                                    src={!isEmptyArray(pictures) ? pictures[0] : 'https://via.placeholder.com/117'}
                                    alt="이미지가 없습니다."
                                  />
                                  <VStack w="300px" minW="300px" alignItems={'unset'} flexDir={'column'}>
                                    <Wrap fontSize={'11px'}>
                                      <Tag variant={'room_number'}>매물번호 {code}</Tag>
                                      {room_type && <Tag variant={'room_number'}>{room_type}</Tag>}
                                      {is_template && <Tag variant={'green'}>템플릿</Tag>}
                                      <Tag variant={is_move_in ? 'current_empty' : 'current_filled'} px={2}>
                                        {is_move_in ? '현재 공실' : '거래 완료'}
                                      </Tag>
                                      <AccessHiddenTag access_status={access_status} />
                                    </Wrap>
                                    <Flex w={'100%'} fontSize={'18px'} fontWeight={'medium'}>
                                      <Text>
                                        {address} {building_name && `(${building_name})`}
                                        {dong && `${dong}동`} {ho && `${ho}호`}
                                      </Text>
                                    </Flex>
                                    <HStack
                                      w={'100%'}
                                      flexWrap={'wrap'}
                                      divider={<StackDivider borderColor={'gray.300'} />}
                                      fontSize={'14px'}
                                      spacing={2}
                                    >
                                      {building_form && <Text>{buildingFormTypeTrans(building_form)}</Text>}
                                      {room_num && <Text>방{room_num}개</Text>}
                                      {dedicated_area && <Text>{dedicated_area}m²</Text>}
                                      {floor && <Text>{floor}층</Text>}
                                      {deposit && <Text>보증금 {deposit}원</Text>}
                                      {rent && <Text>월세 {rent}원</Text>}
                                      {management_fee && <Text>관리비 {management_fee}원</Text>}
                                    </HStack>
                                  </VStack>
                                </HStack>
                              </HStack>
                            </Td>
                            <Td>{room?.company_memo}</Td>
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                </Scrollbars>
              </Box>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <VStack justify={'center'} w={'full'}>
              <Flex justifyContent={'center'} w={'100%'} borderBottom={'1px solid'} borderBottomColor={'gray.400'} />
              <Center mt={'10px'}>
                <ClickIconContainer
                  clickableArea={7}
                  color="zetaBlue"
                  w={6}
                  h={6}
                  onClick={() => setPageNum((prev) => (prev > 1 ? prev - 1 : 1))}
                >
                  <LeftArrowIcon color="zetaBlue" />
                </ClickIconContainer>
                <Text mx={'20px'} textColor={'highGray'}>
                  {pageNum}
                </Text>
                <ClickIconContainer
                  clickableArea={7}
                  color="zetaBlue"
                  w={6}
                  h={6}
                  onClick={() => setPageNum((prev) => prev + 1)}
                >
                  <RightArrowIcon color="zetaBlue" />
                </ClickIconContainer>
              </Center>
            </VStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default RecommendRoomsModal;

import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Image,
  Input,
  ListItem,
  Modal,
  ModalContent,
  ModalOverlay,
  Tag,
  Text,
  Textarea,
  UnorderedList,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { isEmptyArray, isUndefined } from '@chakra-ui/utils';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import Scrollbars from 'react-custom-scrollbars-2';
import { WBox } from '../../Box';
import { RequestRoom, VirtualAccount } from '../../../../lib/common/utils/axios/types';
import { CheckCircleIcon } from '../../../../statics/common/icons';
import {
  useConfirmRecommendedRooms,
  useCreateUserVirtualAccount,
  useMakeTourReservationWithAdmin,
  useSuggestRooms,
  useUpdateVirtualAccount,
} from '../../../../lib/admin/hooks/mutation';
import { CustomInput } from '../../Input';

interface UpdateVirtualAccountModalProps {
  disabled?: boolean;
  virtual_account: VirtualAccount;
  user_id: string;
}

const UpdateVirtualAccountModal: React.FC<UpdateVirtualAccountModalProps> = ({
  children,
  disabled = false,
  virtual_account,
  user_id,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleModalOpen = () => {
    if (!disabled) {
      onOpen();
    }
  };
  const { mutateAsync: UpdateVirtualAccount } = useUpdateVirtualAccount();

  return (
    <>
      <Box onClick={handleModalOpen}>{children}</Box>

      <Modal isOpen={isOpen} onClose={onClose} isCentered motionPreset="none" size={'xl'}>
        <ModalOverlay />
        <Formik
          initialValues={{
            depoAmt: virtual_account?.control?.deposit_amount || '',
            depoEndDt: virtual_account?.control?.deposit_end_date || '',
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            await UpdateVirtualAccount({
              userId: user_id,
              userType: 'tenant',
              payload: {
                depoAmt: typeof values.depoAmt === 'number' ? values.depoAmt : parseInt(values.depoAmt),
                depoEndDt: values.depoEndDt,
              },
            }).then(() => {
              onClose();
            });
          }}
          validationSchema={Yup.object().shape({})}
        >
          {({ values, errors, isValid, handleChange, setFieldValue, isSubmitting }) => {
            return (
              // <Form style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>

              <Form>
                <ModalContent p={5}>
                  <Center flexDir={'column'} m={10} alignItems={'flex-start'}>
                    <HStack>
                      <CheckCircleIcon w={8} h={8} />
                      <Text fontSize={'23px'}>가상계좌 업데이트</Text>
                    </HStack>
                    <VStack fontSize={'20px'} flexDir={'column'} my={4} spacing={0} alignItems={'flex-start'}>
                      <Box>{'업데이트할 가상계좌 정보를 기입해주세요.'}</Box>
                    </VStack>
                    {/* 선택된 매물 리스트 */}

                    <VStack w={'full'}>
                      <WBox w={'full'}>
                        <Box w="full" px={'30px'} py={'30px'}>
                          <VStack>
                            <HStack w="full">
                              <Text minW="130px" fontSize={'14px'}>
                                제어 금액
                              </Text>
                              <Field name={'depoAmt'} as={CustomInput} fontSize={'14px'} addon="원" />
                            </HStack>
                            <HStack w="full">
                              <Text minW="130px" fontSize={'14px'}>
                                제어 날짜
                              </Text>
                              <Field name={'depoEndDt'} as={CustomInput} type={'datetime-local'} fontSize={'14px'} />
                            </HStack>
                          </VStack>
                        </Box>
                      </WBox>
                    </VStack>

                    <Center w="full">
                      <Button
                        mt={'30px'}
                        variant={'basic'}
                        w={'140px'}
                        fontSize={'14px'}
                        fontWeight={'bold'}
                        type="submit"
                        isLoading={isSubmitting}
                      >
                        수정하기
                      </Button>
                    </Center>
                  </Center>
                </ModalContent>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

export default UpdateVirtualAccountModal;

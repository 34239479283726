import { Tbody, Td, Tr } from '@chakra-ui/react';
import { Field, Form } from 'formik';

interface RooonTableType {
  tableText?: string;
  title: string;
  data: any;
}

const HalfTable = (props: RooonTableType) => {
  return (
    <>
      <Tbody fontSize={'14px'}>
        <Tr>
          <Td fontWeight={'500'} bgColor={'#f8f8f8'} width={'25%'}>
            {props.title}
          </Td>
          <Td width={'75%'}>
            {props.data}
            {/* {Array.isArray(props.data) ? props.data.map((el) => el) : props.data} */}
            {/* <Form>
              <Field />
            </Form> */}
          </Td>
        </Tr>
      </Tbody>
    </>
  );
};

export default HalfTable;

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Checkbox,
  Collapse,
  Flex,
  HStack,
  Modal,
  ModalContent,
  ModalOverlay,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Textarea,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import format from 'date-fns/format';
import { useEffect, useRef, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { ModalDisclosureWithServiceIdProps } from '../../common/Modals/base';
import { useConfirmService } from '../../../lib/subscriber/hooks/mutation';
import {
  parseContractHistories,
  parseRequestHistories,
  useGetFormRequest,
  useGetUserAllHistories,
} from '../../../lib/subscriber/hooks/query';
import ReCheckAlertModal from '../../common/Modals/Alert/ReCheckAlert';
import { request_type_transition } from '../../../pages/UserType/subscriber/manage/employee/UsageLists';
import { LeftArrowIcon, RightArrowIcon } from '../../../statics/common/icons';
import { GNoBBox } from '../../common/Box';
import ContractContentTable from '../Table/contractContentTable';
import RequestContentTable from '../Table/requestContentTable';
import UsageContentTable from '../Table/usageContentTable';

const NewRequestForm: React.FC<ModalDisclosureWithServiceIdProps> = ({ isOpen, onClose, service_id, user_id }) => {
  const { isOpen: isFormOpen, onToggle: onFormToggle } = useDisclosure();
  const [phase, setPhase] = useState<'initial' | 'reject' | 'approve'>('initial');
  const [rejectReason, setRejectReason] = useState('');
  const componentStartRef = useRef<HTMLDivElement>(null);
  const componentEndRef = useRef<HTMLDivElement>(null);
  const confirmApprovalRef = useRef();
  const confirmRejectRef = useRef();

  const { isOpen: isHistoryOpen, onToggle: onHistoryToggle } = useDisclosure();
  const { isOpen: isContractOpen, onToggle: onContractToggle } = useDisclosure();

  const {
    isOpen: isConfirmApprovalOpen,
    onOpen: onConfirmApprovalOpen,
    onClose: onConfirmApprovalClose,
  } = useDisclosure();

  const { isOpen: isConfirmRejectOpen, onOpen: onConfirmRejectOpen, onClose: onConfirmRejectClose } = useDisclosure();

  // 이용 약관
  const [checkedItems, setCheckedItems] = useState([false, false]);

  const allChecked = checkedItems.every(Boolean);
  const isIndeterminate = checkedItems.some(Boolean) && !allChecked;

  useEffect(() => {
    if (phase === 'approve' || phase === 'reject') {
      const scroll = setTimeout(() => {
        componentEndRef.current?.scrollIntoView({ behavior: 'smooth' });
      }, 500);

      return () => clearTimeout(scroll);
    }
    if (phase === 'initial') {
      const scroll = setTimeout(() => {
        componentStartRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }, 200);
      return () => clearTimeout(scroll);
    }
  }, [phase]);

  const { mutateAsync } = useConfirmService();

  const { data: serviceData, refetch } = useGetFormRequest(service_id);

  const { data: allHistoriesData } = useGetUserAllHistories(user_id);

  const allRequestHistoriesData = allHistoriesData && parseRequestHistories(allHistoriesData);
  const allContractHistoriesData = allHistoriesData && parseContractHistories(allHistoriesData);

  // if (serviceData === undefined || allRequestHistoriesData === undefined || allContractHistoriesData === undefined) {
  //   return null;
  // }
  console.log('service_id =>', service_id, serviceData);

  return (
    <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside" size={'2xl'} motionPreset="slideInRight">
      <ModalOverlay />
      <ModalContent>
        <Flex flexDir={'column'} w={'full'} h="full" alignItems={'flex-start'}>
          <Box
            w={'full'}
            borderBottomColor={'borderGray'}
            boxShadow={'rgba(0, 0, 0, 0.02) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 2px 3px -2px'}
            px={7}
            py={6}
          >
            <Flex justifyContent={'space-between'}>
              <HStack h={'34px'}>
                {phase !== 'initial' && (
                  <LeftArrowIcon w={6} h={6} color={'black'} cursor={'pointer'} onClick={() => setPhase('initial')} />
                )}
                <Text>💌</Text>
                <HStack spacing={1}>
                  <Text fontSize={'18px'} fontWeight={'medium'} lineHeight={0}>
                    {serviceData?.employee.user_name}님의 이용 신청서
                  </Text>
                  <Text fontSize={'18px'} fontWeight={'medium'} lineHeight={0}>
                    {phase === 'approve' ? '(승인)' : phase === 'reject' ? '(반려)' : ''}
                  </Text>
                </HStack>
              </HStack>
              {phase === 'initial' &&
                // {phase === 'initial' && serviceData?.employee.service.check_status === 'pending' && (
                (serviceData?.employee.service.check_status === 'pending' ||
                serviceData?.employee.service.check_status === 'cancelled' ? (
                  <HStack>
                    <Button
                      variant={'grayBorder_blackFont'}
                      w={'90px'}
                      h={'34px'}
                      borderRadius={'md'}
                      onClick={() => setPhase('reject')}
                    >
                      반려
                    </Button>
                    <Button variant={'basic'} w={'90px'} h={'34px'} onClick={() => setPhase('approve')}>
                      승인
                    </Button>
                  </HStack>
                ) : (
                  <ReCheckAlertModal
                    callback={async () =>
                      await mutateAsync({
                        employee_mutation_id: serviceData?.employee._id || '',
                        service_mutation_id: serviceData?.employee.service._id || '',
                        check_status: 'cancelled',
                        // is_approved: true,
                        rejected_reason: rejectReason,
                      }).then(() => {
                        // onConfirmRejectClose();
                        onClose();
                      })
                    }
                    content={'신청서 취소를 정말 확정하시겠습니까?'}
                  >
                    <Button variant={'reject'}>취소</Button>
                  </ReCheckAlertModal>
                ))}
            </Flex>
          </Box>
          <Scrollbars
            style={{ height: '480px' }}
            thumbSize={400}
            renderView={({ style, ...props }) => (
              <div id="renderView" style={{ ...style }} {...props}>
                {/* renderView */}
              </div>
            )}
          >
            {/* 1. 신청 내용 */}
            <Box w={'full'} px={7} py={6} ref={componentStartRef}>
              <Flex onClick={onFormToggle} alignItems={'center'} justifyContent={'space-between'} cursor={'pointer'}>
                <Text fontWeight={'medium'} fontSize={'14px'}>
                  신청 내용
                </Text>
                <RightArrowIcon
                  w={6}
                  h={6}
                  transform={`rotate(${isFormOpen ? 270 : 90}deg)`}
                  transition={'transform 0.15s linear'}
                />
              </Flex>
              <Box pb={2} borderBottomWidth={'1px'} borderBottomColor={'gray'} />
              <Collapse in={!isFormOpen}>{serviceData && <RequestContentTable data={serviceData} />}</Collapse>
            </Box>
            {/* 2. 이용 내역 */}
            <Box w={'full'} px={7} pb={6}>
              <Flex onClick={onHistoryToggle} alignItems={'center'} justifyContent={'space-between'} cursor={'pointer'}>
                <Text fontWeight={'medium'} fontSize={'14px'}>
                  {serviceData?.employee.user_name}님의 이용 내역
                </Text>
                <RightArrowIcon
                  w={6}
                  h={6}
                  transform={`rotate(${isHistoryOpen ? 270 : 90}deg)`}
                  transition={'transform 0.15s linear'}
                />
              </Flex>
              <Box pb={2} borderBottomWidth={'1px'} borderBottomColor={'gray'} />
              <Collapse in={!isHistoryOpen}>
                <Flex flexDir={'column'} w={'full'} flexGrow={1}>
                  <UsageContentTable data={allRequestHistoriesData} />
                </Flex>
              </Collapse>
            </Box>
            {/* 3. 계약 내용 */}
            <Box w={'full'} px={7} pb={6}>
              <Flex
                onClick={onContractToggle}
                alignItems={'center'}
                justifyContent={'space-between'}
                cursor={'pointer'}
              >
                <Text fontWeight={'medium'} fontSize={'14px'}>
                  {serviceData?.employee.user_name}님의 계약 내용
                </Text>
                <RightArrowIcon
                  w={6}
                  h={6}
                  transform={`rotate(${isContractOpen ? 270 : 90}deg)`}
                  transition={'transform 0.15s linear'}
                />
              </Flex>
              <Box pb={2} borderBottomWidth={'1px'} borderBottomColor={'gray'} />
              <Collapse in={!isContractOpen}>
                <Flex flexDir={'column'} w={'full'} flexGrow={1}>
                  <ContractContentTable data={allContractHistoriesData} />
                </Flex>
              </Collapse>
            </Box>
            {/* 승인 */}
            <Collapse in={phase === 'approve'} style={{ width: '100%' }}>
              <Box w={'full'} px={7} pb={6}>
                <GNoBBox w={'full'}>
                  {/* <HStack w={'full'} mb={4} spacing={4}>
                    <Text minW={'max-content'} fontWeight={'medium'}>
                      {`박지혜`}님께 월세 지원을 하시겠습니까?
                    </Text>
                    <InputGroup>
                      <Input
                        w={'100%'}
                        style={{ backgroundColor: 'white' }}
                        placeholder="지원하실 금액을 입력해 주세요."
                        _placeholder={{ fontSize: '14px', color: 'borderWhite' }}
                        textAlign={'center'}
                        // type={'number'}
                        h={'34px'}
                        onChange={(e) => setSupportFee(deCommafy(e.target.value) as any)}
                        value={threeCommafy(supportFee)}
                      />
                      <InputRightAddon
                        children="원"
                        bgColor={'blue.500'}
                        color="white"
                        fontSize={'14px'}
                        h={'34px'}
                        fontWeight={'medium'}
                      />
                    </InputGroup>
                  </HStack> */}
                  <>
                    <Checkbox
                      isChecked={allChecked}
                      isIndeterminate={isIndeterminate}
                      onChange={(e) => setCheckedItems([e.target.checked, e.target.checked])}
                      fontWeight={'medium'}
                      fontSize={'16px'}
                    >
                      모두 확인하였으며, 동의합니다.
                    </Checkbox>
                    <Stack mt={1} spacing={1}>
                      <Checkbox
                        size="smd"
                        isChecked={checkedItems[0]}
                        onChange={(e) => setCheckedItems([e.target.checked, checkedItems[1]])}
                        fontWeight={'medium'}
                      >
                        <Box>
                          <Text color={'zetaBlue'} display={'inline-block'}>
                            [필수]&nbsp;
                          </Text>
                          위 내용으로 보홈 이용을 승인합니다.
                        </Box>
                      </Checkbox>
                      <Checkbox
                        size="smd"
                        isChecked={checkedItems[1]}
                        onChange={(e) => setCheckedItems([checkedItems[0], e.target.checked])}
                        fontWeight={'medium'}
                        alignItems={'start'}
                      >
                        <Box>
                          <Text color={'zetaBlue'} display={'inline-block'}>
                            [필수]&nbsp;
                          </Text>
                          고객사는 계약서에 명의된 내용을 인지하였습니다. 서비스 이용을 승인한 이용자의 입주 계약
                          만료까지 구독 기간을 보장해야 합니다. 희망 입주 기간과 실제 입주 계약 기간이 다른 경우 추가
                          승인 절차가 필요합니다. 또한, 고객사는 이용자의 매물 현장 탐방을 위해 최대 2시간의 외출을
                          허용합니다. 더 자세한 사항은 보홈 구독 계약서 제4조에 명시되어 있습니다.
                        </Box>
                      </Checkbox>
                    </Stack>
                  </>
                </GNoBBox>
                <HStack w="full" mt={6} justifyContent={'center'}>
                  <Button
                    variant={'gray_hoverGray'}
                    fontSize={'20px'}
                    fontWeight={'bold'}
                    style={{ width: '102px', height: '44px' }}
                    onClick={onClose}
                  >
                    취소
                  </Button>
                  <>
                    <Button
                      variant={'basic'}
                      fontSize={'20px'}
                      fontWeight={'bold'}
                      style={{ width: '192px', height: '44px' }}
                      isDisabled={!allChecked}
                      onClick={() => {
                        onConfirmApprovalOpen();
                      }}
                    >
                      승인 완료
                    </Button>
                    <AlertDialog
                      isOpen={isConfirmApprovalOpen}
                      leastDestructiveRef={confirmApprovalRef.current}
                      onClose={onConfirmApprovalClose}
                      isCentered
                    >
                      <AlertDialogOverlay>
                        <AlertDialogContent>
                          <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            승인 확정
                          </AlertDialogHeader>

                          <AlertDialogBody>
                            <Text display={'inline-block'} fontWeight={'medium'}>
                              {serviceData?.employee.user_name}
                            </Text>
                            님의 신청을 승인하시겠습니까?
                          </AlertDialogBody>

                          <AlertDialogFooter>
                            <Button
                              ref={confirmApprovalRef.current}
                              onClick={onConfirmApprovalClose}
                              variant={'gray_hoverGray'}
                            >
                              취소
                            </Button>
                            <Button
                              variant={'basic'}
                              style={{ height: '40px', fontSize: '16px' }}
                              onClick={() => {
                                mutateAsync({
                                  employee_mutation_id: serviceData?.employee._id || '',
                                  service_mutation_id: serviceData?.employee.service._id || '',
                                  // is_approved: true,
                                  check_status: 'approved',
                                }).then(() => {
                                  onConfirmApprovalClose();
                                  onClose();
                                });
                              }}
                              ml={3}
                            >
                              승인 완료
                            </Button>
                          </AlertDialogFooter>
                        </AlertDialogContent>
                      </AlertDialogOverlay>
                    </AlertDialog>
                  </>
                </HStack>
              </Box>
            </Collapse>
            {/* 반려 */}
            <Collapse in={phase === 'reject'} style={{ width: '100%' }}>
              <Box w={'full'} px={7} pb={6}>
                <Textarea
                  value={rejectReason}
                  onChange={(e) => setRejectReason(e.target.value)}
                  placeholder="반려 사유를 작성해 주세요. 작성해주신 내용은 해당 임직원에게 전달됩니다."
                  _placeholder={{ color: 'silverGray', fontSize: '16px' }}
                  bgColor={'#f8f8f8'}
                  borderRadius={'md'}
                  resize={'none'}
                  h={'192px'}
                  px={'18px'}
                  py={'25px'}
                />
                <HStack w="full" mt={6} justifyContent={'center'}>
                  <Button
                    variant={'gray_hoverGray'}
                    fontSize={'20px'}
                    fontWeight={'bold'}
                    style={{ width: '102px', height: '44px' }}
                    onClick={onClose}
                  >
                    취소
                  </Button>
                  <>
                    <Button
                      variant={'basic'}
                      fontSize={'20px'}
                      fontWeight={'bold'}
                      style={{ width: '192px', height: '44px' }}
                      onClick={onConfirmRejectOpen}
                    >
                      반려 완료
                    </Button>
                    <AlertDialog
                      isOpen={isConfirmRejectOpen}
                      leastDestructiveRef={confirmRejectRef.current}
                      onClose={onConfirmRejectClose}
                      isCentered
                    >
                      <AlertDialogOverlay>
                        <AlertDialogContent>
                          <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            반려 확정
                          </AlertDialogHeader>

                          <AlertDialogBody>
                            <Text display={'inline-block'} fontWeight={'medium'}>
                              {serviceData?.employee.user_name}
                            </Text>
                            님의 신청을 반려하시겠습니까?
                          </AlertDialogBody>

                          <AlertDialogFooter>
                            <Button
                              ref={confirmRejectRef.current}
                              onClick={onConfirmRejectClose}
                              variant={'gray_hoverGray'}
                            >
                              취소
                            </Button>
                            <Button
                              variant={'basic'}
                              style={{ height: '40px', fontSize: '16px' }}
                              onClick={() =>
                                mutateAsync({
                                  employee_mutation_id: serviceData?.employee._id || '',
                                  service_mutation_id: serviceData?.employee.service._id || '',
                                  rejected_reason: rejectReason,
                                  check_status: 'rejected',
                                }).then(() => {
                                  onConfirmRejectClose();
                                  onClose();
                                })
                              }
                              ml={3}
                            >
                              반려 완료
                            </Button>
                          </AlertDialogFooter>
                        </AlertDialogContent>
                      </AlertDialogOverlay>
                    </AlertDialog>
                  </>
                </HStack>
              </Box>
            </Collapse>
            <Box ref={componentEndRef} />
          </Scrollbars>
        </Flex>
      </ModalContent>
    </Modal>
  );
};

export default NewRequestForm;

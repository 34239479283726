import { Box, HStack, Text } from '@chakra-ui/react';
import { Field, FormikProps } from 'formik';
import { CustomInput } from '../Input';
import Validation from './Validation';
import { isArray } from 'lodash';

interface Props {
  isEditable: boolean;
  props: FormikProps<any>;
  name: string | Array<string>;
  CInput?: JSX.Element;
  CText?: JSX.Element;
}

export const FormikEditableInput: React.FC<Omit<Props, 'isEditable'>> = ({ props, name }) => {
  const { errors } = props;
  const name_arr = isArray(name) ? name : [name];
  return (
    <>
      {name_arr.map((n, index) => (
        <Box key={index}>
          <Field name={n} as={CustomInput} isInvalid={!!errors[n]} />
          <Validation error={errors[n] as any} />
        </Box>
      ))}
    </>
  );
};

export const FormikText: React.FC<Omit<Props, 'isEditable'>> = ({ props, name }) => {
  const { values } = props;
  const name_arr = isArray(name) ? name : [name];
  return (
    <>
      {name_arr.map((n, index) => (
        <Text key={index}>{values[n]}</Text>
      ))}
    </>
  );
};

const FormikEditableValidationComponent: React.FC<Props> = ({ isEditable, props, name, CInput, CText }) => {
  return (
    <Box>
      {isEditable ? (
        CInput ? (
          { CInput }
        ) : (
          <HStack align={'start'}>
            <FormikEditableInput props={props} name={name} />
          </HStack>
        )
      ) : CText ? (
        { CText }
      ) : (
        <HStack>
          <FormikText props={props} name={name} />
        </HStack>
      )}
    </Box>
  );
};

export default FormikEditableValidationComponent;

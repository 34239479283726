import { SmallCloseIcon } from '@chakra-ui/icons';
import {
  HStack,
  InputGroup,
  InputLeftElement,
  Input,
  Button,
  StackProps,
  InputProps,
  ButtonProps,
  InputRightElement,
  useToast,
  Box,
} from '@chakra-ui/react';
import { useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { unionTwoKeys, useToInitialParamsURL } from '../../../lib/common/utils/base';
import { warning } from '../../../statics/common/data/toast';
import { union } from 'lodash';
import { default_items_limit_number } from '../../../lib/common/utils/values';

interface CustomSearchProps extends StackProps {
  placeholder: string;
  inputProps?: InputProps;
  buttonProps?: ButtonProps;
  isFormWrap?: boolean;
  pseudoQueryKey?: string;
}

export const CustomSearch: React.FC<CustomSearchProps> = ({
  placeholder,
  inputProps,
  buttonProps,
  isFormWrap = true,
  pseudoQueryKey,
  ...props
}) => {
  const baseSearchQueryKey = 'search';
  const searchQueryKey = unionTwoKeys(pseudoQueryKey, baseSearchQueryKey);

  const basePageQueryKey = 'page';
  const pageQueryKey = unionTwoKeys(pseudoQueryKey, basePageQueryKey);

  const basePagelistQueryKey = 'pagelist';
  const pagelistQueryKey = unionTwoKeys(pseudoQueryKey, basePagelistQueryKey);

  const [searchParams, setSearchParams] = useSearchParams();
  const initialText = searchParams.get(searchQueryKey) || '';
  const [text, setText] = useState<string>(initialText);
  // console.log('searchParams =>', searchParams.get(searchQueryKey));
  const navigate = useNavigate();
  const { pathname } = location;

  const [toInitialParamsURL] = useToInitialParamsURL();

  const handleTypeText = (e: any) => {
    setText(e.target.value);
  };

  const resetText = () => {
    searchParams.delete(searchQueryKey);
    setSearchParams(searchParams);
    setText('');
  };

  const handleSubmitButton = () => {
    // if (text) {
    searchParams.set(searchQueryKey, text);
    searchParams.set(pageQueryKey, '1');
    searchParams.set(pagelistQueryKey, '1');
    setSearchParams(searchParams);
    // }
    // else {
    //   if (!toast.isActive(tst_id)) {
    //     toast({ ...warning, id: tst_id, title: '한 글자 이상의 텍스트를 입력해 주세요', description: '' });
    //   }
    // }
    // usequery가 변하는 params 캐치 못할시 navigate 고려
  };

  const handleFocus = (e: any) => e.target.select();

  const resetParams = () => {
    // toInitialParamsURL();
    resetText();
    navigate({
      pathname,
      search: `?${createSearchParams({
        page: '1',
        limit: default_items_limit_number.toString(),
        search: '',
        pagelist: '1',
      })}`,
    });
  };

  if (!isFormWrap) {
    return (
      <HStack alignItems={'center'} w={'450px'} {...props}>
        <InputGroup>
          <InputLeftElement position={'absolute'} top="-2px" children={<FaSearch color={'#A0A0A0'} />} />
          <Input
            // type="search"
            sx={{
              userSelect: false,
            }}
            fontSize={'13px'}
            h={'36px'}
            bg={'white'}
            placeholder={placeholder}
            _placeholder={{ color: 'borderWhite' }}
            _hover={{}}
            onChange={(e) => handleTypeText(e)}
            value={text}
            onFocus={handleFocus}
            // autoFocus
            {...inputProps}
          />
          <InputRightElement
            position={'absolute'}
            top="-2px"
            cursor={'pointer'}
            children={<SmallCloseIcon color={'#A0A0A0'} />}
            onClick={() => {
              resetText();
            }}
          />
        </InputGroup>
        <Button variant={'basic'} w={'90px'} type="submit" onClick={handleSubmitButton} {...buttonProps}>
          조회
        </Button>
        <Button variant={'warning'} w={'90px'} type="submit" onClick={resetParams} {...buttonProps}>
          초기화
        </Button>
      </HStack>
    );
  }

  return (
    <form onSubmit={(e) => e.preventDefault()} id="ggggg">
      <HStack alignItems={'center'} w={'450px'} {...props}>
        <InputGroup>
          <InputLeftElement position={'absolute'} top="-2px" children={<FaSearch color={'#A0A0A0'} />} />
          <Input
            // type="search"
            sx={{
              userSelect: false,
            }}
            fontSize={'13px'}
            h={'36px'}
            bg={'white'}
            placeholder={placeholder}
            _placeholder={{ color: 'borderWhite' }}
            _hover={{}}
            onChange={(e) => handleTypeText(e)}
            value={text}
            onFocus={handleFocus}
            // autoFocus
            {...inputProps}
          />
          <InputRightElement
            position={'absolute'}
            top="-2px"
            cursor={'pointer'}
            children={<SmallCloseIcon color={'#A0A0A0'} />}
            onClick={() => {
              resetText();
            }}
          />
        </InputGroup>
        <Button variant={'basic'} w={'90px'} type="submit" onClick={handleSubmitButton} {...buttonProps}>
          조회
        </Button>
        <Button variant={'warning'} w={'90px'} type="submit" onClick={resetParams} {...buttonProps}>
          초기화
        </Button>
      </HStack>
    </form>
  );
};
interface CustomSearchProps extends StackProps {
  placeholder: string;
  inputProps?: InputProps;
  buttonProps?: ButtonProps;
}

export const StyledSearch: React.FC<CustomSearchProps> = ({ placeholder, inputProps, buttonProps, ...props }) => {
  return (
    <HStack
      bgColor={'lotionWhite'}
      px={2}
      py={4}
      alignItems={'center'}
      w={'full'}
      h={'full'}
      border="1px solid"
      borderColor={'silverGray'}
      borderRadius={'md'}
      boxShadow={'sm'}
      {...props}
    >
      <InputGroup>
        <InputLeftElement position={'absolute'} top="-2px" children={<FaSearch color={'#A0A0A0'} />} />
        <Input
          type="search"
          fontSize={'13px'}
          h={'36px'}
          bgColor={'white'}
          placeholder={placeholder}
          _placeholder={{ color: 'borderWhite' }}
          _hover={{}}
          {...inputProps}
        />
      </InputGroup>
      <Button variant={'basic'} w={'90px'} type="submit" {...buttonProps}>
        조회
      </Button>
    </HStack>
  );
};

export const BasicSearch: React.FC<CustomSearchProps> = ({ placeholder, ...props }) => {
  const [value, setValue] = useState<string>('');

  return (
    <HStack {...props} w={{ base: '100%', md: '40%' }}>
      <InputGroup>
        <InputLeftElement position={'absolute'} top="-2px" children={<FaSearch color={'#A0A0A0'} />} />
        <Input
          // type="search"
          fontSize={'13px'}
          h={'36px'}
          bgColor={'white'}
          placeholder={placeholder}
          _placeholder={{ color: 'borderWhite' }}
          _hover={{}}
          onChange={(e) => setValue(e.target.value)}
        />
      </InputGroup>
      <Button variant={'basic'} w={'90px'} onClick={() => console.log(value)}>
        조회
      </Button>
    </HStack>
  );
};

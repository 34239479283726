import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Table,
  Tbody,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { FieldArray, useFormikContext } from 'formik';
import { useState } from 'react';
import Dropzone from 'react-dropzone';
import { CustomInput } from '../../Input';
import { TrContainer } from '../../Table/Tr';
import { failToUpload } from '../../../../statics/common/data/toast';
import { CheckCircleIcon } from '../../../../statics/common/icons';
import { formikInitialValuesProps } from '../ConfirmContractModal';

interface RegisterFacilityInfoModalProps {
  disabled?: boolean;
  fieldArrayKey?: string;
  is_fileInfosProps?: boolean;
}

const RegisterFacilityInfoModal: React.FC<RegisterFacilityInfoModalProps> = ({
  children,
  disabled = false,
  fieldArrayKey,
  is_fileInfosProps,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { values, setFieldValue } = useFormikContext<formikInitialValuesProps>();
  const [search, setSearch] = useState<string>('');
  const [s_facility_name, setSFacilityName] = useState<string>('');
  const [s_facility_pictures, setSFacilityPictures] = useState<Array<File>>([]);
  const toast = useToast();

  const handleModalOpen = () => {
    if (!disabled) {
      onOpen();
    }
  };

  const onClickCancelButton = () => {
    onClose();
  };

  const isAbleClickRegisterButton = true;

  const fieldArray_key = fieldArrayKey ? fieldArrayKey : 'facilities';

  return (
    <>
      <Box onClick={handleModalOpen}>{children}</Box>

      <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside" isCentered size={'2xl'}>
        <ModalOverlay />
        <ModalContent p={5}>
          <ModalCloseButton />
          {/* <Scrollbars style={{ height: '100%' }}> */}
          <FieldArray name={fieldArray_key}>
            {({ insert, remove, push }) => (
              <Box w="full" h="full">
                <Flex flexDir={'column'} m={10} alignItems={'flex-start'} h="full">
                  <HStack mb={2}>
                    <CheckCircleIcon w={8} h={8} />
                    <Text fontSize={'23px'}>공용시설 추가</Text>
                  </HStack>
                  <Box mb={6}>
                    <Text fontSize={'18px'}>등록하실 공용시설의 이름과 사진을 등록해주세요.</Text>
                  </Box>
                  <Box mb={1}>
                    <Text textStyle={'bold15'}>시설 정보</Text>
                  </Box>
                  <Box w="full" h="full">
                    <Table w={'100%'}>
                      <Tbody w={'100%'}>
                        <TrContainer title="시설명" textStyle={'medium14'}>
                          <CustomInput value={s_facility_name} onChange={(e) => setSFacilityName(e.target.value)} />
                        </TrContainer>
                        <TrContainer title="사진" textStyle={'medium14'}>
                          <HStack>
                            <CustomInput
                              isDisabled
                              value={s_facility_pictures.map((pic) => pic.name).join(',') || ''}
                            />
                            {/* <CustomInput isDisabled value={`s_facility_pictures${[0]}.name` || ''} /> */}
                            <Dropzone
                              accept={'image/*'}
                              onDrop={(acceptedFiles) => {
                                console.log('acceptedFiles =>', acceptedFiles);

                                if (acceptedFiles.length === 0) {
                                  return;
                                  // } else if (acceptedFiles.length > 1) {
                                  //   toast({ ...failToUpload, description: '한 장의 사진만 선택 가능합니다.' });
                                } else {
                                  setSFacilityPictures(acceptedFiles);
                                  // setSFacilityPicture(acceptedFiles[0]);
                                  // setFieldValue('files', (values.files as any).concat(acceptedFiles));
                                }
                              }}
                            >
                              {({ isDragActive, isDragReject, acceptedFiles, getRootProps, getInputProps }) => {
                                return (
                                  <Flex flexDir={'column'}>
                                    <Center {...getRootProps()}>
                                      <input {...getInputProps()} />
                                      <Center
                                        bgColor={'zetaBlue'}
                                        borderRadius={'md'}
                                        borderWidth={'1px'}
                                        cursor="pointer"
                                        fontWeight={'medium'}
                                        _hover={{ opacity: '0.7' }}
                                        transition={'0.18s linear'}
                                      >
                                        <Text
                                          fontSize={'14px'}
                                          px={4}
                                          h="32px"
                                          fontWeight={400}
                                          color="white"
                                          w="max"
                                          as={Flex}
                                          alignItems={'center'}
                                        >
                                          파일 선택
                                        </Text>
                                      </Center>
                                    </Center>
                                  </Flex>
                                );
                              }}
                            </Dropzone>
                          </HStack>
                        </TrContainer>
                      </Tbody>
                    </Table>

                    <HStack w="full" mt={'30px'} justify={'center'}>
                      <Button
                        variant={'gray'}
                        w={'140px'}
                        fontSize={'14px'}
                        fontWeight={'bold'}
                        onClick={onClickCancelButton}
                      >
                        취소
                      </Button>
                      <Button
                        variant={'basic'}
                        w={'140px'}
                        fontSize={'14px'}
                        fontWeight={'bold'}
                        isDisabled={!isAbleClickRegisterButton}
                        onClick={() => {
                          is_fileInfosProps
                            ? push({
                                name: s_facility_name,
                                removed: false,
                                file_infos: s_facility_pictures.map((file) => ({
                                  url: file,
                                  removed: false,
                                })),
                              })
                            : push({
                                name: s_facility_name,
                                facility_picture: s_facility_pictures,
                              });
                          onClose();
                        }}
                      >
                        등록
                      </Button>
                    </HStack>
                  </Box>
                </Flex>
              </Box>
            )}
          </FieldArray>
          {/* </Scrollbars> */}
        </ModalContent>
      </Modal>
    </>
  );
};

export default RegisterFacilityInfoModal;

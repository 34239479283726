import { Button, ButtonProps } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

interface RequestChatButtonProps extends ButtonProps {
  chat_room_id: string | null;
}

const RequestChatButton: React.FC<RequestChatButtonProps> = ({ children, chat_room_id, ...props }) => {
  const navigate = useNavigate();
  return (
    <Button
      variant={'basic'}
      style={{ height: '30px' }}
      onClick={() => {
        navigate(`/admin/chat/${chat_room_id}`, {
          state: {
            chat_room_id,
          },
        });
      }}
      {...props}
    >
      {children ? children : '채팅하기'}
    </Button>
  );
};

export default RequestChatButton;

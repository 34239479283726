import { format, isToday, isYesterday } from 'date-fns';
import { RemodelingItemEnum, RemodelingSeasonEnum, RequestStatusEnum } from '../axios/types';
import { bank_data } from '../../../../statics/common/data/bank';
import _, {
  isNumber,
  pickBy,
  identity,
  isNaN,
  isUndefined,
  transform,
  merge,
  mergeWith,
  has,
  defaultsDeep,
  isEmpty,
} from 'lodash';
import { createSearchParams, URLSearchParamsInit, useNavigate, useSearchParams } from 'react-router-dom';
import { default_items_limit_number } from '../values';
import { datedotformat } from '../dateformatter';
import { Tag, TagProps, Text, TextProps } from '@chakra-ui/react';

export const NumberInputTrim = (number: string) => {
  const value = number.trim().replace(/[^0-9]/g, '');
  return value;
};

export const RegistrationNumberAutoFormat = (phoneNumber: string): string => {
  if (phoneNumber.slice(-1) === '-') {
    return phoneNumber;
  }
  const number = phoneNumber.trim().replace(/[^0-9]/g, '');

  if (number.length < 4) return number;
  if (number.length < 6) return number.replace(/(\d{3})(\d{1})/, '$1-$2');
  if (number.length < 12) return number.replace(/(\d{3})(\d{2})(\d{1})/, '$1-$2-$3');
  return number.replace(/(\d{3})(\d{3})(\d{6})/, '$1-$2-$3');
};

export const NumberThreeCommafyTrim = (value: string) => {
  const format = value.trim().replace(/[^0-9,]/g, '');
  return format;
};

export const num2han = (num: any) => {
  if (!num) {
    return '영원';
  }
  num = parseInt((num + '').replace(/[^0-9]/g, ''), 10) + '';
  if (num == '0') return '영';

  const number = ['영', '일', '이', '삼', '사', '오', '육', '칠', '팔', '구'];
  const unit = ['', '만', '억', '조'];
  const smallUnit = ['천', '백', '십', ''];

  const result = [];

  const unitCnt = Math.ceil(num.length / 4);
  num = num.padStart(unitCnt * 4, '0');

  const regexp = /[\w\W]{4}/g;
  const array = num.match(regexp);

  for (let i = array.length - 1, unitCnt = 0; i >= 0; i--, unitCnt++) {
    const hanValue = _makeHan(array[i]);
    if (hanValue == '') continue;

    result.unshift(hanValue + unit[unitCnt]);
  }

  function _makeHan(text: any) {
    let str = '';
    for (let i = 0; i < text.length; i++) {
      const num = text[i];
      if (num == '0') continue;

      str += number[num] + smallUnit[i];
    }
    return str;
  }

  return result.join('') + '원';
};

export const TodayYesterdayFormat = (date: string) => {
  return isToday(Date.parse(date)) ? '오늘' : isYesterday(Date.parse(date)) ? '어제' : datedotformat(date);
};

export const request_status_trans = {
  new: '신규',
  delivery_waiting: '전달 대기',
  delivery_completed: '전달 완료',
  tour_reservation: '투어 예약',
  contracting: '계약 중',
  contracted: '계약 완료',
  moving_in: '입주 중',
  moving_out: '퇴실',
  extending: '연장',
  rejected: '반려',
  cancelled: '취소',
};

export const requestStatusTrans = (request_status_type?: RequestStatusEnum) => {
  if (!request_status_type) {
    return '';
  }
  return request_status_trans[request_status_type];
};

export const remodeling_item_trans = {
  veneer: '장판',
  wallpaper: '벽지',
  molding: '몰딩',
  toilet: '화장실',
  kitchen: '부엌',
};

export const remodelingItemTrans = (remodeling_item: RemodelingItemEnum) => {
  return remodeling_item_trans[remodeling_item];
};

export const remodeling_season = [
  {
    label: '최근 2년 이내',
    value: 'first',
  },
  {
    label: '5년 이내',
    value: 'within_few_years',
  },
  {
    label: '입주 시 조율 가능',
    value: 'tunable',
  },
  {
    label: '해당 사항 없음',
    value: 'none',
  },
];

export const remodeling_season_trans = {
  first: '최근 2년 이내',
  within_few_years: '5년 이내',
  tunable: '입주 시 조율 가능',
  none: '해당 사항 없음',
};

export const remodelingSeasonTrans = (remodeling_season: RemodelingSeasonEnum | '') => {
  if (remodeling_season === '') {
    return;
  } else {
    return remodeling_season_trans[remodeling_season];
  }
};

export const extractAddressArrFromRegionArr = (regions: Array<any>, includesDong = false) => {
  return regions.map((region) => `${region.sigungu_name || ''}${(includesDong && region.dongli_name) || ''}`);
};

export const bankNameTrans = (bank_id: number | string | undefined) => {
  if (isUndefined(bank_id)) {
    return '';
  }
  const parsedBankId = typeof bank_id === 'string' ? parseInt(bank_id) : bank_id;

  return bank_data.data.find((obj) => obj.bankId === parsedBankId)?.bankName;
};

export const trimObject = (obj: any) => {
  for (const key in obj) {
    if (obj[key] === undefined) {
      delete obj[key];
    }
  }
  return obj;
};

export const booleanToString = (arg: any) => {
  let result = '';
  switch (true) {
    case arg === true:
    case arg === 'true':
      result = 'true';
      break;
    case arg === false:
    case arg === 'false':
      result = 'false';
      break;
  }
  return result;
};

export const booleanToKoString = (boolean: boolean | undefined) => {
  if (!boolean) {
    return '';
  }
  return boolean ? '있음' : '없음';
};

export const boolstrToBoolean = (str: 'true' | 'false' | string) => {
  return str === 'true' ? true : str === 'false' ? false : str;
};

export const truncateLength = (string: any, length = 20) => {
  if (!string) {
    return '';
  }
  if (string.length > length) {
    return `${string?.toString?.()?.substring(0, length)}...`;
  } else {
    return string;
  }
};

export const floorAtFirst = (number: number) => {
  if (isNumber(number)) {
    return Math.floor(number * 10) / 10;
  } else {
    return number;
  }
};

export const floorAtSecond = (number: number) => {
  if (isNumber(number)) {
    return Math.floor(number * 100) / 100;
  } else {
    return number;
  }
};

export const GMT_to_UTC = (gmt: Date) => {
  if (!gmt) {
    return '';
  }

  const utc = Date.UTC(
    gmt.getUTCFullYear(),
    gmt.getUTCMonth(),
    gmt.getUTCDate(),
    gmt.getUTCHours(),
    gmt.getUTCMinutes(),
    gmt.getUTCSeconds(),
  );

  return new Date(utc);
};

export const squaredToPeong = (squared: number | string | undefined) => {
  if (squared) {
    const parsed = isNumber(squared) ? squared : parseFloat(squared);
    const peong = parsed / 3.305785;
    return floorAtFirst(peong);
  } else {
    return 0;
  }
};

export const peongToSquared = (peong: number | string | undefined) => {
  if (peong) {
    const parsed = isNumber(peong) ? peong : parseFloat(peong);
    const squared = parsed * 3.305785;
    return floorAtSecond(squared);
  } else {
    return 0;
  }
};

export const ToAllFilledObj = (obj: object) => {
  // return pickBy(obj, identity);
  return _.pickBy(obj, (value, key) => {
    if (_.isNumber(value) || _.isString(value) || (_.isBoolean(value) && value)) {
      return _.identity(value);
    } else {
      if (!_.isEmpty(value)) {
        return _.identity(value);
      }
    }
  });
};

export const isAllFilledObj = (obj: object) => {
  const keys_len = Object.keys(obj).length;
  const filled = ToAllFilledObj(obj);
  const keys_filled_len = Object.values(filled).length;
  return keys_len === keys_filled_len;
};

/** toNumeric = false시 params values 전부 string으로 타입 변경, 기본값 true */
export const currentParamsObject = (toNumeric = true) => {
  const { search } = location;
  const params = new URLSearchParams(search);

  return paramsToObject(params.entries(), toNumeric);
};

export const currentParamsFilledObject = (toNumeric = true) => {
  return ToAllFilledObj(currentParamsObject(toNumeric));
};

export const useToInitialParamsURL = () => {
  const navigate = useNavigate();
  const { pathname } = location;
  const [searchParams, setSearchParams] = useSearchParams();
  const params = new URLSearchParams();
  const toInitialParamsURL = () => {
    // 1
    // params.set('page', '1');
    // params.set('limit', default_items_limit_number.toString());
    // params.set('pagelist', '1');
    // setSearchParams(params);
    // 2
    navigate({
      pathname,
      search: `?${createSearchParams({
        limit: default_items_limit_number.toString(),
        page: '1',
        search: '',
        pagelist: '1',
      })}`,
    });
    // navigate(pathname);
  };
  return [toInitialParamsURL];
};

export const addTotalToObj = (obj: object, placeholder?: string) =>
  Object.assign({ '': placeholder ? placeholder : '전체' }, obj);

/** key가 false면 inCase 값을 출력. inCase 기본값은 '' */
export const extractValFromObj = <Type, Key extends keyof Type>(obj: Type, key: Key, inCase = '') => {
  return key ? obj[key] : inCase;
};

export const paramsToObject = (entries: IterableIterator<[string, string]>, toNumeric = true) => {
  const result = {} as any;
  if (toNumeric) {
    for (const [key, value] of entries) {
      isNumeric(value) ? (result[key] = parseInt(value)) : (result[key] = value);
    }
  } else {
    for (const [key, value] of entries) {
      result[key] = value;
    }
  }
  return result;
};

/** 값이 존재할 경우 Array 리턴, 존재 안 할시 [] 리턴 */
export const parseJsonToArr = (value: string) => {
  if (!value) {
    return [];
  }
  return JSON.parse(value);
};

/** 값이 존재할 경우 Object 리턴, 존재 안 할시 {} 리턴 */
export const parseJsonToObj = (value: string) => {
  // || value.indexOf('{') === 0 || value.indexOf('}') === value.length - 1
  if (!value) {
    return {};
  }
  // let result = {}
  // try{
  //   result = typeof value === 'object' ? JSON.stringify(value) : value
  // }catch(err){
  //   console.error(err)
  // }finally{
  //   return result
  // }
  return JSON.parse(value);
};

export const isNumeric = (value: string | number | undefined | null) => {
  return typeof value === 'number' ? true : !isNaN(parseInt(value as any));
};

const separator = '+';

/** pseudoQueryKey + '+' + baseQueryKey */
export const unionTwoKeys = (pseudo: string | undefined, base: string, sep = separator) => {
  if (!pseudo) {
    return base;
  }
  return pseudo + sep + base;
};

export const parseTwoKeys = (union: string) => {
  if (!union) {
    return '';
  }
  const [pseudo, base] = union.split(separator);
  return;
};

export type TagParamProps = {
  [key: string]: {
    trans: string;
    variant: string;
  };
};

// export const updateObjWithVariants = (obj_trans: object, obj_variants: object): TagParamProps => {
//   const a = _.keys(obj_trans);
//   const b = _.keys(obj_variants);
//   const isSame = _.isEqual(a, b);

//   if (!isSame) {
//     console.error('Two objects should have same keys in "updateObjWithVariants" function.');
//   }

//   const refined = mergeWith(
//     obj_trans,
//     obj_variants,
//     (value: any, srcValue: any, key: string, object: any, source: any) => {
//       return {
//         trans: value,
//         variant: srcValue,
//       };
//     },
//   ) as TagParamProps;
//   return refined;
// };

export const buildSearchParams = (paramObj: object) => {
  const { pathname } = location;
  const paramsprops = currentParamsObject(false);
  const searchParams = {
    pathname,
    search: `?${createSearchParams(
      ToAllFilledObj({
        ...paramsprops,
        ...paramObj,
      }) as URLSearchParamsInit,
    )}`,
  };
  return searchParams;
};

interface TextWithCheckEmptyProps extends TextProps {
  value: any;
  shownAs?: any;
  emptyTextProps?: TextProps;
}

export const TextWithCheckEmpty: React.FC<TextWithCheckEmptyProps> = ({
  children,
  value,
  shownAs = '무관',
  emptyTextProps,
  ...props
}) => {
  if (!isEmpty(value)) {
    return <Text {...props}>{children}</Text>;
  } else {
    return (
      <Text color={'gray.400'} {...emptyTextProps}>
        {shownAs}
      </Text>
    );
  }
};
interface TextWithBoolProps extends TextProps {
  value: any;
  shownAs?: any;
  emptyTextProps?: TextProps;
}

export const TextWithBool: React.FC<TextWithBoolProps> = ({
  children,
  value,
  shownAs = '무관',
  emptyTextProps,
  ...props
}) => {
  if (value) {
    return <Text {...props}>{children}</Text>;
  } else {
    return (
      <Text color={'gray.400'} {...emptyTextProps}>
        {shownAs}
      </Text>
    );
  }
};

export const ComponentAndTextCheckEmpty: React.FC<TextWithCheckEmptyProps> = ({
  children,
  value,
  shownAs = '무관',
  emptyTextProps,
  ...props
}) => {
  if (!isEmpty(value)) {
    return <>{children}</>;
  } else {
    return (
      <Text color={'gray.400'} {...emptyTextProps}>
        {shownAs}
      </Text>
    );
  }
};

export const CheckEmpty: React.FC<TextWithCheckEmptyProps> = ({
  children,
  value,
  shownAs = '무관',
  emptyTextProps,
  ...props
}) => {
  if (!isEmpty(value)) {
    return <>{children}</>;
  } else {
    return <>{shownAs}</>;
  }
};

export const isFile = (file: any) => {
  if (isEmpty(file)) {
    return false;
  }
  if (file instanceof File) {
    return true;
  } else {
    return false;
  }
};

import { GlobalStatusMenu } from '../SetGlobalStatus';

export const SetCheckHouseStatusParams: React.FC = () => {
  return (
    <GlobalStatusMenu
      minW={'120.55px'}
      placeholder="집정보 확인"
      param_key="status"
      options={['approved', 'rejected', 'pending']}
    />
  );
};

export const SetSupportPaymentStatusParams: React.FC = () => {
  return (
    <GlobalStatusMenu
      minW={'104.53px'}
      placeholder="지원상태"
      param_key="support_payment_status"
      options={['none', 'pending', 'proceeding', 'completed', 'cancelled']}
    />
  );
};

export const SetPaymentStatusParams: React.FC = () => {
  return (
    <GlobalStatusMenu
      minW={'107.66px'}
      is_payment
      placeholder="납부상태"
      param_key="payment_status"
      options={['none', 'paid', 'overdue']}
    />
  );
};

export const SetConfirmStatusParams: React.FC = () => {
  return (
    <GlobalStatusMenu
      minW={'139.75px'}
      placeholder="승인여부(전체)"
      param_key="status"
      options={['pending', 'approved', 'rejected', 'cancelled']}
    />
  );
};

export const SetServiceStatusParams: React.FC = () => {
  return (
    <GlobalStatusMenu
      minW={'126.88px'}
      placeholder="서비스(전체)"
      param_key="category"
      options={['house_hunting', 'support_payment']}
    />
  );
};

import { Route, RouteProps, Routes } from 'react-router-dom';
import CompanyLoginPage from '../../../pages/common/Login/Company';

const CompanyLoginRoute: React.FC<RouteProps> = () => {
  return (
    <Routes>
      <Route index element={<CompanyLoginPage />} />
    </Routes>
  );
};

export default CompanyLoginRoute;

import { Box, BoxProps, Collapse } from '@chakra-ui/react';
import React from 'react';

interface ValidationWithTouchProps extends BoxProps {
  error: string | boolean | undefined;
  touch: string | boolean | undefined;
}

const ValidationWithTouch: React.FC<ValidationWithTouchProps> = ({ error, touch, ...props }) => (
  <Collapse in={!!error && !!touch}>
    <Box fontSize={'12px'} color="primaryRed" mt={1} ml={1} {...props}>
      *{error}
    </Box>
  </Collapse>
);

export default ValidationWithTouch;

import { Box, Flex, ListItem, Textarea, UnorderedList } from '@chakra-ui/react';
import { Field, useFormikContext } from 'formik';

export const BuildingDescription = () => {
  const formik = useFormikContext<any>();
  const { values, setFieldValue } = formik;
  return (
    <Flex flexDir={'column'} w={'full'} alignItems={'stretch'}>
      <Box fontWeight={'bold'} fontSize={'18px'} w={'100%'} textAlign={'start'} flexGrow={1} mb={2}>
        건물 상세 정보
      </Box>
      <Box w="full" borderBottomWidth={'1px'} borderBottomColor={'gray.400'} />
      <UnorderedList w={'100%'} textAlign={'start'} flexGrow={1} pl={2} my={4}>
        <ListItem>해당 건물에 대한 자세한 설명을 입력해 주세요.</ListItem>
        <ListItem>한글, 영어, 숫자, m2을 제외한 특수문자(괄호포함)등은 임의로 삭제될 수 있습니다.</ListItem>
      </UnorderedList>
      <Box w="full" borderBottomWidth={'1px'} borderBottomColor={'gray.200'} mb={2} />
      <Field
        name="building_description"
        as={Textarea}
        bgColor="lotionWhite"
        borderWidth={'1px'}
        borderColor={'silverGray'}
        maxLength={950}
        h="160px"
        _hover={{}}
      />
    </Flex>
  );
};

// await instance.post('v1/subscriber/master/token/verify', {
//   token: (user as any).accessToken,
//   login_type: 'email',
//   email: values.email,
// });

// GET

export const verify_user_path = '/token/verify';
// export const verify_user_path = '/subscriber/master/token/verify';
export const verify_user_key = '/token/verify';
// export const verify_user_key = '/subscriber/master/token/verify';
export const company_path = '/subscriber/master/company';
export const company_key = '/subscriber/master/company';
export const contract_key = '/subscriber/master/subscription-contract';
export const contract_path = '/subscriber/master/subscription-contract';
export const contracts_key = '/subscriber/master/company/subscription-contracts';
export const contracts_path = '/subscriber/master/company/subscription-contracts';
export const payments_key = '/subscriber/master/subscription-payments';
export const payments_path = '/subscriber/master/subscription-payments';
export const notifications_list_key = 'notifications';
export const notifications_list_path = 'notifications';
export const employee_services_key = '/subscriber/master/company/employees/services';
export const employee_services_path = '/company/employees/services';
export const employee_histories_key = '/subscriber/master/employees/user_id/histories';
export const employee_counts_key = '/subscriber/master/employees/count';
export const employee_counts_path = '/subscriber/master/employees/count';
export const employee_usings_key = '/subscriber/master/company/employees/usings';
export const employee_usings_path = '/company/employees/usings';

export const not_checked_count_path = 'notifications-fcms/not-checked-count';
export const not_checked_count_key = 'notifications-fcms/not-checked-count';

export const all_announcements_path = 'announcements';
export const all_announcements_key = 'announcements';

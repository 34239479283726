import { Flex, Center, Collapse, HStack, Box, ListItem, UnorderedList, toast, useToast } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import Dropzone from 'react-dropzone';
import { titleProperties } from '../type';
import Thumb from '../../../../Thumb/base';
import { failToUpload } from '../../../../../../statics/common/data/toast';

interface UploadBuildingPictureDropZoneContainerProps {
  content?: any;
}

export const UploadBuildingPictureDropZoneContainer: React.FC<UploadBuildingPictureDropZoneContainerProps> = ({
  content,
}) => {
  return (
    <Flex flexDir={'column'} w={'full'} alignItems={'stretch'}>
      <Box w={'100%'} textAlign={'start'} flexGrow={1} {...titleProperties}>
        건물 사진 등록
      </Box>
      {content ? (
        content
      ) : (
        <>
          <Box w="full" borderBottomWidth={'1px'} borderBottomColor={'gray'} />
          <UnorderedList w={'100%'} textAlign={'start'} flexGrow={1} pl={2} my={4}>
            <ListItem>최대 5장까지 등록이 가능합니다. (한 장당 10MB이내)</ListItem>
            <ListItem>건물의 외부 현관, 주차장, 무인택배함 등 외부 시설이 잘 드러난 사진을 등록해주세요.</ListItem>
          </UnorderedList>
        </>
      )}
      <UploadBuildingPictureDropZone />
      {/* 다음 */}
    </Flex>
  );
};
export const UploadBuildingPictureDropZone = () => {
  const formik = useFormikContext<any>();
  const { values, setFieldValue } = formik;
  const toast = useToast();
  const uploadedPictures = values.files.length;
  const text = uploadedPictures ? `+ ${uploadedPictures}개의 사진 등록` : '+ 사진 등록';
  return (
    <Dropzone
      accept={'image/*'}
      onDrop={(acceptedFiles) => {
        if (acceptedFiles.length === 0) {
          return;
        } else {
          if (uploadedPictures + acceptedFiles.length > 5) {
            toast(failToUpload);
          } else {
            setFieldValue('files', (values.files as any).concat(acceptedFiles));
          }
        }
      }}
    >
      {({ isDragActive, isDragReject, acceptedFiles, getRootProps, getInputProps }) => {
        return (
          <Flex flexDir={'column'} w={'full'}>
            <Center {...getRootProps()} w={'full'}>
              <input {...getInputProps()} />
              <Center
                bgColor={'lotionWhite'}
                w={'full'}
                h={'160px'}
                borderRadius={'2px'}
                borderWidth={'1px'}
                borderColor={'silverGray'}
                cursor="pointer"
                fontWeight={'medium'}
                _hover={{ bgColor: 'zetaBlue', color: 'white' }}
                transition={'0.18s linear'}
              >
                <Center
                  fontSize={'24px'}
                  fontWeight={'bold'}
                  bgColor={'zetaBlue'}
                  color="white"
                  w={'max'}
                  borderRadius={'md'}
                  px={8}
                  py={2}
                >
                  {text}
                </Center>
              </Center>
            </Center>
            <Collapse style={{ width: '100%' }} in={values.files.length !== 0}>
              <HStack mt={6}>
                {values.files.map((file: any, i: any) => (
                  <Thumb key={i} file={file} />
                ))}
              </HStack>
            </Collapse>
          </Flex>
        );
      }}
    </Dropzone>
  );
};

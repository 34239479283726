import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import { ButtonGroup, Tooltip, useEditableControls } from '@chakra-ui/react';
import styled from '@emotion/styled';

const IconContainer = styled.button`
  display: flex;
  padding: 5px;
  border-radius: 5px;
  height: 24px;
  width: 24px;
  &:hover {
    background-color: #f0f0f0;
  }
  transition: 0.3s ease;
`;

export const EditControlButton = () => {
  const { isEditing, getSubmitButtonProps, getCancelButtonProps, getEditButtonProps } = useEditableControls();
  return isEditing ? (
    <ButtonGroup justifyContent="center" size="sm">
      <Tooltip label="저장하기" bg={'zetaBlue'} color="white">
        <IconContainer {...getSubmitButtonProps()}>
          <CheckIcon />
        </IconContainer>
      </Tooltip>
      <Tooltip label="취소하기" bg={'primaryRed'} color="white">
        <IconContainer {...getCancelButtonProps()} style={{ marginRight: '4px' }}>
          <CloseIcon />
        </IconContainer>
      </Tooltip>
    </ButtonGroup>
  ) : null;
};

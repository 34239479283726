import {
  Box,
  Button,
  Center,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { isUndefined } from '@chakra-ui/utils';
import React, { useContext } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { AlertContext } from '../../../../contexts/common/AlertContext';
import { useUpdateNotificationsCheck } from '../../../../lib/common/hooks/mutation';
import { useGetNotCheckedCount } from '../../../../lib/common/hooks/query';
import { BellIcon, IconCircleContiner } from '../../../../statics/common/icons';
import Loading from '../../Loading';
import AlertCardContainers from './CardContainers';
import Tabs from './Usage';

const OpenAlertButton = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  const { tab } = useContext(AlertContext);
  const isAlert = tab === '중요 알림';

  const { data: allnotiData, isLoading } = useGetNotCheckedCount({
    options: {
      enabled: isOpen,
    },
  });
  const { mutate } = useUpdateNotificationsCheck();

  if (isLoading) return <BellIcon />;
  return (
    <>
      {/* <AlertStore> */}
      <IconCircleContiner
        itemRef={btnRef.current}
        onClick={onOpen}
        role="group"
        id="notification_alert_button"
        _hover={{}}
      >
        <BellIcon />
        {isUndefined(allnotiData) ? undefined : (
          <Box
            pos={'absolute'}
            top={'-12%'}
            right={'-4%'}
            display={allnotiData?.notification_count === 0 ? 'none' : 'block'}
            bgColor={'primaryRed'}
            boxSize={'16px'}
            borderRadius={'full'}
            fontWeight={'bold'}
            fontSize={allnotiData?.notification_count?.toString().length === 1 ? '11px' : '9px'}
            // w={'20px'}
            // h={'20px'}
            // fontSize={'10px'}
            _groupHover={{
              bgColor: 'zetaBlue',
              top: 'unset',
              right: 'unset',
              boxSize: 'full',
              borderRadius: 'full',
              transition: '0.15s linear',
              fontSize: allnotiData?.notification_count?.toString().length === 1 ? '16px' : '16px',
              fontWeight: 'medium',
            }}
          >
            <Center boxSize={'full'}>
              <Text color={'white'} lineHeight={0}>
                {allnotiData?.notification_count > 99 ? '+99' : allnotiData?.notification_count}
              </Text>
            </Center>
          </Box>
        )}
      </IconCircleContiner>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef.current}
        variant={'alert'}
        size={'sm'}
      >
        <DrawerOverlay />
        <DrawerContent style={{ top: '80px', right: '1%', bottom: '4%' }} borderRadius={'lg'}>
          <DrawerCloseButton />
          <DrawerHeader fontSize={'24px'} fontWeight={'medium'}>
            <Flex alignItems={'center'}>
              <Box mr={2}>알림</Box>
              <Center fontSize={'12px'} color={'gray.500'}>
                {allnotiData?.notification_count}개의 읽지 않은 알림이 있습니다.
              </Center>
            </Flex>
          </DrawerHeader>

          <Box py={2} h="full">
            <Box px={6}>
              {/* 기능 완료, 미사용중 */}
              {/* <Tabs /> */}
              <Flex justify={'end'} mb={1}>
                <Button variant={'basic'} onClick={() => mutate()}>
                  모두 읽음 처리
                </Button>
              </Flex>
            </Box>
            <Scrollbars style={{ height: 'calc(100% - 113px)' }}>
              {/* <Scrollbars> */}
              <Box>
                <Box mx={6}>
                  {isAlert ? <AlertCardContainers /> : <Text fontSize={'14px'}>새로운 공지 사항이 없습니다.</Text>}
                </Box>
              </Box>
            </Scrollbars>
          </Box>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default OpenAlertButton;

import { Box, Center, CenterProps, Text } from '@chakra-ui/react';
import { useGetCompanyContract } from '../../../lib/subscriber/hooks/query';
import { subscriptionColorTrans, subscriptionTypeTrans } from '../../../lib/subscriber/utils/utils';

interface ContractTypeProps {
  centerprops?: CenterProps;
}

const ContractType: React.FC<ContractTypeProps> = ({ centerprops }) => {
  const { data: contractdata } = useGetCompanyContract();
  if (contractdata === undefined) {
    return null;
  }

  const { subscription_type } = contractdata;

  return (
    <Center justifyContent={'flex-start'} fontSize={'20px'} fontWeight={700} {...centerprops}>
      <Box w={'8px'} h={'25px'} borderRadius={'md'} bgColor={subscriptionColorTrans(subscription_type)} mr={1} />
      <Text>{subscriptionTypeTrans(subscription_type)}</Text>
    </Center>
  );
};

export default ContractType;

import { Menu, MenuButton, MenuButtonProps, MenuItem, MenuList } from '@chakra-ui/react';
import * as _ from 'lodash';
import React from 'react';
import { createSearchParams, URLSearchParamsInit, useNavigate } from 'react-router-dom';
import { global_payment_status_trans, global_status_trans } from '../../../../../lib/common/utils/utils';
import {
  addTotalToObj,
  buildSearchParams,
  currentParamsObject,
  extractValFromObj,
  ToAllFilledObj,
} from '../../../../../lib/common/utils/base';
import RightArrowButton from '../../../Button/RightArrowButton';

interface GlobalStatusMenuProps extends MenuButtonProps {
  placeholder: string;
  options: Array<keyof typeof global_status_trans> | Array<keyof typeof global_payment_status_trans>;
  param_key: string;
  is_payment?: boolean;
}

export const GlobalStatusMenu: React.FC<GlobalStatusMenuProps> = ({
  placeholder,
  options,
  param_key,
  is_payment,
  ...props
}) => {
  const { pathname, search: srch } = location;
  const navigate = useNavigate();
  const { ...paramsprops } = currentParamsObject(false);

  const onClickMenuItem = (value: string) => {
    const searchParams = buildSearchParams({
      page: '1',
      pagelist: '1',
      [param_key]: value,
    });
    navigate(searchParams);
  };

  const trans = is_payment ? global_payment_status_trans : global_status_trans;

  const selections = _.pick(trans, options);

  const options_arr = addTotalToObj(selections, placeholder);
  const title = extractValFromObj(trans, paramsprops[param_key], placeholder);

  return (
    <Menu autoSelect={false}>
      <MenuButton as={RightArrowButton} h={'36px'} {...props}>
        {title}
      </MenuButton>
      <MenuList zIndex={2} style={{ margin: 0 }}>
        {Object.entries(options_arr).map((option, index) => {
          const [key, value] = option;
          const isActivate = value === title;
          return (
            <MenuItem
              key={index}
              bgColor={isActivate ? 'zetaBlue' : 'white'}
              color={isActivate ? 'white' : '#414141'}
              onClick={() => onClickMenuItem(key)}
              isDisabled={isActivate}
            >
              {value}
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  );
};

import { Box, Button, HStack, Image, Link, Text, VStack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { GItem } from '../../../../../components/common/GridItem';
import Tab from '../../../../../components/common/Tab';
import { useGetCompanyInfomation } from '../../../../../lib/subscriber/hooks/query';
import { LocationIcon, TwoPeopleIcon } from '../../../../../statics/common/icons';

const CompanySection = () => {
  const navigate = useNavigate();
  const { data: cpInfo } = useGetCompanyInfomation();

  if (cpInfo === undefined) {
    return null;
  }
  const {
    _id,
    address,
    business_certificate,
    business_item,
    business_registration_number,
    business_status,
    business_type,
    capital,
    company_code,
    company_type,
    createdAt,
    detail_address,
    digital_tax_invoice_email,
    employee_ids,
    employee_number,
    establishment_date,
    is_activated,
    limit_manager,
    location: { coordinates, type },
    logo_url,
    name,
    representative_number,
    road_address,
    subscription_contract_ids,
    term_and_conditions,
    updatedAt,
  } = cpInfo;
  return (
    <GItem h="580px" minH="580px">
      <VStack p={5} spacing={5} w={'full'}>
        <Box w="full">
          {logo_url && (
            <Image
              w={'full'}
              maxH={'200px'}
              objectFit="contain"
              src={logo_url}
              alt="company-logo"
              borderRadius={'md'}
            />
          )}
        </Box>
        <VStack w={'full'}>
          <HStack justifyContent={'space-between'} alignItems={'flex-start'} w={'full'}>
            <VStack alignItems={'flex-start'} w={'full'} spacing={0}>
              <Text fontSize={'20px'} fontWeight={'medium'}>
                {name}
              </Text>
              <Text fontSize={'14px'}>코드번호 {company_code}</Text>
            </VStack>
            <Button onClick={() => navigate('/company/infos')} variant={'grayBorder_blackFont'} h={'30px'}>
              정보 수정
            </Button>
          </HStack>
          <VStack w="full" spacing={0}>
            <HStack w="full" justifyContent={'flex-start'} fontSize={'12px'} spacing={1}>
              <TwoPeopleIcon />
              <Text>{employee_number}명</Text>
            </HStack>
            <HStack w="full" justifyContent={'flex-start'} fontSize={'12px'} spacing={1}>
              <LocationIcon />
              <Text>{`${address && address}${detail_address && `, ${detail_address}`}`}</Text>
            </HStack>
          </VStack>
        </VStack>
      </VStack>
      <Box>
        <Tab to={'/company/manage/subscription'} logo={'📝'} text={'현재 구독 중인 플랜'} />
        <Tab to={'/company/manage/subscription/contract'} logo={'📄'} text={'계약서 조회'} />
      </Box>
    </GItem>
  );
};

export default CompanySection;

import { Box, Flex, useMediaQuery } from '@chakra-ui/react';
import React, { useContext, useMemo } from 'react';
import Sidebar from '../../../components/common/Sidebar';
import HeaderNavBar from '../HeadNavBar';
import { Outlet, useLocation, matchPath } from 'react-router-dom';
import useMdDetect from '../../../lib/common/hooks/useMdDetect';
import { useGetUserType } from '../../../lib/common/hooks/query';
import { useUserTokenVerify } from '../../../lib/common/hooks/query';
import Loading from '../../../components/common/Loading';
import { auth, useLogout } from '../../../lib/common/utils/firebase/init';
import { useAuthState } from 'react-firebase-hooks/auth';
import { HeadNavBarLayout } from '../HeadNavBar/HeadNavBarLayout';

export type SubTitleInfosType = Array<{
  subTitle: string;
  router_name: string;
}>;

const Mainlayout: React.FC = () => {
  const { pathname } = useLocation();
  const { data } = useGetUserType();

  // 헤더 메인 타이틀
  console.log('this is main Lay out');

  const { company_type: userType } = data;

  return (
    <Box minH={'100vh'} h="100%">
      <Sidebar />
      <Box pl={'0px'}>
        <Box pl={'210px'}>
          <Box p={'28px'}>
            <HeadNavBarLayout />
            <Box id="here" w="full" minH={userType === 'admin' ? 'calc(100vh - 168px)' : 'calc(100vh - 128px)'}>
              <Outlet />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Mainlayout;

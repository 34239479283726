import { Box, Center, Table, Tbody, Td, Text, Th, Thead, Tooltip, Tr } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import QueryParamsLayout from '../../../../../../layouts/common/main/QueryParamsLayout';
import { useGetAllAdminAnnouncements } from '../../../../../../lib/admin/hooks/query';
import {
  announcementTypeTrans,
  companySelectTypeTrans,
  progressStatusTrans,
  userSelectTypeTrans,
} from '../../../../../../lib/common/utils/utils';
import { currentParamsObject, TextWithBool, ToAllFilledObj } from '../../../../../../lib/common/utils/base';
import { datedetaildotformat_mm } from '../../../../../../lib/common/utils/dateformatter';
import { FaPlusInCircle } from '../../../../../../statics/common/icons';
import AddNewRoomBtn from '../../../../../../components/common/Button/AddNewRoomButton';
import Loading from '../../../../../../components/common/Loading';

const Announcements = () => {
  const { search: srch } = location;
  const _params = currentParamsObject();
  const params = ToAllFilledObj(_params);

  const res = useGetAllAdminAnnouncements({
    axiosConfig: {
      params,
    },
  }) as any;

  const navigate = useNavigate();

  const handleClickUploadAnnouncementCircle = () => {
    return navigate('/admin/manage/announcements/main/post/announcement');
  };
  const { isLoading } = res;
  if (isLoading) return <Loading />;
  return (
    <QueryParamsLayout
      res={res}
      modalOnLayout={
        <Box pos={'absolute'} right={0} bottom={0}>
          <Tooltip label={'공지 등록하기'} fontSize={'14px'} defaultIsOpen hasArrow placement={'top'}>
            <Box>
              <FaPlusInCircle onClick={handleClickUploadAnnouncementCircle} />
            </Box>
          </Tooltip>
        </Box>
      }
    >
      <Table variant="head_silverbg_toplining">
        <Thead>
          <Tr>
            <Th>종류</Th>
            <Th>공지대상</Th>
            <Th>제목</Th>
            <Th>작성자</Th>
            <Th>등록일시</Th>
            <Th>업데이트일시</Th>
            <Th>상태</Th>
          </Tr>
        </Thead>
        <Tbody>
          {res.data?.result.map((v: any, index: number) => {
            console.log(v.title);

            return (
              <Tr
                key={srch + index}
                _hover={{
                  bgColor: 'blackAlpha.50',
                }}
                transition={'linear 0.18s'}
                cursor={'pointer'}
                onClick={(e) => {
                  navigate(`detail/${v._id}`);
                }}
              >
                <Td>
                  <Center>
                    <Text>{announcementTypeTrans(v.announcement_type)}</Text>
                    <TextWithBool value={!v.is_fixed} shownAs={'(고정)'} />
                  </Center>
                </Td>
                <Td>
                  <Center>
                    <Text>{companySelectTypeTrans(v.readable_company_type)}</Text>
                    <Text>/</Text>
                    <Text>{userSelectTypeTrans(v.readable_user_type)}</Text>
                  </Center>
                </Td>
                <Td>
                  <TextWithBool value={v.title} shownAs={'제목없음'}>
                    {v.title}
                  </TextWithBool>
                </Td>
                <Td>
                  <Center>
                    <Text>{v.user.user_name}</Text>
                    <Text>({v.user.company_name})</Text>
                  </Center>
                </Td>
                <Td>{datedetaildotformat_mm(v.createdAt)}</Td>
                <Td>{datedetaildotformat_mm(v.updatedAt)}</Td>
                <Td>{progressStatusTrans(v.status)}</Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </QueryParamsLayout>
  );
};
export default Announcements;

import { Flex, Center, Collapse, HStack, Box, ListItem, UnorderedList, Text, Table, Tbody, Tr } from '@chakra-ui/react';
import { FieldArray, useFormikContext } from 'formik';
import _ from 'lodash';
import { Fragment } from 'react';
import Dropzone from 'react-dropzone';
import { titleProperties } from '../type';
import DropzoneOpacityFormikThumb, { FileInfosProps } from '../../../../Dropzone/DropzoneOpacityFormikThumb';
import MultiFilesCommonDropzone from '../../../../Dropzone/MultiFilesCommonDropzone';
import FacilityThumb from '../../../../Thumb/FacilityThumb';
import { TdFullWContainer } from '../../../../Table/Tr';
import { DeleteCircle } from '../../../../../../statics/common/icons';
import RegisterFacilityInfoModal from '../../../RegisterFacilityInfoModal';

interface UploadFacilityDropZoneContainerProps {
  content?: any;
}

export const UploadFacilityDropZoneContainer: React.FC<UploadFacilityDropZoneContainerProps> = ({ content }) => {
  return (
    <Flex flexDir={'column'} w={'full'} alignItems={'stretch'}>
      <Box w={'100%'} textAlign={'start'} flexGrow={1} {...titleProperties}>
        공용시설 등록
      </Box>
      {content ? (
        content
      ) : (
        <>
          <Box w="full" borderBottomWidth={'1px'} borderBottomColor={'gray'} />
          <UnorderedList w={'100%'} textAlign={'start'} flexGrow={1} pl={2} my={4}>
            <ListItem>시설별로 이름과 사진을 함께 등록해주세요.</ListItem>
          </UnorderedList>
        </>
      )}
      <UploadFacilityDropZone />
      {/* 다음 */}
    </Flex>
  );
};
export const UploadFacilityDropZone = () => {
  const formik = useFormikContext<any>();
  const props = formik;
  const filtered_facilities = props.values.facilities.filter((facility: any) => !facility.removed);
  const text = filtered_facilities.length ? `+ ${filtered_facilities.length}개의 시설 등록` : '+ 시설 등록';
  return (
    <Flex flexDir={'column'} w={'full'}>
      <RegisterFacilityInfoModal is_fileInfosProps>
        <Center w={'full'}>
          <Center
            bgColor={'lotionWhite'}
            w={'full'}
            h={'160px'}
            borderRadius={'2px'}
            borderWidth={'1px'}
            borderColor={'silverGray'}
            cursor="pointer"
            fontWeight={'medium'}
            _hover={{ bgColor: 'zetaBlue', color: 'white' }}
            transition={'0.18s linear'}
          >
            <Center
              fontSize={'24px'}
              fontWeight={'bold'}
              bgColor={'zetaBlue'}
              color="white"
              w={'max'}
              borderRadius={'md'}
              px={8}
              py={2}
            >
              {text}
            </Center>
          </Center>
        </Center>
      </RegisterFacilityInfoModal>
      <Table w={'100%'} variant="simple" mt={2}>
        <Tbody w={'100%'}>
          <FieldArray name="facilities">
            {(arrayprops) => {
              return props.values.facilities.map(
                (
                  facility: {
                    name: string;
                    removed: boolean;
                    file_infos: FileInfosProps[];
                  },
                  index: number,
                ) => {
                  return (
                    <Tr w={'100%'} key={index} opacity={facility.removed ? 0.4 : 1}>
                      <TdFullWContainer
                        title={
                          <Flex align={'center'}>
                            <DeleteCircle
                              mr={1}
                              layerStyle={'pointer'}
                              boxSize={4}
                              onClick={(e) => {
                                props.setFieldValue(
                                  `facilities[${index}].removed`,
                                  !props.values.facilities[index].removed,
                                );
                              }}
                            />
                            <Text>{facility.name}</Text>
                          </Flex>
                        }
                        ContentTdProps={{ colSpan: 4 }}
                      >
                        <Box>
                          <Flex align={'center'}>
                            <Box mr={4}>
                              <MultiFilesCommonDropzone
                                funcInSuccessProcess={async (files) => {
                                  if (_.isEmpty(files)) {
                                    //
                                  } else {
                                    props.setFieldValue(`facilities[${index}].file_infos`, [
                                      ...props.values.facilities[index].file_infos,
                                      ...files.map((url) => ({
                                        url,
                                        removed: false,
                                      })),
                                    ]);
                                  }
                                }}
                                customVariant={'plus'}
                              />
                            </Box>
                            <Box>파일 1개당 첨부용량 30MB, 파일 업로드 문서당 최대 10개 가능</Box>
                          </Flex>
                          {!_.isEmpty(facility.file_infos) && (
                            <Box mt={2}>
                              <DropzoneOpacityFormikThumb
                                file_infos={facility.file_infos}
                                // onDeleteBtn={isEditable}
                                formikDeleteKey={`facilities[${index}]`}
                                onDeleteBtn={true}
                              />
                            </Box>
                          )}
                        </Box>
                      </TdFullWContainer>
                    </Tr>
                  );
                },
              );
            }}
          </FieldArray>
        </Tbody>
      </Table>
    </Flex>
  );
};

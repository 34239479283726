import { Box, Flex, Tag, TagProps, Text, Tooltip } from '@chakra-ui/react';
import { AccessStatusEnum } from '../../../lib/common/utils/axios/types';

interface AccessHiddenTagProps {
  access_status?: AccessStatusEnum;
}

export const AccessHiddenTag: React.FC<AccessHiddenTagProps> = ({ children, access_status, ...props }) => {
  return (
    <Tooltip
      label={
        <Flex direction={'column'}>
          <Box>
            매물 공개가
            <Text color="primaryRed" display={'inline'}>
              &nbsp;숨김&nbsp;
            </Text>
            상태인 매물입니다.
          </Box>
          <Box>수정하시려면 매물 클릭 후 상세 정보에서 수정해 주세요.</Box>
        </Flex>
      }
      isDisabled={access_status === 'open'}
      placement="right"
      hasArrow
    >
      <Box>
        {access_status === 'hidden' && (
          <Tag variant={'access_hidden'} px={2}>
            숨김
          </Tag>
        )}
      </Box>
    </Tooltip>
  );
};

import { Center, HStack, Link, Spinner, Text, VStack } from '@chakra-ui/react';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import { Container } from '.';
import { room_new_request_path } from '../../../../statics/agent/data/KeyPath';
import { fetchClient } from '../../../../lib/common/utils/axios';
import { BaseResponse, RoomNewRequest } from '../../../../lib/common/utils/axios/types';
import { ClickIconContainer, LeftArrowIcon, RightArrowIcon } from '../../../../statics/common/icons';
import NewRequestContainer from '../../../../layouts/agent/CustomRoomRequest/new';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../../../lib/common/utils/firebase/init';

const RequestsSection: React.FC = () => {
  const [user, loading, error] = useAuthState(auth);
  const initialActivate = 'newRooms';
  const [activate, setActivate] = useState(initialActivate);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const handleUpperClicked = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const currentTarget = e.currentTarget.id;
    setActivate(currentTarget);
  };

  const { data: newRequests, refetch } = useQuery(
    [room_new_request_path, currentPage],
    async () => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      const value = await instance.get<BaseResponse<RoomNewRequest[]>>(room_new_request_path, {
        params: { page: currentPage, limit: 5 },
      });
      return value?.data;
    },
    {
      retry: false,
    },
  );

  return (
    <Container w={'100%'} mb={4} h={'368px'} boxShadow={'0px 0px 20px 0px #DDDDDD'}>
      <VStack spacing={4} alignItems={'unset'} flexBasis={'100%'}>
        <HStack alignItems={'flex-end'}>
          <Text fontWeight={'bold'} fontSize={'20px'}>{`신규 매물 요청(${3})`}</Text>
          <HStack flex={1} align="right" spacing={4} alignItems={'center'} justifyContent={'flex-end'}>
            <Link
              to={'/agency/manage/requests'}
              as={RouterLink}
              cursor={'pointer'}
              color={'zetaBlue'}
              textDecor={'underline'}
              transition="transform ease 0.2s"
              _hover={{ color: 'highGray' }}
              _focus={{}}
              fontWeight={'medium'}
            >
              전체 요청 보기
            </Link>
            <ClickIconContainer
              clickableArea={7}
              color="zetaBlue"
              w={6}
              h={6}
              onClick={() => {
                setCurrentPage((prev) => {
                  if (prev < 2) {
                    return prev;
                  }
                  return prev - 1;
                });
              }}
            >
              <LeftArrowIcon />
            </ClickIconContainer>
            <Text fontSize={'16px'} cursor={'default'} fontWeight={'medium'}>
              {currentPage}
            </Text>
            <ClickIconContainer
              clickableArea={7}
              color="zetaBlue"
              w={6}
              h={6}
              onClick={() => {
                setCurrentPage((prev) => prev + 1);
              }}
            >
              <RightArrowIcon />
            </ClickIconContainer>
          </HStack>
        </HStack>
        {newRequests ? (
          <VStack alignItems={'unset'}>
            {newRequests?.data?.map((request: any) => {
              const { custom_room_request_id } = request;
              return <NewRequestContainer key={custom_room_request_id} item={request} addLikeBtn addProcessBtns />;
            })}
          </VStack>
        ) : (
          <Center h={'full'}>
            <Spinner color="zetaBlue" />
          </Center>
        )}
      </VStack>
    </Container>
  );
};

export default RequestsSection;

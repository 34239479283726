import { Box, Button, Center, Flex, Input, Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/react';
import { useFormik } from 'formik';
import { CustomInput } from '../../Input';
import * as Type from '../../../../types/common/CommonType';
import { useCreateOtherRoom } from '../../../../lib/common/hooks/mutation';

const initialValues = {
  url: '',
};

interface Props {
  isOpen: boolean;
  onClose: Type.FuntionType;
}

export const OtherRoomCreatorModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const { mutateAsync: createOtherRoom } = useCreateOtherRoom();
  const formik = useFormik({
    initialValues,
    onSubmit: async (values: any, formikHelpers) => {
      await createOtherRoom({ url: values?.url });
      formikHelpers.resetForm();
      await onClose();
    },
  });
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="xl">
      <ModalContent>
        <ModalBody>
          <Center>
            <Input
              flexGrow={1}
              padding={'4px'}
              margin={'4px'}
              onChange={(e) => {
                formik.setFieldValue('url', e.target.value);
              }}
              value={formik.values.url}
              placeholder="매물 상세 페이지"
            ></Input>
            <Box>
              {' '}
              <Button
                width={'64px'}
                flexGrow={1}
                padding={'4px'}
                margin={'4px'}
                variant="basic"
                onClick={formik.submitForm}
              >
                등록
              </Button>
            </Box>
          </Center>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

import { Grid, GridItem } from '@chakra-ui/react';
import RoomRequestDetail from '../../../../layouts/common/RoomRequestDetail/AfterSuggestion';
import ChatList from './ChatList';
import ChatRoom from './ChatRoom';

const ChatPage = () => {
  return (
    <Grid templateColumns="repeat(12, 1fr)" gap={2} h="100%">
      {/* Chat List */}
      <GridItem colSpan={3} w="100%" h="100%">
        <ChatList />
      </GridItem>
      {/* Chat Room */}
      <GridItem colSpan={5} w="100%" h="100%" border={'1px solid'} borderColor={'silverGray'} borderRadius={'0.3rem'}>
        {/* <GridItem w="100%" h="100%" bg="blue.500"> */}
        <ChatRoom />
      </GridItem>
      {/* Request Form */}
      <GridItem colSpan={4} w="100%" h="100%" border={'1px solid'} borderColor={'silverGray'} borderRadius={'0.3rem'}>
        <RoomRequestDetail />
      </GridItem>
    </Grid>
  );
};

export default ChatPage;

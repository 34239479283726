import {
  Modal,
  ModalOverlay,
  ModalContent,
  Center,
  Box,
  VStack,
  HStack,
  Button,
  StackDivider,
  Table,
  Tbody,
  Thead,
  Tr,
  ModalHeader,
  Textarea,
  Checkbox,
  Flex,
  Collapse,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { Field, Form, Formik } from 'formik';
import { ModalDisclosureProps } from '../../common/Modals/base';
import { threeCommafy } from '../../../lib/common/utils/digitformatter';
import SelectBox from '../../agent/SelectBox/base';
import { CustomInput } from '../../common/Input';
import { WTd } from '../../common/Table/Td';
import { WTh } from '../../common/Table/Th';
import { TdFullWContainer } from '../../common/Table/Tr';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { FileInput } from '../../../pages/common/SignUp/Agency/sections/sections/FileInput';
import Dropzone from 'react-dropzone';
import Thumb from '../../common/Thumb/base';
import { AddIcon } from '@chakra-ui/icons';
import axios from 'axios';
import { datedotformat } from '../../../lib/common/utils/dateformatter';

const dummy_complaints = [
  {
    _id: '2',
    title: '물이 잘 안내려가요',
    content: '비번 입력하는데 아무 반응이  없어요. 확인해주세요',
    user_name: '김철수',
    complaint_date: new Date('2021-9-20'),
    final_admin_dealwith: '전희재',
    // final_date_dealwith: new Date('2021-9-23'),
  },
  {
    _id: '1',
    title: '방문 예정',
    content: '내일 10시에 방문해서 확인해드리겠습니다.',
    user_name: '전희재',
    complaint_date: new Date('2021-9-10'),
    final_admin_dealwith: '전희재',
    final_date_dealwith: new Date('2021-9-12'),
  },
];

const ComplaintRequestForm: React.FC<ModalDisclosureProps> = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside" size={'6xl'} motionPreset="slideInRight">
      <ModalOverlay />
      <ModalContent p={5} overflowY={'auto'}>
        <ModalHeader fontSize={'24px'} color="zetaBlue" px={0}>
          민원 요청
        </ModalHeader>
        <Scrollbars style={{ height: '800px' }} autoHide universal>
          <VStack justifyContent={'start'} w={'100%'} spacing={6}>
            {/* 임대인 정보 */}
            <VStack spacing={3} justifyContent={'start'} w={'100%'}>
              <Box w={'full'} fontWeight={'bold'} fontSize={'18px'} textAlign={'start'}>
                요청 정보
              </Box>
              <Table w={'100%'} variant="simple">
                <Tbody w={'100%'}>
                  <Tr w={'100%'} borderTopWidth={'1px'} borderTopColor={'gainGray'}>
                    <TdFullWContainer title={'요청 번호'}>
                      <HStack>
                        <Box>{''}</Box>
                      </HStack>
                    </TdFullWContainer>
                    <TdFullWContainer title={'요청 상태'}>
                      <HStack>
                        <Box>{''}</Box>
                      </HStack>
                    </TdFullWContainer>
                  </Tr>
                  <Tr>
                    <TdFullWContainer title={'요청자'}>
                      <Box>{''}</Box>
                    </TdFullWContainer>
                    <TdFullWContainer title={'요청자 계정'}>
                      <Box>{''}</Box>
                    </TdFullWContainer>
                  </Tr>
                  <Tr>
                    <TdFullWContainer title={'요청 생성일'}>
                      <Box>{''}</Box>
                    </TdFullWContainer>
                    <TdFullWContainer title={'최종 처리일'}>
                      <Box>{''}</Box>
                    </TdFullWContainer>
                  </Tr>
                </Tbody>
              </Table>
            </VStack>
            {/* 요청 및 지원 내용 */}
            <VStack spacing={3} justifyContent={'start'} w={'100%'}>
              <Box w={'full'} fontWeight={'bold'} fontSize={'18px'} textAlign={'start'}>
                요청 및 지원 내용
              </Box>
              {/* 고객사 승인 내역 */}

              <Table variant="simple">
                <Thead bgColor={'#F8F8F8'} borderColor={'gray.100'}>
                  <Tr>
                    <WTh borderR>등록자</WTh>
                    <WTh borderR>등록 일시</WTh>
                    <WTh borderR>제목</WTh>
                    <WTh>내용</WTh>
                    <WTh>수정</WTh>
                  </Tr>
                </Thead>
                <Tbody>
                  {/* format(date, "yy-MM-dd") */}
                  {dummy_complaints.map((complaint, index) => (
                    <Tr key={index}>
                      <WTd borderR>{complaint.user_name}</WTd>
                      <WTd borderR>{datedotformat(complaint.complaint_date)}</WTd>

                      <WTd borderR>{complaint.title}</WTd>
                      <WTd borderR>{complaint.content} + 사진도 추가</WTd>
                      <WTd>
                        <Button variant={'basic'}>수정</Button>
                      </WTd>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </VStack>
            {/* 답변 등록하기 */}
            <VStack spacing={3} justifyContent={'start'} w={'100%'}>
              <Box w={'full'} fontWeight={'bold'} fontSize={'18px'} textAlign={'start'}>
                답변 등록하기
              </Box>
              <Formik
                initialValues={{
                  title: '',
                  content: '',
                  files: [],
                }}
                onSubmit={async (values, { setSubmitting }) => {
                  console.log(values);
                  setSubmitting(false);
                }}
              >
                {({ handleChange, values, handleSubmit, setFieldValue }) => (
                  <Form style={{ display: 'flex', width: '100%' }}>
                    <VStack w={'100%'} mx={'40px'} spacing={6}>
                      <HStack w={'100%'}>
                        <Box fontWeight={'medium'} minW={'80px'}>
                          제목
                        </Box>
                        <Field
                          name="title"
                          as={CustomInput}
                          autoFocus
                          placeholder="제목을 입력해 주세요."
                          onChange={handleChange}
                        />
                      </HStack>
                      <HStack w={'100%'}>
                        <Box fontWeight={'medium'} minW={'80px'}>
                          내용
                        </Box>
                        <Field
                          name="content"
                          as={Textarea}
                          fontSize="14px"
                          px={'12px'}
                          bgColor="lotionWhite"
                          boxShadow={'sm'}
                          borderColor="silverGray"
                          borderRadius={'md'}
                          placeholder="내용을 입력해 주세요."
                          h={'140px'}
                          onChange={handleChange}
                        />
                      </HStack>
                      <HStack w={'100%'}>
                        <Box fontWeight={'medium'} minW={'80px'}>
                          첨부 파일
                        </Box>
                        {/* <FileInput id="pictures" name="pictures" handleFormikChange={handleChange}>
                          첨부 파일
                        </FileInput> */}
                        <Dropzone
                          accept={'image/*'}
                          onDrop={(acceptedFiles) => {
                            if (acceptedFiles.length === 0) {
                              return;
                            } else {
                              setFieldValue('files', (values.files as any).concat(acceptedFiles));
                            }
                          }}
                        >
                          {({ isDragActive, isDragReject, acceptedFiles, getRootProps, getInputProps }) => {
                            // if (isDragActive) {
                            // 팝업으로 에러 처리.
                            //   return <div>'This file is authorized'</div>;
                            // }

                            // if (isDragReject) {
                            //   return <div>'This file is not authorized'</div>;
                            // }

                            // if (values.files.length === 0) {
                            //   return <p>Try dragging a file here!</p>;
                            // }
                            return (
                              <Flex flexDir={'column'} w={'full'}>
                                <Center {...getRootProps()} w={'full'}>
                                  <input {...getInputProps()} />
                                  <Center
                                    bgColor={'lotionWhite'}
                                    w={'full'}
                                    h={'120px'}
                                    borderRadius={'5px'}
                                    borderWidth={'1px'}
                                    borderColor={'silverGray'}
                                    cursor="pointer"
                                    fontWeight={'medium'}
                                    _hover={{ bgColor: 'zetaBlue', color: 'white' }}
                                    transition={'0.18s linear'}
                                  >
                                    <Box>첨부 파일을 추가해주세요.</Box>
                                  </Center>
                                </Center>
                                <Collapse style={{ width: '100%' }} in={values.files.length !== 0}>
                                  <HStack mt={6}>
                                    {values.files.map((file, i) => (
                                      <Thumb key={i} file={file} />
                                    ))}
                                  </HStack>
                                </Collapse>
                              </Flex>
                            );
                          }}
                        </Dropzone>
                      </HStack>
                      <HStack w={'100%'}>
                        <Box fontWeight={'medium'} minW={'80px'}>
                          상태 변경
                        </Box>
                        <HStack justifyContent={'space-between'} w={'full'}>
                          <HStack>
                            <Checkbox defaultChecked>진행중</Checkbox>
                            <Checkbox defaultChecked>처리 완료</Checkbox>
                          </HStack>
                          <Button variant={'basic'} type="submit">
                            등록 하기
                          </Button>
                        </HStack>
                      </HStack>
                    </VStack>
                  </Form>
                )}
              </Formik>
            </VStack>
          </VStack>
        </Scrollbars>
      </ModalContent>
    </Modal>
  );
};

export default ComplaintRequestForm;

import {
  Box,
  Button,
  Center,
  Checkbox,
  Collapse,
  Flex,
  HStack,
  Image,
  StackDivider,
  Tag,
  Table,
  TableCaption,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Tooltip,
  useDisclosure,
  VStack,
  Wrap,
} from '@chakra-ui/react';
import { isUndefined } from '@chakra-ui/utils';
import { isEmptyArray } from 'formik';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import RoomSuggestSender from '../../../components/agent/Modals/RoomSuggestSender';
import TextLink from '../../../components/common/Link/TextLink';
import { pathOfUserDetailPage } from '../../../lib/common/utils/utils';
import { BuildingRoom, BuildingRoomTemplate, Room, TenantStatus } from '../../../lib/common/utils/axios/types';
import { truncateLength } from '../../../lib/common/utils/base';
import { buildingFormTypeTrans } from '../../../lib/subscriber/utils/utils';
import { staticThreeCommafy } from '../../../lib/common/utils/digitformatter';
import { ClickIconContainer, LeftArrowIcon, RightArrowIcon } from '../../../statics/common/icons';
import { AccessHiddenTag } from '../../../components/common/Tag';
import * as Util from '../../../lib/common/utils/Util';
import * as Type from '../../../types/common/CommonType';

interface RoomInfoContainerProps {
  minifyMemo?: boolean;
  minifyActionBtns?: boolean;
  roomsData?: Room[] | undefined;
  buildingsroomsData?: Type.Building[];
  pageNum?: number;
  setPageNum?: React.Dispatch<React.SetStateAction<number>>;
}

const RoomInfoContainer: React.FC<RoomInfoContainerProps> = ({
  minifyMemo = false,
  minifyActionBtns = false,
  buildingsroomsData,
  pageNum,
  setPageNum,
}) => {
  const roomsData = buildingsroomsData;
  const { isOpen: isSOpen, onOpen: onSOpen, onClose: onSClose } = useDisclosure();
  const location = useLocation();
  const routeUserType = Util.getRouteUserTypeFromUrl(location.pathname);
  const navigate = useNavigate();
  const moveToDetail = (id: string) => () => {
    navigate(`/${routeUserType}/manage/roomInfo/${id}`);
  };

  const initialCheckedItems = Array(roomsData?.length).fill(false);
  const [checkedItems, setCheckedItems] = useState(initialCheckedItems);
  const allChecked = checkedItems.every(Boolean);
  const isIndeterminate = checkedItems.some(Boolean) && !allChecked;
  const saveIsDisabled = !checkedItems.some(Boolean);
  const [checkedItemsInDetail, setCheckedItemsInDetail] = useState<Array<any>>([]);

  const agency_all_rooms = roomsData?.map((room, index) => Object.assign(room, { indexing: index }));

  const [sequence, setSequence] = useState<number>(0);
  return (
    <Flex flexDir={'column'} w={'100%'}>
      <Box h={'60vh'}>
        {/* <Scrollbars> */}
        <Table variant="all_line_silver">
          {minifyActionBtns && (
            <TableCaption textAlign={'end'} placement="top" borderBottom={'unset'} m={0}>
              <Flex justifyContent={'space-between'} ml={'65px'}>
                <Center>
                  <Collapse style={{ width: '100%' }} in={checkedItemsInDetail.length !== 0}>
                    <HStack>
                      {checkedItemsInDetail.map(
                        (item, index) =>
                          item.thumbnail && (
                            <VStack key={index}>
                              <Image
                                boxSize={'80px'}
                                src={item.thumbnail}
                                alt={'room-thumnail'}
                                _hover={{ opacity: 0.6 }}
                                transition={'0.18s linear'}
                                cursor={'pointer'}
                                onClick={() => {
                                  setCheckedItemsInDetail((prevItems) => {
                                    const newArray = [...prevItems];
                                    const newArray_filtered = newArray.filter(
                                      (value) => value.sequence !== (item as any).sequence,
                                    );
                                    return newArray_filtered;
                                  });
                                  setCheckedItems((prevItems) => {
                                    const newArray = [...prevItems];
                                    newArray[item.indexing] = !newArray[item.indexing];
                                    return newArray;
                                  });
                                }}
                              />
                            </VStack>
                          ),
                      )}
                    </HStack>
                  </Collapse>
                </Center>
                <Center>
                  <Button
                    variant={saveIsDisabled ? 'filter' : 'basic'}
                    h={'34px'}
                    isDisabled={saveIsDisabled ? true : false}
                    onClick={onSOpen}
                  >
                    제안하기
                  </Button>
                  <Button ml={2} variant={'basic'}>
                    요청 거절하기
                  </Button>
                </Center>
              </Flex>
            </TableCaption>
          )}

          <Thead>
            {!minifyActionBtns && (
              <Tr>
                <Th w={'20px'}>
                  <Center>
                    <Checkbox
                      isChecked={allChecked}
                      isIndeterminate={isIndeterminate}
                      onChange={(e) => setCheckedItems(Array(agency_all_rooms?.length).fill(e.target.checked))}
                    />
                  </Center>
                </Th>
                <Th>
                  <HStack divider={<StackDivider borderColor="rgb(235, 240, 246)" />}>
                    <Flex flexGrow={1} justifyContent={'end'}></Flex>
                  </HStack>
                </Th>
                <Th>관리자 메모</Th>
                <Th>제안 내역</Th>
              </Tr>
            )}
          </Thead>
          <Tbody borderBottomRadius={'3xl'}>
            {agency_all_rooms?.map((building, index) => {
              if (isUndefined(building?.room?._id)) {
                return null;
              }

              return (
                <Tr key={building?.room?._id} bgColor={checkedItems[index] ? '#ECF1FF' : 'unset'}>
                  <Td>
                    <Center>
                      <Checkbox
                        isChecked={checkedItems[index]}
                        onChange={(_e) => {
                          setCheckedItemsInDetail((prevItems) => {
                            const newArray = [...prevItems];
                            if (!checkedItems[index]) {
                              Object.assign(building, { sequence });
                              setSequence((prev) => prev + 1);
                              newArray.push(building);
                              return newArray;
                            } else {
                              const newArray_filtered = newArray.filter(
                                (value) => value.sequence !== (building as any).sequence,
                              );
                              return newArray_filtered;
                            }
                          });
                          setCheckedItems((prevItems: any) => {
                            const newArray = [...prevItems];
                            newArray[index] = !newArray[index];
                            return newArray;
                          });
                        }}
                      />
                    </Center>
                  </Td>
                  <Td minW={'500px'} w="500px" p={1}>
                    <HStack spacing={4} justifyContent={'space-between'} w="full">
                      <HStack
                        spacing={2}
                        flexGrow={1}
                        onClick={moveToDetail(building?.room?._id || '')}
                        cursor="pointer"
                        _hover={{
                          opacity: 0.7,
                        }}
                      >
                        <Image
                          boxSize={'117px'}
                          src={
                            !isEmptyArray(building?.room?.pictures)
                              ? building?.room?.pictures?.[0]
                              : 'https://via.placeholder.com/117'
                          }
                          alt="이미지가 없습니다"
                        />
                        <VStack alignItems={'unset'} flexDir={'column'}>
                          <Wrap fontSize={'11px'} minW={'max'}>
                            <Tag variant={'room_number'}>매물번호 {building?.room?.code}</Tag>
                            {building?.room?.registrant_type == 'agent' && <Tag>중개사</Tag>}

                            {building?.room?.room_type && (
                              <Tag variant={'room_number'}>{truncateLength(building?.room?.room_type, 10)}</Tag>
                            )}
                            {building?.room?.is_template && <Tag variant={'green'}>템플릿</Tag>}
                            <Tag variant={building?.room?.is_move_in ? 'current_empty' : 'current_filled'} px={2}>
                              {building?.room?.is_move_in ? '현재 공실' : '거래 완료'}
                            </Tag>
                            <AccessHiddenTag access_status={building?.room?.access_status} />
                          </Wrap>
                          <Flex w={'100%'} fontSize={'18px'} fontWeight={'medium'} flexDir={'column'}>
                            <Flex flexWrap={'wrap'}>
                              <Text minW={'max'}>{`${building?.address},`}&nbsp;</Text>
                              <Text>{building?.room?.dong && `${building?.room?.dong}동`}&nbsp; </Text>
                              <Text>{building?.room?.ho && `${building?.room?.ho}호`}&nbsp;</Text>
                              <Text>{`(${truncateLength(building?.name)}) `}</Text>
                            </Flex>
                          </Flex>
                          <HStack
                            w={'100%'}
                            flexWrap={'wrap'}
                            divider={<StackDivider borderColor={'gray.300'} />}
                            fontSize={'14px'}
                            spacing={2}
                          >
                            {building?.building_form && <Text>{buildingFormTypeTrans(building?.building_form)}</Text>}
                            {building?.room?.room_num && <Text>방{building?.room?.room_num}개</Text>}
                            {building?.room?.dedicated_area && <Text>{building?.room?.dedicated_area}m²</Text>}
                            {building?.room?.floor && <Text>{building?.room?.floor}층</Text>}
                            {building?.room?.deposit && (
                              <Text>보증금 {staticThreeCommafy(building?.room?.deposit)}원</Text>
                            )}
                            {building?.room?.rent && <Text>월세 {staticThreeCommafy(building?.room?.rent)}원</Text>}
                            {building?.room?.management_fee && (
                              <Text>관리비 {staticThreeCommafy(building?.room?.management_fee)}원</Text>
                            )}
                          </HStack>
                        </VStack>
                      </HStack>
                    </HStack>
                  </Td>
                  <Td maxW={'200px'} w={'150px'} fontSize={'14px'}>
                    {building?.room?.company_memo ? (
                      <Center maxW={'200px'}>{building?.room?.company_memo}</Center>
                    ) : (
                      <Center>
                        <Text color={'gray.400'}>메모 없음</Text>
                      </Center>
                    )}
                  </Td>
                  <Td maxW={'200px'} w={'150px'}>
                    <Flex flexWrap={'wrap'}>
                      {building?.room?.custom_room_requests?.map((request, index) => {
                        const status = request.status as TenantStatus;
                        const user_id = request.tenant_id;
                        const base = pathOfUserDetailPage(status);

                        return (
                          <TextLink to={`${base}${request.tenant_id}`} isExternal key={index}>
                            <Text mr={1}>{request.tenant_name}</Text>
                          </TextLink>
                        );
                      })}
                    </Flex>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Box>
      {pageNum && (
        <>
          <Flex justifyContent={'center'} w={'100%'} borderBottom={'1px solid'} borderBottomColor={'gray.400'} />
          <Center mt={'10px'}>
            <ClickIconContainer
              clickableArea={7}
              w={6}
              h={6}
              onClick={() => {
                if ((pageNum || 1) === 1) return;
                if (setPageNum) setPageNum((prev) => (prev || 1) - 1);
                console.log('previous', pageNum);
              }}
            >
              <LeftArrowIcon color="zetaBlue" />
            </ClickIconContainer>
            <Text mx={'20px'} textColor={'highGray'}>
              {`${pageNum}`}
            </Text>
            <ClickIconContainer
              clickableArea={7}
              color="zetaBlue"
              w={6}
              h={6}
              onClick={() => {
                if (setPageNum) setPageNum((prev) => Math.max((prev || 1) + 1, 1));
                console.log('next', pageNum);
              }}
            >
              <RightArrowIcon color="zetaBlue" />
            </ClickIconContainer>
          </Center>
        </>
      )}
      {/* 모달 */}
      <RoomSuggestSender isOpen={isSOpen} onClose={onSClose} />
    </Flex>
  );
};

export default RoomInfoContainer;

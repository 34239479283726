import { useToast } from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import { useMemo } from 'react';
import { DropzoneOptions, DropzoneState, useDropzone } from 'react-dropzone';
import { error } from '../../../../statics/common/data/toast';
import { acceptStyle, baseStyle, focusedStyle, rejectStyle } from '../../../../theme/common/Dropzone/style';

/** return [useDropzone, style] */
export const useDropzoneOneFileUpload = (
  funcInSuccessProcess: (file: File) => void,
  customVariant = 'basic',
  dropzoneOptions?: DropzoneOptions,
) => {
  const toast = useToast();
  const dropzone = useDropzone({
    accept: ['image/jpeg', 'image/jpg'],
    maxFiles: 1,
    onDrop: (acceptedFiles, fileRejections, event) => {
      if (!isEmpty(fileRejections)) {
        const err = fileRejections[0].errors[0];
        if (err.code === 'too-many-files') {
          toast({ ...error, description: '한 번에 한 장의 사진만 처리 가능합니다.' });
        }
      } else {
        funcInSuccessProcess(acceptedFiles[0]);
      }
    },
    ...dropzoneOptions,
  });
  const style = useDropzoneStyleMemo(dropzone, customVariant);

  return [dropzone, style];
};

/** return [useDropzone, style] */
export const useDropzoneMultiFilesUpload = (
  funcInSuccessProcess: (files: Array<File>) => void,
  customVariant = 'basic',
  dropzoneOptions?: DropzoneOptions,
) => {
  const toast = useToast();
  const dropzone = useDropzone({
    accept: ['image/jpeg', 'image/jpg', 'image/png'],
    onDrop: (acceptedFiles, fileRejections, event) => {
      if (!isEmpty(fileRejections)) {
        //
      } else {
        if (acceptedFiles.length < 1) {
          toast({ ...error, description: '한 장 이상의 사진을 업로드 해주세요.' });
        } else {
          funcInSuccessProcess(acceptedFiles);
        }
      }
    },
    ...dropzoneOptions,
  });
  const style = useDropzoneStyleMemo(dropzone, customVariant);

  return [dropzone, style];
};

export const useDropzoneStyleMemo = (dropzoneState: DropzoneState, customVariant = 'basic') => {
  const { isFocused, isDragAccept, isDragReject } = dropzoneState;
  return useMemo(
    () => ({
      ...baseStyle[customVariant],
      ...(isFocused ? focusedStyle[customVariant] : {}),
      ...(isDragAccept ? acceptStyle[customVariant] : {}),
      ...(isDragReject ? rejectStyle[customVariant] : {}),
    }),
    [isFocused, isDragAccept, isDragReject],
  ) as any;
};

import { HStack, Flex, Text, TextProps } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

interface InfoSectorProps extends TextProps {
  total: number;
}

const InfoSector: React.FC<InfoSectorProps> = ({ total, children, ...props }) => {
  return (
    <HStack w={'full'} justifyContent={'space-between'}>
      <Text fontSize={'16px'} fontWeight={'medium'} {...props}>
        {children}
      </Text>
      <Link to="test">
        <Flex>
          <Text fontSize={'16px'} color="mountainGreen" textDecorationLine={'underline'}>
            {total}
          </Text>
          <Text fontSize={'16px'}>건</Text>
        </Flex>
      </Link>
    </HStack>
  );
};

export default InfoSector;

import { Box, Center, Flex, StackProps, VStack } from '@chakra-ui/react';

interface ProgressItemProps extends StackProps {
  num: number;
  last?: boolean;
  step: number;
}

const ProgressItem: React.FC<ProgressItemProps> = ({ num, last, step, ...props }) => {
  const currentStep = num === step;

  // 여기부터! 클릭 제거

  return (
    <VStack
      id="1"
      spacing={4}
      w={'60px'}
      // cursor="pointer"
      // _hover={{ textColor: 'red' }}
      transition={'0.2s linear'}
      position="relative"
      {...props}
    >
      {/* current step */}
      {currentStep && (
        <Center
          w={'30px'}
          h={'30px'}
          bgColor={'zetaBlue'}
          color="white"
          borderRadius={'full'}
          fontSize={'13px'}
          // _hover={{ bgColor: 'rgb(45, 122, 196)' }}
          // transition={'0.2s linear'}
          pointerEvents={'none'}
          _before={
            !last
              ? {
                  content: "''",
                  position: 'absolute',
                  borderBottom: '1px solid',
                  borderColor: 'silverGray',
                  w: '30px',
                  left: '45px',
                }
              : {}
          }
        >
          {num}
        </Center>
      )}
      {/* not current step */}
      {!currentStep && (
        <Center
          w={'30px'}
          h={'30px'}
          bgColor={'white'}
          color="silverGray"
          borderRadius={'full'}
          border={'1px solid'}
          borderColor={'silverGray'}
          fontSize={'13px'}
          // _hover={{ bgColor: 'rgb(45, 122, 196)' }}
          // transition={'0.2s linear'}
          pointerEvents={'none'}
          _before={
            !last
              ? {
                  content: "''",
                  position: 'absolute',
                  borderBottom: '1px solid ',
                  borderColor: 'silverGray',
                  w: '30px',
                  left: '45px',
                }
              : {}
          }
        >
          {num}
        </Center>
      )}
    </VStack>
  );
};

interface SignUpLayoutProps {
  step: number;
}

const SignUpLayout: React.FC<SignUpLayoutProps> = ({ step, children }) => {
  //  step === 4 => complete
  return (
    <VStack alignItems={'unset'} spacing={6} h={'full'}>
      <Flex alignItems={'center'} justifyContent={'space-between'}>
        {/* Title */}
        {step !== 4 && (
          <Box fontSize={'25px'} fontWeight={'medium'}>
            회원가입
          </Box>
        )}
        {/* Progression */}
        {(step === 1 || step === 2 || step === 3) && (
          <Flex>
            <ProgressItem num={1} step={step} />
            <ProgressItem num={2} step={step} />
            <ProgressItem num={3} step={step} last />
          </Flex>
        )}
      </Flex>
      {/* Checkboxes */}
      {children}
    </VStack>
  );
};

export default SignUpLayout;

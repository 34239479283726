import { Box, Center, Flex, toast, useToast } from '@chakra-ui/react';
import Dropzone from 'react-dropzone';
import {
  useChangeSubscriptionContractStatus,
  useChangeLeaseContractArrayField,
} from '../../../lib/admin/hooks/mutation';
import { error } from '../../../statics/common/data/toast';
import * as S from '../../../theme/common/styles';

interface ContractFileStatusDropzoneProps {
  subscription_contract_id?: string;
  lease_contract_id?: string;
  contractType?: 'subscription' | 'lease';
  formDataKey: string;
  acceptOneFile?: boolean;
}

const ContractFileStatusDropzone: React.FC<ContractFileStatusDropzoneProps> = ({
  subscription_contract_id,
  lease_contract_id,
  formDataKey,
  acceptOneFile,
  contractType,
}) => {
  const { mutate: changeSubscriptionContractStatus } = useChangeSubscriptionContractStatus();
  const { mutate: changeLeaseContractArrayField } = useChangeLeaseContractArrayField();
  const toast = useToast();
  return (
    <Dropzone
      onDrop={(acceptedFiles) => {
        // if (acceptOneFile) {
        if (acceptedFiles.length === 0) {
          return;
        } else {
          // 한 장의 파일만 업로드 가능
          if (acceptOneFile) {
            if (acceptedFiles.length > 1) {
              toast({ ...error, title: '한 장의 파일만 업로드 가능합니다.', description: '' });
            } else {
              const formData = new FormData();
              formData.append(formDataKey, acceptedFiles[0]);
              if (contractType === 'subscription') {
                changeSubscriptionContractStatus({
                  subscription_contract_id: subscription_contract_id || '',
                  formData,
                });
              }
            }
            // 여러장 파일 업로드 가능
          } else {
            const formData = new FormData();
            acceptedFiles.map((file) => {
              formData.append(formDataKey, file);
            });
            if (contractType === 'lease') {
              // callback
              changeLeaseContractArrayField({
                lease_contract_id: lease_contract_id || '',
                field: 'lease_contract_pdfs',
                operation: 'push',
                formData,
              });
            }
          }
        }
      }}
    >
      {({ isDragActive, isDragReject, acceptedFiles, getRootProps, getInputProps }) => {
        return (
          <Flex flexDir={'column'} w={'full'}>
            <Center {...getRootProps()} w={'full'}>
              <input {...getInputProps()} />
              <Center sx={{ ...S.basicButton }}>
                <Box minW={'max'}>첨부 파일 추가</Box>
              </Center>
            </Center>
          </Flex>
        );
      }}
    </Dropzone>
  );
};

export default ContractFileStatusDropzone;

import { format, addDays, formatISO, lastDayOfMonth } from 'date-fns';
import { ko } from 'date-fns/esm/locale';

const toDoubleDigits = (num: number) => {
  if (num < 10) {
    return '0' + num.toString();
  } else {
    return num;
  }
};

/** yyyy.MM.dd */
export const datedotformat = (s: Date | string | undefined | null | number, calc = 0) => {
  return !s
    ? ''
    : format(
        typeof s === 'string' ? Date.parse(s) + calc : typeof s === 'number' ? s + calc : s.getTime() + calc,
        'yyyy.MM.dd',
      );
  // return !s ? '' : format(typeof s === 'string' ? Date.parse(s) : s, 'yyyy.MM.dd');
};

/** yyyy-MM-dd */
export const datedashformat = (s: Date | string | undefined | null, calc = 0) => {
  return !s
    ? ''
    : format(
        typeof s === 'string' ? Date.parse(s) + calc : typeof s === 'number' ? s + calc : s.getTime() + calc,
        'yyyy-MM-dd',
      );
};

/** 이번달의 첫번째와 마지막 일: return [firstDateOfMonth, lastDateOfMonth] */
export const firstAndlastDayThisMonth = () => {
  const today = new Date();
  const firstDateOfMonth = format(today, 'yyyy-MM-01');
  const lastDateOfMonth = format(lastDayOfMonth(today), 'yyyy-MM-dd');
  return [firstDateOfMonth, lastDateOfMonth];
};

export const dateyyyyMMformat = (s: Date | string | undefined | null, calc = 0) => {
  return !s
    ? ''
    : format(
        typeof s === 'string' ? Date.parse(s) + calc : typeof s === 'number' ? s + calc : s.getTime() + calc,
        'yyyy-MM',
      );
};

export const dateMMformat = (s: Date | string | undefined | null, calc = 0) => {
  return !s
    ? ''
    : format(
        typeof s === 'string' ? Date.parse(s) + calc : typeof s === 'number' ? s + calc : s.getTime() + calc,
        'MM',
      );
};

export const datedotkoformat = (s: Date | string | undefined | null, calc = 0) => {
  return !s
    ? ''
    : format(
        typeof s === 'string' ? Date.parse(s) + calc : typeof s === 'number' ? s + calc : s.getTime() + calc,
        'yyyy년 MM월 dd일',
      );
};

export const datedetaildotformat_mm = (s: Date | string | undefined | null, calc = 0) => {
  return !s
    ? ''
    : format(
        typeof s === 'string' ? Date.parse(s) + calc : typeof s === 'number' ? s + calc : s.getTime() + calc,
        'yyyy.MM.dd a hh:mm',
        { locale: ko },
      );
};

export const datedetaildotformat_ss = (s: Date | string | undefined | null | number, calc = 0) => {
  return !s
    ? ''
    : format(
        typeof s === 'string' ? Date.parse(s) + calc : typeof s === 'number' ? s + calc : s.getTime() + calc,
        'yyyy.MM.dd a hh:mm:ss',
        { locale: ko },
      );
};

export const datedetaildotkoformat = (s: Date | string | undefined | null, calc = 0) => {
  return !s
    ? ''
    : format(
        typeof s === 'string' ? Date.parse(s) + calc : typeof s === 'number' ? s + calc : s.getTime() + calc,
        'yyyy년 MM월 dd일 a hh:mm',
        {
          locale: ko,
        },
      );
};

export const date_ahhmm_format = (s: Date | string | undefined | null, calc = 0) => {
  return !s
    ? ''
    : format(
        typeof s === 'string' ? Date.parse(s) + calc : typeof s === 'number' ? s + calc : s.getTime() + calc,
        'a hh:mm',
        { locale: ko },
      );
};

export const dateformatter = (date: Date, sign: string): string => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return year + sign + toDoubleDigits(month) + sign + toDoubleDigits(day);
};

export const dateISOflatformat = (date: Date, removeZ = true) => {
  // const year = date.getFullYear();
  // const month = date.getMonth() + 1;
  // const day = date.getDate();
  // const formatter = formatISO(new Date(year, month, day));
  // if (removeZ) {
  //   console.log(formatter);

  //   return formatter.slice(0, -1);
  //   return formatter;
  // } else {
  //   return formatter;
  // }
  if (removeZ) {
    return dateformatter(date, '-') + 'T00:00';
  } else {
    return dateformatter(date, '-') + 'T00:00Z';
  }
};

export const fourtwotwoDashfy = (number: number) => {
  // const str = number.toString().split('.');

  // if (str[0].length >= 5) {
  //   str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
  // }
  // if (str[1] && str[1].length >= 5) {
  //   str[1] = str[1].replace(/(\d{3})/g, '$1 ');
  // }
  const str = number.toString();
  const text = str.replace(/^(?=(?:\d-?){10}$)\d+(?:-\d+){1,3}/, '$1-$2-$3');
  // const text = str.replace(/(\d{3})(\d{3})(\d{3})/, '$1-$2-$3');
  return text;
};

export const threetwofiveDashfy = (number: number | string) => {
  if (number === null || number === undefined) {
    return 0;
  }
  const str = number.toString();
  // 123-12-12345
  const text = str.replace(/^(\d{3})(\d{2})(\d{5})$/, '$1-$2-$3');

  return text;
};

import {
  Box,
  Button,
  Center,
  Collapse,
  Flex,
  HStack,
  Input,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Table,
  Tbody,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { Field, useFormikContext } from 'formik';
import { useEffect, useMemo } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import _ from 'lodash';
import MultiFilesCommonDropzone from '../../Dropzone/MultiFilesCommonDropzone';
import { CustomInput } from '../../Input';
import { TrContainer } from '../../Table/Tr';
import { bankNameTrans } from '../../../../lib/common/utils/base';
import { minmax_select, payment_method_select, payment_status_select } from '../../../../statics/common/data/selectbox';
import { CheckCircleIcon } from '../../../../statics/common/icons';
import SelectBox from '../../../agent/SelectBox/base';
import AddAccountInfosModal from '../AddAccountModal';
import { formikInitialValuesProps } from '../ConfirmContractModal';
import DropzoneOpacityFormikThumb from '../../Dropzone/DropzoneOpacityFormikThumb';

interface RegisterContractInfoModalProps {
  disabled?: boolean;
}

const RegisterContractInfoModal: React.FC<RegisterContractInfoModalProps> = ({ children, disabled = false }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const props = useFormikContext<formikInitialValuesProps>();

  const handleModalOpen = () => {
    if (!disabled) {
      onOpen();
    }
  };

  const onClickRegisterButton = () => {
    props.setFieldValue('is_clicked_register_contract_button_once', true);
    onClose();
  };

  const funcInSuccessProcess = async (files: File[]) => {
    // funcInSuccessProcess(files);

    if (_.isEmpty(files)) {
      //
    } else {
      props.setFieldValue('contract.file_infos', [
        ...props.values.contract.file_infos,
        ...files.map((url) => ({
          url,
          removed: false,
        })),
      ]);
    }
  };

  const total_payment_price = useMemo(() => {
    const rent = props.values.contract.rent as any;
    const down_payment = props.values.contract.down_payment as any;
    const deposit = props.values.contract.deposit as any;
    const management_fee = props.values.contract.management_fee as any;

    switch (props.values.contract.payment_status) {
      // 계약금
      case 'down_payment_expected':
        return down_payment;
      // 보증금+월세
      case 'balance_payment_expected':
        return deposit - down_payment + rent + management_fee;
      // 월세
      case 'rent_expected':
        return rent + management_fee;
      // case '':
    }
    return 0;
  }, [
    props.values.contract.payment_status,
    props.values.contract.rent,
    props.values.contract.down_payment,
    props.values.contract.deposit,
    props.values.contract.management_fee,
  ]);

  return (
    <>
      <Box onClick={handleModalOpen}>{children}</Box>

      <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside" isCentered size={'3xl'}>
        <ModalOverlay />
        <ModalContent p={5} h={'100%'}>
          <ModalCloseButton />
          <Flex flexDir={'column'} m={10} alignItems={'flex-start'} h="full">
            <HStack>
              <CheckCircleIcon w={8} h={8} />
              <Text fontSize={'23px'}>계약 정보 등록</Text>
            </HStack>
            <Box w="full">
              <VStack fontSize={'18px'} alignItems={'start'}>
                <VStack spacing={0} alignItems={'start'} mt={2} mb={4}>
                  <Box>{'계약 정보를 입력해 주세요. (주차비는 관리비에 포함)'}</Box>
                </VStack>
              </VStack>
            </Box>
            <Scrollbars style={{ height: '100%' }}>
              <VStack w="full" spacing={4}>
                <Flex flexDir={'column'} fontWeight={'bold'} w="full">
                  <Table w={'100%'}>
                    <Tbody w={'100%'}>
                      <TrContainer title="계약 등록일" textStyle={'medium14'}>
                        <Field
                          name={'contract.contract_created_date'}
                          as={Input}
                          type={'date'}
                          fontSize={'14px'}
                          h={'32px'}
                        />
                      </TrContainer>
                      <TrContainer title="계약서" textStyle={'medium14'} required>
                        <Box fontWeight={'medium'}>
                          <Flex align={'center'}>
                            <Box mr={4}>
                              <MultiFilesCommonDropzone
                                funcInSuccessProcess={funcInSuccessProcess}
                                customVariant={'plus'}
                                dropzoneOptions={{
                                  accept: ['application/pdf'],
                                }}
                              />
                            </Box>
                            {/* <Box>파일 1개당 첨부용량 30MB, 파일 업로드 문서당 최대 10개 가능</Box> */}
                          </Flex>
                          {!_.isEmpty(props.values.contract.file_infos) && (
                            <Box mt={2}>
                              <DropzoneOpacityFormikThumb
                                file_infos={props.values.contract.file_infos}
                                // onDeleteBtn={isEditable}
                                onDeleteBtn={true}
                                formikDeleteKey={'contract'}
                              />
                            </Box>
                          )}
                        </Box>
                        {/* <ValidationWithTouch error={!!errors.resident_registers} touch={!!touched.resident_registers} /> */}
                      </TrContainer>
                      <TrContainer title="계약 기간" textStyle={'medium14'} required>
                        <HStack>
                          <Field
                            name={'contract.contract_start_date'}
                            as={Input}
                            type={'date'}
                            fontSize={'14px'}
                            h={'32px'}
                            max={props.values.contract.contract_end_date}
                          />
                          <Text textStyle={'normal14'}>-</Text>
                          <Field
                            name={'contract.contract_end_date'}
                            as={Input}
                            type={'date'}
                            fontSize={'14px'}
                            h={'32px'}
                            min={props.values.contract.contract_start_date}
                          />
                          {/* <Input min={values.contract.contract_start_date} type={'date'} />
                          <Input min={''} type={'date'} />
                          <Input min={undefined} type={'date'} /> */}
                        </HStack>
                      </TrContainer>
                      <TrContainer title="납부상태" textStyle={'medium14'} required>
                        <HStack w={'341.5px'}>
                          <Field
                            name="contract.payment_status"
                            as={SelectBox}
                            h={'32px'}
                            bgColor="lotionWhite"
                            placeholder="선택"
                          >
                            {payment_status_select.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </Field>
                        </HStack>
                      </TrContainer>
                      <TrContainer title="납부일(매월)" textStyle={'medium14'} required>
                        <HStack w={'160px'}>
                          {/* <Field name="contract.payment_day" as={CustomInput} placeholder="1~27 사이 숫자 입력"></Field> */}
                          <Field
                            name="contract.payment_day"
                            as={SelectBox}
                            h={'32px'}
                            bgColor="lotionWhite"
                            placeholder="선택"
                          >
                            {minmax_select(1, 31).map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </Field>
                          <Text textStyle={'normal14'}>일</Text>
                          {/* 디엔 1 ~ 27일 선택 */}
                        </HStack>
                      </TrContainer>
                      <TrContainer title="계약금" textStyle={'medium14'} required>
                        <HStack w={'160px'}>
                          <Field
                            name="contract.down_payment"
                            as={CustomInput}
                            placeholder="숫자만 입력"
                            type="number"
                          ></Field>
                          <Text textStyle={'normal14'}>원</Text>
                        </HStack>
                      </TrContainer>
                      {/* <TrContainer title="잔금" textStyle={'medium14'} >
                        <HStack w={'160px'}>
                          <Field name="contract.balance_payment" as={CustomInput} placeholder="숫자만 입력"></Field>
                          <Text textStyle={'normal14'}>원</Text>
                        </HStack>
                      </TrContainer> */}
                      <TrContainer title="보증금" textStyle={'medium14'} required>
                        <HStack w={'160px'}>
                          <Field
                            name="contract.deposit"
                            as={CustomInput}
                            placeholder="숫자만 입력"
                            type="number"
                          ></Field>
                          <Text textStyle={'normal14'}>원</Text>
                        </HStack>
                      </TrContainer>
                      <TrContainer title="월 임대료 / 관리비" textStyle={'medium14'} required>
                        <HStack textStyle={'normal14'}>
                          <HStack w={'160px'}>
                            <Field
                              name="contract.rent"
                              as={CustomInput}
                              placeholder="숫자만 입력"
                              type="number"
                            ></Field>
                            <Text>원</Text>
                          </HStack>
                          <Text>/</Text>
                          <HStack w={'160px'}>
                            <Field
                              name="contract.management_fee"
                              as={CustomInput}
                              placeholder="숫자만 입력"
                              type="number"
                            ></Field>
                            <Text>원</Text>
                          </HStack>
                        </HStack>
                      </TrContainer>
                      <TrContainer title="최종 금액" textStyle={'medium14'}>
                        <HStack w={'160px'}>
                          <CustomInput
                            placeholder="최종 금액은 자동 계산 됩니다."
                            type="number"
                            isDisabled
                            value={total_payment_price}
                          />
                          <Text textStyle={'normal14'}>원</Text>
                        </HStack>
                      </TrContainer>
                      <TrContainer title="납부방법" textStyle={'medium14'} required>
                        <HStack w={'341.5px'}>
                          <Field
                            name="contract.payment_method"
                            as={SelectBox}
                            h={'32px'}
                            bgColor="lotionWhite"
                            placeholder="선택"
                          >
                            {payment_method_select.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </Field>
                          {/* 자동발급으로 변경 */}
                          {/* <CreateVirtualAccountModal userType="tenant" userId={values.user_id}>
                            <Button variant={'basic'} px={7}>
                              가상계좌 발급
                            </Button>
                          </CreateVirtualAccountModal> */}
                        </HStack>
                      </TrContainer>
                      <TrContainer title="정산계좌" textStyle={'medium14'} required>
                        <HStack w={'100%'}>
                          <Field
                            name="host_selected_account"
                            as={SelectBox}
                            h={'32px'}
                            bgColor="lotionWhite"
                            placeholder="기존 계좌에서 선택"
                            // value=
                            onChange={(e: any) => {
                              props.setFieldValue('host_selected_account', e.target.value);
                              // console.log('e => ', e.target.value);
                            }}
                          >
                            {props.values.accounts.map((account: any, index) => (
                              <option key={index} value={JSON.stringify(account)}>
                                {`${bankNameTrans(account?.bank_code)} ${account?.account_number} (${
                                  account?.account_owner_name
                                })`}
                              </option>
                            ))}
                          </Field>
                          <AddAccountInfosModal user_id={props.values.host_id}>
                            <Button variant={'basic'}>새 계좌 추가</Button>
                          </AddAccountInfosModal>
                        </HStack>
                      </TrContainer>
                    </Tbody>
                  </Table>
                </Flex>
              </VStack>
            </Scrollbars>
            <Center pt={10} w="full">
              <Button
                variant={'basic'}
                w="200px"
                minH="44px"
                onClick={onClickRegisterButton}
                isDisabled={!props.isValid}
              >
                등록 완료
              </Button>
            </Center>
          </Flex>
        </ModalContent>
      </Modal>
    </>
  );
};

export default RegisterContractInfoModal;

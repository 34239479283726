import { Box, Button, Flex, Modal, ModalContent, ModalOverlay, useDisclosure } from '@chakra-ui/react';
import { Form, Formik, FormikProps } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';
import { ModalDisclosureProps } from '../base';
import { queryClient } from '../../../..';
import { building_detail_key } from '../../../../statics/admin/data/KeyPath';
import PageOutCheck from '../../Dialog/PageOutCheck';
import CommonTypeBuilding from './sections/Building';
import Picture from './sections/RoomPictureRegisterModal';
import CommonTypeRoom from './sections/Room';

export const initialValue = {
  // 1 - 건물 등록
  files: [],
  building_type: '',
  building_form: '',
  building_description: '',
  address: '',
  jibun_address: '',
  road_address: '',
  zip_code: '',
  sido_name: '',
  sigungu_name: '',
  dongli_name: '',
  building_number: '',
  name: '',
  completion_date: '',
  floor: '',
  household_number: '',
  entrance_type: '',
  is_elevator: '',
  is_management: '',
  security_facilities: [],
  share_facilities: [],
  is_parking: '',
  host_id: '',
  parking_num: '',
  parking_cost: '',
  building_pictures: [],
  coliving_room_type_frontend: '',
  room_types: [],
  facilities: [], // {  }
  // 2 - 매물 등록
  room_type: '',
  building_id: '',
  deposit: '', // applied
  rent: '', // applied
  management_fee: '', // applied
  common_items: [], // applied
  individual_items: [], // applied
  move_in: {
    status: '',
    date: '',
  },
  minimum_contract_month: '', // applied
  pictures: [], // to room_pictures
  description: '',
  dong: '', // applied
  ho: '', // applied
  floor_r: '', // applied
  floor_type: '', // applied
  room_living_type: '', // applied
  room_num: '', // applied
  toilet_num: '', // applied
  supply_area: '', // applied
  supply_area_peong: '', // nonononononono
  dedicated_area: '', // applied
  dedicated_area_peong: '', // nonononononono
  room_direction: '', // applied
  heating_type: '', // applied
  default_options: [], // applied
  additional_options: [], // applied
  is_security_window: '', // applied
  is_parking_r: '', // applied
  is_pet: '', // applied
  // is_interior: '',
  remodeling_season: '',
  remodeling_items: [],
  is_bohome: '',
  access_status: 'open',
};

export interface ChildProps {
  formik: FormikProps<typeof initialValue>;
}

export interface StepProps {
  step: 0 | 1 | 2;
  setStep: React.Dispatch<React.SetStateAction<0 | 1 | 2>>;
}

const RegisterBuildingRoom: React.FC<ModalDisclosureProps> = ({ isOpen, onClose }) => {
  const [step, setStep] = useState<0 | 1 | 2>(0);
  const { isOpen: isReCheckOpen, onOpen: onReCheckOpen, onClose: onReCheckClose } = useDisclosure();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="outside"
      size={'7xl'}
      motionPreset="slideInBottom"
      preserveScrollBarGap
      closeOnEsc={false}
      closeOnOverlayClick={false}
      onEsc={() => onReCheckOpen()}
      onOverlayClick={() => onReCheckOpen()}
    >
      <ModalOverlay />
      <ModalContent>
        <Box w={'100%'} h={'100%'}>
          <Flex flexDir={'column'} h={'full'}>
            <Formik
              initialValues={initialValue}
              onSubmit={(values, { setSubmitting }) => {
                console.log(values);
                setSubmitting(false);
              }}
              validationSchema={Yup.object().shape({
                room_num: Yup.string()
                  .required('1~3개의 방 개수만 입력 가능합니다.')
                  .test(
                    'only positive room_num',
                    '1~3개의 방 개수만 입력 가능합니다.',
                    (val) => typeof val === 'string' && parseFloat(val) >= 1 && parseFloat(val) <= 3,
                  )
                  .matches(/^[0-9|.|]+$/, '숫자만 입력 가능합니다.'),
              })}
            >
              {(props) => {
                const { handleReset, values } = props;
                console.log('기본 타입 건물/방=>', values);

                return (
                  <Form style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                    {
                      {
                        0: <CommonTypeBuilding step={step} setStep={setStep} />,
                        1: <CommonTypeRoom step={step} setStep={setStep} />,
                        2: <Picture step={step} setStep={setStep} onCloseModal={onClose} />,
                      }[step]
                    }
                    {/* 모달 */}
                    <PageOutCheck
                      isOpen={isReCheckOpen}
                      onClose={onReCheckClose}
                      title="등록 취소"
                      content="등록 전 기입하신 정보들은 저장되지 않습니다."
                      Exitbutton={
                        <Button
                          onClick={() => {
                            onClose();
                            queryClient.removeQueries(building_detail_key);
                            handleReset();
                            setStep(0);
                          }}
                          variant={'gray'}
                          w="80px"
                          _hover={{ bgColor: 'red', borderColor: 'red' }}
                        >
                          나가기
                        </Button>
                      }
                    />
                  </Form>
                );
              }}
            </Formik>
          </Flex>
        </Box>
      </ModalContent>
    </Modal>
  );
};

export default RegisterBuildingRoom;

import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Image,
  Img,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  StackDivider,
  Text,
  Tooltip,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { isEmptyArray } from '@chakra-ui/utils';
import { isUndefined } from 'lodash';
import Scrollbars from 'react-custom-scrollbars-2';
import { LeaseContract, MoveInAndOut } from '../../../../lib/common/utils/axios/types';
import { datedotformat } from '../../../../lib/common/utils/dateformatter';
import ConfirmMoveOutModal from '../ConfirmMoveOutModal';
import ImageSliderModal from '../ImageSliderModal';

interface TitleAndPicturesProps {
  pictures: Array<string> | undefined;
  title?: string;
  titleAndPictures?: {
    title?: string;
    pictures?: string[] | undefined;
  }[];
}

const TitleAndPictures: React.FC<TitleAndPicturesProps> = ({ pictures, title, titleAndPictures }) => {
  return (
    <VStack align={'start'} spacing={1}>
      {title && <Text textStyle={'bold16'}>{title}</Text>}
      {!isUndefined(pictures) && !isEmptyArray(pictures) ? (
        <HStack spacing={1}>
          {pictures.map((picture, index) => (
            <ImageSliderModal key={index} titleAndPictures={{ title, pictures }}>
              <Image
                cursor={'pointer'}
                src={picture}
                // fallbackSrc="https://via.placeholder.com/75"
                alt={'오류'}
                boxSize={'75px'}
                objectFit="cover"
                borderRadius={'md'}
              />
            </ImageSliderModal>
          ))}
        </HStack>
      ) : (
        <Box boxSize={'75px'} borderRadius={'md'} background={'#CCCCCC'}>
          <Center h="full" textStyle={'normal10'} color={'gray'}>
            사진 없음
          </Center>
        </Box>
      )}
    </VStack>
  );
};

interface MoveInAndOutInfoCheckModalProps {
  disabled?: boolean;
  lease_contract: LeaseContract;
}

const MoveInAndOutInfoCheckModal: React.FC<MoveInAndOutInfoCheckModalProps> = ({
  disabled = false,
  lease_contract,
  children,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleModalOpen = () => {
    if (!disabled) {
      onOpen();
    }
  };

  const titleAndPictures = [
    {
      title: '현관입구',
      pictures: lease_contract?.move_in?.entrance_pictures,
    },
    {
      title: '화장실',
      pictures: lease_contract?.move_in?.toilet_pictures,
    },
    {
      title: '주방',
      pictures: lease_contract?.move_in?.kitchen_pictures,
    },
    {
      title: '방',
      pictures: lease_contract?.move_in?.room_pictures,
    },
    {
      title: '파손 흔적 확인',
      pictures: lease_contract?.move_in?.broken_pictures,
    },
  ];

  return (
    <>
      <Box onClick={handleModalOpen}>{children}</Box>
      <Modal isOpen={isOpen} onClose={onClose} size={'8xl'} isCentered>
        <ModalOverlay />
        <ModalContent minW={'800px'}>
          <ModalCloseButton />
          <ModalBody py={0} px={0}>
            <HStack divider={<StackDivider borderColor={'#E2E8F0'} />} align={'start'} spacing={0}>
              {/* 체크인 */}
              <Box flex={1} pt={6}>
                <Text textStyle={'bold20'} pl={6}>
                  체크인 사진 ({datedotformat(lease_contract?.move_in?.createdAt)})
                </Text>
                <Box border={'1px solid #E5E5E5'} boxShadow={'0px 2px 4px rgba(0, 0, 0, 0.1)'} mt={2}></Box>
                {/* 사진들 */}
                <Box pl={6} pr={3} py={6}>
                  <VStack align={'start'} spacing={6}>
                    <TitleAndPictures title="현관입구" pictures={lease_contract?.move_in?.entrance_pictures} />
                    <TitleAndPictures title="화장실" pictures={lease_contract?.move_in?.toilet_pictures} />
                    <TitleAndPictures title="주방" pictures={lease_contract?.move_in?.kitchen_pictures} />
                    <TitleAndPictures title="방" pictures={lease_contract?.move_in?.room_pictures} />
                    <TitleAndPictures title="파손 흔적 확인" pictures={lease_contract?.move_in?.broken_pictures} />
                    {lease_contract?.move_in?.tenant_description && (
                      <Tooltip label={'등록한 파손 흔적 사진에 대한 설명입니다.'} hasArrow>
                        <Box layerStyle={'request_line_tab'} textStyle={'normal14'}>
                          {lease_contract?.move_in?.tenant_description}
                          {/* {move_in?.tenant_description} */}
                        </Box>
                      </Tooltip>
                    )}
                  </VStack>
                </Box>
              </Box>
              {/* 체크아웃 */}
              <Box flex={1} pt={6}>
                <Flex w="full" justify={'space-between'} pr={10}>
                  <Text textStyle={'bold20'} pl={6}>
                    체크아웃 사진 ({datedotformat(lease_contract?.moving_out?.createdAt) || '미등록'})
                  </Text>
                  <Center>
                    <ConfirmMoveOutModal lease_contract={lease_contract}>
                      <Button variant={'basic'} height={'30px'}>
                        퇴실처리
                      </Button>
                    </ConfirmMoveOutModal>
                  </Center>
                </Flex>
                <Box border={'1px solid #E5E5E5'} boxShadow={'0px 2px 4px rgba(0, 0, 0, 0.1)'} mt={2}></Box>
                {/* 사진들 */}
                <Box pl={6} pr={3} py={6}>
                  {lease_contract?.moving_out?.createdAt ? (
                    <VStack align={'start'} spacing={6}>
                      <TitleAndPictures title="현관입구" pictures={lease_contract?.moving_out?.entrance_pictures} />
                      <TitleAndPictures title="화장실" pictures={lease_contract?.moving_out?.toilet_pictures} />
                      <TitleAndPictures title="주방" pictures={lease_contract?.moving_out?.kitchen_pictures} />
                      <TitleAndPictures title="방" pictures={lease_contract?.moving_out?.room_pictures} />
                      <TitleAndPictures title="파손 흔적 확인" pictures={lease_contract?.moving_out?.broken_pictures} />
                      {lease_contract?.moving_out?.tenant_description && (
                        <Tooltip label={'등록한 파손 흔적 사진에 대한 설명입니다.'} hasArrow>
                          <Box layerStyle={'request_line_tab'} textStyle={'normal14'}>
                            {lease_contract?.moving_out?.tenant_description}
                          </Box>
                        </Tooltip>
                      )}
                    </VStack>
                  ) : (
                    <Center>아직 등록 전입니다.</Center>
                  )}
                </Box>
              </Box>
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default MoveInAndOutInfoCheckModal;

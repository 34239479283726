import { Box, Button, Flex, HStack, Table, Tbody, Td, Text, Tr, useDisclosure, VStack } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';
import InitFormikEditableButton from '../../../../../../../../components/common/Button/InitFormikEditableButton';
import FormikEditableValidationComponent from '../../../../../../../../components/common/FormikValidation/FormikEditableValidationInput';
import AddressFinder from '../../../../../../../../components/common/Modals/AddressFinder';
import TextLink from '../../../../../../../../components/common/Link/TextLink';
import { TdFullWContainer } from '../../../../../../../../components/common/Table/Tr';
import { useUpdateUserInfo } from '../../../../../../../../lib/admin/hooks/mutation';
import { OneCompanyType } from '../../../../../../../../lib/common/utils/axios/types';
import { bankNameTrans, currentParamsObject } from '../../../../../../../../lib/common/utils/base';
import { CompanyTypeTrans } from '../../../../../../../../lib/subscriber/utils/utils';
import { datedetaildotkoformat } from '../../../../../../../../lib/common/utils/dateformatter';
import AddAccountInfosModal from '../../../../../../../../components/common/Modals/AddAccountModal';
import { AddAccountModal } from '../../../../../../../../components/common/Modals/AddAccountModal/AddAccountModal';
import { useGetCompanyHost } from '../../../../../../../../lib/common/hooks/query';
import Loading from '../../../../../../../../components/common/Loading';

const Info = () => {
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpenAddAccount, onClose: onCloseAddAccount, onOpen: onOpenAddAccount } = useDisclosure();
  const { id } = currentParamsObject(false);

  const { mutateAsync: updateUserInfo } = useUpdateUserInfo();

  const { data, isFetching, isLoading } = useGetCompanyHost(id || '');
  const company = data?.data;
  if (isLoading) return <Loading />;
  return (
    <Formik
      initialValues={{
        user_name: company?.employee?.user_name,
        phone_number_first: company?.employee?.phone_number_first,
      }}
      validationSchema={Yup.object().shape({
        user_name: Yup.string().required('대표 담당자명을 입력해 주세요.'),
        phone_number_first: Yup.string()
          .matches(/^((?!-).)*$/, '- 없이 입력해 주세요.')
          .min(7, ({ min }) => `연락처는 최소 ${min}글자입니다.`)
          .max(16, ({ max }) => `연락처는 최대 ${max}글자입니다.`)
          .required('연락처를 입력해 주세요.'),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        await updateUserInfo({
          user_id: id || '',
          user_name: values.user_name,
          phone_number_first: values.phone_number_first,
        })
          .then(() => {
            setIsEditable(false);
          })
          .finally(() => {
            setSubmitting(false);
          });
      }}
    >
      {(props) => {
        const isNotAnyChange = props.initialValues === props.values;

        const eviprops = {
          isEditable,
          props,
        };
        return (
          <Form id="form-a" style={{ width: '100%', height: '100%' }}>
            <Flex w={'100%'}>
              <VStack justifyContent={'start'} w={'100%'} spacing={6}>
                {/* 회원 정보 */}
                <VStack spacing={3} justifyContent={'start'} align={'flex-start'} w={'100%'}>
                  <HStack w={'full'} spacing={2} justify={'space-between'}>
                    <Box fontWeight={'bold'} fontSize={'18px'} textAlign={'start'} w={'112px'}>
                      회원 정보
                    </Box>
                    <Box flex={1} />
                    <InitFormikEditableButton
                      props={props}
                      isDisabledCompleteEdit={isNotAnyChange}
                      isEditable={isEditable}
                      setIsEditable={setIsEditable}
                    />
                  </HStack>
                  <Table w={'100%'} variant="simple">
                    <Tbody w={'100%'}>
                      <Tr w={'100%'}>
                        <TdFullWContainer title={'이름'}>
                          <FormikEditableValidationComponent {...eviprops} name={'user_name'} />
                        </TdFullWContainer>
                        <TdFullWContainer title={'회원 가입일'}>
                          {datedetaildotkoformat(company?.employee?.createdAt)}
                        </TdFullWContainer>
                      </Tr>
                      <Tr>
                        <TdFullWContainer title={'휴대폰번호'}>
                          <FormikEditableValidationComponent {...eviprops} name={'phone_number_first'} />
                        </TdFullWContainer>
                        <TdFullWContainer title={'이메일'}>
                          <Text>{company?.employee?.email}</Text>
                        </TdFullWContainer>
                      </Tr>
                      <Tr>
                        <TdFullWContainer title={'등록서류'}>
                          <Flex flexDir={'column'}>
                            {company?.employee?.resident_register_urls?.length === 0 ? (
                              <Text color={'gray'}>등록된 서류가 없습니다.</Text>
                            ) : (
                              company?.employee?.resident_register_urls?.map((url: any, index: number) => (
                                <TextLink key={index} href={url} target={'_blank'}>{`첨부파일 ${index}`}</TextLink>
                              ))
                            )}
                          </Flex>
                        </TdFullWContainer>
                        <Td></Td>
                        <Td></Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </VStack>
                {/* 사업자 정보 */}
                <VStack spacing={3} justifyContent={'start'} align={'flex-start'} w={'100%'}>
                  <Box w={'100%'} fontWeight={'bold'} fontSize={'18px'} textAlign={'start'} flexGrow={1}>
                    사업자 정보
                  </Box>
                  <Table w={'100%'} variant="simple">
                    <Tbody w={'100%'}>
                      <Tr w={'100%'}>
                        <TdFullWContainer title={'사업자 정보'}>
                          <HStack>
                            {/* <Box>{(state as any)._id}</Box> */}
                            <Box>{CompanyTypeTrans(company?.business_type as OneCompanyType)}</Box>
                          </HStack>
                        </TdFullWContainer>
                        <TdFullWContainer title={'임대 사업자 번호'}>
                          <Box>{company?.business_registration_number}</Box>
                        </TdFullWContainer>
                      </Tr>
                      <Tr>
                        <TdFullWContainer title={'법인명'}>
                          <Box>{company?.name}</Box>
                        </TdFullWContainer>
                        <TdFullWContainer title={'사업자등록증'}>
                          {company?.business_certificate ? (
                            <Text>{company?.business_certificate}</Text>
                          ) : (
                            <Text color={'gray'}>등록된 사업자등록증이 없습니다.</Text>
                          )}
                        </TdFullWContainer>
                      </Tr>
                    </Tbody>
                  </Table>
                </VStack>
                {/* 계좌 정보 */}
                <VStack spacing={3} justifyContent={'start'} align={'flex-start'} w={'100%'}>
                  <HStack w={'full'} spacing={2} justify={'space-between'}>
                    <Flex>
                      <Box
                        fontWeight={'bold'}
                        fontSize={'18px'}
                        textAlign={'start'}
                        w={'-webkit-fit-content'}
                        paddingRight={'18px'}
                      >
                        계좌 정보
                      </Box>
                      <Button variant={'basic'} onClick={onOpenAddAccount}>
                        새 계좌 추가
                      </Button>
                      <AddAccountModal isOpen={isOpenAddAccount} onClose={onCloseAddAccount} />
                    </Flex>
                  </HStack>
                  <Table w={'100%'} variant="simple">
                    <Tbody w={'100%'}>
                      {company?.employee?.accounts.length === 0 ? (
                        <Text color={'gray'}>등록된 계좌가 없습니다.</Text>
                      ) : (
                        company?.employee?.accounts.map((account: any, index) => (
                          <Tr key={index}>
                            <TdFullWContainer title={`계좌 ${index + 1}`}>
                              <Text>
                                {bankNameTrans(account?.bank_code) +
                                  ' ' +
                                  account?.account_owner_name +
                                  '(' +
                                  account?.account_number +
                                  ')'}
                              </Text>
                            </TdFullWContainer>
                            <Td></Td>
                            <Td></Td>
                          </Tr>
                        ))
                      )}
                    </Tbody>
                  </Table>
                </VStack>
              </VStack>
            </Flex>
            <AddressFinder isOpen={isOpen} onClose={onClose} formik={props} />
          </Form>
        );
      }}
    </Formik>
  );
};

export default Info;

import { Box, BoxProps, Flex, styled, useMediaQuery, useToast } from '@chakra-ui/react';
import {} from 'firebase/messaging/sw';
import React, { useContext, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Outlet } from 'react-router-dom';
import { onForeGroundMessageListener } from '../../../lib/common/utils/firebase/init';

const variants = {
  hidden: { opacity: 0, x: 100, y: 0 },
  enter: { opacity: 1, x: 0, y: 0 },
  exit: { opacity: 0, x: -100, y: 0 },
  // exit: { opacity: 0, x: 100, y: -100 }
};

const UnderTapLayout: React.FC<BoxProps> = ({ children, style, ...props }) => {
  const [show, setShow] = useState(false);
  const [isTokenFound, setTokenFound] = useState(false);
  const [notification, setNotification] = useState({ title: '', body: '' });
  const toast = useToast();
  const [mdDetect] = useMediaQuery('(min-width: 768px)');

  onForeGroundMessageListener()
    .then((payload: any) => {
      //alis 신규 채팅 알림 | 채팅 알림
      console.log('onForeGroundMessageListener: ', payload);

      setNotification({ title: payload.notification.title, body: payload.notification.body });
      setShow(true);

      toast({
        title: payload.notification.title,
        description: payload.notification.body,
        status: 'success',
        variant: 'success',
        duration: 2000,
        isClosable: true,
        position: 'top-right',
      });
    })
    .catch((err) => console.log('failed: ', err));

  return (
    <>
      <Flex
        w={'100%'}
        h="calc(100vh - 168px)"
        minH="calc(100vh - 168px)"
        bgColor={'white'}
        borderBottomRadius={'5px'}
        borderTopEndRadius={'5px'}
        px={5}
        pt={5}
        pb={5}
        boxShadow={'2px 2px rgba(221,221,221, 0.2)'}
        id="under-tab-layout"
        {...props}
      >
        <Scrollbars
          renderTrackVertical={({ style, ...props }) => (
            <div
              {...props}
              style={{
                ...style,
                position: 'absolute',
                width: '2px',
                right: '0px',
                bottom: '2px',
                top: '2px',
                borderRadius: '2px',
              }}
              className="undertaplayout-track-vertical"
            />
          )}
        >
          <Box w="full" h="full" p={1}>
            <Outlet />
          </Box>
        </Scrollbars>
      </Flex>
    </>
  );
};

export default UnderTapLayout;

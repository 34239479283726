import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import { WBox } from '../../Box';
import { useUpdateVirtualAccountName } from '../../../../lib/common/hooks/mutation';
import * as T from '../../../../types/common/CommonType';
import { CustomInput } from '../../Input';
import { CheckCircleIcon } from '@chakra-ui/icons';

interface UpdateVirtualAccountNameModalProps {
  virtual_account: T.VirtualAccount;
  user_id: string;
}

const UpdateVirtualAccountNameModal: React.FC<UpdateVirtualAccountNameModalProps> = ({
  children,
  virtual_account,
  user_id,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { mutateAsync: UpdateVirtualAccountName } = useUpdateVirtualAccountName();
  return (
    <>
      <Box onClick={onOpen}>{children}</Box>
      <Modal isOpen={isOpen} onClose={onClose} isCentered motionPreset="none" size={'xl'}>
        <ModalOverlay />
        <Formik
          initialValues={{
            vaccntOwnerName: virtual_account?.account_owner_name,
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            await UpdateVirtualAccountName({
              user_id: user_id,
              vaccntOwnerName: values?.vaccntOwnerName || '',
            }).then(() => {
              onClose();
            });
          }}
        >
          {({ values, errors, isValid, handleChange, setFieldValue, isSubmitting }) => {
            return (
              <Form>
                <ModalContent p={5}>
                  <Center flexDir={'column'} m={10} alignItems={'flex-start'}>
                    <HStack>
                      <CheckCircleIcon w={8} h={8} />
                      <Text fontSize={'23px'}>가상계좌 업데이트</Text>
                    </HStack>
                    <VStack fontSize={'20px'} flexDir={'column'} my={4} spacing={0} alignItems={'flex-start'}>
                      <Box>{'업데이트할 가상계좌 정보를 기입해주세요.'}</Box>
                    </VStack>
                    {/* 선택된 매물 리스트 */}

                    <VStack w={'full'}>
                      <WBox w={'full'}>
                        <Box w="full" px={'30px'} py={'30px'}>
                          <VStack>
                            <HStack w="full">
                              <Text minW="130px" fontSize={'14px'}>
                                이름
                              </Text>
                              <Field name={'vaccntOwnerName'} as={CustomInput} fontSize={'14px'} />
                            </HStack>
                          </VStack>
                        </Box>
                      </WBox>
                    </VStack>

                    <Center w="full">
                      <Button
                        mt={'30px'}
                        variant={'basic'}
                        w={'140px'}
                        fontSize={'14px'}
                        fontWeight={'bold'}
                        type="submit"
                        isLoading={isSubmitting}
                      >
                        수정하기
                      </Button>
                    </Center>
                  </Center>
                </ModalContent>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

export default UpdateVirtualAccountNameModal;

import { CheckIcon } from '@chakra-ui/icons';
import { Box, Button, Divider, Flex, Stack, Text } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { MinusIcon } from '../../../../../statics/common/icons';
import * as Yup from 'yup';
import { BasicInput } from '../../../../../components/subscriber/Input/StyledBaiscInput';

/* .../company/infos/private/ */

const PrivateInfosPage = () => {
  return (
    <Formik
      initialValues={{
        userId: '',
        password: '',
        passwordCheck: '',
        userName: '',
        first_phone_number: '',
        second_phone_number: '',
        third_phone_number: '',
        first_private_number: '',
        second_private_number: '',
        third_private_number: '',
        email: '',
      }}
      validationSchema={Yup.object({
        userId: Yup.string().min(3, '세글자 이상').max(10, '10글자 이하').required('입력 필요'),
        password: Yup.string()
          .required('No password provided.')
          .min(8, 'Password is too short - 8글자 이상')
          .matches(
            // eslint-disable-next-line no-useless-escape
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
            'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
          ),
        passwordCheck: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
        userName: Yup.string().min(3, '세글자 이상').max(10, '10글자 이하').required('입력 필요'),
        first_phone_number: Yup.number().max(3, '3글자 이하').required('입력 필요'),
        second_phone_number: Yup.number().max(3, '4글자 이하').required('입력 필요'),
        third_phone_number: Yup.number().max(3, '4글자 이하').required('입력 필요'),
        first_private_number: Yup.number().max(3, '3글자 이하').required('입력 필요'),
        second_private_number: Yup.number().max(3, '4글자 이하').required('입력 필요'),
        third_private_number: Yup.number().max(3, '4글자 이하').required('입력 필요'),
        email: Yup.string().email().max(100, '올바른 이메일이 아닙니다.').required('이메일 입력이 필요합니다.'),
      })}
      onSubmit={(values, { setSubmitting }) => {
        console.log('submit in private');

        setTimeout(() => {
          alert(JSON.stringify(values, null, 2));
          setSubmitting(false);
        }, 800);
      }}
    >
      {(formik) => (
        <Form>
          <Flex flexDir={'column'} align={'center'} justify={'center'} h="600px">
            <Flex
              flexDir={'column'}
              w={{ base: '450px', md: '900px' }}
              bgColor={'white'}
              rounded={'xl'}
              boxShadow={'lg'}
              p={6}
            >
              <Box id="header" mb={'24px'}>
                <Text fontSize={'18px'} color={'midGray'} mb={'12px'}>
                  회원 정보
                </Text>
                <Divider />
              </Box>

              <Flex justifyContent={'space-between'} id="inputs_box" flexDir={{ base: 'column', md: 'row' }}>
                <Flex id="inputs_1" w={'400px'} flexDir={'column'}>
                  <Stack spacing={6}>
                    <Flex align="center" position={'relative'} w={'100%'}>
                      <Text w={'130px'}>아이디</Text>
                      <BasicInput
                        id="userId"
                        placeholder="아이디"
                        type="text"
                        position={'relative'}
                        {...formik.getFieldProps('userId')}
                      />
                      <CheckIcon color="#183377" zIndex={2} position={'absolute'} right={0} mr={'10px'} />
                    </Flex>
                    <Flex align="center" position={'relative'} w={'100%'}>
                      <Text w={'130px'}>비밀번호</Text>
                      <BasicInput
                        id="password"
                        type="password"
                        position={'relative'}
                        {...formik.getFieldProps('password')}
                      />
                    </Flex>
                    <Flex align="center" position={'relative'} w={'100%'}>
                      <Text w={'130px'}>비밀번호 확인</Text>
                      <BasicInput
                        id="passwordCheck"
                        type="password"
                        position={'relative'}
                        {...formik.getFieldProps('passwordCheck')}
                      />
                      <CheckIcon color="#183377" zIndex={2} position={'absolute'} right={0} mr={'10px'} />
                    </Flex>
                  </Stack>
                </Flex>
                <Flex id="inputs_2" w={'400px'} flexDir={'column'} mt={{ base: '24px', md: '0px' }}>
                  <Stack spacing={6}>
                    <Flex align="center" position={'relative'} w={'100%'}>
                      <Text w={'130px'}>담당자 이름</Text>
                      <BasicInput
                        id="userName"
                        placeholder="이름"
                        type="password"
                        position={'relative'}
                        {...formik.getFieldProps('userName')}
                      />
                      <CheckIcon color="#183377" zIndex={2} position={'absolute'} right={0} mr={'10px'} />
                    </Flex>

                    <Flex align="center" position={'relative'} w={'100%'}>
                      <Text w={'130px'}>연락처</Text>
                      <Flex>
                        <BasicInput
                          w={'82px'}
                          id="first_phone_number"
                          placeholder="010"
                          type="number"
                          {...formik.getFieldProps('first_phone_number')}
                        />
                        <Flex mx={'4px'} align="center">
                          <MinusIcon />
                        </Flex>
                        <BasicInput
                          w={'82px'}
                          id="second_phone_number"
                          placeholder="1234"
                          type="number"
                          {...formik.getFieldProps('second_phone_number')}
                        />
                        <Flex mx={'4px'} align="center">
                          <MinusIcon />
                        </Flex>
                        <BasicInput
                          w={'82px'}
                          id="third_phone_number"
                          placeholder="5678"
                          type="number"
                          {...formik.getFieldProps('third_phone_number')}
                        />
                      </Flex>
                    </Flex>
                    <Flex align="center" position={'relative'} w={'100%'}>
                      <Text w={'130px'}>사내 직통 번호</Text>
                      <Flex>
                        <BasicInput
                          w={'82px'}
                          id="first_private_number"
                          placeholder="010"
                          type="number"
                          {...formik.getFieldProps('first_private_number')}
                        />
                        <Flex mx={'4px'} align="center">
                          <MinusIcon />
                        </Flex>
                        <BasicInput
                          w={'82px'}
                          id="second_private_number"
                          placeholder="1234"
                          type="number"
                          {...formik.getFieldProps('second_private_number')}
                        />
                        <Flex mx={'4px'} align="center">
                          <MinusIcon />
                        </Flex>
                        <BasicInput
                          w={'82px'}
                          id="third_private_number"
                          placeholder="5678"
                          type="number"
                          {...formik.getFieldProps('third_private_number')}
                        />
                      </Flex>
                    </Flex>

                    {/* validation */}
                    <Flex align="center" w={'400px'}>
                      <Text w={'130px'}>이메일</Text>
                      <Box position={'relative'}>
                        <BasicInput id="email" type="email" position={'relative'} {...formik.getFieldProps('email')} />
                        <Box position={'absolute'} fontSize={'12px'} color="red.400">
                          {formik.touched.email && formik.errors.email ? <Box>{formik.errors.email}</Box> : null}
                        </Box>
                      </Box>
                    </Flex>
                  </Stack>
                </Flex>
              </Flex>
            </Flex>
            <Flex mt={{ base: '20px', md: '50px' }} flexDir={{ md: 'row' }} justify={'center'} align="center">
              <Button
                bg={'red.400'}
                color={'white'}
                w="150px"
                _hover={{
                  bg: 'red.500',
                }}
                size="sm"
                mr="10px"
              >
                홈으로
              </Button>
              <Button
                bg={'zetaBlue'}
                color={'white'}
                w="150px"
                _hover={{
                  bg: 'blue.500',
                }}
                size="sm"
                type="submit"
              >
                저장
              </Button>
            </Flex>
          </Flex>
        </Form>
      )}
    </Formik>
  );
};

export default PrivateInfosPage;

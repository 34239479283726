import { Box, Button, Table, Tbody, Td, Text, Textarea, Th, Thead, Tooltip, Tr } from '@chakra-ui/react';
import { isEmptyArray } from '@chakra-ui/utils';
import format from 'date-fns/format';
import { Link } from 'react-router-dom';
import QueryParamsLayout from '../../../../../../layouts/common/main/QueryParamsLayout';
import { useGetAllAgenciesManagers, useGetAllCompaniesManagers } from '../../../../../../lib/admin/hooks/query';
import { currentParamsObject, ToAllFilledObj } from '../../../../../../lib/common/utils/base';
import { datedotformat } from '../../../../../../lib/common/utils/dateformatter';
import ConfirmApproveCompanyModal from '../../../../../../components/common/Modals/ConfirmApproveCompanyModal';
import { isEmpty } from 'lodash';
import TextLink from '../../../../../../components/common/Link/TextLink';
import UserTooltip from '../../../../../../components/common/Tooltip';
import * as Type from '../../../../../../types/common/CommonType';
import Loading from '../../../../../../components/common/Loading';

const User = () => {
  const { search: srch } = location;
  const _params = currentParamsObject();
  const params = ToAllFilledObj(_params);

  const res = useGetAllAgenciesManagers({
    axiosConfig: {
      params,
    },
  });
  const { isLoading } = res;
  if (isLoading) return <Loading />;
  return (
    <QueryParamsLayout
      res={res}
      moreFilters={{ confirmStatusMenu: 'left', serviceStatusMenu: 'left' }}
      placeholder="회사명/고유코드/담당자명으로 검색"
    >
      <Table variant="head_silverbg_toplining">
        <Thead bgColor={'#F8F8F8'} borderColor={'gray.100'}>
          <Tr>
            <Th>가입일</Th>
            <Th>기업명</Th>
            <Th>회사 고유코드</Th>
            <Th>담당자명</Th>
            <Th>연락처</Th>
            <Th>이메일</Th>
            <Th>승인 여부</Th>
            <Th>업데이트일</Th>
          </Tr>
        </Thead>
        <Tbody>
          {res.data?.result.map((company: Type.Company, index) => {
            console.log(index === 0 && company?.default_memo.description);
            return (
              <Tr key={srch + index}>
                <Td>{datedotformat(company?.createdAt)}</Td>

                <Td>
                  <UserTooltip memo={company?.default_memo}>
                    <TextLink to={`detail/info?id=${company?._id}`}>{company?.name}</TextLink>
                  </UserTooltip>
                </Td>
                <Td>{company?.company_code}</Td>
                <Td>{company?.master?.user_name}</Td>
                <Td>{company?.master?.phone_number_first}</Td>
                <Td>{company?.master?.email}</Td>
                <Td>
                  {company?.master?.is_approved === undefined ? (
                    <ConfirmApproveCompanyModal company_manager_data={company}>
                      <Button variant={'basic'}>승인 요청</Button>
                    </ConfirmApproveCompanyModal>
                  ) : company?.master?.is_approved ? (
                    '승인'
                  ) : (
                    '반려'
                  )}
                </Td>
                <Td>{datedotformat(company?.updatedAt)}</Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </QueryParamsLayout>
  );
};

export default User;

import { Box, Skeleton, Text, VStack } from '@chakra-ui/react';
import { parseNotifications, useGetAllNotifications } from '../../../../../lib/subscriber/hooks/query';

import { NotificationType, Notification } from '../../../../../lib/common/utils/axios/types';
import CardContainer from '../Container';
import { isToday } from 'date-fns';
import { isEmptyArray } from '@chakra-ui/utils';
import Scrollbars from 'react-custom-scrollbars-2';

const AlertCardContainers = () => {
  const { data: notices, isLoading } = useGetAllNotifications({
    options: {
      cacheTime: 500,
    },
  });

  // if (notices === undefined) {
  //   return null;
  // }

  if (isEmptyArray(notices)) {
    return <Text fontSize={'14px'}>새로운 중요 알림이 없습니다.</Text>;
  }

  const data_collection = parseNotifications(notices);

  return (
    // <Box>
    <Skeleton isLoaded={!isLoading} w="full" h="full">
      {/* map으로 처리할것 */}
      {/* <Scrollbars style={{ height: '100%' }}> */}
      <VStack spacing={3}>
        {data_collection?.map((data: Notification[], index: any) => (
          <CardContainer data={data} key={index} />
        ))}
      </VStack>
      {/* </Scrollbars> */}
    </Skeleton>
    // </Box>
  );
};

export default AlertCardContainers;

import {
  Box,
  Button,
  HStack,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { Announcement } from '../../../../lib/common/utils/axios/types';
import { TodayYesterdayFormat } from '../../../../lib/common/utils/base';

interface AnnouncementModalProps {
  data: Announcement;
}

const AnnouncementModal: React.FC<AnnouncementModalProps> = ({ data }) => {
  const { isOpen: isOpenAnnouncement, onOpen: onOpenAnnouncement, onClose: onCloseAnnouncement } = useDisclosure();
  const { title, description, createdAt, file_urls, picture_urls } = data;

  return (
    <>
      <HStack
        onClick={onOpenAnnouncement}
        fontSize={'16px'}
        w="full"
        justify={'space-between'}
        cursor={'pointer'}
        py={1}
        userSelect={'none'}
        transition={'0.15s linear'}
        _hover={{
          opacity: 0.7,
        }}
        role="group"
      >
        <Box _groupHover={{ textDecor: 'underline' }}>{title}</Box>
        <Box>
          <Text color={'#C4C4C4'}>{TodayYesterdayFormat(createdAt)}</Text>
        </Box>
      </HStack>

      <Modal isOpen={isOpenAnnouncement} onClose={onCloseAnnouncement} isCentered size={'2xl'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize={'18px'}>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody fontSize={'16px'} maxH={'40vh'}>
            <Box mb={4} dangerouslySetInnerHTML={{ __html: description }} />
            <Box mb={4}>
              <VStack>
                {picture_urls.map((picture_url, index) => (
                  <Image key={index} src={picture_url} maxW={'400px'} />
                ))}
              </VStack>
            </Box>
          </ModalBody>
          <ModalFooter justifyContent={'space-between'}>
            <HStack>
              <Box>
                <Text>첨부파일:</Text>
              </Box>
              <HStack>
                {file_urls.map((file_url, index) => (
                  <Link key={index} href={file_url} isExternal>
                    {`첨부 #${index + 1}`}
                  </Link>
                ))}
              </HStack>
            </HStack>
            <Button colorScheme="blue" mr={3} onClick={onCloseAnnouncement}>
              닫기
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AnnouncementModal;

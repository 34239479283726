import { Box, Center, Flex, Image } from '@chakra-ui/react';
import Intro from '../../../../components/agent/Intro/Agency';
import background from '../../../../statics/common/images/background.jpeg';
import Login from './sections/login';

const AgencyLoginPage = () => {
  return (
    <Box w={'100vw'} h={'100vh'}>
      <Image src={background} w={'100%'} h={'100%'} objectFit="cover" opacity={'0.16'} position={'absolute'} />
      <Center w={'100%'} h={'100%'}>
        <Box w={'1372px'} h={'772px'} position={'relative'} boxShadow={'lg'}>
          <Image src={background} w={'100%'} h={'100%'} objectFit="cover" />
          <Box w={'100%'} h={'100%'} position={'absolute'} top="0" bgColor="zetaBlue" opacity={0.5} />
          <Box w={'100%'} h={'100%'} position={'absolute'} top="0" bgColor="black" opacity={0.2} />
          <Flex position={'absolute'} top="0" w={'100%'} h="100%">
            <Box w="54%" h="100%" px="87px" pt="154px" pb="92px">
              {/* Intro */}
              <Intro />
            </Box>
            <Box bgColor={'white'} w="47%" h="100%" borderTopLeftRadius={'3rem'}>
              <Login />
            </Box>
          </Flex>
        </Box>
      </Center>
    </Box>
  );
};

export default AgencyLoginPage;

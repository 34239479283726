import {
  Box,
  Button,
  Flex,
  HStack,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Table,
  Tbody,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { Field, FieldArray, useFormikContext } from 'formik';
import ValidationWithTouch from '../../FormikValidation/ValidationWithTouch';
import { CustomInput } from '../../Input';
import { TrContainer } from '../../Table/Tr';
import { CheckCircleIcon } from '../../../../statics/common/icons';
import SelectBox from '../../SelectBox/EstatesSelectBox';
import { initialValuesProps } from '../RegisterNewHostModal';
import { bank_data } from '../../../../statics/common/data/bank';
import { useVerifyAccount } from '../../../../lib/common/hooks/mutation';
import { verifyAccountDuplicated } from '../../../../statics/common/data/toast';
import { isUndefined } from 'lodash';
import { useState } from 'react';
import { useAddUserAccount } from '../../../../lib/common/hooks/mutation';

interface AddAccountInfosModalProps {
  disabled?: boolean;
  user_id?: string;
}

const AddAccountInfosModal: React.FC<AddAccountInfosModalProps> = ({ children, disabled, user_id }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const formik = useFormikContext<initialValuesProps>();
  const { values, errors, touched, setFieldValue, handleChange } = formik;
  const allAccounts = values.accounts;
  const [isInitialEmpty, setIsInitialEmpty] = useState<boolean>(allAccounts.length === 0);
  const [currentIdx, setCurrentInx] = useState<number>(isInitialEmpty ? 0 : allAccounts.length - 1);
  const toast = useToast();
  const registerToastId = 'registerToastId';

  const handleModalOpen = () => {
    if (!disabled) {
      onOpen();
    }
  };
  // const currentIndex = 0;

  // const allAccounts = values.accounts.filter((account) => account.is_added === true);

  const currentAccountInfos = allAccounts[currentIdx];

  // const currentIdx = values.accounts.filter((account) => account.is_added === true).length;
  console.log('added_accounts =>', allAccounts);
  console.log('currentIdx =>', currentIdx);
  console.log('currentAccountInfos =>', currentAccountInfos);

  const { mutateAsync, isLoading } = useVerifyAccount();

  const { mutateAsync: AddUserAccount } = useAddUserAccount();

  const handleClickAuth = () => {
    const { account_number, account_owner_name, bank_code } = currentAccountInfos;
    mutateAsync({
      account_number,
      account_owner_name,
      bank_code,
    }).then((data) => {
      const verified = data.data.data.is_account_verified;
      if (verified) {
        setFieldValue(`accounts.${user_id ? currentIdx + 1 : currentIdx}.is_auth`, verified);
      }
    });
  };

  // const isAuthSucceeded = added_accounts[currentIdx]?.is_auth ? false : true;
  // const isAuthSucceeded = isUndefined(added_accounts[currentIdx]) ? false : added_accounts[currentIdx].is_auth;
  const isAuthSucceeded = !isUndefined(allAccounts[user_id ? currentIdx + 1 : currentIdx]?.is_auth);

  const isAbleClickAuth = (() => {
    if (isUndefined(currentAccountInfos)) {
      return false;
    }
    const { account_number, account_owner_name, bank_code } = currentAccountInfos;
    if (isAuthSucceeded) {
      return false;
    }
    if (account_number && account_owner_name && bank_code) {
      return true;
    }
    return false;
  })();

  // const initial = { bank_code: 1234, account_number: '', account_owner_name: '', is_auth: false, is_added: false };

  const handleCancelRegister = () => {
    // setFieldValue(`accounts.${currentIdx}`, initial);
    onClose();
  };

  const handleClickRegister = () => {
    // !! 계좌 등록하기 API 호출
    const { account_number } = currentAccountInfos;

    const isDuplicated = allAccounts.filter((account) => account.account_number === account_number).length > 1;
    if (!isDuplicated) {
      // setFieldValue(`accounts.${currentIdx + 1}`, initial);
      setFieldValue(`accounts.${user_id ? currentIdx + 1 : currentIdx}.is_added`, true);

      const { account_number, account_owner_name, bank_code } = allAccounts[user_id ? currentIdx + 1 : currentIdx];

      if (user_id) {
        console.log('check');

        AddUserAccount({
          user_id,
          account: {
            account_number,
            account_owner_name,
            bank_code: bank_code,
          } as any,
        }).then(() => {
          setCurrentInx((prev) => prev + 1);
          onClose();
        });
      } else {
        setCurrentInx((prev) => prev + 1);
        onClose();
      }
    } else {
      if (!toast.isActive(registerToastId)) {
        toast({ ...verifyAccountDuplicated, id: registerToastId });
      }
    }
  };

  return (
    <>
      <Box onClick={handleModalOpen}>{children}</Box>

      <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside" isCentered size={'xl'}>
        <ModalOverlay />
        <ModalContent p={5}>
          <ModalCloseButton />
          <Flex flexDir={'column'} m={10} alignItems={'flex-start'}>
            <HStack mb={5}>
              <CheckCircleIcon w={8} h={8} />
              <Text fontSize={'23px'}>계좌 추가</Text>
            </HStack>
            <Box mb={5}>
              <Text fontSize={'16px'}>추가하실 임대인의 계좌정보를 입력해 주세요.</Text>
            </Box>
            <Table w={'100%'}>
              <Tbody w={'100%'}>
                <TrContainer title="은행" textStyle={'medium14'}>
                  {/* 여기! */}
                  <Field
                    name={`accounts.${isInitialEmpty ? currentIdx : currentIdx + 1}.bank_code`}
                    as={SelectBox}
                    placeholder="은행 선택"
                    fontSize="12px"
                    h="32px"
                    isDisabled={isAuthSucceeded}
                  >
                    {bank_data.data.map((option) => (
                      <option key={option.bankId} value={option.bankId}>
                        {option.bankName}
                      </option>
                    ))}
                  </Field>
                </TrContainer>
                <TrContainer title="계좌번호" textStyle={'medium14'}>
                  <Field
                    name={`accounts.${isInitialEmpty ? currentIdx : currentIdx + 1}.account_number`}
                    as={CustomInput}
                    placeholder="'-'없이 숫자만 입력해 주세요."
                    isDisabled={isAuthSucceeded}
                    px={4}
                  />
                </TrContainer>
                <TrContainer title="예금주명" textStyle={'medium14'}>
                  <HStack>
                    <Field
                      name={`accounts.${isInitialEmpty ? currentIdx : currentIdx + 1}.account_owner_name`}
                      as={CustomInput}
                      placeholder="예금주명을 입력해 주세요."
                      isDisabled={isAuthSucceeded}
                      px={4}
                    />
                    <Button
                      variant="basic"
                      style={{ height: '32px' }}
                      onClick={handleClickAuth}
                      isLoading={isLoading}
                      isDisabled={!isAbleClickAuth}
                    >
                      {isAuthSucceeded ? '인증 완료' : '실계좌 인증'}
                    </Button>
                  </HStack>
                </TrContainer>
              </Tbody>
            </Table>
            <HStack w="full" justify={'center'} mt={4}>
              <Button variant={'gray'} onClick={handleCancelRegister} w="114px">
                취소
              </Button>
              <Button variant={'basic'} onClick={handleClickRegister}>
                계좌 등록하기
              </Button>
            </HStack>
          </Flex>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddAccountInfosModal;

import { matchPath } from 'react-router-dom';
import { RouteUserType, SubTitleInfosType, SubTitleInfoType, UndefinedNull } from '../../../types/common/CommonType';
import _ from 'lodash';
import * as Validator from './Validator';
import { AxiosResponse } from 'axios';
import { UseQueryResult } from 'react-query';
import { getLocalStorageStringItem, setLocalStorageStringItem } from './localstorage';
import { cachedItemList } from '../../../statics/common/data/localstorage';
import { bank_data } from '../../../statics/common/data/bank';

export const getRouteUserTypeFromUrl = (url: string): RouteUserType => {
  return url.split('/')[1] as RouteUserType;
};

export const getCompanyUserTypePathKeyFromLocalStorage = () => {
  let companyType = getLocalStorageStringItem('--bohome-company-type') ?? 'company';
  let userType = getLocalStorageStringItem('--bohome-user-type') ?? 'master';
  return {
    company_type: companyType,
    user_type: userType,
    path: `/${companyType}/${userType}`,
    key: `/${companyType}/${userType}`,
  };
};

type handleTitleAndSubtitleInfosTypeProp = {
  routeUserType: RouteUserType;
  pathname: string;
};
type Return = [string, SubTitleInfosType];
export const handleTitleAndSubtitleInfosType = (props: handleTitleAndSubtitleInfosTypeProp): Return => {
  const { routeUserType, pathname } = props;
  let title = '';
  let subTitleInfos: SubTitleInfosType = [];
  switch (routeUserType) {
    case 'admin':
      switch (true) {
        case !!matchPath('/admin/dashboard/*', pathname):
          title = '대시보드';
          subTitleInfos = [
            {
              subTitle: '고객사',
              router_name: '/admin/dashboard/companies',
            },
            {
              subTitle: '유저',
              router_name: '/admin/dashboard/users',
            },
            {
              subTitle: '중개사',
              router_name: '/admin/dashboard/agencies',
            },
            {
              subTitle: '정산관리',
              router_name: '/admin/dashboard/settlement',
            },
          ];
          break;
        case !!matchPath('/admin/manage/customer/*', pathname):
          title = '회원 관리';
          subTitleInfos = [
            {
              subTitle: '임직원',
              router_name: '/admin/manage/customers/user',
            },
            {
              subTitle: '고객사',
              router_name: '/admin/manage/customers/company',
            },
            {
              subTitle: '중개사',
              router_name: '/admin/manage/customers/agency',
            },
            {
              subTitle: '임대인',
              router_name: '/admin/manage/customers/host',
            },
          ];
          break;
        case !!matchPath('/admin/manage/companies/*', pathname):
          title = '구독 관리';
          subTitleInfos = [
            {
              subTitle: '구독 요청',
              router_name: '/admin/manage/companies/new',
            },
            {
              subTitle: '구독 중',
              router_name: '/admin/manage/companies/existing',
            },
          ];
          break;
        case !!matchPath('/admin/manage/supportpayment/*', pathname):
          title = '월세 지원 관리';
          subTitleInfos = [
            {
              subTitle: '메인',
              router_name: '/admin/manage/supportpayment/main',
            },
            {
              subTitle: '사전 등록',
              router_name: '/admin/manage/supportpayment/preservicerequests',
            },
            {
              subTitle: '정산 관리',
              router_name: '/admin/manage/supportpayment/settlements/subscription',
            },
          ];
          break;
        default:
          break;
      }
      break;
  }
  return [title, subTitleInfos];
};

export const parseBlank = (arg: any) => {
  let result;
  try {
    if (
      arg === '' ||
      arg === 'undefined' ||
      _.isUndefined(arg) ||
      arg === 'null' ||
      _.isNull(arg) ||
      arg === 'NaN' ||
      _.isNaN(arg)
    ) {
      result = undefined;
    } else {
      result = arg;
    }
  } catch (err) {
    console.log(err);
  } finally {
    return result;
  }
};

export const isBlank = (arg: any) => {
  let result = false;
  try {
    result = !parseBlank(arg);
  } catch (err) {
    console.log(err);
  } finally {
    return result;
  }
};

export const parseJsonString = (arg: any) => {
  let result = undefined;
  try {
    if (parseBlank(arg)) {
      if (typeof arg == 'string') {
        if (Validator.isJsonString(arg)) {
          result = JSON.parse(arg);
        }
      } else if (typeof arg == 'object') {
        arg = JSON.stringify(arg);
        if (Validator.isJsonString(arg)) {
          result = JSON.parse(arg);
        }
      }
    }
  } catch (err) {
    console.log(err);
  } finally {
    return result;
  }
};

export const getAxiosData = <Type>(data: AxiosResponse<Type>): Type => {
  return data.data;
};

export const currentParamsObject = (toNumeric = true) => {
  const { search } = location;
  const params = new URLSearchParams(search);
  return paramsToObject(Array.from(params), toNumeric);
};

export const paramsToObject = (entries: Array<[string, string]>, toNumeric = true) => {
  let result = {} as any;
  if (toNumeric) {
    for (const [key, value] of entries) {
      isNumeric(value) ? (result[key] = parseInt(value)) : (result[key] = value);
    }
  } else {
    entries?.forEach((elem) => {
      const [key, value] = elem;
      result[key] = value;
    });
  }
  return result;
};

export const ToAllFilledObj = (obj: object) => {
  return _.pickBy(obj, (value, key) => {
    if (_.isNumber(value) || _.isString(value) || (_.isBoolean(value) && value)) {
      return _.identity(value);
    } else {
      if (!_.isEmpty(value)) {
        return _.identity(value);
      }
    }
  });
};

export const isNumeric = (arg: any) => {
  return typeof arg == 'number' ? true : !_.isNaN(parseInt(arg));
};

export const getChachedItem = () => {
  let result = {};
  try {
    result = cachedItemList?.reduce((pre, curr) => {
      return { ...pre, [curr]: getLocalStorageStringItem(curr) };
    }, {} as any);
  } catch (err) {
    console.error(err);
  } finally {
    return result;
  }
};

export const staticThreeCommafy = (number: number | undefined | null) => {
  if (number === null || _.isUndefined(number) || _.isNaN(_.toNumber(number))) {
    return '-';
  }

  let num = number;
  if (_.isString(num)) {
    num = parseInt(num);
    if (isNaN(num)) {
      return '-';
    }
  }
  const rounded = _.round(num, 2);
  let str = rounded.toString();
  if (str.length >= 5) {
    str = str.replace(/(\d)(?=(\d{3})+$)/g, '$1,');
  }
  return str;
};

export const parseBoolean = (arg: any) => {
  let result = undefined;
  try {
    switch (arg) {
      case true:
      case 'true':
      case 'approved':
        result = true;
        break;

      case false:
      case 'false':
      case 'rejected':
        result = false;
        break;

      case undefined:
      case 'undefined':
      case '':
        result = undefined;
        break;

      default:
        result = undefined;
    }
  } catch (err) {
    console.log(err);
  } finally {
    return result;
  }
};

export const toBooleanString = (arg: any) => {
  let result = undefined;
  try {
    switch (arg) {
      case true:
      case 'true':
        result = 'true';
        break;

      case false:
      case 'false':
        result = 'false';
        break;

      case undefined:
      case 'undefined':
      case '':
        result = undefined;
        break;

      default:
        result = undefined;
    }
  } catch (err) {
    console.log(err);
  } finally {
    return result;
  }
};

export const getCompanyType = () => {
  return getLocalStorageStringItem('--bohome-company-type') ?? 'company';
};

export const getUserType = () => {
  return getLocalStorageStringItem('--bohome-user-type') ?? 'master';
};

interface GetPageRangeProps {
  page: number;
  range: number;
}
export const getPageRange = ({ page, range }: GetPageRangeProps) => {
  return Math.ceil(page / range);
};

export const getLastFourNumber = (arg?: string) => {
  let result = '0000';
  try {
    arg = arg || '';
    let validateLastFourNumber = /(.*)([0-9]{4})$/;
    if (validateLastFourNumber.test(arg)) {
      result = arg.replace(validateLastFourNumber, `$2`);
    }
  } catch (err) {
    console.log(err);
  } finally {
    return result;
  }
};

export const bankNameTrans = (bank_id: number | string | undefined) => {
  const _bank_id = typeof bank_id === 'string' ? parseInt(bank_id) : bank_id;
  return bank_data.data.find((obj) => obj.bankId === _bank_id)?.bankName;
};

export const getOwnPropertyNames = (data: any) => {
  let result: any[] = [];
  try {
    let _data = _.cloneDeep(data);
    for (let key in _data) {
      result.push(key);
    }
  } catch (err) {
    console.error(err);
  } finally {
    return result;
  }
};

import { VStack, Box, Center, Button, HStack } from '@chakra-ui/react';
import { Field, useFormikContext } from 'formik';
import { initialValuesProps } from '.';
import { FRadioButtonGroup } from '../../Radio';
import { alternative_radio } from '../../../../statics/common/data/radio';

export const OneSelectHasCompany = () => {
  const { values, setFieldValue } = useFormikContext<initialValuesProps>();
  const isAbleNextStep = values.has_company !== 'not-selected';

  const handleClickButton = () => {
    switch (values.has_company) {
      case 'true':
        setFieldValue('step', 'two_complex-register');

        break;
      case 'false':
        setFieldValue('step', 'two_simple-register');

        break;
      case 'not-selected':
        break;
    }
  };

  return (
    <>
      <VStack fontSize={'20px'} flexDir={'column'} spacing={4} alignItems={'center'}>
        <Box>{'임대인이 사업자인가요?'}</Box>
        <HStack>
          <Button variant={'gray'} w="120px" onClick={() => setFieldValue('step', 'two_complex-register')}>
            예
          </Button>
          <Button variant={'basic'} w="120px" onClick={() => setFieldValue('step', 'two_simple-register')}>
            아니요
          </Button>
        </HStack>
      </VStack>
    </>
  );
};

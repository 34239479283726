import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Image,
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useRadio,
  useRadioGroup,
  VStack,
} from '@chakra-ui/react';
import { Form, Formik, useFormikContext } from 'formik';
import { useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { WBox } from '../../../Box';
import { ModalDisclosureProps } from '../../base';
import { StyledSearch } from '../../../Search';
import { useGetBuildings } from '../../../../../lib/admin/hooks/query';
import { buildingFormsEnum } from '../../../../../lib/common/utils/axios/types';
import { buildingFormTypeTrans, buildingTypeTrans } from '../../../../../lib/subscriber/utils/utils';
import { CheckCircleIcon } from '../../../../../statics/common/icons';

// const BuildingLists = async () => {
//   const response = await faxios
//     ?.then((instance) => {
//       return instance?.get<any>('v1/agency/master/building');
//     })
//     .catch((error) => {
//       console.log(error);
//     });

//   return response?.data;
// };

const NoStyleRadio = (props: any) => {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label" w="full">
      <input {...input} />
      <HStack
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderColor={'silverGray'}
        borderRadius={'md'}
        boxShadow={'sm'}
        w={'full'}
        _checked={{
          bg: '#ECF1FF',
          borderColor: '#183377',
        }}
        transition={'0.18s linear'}
        px={5}
        py={3}
      >
        {props.children}
      </HStack>
    </Box>
  );
};

interface RetrieveBuildingProps extends ModalDisclosureProps {
  step: 0 | 1 | 2;
  setStep: React.Dispatch<React.SetStateAction<0 | 1 | 2>>;
}

const RetrieveBuilding: React.FC<RetrieveBuildingProps> = ({ isOpen, onClose, step, setStep }) => {
  // const [selectedId, setSelectedId] = useState<string>('');
  const { values, setFieldValue } = useFormikContext<any>();
  const [selectedItem, setSelectedItem] = useState<string>('');
  const [search, setSearch] = useState<string>('');
  const { data: buildingLists } = useGetBuildings({
    options: {
      enabled: isOpen,
    },
    axiosConfig: {
      params: {
        search,
      },
    },
  });

  console.log('buildingLists =>', buildingLists);

  // const { data: selectedBuilding, refetch } = useGetBuilding(selectedId);

  // useEffect(() => {
  //   if (selectedId) {
  //     refetch();
  //   }
  // }, [selectedId]);

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'retrieve_group',
    defaultValue: 1,
    value: selectedItem,
    onChange: (value) => {
      setSelectedItem(value);
    },
  });

  const handleConfirmClick = () => {
    const parsedData = JSON.parse(selectedItem);
    console.log('parsedData => ', parsedData);

    setFieldValue('host_id', parsedData.host_id);
    setFieldValue('building_id', parsedData._id);
    setFieldValue('room_types', parsedData.room_types);
    setFieldValue('building_form', parsedData.building_form);
  };

  const group = getRootProps();
  // console.log('selected => ', selectedId);
  // console.log('selectedBuilding => ', selectedBuilding);
  // console.log('selectedId => ', selectedId);
  return (
    <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside" size={'2xl'} motionPreset="slideInRight">
      <ModalOverlay />
      <ModalContent px={'60px'} pt={'60px'} pb={'40px'} overflowY={'auto'}>
        <ModalHeader fontSize={'24px'} color="#414141" px={0} pb={6}>
          <VStack alignItems={'flex-start'}>
            <HStack>
              <CheckCircleIcon w={'32px'} h={'32px'} color="zetaBlue" />
              <Text>건물 정보 불러오기</Text>
            </HStack>
            <Text fontSize={'18px'} fontWeight={'normal'}>
              이미 등록한 건물에 방을 추가하시나요? 건물을 선택해 주세요.
            </Text>
          </VStack>
        </ModalHeader>
        <WBox p={3}>
          <Formik initialValues={{ search: '' }} onSubmit={(value) => console.log(value)}>
            {(props) => (
              <Form style={{ marginBottom: '12px' }}>
                <StyledSearch
                  placeholder="건물명 / 주소"
                  inputProps={{
                    onChange: (e) => {
                      setSearch(e.target.value);
                    },
                  }}
                />
              </Form>
            )}
          </Formik>
          {/* 리스트 */}
          <Scrollbars style={{ height: '320px', width: '100%' }}>
            <VStack mt={2} {...group} h={'320px'}>
              {buildingLists ? (
                buildingLists.map((building, index) => {
                  const radio = getRadioProps({ value: JSON.stringify(building) });
                  // const radio = getRadioProps({ value: building._id });
                  return (
                    <Box key={index} w={'full'}>
                      <NoStyleRadio {...radio}>
                        <Image
                          boxSize={'67px'}
                          src={building.pictures[0]}
                          alt={'room-thumnail'}
                          fallbackSrc="https://via.placeholder.com/67?text=No+Image"
                        />
                        <VStack spacing={0} w={'full'} alignItems={'flex-start'}>
                          <Flex>
                            <Text textStyle={'medium14'}>{building.address}&nbsp;</Text>
                            {building.name && <Text textStyle={'medium14'}>({building.name})</Text>}
                          </Flex>
                          <Text fontSize={'12px'}>
                            {/* {'빌라·주택'} / {'원룸'} */}
                            {buildingTypeTrans(building.building_type)} /{' '}
                            {buildingFormTypeTrans(building.building_form as buildingFormsEnum)}
                          </Text>
                        </VStack>
                      </NoStyleRadio>
                    </Box>
                  );
                })
              ) : (
                <Center h="full">등록된 건물이 없습니다.</Center>
              )}
            </VStack>
          </Scrollbars>
        </WBox>
        <Flex justifyContent={'center'}>
          <Button
            variant={'basic'}
            mt={10}
            w="80%"
            style={{ height: '40px' }}
            isDisabled={buildingLists && selectedItem ? false : true}
            onClick={() => {
              handleConfirmClick();
              onClose();
              setStep(1);
            }}
          >
            확정하기
          </Button>
        </Flex>
      </ModalContent>
    </Modal>
  );
};

export default RetrieveBuilding;

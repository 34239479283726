// ../agency/manage/requests
// 찜한 요청 탭 select boxes

import { CompanySelectTypeEnum } from '../../../lib/common/utils/axios/types';

export type SelectListNumberType = Array<{
  label: string;
  value: number;
}>;

export type SelectListStringType = Array<{
  label: string;
  value: string;
}>;

export const total_option = [
  {
    label: '전체',
    value: '',
  },
];
export const payment_status_select = [
  {
    label: '계약금',
    value: 'down_payment_expected',
  },
  {
    label: '보증금+월세',
    value: 'balance_payment_expected',
  },
  {
    label: '월세',
    value: 'rent_expected',
  },
];

export const payment_method_select = [
  {
    label: '가상계좌',
    value: 'virtual_account',
  },
  {
    label: 'CMS 자동이체',
    value: 'cms',
  },
  {
    label: '삼성카드',
    value: 'samsung_card',
  },
  {
    label: '계좌이체',
    value: 'account_transfer',
  },
  {
    label: '관리자 승인',
    value: 'admin_privilege',
  },
];

export const payment_method_with_total_select = total_option.concat(payment_method_select);

export const payment_method_select_more = (more: SelectListStringType): SelectListStringType => {
  // function
  return payment_method_select.concat(more);
};

export const like_request_select_1 = [
  {
    label: '전체 보기',
    value: 1,
  },
  {
    label: '신규요청',
    value: 2,
  },
  {
    label: '제안한요청',
    value: 3,
  },
];

export const like_request_select_2_2 = [
  {
    label: '전체보기',
    value: 1,
  },
  {
    label: '신규요청',
    value: 2,
  },
  {
    label: '종료예정',
    value: 3,
  },
];

export const like_request_select_2_3 = [
  {
    label: '전체보기',
    value: 1,
  },
  {
    label: '전달대기',
    value: 2,
  },
  {
    label: '전달완료',
    value: 3,
  },
  {
    label: '투어예약',
    value: 4,
  },
  {
    label: '계약진행',
    value: 5,
  },
];

// 건물 (Building)

// 1. 건물 유형
export const building_type = [
  { label: '단독주택', value: 'detached_house' },
  { label: '공동주택', value: 'apartment_house' },
  { label: '제1종 근린생활시설', value: 'neighborhood_living_1' },
  { label: '제2종 근린생활시설', value: 'neighborhood_living_2' },
  { label: '문화 및 집회시설', value: 'cultural_gathering' },
  { label: '종교시설', value: 'religion' },
  { label: '판매시설', value: 'sale' },
  { label: '운수시설', value: 'transportation' },
  { label: '의료시설', value: 'medical' },
  { label: '교육연구시설', value: 'education_research' },
  { label: '노유자(幼者: 노인 및 어린이)시설', value: 'elderly_children' },
  { label: '수련시설', value: 'training' },
  { label: '운동시설', value: 'exercise' },
  { label: '업무시설', value: 'business' },
  { label: '숙박시설', value: 'accommodation' },
  { label: '위락(慰)시설', value: 'amusement' },
  { label: '공장', value: 'factory' },
  { label: '창고시설', value: 'ware_house' },
  { label: '위험물 저장 및 처리 시설', value: 'danger' },
  { label: '자동차 관련 시설', value: 'car' },
  { label: '동물 및 식물 관련 시설', value: 'animal_plant' },
  { label: '자원순환 관련 시설', value: 'resource_circulation' },
  { label: '교정(矯正) 및 군사 시설', value: 'military' },
  { label: '방송통신시설', value: 'broadcast' },
  { label: '발전시설', value: 'power_plant' },
  { label: '묘지 관련 시설', value: 'cemetery' },
  { label: '관광 휴게시설', value: 'tourist' },
  { label: '장례시설', value: 'funeral' },
  { label: '야영장 시설', value: 'campsite' },
  { label: '미등기건물', value: 'unregistered' },
  { label: '그 밖에 토지의 정착물', value: 'others' },
];

// 2. 건물 형태
// 수정
export const building_form = [
  {
    label: '빌라',
    value: 'villa',
  },
  {
    label: '오피스텔',
    value: 'officetel',
  },
  {
    label: '연립',
    value: 'row_house',
  },
  {
    label: '단독',
    value: 'house',
  },
  {
    label: '다세대',
    value: 'multi_family_house_s',
  },
  {
    label: '다가구',
    value: 'multi_family_house_g',
  },
  {
    label: '전원주택',
    value: 'country_house',
  },
  {
    label: '상가주택',
    value: 'commercial_house',
  },
  {
    label: '한옥주택',
    value: 'hanok_house',
  },
  {
    label: '쉐어하우스',
    value: 'share_house',
  },
  {
    label: '아파트',
    value: 'apartment',
  },
  {
    label: '프리미엄오피스텔',
    value: 'coliving_house',
  },
];

// 방

export const contract_period = [
  {
    label: '3',
    value: 3,
  },
  // {
  //   label: '4',
  //   value: '4',
  // },
  // {
  //   label: '5',
  //   value: '5',
  // },
  {
    label: '6',
    value: 6,
  },
  // {
  //   label: '7',
  //   value: '7',
  // },
  // {
  //   label: '8',
  //   value: '8',
  // },
  // {
  //   label: '9',
  //   value: '9',
  // },
  // {
  //   label: '10',
  //   value: '10',
  // },
  // {
  //   label: '11',
  //   value: '11',
  // },
  {
    label: '12',
    value: 12,
  },
  {
    label: '24',
    value: 24,
  },
  // {
  //   label: '24',
  //   value: '24',
  // },
];

export const room_direction = [
  {
    label: '동',
    value: 'east',
  },
  {
    label: '서',
    value: 'west',
  },
  {
    label: '남',
    value: 'south',
  },
  {
    label: '북',
    value: 'north',
  },
  {
    label: '남동',
    value: 'south_east',
  },
  {
    label: '남서',
    value: 'south_west',
  },
  {
    label: '북동',
    value: 'north_east',
  },
  {
    label: '북서',
    value: 'north_west',
  },
];

export const heating_type = [
  {
    label: '개별난방',
    value: 'individual_heating',
  },
  {
    label: '중앙난방',
    value: 'central_heating',
  },
  {
    label: '지역난방',
    value: 'district_heating',
  },
  {
    label: '혼합',
    value: 'mix_heating',
  },
  {
    label: '없음',
    value: 'none',
  },
];
export const remodeling_season = [
  {
    label: '최근 2년 이내',
    value: 'first',
  },
  {
    label: '5년 이내',
    value: 'within_few_years',
  },
  {
    label: '입주 시 조율 가능',
    value: 'tunable',
  },
  {
    label: '해당 사항 없음',
    value: 'none',
  },
];

export const contract_status = [
  {
    label: '구독 요청',
    value: 'confirm_expected',
  },
  {
    label: '서명 대기',
    value: 'sign_expected',
  },
  {
    label: '결제 대기',
    value: 'total_contract_payment_expected',
  },
  {
    label: '계약 완료',
    value: 'subscription_payment_expected',
  },
];

export const global_status_select = [
  {
    label: '-',
    value: 'none',
  },
  {
    label: '대기중',
    value: 'pending',
  },
  {
    label: '승인',
    value: 'approved',
  },
  {
    label: '진행중',
    value: 'proceeding',
  },
  {
    label: '완료',
    value: 'completed',
  },
  {
    label: '반려',
    value: 'rejected',
  },
  {
    label: '취소',
    value: 'cancelled',
  },
  {
    label: '지연',
    value: 'delayed',
  },
  {
    label: '확인',
    value: 'checked',
  },
  {
    label: '철회',
    value: 'withdrawn',
  },
  {
    label: '폐기',
    value: 'deprecated',
  },
  {
    label: '종료',
    value: 'finished',
  },
];

export const lease_payment_proxy_status = [
  {
    label: '-',
    value: 'none',
  },
  {
    label: '요청중',
    value: 'pending',
  },
  {
    label: '승인',
    value: 'approved',
  },
  {
    label: '진행중',
    value: 'proceeding',
  },
  {
    label: '완료',
    value: 'completed',
  },
  {
    label: '거절',
    value: 'rejected',
  },
  {
    label: '취소',
    value: 'cancelled',
  },
];

export const certificate_issue_type_object = {
  none: '미발행',
  cash_receipt: '현금영수증',
  tax_invoice: '세금계산서',
};

export const certificate_issue_type_select = [
  {
    label: '미발행',
    value: 'none',
  },
  {
    label: '현금영수증',
    value: 'cash_receipt',
  },
  {
    label: '세금계산서',
    value: 'tax_invoice',
  },
];

export const global_payment_status_trans = {
  none: '납부 전',
  overdue: '연체',
  paid: '납부 완료',
};

export const global_payment_status_select = [
  {
    label: '납부 전',
    value: 'none',
  },
  {
    label: '연체',
    value: 'overdue',
  },
  {
    label: '납부 완료',
    value: 'paid',
  },
];

export const lease_payment_type_with_total_select = total_option.concat(global_payment_status_select);

export const certificate_issue_type_with_total_select = total_option.concat(certificate_issue_type_select);

export const company_with_total_select = [
  {
    label: '전체',
    value: 'all',
  },
  {
    label: '고객사',
    value: 'subscriber',
  },
  {
    label: '중개사',
    value: 'agency',
  },
  {
    label: '어드민',
    value: 'admin',
  },
  {
    label: '임대',
    value: 'lease',
  },
];

export const user_with_total_select = [
  {
    label: '전체',
    value: 'all',
  },
  {
    label: '임직원',
    value: 'tenant',
  },
  {
    label: '임대인',
    value: 'host',
  },
  {
    label: '마스터',
    value: 'master',
  },
  {
    label: '매니저',
    value: 'manager',
  },
];

export const user_with_total_all_select = [
  {
    label: '전체',
    value: 'all',
  },
  {
    label: '임직원',
    value: 'tenant',
  },
  {
    label: '임대인',
    value: 'host',
  },
  {
    label: '마스터',
    value: 'master',
  },
  {
    label: '매니저',
    value: 'manager',
  },
];

export const user_with_total_select_subscriber = [
  {
    label: '전체',
    value: 'all',
  },
  {
    label: '임직원',
    value: 'tenant',
  },
  {
    label: '마스터',
    value: 'master',
  },
  {
    label: '매니저',
    value: 'manager',
  },
];

export const user_with_total_select_agency = [
  {
    label: '전체',
    value: 'all',
  },
  {
    label: '마스터',
    value: 'master',
  },
  {
    label: '매니저',
    value: 'manager',
  },
];

export const user_with_total_select_admin = [
  {
    label: '전체',
    value: 'all',
  },
  {
    label: '마스터',
    value: 'master',
  },
  {
    label: '매니저',
    value: 'manager',
  },
];

export const user_with_total_select_lease = [
  {
    label: '전체',
    value: 'all',
  },
  {
    label: '임대인',
    value: 'host',
  },
];

export const user_with_total_optional_select = {
  all: user_with_total_all_select,
  subscriber: user_with_total_select_subscriber,
  agency: user_with_total_select_agency,
  admin: user_with_total_select_admin,
  lease: user_with_total_select_lease,
};

export const choose_user_with_total_optional_select = (company_select_type: CompanySelectTypeEnum | undefined) => {
  if (!company_select_type) {
    return [];
  } else {
    return user_with_total_optional_select[company_select_type];
  }
};

export const proxy_payment_status_selectbox = [
  {
    label: '연체',
    value: 'false',
  },
  {
    label: '납부 완료',
    value: 'true',
  },
];

export const subscription_payment_status = [
  {
    label: '연체',
    value: 'false',
  },
  {
    label: '납부 완료',
    value: 'true',
  },
];

export const settlement_payment_status = [
  {
    label: '미정산',
    value: 'false',
  },
  {
    label: '정산 완료',
    value: 'true',
  },
];

/** 공지관련 모든 select objects */
export const announcement_select = {
  type: [
    {
      label: '일반',
      value: 'default',
    },
    {
      label: '긴급',
      value: 'urgent',
    },
    {
      label: '업데이트',
      value: 'updated',
    },
  ],
  company_target: company_with_total_select,
  user_target: user_with_total_select,
};

export const minmax_select = (minimum: number, maximum: number) => {
  const item = [];
  for (let min = minimum; min <= maximum; min++) {
    item.push({
      label: min.toString(),
      value: min,
    });
  }
  return item;
};

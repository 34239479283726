import {
  Avatar,
  Box,
  Button,
  Flex,
  HStack,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Radio,
  RadioGroup,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { isEmptyArray, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { StyledSearch } from '../../Search';
import { formikInitialValuesProps } from '../ConfirmContractModal';
import RegisterNewHostModal from '../RegisterNewHostModal';
import ExistingHostIdTooltip from './tooltip';
import * as Type from '../../../../types/common/CommonType';
import { useGetAllCompaniesAllHosts, useGetAllCompaniesAllHostsOnModal } from '../../../../lib/common/hooks/query';
import Loading from '../../Loading';
import { useLocalParams } from '../../../../lib/common/hooks/Hook';
import LocalPagination from '../../../../components/common/Pagination/LocalPagination';

interface RegisterHostsInfoModalProps {
  disabled?: boolean;
  handleRegisterButton?: (id: string, name: string, accounts: Array<any>) => void;
  necessityAddingHost?: boolean;
}

const RegisterHostsInfoModal: React.FC<RegisterHostsInfoModalProps> = ({
  children,
  disabled = false,
  handleRegisterButton,
  necessityAddingHost,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { values, setFieldValue } = useFormikContext<formikInitialValuesProps>();
  const [queryParams, setQueryParams] = useState<any>({});
  const [search, setSearch] = useState<string>('');
  const [s_hostId, setSHostId] = useState<string>('');
  const [s_hostName, setSHostName] = useState<string>('');
  const [s_accounts, setSAccounts] = useState<Array<any>>([]);

  const host_id_formik = values.host_id;

  const res = useGetAllCompaniesAllHosts({
    axiosConfig: {
      params: {
        page: 1,
        limit: 100,
        search,
      },
    },
  });
  const hostsData = res.data?.data;

  const isSelectClickRegisterButton = s_hostId && s_hostName;

  const onClickRegisterButton = () => {
    if (handleRegisterButton) {
      handleRegisterButton(s_hostId, s_hostName, s_accounts);
    } else {
      setFieldValue('host_id', s_hostId);
      setFieldValue('host_name', s_hostName);
      setFieldValue('accounts', s_accounts);
      setFieldValue('is_clicked_register_host_button_once', true);
    }
    onClose();
  };
  const { isLoading } = res;
  if (isLoading) return <Loading />;
  return (
    <>
      <Box onClick={onOpen}>{children}</Box>

      <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside" isCentered size={'xl'}>
        <ModalOverlay />
        <ModalContent p={5} h="full">
          {/* <LocalPagination /> */}
          <ModalCloseButton />
          <Box w="full" h="full">
            <Flex flexDir={'column'} m={10} alignItems={'flex-start'} h="full">
              <HStack>
                <Text fontSize={'23px'}>임대인 정보 등록</Text>
              </HStack>
              <Box w="full" h="full">
                <VStack fontSize={'18px'} alignItems={'start'}>
                  <VStack spacing={0} alignItems={'start'} mt={2} mb={4}>
                    <Box>{'임대인을 선택해 주세요.'}</Box>
                  </VStack>
                </VStack>
                <Box mb={4}>
                  <StyledSearch
                    w={'full'}
                    placeholder="호스트명 / 이메일 / 연락처로 검색"
                    inputProps={{
                      onChange: (e) => {
                        setSearch(e.target.value);
                      },
                    }}
                  />
                </Box>

                <Box
                  w="full"
                  h="60%"
                  border="1px solid"
                  borderColor={'silverGray'}
                  p={4}
                  bgColor={'lotionWhite'}
                  borderRadius={'md'}
                  boxShadow={'sm'}
                >
                  <Scrollbars style={{ height: '90%' }}>
                    <VStack>
                      {hostsData?.map((hostUser: any, index: number) => {
                        const {
                          user: { user_name, email, phone_number_first, _id, accounts },
                        } = hostUser;
                        const isRegistered = host_id_formik === _id;
                        console.log(index, isRegistered);
                        return (
                          <ExistingHostIdTooltip isRegistered={isRegistered} key={index}>
                            <Box
                              key={index}
                              w="full"
                              border="1px solid"
                              borderColor={isRegistered ? 'ActiveBorder' : 'silverGray'}
                              p={2}
                              borderRadius={'md'}
                              boxShadow={'sm'}
                              bgColor={isRegistered ? 'aliceblue' : 'white'}
                            >
                              <RadioGroup
                                onLoad={() => {
                                  return;
                                }}
                                onChange={(value) => {
                                  console.log(value);

                                  setSHostId(value);
                                  setSHostName(user_name);
                                  if (!isEmptyArray(accounts)) {
                                    setSAccounts(
                                      accounts.map((account: any, index: number) => {
                                        return {
                                          bank_code: account?.bank_code,
                                          account_number: account?.account_number,
                                          account_owner_name: account?.account_owner_name,
                                          is_auth: true,
                                          is_added: true,
                                        };
                                      }),
                                    );
                                  }
                                }}
                                // value={values.host_id}
                                value={s_hostId}
                              >
                                <Radio value={_id} name={'123'}>
                                  <HStack spacing={4}>
                                    <Avatar name={user_name} />
                                    <Text maxW={'60px'} w={'60px'}>
                                      {user_name}
                                    </Text>
                                    <VStack fontSize={'11px'} spacing={1}>
                                      <HStack spacing={1}>
                                        <Text fontWeight={'bold'}>이메일</Text>
                                        <Text>{email}</Text>
                                      </HStack>
                                      <HStack spacing={1} justify={'start'} w="full">
                                        <Text fontWeight={'bold'}>연락처</Text>
                                        <Text>{phone_number_first}</Text>
                                      </HStack>
                                    </VStack>
                                  </HStack>
                                </Radio>
                              </RadioGroup>
                            </Box>
                          </ExistingHostIdTooltip>
                        );
                      })}
                    </VStack>
                  </Scrollbars>
                  <Flex pt={3} justify={'end'}>
                    <RegisterNewHostModal>
                      <Button variant={'gray'}>신규 등록</Button>
                    </RegisterNewHostModal>
                  </Flex>
                </Box>

                <HStack w="full" mt={'30px'} justify={'center'}>
                  <Button variant={'gray'} w={'140px'} fontSize={'14px'} fontWeight={'bold'} onClick={onClose}>
                    취소
                  </Button>
                  <Button
                    variant={'basic'}
                    w={'140px'}
                    fontSize={'14px'}
                    fontWeight={'bold'}
                    isDisabled={necessityAddingHost && !isSelectClickRegisterButton}
                    onClick={onClickRegisterButton}
                  >
                    {isSelectClickRegisterButton ? '등록' : '임대인 없이 등록하기'}
                  </Button>
                </HStack>
              </Box>
            </Flex>
          </Box>
        </ModalContent>
      </Modal>
    </>
  );
};

export default RegisterHostsInfoModal;

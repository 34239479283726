import { Box, Button, Center, VStack } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { CheckCircleIcon } from '../../../../../statics/common/icons';
import React from 'react';

const Five: React.FC = () => {
  return (
    <Center flexDir={'column'} m={10}>
      <CheckCircleIcon />
      <VStack spacing={0} fontSize={'25px'}>
        <Box my={10}>회원가입 신청 완료</Box>
        <VStack spacing={50}>
          <VStack fontSize={'17px'}>
            <Box>회원가입 신청이 완료 되었습니다.</Box>
          </VStack>
          <Link to={'/login/agency'}>
            <Button variant={'basic'} w={'210px'} height={'40px'}>
              확인
            </Button>
          </Link>
        </VStack>
      </VStack>
    </Center>
  );
};

export default Five;

import { Box, HStack, Table, Tbody, Td, Text, Thead, Tr, VStack } from '@chakra-ui/react';
import { isUndefined } from '@chakra-ui/utils';
import { useSearchParams } from 'react-router-dom';
import CommonChatButton from '../../../../../../../../components/admin/Button/CommonChatButton';
import { CustomEditable } from '../../../../../../../../components/common/Editable';
import QueryParamsLayout from '../../../../../../../../layouts/common/main/QueryParamsLayout';
import ChangeCertificateIssueStatus from '../../../../../../../../components/common/SelectBox/ChangeCertificateIssueStatus';
import ChangeSettlementPaymentStatus from '../../../../../../../../components/common/SelectBox/ChangeSettlementPaymentStatus';
import ChangeLeasePaymentStatus from '../../../../../../../../components/common/SelectBox/LeasePaymentStatus';
import ChangeLeaseProxyPaymentStatus from '../../../../../../../../components/common/SelectBox/LeaseProxyPaymentStatus';
import { WTd } from '../../../../../../../../components/common/Table/Td';
import { WTh } from '../../../../../../../../components/common/Table/Th';
import TextLink from '../../../../../../../../components/common/Link/TextLink';
import { TdFullWContainer } from '../../../../../../../../components/common/Table/Tr';
import { useGetCompanyManager, useGetCompanyUserLeaseContracts } from '../../../../../../../../lib/admin/hooks/query';
import { paymentMethodTypeTrans } from '../../../../../../../../lib/subscriber/utils/utils';
import { datedotformat } from '../../../../../../../../lib/common/utils/dateformatter';
import { staticThreeCommafy } from '../../../../../../../../lib/common/utils/digitformatter';
import { FaPlusInCircle } from '../../../../../../../../statics/common/icons';
import { request_type_transition } from '../../../../../../subscriber/manage/employee/UsageLists';
import RegisterManualBillModal from '../../../../../../../../components/common/Modals/RegisterManualBillModal';
import { currentParamsObject, ToAllFilledObj } from '../../../../../../../../lib/common/utils/base';
import Loading from '../../../../../../../../components/common/Loading';

const LSettlement = () => {
  const { id, page, limit } = currentParamsObject(false);
  const { data: cpManager } = useGetCompanyManager(id || '');

  const _params = currentParamsObject(false);
  const params = ToAllFilledObj(_params);

  const res = useGetCompanyUserLeaseContracts(id || '', {
    axiosConfig: {
      params,
    },
  });
  const { data: userContracts } = res;

  if (isUndefined(cpManager)) {
    return null;
  }

  const {
    employee: {
      _id: employee_id,
      user_name,
      email,
      phone_number_first,
      createdAt,
      company_name,
      services: serviceArrData,
      chat_rooms,
      virtual_account,
    },
  } = cpManager;

  console.log(userContracts);
  const { isLoading } = res;
  if (isLoading) return <Loading />;
  return (
    <VStack justifyContent={'start'} w={'100%'} h="full" spacing={6} id={'here'}>
      {/* 가상계좌 정보 */}
      <VStack spacing={3} justifyContent={'start'} align={'flex-start'} w={'100%'}>
        <HStack w={'full'} spacing={0} justify={'space-between'}>
          <Box fontWeight={'bold'} fontSize={'18px'} textAlign={'start'} w={'112px'}>
            가상계좌 정보
          </Box>
          <CommonChatButton chatRooms={chat_rooms} user_ids={[employee_id]} />
        </HStack>
        {/* 테이블 style={{}} 에 포지션 start 이런거 찾아보기. */}
        <Box>
          <Table w={'100%'} variant="simple">
            {/* <Table w={'100%'} variant="simple" style={{ tableLayout: 'fixed' }}> */}
            <Tbody w={'100%'}>
              <Tr w={'100%'} borderTopWidth={'1px'} borderTopColor={'gainGray'}>
                <TdFullWContainer title={'계좌 소유주명'}>
                  {/* <CustomEditable value={user_name} /> */}
                  <Text> {virtual_account?.account_owner_name || user_name}</Text>
                </TdFullWContainer>
                <TdFullWContainer title={'이용자'}>
                  {/* <CustomEditable value={company_name} /> */}
                  <TextLink to={`/admin/manage/customers/user/detail/linfo?id=${employee_id}`}>{user_name}</TextLink>
                </TdFullWContainer>
                <TdFullWContainer title={'은행'}>
                  <Text>상호저축은행</Text>
                </TdFullWContainer>
              </Tr>
              <Tr>
                <TdFullWContainer title={'계좌 번호'}>{virtual_account?.account_number}</TdFullWContainer>
                <TdFullWContainer title={'제어 금액'}>
                  {virtual_account?.control?.deposit_amount ? `${virtual_account?.control?.deposit_amount}원` : '-'}
                </TdFullWContainer>
                <TdFullWContainer title={''}></TdFullWContainer>
              </Tr>
            </Tbody>
          </Table>
        </Box>
      </VStack>
      {/* 이용 내역 */}
      <VStack spacing={3} justifyContent={'start'} align={'flex-start'} w="full" h="calc(100vh - 428px)">
        <Box w={'100%'} fontWeight={'bold'} fontSize={'18px'} textAlign={'start'} flexGrow={1}>
          이용 내역
        </Box>
        <QueryParamsLayout
          res={res}
          modalOnLayout={
            // 수동 청구서 등록하기
            <Box pos={'absolute'} right={0} bottom={0}>
              <RegisterManualBillModal disabled={false}>
                <FaPlusInCircle />
              </RegisterManualBillModal>
            </Box>
          }
          removeTopBar
        >
          <Table variant="head_silverbg_toplining">
            <Thead bgColor={'#F8F8F8'} borderColor={'gray.100'}>
              <Tr>
                <WTh>청구 종류</WTh>
                <WTh>유저이름</WTh>
                <WTh>송금자명</WTh>
                <WTh>정산(임대인)</WTh>
                <WTh>청구 금액</WTh>
                <WTh>납부 상태</WTh>
                <WTh>정산 상태</WTh>
                <WTh>납기일</WTh>
                <WTh>납부일</WTh>
                <WTh>정산일</WTh>
                <WTh>납부방법</WTh>
                <WTh>증빙발행</WTh>
                {/* <WTh>대납 상태</WTh> */}
              </Tr>
            </Thead>
            <Tbody>
              {userContracts?.result.map((tenant, index) => {
                const {
                  employee: {
                    // 송금인
                    user_name,
                    _id: user_id,
                    lease_contract: {
                      _id: lease_contract_id,
                      contract_start_date,
                      contract_end_date,
                      lease_payment: {
                        _id: lease_payment_id,
                        host,
                        // 납기일
                        expected_payment_date,
                        // 납부일
                        real_payment_date,
                        // 정산일
                        real_transfer_date,
                        payment_method_type,
                        certificate_issue_type,
                        proxy_payment_status,
                        is_paid,
                        is_transferred,
                        price,
                        host_id,
                        contract_payment,
                        management_fee,
                        payment_status,
                      },
                    },
                  },
                } = tenant;
                return (
                  <Tr key={lease_payment_id + index}>
                    <Td>임대료({new Date(expected_payment_date).getMonth() + 1}월)</Td>
                    <Td>
                      <TextLink
                        href={`/admin/manage/customers/user/detail/lsettlement?id=${user_id}`}
                        target={'_blank'}
                      >
                        {user_name}
                      </TextLink>
                    </Td>
                    <Td>{virtual_account?.account_owner_name || user_name}</Td>

                    <Td>
                      <TextLink to={`/admin/manage/customers/host/detail/info?id=${host?._id}`}>
                        {host?.user_name}
                      </TextLink>
                    </Td>
                    <Td>{staticThreeCommafy(contract_payment + management_fee + price)}원</Td>
                    <Td>
                      <ChangeLeasePaymentStatus
                        payment_status={payment_status}
                        lease_payment_id={lease_payment_id}
                      ></ChangeLeasePaymentStatus>
                    </Td>
                    <Td>
                      <ChangeSettlementPaymentStatus
                        is_transferred={is_transferred}
                        lease_payment_id={lease_payment_id}
                      />
                    </Td>
                    <Td>{datedotformat(expected_payment_date)}</Td>
                    <Td>{datedotformat(real_payment_date)}</Td>
                    <Td>{datedotformat(real_transfer_date)}</Td>
                    <Td>{paymentMethodTypeTrans(payment_method_type)}</Td>
                    <Td>
                      <ChangeCertificateIssueStatus
                        certificate_issue_status={certificate_issue_type}
                        lease_payment_id={lease_payment_id}
                      ></ChangeCertificateIssueStatus>
                    </Td>
                    {/* <Td>
                    <ChangeLeaseProxyPaymentStatus
                      lease_proxy_payment_status={proxy_payment_status}
                      lease_payment_id={lease_payment_id}
                    ></ChangeLeaseProxyPaymentStatus>
                  </Td> */}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </QueryParamsLayout>
      </VStack>
    </VStack>
  );
};

export default LSettlement;

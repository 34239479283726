import { Route, RouteProps, Routes } from 'react-router-dom';
import AgencySignUpPage from '../../../pages/common/SignUp/Agency';

const AgencySignUpRoute: React.FC<RouteProps> = () => {
  return (
    <Routes>
      <Route index element={<AgencySignUpPage />} />
    </Routes>
  );
};

export default AgencySignUpRoute;

import { NumberThreeCommafyTrim } from '../base';
// import { isUndefined } from '@chakra-ui/utils';
import { isUndefined, isString, round, isEmpty } from 'lodash';

export const threeCommafy = (number: number) => {
  // const str = number.toString().split('.');
  // if (str[0].length >= 5) {
  //   str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
  // }
  // if (str[1] && str[1].length >= 5) {
  //   str[1] = str[1].replace(/(\d{3})/g, '$1 ');
  // }
  // return str.join('.');

  if (number) {
    const number_str = number.toString();
    const is_leading_zero = number_str[0] === '0';
    if (is_leading_zero) {
      return '';
    }
  }

  const trim = NumberThreeCommafyTrim(number as any);
  const value = deCommafy(trim);
  let str = value.toString();
  // str = str.replace(/^0/g, '');
  if (str.length >= 5) {
    str = str.replace(/(\d)(?=(\d{3})+$)/g, '$1,');
  }
  return str;
};

export const staticThreeCommafy = (number: any) => {
  if (number === null || isUndefined(number) || isNaN(number)) {
    return '-';
  }

  let num = number;
  if (isString(num)) {
    num = parseInt(num);
    if (isNaN(num)) {
      return '-';
    }
  }
  const rounded = round(num, 2);
  let str = rounded.toString();
  if (str.length >= 5) {
    str = str.replace(/(\d)(?=(\d{3})+$)/g, '$1,');
  }
  return str;
};

export const phoneNumberdashfy = (number: null | undefined | number | string) => {
  if (number === null || number === undefined) {
    return 0;
  }
  const str = number.toString();
  // 01012345678 : 3-4-4
  const text = str.replace(/^(\d{3})(\d{4})(\d{4})$/, '$1-$2-$3');

  return text;
};

export const deCommafy = (commafied: string) => {
  const result = commafied.replaceAll(',', '');
  return result;
};

export const deDashfy = (dashfied: string) => {
  const result = dashfied.replaceAll('-', '');
  return result;
};

export const decimalize = (number: number | string | undefined) => {
  if (isUndefined(number)) {
    return '';
  }
  let str = typeof number === 'string' ? number : number.toString();
  if (number < 10) {
    return (str = '0' + str);
  } else {
    return str;
  }
};

import { Grid, GridItem } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import RoomRequestDetail from '../../../layouts/common/RoomRequestDetail/AfterSuggestion';
import ChatList from './ChatList';
import ChatRoom from './ChatRoom';

const ChatPage = () => {
  return (
    <Grid templateColumns="repeat(12, 1fr)" gap={2} w="full" pos={'relative'}>
      {/* Chat List */}
      {/* <GridItem colSpan={3} w="100%" h="100%">
        <ChatList />
      </GridItem> */}
      {/* Chat Room */}
      <GridItem
        colSpan={6}
        w="100%"
        h="100%"
        border={'1px solid'}
        borderColor={'silverGray'}
        borderRadius={'0.3rem'}
        minH={0}
        // pos={'sticky'}
        // overflowY={'scroll'}
      >
        {/* <GridItem w="100%" h="100%" bg="blue.500"> */}
        <ChatRoom />
      </GridItem>
      {/* Request Form */}
      <GridItem colSpan={6} w="100%" h="100%" border={'1px solid'} borderColor={'silverGray'} borderRadius={'0.3rem'}>
        <RoomRequestDetail />
      </GridItem>
    </Grid>
  );
};

export default ChatPage;

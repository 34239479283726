import { Box, BoxProps } from '@chakra-ui/react';
import { DropzoneOptions } from 'react-dropzone';
import { useDropzoneOneFileUpload } from '../../../lib/common/hooks/dropzone/hook';

interface Props {
  funcInSuccessProcess: (file: File) => void;
  customVariant?: string;
}

const OneFileCommonDropzone: React.FC<Props> = ({ funcInSuccessProcess, customVariant = 'basic', children }) => {
  const [useDropzone, style] = useDropzoneOneFileUpload(funcInSuccessProcess, customVariant);
  const { getRootProps, getInputProps } = useDropzone;

  return (
    <Box className="container">
      <Box {...getRootProps({ ...style, height: '200px', justifyContent: 'center' } as BoxProps)}>
        <input {...getInputProps()} />
        {children}
      </Box>
    </Box>
  );
};

export default OneFileCommonDropzone;

import { ChevronRightIcon } from '@chakra-ui/icons';
import { Box } from '@chakra-ui/react';
import Slider, { CustomArrowProps, Settings } from 'react-slick';
import { MdArrowRight } from 'react-icons/md';

const PrevArrow = (props: CustomArrowProps) => {
  const { className, style, onClick, currentSlide, slideCount } = props;
  if (slideCount) {
    if (currentSlide === 0) {
      return null;
    }
  }
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', left: '20px', zIndex: 10, transform: 'scale(2)' }}
      onClick={onClick}
    />
  );
};

const NextArrow = (props: CustomArrowProps) => {
  const { className, style, onClick, currentSlide, slideCount } = props;
  if (slideCount) {
    if (currentSlide === slideCount - 1) {
      return null;
    }
  }
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', right: '20px', zIndex: 10, transform: 'scale(2)' }}
      onClick={onClick}
    />
    // <Box
    //   className={className}
    //   onClick={onClick}
    //   right={'20px'}
    //   _before={{
    //     content: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='100' cy='50' r='40' stroke='black' stroke-width='2' fill='red'/%3E%3Cpolyline points='20,20 40,25 60,40 80,120 120,140 200,180' style='fill:none;stroke:black;stroke-width:3'/%3E%3C/svg%3E ")`,
    //   }}
    //   zIndex={11000}
    //   w="20px"
    //   h="20px"
    // >
    //   <ChevronRightIcon id="hehe" w="20px" h="20px" />
    // </Box>
  );
};

const sliderSettings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  prevArrow: <PrevArrow />,
  nextArrow: <NextArrow />,
  lazyLoad: 'anticipated',
  // customPaging: (i) => {
  //   return (
  //     <a>
  //       <img src={`/abstract0${i + 1}.jpg`} />
  //     </a>
  //   );
  // },
  // appendDots: (dots) => (
  //   <Box pb={10}>
  //     <ul style={{ margin: '0px' }}> {dots} </ul>
  //   </Box>
  // ),
} as Settings;

/** 예시) 지도 전체 매물 페이지에서 각 매물 클릭시 보이는 이미지 슬라이더 */
const CustomSliderOne: React.FC<Settings> = ({ children, ...props }) => {
  return (
    <Slider {...sliderSettings} {...props}>
      {children}
    </Slider>
  );
};

export default CustomSliderOne;

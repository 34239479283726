import { Box, ComponentWithAs, HStack, StackProps, TableColumnHeaderProps, Td } from '@chakra-ui/react';

interface WTdProps extends TableColumnHeaderProps {
  borderR?: boolean;
  borderL?: boolean;
  flexProps?: StackProps;
}

export const WTd: ComponentWithAs<'td', WTdProps> = ({ children, borderR, borderL, flexProps, ...props }) => (
  <Td
    borderRight={borderR ? '1px solid rgb(235,240,246)' : 'unset'}
    borderLeft={borderL ? '1px solid rgb(235,240,246)' : 'unset'}
    textAlign={'center'}
    wordBreak={'keep-all'}
    fontSize={'14px'}
    px={3}
    py={2}
    {...props}
  >
    <HStack w={'full'} justifyContent={'center'} {...flexProps}>
      <Box>{children}</Box>
    </HStack>
  </Td>
);

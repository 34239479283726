import { Box, Modal, ModalCloseButton, ModalContent, ModalOverlay, useDisclosure } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import Scrollbars from 'react-custom-scrollbars-2';
import * as Yup from 'yup';
import { FileInfosProps } from '../../Dropzone/DropzoneOpacityFormikThumb';
import { filterRemovedFiles } from '../../../../lib/common/utils/dropzone/function';
import {
  useCreateLeaseContractStatus,
  useCreateLeasePaymentsStatus,
  useCreateUserVirtualAccount,
  useDuplicateRealRoom,
  useUpdateRoom,
} from '../../../../lib/admin/hooks/mutation';
import { booleanToString } from '../../../../lib/common/utils/base';
import { ZeroMain } from './ZeroMain';
import * as Type from '../../../../types/common/CommonType';

interface ConfirmContractModalProps {
  disabled?: boolean;
  requestRoom: Type.Company;
}

export interface initialValuesProps {
  step: 'zero_main' | 'one_check-room' | 'two_register-hostInfo' | 'three_register-contractInfo';
}

export interface formikInitialValuesProps {
  // 프론트 필요 데이터
  step: any;
  // is_clicked_revise_button_once: boolean;
  is_clicked_register_room_button_once: boolean;
  is_clicked_register_host_button_once: boolean;
  is_clicked_register_contract_button_once: boolean;
  host_id: string;
  host_name: string;
  host: any;
  //
  // 매물 등록 정보
  admin_secret_memo: any;
  code: any;
  files: never[];
  building_type: any;
  building_form: any;
  address: any;
  jibun_address: any;
  road_address: any;
  zip_code: any;
  sido_name: any;
  sigungu_name: any;
  dongli_name: any;
  building_number: any;
  name: any;
  completion_date: any;
  floor: any;
  floor_r: any;
  household_number: any;
  entrance_type: any;
  is_elevator: any;
  is_management: any;
  security_facilities: never[];
  is_parking: boolean;
  parking_num: any;
  parking_cost: any;
  building_pictures: never[];
  building_id: any;
  room_id: string;
  deposit: number;
  rent: number;
  management_fee: number;
  common_items: string[];
  individual_items: string[];
  custom_room_requests: any;
  move_in: {
    status: any;
    date: any;
  };
  minimum_contract_month: number;
  pictures: string[];
  description: any;
  dong: any;
  ho: any;
  floor_type: any;
  is_move_in: boolean;
  room_living_type: any;
  share_facilities: string[];
  room_num: number;
  room_type: string[];
  toilet_num: number;
  supply_area: number;
  supply_area_peong: number;
  dedicated_area: number;
  dedicated_area_peong: number;
  room_direction: any;
  heating_type: any;
  default_options: string[];
  additional_options: string[];
  is_security_window: boolean;
  is_parking_r: boolean;
  is_pet: boolean;
  remodeling_season: any;
  remodeling_items: never[];
  is_bohome: boolean;
  is_real: boolean;
  is_template: boolean;
  // 임대인(호스트) 정보 등록
  host_selected_account: string;
  // 계약 정보 등록

  contract: {
    contract_created_date: string; // 계약 등록일?
    file_infos: Array<FileInfosProps> | [];
    // 두 개로 분리(lease_contract_pdfs, salary_deduction_contract_pdf) ???
    contract_start_date: string;
    contract_end_date: string;
    down_payment: string; // 계약금
    balance_payment: string; // 잔금
    deposit: string; // 보증금
    rent: string;
    management_fee: string;
    payment_status: string; // 납부상태
    payment_method: string; // 납부계좌
    payment_day: string;
  };
  accounts: Array<{
    bank_code: number;
    account_number: string;
    account_owner_name: string;
    is_auth: boolean; // 인증된 계좌
    is_added: boolean; // 등록되서 리스트에 추가된 계좌
  }>; // 정산계좌
}

const ConfirmContractModal: React.FC<ConfirmContractModalProps> = ({ children, disabled = false, requestRoom }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleModalOpen = () => {
    if (!disabled) {
      onOpen();
    }
  };

  const { mutateAsync: UpdateRoom } = useUpdateRoom();
  const { mutateAsync: DuplicateRealRoom } = useDuplicateRealRoom();
  const { mutateAsync: CreateContract } = useCreateLeaseContractStatus();
  const { mutateAsync: CreateLeasePayments } = useCreateLeasePaymentsStatus();
  // todo CreateVirtualAccount 컨트롤
  // const { mutateAsync: CreateVirtualAccount } = useCreateUserVirtualAccount();

  const employee = requestRoom?.employee;
  const custom_room_request = employee?.custom_room_request;
  const recommended_room = custom_room_request?.recommended_room;
  const room = recommended_room?.room || {};
  const room_id = room?._id;
  const building = room?.building;
  const building_id = building?._id;
  const address = building?.address;
  const building_form = building?.building_form;
  const admin_secret_memo = room?.admin_secret_memo;
  const code = room?.code;
  const pictures = room?.pictures;
  const is_move_in = room?.is_move_in;
  const is_parking = room?.is_parking;
  const is_pet = room?.is_pet;
  const is_security_window = room?.is_security_window;
  const default_options = room?.default_options;
  const common_items = room?.common_items;
  const move_in = room?.move_in;
  const room_living_type = room?.room_living_type;
  const room_direction = room?.room_direction;
  const remodeling_season = room?.remodeling_season;
  const remodeling_items = room?.remodeling_items;
  const individual_items = room?.individual_items;
  const floor_type = room?.floor_type;
  const toilet_num = room?.toilet_num;
  const supply_area = room?.supply_area;
  const heating_type = room?.heating_type;
  const additional_options = room?.additional_options;
  const share_facilities = room?.share_facilities;
  const dong = room?.dong;
  const ho = room?.ho;
  const host_id = room?.host_id;
  const host = room?.host;
  const room_num = room?.room_num;
  const room_type = room?.room_type;
  const rent = room?.rent;
  const deposit = room?.deposit;
  const dedicated_area = room?.dedicated_area;
  const floor = room?.floor;
  const management_fee = room?.management_fee;
  const minimum_contract_month = room?.minimum_contract_month;
  const description = room?.description;
  const is_bohome = room?.is_bohome;
  const is_real = room?.is_real;
  const is_template = room?.is_template;
  const custom_room_requests = room?.custom_room_request_ids;

  const initialValues = {
    /* 스텝 */
    step: 'zero_main',
    is_clicked_register_room_button_once: false,
    is_clicked_register_host_button_once: false,
    is_clicked_register_contract_button_once: false,
    host_id: host_id,
    host: host,
    custom_room_requests: custom_room_requests,
    host_name: '',
    //
    /* 매물 정보 */
    admin_secret_memo: admin_secret_memo,
    code: code,
    // 1 - 건물 등록
    files: [],
    building_type: '',
    building_form: building_form,
    address: address,
    jibun_address: '',
    road_address: '',
    zip_code: '',
    sido_name: '',
    sigungu_name: '',
    dongli_name: '',
    building_number: '',
    name: '',
    completion_date: '',
    floor: floor,
    floor_r: floor,
    share_facilities: share_facilities,
    household_number: '',
    entrance_type: '',
    is_elevator: '',
    is_management: '',
    security_facilities: [],
    is_parking: is_parking,
    parking_num: '',
    parking_cost: '',
    building_pictures: [],
    // 2 - 매물 등록
    building_id: building_id,
    room_id: room?._id,
    deposit: deposit, // applied
    is_move_in: is_move_in,
    rent: rent, // applied
    management_fee: management_fee, // applied
    common_items: common_items, // applied
    individual_items: individual_items, // applied
    move_in: {
      status: move_in?.status,
      date: move_in?.date ? Date.parse(move_in?.date as any) : new Date(),
    },
    minimum_contract_month: minimum_contract_month, // applied
    pictures: pictures, // to room_pictures
    description: description,
    dong: dong, // applied
    ho: ho, // applied
    floor_type: floor_type, // applied
    room_living_type: room_living_type, // applied
    room_num: room_num, // applied
    room_type: room_type,
    toilet_num: toilet_num, // applied
    supply_area: supply_area, // applied
    supply_area_peong: Math.floor((parseFloat(supply_area as any) / 3.305785) * 10) / 10,
    dedicated_area: dedicated_area, // applied
    dedicated_area_peong: Math.floor((parseFloat(dedicated_area as any) / 3.305785) * 10) / 10, // nonononononono
    room_direction: room_direction, // applied
    heating_type: heating_type, // applied
    default_options: default_options, // applied
    additional_options: additional_options, // applied
    is_security_window: is_security_window, // applied
    is_parking_r: is_parking, // applied
    is_pet: is_pet, // applied
    // is_interior: '',
    remodeling_season: remodeling_season,
    remodeling_items: remodeling_items,
    is_bohome: is_bohome,
    is_real: is_real,
    is_template: is_template,
    // 2-임대인(호스트) 등록
    host_selected_account: '',
    accounts: [{ bank_code: 1234, account_number: '', account_owner_name: '', is_auth: false, is_added: false }],

    // 3-계약 정보 등록
    contract: {
      contract_created_date: '', // 계약 등록일?
      file_infos: [],
      contract_start_date: '',
      contract_end_date: '',
      payment_status: 'rent_expected', // 납부상태
      payment_day: '', // 납부날짜
      down_payment: '', // 계약금
      balance_payment: '', // 잔금
      deposit: '', // 보증금
      rent: '',
      management_fee: '',
      payment_method: 'virtual_account', // 납부계좌
    },
  } as any;

  return (
    <>
      <Box onClick={handleModalOpen}>{children}</Box>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
        scrollBehavior="inside"
        isCentered
        size={'xl'}
      >
        <ModalOverlay />
        <Formik
          initialValues={initialValues}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            /* RoomFormData */
            const RoomFormData = new FormData();

            RoomFormData.append('deposit', values.deposit.toString());
            RoomFormData.append('rent', values.rent.toString());
            RoomFormData.append('management_fee', values.management_fee.toString());
            values.common_items.map((item: any) => {
              RoomFormData.append('common_items[]', item);
            });
            values.individual_items.map((item: any) => {
              RoomFormData.append('individual_items[]', item);
            });
            RoomFormData.append('move_in', JSON.stringify(values.move_in));
            if (values.minimum_contract_month) {
              RoomFormData.append('minimum_contract_month', values.minimum_contract_month.toString());
            }
            values.pictures.map((item: any) => {
              RoomFormData.append('room_picture_urls[]', item);
            });
            RoomFormData.append('dong', values.dong);
            RoomFormData.append('ho', values.ho);
            RoomFormData.append('floor_r', values.floor_r.toString());
            RoomFormData.append('floor_type', values.floor_type);
            RoomFormData.append('room_living_type', values.room_living_type);
            RoomFormData.append('room_num', values.room_num.toString());
            RoomFormData.append('toilet_num', values.toilet_num.toString());
            RoomFormData.append('supply_area', values.supply_area.toString());
            RoomFormData.append('dedicated_area', values.dedicated_area.toString());
            RoomFormData.append('room_direction', values.room_direction);
            RoomFormData.append('heating_type', values.heating_type);
            values.default_options.map((item: any) => {
              RoomFormData.append('default_options[]', item);
            });
            values.additional_options.map((item: any) => {
              RoomFormData.append('additional_options[]', item);
            });
            RoomFormData.append('is_security_window', booleanToString(values.is_security_window));
            RoomFormData.append('is_parking_r', booleanToString(values.is_parking));
            RoomFormData.append('is_pet', booleanToString(values.is_pet));
            RoomFormData.append('remodeling_season', values.remodeling_season);
            values.remodeling_items.map((item: any) => {
              RoomFormData.append('remodeling_items[]', item);
            });
            RoomFormData.append('is_bohome', booleanToString(values.is_bohome));
            RoomFormData.append('description', values.description);
            /* RoomFormData/*/

            /* ContractFormData */
            const ContractFormData = new FormData();
            ContractFormData.append('room_id', values.room_id); // check
            ContractFormData.append('host_id', values.host_id);
            // ContractFormData.append('tenant_id', '');
            ContractFormData.append('custom_room_request_id', custom_room_request?._id || '');
            ContractFormData.append('account', values.host_selected_account); // 이미 stringify 되었음.
            ContractFormData.append('payment_day', values.contract.payment_day);
            ContractFormData.append('payment_status', values.contract.payment_status);
            ContractFormData.append('payment_method_type', values.contract.payment_method); // 납부 방법
            ContractFormData.append('down_payment', values.contract.down_payment);
            ContractFormData.append(
              'balance_payment',
              ((values.contract.deposit as any) - (values.contract.down_payment as any)) as any,
            );
            ContractFormData.append('deposit', values.contract.deposit.toString());
            ContractFormData.append('rent', values.contract.rent.toString());
            ContractFormData.append('management_fee', values.contract.management_fee.toString());
            ContractFormData.append('contract_type', 'sublease_contract');
            ContractFormData.append(
              'contract_created_date',
              // JSON.stringify(new Date(values.contract.contract_created_date)),
              new Date(values.contract.contract_created_date).toString(),
            );
            ContractFormData.append(
              'contract_start_date',
              // JSON.stringify(new Date(values.contract.contract_start_date)),
              new Date(values.contract.contract_start_date).toString(),
            );
            ContractFormData.append('contract_end_date', new Date(values.contract.contract_end_date).toString());
            // ContractFormData.append('is_lease_fund', '');
            const [filtered_exists, _1, filtered_Files, filtered_urls] = filterRemovedFiles(values.contract.file_infos);
            filtered_Files.map((contract: File) => {
              ContractFormData.append('lease_contract_pdfs', contract); // 계약서
            });
            ContractFormData.append('is_host', 'true');
            ContractFormData.append('is_tenant', 'true');
            ContractFormData.append('is_agent', 'true');
            ContractFormData.append('is_admin', 'true');
            /* ContractFormData/ */

            /* FormData 전송 후 RealRoom 생성 API 호출 1 */
            const CreateRealRoom =
              values.is_real && values.is_template
                ? DuplicateRealRoom
                : values.is_real && values.is_template === false
                ? UpdateRoom
                : () => null;

            CreateRealRoom({
              formData: RoomFormData,
              room_id: room?._id || '',
            })
              ?.then((callbackdata) => {
                ContractFormData.append('real_room_id', (callbackdata as any)?.data.data.room._id); // from above api
              })
              .then(() => {
                // setSubmitting(false);
                CreateContract({
                  formData: ContractFormData,
                }).then((callbackdata) => {
                  const lease_contract_id = (callbackdata as any)?.data.data._id;
                  CreateLeasePayments({
                    lease_contract_id,
                    payment_method_type: (callbackdata as any)?.data.data.payment_method_type,
                  }).then(() => {
                    onClose();
                  });
                });
              });
          }}
          validationSchema={Yup.object().shape({
            // host_selected_account: Yup.string().required(),
            contract: Yup.object().shape({
              // contracts: Yup.array().min(1).required(),
              file_infos: Yup.array().min(1).required(),
              contract_start_date: Yup.string().required(),
              contract_end_date: Yup.string().required(),
              payment_status: Yup.string().required(),
              payment_day: Yup.string().required(),
              down_payment: Yup.number().required(),
              // down_payment: Yup.string()

              //   .matches(/^[0-9]+$/, '숫자만 입력 가능합니다.')
              //   .required(),
              deposit: Yup.number()
                .min(Yup.ref('down_payment'), '보증금은 계약금보다 크거나 같은 금액이어야 합니다.')
                // .moreThan(Yup.ref('down_payment'), '보증금은 계약금보다 큰 금액이어야 합니다.')
                // .matches(/^[0-9]+$/, '숫자만 입력 가능합니다.')
                .required(),
              rent: Yup.string()
                .matches(/^[0-9]+$/, '숫자만 입력 가능합니다.')
                .required(),
              management_fee: Yup.string()
                .matches(/^[0-9]+$/, '숫자만 입력 가능합니다.')
                .required(),
              payment_method: Yup.string().required(),
            }),
            // .required(),
          })}
        >
          {({ values, errors, isValid, handleChange, setFieldValue, isSubmitting }) => {
            const isAbleReservation = true;
            const steps = {
              zero_main: <ZeroMain />,
            } as any;
            // as {
            //   [key in initialValuesProps['step']]: JSX.Element;
            // };

            console.log('values.contract =>', values.contract);

            return (
              // <Form style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>

              <Form>
                <ModalContent p={5} h={values.step === 'zero_main' ? '45%' : 'full'}>
                  <ModalCloseButton zIndex={1000} />
                  <Scrollbars style={{ height: '100%' }}>
                    <Box w="full">{steps[values.step]}</Box>
                  </Scrollbars>
                </ModalContent>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

export default ConfirmContractModal;

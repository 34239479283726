import { Flex, Wrap, Box, useEditable, Text } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { isEmpty, values } from 'lodash';
import { isFile } from '../../../lib/common/utils/base';
import { DeleteCircle } from '../../../statics/common/icons';
import TextLink from '../Link/TextLink';
import TextLinkFileThumb from './TextLinkFileThumb';

export type FileInfosProps = {
  url: string;
  removed: boolean;
};

// export type FileNameInfosProps = {
//   name: string;
//   removed: boolean;
//   urls: Array<FileInfosProps>;
// };

interface Props {
  file_infos: FileInfosProps[] | undefined;
  onDeleteBtn: boolean;
  formikDeleteKey?: string;
}

const DropzoneOpacityFormikThumb: React.FC<Props> = ({ file_infos, onDeleteBtn, formikDeleteKey }) => {
  const { setFieldValue } = useFormikContext();

  if (isEmpty(file_infos)) {
    return null;
  } else {
    return (
      <Flex w={'full'}>
        <Wrap>
          {file_infos?.map((info, index) => {
            return (
              <Box key={index} pos={'relative'} transition={'0.18s linear'}>
                {isFile(info.url) ? (
                  <TextLinkFileThumb file={info.url as any} removed={info.removed}>
                    {index + 1}번째 파일
                  </TextLinkFileThumb>
                ) : (
                  <TextLink href={info.url} target={'_blank'} fontSize={'14px'} opacity={info.removed ? 0.4 : 1}>
                    {index + 1}번째 파일
                  </TextLink>
                )}
                {onDeleteBtn && (
                  <Box layerStyle={'abs_tr'} zIndex={0}>
                    <DeleteCircle
                      boxSize={4}
                      layerStyle={'pointer'}
                      onClick={() => {
                        const deleteKey = formikDeleteKey
                          ? `${formikDeleteKey}.file_infos[${index}].removed`
                          : `file_infos[${index}].removed`;

                        info.removed ? setFieldValue(deleteKey, false) : setFieldValue(deleteKey, true);
                      }}
                    />
                  </Box>
                )}
              </Box>
            );
          })}
        </Wrap>
      </Flex>
    );
  }
};

export default DropzoneOpacityFormikThumb;

import { CheckIcon } from '@chakra-ui/icons';
import { Flex, Box, Divider, Stack, Button, Text, VStack, HStack, Input } from '@chakra-ui/react';
import { SingleDatepicker } from '../../../../../components/common/Calender';
import { Formik, Form, Field } from 'formik';
import { useState } from 'react';
import { MinusIcon } from '../../../../../statics/common/icons';
import { BasicInput } from '../../../../../components/subscriber/Input/StyledBaiscInput';
import FileUploader from '../../../../../components/subscriber/FileUploader/FileUploader';

/* .../company/infos/company/ */

const CompanyInfosPage = () => {
  const [date, setDate] = useState(new Date());

  return (
    <Formik
      initialValues={{
        companyName: '',
        businessType: '',
        first_regis_num: '',
        second_regis_num: '',
        third_regis_num: '',
        first_corporation_num: '',
        second_corporation_num: '',
        first_address: '',
        second_address: '',
        representativeName: '',
        first_companyType: '',
        second_companyType: '',
        founding_date: '',
        workers_num: '',
        business_registration: '',
      }}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          alert(JSON.stringify({ ...values, founding_date: date }, null, 2));
          setSubmitting(false);
        }, 800);
      }}
    >
      {(formik) => (
        <Form>
          <Flex flexDir={'column'} align={'center'} justify={'center'} h={{ base: '790px', md: '600px' }}>
            <Flex
              flexDir={'column'}
              w={{ base: '450px', md: '900px' }}
              bgColor={'white'}
              rounded={'xl'}
              boxShadow={'lg'}
              p={6}
            >
              <Box id="header" mb={'24px'}>
                <Text fontSize={'18px'} color={'midGray'} mb={'12px'}>
                  기업 정보
                </Text>
                <Divider />
              </Box>

              <Flex justifyContent={'space-between'} id="inputs_box" flexDir={{ base: 'column', md: 'row' }}>
                <Flex id="inputs_1" w={'400px'} flexDir={'column'}>
                  <Stack spacing={6}>
                    <Flex align="center" position={'relative'} w={'100%'}>
                      <Text w={'130px'}>기업명</Text>
                      <BasicInput
                        id="userId"
                        placeholder="기업명"
                        type="text"
                        position={'relative'}
                        {...formik.getFieldProps('companyName')}
                      />
                      <CheckIcon color="#183377" zIndex={2} position={'absolute'} right={0} mr={'10px'} />
                    </Flex>
                    <Field component="div" name="businessType">
                      <Flex>
                        <Text w={'130px'}>사업자 유형</Text>
                        <Flex w="135.33px">
                          <Flex
                            h="33px"
                            fontSize={'14px'}
                            bgColor={formik.values.businessType === '개인' ? 'zetaBlue' : 'whiteGray'}
                            color={formik.values.businessType === '개인' ? 'white' : 'midGray'}
                            justify={'center'}
                            align={'center'}
                            borderLeftRadius={'md'}
                            boxSizing="border-box"
                            _hover={{
                              transition: '0.1s',
                              background: 'zetaBlue',
                              color: 'white',
                            }}
                          >
                            <Input
                              type="radio"
                              id="radioOne"
                              defaultChecked={formik.values.businessType === '개인'}
                              name="businessType"
                              value="개인"
                              style={{ display: 'none' }}
                            />
                            <label htmlFor="radioOne">
                              <Flex
                                style={{ width: '135.33px', height: '33px', cursor: 'pointer' }}
                                align={'center'}
                                justify={'center'}
                              >
                                <Text>개인</Text>
                              </Flex>
                            </label>
                          </Flex>

                          <Flex
                            w="135.33px"
                            h="33px"
                            fontSize={'14px'}
                            bgColor={formik.values.businessType === '법인' ? '#183377' : '#F0F0F0'}
                            color={formik.values.businessType === '법인' ? 'white' : 'midGray'}
                            justify={'center'}
                            align={'center'}
                            borderRightRadius={'md'}
                            boxSizing="border-box"
                            _hover={{
                              transition: '0.1s',
                              background: 'zetaBlue',
                              color: 'white',
                            }}
                          >
                            <Input
                              type="radio"
                              id="radioTwo"
                              defaultChecked={formik.values.businessType === '법인'}
                              name="businessType"
                              value="법인"
                              style={{ display: 'none' }}
                            />
                            <label htmlFor="radioTwo">
                              <Flex
                                style={{ width: '135.33px', height: '33px', cursor: 'pointer' }}
                                align={'center'}
                                justify={'center'}
                              >
                                <Text>법인</Text>
                              </Flex>
                            </label>
                          </Flex>
                        </Flex>
                      </Flex>
                    </Field>

                    <Flex align="center" position={'relative'} w={'100%'}>
                      <Text w={'130px'}>사업자 등록번호</Text>
                      <Flex w={'270px'}>
                        <BasicInput
                          w={'100%'}
                          id="first_regis_num"
                          placeholder="123"
                          type="number"
                          {...formik.getFieldProps('first_regis_num')}
                        />
                        <Flex mx={'4px'} align="center">
                          <MinusIcon />
                        </Flex>
                        <BasicInput
                          w={'100%'}
                          id="second_regis_num"
                          placeholder="45"
                          type="number"
                          {...formik.getFieldProps('second_regis_num')}
                        />
                        <Flex mx={'4px'} align="center">
                          <MinusIcon />
                        </Flex>
                        <BasicInput
                          w={'100%'}
                          id="third_regis_num"
                          placeholder="67890"
                          type="number"
                          {...formik.getFieldProps('third_regis_num')}
                        />
                      </Flex>
                    </Flex>
                    <Flex align="center" position={'relative'}>
                      <Text w={'130px'}>법인 등록번호</Text>
                      <Flex w={'270px'}>
                        <BasicInput
                          w={'100%'}
                          id="first_corporation_num"
                          placeholder="123"
                          type="number"
                          {...formik.getFieldProps('first_corporation_num')}
                        />
                        <Flex mx={'4px'} align="center">
                          <MinusIcon />
                        </Flex>
                        <BasicInput
                          w={'100%'}
                          id="second_corporation_num"
                          placeholder="45"
                          type="number"
                          {...formik.getFieldProps('second_corporation_num')}
                        />
                      </Flex>
                    </Flex>

                    <Flex align="flex-start" position={'relative'} w={'100%'}>
                      <Text w={'130px'} mt="4px">
                        건물 주소
                      </Text>
                      <VStack>
                        <BasicInput
                          id="first_address"
                          placeholder="기본 주소"
                          type="text"
                          position={'relative'}
                          mb={'10px'}
                          {...formik.getFieldProps('first_address')}
                        />
                        <BasicInput
                          id="second_address"
                          placeholder="상세 주소"
                          type="text"
                          position={'relative'}
                          {...formik.getFieldProps('second_address')}
                        />
                      </VStack>
                    </Flex>
                  </Stack>
                </Flex>
                <Flex id="inputs_2" w={'400px'} flexDir={'column'} mt={{ base: '24px', md: '0px' }}>
                  <Stack spacing={6}>
                    <Flex align="center" position={'relative'}>
                      <Text w={'130px'}>대표자 이름</Text>
                      <BasicInput
                        id="representativeName"
                        placeholder="이름"
                        type="text"
                        position={'relative'}
                        {...formik.getFieldProps('representativeName')}
                      />
                    </Flex>
                    <Flex align="center" position={'relative'}>
                      <Text w={'130px'}>업태 및 업종</Text>
                      <Flex w={'270px'} justify={'space-between'}>
                        <BasicInput
                          w={'130px'}
                          id="first_companyType"
                          placeholder="업태"
                          type="text"
                          position={'relative'}
                          {...formik.getFieldProps('first_companyType')}
                        />
                        <BasicInput
                          w={'130px'}
                          id="second_companyType"
                          placeholder="업종"
                          type="text"
                          position={'relative'}
                          {...formik.getFieldProps('second_companyType')}
                        />
                      </Flex>
                    </Flex>
                    <Flex align="center" position={'relative'}>
                      <Text w={'130px'}>설립일</Text>
                      <SingleDatepicker
                        date={date}
                        onDateChange={setDate}
                        propsConfigs={{
                          inputProps: {
                            bgColor: '#F0F0F0',
                            size: 'sm',
                            borderRadius: 'md',
                            color: 'highGray',
                            px: '16px',
                            w: '270px',
                          },
                        }}
                        {...formik.getFieldProps('founding_date')}
                      />
                    </Flex>
                    <Flex align="center" position={'relative'}>
                      <Text w={'130px'}>근로자 수</Text>
                      <HStack w={'270px'}>
                        <BasicInput
                          w={'100%'}
                          id="workers_num"
                          type="number"
                          position={'relative'}
                          {...formik.getFieldProps('workers_num')}
                        />
                        <Text>명</Text>
                      </HStack>
                    </Flex>
                    <FileUploader testFormik={formik} />
                  </Stack>
                </Flex>
              </Flex>
            </Flex>
            <Flex mt={{ base: '20px', md: '50px' }} flexDir={{ md: 'row' }} justify={'center'} align="center">
              <Button
                bg={'red.400'}
                color={'white'}
                w="150px"
                _hover={{
                  bg: 'red.500',
                }}
                size="sm"
                mr="10px"
              >
                홈으로
              </Button>
              <Button
                bg={'zetaBlue'}
                color={'white'}
                w="150px"
                _hover={{
                  bg: 'blue.500',
                }}
                size="sm"
                type={'submit'}
              >
                저장
              </Button>
            </Flex>
          </Flex>
        </Form>
      )}
    </Formik>
  );
};

export default CompanyInfosPage;

import { Box, Flex, HStack, Text, Tooltip, VStack } from '@chakra-ui/react';
import ActiveText from '../../../../../../../../../../components/common/Active';
import { useGetCompanyManager } from '../../../../../../../../../../lib/admin/hooks/query';
import { currentParamsObject } from '../../../../../../../../../../lib/common/utils/base';
import { tenanStatusTypeTrans } from '../../../../../../../../../../lib/subscriber/utils/utils';

const BeforeLayout: React.FC = ({ children }) => {
  const { id } = currentParamsObject(false);

  const { data: user_data } = useGetCompanyManager(id || '', {
    options: {
      enabled: !!id,
    },
  });

  return (
    <VStack alignItems={'start'} w={'100%'}>
      <HStack alignItems={'end'} mb={4}>
        <Tooltip
          placement={'bottom-start'}
          label={
            <Flex flexDir={'column'}>
              <Box>이메일: {user_data?.employee.email}</Box>
              <Box>회사: {user_data?.employee.company_name}</Box>
            </Flex>
          }
        >
          <Flex lineHeight={1} mr={4} align={'end'}>
            <Text textStyle={'bold30'}>{user_data?.employee.user_name}</Text>
            <Text textStyle={'medium12'}>{tenanStatusTypeTrans(user_data?.employee.tenant_status as any)}</Text>
          </Flex>
        </Tooltip>
        <HStack spacing={8} divider={<Text>&nbsp;&nbsp;|&nbsp;&nbsp;</Text>}>
          <ActiveText to={`/admin/manage/customers/user/detail/info?id=${id}`}>회원 정보</ActiveText>
          <ActiveText to={`/admin/manage/customers/user/detail/request?id=${id}`}>{'입주 요청 / 전달현황'}</ActiveText>
          <ActiveText to={`/admin/manage/customers/user/detail/memo?id=${id}`}>메모</ActiveText>
        </HStack>
      </HStack>
      <Flex w={'100%'}>{children}</Flex>
    </VStack>
  );
};
export default BeforeLayout;

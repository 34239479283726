import QueryParamsLayout from '../../../../../../layouts/common/main/QueryParamsLayout';
import { useGetAllCompaniesContracts } from '../../../../../../lib/admin/hooks/query';
import { currentParamsObject, ToAllFilledObj } from '../../../../../../lib/common/utils/base';
import SubscriptionContractsTable from '../../../../../../components/admin/Table/CompanyNewTable/table';
import Loading from '../../../../../../components/common/Loading';

const New = () => {
  const _params = currentParamsObject();
  const params = ToAllFilledObj(_params);
  const res = useGetAllCompaniesContracts({
    axiosConfig: {
      params,
    },
  }) as any;
  const { isLoading } = res;
  if (isLoading) return <Loading />;
  return (
    <QueryParamsLayout res={res}>
      <SubscriptionContractsTable res={res} />
    </QueryParamsLayout>
  );
};

export default New;

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Center,
  Checkbox,
  Collapse,
  Flex,
  HStack,
  Modal,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  Textarea,
  useDisclosure,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { GNoBBox } from '../../../common/Box';
import { useConfirmService } from '../../../../lib/subscriber/hooks/mutation';
import {
  parseContractHistories,
  parseRequestHistories,
  useGetCompanyIsApproved,
  useGetUserAllHistories,
} from '../../../../lib/subscriber/hooks/query';

import { BaseResponse, HistoryType, Using } from '../../../../lib/common/utils/axios/types';
import { LeftArrowIcon, RightArrowIcon } from '../../../../statics/common/icons';
import {
  FiveMovingIn,
  FiveMovingInandExtend,
  FourContracted,
  OneIsApproved,
  OneIsApprovedAndExtra,
  TwoSeekingHouse,
} from './sections';

interface UsageStatusModalProps {
  using: Using;
}

const UsageStatusModal: React.FC<UsageStatusModalProps> = ({ using }) => {
  const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure();
  // confirm btns
  const {
    isOpen: isConfirmApprovalOpen,
    onOpen: onConfirmApprovalOpen,
    onClose: onConfirmApprovalClose,
  } = useDisclosure();

  const { isOpen: isConfirmRejectOpen, onOpen: onConfirmRejectOpen, onClose: onConfirmRejectClose } = useDisclosure();

  const [phase, setPhase] = useState<'initial' | 'reject' | 'approve'>('initial');
  const [rejectReason, setRejectReason] = useState('');

  const componentStartRef = useRef<HTMLDivElement>(null);
  const componentEndRef = useRef<HTMLDivElement>(null);
  const inputFocus = useRef<any>(null);
  const confirmApprovalRef = useRef();
  const confirmRejectRef = useRef();

  // 이용 약관
  const [checkedItems, setCheckedItems] = useState([false, false]);

  const allChecked = checkedItems.every(Boolean);
  const isIndeterminate = checkedItems.some(Boolean) && !allChecked;

  useEffect(() => {
    if (phase === 'approve' || phase === 'reject') {
      const scroll = setTimeout(() => {
        componentEndRef.current?.scrollIntoView({ behavior: 'smooth' });
      }, 500);

      return () => clearTimeout(scroll);
    }
    if (phase === 'initial') {
      const scroll = setTimeout(() => {
        componentStartRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }, 200);
      return () => clearTimeout(scroll);
    }
  }, [phase]);

  const { mutateAsync } = useConfirmService();

  const { data: allHistoriesData } = useGetUserAllHistories(using.employee._id, {
    options: {
      enabled: isModalOpen,
    },
  });

  const allRequestHistoriesData = allHistoriesData && parseRequestHistories(allHistoriesData);
  const allContractHistoriesData = allHistoriesData && parseContractHistories(allHistoriesData);

  const checkStatus = (() => {
    if (
      using.employee.parsed_last_service === undefined ||
      using.employee.parsed_last_service.check_status !== 'pending'
    ) {
      switch (using.employee.tenant_status) {
        case 'in_service':
          // 승인 완료
          return 'one_isApproved';
        case 'in_custom_room_request':
          // 집찾기중
          return 'two_seekingHouse';
        case 'contracting':
          // 계약중
          return 'three_contracting';
        case 'contracted':
          // 계약완료
          return 'four_contracted';
        case 'moving_in':
          // 입주중
          return 'five_movingIn';
        default:
          // break;
          return 'zero_onlyHistory';
      }
    } else {
      const { request_type } = using.employee.parsed_last_service;
      if (request_type === 'changing') {
        // 승인 완료 & 추가 승인 요청
        return 'one_isApprovedandExtra';
      } else if (request_type === 'extending') {
        // 입주중 & 계약 연장
        return 'five_movingInandExtend';
      }
      return 'zero_onlyHistory';
    }
  })();

  return (
    <>
      <Text onClick={onModalOpen} textDecor={'underline'} textUnderlineOffset={2} cursor={'pointer'}>
        상세보기
      </Text>
      <Modal
        isOpen={isModalOpen}
        onClose={onModalClose}
        scrollBehavior="inside"
        size={'2xl'}
        motionPreset="slideInRight"
      >
        <ModalOverlay />
        <ModalContent>
          <Flex flexDir={'column'} w={'full'} h="full" alignItems={'flex-start'}>
            <Box
              w={'full'}
              borderBottomColor={'borderGray'}
              boxShadow={'rgba(0, 0, 0, 0.02) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 2px 3px -2px'}
              px={7}
              py={6}
            >
              <Flex justifyContent={'space-between'}>
                <HStack h={'34px'}>
                  {phase !== 'initial' && (
                    <LeftArrowIcon w={6} h={6} color={'black'} cursor={'pointer'} onClick={() => setPhase('initial')} />
                  )}
                  <Center fontSize={'18px'}>
                    <Text mr={2}>💌</Text>
                    <Text fontWeight={'medium'}>
                      {using.employee.user_name}
                      {
                        {
                          zero_onlyHistory: '님의 이용 현황',
                          one_isApproved: '님의 이용 현황',
                          two_seekingHouse: '님의 이용 현황',
                          three_contracting: '님의 이용 현황', // UI X ?
                          four_contracted: '님의 이용 현황',
                          five_movingIn: '님의 이용 현황',
                          one_isApprovedandExtra: '님의 추가 이용 신청서',
                          five_movingInandExtend: '님의 계약 연장 신청서',
                        }[checkStatus]
                      }
                    </Text>
                  </Center>
                </HStack>
                {phase === 'initial' &&
                  (checkStatus === 'one_isApprovedandExtra' || checkStatus === 'five_movingInandExtend') && (
                    <HStack>
                      <Button
                        variant={'grayBorder_blackFont'}
                        w={'90px'}
                        borderRadius={'md'}
                        onClick={() => setPhase('reject')}
                      >
                        반려
                      </Button>
                      <Button variant={'basic'} w={'90px'} onClick={() => setPhase('approve')}>
                        승인
                      </Button>
                    </HStack>
                  )}
              </Flex>
            </Box>
            <Scrollbars
              style={{ height: '640px' }}
              thumbSize={400}
              id="scroll-form"
              renderView={({ style, ...props }) => (
                <div id="renderView" style={{ ...style }} {...props}>
                  {/* renderView */}
                </div>
              )}
            >
              {
                {
                  one_isApproved: <OneIsApproved usageData={allRequestHistoriesData} userData={using.employee} />,
                  two_seekingHouse: <TwoSeekingHouse usageData={allRequestHistoriesData} userData={using.employee} />,
                  three_contracting: (
                    // null ?
                    <OneIsApproved usageData={allRequestHistoriesData} userData={using.employee} />
                  ),
                  four_contracted: (
                    <FourContracted
                      usageData={allRequestHistoriesData}
                      contractData={allContractHistoriesData}
                      userData={using.employee}
                    />
                  ),
                  five_movingIn: (
                    <FiveMovingIn
                      usageData={allRequestHistoriesData}
                      contractData={allContractHistoriesData}
                      userData={using.employee}
                    />
                  ),
                  zero_onlyHistory: (
                    // use like dummy
                    <OneIsApproved usageData={allRequestHistoriesData} userData={using.employee} />
                  ),
                  one_isApprovedandExtra: (
                    <OneIsApprovedAndExtra
                      usageData={allRequestHistoriesData}
                      userData={using.employee}
                      serviceData={using.employee.parsed_last_service}
                    />
                  ),
                  five_movingInandExtend: (
                    <FiveMovingInandExtend
                      usageData={allRequestHistoriesData}
                      userData={using.employee.user_name}
                      serviceData={using.employee.parsed_last_service}
                    />
                  ),
                }[checkStatus]
              }
              {/* 승인 */}
              <Collapse in={phase === 'approve'} style={{ width: '100%' }}>
                <Box w={'full'} px={7} pb={6}>
                  <GNoBBox w={'full'}>
                    <>
                      <Checkbox
                        isChecked={allChecked}
                        isIndeterminate={isIndeterminate}
                        onChange={(e) => setCheckedItems([e.target.checked, e.target.checked])}
                        fontWeight={'medium'}
                        fontSize={'16px'}
                      >
                        모두 확인하였으며, 동의합니다.
                      </Checkbox>
                      <Stack mt={1} spacing={1}>
                        <Checkbox
                          size="smd"
                          isChecked={checkedItems[0]}
                          onChange={(e) => setCheckedItems([e.target.checked, checkedItems[1]])}
                          fontWeight={'medium'}
                        >
                          <Box>
                            <Text color={'zetaBlue'} display={'inline-block'}>
                              [필수]&nbsp;
                            </Text>
                            위 내용으로 보홈 이용을 승인합니다.
                          </Box>
                        </Checkbox>
                        <Checkbox
                          size="smd"
                          isChecked={checkedItems[1]}
                          onChange={(e) => setCheckedItems([checkedItems[0], e.target.checked])}
                          fontWeight={'medium'}
                          alignItems={'start'}
                        >
                          <Box>
                            <Text color={'zetaBlue'} display={'inline-block'}>
                              [필수]&nbsp;
                            </Text>
                            고객사는 계약서에 명의된 내용을 인지하였습니다. 서비스 이용을 승인한 이용자의 입주 계약
                            만료까지 구독 기간을 보장해야 합니다. 희망 입주 기간과 실제 입주 계약 기간이 다른 경우 추가
                            승인 절차가 필요합니다. 또한, 고객사는 이용자의 매물 현장 탐방을 위해 최대 2시간의 외출을
                            허용합니다. 더 자세한 사항은 보홈 구독 계약서 제4조에 명시되어 있습니다.
                          </Box>
                        </Checkbox>
                      </Stack>
                    </>
                  </GNoBBox>
                  <HStack w="full" mt={6} justifyContent={'center'}>
                    <Button
                      variant={'gray_hoverGray'}
                      fontSize={'20px'}
                      fontWeight={'bold'}
                      style={{ width: '102px', height: '44px' }}
                      onClick={onModalClose}
                    >
                      취소
                    </Button>
                    <>
                      <Button
                        variant={'basic'}
                        fontSize={'20px'}
                        fontWeight={'bold'}
                        style={{ width: '192px', height: '44px' }}
                        isDisabled={!allChecked}
                        onClick={() => {
                          onConfirmApprovalOpen();
                        }}
                      >
                        승인 완료
                      </Button>
                      <AlertDialog
                        isOpen={isConfirmApprovalOpen}
                        leastDestructiveRef={confirmApprovalRef.current}
                        onClose={onConfirmApprovalClose}
                        isCentered
                      >
                        <AlertDialogOverlay>
                          <AlertDialogContent>
                            <AlertDialogHeader fontSize="lg" fontWeight="bold">
                              승인 확정
                            </AlertDialogHeader>

                            <AlertDialogBody>
                              <Text display={'inline-block'} fontWeight={'medium'}>
                                {using.employee.user_name}
                              </Text>
                              님의 신청을 승인하시겠습니까?
                            </AlertDialogBody>

                            <AlertDialogFooter>
                              <Button
                                ref={confirmApprovalRef.current}
                                onClick={onConfirmApprovalClose}
                                variant={'gray_hoverGray'}
                              >
                                취소
                              </Button>
                              <Button
                                variant={'basic'}
                                style={{ height: '40px', fontSize: '16px' }}
                                onClick={() => {
                                  mutateAsync({
                                    employee_mutation_id: using.employee._id,
                                    service_mutation_id: using.employee.parsed_last_service._id,
                                    // is_approved: true,
                                    check_status: 'approved',
                                  }).then(() => {
                                    onConfirmApprovalClose();
                                    onModalClose();
                                  });
                                }}
                                ml={3}
                              >
                                승인 완료
                              </Button>
                            </AlertDialogFooter>
                          </AlertDialogContent>
                        </AlertDialogOverlay>
                      </AlertDialog>
                    </>
                  </HStack>
                </Box>
              </Collapse>
              {/* 반려 */}
              <Collapse in={phase === 'reject'} style={{ width: '100%' }}>
                <Box w={'full'} px={7} pb={6}>
                  <Text color={'primaryRed'} mb={1}>
                    *추가 승인 신청을 반려하는 경우에는 이미 승인 받은 기간만 임직원이 이용할 수 있습니다.
                  </Text>
                  <Textarea
                    value={rejectReason}
                    onChange={(e) => setRejectReason(e.target.value)}
                    placeholder="반려 사유를 작성해 주세요. 작성해주신 내용은 해당 임직원에게 전달됩니다."
                    _placeholder={{ color: 'silverGray', fontSize: '16px' }}
                    bgColor={'#f8f8f8'}
                    borderRadius={'md'}
                    resize={'none'}
                    h={'192px'}
                    px={'18px'}
                    py={'25px'}
                    ref={inputFocus}
                  />
                  <HStack w="full" mt={6} justifyContent={'center'}>
                    <Button
                      variant={'gray_hoverGray'}
                      fontSize={'20px'}
                      fontWeight={'bold'}
                      style={{ width: '102px', height: '44px' }}
                      onClick={onModalClose}
                    >
                      취소
                    </Button>
                    <>
                      <Button
                        variant={'basic'}
                        fontSize={'20px'}
                        fontWeight={'bold'}
                        style={{ width: '192px', height: '44px' }}
                        onClick={onConfirmRejectOpen}
                      >
                        반려 완료
                      </Button>
                      <AlertDialog
                        isOpen={isConfirmRejectOpen}
                        leastDestructiveRef={confirmRejectRef.current}
                        onClose={onConfirmRejectClose}
                        isCentered
                      >
                        <AlertDialogOverlay>
                          <AlertDialogContent>
                            <AlertDialogHeader fontSize="lg" fontWeight="bold">
                              반려 확정
                            </AlertDialogHeader>

                            <AlertDialogBody>
                              <Text display={'inline-block'} fontWeight={'medium'}>
                                {using.employee.user_name}
                              </Text>
                              님의 신청을 반려하시겠습니까?
                            </AlertDialogBody>

                            <AlertDialogFooter>
                              <Button
                                ref={confirmRejectRef.current}
                                onClick={onConfirmRejectClose}
                                variant={'gray_hoverGray'}
                              >
                                취소
                              </Button>
                              <Button
                                variant={'basic'}
                                style={{ height: '40px', fontSize: '16px' }}
                                onClick={() =>
                                  mutateAsync({
                                    employee_mutation_id: using.employee._id,
                                    service_mutation_id: using.employee.parsed_last_service._id,
                                    // is_approved: false,
                                    check_status: 'rejected',
                                    rejected_reason: rejectReason,
                                  }).then(() => {
                                    onConfirmRejectClose();
                                    onModalClose();
                                  })
                                }
                                ml={3}
                              >
                                반려 완료
                              </Button>
                            </AlertDialogFooter>
                          </AlertDialogContent>
                        </AlertDialogOverlay>
                      </AlertDialog>
                    </>
                  </HStack>
                </Box>
              </Collapse>
              {/* 끝 */}
              <Box ref={componentEndRef} />
            </Scrollbars>
          </Flex>
        </ModalContent>
      </Modal>
    </>
  );
};

export default UsageStatusModal;

import {
  Box,
  Button,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  StackDivider,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { currentParamsObject, parseJsonToArr } from '../../../../../lib/common/utils/base';
import _ from 'lodash';
import { SetDeposit, SetManagementFee, SetRent, SetTotalSum } from '../../SetRangeSlider';

export const SetRoomPriceRangeSliders: React.FC = () => {
  const { ...paramsprops } = currentParamsObject(false);
  const isSelected = paramsprops.rent || paramsprops.total_sum;

  return (
    <Popover>
      <PopoverTrigger>
        <Button variant={isSelected ? 'checked' : 'unChecked'}>보증금/월세</Button>
        {/* <Button variant={is_filled_arr ? 'checked' : 'unChecked'}>신청 항목 필터</Button> */}
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>보증금/월세</PopoverHeader>
        <PopoverBody>
          <VStack align={'start'} w="full">
            <Box w="full">
              <SetRent />
            </Box>
            <Box w="full">
              <SetTotalSum />
            </Box>
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

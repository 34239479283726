import { Tooltip, TooltipProps } from '@chakra-ui/react';

interface ExistingHostIdTooltipProps extends TooltipProps {
  isRegistered: boolean;
}

const ExistingHostIdTooltip: React.FC<ExistingHostIdTooltipProps> = ({ children, isRegistered, ...props }) => {
  const text = '매물의 기존 등록된 호스트입니다.';
  return (
    <Tooltip label={text} isDisabled={!isRegistered} placement="right" hasArrow {...props}>
      {children}
    </Tooltip>
  );
};

export default ExistingHostIdTooltip;

import { Box, Button, Center, Flex, HStack, Input, Textarea, useToast } from '@chakra-ui/react';
import { Field, Form, Formik, useFormikContext } from 'formik';
import { useRef } from 'react';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { useParams } from 'react-router-dom';
import { useChatNotify } from '../../../lib/common/utils/axios';
import { error } from '../../../statics/common/data/toast';
import { auth, chatroomDocRef, saveMessage } from '../../../lib/common/utils/firebase/init';
import { HouseAddChatIcon, ImageChatIcon } from '../../../statics/common/icons';
import OptionBtn from '../../common/Box/ChatBox/OptionBtn';
import { useAuthState } from 'react-firebase-hooks/auth';

const searchbar_formik_initialValues = {
  message: '',
};

const ChatSenderBar: React.FC = () => {
  const [user, loading, error] = useAuthState(auth);
  const chatNotify = useChatNotify();
  const inputFocus = useRef<HTMLInputElement>(null);
  const params = useParams();

  const chatRoomId = params.chatroomid ? params.chatroomid : '';

  const [data] = useDocumentData(chatroomDocRef(chatRoomId));
  const toast = useToast();

  // const [test] =

  const userIds: any[] = [];
  if (data !== undefined && data.users !== undefined) {
    (data.users as any[]).forEach((user: { _id: any }) => {
      userIds.push(user._id);
    });
  }

  return (
    <Formik
      initialValues={searchbar_formik_initialValues}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        await saveMessage(values.message, chatRoomId, userIds);
        resetForm();
        setSubmitting(false);
        inputFocus.current?.focus();
        await chatNotify('admin', 'master', chatRoomId, {
          message: values.message,
        });
      }}
    >
      {({ values, errors, touched, handleSubmit, isSubmitting, setSubmitting, getFieldProps, isValid }) => {
        return (
          <Form
            style={{
              height: '100%',
              width: '100%',
              display: 'flex',
              // position: 'relative',
              // paddingBottom: `${msg_spacing * 1.5}px`,
            }}
          >
            <Flex w="full" h="full" flexDir={'column'}>
              <Box border="1px solid" borderColor={'silverGray'} m={4}>
                <Box borderBottom="1px solid rgb(227, 231, 241)">
                  <HStack px={5} py={2}>
                    <OptionBtn>
                      <ImageChatIcon />
                      <Box fontSize={'10px'} fontWeight={'bold'}>
                        사진
                      </Box>
                    </OptionBtn>
                    <OptionBtn>
                      <HouseAddChatIcon />
                      <Box fontSize={'10px'} fontWeight={'bold'}>
                        추가 매물
                      </Box>
                    </OptionBtn>
                  </HStack>
                </Box>
                <Flex>
                  <Field
                    as={Textarea}
                    name="message"
                    id="message"
                    placeholder="메세지를 입력해 주세요. (Enter : 띄어쓰기)"
                    fontSize={'14px'}
                    fontWeight={'medium'}
                    border={'none'}
                    borderRadius={'unset'}
                    resize="none"
                    _focus={{}}
                    h="80px"
                  />
                  {/* <Input multiple /> */}
                  <Center p={2}>
                    <Button type="submit" variant={'basic'} style={{ height: '100%' }} isLoading={isSubmitting}>
                      전송
                    </Button>
                  </Center>
                </Flex>
              </Box>
            </Flex>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ChatSenderBar;

import { useToast } from '@chakra-ui/react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import {
  company_hosts_key,
  company_managers_key,
  custom_room_requests_key,
  custom_room_request_rooms_key,
  tenant_detail_lookup_key,
  tenant_lease_contract_detail_lookup_key,
  virtual_account_user_key,
} from '../../../statics/admin/data/KeyPath';
import { queryClient } from '../../..';
import { saveFail, saveSuccess } from '../../../statics/common/data/toast';
import {
  companies_hosts_key,
  companies_managers_key,
  companies_tenants_leaseContracts_key,
  pre_service_requests_key,
  Revise_buildings_rooms_key,
  supportPayment_company_users_key,
  supportPayment_company_users_v2_key,
  tenant_complaint_key,
} from '../../../statics/agent/data/KeyPath';
import { fetchClient } from '../../common/utils/axios';
import {
  AccountType,
  CertificateIssueTypeEnum,
  GlobalStatus,
  LeaseProxyPaymentStatusEnum,
  OperationEnum,
  PaymentMethodType,
  RequestStatusEnum,
  ServiceCategoryEnum,
  SubscriptionPaymentStatusEnum,
  TourTypeEnum,
} from '../../common/utils/axios/types';
import { useGetUserPathandKey } from '../../common/hooks/query';
import { trimObject } from '../../common/utils/base';
import { custom_room_request_key } from '../../../statics/common/data/KeyPath';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../common/utils/firebase/init';

interface MuteConfirmApproveCompanyProps {
  is_approved: boolean;
  rejected_reason?: string;
}

export const useConfirmCompany = (user_id: string) => {
  const [user, loading, error] = useAuthState(auth);
  const toast = useToast();
  const { data: ud } = useGetUserPathandKey();

  return useMutation(
    async (mute: MuteConfirmApproveCompanyProps) => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      return await instance.post(`${ud?.path}/users/masters/${user_id}/confirm`, {
        rejected_reason: mute.rejected_reason,
        is_approved: mute.is_approved,
      });
    },

    {
      onSuccess: () => {
        queryClient.invalidateQueries([]);
        toast(saveSuccess);
      },
      onError: () => {
        toast(saveFail);
      },
    },
  );
};

interface MuteConfirmMoveOutProps {
  admin_check_status: GlobalStatus;
  rejected_reason?: string;
}

export const useConfirmMoveOut = (lease_contract_id: string) => {
  const [user, loading, error] = useAuthState(auth);
  const toast = useToast();
  const { data: ud } = useGetUserPathandKey();

  return useMutation(
    async (mute: MuteConfirmMoveOutProps) => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      return await instance.post(`${ud?.path}/lease-contracts/${lease_contract_id}/moving-out/confirm`, {
        rejected_reason: mute.rejected_reason,
        admin_check_status: mute.admin_check_status,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([]);
        toast(saveSuccess);
      },
      onError: () => {
        toast(saveFail);
      },
    },
  );
};

interface MuteConfirmRecommendedRoomsProps {
  is_admin: boolean;
  rooms: Array<{
    _id: string;
    admin_relay_memo: string;
  }>;
}

export const useConfirmRecommendedRooms = (custom_room_request_id: string) => {
  const [user, loading, error] = useAuthState(auth);
  const toast = useToast();
  const { data: ud } = useGetUserPathandKey();

  return useMutation(
    async (mute: MuteConfirmRecommendedRoomsProps) => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      return await instance.post(`${ud?.path}/custom-room-requests/${custom_room_request_id}/rooms/confirm`, {
        rooms: mute.rooms,
        is_admin: mute.is_admin,
      });
    },

    {
      onSuccess: () => {
        queryClient.invalidateQueries([]);
        toast(saveSuccess);
      },
      onError: () => {
        toast(saveFail);
      },
    },
  );
};

interface MuteSuggestRoomsProps {
  rooms: Array<{
    _id: string;
    admin_relay_memo: string;
  }>;
}

export const useSuggestRooms = (custom_room_request_id: string) => {
  const [user, loading, error] = useAuthState(auth);
  const toast = useToast();
  const { data: ud } = useGetUserPathandKey();

  return useMutation(
    async (mute: MuteSuggestRoomsProps) => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      return await instance.post(`${ud?.path}/custom-room-requests/${custom_room_request_id}/rooms/suggest`, {
        rooms: mute.rooms,
      });
    },

    {
      onSuccess: () => {
        queryClient.invalidateQueries([]);
        toast(saveSuccess);
      },
      onError: () => {
        toast(saveFail);
      },
    },
  );
};

interface MuteReviseRoomAdminMemoProps {
  room_id: string;
  room_memo: string;
}

export const useUpdateRoomAdminMemo = () => {
  const [user, loading, error] = useAuthState(auth);
  const toast = useToast();
  const { data: ud } = useGetUserPathandKey();

  return useMutation(
    async (mute: MuteReviseRoomAdminMemoProps) => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      return await instance.patch(`${ud?.path}/rooms/${mute.room_id}/company-memo`, {
        company_memo: mute.room_memo,
      });
    },

    {
      onSuccess: () => {
        queryClient.invalidateQueries([custom_room_request_rooms_key]);
        toast(saveSuccess);
      },
      onError: () => {
        toast(saveFail);
      },
    },
  );
};

interface MuteReviseTenantLeaseContractProps {
  lease_contract_id: string;
  formData: FormData;
}

export const useUpdateTenantLeaseContract = () => {
  const [user, loading, error] = useAuthState(auth);
  const toast = useToast();
  const { data: ud } = useGetUserPathandKey();

  return useMutation(
    async (mute: MuteReviseTenantLeaseContractProps) => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      return await instance.patch(`${ud?.path}/lease-contracts/${mute.lease_contract_id}`, mute.formData);
    },

    {
      onSuccess: () => {
        queryClient.invalidateQueries([tenant_lease_contract_detail_lookup_key]);
        queryClient.invalidateQueries([supportPayment_company_users_key]);
        toast(saveSuccess);
      },
      onError: () => {
        toast(saveFail);
      },
    },
  );
};

interface MuteReviseAnnouncementProps {
  announcement_id: string;
  formData: FormData;
}

export const useUpdateAnnouncement = () => {
  const [user, loading, error] = useAuthState(auth);
  const toast = useToast();
  const { data: ud } = useGetUserPathandKey();

  return useMutation(
    async (mute: MuteReviseAnnouncementProps) => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      return await instance.patch(`${ud?.path}/announcements/${mute.announcement_id}`, mute.formData);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([]);
        toast({ ...saveSuccess, title: '공지를 업데이트했습니다', description: '' });
      },
      onError: () => {
        toast({ ...saveFail, title: '공지 업데이트에 실패했습니다', description: '' });
      },
    },
  );
};

interface MuteCreateAnnouncementProps {
  formData: FormData;
}

export const useCreateAnnouncement = () => {
  const [user, loading, error] = useAuthState(auth);
  const toast = useToast();
  const { data: ud } = useGetUserPathandKey();

  return useMutation(
    async (mute: MuteCreateAnnouncementProps) => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      return await instance.post(`${ud?.path}/announcement`, mute.formData);
    },

    {
      onSuccess: () => {
        queryClient.invalidateQueries([]);
        toast({ ...saveSuccess, title: '새로운 공지를 생성하였습니다', description: '' });
      },
      onError: () => {
        toast({ ...saveFail, title: '공지 생성에 실패했습니다', description: '' });
      },
    },
  );
};

interface MuteUpdateAdminRelayMemoProps {
  formData?: FormData;
  custom_room_request_id?: string;
  room_id?: string;
}

export const useUpdateAdminRelayMemo = () => {
  const [user, loading, error] = useAuthState(auth);
  const toast = useToast();
  const { data: ud } = useGetUserPathandKey();

  return useMutation(
    async (mute: MuteUpdateAdminRelayMemoProps) => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      return await instance.patch(
        `${ud?.path}/custom-room-requests/${mute.custom_room_request_id}/rooms/${mute.room_id}/memo`,
        mute.formData,
      );
    },

    {
      onSuccess: () => {
        queryClient.invalidateQueries([]);
        toast({ ...saveSuccess, title: '보홈 코멘트 업데이트 완료', description: '' });
      },
      onError: () => {
        toast({ ...saveFail, title: '보홈 코멘트 업데이트 실패', description: '' });
      },
    },
  );
};

interface MuteUpdateAdminToTenantMemoProps {
  formData: FormData;
  user_id: string;
}

/** 어드민이 임직원에게 작성한 메모 업데이트 */
export const useUpdateAdminToTenantMemo = () => {
  const [user, loading, error] = useAuthState(auth);
  const toast = useToast();
  const { data: ud } = useGetUserPathandKey();

  return useMutation(
    async (mute: MuteUpdateAdminToTenantMemoProps) => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      return await instance.patch(`${ud?.path}/users/${mute.user_id}/memo`, mute.formData);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([]);
        toast({ ...saveSuccess, title: '저장 성공', description: '성공적으로 저장이 완료되었습니다.' });
      },
      onError: () => {
        toast(saveFail);
      },
    },
  );
};

interface MuteUpdateAdminToCompanyMemoProps {
  formData: FormData;
  company_id: string;
}

/** 어드민이 고객사에게 작성한 메모 업데이트 */
export const useUpdateAdminToCompanyMemo = () => {
  const [user, loading, error] = useAuthState(auth);
  const toast = useToast();
  const { data: ud } = useGetUserPathandKey();

  return useMutation(
    async (mute: MuteUpdateAdminToCompanyMemoProps) => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      return await instance.patch(`${ud?.path}/companies/${mute.company_id}/memo`, mute.formData);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([]);
        toast({ ...saveSuccess, title: '저장 성공', description: '성공적으로 저장이 완료되었습니다.' });
      },
      onError: () => {
        toast(saveFail);
      },
    },
  );
};

interface MuteUpdateUserInfoProps {
  user_id: string;
  phone_number_first?: string;
  phone_number_second?: string;
  user_name?: string;
  department?: string;
  position?: string;
}

export const useUpdateUserInfo = () => {
  const [user, loading, error] = useAuthState(auth);
  const toast = useToast();
  const { data: ud } = useGetUserPathandKey();

  return useMutation(
    async ({ user_id, ...props }: MuteUpdateUserInfoProps) => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      return await instance.patch(`${ud?.path}/users/${user_id}/profile`, {
        ...props,
      });
    },

    {
      onSuccess: () => {
        queryClient.invalidateQueries([company_managers_key]);
        queryClient.invalidateQueries([company_hosts_key]);
        queryClient.invalidateQueries([tenant_detail_lookup_key]);
        toast(saveSuccess);
      },
      onError: () => {
        toast(saveFail);
      },
    },
  );
};

interface MuteUpdateRoomProps {
  room_id: string;
  formData: FormData;
}

export const useUpdateRoom = () => {
  const [user, loading, error] = useAuthState(auth);
  const toast = useToast();
  const { data: ud } = useGetUserPathandKey();

  return useMutation(
    async (mute: MuteUpdateRoomProps) => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      return await instance.patch(`${ud?.path}/rooms/${mute.room_id}`, mute.formData);
    },
    {
      onSuccess: () => {
        toast(saveSuccess);
      },
      onError: () => {
        toast(saveFail);
      },
    },
  );
};

interface MuteCreateBuildingProps {
  formData: FormData;
}

export const useCreateBuilding = () => {
  const [user, loading, error] = useAuthState(auth);
  const toast = useToast();
  const { data: ud } = useGetUserPathandKey();

  return useMutation(
    async (mute: MuteCreateBuildingProps) => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      return await instance.post(`${ud?.path}/building`, mute.formData);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([]);
        toast({ ...saveSuccess, description: '건물이 생성 되었습니다.' });
      },
      onError: () => {
        toast({ ...saveFail, description: '건물 생성에 실패했습니다.' });
      },
    },
  );
};

interface MuteUpdateBuildingProps {
  formData: FormData;
  building_id: string;
}

export const useUpdateBuilding = () => {
  const [user, loading, error] = useAuthState(auth);
  const toast = useToast();
  const { data: ud } = useGetUserPathandKey();

  return useMutation(
    async (mute: MuteUpdateBuildingProps) => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      return await instance.patch(`${ud?.path}/buildings/${mute.building_id}`, mute.formData);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
        toast({ ...saveSuccess, description: '건물 정보를 업데이트하였습니다.' });
      },
      onError: () => {
        toast({ ...saveFail, description: '건물 정보 업데이트에 실패하였습니다.' });
      },
    },
  );
};

interface MuteUploadFilesProps {
  formData: FormData;
}

export const useUploadFiles = () => {
  const [user, loading, error] = useAuthState(auth);
  const toast = useToast();
  const { data: ud } = useGetUserPathandKey();

  return useMutation(
    async (mute: MuteUploadFilesProps) => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      return await instance.post(`${ud?.path}/pictures`, mute.formData);
    },
    {
      onSuccess: () => {
        toast({ ...saveSuccess });
      },
      onError: () => {
        toast({ ...saveFail });
      },
    },
  );
};

interface MuteDeleteRoomProps {
  room_id: string;
}

export const useDeleteRoom = () => {
  const [user, loading, error] = useAuthState(auth);
  const toast = useToast();
  const { data: ud } = useGetUserPathandKey();
  const navigate = useNavigate();

  return useMutation(
    async (mute: MuteDeleteRoomProps) => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      return await instance.delete(`${ud?.path}/rooms/${mute.room_id}`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([]);
        toast({ ...saveSuccess, description: '매물이 삭제 되었습니다.' });
        navigate('/admin/manage/estates/rooms');
      },
      onError: () => {
        toast({ ...saveFail, description: '매물 삭제에 실패했습니다.' });
      },
    },
  );
};

interface MuteDeleteSubscriptionContractFieldProps {
  subscription_contract_id: string;
  field: string;
}

export const useDeleteSubscriptionContractField = () => {
  const [user, loading, error] = useAuthState(auth);
  const toast = useToast();
  const { data: ud } = useGetUserPathandKey();
  const navigate = useNavigate();

  return useMutation(
    async (mute: MuteDeleteSubscriptionContractFieldProps) => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      return await instance.delete(
        `${ud?.path}/subscription-contracts/${mute.subscription_contract_id}/fields/${mute.field}`,
      );
    },

    {
      onSuccess: () => {
        queryClient.invalidateQueries();
      },
      onError: () => {
        return;
      },
    },
  );
};

interface MuteUpdateLeaseContractArrayFieldProps {
  lease_contract_id: string;
  field: string;
  operation: OperationEnum;
  formData: FormData;
}

export const useChangeLeaseContractArrayField = () => {
  const [user, loading, error] = useAuthState(auth);
  const toast = useToast();
  const { data: ud } = useGetUserPathandKey();
  const navigate = useNavigate();

  return useMutation(
    async (mute: MuteUpdateLeaseContractArrayFieldProps) => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      return await instance.post(
        `${ud?.path}/lease-contracts/${mute.lease_contract_id}/fields/${mute.field}/operations/${mute.operation}`,
        mute.formData,
      );
    },

    {
      onSuccess: () => {
        queryClient.invalidateQueries();
        toast({ ...saveSuccess });
      },
      onError: () => {
        toast({ ...saveFail });
      },
    },
  );
};

interface MuteCreateRoomInBuildingProps {
  building_id: string;
  formData: FormData;
}

export const useCreateRoomInBuilding = () => {
  const [user, loading, error] = useAuthState(auth);
  const toast = useToast();
  const { data: ud } = useGetUserPathandKey();

  return useMutation(
    async (mute: MuteCreateRoomInBuildingProps) => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      return await instance.post(`${ud?.path}/buildings/${mute.building_id}/room`, mute.formData);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
        toast({ ...saveSuccess, description: '매물이 생성 되었습니다.' });
      },
      onError: () => {
        toast({ ...saveFail, description: '매물 생성에 실패했습니다.' });
      },
    },
  );
};

interface MuteSendMessageOnComplaint {
  user_id: string;
  complaint_id: string;
  formData: FormData;
}

export const useSendMessageOnComplaint = () => {
  const [user, loading, error] = useAuthState(auth);
  const toast = useToast();
  const { data: ud } = useGetUserPathandKey();

  return useMutation(
    async (mute: MuteSendMessageOnComplaint) => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      return await instance.post(`${ud?.path}/tenants/${mute.user_id}/complaints/${mute.complaint_id}`, mute.formData);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([tenant_complaint_key]);
        toast({ ...saveSuccess, description: '성공적으로 답변을 보냈습니다.' });
      },
      onError: () => {
        toast({ ...saveFail, description: '답변 보내기에 실패했습니다.' });
      },
    },
  );
};

interface MuteDuplicateRealRoomProps {
  room_id: string;
  formData: FormData;
}

export const useDuplicateRealRoom = () => {
  const [user, loading, error] = useAuthState(auth);
  const toast = useToast();
  const { data: ud } = useGetUserPathandKey();

  return useMutation(
    async (mute: MuteDuplicateRealRoomProps) => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      return await instance.post(`${ud?.path}/rooms/${mute.room_id}/duplicate-real-room`, mute.formData);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([Revise_buildings_rooms_key]);
        toast(saveSuccess);
      },
      onError: () => {
        toast(saveFail);
      },
    },
  );
};

interface MuteDuplicateRoomProps {
  room_id: string;
}

export const useDuplicateRoom = () => {
  const [user, loading, error] = useAuthState(auth);
  const toast = useToast();
  const { data: ud } = useGetUserPathandKey();

  return useMutation(
    async (mute: MuteDuplicateRoomProps) => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      return await instance.post(`${ud?.path}/rooms/${mute.room_id}/duplicate`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([]);
        toast({ ...saveSuccess, title: '매물 복사에 성공하였습니다', description: '' });
      },
      onError: () => {
        toast({ ...saveFail, title: '매물 복사에 실패하였습니다', description: '' });
      },
    },
  );
};

interface MuteMakeUserHostProps {
  user_name: string;
  phone_number_first: string;
  registration_number: string;
  accounts: Array<{
    bank_code: number;
    account_number: string;
    account_owner_name: string;
  }>;
  resident_registers: Array<File>;
  account_copies: Array<File>;
  address: string;
  road_address: string;
  sido_name: string;
  sigungu_name: string;
  dongli_name: string;
  detail_address: string;
}

export const useMakeUserHost = () => {
  const [user, loading, error] = useAuthState(auth);
  const toast = useToast();
  const { data: ud } = useGetUserPathandKey();

  const formData = new FormData();

  return useMutation(
    async (mute: MuteMakeUserHostProps) => {
      const {
        user_name,
        phone_number_first,
        accounts,
        resident_registers,
        account_copies,
        registration_number,
        address,
        road_address,
        sido_name,
        sigungu_name,
        dongli_name,
        detail_address,
      } = mute;
      formData.append('user_name', user_name);
      formData.append('registration_number', registration_number);
      formData.append('address', address);
      formData.append('road_address', road_address);
      formData.append('sido_name', sido_name);
      formData.append('sigungu_name', sigungu_name);
      formData.append('dongli_name', dongli_name);
      formData.append('detail_address', detail_address);
      formData.append('phone_number_first', phone_number_first);

      formData.append('accounts', JSON.stringify(accounts));

      resident_registers.map((register) => {
        formData.append('resident_registers', register);
      });
      account_copies.map((copy) => {
        formData.append('account_copies', copy);
      });
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      return await instance.post(`${ud?.path}/users/host`, formData);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([companies_hosts_key]);

        toast(saveSuccess);
      },
      onError: () => {
        toast({
          ...saveFail,
          description: '저장에 실패하셨습니다.',
        });
      },
    },
  );
};

interface MuteMakeUserHostAndCompanyProps extends MuteMakeUserHostProps {
  business_certificate: File;
  business_type: string;
  business_registration_number: string;
  company_name: string;
  registration_number: string;
  address: string;
  road_address: string;
  sido_name: string;
  sigungu_name: string;
  dongli_name: string;
  detail_address: string;
}

export const useMakeUserHostAndCompany = () => {
  const [user, loading, error] = useAuthState(auth);
  const toast = useToast();
  const { data: ud } = useGetUserPathandKey();
  const formData = new FormData();

  return useMutation(
    async (mute: MuteMakeUserHostAndCompanyProps) => {
      const {
        user_name,
        phone_number_first,
        accounts,
        resident_registers,
        account_copies,
        business_certificate,
        business_type,
        business_registration_number,
        registration_number,
        company_name,
        address,
        road_address,
        sido_name,
        sigungu_name,
        dongli_name,
        detail_address,
      } = mute;

      // const accounts_parsed = accounts.filter((account) => account.)

      formData.append('user_name', user_name);
      formData.append('phone_number_first', phone_number_first);
      formData.append('accounts', JSON.stringify(accounts));

      resident_registers.map((register) => {
        formData.append('resident_registers', register);
      });
      account_copies.map((copy) => {
        formData.append('account_copies', copy);
      });

      formData.append('business_certificate', business_certificate);
      formData.append('business_type', business_type);
      formData.append('business_registration_number', business_registration_number);
      formData.append('registration_number', registration_number);
      formData.append('company_name', company_name);
      //
      formData.append('address', address);
      formData.append('road_address', road_address);
      formData.append('sido_name', sido_name);
      formData.append('sigungu_name', sigungu_name);
      formData.append('dongli_name', dongli_name);
      formData.append('detail_address', detail_address);

      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      return await instance.post(`${ud?.path}/users/host-and-company`, formData);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([companies_hosts_key]);

        toast(saveSuccess);
      },
      onError: () => {
        toast({
          ...saveFail,
          description: '저장에 실패하셨습니다. 미기입된 사항이나 중복된 사업자가 아닌지 확인이 필요합니다.',
        });
      },
    },
  );
};

interface MuteMakeTourReservationProps {
  room_ids: Array<string>;
  start_date: Date;
  end_date: Date;
  location_description: string;
  tour_type: TourTypeEnum;
}

export const useMakeTourReservationWithAdmin = (custom_room_request_id: string) => {
  const [user, loading, error] = useAuthState(auth);
  const toast = useToast();
  const { data: ud } = useGetUserPathandKey();

  return useMutation(
    async (mute: MuteMakeTourReservationProps) => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      return await instance.post(`${ud?.path}/custom-room-requests/${custom_room_request_id}/tour/with-admin`, {
        room_ids: mute.room_ids,
        start_date: mute.start_date,
        end_date: mute.end_date,
        location_description: mute.location_description,
        tour_type: mute.tour_type,
      });
    },

    {
      onSuccess: () => {
        queryClient.invalidateQueries([custom_room_request_rooms_key, custom_room_requests_key]);
        toast(saveSuccess);
      },
      onError: () => {
        toast(saveFail);
      },
    },
  );
};

interface MuteUpdateRecommendedRoomsProps {
  room_ids: Array<string>;
  status: RequestStatusEnum;
}

/** 입주신청서 추천된 매물 상태 변경 */
export const useUpdateRecommendedRooms = (custom_room_request_id: string) => {
  const [user, loading, error] = useAuthState(auth);
  const toast = useToast();
  const { data: ud } = useGetUserPathandKey();

  return useMutation(
    async (mute: MuteUpdateRecommendedRoomsProps) => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      return await instance.patch(`${ud?.path}/custom-room-requests/${custom_room_request_id}/rooms/status`, {
        room_ids: mute.room_ids,
        status: mute.status,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
        toast(saveSuccess);
      },
      onError: () => {
        toast(saveFail);
      },
    },
  );
};

interface MuteCreateCommonChatRoomProps {
  user_ids: Array<string>;
}
export const useCreateCommonChatRoom = () => {
  const [user, loading, error] = useAuthState(auth);
  const toast = useToast();
  const { data: ud } = useGetUserPathandKey();

  return useMutation(
    async (mute: MuteCreateCommonChatRoomProps) => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      return await instance.post(`${ud?.path}/chat-room`, {
        user_ids: mute.user_ids,
      });
    },

    {
      onSuccess: () => {
        queryClient.invalidateQueries([custom_room_request_rooms_key]);
        toast({ ...saveSuccess, title: '채팅방 생성', description: '채팅방 생성 완료' });
      },
      onError: () => {
        toast({ ...saveFail, title: '채팅방 생성', description: '채팅방 생성 실패' });
      },
    },
  );
};

interface MuteUpdateSubscriptionPaymentStatusProps {
  payment_type?: string;
  payment_status?: SubscriptionPaymentStatusEnum;
  price?: number;
  use_start_date?: string;
  use_end_date?: string;
  expected_payment_date?: number;
  real_payment_date?: string;
  payment_method_type?: string;
  is_paid?: boolean;
  description?: string;
  certificate_issue_type?: CertificateIssueTypeEnum;
}
export const useUpdateSubscriptionPaymentStatus = (subscription_payment_id: string) => {
  const [user, loading, error] = useAuthState(auth);
  const toast = useToast();
  const { data: ud } = useGetUserPathandKey();

  return useMutation(
    async (mute: MuteUpdateSubscriptionPaymentStatusProps) => {
      const req = trimObject(mute);
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      return await instance.patch(`${ud?.path}/subscription-payments/${subscription_payment_id}`, req);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
        toast({ ...saveSuccess, title: '납부 상태 변경', description: '납부 상태 변경 완료' });
      },
      onError: () => {
        toast({ ...saveFail, title: '납부 상태 변경', description: '납부 상태 변경 실패' });
      },
    },
  );
};

interface MuteChangeSubscriptionContractStatusProps {
  subscription_contract_id: string;
  formData: FormData;
}
export const useChangeSubscriptionContractStatus = () => {
  const [user, loading, error] = useAuthState(auth);
  const toast = useToast();
  const { data: ud } = useGetUserPathandKey();

  return useMutation(
    async (mute: MuteChangeSubscriptionContractStatusProps) => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      return await instance.patch(`${ud?.path}/subscription-contracts/${mute.subscription_contract_id}`, mute.formData);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
        toast({ ...saveSuccess, title: '계약 상태 변경', description: '계약 상태 변경 완료' });
      },
      onError: () => {
        toast({ ...saveFail, title: '계약 상태 변경', description: '계약 상태 변경 실패' });
      },
    },
  );
};

interface MuteChangeSubscriptionPaymentStatusProps {
  status: GlobalStatus;
}
export const useChangeSubscriptionPaymentStatus = (subscription_payment_id: string) => {
  const [user, loading, error] = useAuthState(auth);
  const toast = useToast();
  const { data: ud } = useGetUserPathandKey();

  return useMutation(
    async (mute: MuteChangeSubscriptionPaymentStatusProps) => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      return await instance.patch(`${ud?.path}/subscription-payments/${subscription_payment_id}`, {
        status: mute.status,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
        toast({ ...saveSuccess, title: '계약 영수증 상태 변경', description: '계약 영수증 상태 변경 완료' });
      },
      onError: () => {
        toast({ ...saveFail, title: '계약 영수증 상태 변경', description: '계약 영수증 상태 변경 실패' });
      },
    },
  );
};

interface MuteRegisterPreServiceReqeustsFileProps {
  formData: FormData;
  company_id: string;
}
export const MuteRegisterPreServiceReqeustsFile = () => {
  const [user, loading, error] = useAuthState(auth);
  const toast = useToast();
  const { data: ud } = useGetUserPathandKey();

  return useMutation(
    async (mute: MuteRegisterPreServiceReqeustsFileProps) => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      return await instance.post(`${ud?.path}/companies/${mute.company_id}/pre-service-requests/file`, mute.formData);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([pre_service_requests_key]);
        toast({ ...saveSuccess, title: '파일 업로드 및 대량 등록', description: '파일 업로드 및 대량 등록 완료' });
      },
      onError: () => {
        toast({ ...saveFail, title: '파일 업로드 및 대량 등록', description: '파일 업로드 및 대량 등록 실패' });
      },
    },
  );
};

interface MuteCreateSubscriptionPaymentOfSupportPaymentProps {
  company_id: string;
}
export const MuteCreateSubscriptionPaymentOfSupportPayment = () => {
  const [user, loading, error] = useAuthState(auth);
  const toast = useToast();
  const { data: ud } = useGetUserPathandKey();

  return useMutation(
    async (mute: MuteCreateSubscriptionPaymentOfSupportPaymentProps) => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      return await instance.post(`${ud?.path}/create-or-update-subscription-payment-of-support-payment`);
    },
    {
      onSuccess: () => {
        toast({ ...saveSuccess, title: '구독 납부 영수증 생성', description: '10초 뒤 새로고침 해주세요' });
      },
      onError: () => {
        toast({ ...saveFail, title: '구독 납부 영수증 생성', description: '10초 뒤 새로고침 해주세요' });
      },
    },
  );
};

interface MuteUpdateCompanyRoomMemoProps {
  room_id?: string;
  company_memo?: string;
}

/** 회사(어드민, 고객사, 중개사, 호스트)의 특정 매물 메모 수정 */
export const useUpdateCompanyRoomMemo = () => {
  const [user, loading, error] = useAuthState(auth);
  const toast = useToast();
  const { data: ud } = useGetUserPathandKey();

  return useMutation(
    async (mute: MuteUpdateCompanyRoomMemoProps) => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      return await instance.patch(`${ud?.path}/rooms/${mute.room_id}/company-memo`, {
        company_memo: mute.company_memo,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
        toast({ ...saveSuccess, title: '매물 메모', description: '매물 메모 업데이트 성공' });
      },
      onError: () => {
        toast({ ...saveFail, title: '매물 메모', description: '매물 메모 업데이트 실패' });
      },
    },
  );
};

interface MuteChangeContractProps {
  subscription_contract_id: string;
  status_path:
    | 'confirm-expected'
    | 'sign-expected'
    | 'total-contract-payment-expected'
    | 'subscription-payment-expected';
}

export const useChangeSubscriptionContractPaymentStatus = () => {
  const [user, loading, error] = useAuthState(auth);
  const toast = useToast();
  const { data: ud } = useGetUserPathandKey();

  return useMutation(
    async (mute: MuteChangeContractProps) => {
      const { subscription_contract_id, status_path } = mute;
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      return await instance.post(`${ud?.path}/subscription-contracts/${subscription_contract_id}/${status_path}`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([]);
        toast(saveSuccess);
      },
      onError: () => {
        toast(saveFail);
      },
    },
  );
};

export type VirtualAccountUserType = 'tenant' | 'company';

export interface MuteCreateUserVirtualAccountProps {
  userType: VirtualAccountUserType;
  userId: string;
}
export const useCreateUserVirtualAccount = () => {
  const [user, loading, error] = useAuthState(auth);
  const toast = useToast();
  const { data: ud } = useGetUserPathandKey();

  return useMutation(
    async (mute: MuteCreateUserVirtualAccountProps) => {
      const { userType, userId } = mute;
      const userTypePath = {
        tenant: 'tenants',
        company: 'subscribers',
      }[userType];
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      await instance.post(`${ud?.path}/users/${userTypePath}/${userId}/wp/user`);
      let result = await instance.post(`${ud?.path}/users/${userTypePath}/${userId}/wp/virtual-account`);
      return result;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([]);
        toast({ ...saveSuccess, title: '발급 성공', description: '가상계좌 발급이 완료되었습니다.' });
      },
      onError: () => {
        toast({ ...saveFail, title: '발급 실패', description: '가상계좌 발급을 실패하였습니다.' });
      },
    },
  );
};

export interface MuteCreateUserVirtualAccountAndLeasePaymentStatusProps {
  userType: VirtualAccountUserType;
  userId: string;
  lease_contract_id: string;
}

/** 월세지원유저의 가상계좌 생성할때도 필요 */
export const useCreateUserVirtualAccountAndLeasePaymentStatus = () => {
  const [user, loading, error] = useAuthState(auth);
  const toast = useToast();
  const { data: ud } = useGetUserPathandKey();

  return useMutation(
    async (mute: MuteCreateUserVirtualAccountAndLeasePaymentStatusProps) => {
      const { userType, userId } = mute;
      const userTypePath = {
        tenant: 'tenants',
        company: 'subscribers',
      }[userType];
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      await instance.post(`${ud?.path}/users/${userTypePath}/${userId}/wp/user`);
      await instance.post(`${ud?.path}/users/${userTypePath}/${userId}/wp/virtual-account`);
      const result = await instance.post(`${ud?.path}/lease-contracts/${mute.lease_contract_id}/lease-payments/v2`);
      return result;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([]);
        toast({ ...saveSuccess, title: '발급 성공', description: '가상계좌 발급이 완료되었습니다.' });
      },
      onError: () => {
        toast({ ...saveFail, title: '발급 실패', description: '가상계좌 발급을 실패하였습니다.' });
      },
    },
  );
};

export interface useCreateCompanyVirtualAccountProps {
  company_id: string;
  category: ServiceCategoryEnum;
}

export const useCreateCompanyVirtualAccount = () => {
  const [user, loading, error] = useAuthState(auth);
  const toast = useToast();
  const { data: ud } = useGetUserPathandKey();

  return useMutation(
    async (mute: useCreateCompanyVirtualAccountProps) => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      return await instance.post(`${ud?.path}/companies/${mute.company_id}/wp/user-and-virtual-account`, {
        category: mute.category,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([supportPayment_company_users_v2_key]);
        toast({ ...saveSuccess, title: '계좌 생성 성공', description: '계좌이 완료되었습니다.' });
      },
      onError: () => {
        toast({ ...saveFail, title: '계좌 생성 실패', description: '계좌이 실패하였습니다.' });
      },
    },
  );
};

interface MuteUpdateVirtualAccountProps extends MuteCreateUserVirtualAccountProps {
  payload: {
    vaccntOwnerName?: string;
    depoAmt?: number;
    depoEndDt?: string;
  };
}

export const useUpdateVirtualAccount = () => {
  const [user, loading, error] = useAuthState(auth);
  const toast = useToast();
  const { data: ud } = useGetUserPathandKey();

  return useMutation(
    async (mute: MuteUpdateVirtualAccountProps) => {
      const { userType, userId } = mute;
      const userTypePath = {
        tenant: 'tenants',
        company: 'subscribers',
      }[userType];
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      return await instance.post(
        `${ud?.path}/users/${userTypePath}/${userId}/wp/virtual-account/control`,
        mute.payload,
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([virtual_account_user_key]);
        queryClient.invalidateQueries([tenant_detail_lookup_key]);
        toast({ ...saveSuccess, title: '업데이트 성공', description: '가상계좌 업데이트가 완료되었습니다.' });
      },
      onError: () => {
        toast({ ...saveFail, title: '업데이트 실패', description: '가상계좌 업데이트를 실패하였습니다.' });
      },
    },
  );
};

interface MuteAddUserAccountProps {
  user_id: string;
  account: AccountType;
}
export const useAddUserAccount = () => {
  const [user, loading, error] = useAuthState(auth);
  const toast = useToast();
  const { data: ud } = useGetUserPathandKey();

  return useMutation(
    async (mute: MuteAddUserAccountProps) => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      return await instance.post(`${ud?.path}/users/${mute.user_id}/account/add`, {
        account: {
          bank_code: mute.account?.bank_code,
          account_owner_name: mute.account?.account_owner_name,
          account_number: mute.account?.account_number,
        },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([]);
        toast({ ...saveSuccess, title: '계좌 생성 성공', description: '계좌이 완료되었습니다.' });
      },
      onError: () => {
        toast({ ...saveFail, title: '계좌 생성 실패', description: '계좌이 실패하였습니다.' });
      },
    },
  );
};

interface MuteCreateLeaseContractProps {
  formData: FormData;
}
export const useCreateLeaseContractStatus = () => {
  const [user, loading, error] = useAuthState(auth);
  const toast = useToast();
  const { data: ud } = useGetUserPathandKey();

  return useMutation(
    async (mute: MuteCreateLeaseContractProps) => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      return await instance.post(`${ud?.path}/lease-contract`, mute.formData);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
        toast({ ...saveSuccess, title: '계약 생성 성공', description: '계약이 완료되었습니다.' });
      },
      onError: () => {
        toast({ ...saveFail, title: '계약 생성 실패', description: '계약이 실패하였습니다.' });
      },
    },
  );
};

interface MuteCreateLeasePaymentsProps {
  lease_contract_id: string;
  payment_method_type: PaymentMethodType;
}
export const useCreateLeasePaymentsStatus = () => {
  const [user, loading, error] = useAuthState(auth);
  const toast = useToast();
  const { data: ud } = useGetUserPathandKey();
  console.log('useCreateLeasePaymentsStatus');

  return useMutation(
    async (mute: MuteCreateLeasePaymentsProps) => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      return await instance.post(`${ud?.path}/lease-contracts/${mute.lease_contract_id}/lease-payments/v2`, {
        payment_method_type: mute.payment_method_type,
      });
    },
    {
      onSuccess: () => {
        // queryClient.invalidateQueries([virtual_account_key]);
        toast({
          ...saveSuccess,
          title: '구독 납부 영수증 생성 성공',
          description: '구독 납부 영수증이 완료되었습니다.',
        });
      },
      onError: () => {
        toast({ ...saveFail, title: '구독 납부 영수증 생성 실패', description: '구독 납부 영수증이 실패하였습니다.' });
      },
    },
  );
};

interface MuteUpdateLeasePaymentsProps {
  lease_payment_id: string;
  lease_contract?: {
    use_start_date?: string;
    use_end_date?: string;
    expected_payment_date?: string;
    payment_method_type?: string;
    host_id?: string;
    real_room_id?: string;
    tenant_id?: string;
    lease_contract_id?: string;
    contract_payment?: number;
    price?: number;
    management_fee?: number;
    support_payment?: number;
    real_payment_date?: string;
    real_transfer_date?: string;
    is_paid?: boolean;
    is_transferred?: boolean;
    proxy_payment_status?: LeaseProxyPaymentStatusEnum;
    status?: GlobalStatus;
    support_payment_status?: string;
    certificate_issue_type?: CertificateIssueTypeEnum;
    is_certificate_issued?: boolean;
    payment_status?: string;
  };
}
export const useUpdateLeasePaymentsStatus = () => {
  const [user, loading, error] = useAuthState(auth);
  const toast = useToast();
  const { data: ud } = useGetUserPathandKey();

  return useMutation(
    async (mute: MuteUpdateLeasePaymentsProps) => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      return await instance.patch(`${ud?.path}/lease-payments/${mute.lease_payment_id}`, mute.lease_contract);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([companies_tenants_leaseContracts_key]);
        toast({
          ...saveSuccess,
          title: '구독 납부 영수증 업데이트 성공',
          description: '구독 납부 영수증이 업데이트 되었습니다.',
        });
      },
      onError: () => {
        toast({
          ...saveFail,
          title: '구독 납부 영수증 업데이트 실패',
          description: '구독 납부 영수증 업데이트가 실패하였습니다.',
        });
      },
    },
  );
};

interface MuteCreateManualSimpleLeasePaymentProps {
  use_start_date: string;
  use_end_date: string;
  expected_payment_date: string;
  payment_method_type: string;
  host_id: string;
  real_room_id?: string;
  tenant_id?: string;
  lease_contract_id?: string;
  contract_payment?: number;
  price?: number;
  management_fee?: number;
  support_payment?: number;
  real_payment_date?: string;
  real_transfer_date?: string;
  is_paid?: boolean;
  is_transferred?: boolean;
  is_proxy_payment?: boolean;
  support_status?: string;
  // proxy_payment_status?: LeaseProxyPaymentStatusEnum;
  certificate_issue_type?: CertificateIssueTypeEnum;
  // is_certificate_issued?: boolean;
  payment_status?: string;
}
export const useCreateManualSimpleLeasePayment = () => {
  const [user, loading, error] = useAuthState(auth);
  const toast = useToast();
  const { data: ud } = useGetUserPathandKey();

  return useMutation(
    async (mute: MuteCreateManualSimpleLeasePaymentProps) => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      return await instance.post(`${ud?.path}/lease-payment/no-validation`, mute);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([companies_tenants_leaseContracts_key]);
        toast({
          ...saveSuccess,
          title: '수동 청구서 등록 성공',
          description: '수동 청구서가 등록되었습니다.',
        });
      },
      onError: () => {
        toast({
          ...saveFail,
          title: '수동 청구서 등록 실패',
          description: '수동 청구서 등록에 실패하였습니다.',
        });
      },
    },
  );
};

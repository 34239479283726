import { Table, Tbody, Td, Text, Th, Thead, Tr, VStack } from '@chakra-ui/react';
import { format } from 'date-fns';
import { UseQueryResult } from 'react-query';
import ChangeCertificateIssueStatus from '../../../common/SelectBox/ChangeCertificateIssueStatus';
import ChangeSubscriptionPaymentGlobalStatus from '../../../common/SelectBox/ChanageSubscriptionPaymentGlobalStatus';
import ChangeSubscriptionPaymentStatus from '../../../common/SelectBox/ChangeSubscriptionPaymentStatus';
import ChangeSubscriptionRealPaymentDate from '../../../common/SelectBox/ChangeSubscriptionPaymentRealPaymentDate';
import { ServiceCategoryTrans } from '../../../../lib/common/utils/utils';
import { CompanyPayment, QueryResponse } from '../../../../lib/common/utils/axios/types';
import { paymentMethodTypeTrans } from '../../../../lib/subscriber/utils/utils';
import { datedotformat } from '../../../../lib/common/utils/dateformatter';
import { staticThreeCommafy } from '../../../../lib/common/utils/digitformatter';

interface SubscriptionSettlementsTableProps<Type> {
  res?: UseQueryResult<QueryResponse<Type>, unknown>;
}

const SubscriptionSettlementsTable: React.FC<SubscriptionSettlementsTableProps<CompanyPayment[]>> = ({ res }) => {
  console.log('res =>', res);

  return (
    <Table variant="head_silverbg_toplining">
      <Thead bgColor={'#F8F8F8'} borderColor={'gray.100'}>
        <Tr>
          <Th>청구 종류</Th>
          <Th>회사명</Th>
          <Th>서비스</Th>
          <Th>청구 금액</Th>
          <Th>납부 상태</Th>
          <Th>납기일</Th>
          <Th>납부일</Th>
          <Th>납부방법</Th>
          <Th>증빙발행</Th>
          <Th>상태</Th>
        </Tr>
      </Thead>
      <Tbody>
        {res?.data?.result.map((tenant, index) => {
          if (tenant.subscription_contract.subscription_payment.category === 'support_payment') {
            console.log(tenant.subscription_contract.subscription_payment);
          }
          const {
            subscription_contract: {
              available_user_count,
              contract_created_date,
              contract_start_date,
              subscription_type,
              subscription_payment: {
                _id: subscription_payment_id,
                company_id,
                certificate_issue_type,
                category,
                createdAt,
                discount_price,
                expected_payment_date,
                is_paid,
                payment_status,
                is_expenditure_evidence,
                payment_method_type,
                payment_type,
                price,
                subscription_contract_id,
                status,
                updatedAt,
                use_start_date,
                total_sum,
                vat,
                real_payment_date,
              },
            },
            name: user_name,
            virtual_account,
          } = tenant;

          return (
            <Tr key={index}>
              <Td>{expected_payment_date ? `구독료(${new Date(expected_payment_date).getMonth() + 1}월)` : '-'}</Td>
              <Td>
                <Text>{user_name}</Text>
              </Td>
              <Td>
                <Text>{ServiceCategoryTrans(category)}</Text>
              </Td>
              <Td>{staticThreeCommafy(total_sum)}원</Td>
              <Td>
                <ChangeSubscriptionPaymentStatus
                  payment_status={payment_status}
                  subscription_payment_id={subscription_payment_id}
                />
              </Td>
              <Td>{datedotformat(expected_payment_date) || '-'}</Td>
              <Td>
                <ChangeSubscriptionRealPaymentDate
                  real_payment_date={real_payment_date}
                  subscription_payment_id={subscription_payment_id}
                />
              </Td>
              <Td>{paymentMethodTypeTrans(payment_method_type)}</Td>
              <Td>
                <ChangeCertificateIssueStatus
                  certificate_issue_status={certificate_issue_type}
                  subscription_payment_id={subscription_payment_id}
                ></ChangeCertificateIssueStatus>
              </Td>
              <Td>
                <ChangeSubscriptionPaymentGlobalStatus
                  global_status={status}
                  subscription_payment_id={subscription_payment_id}
                />
              </Td>
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
};

export default SubscriptionSettlementsTable;

import { Count, DetailContract, Payment } from '../../../lib/common/utils/axios/types';

export const placeholderData_count = {
  services_count: [
    {
      _id: {
        key: 'total',
      },
      count: 0,
      ids: [''],
    },
    {
      _id: {
        key: 'new',
      },
      count: 0,
      ids: [''],
    },
    {
      _id: {
        key: 'changing',
      },
      count: 0,
      ids: [''],
    },
    {
      _id: {
        key: 'extending',
      },
      count: 0,
      ids: [''],
    },
    {
      _id: {
        key: 'withdrawal',
      },
      count: 0,
      ids: [''],
    },
    {
      _id: {
        key: 'moving_out_and_extending',
      },
      count: 0,
      ids: [''],
    },
  ],
  usings_count: [
    {
      _id: {
        key: 'using',
      },
      count: 0,
      ids: [''],
    },
    {
      _id: {
        key: 'in_service',
      },
      count: 0,
      ids: [''],
    },
    {
      _id: {
        key: 'in_custom_room_request',
      },
      count: 0,
      ids: [''],
    },
    {
      _id: {
        key: 'contracting',
      },
      count: 0,
      ids: [''],
    },
    {
      _id: {
        key: 'contracted',
      },
      count: 0,
      ids: [''],
    },
    {
      _id: {
        key: 'moving_in',
      },
      count: 0,
      ids: [''],
    },
    {
      _id: {
        key: 'moving_out',
      },
      count: 0,
      ids: [''],
    },
  ],
  supports_count: [
    {
      _id: {
        key: 'total',
      },
      count: 0,
      ids: [''],
    },
  ],
  proxy_payments_count: [
    {
      _id: {
        key: 'total',
      },
      count: 0,
      ids: [''],
    },
  ],
  lease_payment_overdues_count: [
    {
      _id: {
        key: 'total',
      },
      count: 0,
      ids: [''],
    },
  ],
  inactive_count: [
    {
      _id: {
        key: 'total',
      },
      count: 0,
      ids: [''],
    },
  ],
  subscription_payment_overdues_count: [
    {
      _id: {
        key: 'total',
      },
      count: 0,
      ids: [''],
    },
  ],
} as Count;

export const placeholderData_subscription_contract = {
  change_request: {
    check_status: 'none',
    description: '',
  },
  _id: '',
  company_id: '',
  price: 0,
  discount_price: 0,
  status: 'new',
  payment_status: '',
  payment_day: 0,
  subscription_type: 'basic',
  contract_created_date: '2022-06-12T07:21:39.885Z',
  contract_start_date: '2022-06-12T07:21:39.885Z',
  subscription_payment_ids: [''],
  description: 'description',
  available_user_count: 0,
  is_year: false,
  is_init: true,
  payment_method_type: 'virtual_account',
  certificate_issue_type: 'tax_invoice',
  subscriber_check_status: 'checked',
  admin_check_status: 'pending',
  version: 1,
  version_ids: [],
  term_and_conditions: [],
  createdAt: '2022-06-12T07:21:39.896Z',
  updatedAt: '2022-06-12T16:58:52.748Z',
  __v: 0,
  vat: 50,
  request_type: 'new',
} as any;

export const placeholderData_subscription_payments = [
  {
    available_user_count: 0,
    real_payment_date: '',
    description: '',
    company_id: '',
    createdAt: '2022-06-12T16:58:52.748Z',
    discount_price: 0,
    expected_payment_date: '2022-06-12T16:58:52.748Z',
    is_paid: false,
    payment_method_type: 'virtual_account',
    payment_type: '',
    price: 0,
    subscription_contract_id: '',
    subscription_type: 'basic',
    updatedAt: '2022-06-12T16:58:52.748Z',
    use_end_date: '2022-06-12T16:58:52.748Z',
    use_start_date: '2022-06-12T16:58:52.748Z',
    vat: 0,
    __v: 0,
    _id: '',
  },
] as Payment[];

import { Outlet } from 'react-router-dom';
import DetailLayout from './DetailLayout';

const DetailLayoutRouter = () => {
  return (
    <DetailLayout>
      <Outlet />
    </DetailLayout>
  );
};

export default DetailLayoutRouter;

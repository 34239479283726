import { Flex, HStack, VStack } from '@chakra-ui/react';
import RippleButton from '../../../../../../components/common/Button/RippleButton';
import KakaoMap from '../../../../../../layouts/common/KakaoMap';
import InfoSector from '../sections/InfoSector';

const Companies = () => {
  return (
    <HStack alignItems={'flex-start'} spacing={10} pr={10}>
      {/* section 1 */}
      <Flex w={'250px'}>
        <VStack w={'full'} spacing={6}>
          {/* 고객사 회원가입 신청 */}
          <InfoSector total={0}>고객사 회원가입 신청</InfoSector>
          {/* 엔터프라이즈 플랜 요청 */}
          <InfoSector total={0}>엔터프라이즈 플랜 요청</InfoSector>
          {/* 신규 구독 요청 */}
          <InfoSector total={0}>신규 구독 요청</InfoSector>
          {/* 플랜 변경 요청 */}
          <InfoSector total={0}>플랜 변경 요청</InfoSector>
          {/* <RippleButton /> */}
        </VStack>
      </Flex>
    </HStack>
  );
};
export default Companies;

import { Box, ListItem, Text, UnorderedList, VStack } from '@chakra-ui/react';
import { Container } from '../sections/Container';
import { Main } from '../sections/Main';

export const PersonInfoTerm = () => (
  <Container height="100%">
    <Main>
      <Text fontWeight={'bold'} color={'zetaBlue'} fontSize={'11px'}>
        개인정보 이용 동의
      </Text>
      <VStack alignItems={'start'}>
        <VStack alignItems={'start'} spacing={3}>
          {/* 제1조 */}
          <VStack alignItems={'start'}>
            <Box fontSize={'11px'}>
              서비스 이용에 필수적인 개인정보를 다음과 같은 목적을 위하여 수집합니다. 처리하는 개인정보는 다음의 목적
              이외의 용도로는 이용되지 않으며, 처리 목적과 항목이 변경되는 경우에는 사전에 이용자의 동의를 받습니다.
            </Box>
          </VStack>
          {/* 제2조 */}
          <VStack alignItems={'start'}>
            <Box>
              <UnorderedList fontSize={'11px'} pl={2}>
                <ListItem>수집 목적 : 주거 복지 서비스 운영</ListItem>
                <ListItem>수집 항목 : </ListItem>
              </UnorderedList>
              <VStack alignItems={'start'} fontSize={'11px'} spacing={3} mt={3} pl={5}>
                <Box>
                  <Box fontSize={'11px'}>서비스 이용 과정에서 처리하는 개인정보의 항목</Box>
                  <Box fontSize={'11px'}>
                    - 필수항목: 프로필 정보 (닉네임/프로필 사진), 이름, 전화번호, 이메일, 성별, 주소, 주민등록번호 혹은
                    사업자 등록번호
                  </Box>
                </Box>
                <Box>
                  <Box fontSize={'11px'}>서비스 이용 과정에서 처리하는 정보</Box>
                  <Box fontSize={'11px'}>- 임대차 계약 작성 정보</Box>
                  <Box fontSize={'11px'}>- 월세 정산 및 증빙 자료</Box>
                </Box>
                <Box>
                  <Box fontSize={'11px'}>서비스 이용 과정에서 자동 생성 / 수집되는 정보</Box>
                  <Box fontSize={'11px'}>- IP 주소, 쿠키, 방문 일시, 서비스 이용 기록, 불량 이용 기록, 기기정보</Box>
                </Box>
                <Box>
                  <Box fontSize={'11px'}>개인정보 수집 방법 </Box>
                  <Box fontSize={'11px'}>- 회원가입 및 서비스 이용을 위해 고객이 직접 입력한 정보</Box>
                  <Box fontSize={'11px'}>- 상담과 민원처리 과정에서 수집/생성된 정보 </Box>
                  <Box fontSize={'11px'}>- 온오프라인의 이벤트/설문 등을 통한 수집 </Box>
                  <Box fontSize={'11px'}>- 서비스 이용과정에서 자동으로 수집되는 정보 </Box>
                </Box>
                <Box fontSize={'11px'}></Box>
              </VStack>
              <UnorderedList fontSize={'11px'} pl={2}>
                <ListItem>보유 및 이용기간 : </ListItem>
              </UnorderedList>
              <Box fontSize={'11px'} ml={5}>
                회원이 탈퇴를 요청하거나 개인정보 수집·이용에 대한 동의를 철회하는 경우, 수집·이용 목적이 달성되거나
                보유기간이 종료한 경우에는 해당 개인정보를 지체 없이 파기합니다. 단, 임대차 계약기간동안에는 개인정보
                수집,이용에 대한 동의가 필수입니다.
              </Box>
            </Box>
            <Text fontSize={'11px'} color="gray">
              *위 동의를 거부할 권리가 있으며, 동의를 거부하실 경우 서비스 이용이 제한됩니다.
            </Text>
          </VStack>
        </VStack>
      </VStack>
    </Main>
    <Main>
      <Text fontWeight={'bold'} fontSize={'11px'} color={'zetaBlue'} mt={4}>
        개인정보 처리 위탁 동의
      </Text>
      <VStack alignItems={'start'}>
        <Box fontSize={'11px'}>
          다음와 같이 개인정보 처리 업무를 일부 위탁하고 있으며, 관계법령에 따라 위탁 계약 시 개인정보가 안전하게 관리될
          수 있도록 필요한 조치를 하고 있습니다.
        </Box>
        <VStack alignItems={'start'} spacing={6}>
          <UnorderedList fontSize={'11px'} pl={2}>
            <ListItem>위탁받는 자 : 네이버클라우드(주)</ListItem>
            <Box fontSize={'11px'}>위탁하는 업무의 내용 : 문자인증, 알림톡 발송 서비스 </Box>
            <ListItem>위탁받는 자 : 웰컴페이먼츠 (주) </ListItem>
            <Box fontSize={'11px'}>위탁하는 업무의 내용 : 가상계좌 생성 및 월세 정산</Box>
            <ListItem>위탁받는 자 : 삼성카드 (주) </ListItem>
            <Box fontSize={'11px'}>위탁하는 업무의 내용 : 월세 정산 </Box>
            <ListItem mt={4}>보유기간 및 이용기간 : </ListItem>
            <Box fontSize={'11px'}>회원 탈퇴시 혹은 위탁 계약 종료 시까지</Box>
          </UnorderedList>
          <Text fontSize={'11px'} color="gray">
            *위 동의를 거부할 권리가 있으며, 동의를 거부하실 경우 서비스 이용이 제한됩니다.
          </Text>
        </VStack>
      </VStack>
    </Main>
  </Container>
);

import { Box, Center, Checkbox, Flex, HStack, Table, Tbody, Td, Text, Th, Thead, Tr, VStack } from '@chakra-ui/react';
import { addMonths, subMonths } from 'date-fns';
import format from 'date-fns/format';
import isToday from 'date-fns/isToday';
import { Field, Form, Formik } from 'formik';
import { useState } from 'react';
import { takeMonth, weeks } from '../../../../../../lib/common/utils/calender';
import { LeftArrowIcon, RightArrowIcon } from '../../../../../../statics/common/icons';

const Calendar = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const data = takeMonth(currentDate)();
  const nextMonth = () => {
    setCurrentDate(addMonths(currentDate, 1));
  };
  const prevMonth = () => {
    setCurrentDate(subMonths(currentDate, 1));
  };
  const initialValues = {
    ad_room_period: false,
    ad_tour: false,
    ad_in: false,
    ad_out: false,
    ad_monthly_payment: false,
    ad_subscription_fee: false,
  };

  return (
    <Box>
      {/* 테이블 상단 */}
      <HStack justifyContent={'space-between'} alignItems={'flex-end'}>
        <VStack spacing={0} alignItems={'flex-start'} flexWrap={'wrap'} w={'170px'}>
          <Box fontWeight={700} fontSize={'20px'}>
            {selectedDate.getFullYear()}년
          </Box>
          <HStack>
            <Text fontWeight={700} fontSize={'24px'} minW={'91px'}>
              {format(selectedDate, 'M월 d일')}
            </Text>
            <Text fontWeight={700} fontSize={'24px'} color="midGray">
              {weeks[selectedDate.getDay()]}요일
            </Text>
            <Center onClick={prevMonth} cursor={'pointer'} h={'100%'} minW={'30px'}>
              <LeftArrowIcon />
            </Center>
            <Center onClick={nextMonth} cursor={'pointer'} h={'100%'} minW={'30px'}>
              <RightArrowIcon />
            </Center>
          </HStack>
        </VStack>
        <Formik
          initialValues={initialValues}
          onSubmit={(values, { setSubmitting }) => {
            console.log(values);
            setSubmitting(false);
          }}
        >
          {({ values, handleChange }) => (
            <Form style={{ display: 'flex' }}>
              <HStack m={2}>
                <Field
                  name="ad_room_period"
                  as={Checkbox}
                  placeholder="비밀번호를 다시 한번 입력해 주세요."
                  type="password"
                  onChange={handleChange}
                  variant="small"
                >
                  매물 전달 기한
                </Field>
                <Field
                  name="ad_room_period"
                  as={Checkbox}
                  placeholder="비밀번호를 다시 한번 입력해 주세요."
                  type="password"
                  onChange={handleChange}
                  variant="small"
                >
                  투어
                </Field>
                <Field
                  name="ad_room_period"
                  as={Checkbox}
                  placeholder="비밀번호를 다시 한번 입력해 주세요."
                  type="password"
                  onChange={handleChange}
                  variant="small"
                >
                  입주
                </Field>
                <Field
                  name="ad_room_period"
                  as={Checkbox}
                  placeholder="비밀번호를 다시 한번 입력해 주세요."
                  type="password"
                  onChange={handleChange}
                  variant="small"
                >
                  퇴실
                </Field>
                <Field
                  name="ad_room_period"
                  as={Checkbox}
                  placeholder="비밀번호를 다시 한번 입력해 주세요."
                  type="password"
                  onChange={handleChange}
                  variant="small"
                >
                  월세 납부
                </Field>
                <Field
                  name="ad_room_period"
                  as={Checkbox}
                  placeholder="비밀번호를 다시 한번 입력해 주세요."
                  type="password"
                  onChange={handleChange}
                  variant="small"
                >
                  구독료 납부
                </Field>
              </HStack>
            </Form>
          )}
        </Formik>
      </HStack>
      {/* 테이블 */}
      <Table variant="simple">
        <Thead>
          <Tr>
            {weeks.map((week, index) => (
              <Th
                key={index}
                bgColor={'gray.200'}
                fontSize={'14px'}
                w={'140px'}
                py={1}
                textAlign={'center'}
                borderWidth={'1px'}
                borderColor={'gray.300'}
              >
                {week}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {data.map((week, index) => (
            <Tr key={index}>
              {week.map((day, index) => (
                <Td
                  key={index}
                  borderWidth={'1px'}
                  borderColor={'gray.300'}
                  w={'140px'}
                  p={0}
                  onClick={() => setSelectedDate(day)}
                  cursor={'pointer'}
                >
                  <VStack alignItems={'start'} spacing={0}>
                    <Center
                      fontSize={'14px'}
                      minW={'20px'}
                      bgColor={
                        isToday(day)
                          ? 'zetaBlue'
                          : JSON.stringify(day) === JSON.stringify(selectedDate)
                          ? 'primaryRed'
                          : 'white'
                      }
                      borderRadius={'full'}
                      transition={'0.15s linear'}
                      color={
                        isToday(day)
                          ? 'white'
                          : JSON.stringify(day) === JSON.stringify(selectedDate)
                          ? 'white'
                          : 'initial'
                      }
                      fontWeight={isToday(day) ? 600 : JSON.stringify(day) === JSON.stringify(selectedDate) ? 600 : 400}
                      m={'1px'}
                    >
                      {format(day, 'd')}
                    </Center>
                    {/* 리스트들 */}
                    <VStack w={'full'} fontSize={'12px'} px={1} spacing={0} h={'100px'}>
                      <Box w={'full'} bgColor={'mountainGreen'} color="white" fontWeight={'bold'}>
                        {'01'}건의 입금
                      </Box>
                      <Box w={'full'} fontWeight={'medium'}>
                        {'01'}건의 입금
                      </Box>
                    </VStack>
                  </VStack>
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default Calendar;

import { isEmptyObject, isUndefined } from '@chakra-ui/utils';
import { AxiosRequestConfig } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import {
  company_key,
  company_path,
  contracts_key,
  contracts_path,
  contract_key,
  contract_path,
  employee_counts_key,
  employee_counts_path,
  employee_histories_key,
  employee_services_key,
  employee_services_path,
  employee_usings_key,
  employee_usings_path,
  notifications_list_key,
  notifications_list_path,
  payments_key,
  payments_path,
  verify_user_key,
  verify_user_path,
} from '../../../statics/subscriber/data/KeyPath';
import { useQueryParamsFrame } from '../../admin/hooks/query';
import { fetchClient } from '../../common/utils/axios';
import {
  BaseResponse,
  Basic,
  Company,
  CompanyContract,
  Count,
  CountDetailStatus,
  CountDetailType,
  DetailContract,
  HistoryType,
  Notification,
  Payment,
  QueryResponse,
  Service,
  User,
  Using,
} from '../../common/utils/axios/types';
import { useGetUserPathandKey, useGetUserType } from '../../common/hooks/query';
import {
  placeholderData_count,
  placeholderData_subscription_contract,
  placeholderData_subscription_payments,
} from '../../../statics/subscriber/data/placeholderData';
import * as Util from '../../common/utils/Util';
import { useLocation } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../common/utils/firebase/init';

/* ---------------- */
/* ---- Query ----- */
/* ---------------- */

/* Basic Type */

export const useGetCompanyIsApproved = () => {
  const [user, loading, error] = useAuthState(auth);
  const { data: ud, isLoading } = useGetUserPathandKey();
  const company_type = ud?.company_type;
  const user_type = ud?.user_type;

  return useQuery([company_key, 'check-status'], async () => {
    const token = await user?.getIdToken();
    const instance = await fetchClient({ headers: { token } });
    const value = await instance.get<BaseResponse<Company>>(`${company_type}/${user_type}/company`);
    return company_type === 'admin' ? true : value?.data?.data?.is_approved;
  }).data;
};

export const useGetCompanyInfomation = () => {
  const [user, loading, error] = useAuthState(auth);
  const { data: ud } = useGetUserPathandKey();

  return useQuery([company_key], async () => {
    const token = await user?.getIdToken();
    const instance = await fetchClient({ headers: { token } });
    const value = await instance.get<BaseResponse<Company>>(`${ud?.path}/company`);
    return value?.data?.data;
  });
};

//특정 이용 신청 내역
export const useGetFormRequest = (service_id: string, basic?: Basic<any>) => {
  const [user, loading, error] = useAuthState(auth);
  const isApproved = useGetCompanyIsApproved();
  const { data: ud } = useGetUserPathandKey();

  return useQuery(
    [employee_services_key, service_id],
    async () => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      const value = await instance.get<any>(`${ud?.path}/services/${service_id}`);
      return value?.data?.data;
    },
    {
      ...basic?.options,
      enabled:
        basic?.options?.enabled === undefined ? !!ud && !!isApproved : basic?.options?.enabled && !!ud && !!isApproved,
      keepPreviousData: false,
    },
    // { enabled: !!isApproved },
  );
};

//한 회사 모든 계약서 내역
export const useGetAllCompanyContracts = ({ axiosConfig, options }: Basic<CompanyContract[]>) => {
  const [user, loading, error] = useAuthState(auth);
  const isApproved = useGetCompanyIsApproved();
  return useQuery(
    [contracts_key, axiosConfig?.params],
    async () => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      const value = await instance.get<BaseResponse<CompanyContract[]>>(contracts_path, {
        params: axiosConfig?.params,
      });
      return value?.data?.data;
    },

    {
      enabled: !!isApproved,
      ...options,
    },
  );
};

export const useGetAllFormRequests = (basic?: Basic<QueryResponse<Service[]>>) => {
  return useQueryParamsFrame<Service[]>(
    [employee_services_key, { ...basic?.axiosConfig?.params }],
    employee_services_path,
    basic,
  );
};

//특정 사용자 모든 내역
export const useGetUserAllHistories = (user_id: string, basic?: Basic<HistoryType[]>) => {
  const [user, loading, error] = useAuthState(auth);
  const isApproved = useGetCompanyIsApproved();
  const { data: ud } = useGetUserPathandKey();
  return useQuery(
    [employee_histories_key, user_id],
    async () => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      const value = await instance.get<BaseResponse<HistoryType[]>>(`${ud?.path}/employees/${user_id}/histories`);
      return value?.data?.data;
    },
    {
      enabled: basic?.options?.enabled === undefined ? !!isApproved : basic?.options?.enabled && !!isApproved,
      ...basic?.options,
      keepPreviousData: false,
    },
  );
};
// - 1. 특정 사용자 모든 이용 내역
export const parseRequestHistories = (dataArr: HistoryType[]) => {
  return dataArr.filter((data) => data.type === 'request_history');
};
// - 2. 특정 사용자 모든 계약 내역
export const parseContractHistories = (dataArr: HistoryType[]) => {
  return dataArr.filter((data) => data.type === 'contract_history');
};

//모든 고객사 알림
export const useGetAllNotifications = (basic?: Basic<Notification[]>) => {
  const [user, loading, error] = useAuthState(auth);
  const { data: ud } = useGetUserPathandKey();

  return useQuery(
    [notifications_list_key],
    async () => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      const value = await instance.get<BaseResponse<Notification[]>>(`${ud?.path}/${notifications_list_path}`);
      return value?.data?.data;
    },
    basic?.options,
  );
};
// - 1. 알림 타입별로 파싱
export const parseNotifications = (dataArr: Notification[] | undefined) => {
  if (isUndefined(dataArr)) {
    return undefined;
  }
  const data_collection = [] as any;
  const noti_type_collection = [] as any;
  dataArr.forEach((notice) => {
    for (const [key, value] of Object.entries(notice)) {
      if (key === 'notification_type') {
        if (!noti_type_collection.includes(value)) {
          noti_type_collection.push(value);
          data_collection.push(dataArr.filter((data) => (data as any).notification_type === value));
        }
      }
    }
  });
  return data_collection;
};

// 한 회사의 모든 임차인의 모든 상태 카운트
export const useGetAllCounts = (basic?: Basic<Count>) => {
  const [user, loading, error] = useAuthState(auth);
  const isApproved = useGetCompanyIsApproved();
  const { data: ud } = useGetUserPathandKey();

  return useQuery(
    [employee_counts_key],
    async () => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      return await (
        await instance.get<BaseResponse<Count>>(`${ud?.path}/employees/count`)
      ).data.data;
    },
    {
      enabled:
        basic?.options?.enabled === undefined ? !!ud && !!isApproved : basic?.options?.enabled && !!ud && !!isApproved,
      placeholderData: placeholderData_count,
      ...basic?.options,
    },
  );
};

//한 회사 현재 계약서 내역
export const useGetCompanyContract = (basic?: Basic<DetailContract>) => {
  const [user, loading, error] = useAuthState(auth);
  const isApproved = useGetCompanyIsApproved();
  const { data: ud } = useGetUserPathandKey();

  return useQuery(
    [contract_key],
    async () => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      return await instance.get<BaseResponse<DetailContract>>(`${ud?.path}/subscription-contract`).then((value) => {
        console.log(value);

        return value.data.data;
      });
    },
    {
      enabled:
        basic?.options?.enabled === undefined ? !!ud && !!isApproved : basic?.options?.enabled && !!ud && !!isApproved,
      placeholderData: placeholderData_subscription_contract,
      ...basic?.options,
    },
  );
};

//한 회사 현재 계약서의 모든 결제 내역들
export const useGetAllCompanyContractPayments = (basic?: Basic<Payment[]>) => {
  const [user, loading, error] = useAuthState(auth);
  const isApproved = useGetCompanyIsApproved();
  const { data: ud } = useGetUserPathandKey();

  return useQuery(
    [payments_key],
    async () => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      const value = await instance.get<BaseResponse<Payment[]>>(`${ud?.path}/subscription-payments`);
      return value?.data?.data;
    },
    {
      enabled:
        basic?.options?.enabled === undefined ? !!ud && !!isApproved : basic?.options?.enabled && !!ud && !!isApproved,
      placeholderData: placeholderData_subscription_payments,
      ...basic?.options,
    },
  );
};

export const useGetAllUsings = (basic?: Basic<QueryResponse<Using[]>>) => {
  return useQueryParamsFrame<Using[]>(
    [employee_usings_key, { ...basic?.axiosConfig?.params }],
    employee_usings_path,
    basic,
  );
};

export const parseCounts = (dataArr: CountDetailType<CountDetailStatus>, status: CountDetailStatus) => {
  return dataArr.filter((data) => data._id.key === status)[0];
};

import React from 'react';
import { Button, Box, Flex, Center } from '@chakra-ui/react';
import * as S from '../../../theme/common/styles';

interface Props {
  url?: string;
  content?: string;
}

const UrlButton: React.FC<Props> = ({ url, content }) => {
  return (
    <Center w={'120px'} justifyContent={'flex-start'} placeItems={'center'} margin={'12px'}>
      <a href={url}>
        <Button sx={{ ...S.basicButton }}>{content}</Button>
      </a>
    </Center>
  );
};

export default UrlButton;

import { Box, Flex, HStack, Table, Tbody, Td, Text, Tr } from '@chakra-ui/react';
import { useQuery } from 'react-query';
import { building_partialPath, rooms_id_path } from '../../../statics/agent/data/KeyPath';
import { fetchClient } from '../../../lib/common/utils/axios';
import { BaseResponse, BuildingDetail, RoomDetailInfo } from '../../../lib/common/utils/axios/types';
import { booleanToKoString } from '../../../lib/common/utils/base';
import { building_form, building_type } from '../../../statics/common/data/selectbox';
import { datedotformat } from '../../../lib/common/utils/dateformatter';
import HalfTable from '../../../components/common/Table/HalfTable';
import QuarterTable from '../../../components/common/Table/QuaterTable';
import { security_func } from '../../../lib/common/utils/roomInfoUtil';
import { TdFullWContainer } from '../../../components/common/Table/Tr';
import { staticThreeCommafy } from '../../../lib/common/utils/digitformatter';
import { useLocation } from 'react-router-dom';
import * as Util from '../../../lib/common/utils/Util';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../../lib/common/utils/firebase/init';
import Loading from '../../../components/common/Loading';

interface BuildingId {
  id: string | undefined;
  building?: any;
  room?: any;
}
const BuildingInfo = (props: BuildingId) => {
  // const [user, loading, error] = useAuthState(auth);
  // const location = useLocation();
  // const routeUserType = Util.getRouteUserTypeFromUrl(location.pathname);
  const building = props?.building;
  // const room = props?.room;
  // const { data, isLoading } = useQuery([building_partialPath, props.id], async () => {
  //   const token = await user?.getIdToken();
  //   const instance = await fetchClient({ headers: { token } });
  //   const res = await instance.get<BaseResponse<BuildingDetail>>(`/${routeUserType}/master/buildings/${props.id}`);
  //   return res.data.data;
  // });

  // if (isLoading) return <Loading />;

  return (
    <>
      <Box py={5}>
        <Text fontWeight={700} pb={3}>
          건물 정보
        </Text>
        <Table>
          <Tbody>
            <Tr w={'100%'} borderTop="1px">
              <TdFullWContainer title={'건물 유형'} ContentTdProps={{ padding: '16px' }}>
                <Flex w="100%">
                  {building_type[building_type.findIndex((el) => el.value == building?.building_type)].label}
                </Flex>
              </TdFullWContainer>
              <TdFullWContainer title={'건물 형태'} ContentTdProps={{ padding: '16px' }}>
                <Flex w="100%">
                  {building_form[building_form.findIndex((el) => el.value == building?.building_form)].label}
                </Flex>
              </TdFullWContainer>
            </Tr>
            <Tr w={'100%'}>
              <TdFullWContainer title={'건물명'} ContentTdProps={{ padding: '16px' }}>
                <Flex w="100%">{building?.name}</Flex>
              </TdFullWContainer>
              <TdFullWContainer title={'준공일'} ContentTdProps={{ padding: '16px' }}>
                <Flex w="100%">{datedotformat(building?.completion_date)}</Flex>
              </TdFullWContainer>
            </Tr>
            <Tr w={'100%'}>
              <TdFullWContainer title={'전체 층수 및 세대수'} ContentTdProps={{ colSpan: 4, padding: '16px' }}>
                <Flex w="100%">{`${building?.floor}층 ${
                  building?.household_number ? '/ ' + building?.household_number + '세대' : ''
                }`}</Flex>
              </TdFullWContainer>
            </Tr>
          </Tbody>
        </Table>
      </Box>
      <Box py={5}>
        <Text fontWeight={700} pb={3}>
          건물 시설 정보
        </Text>
        <Table>
          <Tbody>
            <Tr w={'100%'} borderTop="1px">
              <TdFullWContainer title={'현관 유형'} ContentTdProps={{ colSpan: 4, padding: '16px' }}>
                <Flex w="100%">
                  {building_type[building_type.findIndex((el) => el.value == building?.building_type)].label}
                </Flex>
              </TdFullWContainer>
            </Tr>
            <Tr w={'100%'}>
              <TdFullWContainer title={'엘레베이터'} ContentTdProps={{ colSpan: 4, padding: '16px' }}>
                <Flex w="100%">{booleanToKoString(building?.is_elevator)}</Flex>
              </TdFullWContainer>
            </Tr>
            <Tr w={'100%'}>
              <TdFullWContainer title={'보안 시설'} ContentTdProps={{ colSpan: 4, padding: '16px' }}>
                <Flex w="100%">{security_func(building?.security_facilities)}</Flex>
              </TdFullWContainer>
            </Tr>
            <Tr w={'100%'}>
              <TdFullWContainer title={'주차 시설'} ContentTdProps={{ padding: '16px' }}>
                <Flex w="100%">{booleanToKoString(building?.is_parking)}</Flex>
              </TdFullWContainer>
              <TdFullWContainer title={'주차 비용'}>
                <Flex w="100%"> {staticThreeCommafy(building?.parking_cost) + '원'}</Flex>
              </TdFullWContainer>
            </Tr>
          </Tbody>
        </Table>
      </Box>
    </>
  );
};

export default BuildingInfo;

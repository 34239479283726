import {
  HStack,
  Menu,
  Tooltip,
  Flex,
  Box,
  MenuButton,
  Avatar,
  Stack,
  MenuList,
  MenuItem,
  Text,
} from '@chakra-ui/react';
import {
  Link as RouterLink,
  matchPath,
  NavLink,
  NavLinkProps,
  useLocation,
  useMatch,
  useNavigate,
} from 'react-router-dom';
import { AlertStore } from '../../../contexts/common/AlertContext';
import { useGetUserType } from '../../../lib/common/hooks/query';
import { datedotkoformat, datedetaildotkoformat } from '../../../lib/common/utils/dateformatter';
import { auth, logout, useLogout } from '../../../lib/common/utils/firebase/init';
import { getLocalStorageStringItem } from '../../../lib/common/utils/localstorage';
import user from '../../../pages/UserType/admin/manage/customers/user';
import { IconCircleContiner, SettingFocusIcon, SettingIcon } from '../../../statics/common/icons';
import OpenAlertButton from '../../common/Button/OpenAlertButton';

interface CompanyMenuProps {
  isSettingPage?: boolean;
}

const CompanyMenu: React.FC<CompanyMenuProps> = ({ isSettingPage }) => {
  const user = auth.currentUser;
  const email = getLocalStorageStringItem('--bohome-email');
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { data, isLoading } = useGetUserType();
  const _logout = useLogout();
  if (data === undefined || isLoading) {
    console.log('loading...');
    return null;
  }

  const { company_type: userType } = data;

  const userTransType = {
    admin: '보홈관리자',
    agency: '중개사',
    subscriber: '고객사',
  }[userType];
  return (
    <HStack
      spacing={14}
      bgColor={{ base: 'unset', md: 'white' }}
      padding={{ base: 'unset', md: '8px 13px' }}
      borderRadius={{ base: 'unset', md: '10px' }}
      boxShadow={{ base: 'unset', md: 'rgba(100, 100, 111, 0.05) 0px 7px 29px 0px' }}
      // mb={7}
    >
      <Menu isLazy autoSelect={false}>
        <Tooltip
          label={
            <Flex flexDir={'column'}>
              <Flex>
                <Text>계정 생성일:&nbsp;</Text>
                <Text>{datedotkoformat(user?.metadata.creationTime)}</Text>
              </Flex>
              <Flex>
                <Text>최근 접속시각:&nbsp;</Text>
                <Text>{datedetaildotkoformat(user?.metadata.lastSignInTime)}</Text>
              </Flex>
              <Box></Box>
            </Flex>
          }
          hasArrow
        >
          <Box>
            <MenuButton transition={'0.2s'} _hover={{ borderBottom: { base: '0.5px solid #BAC2D6', md: 'none' } }}>
              <HStack>
                {/* <Avatar size={'sm'} /> */}
                <Avatar size={'sm'} name={email || ''} bgColor={'gray.300'} />
                <Stack direction={{ base: 'column', md: 'row' }} spacing={{ base: 0, md: 2 }}>
                  <Text fontSize={'14px'} boxSize={'max-content'}>
                    {email}
                  </Text>
                  <Text fontSize={'14px'} boxSize={'max-content'} textColor={'midGray'}>
                    {userTransType}
                  </Text>
                </Stack>
              </HStack>
            </MenuButton>
          </Box>
        </Tooltip>
        <MenuList zIndex={100} bgColor={'zetaBlue'} color={'white'}>
          <MenuItem _focus={{ opacity: '0.7' }}>
            <RouterLink to="/company/manage/dashboard">대시보드</RouterLink>
          </MenuItem>
          <MenuItem _focus={{ opacity: '0.7' }}>
            <RouterLink to="/company/infos">내 정보 수정</RouterLink>
          </MenuItem>
          <MenuItem _focus={{ opacity: '0.7' }} onClick={_logout}>
            로그아웃
          </MenuItem>
        </MenuList>
      </Menu>
      <HStack display={{ base: 'none', md: 'flex' }} ml={{ base: 'unset', md: 2 }}>
        {/* <IconCircleContiner
                onClick={() => {
                  navigate('/company/manage/infos');
                }}
              >
                {matchPath('/company/manage/infos/*', pathname) ? <SettingFocusIcon /> : <SettingIcon />}
              </IconCircleContiner> */}

        {matchPath('/company/infos/*', pathname) ? (
          <IconCircleContiner
            onClick={() => {
              navigate('/company/infos');
            }}
            bgColor={'zetaBlue'}
          >
            <SettingFocusIcon />
          </IconCircleContiner>
        ) : (
          <IconCircleContiner
            onClick={() => {
              navigate('/company/infos');
            }}
          >
            <SettingIcon />
          </IconCircleContiner>
        )}
        <AlertStore>
          <OpenAlertButton />
        </AlertStore>
        {/* <IconCircleContiner>
                <ChatIcon />
              </IconCircleContiner> */}
        {/* </HStack> */}
      </HStack>
    </HStack>
  );
};

export default CompanyMenu;

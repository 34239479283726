import { Table, Thead, Tr, Tbody, HStack, Avatar, Box, Button } from '@chakra-ui/react';
import { WTd } from '../../../../../../../components/common/Table/Td';
import { WTh } from '../../../../../../../components/common/Table/Th';

const Manager = () => {
  return (
    <Table variant="simple">
      <Thead bgColor={'#F8F8F8'} borderColor={'gray.100'}>
        <Tr>
          <WTh w={'75%'} borderR>
            Manager
          </WTh>
          <WTh w={'25%'}>관리</WTh>
        </Tr>
      </Thead>
      <Tbody>
        <Tr>
          <WTd borderR>
            <HStack justifyContent={'center'}>
              <Avatar />
              <Box>신한 110-***-0000</Box>
            </HStack>
          </WTd>
          <WTd>
            <HStack justifyContent={'center'}>
              <Button variant={'basic'}>계좌 변경</Button>
              <Button variant={'basic'}>삭제</Button>
            </HStack>
          </WTd>
        </Tr>
      </Tbody>
    </Table>
  );
};

export default Manager;

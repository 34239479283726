import {
  Button,
  ButtonGroup,
  Center,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { FormikProps } from 'formik';

interface Props {
  isDisabledCompleteEdit: boolean;
  props: FormikProps<any>;
  isEditable: boolean;
  setIsEditable: (value: React.SetStateAction<boolean>) => void;
  title: string;
}

const FormikEditableWithReCheckButton: React.FC<Props> = ({
  isDisabledCompleteEdit,
  props,
  isEditable,
  setIsEditable,
  title,
}) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  return isEditable ? (
    <ButtonGroup variant="outline">
      <Button
        variant={'gray'}
        _hover={{ bgColor: 'red', borderColor: 'red' }}
        onClick={() => {
          props.handleReset();
          setIsEditable(false);
        }}
        autoFocus
        w={'90px'}
        isDisabled={props.isSubmitting}
      >
        수정 취소
      </Button>
      <>
        <Button
          variant={'basic'}
          isLoading={props.isSubmitting}
          isDisabled={!props.isValid || isDisabledCompleteEdit}
          w={'90px'}
          onClick={() => {
            onOpen();
          }}
        >
          수정 완료
        </Button>

        <Modal isOpen={isOpen} onClose={onClose} isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <Center flexDir={'column'} m={10}>
              {/* <Text fontSize={'18px'}>000님의 집 정보를 수정하시겠습니까?</Text> */}
              <Text fontSize={'18px'}>{title}</Text>
              <Center w="full" mt={'30px'}>
                <HStack>
                  <Button
                    variant={'warning'}
                    w={'140px'}
                    fontSize={'14px'}
                    fontWeight={'bold'}
                    onClick={() => {
                      onClose();
                    }}
                  >
                    취소
                  </Button>
                  <Button
                    variant={'basic'}
                    w={'140px'}
                    fontSize={'14px'}
                    fontWeight={'bold'}
                    type={'submit'}
                    onClick={() => {
                      props.handleSubmit();
                    }}
                  >
                    수정하기
                  </Button>
                </HStack>
              </Center>
            </Center>
          </ModalContent>
        </Modal>
      </>
    </ButtonGroup>
  ) : (
    <Button variant={'warning'} w={'90px'} onClick={() => setIsEditable((prev) => !prev)}>
      수정
    </Button>
  );
};

export default FormikEditableWithReCheckButton;

import { Box, Button, Input, VStack } from '@chakra-ui/react';
import { FastField, Field, Form, Formik, useFormikContext } from 'formik';
// import Quill from 'quill';
import React, { useRef, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';

const Editor = () => {
  const Size = Quill.import('attributors/style/size');
  Size.whitelist = ['12px', '14px', '16px', '18px', '20px'];
  Quill.register(Size, true);

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ size: ['12px', '14px', '16px', '18px', '20px'] }],
      [{ font: [] }],
      [{ align: ['right', 'center', 'justify'] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link', 'image'],
      [{ color: ['red', '#785412'] }],
      [{ background: ['red', '#785412'] }],
    ],
  };

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'link',
    'color',
    'image',
    'background',
    'align',
    'size',
    'font',
  ];

  const [code, setCode] = useState('hellllo');
  const handleProcedureContentChange = (
    content: string,
    delta: any,
    source: any,
    editor: ReactQuill.UnprivilegedEditor,
  ) => {
    setCode(content);
  };

  return (
    <>
      <ReactQuill
        theme="snow"
        modules={modules}
        formats={formats}
        value={code}
        onChange={handleProcedureContentChange}
      />
    </>
  );
};

const PlaygroundThree = () => {
  return <Editor />;
};

export default PlaygroundThree;

import { Box, Button, Center, HStack, Text, VStack } from '@chakra-ui/react';
import { isEmptyObject, isUndefined } from '@chakra-ui/utils';
import { PathProps } from '.';
import { GBox, GNoBBox } from '../../../../../components/common/Box';
import { GItem } from '../../../../../components/common/GridItem';
import Tab from '../../../../../components/common/Tab';
import {
  parseCounts,
  useGetAllCompanyContractPayments,
  useGetAllCounts,
  useGetCompanyContract,
  useGetCompanyInfomation,
} from '../../../../../lib/subscriber/hooks/query';
import { subscriptionColorTrans, subscriptionTypeTrans } from '../../../../../lib/subscriber/utils/utils';
import { decimalize } from '../../../../../lib/common/utils/digitformatter';
import { PersonCheckIcon, TwoPeopleIcon } from '../../../../../statics/common/icons';

type StatusProps = PathProps;

const Status: React.FC<StatusProps> = () => {
  const { data: countsdata, isPlaceholderData: isCountsPlaceholderData } = useGetAllCounts();
  const { data: contractdata, isPlaceholderData: isContractPlaceholderData } = useGetCompanyContract();
  const { data: paymentsdata, isPlaceholderData: isPaymentPlaceholderData } = useGetAllCompanyContractPayments();
  const { data: cpdata, isPlaceholderData: isCompanyInfoPlaceholderData } = useGetCompanyInfomation();

  if (countsdata === undefined || contractdata === undefined || paymentsdata === undefined) {
    return null;
  }

  const { services_count, usings_count } = countsdata;

  const total_requests_count =
    parseCounts(services_count, 'total') !== undefined ? parseCounts(services_count, 'total').count : 0;
  const total_on_using_employees_count =
    parseCounts(usings_count, 'total') !== undefined ? parseCounts(usings_count, 'total').count : 0;

  const { subscription_type, available_user_count } = contractdata;

  const isNotSubscribing = isContractPlaceholderData || isEmptyObject(contractdata);

  const availableUserCount = isUndefined(available_user_count) ? 0 : available_user_count;

  return (
    // <GItem rowStart={1} rowEnd={8} colStart={1} colEnd={2} minW={0}>
    <GItem w="300px" minW="300px" maxW="300px" h="580px" minH="580px">
      <VStack p={5} spacing={1} w={'full'}>
        <HStack justifyContent={'space-between'} alignItems={'center'} w={'full'}>
          <Box overflow={'hidden'} whiteSpace={'nowrap'} textOverflow={'ellipsis'}>
            <Text fontSize={'22px'} fontWeight={'bold'} display={'inline'}>
              {cpdata?.name}
            </Text>
            <Text fontSize={'18px'} fontWeight={'bold'}>
              의 이용 현황
            </Text>
          </Box>
          {!isNotSubscribing && contractdata.payment_status === 'confirm_expected' && (
            <Button variant={'grayBorder_blackFont'} h={'26px'} px={2} minW={'max'}>
              플랜 변경
            </Button>
          )}
        </HStack>
        <VStack alignItems={'flex-start'} w="full" spacing={1}>
          <Center justifyContent={'start'} fontSize={'20px'} fontWeight={700} mt={2} mb={2}>
            <Box
              w={'15px'}
              h={'45px'}
              borderRadius={'lg'}
              bgColor={isNotSubscribing ? 'gray' : subscriptionColorTrans(subscription_type)}
              mr={4}
            />
            <Text>{isNotSubscribing ? '구독 전' : subscriptionTypeTrans(subscription_type)}</Text>
          </Center>
          <HStack w="full" justifyContent={'flex-start'} fontWeight={'medium'} fontSize={'18px'}>
            <TwoPeopleIcon />
            <Text>
              {total_on_using_employees_count}인 / {availableUserCount}인 이용 중
            </Text>
          </HStack>
          <HStack w="full" justifyContent={'flex-start'} fontWeight={'medium'} fontSize={'18px'}>
            <PersonCheckIcon />
            <Text>잔여 : {availableUserCount - total_on_using_employees_count}인</Text>
          </HStack>
        </VStack>
      </VStack>
      {/* 이용 신청 */}
      <Tab to={'/company/manage/employee'} logo={'💌'} text={`전체 이용 신청 (${decimalize(total_requests_count)})`} />
      <Tab
        to={'/company/manage/employee/onseek'}
        logo={'🏠'}
        text={`전체 이용중인 임직원 (${decimalize(total_on_using_employees_count)})`}
      />
    </GItem>
  );
};

export default Status;

import { Box, ListItem, Text, UnorderedList, VStack } from '@chakra-ui/react';
import { Container } from '../sections/Container';
import { Main } from '../sections/Main';

export const MarketingTerm = () => (
  <Container height="100%">
    <Main>
      <Text fontWeight={'bold'} color="zetaBlue" fontSize={'11px'}>
        마케팅 정보 활용 동의
      </Text>
      <VStack alignItems={'start'}>
        <VStack alignItems={'start'} spacing={3}>
          {/* 제1조 */}
          <VStack alignItems={'start'}>
            <Box fontSize={'11px'}>
              서비스 이용에 필수적인 개인정보를 다음과 같은 목적을 위하여 수집합니다. 처리하는 개인정보는 다음의 목적
              이외의 용도로는 이용되지 않으며, 처리 목적과 항목이 변경되는 경우에는 사전에 이용자의 동의를 받습니다.
            </Box>
            <Box>
              <UnorderedList fontSize={'11px'} pl={2}>
                <ListItem>수집항목 : 이름, 전화번호, 이메일 </ListItem>
                <ListItem>
                  수집 및 이용목적 : 주식회사 리버블의 운영 및 광고성 정보 전송 혹은 서비스 관련 정보 전송
                </ListItem>
                <ListItem>보유 및 이용기간 : 이용약관 및 정보, 광고 활용동의 철회하거나 탈퇴시까지 보유</ListItem>
              </UnorderedList>
            </Box>
          </VStack>
        </VStack>
      </VStack>
    </Main>
  </Container>
);

import {
  Box,
  Flex,
  Skeleton,
  SkeletonText,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';
import _ from 'lodash';
import ContractFileStatusDropzone from '../../../../../../components/admin/Dropzone/ContractFileStatusDropzone';
import QueryParamsLayout from '../../../../../../layouts/common/main/QueryParamsLayout';
import TextLink from '../../../../../../components/common/Link/TextLink';
import { useChangeLeaseContractArrayField } from '../../../../../../lib/admin/hooks/mutation';
import { useGetAllCompaniesTenantsContracts } from '../../../../../../lib/admin/hooks/query';
import {
  globalRequstTrans,
  globalRequstVariantTrans,
  globalStatusTrans,
  paymentStatusTrans,
} from '../../../../../../lib/common/utils/utils';
import { PaymentMethodType, PaymentStatus } from '../../../../../../lib/common/utils/axios/types';
import { bankNameTrans, currentParamsObject, ToAllFilledObj } from '../../../../../../lib/common/utils/base';
import {
  paymentMethodTypeTrans,
  tenanStatusTypeTrans,
  tenanStatusVariantTrans,
} from '../../../../../../lib/subscriber/utils/utils';
import { datedotformat } from '../../../../../../lib/common/utils/dateformatter';
import { decimalize, phoneNumberdashfy, staticThreeCommafy } from '../../../../../../lib/common/utils/digitformatter';
import { DeleteCircle } from '../../../../../../statics/common/icons';
import ReCheckAlertModal from '../../../../../../components/common/Modals/Alert/ReCheckAlert';
import Loading from '../../../../../../components/common/Loading';

// 현재, 계약 관리 -> 입주 중 관리로 라우터 변경
const Main = () => {
  const _params = currentParamsObject();
  const params = ToAllFilledObj(_params);

  const res = useGetAllCompaniesTenantsContracts({
    axiosConfig: {
      params,
    },
  });

  const { mutate: changeLeaseContractArrayField } = useChangeLeaseContractArrayField();

  const { isLoading } = res;
  if (isLoading) return <Loading />;
  return (
    <Flex flexDir={'column'} h="full">
      <Box layerStyle={'gray'} boxShadow={'sm'} px={2} py={1} mb={2}>
        <SkeletonText noOfLines={2} spacing="2" skeletonHeight="5" isLoaded={!isLoading}>
          <Flex textStyle={'medium15'}>
            <Flex flexDir={'column'} mr={4}>
              <Box mb={1}>계약 완료({decimalize(res.data?.tenant_status_count_info?.contracted)})</Box>
              <Box>입주중({decimalize(res.data?.tenant_status_count_info?.moving_in)})</Box>
            </Flex>
            <Flex flexDir={'column'}>
              <Box mb={1}>퇴실요청({decimalize(res.data?.tenant_status_count_info?.moving_out)})</Box>
              <Box>연장요청({decimalize(res.data?.tenant_status_count_info?.extending)})</Box>
            </Flex>
          </Flex>
        </SkeletonText>
      </Box>

      <QueryParamsLayout res={res}>
        <Table variant="head_silverbg_toplining">
          <Thead>
            <Tr>
              {/*  */}
              <Th>계약상태</Th>
              <Th>임차인명</Th>
              <Th>임차인정보</Th>
              <Th>납부방법</Th>
              <Th>계약기간</Th>
              <Th>연장/퇴실 요청</Th>
              <Th>계약금/잔금/보증금/관리비</Th>
              <Th>계약서</Th>
              <Th>임대인</Th>
              <Th>매물</Th>
              <Th>입주정보</Th>
            </Tr>
          </Thead>
          <Tbody h="200px" maxH="200px" overflow={'scroll'}>
            {res.data?.result.map((value, index) => {
              const {
                employee: {
                  _id: employee_id,
                  email,
                  phone_number_first,
                  tenant_status,
                  user_name,
                  company_name,
                  company_id,
                  lease_contract: {
                    contract_options: { is_parking, is_pet },
                    account,
                    lease_contract_pdfs,
                    real_room_id,
                    down_payment, // 계약금
                    balance_payment, // 잔금
                    deposit, // 보증금
                    management_fee, // 관리비
                    move,
                    host_id,
                    payment_method_type,
                    payment_status,
                    payment_day,
                    _id: lease_contract_id,
                  },
                },
              } = value;
              const bank_code = account?.bank_code;
              const account_number = account?.account_number;
              const account_owner_name = account?.account_owner_name;

              return (
                <Tr key={index}>
                  <Td>
                    <Tag variant={tenanStatusVariantTrans(tenant_status)}>
                      <Text textStyle={'bold11'}>{tenanStatusTypeTrans(tenant_status)}</Text>
                    </Tag>
                  </Td>
                  <Td>
                    <Flex flexDir={'column'} align={'start'}>
                      <TextLink to={`/admin/manage/customers/user/detail/info?id=${employee_id}`}>{user_name}</TextLink>
                      <TextLink to={`/admin/manage/customers/company/detail/info?id=${company_id}`}>
                        ({company_name})
                      </TextLink>
                    </Flex>
                  </Td>
                  <Td>
                    <VStack align={'start'}>
                      <Box>
                        <Text display={'inline'} fontWeight={'medium'}>
                          이메일:&nbsp;
                        </Text>
                        {email}
                      </Box>
                      <Box>
                        <Text display={'inline'} fontWeight={'medium'}>
                          휴대폰번호:&nbsp;
                        </Text>
                        {phoneNumberdashfy(phone_number_first)}
                      </Box>
                      <Flex id="ef">
                        <Text display={'inline'} fontWeight={'medium'}>
                          계좌정보:&nbsp;
                        </Text>
                        <Flex flexDir={'column'}>
                          <Text minW={'max'}>{bankNameTrans(bank_code) + ' ' + account_owner_name}</Text>
                          <Text>{'(' + account_number + ')'}</Text>
                        </Flex>
                      </Flex>
                    </VStack>
                  </Td>
                  <Td>
                    <VStack align={'start'}>
                      <Box>
                        <Text display={'inline'} fontWeight={'medium'}>
                          납부방법:&nbsp;
                        </Text>
                        {paymentMethodTypeTrans(payment_method_type as PaymentMethodType)}
                      </Box>
                      <Flex>
                        <Text fontWeight={'medium'}>납부날짜:&nbsp;</Text>
                        <Text minW={'max'}>매월 {payment_day}일</Text>
                      </Flex>
                      <Box>
                        <Text display={'inline'} fontWeight={'medium'}>
                          납부상태:&nbsp;
                        </Text>
                        {paymentStatusTrans(payment_status as PaymentStatus)}
                      </Box>
                    </VStack>
                  </Td>
                  <Td>
                    <Text>{datedotformat(value.employee.lease_contract.contract_start_date)}</Text>
                    <Text>-</Text>
                    <Text>{datedotformat(value.employee.lease_contract.contract_end_date)}</Text>
                  </Td>
                  <Td>
                    <Tag variant={globalRequstVariantTrans(value.employee.lease_contract.request_type)}>
                      {globalRequstTrans(value.employee.lease_contract.request_type) +
                        globalStatusTrans(value.employee.lease_contract.move.status)}
                    </Tag>
                  </Td>
                  <Td>
                    <VStack align={'start'}>
                      <Box>
                        <Text display={'inline'} fontWeight={'medium'}>
                          계약금:&nbsp;
                        </Text>
                        {staticThreeCommafy(down_payment)}원
                      </Box>
                      <Box>
                        <Text display={'inline'} fontWeight={'medium'}>
                          잔금:&nbsp;
                        </Text>
                        {staticThreeCommafy(balance_payment)}원
                      </Box>
                      <Box>
                        <Text display={'inline'} fontWeight={'medium'}>
                          보증금:&nbsp;
                        </Text>
                        {staticThreeCommafy(deposit)}원
                      </Box>
                      <Box>
                        <Text display={'inline'} fontWeight={'medium'}>
                          관리비:&nbsp;
                        </Text>
                        {staticThreeCommafy(management_fee)}원
                      </Box>
                    </VStack>
                  </Td>
                  <Td>
                    <VStack>
                      <ContractFileStatusDropzone
                        lease_contract_id={lease_contract_id}
                        contractType="lease"
                        formDataKey="files"
                      />
                      {lease_contract_pdfs.map((url, index) => (
                        <Flex justifyContent={'center'} key={index}>
                          <Box pos={'relative'}>
                            <TextLink href={url} target={'_blank'}>
                              {`계약서 ${index + 1}`}
                            </TextLink>
                            <Box layerStyle={'abs_tr'} zIndex={0}>
                              <ReCheckAlertModal
                                content={`계약서를 삭제하시겠습니까?`}
                                callback={() => {
                                  const formData = new FormData();
                                  formData.append('values[]', url);
                                  changeLeaseContractArrayField({
                                    lease_contract_id,
                                    field: 'lease_contract_pdf_urls',
                                    operation: 'delete',
                                    formData,
                                  });
                                }}
                              >
                                <DeleteCircle boxSize={4} layerStyle={'pointer'} />
                              </ReCheckAlertModal>
                            </Box>
                          </Box>
                        </Flex>
                      ))}
                      {/* )} */}
                    </VStack>
                  </Td>
                  <Td>
                    <TextLink href={`/admin/manage/customers/host/detail/info?id=${host_id}`} target={'_blank'}>
                      상세 정보
                    </TextLink>
                  </Td>
                  <Td>
                    <TextLink to={`/admin/manage/roomInfo/${real_room_id}`} isExternal>
                      상세 정보
                    </TextLink>
                  </Td>
                  <Td>
                    <TextLink to={`/admin/manage/customers/user/detail/lmove?id=${employee_id}`} isExternal>
                      상세 정보
                    </TextLink>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </QueryParamsLayout>
    </Flex>
  );
};

export default Main;

import { AnimatePresence } from 'framer-motion';
import { Suspense, useEffect, useMemo } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Mainlayout from './layouts/common/main/MainLayout';
import Loading from './components/common/Loading';
import RootPageNavigateRouter from './pages/common/Main';
import NoAccessPage from './pages/common/NoAccess';
import NotFoundPage from './pages/common/NotFound';
import { MarketingTerm, PersonInfoTerm, ServiceTerm } from './pages/common/SignUp/Company/sections/sections/terms';
import AuthProtectedRoute from './routes/common/AuthProtectedRoute';
import AdminCheckRoute from './routes/admin/AdminCheckRoute';
import AgencyCheckRoute from './routes/agent/AgencyCheckRoute';
import CompanyCheckRoute from './routes/subscriber/CompanyCheckRoute';
import AuthPublicRoute from './routes/common/AuthPublicRoute';
import LoginRoute from './routes/common/LoginRoute';
import AdminLoginRoute from './routes/admin/LoginRoute/AdminLogin';
import AgencyLoginRoute from './routes/agent/LoginRoute/AgencyLogin';
import CompanyLoginRoute from './routes/subscriber/LoginRoute/CompanyLogin';
import AgencySignUpRoute from './routes/agent/SignUpRoute/AgencySignUp';
import CompanySignUpRoute from './routes/subscriber/SignUpRoute/CompanySignUp';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, useLogout } from './lib/common/utils/firebase/init';
import { useNavigate } from 'react-router-dom';
import * as Util from './lib/common/utils/Util';
import * as Validator from './lib/common/utils/Validator';
import { removeItemListOnLogout, shouldCachedItemList } from './statics/common/data/localstorage';
import { getLocalStorageStringItem, removeLocalStorageItems } from './lib/common/utils/localstorage';
import { signOut } from 'firebase/auth';
import ServerNotMatched from './pages/common/ServerNotMatched';

export type planStatusType = typeof planStatus;

export const planStatus:
  | 'appliedButWait'
  | 'approvedButNotApply'
  | 'approvedAndWaitSign'
  | 'approvedAndWaitPayment'
  | 'approved'
  | 'rejected' = 'appliedButWait';

const App = () => {
  const [user, loading, error] = useAuthState(auth);
  const location = useLocation();
  if (loading) return <>Firebase</>;
  return (
    <>
      <AnimatePresence key={location.pathname} exitBeforeEnter initial={true}>
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<RootPageNavigateRouter />} />
          <Route element={<Mainlayout />}>
            <Route path="admin/*" element={<AdminCheckRoute />} />
            <Route path="company/*" element={<CompanyCheckRoute />} />
            <Route path="agency/*" element={<AgencyCheckRoute />} />
          </Route>

          <Route path="login">
            {/* <Route element={<LoginRoute />}></Route> */}
            <Route path="admin/*" element={<AdminLoginRoute />} />
            <Route path="company/*" element={<CompanyLoginRoute />} />
            <Route path="agency/*" element={<AgencyLoginRoute />} />
          </Route>

          <Route path="signup">
            <Route path="agency/*" element={<AgencySignUpRoute />} />
            <Route path="company/*" element={<CompanySignUpRoute />} />
          </Route>

          <Route path="termandcondition">
            <Route path="company">
              <Route path="personinfoterm/*" element={<PersonInfoTerm />} />
              <Route path="marketingterm/*" element={<MarketingTerm />} />
              <Route path="serviceterm/*" element={<ServiceTerm />} />
            </Route>
          </Route>
          <Route path="/noaccess" element={<NoAccessPage />} />
          <Route path="/noserver" element={<ServerNotMatched />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </AnimatePresence>
    </>
  );
};

export default App;

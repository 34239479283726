import { Box, Center, Container, Image, Text } from '@chakra-ui/react';
import React from 'react';
import { Navigate, RouteProps, useNavigate } from 'react-router-dom';
import { useLogout } from '../../../lib/common/utils/firebase/init';
import { RightArrowIcon } from '../../../statics/common/icons';
import logo_blue from '../../../statics/common/images/logo-blue.png';

const NoAccessPage: React.FC<RouteProps> = () => {
  const navigate = useNavigate();
  const _logout = useLogout();
  return (
    <Container h="100vh" minH="100vh">
      <Center h="100%" flexDir={'column'}>
        <Image src={logo_blue} mb={8} />
        <Box>
          <Text fontWeight={500} fontSize={'22px'}>
            접근이 금지된 페이지입니다!
          </Text>
        </Box>
        <Center fontSize={'20px'} mt={4} onClick={_logout} cursor={'pointer'} _hover={{ opacity: 0.7 }}>
          <Text>로그아웃하기</Text>
          <RightArrowIcon />
        </Center>
      </Center>
    </Container>
  );
};

export default NoAccessPage;

import axios from 'axios';
import { AnalyticsCallOptions, getAnalytics, logEvent, setUserId } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import {
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  getAuth,
  reauthenticateWithCredential,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  updatePassword,
} from 'firebase/auth';
import {
  addDoc,
  collection,
  doc,
  getFirestore,
  onSnapshot,
  orderBy,
  query,
  QueryConstraint,
  serverTimestamp,
  updateDoc,
} from 'firebase/firestore';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { queryClient } from '../../../..';
import { getLocalStorageStringItem, removeLocalStorageItems } from '../localstorage';
import { removeItemListOnLogout } from '../../../../statics/common/data/localstorage';
import { Navigate, useNavigate } from 'react-router-dom';
import { QueryCache } from 'react-query';
import React from 'react';

// https://www.freecodecamp.org/news/react-firebase-authentication-and-crud-operations/
// https://blog.logrocket.com/user-authentication-firebase-react-apps/

export const firebaseConfig = {
  // eslint-disable-next-line no-undef
  apiKey: process.env.REACT_APP_API_KEY,
  // eslint-disable-next-line no-undef
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  // eslint-disable-next-line no-undef
  projectId: process.env.REACT_APP_PROJECT_ID,
  // eslint-disable-next-line no-undef
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  // eslint-disable-next-line no-undef
  messagingSenderId: process.env.REACT_APP_MESSAGIN_ID,
  // eslint-disable-next-line no-undef
  appId: process.env.REACT_APP_APP_ID,
  // eslint-disable-next-line no-undef
  measurementId: process.env.REACT_APP_MEASUREMENTID,
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const firestore = getFirestore(app);

export const messaging = getMessaging(app);

export const analytics = getAnalytics(app);

export const pageViewEvent_GA = (title: string, options?: AnalyticsCallOptions) => {
  return logEvent(
    analytics,
    'page_view',
    {
      page_path: location.pathname,
      page_title: title,
    },
    options,
  );
};

export const setUserId_GA = (userId: string) => {
  return setUserId(analytics, userId);
};

export const getFCMToken = async (setTokenFound: any) => {
  return await getToken(messaging, {
    vapidKey: 'BEatN0NcWA6UdKr8u-teIepcJl4kNlED-shMnqWcZApuycPQ_vYfo7Ltpq1sj1_m_DlmDgmcJJJlTp_JCU4RIO0',
  })
    .then((currentToken) => {
      if (currentToken) {
        console.log('current token for client: ', currentToken);
        setTokenFound(true);
        return currentToken;
      } else {
        console.log('No registration token available. Request permission to generate one.');
        setTokenFound(false);
        return false;
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
};

export const onForeGroundMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

export const onBackGroundMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
// new Promise((resolve) => {
//   onBackgroundMessage(messaging, (payload) => {
//     resolve(payload);
//   });
// });

export const createUser = (email: string, password: string) => {
  //   const navigate = useNavigate();
  createUserWithEmailAndPassword(auth, email, password)
    .then(async (userCredential) => {
      // Signed in
      const user = userCredential.user;
      console.log('userCredential => ', userCredential);
      await axios
        .post(
          'http://3.39.5.188:3080/api/public/verify_token',
          {
            accessToken: (userCredential.user as any).accessToken,
            refreshToken: userCredential.user.refreshToken,
            provider: userCredential.user.providerId,
          },
          { withCredentials: true },
        )
        .then((res) => {
          console.log('res => ', res);
        })
        .catch((error) => {
          console.log('error1 => ', error);
        });
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log('error2 => ', error);
    });
};

export const signIn = (email: string, password: string) => {
  return signInWithEmailAndPassword(auth, email, password);
};

export const FirebaseUpdatePassword = async (newPassword: string) => {
  try {
    if (auth.currentUser) {
      await updatePassword(auth.currentUser, newPassword);
    }
  } catch (err: any) {
    console.error(err);
  }
};

export const FirebaseReauthenticateWithCredential = async (providedPassword: string) => {
  const { currentUser } = auth;
  // try {
  //   if (currentUser) {
  //     const credential = EmailAuthProvider.credential(currentUser.email || '', providedPassword);
  //     await reauthenticateWithCredential(currentUser, credential);
  //   }
  // } catch (err: any) {
  //   console.error(err);
  // }
  if (currentUser) {
    const credential = EmailAuthProvider.credential(currentUser.email || '', providedPassword);
    await reauthenticateWithCredential(currentUser, credential);
  }
};

const sendPasswordReset = async (email: string) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert('Password reset link sent!');
  } catch (err: any) {
    console.error(err);
    alert(err.message);
  }
};

// 유저 타입에 따라 redirect 수정.
export const logout = () => {
  console.log('auth.currentUser => ', auth.currentUser);
  // 유저 정보 타입 갖고 와서 넣기.
  removeLocalStorageItems(removeItemListOnLogout);
  signOut(auth).then;
  return <></>;
};

export const Logout: React.FC<any> = ({ children, ...props }) => {
  console.log('log out component');
  removeLocalStorageItems(removeItemListOnLogout);
  signOut(auth).then;
  return <Navigate to="/" />;
};

export const useLogout = () => {
  const navigate = useNavigate();
  const companyType = getLocalStorageStringItem('--bohome-company-type');
  const userType = getLocalStorageStringItem('--bohome-user-type');
  const routeUserType = getLocalStorageStringItem('--bohome-route-user-type') || 'company';
  queryClient.invalidateQueries([]);
  queryClient.clear();

  return () => {
    navigate(`/login/${routeUserType}`);
    removeLocalStorageItems(removeItemListOnLogout);
    signOut(auth);
  };
};

export const getProfilePicUrl = () => {
  //  return auth.currentUser?.photoURL || '/images/profile_placeholder.png';
  return auth.currentUser?.photoURL;
};

export const getUserEmail = () => {
  return auth.currentUser?.email;
};

export const getUserId = () => {
  return auth.currentUser?.uid;
};

// 테스트
export const createOnetoOneChatroom = async (one: string, another: string) => {
  try {
    const message = await addDoc(collection(getFirestore(), 'Chatroom'), {
      one,
      another,
      timestamp: serverTimestamp(),
    });
  } catch (error: any) {
    console.error('Error writing new message to Firebase Database', error);
  }
};

export const chatroomName = process.env.REACT_APP_CHAT_ROOM || '';

export const saveMessage = async (messageText: string, chatRoomId: string, userArr: Array<any>) => {
  try {
    await addDoc(collection(getFirestore(), `${chatroomName}/${chatRoomId}/messages`), {
      // createdAt: serverTimestamp(),
      text: messageText,
      createdAt: new Date().getTime(),
      image: null,
      user: {
        _id: getUserId(),
        name: getUserEmail(),
      },
      userArr,
    }).then(() => {
      updateDoc(chatroomDocRef(chatRoomId), {
        latest_message: {
          text: messageText,
          createdAt: new Date().getTime(),
          image: null,
          user: {
            _id: getUserId(),
            name: getUserEmail(),
          },
          userArr,
        },
      });
    });
  } catch (error: any) {
    console.error('Error writing new message to Firebase Database', error);
  }
};

// export const chatroomDocRef = (chatRoomId: string) => doc(firestore, chatroomName, chatRoomId);
export const chatroomsCol = collection(firestore, chatroomName);
// export const chatroomsQuery = query(chatroomsCol, orderBy('timestamp', 'desc'));
export const chatroomsQuery = query(chatroomsCol, orderBy('latest_message.createdAt', 'desc'));
export const chatroomDocRef = (chatRoomId: string) => doc(firestore, `${chatroomName}/${chatRoomId}`);

export const messagesColRef = (chatRoomId: string) => collection(firestore, `${chatroomName}/${chatRoomId}/messages`);

export const messagesQuery = (chatRoomId: string, ...queryConstraints: QueryConstraint[]) =>
  query(messagesColRef(chatRoomId), ...queryConstraints);

export const loadUserLists = () => {
  onSnapshot(collection(getFirestore(), 'ChatRoomList'), (doc) => {
    // Array of CJ messages docs
    // doc.docs.map((value) => console.log(value.id));

    // detect whether the user belongs to which docs.
    const threads = doc.docs.map((value) => ({
      _id: value.id,
      name: '',
      ...value.data(),
    }));
    console.log('threads => ', threads);
    return threads;
  });
};

export const getWebFcmToken = async () => {
  let result;
  try {
    result = await getToken(messaging, {
      vapidKey: 'BEatN0NcWA6UdKr8u-teIepcJl4kNlED-shMnqWcZApuycPQ_vYfo7Ltpq1sj1_m_DlmDgmcJJJlTp_JCU4RIO0',
    });
  } catch (err) {
    console.error(err);
  } finally {
    return result;
  }
};

import {
  Box,
  Button,
  Flex,
  HStack,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  Textarea,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useConfirmMoveOut, useCreateCompanyVirtualAccount } from '../../../../lib/admin/hooks/mutation';
import { LeaseContract, ServiceCategoryEnum } from '../../../../lib/common/utils/axios/types';
import { LeftArrowIcon } from '../../../../statics/common/icons';

interface ConfirmMoveOutModalProps {
  disabled?: boolean;
  lease_contract: LeaseContract;
}

const ConfirmMoveOutModal: React.FC<ConfirmMoveOutModalProps> = ({ children, lease_contract, disabled = false }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isOnReject, setIsOnReject] = useState<boolean>(false);
  const [rejectReason, setRejectReason] = useState('');

  const { mutateAsync: confirmMoveOut } = useConfirmMoveOut(lease_contract._id);

  const handleModalOpen = () => {
    if (!disabled) {
      onOpen();
    }
  };

  const onClickOnRejectButton = () => {
    setIsOnReject((prev) => !prev);
  };

  const onClickApproveButton = async () => {
    await confirmMoveOut({
      admin_check_status: 'approved',
    }).then(() => {
      onClose();
    });
  };

  const onClickRejectButton = async () => {
    await confirmMoveOut({
      admin_check_status: 'rejected',
      rejected_reason: rejectReason,
    }).then(() => {
      onClose();
    });
  };

  console.log(lease_contract);

  return (
    <>
      <Box onClick={handleModalOpen}>{children}</Box>

      <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside" isCentered size={'xl'}>
        <ModalOverlay />
        {!isOnReject ? (
          <ModalContent p={10}>
            <ModalCloseButton />
            <Box w="full" h="full">
              <Box textAlign={'center'}>
                <Text textStyle={'medium18'}>퇴실처리 시 미납금, 체크아웃 확인 필수!</Text>
                <Text textStyle={'medium18'}>
                  정말로 {lease_contract?.tenant?.user_name}님의 퇴실승인 처리하시겠습니까?
                </Text>
              </Box>
            </Box>
            <HStack w="full" mt={'24px'} justify={'center'}>
              <Button variant={'red'} w={'100px'} onClick={onClickOnRejectButton}>
                <Text textStyle={'medium14'}>퇴실 반려</Text>
              </Button>
              <Tooltip label={'클릭시 퇴실 승인이 완료됩니다.'} hasArrow>
                <Button variant={'basic'} w={'100px'} onClick={onClickApproveButton}>
                  <Text textStyle={'medium14'}>퇴실 승인</Text>
                </Button>
              </Tooltip>
            </HStack>
          </ModalContent>
        ) : (
          <ModalContent p={6}>
            <Flex align={'center'} mb={3}>
              <HStack flex={1}>
                <LeftArrowIcon
                  w={6}
                  h={6}
                  color={'black'}
                  cursor={'pointer'}
                  onClick={() => setIsOnReject((prev) => !prev)}
                />
                <Text fontSize={'18px'} fontWeight={'medium'}>
                  반려 사유 입력
                </Text>
              </HStack>
              <Tooltip label={'클릭시 퇴실 반료가 완료됩니다.'} hasArrow>
                <Button variant={'red'} onClick={onClickRejectButton}>
                  반려완료
                </Button>
              </Tooltip>
            </Flex>
            <Textarea
              value={rejectReason}
              onChange={(e) => setRejectReason(e.target.value)}
              placeholder="반려 사유를 작성해 주세요."
              _placeholder={{ color: 'silverGray', fontSize: '16px' }}
              bgColor={'#f8f8f8'}
              borderRadius={'md'}
              resize={'none'}
              h={'192px'}
              px={'18px'}
              py={'25px'}
            />
          </ModalContent>
        )}
      </Modal>
    </>
  );
};

export default ConfirmMoveOutModal;

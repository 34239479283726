import { Box, HStack, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { FaPlus } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import QueryParamsLayout from '../../../../../../layouts/common/main/QueryParamsLayout';
import TextLink from '../../../../../../components/common/Link/TextLink';
import UserTooltip from '../../../../../../components/common/Tooltip';
import { currentParamsObject, ToAllFilledObj } from '../../../../../../lib/common/utils/base';
import { CompanyTypeTrans } from '../../../../../../lib/subscriber/utils/utils';
import { datedotformat, threetwofiveDashfy } from '../../../../../../lib/common/utils/dateformatter';
import { phoneNumberdashfy } from '../../../../../../lib/common/utils/digitformatter';
import RegisterNewHostModal from '../../../../../../components/common/Modals/RegisterNewHostModal';
import Loading from '../../../../../../components/common/Loading';
import { useGetAllCompaniesAllHosts } from '../../../../../../lib/common/hooks/query';
import * as Type from '../../../../../../types/common/CommonType';

const Host = () => {
  const { search: srch } = location;
  const { page, limit } = currentParamsObject();

  const _params = currentParamsObject();
  const params = ToAllFilledObj(_params);

  const res = useGetAllCompaniesAllHosts({
    axiosConfig: {
      params,
    },
  });
  const { isLoading } = res;
  if (isLoading) return <Loading />;
  return (
    <QueryParamsLayout
      res={res}
      modalOnLayout={
        <Box pos={'absolute'} right={0} bottom={0}>
          <Box m={10}>
            <RegisterNewHostModal disabled={false}>
              <Box
                bgColor={'zetaBlue'}
                color={'white'}
                fontSize={'24px'}
                px={5}
                py={5}
                borderRadius={'full'}
                boxShadow={'lg'}
                cursor={'pointer'}
                userSelect={'none'}
              >
                <HStack>
                  <Text display={'inline'} fontSize={'36px'} fontWeight={'extrabold'}>
                    <FaPlus />
                  </Text>
                </HStack>
              </Box>
            </RegisterNewHostModal>
          </Box>
        </Box>
      }
    >
      <Table variant="head_silverbg_toplining">
        <Thead bgColor={'#F8F8F8'} borderColor={'gray.100'}>
          <Tr>
            <Th>가입일</Th>
            <Th>임대인명</Th>
            <Th>연락처</Th>
            {/* <Th>이메일</Th> */}
            <Th>사업자 구분 / 사업자 번호</Th>
            {/* <Th>임대관리 유무</Th> */}
            {/* <Th>보유 매물</Th> */}
            {/* {todo host company registrant, host paper company for registrant, managing host 개념(모든 user에 대한 user_infos개념 이것도 company 기준인가 유저기준인가하면 컴패니 기준)} */}
            <Th>생성자</Th>
          </Tr>
        </Thead>
        <Tbody>
          {res.data?.result.map((company, index) => {
            const { name, user, business_type, business_registration_number } = company;
            const createdAt = user?.createdAt;
            const phone_number_first = user?.phone_number_first;
            const email = user?.email;
            const user_name = user?.user_name;
            const host_id = user?._id;

            return (
              <Tr key={srch + index}>
                <Td>{datedotformat(createdAt)}</Td>
                <Td textStyle={'pointer'}>
                  <UserTooltip memo={company?.user?.default_memo}>
                    <Link to={`detail/info?id=${host_id}`}>
                      <Text>{user_name}</Text>
                    </Link>
                  </UserTooltip>
                </Td>
                <Td>
                  <Text minW={'max'}>{phoneNumberdashfy(phone_number_first)}</Text>
                </Td>
                {/* <Td>
                  <TextLink href={`mailto:${email}`}>{email}</TextLink>
                </Td> */}
                <Td>
                  <Text minW={'max'}>
                    {`${CompanyTypeTrans(business_type)}`} / {name} / {threetwofiveDashfy(business_registration_number)}
                  </Text>
                </Td>
                {/* <Td textStyle={'pointer'}>
                  <Link to={`detail/info?id=${company?._id}`}>
                    <Text>{'조회'}</Text>
                  </Link>
                </Td> */}
                {/* <Td></Td> */}
                <Td>
                  <Text>{`${Type.RegistrantTransType[company?.user?.registrant_type || '']}`}</Text>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </QueryParamsLayout>
  );
};
export default Host;

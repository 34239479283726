import {
  AdminCheckStatus,
  CheckStatus,
  CommuteTimesEnum,
  CommuteTransportationsEnum,
  CompanyUserEnum,
  MoreOptionsEnum,
  ContractPaymentStatus,
  RoomLivingTypesEnum,
  RoomNumsEnum,
  ShareFacilitiesEnum,
  TenantStatus,
  TourTypeEnum,
  PaymentStatus,
  ProgressStatusEnum,
  ServiceCategoryEnum,
  GlobalStatus,
  SubscriptionPaymentStatusEnum,
  LeasePaymentStatusEnum,
  GlobalRequestType,
  RequestMatchingTypeEnum,
  AnnouncementTypeEnum,
  CompanySelectTypeEnum,
  UserSelectTypeEnum,
  WpBalanceLogListType,
  WpBalanceLog,
  buildingFormsEnum,
  buildingTypesEnum,
  FloorsEnum,
  NotificationType,
  OneCompanyType,
  PaymentMethodType,
  RequestType,
  SubscriptionTypeEnum,
} from './axios/types';
import { getTime } from 'date-fns';
import { NavigateOptions, useNavigate } from 'react-router-dom';
import { isEmpty, isUndefined } from 'lodash';
import { format, isToday, isYesterday } from 'date-fns';

export const subscription_payment_status_trans = {
  none: '납부 전',
  overdue: '연체',
  paid: '납부 완료',
};

export const subscriptionPaymentStatusTrans = (
  subscription_payment_status: SubscriptionPaymentStatusEnum | undefined,
) => {
  if (isUndefined(subscription_payment_status)) {
    return '';
  }
  return subscription_payment_status_trans[subscription_payment_status];
};

export const request_matching_type_trans = {
  forward: '순매칭',
  reverse: '역매칭',
};

export const RequestMatchingTypeTrans = (request_matching_type: RequestMatchingTypeEnum) => {
  return request_matching_type_trans[request_matching_type];
};

export const service_category_trans = {
  house_hunting: '집찾기',
  support_payment: '월세 지원',
};

export const ServiceCategoryTrans = (service_category: ServiceCategoryEnum) => {
  return service_category_trans[service_category];
};

export const announcement_type_trans = {
  default: '일반',
  urgent: '긴급',
  updated: '업데이트',
};

export const announcementTypeTrans = (announcement_type: AnnouncementTypeEnum | undefined) => {
  if (isUndefined(announcement_type)) {
    return '';
  }
  return announcement_type_trans[announcement_type];
};

export const progress_status_trans = {
  pending: '대기',
  proceeding: '진행중',
  completed: '완료',
  reserved: '예약',
  deprecated: '폐지',
};

export const progressStatusTrans = (progress_status: ProgressStatusEnum | undefined) => {
  if (isUndefined(progress_status)) {
    return '';
  }
  return progress_status_trans[progress_status];
};

export const global_payment_status_trans = {
  none: '납부 전',
  overdue: '연체',
  paid: '납부 완료',
};

export const globalPaymentStatusTrans = (global_payment_status: LeasePaymentStatusEnum) => {
  if (!global_payment_status) {
    return '';
  }
  return global_payment_status_trans[global_payment_status];
};

export const company_select_type_trans = {
  all: '전체',
  lease: '임대',
  admin: '어드민',
  agency: '중개사',
  subscriber: '고객사',
};

export const companySelectTypeTrans = (company_select_type: CompanySelectTypeEnum | undefined) => {
  if (!company_select_type) {
    return '';
  }
  return company_select_type_trans[company_select_type];
};

export const user_select_type_trans = {
  all: '전체',
  tenant: '임직원',
  host: '임대인',
  master: '마스터',
  manager: '매니저',
};

export const userSelectTypeTrans = (user_select_type: UserSelectTypeEnum | undefined) => {
  if (!user_select_type) {
    return '';
  }
  return user_select_type_trans[user_select_type];
};

/** type GlobalStatus 따라 차후 더 추가 */
export const global_status_trans = {
  none: '-',
  pending: '대기',
  approved: '승인',
  proceeding: '진행중',
  completed: '완료',
  rejected: '반려',
  cancelled: '취소',
  delayed: '지연',
  checked: '확인됨',
  withdrawn: '철회',
  deprecated: '폐기',
  finished: '종료',
  house_hunting: '집찾기',
  support_payment: '월세 지원',
};

export const globalStatusTrans = (global_status: GlobalStatus) => {
  if (!global_status) {
    return '';
  }
  return global_status_trans[global_status];
};

export const global_request_trans = {
  none: '-',
  move_in: '입주',
  moving_in: '입주',
  move_out: '퇴실',
  moving_out: '퇴실',
  extending: '연장',
  moving_out_and_extending: '이사',
  new: 'new',
};

export const globalRequstTrans = (global_request: GlobalRequestType) => {
  if (!global_request) {
    return '';
  }
  return global_request_trans[global_request];
};

export const global_request_variant_trans = {
  none: 'none',
  move_in: 'skyBlue',
  moving_in: 'skyBlue',
  move_out: 'strawberry',
  moving_out: 'strawberry',
  extending: 'yellow',
  moving_out_and_extending: 'skyBlue',
  new: 'skyBlue',
};

export const globalRequstVariantTrans = (global_request_variant: GlobalRequestType) => {
  if (!global_request_variant) {
    return '';
  }
  return global_request_variant_trans[global_request_variant];
};

export const room_number_trans = {
  one_room: '원룸',
  two_room: '투룸',
  three_room: '쓰리룸 이상',
};

export const roomNumberTrans = (room_number_type: RoomNumsEnum) => {
  if (!room_number_type) {
    return '';
  }
  return room_number_trans[room_number_type];
};

export const room_living_type_trans = {
  open: '오픈형',
  devide: '분리형',
  duplex: '복층',
};

export const roomLivingTypeTrans = (subscription_type: RoomLivingTypesEnum) => {
  if (!subscription_type) {
    return '';
  }
  return room_living_type_trans[subscription_type];
};

export const share_facilities_trans = {
  kitchen: '공용 주방',
  shower_room: '공용 샤워실',
  toilet: '공용 화장실',
  lounge: '라운지',
  terrace: '테라스',
  rooftop: '루프탑',
  laundary_room: '세탁실',
};

export const shareFacilitiesTrans = (share_facility: ShareFacilitiesEnum) => {
  return share_facilities_trans[share_facility];
};

export const more_options_trans = {
  new_construction: '신축',
  parking: '주차',
  security_facility: '보안시설',
  elevator: '엘리베이터',
  pets: '반려동물',
  roadside_location: '대로변위치',
  etc: '기타',
};

export const moreOptionsTrans = (more_options_type: MoreOptionsEnum) => {
  return more_options_trans[more_options_type];
};

export const commute_transportations_trans = {
  walk: '도보',
  bus: '버스',
  subway: '지하철',
  own_car: '자차',
};

export const commuteTransportationsTrans = (commute_transportations_type: CommuteTransportationsEnum) => {
  return commute_transportations_trans[commute_transportations_type];
};

export const inout_type_trans = {
  IN: '입금',
  OUT: '출금',
};

export const inoutTypeTrans = (inout: 'IN' | 'OUT') => {
  if (!inout) {
    return '';
  }
  return inout_type_trans[inout];
};

export const inout_color_trans = {
  IN: 'zetaBlue',
  OUT: 'primaryRed',
};

export const inoutColorTrans = (inout: 'IN' | 'OUT') => {
  if (!inout) {
    return '';
  }
  return inout_color_trans[inout];
};

export const company_user_type_trans = {
  master: '대표 담당자',
  manager: '담당자',
};

export const companyUserTypeTrans = (company_user_type: CompanyUserEnum) => {
  return company_user_type_trans[company_user_type];
};

export const tour_status_trans = {
  house_tour: '하우스 투어',
  contract_meeting: '계약 미팅',
};

export const tourStatusTrans = (tour_status: TourTypeEnum) => {
  return tour_status_trans[tour_status];
};

export const contract_status_trans = {
  confirm_expected: '구독 요청',
  sign_expected: '서명 대기',
  total_contract_payment_expected: '결제 대기',
  subscription_payment_expected: '계약 완료',
  payment_expected: '',
  payment_completed: '',
};

export const contractStatusTrans = (contract_status: ContractPaymentStatus) => {
  return contract_status_trans[contract_status];
};

export const payment_status_trans = {
  down_payment_expected: '계약금',
  balance_payment_expected: '보증금+월세',
  rent_expected: '월세',
};

export const paymentStatusTrans = (payment_status: PaymentStatus) => {
  return payment_status_trans[payment_status];
};

export const commute_times_trans = {
  walkable: '걸어서 가는 거리',
  transport_20: '20분 이내',
  transport_40: '40분 이내',
  transport_60: '60분 이내',
  do_not_matter: '상관 없음',
};

export const commuteTimesTrans = (commute_times_type: CommuteTimesEnum) => {
  return commute_times_trans[commute_times_type];
};

export const fullOptionTrans = (is_full_option: boolean) => {
  return is_full_option ? '풀옵션' : '';
};

export const isPaidTrans = (is_paid: boolean | string) => {
  return is_paid ? '납부 완료' : '연체';
};

export const isParkingTrans = (is_parking: boolean | string) => {
  let result = '';
  switch (true) {
    case is_parking === true:
    case is_parking === 'true':
      result = '주차';
      break;
    case is_parking === false:
    case is_parking === 'false':
      result = '';
      break;
  }
  return result;
};

export const isPetTrans = (is_pet: boolean | string) => {
  let result = '';
  switch (true) {
    case is_pet === true:
    case is_pet === 'true':
      result = '반려동물';
      break;
    case is_pet === false:
    case is_pet === 'false':
      result = '';
      break;
  }
  return result;
};

export const isSecurityWindowTrans = (is_security_window: boolean | string) => {
  let result = '';
  switch (true) {
    case is_security_window === true:
    case is_security_window === 'true':
      result = '방범창';
      break;
    case is_security_window === false:
    case is_security_window === 'false':
      result = '';
      break;
  }
  return result;
};

export const isExpenditureEvidence = (is_expenditure_evidence: boolean) => {
  return is_expenditure_evidence ? '발행' : '미발행';
};

export const roomRequestDateFormat = (createdAt: string, end_date: string) => {
  // 86400000;

  const now = new Date();

  return Date.parse(end_date) - getTime(now) < 0
    ? '종료'
    : Date.parse(end_date) - getTime(now) < 86400000
    ? '종료 예정'
    : getTime(now) - Date.parse(createdAt) < 86400000
    ? '신규 요청'
    : '-';
};

export const isRoomRecommendationDelivered = (is_deliverd: boolean) => {
  return is_deliverd ? '전달 완료' : '전달 대기';
};

export const isRoomRecommendationStatus = (status?: AdminCheckStatus) => {
  switch (status) {
    case 'approved':
      return '전달 완료';
    case 'pending':
      return '전달 대기';
    case 'rejected':
      return '반려';
    case 'withdrawn':
      return '철회';
    default:
      break;
  }
};

export const getCurrent_WP_Amt = (wpBalanceLog: WpBalanceLog) => {
  if (!wpBalanceLog) {
    return 0;
  }
  const { inoutType, balanceAmt, changeAmt } = wpBalanceLog;
  const amt = inoutType === 'IN' ? balanceAmt + changeAmt : balanceAmt - changeAmt;

  return amt;
};

export const getCurrent_WP_Amt_FromList = (wpBalanceLogList: WpBalanceLogListType) => {
  if (!wpBalanceLogList || isEmpty(wpBalanceLogList)) {
    return 0;
  }
  const wpBalanceLog = wpBalanceLogList[0];
  const { inoutType, balanceAmt, changeAmt } = wpBalanceLog;
  const amt = inoutType === 'IN' ? balanceAmt + changeAmt : balanceAmt - changeAmt;

  return amt;
};

export const useNavigateToUserDetailPage = () => {
  const navigate = useNavigate();

  const onNavigate = (status: TenantStatus, user_id: string, options?: NavigateOptions | undefined) => {
    const isAfter = status === 'contracted' || status === 'moving_in' || status === 'moving_out';
    return navigate(!isAfter ? `${before_path}${user_id}` : `${after_path}${user_id}`, options);
  };
  return [onNavigate];
};

const before_path = '/admin/manage/customers/user/detail/info?id=';
const after_path = '/admin/manage/customers/user/detail/linfo?id=';

export const pathOfUserDetailPage = (status: TenantStatus) => {
  const isAfter = status === 'contracted' || status === 'moving_in' || status === 'moving_out';
  return !isAfter ? before_path : after_path;
};

export const subscription_type_trans = {
  basic: '베이직',
  advanced: '어드밴스드',
  enterprise: '엔터프라이즈',
};

export const subscriptionTypeTrans = (subscription_type: SubscriptionTypeEnum) => {
  return isUndefined(subscription_type) ? '' : subscription_type_trans[subscription_type];
};

export const paymentPeriodTrans = (is_year: boolean) => {
  return is_year ? '연 결제' : '월 결제';
};

const payment_method_type_trans = {
  virtual_account: '가상계좌',
  cms: 'CMS 자동이체',
  samsung_card: '삼성카드',
  account_transfer: '계좌이체',
  admin_privilege: '관리자 승인',
};

export const paymentMethodTypeTrans = (method_type: PaymentMethodType) => {
  return payment_method_type_trans[method_type];
};

const company_type_trans = {
  corporation: '법인',
  sole_proprietor: '개인',
};

export const CompanyTypeTrans = (company_type: OneCompanyType) => {
  return company_type_trans[company_type];
};

const request_type_trans = {
  new: '신규',
  extending: '연장',
  changing: '변경',
  withdrawal: '철회',
};

export const requesTypeTrans = (request_type: RequestType) => {
  return request_type_trans[request_type];
};

const subscription_color = {
  basic: 'basicPlan',
  advanced: 'advancedPlan',
  enterprise: 'enterprisePlan',
};

export const subscriptionColorTrans = (subscription_type: SubscriptionTypeEnum) => {
  return subscription_color[subscription_type];
};

const tenant_status_type_trans = {
  using: '이용중',
  in_service: '승인 완료',
  in_custom_room_request: '집 찾기 중',
  pending_in_custom_room_request: '집 찾기 중(미승인)',
  contracting: '계약 중',
  contracted: '계약 완료',
  moving_in: '입주 중',
  moving_out: '퇴실',
  extending: '연장',
  none: '-',
};

const tenant_status_variant_trans = {
  using: 'green',
  in_service: 'green',
  in_custom_room_request: 'green',
  pending_in_custom_room_request: 'green',
  contracting: 'green',
  contracted: 'greenBorder',
  moving_in: 'green',
  moving_out: 'green',
  extending: 'green',
  none: 'green',
};

export const tenanStatusTypeTrans = (tenant_status: TenantStatus) => {
  if (!tenant_status) {
    return '';
  }
  return tenant_status_type_trans[tenant_status];
};

export const tenanStatusVariantTrans = (tenant_status: TenantStatus) => {
  if (!tenant_status) {
    return '';
  }
  return tenant_status_variant_trans[tenant_status];
};

export const building_form_type_trans = {
  villa: '빌라',
  officetel: '오피스텔',
  row_house: '연립',
  house: '단독',
  multi_family_house_s: '다세대',
  multi_family_house_g: '다가구',
  country_house: '전원주택',
  commercial_house: '상가주택',
  hanok_house: '한옥주택',
  share_house: '쉐어하우스',
  coliving_house: '프리미엄오피스텔',
  apartment: '아파트',
};

export const buildingFormTypeTrans = (building_form: buildingFormsEnum | '') => {
  if (!building_form) {
    return '';
  }
  return building_form_type_trans[building_form];
};

export const building_type_trans = {
  detached_house: '단독주택',
  apartment_house: '공동주택',
  neighborhood_living_1: '제1종 근린생활시설',
  neighborhood_living_2: '제2종 근린생활시설',
  cultural_gathering: '문화 및 집회시설',
  religion: '종교시설',
  sale: '판매시설',
  transportation: '운수시설',
  medical: '의료시설',
  education_research: '교육연구시설',
  elderly_children: '노유자(幼者: 노인 및 어린이)시설',
  training: '수련시설',
  exercise: '운동시설',
  business: '업무시설',
  accommodation: '숙박시설',
  amusement: '위락(慰)시설',
  factory: '공장',
  ware_house: '창고시설',
  danger: '위험물 저장 및 처리 시설',
  car: '자동차 관련 시설',
  animal_plant: '동물 및 식물 관련 시설',
  resource_circulation: '자원순환 관련 시설',
  military: '교정(矯正) 및 군사 시설',
  broadcast: '방송통신시설',
  power_plant: '발전시설',
  cemetery: '묘지 관련 시설',
  tourist: '관광 휴게시설',
  funeral: '장례시설',
  campsite: '야영장 시설',
  unregistered: '미등기건물',
  others: '그 밖에 토지의 정착물',
};

export const buildingTypeTrans = (building_type: buildingTypesEnum) => {
  if (!building_type) {
    return '';
  }
  return building_type_trans[building_type];
};

export const floor_type_trans = {
  ground: '지상',
  rooftop: '옥탑',
  underground: '반지하',
};

export const floorTypeTrans = (floor_type: FloorsEnum) => {
  return floor_type_trans[floor_type];
};

export const checkArrAndGetFirst = (dataArr: Array<any>) => {
  if (dataArr.length >= 1) {
    return dataArr[0];
  } else {
    return {};
  }
};

export const NotiIconMatch = (notification_type: NotificationType) => {
  switch (notification_type) {
    case 'room_recommendation':
      return '📝';
    case 'user_approval_request':
      return '🆕';
    case 'custom_room_request_creation':
      return '🆕';
    case 'user_confirmation':
      return '🔔';
    case 'subscription_contract_request':
      return '🔔';
    case 'subscription_payment_request':
      return '💰';
    case 'service_request':
      return '💌';
    case 'lease_contract_completion':
      return '🏠';
    case 'proxy_payment_request':
      return '🚨';
    case 'subscription_payment_overdue_request':
      return '🚨';
    default:
      return '🔔';
  }
};

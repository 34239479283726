import { Box, HStack, Skeleton, StackProps, VStack, Wrap, WrapItem } from '@chakra-ui/react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { UseQueryResult } from 'react-query';
import { QueryResponse } from '../../../lib/common/utils/axios/types';
import NoShownData from '../../../components/common/NoShownData';
import Pagination from '../../../components/common/Pagination';
import LimitMenu from '../../../components/common/Pagination/LimitMenu';
import ServiceCategoryMenu from '../../../components/common/Pagination/ServiceCategoryMenu';
import { CustomSearch } from '../../../components/common/Search';
import SetYearAndMonthParams from '../../../components/common/Checkbox/SetYearAndMonthParamsCheckBox';
import * as _ from 'lodash';
// import SetApprovedRejectedPendingParams from '../SelectBox/SetApprovedRejectedPendingParams';
import { isUndefined } from 'lodash';
import {
  SetCheckHouseStatusParams,
  SetConfirmStatusParams,
  SetPaymentStatusParams,
  SetServiceStatusParams,
  SetSupportPaymentStatusParams,
} from '../../../components/common/Menu';
import { SetUsingSupportPayment } from '../../../components/common/Checkbox/SetBooleanCheckBox';
import { isEmptyArray } from 'formik';
import {
  QueryParamsLayoutContext,
  QueryParamsLayoutContextProvider,
} from '../../../contexts/common/QueryParamsLayoutContext';
import {
  SetCheckStatusCheckboxes,
  SetRequestTypeCheckboxes,
} from '../../../components/common/Checkbox/SetMultiBooleanCheckBox/SetCustomStatus';
import Loading from '../../../components/common/Loading';
import { useEffect } from 'react';
import HeaderNavBar from '../HeadNavBar';
import { matchPath, useLocation } from 'react-router-dom';
import { useGetUserType } from '../../../lib/common/hooks/query';
import { SubTitleInfosType } from './MainLayout';
import * as Type from '../../../types/common/CommonType';

type pos = 'left' | 'middle' | 'right';

interface QueryParamsLayoutProps extends StackProps {
  res?: UseQueryResult<Type.QueryResponse<any>>;
  data?: QueryResponse<any>;
  isLoading?: boolean;
  modalOnLayout?: JSX.Element;
  moreFilters?: {
    yearAndMonthSelect?: pos;
    serviceCategoryMenu?: pos;
    checkHouseStatusMenu?: pos;
    supportPaymentStatusMenu?: pos;
    paymentStatusMenu?: pos;
    confirmStatusMenu?: pos;
    serviceStatusMenu?: pos;
    usingSupportPaymentCheckbox?: pos;
    requestTypeCheckboxes?: pos;
    checkStatusCheckboxes?: pos;
  };
  removeSearchBar?: boolean;
  removeLimitBar?: boolean;
  removeTopBar?: boolean; // SearchBar + LimitBar
  placeholder?: string;
  contentProps?: React.CSSProperties;
  pseudoQueryKey?: string;
  pageInfo?: Type.PageInfo;
}

const QueryParamsLayout: React.FC<QueryParamsLayoutProps> = ({
  children,
  res,
  modalOnLayout,
  moreFilters = {},
  removeSearchBar = false,
  removeLimitBar = false,
  removeTopBar = false,
  placeholder = '검색',
  contentProps,
  pseudoQueryKey,
  pageInfo,
  ...props
}) => {
  const arr = _.invertBy(moreFilters);

  const obj_filter = {
    yearAndMonthSelect: SetYearAndMonthParams,
    serviceCategoryMenu: ServiceCategoryMenu,
    checkHouseStatusMenu: SetCheckHouseStatusParams,
    supportPaymentStatusMenu: SetSupportPaymentStatusParams,
    paymentStatusMenu: SetPaymentStatusParams,
    confirmStatusMenu: SetConfirmStatusParams,
    serviceStatusMenu: SetServiceStatusParams,
    usingSupportPaymentCheckbox: SetUsingSupportPayment,
    requestTypeCheckboxes: SetRequestTypeCheckboxes,
    checkStatusCheckboxes: SetCheckStatusCheckboxes,
  };
  const keys_left = arr.left as Array<keyof typeof obj_filter>;
  const keys_middle = arr.middle as Array<keyof typeof obj_filter>;
  const keys_right = arr.right as Array<keyof typeof obj_filter>;

  return (
    <QueryParamsLayoutContextProvider>
      <QueryParamsLayoutContext.Consumer>
        {() => {
          return (
            <>
              <VStack alignItems={'start'} w={'100%'} h="full" pos="relative" {...props}>
                {!removeTopBar && (
                  <Wrap spacing={2} w="full" align={'center'}>
                    {keys_left && (
                      <HStack>
                        {keys_left.map((key, index) => {
                          const Filter = obj_filter[key];
                          return <Filter key={index} />;
                        })}
                      </HStack>
                    )}
                    {!removeSearchBar && <CustomSearch placeholder={placeholder} pseudoQueryKey={pseudoQueryKey} />}
                    <Box flex={1} />
                    {keys_middle?.map((key, index) => {
                      const Filter = obj_filter[key];
                      return (
                        <WrapItem key={index}>
                          <Filter />
                        </WrapItem>
                      );
                    })}
                    {!removeLimitBar && <LimitMenu />}
                    {keys_right?.map((key, index) => {
                      const Filter = obj_filter[key];
                      return (
                        <WrapItem key={index}>
                          <Filter />
                        </WrapItem>
                      );
                    })}
                  </Wrap>
                )}
                <VStack spacing={5} w="100%" h="full">
                  <Scrollbars
                    style={{ height: '100%', minHeight: 'max-content', ...contentProps }}
                    autoHide
                    renderTrackVertical={({ style, ...props }) => (
                      <div
                        {...props}
                        style={{
                          ...style,
                          right: '0px',
                          bottom: '2px',
                          top: '44px',
                          borderRadius: '10px',
                        }}
                        className="queryparamslayout-track-vertical"
                      />
                    )}
                  >
                    {res?.data ? children : <NoShownData />}
                  </Scrollbars>
                  {<Pagination res={res} pseudoQueryKey={pseudoQueryKey} pageInfo={pageInfo} />}
                </VStack>
                {modalOnLayout}
              </VStack>
            </>
          );
        }}
      </QueryParamsLayoutContext.Consumer>
    </QueryParamsLayoutContextProvider>
  );
};

export default QueryParamsLayout;

import { Box, Center, Image, Progress, Text } from '@chakra-ui/react';
import logo_blue from '../../../statics/common/images/logo-blue.png';

const Loading = () => {
  return (
    <Center w="100vw" h="100vh">
      <Box>
        <Center h="100%" alignItems={'flex-end'} mb={4}>
          <Image src={logo_blue} />
          <Text fontWeight={700} fontSize={'14px'} color="zetaBlue">
            Now Loading...
          </Text>
        </Center>
        <Progress size="xs" isIndeterminate colorScheme={'facebook'} />
      </Box>
    </Center>
  );
};

export default Loading;

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  ButtonGroup,
} from '@chakra-ui/react';
import { useRef } from 'react';
import { DialogDisclosureProps } from '.';
import { queryClient } from '../../..';
import { rooms_id_path, rooms_key } from '../../../statics/agent/data/KeyPath';

interface PageOutProps extends DialogDisclosureProps {
  title: string;
  content: string;
  Exitbutton?: any;
}

const PageOutCheck: React.FC<PageOutProps> = ({ onClose, isOpen, title, content, Exitbutton }) => {
  const cancelRef = useRef();
  return (
    <>
      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef.current} onClose={onClose} isCentered>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {title}
            </AlertDialogHeader>

            <AlertDialogBody>{content}</AlertDialogBody>

            <AlertDialogFooter>
              <ButtonGroup variant="outline">
                {/* <Button variant={'gray'} w="80px" _hover={{ bgColor: 'red', borderColor: 'red' }}>
                  나가기
                </Button> */}
                <Box
                  onClick={() => {
                    onClose();
                    queryClient.invalidateQueries(rooms_key);
                  }}
                >
                  {Exitbutton}
                </Box>
                {/* <Exitbutton /> */}
                <Button
                  variant={'basic'}
                  w="80px"
                  onClick={() => {
                    onClose();
                    queryClient.invalidateQueries(rooms_key);
                  }}
                  autoFocus
                >
                  머무르기
                </Button>
              </ButtonGroup>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default PageOutCheck;

import { Text } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';

interface ActiveTextProps {
  to: string;
}

export const ActiveText: React.FC<ActiveTextProps> = ({ to, children }) => {
  const { pathname } = useLocation();

  const included = to.includes(pathname);

  return (
    <Text as={RouterLink} to={to} fontWeight={included ? 'bold' : 'normal'}>
      {children}
    </Text>
  );
};

export default ActiveText;

import { Box, HStack, Table, Tbody, Td, Text, Th, Thead, Tr, VStack } from '@chakra-ui/react';
import { useSearchParams } from 'react-router-dom';
import QueryParamsLayout from '../../../../../../../../layouts/common/main/QueryParamsLayout';
import TextLink from '../../../../../../../../components/common/Link/TextLink';
import {
  useGetAllSubscriptionContracts,
  useGetCompanyAllManagers,
} from '../../../../../../../../lib/admin/hooks/query';
import { subscriptionPaymentStatusTrans } from '../../../../../../../../lib/common/utils/utils';
import { SubscriptionTypeEnum } from '../../../../../../../../lib/common/utils/axios/types';
import { currentParamsObject, ToAllFilledObj } from '../../../../../../../../lib/common/utils/base';
import { paymentPeriodTrans, subscriptionTypeTrans } from '../../../../../../../../lib/subscriber/utils/utils';
import { datedotformat } from '../../../../../../../../lib/common/utils/dateformatter';
import CompanyAllUsersHouseHuntingRequestModal from '../../../../../../../../components/common/Modals/CompanyAllUsersHouseHuntingRequestModal';
import CompanyAllUsersUsageListModal from '../../../../../../../../components/common/Modals/CompanyAllUsersUsageListModal';
import SubscriptionContractsTable from '../../../../../../../../components/admin/Table/CompanyNewTable/table';
import Loading from '../../../../../../../../components/common/Loading';

const User = () => {
  const { page, limit, id } = currentParamsObject(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const _params = currentParamsObject();
  const params = ToAllFilledObj(_params);

  const { data: cpData } = useGetCompanyAllManagers(id);
  const res = useGetAllSubscriptionContracts(id, {
    axiosConfig: {
      params,
    },
  });
  const { data: contractsData } = res;

  console.log('contractsData =>', contractsData);

  const allusers_househunting_request_querykey = 'allusers_househunting_request';
  const allusers_usagelist_querykey = 'allusers_usagelist';

  const handleOpenHousehuntingRequestModal = () => {
    if (cpData?._id) {
      searchParams.set(allusers_househunting_request_querykey, cpData._id || '');
      setSearchParams(searchParams);
    }
  };

  const handleOpenUsageListModal = () => {
    if (cpData?._id) {
      searchParams.set(allusers_usagelist_querykey, cpData._id || '');
      setSearchParams(searchParams);
    }
  };
  const { isLoading } = res;
  if (isLoading) return <Loading />;
  return (
    <VStack justifyContent={'start'} w={'100%'} h="full" spacing={6}>
      {/* 회원 정보 */}
      <VStack spacing={3} justifyContent={'start'} align={'flex-start'} w={'100%'}>
        <HStack w={'full'} spacing={0} justify={'space-between'}>
          <Box fontWeight={'bold'} fontSize={'18px'} textAlign={'start'} w={'112px'}>
            구독 현황
          </Box>
        </HStack>
        <Table variant="head_silverbg_toplining">
          <Thead>
            <Tr>
              <Th>회사명</Th>
              <Th>회사 코드</Th>
              <Th>구독 플랜</Th>
              <Th>이용 현황</Th>
              <Th>승인 대기</Th>
              <Th>구독료 납부</Th>
              <Th>구독 유효 기간</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>{cpData?.name}</Td>
              <Td>{cpData?.company_code}</Td>
              <Td>
                {subscriptionTypeTrans(
                  cpData?.house_hunting_subscription_contract?.subscription_type as SubscriptionTypeEnum,
                )}
              </Td>
              <Td textStyle={'pointer'} onClick={handleOpenUsageListModal}>
                {cpData?.house_hunting_subscription_contract?.count}/
                {cpData?.house_hunting_subscription_contract?.available_user_count}인
              </Td>
              <Td textStyle={'pointer'} onClick={handleOpenHousehuntingRequestModal}>
                {cpData?.house_hunting_subscription_contract?.pending_count}인
              </Td>
              <Td>
                {subscriptionPaymentStatusTrans(
                  cpData?.house_hunting_subscription_contract?.subscription_payment?.payment_status,
                ) || '-'}
              </Td>
              <Td color={'primaryRed'}>
                {datedotformat(cpData?.house_hunting_subscription_contract?.contract_start_date)}~
                {datedotformat(cpData?.house_hunting_subscription_contract?.contract_end_date)}
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </VStack>
      {/* 구독 신청 내역 */}
      <VStack spacing={3} justifyContent={'start'} align={'flex-start'} w={'100%'} h="full">
        <Box w={'100%'} fontWeight={'bold'} fontSize={'18px'} textAlign={'start'} flexGrow={1}>
          구독 신청 내역
        </Box>
        <QueryParamsLayout res={res}>
          <SubscriptionContractsTable res={res} />
        </QueryParamsLayout>
      </VStack>
      <CompanyAllUsersHouseHuntingRequestModal queryKeys={[allusers_househunting_request_querykey]} />
      <CompanyAllUsersUsageListModal queryKeys={[allusers_usagelist_querykey]} />
    </VStack>
  );
};

export default User;

import { Checkbox, CheckboxProps } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { createSearchParams, URLSearchParamsInit, useNavigate } from 'react-router-dom';
import { buildSearchParams, currentParamsObject, ToAllFilledObj } from '../../../../lib/common/utils/base';

interface GlobalStatusCheckboxProps extends CheckboxProps {
  param_key: string;
}

export const GlobalStatusCheckbox: React.FC<GlobalStatusCheckboxProps> = ({ param_key, children, ...props }) => {
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);

  const onClickCheckbox = () => setIsChecked((prev) => !prev);
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      const param_value = isChecked || '';
      const searchParams = buildSearchParams({
        page: '1',
        pagelist: '1',
        [param_key]: param_value,
      });
      navigate(searchParams);
    }
  }, [isChecked]);

  return (
    <Checkbox onChange={onClickCheckbox} isChecked={isChecked} size={'smd14'} {...props}>
      {children}
    </Checkbox>
  );
};

import {
  Box,
  BoxProps,
  ComponentWithAs,
  Flex,
  TableCellProps,
  Td,
  Text,
  Tr,
  TableBodyProps,
  HTMLChakraProps,
} from '@chakra-ui/react';

export const TitleTd: ComponentWithAs<'td', TableCellProps> = ({ children, ...props }) => {
  return (
    <Td minW={'170px'} w={'170px'} bgColor="#F8F8F8" fontWeight={'normal'} p={2} pl={4} {...props}>
      {children}
    </Td>
  );
};

export const ContentTd: ComponentWithAs<'td', TableCellProps> = ({ children, ...props }) => {
  return (
    <Td p={2} pl={4} {...props}>
      {children}
    </Td>
  );
};

interface TrContainerProps extends BoxProps {
  title: any;
  required?: boolean;
  isError?: any;
}

export const TrContainer: React.FC<TrContainerProps> = ({ children, title, required, isError, ...props }) => {
  return (
    <Tr borderTop={'1px solid rgb(235, 240, 246)'}>
      <TitleTd bgColor={isError ? 'primaryRed' : '#F8F8F8'} color={isError && 'white'}>
        <Flex>
          {required ? <Text>*</Text> : <Box w={'7px'} />}
          <Box fontSize={'14px'} {...props}>
            {title}
          </Box>
        </Flex>
      </TitleTd>
      <ContentTd>{children}</ContentTd>
    </Tr>
  );
};

interface TdContainerProps {
  title: any;
  required?: boolean;
  isError?: any;
}

export const TdContainer: React.FC<TdContainerProps> = ({ children, title, required, isError }) => {
  return (
    <>
      <TitleTd bgColor={isError ? 'primaryRed' : '#F8F8F8'} color={isError && 'white'}>
        <Flex>
          {required ? <Text>*</Text> : <Box w={'7px'} />}
          <Box w={'150px'} fontSize={'14px'}>
            {title}
          </Box>
        </Flex>
      </TitleTd>
      <ContentTd>{children}</ContentTd>
    </>
  );
};

interface TdFullWContainerProps extends TdContainerProps {
  titleTdProps?: TableCellProps;
  ContentTdProps?: TableCellProps;
  isMinWMax?: boolean;
}

export const tabletitle_fitcontent_props = {
  titleTdProps: {
    maxW: 'fit-content',
    w: 'fit-content',
    minW: 'fit-content',
    p: '4',
  },
  isMinWMax: true,
  ContentTdProps: {
    w: 'full',
  },
};

export const TdFullWContainer: React.FC<TdFullWContainerProps> = ({
  children,
  title,
  required,
  isError,
  titleTdProps,
  ContentTdProps,
  isMinWMax = false,
}) => {
  return (
    <>
      <TitleTd w={'170px'} bgColor={isError ? 'primaryRed' : '#F8F8F8'} color={isError && 'white'} {...titleTdProps}>
        <Flex w={'100%'}>
          <Box w={'100%'} textStyle={'medium14'}>
            <Box display={'inline-block'} minW={isMinWMax ? 'max' : 'unset'}>
              {title}
            </Box>
            {required && <Text display={'inline-block'}>*</Text>}
          </Box>
        </Flex>
      </TitleTd>
      <ContentTd w={'300px'} fontSize={'14px'} {...ContentTdProps}>
        {children}
      </ContentTd>
    </>
  );
};

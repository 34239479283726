import { Box, Button, Flex, HStack, Input, Text, VStack } from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import { FaPlus } from 'react-icons/fa';
import { useSearchParams } from 'react-router-dom';
import QueryParamsLayout from '../../../../../../layouts/common/main/QueryParamsLayout';
import {
  useGetAllCompaniesContractsPayments,
  useGetAllCompaniesTenantsLeaseContracts,
} from '../../../../../../lib/admin/hooks/query';
import {
  currentParamsFilledObject,
  currentParamsObject,
  ToAllFilledObj,
} from '../../../../../../lib/common/utils/base';
import { firstAndlastDayThisMonth } from '../../../../../../lib/common/utils/dateformatter';
import {
  certificate_issue_type_with_total_select,
  lease_payment_type_with_total_select,
  payment_method_with_total_select,
} from '../../../../../../statics/common/data/selectbox';
import { FaPlusInCircle } from '../../../../../../statics/common/icons';
import RegisterManualBillModal from '../../../../../../components/common/Modals/RegisterManualBillModal';
import SelectBox from '../../../../../../components/common/SelectBox/EstatesSelectBox';
import SubscriptionSettlementsTable from '../../../../../../components/admin/Table/SubscriptionTable/table';
import Loading from '../../../../../../components/common/Loading';

const Memo = () => {
  return (
    <HStack
      bgColor={'lotionWhite'}
      px={4}
      py={4}
      alignItems={'center'}
      w={'full'}
      border="1px solid"
      borderColor={'silverGray'}
      borderRadius={'md'}
      boxShadow={'sm'}
    >
      {/* <Text textStyle={'bold14'}>메모</Text> */}
      <Box fontSize={'11px'}>
        • 납기일 = 이용자가 납부해야되는 날짜&nbsp;&nbsp;
        <Text display={'inline'} color={'#FB4667'}>
          납부일 = 실제 이용자가 납부한 날짜&nbsp;&nbsp;
        </Text>
        정산일 = 보홈 통장에서 임대인에게 정산된(송금된) 날짜
      </Box>
    </HStack>
  );
};

const SubscriptionSettlements = () => {
  const FieldBoxWidth = '140px';
  const FieldBoxHeight = '32px';
  const TextBoxWidth = '60px';
  const DateBoxWidth = '140px';

  const [searchParams, setSearchParams] = useSearchParams();

  const params = currentParamsObject(false);

  const [firstDateOfMonth, lastDateOfMonth] = firstAndlastDayThisMonth();

  const {
    limit = '',
    page = '',
    search = '',
    send_user_name = '',
    receive_user_name = '',
    payment_status = '',
    payment_method_type = '',
    certificate_issue_type = '',
    minimum_date = firstDateOfMonth,
    maximum_date = lastDateOfMonth,
  } = params;

  const res = useGetAllCompaniesContractsPayments({
    axiosConfig: {
      params: {
        ...params,
        minimum_date,
        maximum_date,
      },
    },
  });

  const initialValues = {
    limit,
    page,
    search,
    send_user_name,
    receive_user_name,
    payment_status,
    payment_method_type,
    certificate_issue_type,
    minimum_date,
    maximum_date,
  };
  const { isLoading } = res;
  if (isLoading) return <Loading />;
  return (
    <Box w="full" h={'full'}>
      <VStack alignItems={'start'} w={'full'} h="full">
        <Memo />
        {/* Filters */}
        <Formik
          initialValues={initialValues}
          onSubmit={(values, { setSubmitting }) => {
            const prevObj = ToAllFilledObj(values);
            for (const key in values) searchParams.set(key, (values as any)[key]);
            searchParams.set('page', '1');
            setSearchParams(searchParams);
          }}
        >
          {({ values, handleChange, handleSubmit, setFieldValue, resetForm }) => (
            <Form style={{ display: 'flex', width: '100%', height: '100%' }}>
              <VStack w={'full'}>
                <HStack
                  bgColor={'lotionWhite'}
                  px={4}
                  py={4}
                  alignItems={'center'}
                  w={'full'}
                  border="1px solid"
                  borderColor={'silverGray'}
                  borderRadius={'md'}
                  boxShadow={'sm'}
                  fontSize={'14px'}
                >
                  <VStack align={'start'}>
                    <HStack spacing={5}>
                      {/* 2 */}
                      <HStack>
                        <Box minW={TextBoxWidth}>납부상태</Box>
                        <Field
                          // 정산 내역하고 다른 name
                          name="payment_status"
                          as={SelectBox}
                          minW={FieldBoxWidth}
                          w={FieldBoxWidth}
                          h={FieldBoxHeight}
                          bgColor="lotionWhite"
                        >
                          {lease_payment_type_with_total_select.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </Field>
                      </HStack>
                      {/* 3 */}
                      <HStack>
                        <Box minW={TextBoxWidth}>납부방법</Box>
                        <Field
                          name="payment_method_type"
                          as={SelectBox}
                          minW={FieldBoxWidth}
                          w={FieldBoxWidth}
                          h={FieldBoxHeight}
                          bgColor="lotionWhite"
                        >
                          {payment_method_with_total_select.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </Field>
                      </HStack>
                      {/* 4 */}
                      <HStack>
                        <Box minW={TextBoxWidth}>증빙발행</Box>
                        <Field
                          name="certificate_issue_type"
                          as={SelectBox}
                          minW={FieldBoxWidth}
                          w={FieldBoxWidth}
                          h={FieldBoxHeight}
                          bgColor="lotionWhite"
                        >
                          {certificate_issue_type_with_total_select.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </Field>
                      </HStack>
                    </HStack>
                    <HStack spacing={5}>
                      {/* 5 */}
                      <HStack>
                        <Box minW={TextBoxWidth}>회사명</Box>
                        <Field
                          name="search"
                          as={Input}
                          placeholder={'회사명 입력'}
                          _placeholder={{
                            fontSize: '14px',
                          }}
                          fontSize={'14px'}
                          minW={FieldBoxWidth}
                          w={FieldBoxWidth}
                          h={FieldBoxHeight}
                          bgColor="lotionWhite"
                        ></Field>
                      </HStack>
                      {/* 7 */}
                      <HStack>
                        <Box minW={TextBoxWidth}>기간 조회</Box>
                        <Field
                          name={'minimum_date'}
                          as={Input}
                          type={'date'}
                          fontSize={'14px'}
                          h={FieldBoxHeight}
                          maxW={DateBoxWidth}
                        />
                        <Box>-</Box>
                        <Field
                          name={'maximum_date'}
                          as={Input}
                          type={'date'}
                          fontSize={'14px'}
                          h={FieldBoxHeight}
                          maxW={DateBoxWidth}
                        />
                      </HStack>
                    </HStack>
                  </VStack>
                  <HStack flexGrow={1} justify={'center'}>
                    <Button variant={'basic'} w={'90px'} type="submit">
                      조회
                    </Button>
                    <Button
                      variant={'warning'}
                      w={'90px'}
                      onClick={() => {
                        resetForm();
                        handleSubmit();
                      }}
                    >
                      초기화
                    </Button>
                  </HStack>
                </HStack>
                <QueryParamsLayout
                  res={res}
                  modalOnLayout={
                    // 수동 청구서 등록하기
                    <Box pos={'absolute'} right={0} bottom={0}>
                      <RegisterManualBillModal disabled={false}>
                        <FaPlusInCircle />
                      </RegisterManualBillModal>
                    </Box>
                  }
                  removeTopBar
                >
                  <SubscriptionSettlementsTable res={res} />
                </QueryParamsLayout>
              </VStack>
            </Form>
          )}
        </Formik>
      </VStack>
    </Box>
  );
};

export default SubscriptionSettlements;

import {
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Portal,
  StackDivider,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
  VStack,
  Wrap,
} from '@chakra-ui/react';
import { isEmptyArray, isEmptyObject, isUndefined } from '@chakra-ui/utils';
import { format } from 'date-fns';
import { Fragment, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { StyledSearch } from '../../Search';
import TextLink from '../../Link/TextLink';
import { useGetCustomRoomRequestRecommendedRooms } from '../../../../lib/common/hooks/query';
import { isRoomRecommendationStatus, RequestMatchingTypeTrans } from '../../../../lib/common/utils/utils';
import { RequestMatchingTypeEnum, RequestRoom, TenantStatus } from '../../../../lib/common/utils/axios/types';
import { requestStatusTrans, truncateLength } from '../../../../lib/common/utils/base';
import { buildingFormTypeTrans } from '../../../../lib/subscriber/utils/utils';
import { datedetaildotformat_mm, datedotformat } from '../../../../lib/common/utils/dateformatter';
import { staticThreeCommafy } from '../../../../lib/common/utils/digitformatter';
import { ClickIconContainer, LeftArrowIcon, RightArrowIcon } from '../../../../statics/common/icons';
import Memo from '../../Memo/base';
import ConfirmContractModal from '../ConfirmContractModal';
import ConfirmRequestRoomsModal from '../ConfirmRequestRoomsModal';
import MakeContractReservationModal from '../MakeContractReservationModal';
import MakeTourReservationModal from '../MakeTourReservationModal';
import RecommendRoomsModal from '../RecommendRoomsModal';
import RejectRequestRoomsDialog from '../RejectRequestRoomsDialog';
import _ from 'lodash';
import CancleRecommendedRoomsModal from '../CancleRecommendedRoomsModal';
import { AccessHiddenTag } from '../../Tag';
import * as Type from '../../../../types/common/CommonType';
import * as Util from '../../../../lib/common/utils/Util';
import { useLocation } from 'react-router-dom';

interface RequestRoomsModalProps {
  room_request_id: string;
  user_name?: string;
  company_name?: string;
  request_code?: string;
  room_number_codes?: string[];
  tenant_id?: string;
  tenant_status?: TenantStatus;
  request_createdAt: string;
  matching_type?: RequestMatchingTypeEnum;
}

const CustomRoomRequestModal: React.FC<RequestRoomsModalProps> = ({
  room_request_id,
  user_name,
  company_name,
  request_code,
  room_number_codes,
  request_createdAt,
  tenant_id,
  matching_type,
  children,
}) => {
  const location = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [typedSearch, setTypedSearch] = useState<string>('');
  const [checkedItems, setCheckedItems] = useState<Type.Company[]>([]);

  const checkedItemIds = checkedItems.map((item) => item?.employee?.custom_room_request?.recommended_room?.room_id);

  const { data: requestRoomsData, refetch: refetchRequestRoomsData } = useGetCustomRoomRequestRecommendedRooms(
    room_request_id,
    {
      options: {
        enabled: isOpen,
        keepPreviousData: false,
      },
      axiosConfig: {
        params: {
          search: typedSearch,
          limit: 1000,
        },
      },
    },
  );

  console.log('requestRoomsData =>', requestRoomsData);

  const currentAllRoomIds = requestRoomsData?.map(
    (room) => room?.employee?.custom_room_request?.recommended_room?.room_id,
  );

  const allChecked = currentAllRoomIds?.every((item) => checkedItemIds.includes(item || ''));
  const isIndeterminate = currentAllRoomIds?.some((item) => checkedItemIds.includes(item || '')) && !allChecked;
  const saveIsDisabled = !currentAllRoomIds?.some((item) => checkedItemIds.includes(item || ''));
  const routeUserType = Util.getRouteUserTypeFromUrl(location?.pathname);
  console.log('debug', routeUserType);

  const pathToDetailRequest =
    routeUserType == 'admin'
      ? `/${routeUserType}/manage/customers/user/detail/request?id=${tenant_id}&requestid=${room_request_id}`
      : `/${routeUserType}/manage/estates/rooms`;

  return (
    <>
      <Box onClick={onOpen}>{children}</Box>
      <Modal isOpen={isOpen} onClose={onClose} size={'7xl'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Flex pr={6} align={'end'}>
              <Box flexGrow={1}>
                <TextLink href={pathToDetailRequest} target={'_blank'} fontSize={'20px'} textUnderlineOffset={5}>
                  {user_name} ({company_name})
                </TextLink>
              </Box>
              <Box fontSize={'16px'}>
                <HStack>
                  <HStack>
                    <Text>{matching_type && `(${RequestMatchingTypeTrans(matching_type)})`}</Text>
                    <Text>요청 번호</Text>
                    <Text fontWeight={'normal'} lineHeight={1.2}>
                      {request_code}
                    </Text>
                  </HStack>
                  <HStack>
                    <Text>제출 일자</Text>
                    <Text fontWeight={'normal'} lineHeight={1.2}>
                      {datedotformat(request_createdAt)}
                    </Text>
                  </HStack>
                </HStack>
              </Box>
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <StyledSearch
              w={'full'}
              placeholder="회사/유저 이름과 주소로 검색이 가능합니다."
              inputProps={{
                onChange: (e) => {
                  setTypedSearch(e.target.value);
                },
              }}
            />
            <Flex flexDir={'column'} w={'100%'}>
              {!isUndefined(room_number_codes) && !isEmptyArray(room_number_codes) && (
                <HStack spacing={0} my={1}>
                  <Text>유저가 선택한 보홈 기존 매물의 방 번호 리스트:&nbsp;</Text>
                  <HStack divider={<StackDivider />}>
                    {room_number_codes.map((code, index) => (
                      <Text key={index + code}>{code}</Text>
                    ))}
                  </HStack>
                </HStack>
              )}
              <Box h={'60vh'}>
                <Scrollbars universal>
                  <Table variant="all_line_silver">
                    <Thead>
                      <Tr>
                        <Th w={'20px'}>
                          <Center>
                            <Checkbox
                              isChecked={allChecked}
                              isIndeterminate={isIndeterminate}
                              onChange={(e) => {
                                if (!e.target.checked) {
                                  setCheckedItems((prevArr) => {
                                    if (!isUndefined(currentAllRoomIds)) {
                                      return prevArr.filter(
                                        (item) =>
                                          !currentAllRoomIds.includes(
                                            item?.employee?.custom_room_request?.recommended_room?.room_id,
                                          ),
                                      );
                                    } else {
                                      return [];
                                    }
                                  });
                                } else {
                                  setCheckedItems((prevArr) => {
                                    if (!isUndefined(currentAllRoomIds) && !isUndefined(requestRoomsData)) {
                                      const notyetIncludeItems = requestRoomsData.filter(
                                        (elem) =>
                                          !checkedItemIds.includes(
                                            elem?.employee?.custom_room_request?.recommended_room?.room_id || '',
                                          ),
                                      );
                                      return prevArr.concat(notyetIncludeItems);
                                    }
                                    return [];
                                  });
                                }
                              }}
                            />
                          </Center>
                        </Th>
                        <Th colSpan={2}>
                          <HStack divider={<StackDivider borderColor="rgb(235, 240, 246)" />}>
                            <HStack>
                              {routeUserType == 'admin' ? (
                                <ConfirmRequestRoomsModal disabled={saveIsDisabled} requestRooms={checkedItems}>
                                  <Button
                                    variant={'basic'}
                                    style={{
                                      height: '27px',
                                    }}
                                    fontSize={'14px'}
                                    h={'27px'}
                                    p={2}
                                    borderRadius={'0.2rem'}
                                    isDisabled={saveIsDisabled}
                                  >
                                    전달하기
                                  </Button>
                                </ConfirmRequestRoomsModal>
                              ) : (
                                <></>
                              )}

                              {/* <RejectRequestRoomsDialog disabled={saveIsDisabled} requestRooms={checkedItems}>
                                <Button
                                  variant={'warning'}
                                  fontSize={'14px'}
                                  style={{
                                    height: '27px',
                                  }}
                                  p={2}
                                  borderRadius={'0.2rem'}
                                  isDisabled={saveIsDisabled}
                                >
                                  반려
                                </Button>
                              </RejectRequestRoomsDialog> */}
                            </HStack>
                            <MakeTourReservationModal disabled={saveIsDisabled} requestRooms={checkedItems}>
                              <Button
                                id="tour-reservation-button"
                                variant={'basic'}
                                style={{
                                  height: '27px',
                                }}
                                fontSize={'14px'}
                                p={2}
                                borderRadius={'0.2rem'}
                                isDisabled={saveIsDisabled}
                              >
                                투어예약
                              </Button>
                            </MakeTourReservationModal>
                            {routeUserType == 'admin' ? (
                              <CancleRecommendedRoomsModal disabled={saveIsDisabled} requestRooms={checkedItems}>
                                <Button
                                  id="tour-reservation-button"
                                  variant={'red'}
                                  style={{
                                    height: '27px',
                                  }}
                                  fontSize={'14px'}
                                  p={2}
                                  borderRadius={'0.2rem'}
                                  _hover={{
                                    bgColor: 'red.300',
                                  }}
                                  isDisabled={saveIsDisabled}
                                >
                                  전달취소
                                </Button>
                              </CancleRecommendedRoomsModal>
                            ) : (
                              <></>
                            )}
                          </HStack>
                        </Th>
                        <Th>상태</Th>
                        <Th>투어 일정</Th>
                        <Th>계약 일정</Th>
                        {routeUserType == 'admin' ? <Th>계약 확정</Th> : null}
                      </Tr>
                    </Thead>
                    <Tbody>
                      {requestRoomsData?.map((requestRoom, index) => {
                        const employee = requestRoom?.employee;
                        const custom_room_request = requestRoom?.employee?.custom_room_request;
                        const recommended_room = requestRoom?.employee?.custom_room_request?.recommended_room;
                        const matching_type = requestRoom?.employee?.custom_room_request?.matching_type;
                        const room = recommended_room?.room;
                        const admin_check_status = recommended_room?.admin_check_status;
                        const house_tour = recommended_room?.house_tour;
                        const contract_meeting = recommended_room?.contract_meeting;
                        const status = recommended_room?.status;

                        const roomRecommendationStatus = isRoomRecommendationStatus(admin_check_status);

                        if (_.isEmpty(room) || !room) {
                          return null;
                        }

                        const isChecked = checkedItems.some(
                          (item) => item?.employee?.custom_room_request?.recommended_room?.room_id === room._id,
                        );

                        const isAbleTourReservation = isEmptyObject(house_tour);
                        const isAbleContractReservation = [
                          'delivery_completed',
                          'tour_reservation',
                          'contracting',
                          'in_custom_room_request',
                        ].includes(status || '');
                        const isCompleteContractReservation =
                          !isEmptyObject(house_tour) && !isEmptyObject(contract_meeting);

                        const isAbleContractConfirm = [
                          'delivery_completed',
                          'tour_reservation',
                          'contracting',
                          'in_custom_room_request',
                        ].includes(status || '');

                        const isCompleteContractConfirm = ['contracted'].includes(status || '');

                        return (
                          <Tr key={(room?._id || '') + index} bgColor={isChecked ? '#ECF1FF' : 'unset'}>
                            <Td>
                              <Center>
                                <Checkbox
                                  isChecked={isChecked}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setCheckedItems((prevArr) => prevArr.concat(requestRoom));
                                    } else {
                                      setCheckedItems((prevArr) =>
                                        prevArr.filter(
                                          (elem) =>
                                            elem?.employee?.custom_room_request?.recommended_room?.room_id !== room._id,
                                        ),
                                      );
                                    }
                                  }}
                                />
                              </Center>
                            </Td>
                            <Td textAlign={'center'} fontSize={'14px'}>
                              <VStack>
                                <Tooltip label={'중개사'} placement="top" hasArrow>
                                  <Text>{recommended_room?.agent?.user_name}</Text>
                                </Tooltip>
                                {recommended_room.room.host && (
                                  <Tooltip label={'임대인'} placement="top" hasArrow>
                                    <Box>
                                      <TextLink
                                        target={'_blank'}
                                        href={`/admin/manage/customers/host/detail/info?id=${recommended_room.room.host._id}`}
                                      >
                                        {recommended_room.room.host.user_name}
                                      </TextLink>
                                    </Box>
                                  </Tooltip>
                                )}
                              </VStack>
                            </Td>
                            {/* 매물 정보 */}
                            <Td minW={'500px'} w="500px" p={1}>
                              <HStack spacing={4} justifyContent={'space-between'} w="full" mb={1}>
                                <HStack spacing={2} flexGrow={1}>
                                  <Image
                                    boxSize={'117px'}
                                    src={
                                      !isEmptyArray(room.pictures)
                                        ? room.pictures[0]
                                        : 'https://via.placeholder.com/117'
                                    }
                                    alt="이미지가 없습니다."
                                  />
                                  <VStack alignItems={'unset'} flexDir={'column'}>
                                    <Wrap fontSize={'11px'}>
                                      <Tag variant={'room_number'}>매물번호 {room.code}</Tag>
                                      {room.room_type && <Tag variant={'room_number'}>{room.room_type}</Tag>}
                                      {room.is_template && <Tag variant={'green'}>템플릿</Tag>}
                                      <Tag variant={room.is_move_in ? 'current_empty' : 'current_filled'} px={2}>
                                        {room.is_move_in ? '현재 공실' : '거래 완료'}
                                      </Tag>
                                      <AccessHiddenTag access_status={room.access_status} />
                                    </Wrap>
                                    <Flex w={'100%'} fontSize={'18px'} fontWeight={'medium'} flexDir={'column'}>
                                      <Flex flexWrap={'wrap'}>
                                        <Text minW={'max'}>{room?.building?.address}&nbsp;</Text>
                                        <Text>{room.dong && `${room.dong}동`}&nbsp;</Text>
                                        <Text>{room.ho && `${room.ho}호`}&nbsp;</Text>
                                        <Text>{`(${truncateLength(room?.building?.name)})`}&nbsp;</Text>
                                      </Flex>
                                    </Flex>
                                    <HStack
                                      w={'100%'}
                                      flexWrap={'wrap'}
                                      divider={<StackDivider borderColor={'gray.300'} />}
                                      fontSize={'14px'}
                                      spacing={2}
                                    >
                                      {room?.building?.building_form && (
                                        <Text>{buildingFormTypeTrans(room?.building?.building_form)}</Text>
                                      )}
                                      {room?.room_num && <Text>방{room?.room_num}개</Text>}
                                      {room?.dedicated_area && <Text>{room?.dedicated_area}m²</Text>}
                                      {room?.floor && <Text>{room?.floor}층</Text>}
                                      {room?.deposit && <Text>보증금 {staticThreeCommafy(room?.deposit)}원</Text>}
                                      {room?.rent && <Text>월세 {staticThreeCommafy(room?.rent)}원</Text>}
                                      {room?.management_fee && (
                                        <Text>관리비 {staticThreeCommafy(room?.management_fee)}원</Text>
                                      )}
                                    </HStack>
                                  </VStack>
                                </HStack>
                                {/* Notes minifier */}
                              </HStack>
                              {/* 메모 */}
                              <Memo userType="admin" editableAll={false} data={requestRoom} />
                            </Td>
                            {/* 상태 */}
                            <Td textAlign={'center'}>
                              <Tag
                                minW={'max'}
                                variant={
                                  recommended_room?.status === 'contracted'
                                    ? 'orange'
                                    : recommended_room?.status === 'moving_in'
                                    ? 'orange'
                                    : 'green'
                                }
                                fontWeight={'bold'}
                                fontSize={'13px'}
                                minWidth={'80px'}
                                justifyContent={'center'}
                              >
                                {requestStatusTrans(recommended_room?.status as any)}
                                {/* {roomRecommendationDeliver} */}
                              </Tag>
                            </Td>
                            <Td textAlign={'center'} fontSize={'11px'}>
                              {isAbleTourReservation ? (
                                <MakeTourReservationModal
                                  disabled={!isAbleTourReservation}
                                  requestRooms={[requestRoom]}
                                >
                                  <Button disabled={!isAbleTourReservation} variant={'basic'}>
                                    투어 예약
                                  </Button>
                                </MakeTourReservationModal>
                              ) : (
                                <>
                                  <Text>{datedetaildotformat_mm(house_tour?.start_date)}</Text>
                                  <Text>{house_tour?.location_description}</Text>
                                </>
                              )}
                            </Td>
                            <Td textAlign={'center'} fontSize={'11px'}>
                              {!isCompleteContractReservation ? (
                                <MakeContractReservationModal
                                  disabled={!isAbleContractReservation}
                                  requestRooms={[requestRoom]}
                                >
                                  <Button disabled={!isAbleContractReservation} variant={'basic'}>
                                    계약 예약
                                  </Button>
                                </MakeContractReservationModal>
                              ) : (
                                <>
                                  <Text>{datedetaildotformat_mm(contract_meeting?.start_date)}</Text>
                                  <Text>{contract_meeting?.location_description}</Text>
                                </>
                              )}
                            </Td>
                            {routeUserType == 'admin' ? (
                              <Td textAlign={'center'} fontSize={'11px'}>
                                <ConfirmContractModal
                                  disabled={!isAbleContractConfirm}
                                  requestRoom={requestRoom as any}
                                >
                                  <Button disabled={!isAbleContractConfirm} variant={'basic'}>
                                    계약 완료
                                  </Button>
                                </ConfirmContractModal>
                              </Td>
                            ) : (
                              <></>
                            )}
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                </Scrollbars>
              </Box>
            </Flex>
          </ModalBody>
          <Box pos={'absolute'} right={10} bottom={10}>
            <Box>
              <RecommendRoomsModal
                room_request_id={room_request_id}
                user_name={user_name}
                company_name={company_name}
                request_code={request_code}
                request_createdAt={request_createdAt}
              >
                <Box
                  bgColor={'zetaBlue'}
                  color={'white'}
                  fontSize={'24px'}
                  px={5}
                  py={2}
                  borderRadius={'full'}
                  boxShadow={'lg'}
                  cursor={'pointer'}
                  userSelect={'none'}
                >
                  <HStack>
                    <Text display={'inline'} fontSize={'36px'} fontWeight={'extrabold'}>
                      +
                    </Text>
                    <Text>매물추가</Text>
                  </HStack>
                </Box>
              </RecommendRoomsModal>
            </Box>
          </Box>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CustomRoomRequestModal;

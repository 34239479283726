import { Text, Link, TextProps, LinkProps } from '@chakra-ui/react';
import { Link as RouterLink, To } from 'react-router-dom';

interface TextLinkProps extends LinkProps {
  to?: To;
  href?: string;
  target?: React.HTMLAttributeAnchorTarget;
}

const TextLink: React.FC<TextLinkProps> = ({ children, to, href, target, ...props }) => {
  if (to) {
    return (
      <Link
        as={RouterLink}
        to={to}
        textDecorationLine={'underline'}
        textUnderlineOffset={'2px'}
        cursor={'pointer'}
        color={'#414141'}
        _hover={{}}
        {...props}
      >
        {children}
      </Link>
    );
  }
  if (href) {
    return (
      <Link
        href={href}
        target={target}
        textDecorationLine={'underline'}
        textUnderlineOffset={'2px'}
        cursor={'pointer'}
        color={'#414141'}
        _hover={{}}
        {...props}
      >
        {children}
      </Link>
    );
  }
  return (
    <Text textDecorationLine={'underline'} textUnderlineOffset={'2px'} cursor={'pointer'} color={'#414141'} _hover={{}}>
      {children}
    </Text>
  );
};

export default TextLink;

import { Box, Button, Center, Flex, HStack, Tag, Text, Tooltip, VStack } from '@chakra-ui/react';
import QueryParamsLayout from '../main/QueryParamsLayout';
import { useGetCustomRoomRequests } from '../../../lib/common/hooks/query';
import {
  isRoomRecommendationDelivered,
  roomLivingTypeTrans,
  roomNumberTrans,
  roomRequestDateFormat,
} from '../../../lib/common/utils/utils';
import {
  currentParamsObject,
  requestStatusTrans,
  ToAllFilledObj,
  TodayYesterdayFormat,
} from '../../../lib/common/utils/base';
import { staticThreeCommafy } from '../../../lib/common/utils/digitformatter';
import RequestRoomsModal from '../../../components/common/Modals/CustomRoomRequestModal';
import _ from 'lodash';
import Loading from '../../../components/common/Loading';
import * as Util from '../../../lib/common/utils/Util';
import * as Type from '../../../types/common/CommonType';
import * as S from '../../../theme/common/styles';
import CustomRoomRequestDetail from '../../../layouts/common/CustomRoomRequestDetail';
import { useState } from 'react';

const CustomRoomRequests = () => {
  const _params = currentParamsObject();
  const params = ToAllFilledObj(_params);
  const res = useGetCustomRoomRequests({
    axiosConfig: {
      params,
    },
  });
  const [id, setId] = useState<string | undefined>();
  const { isLoading } = res;
  if (isLoading) return <Loading />;
  const defaultId = res.data?.data?.[0]?.employee?.custom_room_request?._id;
  // setId(defaultId);

  return (
    <QueryParamsLayout res={res}>
      <Flex>
        <VStack align={'start'} pb={2}>
          {res.data?.result.map((elem, index) => {
            const {
              employee: {
                custom_room_request: {
                  _id: custom_room_request_id,
                  createdAt,
                  updatedAt,
                  end_date,
                  matching_type,
                  status,
                  code,
                  room_number_codes,
                  tenant_id,
                },
                user_name,
                service,
              },
              name: cpName,
            } = elem;

            const roomRequestsDate = roomRequestDateFormat(createdAt || '', end_date || '');

            return (
              <HStack key={index + (custom_room_request_id || '')} pos={'relative'} layerStyle={'request_line_tab'}>
                <HStack>
                  <HStack
                    pos={'absolute'}
                    bgColor={'lotionWhite'}
                    py={1}
                    borderRight={'1px solid'}
                    borderRightColor={'gray.200'}
                  >
                    {roomRequestsDate ? (
                      <Tag
                        minW={'80px'}
                        variant={
                          roomRequestsDate === '신규 요청'
                            ? 'new_request'
                            : roomRequestsDate === '종료 예정'
                            ? 'end_expected_request'
                            : ''
                        }
                      >
                        <Center w="full">
                          <Text>{roomRequestsDate}</Text>
                        </Center>
                      </Tag>
                    ) : (
                      <Box w={`80px`} />
                      // <Box />
                    )}

                    {service?.check_status ? (
                      <Tag
                        minW={'80px'}
                        variant={
                          service?.check_status === 'pending'
                            ? 'after_approved_service'
                            : service?.check_status === 'approved'
                            ? 'after_approved_service'
                            : service?.check_status === 'cancelled'
                            ? 'end_expected_request'
                            : service?.check_status === 'rejected'
                            ? 'before_approved_service'
                            : service?.check_status === 'withdrawn'
                            ? 'before_approved_service'
                            : ''
                        }
                      >
                        <Center w="full">
                          <Text>
                            {service?.check_status === 'pending'
                              ? '신청서 보기'
                              : service?.check_status === 'approved'
                              ? '승인'
                              : service?.check_status === 'cancelled'
                              ? '취소'
                              : service?.check_status === 'rejected'
                              ? '반려'
                              : service?.check_status === 'withdrawn'
                              ? '철회'
                              : ''}
                          </Text>
                        </Center>
                      </Tag>
                    ) : (
                      <Box />
                    )}

                    <RequestRoomsModal
                      room_request_id={custom_room_request_id || ''}
                      room_number_codes={room_number_codes}
                      user_name={user_name}
                      company_name={cpName}
                      request_code={code}
                      request_createdAt={createdAt || ''}
                      tenant_id={tenant_id}
                      matching_type={matching_type}
                    >
                      <Text
                        maxW={'180px'}
                        w={'180px'}
                        isTruncated
                        textDecor={'underline'}
                        textUnderlineOffset={2}
                        fontSize={'14px'}
                        cursor={'pointer'}
                      >
                        {user_name} ({cpName})
                      </Text>
                    </RequestRoomsModal>
                  </HStack>
                  <Box w={`${360}px`} />
                  <Box pos={'relative'} bgColor={'gray.200'} p={1} borderRadius={'md'} boxShadow={'md'}>
                    <Center>
                      <Text fontSize={'11px'} minW={'98.48px'} mr={2}>
                        {TodayYesterdayFormat(updatedAt || '')} 업데이트
                      </Text>
                      <Tag
                        minW={'max'}
                        variant={status === 'contracted' ? 'orange' : status === 'moving_in' ? 'orange' : 'green'}
                        fontWeight={'bold'}
                        fontSize={'13px'}
                        minWidth={'80px'}
                        justifyContent={'center'}
                      >
                        {requestStatusTrans(status)}
                      </Tag>
                    </Center>
                  </Box>
                  <Box />
                  <Center>
                    <Button
                      onClick={() => {
                        setId(elem?.employee?.custom_room_request?._id);
                      }}
                    >
                      상세
                    </Button>
                  </Center>
                </HStack>
              </HStack>
            );
          })}
        </VStack>
        <Flex sx={{ ...S.CustomRoomRequestDetailBorderBox, flexGrow: 1 }}>
          <CustomRoomRequestDetail room_request_id={id || defaultId}></CustomRoomRequestDetail>
        </Flex>
      </Flex>
    </QueryParamsLayout>
  );
};
export default CustomRoomRequests;

import { Box, Button, Center, Flex, HStack, Spinner, Text, useToast, VStack } from '@chakra-ui/react';
import { CustomInput } from '../../../../components/common/Input';
import { reauthenticateWithCredential, EmailAuthProvider } from 'firebase/auth';
import { auth } from '../../../../lib/common/utils/firebase/init';
import { useEffect, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { AuthSuccess, overTried, password_notFillIn, password_notMatched } from '../../../../statics/common/data/toast';
import { FirebaseError } from 'firebase/app';
import { LockClosedIcon, LockIcon } from '../../../../statics/common/icons';
import { getWithExpiry, setWithExpiry } from '../../../../lib/common/utils/localstorage';

const InfosPage = () => {
  const toast = useToast();
  const navigate = useNavigate();

  const ispasswordRechecked = getWithExpiry('passwordRechecked');

  useEffect(() => {
    if (ispasswordRechecked) {
      navigate('/company/infos/selection');
    }
  }, []);

  if (ispasswordRechecked) {
    return (
      <Center w={'full'} h={'full'}>
        <Spinner />
      </Center>
    );
  } else {
    return (
      <Center w="full" h="90%">
        <Flex
          flexDir={'column'}
          bg="white"
          borderRadius={'lg'}
          boxShadow={'2px 2px 20px 0px #DDDDDD'}
          w={'500px'}
          h="430px"
          alignItems={'flex-start'}
        >
          <Box
            w={'full'}
            borderBottomColor={'borderGray'}
            boxShadow={'rgba(0, 0, 0, 0.02) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 2px 3px -2px'}
            //   px={5}
            py={12}
          >
            <HStack justifyContent={'center'}>
              <LockIcon w={6} h={6} />
              <Text fontWeight={'bold'} fontSize={'24px'}>
                로그인 비밀번호 재입력
              </Text>
            </HStack>
          </Box>
          <VStack w="full" h="full" spacing={0}>
            <Text fontSize={'18px'} fontWeight={'medium'} my={10}>
              민감한 개인정보 보호를 위해 요청드리고 있어요.
            </Text>
            <Formik
              initialValues={{ password: '' }}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
                const { password } = values;
                const user = auth.currentUser;
                if (!user) {
                  //   return null;
                  return undefined;
                }
                const email = user?.email;
                if (password === '') {
                  toast(password_notFillIn);
                  return undefined;
                }
                const credential = EmailAuthProvider.credential(email ? email : 'wrong', password);
                return reauthenticateWithCredential(user, credential)
                  .then(() => {
                    setWithExpiry('passwordRechecked', true, 1000 * 60 * 30);
                  })
                  .then(() => {
                    toast({ ...AuthSuccess, position: 'bottom-right' });
                    navigate('/company/infos/selection', {
                      replace: true,
                    });
                  })
                  .catch((error: FirebaseError) => {
                    if (error.code.includes('too-many-requests')) {
                      toast(overTried);
                    } else if (error.code.includes('wrong-password')) {
                      toast(password_notMatched);
                    } else {
                      console.log('알 수 없는 오류');
                    }
                  })
                  .finally(() => {
                    setSubmitting(false);
                  });
              }}
            >
              {({ isSubmitting }) => (
                <Form style={{ width: '100%' }}>
                  <VStack w="full" h="full" px={20} spacing={5}>
                    <Field
                      name="password"
                      as={CustomInput}
                      placeholder="비밀번호를 입력하세요."
                      _placeholder={{ fontSize: '13px', color: 'highGray' }}
                      fontSize={'13px'}
                      h={'40px'}
                      boxShadow={'0px 2px 2px 0px #00000033'}
                      type={'password'}
                    />
                    <Button
                      type="submit"
                      variant={'basic'}
                      w="100%"
                      style={{ height: '40px' }}
                      boxShadow={'0px 2px 2px 0px #00000033'}
                      isLoading={isSubmitting}
                    >
                      확인
                    </Button>
                  </VStack>
                </Form>
              )}
            </Formik>
          </VStack>
        </Flex>
      </Center>
    );
  }
};

export default InfosPage;

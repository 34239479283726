import {
  Box,
  Button,
  Center,
  Collapse,
  Flex,
  HStack,
  Table,
  Tbody,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { Field, useFormikContext } from 'formik';
import Dropzone from 'react-dropzone';
import { initialValuesProps } from '.';
import ValidationWithTouch from '../../FormikValidation/ValidationWithTouch';
import { CustomInput } from '../../Input';
import AddressFinder from '../AddressFinder';
import { TrContainer } from '../../Table/Tr';
import AddAccountInfosModal from '../AddAccountModal';

export const TwoSimpleRegister = () => {
  const formik = useFormikContext<initialValuesProps>();
  const { values, errors, touched, setFieldValue, isSubmitting, handleChange } = formik;
  const { isOpen: isOpenAddress, onOpen: onOpenAddress, onClose: onCloseAddress } = useDisclosure();

  const isAbleAddAccountInfos = true;

  const addedAccountNum = values.accounts.filter((account) => account?.is_added).length;

  // const isAble

  return (
    <>
      <VStack w="full" spacing={4}>
        {/* 1 */}
        <Flex flexDir={'column'} fontWeight={'bold'} w="full">
          <Box mb={1}>
            <Text>회원 정보</Text>
          </Box>
          <Table w={'100%'}>
            <Tbody w={'100%'}>
              <TrContainer title="이름" textStyle={'medium14'}>
                <Field name="user_name" as={CustomInput} autoFocus placeholder="임대인명을 입력해 주세요."></Field>
                <ValidationWithTouch error={errors.user_name} touch={touched.user_name} />
              </TrContainer>
              <TrContainer title="휴대폰번호" textStyle={'medium14'}>
                <Field
                  name="phone_number_first"
                  as={CustomInput}
                  type={'tel'}
                  placeholder="'-'를 제외하고 입력해 주세요."
                ></Field>
                <ValidationWithTouch error={errors.phone_number_first} touch={touched.phone_number_first} />
              </TrContainer>
              {/* <TrContainer title="이메일" textStyle={'medium14'}>
              <Field name="email" as={CustomInput} type={'tel'} placeholder="'-'를 제외하고 입력해 주세요."></Field>
              <ValidationWithTouch error={errors.email} touch={touched.email} />
            </TrContainer> */}
            </Tbody>
          </Table>
        </Flex>
        {/* 2 */}
        <Flex flexDir={'column'} fontWeight={'bold'} w="full">
          <Box mb={1}>
            <Text>회원 정보</Text>
          </Box>
          <Table w={'100%'}>
            <Tbody w={'100%'}>
              <TrContainer title="주민등록등본" textStyle={'medium14'}>
                <Dropzone
                  accept={'image/*'}
                  onDrop={(acceptedFiles) => {
                    if (acceptedFiles.length === 0) {
                      return;
                    } else {
                      setFieldValue('resident_registers', values.resident_registers.concat(acceptedFiles));
                    }
                  }}
                >
                  {({ getRootProps, getInputProps }) => {
                    return (
                      <Flex flexDir={'column'} w={'full'}>
                        <Center {...getRootProps()} w={'full'}>
                          <input {...getInputProps()} />
                          <Center
                            bgColor={'lotionWhite'}
                            w={'full'}
                            h={'40px'}
                            borderRadius={'5px'}
                            borderWidth={'1px'}
                            borderColor={'silverGray'}
                            cursor="pointer"
                            fontWeight={'medium'}
                            _hover={{ bgColor: 'zetaBlue', color: 'white' }}
                            transition={'0.18s linear'}
                          >
                            <Box fontSize={'14px'} fontWeight={'bold'}>
                              첨부 파일을 추가해주세요.
                            </Box>
                          </Center>
                        </Center>
                        <Collapse style={{ width: '100%' }} in={values.resident_registers.length !== 0}>
                          <HStack mt={2}>
                            <Box fontSize={'14px'} fontWeight={'normal'}>
                              {values.resident_registers.length + '개의 파일 첨부 완료'}
                            </Box>
                          </HStack>
                        </Collapse>
                      </Flex>
                    );
                  }}
                </Dropzone>
                <ValidationWithTouch error={!!errors.resident_registers} touch={!!touched.resident_registers} />
              </TrContainer>
              <TrContainer title="주민등록번호" textStyle={'medium14'}>
                <HStack>
                  <Field name="registration_number_front" as={CustomInput} placeholder="6자리" />
                  <Field name="registration_number_back" as={CustomInput} placeholder="7자리" />
                </HStack>
              </TrContainer>
              <TrContainer title="소재지" textStyle={'medium14'}>
                {/* <CustomInput onFocus={} /> */}
                <VStack spacing={1}>
                  <Field
                    name="address"
                    as={CustomInput}
                    placeholder="기본 주소를 입력해 주세요."
                    onChange={() => true}
                    onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
                      onOpenAddress();
                      e.currentTarget.blur();
                    }}
                    onClick={onOpenAddress}
                    cursor={'pointer'}
                    autoComplete="off"
                  />
                  <Field name="detail_address" as={CustomInput} placeholder="상세 주소를 입력해 주세요." />
                </VStack>
                {/* <ValidationWithTouch error={errors.address} touch={touched.address} /> */}
              </TrContainer>
              <TrContainer title="출금계좌 사본" textStyle={'medium14'}>
                <Dropzone
                  accept={'image/*'}
                  onDrop={(acceptedFiles) => {
                    if (acceptedFiles.length === 0) {
                      return;
                    } else {
                      setFieldValue('account_copies', values.account_copies.concat(acceptedFiles));
                    }
                  }}
                >
                  {({ getRootProps, getInputProps }) => {
                    return (
                      <Flex flexDir={'column'} w={'full'}>
                        <Center {...getRootProps()} w={'full'}>
                          <input {...getInputProps()} />
                          <Center
                            bgColor={'lotionWhite'}
                            w={'full'}
                            h={'40px'}
                            borderRadius={'5px'}
                            borderWidth={'1px'}
                            borderColor={'silverGray'}
                            cursor="pointer"
                            fontWeight={'medium'}
                            _hover={{ bgColor: 'zetaBlue', color: 'white' }}
                            transition={'0.18s linear'}
                          >
                            <Box fontSize={'14px'} fontWeight={'bold'}>
                              첨부 파일을 추가해주세요.
                            </Box>
                          </Center>
                        </Center>
                        <Collapse style={{ width: '100%' }} in={values.account_copies.length !== 0}>
                          <HStack mt={2}>
                            <Box fontSize={'14px'} fontWeight={'normal'}>
                              {values.account_copies.length + '개의 파일 첨부 완료'}
                            </Box>
                          </HStack>
                        </Collapse>
                      </Flex>
                    );
                  }}
                </Dropzone>
                <ValidationWithTouch error={!!errors.account_copies} touch={!!touched.account_copies} />
              </TrContainer>
            </Tbody>
          </Table>
          {/* Modal */}
          <AddressFinder isOpen={isOpenAddress} onClose={onCloseAddress} formik={formik} />
        </Flex>
        {/* 3 */}
        <Flex flexDir={'column'} fontWeight={'bold'} w="full">
          <Box mb={1}>
            <Text>계좌 정보</Text>
          </Box>
          <Table w={'100%'}>
            <Tbody w={'100%'}>
              <TrContainer title="계좌" textStyle={'medium14'}>
                <AddAccountInfosModal disabled={!isAbleAddAccountInfos}>
                  <Button variant={'basic'} disabled={!isAbleAddAccountInfos}>
                    새 계좌 추가
                  </Button>
                </AddAccountInfosModal>

                <Collapse style={{ width: '100%' }} in={addedAccountNum !== 0}>
                  <HStack mt={2}>
                    <Box fontSize={'14px'} fontWeight={'normal'}>
                      {addedAccountNum + '개의 계좌 추가 완료'}
                    </Box>
                  </HStack>
                </Collapse>
                {/* <ValidationWithTouch error={errors.accounts} touch={touched.accounts} /> */}
              </TrContainer>
            </Tbody>
          </Table>
        </Flex>
      </VStack>
      <Center pt={10}>
        <Button variant={'basic'} w="200px" minH="44px" type="submit" isLoading={isSubmitting}>
          등록 완료
        </Button>
      </Center>
    </>
  );
};

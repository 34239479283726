import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Table,
  Tbody,
  Text,
  Textarea,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { Field, Form, Formik } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';
import { GTitleItem } from '../../GridItem';
import { CustomInput } from '../../Input';
import { TdFullWContainer } from '../../Table/Tr';
import { useConfirmCompany } from '../../../../lib/admin/hooks/mutation';
import { CompanyContract, CompanyManager } from '../../../../lib/common/utils/axios/types';
import {
  paymentMethodTypeTrans,
  paymentPeriodTrans,
  requesTypeTrans,
  subscriptionTypeTrans,
} from '../../../../lib/subscriber/utils/utils';
import { certificate_issue_type_object } from '../../../../statics/common/data/selectbox';
import { datedotformat } from '../../../../lib/common/utils/dateformatter';
import { ClickIconContainer, LeftArrowIcon } from '../../../../statics/common/icons';

interface ConfirmApproveCompanyWithTableModalProps {
  company_manager_data: CompanyContract;
}

const ConfirmApproveCompanyWithTableModal: React.FC<ConfirmApproveCompanyWithTableModalProps> = ({
  children,
  company_manager_data,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    name,
    _id,
    subscription_contract: {
      contract_created_date,
      contract_start_date,
      request_type,
      available_user_count,
      subscription_type,
      payment_method_type,
      is_year,
      certificate_issue_type,
    },
  } = company_manager_data;
  const { mutateAsync } = useConfirmCompany(_id);

  const [isRejectClicked, setIsRejectClicked] = useState<boolean>(false);
  const [rejectedReason, setRejectedReason] = useState<string>('');

  return (
    <>
      <Box onClick={onOpen}>{children}</Box>

      <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside" size={'xl'} motionPreset="scale" isCentered>
        <ModalOverlay />
        <Formik
          initialValues={
            {
              // rooms: rooms.map((room) => {
              //   return {
              //     _id: room.room_id,
              //     admin_relay_memo: room.admin_secret_memo,
              //   };
              // }),
            }
          }
          onSubmit={(values, { setSubmitting }) => {
            // mutateAsync({
            //   rooms: values.rooms,
            // }).then(() => {
            //   onClose();
            // });
          }}
          validationSchema={Yup.object().shape({})}
        >
          {({ values, errors, isValid, handleChange, setFieldValue }) => {
            console.log('values => ', values);
            return (
              // <Form style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>

              <Form>
                <ModalContent>
                  <ModalCloseButton autoFocus />
                  <GTitleItem
                    upperSection={
                      <Flex justifyContent={'space-between'} alignItems={'center'} cursor={'pointer'}>
                        <Text fontSize={'18px'} fontWeight={'medium'}>
                          이용 신청 현황
                        </Text>
                      </Flex>
                    }
                    boxShadow={{}}
                  >
                    <Box p={5} w="full">
                      <Box fontWeight={400} fontSize={'16px'} textAlign={'start'} mb={2}>
                        구독 플랜
                      </Box>
                      <Table w={'100%'} variant="simple">
                        <Tbody w={'100%'}>
                          <Tr w={'100%'} borderTopWidth={'1px'} borderTopColor={'gainGray'}>
                            <TdFullWContainer title={'신청일'}>
                              <Text>{datedotformat(contract_created_date)}</Text>
                            </TdFullWContainer>
                          </Tr>
                          <Tr>
                            <TdFullWContainer title={'구분'}>
                              <Text>{requesTypeTrans(request_type)}</Text>
                            </TdFullWContainer>
                          </Tr>
                          <Tr>
                            <TdFullWContainer title={'플랜 종류/이용 인원'}>
                              <Text>
                                {subscriptionTypeTrans(subscription_type)} / {available_user_count}인
                              </Text>
                            </TdFullWContainer>
                          </Tr>
                          <Tr>
                            <TdFullWContainer title={'결제 방식'}>
                              <Text>{paymentPeriodTrans(is_year)}</Text>
                            </TdFullWContainer>
                          </Tr>
                          <Tr>
                            <TdFullWContainer title={'결제 수단/증빙 수단'}>
                              <Text>
                                {paymentMethodTypeTrans(payment_method_type)} /{' '}
                                {certificate_issue_type_object[certificate_issue_type]}
                              </Text>
                            </TdFullWContainer>
                          </Tr>
                        </Tbody>
                      </Table>
                    </Box>
                  </GTitleItem>
                </ModalContent>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

export default ConfirmApproveCompanyWithTableModal;

import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Image,
  Modal,
  ModalContent,
  ModalOverlay,
  Tag,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import Scrollbars from 'react-custom-scrollbars-2';
import { ModalDisclosureProps } from '../../common/Modals/base';
import SelectBox from '../SelectBox/base';
import { CheckCircleIcon } from '../../../statics/common/icons';
import { WBox } from '../../common/Box';

const RoomSuggestSender: React.FC<ModalDisclosureProps> = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside" size={'2xl'} motionPreset="slideInRight">
      <ModalOverlay />
      <ModalContent p={5} h={'720px'}>
        <Center flexDir={'column'} m={10} alignItems={'flex-start'}>
          <HStack>
            <CheckCircleIcon w={8} h={8} />
            <Text fontSize={'23px'}>매물 제안 메시지</Text>
          </HStack>
          <VStack fontSize={'20px'} flexDir={'column'} my={4} spacing={0} alignItems={'flex-start'}>
            <Box>{`보홈의 검토 과정을 거쳐 ${'김한별'}님에게 매물이 전달됩니다.`}</Box>
            <Box>매물과 함께 전달하실 메세지를 작성해주세요.</Box>
          </VStack>
          {/* 선택된 매물 리스트 */}
          <Scrollbars
            autoHide
            universal
            style={{
              height: '380px',
            }}
          >
            <VStack w={'full'}>
              {/* <Box>List 1</Box> */}
              <WBox w={'full'}>
                <HStack w={'full'} p={3} spacing={3}>
                  <Image boxSize={'92px'} src={'https://via.placeholder.com/120'} alt={'room-thumnail'} />
                  <VStack spacing={1} w={'full'}>
                    <Flex w={'full'} justifyContent={'space-between'}>
                      {/* 주소 */}
                      <Box fontSize={'14px'}>{'서울시 구로구 구로동 123-1 (행복빌라) 101호'}</Box>
                      {/* 매물번호 */}
                      <Tag variant={'room_number'}>매물번호 {'1234567'}</Tag>
                    </Flex>
                    <Textarea
                      borderWidth={'1px'}
                      borderColor={'silverGray'}
                      placeholder="함께 전달하실 메세지를 작성해주세요."
                      fontSize="12px"
                      _placeholder={{
                        color: 'silverGray',
                      }}
                      resize={'none'}
                      _hover={{
                        borderColor: 'blue.500',
                        cursor: 'text',
                      }}
                    ></Textarea>
                  </VStack>
                </HStack>
              </WBox>
              {/* <Box>List 2</Box> */}
              <WBox w={'full'}>
                <HStack w={'full'} p={3} spacing={3}>
                  <Image boxSize={'92px'} src={'https://via.placeholder.com/120'} alt={'room-thumnail'} />
                  <VStack spacing={1} w={'full'}>
                    <Flex w={'full'} justifyContent={'space-between'}>
                      {/* 주소 */}
                      <Box fontSize={'14px'}>{'서울시 구로구 구로동 123-1 (행복빌라) 101호'}</Box>
                      {/* 매물번호 */}
                      <Tag variant={'room_number'}>매물번호 {'1234567'}</Tag>
                    </Flex>
                    <Textarea
                      borderWidth={'1px'}
                      borderColor={'silverGray'}
                      placeholder="함께 전달하실 메세지를 작성해주세요."
                      fontSize="12px"
                      _placeholder={{
                        color: 'silverGray',
                      }}
                      resize={'none'}
                      _hover={{
                        borderColor: 'blue.500',
                        cursor: 'text',
                      }}
                    ></Textarea>
                  </VStack>
                </HStack>
              </WBox>
              {/* <Box>List 3</Box> */}
              <WBox w={'full'}>
                <HStack w={'full'} p={3} spacing={3}>
                  <Image boxSize={'92px'} src={'https://via.placeholder.com/120'} alt={'room-thumnail'} />
                  <VStack spacing={1} w={'full'}>
                    <Flex w={'full'} justifyContent={'space-between'}>
                      {/* 주소 */}
                      <Box fontSize={'14px'}>{'서울시 구로구 구로동 123-1 (행복빌라) 101호'}</Box>
                      {/* 매물번호 */}
                      <Tag variant={'room_number'}>매물번호 {'1234567'}</Tag>
                    </Flex>
                    <Textarea
                      borderWidth={'1px'}
                      borderColor={'silverGray'}
                      placeholder="함께 전달하실 메세지를 작성해주세요."
                      fontSize="12px"
                      _placeholder={{
                        color: 'silverGray',
                      }}
                      resize={'none'}
                      _hover={{
                        borderColor: 'blue.500',
                        cursor: 'text',
                      }}
                    ></Textarea>
                  </VStack>
                </HStack>
              </WBox>
            </VStack>
          </Scrollbars>
          <Flex justifyContent={'center'} w={'100%'} borderBottom={'1px solid'} borderBottomColor={'gray.400'} mt={1} />
          <Center w="full">
            <Button mt={'30px'} variant={'basic'} w={'140px'} fontSize={'14px'} fontWeight={'bold'}>
              제안 완료
            </Button>
          </Center>
        </Center>
      </ModalContent>
    </Modal>
  );
};

export default RoomSuggestSender;

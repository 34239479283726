import { Center, Spinner, Text } from '@chakra-ui/react';
import { useCallback, useEffect, useMemo } from 'react';

import { RouteProps, useNavigate, Outlet } from 'react-router-dom';
import { auth } from '../../../lib/common/utils/firebase/init';
import { getWithExpiry } from '../../../lib/common/utils/localstorage';

const PasswordRecheckedRoute = () => {
  const navigate = useNavigate();

  const ispasswordRechecked = getWithExpiry('passwordRechecked');

  useEffect(() => {
    if (!ispasswordRechecked) {
      navigate('/company/infos');
    }
  }, []);

  if (!ispasswordRechecked) {
    return null;
  }
  return <Outlet />;
};

export default PasswordRecheckedRoute;

import { useToast } from '@chakra-ui/react';
import axios, { AxiosResponse } from 'axios';
import { useMutation, UseMutationResult } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { queryClient } from '../../..';
import { error, success, verifyAccountFail, verifyAccountSuccess } from '../../../statics/common/data/toast';
import { fetchClient } from '../utils/axios';
import { useGetUserPathandKey } from './query';
import { saveFail, saveSuccess } from '../../../statics/common/data/toast';

import * as KeyPath from '../../../statics/common/data/KeyPath';
import * as Type from '../../../types/common/CommonType';
import * as Util from '../../../lib/common/utils/Util';
import { MuteCreateSubscriptionPaymentOfSupportPayment } from '../../admin/hooks/mutation';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../utils/firebase/init';
import { async } from '@firebase/util';

export const useUpdateNotificationsCheck = () => {
  const [user, loading, error] = useAuthState(auth);
  const toast = useToast();
  const { data: ud } = useGetUserPathandKey();
  const toast_id = 'toast_id';
  return useMutation(
    async () => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      return await instance.post(`${ud?.path}/notifications/check`);
    },
    {
      onSuccess: () => {
        if (!toast.isActive(toast_id)) {
          toast({ ...success, id: toast_id, title: '모두 읽음 처리 완료', description: '' });
        }
        queryClient.invalidateQueries([KeyPath.not_checked_count_key]);
        queryClient.invalidateQueries([KeyPath.notifications_list_key]);
      },
    },
  );
};

interface MuteVerifyAccountProps {
  account_number: string;
  account_owner_name: string;
  bank_code: number | string;
}

export const useVerifyAccount = () => {
  const [user, loading, error] = useAuthState(auth);
  const toast = useToast();

  return useMutation(
    async (mute: MuteVerifyAccountProps) => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      return await instance.post(`/common/wp/account/verify`, mute);
    },

    {
      onSuccess: (data) => {
        const verified = (data as any).data.data.is_account_verified;
        if (verified) {
          toast(verifyAccountSuccess);
        } else {
          toast(verifyAccountFail);
        }
      },
      onError: () => {
        toast(verifyAccountFail);
      },
    },
  );
};

interface MuteRemoveFurnitures_AIVoucherProps {
  formData: FormData;
}

export const useRemoveFurnitures_AIVoucher = () => {
  const [user, loading, error] = useAuthState(auth);
  const toast = useToast();
  const { data: ud } = useGetUserPathandKey();

  return useMutation(
    async (mute: MuteRemoveFurnitures_AIVoucherProps) => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      return await instance.post(`${ud?.path}/furniture-removed-picture`, mute.formData);
    },
    {
      onSuccess: (data) => {
        toast({ ...success, description: '성공적으로 매물 사진에서 가구를 제거했습니다.' });
      },
      onError: (err) => {
        const error_data = JSON.stringify(err);
        toast({ ...error, description: error_data });
      },
    },
  );
};

interface MuteUpdateRoomProps {
  room_id: string;
  formData: FormData;
}

export const useUpdateRoom = (): Type.MutationResult<Type.Building> => {
  const [user, loading, error] = useAuthState(auth);
  const toast = useToast();
  const { data: ud } = useGetUserPathandKey();

  return useMutation(
    async (mute: MuteUpdateRoomProps) => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      return await instance.patch(`${ud?.path}/rooms/${mute.room_id}`, mute.formData);
    },
    {
      onSuccess: () => {
        toast(saveSuccess);
      },
      onError: () => {
        toast(saveFail);
      },
    },
  );
};

interface MuteCreateBuildingProps {
  formData: FormData;
}

export const useCreateBuilding = (): Type.MutationResult<Type.Building> => {
  const [user, loading, error] = useAuthState(auth);
  const toast = useToast();
  const { data: ud } = useGetUserPathandKey();

  return useMutation(
    async (mute: MuteCreateBuildingProps) => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      return await instance.post(`${ud?.path}/building`, mute.formData);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([]);
        toast({ ...saveSuccess, description: '건물이 생성 되었습니다.' });
      },
      onError: () => {
        toast({ ...saveFail, description: '건물 생성에 실패했습니다.' });
      },
    },
  );
};

interface MuteUpdateBuildingProps {
  formData: FormData;
  building_id: string;
}

export const useUpdateBuilding = (): Type.MutationResult<Type.Building> => {
  const [user, loading, error] = useAuthState(auth);
  const toast = useToast();
  const { data: ud } = useGetUserPathandKey();

  return useMutation(
    async (mute: MuteUpdateBuildingProps) => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      return await instance.patch(`${ud?.path}/buildings/${mute.building_id}`, mute.formData);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
        toast({ ...saveSuccess, description: '건물 정보를 업데이트하였습니다.' });
      },
      onError: () => {
        toast({ ...saveFail, description: '건물 정보 업데이트에 실패하였습니다.' });
      },
    },
  );
};

interface MuteUploadFilesProps {
  formData: FormData;
}

export const useUploadFiles = () => {
  const [user, loading, error] = useAuthState(auth);
  const toast = useToast();
  const { data: ud } = useGetUserPathandKey();

  return useMutation(
    async (mute: MuteUploadFilesProps) => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      return await instance.post(`${ud?.path}/pictures`, mute.formData);
    },
    {
      onSuccess: () => {
        toast({ ...saveSuccess });
      },
      onError: () => {
        toast({ ...saveFail });
      },
    },
  );
};

interface MuteCreateRoomInBuildingProps {
  building_id: string;
  formData: FormData;
}

export const useCreateRoomInBuilding = (): Type.MutationResult<Type.Building> => {
  const [user, loading, error] = useAuthState(auth);
  const toast = useToast();
  const { data: ud } = useGetUserPathandKey();

  return useMutation(
    async (mute: MuteCreateRoomInBuildingProps) => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      return await instance.post(`${ud?.path}/buildings/${mute.building_id}/room`, mute.formData);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
        toast({ ...saveSuccess, description: '매물이 생성 되었습니다.' });
      },
      onError: () => {
        toast({ ...saveFail, description: '매물 생성에 실패했습니다.' });
      },
    },
  );
};

interface MuteUpdateCompanyRoomMemoProps {
  room_id?: string;
  company_memo?: string;
}

/** 회사(어드민, 고객사, 중개사, 호스트)의 특정 매물 메모 수정 */
export const useUpdateCompanyRoomMemo = () => {
  const [user, loading, error] = useAuthState(auth);
  const toast = useToast();
  const { data: ud } = useGetUserPathandKey();

  return useMutation(
    async (mute: MuteUpdateCompanyRoomMemoProps) => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      return await instance.patch(`${ud?.path}/rooms/${mute.room_id}/company-memo`, {
        company_memo: mute.company_memo,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
        toast({ ...saveSuccess, title: '매물 메모', description: '매물 메모 업데이트 성공' });
      },
      onError: () => {
        toast({ ...saveFail, title: '매물 메모', description: '매물 메모 업데이트 실패' });
      },
    },
  );
};

interface MuteDuplicateRoomProps {
  room_id: string;
}

export const useDuplicateRoom = () => {
  const [user, loading, error] = useAuthState(auth);
  const toast = useToast();
  const { data: ud } = useGetUserPathandKey();

  return useMutation(
    async (mute: MuteDuplicateRoomProps) => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      return await instance.post(`${ud?.path}/rooms/${mute.room_id}/duplicate`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([]);
        toast({ ...saveSuccess, title: '매물 복사에 성공하였습니다', description: '' });
      },
      onError: () => {
        toast({ ...saveFail, title: '매물 복사에 실패하였습니다', description: '' });
      },
    },
  );
};

interface MuteMakeUserHostProps {
  user_name: string;
  phone_number_first: string;
  registration_number: string;
  accounts: Array<{
    bank_code: number;
    account_number: string;
    account_owner_name: string;
  }>;
  resident_registers: Array<File>;
  account_copies: Array<File>;
  address: string;
  road_address: string;
  sido_name: string;
  sigungu_name: string;
  dongli_name: string;
  detail_address: string;
}

export const useMakeUserHost = (basic?: Type.Basic) => {
  const [user, loading, error] = useAuthState(auth);
  const toast = useToast();
  const config = basic?.axiosConfig;
  const { path } = Util.getCompanyUserTypePathKeyFromLocalStorage();
  const formData = new FormData();

  return useMutation(
    async (mute: MuteMakeUserHostProps) => {
      const {
        user_name,
        phone_number_first,
        accounts,
        resident_registers,
        account_copies,
        registration_number,
        address,
        road_address,
        sido_name,
        sigungu_name,
        dongli_name,
        detail_address,
      } = mute;
      formData.append('user_name', user_name);
      formData.append('registration_number', registration_number);
      formData.append('address', address);
      formData.append('road_address', road_address);
      formData.append('sido_name', sido_name);
      formData.append('sigungu_name', sigungu_name);
      formData.append('dongli_name', dongli_name);
      formData.append('detail_address', detail_address);
      formData.append('phone_number_first', phone_number_first);
      formData.append('accounts', JSON.stringify(accounts));
      resident_registers.map((register) => {
        formData.append('resident_registers', register);
      });
      account_copies.map((copy) => {
        formData.append('account_copies', copy);
      });

      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      return instance.post(`${path}/users/host`, formData, { ...config });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([KeyPath.company_hosts_key]);

        toast(saveSuccess);
      },
      onError: () => {
        toast({
          ...saveFail,
          description: '저장에 실패하셨습니다.',
        });
      },
    },
  );
};

interface MuteMakeUserHostAndCompanyProps extends MuteMakeUserHostProps {
  business_certificate: File;
  business_type: string;
  business_registration_number: string;
  company_name: string;
  registration_number: string;
  address: string;
  road_address: string;
  sido_name: string;
  sigungu_name: string;
  dongli_name: string;
  detail_address: string;
}

export const useMakeUserHostAndCompany = (basic?: Type.Basic) => {
  const [user, loading, error] = useAuthState(auth);
  const toast = useToast();
  const config = basic?.axiosConfig;
  const { path } = Util.getCompanyUserTypePathKeyFromLocalStorage();
  const formData = new FormData();

  return useMutation(
    async (mute: MuteMakeUserHostAndCompanyProps) => {
      const {
        user_name,
        phone_number_first,
        accounts,
        resident_registers,
        account_copies,
        business_certificate,
        business_type,
        business_registration_number,
        registration_number,
        company_name,
        address,
        road_address,
        sido_name,
        sigungu_name,
        dongli_name,
        detail_address,
      } = mute;

      formData.append('user_name', user_name);
      formData.append('phone_number_first', phone_number_first);
      formData.append('accounts', JSON.stringify(accounts));
      resident_registers.map((register) => {
        formData.append('resident_registers', register);
      });
      account_copies.map((copy) => {
        formData.append('account_copies', copy);
      });
      formData.append('business_certificate', business_certificate);
      formData.append('business_type', business_type);
      formData.append('business_registration_number', business_registration_number);
      formData.append('registration_number', registration_number);
      formData.append('company_name', company_name);
      formData.append('address', address);
      formData.append('road_address', road_address);
      formData.append('sido_name', sido_name);
      formData.append('sigungu_name', sigungu_name);
      formData.append('dongli_name', dongli_name);
      formData.append('detail_address', detail_address);

      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      return await instance.post(`${path}/users/host-and-company`, formData, { ...config });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([KeyPath.companies_hosts_key]);
        toast(saveSuccess);
      },
      onError: () => {
        toast({
          ...saveFail,
          description: '저장에 실패하셨습니다.',
        });
      },
    },
  );
};

interface MuteAddUserAccountProps {
  user_id: string;
  account: Type.AccountType;
}
export const useAddUserAccount = () => {
  const [user, loading, error] = useAuthState(auth);
  const toast = useToast();
  const { data: ud } = useGetUserPathandKey();

  return useMutation(
    async (mute: MuteAddUserAccountProps) => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      return await instance.post(`${ud?.path}/users/${mute.user_id}/account/add`, {
        account: {
          bank_code: mute.account?.bank_code,
          account_owner_name: mute.account?.account_owner_name,
          account_number: mute.account?.account_number,
        },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([]);
        toast({ ...saveSuccess, title: '계좌 생성 성공', description: '계좌이 완료되었습니다.' });
      },
      onError: () => {
        toast({ ...saveFail, title: '계좌 생성 실패', description: '계좌이 실패하였습니다.' });
      },
    },
  );
};

interface MuteCreateOtherRoomProps {
  url: string;
}
export const useCreateOtherRoom = () => {
  const [user, loading, error] = useAuthState(auth);
  const toast = useToast();
  const { data: ud } = useGetUserPathandKey();

  return useMutation(
    async (mute: MuteCreateOtherRoomProps) => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      return await instance.post(`${ud?.path}/create-other-room-from-url`, { url: mute?.url });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([]);
        toast({ ...saveSuccess, title: '계좌 생성 성공', description: '계좌이 완료되었습니다.' });
      },
      onError: () => {
        toast({ ...saveFail, title: '계좌 생성 실패', description: '계좌이 실패하였습니다.' });
      },
    },
  );
};

interface SendMoniesProps {
  data?: any;
}

export const useSendMonies = () => {
  const [user, loading, error] = useAuthState(auth);
  const toast = useToast();
  const { data: ud } = useGetUserPathandKey();

  return useMutation(
    async (mute: SendMoniesProps) => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      return await instance.post(`${ud?.path}/send-monies`, mute.data);
    },
    {
      onSuccess: () => {
        toast({ ...saveSuccess });
      },
      onError: () => {
        toast({ ...saveFail });
      },
    },
  );
};

interface MuteConfirmRecommendedRoomsProps {
  is_admin: boolean;
  rooms: Array<any>;
}

export const useConfirmRecommendedRooms = (custom_room_request_id: string) => {
  const [user, loading, error] = useAuthState(auth);
  const toast = useToast();
  const { data: ud } = useGetUserPathandKey();

  return useMutation(
    async (mute: MuteConfirmRecommendedRoomsProps) => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      return await instance.post(`${ud?.path}/custom-room-requests/${custom_room_request_id}/rooms/confirm`, {
        rooms: mute.rooms,
        is_admin: mute.is_admin,
      });
    },

    {
      onSuccess: () => {
        queryClient.invalidateQueries([]);
        toast(saveSuccess);
      },
      onError: () => {
        toast(saveFail);
      },
    },
  );
};

interface MuteSuggestRoomsProps {
  rooms: any[];
}

export const useSuggestRooms = (custom_room_request_id: string) => {
  const [user, loading, error] = useAuthState(auth);
  const toast = useToast();
  const { data: ud } = useGetUserPathandKey();

  return useMutation(
    async (mute: MuteSuggestRoomsProps) => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      return await instance.post(`${ud?.path}/custom-room-requests/${custom_room_request_id}/rooms/suggest`, {
        rooms: mute.rooms,
      });
    },

    {
      onSuccess: () => {
        queryClient.invalidateQueries([]);
        toast(saveSuccess);
      },
      onError: () => {
        toast(saveFail);
      },
    },
  );
};

interface MuteUpdateAdminRelayMemoProps {
  formData?: FormData;
  custom_room_request_id?: string;
  room_id?: string;
}

export const useUpdateAdminRelayMemo = () => {
  const [user, loading, error] = useAuthState(auth);
  const toast = useToast();
  const { data: ud } = useGetUserPathandKey();

  return useMutation(
    async (mute: MuteUpdateAdminRelayMemoProps) => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      return await instance.patch(
        `${ud?.path}/custom-room-requests/${mute.custom_room_request_id}/rooms/${mute.room_id}/memo`,
        mute.formData,
      );
    },

    {
      onSuccess: () => {
        queryClient.invalidateQueries([]);
        toast({ ...saveSuccess, title: '보홈 코멘트 업데이트 완료', description: '' });
      },
      onError: () => {
        toast({ ...saveFail, title: '보홈 코멘트 업데이트 실패', description: '' });
      },
    },
  );
};

interface MuteUpdateVirtualAccountNameProps {
  user_id: string;
  vaccntOwnerName: string;
}

export const useUpdateVirtualAccountName = () => {
  const [user, loading, error] = useAuthState(auth);
  const toast = useToast();
  const { data: ud } = useGetUserPathandKey();

  return useMutation(
    async (mute: MuteUpdateVirtualAccountNameProps) => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      return await instance.patch(`${ud?.path}/users/tenants/${mute.user_id}/wp/virtual-account/name`, {
        vaccntOwnerName: mute.vaccntOwnerName,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([]);
        toast({ ...saveSuccess, description: '가상계좌 이름변경 성공', title: 'SUCCESS' });
      },
      onError: () => {
        toast({ ...saveFail, description: '가상계좌 이름변경 실패', title: 'FAIL' });
      },
    },
  );
};

interface MuteUpdateStaticDataProps {
  _type: string;
  key: string;
  value: string;
}

export const useUpdateStaticData = () => {
  const [user, loading, error] = useAuthState(auth);
  const toast = useToast();
  const { data: ud } = useGetUserPathandKey();

  return useMutation(
    async (mute: MuteUpdateStaticDataProps) => {
      const token = await user?.getIdToken();
      const instance = await fetchClient({ headers: { token } });
      return await instance.patch(`${ud?.path}/static-data`, {
        _type: mute._type,
        key: mute.key,
        value: mute.value,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([]);
        toast({ ...saveSuccess, description: '가상계좌 이름변경 성공', title: 'SUCCESS' });
      },
      onError: () => {
        toast({ ...saveFail, description: '가상계좌 이름변경 실패', title: 'FAIL' });
      },
    },
  );
};

import { VStack, HStack, Box, Flex, Button } from '@chakra-ui/react';
import { WBox } from '../../../../../../components/common/Box';
import { HouseActiveIcon } from '../../../../../../statics/common/icons';
import AgencyInfo from './AgencyInfo';
import Manager from './Manager';
import SalesRegion from './SalesRegion';

const EditAgencyPage = () => {
  return (
    <WBox px={8} py={5}>
      <VStack alignItems={'flex-start'} spacing={8}>
        {/* Title */}
        <HStack>
          <HouseActiveIcon />
          <Box fontSize={'24px'} fontWeight={'bold'}>
            중개사무소 정보
          </Box>
        </HStack>
        {/* 영업 지역 */}
        <VStack w="100%">
          <Box fontWeight={'bold'} fontSize={'18px'} textAlign={'start'} flexGrow={1} w={'100%'}>
            영업 지역
          </Box>
          <Flex w="100%">
            <SalesRegion />
          </Flex>
        </VStack>
        {/* 중개사무소 정보 */}
        <VStack w="100%">
          <Box fontWeight={'bold'} fontSize={'18px'} textAlign={'start'} flexGrow={1} w={'100%'}>
            중개사무소 정보
          </Box>
          <Flex w="100%">
            <AgencyInfo />
          </Flex>
        </VStack>
        {/* 담당자 관리 */}
        <VStack w="100%">
          <Box fontWeight={'bold'} fontSize={'18px'} textAlign={'start'} flexGrow={1} w={'100%'}>
            담당자 관리
          </Box>
          {/* table */}
          <Manager />
        </VStack>
        <Flex w="100%" justifyContent={'flex-end'}>
          <Button variant={'basic'}>수정 완료</Button>
        </Flex>
      </VStack>
    </WBox>
  );
};

export default EditAgencyPage;

import {
  useDisclosure,
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  ButtonProps,
} from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { useRef } from 'react';

interface RequestSubscriptionModalButtonProps {
  initBtnProps: ButtonProps;
  confirmBtnProps?: ButtonProps;
}

const RequestSubscriptionModalButton: React.FC<RequestSubscriptionModalButtonProps> = ({
  initBtnProps,
  confirmBtnProps,
  children,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();

  const { handleSubmit } = useFormikContext();

  return (
    <>
      <Button
        // type="submit"
        variant={'basic'}
        w="full"
        fontSize={'16px'}
        style={{ height: '42px' }}
        fontWeight={'medium'}
        onClick={onOpen}
        {...initBtnProps}
      >
        {children}
      </Button>

      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef.current} onClose={onClose} isCentered>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              재확인
            </AlertDialogHeader>

            <AlertDialogBody>구독 요청을 확정하시겠습니까?</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef.current} onClick={onClose}>
                취소
              </Button>
              <Button
                type="submit"
                variant={'basic'}
                style={{ height: '40px' }}
                fontSize={'16px'}
                onClick={() => {
                  handleSubmit();
                  onClose();
                }}
                ml={3}
                {...confirmBtnProps}
              >
                확정
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default RequestSubscriptionModalButton;

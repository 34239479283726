import { Box, Center, Flex, Image } from '@chakra-ui/react';
import { Form, Formik, FormikProps } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import Intro from '../../../../components/agent/Intro/Agency';
import background from '../../../../statics/common/images/background.jpeg';
import Five from './sections/Five';
import Four from './sections/Four';
import One from './sections/One';
import SignUpLayout from './sections/sections/SignUpLayout';
import Six from './sections/Six';
import Three from './sections/Three';
import Two from './sections/Two';

export const initialValue = {
  one: false,
  two: false,
  three: false,
  four: false,
  five: false,
  // 1
  company_name: '',
  ceo: '',
  brokerage_number: '',
  address: '',
  phone_number: '',
  business_registration_number: '',
  business_certificate: '',
  brokerage_license: '',
  // 2
  email: '',
  user_name: '',
  // not including password
  p_password: '',
  p_confirm_password: '',
  phone_number_first: '',
  // 3
  sales_regions: [
    // {
    //   sido_name: '',
    //   sigungu_name: '',
    //   dongli_name: '',
    // },
  ],
  road_address: '',
  detail_address: '',
  sido_name: '',
  sigungu_name: '',
  dongli_name: '',
};

export interface ChildProps {
  formik: FormikProps<typeof initialValue>;
}

export interface StepProps {
  setStep: React.Dispatch<React.SetStateAction<0 | 1 | 2 | 3 | 4 | 5>>;
}

const AgencySignUpPage = () => {
  const [step, setStep] = useState<0 | 1 | 2 | 3 | 4 | 5>(0);
  return (
    <Box w={'100vw'} h={'100vh'}>
      <Image src={background} w={'100%'} h={'100%'} objectFit="cover" opacity={'0.16'} position={'absolute'} />
      <Center w={'100%'} h={'100%'}>
        <Box w={'1372px'} h={'772px'} position={'relative'} boxShadow={'lg'}>
          <Image src={background} w={'100%'} h={'100%'} objectFit="cover" />
          <Box w={'100%'} h={'100%'} position={'absolute'} top="0" bgColor="zetaBlue" opacity={0.5} />
          <Box w={'100%'} h={'100%'} position={'absolute'} top="0" bgColor="black" opacity={0.2} />
          <Flex position={'absolute'} top="0" w={'100%'} h="100%">
            <Box w="54%" h="100%" px="87px" pt="154px" pb="92px">
              <Intro />
            </Box>
            <Box bgColor={'white'} w="47%" h="100%" borderTopLeftRadius={'3rem'} px={14} py={20} overflowY={'auto'}>
              {/* SignUp */}
              {/* <One /> */}
              <SignUpLayout step={step}>
                <Flex flexDir={'column'} h={'full'}>
                  <Formik
                    initialValues={initialValue}
                    onSubmit={(values, { setSubmitting }) => {
                      console.log(values);
                      setSubmitting(false);
                    }}
                    validationSchema={Yup.object().shape({
                      p_email: Yup.string().email('유효하지 않는 이메일입니다.'),
                      // p_name
                      p_password: Yup.string()
                        .matches(/\w*[A-z]\w*/, '비밀번호는 영문을 포함하여야 합니다.')
                        .matches(/\d/, '비밀번호는 숫자를 포함하여야 합니다.')
                        .matches(/[!@#$%^&*()\-_"=+{}; :,<.>]/, '비밀번호는 특수문자를 포함하여야 합니다.')
                        .min(8, ({ min }) => `비밀번호는 최소 ${min}글자입니다.`)
                        .max(16, ({ max }) => `비밀번호는 최대 ${max}글자입니다.`),
                      p_confirm_password: Yup.string().when('p_password', {
                        is: (val: any) => (val && val.length > 0 ? true : false),
                        then: Yup.string().oneOf([Yup.ref('p_password')], '위의 비밀번호와 일치하지 않습니다.'),
                      }),
                      user_name: Yup.string().required('성함을 입력해 주세요.'),
                      department: Yup.string().required('부서를 입력해 주세요.'),
                      position: Yup.string().required('직책을 입력해 주세요.'),
                      phone_number_first: Yup.string().matches(/^((?!-).)*$/, '- 없이 입력해 주세요.'),
                    })}
                  >
                    <Form style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                      {
                        {
                          0: <One setStep={setStep} />,
                          1: <Two setStep={setStep} />,
                          2: <Three setStep={setStep} />,
                          3: <Four setStep={setStep} />,
                          4: <Five />,
                        }[step as 1 | 2 | 3 | 4]
                      }
                    </Form>
                  </Formik>
                </Flex>
              </SignUpLayout>
            </Box>
          </Flex>
        </Box>
      </Center>
    </Box>
  );
};

export default AgencySignUpPage;

import React from 'react';
import { Navigate, Route, RouteProps, Routes } from 'react-router-dom';
import { useGetUserType } from '../../lib/common/hooks/query';
import ChatPage from '../../pages/UserType/agent/chat/ChatPage';
import AgencyDashboard from '../../pages/UserType/agent/dashboard';
import AgencyManageAccountPage from '../../pages/UserType/agent/manage/account';
import EditAccountPage from '../../pages/UserType/agent/manage/account/editAccount';
import EditAgencyPage from '../../pages/UserType/agent/manage/account/editAgency';
import EditInfoPage from '../../pages/UserType/agent/manage/account/editInfo';
import AgencyManageRequestsPage from '../../pages/UserType/agent/manage/requests';
import AgencyServicePage from '../../pages/UserType/agent/service';
import Notfoundpage from '../../pages/common/NotFound';
import Loading from '../../components/common/Loading';
import UnderTapLayout from '../../layouts/common/main/UnderTapLayout';
import Rooms from '../../pages/UserType/agent/manage/estates/rooms';
import RoomDetail from '../../layouts/common/RoomDetail';
import Buildings from '../../layouts/common/Buildings';
import BuildingDetail from '../../layouts/common/BuildingDetail';
import StepOne from '../../pages/UserType/agent/manage/moverequests/stepone';

const AgencyCheckRoute: React.FC = () => {
  const { data, isLoading } = useGetUserType();

  const { company_type: userType } = data;
  console.log('agencyCheck Free');

  return (
    <Routes>
      <Route element={<UnderTapLayout />}>
        <Route path="chat">
          <Route index element={<ChatPage />} />
        </Route>
        <Route path="dashboard">
          <Route index element={<AgencyDashboard />} />
        </Route>
        <Route path="manage">
          <Route path="requests">
            <Route index element={<AgencyManageRequestsPage />} />
          </Route>
          <Route path="estates">
            <Route path="buildings">
              <Route index element={<Buildings />} />
              <Route path="detail">
                <Route path=":buildingId" element={<BuildingDetail />} />
              </Route>
            </Route>
            <Route path="rooms">
              <Route index element={<Rooms />} />
              <Route path="mapview" element={<>MapView</>} />
            </Route>
          </Route>
          <Route path="moverequests">
            <Route path="stepone" element={<StepOne />} />
          </Route>
          <Route path="roomInfo">
            <Route path=":id" element={<RoomDetail />} />
          </Route>
          <Route path="account">
            <Route index element={<AgencyManageAccountPage />} />
            <Route path="editInfo">
              <Route index element={<EditInfoPage />} />
            </Route>
            <Route path="editAccount">
              <Route index element={<EditAccountPage />} />
            </Route>
            <Route path="editAgency">
              <Route index element={<EditAgencyPage />} />
            </Route>
          </Route>
        </Route>
        <Route path="service">
          <Route index element={<AgencyServicePage />} />
        </Route>
        <Route path="*" element={<Notfoundpage />} />
      </Route>
    </Routes>
  );
};

export default AgencyCheckRoute;

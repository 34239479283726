import {
  Box,
  Button,
  Editable,
  EditableInput,
  EditablePreview,
  EditableProps,
  EditableTextarea,
  Flex,
  HStack,
  Input,
  useToast,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { saveCancle } from '../../../statics/common/data/toast';
import { EditControlButton } from './EditableControlButton';
import * as Type from '../../../types/common/CommonType';

interface CustomEditableProps extends EditableProps {
  // submitCallback?: (nextValue: string) => void;
  submitCallback?: Type.FuntionType;
  selectInputOrTextArea?: 'input' | 'textarea';
}

export const CustomEditable: React.FC<CustomEditableProps> = ({
  children,
  value,
  submitCallback,
  selectInputOrTextArea = 'input',
  ...props
}) => {
  const formik = useFormik({
    initialValues: { company_memo: '' },
    onSubmit: () => {
      submitCallback?.();
      return;
    },
  });
  const toast = useToast();
  const cancel = 'save-cancel';
  return (
    <Editable
      defaultValue={value ? value : ''}
      onCancel={() => ({})}
      onSubmit={submitCallback}
      selectAllOnFocus={false}
      submitOnBlur={false}
      display={'text'}
      {...props}
    >
      <EditablePreview />
      <HStack w={'100%'}>
        <EditableInput />
        <EditControlButton />
      </HStack>
    </Editable>
  );
};

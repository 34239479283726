import { InfoOutlineIcon } from '@chakra-ui/icons';
import { Table, Tbody, Tr, Flex, VStack, Tooltip, HStack, Button, Text, useDisclosure } from '@chakra-ui/react';
import { Field, useFormikContext } from 'formik';
import { CustomTd } from '../type';
import { SingleDatepicker } from '../../../../Calender';
import { CustomInput } from '../../../../Input';
import SelectBox from '../../../../../agent/SelectBox/base';
import {
  building_form as building_form_data,
  building_type as building_type_data,
} from '../../../../../../statics/common/data/selectbox';
import AddressFinder from '../../../AddressFinder';

const BuildingInfoTable: React.FC = ({ children }) => {
  const { isOpen: isOpenAddressFinder, onOpen: onOpenAddressFinder, onClose: onCloseAddressFinder } = useDisclosure();
  const formik = useFormikContext<any>();
  const { setFieldValue, values } = formik;
  return (
    <>
      <Table w={'100%'} variant="simple">
        <Tbody w={'100%'}>
          <Tr w={'100%'}>
            <CustomTd title={'건물유형'} required>
              <Flex w="200px" minW="200px">
                <Field name="building_type" as={SelectBox} h={'32px'} bgColor="lotionWhite" placeholder="선택">
                  {building_type_data.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Field>
              </Flex>
            </CustomTd>
          </Tr>
          <Tr w={'100%'}>
            <CustomTd title={'건물형태'} required>
              <Flex w="200px" minW="200px">
                <Field name="building_form" as={SelectBox} h={'32px'} bgColor="lotionWhite" placeholder="선택">
                  {building_form_data.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Field>
              </Flex>
            </CustomTd>
          </Tr>
          <Tr w={'100%'}>
            <CustomTd title={'주소'} required>
              <Flex w={'100%'}>
                <VStack w={'50%'}>
                  <Field
                    name="address"
                    as={CustomInput}
                    bgColor="lotionWhite"
                    placeholder=""
                    onChange={onOpenAddressFinder}
                    // onFocus={onOpenAddressFinder}
                    onClick={onOpenAddressFinder}
                    cursor="pointer"
                    autoComplete="off"
                  />
                </VStack>
              </Flex>
            </CustomTd>
          </Tr>
          <Tr w={'100%'}>
            <CustomTd
              title={
                <Tooltip
                  label="건물명은 매물 검색시 사용되기 때문에 정확히 기입해주세요."
                  hasArrow
                  bg="white"
                  color="highGray"
                  px={2}
                  py={3}
                  w={'220px'}
                  textAlign={'center'}
                  placement="top"
                >
                  <HStack>
                    <Text>건물명</Text>
                    <InfoOutlineIcon />
                  </HStack>
                </Tooltip>
              }
            >
              <Flex w={'20%'}>
                <Field name="name" w="200px" minW="200px" as={CustomInput} bgColor="lotionWhite" placeholder="" />
              </Flex>
            </CustomTd>
          </Tr>
          <Tr w={'100%'}>
            <CustomTd title={'준공일'}>
              <HStack w="200px" minW="200px">
                {/* <SingleDatepicker
                  date={values.completion_date as any}
                  onDateChange={(date) => {
                    console.log(date);
                    setFieldValue('completion_date', date);
                  }}
                  propsConfigs={{
                    inputProps: {
                      bgColor: 'lotionWhite',
                      size: 'sm',
                      borderRadius: 'md',
                      borderColor: 'silverGray',
                      color: 'highGray',
                    },
                    dateNavBtnProps: {
                      color: 'highGray',
                      _focus: {},
                    },
                  }}
                /> */}
                <Field name="completion_date" type="date" as={CustomInput} placeholder="날짜 선택" />
                <Button variant={'lotionWhite'} h={'32px'} onClick={() => setFieldValue('completion_date', '')}>
                  초기화
                </Button>
              </HStack>
            </CustomTd>
          </Tr>
          <Tr w={'100%'}>
            <CustomTd title={'전체 층수 및 세대수'}>
              <HStack>
                <Flex w="96px" minW="96px">
                  <Field name="floor" as={CustomInput} bgColor="lotionWhite" addon="층" />
                </Flex>
                <Flex w="96px" minW="96px">
                  <Field name="household_number" as={CustomInput} bgColor="lotionWhite" addon="세대" />
                </Flex>
              </HStack>
            </CustomTd>
          </Tr>
          {children}
        </Tbody>
      </Table>
      <AddressFinder isOpen={isOpenAddressFinder} onClose={onCloseAddressFinder} formik={formik} />
    </>
  );
};

export default BuildingInfoTable;

import { useParams } from 'react-router-dom';
import RequestRoomDetailTables from '../CustomRoomRequestDetail';

const RoomRequestDetail = () => {
  const params = useParams();
  const chatRoomId = params.chatroomid ? params.chatroomid : '';

  return <RequestRoomDetailTables room_request_id={chatRoomId} />;
};

export default RoomRequestDetail;

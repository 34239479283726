import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { useChangeSubscriptionContractStatus } from '../../../../lib/admin/hooks/mutation';
import { GlobalStatus } from '../../../../lib/common/utils/axios/types';
import { global_status_select } from '../../../../statics/common/data/selectbox';
import SelectBox from '../../../agent/SelectBox/base';

interface ChangeSubscriptionContractStatusProps {
  global_status: GlobalStatus;
  subscription_contract_id: string;
}

const ChangeSubscriptionContractStatus: React.FC<ChangeSubscriptionContractStatusProps> = ({
  global_status,
  subscription_contract_id,
}) => {
  const { mutateAsync } = useChangeSubscriptionContractStatus();
  const [value, setValue] = useState<GlobalStatus>(global_status);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();
  const formData = new FormData();

  const handleChangeOption = (e: any) => {
    setValue(e.target.value);
    onOpen();
  };

  const handleFinalize = () => {
    formData.append('status', value);
    mutateAsync({
      formData,
      subscription_contract_id,
    }).then(() => {
      onClose();
    });
  };

  const handleCloseAlert = () => {
    setValue(global_status);
  };

  const handleCancle = () => {
    handleCloseAlert();
    onClose();
  };

  return (
    <>
      <SelectBox
        value={global_status}
        onChange={(e) => {
          handleChangeOption(e);
        }}
      >
        {global_status_select.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </SelectBox>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef.current}
        onClose={onClose}
        isCentered
        onEsc={handleCloseAlert}
        onOverlayClick={handleCloseAlert}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              재확인
            </AlertDialogHeader>

            <AlertDialogBody>확정하시겠습니까?</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef.current} variant={'gray'} onClick={handleCancle}>
                취소
              </Button>
              <Button colorScheme="red" onClick={handleFinalize} variant={'basic'} ml={3}>
                확정하기
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default ChangeSubscriptionContractStatus;

import { Button } from '@chakra-ui/react';
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useGetUserType } from '../../../lib/common/hooks/query';
import Loading from '../../../components/common/Loading';
import { getLocalStorageStringItem } from '../../../lib/common/utils/localstorage';

const Main: React.FC = () => {
  const { data, isLoading } = useGetUserType();

  const { company_type: userType } = data;

  switch (userType) {
    case 'admin':
      return <Navigate to={{ pathname: '/admin/dashboard/companies' }} />;

    case 'subscriber':
      return <Navigate to={{ pathname: '/company/manage/dashboard' }} />;

    case 'agency':
      return <Navigate to={{ pathname: '/agency/manage/estates/rooms' }} />;

    default:
      console.log('this is main default');
      return <Navigate to={{ pathname: '/noaccess' }} />;
  }
};

export default Main;

import {
  Box,
  Button,
  Center,
  Collapse,
  Flex,
  HStack,
  Table,
  Tbody,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { Field, useFormikContext } from 'formik';
import Dropzone from 'react-dropzone';
import { initialValuesProps } from '.';
import ValidationWithTouch from '../../FormikValidation/ValidationWithTouch';
import { CustomInput } from '../../Input';
import AddressFinder from '../AddressFinder';
import { FRadioButtonGroup } from '../../Radio';
import { TrContainer } from '../../Table/Tr';
import { business_type_radio } from '../../../../statics/common/data/radio';
import AddAccountInfosModal from '../AddAccountModal';

export const TwoComplexRegister = () => {
  const formik = useFormikContext<initialValuesProps>();
  const { values, errors, touched, setFieldValue, isSubmitting, handleChange } = formik;
  const { isOpen: isOpenAddress, onOpen: onOpenAddress, onClose: onCloseAddress } = useDisclosure();
  const toast = useToast();

  const isAbleAddAccountInfos = true;

  const addedAccountNum = values.accounts.filter((account) => account?.is_added).length;

  return (
    <>
      <VStack w="full" spacing={4}>
        {/* 1 */}
        <Flex flexDir={'column'} fontWeight={'bold'} w="full">
          <Box mb={1}>
            <Text>회원 정보</Text>
          </Box>
          <Table w={'100%'}>
            <Tbody w={'100%'}>
              <TrContainer title="이름" textStyle={'medium14'}>
                <Field name="user_name" as={CustomInput} autoFocus placeholder="임대인명을 입력해 주세요."></Field>
                <ValidationWithTouch error={errors.user_name} touch={touched.user_name} />
              </TrContainer>
              <TrContainer title="휴대폰번호" textStyle={'medium14'}>
                <Field
                  name="phone_number_first"
                  as={CustomInput}
                  type={'tel'}
                  placeholder="'-'를 제외하고 입력해 주세요."
                ></Field>
                <ValidationWithTouch error={errors.phone_number_first} touch={touched.phone_number_first} />
              </TrContainer>
            </Tbody>
          </Table>
        </Flex>
        {/* 2 */}
        <Flex flexDir={'column'} fontWeight={'bold'} w="full">
          <Box mb={1}>
            <Text>회원 정보</Text>
          </Box>
          <Table w={'100%'}>
            <Tbody w={'100%'}>
              <TrContainer title="사업자 구분" textStyle={'medium14'}>
                <VStack align={'start'} spacing={1}>
                  <Field name="business_type" as={FRadioButtonGroup} options={business_type_radio} />
                  <ValidationWithTouch error={errors.business_type} touch={touched.business_type} />
                  <Field
                    name="business_registration_number"
                    as={CustomInput}
                    placeholder="'-'를 제외하고 입력해 주세요."
                    maxLength={10}
                  />
                  <ValidationWithTouch
                    error={errors.business_registration_number}
                    touch={touched.business_registration_number}
                  />
                </VStack>
              </TrContainer>
              <TrContainer title="법인명" textStyle={'medium14'}>
                <Field name="company_name" as={CustomInput} placeholder="법인명을 입력해 주세요."></Field>
                <ValidationWithTouch error={errors.company_name} touch={touched.company_name} />
              </TrContainer>
              <TrContainer title="소재지" textStyle={'medium14'}>
                {/* <CustomInput onFocus={} /> */}
                <VStack spacing={1}>
                  <Field
                    name="address"
                    as={CustomInput}
                    placeholder="기본 주소를 입력해 주세요."
                    onChange={() => true}
                    onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
                      onOpenAddress();
                      e.currentTarget.blur();
                    }}
                    onClick={onOpenAddress}
                    cursor={'pointer'}
                    autoComplete="off"
                  />
                  <Field name="detail_address" as={CustomInput} placeholder="상세 주소를 입력해 주세요." />
                </VStack>
                <ValidationWithTouch error={errors.address} touch={touched.address} />
                {/* <ValidationWithTouch error={errors.detail_address} touch={touched.detail_address} /> */}
              </TrContainer>
              <TrContainer title="사업자등록증" textStyle={'medium14'}>
                <Dropzone
                  accept={'image/*'}
                  onDrop={(acceptedFile) => {
                    console.log(acceptedFile);

                    if (acceptedFile.length === 0) {
                      return;
                    } else if (acceptedFile.length > 1 || values.business_certificate) {
                      toast({
                        title: '파일 업로드 실패',
                        description: `파일의 크기를 확인하시거나 개수가 1장을 넘지 않는지 확인하세요.`,
                        status: 'error',
                        variant: 'error',
                        duration: 2000,
                        isClosable: true,
                      });
                      return;
                    } else {
                      setFieldValue('business_certificate', acceptedFile[0]);
                      return;
                    }
                  }}
                  disabled={!!values.business_certificate}
                >
                  {({ getRootProps, getInputProps }) => {
                    return (
                      <Flex flexDir={'column'} w={'full'}>
                        <Center {...getRootProps()} w={'full'}>
                          <input {...getInputProps()} />
                          <Center
                            bgColor={values.business_certificate ? 'zetaBlue' : 'lotionWhite'}
                            color={values.business_certificate ? 'white' : '#414141'}
                            w={'full'}
                            h={'40px'}
                            borderRadius={'5px'}
                            borderWidth={'1px'}
                            borderColor={'silverGray'}
                            cursor="pointer"
                            fontWeight={'medium'}
                            _hover={values.business_certificate ? {} : { bgColor: 'zetaBlue', color: 'white' }}
                            transition={'0.18s linear'}
                          >
                            <Box fontSize={'14px'} fontWeight={'bold'}>
                              {values.business_certificate ? '파일 첨부 완료' : '첨부 파일을 추가해주세요.'}
                            </Box>
                          </Center>
                        </Center>
                      </Flex>
                    );
                  }}
                </Dropzone>
                <ValidationWithTouch error={errors.business_certificate} touch={touched.business_certificate} />
              </TrContainer>
              <TrContainer title="주민등록등본" textStyle={'medium14'}>
                <Dropzone
                  accept={'image/*'}
                  onDrop={(acceptedFiles) => {
                    if (acceptedFiles.length === 0) {
                      return;
                    } else {
                      setFieldValue('resident_registers', values.resident_registers.concat(acceptedFiles));
                    }
                  }}
                >
                  {({ getRootProps, getInputProps }) => {
                    return (
                      <Flex flexDir={'column'} w={'full'}>
                        <Center {...getRootProps()} w={'full'}>
                          <input {...getInputProps()} />
                          <Center
                            bgColor={'lotionWhite'}
                            w={'full'}
                            h={'40px'}
                            borderRadius={'5px'}
                            borderWidth={'1px'}
                            borderColor={'silverGray'}
                            cursor="pointer"
                            fontWeight={'medium'}
                            _hover={{ bgColor: 'zetaBlue', color: 'white' }}
                            transition={'0.18s linear'}
                          >
                            <Box fontSize={'14px'} fontWeight={'bold'}>
                              첨부 파일을 추가해주세요.
                            </Box>
                          </Center>
                        </Center>
                        <Collapse style={{ width: '100%' }} in={values.resident_registers.length !== 0}>
                          <HStack mt={2}>
                            <Box fontSize={'14px'} fontWeight={'normal'}>
                              {values.resident_registers.length + '개의 파일 첨부 완료'}
                            </Box>
                          </HStack>
                        </Collapse>
                      </Flex>
                    );
                  }}
                </Dropzone>
                <ValidationWithTouch error={!!errors.resident_registers} touch={!!touched.resident_registers} />
              </TrContainer>
              <TrContainer title="주민등록번호" textStyle={'medium14'}>
                <HStack>
                  <Field name="registration_number_front" as={CustomInput} placeholder="6자리" />
                  <Field name="registration_number_back" as={CustomInput} placeholder="7자리" />
                </HStack>
              </TrContainer>
              <TrContainer title="출금계좌 사본" textStyle={'medium14'}>
                <Dropzone
                  accept={'image/*'}
                  onDrop={(acceptedFiles) => {
                    if (acceptedFiles.length === 0) {
                      return;
                    } else {
                      setFieldValue('account_copies', values.account_copies.concat(acceptedFiles));
                    }
                  }}
                >
                  {({ getRootProps, getInputProps }) => {
                    return (
                      <Flex flexDir={'column'} w={'full'}>
                        <Center {...getRootProps()} w={'full'}>
                          <input {...getInputProps()} />
                          <Center
                            bgColor={'lotionWhite'}
                            w={'full'}
                            h={'40px'}
                            borderRadius={'5px'}
                            borderWidth={'1px'}
                            borderColor={'silverGray'}
                            cursor="pointer"
                            fontWeight={'medium'}
                            _hover={{ bgColor: 'zetaBlue', color: 'white' }}
                            transition={'0.18s linear'}
                          >
                            <Box fontSize={'14px'} fontWeight={'bold'}>
                              첨부 파일을 추가해주세요.
                            </Box>
                          </Center>
                        </Center>
                        <Collapse style={{ width: '100%' }} in={values.account_copies.length !== 0}>
                          <HStack mt={2}>
                            <Box fontSize={'14px'} fontWeight={'normal'}>
                              {values.account_copies.length + '개의 파일 첨부 완료'}
                            </Box>
                          </HStack>
                        </Collapse>
                      </Flex>
                    );
                  }}
                </Dropzone>
                <ValidationWithTouch error={!!errors.account_copies} touch={!!touched.account_copies} />
              </TrContainer>
            </Tbody>
          </Table>
          {/* Modal */}
          <AddressFinder isOpen={isOpenAddress} onClose={onCloseAddress} formik={formik} />
        </Flex>
        {/* 3 */}
        <Flex flexDir={'column'} fontWeight={'bold'} w="full">
          <Box mb={1}>
            <Text>계좌 정보</Text>
          </Box>
          <Table w={'100%'}>
            <Tbody w={'100%'}>
              <TrContainer title="계좌" textStyle={'medium14'}>
                <AddAccountInfosModal disabled={!isAbleAddAccountInfos}>
                  <Button variant={'basic'} disabled={!isAbleAddAccountInfos}>
                    새 계좌 추가
                  </Button>
                </AddAccountInfosModal>

                <Collapse style={{ width: '100%' }} in={addedAccountNum !== 0}>
                  <HStack mt={2}>
                    <Box fontSize={'14px'} fontWeight={'normal'}>
                      {addedAccountNum + '개의 계좌 추가 완료'}
                    </Box>
                  </HStack>
                </Collapse>
                {/* <ValidationWithTouch error={errors.accounts} touch={touched.accounts} /> */}
              </TrContainer>
            </Tbody>
          </Table>
        </Flex>
      </VStack>
      <Center pt={10}>
        <Button variant={'basic'} w="200px" minH="44px" type="submit" isLoading={isSubmitting}>
          등록 완료
        </Button>
      </Center>
    </>
  );
};

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Input,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { useUpdateSubscriptionPaymentStatus } from '../../../../lib/admin/hooks/mutation';
import { ContractPaymentStatus } from '../../../../lib/common/utils/axios/types';
import { contract_status, subscription_payment_status } from '../../../../statics/common/data/selectbox';
import { datedotformat } from '../../../../lib/common/utils/dateformatter';
import SelectBox from '../../../agent/SelectBox/base';

interface ChangeSubscriptionRealPaymentDateProps {
  real_payment_date?: string;
  subscription_payment_id: string;
}

const ChangeSubscriptionRealPaymentDate: React.FC<ChangeSubscriptionRealPaymentDateProps> = ({
  real_payment_date,
  subscription_payment_id,
}) => {
  const { mutateAsync } = useUpdateSubscriptionPaymentStatus(subscription_payment_id);
  const [value, setValue] = useState<string>(real_payment_date || '');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();

  const handleChangeOption = (e: any) => {
    const v = e.target.value;
    setValue(v);
    onOpen();
  };

  const handleFinalize = () => {
    mutateAsync({
      real_payment_date: value,
    }).then(() => {
      onClose();
    });
  };

  const handleCloseAlert = () => {
    setValue(real_payment_date || '');
  };

  const handleCancle = () => {
    handleCloseAlert();
    onClose();
  };

  if (real_payment_date) {
    return <Text>{datedotformat(real_payment_date)}</Text>;
  } else {
    return (
      <>
        {/* <SelectBox
          value={value ? 'true' : 'false'}
          onChange={(e) => {
            handleChangeOption(e);
          }}
        >
          {subscription_payment_status.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </SelectBox> */}

        <Input
          type="date"
          value={value || ''}
          onChange={(e) => {
            handleChangeOption(e);
          }}
          h={'36px'}
          fontSize={'14px'}
          style={{
            backgroundColor: 'white',
          }}
        />

        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef.current}
          onClose={onClose}
          isCentered
          onEsc={handleCloseAlert}
          onOverlayClick={handleCloseAlert}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                재확인
              </AlertDialogHeader>

              <AlertDialogBody>확정하시겠습니까?</AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef.current} variant={'gray'} onClick={handleCancle}>
                  취소
                </Button>
                <Button colorScheme="red" onClick={handleFinalize} variant={'basic'} ml={3}>
                  확정하기
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </>
    );
  }
};

export default ChangeSubscriptionRealPaymentDate;

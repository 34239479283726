import { Flex, Center, Box, Collapse, HStack, Button, FlexProps, CenterProps } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import Dropzone from 'react-dropzone';
import Thumb from '../Thumb/base';

interface Props extends FlexProps {
  formikKey?: string;
  centerProps?: CenterProps;
}

const FormikFileUploadButton: React.FC<Props> = ({ formikKey = 'files', centerProps, ...props }) => {
  const { values, setFieldValue, handleChange, getFieldProps } = useFormikContext<any>();
  return (
    <Dropzone
      //   accept={[
      //     '.csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values',
      //   ]}
      //   accept={'.csv'}
      onDrop={(acceptedFiles) => {
        if (acceptedFiles.length === 0) {
          return;
        } else {
          //   api 호출
          // const formData = new FormData();
          // formData.append('pre_service_request_file', acceptedFiles[0]);
          const collection: any[] = [];
          const previous_value = values[formikKey];

          collection.push(...previous_value, ...acceptedFiles);

          setFieldValue(formikKey, collection);
        }
      }}
    >
      {({ isDragActive, isDragReject, acceptedFiles, getRootProps, getInputProps }) => {
        return (
          <Flex flexDir={'column'} w={'full'} {...props}>
            <Center {...getRootProps()} w={'full'} justifyContent={'start'} {...centerProps}>
              <input {...getInputProps()} />
              <Button variant={'basic'}>
                <Box>파일 업로드</Box>
              </Button>
            </Center>
          </Flex>
        );
      }}
    </Dropzone>
  );
};

export default FormikFileUploadButton;

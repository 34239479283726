import { Flex } from '@chakra-ui/react';
import { useState } from 'react';
import LikedRequestSection from './liked';
import NewRequestSection from './new';
import SuggestedRequestSection from './suggested';

const AgencyManageRequestsPage = () => {
  const taps = [
    { id: 'new', title: '신규 요청' },
    { id: 'suggested', title: '제안한 요청' },
    { id: 'liked', title: '찜한 요청' },
  ];
  const initialActivate = taps[0].id;
  const [activeId, setActiveId] = useState(initialActivate);

  return (
    <Flex flexDir={'column'}>
      {/* 메인 */}
      {{ new: <NewRequestSection />, suggested: <SuggestedRequestSection />, liked: <LikedRequestSection /> }[activeId]}
    </Flex>
  );
};

export default AgencyManageRequestsPage;

import { Box, Skeleton, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import QueryParamsLayout from '../../../../../../../../layouts/common/main/QueryParamsLayout';
import TextLink from '../../../../../../../../components/common/Link/TextLink';
import { useGetHostAllBuildingsRooms } from '../../../../../../../../lib/admin/hooks/query';
import { currentParamsObject, ToAllFilledObj } from '../../../../../../../../lib/common/utils/base';
import { datedotformat } from '../../../../../../../../lib/common/utils/dateformatter';
import { decimalize } from '../../../../../../../../lib/common/utils/digitformatter';
import Loading from '../../../../../../../../components/common/Loading';

const Estate = () => {
  const { search: srch } = location;
  const { page, limit, id } = currentParamsObject(false);

  const _params = currentParamsObject();
  const params = ToAllFilledObj(_params);
  const res = useGetHostAllBuildingsRooms(id, {
    axiosConfig: {
      params,
    },
  });
  const { isLoading } = res;
  if (isLoading) return <Loading />;
  return (
    <Box w="full" h="calc(100vh - 320px)">
      <Box mb={2}>
        <Skeleton isLoaded={!isLoading}>
          <Text textStyle={'bold18'}>{`전체 매물 (${decimalize(res.data?.total)}건)`}</Text>
        </Skeleton>
      </Box>
      <QueryParamsLayout res={res}>
        <Table variant="head_silverbg_toplining">
          <Thead bgColor={'#F8F8F8'} borderColor={'gray.100'}>
            <Tr>
              <Th>매물 등록일</Th>
              <Th>매물번호</Th>
              <Th>주소</Th>
              <Th>매물상태</Th>
              <Th>입주자명</Th>
              <Th>중개사</Th>
            </Tr>
          </Thead>
          <Tbody>
            {res.data?.result.map((res, index) => {
              const { address, room } = res;
              return (
                <Tr key={srch + index}>
                  <Td>{datedotformat(room.createdAt)}</Td>
                  <Td>
                    <TextLink href={`/admin/manage/roomInfo/${room._id}`} target={'_blank'} minW={'max'}>
                      {room.code}
                    </TextLink>
                  </Td>
                  <Td>
                    <Text minW={'max'} textAlign={'start'}>
                      {address} {room.ho && `${room.ho}호`} {room.dong && `${room.dong}동`}
                    </Text>
                  </Td>
                  <Td>
                    <Text>{room.is_move_in ? '현재 공실' : '거래 완료'}</Text>
                  </Td>
                  <Td>
                    {room.tenant ? (
                      <TextLink to={`/admin/manage/customers/user/detail/linfo?id=${room.tenant._id}`}>
                        {room.tenant.user_name}
                      </TextLink>
                    ) : (
                      <Text>-</Text>
                    )}
                  </Td>
                  <Td>
                    {room.agent ? (
                      <TextLink to={`/admin/manage/customers/agency/detail/info?id=${room.agent._id}`}>
                        {room.agent.user_name}
                      </TextLink>
                    ) : (
                      <Text>-</Text>
                    )}
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </QueryParamsLayout>
    </Box>
  );
};
export default Estate;

import { Box, BoxProps } from '@chakra-ui/react';

export const WBox: React.FC<BoxProps> = ({ children, ...props }) => (
  <Box bgColor="white" border={'1px solid'} borderColor={'silverGray'} borderRadius={'md'} {...props}>
    {children}
  </Box>
);

export const GBox: React.FC<BoxProps> = ({ children, ...props }) => (
  <Box bgColor="white" border={'1px solid'} borderColor={'silverGray'} {...props}>
    {children}
  </Box>
);

// bg Gray No Board Box
export const GNoBBox: React.FC<BoxProps> = ({ children, ...props }) => (
  <Box bgColor="#F8F8F8" borderWidth={'0px'} borderRadius={'md'} px={4} py={6} {...props}>
    {children}
  </Box>
);

import { Box, Flex, HStack, Text, VStack } from '@chakra-ui/react';
import { format, isToday, isYesterday } from 'date-fns';
import { Notification, TenantStatus } from '../../../../lib/common/utils/axios/types';
import { NotiIconMatch } from '../../../../lib/subscriber/utils/utils';
import { TodayYesterdayFormat } from '../../../../lib/common/utils/base';
import { useGetCompanyManager } from '../../../../lib/admin/hooks/query';
import { pathOfUserDetailPage } from '../../../../lib/common/utils/utils';
import { useGetUserType } from '../../../../lib/common/hooks/query';
import { isUndefined } from 'lodash';

interface FirstCardSetProps {
  isOpen: boolean;
  onToggle: () => void;
  info: Notification;
  count: number;
}

const FirstCardSet: React.FC<FirstCardSetProps> = ({ isOpen, onToggle, info, count }) => {
  const userId = ((info as any)?.data as any)?.user_id;
  const isChecked = (info as any)?.is_checked;
  const { refetch } = useGetCompanyManager(userId || '', {
    options: {
      enabled: false,
    },
  });
  const { data, isLoading } = useGetUserType();
  if (data === undefined || isLoading) {
    console.log('loading...');
    return null;
  }
  const company_type = data?.company_type;

  const handleNavigateUserDetailPage = async () => {
    await refetch().then((result) => {
      const status = result.data?.employee.tenant_status as TenantStatus;
      const employeeId = result.data?.employee._id;
      const base = pathOfUserDetailPage(status);
      if (employeeId) {
        window.open(`${base}${employeeId}`);
      }
    });
  };
  const handleClickCard = async () => {
    const data = (info as any).data;

    if (isUndefined(company_type)) {
      return;
    }

    // if (company_type === 'admin') {

    // } else if ()

    /* 1. company_type에 따라 기능 분리 */
    switch (company_type) {
      case 'admin':
        // if (data.notification_type === 'user_approval_request') {
        // }
        if (data.user_id) {
          await handleNavigateUserDetailPage();
        } else if (data.subscription_contract_id) {
          window.open(`/admin/manage/companies/new`);
        }
        break;
      case 'agency':
        break;
      case 'subscriber':
        break;

      default:
        return;
    }
  };

  return (
    <Box
      pos={'relative'}
      w="full"
      id="cardset"
      role={'group'}
      // _hover={{ opacity: 0.7 }}
      // transition={'0.2s linear'}
      mb={isOpen ? 0 : 4}
    >
      {/* 카드 */}
      <Box
        layerStyle={company_type === 'admin' ? 'hover_opacity_animate' : ''}
        // layerStyle={userId && 'hover_opacity_animate'}
        _hover={{
          opacity: 1,
        }}
        bgColor={'#FBFBFB'}
        borderColor={isChecked ? 'gray.100' : 'gray.400'}
        borderRadius={'md'}
        borderWidth={1}
        w="full"
        color={isChecked ? 'gray.300' : '#414141'}
        pos={'relative'}
        zIndex={3}
        boxShadow={'sm'}
        onClick={handleClickCard}
      >
        <Box p={3}>
          <HStack align={'start'}>
            <Box display={'inline-block'}>{NotiIconMatch(info.notification_type)}</Box>
            <VStack w="full" align={'start'}>
              <HStack w="full" justify={'space-between'}>
                <Box>
                  {/* 제목 */}
                  <Text fontSize={'12px'} fontWeight={'bold'}>
                    {info.title}
                  </Text>
                </Box>
                {/* 날짜 */}
                <Box>
                  <Text fontSize={'12px'} fontWeight={'medium'} color="#C4C4C4">
                    {TodayYesterdayFormat(info.createdAt)}
                  </Text>
                </Box>
              </HStack>
              <Box>
                <Text fontSize={'14px'}>{info.description}</Text>
              </Box>
              <Flex w="full" justify={'end'}>
                <Text
                  textDecor={'underline'}
                  color={'zetaBlue'}
                  opacity={isChecked ? 0.4 : 1}
                  textUnderlineOffset={'1px'}
                  cursor={'pointer'}
                  fontSize={'12px'}
                  fontWeight={'medium'}
                  onClick={(e) => {
                    e.stopPropagation();
                    onToggle();
                  }}
                >
                  {isOpen ? '숨기기' : `${count - 1}개 더 보기`}
                </Text>
              </Flex>
            </VStack>
          </HStack>
        </Box>
      </Box>
      {!isOpen && (
        <>
          {/* 잔상 1 */}
          <Flex w="full" justify={'center'} pos={'absolute'} top={2} h={'100%'}>
            <Box
              bgColor={'#FBFBFB'}
              borderColor={isChecked ? 'gray.100' : 'gray.300'}
              borderRadius={'md'}
              borderWidth={1}
              w="95%"
              zIndex={2}
            ></Box>
          </Flex>
          {/* 잔상 2 */}
          <Flex w="full" justify={'center'} pos={'absolute'} top={4} h={'100%'}>
            <Box
              bgColor={'#FBFBFB'}
              borderColor={'gray.100'}
              borderRadius={'md'}
              borderWidth={1}
              boxShadow={'sm'}
              w="90%"
              zIndex={1}
            ></Box>
          </Flex>
        </>
      )}
    </Box>
  );
};

export default FirstCardSet;

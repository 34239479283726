import {
  Box,
  Button,
  HStack,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { useCreateUserVirtualAccount, VirtualAccountUserType } from '../../../../lib/admin/hooks/mutation';
import { formikInitialValuesProps } from '../ConfirmContractModal';

interface CreateVirtualAccountModalProps {
  disabled?: boolean;
  userType: VirtualAccountUserType;
  userId: string;
}

const CreateVirtualAccountModal: React.FC<CreateVirtualAccountModalProps> = ({
  children,
  disabled = false,
  userType,
  userId,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { mutateAsync } = useCreateUserVirtualAccount();

  const handleModalOpen = () => {
    if (!disabled) {
      onOpen();
    }
  };

  const onClickCancelButton = () => {
    onClose();
  };

  const onClickCreateButton = () => {
    mutateAsync({
      userId,
      userType,
    }).then(() => {
      onClose();
    });
  };

  const UserType = userType === 'company' ? '고객사' : userType === 'tenant' ? '임직원' : '...';

  return (
    <>
      <Box onClick={handleModalOpen}>{children}</Box>

      <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside" isCentered size={'xl'}>
        <ModalOverlay />
        <ModalContent
          p={'100px'}
          // h={values.step === 'zero_main' ? '45%' : 'full'}
        >
          <ModalCloseButton />
          {/* <Scrollbars style={{ height: '100%' }}> */}
          <Box w="full" h="full">
            <Box textAlign={'center'}>
              <Text textStyle={'medium18'}>해당 {UserType}의 가상계좌를 발급하시겠습니까?</Text>
            </Box>
          </Box>
          <HStack w="full" mt={'30px'} justify={'center'}>
            <Button variant={'gray'} w={'100px'} onClick={onClickCancelButton}>
              <Text textStyle={'medium14'}>아니오</Text>
            </Button>
            <Button variant={'basic'} w={'100px'} onClick={onClickCreateButton}>
              <Text textStyle={'medium14'}>네</Text>
            </Button>
          </HStack>
          {/* </Scrollbars> */}
        </ModalContent>
      </Modal>
    </>
  );
};

export default CreateVirtualAccountModal;

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react';
import { useRef } from 'react';
import { DialogDisclosureProps } from '.';

interface DeleteReCheckProps extends DialogDisclosureProps {
  title: string;
  content: string;
  item: any;
}

const DeleteReCheck: React.FC<DeleteReCheckProps> = ({ onClose, isOpen, title, content, item }) => {
  const cancelRef = useRef();
  console.log('item => ', item);

  return (
    <>
      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef.current} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {title}
            </AlertDialogHeader>

            <AlertDialogBody>{content}</AlertDialogBody>

            <AlertDialogFooter>
              <Button size="sm" w={'80px'} ref={cancelRef.current} onClick={onClose}>
                취소
              </Button>
              <Button size="sm" w={'80px'} colorScheme="red" onClick={onClose} ml={3}>
                삭제
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default DeleteReCheck;

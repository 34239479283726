import { Box, BoxProps } from '@chakra-ui/react';

export const PlanCardBox: React.FC<BoxProps> = ({ children, ...props }) => (
  <Box
    justifyContent={'space-between'}
    borderWidth={'1px'}
    borderColor={'borderGray'}
    borderRadius={'xl'}
    px={3}
    py={3}
    w={'full'}
    minW="275px"
    // h={'full'}
    boxShadow={'0px 0px 4px 4px #E5E5E53B;'}
    h={'423px'}
    {...props}
  >
    {children}
  </Box>
);

import { Box, Collapse, Flex, Input, InputProps } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { useState } from 'react';
import { initialValue } from '../..';

interface FileInputProps extends InputProps {
  handleFormikChange: any;
}

export const FileInput: React.FC<FileInputProps> = ({ children, id, handleFormikChange, ...props }) => {
  const [selectedFile, setSelectedFile] = useState('');
  const [selectedFileName, setSelectedFileName] = useState('');
  const handleselectedFile = (e: any) => {
    setSelectedFile(e.target.files[0]);
    setSelectedFileName(e.target.files[0].name);
    handleFormikChange(e);
    // check!
    // setFieldValue('business_registration', e.target.files[0]);
    // check!
    if (props.name) {
      setFieldValue(props.name, e.target.files[0]);
    }
  };
  // console.log('selectedFile => ', selectedFile);

  const { values, setFieldValue, handleChange } = useFormikContext<typeof initialValue>();

  console.log('input => ', values);

  return (
    <>
      <Input type="file" id={id} display={'none'} onChange={handleselectedFile} {...props} />
      <label htmlFor={id} style={{ width: '100%' }}>
        <Flex
          bgColor={'lotionWhite'}
          borderColor={'silverGray'}
          borderWidth={'1px'}
          boxShadow={'sm'}
          borderRadius={'md'}
          fontSize={'12px'}
          h={'32px'}
          _hover={{
            bgColor: 'zetaBlue',
            color: 'white',
            borderColor: 'silverGray',
          }}
          transition={'0.18s ease'}
          alignItems={'center'}
          justifyContent={'space-between'}
          px={3}
          cursor="pointer"
        >
          <Box>{children}</Box>
          {!!selectedFileName && <Box>첨부 완료</Box>}
        </Flex>
      </label>
      <Collapse style={{ width: '100%' }} in={!!selectedFileName}>
        {selectedFileName}
      </Collapse>
    </>
  );
};

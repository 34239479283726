import { Box, Button, Flex, Grid, HStack, Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import { FaSearch } from 'react-icons/fa';
import { GItem } from '../../../../../components/common/GridItem';
import RequestLists from './RequestLists';
import Status from './Status';
import UsageLists from './UsageLists';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';

export interface PathProps {
  path: {
    [key in 'main' | 'onseek' | 'oncontract' | 'onmovein']: {
      pathname: string;
      isCurrent: boolean;
    };
  };
}

const SubscriptionMainPage = () => {
  const [currentPage, setCurrentPage] = useState('');
  const { pathname } = useLocation();
  // const { main, onseek, oncontract, onmovein } = {
  const path = {
    main: {
      pathname: '/company/manage/employee',
      isCurrent: pathname === '/company/manage/employee',
    },
    onseek: {
      pathname: '/company/manage/employee/onseek',
      isCurrent: pathname === '/company/manage/employee/onseek',
    },
    oncontract: {
      pathname: '/company/manage/employee/oncontract',
      isCurrent: pathname === '/company/manage/employee/oncontract',
    },
    onmovein: {
      pathname: '/company/manage/employee/onmovein',
      isCurrent: pathname === '/company/manage/employee/onmovein',
    },
    _pathname: pathname,
  };
  // 250 6*4
  return (
    <HStack w="full" h="full" align={'start'} spacing={6}>
      {/* 회사 관련 정보 섹션 */}
      <Status path={path} />
      <Grid
        w="full"
        h="full"
        // templateRows="repeat(10, 1fr)"
        // gridAutoRows={'minmax(5.7vh, auto)'}
        // gridTemplateColumns={'1fr'}
        gridTemplateRows={'150px calc(100vh - 302px)'}
        // gridAutoColumns={'minmax(5vw,auto)'}
        gap={6}
      >
        {/* 이용 신청 현황 */}
        <RequestLists path={path} />
        {/* 이용 신청 내역 */}
        <UsageLists path={path} />
      </Grid>
    </HStack>
  );
};

export default SubscriptionMainPage;

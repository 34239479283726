import { CloseIcon, InfoOutlineIcon } from '@chakra-ui/icons';
import {
  Table,
  Tbody,
  Tr,
  Flex,
  VStack,
  Tooltip,
  HStack,
  Button,
  Text,
  useDisclosure,
  toast,
  useToast,
  Box,
} from '@chakra-ui/react';
import { Field, FieldArray, isEmptyArray, useFormikContext } from 'formik';
import { CustomTd } from '../type';
import { SingleDatepicker } from '../../../../Calender';
import { CustomInput } from '../../../../Input';
import SelectBox from '../../../../../agent/SelectBox/base';
import {
  building_form as building_form_data,
  building_type as building_type_data,
} from '../../../../../../statics/common/data/selectbox';
import AddressFinder from '../../../AddressFinder';
import BuildingInfoTable from './a-buildinginfo';
import { warning } from '../../../../../../statics/common/data/toast';
import { Fragment } from 'react';

const ColivingBuildingInfoTable: React.FC = ({ children }) => {
  const formik = useFormikContext<any>();
  const { setFieldValue, values } = formik;
  const toast = useToast();

  const handleAddItem = () => {
    const value = values.coliving_room_type_frontend;
    const idx = values.room_types.length;
    if (value) {
      setFieldValue(`room_types[${idx}]`, value);
      setFieldValue('coliving_room_type_frontend', '');
    } else {
      toast({ ...warning, title: '입력 필요', description: '정확한 입력이 필요합니다.' });
    }
  };

  const onCheckEnter = (e: any) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      handleAddItem();
    }
  };

  return (
    <BuildingInfoTable>
      <Tr w={'100%'}>
        <CustomTd
          title={
            <Tooltip
              label="건물 내 모든 종류의 룸 타입을 입력해 주세요."
              hasArrow
              bg="white"
              color="highGray"
              px={2}
              py={3}
              w={'220px'}
              textAlign={'center'}
              placement="top"
            >
              <HStack>
                <Text>룸 타입</Text>
                <InfoOutlineIcon />
              </HStack>
            </Tooltip>
          }
        >
          <HStack w="200px" minW="200px">
            <Field
              name="coliving_room_type_frontend"
              as={CustomInput}
              bgColor="lotionWhite"
              placeholder=""
              onKeyPress={onCheckEnter}
            />
            <Button variant={'lotionWhite'} h={'32px'} onClick={handleAddItem}>
              추가하기
            </Button>
          </HStack>
          <FieldArray name="room_types">
            {({ insert, remove, push }) => (
              <>
                {!isEmptyArray(values.room_types) && (
                  <HStack mt={2}>
                    {values.room_types.map((roomType: any, index: any) => {
                      return (
                        <Button
                          key={index}
                          variant={'blue_hover_silver'}
                          h={'32px'}
                          fontWeight={400}
                          px={2}
                          py={1}
                          onClick={() => remove(index)}
                        >
                          <HStack>
                            <Text>{roomType}</Text>
                            <CloseIcon boxSize={'10px'} />
                          </HStack>
                        </Button>
                      );
                    })}
                  </HStack>
                )}
              </>
            )}
          </FieldArray>
        </CustomTd>
      </Tr>
    </BuildingInfoTable>
  );
};

export default ColivingBuildingInfoTable;
